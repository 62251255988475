import { useState, useEffect, createContext } from 'react';
// import useAccountingRoles from '../../custom-hooks/useAccountingRoles';

const INITIAL_VALUES = {
  fetchCount: 0,
  setFetchCount: (v) => {},
  isLoading: false,
  setIsLoading: (v) => {},
  isInfoLoading: false,
  setIsInfoLoading: (v) => {},
  accountingModalOpened: false,
  setAccountingModalOpened: (v) => {},
  accountInfoModalOpened: false,
  setAccountInfoModalOpened: (v) => {},
  selectedAccountNode: null,
  setSelectedAccountNode: (v) => {},
  selectedAccountId: null,
  setSelectedAccountId: (v) => {},
  accountingTree: [],
  setAccountingTree: (v) => {},
  mappedAccountingTree: {},
  setMappedAccountingTree: (v) => {},
  //
  accountingRoles: [],
  setAccountingRoles: (v) => {},
  editMode: false,
  setEditMode: (v) => {},
  //
  isBaseNode: false,
  setIsBaseNode: (v) => {},
  foundAccount: false,
  setFoundAccount: (v) => {},
  accountInfo: null,
  setAccountInfo: (v) => {},
  accountsLists: null,
  setAccountsLists : (v) => {},
  accountsListsUpdates: null,
  setAccountsListsUpdates : (v) => {},
};

const AccountingContext = createContext(INITIAL_VALUES);

export const AccountingProvider = ({ children }) => {
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [isInfoLoading, setIsInfoLoading] = useState(INITIAL_VALUES.isInfoLoading);
  const [accountingModalOpened, setAccountingModalOpened] = useState(
    INITIAL_VALUES.accountingModalOpened
  );
  const [accountInfoModalOpened, setAccountInfoModalOpened] = useState(
    INITIAL_VALUES.accountingModalOpened
  );
  const [accountingTree, setAccountingTree] = useState(
    INITIAL_VALUES.accountingTree
  );
  const [mappedAccountingTree, setMappedAccountingTree] = useState(
    INITIAL_VALUES.mappedAccountingTree
  );
  const [selectedAccountNode, setSelectedAccountNode] = useState(
    INITIAL_VALUES.selectedAccountNode
  );
  const [selectedAccountId, setSelectedAccountId] = useState(
    INITIAL_VALUES.selectedAccountId
  );
  const [accountsLists , setAccountsLists] = useState(
    INITIAL_VALUES.accountsLists
  );
  const [accountsListsUpdates , setAccountsListsUpdates] = useState(
    INITIAL_VALUES.accountsListsUpdates
  );
  //
  // const [accountingRoles, setAccountingRoles] = useState(
  //   INITIAL_VALUES.accountingRoles
  // );
  // //
  const [editMode, setEditMode] = useState(INITIAL_VALUES.editMode);
  const [isBaseNode, setIsBaseNode] = useState(INITIAL_VALUES.isBaseNode);
  const [foundAccount, setFoundAccount] = useState(INITIAL_VALUES.isLoading);
  const [accountInfo, setAccountInfo] = useState(INITIAL_VALUES.accountInfo);

  useEffect(() => {
    if (accountingTree?.length >= 0) {
      setMappedAccountingTree({
        name: 'شجرة الحســابات',
        toggled: true,
        fakeLeaf: true,
        children: [...accountingTree]
      });
    }
  }, [accountingTree]);

  // const allAccountingRoles = useAccountingRoles();

  // useEffect(() => {
  //   if (allAccountingRoles?.length > 0) {
  //     setAccountingRoles(allAccountingRoles);
  //   }
  // }, [allAccountingRoles]);

  return (
    <AccountingContext.Provider
      value={{
        fetchCount,
        setFetchCount,
        isLoading,
        setIsLoading,
        accountingModalOpened,
        setAccountingModalOpened,
        accountInfoModalOpened,
        setAccountInfoModalOpened,
        selectedAccountNode,
        setSelectedAccountNode,
        selectedAccountId,
        setSelectedAccountId,
        accountingTree,
        setAccountingTree,
        mappedAccountingTree,
        setMappedAccountingTree,
        //
        // accountingRoles,
        // setAccountingRoles,
        //
        editMode,
        setEditMode,
        isBaseNode,
        setIsBaseNode,
        setFoundAccount,
        foundAccount,
        accountInfo,
        setAccountInfo,
        isInfoLoading, 
        setIsInfoLoading,
        accountsLists,
        setAccountsLists,
        accountsListsUpdates,
        setAccountsListsUpdates
      }}
    >
      {children}
    </AccountingContext.Provider>
  );
};

export default AccountingContext;
