import React, { useContext } from 'react';
import { Modal } from 'antd';
import ClientsContext from '../../../contexts/clients-context/ClientsContext';
import InvoiceClientForm from './InvoiceClientForm';
import { useTranslation } from 'react-i18next';

const InvoiceNewClientModal = ({ setAllClientsFetchCount }) => {
  const { modalOpened, setModalOpened } = useContext(ClientsContext);
  const { t } = useTranslation();

  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '642px' }}
      title={t('Customers.AddNewCustomer')}
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
      }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      <InvoiceClientForm setAllClientsFetchCount={setAllClientsFetchCount} />
    </Modal>
  );
};

export default InvoiceNewClientModal;
