/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Pagination, Table } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import tableColumns from './tableColumns';
import queryString from 'query-string';
import UserContext from '../../contexts/user-context/UserProvider';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import ReturnModalContext from '../../contexts/return-modal-context/ReturnModalContext';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import getAllSaleInvoicesApi from '../../apis/sale-invoice-api/getAllSaleInvoicesApi';
import Links from '../../components/app/Links';
import { useTranslation } from 'react-i18next';
// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const PageTable = () => {
  const routerLinks = Links();
  const { t } = useTranslation();

  const { setReturnModalOpened, returnModalOpened } =
    useContext(ReturnModalContext);
  const {
    fetchCountSaleInvoices,
    isLoadingAllSaleInvoices,
    setIsLoadingAllSaleInvoices,
    setFetchCountSaleInvoices,
    allFetchedSaleInvoices,
    setAllFetchedSaleInvoice,
    selectedInvoice,
    setSelectedInvoice,
    setLaterInvoiceModalOpened,
    setSelectedSaleInvoiceModalOpened,
    ///
    tableFilter,
    tablePagination,
    setTablePagination,
    setLoadingInvoice
  } = useContext(PosInvoiceContext);
  const { user } = useContext(UserContext);
  const history = useHistory();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoadingAllSaleInvoices(true);

      customApiRequest(
        getAllSaleInvoicesApi(user?.token || user?.employee?.token, {
          ...tableFilter,
          ...values
        }),
        (res) => {
          setIsLoadingAllSaleInvoices(false);
          if (res?.status === 200 && res?.data?.data?.data?.length >= 0) {
            const data = res?.data?.data?.data;
            setAllFetchedSaleInvoice(data);

            if (res?.data?.data?.pagination) {
              setTablePagination(res.data.data.pagination);
            }
          }
        },
        (error) => {
          setIsLoadingAllSaleInvoices(false);
        }
      );
    }
    return () => {
      isMounted = false;
    };
  }, [fetchCountSaleInvoices , allFetchedSaleInvoices?.length]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedSaleInvoices?.length >= 0) {
      if (isMounted) {
        setTableDataSource(mapDataSource(allFetchedSaleInvoices));
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    fetchCountSaleInvoices,
    allFetchedSaleInvoices,
    allFetchedSaleInvoices?.length
  ]);

  return (
    <>
      <Table
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedSaleInvoices,
          setLaterInvoiceModalOpened,
          setFetchCountSaleInvoices,
          selectedInvoice,
          setSelectedInvoice,
          setIsLoadingAllSaleInvoices,
          setSelectedSaleInvoiceModalOpened,
          setReturnModalOpened,
          user?.employee?.token,
          setLoadingInvoice
        )}
        loading={isLoadingAllSaleInvoices}
        // scroll={{ x: 400 }}
        footer={false}
      />

      {tablePagination && (
        <Pagination
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '15px'
          }}
          defaultCurrent={1}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          showSizeChanger={false}
          // itemRender={itemRender}
          onChange={(page, pageSize) => {
            setFetchCountSaleInvoices((prev) => prev + 1);
            history.push(`${routerLinks?.saleInvoicesPosPage}?page=${page}`);
          }}
          hideOnSinglePage={true}
        />
      )}
    </>
  );
};

export default PageTable;
