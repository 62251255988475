import React from 'react';

const AddDocumentIcon = ({ color }) => {
  return (
    <svg
      id="surface1"
      xmlns="http://www.w3.org/2000/svg"
      width="10.297"
      height="11.319"
      viewBox="0 0 10.297 11.319"
    >
      <path
        id="Path_3044"
        data-name="Path 3044"
        d="M7.714,4.9V2.758a.248.248,0,0,0-.068-.163L5.244.073A.239.239,0,0,0,5.072,0H1.264A1.269,1.269,0,0,0,0,1.276v7.2A1.261,1.261,0,0,0,1.264,9.743H4.27A3.244,3.244,0,1,0,7.714,4.9ZM5.308.828l1.611,1.7H5.874a.57.57,0,0,1-.566-.568ZM1.264,9.271a.79.79,0,0,1-.793-.795v-7.2a.8.8,0,0,1,.793-.8H4.836V1.956A1.04,1.04,0,0,0,5.874,3H7.242V4.838c-.071,0-.127-.009-.189-.009A3.292,3.292,0,0,0,4.9,5.641h-3a.236.236,0,1,0,0,.472H4.463a3.753,3.753,0,0,0-.415.731H1.906a.236.236,0,0,0,0,.472H3.895a3.238,3.238,0,0,0,.142,1.958H1.264Zm5.787,1.578A2.772,2.772,0,1,1,9.823,8.077,2.775,2.775,0,0,1,7.051,10.849Zm0,0"
        transform="translate(0)"
        fill={color ? color : '#fff'}
      />
      <path
        id="Path_3045"
        data-name="Path 3045"
        d="M75.787,202.156h2.39a.236.236,0,1,0,0-.472h-2.39a.236.236,0,1,0,0,.472Zm0,0"
        transform="translate(-73.881 -197.225)"
        fill={color ? color : '#fff'}
      />
      <path
        id="Path_3046"
        data-name="Path 3046"
        d="M266.7,312.458h-.722v-.734a.236.236,0,1,0-.472,0v.734h-.734a.236.236,0,0,0,0,.472h.734v.722a.236.236,0,0,0,.472,0v-.722h.722a.236.236,0,0,0,0-.472Zm0,0"
        transform="translate(-258.687 -304.602)"
        fill={color ? color : '#fff'}
      />
    </svg>
  );
};

export default AddDocumentIcon;
