import axios from 'axios';

const addNewNoteApi = async (token, values, edit = false) => {
  // const mappedValues = {};
  // if (values.title) mappedValues.title = values.title;
  // if (values.note) mappedValues.note = values.note;
  // if (values.id) mappedValues.id = values.id;
      const formData = new FormData();
    // Append filterValues to FormData
    Object.keys(values).forEach(key => {
      formData.append(key, values[key]);
    });
  try {
    let url;
    if (!edit) url = '/Note/create';
    else url = '/Note/update';
    const res = await axios.post(url,   formData, // Use formData as the data payload
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data', // Set Content-Type to multipart/form-data
          lang: 'ar',
          Authorization: 'Bearer ' + token
        }
      });
    return res;
  } catch (error) {
    throw error;
  }
};

export default addNewNoteApi;
