/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import getSingleInvoiceApi from '../apis/sale-invoice-api/getSingleInvoiceApi';
import UserContext from '../contexts/user-context/UserProvider';
import useCustomApiRequest from '../custom-hooks/useCustomApiRequest';

const useSingleInvoice = (invoiceId) => {
  const [singleInvoice, setSingleInvoice] = useState(null);
  const { user } = useContext(UserContext);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getSingleInvoiceApi(user?.token || user?.employee?.token, {
          invoiceId
        }),
        (res) => {
          if (res?.data?.data) {
            const data = res.data.data;
            setSingleInvoice(data);
          } else {
            //
          }
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return singleInvoice;
};

export default useSingleInvoice;
