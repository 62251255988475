import './Logo.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import routerLinks from '../../components/app/routerLinks';

const Logo = ({ src, className, isExternalLink = false }) => {
  return isExternalLink ? (
    <a
      className={className}
      href="https://fatoorah.sa"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={src || '/assets/imgs/logo/logo-white-2.png'} alt="app logo" />
    </a>
  ) : (
    <Link className={className} to={routerLinks.homePage}>
      <img src={src || '/assets/imgs/logo/logo-white-2.png'} alt="app logo" />
    </Link>
  );
};

export default Logo;
