/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import getAllWarehousesApi from '../apis/settings-apis/settings-warehouses-apis/getAllWarehouses';
import UserContext from '../contexts/user-context/UserProvider';
import useCustomApiRequest from './useCustomApiRequest';
const useWarehouses = () => {
  const [allWarehouses, setAllWarehouses] = useState(null);
  const { user } = useContext(UserContext);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getAllWarehousesApi(user?.token),
        (res) => {
          if (res?.status === 200 && res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllWarehouses(data);
          } else {
            //
          }
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return allWarehouses;
};

export default useWarehouses;
