import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import TableFilter from './TableFilter';
import PageTable from './PageTable';
import PageModal from './PageModal';
import { StockOutlined } from '@ant-design/icons';
import StoreInventoryContext from '../../../contexts/store-inventory-context/StoreInventoryProvider';
import Countdown from '../../../components/countdown/Countdown';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import SelectedReceiptModalProducts from './SelectedReceiptModalProducts';

const StoreInventoryPage = () => {
  const {
    modalOpened,
    setModalOpened,
    selectedReceiptModalOpened,setSelectedInventory
  } = useContext(StoreInventoryContext);
  const { t } = useTranslation();
  return (
    <div className="measurement-units-page">
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">
            <StockOutlined /> {t('Inventory.WarehouseInventory.title')}
          </h2>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('Inventory.WarehouseInventory.title')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.storePage.title')}
            title3={t('Inventory.WarehouseInventory.title')}
            link3={'/store/inventory'}
          />
        </div>
        {/* <Countdown /> */}
        <SearchNotificationsProfile />
      </div>

      <div className="custom-filter-add-section">
        <div className="add-section">
          <div
            className="add-account-btn"
            onClick={() => {
              // if selected unit then reset it
              // open the unit modal
              setSelectedInventory(null);
              setModalOpened(true);
            }}
          >
            <span className="text-span">
              {t('Inventory.WarehouseInventory.addNewInventory')}
            </span>
          </div>
        </div>
        <TableFilter />
      </div>

      <PageTable />
      {selectedReceiptModalOpened && <SelectedReceiptModalProducts/>}

      {modalOpened && <PageModal />}
    </div>
  );
};

export default StoreInventoryPage;
