import React, { useContext } from 'react';
import PageTable from './PageTable';
import './cashOut.scss';
import { useHistory } from 'react-router-dom';
import Links from '../../../components/app/Links';
import SharedLayoutBtns from '../../../components/app/pos-section/SharedLayoutBtns';
import NotificationsProfile from '../../../components/app/pos-section/notifications-profile/NotificationsProfile';
import PageModal from './PageModal';
import { useState } from 'react';
import PosCashPaymentsContext from '../../../contexts/pos-cash-payments/PosCashPaymentsContext';
import { useEffect } from 'react';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import getAllDepartments from '../../../apis/payment-departments/getAllDepartments';
import PrintPageModal from './PrintPageModal';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

const CashOutProducts = () => {
  const {
    modalOpened,
    setModalOpened,
    setSelectedCash,
    printModalCash,
    modalOpenedCashIn
  } = useContext(PosCashPaymentsContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const customApiRequest = useCustomApiRequest();
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getAllDepartments(user?.token || user?.employee?.token, {}, true),
        (res) => {
          if (res?.status === 200 && res?.data?.data?.length >= 0) {
            const data = res?.data?.data;
            setDepartments(data);
          }
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="CashOut-page">
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">{t('Inventory.CashOut.title')}</h2>
          <p>{t('Inventory.CashOut.subTitle')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('Inventory.CashOut.title')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.storePage.title')}
            title3={t('Inventory.CashOut.title')}
            link3={'/store/payment-voucher'}
          />
        </div>
        <div className="pos-navbar">
          {/* <SharedLayoutBtns /> */}
          <SearchNotificationsProfile noSearch />
        </div>
      </div>

      <div className="add-section">
        {/* <div className="section-text">
          <p className="bold-font">وحدات القياس</p>
        </div> */}
        <div
          className="add-account-btn"
          onClick={() => {
            // if selected unit then reset it
            // open the unit modal
            setSelectedCash(null);
            setModalOpened(true);
          }}
        >
          <span className="text-span">{t('Inventory.CashOut.addCashOut')}</span>
        </div>
      </div>

      {/* <div className="custom-filter-add-section1">
        <div className="add-section">
          <div
            className="add-btn"
            onClick={() => {
              setSelectedInvoice(null);
              setStep(0);
              resetPos();
              history.push(routerLinks.posPage);
            }}
          >
            <span className="text-span">إضافة  فاتورة بيع جديدة</span>
          </div>
        </div>
        <TableFilter />
      </div> */}
      <PageTable />
      {modalOpened && <PageModal departments={departments} />}
      {printModalCash && <PrintPageModal />}
      {/* {returnModalOpened && <ReturnModal />}

      {selectedSaleInvoiceModalOpned && <SelectedSaleInvoiceModal />}
      {laterInvoiceModalOpened && <LaterInvoiceModal />} */}
    </div>
  );
};

export default CashOutProducts;
