/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import { Select, DatePicker } from 'antd';
import UserContext from '../../contexts/user-context/UserProvider';
import SelectDropDown from '../../common/icons/SelectDropDown';
import useUsers from '../../custom-hooks/useUsers';
import usePos from '../../custom-hooks/usePos';
import getAllShiftsApi from '../../apis/shifts-apis/getAllShiftsApi';
import ShiftsContext from '../../contexts/shifts-context/ShiftsContext';
import './TableFilter.scss';
import CalendarIcon from '../../common/icons/CalendarIcon';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { useTranslation } from 'react-i18next';


const { RangePicker } = DatePicker;

const TableFilter = () => {
  const { Option } = Select;
  const { user } = useContext(UserContext);
  const allPosEmployees = useUsers(5);
  const {t} = useTranslation()
  const statusArr = [
    {
      title: t('Shift.active'),
      value: '1'
    },
    {
      title: t('Shift.Inactive'),
      value: '2'
    }
  ];
  const allPos = usePos();
  const {
    setAllFetchedShifts,
    setIsLoading,
    setTablePagination,
    filterValues,
    setFilterValues
  } = useContext(ShiftsContext);
  // const [filterValues, setFilterValues] = React.useState({
  //   status: null,
  //   from: null,
  //   to: null,
  //   pos_id: null,
  //   admin_id: null
  // });
  async function handleChange(filterType, value) {
    setFilterValues((prevState) => {
      if (filterType === 'status') {
        return {
          ...prevState,
          status: value
        };
      } else if (filterType === 'from') {
        return {
          ...prevState,
          from: value
        };
      } else if (filterType === 'to') {
        return {
          ...prevState,
          to: value
        };
      } else if (filterType === 'admin_id') {
        return {
          ...prevState,
          admin_id: value
        };
      } else if (filterType === 'pos_id') {
        return {
          ...prevState,
          pos_id: value
        };
      }
    });
  }

  const [selectedPicker, setSelectedPicker] = React.useState(null);
  const handlePickerChange = (value, string) => {
    setSelectedPicker({
      moments: value,
      strings: string
    });
  };

  React.useEffect(() => {
    if (selectedPicker?.strings?.length > 0) {
      setFilterValues((prev) => ({
        ...prev,
        from: selectedPicker.strings[0],
        to: selectedPicker.strings[1]
      }));
    }
  }, [selectedPicker?.strings]);

  const customApiRequest = useCustomApiRequest();
  async function handleSubmit(e) {
    e.preventDefault();
    customApiRequest(
      getAllShiftsApi(user?.token, filterValues),
      (res) => {
        setIsLoading(false);
        if (res?.status === 200 && res?.data?.data?.data?.length >= 0) {
          const data = res.data.data.data;
          setAllFetchedShifts(data);
        } else {
        }
        if (res.data.data.pagination) {
          setTablePagination(res.data.data.pagination);
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }

  return (
    <div
      className="custom-table-fitler"
      style={{ marginTop: 42, marginBottom: 22 }}
    >
      <div className="form-excel-export-wrap">
        <form className="filter-form" onSubmit={handleSubmit}>
          <div className="range-picker-wrap">
            <RangePicker
              value={selectedPicker?.moments && selectedPicker.moments}
              onChange={handlePickerChange}
              // format="YYYY-MM-DD"
              className={`filter-range-picker`}
              allowClear={true}
              suffixIcon={<CalendarIcon />}
              placeholder={[t('Shift.dateFrom'), t('Shift.dateTo')]}
            />
          </div>

          <label className="entries-wrap">
            {/* <span className="select-title">حالة الشيفت</span> */}
            <Select
              defaultValue={null}
              style={{ width: 152 }}
              onChange={(v) => handleChange('status', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>{t('Shift.allCases')}</Option>
              {statusArr.map((obj, index) => (
                <Option key={index} value={obj.value}>
                  {obj.title}
                </Option>
              ))}
            </Select>
          </label>
          <label className="entries-wrap">
            {/* <span className="select-title">إختار الموظف</span> */}
            <Select
              defaultValue={null}
              style={{ width: 152 }}
              onChange={(v) => handleChange('admin_id', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>{t('Shift.allEmployees')}</Option>
              {allPosEmployees?.length > 0 &&
                allPosEmployees.map((obj, index) => (
                  <Option key={index} value={obj.id}>
                    {obj.name}
                  </Option>
                ))}
            </Select>
          </label>
          <label className="entries-wrap">
            {/* <span className="select-title">اختار نقطة البيع</span> */}
            <Select
              defaultValue={null}
              style={{ width: 152 }}
              onChange={(v) => handleChange('pos_id', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>{t('Shift.allSalePoints')}</Option>
              {allPos?.length > 0 &&
                allPos.map((obj, index) => (
                  <Option key={index} value={obj.id}>
                    {obj.name}
                  </Option>
                ))}
            </Select>
          </label>

          <button className="filter-submit-btn" type="submit">
          {t('Shift.search')}
          </button>
        </form>
      </div>
    </div>
  );
};
export default TableFilter;
