/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import getAllPosApi from '../apis/settings-apis/pos-apis/getAllPosApi';
import UserContext from '../contexts/user-context/UserProvider';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';
const usePos = () => {
  const [allPos, setAllPos] = useState(null);
  const { user } = useContext(UserContext);

  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getAllPosApi(user?.token),

        (res) => {
          if (checkRes(res) && res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllPos(data);
          }
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return allPos;
};

export default usePos;
