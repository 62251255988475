import { Modal } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import TableFormForProducts from './TableFormForProducts';

const PageModalForProducts = () => {
  const { modalOpened, setModalOpened, setSelectedReceipt, selectedReceipt } =
    useContext(OutgoingPurchaseInvoiceContext);
  // const { allProducts } = useProducts();
  const { t } = useTranslation();

  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        selectedReceipt
          ? t('Invoices.InvoiceReturnRequest')
          : t('Invoices.AddNewSalesInvoice')
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedReceipt(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedReceipt(null);
      }}
      footer={false}
    >
      <TableFormForProducts />
    </Modal>
  );
};

export default PageModalForProducts;
