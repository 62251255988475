import React, { useContext, useRef } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import TableForm from './TableForm';
import PosCashPaymentsContext from '../../../contexts/pos-cash-payments/PosCashPaymentsContext';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import { useReactToPrint } from 'react-to-print';
import { PrinterOutlined } from '@ant-design/icons';
import ReceiptDetailsComponentV2 from './ReceiptDetailsComponentV2';

const PrintPageModal = () => {
  const {
    selectedCash,
    setSelectedCash,
    printModalCash, 
    setPrintModalCash
  } = useContext(PosCashPaymentsContext);
  
  const a4RefV2 = useRef();
  const handlePrintA4V2 = useReactToPrint({
    content: () => a4RefV2.current,
    pageStyle: `
      @media print {
        html, body { height: initial !important; overflow: initial !important; }
        //tr { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
        tr { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
        //td { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
        td { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
        tbody { display: table-row-group !important; }
        thead {
          display: table-row-group !important;
        }
        tfoot {
          display: table-row-group !important;
        }
      @page {
        size: auto;
        margin: none;
      }
      .qr-code-wrap canvas {
        width: 128px !important;
        height: 128px !important;
      }
      .pagebreak {
        display: block;
        size: auto;
      }
      .invoice-details-wrap {
        padding: 0;
      }
      .ant-descriptions-item-content,
        .ant-descriptions-item-label {
          padding: 4px 12px !important;
        }
      }
      .ant-descriptions-header {
        margin: 4px 0 !important;
      }
      .models-title {
        direction: rtl;
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .ant-table-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
      }
      th {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      .invoice-extra-prices-table .ant-table-cell {
        padding: 4px 12px !important;
        text-align: right;
      }
    `
  });
  const renderInvoiceHeading = () => {
    return (
      <span style={{ paddingLeft: '20px' }}>طباعة افاتورة</span>
    )
  };
  const renderModalBody = () => {
    return (
      <ReceiptDetailsComponentV2
        ref={a4RefV2}
        selectedReceipt={selectedCash}
      />
    );
  };

  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        <>
          {/* <Tooltip title="طباعة الفاتورة"> */}
          {renderInvoiceHeading()}
          <Tooltip title="طباعة الفاتورة">
            <Button
              onClick={handlePrintA4V2}
              className="circled-btn"
              type="dashed"
              shape="circle"
              icon={<PrinterOutlined />}
            />
          </Tooltip>
        </>
      }
      visible={printModalCash}
      onOk={() => {
        setPrintModalCash(false);
        setSelectedCash(null);
      }}
      onCancel={() => {
        setPrintModalCash(false);
        setSelectedCash(null);
      }}
      footer={false}
    >
      {renderModalBody()}
    </Modal>
  );
};

export default PrintPageModal;
