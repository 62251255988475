import axios from 'axios';
import moment from 'moment';

const getBalanceSheetDataApi = async (token, dateValues) => {
  // let url = `accountingTree/accounts/${id}/balance-sheet?from_date=${dateValues.from}&to_date=${dateValues.to}`
  let url = `accounting/report/ledger_list/${dateValues?.account_id}?from_date=${moment(dateValues.from).format("YYYY-MM-DD")}&to_date=${moment(dateValues.to).format("YYYY-MM-DD")}`
  try {
    const res = await axios({
      method: 'get',
      url: url,
      baseURL: process.env.REACT_APP_BASE_TEST_URL,
      // baseURL: "https://testclient-api.fatoorah.sa/apiAdmin",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
      
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getBalanceSheetDataApi;
