import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import FileSaver from 'file-saver';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import deleteProductApi from '../../../apis/store-apis/products/deleteProductApi';

const saveFile = (url, title) => {
  FileSaver.saveAs(url, title);
};

const tableColumns = (
  allFetchedProducts,
  setModalOpened,
  setFetchCount,
  setSelectedProduct,
  setSelectedProductId,
  setIsLoading,
  setSelectedProductModalOpened,
  setBarCodeModalOpned,
  token,
  t
) => {
  const handleEditProduct = (record) => {
    const found = allFetchedProducts.find((place) => place.id === record.id);
    if (found) {
      setSelectedProduct({ ...found });
      setModalOpened(true);
    }
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteProductApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('services.successNotification'),
          message: t('services.deletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('services.errorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('services.pleaseTryLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('services.errorOccurred'),
        message: t('services.tryLater')
      });
    }
  };
  const hanldeSelectProduct = (record) => {
    setSelectedProductModalOpened(true);
    setSelectedProductId(record.id);
    // const found = allFetchedProducts.find((obj) => obj.id === record.id);
    // if (found) setSelectedProduct({ ...found });
  };
  // const handlePrintClick = (record) => {
  //   setSelectedProduct(record);
  //   setBarCodeModalOpned(true);
  // };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditProduct(record)}>
          <EditIcon />
          {t('services.update')}
        </div>
        <Popconfirm
          title={t('services.deleteServiceConfirm')}
          onConfirm={() => handleDeleteRequest(record.id)}
          okText={t('services.yes')}
          cancelText={t('services.no')}
        >
          <Tooltip title={t('services.deleteService')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      title: t('services.serviceCode'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('services.serviceName'),
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-title desc-wrap">
            <span className="name-it-self">{record.name}</span>
          </div>
        );
      },
      sorter: (a, b) => {
        let nameA = a.name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      }
    },

    {
      title: t('services.sellingPrice'),
      dataIndex: 'salePrice',
      // width: 192,
      render: (_, record) => {
        return <div className="row-cell row-text">{parseFloat(record?.salePrice).toFixed(2)}</div>;
      },
      sorter: (a, b) => a.salePrice - b.salePrice
    },

    {
      title: t('services.serviceImage'),
      dataIndex: 'image',
      // width: 180,
      render: (_, record) => {
        return (
          <div className="row-cell row-img">
            {record.image ? (
              <img
                onClick={() => saveFile(record.image, record.name)}
                src={record.image}
                alt={record.name ? record.name : 'product'}
              />
            ) : (
              '-----'
            )}
          </div>
        );
      }
    },
    // {
    //   title: 'طباعة الباركود',
    //   render: (_, record) => {
    //     return (
    //       <div
    //         className="row-cell row-circled-btn"
    //         onClick={() => {
    //           handlePrintClick(record);
    //         }}
    //       >
    //         <Tooltip title="طباعة الباركود">
    //           <Button
    //             className="circled-btn"
    //             type="dashed"
    //             shape="circle"
    //             icon={<PrinterOutlined />}
    //           />
    //         </Tooltip>
    //       </div>
    //     );
    //   }
    // },
    {
      title: t('services.servicePreview'),
      render: (_, record) => {
        return (
          <div
            className="row-cell row-circled-btn"
            onClick={() => {
              hanldeSelectProduct(record);
            }}
          >
            <Tooltip title={t('services.servicePreview')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
              />
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: t('services.settings'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
