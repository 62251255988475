import React, { useContext } from 'react';
import UserContext from '../contexts/user-context/UserProvider';
import { useHistory } from 'react-router-dom';
import routerLinks from '../components/app/routerLinks';
import useCustomApiRequest from './useCustomApiRequest';
import errorNotification from '../utils/errorNotification';
import { signinApi } from '../apis/auth/signin';
import { useTranslation } from 'react-i18next';
import userRoles from '../userRoles';
import { store } from 'react-notifications-component';

const useSigninEmailPassword = () => {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const { setCurrentUser, setUserToState, setIsNewLoggedIn, phone, setPhone , msg , setMsg} =
    useContext(UserContext);
  const customApiRequest = useCustomApiRequest();
  const [isLoadingSignin, setIsLoadingSignin] = React.useState(false);
  const signMeInWithEmailPassword = (data) => {
    setIsLoadingSignin(true);
    const formData = new FormData();
    formData.append('phone', data?.phone);
    formData.append('fire_base', data?.fire_base);
    // formData.append('password', data?.password);

    customApiRequest(
      signinApi(data, i18n.language),
      (res) => {
        setIsLoadingSignin(false);
        if (res?.data?.status === false || res?.data?.status == 0) {
          errorNotification({
            title: t('LoginPage.loginError'),
            message: res?.data?.message || t('LoginPage.dataIncorrect')
          });
        } else if (
          (res?.data?.status === true || res?.data?.status === 1) &&
          res?.data?.data
        ) {
          setPhone(res?.data?.data.phone);
          if (data?.remember) {
            setIsNewLoggedIn(true);
            // setCurrentUser({ ...res?.data?.data });
            // store.addNotification({
            //   title: res?.data?.message,
            //   message: res?.data?.message,
            //   type: 'success',
            //   insert: 'top',
            //   container: 'top-right',
            //   animationIn: ['animate__animated', 'animate__fadeIn'],
            //   animationOut: ['animate__animated', 'animate__fadeOut'],
            //   dismiss: {
            //     duration: 3000,
            //     showIcon: true,
            //     onScreen: true
            //   }
            // });
            setMsg(res?.data?.message);
          } else {
            setUserToState({ ...res?.data?.data });
            history.push(routerLinks?.homePage);
          }
        } else {
          errorNotification({
            title: t('LoginPage.loginError'),
            message: res?.data?.message || t('LoginPage.dataIncorrect')
            // message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
          });
        }
      },

      (error) => {
        setIsLoadingSignin(false);
        errorNotification({
          title: t('LoginPage.loginError'),
          message: t('LoginPage.tryLaterMessage')
        });
      }
    );
  };

  return { signMeInWithEmailPassword, isLoadingSignin, setIsLoadingSignin };
};

export default useSigninEmailPassword;
