/* eslint-disable react-hooks/exhaustive-deps */
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Spin, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Treebeard } from 'react-treebeard';
import decorators from 'react-treebeard/dist/components/Decorators';
import deleteAccountingApi from '../../../apis/accounting-apis/deleteAccountingApi';
import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import AccountingContext from '../../../contexts/accounting-context/AccountingContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
// import defaultTheme from 'react-treebeard/lib/themes/default';
import { useTranslation } from 'react-i18next';
import createAccountingTreeApi from '../../../apis/accounting-apis/createAccountingTreeApi';
import registerComplexTreeApi from '../../../apis/accounting-apis/registerComplexTreeApi';
import registerEmptyTreeApi from '../../../apis/accounting-apis/registerEmptyTreeApi';
import registerSimpleTreeApi from '../../../apis/accounting-apis/registerSimpleTreeApi';
import './AccountingPage.scss';
const treeStyle = {
  tree: {
    base: {
      backgroundColor: 'transparent',
      fontFamily: 'inherit',
      margin: 0,
      padding: 0,
      borderWidth: '1px 0 0 1px',
      borderStyle: 'solid',
      borderColor: 'rgba(0, 0, 0, .1)',
      color: 'rgb(35,31,32)',
      fontSize: '1.3rem'
    },
    node: {
      base: {
        position: 'relative'
        // borderRight: '1px solid rgba(0, 0, 0, .1)'
      },
      link: {
        cursor: 'pointer',
        position: 'relative',

        // padding: '20px 16px',
        padding: '10px 16px',
        // display: 'block',
        display: 'flex',
        // borderRadius: '8px 0 0 8px',
        borderBottom: '1px solid rgba(0, 0, 0, .1)',
        borderRight: '1px solid rgba(0, 0, 0, .1)',
        // backgroundColor: 'transparent',
        backgroundColor: '#f5f5f5'

        // marginBottom: '12px'
      },
      activeLink: {
        // background: 'rgba(0, 0, 0, 0.04)'
        // backgroundColor: '#fff'
      },
      toggle: {
        height: 14,
        width: 14,
        base: {
          position: 'relative',
          display: 'inline-block',
          verticalAlign: 'top'
          // marginLeft: '-5px',
          // height: '24px',
          // width: '24px'
        },
        wrapper: {
          // position: 'absolute',
          // top: '50%',
          // left: '50%',
          // transform: 'translate(-50%, -50%)'
          // height: '14px'
        },

        arrow: {
          // fill: 'rgb(35,31,32)',
          fill: '#2e4652',
          strokeWidth: 0
        }
      },
      header: {
        borderBottom: '1px solid rgba(0, 0, 0, .1)',
        base: {
          display: 'inline-block',
          // verticalAlign: 'top',
          color: '#2e4652'
        },
        connector: {
          width: '2px',
          height: '12px',
          borderRight: 'solid 2px black',
          borderBottom: 'solid 2px black',
          position: 'absolute',
          top: '0px',
          right: '-21px',
          left: 'auto'
        },
        title: {
          lineHeight: '28px',
          verticalAlign: 'middle',
          paddingRight: '12px'
        }
      },
      subtree: {
        listStyle: 'none',
        paddingRight: '32px'
      },
      loading: {
        color: '#E2C089'
      }
    }
  }
};

const AccountingTree = () => {
  const { user } = useContext(UserContext);
  // console.log(user)
  const {
    isLoading,
    setIsLoading,
    setFetchCount,
    fetchCount,
    setAccountingModalOpened,
    setAccountInfoModalOpened,
    setSelectedAccountNode,
    setSelectedAccountId,
    accountingTree,
    setAccountingTree,
    mappedAccountingTree,
    setMappedAccountingTree,
    setEditMode,
    setIsBaseNode,
    setFoundAccount,
    foundAccount
  } = useContext(AccountingContext);
  const [newTreeAllData, setNewTreeAllData] = useState();
  const [newTreeData, setNewTreeData] = useState();
  const [accountingTreeData, setAccountingTreeData] = useState();
  const [connectionError, setConnectionError] = useState(false);
  const { t } = useTranslation();

  const customApiRequest = useCustomApiRequest();

  const handleAddLeaf = async (node, isBaseLeaf, e) => {
    setEditMode(false);
    setSelectedAccountNode(node);
    setAccountingModalOpened(true);

    if (isBaseLeaf) {
      setIsBaseNode(true);
    } else {
      setIsBaseNode(false);
    }
    e.stopPropagation();
  };
  const handleEditLeaf = (node, e) => {
    setEditMode(true);
    setSelectedAccountNode(node);
    setAccountingModalOpened(true);
    e.stopPropagation();
  };
  const handleDeleteLeaf = async (node) => {
    try {
      setIsLoading(true);
      const res = await deleteAccountingApi(
        user?.token,
        (node?.subtype_id && node?.subtype_id?.split('-').at(-1)) ||
          (node?.id && node?.id?.split('-').at(-1)),
        node?.leaf_type
      ); // Here delete
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Messages.addedSuccessfully'),
          message: t('Messages.DeletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Messages.ErrorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Messages.TryAgainLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      // setApiLoading(false);
      errorNotification({
        title: t('Messages.ErrorOccurred'),
        message: t('Messages.TryAgainLater')
      });
    }
  };

  const handleAccountInfo = (id, e) => {
    if (typeof id === 'number') {
      setAccountInfoModalOpened(true);
      setSelectedAccountId(id);
    } else if (id?.split('').includes('-')) {
      setAccountInfoModalOpened(true);
      setSelectedAccountId(id?.split('-').at(-1));
    }
  };

  const [cursor, setCursor] = React.useState(false);
  const onToggle = (node, toggled) => {
    if (cursor) {
      setCursor((prev) => ({ ...prev, active: false }));
    }
    node.active = true;
    if (node.children) {
      node.toggled = toggled;
    }
    setCursor(node);
    setMappedAccountingTree({ ...mappedAccountingTree });
  };

  const CustomHeader = (props) => {
    return (
      <div style={props.style.base} className="node-name-action-btns">
        <div className="name-btns-wrap">
          <div style={{ ...props.style.title }}>{` ${props.node.name}`}</div>

          <div className="action-btns">
            {/* Add Btn */}
            {props.node.leaf_type === 'transaction' ? (
              ''
            ) : (
              <div
                className="add-btn"
                onClick={(e) => {
                  handleAddLeaf(props.node, false, e);
                }}
              >
                <PlusOutlined />
                <div className="btn-text">{t('Accounts.AddAccount')}</div>
              </div>
            )}
            {!props.node.fakeLeaf && (
              <>
                {/* Edit Btn */}
                {props.node.leaf_type === 'transaction' ? (
                  ''
                ) : (
                  <div
                    className="edit-btn"
                    onClick={(e) => handleEditLeaf(props.node, e)}
                  >
                    <EditIcon />
                    <span className="btn-text">{t('Accounts.Edit')}</span>
                  </div>
                )}

                {/* Delete Btn */}
                {props?.node?.children ||
                props?.node?.timesUsed ||
                props.node.leaf_type === 'transaction' ? (
                  <Tooltip
                    title={t('Accounts.NotAllowedToDelete')}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div
                      style={{ cursor: 'not-allowed' }}
                      className="delete-btn"
                    >
                      <DeleteIcon />
                    </div>
                  </Tooltip>
                ) : (
                  <Popconfirm
                    // title="هل أنت متأكد من الحذف"
                    title={t('Messages.areyouSureToDelete')}
                    onConfirm={(e) => {
                      e.stopPropagation();
                      handleDeleteLeaf(props.node);
                    }}
                    onCancel={(e) => {
                      e.stopPropagation();
                    }}
                    okText={t('Messages.yes')}
                    cancelText={t('Messages.no')}
                  >
                    <Tooltip
                      title={t('Messages.delete')}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className="delete-btn">
                        <DeleteIcon />
                      </div>
                    </Tooltip>
                  </Popconfirm>
                )}

                {/* View Btn */}
                {props.node.leaf_type === 'transaction' ? (
                  ''
                ) : (
                  <div className="circled-btn-wrap">
                    <Tooltip title={t('Accounts.viewTheAccountsBranch')}>
                      <Button
                        onClick={(e) => {
                          handleAccountInfo(
                            props?.node?.id || props?.node?.subtype_id,
                            e
                          );
                        }}
                        className="circled-btn"
                        type="dashed"
                        shape="circle"
                        icon={<EyeOutlined />}
                      />
                    </Tooltip>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleRegisterComplexNewTree = async () => {
    let res = await registerComplexTreeApi(
      +user.ref_key.split('_')[1],
      user?.token
    );
          setIsLoading(true);

    if (
      (res?.status === 203 || res?.status === 200)&&
      res?.data?.data &&
      res?.data?.data !== 'not found'
    ) {
      setFetchCount((prev) => prev + 1);
      setNewTreeAllData(res?.data?.data);
      setFoundAccount(true);
      const data = res.data.data;
      setAccountingTree(data);
      setAccountingTreeData(data);
      convertDataToTree(data);
      setConnectionError(false);
       successNotification({
         title: t('Accounts.Expandedtreecreatedsuccessfully'),
         message: 'successMessage'
       });
      setIsLoading(false);
    } else {
      setIsLoading(false);
         errorNotification({
           title: t('Messages.ErrorOccurred'),
           message: res?.data?.message || t('Messages.TryAgainLater')
         });
    }
  };
  const handleRegisterSimpleNewTree = async () => {
    let res = await registerSimpleTreeApi(
      +user.ref_key.split('_')[1],
      user?.token
    );
    setIsLoading(true);

    if (
      (res?.status === 203 || res?.status === 200) &&
      res?.data?.data &&
      res?.data?.data !== 'not found'
    ) {
      setFetchCount((prev) => prev + 1);
      setNewTreeAllData(res?.data?.data);
      setFoundAccount(true);
      const data = res.data.data;
      setAccountingTree(data);
      setAccountingTreeData(data);
      convertDataToTree(data);
      setConnectionError(false);
       successNotification({
         title: t('Accounts.Simplifiedtreecreatedsuccessfully'),
         message: 'successMessage'
       });
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  const handleRegisterEmptyNewTree = async () => {
    let res = await registerEmptyTreeApi(
      +user.ref_key.split('_')[1],
      user?.token
    );
    setIsLoading(true)
    if (
      (res?.status === 203 || res?.status === 200) &&
      res?.data?.data &&
      res?.data?.data !== 'not found'
    ) {
      setFetchCount((prev) => prev + 1);
      setNewTreeAllData(res?.data?.data);
      setFoundAccount(true);
      const data = res.data.data;
      setAccountingTree(data);
      setAccountingTreeData(data);
      convertDataToTree(data);
      setConnectionError(false);
      successNotification({
        title: t('Accounts.Emptytreecreatedsuccessfully'),
        message: 'successMessage'
      });
      setIsLoading(false);

    } else {
      errorNotification({
        title: t('Messages.ErrorOccurred'),
        message: res?.data?.message || t('Messages.TryAgainLater')
      });
      setIsLoading(false);
    }
  };

  const fetchDataTree = async () => {
    let isMounted = true;
    setIsLoading(true);
      // console.log('createAccountingTreeApi');
    if (isMounted) {
      customApiRequest(
        createAccountingTreeApi(user?.token, user?.client_id),
        (res) => {
          if (
            res?.status === 200 &&
            res?.data?.data &&
            res?.data?.data !== 'not found'
          ) {
            // setFetchCount((prev) => prev + 1)
            setNewTreeAllData(res?.data?.data);
            setFoundAccount(true);
            const data = res?.data?.data;
            setAccountingTree(data);
            setAccountingTreeData(data);
            convertDataToTree(data);
            setConnectionError(false);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
      // try {
      //   const res = await axios({
      //     method: 'get',
      //     baseURL: process.env.REACT_APP_BASE_TEST_URL,
      //     // baseURL: 'https://testclient-api.fatoorah.sa/apiAdmin',
      //     url: `/accounting/account?client_id=${user?.client_id}`,
      //     headers: {
      //       Accept: 'application/json',
      //       'Content-Type': 'applications/json',
      //       Authorization: 'Bearer ' + user?.token,
      //       lang: 'ar'
      //     }
      //   });
      //   setIsLoading(false);
      //   if (
      //     res?.status === 200 &&
      //     res?.data?.data &&
      //     res?.data?.data !== 'not found'
      //   ) {
      //     // setFetchCount((prev) => prev + 1)
      //     setNewTreeAllData(res?.data?.data);
      //     setFoundAccount(true);
      //     const data = res?.data?.data;
      //     setAccountingTree(data);
      //     setAccountingTreeData(data);
      //     convertDataToTree(data);
      //     setConnectionError(false);
      //     setIsLoading(false);
      //   } else {
      //     setIsLoading(false);
      //
      //   }
      //   return res;
      // } catch (error) {
      //   throw error;
      // }
    }
    return () => (isMounted = false);
  };

  useEffect(() => {
    fetchDataTree();
  }, [fetchCount]);

  // انشاء شجره الحسابات
  const CustomHeader2 = (props) => {
    return (
      <div style={props.style.base} className="node-name-action-btns">
        <div className="name-btns-wrap">
          <div style={{ ...props.style.title }}>{` ${props.node.name}`}</div>
          {props.node.fakeLeaf && (
            <div className="acc-tree-btn">
              <div className="action-btns">
                <div
                  title={t('Accounts.Createanemptytree')}
                  className="add-btn"
                  onClick={(e) => handleRegisterEmptyNewTree()}
                >
                  <PlusOutlined />
                  <div className="btn-text">{t('Accounts.Emptytree')}</div>
                </div>
              </div>
              <div className="action-btns">
                <div
                  title={t('Accounts.Createasimpletree')}
                  className="add-btn"
                  onClick={(e) => handleRegisterSimpleNewTree()}
                >
                  <PlusOutlined />
                  <div className="btn-text">{t('Accounts.Simpletree')}</div>
                </div>
              </div>
              <div className="action-btns">
                <div
                  title={t('Accounts.Createanexpandedtree')}
                  className="add-btn"
                  onClick={(e) => handleRegisterComplexNewTree()}
                >
                  <PlusOutlined />
                  <div className="btn-text">{t('Accounts.Expandedtree')}</div>
                </div>
              </div>
            </div>
            // <Dropdown menu={menuProps}>
            //   <Button>
            //     <Space>
            //       Button
            //       <DownOutlined />
            //     </Space>
            //   </Button>
            // </Dropdown>
          )}
        </div>
      </div>
    );
  };

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const onExpand = (newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const recursiveSubChild = (
    data,
    item,
    subtype_item,
    subchild_item,
    parentId
  ) => {
    const currentId = parentId
      ? `${parentId}-${subchild_item.id}`
      : `${item.id}-${subtype_item.id}-${subchild_item.id}`;
    if (
      subchild_item?.subs?.length > 0 ||
      subchild_item?.accounts?.length > 0
    ) {
      return {
        name: `${subchild_item.acn} - ${subchild_item.name}`,
        id: currentId,
        leaf_type: 'subchild',
        parent_id: String(subchild_item?.parent_id),
        type: subchild_item.type,
        acn: subchild_item.acn,
        timesUsed: subchild_item.timesUsed,
        children: [
          //   ...subchild_item.subs.map((nest) => {
          //     if (nest?.subs) {
          //       return recursiveSubChild(data, item, subtype_item, nest, currentId);
          //     } else {
          //       return {
          //         name: nest.name,
          //         id: `${currentId}-${nest.id}`,
          //         leaf_type: "subchild"
          //       };
          //     }
          // }),
          ...processAccounts(
            data,
            item,
            subtype_item,
            subchild_item.subs,
            currentId,
            subchild_item.type
          )
        ]
      };
    } else {
      return {
        name: `${subchild_item.acn} - ${subchild_item.name}`,
        id: currentId,
        leaf_type: 'subchild',
        parent_id: String(subchild_item?.parent_id),
        type: subchild_item.type,
        timesUsed: subchild_item.timesUsed,
        acn: subchild_item.acn,
        children: processAccounts(data, item, subtype_item)
      };
    }
  };

  function processAccounts(data, item, subtype_item, accounts, parentId, type) {
    return accounts?.map((account) => {
      const currentId = parentId
        ? `${parentId}-${account.id}`
        : `${item.id}-${subtype_item.id}-${account.id}`;
      // const currentId = subtype_item.id;
      if (account?.subs?.length > 0) {
        return {
          name: `${account.acn} - ${account.name}`,
          id: currentId,
          leaf_type: 'account',
          analytical: account.analytical,
          holder: account.name,
          initial_balance: account.initial_balance,
          type: account.type,
          company_id: account.company_id,
          client_id: account.client_id,
          type_id: account.type_id,
          parent_type: 'subtype',
          parent_id: String(account?.parent_id),
          timesUsed: account.timesUsed,
          acn: account.acn,
          children: account?.subs?.map((acc_sub) => {
            return {
              name: `${acc_sub.acn} - ${acc_sub.name}`,
              id: `${currentId}-${acc_sub?.id}`,
              leaf_type: 'account',
              parent_id: acc_sub?.parent_id,
              type: acc_sub.type,
              acn: acc_sub.acn
            };
          })
        };
      } else {
        return {
          name: `${account.acn} - ${account.name}`,
          subtype_id: currentId,
          analytical: account.analytical,
          holder: account.name,
          initial_balance: account.initial_balance,
          type: account.type,
          company_id: account.company_id,
          client_id: account.client_id,
          type_id: account.type_id,
          parent_type: 'subtype',
          leaf_type: 'account',
          parent_id: String(account?.parent_id),
          acn: account.acn,
          timesUsed: account.timesUsed
        };
      }
    });
  }

  const subChild = (item3) => {
    if (item3.subtypes_childs.length === 0) {
      return {
        name: `${item3.acn} - ${item3.name}`,
        type: 'subchild',
        parent_id: String(item3?.parent_id),
        timesUsed: item3.timesUsed,
        acn: item3.acn
      };
    }
    return {
      name: `${item3.acn} - ${item3.name}`,
      type: 'subchild',
      parent_id: String(item3?.parent_id),
      timesUsed: item3.timesUsed,
      acn: item3.acn,
      children: item3.subtypes_childs.map((item3) => subChild(item3))
    };
  };

  // const subAcc = (item3) => {
  //   if (item3.subtypes_childs.length === 0) {
  //     return {
  //       name: item3.name,
  //       type: 'subchild'
  //     };
  //   }
  //   return {
  //     name: item3.name,
  //     type: 'subchild',
  //     children: item3.subtypes_childs.map((item3) => subChild(item3))
  //   };
  // };
  const convertDataToTree = (data) => {
    let newData = [
      {
        name: t('Accounts.AccountTree'),
        id: 'fake-leaf-id',
        fakeLeaf: true,
        leaf_type: 'type',
        children:
          data &&
          data.length > 0 &&
          data !== 'not found' &&
          data?.map((item, index) => {
            if (item.subs.length === 0) {
              return {
                name: `${item?.acn} - ${item.name}`,
                id: data.id + '-' + item.id,
                leaf_type: 'subtype',
                parent_id: item?.parent_id || null,
                timesUsed: item.timesUsed,
                acn: item.acn,
                type: item.type
              };
            } else {
              return {
                name: `${item?.acn} - ${item.name}`,
                id: item.id,
                leaf_type: 'type',
                parent_id: item?.parent_id || null,
                type: item.type,
                timesUsed: item.timesUsed,
                acn: item.acn,
                children: item.subs.map((subtype_item, index) => {
                  if (subtype_item?.subs?.length > 0) {
                    return {
                      index: index + 1,
                      name: `${subtype_item.acn} - ${subtype_item.name}`,
                      id: item.id + '-' + subtype_item?.id,
                      parent_id: String(subtype_item?.parent_id) || null,
                      leaf_type: 'subtype',
                      type: subtype_item.type,
                      acn: subtype_item.acn,
                      timesUsed: subtype_item.timesUsed,
                      // children: [
                      //   subtype_item?.subs?.map((subchild_item) => {
                      //       if (subchild_item?.subs?.length > 0) {
                      //           return {
                      //             name: subchild_item.name,
                      //             id: data.id + "-" + item.id + "-" + subtype_item?.id + "-" + subchild_item?.id,
                      //           }
                      //       }
                      //   })
                      // ]
                      // children: subtype_item?.subs?.map((subchild_item) => {
                      //     if (subchild_item?.subs?.length > 0) {
                      //         return {
                      //           name: subchild_item.name,
                      //           id: item.id + "-" + subtype_item?.id + "-" + subchild_item?.id,
                      //         }
                      //     }
                      // })
                      children: [
                        ...subtype_item?.subs
                          ?.map((subchild_item) => {
                            return recursiveSubChild(
                              data,
                              item,
                              subtype_item,
                              subchild_item
                            );
                          })
                          .filter(Boolean)
                        // ...processAccounts(data, item, subtype_item, subtype_item?.subs)
                      ]
                    };
                  } else {
                    return {
                      index: index + 1,
                      name: `${subtype_item.acn} - ${subtype_item.name}`,
                      id: item.id + '-' + subtype_item.id,
                      leaf_type: 'subchild',
                      parent_id: String(subtype_item?.parent_id),
                      type: subtype_item.type,
                      acn: subtype_item.acn,
                      timesUsed: subtype_item.timesUsed
                    };
                  }
                })
              };
            }
          })
        // children: [
        //   {
        //     name: data.name,
        //     id: data.id,
        //     leaf_type: "company",
        //     children: data?.types?.map((item) => {
        //         if (item.subtypes.length === 0) {
        //             return {
        //               name: item.name,
        //               id: data.id + "-" + item.id,
        //               leaf_type: "type",
        //             };
        //         } else {
        //             return {
        //                 name: item.name,
        //                 id: data.id + "-" + item.id,
        //                 leaf_type: "type",
        //                 children: item.subtypes.map((subtype_item , index) => {
        //                     if (subtype_item.subtypes_childs.length > 0 || subtype_item.accounts.length > 0) {
        //                         return {
        //                             index : index + 1,
        //                             name: subtype_item.name,
        //                             id: data.id + "-" + item.id + "-" + subtype_item.id,
        //                             leaf_type: "subtype",
        //                             children: [
        //                                 ...subtype_item.subtypes_childs.map((subchild_item) => {
        //                                   return recursiveSubChild(data, item, subtype_item, subchild_item)
        //                                 }).filter(Boolean), ...processAccounts(data, item, subtype_item, subtype_item?.accounts)
        //                             ]
        //                         };
        //                     } else {
        //                         return {
        //                             index : index + 1,
        //                             name: subtype_item.name,
        //                             id: data.id + "-" + item.id + "-" + subtype_item.id,
        //                             leaf_type: "subtype",
        //                         };
        //                     }
        //                 }),
        //             };
        //         }
        //     }),
        //   },
        // ],
      }
    ];

    setNewTreeData(newData);
  };
  // useEffect(() => {
  //     let isMounted = true;
  //     setIsLoading(true);
  //     if (isMounted) {
  //         customApiRequest(
  //           getAccountingTreeApi(user?.token, user?.client_id),
  //           (res) => {
  //                 setIsLoading(false);
  //                 if (res?.status === 200 && res?.data?.data === "fail") {
  //                     setFoundAccount(false)
  //                     setConnectionError(false)
  //                 }
  //                 else if (res?.status === 203 && res?.data?.data) {
  //                     setFoundAccount(true)
  //                     const data = res.data.data;
  //                     setAccountingTree(data)
  //                     setAccountingTreeData(data);
  //                     convertDataToTree(data)
  //                     setConnectionError(false)
  //                 } else {
  //                     setIsLoading(false);
  //                     setConnectionError(false)
  //                 }
  //             },
  //             (error) => {
  //                 setConnectionError(true)
  //                 setIsLoading(false);
  //             }
  //         );

  //     }

  //     return () => {
  //       isMounted = false;
  //     };
  // }, [fetchCount]);

  let placeHolderData = [
    {
      name: t('Accounts.AccountTree'),
      id: 'fake-leaf-id',
      fakeLeaf: true
    }
  ];
  if (isLoading) {
    return (
      <div className="spin-tree-wrap">
        <Spin />
      </div>
    );
  } else if (accountingTree && !connectionError) {
    return (
      <div className="accounting-tree">
        {newTreeData ? (
          <Treebeard
            // data={accountingTree?.length > 0 ? accountingTree : []}
            data={newTreeData && newTreeData}
            onToggle={onToggle}
            style={treeStyle}
            decorators={{
              ...decorators,
              Header: foundAccount ? CustomHeader : ''
            }}
          />
        ) : (
          <>
            <Treebeard
              // data={accountingTree?.length > 0 ? accountingTree : []}
              data={placeHolderData}
              onToggle={onToggle}
              style={treeStyle}
              decorators={{ ...decorators, Header: CustomHeader2 }}
            />
          </>
        )}
      </div>
    );
  } else {
    <div className="accounting-tree">
      {newTreeData ? (
        <Treebeard
          // data={accountingTree?.length > 0 ? accountingTree : []}
          data={newTreeData && newTreeData}
          onToggle={onToggle}
          style={treeStyle}
          decorators={{
            ...decorators,
            Header: foundAccount ? CustomHeader : ''
          }}
        />
      ) : (
        <>
          <Treebeard
            // data={accountingTree?.length > 0 ? accountingTree : []}
            data={placeHolderData}
            onToggle={onToggle}
            style={treeStyle}
            decorators={{ ...decorators, Header: CustomHeader2 }}
          />
        </>
      )}
    </div>;
  }
  return null;
};

export default AccountingTree;
