import HomeIcon from '../../common/icons/HomeIcon';
import ShoppingIcon from '../../common/icons/ShoppingIcon';
// import TransferIcon from '../../common/icons/TransferIcon';
// import HrIcon from '../../common/icons/HrIcon';
import AnalysisIcon from '../../common/icons/AnalysisIcon';
// import routerLinks? from '../app/routerLinks?';
import StoreIcon from '../../common/icons/StoreIcon';
// import ProjectsIcons from '../../common/icons/ProjectsIcons';
// import CRMIcon from '../../common/icons/CRMIcon';
import PosIcon from '../../common/icons/PosIcon';
// import WarehouseIcon from '../../common/icons/WarehouseIcon';
import AccountingNewIcon from '../../common/icons/AccountingNewIcon';
import ArchiveIcon from '../../common/icons/ArchiveIcon';
import accountingRouterLinks from '../app/accounting-routes/accountingRouterLinks';
import Links from '../app/Links';
import Cookies from 'js-cookie';
import StockIcon from '../../common/icons/StockIcon copy';
import AltSettingsIcon from '../../common/icons/AltSettingsIcon copy';
import salesRouterLinks from '../app/sales-routes/salesRouterLinks';
// import partnerLinks from './partnerLinks';
import dataEntryLinks from './dataEntryLinks';
import delegateLinks from './delegateLinks';
import posLinks from './posLinks';
import SupportIcon from '../../common/icons/SupportIcon';
import QrIcon from '../../common/icons/QrIcon';
import partnerLinks from './partnerLinks';

const mainAppBarLinks = (t) => {
  let user =
    Cookies.get('currentUser') && JSON.parse(Cookies.get('currentUser'));
  const routerLinks = Links();
  let linksServices;
  if (user?.packageId == 4) {
    linksServices = [
      {
        id: 1,
        to: routerLinks?.homePage,
        name: t('AppBar.Services.homePage'),
        isLink: true,
        icon: (color) => (color ? <HomeIcon color={color} /> : <HomeIcon />),
        subMenu: [],
        visibleSubMenu: false
      },
      {
        id: 6,
        to: routerLinks?.storePage,
        name: t('AppBar.Services.storePage.title'),
        icon: (color) => (color ? <StoreIcon color={color} /> : <StoreIcon />),
        visibleSubMenu: false,
        subMenu: [
          {
            id: 1,
            to: routerLinks?.storeItemManagerPage,
            name: t('AppBar.Services.storePage.storeItemManagerPage')
          },
          {
            id: 2,
            to: routerLinks?.storeProductsIngredientsPage,
            name: t('AppBar.Services.storePage.storeProductsIngredientsPage')
          }
        ]
      },
      {
        id: 4112121221,
        to: '/sales',
        name: t('AppBar.Services.salesPage.title'),
        icon: (color) =>
          color ? <ShoppingIcon color={color} /> : <ShoppingIcon />,
        visibleSubMenu: false,
        subMenu: [
          {
            id: 1,
            to: routerLinks?.outgoingPurchaseInvoice,
            name: t('AppBar.Services.salesPage.outgoingPurchaseInvoice')
          },
          {
            id: 10,
            to: routerLinks?.outgoingPurchaseInvoiceReturned,
            name: t('Invoices.ReturnedSaleInvoice')
          },
          {
            id: 2,
            to: routerLinks?.priceOfferRouteNew,
            name: t('AppBar.Services.salesPage.priceOfferRouteNew')
          },
          {
            id: 4,
            to: routerLinks?.clientPage,
            name: t('AppBar.Services.salesPage.clientPage')
          },
          {
            id: 543,
            to: routerLinks?.customerPaymentsPage,
            name: t('AppBar.Services.salesPage.customerPaymentsPage')
          }
        ]
      },
      {
        id: 4118871267671,
        // to: routerLinks?.salesPage,
        to: routerLinks?.incomingPurchaseInvoice,
        // isLink: true,
        name: t('AppBar.Services.incomingPurchaseInvoice.title'),
        icon: (color) =>
          color ? <ShoppingIcon color={color} /> : <ShoppingIcon />,
        visibleSubMenu: false,
        subMenu: [
          {
            id: 7,
            to: routerLinks?.incomingPurchaseInvoice,
            name: t(
              'AppBar.Services.incomingPurchaseInvoice.incomingPurchaseInvoice'
            )
          },
          {
            id: 701,
            to: routerLinks?.incomingPurchaseInvoiceReturned,
            name: t('purchaseInvoices.ReturnPurchaseInvoices')
          },
          {
            id: 48918293,
            to: routerLinks?.suppliersPage,
            name: t('AppBar.Services.incomingPurchaseInvoice.suppliersPage')
          },
          {
            id: 48918293,
            to: routerLinks?.suppliersPaymentsPage,
            name: t(
              'AppBar.Services.incomingPurchaseInvoice.suppliersPaymentsPage'
            )
          }
        ]
      },
      // {
      //   id: 163,
      //   to: routerLinks?.accountingPage,
      //   name: t('AppBar.Services.accountingPage.title'),
      //   icon: (color) =>
      //     color ? <AccountingNewIcon color={color} /> : <AccountingNewIcon />,
      //   visibleSubMenu: false,
      //   subMenu: [
      //     {
      //       id: 164,
      //       name: t('AppBar.Services.accountingPage.accountingTreePage'),
      //       to: routerLinks?.accountingTreePage,
      //       visibleSubMenu: true
      //     },
      //     {
      //       id: 16411,
      //       name: t('AppBar.Services.accountingPage.balanceSheetPage'),
      //       to: routerLinks?.balanceSheetPage
      //     },
      //     {
      //       id: 16412,
      //       name: t('AppBar.Services.accountingPage.summaryPage'),
      //       to: routerLinks?.summaryPage
      //     },
      //     // {
      //     //   id: 16413,
      //     //   name: 'كشف حساب',
      //     //   to: routerLinks?.accountStatementPage
      //     // },
      //     {
      //       id: 165,
      //       name: t('AppBar.Services.accountingPage.accountingJournalsPage'),
      //       to: routerLinks?.accountingJournalsPage
      //     }
      //     // {
      //     //   id: 166,
      //     //   name: 'المـعـامـلات',
      //     //   to: routerLinks?.accountingTransactionsPage
      //     // }
      //   ]
      // },
      {
        id: 91,
        to: routerLinks?.analysisPage,
        name: t('AppBar.Services.analysisPage.title'),
        icon: (color) =>
          color ? <AnalysisIcon color={color} /> : <AnalysisIcon />,
        subMenu: [
          {
            id: 888,
            name: t(
              'AppBar.Services.analysisPage.outgoingPurchaseInvoiceReports'
            ),
            to: routerLinks?.outgoingPurchaseInvoiceReports
          },
          {
            id: 6765448,
            name: t(
              'AppBar.Services.analysisPage.incomingPurchaseInvoiceReports'
            ),
            to: routerLinks?.incomingPurchaseInvoiceReports
          },
          {
            id: 772,
            name: t('AppBar.Services.analysisPage.taxInvoiceReportsPage'),
            to: routerLinks?.taxInvoiceReportsPage
          },
          {
            id: 77200,
            name: t('ProfitAndLossReports.title'),
            to: routerLinks?.profitAndLosesReportsPage
          }
        ],
        visibleSubMenu: false
      },

      {
        id: 11,
        to: routerLinks?.archivePage,
        name: t('AppBar.Services.archivePage.title'),
        icon: (color) =>
          color ? <ArchiveIcon color={color} /> : <ArchiveIcon />,
        subMenu: [
          {
            id: 1,
            to: routerLinks?.archiveNotesPage,
            name: t('AppBar.Services.archivePage.archiveNotesPage')
          },
          // {
          //   id: 2,
          //   to: routerLinks?.archiveCalendarPage,
          //   name: 'تقويم'
          // },
          {
            id: 3,
            to: routerLinks?.archiveDocsPage,
            name: t('AppBar.Services.archivePage.archiveDocsPage')
          }
        ],
        visibleSubMenu: false
      },
      {
        id: 11224,
        to: routerLinks?.fatoorahStorePage,
        isLink: true,
        name: t('AppBar.Services.fatoorahStorePage'),
        icon: (color) =>
          color ? <ArchiveIcon color={color} /> : <ArchiveIcon />,
        subMenu: [],
        visibleSubMenu: false
      },
      // {
      //   id: 112204,
      //   to: routerLinks?.technicalSupportPage,
      //   isLink: true,
      //   name: 'تذاكر الدعم',
      //   icon: (color) =>
      //     color ? <SupportIcon color={color} /> : <SupportIcon />,
      //   subMenu: [],
      //   visibleSubMenu: false
      // },
      {
        id: 1212424049,
        name: t('AppBar.Products.QRpage'),
        to: routerLinks?.QRPage,
        isLink: true,
        icon: (color) => (color ? <QrIcon color={color} /> : <QrIcon />),
        subMenu: []
      },
      {
        id: 11223,
        to: routerLinks?.upgradePage,
        isLink: true,
        name: t('AppBar.Services.upgradePage'),
        icon: (color) =>
          color ? <ArchiveIcon color={color} /> : <ArchiveIcon />,
        subMenu: [],
        visibleSubMenu: false
      }
    ];
  } else {
    linksServices = [
      {
        id: 1,
        to: routerLinks?.homePage,
        name: t('AppBar.Services.homePage'),
        isLink: true,
        icon: (color) => (color ? <HomeIcon color={color} /> : <HomeIcon />),
        subMenu: [],
        visibleSubMenu: false
      },
      {
        id: 6,
        to: routerLinks?.storePage,
        name: t('AppBar.Services.storePage.title'),
        icon: (color) => (color ? <StoreIcon color={color} /> : <StoreIcon />),
        visibleSubMenu: false,
        subMenu: [
          {
            id: 1,
            to: routerLinks?.storeItemManagerPage,
            name: t('AppBar.Services.storePage.storeItemManagerPage')
          },
          {
            id: 2,
            to: routerLinks?.storeProductsIngredientsPage,
            name: t('AppBar.Services.storePage.storeProductsIngredientsPage')
          }
        ]
      },
      {
        id: 4112121221,
        to: '/sales',
        name: t('AppBar.Services.salesPage.title'),
        icon: (color) =>
          color ? <ShoppingIcon color={color} /> : <ShoppingIcon />,
        visibleSubMenu: false,
        subMenu: [
          {
            id: 1,
            to: routerLinks?.outgoingPurchaseInvoice,
            name: t('AppBar.Services.salesPage.outgoingPurchaseInvoice')
          },
          {
            id: 10,
            to: routerLinks?.outgoingPurchaseInvoiceReturned,
            name: t('Invoices.ReturnedSaleInvoice')
          },
          {
            id: 2,
            to: routerLinks?.priceOfferRouteNew,
            name: t('AppBar.Services.salesPage.priceOfferRouteNew')
          },
          {
            id: 4,
            to: routerLinks?.clientPage,
            name: t('AppBar.Services.salesPage.clientPage')
          },
          {
            id: 543,
            to: routerLinks?.customerPaymentsPage,
            name: t('AppBar.Services.salesPage.customerPaymentsPage')
          }
        ]
      },
      {
        id: 4118871267671,
        // to: routerLinks?.salesPage,
        to: routerLinks?.incomingPurchaseInvoice,
        // isLink: true,
        name: t('AppBar.Services.incomingPurchaseInvoice.title'),
        icon: (color) =>
          color ? <ShoppingIcon color={color} /> : <ShoppingIcon />,
        visibleSubMenu: false,
        subMenu: [
          {
            id: 7,
            to: routerLinks?.incomingPurchaseInvoice,
            name: t(
              'AppBar.Services.incomingPurchaseInvoice.incomingPurchaseInvoice'
            )
          },
          {
            id: 701,
            to: routerLinks?.incomingPurchaseInvoiceReturned,
            name: t('purchaseInvoices.ReturnPurchaseInvoices')
          },
          {
            id: 48918293,
            to: routerLinks?.suppliersPage,
            name: t('AppBar.Services.incomingPurchaseInvoice.suppliersPage')
          },
          {
            id: 48918293,
            to: routerLinks?.suppliersPaymentsPage,
            name: t(
              'AppBar.Services.incomingPurchaseInvoice.suppliersPaymentsPage'
            )
          }
        ]
      },
      {
        id: 163,
        to: routerLinks?.accountingPage,
        name: t('AppBar.Services.accountingPage.title'),
        icon: (color) =>
          color ? <AccountingNewIcon color={color} /> : <AccountingNewIcon />,
        visibleSubMenu: false,
        subMenu: [
          {
            id: 164,
            name: t('AppBar.Services.accountingPage.accountingTreePage'),
            to: routerLinks?.accountingTreePage,
            visibleSubMenu: true
          },
          {
            id: 16411,
            name: t('AppBar.Services.accountingPage.balanceSheetPage'),
            to: routerLinks?.balanceSheetPage
          },
          {
            id: 16412,
            name: t('AppBar.Services.accountingPage.summaryPage'),
            to: routerLinks?.summaryPage
          },
          // {
          //   id: 16413,
          //   name: 'كشف حساب',
          //   to: routerLinks?.accountStatementPage
          // },
          {
            id: 165,
            name: t('AppBar.Services.accountingPage.accountingJournalsPage'),
            to: routerLinks?.accountingJournalsPage
          }
          // {
          //   id: 166,
          //   name: 'المـعـامـلات',
          //   to: routerLinks?.accountingTransactionsPage
          // }
        ]
      },
      {
        id: 91,
        to: routerLinks?.analysisPage,
        name: t('AppBar.Services.analysisPage.title'),
        icon: (color) =>
          color ? <AnalysisIcon color={color} /> : <AnalysisIcon />,
        subMenu: [
          {
            id: 888,
            name: t(
              'AppBar.Services.analysisPage.outgoingPurchaseInvoiceReports'
            ),
            to: routerLinks?.outgoingPurchaseInvoiceReports
          },
          {
            id: 6765448,
            name: t(
              'AppBar.Services.analysisPage.incomingPurchaseInvoiceReports'
            ),
            to: routerLinks?.incomingPurchaseInvoiceReports
          },
          {
            id: 772,
            name: t('AppBar.Services.analysisPage.taxInvoiceReportsPage'),
            to: routerLinks?.taxInvoiceReportsPage
          },
          {
            id: 77200,
            name: t('ProfitAndLossReports.title'),
            to: routerLinks?.profitAndLosesReportsPage
          }
        ],
        visibleSubMenu: false
      },

      {
        id: 11,
        to: routerLinks?.archivePage,
        name: t('AppBar.Services.archivePage.title'),
        icon: (color) =>
          color ? <ArchiveIcon color={color} /> : <ArchiveIcon />,
        subMenu: [
          {
            id: 1,
            to: routerLinks?.archiveNotesPage,
            name: t('AppBar.Services.archivePage.archiveNotesPage')
          },
          // {
          //   id: 2,
          //   to: routerLinks?.archiveCalendarPage,
          //   name: 'تقويم'
          // },
          {
            id: 3,
            to: routerLinks?.archiveDocsPage,
            name: t('AppBar.Services.archivePage.archiveDocsPage')
          }
        ],
        visibleSubMenu: false
      },
      {
        id: 11224,
        to: routerLinks?.fatoorahStorePage,
        isLink: true,
        name: t('AppBar.Services.fatoorahStorePage'),
        icon: (color) =>
          color ? <ArchiveIcon color={color} /> : <ArchiveIcon />,
        subMenu: [],
        visibleSubMenu: false
      },
      // {
      //   id: 112204,
      //   to: routerLinks?.technicalSupportPage,
      //   isLink: true,
      //   name: 'تذاكر الدعم',
      //   icon: (color) =>
      //     color ? <SupportIcon color={color} /> : <SupportIcon />,
      //   subMenu: [],
      //   visibleSubMenu: false
      // },
      {
        id: 1212424039,
        name: t('AppBar.Products.QRpage'),
        to: routerLinks?.QRPage,
        isLink: true,
        icon: (color) => (color ? <QrIcon color={color} /> : <QrIcon />),
        subMenu: []
      },
      {
        id: 11223,
        to: routerLinks?.upgradePage,
        isLink: true,
        name: t('AppBar.Services.upgradePage'),
        icon: (color) =>
          color ? <ArchiveIcon color={color} /> : <ArchiveIcon />,
        subMenu: [],
        visibleSubMenu: false
      }
    ];
  }

  let linksProducts;
  if (user?.role === 5) {
    linksProducts = [
      {
        id: 104,
        to: routerLinks?.posPage,
        name: t('AppBar.posPoints'),
        isLink: true,
        icon: (color) => (color ? <PosIcon color={color} /> : <PosIcon />),
        subMenu: [],
        visibleSubMenu: false
      }
    ];
  } else {
    if (user?.packageId == 4) {
      linksProducts = [
        {
          id: 1,
          to: routerLinks?.homePage,
          name: t('AppBar.Products.homePage'),
          isLink: true,
          icon: (color) => (color ? <HomeIcon color={color} /> : <HomeIcon />),
          subMenu: [],
          visibleSubMenu: false
        },
        // {
        //   id: 104,
        //   to: routerLinks?.posPage,
        //   name: 'نقاط البيع',
        //   isLink: true,
        //   icon: (color) => (color ? <PosIcon color={color} /> : <PosIcon />),
        //   subMenu: [],
        //   visibleSubMenu: false
        // },
        {
          id: 6,
          to: routerLinks?.storePage,
          name: t('AppBar.Products.storePage.title'),
          icon: (color) =>
            color ? <StoreIcon color={color} /> : <StoreIcon />,
          visibleSubMenu: false,
          subMenu: [
            {
              id: 1,
              to: routerLinks?.storeItemManagerProductsPage,
              name: t('AppBar.Products.storePage.storeItemManagerProductsPage')
            },
            // {
            //   id: 2,
            //   to: routerLinks?.storeIngredientsProductsPage,
            //   name: 'مكونات المنتجات'
            // },
            {
              id: 128723487,
              name: t('AppBar.Products.storePage.settingsCategoriesPage'),
              to: routerLinks?.settingsCategoriesPage
            },
            {
              id: 12772,
              name: t('AppBar.Products.storePage.settingsMeasureUnitsPage'),
              to: routerLinks?.settingsMeasureUnitsPage
            },
            {
              id: 12772,
              name: t('AppBar.Products.storePage.storeVariantsPage'),
              to: routerLinks?.storeVariantsPage
            },
            {
              id: 10,
              to: routerLinks?.currentStock,
              name: t('AppBar.Products.storePage.currentStock')
            },
            {
              id: 9,
              to: routerLinks?.firstTermStock,
              name: t('AppBar.Products.storePage.firstTermStock')
            },
            {
              id: 5,
              to: routerLinks?.storeInventory,
              name: t('AppBar.Products.storePage.storeInventory')
            },

            {
              id: 4,
              to: routerLinks?.storeConversionPage,
              name: t('AppBar.Products.storePage.storeConversionPage')
            },
            {
              id: 3333,
              to: routerLinks?.settingsWarehousesPage,
              name: t('AppBar.Products.storePage.settingsWarehousesPage'),
              isLink: true
              // icon: (color) => (color ? <StockIcon color={color} /> : <StockIcon />),
              // subMenu: [],
              // visibleSubMenu: false
            },
            {
              id: 12777,
              name: t('AppBar.Products.storePage.settingsWorkPlacesPage'),
              to: routerLinks?.settingsWorkPlacesPage
            },
            {
              id: 1557,
              name: t('AppBar.Products.storePage.receiptVoucher'),
              to: routerLinks?.receiptVoucher
            },
            {
              id: 1558,
              name: t('AppBar.Products.storePage.paymentVoucher'),
              to: routerLinks?.paymentVoucher
            }
            // {
            //   id: 6,
            //   to: routerLinks?.purchaseOrderPage,
            //   name: 'طلب شراء'
            // },
            // {
            //   id: 7,
            //   to: routerLinks?.incomingPurchaseInvoice,
            //   name: ' فاتورة الشراء'
            // },
            // {
            //   id: 17,
            //   to: routerLinks?.outgoingPurchaseInvoice,
            //   name: ' فاتورة البيع'
            // },
            // {
            //   id: 8,
            //   to: routerLinks?.storeSupplieresRecords,
            //   name: 'السجلات'
            // },
            // {
            //   id: 8,
            //   to: routerLinks?.suppliersPage,
            //   name: 'الموردين'
            // }
            // {
            //   id: 10,
            //   to: routerLinks?.productLabelPage,
            //   name: 'تسمية المنتجات'
            // }
          ]
        },
        {
          id: 4,
          to: routerLinks?.salesPage,
          name: t('AppBar.Products.salesPage.title'),
          icon: (color) =>
            color ? <ShoppingIcon color={color} /> : <ShoppingIcon />,
          visibleSubMenu: false,
          subMenu: [
            // {
            //   id: 8723487,
            //   name: 'فواتير البيع',
            //   to: routerLinks?.salesPage
            // },
            {
              id: 17,
              to: routerLinks?.outgoingPurchaseInvoice,
              name: t('AppBar.Products.salesPage.outgoingPurchaseInvoice')
            },
            {
              id: 10,
              to: routerLinks?.outgoingPurchaseInvoiceReturned,
              name: t('Invoices.ReturnedSaleInvoice')
            },
            {
              id: 'price_offer_page_id',
              name: t('AppBar.Products.salesPage.priceOfferRouteNew'),
              to: salesRouterLinks?.priceOfferRouteNew
            },
            {
              id: 87287,
              name: t('AppBar.Products.salesPage.clientPage'),
              to: routerLinks?.clientPage
            },
            // {
            //   id: 8720003487,
            //   name: 'الشيفتات',
            //   to: routerLinks?.shiftsPage
            // }
            {
              id: 8720003400,
              name: t('AppBar.Products.salesPage.customerPaymentsPage'),
              to: routerLinks?.customerPaymentsPage
            }
          ]
        },

        {
          id: 54654,
          // to: routerLinks?.salesPage,
          to: routerLinks?.incomingPurchaseInvoice,
          name: t('AppBar.Products.incomingPurchaseInvoice.title'),
          icon: (color) =>
            color ? <ShoppingIcon color={color} /> : <ShoppingIcon />,
          visibleSubMenu: false,
          subMenu: [
            {
              id: 7,
              to: routerLinks?.incomingPurchaseInvoice,
              name: t(
                'AppBar.Products.incomingPurchaseInvoice.incomingPurchaseInvoice'
              )
            },
            {
              id: 701,
              to: routerLinks?.incomingPurchaseInvoiceReturned,
              name: t('purchaseInvoices.ReturnPurchaseInvoices')
            },
            {
              id: 6,
              to: routerLinks?.purchaseOrderPage,
              name: t(
                'AppBar.Products.incomingPurchaseInvoice.purchaseOrderPage'
              )
            },
            {
              id: 8,
              to: routerLinks?.suppliersPage,
              name: t('AppBar.Products.incomingPurchaseInvoice.suppliersPage')
            },
            {
              id: 48918293,
              to: routerLinks?.suppliersPaymentsPage,
              name: t(
                'AppBar.Products.incomingPurchaseInvoice.suppliersPaymentsPage'
              )
            }
          ]
        },

        {
          id: 163,
          to: routerLinks?.accountingPage,
          name: t('AppBar.Products.accountingPage.title'),
          icon: (color) =>
            color ? <AccountingNewIcon color={color} /> : <AccountingNewIcon />,
          visibleSubMenu: false,
          subMenu: [
            {
              id: 164,
              name: t('AppBar.Products.accountingPage.accountingTreePage'),
              to: routerLinks?.accountingTreePage
            },
            {
              id: 164110,
              name: t('AppBar.Products.accountingPage.balanceSheetPage'),
              to: routerLinks?.balanceSheetPage
            },
            {
              id: 164120,
              name: t('AppBar.Products.accountingPage.summaryPage'),
              to: routerLinks?.summaryPage
            },
            // {
            //   id: 164130,
            //   name: 'كـشـف حـسـاب',
            //   to: routerLinks?.accountStatementPage
            // },
            {
              id: 165,
              name: t('AppBar.Products.accountingPage.accountingJournalsPage'),
              to: routerLinks?.accountingJournalsPage
            }
            // {
            //   id: 166,
            //   name: 'المـعـامـلات',
            //   to: routerLinks?.accountingTransactionsPage
            // }
          ]
        },
        {
          id: 91,
          to: routerLinks?.analysisPage,
          name: t('AppBar.Products.analysisPage.title'),
          icon: (color) =>
            color ? <AnalysisIcon color={color} /> : <AnalysisIcon />,
          subMenu: [
            {
              id: 777,
              name: t(
                'AppBar.Products.analysisPage.incomingPurchaseInvoiceReports'
              ),
              to: routerLinks?.incomingPurchaseInvoiceReports
            },
            {
              id: 888,
              name: t(
                'AppBar.Products.analysisPage.outgoingPurchaseInvoiceReports'
              ),
              to: routerLinks?.outgoingPurchaseInvoiceReports
            },
            {
              id: 8723487,
              name: t('AppBar.Products.analysisPage.salesPageReports'),
              to: routerLinks?.salesPageReports
            },
            {
              id: 8723123,
              name: t('AppBar.Products.analysisPage.shiftPageReports'),
              to: routerLinks?.shiftPageReports
            },
            {
              id: 772,
              name: t('AppBar.Products.analysisPage.taxInvoiceReportsPage'),
              to: routerLinks?.taxInvoiceReportsPage
            },
            {
              id: 77200,
              name: t('ProfitAndLossReports.title'),
              to: routerLinks?.profitAndLosesReportsPage
            }
          ],
          visibleSubMenu: false
        },

        {
          id: 11224,
          to: routerLinks?.fatoorahStorePage,
          isLink: true,
          name: t('AppBar.Products.fatoorahStorePage'),
          icon: (color) =>
            color ? <ArchiveIcon color={color} /> : <ArchiveIcon />,
          subMenu: [],
          visibleSubMenu: false
        },

        // {
        //   id: 112204,
        //   to: routerLinks?.technicalSupportPage,
        //   isLink: true,
        //   name: 'تذاكر الدعم',
        //   icon: (color) =>
        //     color ? <SupportIcon color={color} /> : <SupportIcon />,
        //   subMenu: [],
        //   visibleSubMenu: false
        // },
        // Fix
        {
          id: 11,
          to: routerLinks?.archivePage,
          name: t('AppBar.Products.archivePage.title'),
          icon: (color) =>
            color ? <ArchiveIcon color={color} /> : <ArchiveIcon />,
          subMenu: [
            {
              id: 1,
              to: routerLinks?.archiveNotesPage,
              name: t('AppBar.Products.archivePage.archiveNotesPage')
            },
            {
              id: 2,
              to: routerLinks?.archiveCalendarPage,
              name: t('AppBar.Products.archivePage.archiveCalendarPage')
            },
            {
              id: 3,
              to: routerLinks?.archiveDocsPage,
              name: t('AppBar.Products.archivePage.archiveDocsPage')
            }
          ],
          visibleSubMenu: false
        },

        {
          id: 1212424029,
          name: t('AppBar.Products.QRpage'),
          to: routerLinks?.QRPage,
          isLink: true,
          icon: (color) => (color ? <QrIcon color={color} /> : <QrIcon />),
          subMenu: []
        },
        {
          id: 121242499,
          name: t('AppBar.Products.upgradePage'),
          to: routerLinks?.upgradePage,
          isLink: true,
          icon: (color) =>
            color ? <ArchiveIcon color={color} /> : <ArchiveIcon />,
          subMenu: []
        }
      ];
    } else {
      linksProducts = [
        {
          id: 1,
          to: routerLinks?.homePage,
          name: t('AppBar.Products.homePage'),
          isLink: true,
          icon: (color) => (color ? <HomeIcon color={color} /> : <HomeIcon />),
          subMenu: [],
          visibleSubMenu: false
        },
        // {
        //   id: 104,
        //   to: routerLinks?.posPage,
        //   name: 'نقاط البيع',
        //   isLink: true,
        //   icon: (color) => (color ? <PosIcon color={color} /> : <PosIcon />),
        //   subMenu: [],
        //   visibleSubMenu: false
        // },
        {
          id: 6,
          to: routerLinks?.storePage,
          name: t('AppBar.Products.storePage.title'),
          icon: (color) =>
            color ? <StoreIcon color={color} /> : <StoreIcon />,
          visibleSubMenu: false,
          subMenu: [
            {
              id: 1,
              to: routerLinks?.storeItemManagerProductsPage,
              name: t('AppBar.Products.storePage.storeItemManagerProductsPage')
            },
            // {
            //   id: 2,
            //   to: routerLinks?.storeIngredientsProductsPage,
            //   name: 'مكونات المنتجات'
            // },
            {
              id: 128723487,
              name: t('AppBar.Products.storePage.settingsCategoriesPage'),
              to: routerLinks?.settingsCategoriesPage
            },
            {
              id: 12772,
              name: t('AppBar.Products.storePage.settingsMeasureUnitsPage'),
              to: routerLinks?.settingsMeasureUnitsPage
            },
            {
              id: 12772,
              name: t('AppBar.Products.storePage.storeVariantsPage'),
              to: routerLinks?.storeVariantsPage
            },
            {
              id: 10,
              to: routerLinks?.currentStock,
              name: t('AppBar.Products.storePage.currentStock')
            },
            {
              id: 9,
              to: routerLinks?.firstTermStock,
              name: t('AppBar.Products.storePage.firstTermStock')
            },
            {
              id: 5,
              to: routerLinks?.storeInventory,
              name: t('AppBar.Products.storePage.storeInventory')
            },

            {
              id: 4,
              to: routerLinks?.storeConversionPage,
              name: t('AppBar.Products.storePage.storeConversionPage')
            },
            {
              id: 3333,
              to: routerLinks?.settingsWarehousesPage,
              name: t('AppBar.Products.storePage.settingsWarehousesPage'),
              isLink: true
              // icon: (color) => (color ? <StockIcon color={color} /> : <StockIcon />),
              // subMenu: [],
              // visibleSubMenu: false
            },
            {
              id: 12777,
              name: t('AppBar.Products.storePage.settingsWorkPlacesPage'),
              to: routerLinks?.settingsWorkPlacesPage
            },
            {
              id: 1557,
              name: t('AppBar.Products.storePage.receiptVoucher'),
              to: routerLinks?.receiptVoucher
            },
            {
              id: 1558,
              name: t('AppBar.Products.storePage.paymentVoucher'),
              to: routerLinks?.paymentVoucher
            }
          ]
        },
        {
          id: 4,
          to: routerLinks?.salesPage,
          name: t('AppBar.Products.salesPage.title'),
          icon: (color) =>
            color ? <ShoppingIcon color={color} /> : <ShoppingIcon />,
          visibleSubMenu: false,
          subMenu: [
            // {
            //   id: 8723487,
            //   name: 'فواتير البيع',
            //   to: routerLinks?.salesPage
            // },
            {
              id: 17,
              to: routerLinks?.outgoingPurchaseInvoice,
              name: t('AppBar.Products.salesPage.outgoingPurchaseInvoice')
            },
            {
              id: 10,
              to: routerLinks?.outgoingPurchaseInvoiceReturned,
              name: t('Invoices.ReturnedSaleInvoice')
            },
            {
              id: 'price_offer_page_id',
              name: t('AppBar.Products.salesPage.priceOfferRouteNew'),
              to: salesRouterLinks?.priceOfferRouteNew
            },
            {
              id: 87287,
              name: t('AppBar.Products.salesPage.clientPage'),
              to: routerLinks?.clientPage
            },
            // {
            //   id: 8720003487,
            //   name: 'الشيفتات',
            //   to: routerLinks?.shiftsPage
            // }
            {
              id: 8720003400,
              name: t('AppBar.Products.salesPage.customerPaymentsPage'),
              to: routerLinks?.customerPaymentsPage
            }
          ]
        },

        {
          id: 54654,
          // to: routerLinks?.salesPage,
          to: routerLinks?.incomingPurchaseInvoice,
          name: t('AppBar.Products.incomingPurchaseInvoice.title'),
          icon: (color) =>
            color ? <ShoppingIcon color={color} /> : <ShoppingIcon />,
          visibleSubMenu: false,
          subMenu: [
            {
              id: 7,
              to: routerLinks?.incomingPurchaseInvoice,
              name: t(
                'AppBar.Products.incomingPurchaseInvoice.incomingPurchaseInvoice'
              )
            },
            {
              id: 701,
              to: routerLinks?.incomingPurchaseInvoiceReturned,
              name: t('purchaseInvoices.ReturnPurchaseInvoices')
            },
            {
              id: 6,
              to: routerLinks?.purchaseOrderPage,
              name: t(
                'AppBar.Products.incomingPurchaseInvoice.purchaseOrderPage'
              )
            },
            {
              id: 8,
              to: routerLinks?.suppliersPage,
              name: t('AppBar.Products.incomingPurchaseInvoice.suppliersPage')
            },
            {
              id: 48918293,
              to: routerLinks?.suppliersPaymentsPage,
              name: t(
                'AppBar.Products.incomingPurchaseInvoice.suppliersPaymentsPage'
              )
            }
          ]
        },

        // {
        //   id: 163,
        //   to: routerLinks?.accountingPage,
        //   name: t('AppBar.Products.accountingPage.title'),
        //   icon: (color) =>
        //     color ? <AccountingNewIcon color={color} /> : <AccountingNewIcon />,
        //   visibleSubMenu: false,
        //   subMenu: [
        //     {
        //       id: 164,
        //       name: t('AppBar.Products.accountingPage.accountingTreePage'),
        //       to: routerLinks?.accountingTreePage
        //     },
        //     {
        //       id: 164110,
        //       name: t('AppBar.Products.accountingPage.balanceSheetPage'),
        //       to: routerLinks?.balanceSheetPage
        //     },
        //     {
        //       id: 164120,
        //       name: t('AppBar.Products.accountingPage.summaryPage'),
        //       to: routerLinks?.summaryPage
        //     },
        //     {
        //       id: 165,
        //       name: t('AppBar.Products.accountingPage.accountingJournalsPage'),
        //       to: routerLinks?.accountingJournalsPage
        //     }

        //   ]
        // },
        {
          id: 91,
          to: routerLinks?.analysisPage,
          name: t('AppBar.Products.analysisPage.title'),
          icon: (color) =>
            color ? <AnalysisIcon color={color} /> : <AnalysisIcon />,
          subMenu: [
            {
              id: 777,
              name: t(
                'AppBar.Products.analysisPage.incomingPurchaseInvoiceReports'
              ),
              to: routerLinks?.incomingPurchaseInvoiceReports
            },
            {
              id: 888,
              name: t(
                'AppBar.Products.analysisPage.outgoingPurchaseInvoiceReports'
              ),
              to: routerLinks?.outgoingPurchaseInvoiceReports
            },
            {
              id: 8723487,
              name: t('AppBar.Products.analysisPage.salesPageReports'),
              to: routerLinks?.salesPageReports
            },
            {
              id: 8723123,
              name: t('AppBar.Products.analysisPage.shiftPageReports'),
              to: routerLinks?.shiftPageReports
            },
            {
              id: 772,
              name: t('AppBar.Products.analysisPage.taxInvoiceReportsPage'),
              to: routerLinks?.taxInvoiceReportsPage
            },
            {
              id: 77200,
              name: t('ProfitAndLossReports.title'),
              to: routerLinks?.profitAndLosesReportsPage
            }
          ],
          visibleSubMenu: false
        },
        {
          id: 11224,
          to: routerLinks?.fatoorahStorePage,
          isLink: true,
          name: t('AppBar.Products.fatoorahStorePage'),
          icon: (color) =>
            color ? <ArchiveIcon color={color} /> : <ArchiveIcon />,
          subMenu: [],
          visibleSubMenu: false
        },

        // {
        //   id: 112204,
        //   to: routerLinks?.technicalSupportPage,
        //   isLink: true,
        //   name: 'تذاكر الدعم',
        //   icon: (color) =>
        //     color ? <SupportIcon color={color} /> : <SupportIcon />,
        //   subMenu: [],
        //   visibleSubMenu: false
        // },
        // Fix
        {
          id: 11,
          to: routerLinks?.archivePage,
          name: t('AppBar.Products.archivePage.title'),
          icon: (color) =>
            color ? <ArchiveIcon color={color} /> : <ArchiveIcon />,
          subMenu: [
            {
              id: 1,
              to: routerLinks?.archiveNotesPage,
              name: t('AppBar.Products.archivePage.archiveNotesPage')
            },
            {
              id: 2,
              to: routerLinks?.archiveCalendarPage,
              name: t('AppBar.Products.archivePage.archiveCalendarPage')
            },
            {
              id: 3,
              to: routerLinks?.archiveDocsPage,
              name: t('AppBar.Products.archivePage.archiveDocsPage')
            }
          ],
          visibleSubMenu: false
        },

        {
          id: 1212424019,
          name: t('AppBar.Products.QRpage'),
          to: routerLinks?.QRPage,
          isLink: true,
          icon: (color) => (color ? <QrIcon color={color} /> : <QrIcon />),
          subMenu: []
        },
        {
          id: 121242499,
          name: t('AppBar.Products.upgradePage'),
          to: routerLinks?.upgradePage,
          isLink: true,
          icon: (color) =>
            color ? <ArchiveIcon color={color} /> : <ArchiveIcon />,
          subMenu: []
        }
      ];
    }
  }

  // const linksPos = [
  //   {
  //     id: 104,
  //     to: routerLinks?.posPage,
  //     name: 'نقاط البيع',
  //     isLink: true,
  //     icon: (color) => (color ? <PosIcon color={color} /> : <PosIcon />),
  //     subMenu: [],
  //     visibleSubMenu: false
  //   },
  // ]

  if (Cookies.get('currentUser') && user?.subscriptionType === 1) {
    return linksProducts;
  } else if (Cookies.get('currentUser') && user?.subscriptionType === 2) {
    return linksServices;
  } else if (Cookies.get('currentUser') && user?.subscriptionType === 3) {
    return partnerLinks(t);
  } else if (Cookies.get('currentUser') && user?.subscriptionType === 4) {
    return dataEntryLinks(t);
  } else if (Cookies.get('currentUser') && user?.subscriptionType === 6) {
    return delegateLinks(t);
  }
};
export default mainAppBarLinks;
