import axios from 'axios';

const getAllPosApi = async (token) => {
  try {
    const res = await axios.get('/Pos/all', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllPosApi;
