import axios from 'axios';

const searchAllClientsApi = async (token, values) => {
  try {
    const res = await axios.get(
      `User/all?page=${values?.page ? values.page : ''}&searchText=${
        values?.searchText ? values.searchText : ''
      }&test=1`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
        }
      }
    );

    return res;
  } catch (error) {
    throw error;
  }
};

export default searchAllClientsApi;
