import HomeIcon from '../../common/icons/HomeIcon';
import ShoppingIcon from '../../common/icons/ShoppingIcon';
import Links from '../app/Links';

const routerLinks = Links();
const delegateLinks = (t) => {
  return [
    {
      id: 1,
      to: routerLinks.homePage,
      name: t('AppBar.Services.homePage'),
      isLink: true,
      icon: (color) => (color ? <HomeIcon color={color} /> : <HomeIcon />),
      subMenu: [],
      visibleSubMenu: false
    },
    {
      id: 4112121221,
      to: '/sales',
      name: t('AppBar.Services.salesPage.title'),
      icon: (color) =>
        color ? <ShoppingIcon color={color} /> : <ShoppingIcon />,
      visibleSubMenu: false,
      subMenu: [
        {
          id: 1,
          to: routerLinks.outgoingPurchaseInvoice,
          name: t('AppBar.Services.salesPage.outgoingPurchaseInvoice')
        },
        {
          id: 2,
          to: routerLinks.priceOfferRouteNew,
          name: t('AppBar.Services.salesPage.priceOfferRouteNew')
        }
      ]
    },
    {
      id: 4118871267671,
      // to: routerLinks.salesPage,
      to: routerLinks.incomingPurchaseInvoice,
      isLink: true,
      name: t('AppBar.Services.incomingPurchaseInvoice.title'),
      icon: (color) =>
        color ? <ShoppingIcon color={color} /> : <ShoppingIcon />,
      visibleSubMenu: false,
      subMenu: []
    }

    // {
    //   id: 123847823,
    //   to: routerLinks.priceOfferRoute,
    //   name: 'عرض اسعار',
    //   isLink: true,
    //   icon: (color) =>
    //     color ? <AnalysisIcon color={color} /> : <AnalysisIcon />,
    //   subMenu: [],
    //   visibleSubMenu: false
    // }
  ];
};
// const delegateLinks = [
//   {
//     id: 1,
//     to: routerLinks.homePage,
//     name: 'الرئيســية',
//     isLink: true,
//     icon: (color) => (color ? <HomeIcon color={color} /> : <HomeIcon />),
//     subMenu: [],
//     visibleSubMenu: false
//   },
//   {
//     id: 4112121221,
//     to: '/sales',
//     name: 'المبيعات',
//     icon: (color) =>
//       color ? <ShoppingIcon color={color} /> : <ShoppingIcon />,
//     visibleSubMenu: false,
//     subMenu: [
//       {
//         id: 1,
//         to: routerLinks.outgoingPurchaseInvoice,
//         name: 'فواتير البيع'
//       },
//       {
//         id: 2,
//         to: routerLinks.priceOfferRouteNew,
//         name: 'عرض السعر'
//       }
//     ]
//   },
//   {
//     id: 4118871267671,
//     // to: routerLinks.salesPage,
//     to: routerLinks.incomingPurchaseInvoice,
//     isLink: true,
//     name: 'المشتريات',
//     icon: (color) =>
//       color ? <ShoppingIcon color={color} /> : <ShoppingIcon />,
//     visibleSubMenu: false,
//     subMenu: []
//   }

//   // {
//   //   id: 123847823,
//   //   to: routerLinks.priceOfferRoute,
//   //   name: 'عرض اسعار',
//   //   isLink: true,
//   //   icon: (color) =>
//   //     color ? <AnalysisIcon color={color} /> : <AnalysisIcon />,
//   //   subMenu: [],
//   //   visibleSubMenu: false
//   // }
// ];

export default delegateLinks;
