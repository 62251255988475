/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Table, Empty, Pagination } from 'antd';
import tableColumns from './tableColumns';
import { useEffect } from 'react';
import { useState } from 'react';
import UserContext from '../../contexts/user-context/UserProvider';
import SaleInvoicesContext from '../../contexts/sale-invoices-context/SaleInvoicesContext';
import { useHistory } from 'react-router-dom';
import salesRouterLinks from '../../components/app/sales-routes/salesRouterLinks';
import getAllTaxInvoiceReportsApi from '../../apis/reports-apis/getAllTaxInvoiceReportsApi';
import getAllSaleInvoicesApi from '../../apis/sales-apis/getAllSaleInvoicesApi';
// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
import { useTranslation } from 'react-i18next';
const PageTable = ({
  allFetchedSaleInvoices,
  isReportsPage,
  taxPage,
  selectedPicker
}) => {
  const [index, setIndex] = useState(0);
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  const {
    fetchCountSaleInvoices,
    isLoadingAllSaleInvoices,
    setIsLoadingAllSaleInvoices,
    setFetchCountSaleInvoices,
    setSelectedInvoice,
    setLaterInvoiceModalOpened,
    setSelectedSaleInvoiceModalOpened,
    tablePagination,
    setTablePagination,
    setAllFetchedSaleInvoice
  } = useContext(SaleInvoicesContext);
  const { user } = useContext(UserContext);
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: tablePagination.current_page * 10 + (index + 1) - 10
    }));
  };

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (allFetchedSaleInvoices?.length >= 0) {
      if (isMounted) {
        setTableDataSource(mapDataSource(allFetchedSaleInvoices));
      }
    } else if (taxPage) {
      setTableDataSource(mapDataSource(allFetchedSaleInvoices.data));
      setTablePagination(allFetchedSaleInvoices.pagination);
    } else {
    }
    return () => {
      isMounted = false;
    };
  }, [
    fetchCountSaleInvoices,
    allFetchedSaleInvoices,
    allFetchedSaleInvoices?.length
  ]);

  const handlePageChange = async (page) => {
    try {
      setIsLoadingAllSaleInvoices(true);
      let res = await getAllTaxInvoiceReportsApi(user?.token, {
        page: page,
        from: selectedPicker?.strings[0],
        to: selectedPicker?.strings[1]
      });

      if (res.status === 200) {
        setIsLoadingAllSaleInvoices(false);
        setAllFetchedSaleInvoice(res.data.data.saleInvoice.data);
        setTablePagination(res.data.data.saleInvoice.pagination);
        setTableDataSource(res.data.data.saleInvoice.data);
      }
    } catch (error) {
      setIsLoadingAllSaleInvoices(false);
    }
  };

  return (
    <>
      <Table
        locale={{
          emptyText: (
            <Empty description={false}>
              {t('CashiersalesInvoiceReport.noData')}
            </Empty>
          )
        }}
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedSaleInvoices,
          setLaterInvoiceModalOpened,
          setFetchCountSaleInvoices,
          setSelectedInvoice,
          setIsLoadingAllSaleInvoices,
          setSelectedSaleInvoiceModalOpened,
          user?.token,
          t,
          taxPage
        )}
        loading={isLoadingAllSaleInvoices}
        // scroll={{ x: 400 }}
        footer={false}
      />
      {tablePagination && (
        <Pagination
          defaultCurrent={1}
          showSizeChanger={false}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          style={{ display: 'flex', justifyContent: 'center' }}
          // itemRender={itemRender}
          onChange={(page, pageSize) => {
            setFetchCountSaleInvoices((prev) => prev + 1);
            setTablePagination({ ...tablePagination, current_page: page });
            if (taxPage) {
              handlePageChange(page);
            }
          }}
          hideOnSinglePage={true}
        />
      )}
    </>
  );
};

export default PageTable;
