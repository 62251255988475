/* eslint-disable eqeqeq */
import * as Yup from 'yup';

const sharedProductsSchemaObj = (t) => {
  return {
    product_id: Yup.string()
      .test(
        'products.index.product_id',
        t('purchaseInvoices.selectProduct'),
        (v, context) => {
          let result = true;
          if (!v && context.from[1].value.details_type == '1') {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.product_id',
        t('purchaseInvoices.selectAnotherProduct'),
        (v, context) => {
          let result = true;
          if (
            v &&
            context.from[1]?.value?.products?.length > 0 &&
            context.from[1]?.value?.products.filter((p) => p.product_id == v)
              ?.length > 1
          ) {
            result = false;
          }
          return result;
        }
      ),
    quantity: Yup.string()
      .test(
        'products.index.quantity',
        t('purchaseInvoices.quantityValidation'),
        (v, context) => {
          let result = true;
          if (!v && context.from[1].value.details_type == '1') {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.quantity',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            // !v.match(/^[0-9]+$/) &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[1].value.details_type == '1'
          ) {
            result = false;
          }
          return result;
        }
      ),

    tax: Yup.string().test(
      'products.index.tax',
      t('purchaseInvoices.onlyNumbersAllowed'),
      (v, context) => {
        let result = true;
        if (v && !v.match(/^(\d+)?(\.\d+)?$/)) {
          result = false;
        }
        return result;
      }
    ),
    warehouse_id: Yup.string().test(
      'products.index.warehouse_id',
      t('purchaseInvoices.selectStock'),
      (v, context) => {
        let result = true;
        if (!v && context.from[1].value.details_type == '1') {
          result = false;
        }
        return result;
      }
    ),
    // expirationDate: Yup.mixed().test(
    //   'products.index.expirationDate',
    //   'اختار تاريخ انتهاء الصلاحية',
    //   (v, context) => {
    //     let result = true;
    //     if (!v && context.from[1].value.details_type == '1') {
    //       result = false;
    //     }
    //     return result;
    //   }
    // ),
    price: Yup.string()
      .test(
        'products.index.price',
        t('purchaseInvoices.productPriceValidation'),
        (v, context) => {
          let result = true;
          // if the bill of type products === 1 and no value of price
          if ((!v || v == 0) && context.from[1].value.details_type == '1')
            result = false;
          return result;
        }
      )
      .test(
        'products.index.price',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[1].value.details_type == '1'
          )
            result = false;
          return result;
        }
      ),
    discount_type: Yup.string().test(
      'products.index.discount_type',
      t('purchaseInvoices.discountType'),
      (v, context) => {
        let result = true;
        if (!v && context.from[1].value.details_type == '1') result = false;
        return result;
      }
    ),
    discount_price: Yup.string()
      .test(
        'products.index.discount_price',
        t('purchaseInvoices.enterDiscountPrice'),
        (v, context) => {
          let result = true;
          if (
            !v &&
            context.from[1].value.details_type == '1' &&
            context.parent.discount_type == '1'
          ) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.discount_price',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[1].value.details_type == '1' &&
            context.parent.discount_type == '1'
          ) {
            result = false;
          }
          return result;
        }
      ),
    // .test(
    //   'products.index.discount_price',
    //   'سعر الخصم اعلى من سعر المنتج',
    //   (v, context) => {
    //     let result = true;
    //     if (
    //       v &&
    //       context.from[1].value.details_type === '1' &&
    //       context.parent.discount_type == '1' &&
    //       parseInt(v) > parseInt(context.parent.price)
    //     )
    //       result = false;
    //     return result;
    //   }
    // ),
    discount_percentage: Yup.string()
      .test(
        'products.index.discount_percentage',
        t('purchaseInvoices.enterDiscountPercentage'),
        (v, context) => {
          let result = true;
          if (
            !v &&
            context.from[1].value.details_type == '1' &&
            context.parent.discount_type == '2'
          ) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.discount_percentage',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[1].value.details_type == '1' &&
            context.parent.discount_type == '2'
          ) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.discount_price',
        t('purchaseInvoices.discountRateHigh'),
        (v, context) => {
          let result = true;
          if (
            v &&
            context.from[1].value.details_type === '1' &&
            context.parent.discount_type == '2' &&
            parseInt(v) > 100
          )
            result = false;
          return result;
        }
      )
  };
};

const sharedIngredientSchemaObj = (t) => {
  return {
    ingredient_id: Yup.string()
      .test(
        'ingredients.index.ingredient_id',
        t('purchaseInvoices.selectProductIngredient'),
        (v, context) => {
          let result = true;
          if (!v && context.from[1].value.details_type == '2') {
            result = false;
          }
          return result;
        }
      )
      .test(
        'ingredients.index.ingredient_id',
        t('purchaseInvoices.selectAnotherIngredient'),
        (v, context) => {
          let result = true;
          if (
            v &&
            context.from[1]?.value?.ingredients?.length > 0 &&
            context.from[1]?.value?.ingredients.filter(
              (p) => p.ingredient_id == v
            )?.length > 1
          ) {
            result = false;
          }
          return result;
        }
      ),

    quantity: Yup.string()
      .test(
        'ingredients.index.quantity',
        t('purchaseInvoices.quantityValidation'),
        (v, context) => {
          let result = true;
          if (!v && context.from[1].value.details_type == '2') {
            result = false;
          }
          return result;
        }
      )
      .test(
        'ingredients.index.quantity',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            // !v.match(/^[0-9]+$/) &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[1].value.details_type == '2'
          ) {
            result = false;
          }
          return result;
        }
      ),
    warehouse_id: Yup.string().test(
      'ingredients.index.warehouse_id',
      t('purchaseInvoices.selectStock'),
      (v, context) => {
        let result = true;
        if (!v && context.from[1].value.details_type == '2') {
          result = false;
        }
        return result;
      }
    ),
    // expirationDate: Yup.mixed().test(
    //   'ingredients.index.expirationDate',
    //   'اختار تاريخ انتهاء الصلاحية',
    //   (v, context) => {
    //     let result = true;
    //     if (!v && context.from[1].value.details_type == '2') {
    //       result = false;
    //     }
    //     return result;
    //   }
    // ),
    price: Yup.string()
      .test(
        'ingredients.index.price',
        t('purchaseInvoices.ingredientPriceValidation'),
        (v, context) => {
          let result = true;
          // if the bill of type ingredients === 2 and no value of price
          if (!v && context.from[1].value.details_type == '2') result = false;
          return result;
        }
      )
      .test(
        'ingredients.index.price',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[1].value.details_type == '2'
          )
            result = false;
          return result;
        }
      ),
    discount_type: Yup.string().test(
      'ingredients.index.discount_type',
      t('purchaseInvoices.discountType'),
      (v, context) => {
        let result = true;
        if (!v && context.from[1].value.details_type == '2') result = false;
        return result;
      }
    ),
    discount_price: Yup.string()
      .test(
        'ingredients.index.discount_price',
        t('purchaseInvoices.enterDiscountPrice'),
        (v, context) => {
          let result = true;
          if (
            !v &&
            context.from[1].value.details_type == '2' &&
            context.parent.discount_type == '1'
          ) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'ingredients.index.discount_price',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[1].value.details_type == '2' &&
            context.parent.discount_type == '1'
          ) {
            result = false;
          }
          return result;
        }
      ),
    // .test(
    //   'ingredients.index.discount_price',
    //   'سعر الخصم اعلى من سعر المكون',
    //   (v, context) => {
    //     let result = true;
    //     if (
    //       v &&
    //       context.from[1].value.details_type === '2' &&
    //       context.parent.discount_type == '1' &&
    //       parseInt(v) > parseInt(context.parent.price)
    //     )
    //       result = false;
    //     return result;
    //   }
    // ),
    discount_percentage: Yup.string()
      .test(
        'ingredients.index.discount_percentage',
        t('purchaseInvoices.enterDiscountPercentage'),
        (v, context) => {
          let result = true;
          if (
            !v &&
            context.from[1].value.details_type == '2' &&
            context.parent.discount_type == '2'
          ) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'ingredients.index.discount_percentage',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[1].value.details_type == '2' &&
            context.parent.discount_type == '2'
          ) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'ingredients.index.discount_price',
        t('purchaseInvoices.discountRateHigh'),
        (v, context) => {
          let result = true;
          if (
            v &&
            context.from[1].value.details_type === '2' &&
            context.parent.discount_type == '2' &&
            parseInt(v) > 100
          )
            result = false;
          return result;
        }
      )
  };
};

const sharedSchema = (t) => {
  return {
    supplier_id: Yup.string().required(t('purchaseInvoices.chooseMerchant')),
    manualNum: Yup.string(),
    details_type: Yup.string().required(
      t('purchaseInvoices.SelectInvoiceType')
    ),
    products: Yup.array().of(
      Yup.object().shape({
        ...sharedProductsSchemaObj
      })
    ),
    ingredients: Yup.array().of(
      Yup.object().shape({
        ingredient_id: Yup.string()
          .test(
            'ingredients.index.ingredient_id',
            t('purchaseInvoices.selectProductIngredient'),
            (v, context) => {
              let result = true;
              if (!v && context.from[1].value.details_type == '2') {
                result = false;
              }
              return result;
            }
          )
          .test(
            'ingredients.index.ingredient_id',
            t('purchaseInvoices.selectAnotherIngredient'),
            (v, context) => {
              let result = true;
              if (
                v &&
                context.from[1]?.value?.ingredients?.length > 0 &&
                context.from[1]?.value?.ingredients.filter(
                  (p) => p.ingredient_id == v
                )?.length > 1
              ) {
                result = false;
              }
              return result;
            }
          ),
        quantity: Yup.string()
          .test(
            'ingredients.index.quantity',
            t('purchaseInvoices.quantityValidation'),
            (v, context) => {
              let result = true;
              if (!v && context.from[1].value.details_type == '2') {
                result = false;
              }
              return result;
            }
          )
          .test(
            'ingredients.index.quantity',
            t('purchaseInvoices.onlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (
                v &&
                // !v.match(/^[0-9]+$/) &&
                !v.match(/^(\d+)?(\.\d+)?$/) &&
                context.from[1].value.details_type == '2'
              ) {
                result = false;
              }
              return result;
            }
          ),
        warehouse_id: Yup.string().test(
          'ingredients.index.warehouse_id',
          t('purchaseInvoices.selectStock'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1].value.details_type == '2') {
              result = false;
            }
            return result;
          }
        ),
        // expirationDate: Yup.mixed().test(
        //   'ingredients.index.expirationDate',
        //   'اختار تاريخ انتهاء الصلاحية',
        //   (v, context) => {
        //     let result = true;
        //     if (!v && context.from[1].value.details_type == '2') {
        //       result = false;
        //     }
        //     return result;
        //   }
        // ),
        price: Yup.string()
          .test(
            'ingredients.index.price',
            t('purchaseInvoices.ingredientPriceValidation'),
            (v, context) => {
              let result = true;
              // if the bill of type ingredients === 2 and no value of price
              if (!v && context.from[1].value.details_type == '2')
                result = false;
              return result;
            }
          )
          .test(
            'ingredients.index.price',
            t('purchaseInvoices.onlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (
                v &&
                !v.match(/^(\d+)?(\.\d+)?$/) &&
                context.from[1].value.details_type == '2'
              )
                result = false;
              return result;
            }
          ),

        discount_type: Yup.string().test(
          'ingredients.index.discount_type',
          t('purchaseInvoices.discountType'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1].value.details_type == '2') result = false;
            return result;
          }
        ),
        discount_price: Yup.string()
          .test(
            'ingredients.index.discount_price',
            t('purchaseInvoices.discountPrice'),
            (v, context) => {
              let result = true;
              if (
                !v &&
                context.from[1].value.details_type == '2' &&
                context.parent.discount_type == '1'
              ) {
                result = false;
              }
              return result;
            }
          )
          .test(
            'ingredients.index.discount_price',
            t('purchaseInvoices.onlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (
                v &&
                !v.match(/^(\d+)?(\.\d+)?$/) &&
                context.from[1].value.details_type == '2' &&
                context.parent.discount_type == '1'
              ) {
                result = false;
              }
              return result;
            }
          ),
        // .test(
        //   'ingredients.index.discount_price',
        //   'سعر الخصم اعلى من سعر المكون',
        //   (v, context) => {
        //     let result = true;
        //     if (
        //       v &&
        //       context.from[1].value.details_type === '2' &&
        //       context.parent.discount_type == '1' &&
        //       parseInt(v) > parseInt(context.parent.price)
        //     )
        //       result = false;
        //     return result;
        //   }
        // ),
        discount_percentage: Yup.string()
          .test(
            'ingredients.index.discount_percentage',
            t('purchaseInvoices.enterDiscountPercentage'),
            (v, context) => {
              let result = true;
              if (
                !v &&
                context.from[1].value.details_type == '2' &&
                context.parent.discount_type == '2'
              ) {
                result = false;
              }
              return result;
            }
          )
          .test(
            'ingredients.index.discount_percentage',
            t('purchaseInvoices.onlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (
                v &&
                !v.match(/^(\d+)?(\.\d+)?$/) &&
                context.from[1].value.details_type == '2' &&
                context.parent.discount_type == '2'
              ) {
                result = false;
              }
              return result;
            }
          )
          .test(
            'ingredients.index.discount_price',
            t('purchaseInvoices.discountRateHigh'),
            (v, context) => {
              let result = true;
              if (
                v &&
                context.from[1].value.details_type === '2' &&
                context.parent.discount_type == '2' &&
                parseInt(v) > 100
              )
                result = false;
              return result;
            }
          )
      })
    ),
    // due_date: Yup.mixed().required('اختار تاريخ الاستحقاق'),
    // discount_type: Yup.string().required('اختار نوع الخصم'),
    discount_price: Yup.string()
      .test(
        'discount_price',
        t('purchaseInvoices.enterDiscountPrice'),
        (v, context) => {
          let result = true;
          if (!v && context.parent?.discount_type === '1') result = false;
          return result;
        }
      )
      .test(
        'discount_price',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.parent?.discount_type === '1'
          )
            result = false;
          return result;
        }
      ),
    discount_percentage: Yup.string()
      .test(
        'discount_percentage',
        t('purchaseInvoices.enterDiscountPercentage'),
        (v, context) => {
          let result = true;
          if (!v && context.parent?.discount_type === '2') result = false;
          return result;
        }
      )
      .test(
        'discount_percentage',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.parent?.discount_type === '2'
          )
            result = false;
          return result;
        }
      ),
    extra_prices: Yup.array().of(
      Yup.object().shape({
        price: Yup.string()
          .test(
            'extra_prices.index.price',
            t('purchaseInvoices.enterPrice'),
            (v, context) => {
              let result = true;
              if (!v && context.parent.title) {
                result = false;
              }
              return result;
            }
          )
          .test(
            'extra_prices.index.price',
            t('purchaseInvoices.onlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (v && !v.match(/^(\d+)?(\.\d+)?$/)) {
                result = false;
              }
              return result;
            }
          ),
        title: Yup.string().test(
          'extra_prices.index.title',
          t('purchaseInvoices.enterPriceStatement'),
          (v, context) => {
            let result = true;
            if (!v && context.parent.price) {
              result = false;
            }
            return result;
          }
        )
      })
    ),
    pay_type: Yup.string().required(t('purchaseInvoices.payTypeValidation')),
    multiPaymentVisa: Yup.string()
      .test(
        'multiPaymentVisa',
        t('purchaseInvoices.visaPlaceholder'),
        (v, context) => {
          let result = true;
          if (!v && context.from[0].value.pay_type == '6') {
            result = false;
          }
          return result;
        }
      )
      .test(
        'multiPaymentVisa',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[0].value.pay_type == '6'
          ) {
            result = false;
          }
          return result;
        }
      ),
    multiPaymentCash: Yup.string()
      .test(
        'multiPaymentCash',
        t('purchaseInvoices.cashAmount'),
        (v, context) => {
          let result = true;
          if (!v && context.from[0].value.pay_type == '6') {
            result = false;
          }
          return result;
        }
      )
      .test(
        'multiPaymentCash',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[0].value.pay_type == '6'
          ) {
            result = false;
          }
          return result;
        }
      ),
    invoice_due_date: Yup.mixed().test(
      'invoice_due_date',
      t('purchaseInvoices.enterPaymentDate'),
      (v, context) => {
        let result = true;
        if (!v && context.from[0].value.pay_type == '2') {
          result = false;
        }
        return result;
      }
    ),
    // first_paid: Yup.mixed().test(
    //   'first_paid',
    //   'من فضلك أدخل الدفعة الأولى',
    //   (v, context) => {
    //     let result = true;
    //     if (!v && context.from[0].value.pay_type == '2') {
    //       result = false;
    //     }
    //     return result;
    //   }
    // ),
    invoice_image: Yup.mixed()
  };
};

const newInvoiceSchema = (t) => {
  return Yup.object().shape({
    ...sharedSchema(t)
  });
};

const editInvoiceSchema = (t) => {
  return Yup.object().shape({
    ...sharedSchema(t),
    products: Yup.array().of(
      Yup.object().shape({
        ...sharedProductsSchemaObj(t),
        returnQuantity: Yup.string()
          .test(
            'products.index.returnQuantity',
            t('purchaseInvoices.returnedQuantityValidate'),
            (v, context) => {
              let result = true;
              if (!v && context.from[1].value.details_type == '1') {
                result = false;
              }
              return result;
            }
          )
          .test(
            'products.index.returnQuantity',
            t('purchaseInvoices.onlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (
                v &&
                // !v.match(/^[0-9]+$/) &&
                !v.match(/^(\d+)?(\.\d+)?$/)
              ) {
                result = false;
              }
              return result;
            }
          )
      })
    ),
    ingredients: Yup.array().of(
      Yup.object().shape({
        ...sharedIngredientSchemaObj(t),
        returnQuantity: Yup.string()
          .test(
            'products.index.returnQuantity',
            t('purchaseInvoices.returnedQuantityValidate'),
            (v, context) => {
              let result = true;
              if (!v && context.from[1].value.details_type == '2') {
                result = false;
              }
              return result;
            }
          )
          .test(
            'products.index.returnQuantity',
            t('purchaseInvoices.onlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (
                v &&
                context.from[1].value.details_type == '2' &&
                // !v.match(/^[0-9]+$/) &&
                !v.match(/^(\d+)?(\.\d+)?$/)
              ) {
                result = false;
              }
              return result;
            }
          )
      })
    )
  });
};

const getIncommingInvoiceSchema = (selectedReceipt, t) => {
  if (!selectedReceipt) return newInvoiceSchema(t);

  return editInvoiceSchema(t);
};

export default getIncommingInvoiceSchema;
