import React, { useContext, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import MainAppProfileMenu from './MainAppProfileMenu';
import CogIcon from '../../common/icons/CogIcon';
import routerLinks from '../app/routerLinks';
import ShiftStopwatch from './ShiftStopwatch';
import UserContext from '../../contexts/user-context/UserProvider';
import XIcon from '../../common/icons/XIcon';
import MenuIcon from '../../common/icons/MenuIcon';
import MainAppBarContext from '../../contexts/main-app-bar-context/MainAppBarProvider';
import './SearchNotificationsProfile.scss';
import userRoles from '../../userRoles';
import Countdown from '../countdown/Countdown';
import i18n from '../../i18n';
import LanguageButton from '../../common/language-button/LanguageButton';
import { useTranslation } from 'react-i18next';

// import MainAppNotifications from '../main-app-notifications/MainAppNotifications';

const SearchNotificationsProfile = (props) => {
  // const { noSearch } = props;
  const { user } = useContext(UserContext);
  const { menuVisible, setMenuVisible } = useContext(MainAppBarContext);
  const { t } = useTranslation();

  const settingsServices = [
    {
      id: 1,
      to: routerLinks.generalSettingsPage,
      title: t('GeneralSettings.title')
    },
    {
      id: 10,
      to: routerLinks.systemSettingsPage,
      title: t('SystemSettings.dropdown')
    },
    {
      id: 2,
      to: routerLinks.upgradePage,
      title: t('AppBar.Products.upgradePage')
    },
    {
      id: 7,
      to: routerLinks.systemUsersPage,
      title: t('help.Users')
    }
  ];
  const settingsProducts = [
    {
      id: 5,
      to: routerLinks.generalSettingsPage,
      title: t('GeneralSettings.title')
    },
    {
      id: 10,
      to: routerLinks.systemSettingsPage,
      title: t('SystemSettings.dropdown')
    },
    {
      id: 6,
      to: routerLinks.settingsPosPage,
      title: t('AppBar.posPoints')
    },
    {
      id: 7,
      to: routerLinks.systemUsersPage,
      title: t('help.Users')
    },
    {
      id: 8,
      to: routerLinks.systemHelpPage,
      title: t('AppBar.help')
    },
    {
      id: 9,
      to: routerLinks.upgradePage,
      title: t('AppBar.Products.upgradePage')
    }
  ];

  const menu = (
    <Menu className="settings-dropdown-ul">
      {user.subscriptionType === 2 &&
        settingsServices.map(({ id, to, title }) => (
          <Menu.Item className="settings-menu-link" key={id}>
            <RouterLink to={to}>{title}</RouterLink>
          </Menu.Item>
        ))}
      {user.subscriptionType === 1 &&
        settingsProducts.map(({ id, to, title }) => (
          <Menu.Item className="settings-menu-link" key={id}>
            <RouterLink to={to}>{title}</RouterLink>
          </Menu.Item>
        ))}
    </Menu>
  );
  const langMenu = (
    <Menu className="settings-dropdown-ul">
      <Menu.Item
        className="settings-menu-link"
        onClick={() => i18n.changeLanguage('en')}
      >
        EN
      </Menu.Item>
      <Menu.Item
        className="settings-menu-link"
        onClick={() => i18n.changeLanguage('ar')}
      >
        AR
      </Menu.Item>
      <Menu.Item
        className="settings-menu-link"
        onClick={() => i18n.changeLanguage('ur')}
      >
        UR
      </Menu.Item>
    </Menu>
  );

  const [settingsClicked, setSettingsClicked] = React.useState(false);
  const [loadingSignOut, setLoadingSignOut] = React.useState(false);

  return (
    <div className="search-notifications-container">
      {/* {user.packageId === 1 && <Countdown />} */}

      {user.packageId === 1 && <Countdown />}

      <div className="search-notifications-profile-wrap">
        {/* {noSearch ? null : <MainAppSearch />} */}
        <button
          className="main-app-bar-menu-btn"
          onClick={() => setMenuVisible((prev) => !prev)}
        >
          {menuVisible ? <XIcon color="#fff" /> : <MenuIcon color="#fff" />}
        </button>
        {user?.expire === 0 ? (
          <ShiftStopwatch
            loadingSignOut={loadingSignOut}
            setLoadingSignOut={setLoadingSignOut}
          />
        ) : null}
        <div className="notifications-profile-wrap">
          <LanguageButton />
        </div>
        <div className="notifications-profile-wrap">
          {user?.role === userRoles?.manager && user?.expire === 0 ? (
            <Dropdown
              overlay={menu}
              trigger={['click']}
              arrow
              placement="bottomCenter"
              overlayClassName="settings-dropdown-wrap"
              onVisibleChange={() => {
                setSettingsClicked((prevState) => !prevState);
              }}
              style={{ marginLeft: '8px' }}
            >
              <div
                className={`settings-btn ${settingsClicked ? 'clicked' : ''}`}
              >
                <CogIcon color={settingsClicked ? '#fff' : '#000'} />
              </div>
            </Dropdown>
          ) : null}

          <MainAppProfileMenu
            loadingSignOut={loadingSignOut}
            setLoadingSignOut={setLoadingSignOut}
          />
        </div>
      </div>
    </div>
    // <div className="bar-container">
    //   <button
    //     className="main-app-bar-menu-btn"
    //     onClick={() => setMenuVisible((prev) => !prev)}
    //   >
    //     {menuVisible ? <XIcon color="#fff" /> : <MenuIcon color="#fff" />}
    //   </button>
    //   <div className="search-notifications-profile-wrap">
    //     <div className="rest-wrap">
    //       {/* {noSearch ? null : <MainAppSearch />} */}
    //       {user?.expire === 0 ? (
    //         <ShiftStopwatch
    //           loadingSignOut={loadingSignOut}
    //           setLoadingSignOut={setLoadingSignOut}
    //         />
    //       ) : null}
    //       <div className="notifications-profile-wrap">
    //         {user?.role === userRoles?.manager && user?.expire === 0 ? (
    //           <Dropdown
    //             className="settings-dropdown"
    //             overlay={menu}
    //             trigger={['click']}
    //             arrow
    //             placement="bottomCenter"
    //             overlayClassName="settings-dropdown-wrap"
    //             onVisibleChange={() => {
    //               setSettingsClicked((prevState) => !prevState);
    //             }}
    //           >
    //             <div
    //               className={`settings-btn ${settingsClicked ? 'clicked' : ''}`}
    //             >
    //               <CogIcon color={settingsClicked ? '#fff' : '#000'} />
    //             </div>
    //           </Dropdown>
    //         ) : null}

    //         {/* <MainAppNotifications /> */}
    //         <MainAppProfileMenu
    //           loadingSignOut={loadingSignOut}
    //           setLoadingSignOut={setLoadingSignOut}
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default SearchNotificationsProfile;
