/* eslint-disable eqeqeq */
import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import FileSaver from 'file-saver';
import { EyeOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import deleteProductApi from '../../../apis/store-apis/products/deleteProductApi';
import productTaxTypes from '../../../productTaxTypes';

const saveFile = (url, title) => {
  FileSaver.saveAs(url, title);
};

const tableColumns = (
  allFetchedProducts,
  setModalOpened,
  setFetchCount,
  setSelectedProduct,
  setSelectedProductId,
  setIsLoading,
  setSelectedProductModalOpened,
  setBarCodeModalOpned,
  t,
  token
) => {
  const handleEditProduct = (record) => {
    const found = allFetchedProducts.find((place) => place.id === record.id);
    if (found) {
      setSelectedProduct({ ...found });
      setModalOpened(true);
    }
  };

  const productSaleValue = (product) => {
    if (product) {
      if (product?.taxType == productTaxTypes?.withTax)
        return product?.salePriceWithTax;
      else if (product?.taxType == productTaxTypes?.withoutTax)
        return product?.salePrice
          ? parseFloat(product?.salePrice).toFixed(2)
          : product.including_tax == 1 && parseFloat(product?.salePriceWithTax).toFixed(2);
    }
    return '---';
  };
  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteProductApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Inventory.Products.addedSuccessfully'),
          message: t('Inventory.Products.warehousesSuccessfullyDeleted')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Inventory.Products.errorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Inventory.Products.pleaseTryLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Inventory.Products.errorOccurred'),
        message: t('Inventory.Products.pleaseTryLater')
      });
    }
  };
  const hanldeSelectProduct = (record) => {
    setSelectedProductModalOpened(true);
    setSelectedProductId(record.id);
  };
  const handlePrintClick = (record) => {
    setSelectedProduct(record);
    setBarCodeModalOpned(true);
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap">
        {(record?.product_type == 1 ||
          record?.product_type == 2 ||
          record?.product_type == 3 ||
          record?.product_type == 4 ||
          record?.product_type == 6 ||
          record?.product_type == 7 ||
          record?.product_type == 5) && (
          <div
            className="edit-btn"
            onClick={(e) => {
              handleEditProduct(record);
            }}
          >
            <EditIcon />
            {t('Inventory.Products.edit')}
          </div>
        )}
        <Popconfirm
          title={t('Inventory.Products.areyouSureToDelete')}
          onConfirm={() => handleDeleteRequest(record.id)}
          okText={t('Inventory.Products.yes')}
          cancelText={t('Inventory.Products.no')}
        >
          <Tooltip title={t('Inventory.Products.delete')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      title: t('Inventory.Products.productCode'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.Products.productName'),
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-title desc-wrap">
            <span className="name-it-self">{record.name}</span>
          </div>
        );
      },
      sorter: (a, b) => {
        let nameA = a.name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      }
    },
    {
      title: t('Inventory.Products.purchasingPrice'),
      dataIndex: 'buyPrice',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.product_type != 2 ? record.buyPrice : '---'}
          </div>
        );
      },
      sorter: (a, b) => a.buyPrice - b.buyPrice
    },
    {
      title: t('Inventory.Products.sellingPrice'),
      dataIndex: 'salePrice',
      // width: 192,
      render: (_, record) => {
        // return <div className="row-cell row-text">{record.salePrice}</div>;
        return (
          <div className="row-cell row-text">{productSaleValue(record)}</div>
        );
      },
      sorter: (a, b) => a.salePrice - b.salePrice
    },
    {
      title: t('Inventory.Products.wholeSalePrice'),
      dataIndex: 'wholesalePrice',
      width: 102,
      render: (_, record) => {
        return <div className="row-cell row-text">{record.wholesalePrice}</div>;
      },
      // sorter: (a, b) => a.wholesalePrice - b.wholesalePrice
    },
    {
      title: t('Inventory.Products.quantity'),
      dataIndex: 'pQuantity',
      // width: 52,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.product_type != 2
              ? record.quantity
                ? record.quantity.toFixed(2)
                : 0
              : '---'}
          </div>
        );
      },
      sorter: (a, b) => a.quantity - b.quantity
    },
    {
      title: t('Inventory.Products.productImage'),
      dataIndex: 'image',
      // width: 180,
      render: (_, record) => {
        return (
          <div className="row-cell row-img">
            {record.image ? (
              <img
                onClick={() => saveFile(record.image, record.name)}
                src={record.image}
                alt={record.name ? record.name : 'product'}
              />
            ) : (
              '-----'
            )}
          </div>
        );
      }
    },
    {
      title: t('Inventory.Products.barcodePrinting'),
      render: (_, record) => {
        return (
          <div
            className="row-cell row-circled-btn"
            onClick={() => {
              handlePrintClick(record);
            }}
          >
            <Tooltip title={t('Inventory.Products.barcodePrinting')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<PrinterOutlined />}
              />
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: t('Inventory.Products.productPreview'),
      width: 30,
      render: (_, record) => {
        return (
          <div
            className="row-cell row-circled-btn"
            onClick={() => {
              hanldeSelectProduct(record);
            }}
          >
            <Tooltip title={t('Inventory.Products.productPreview')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
              />
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: t('Inventory.Products.settings'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
