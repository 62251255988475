/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import StoreConversionContext from '../../../contexts/store-conversion-context/StoreConversionProvider';
import createNewConversionApi from '../../../apis/store-apis/store-conversion-apis/createNewConversionApi';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import useIngredients from '../../../custom-hooks/useIngredients';
import useWarehouses from '../../../custom-hooks/useWarehouses';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdRadioGroup from '../../../common/antd-form-components/AntdRadioGroup';
import getWarehouseQuantityApi from '../../../apis/settings-apis/settings-warehouses-apis/getWarehouseQuantityApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import './StoreConversionPage.scss';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useTranslation } from 'react-i18next';

const TableForm = () => {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    type: Yup.string().required(
      t('Inventory.TransferStocks.chooseConversionType')
    ),
    product_id: Yup.string().test(
      'product_id',
      t('Inventory.TransferStocks.chooseProduct'),
      (v, context) => {
        let result = true;
        if (!v && context.parent.type == '1') result = false;
        return result;
      }
    ),
    ingredient_id: Yup.string().test(
      'ingredient_id',
      t('Inventory.TransferStocks.chooseProductIngredient'),
      (v, context) => {
        let result = true;
        if (!v && context.parent.type == '2') result = false;
        return result;
      }
    ),
    FromStock_id: Yup.string().required(
      t('Inventory.TransferStocks.chooseWarehouseYouWantTransferFrom')
    ),
    intoStock_id: Yup.string().required(
      t('Inventory.TransferStocks.chooseWarehouseYouWantTransferTo')
    ),
    quantity: Yup.string()
      .required(t('Inventory.TransferStocks.enterQuantity'))
      .matches(/^[0-9]+$/, t('Inventory.TransferStocks.onlyNumbersAllowed'))
  });
  const { user } = useContext(UserContext);
  // const { allProducts } = useProducts();
  const allIngredients = useIngredients();
  const allWarehouses = useWarehouses();
  const {
    selectedConversion,
    setIsLoading,
    setModalOpened,
    setFetchCount,
    isSubmittingForm,
    setIsSubmittingForm,
    allProducts
  } = useContext(StoreConversionContext);
  const [deafultQuantity, setDefaultQuantity] = React.useState('');
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      type: '1',
      product_id: '',
      ingredient_id: '',
      FromStock_id: '',
      intoStock_id: '',
      quantity: ''
    }
  });
  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    try {
      const mappedData = {};
      mappedData.type = data.type;
      if (data.type == '1') {
        mappedData.model_id = data.product_id;
      } else if (data.type == '2') {
        mappedData.model_id = data.ingredient_id;
      }
      mappedData.FromStock_id = data.FromStock_id;
      mappedData.intoStock_id = data.intoStock_id;
      mappedData.quantity = data.quantity;
      setIsSubmittingForm(true);
      setIsLoading(true);
      if (selectedConversion) {
        customApiRequest(
          createNewConversionApi(
            user?.token,
            {
              ...mappedData,
              id: selectedConversion?.id
            },
            true
          ),
          (res) => {
            setIsSubmittingForm(false);
            setIsLoading(false);
            if (res?.status === 200 && res?.data?.data) {
              setModalOpened(false);
              setFetchCount((prevState) => prevState + 1);
              successNotification({
                title: t('Inventory.TransferStocks.addedSuccessfully'),
                message: t(
                  'Inventory.TransferStocks.conversionModifiedSuccessfully'
                )
              });
            } else {
              errorNotification({
                title: t('Inventory.TransferStocks.errorOccurred'),
                message: res?.data?.message
                  ? res.data.message
                  : t('Inventory.TransferStocks.pleaseTryLater')
              });
            }
          },
          (error) => {
            setIsSubmittingForm(false);
            setIsLoading(false);
            errorNotification({
              title: t('Inventory.TransferStocks.errorOccurred'),
              message:
                error?.response?.data?.message ||
                t('Inventory.TransferStocks.pleaseTryLater')
            });
          }
        );
      } else {
        customApiRequest(
          createNewConversionApi(user?.token, mappedData),
          (res) => {
            setIsSubmittingForm(false);
            setIsLoading(false);
            if (res?.status === 200 && res?.data?.data) {
              setModalOpened(false);
              setFetchCount((prevState) => prevState + 1);
              successNotification({
                title: t('Inventory.TransferStocks.addedSuccessfully'),
                message: t(
                  'Inventory.TransferStocks.conversionCompletedSuccessfully'
                )
              });
            } else {
              errorNotification({
                title: t('Inventory.TransferStocks.errorOccurred'),
                message: res?.data?.message
                  ? res.data.message
                  : t('Inventory.TransferStocks.pleaseTryLater')
              });
            }
          },
          (error) => {
            setIsSubmittingForm(false);
            setIsLoading(false);
            errorNotification({
              title: t('Inventory.TransferStocks.errorOccurred'),
              message:
                error?.response?.data?.message ||
                t('Inventory.TransferStocks.pleaseTryLater')
            });
          }
        );
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Inventory.TransferStocks.errorOccurred'),
        message: t('Inventory.TransferStocks.pleaseTryLater')
      });
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedConversion) {
      if (selectedConversion.type) {
        setValue('type', String(selectedConversion.type));
      }

      if (
        selectedConversion.type &&
        selectedConversion.type == '1' &&
        selectedConversion.model_id
      ) {
        setValue('product_id', String(selectedConversion.model_id));
      }
      if (
        selectedConversion.type &&
        selectedConversion.type == '2' &&
        selectedConversion.model_id
      ) {
        setValue('ingredient_id', String(selectedConversion.model_id));
      }
      if (selectedConversion.fromStock?.id) {
        setValue('FromStock_id', String(selectedConversion.fromStock.id));
      }
      if (selectedConversion.intoStock?.id) {
        setValue('intoStock_id', String(selectedConversion.intoStock.id));
      }
      if (selectedConversion.quantity >= 0) {
        setValue('quantity', selectedConversion.quantity);
      }
    }
  }, [selectedConversion, selectedConversion?.id]);

  const [form] = Form.useForm();

  return (
    <Form
      fields={[
        {
          name: 'type',
          value: watch('type')
        }
      ]}
      className="store-converstion-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <AntdRadioGroup
          name="type"
          className="form-radio-group"
          control={control}
          label={t('Inventory.TransferStocks.chooseConversionType')}
          validateStatus={errors?.type ? 'error' : ''}
          errorMsg={errors?.type?.message}
          radios={[
            {
              title: t('Inventory.TransferStocks.products'),
              value: '1'
            }
            // {
            //   title: 'مكون منتج',
            //   value: '2'
            // }
          ]}
          // isRadioButton
        />

        {watch('type') == '1' && (
          <div className="select-label-wrap">
            <p className="label-p">
              {t('Inventory.TransferStocks.chooseProduct')}
            </p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name="product_id"
                errorMsg={errors?.product_id && errors.product_id.message}
                validateStatus={errors?.product_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder={t('Inventory.TransferStocks.chooseProduct')}
                options={
                  allProducts?.length > 0 &&
                  allProducts.map((p) => ({
                    title: `${p?.name} ( ${t(
                      'Inventory.TransferStocks.quantity'
                    )} : ${p?.quantity} ) `,
                    value: String(p.id)
                  }))
                }
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="store-converstion-form"
              />
            </div>
          </div>
        )}
        {watch('type') == '2' && (
          <div className="select-label-wrap">
            <p className="label-p">
              {t('Inventory.TransferStocks.chooseProductIngredient')}
            </p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name="ingredient_id"
                errorMsg={errors?.ingredient_id && errors.ingredient_id.message}
                validateStatus={errors?.ingredient_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder={t(
                  'Inventory.TransferStocks.chooseProductIngredient'
                )}
                options={
                  allIngredients?.length > 0 &&
                  allIngredients.map((p) => ({
                    title: p.name,
                    value: String(p.id)
                  }))
                }
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="store-converstion-form"
              />
            </div>
          </div>
        )}

        <div className="from-to-warehouse-wrap">
          <div className="select-label-wrap">
            <p className="label-p">
              {t('Inventory.TransferStocks.chooseWarehouseYouWantTransferFrom')}
            </p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                onChange={async () => {
                  if (
                    watch('type') &&
                    (watch('product_id') || watch('ingredient_id')) &&
                    watch('FromStock_id')
                  ) {
                    try {
                      const res = await getWarehouseQuantityApi(
                        watch('FromStock_id'),
                        watch('type') == '1'
                          ? watch('product_id')
                          : watch('ingredient_id'),
                        watch('type'),
                        user?.token
                      );
                      // is response is success
                      if (res?.status === 200 && res?.data?.data) {
                        if (res?.data?.data) {
                          const data = res.data.data;
                          if (data?.quantity >= 0) {
                            // setValue('quantity', data.quantity);
                            setDefaultQuantity(data.quantity);
                          }
                        }
                      } else {
                        setDefaultQuantity('');
                      }
                    } catch (error) {}
                  }
                }}
                name="FromStock_id"
                errorMsg={errors?.FromStock_id && errors.FromStock_id.message}
                validateStatus={errors?.FromStock_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                options={
                  allWarehouses?.length > 0 &&
                  allWarehouses.map((p) => ({
                    title: p.name,
                    value: String(p.id)
                  }))
                }
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="store-converstion-form"
              />
            </div>
          </div>
          <div className="select-label-wrap">
            <p className="label-p">
              {t('Inventory.TransferStocks.chooseWarehouseYouWantTransferTo')}
            </p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name="intoStock_id"
                errorMsg={errors?.intoStock_id && errors.intoStock_id.message}
                validateStatus={errors?.intoStock_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                options={
                  allWarehouses?.length > 0 &&
                  allWarehouses.map((p) => ({
                    title: p.name,
                    value: String(p.id)
                  }))
                }
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="store-converstion-form"
              />
            </div>
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">
            {t('Inventory.TransferStocks.quantityWantTransferred')}
            {deafultQuantity ? (
              <span>
                {' '}
                ( {t('Inventory.TransferStocks.maxQuantity')} :{' '}
                {deafultQuantity} )
              </span>
            ) : null}
          </p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="quantity"
              type="text"
              placeholder={t(
                'Inventory.TransferStocks.quantityTransferredPlaceholder'
              )}
              errorMsg={errors?.quantity?.message}
              validateStatus={errors?.quantity ? 'error' : ''}
              setValue={setValue}
              control={control}
              // defaultValue={deafultQuantity}
            />
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {t('Inventory.TransferStocks.add')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
