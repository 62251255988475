/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Table, Empty } from 'antd';
import AccountingDetailsContext from '../../../contexts/accounting-context/AccountingDetailsContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import TableColumns from './TableColumns';
import JournalsContext from '../../../contexts/accounting-context/JournalsContext';
import SummaryContext from '../../../contexts/accounting-context/SummaryContext';
import { useTranslation } from 'react-i18next';

const SummaryDetailsTable = ({ type }) => {
  const { user } = useContext(UserContext);
  const {
    allSummary,
    fetchCount,
    setIsLoading,
    setFetchCount,
    setModalOpened,
    setSelectedSummary,
    isLoading,
    setModalOpenedUpdate
  } = useContext(SummaryContext);
  const { t } = useTranslation();

  // map all data to dataSource
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: index + 1,
      name: obj?.name ? obj.name : '',
      description: obj?.name ? obj.name : '',
      title: obj?.title ? obj.title : '',
      the_amount: obj?.balance
        ? obj.balance
        : obj.initial_balance
        ? obj.initial_balance
        : ''
    }));
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setTableDataSource(allSummary && allSummary);
    }
    return () => {
      isMounted = false;
    };
  }, [fetchCount, allSummary]);

  return (
    <>
      <Table
        locale={{
          emptyText: (
            <Empty description={false}>{t('Messages.noDataAvailable')}</Empty>
          )
        }}
        // pagination={{ defaultPageSize: 10, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        // onRow={(record) => record?.level === 1 ? 'table-row-light' :  'table-row-dark'}
        dataSource={tableDataSource}
        columns={TableColumns(
          allSummary,
          setModalOpened,
          setModalOpenedUpdate,
          setFetchCount,
          setSelectedSummary,
          setIsLoading,
          user?.token,
          type
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
    </>
  );
};

export default SummaryDetailsTable;
