/* eslint-disable eqeqeq */
import { Descriptions, Empty, Table } from 'antd';
import QRCode from 'qrcode.react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import WaterMark from '../../../common/water-mark/WaterMark';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import fatoorahViewTypes from '../../../fatoorahViewTypes';

const ReceiptDetailsComponent = React.forwardRef((props, ref) => {
  const { selectedReceipt } = props;
  const systemSettings = useSystemSettings();
  const renderDiscountValue = (record) => {
    if (record?.discountType == '1') {
      // fixed discount price
      return record.discountPrice;
    } else if (record?.discountType == '2') {
      // percentage discount
      return `${record.discountPrice}`;
    } else {
      // no discount
      return t('SalesInvoices.WithoutDiscount');
    }
  };
  const { t } = useTranslation();

  const renderTitle = (invoice_type) => {
    if (invoice_type === 1) {
      if (systemSettings?.fatoraViewType == fatoorahViewTypes?.renter?.value)
        return fatoorahViewTypes?.renter?.mo2ger;
      else if (
        systemSettings?.fatoraViewType == fatoorahViewTypes?.sellerBuyer?.value
      )
        return fatoorahViewTypes?.sellerBuyer?.seller;
      else if (
        systemSettings?.fatoraViewType ==
        fatoorahViewTypes?.senderReceiver?.value
      )
        return fatoorahViewTypes?.senderReceiver?.sender;
    } else if (invoice_type === 2) {
      if (systemSettings?.fatoraViewType == fatoorahViewTypes?.renter?.value)
        return fatoorahViewTypes?.renter?.mst2ger;
      else if (
        systemSettings?.fatoraViewType == fatoorahViewTypes?.sellerBuyer?.value
      )
        return fatoorahViewTypes?.sellerBuyer?.buyer;
      else if (
        systemSettings?.fatoraViewType ==
        fatoorahViewTypes?.senderReceiver?.value
      )
        return fatoorahViewTypes?.senderReceiver?.receiver;
    }
  };

  const renderUser = (obj, type) => {
    const {
      name,
      phone,
      address,
      addressCode,
      VATRegistrationNumber,
      tax_number,
      taxNumber,
      commercialRecord
    } = obj;
    return (
      <div className="supplier-details-wrap">
        {/* <div
          style={{
            marginBottom: 2,
            fontWeight: 'bold'
          }}
        >
          {renderTitle(type)}
        </div> */}
        <Descriptions title={renderTitle(type)} bordered column={1}>
          {name && (
            <Descriptions.Item label={t('Quotations.Name')}>
              {name}
            </Descriptions.Item>
          )}
          {phone && (
            <Descriptions.Item label={t('Quotations.PhoneNumber')}>
              {phone}
            </Descriptions.Item>
          )}
          {address && (
            <Descriptions.Item label={t('Quotations.Address')}>
              {address}
            </Descriptions.Item>
          )}
          {!address && addressCode && (
            <Descriptions.Item label={t('Quotations.Address')}>
              {addressCode}
            </Descriptions.Item>
          )}
          {/* {commercialRecord && (
            <Descriptions.Item label="رقم السجل التجاري">
              {commercialRecord}
            </Descriptions.Item>
          )} */}
          {VATRegistrationNumber && (
            <Descriptions.Item label={t('Quotations.VATNumber')}>
              {VATRegistrationNumber}
            </Descriptions.Item>
          )}
          {tax_number && (
            <Descriptions.Item label={t('Quotations.VATNumber')}>
              {tax_number}
            </Descriptions.Item>
          )}
          {taxNumber && (
            <Descriptions.Item label={t('Quotations.VATNumber')}>
              {taxNumber}
            </Descriptions.Item>
          )}
          {commercialRecord && (
            <Descriptions.Item
              label={t('Quotations.CommercialRegistrationNumber')}
            >
              {commercialRecord}
            </Descriptions.Item>
          )}
          {/* {buildingNum && (
            <Descriptions.Item label="رقـــم المبنى">
              {buildingNum}
            </Descriptions.Item>
          )}
          {streetName && (
            <Descriptions.Item label="اســم الشارع">
              {streetName}
            </Descriptions.Item>
          )}
          {district && (
            <Descriptions.Item label="الحــى">{district}</Descriptions.Item>
          )}
          {city && (
            <Descriptions.Item label="المدينة">{city}</Descriptions.Item>
          )}
          {country && (
            <Descriptions.Item label="البـلد">{country}</Descriptions.Item>
          )}
          {postalCode && (
            <Descriptions.Item label="الرمز البريدى">
              {postalCode}
            </Descriptions.Item>
          )}
          {addressCode && (
            <Descriptions.Item label="الرقم الاضافى للعنوان">
              {addressCode}
            </Descriptions.Item>
          )}
          {VATRegistrationNumber && (
            <Descriptions.Item label={ t('Quotations.VATNumber')}>
              {VATRegistrationNumber}
            </Descriptions.Item>
          )}
          {taxNumber && (
            <Descriptions.Item label={ t('Quotations.VATNumber')}>
              {taxNumber}
            </Descriptions.Item>
          )}
          {tax_number && (
            <Descriptions.Item label={ t('Quotations.VATNumber')}>
              {tax_number}
            </Descriptions.Item>
          )}
          {anotherId && (
            <Descriptions.Item label="معرف أخر">{anotherId}</Descriptions.Item>
          )} */}
        </Descriptions>
      </div>
    );
  };

  const renderModels = () => {
    const obj = {
      ...selectedReceipt?.products,
      name: selectedReceipt?.products[0]?.name
    };
    const hasDiscounts = selectedReceipt.products.some(
      (product) => product.discountPrice && +product.discountPrice > 0
    );
    const products = [obj];
    if (selectedReceipt?.products?.length > 0) {
      return (
        <div className="models-wrap pagebreak">
          <div
            className="models-title bold-font"
            style={{
              marginBottom: 2,
              fontWeight: 'bold'
            }}
          >
            {t('Quotations.ServiceDescription')}:
          </div>
          <div className="receiptDetails-wrapper">
            <Table
              bordered
              locale={{
                emptyText: (
                  <Empty description={false}>
                    {t('Messages.noDataAvailable')}
                  </Empty>
                )
              }}
              pagination={false}
              // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
              // className="antd-custom-table"
              dataSource={selectedReceipt?.products?.map((item, index) => ({
                ...item,
                key: item.id,
                index,
                hidden: +item?.tax
              }))}
              columns={[
                {
                  title: t('Quotations.M'),
                  dataIndex: 'id',
                  render: (_, record) => {
                    return (
                      <div className="row-cell row-index bold-font">
                        <span>{record.index + 1}</span>
                        {/* <span># {record.id}</span> */}
                      </div>
                    );
                  }
                },
                {
                  title: t('Quotations.Name'),

                  dataIndex: 'name',
                  width: 150,
                  render: (_, record) => {
                    const recordText = String(
                      record?.name ||
                        record?.product?.name ||
                        record[record.index]?.product?.name
                    );
                    // const wordsCount = countWords(String(record?.name || record?.product?.name || record[record.index]?.product?.name));
                    // if ((record?.name || record?.product?.name || record[record.index]?.product?.name) && wordsCount > 0) {
                    //   if (wordsCount > 3) {
                    //     return (
                    //       <Popover title="اقرأ المزيد" content={record?.name || record?.product?.name || record[record.index]?.product?.name}>
                    //         <Button size="small" type="text">
                    //           <p className="has-read-more">
                    //             <span>{trimWords(recordText, 4)}</span>
                    //             <ReadOutlined
                    //               className="read-more-book"
                    //               style={{ margin: '0 4px' }}
                    //             />
                    //           </p>
                    //         </Button>
                    //       </Popover>
                    //     );
                    //   } else
                    //     return (
                    //       <div className="row-cell row-text">{recordText}</div>
                    //     );
                    // }
                    return (
                      <div className="row-cell row-text desc-wrap">
                        <span>
                          {record.name ||
                            record?.product?.name ||
                            record[record.index]?.product?.name}
                        </span>
                      </div>
                    );
                  }
                },
                {
                  title: t('Quotations.description'),
                  dataIndex: 'desc',
                  render: (_, record) => {
                    const recordText = String(record?.desc);
                    // const wordsCount = countWords(String(record.desc));
                    // if (record.desc && wordsCount > 0) {
                    //   if (wordsCount > 8) {
                    //     return (
                    //       <Popover title="اقرأ المزيد" content={record.desc}>
                    //         <Button size="small" type="text">
                    //           <p className="has-read-more">
                    //             <span>{trimWords(recordText, 4)}</span>
                    //             <ReadOutlined
                    //               className="read-more-book"
                    //               style={{ margin: '0 4px' }}
                    //             />
                    //           </p>
                    //         </Button>
                    //       </Popover>
                    //     );
                    //   } else
                    //     return (
                    //       <div className="row-cell row-text">{recordText}</div>
                    //     );
                    // }

                    if (record?.desc) {
                      return (
                        <div className="row-cell row-text desc-wrap">
                          {recordText}
                        </div>
                      );
                    } else {
                      return '----';
                    }
                  }
                },
                {
                  title: t('Quotations.quantity'),

                  dataIndex: 'quantity',
                  render: (_, record) => {
                    return (
                      <div className="row-cell row-text">
                        <span>
                          {selectedReceipt?.type == 2 &&
                            (record?.quantity ||
                              record[record.index]?.quantity) > 0 &&
                            '-'}
                          {record?.quantity || record[record.index]?.quantity}
                        </span>
                      </div>
                    );
                  }
                },
                // {
                //   title: 'المستودع',
                //   dataIndex: 'warehouse',
                //   render: (_, record) => {
                //     return (
                //       <div className="row-cell row-text">
                //         <span>{record?.stock?.name}</span>
                //       </div>
                //     );
                //   }
                // },
                {
                  title: t('Quotations.UnitPrice'),

                  dataIndex: 'price',
                  render: (_, record) => {
                    return (
                      <div className="row-cell row-text">
                        <span>
                          {record?.price || record[record.index]?.price}
                        </span>
                      </div>
                    );
                  }
                },
                // {
                //   title: 'السعر',
                //   dataIndex: 'priceBeforeDiscount',
                //   render: (_, record) => {
                //     return (
                //       <div className="row-cell row-text">
                //         <span>{record.priceBeforeDiscount}</span>
                //       </div>
                //     );
                //   }
                // },
                // {
                //   title: 'الوصف',
                //   dataIndex: 'priceBeforeDiscount',
                //   render: (_, record) => {
                //     return (
                //       <div className="row-cell row-text">
                //         <span>{record.desc}</span>
                //       </div>
                //     );
                //   }
                // },
                ...(hasDiscounts
                  ? [
                      {
                        title: t('Quotations.rebate'),
                        dataIndex: 'discount',
                        render: (_, record) => (
                          <div className="row-cell row-text">
                            {renderDiscountValue(record)}
                          </div>
                        )
                      }
                    ]
                  : []),
                // {
                //   title: 'سعر الخصم',
                //   dataIndex: 'discount',
                //   render: (_, record) => {
                //     return (
                //       <div className="row-cell row-text">
                //         <span>{record.discountPrice}</span>
                //       </div>
                //     );
                //   }
                // },
                {
                  title: t('Quotations.Tax'),

                  dataIndex: 'tax',
                  render: (_, record) => {
                    return +record?.tax ? (
                      <div className="row-cell row-text">
                        <span>
                          {record?.tax || record[record.index]?.tax} %
                        </span>
                      </div>
                    ) : (
                      '----'
                    );
                  }
                },
                {
                  title: t('Quotations.TaxRate'),

                  dataIndex: 'totalTax',
                  render: (_, record) => {
                    return +record?.totalTax ? (
                      <div className="row-cell row-text">
                        <span>
                          {selectedReceipt?.type == 2 &&
                            (record?.totalTax ||
                              record[record.index].totalTax) > 0 &&
                            '-'}
                          {record?.totalTax || record[record.index].totalTax}
                        </span>
                      </div>
                    ) : (
                      '----'
                    );
                  }
                },
                {
                  title: t('Quotations.Total'),

                  dataIndex: 'discount',
                  render: (_, record) => {
                    return (
                      <div className="row-cell row-text">
                        <span>
                          {selectedReceipt?.type == 2 &&
                            (record?.totalPrice ||
                              record[record.index].totalPrice) > 0 &&
                            '-'}
                          {record?.totalPrice ||
                            record[record.index].totalPrice}
                        </span>
                      </div>
                    );
                  }
                }
              ]}
              // loading={isLoading}
              // scroll={{ x: 400 }}
              footer={false}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  const renderExtraPrices = () => {
    const addPrices = selectedReceipt.addPrices;
    if (addPrices?.length > 0) {
      return (
        <div className="models-wrap">
          <div
            className="models-title bold-font"
            style={{
              marginBottom: 2,
              fontWeight: 'bold'
            }}
          >
            {t('Quotations.AdditionalPrices')}:{' '}
          </div>
          <Table
            className="invoice-extra-prices-table"
            bordered
            showHeader={false}
            locale={{
              emptyText: (
                <Empty description={false}>
                  {t('Messages.noDataAvailable')}
                </Empty>
              )
            }}
            pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            // className="antd-custom-table"
            dataSource={addPrices.map((item) => ({
              ...item,
              key: item.id
            }))}
            columns={[
              // {
              //   title: 'الكود',
              //   dataIndex: 'id',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-index bold-font">
              //         <span># {record.id}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: t('Quotations.description'),

                dataIndex: 'desc',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text extra-prices-cell desc-wrap">
                      <span>{record.desc}</span>
                    </div>
                  );
                }
              },
              {
                title: t('Quotations.Price'),

                dataIndex: 'price',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text extra-prices-cell">
                      <span>
                        {selectedReceipt?.type == 2 && record.price > 0 && '-'}
                        {record.price}
                      </span>
                    </div>
                  );
                }
              }
            ]}
            // loading={isLoading}
            // scroll={{ x: 400 }}
            footer={false}
          />
        </div>
      );
    }
    return null;
  };

  const renderInovicePrices = () => {
    const {
      productPrice,
      discountType,
      discountValue,
      discountPrice,
      additionPrice,
      totalBeforeTax,
      taxPrice,
      totalPrice,
      paymentType,
      dueDate,
      visa,
      cash,
      invoice_due_date,
      rest,
      first_paid,
      payments,
      type
    } = selectedReceipt;
    return (
      <div className="supplier-details-wrap">
        {/*    <div
          style={{
            marginBottom: 2,
            fontWeight: 'bold'
          }}
        >
          {t('Quotations.TotalAmounts')}:
        </div> */}
        <Descriptions title={t('Quotations.TotalAmounts')} bordered column={1}>
          {productPrice && (
            <Descriptions.Item
              label={t('Quotations.TotalUnitPriceAfterDiscount')}
            >
              {type == 2 && productPrice > 0 && '-'}
              {productPrice}
            </Descriptions.Item>
          )}
          {discountPrice && (
            <Descriptions.Item label={t('Quotations.rebate')}>
              {/* {renderDiscountValue({
                discountType,
                discountValue
              })} */}
              {discountPrice}
            </Descriptions.Item>
          )}
          {/* {discountPrice && (
            <Descriptions.Item label="سعر الخصم">
              {discountPrice}
            </Descriptions.Item>
          )} */}
          {additionPrice && (
            <Descriptions.Item label={t('Quotations.AdditionalPrices')}>
              {type == 2 && additionPrice > 0 && '-'}
              {additionPrice}
            </Descriptions.Item>
          )}
          {totalBeforeTax && (
            <Descriptions.Item label={t('Quotations.TotalPriceBeforeVATAdded')}>
              {type == 2 && totalBeforeTax > 0 && '-'}
              {totalBeforeTax}
            </Descriptions.Item>
          )}
          {+taxPrice && (
            <Descriptions.Item label={t('Quotations.TaxRate')}>
              {type == 2 && taxPrice > 0 && '-'}
              {taxPrice}
            </Descriptions.Item>
          )}
          {totalPrice && (
            <Descriptions.Item
              style={{
                fontSize: '18px',
                color: '#000',
                fontWeight: 'bold'
              }}
              label={t('Quotations.TotalPriceInclusiveOfTax')}
            >
              {type == 2 && totalPrice > 0 && '-'}
              {totalPrice}
            </Descriptions.Item>
          )}
          {totalPrice && (
            <Descriptions.Item
              style={{
                fontSize: '18px',
                color: '#000',
                fontWeight: 'bold'
              }}
              label={t('Invoices.PaymentMethod')}
            >
              {paymentType == 1 && t('Invoices.cash')}
              {paymentType == 2 && t('Invoices.postpaid')}
              {paymentType == 3 && t('Invoices.visa')}
              {paymentType == 5 && t('Invoices.bankTransfer')}
              {paymentType == 6 && t('Invoices.multiplePayment')}
            </Descriptions.Item>
          )}
          {paymentType == 2 && (
            <Descriptions.Item label={t('Quotations.dateOfPayment')}>
              {invoice_due_date}
            </Descriptions.Item>
          )}
          {paymentType == 2 && (
            <>
              {payments?.length &&
                payments?.map((pay, index) => (
                  <Descriptions.Item label={`الدفعه رقم ${index + 1}`}>
                    {pay?.amount}
                  </Descriptions.Item>
                ))}
            </>
          )}
          {paymentType == 2 && (
            <Descriptions.Item label={t('Quotations.residual')}>
              {rest}
            </Descriptions.Item>
          )}
          {paymentType == 6 && (
            <Descriptions.Item label={t('Quotations.cashAmountValue')}>
              {cash}
            </Descriptions.Item>
          )}
          {paymentType == 6 && (
            <Descriptions.Item label={t('Quotations.visaAmountValue')}>
              {visa}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  };

  const renderInvoiceHeading = () => {
    if (selectedReceipt?.type == 2) {
      return <p>{t('purchaseInvoices.returnedInvoice')}</p>;
    } else if (selectedReceipt?.type == 1) {
      if (props?.isIncomingInvoice) {
        return t('Quotations.taxInvoice');
      } else {
        if (
          selectedReceipt?.user?.taxNumber &&
          selectedReceipt?.user
          // selectedReceipt?.userOrSupplier == 2
        ) {
          return t('Quotations.TaxSaleInvoice');
        } else if (
          selectedReceipt?.user
          // selectedReceipt?.userOrSupplier == 1
        ) {
          return t('Quotations.Simplifiedbillofsale');
        }
        return t('Quotations.fiedbillofsale');
      }
    }
  };
  return (
    <div
      className="invoice-details-wrap"
      ref={ref}
      style={{
        padding: '28px 28px',
        // direction: 'rtl',
        display: 'grid',
        gap: '16px',
        width: '100%'
      }}
    >
      {systemSettings?.package_id == 1 && (
        <WaterMark text={t('Quotations.freeTrial')} />
      )}
      <div
        className="inovice-nums-qrcode"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          gap: '22px',
          alignItems: 'center',
          direction: 'rtl'
        }}
      >
        <div
          className="qr-code-logo-wrap"
          style={{
            display: 'flex',
            gap: '22px',
            alignItems: 'center',
            direction: 'rtl',
            justifyContent: 'space-between'
            // gridTemplateColumns: 'auto 1fr auto'
          }}
        >
          <div
            className="img-wrap"
            style={{
              display: 'grid',
              placeItems: 'center',
              minWidth: '92px'
              // minHeight: '142px'
            }}
          >
            {selectedReceipt?.setting?.image ? (
              <img
                className="system-logo"
                src={selectedReceipt?.setting.image}
                alt="logo"
                style={{
                  // width: '92px',
                  height: '60px'
                }}
              />
            ) : null}
          </div>
          <div
            style={{
              fontSize: 20,
              color: '#000',
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 12
            }}
          >
            {<span>{renderInvoiceHeading()}</span>}
          </div>
          <div
            style={{
              visibility: 'hidden'
            }}
          >
            -
          </div>
        </div>
      </div>
      <div className="supp-buyer-wrap" style={{ width: '100%' }}>
        {selectedReceipt?.setting && (
          <div
            className="render-user"
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: 2,
              width: '100%'
            }}
          >
            <div>{renderUser(selectedReceipt?.setting, 1)}</div>
            {/* <span></span> */}

            <div
              className="qr-code-wrap"
              style={{
                justifySelf: 'end',
                display: 'grid',
                gridTemplateRows: 'auto 1fr'
              }}
            >
              <div
                style={{
                  marginBottom: 2,
                  opacity: 0,
                  visibility: 'hidden'
                }}
              >
                --
              </div>
              <QRCode
                value={
                  selectedReceipt?.qrString ? selectedReceipt.qrString : ''
                }
                width={112}
                height={112}
                renderAs="svg"
              />
              {/* <QRCode
              value={
                selectedReceipt?.encryptId
                  ? `${
                      process.env.REACT_APP_FATOORAH_URL
                    }/outgoing-invoice-details/${String(
                      selectedReceipt.encryptId
                    )}`
                  : ''
              }
            /> */}
            </div>
          </div>
        )}
      </div>
      <div
        className="render-user"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: 2
        }}
      >
        {selectedReceipt?.user && renderUser(selectedReceipt?.user, 2)}
        {selectedReceipt?.supplier && renderUser(selectedReceipt?.supplier, 2)}
        <div>
          {/* <div
            style={{
              marginBottom: 2,
              opacity: 0,
              visibility: 'hidden'
            }}
          >
            {'--'}
          </div> */}
          <Descriptions title="" bordered column={1}>
            {selectedReceipt?.parentInvoiceNumber && (
              <Descriptions.Item label={t('Quotations.Returnedinvoicenumber')}>
                {selectedReceipt.parentInvoiceNumber}
              </Descriptions.Item>
            )}
            {selectedReceipt?.invoiceNumber && (
              <Descriptions.Item label={t('Quotations.InvoiceNumber')}>
                {selectedReceipt.invoiceNumber}
              </Descriptions.Item>
            )}
            {selectedReceipt?.date && (
              <Descriptions.Item label={t('Quotations.InvoiceIssueDate')}>
                {selectedReceipt.date}
              </Descriptions.Item>
            )}
            {selectedReceipt?.dueDate &&
              selectedReceipt?.setting?.subscriptionType == 2 && (
                <Descriptions.Item
                  label={t('Quotations.Dateofterminationofservice')}
                >
                  {selectedReceipt.dueDate}
                </Descriptions.Item>
              )}
            {selectedReceipt?.dueDate &&
              selectedReceipt?.setting?.subscriptionType == 1 && (
                <Descriptions.Item label={t('Quotations.Supplydate')}>
                  {selectedReceipt.dueDate}
                </Descriptions.Item>
              )}
            <Descriptions.Item label={t('Quotations.billingTime')}>
              {selectedReceipt?.time ? selectedReceipt.time : '---'}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
      {renderModels()}
      {renderExtraPrices()}
      {renderInovicePrices()}

      {selectedReceipt?.note && (
        <>
          <h3 className="note-modal-box">{t('Quotations.Notes')}</h3>
          <div className="note">
            <p>{selectedReceipt?.note}</p>
          </div>
        </>
      )}

      <div>
        <Descriptions
          title=""
          bordered
          column={1}
          labelStyle={{ fontWeight: 'bold', width: '150px' }}
        >
          {selectedReceipt?.setting?.invoiceSetting?.terms && (
            <Descriptions.Item label={t('SystemSettings.termsAndConditions')}>
              {selectedReceipt?.setting?.invoiceSetting?.terms_text
                ?.toString()
                .split('\r\n')
                .map((item) => (
                  <p>{item}</p>
                ))}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>

      <div
        style={{
          textAlign: 'center',
          padding: '10px'
        }}
      >
        {selectedReceipt?.setting?.slogan ? selectedReceipt.setting.slogan : ''}
      </div>
    </div>
  );
});

export default ReceiptDetailsComponent;
