import React, { useContext } from 'react';
import SearchIcon from '../../../common/icons/SearchIcon';
import './FirstTermStockTableFilter.scss';
import StoreNewProductModalContext from '../../../contexts/store-new-product-context/StoreNewProductProvider';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import searchAllProducts from '../../../apis/store-apis/products/searchAllProducts';
import UserContext from '../../../contexts/user-context/UserProvider';
import StoreNewProductModalForProducts from '../../../components/store-new-product-modal-for-products/StoreNewProductModalForProducts';
import SelectDropDown from '../../../common/icons/SelectDropDown';
import { Form, Select } from 'antd';
import './ExcelModal.scss';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import { useForm } from 'react-hook-form';
import useMainCategories from '../../../custom-hooks/useMainCategories';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useWarehouses from '../../../custom-hooks/useWarehouses';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import SettingsWorkPlacesContext from '../../../contexts/settings-contexts/SettingsWorkPlacesProvider';
import getAllWorkPlacesApi from '../../../apis/settings-apis/work-places-apis/getAllWokrPlacesApi';
import getAllProducts from '../../../apis/store-apis/products/getAllProducts';
import useWorkPlaces from '../../../custom-hooks/useWorkPlaces';
import getProductsWithFirstTerm from '../../../apis/store-apis/products/getProductsWithFirstTerm';
import excelImg from '../../../assets/imgs/icons/excel-btn.png';
import { useCallback } from 'react';
import debounce from 'lodash.debounce';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const FirstTermStockTableFilter = () => {
  const { t } = useTranslation();
  const mainCategories = useMainCategories();
  const allWarehouses = useWarehouses();
  const allWorkPlaces = useWorkPlaces();
  const { Option } = Select;
  // const { RangePicker } = DatePicker;
  const systemSettings = useSystemSettings();
  const [excelModalOpened, setExcelModalOpened] = React.useState(false);
  const { user, filterValues, setFilterValues } = useContext(UserContext);

  const {
    modalOpened,
    setModalOpened,
    setSelectedProduct,
    setSelectedProductId,
    setNameSearchValue,
    nameSearchValue,
    tablePagination,
    setIsLoading,
    isLoading,
    setTablePagination,
    setAllFetchedProducts,
    tableFilter,
    setTableFilter
  } = useContext(StoreNewProductModalContext);

  async function handleChange(filterType, value) {
    setTableFilter((prevState) => {
      if (filterType === 'searchText') {
        return {
          ...prevState,
          searchText: value.target.value
        };
      } else if (filterType === 'work_place') {
        return {
          ...prevState,
          work_place: value
        };
      } else if (filterType === 'stock_id') {
        return {
          ...prevState,
          stock_id: value
        };
      } else if (filterType === 'parent_cat') {
        return {
          ...prevState,
          parent_cat: value
        };
      }
    });
  }

  useEffect(() => {
    if (isLoading) {
    }
  }, [isLoading]);

  const handleTableSearch = async (e) => {
    setNameSearchValue(e.target.value);
    try {
      setIsLoading(true);
      const res = await searchAllProducts(user?.token, {
        ...tableFilter,
        page: 1,
        searchText: nameSearchValue
      });
      if (res?.status === 200 && res?.data?.data?.data) {
        setIsLoading(false);
        setFilterValues({ ...filterValues, searchText: nameSearchValue });
        if (res.data.data.data.length >= 0) {
          const data = res.data.data.data;
          setAllFetchedProducts(data);
        }
        if (res?.data?.data?.pagination) {
          setTablePagination(res?.data?.data?.pagination);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const debouncedChangeHandler = useCallback(
    debounce(async (filterType, value) => {
      handleChange(filterType, value);
    }, 1000),
    []
  );

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    customApiRequest(
      getProductsWithFirstTerm(user?.token, {
        ...tableFilter,
        page: 1
      },
      1),
      (res) => {
        setIsLoading(false);
        if (res.data.data.data.length >= 0) {
          setIsLoading(false);
          const data = res.data.data.data;
          setAllFetchedProducts(data);
        }
        if (res?.data?.data?.pagination) {
          setTablePagination(res?.data?.data?.pagination);
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };

  // const schema = Yup.object().shape({
  //   product_category: Yup.string().required('ادخل اسم المكون')
  // });

  // const {
  //   control,
  //   setValue,
  //   watch,
  //   handleSubmit,
  //   formState: { errors }
  // } = useForm({
  //   resolver: yupResolver(schema),
  //   mode: 'all',
  //   defaultValues: {
  //     product_category: ''
  //   }
  // });

  const customApiRequest = useCustomApiRequest();

  // async function handleCategoriesChange(value) {
  //   setIsLoading(true);
  //   setFilterValues({
  //     ...filterValues,
  //     parent_cat: value,
  //     page: 1
  //   });
  //   if (value === '') {
  //     customApiRequest(
  //       getProductsWithFirstTerm(user?.token, {
  //         ...filterValues,
  //         parent_cat: ''
  //       }),
  //       (res) => {
  //         setIsLoading(false);
  //         if (res.data.data.data.length >= 0) {
  //           const data = res.data.data.data;
  //           setAllFetchedProducts(data);
  //         }
  //         if (res?.data?.data?.pagination) {
  //           setTablePagination(res?.data?.data?.pagination);
  //         }
  //       },
  //       (error) => {
  //         setIsLoading(false);
  //       }
  //     );
  //   } else {
  //     customApiRequest(
  //       getProductsWithFirstTerm(user?.token, {
  //         ...filterValues,
  //         parent_cat: value
  //       }),
  //       (res) => {
  //         setIsLoading(false);
  //         if (res.data.data.data.length >= 0) {
  //           const data = res.data.data.data;
  //           setAllFetchedProducts(data);
  //         }
  //         if (res?.data?.data?.pagination) {
  //           setTablePagination(res?.data?.data?.pagination);
  //         }
  //       },
  //       (error) => {
  //         setIsLoading(false);
  //       }
  //     );
  //   }
  // }

  // async function handleWorkPlacesChange(value) {
  //   setFilterValues({
  //     ...filterValues,
  //     work_place: value,
  //     page: 1
  //   });
  //   setIsLoading(true);
  //   if (value === '') {
  //     customApiRequest(
  //       getProductsWithFirstTerm(user?.token, {
  //         ...filterValues,
  //         work_place: ''
  //       }),
  //       (res) => {
  //         setIsLoading(false);
  //         if (res.data.data.data.length >= 0) {
  //           const data = res.data.data.data;
  //           setAllFetchedProducts(data);
  //         }
  //         if (res?.data?.data?.pagination) {
  //           setTablePagination(res?.data?.data?.pagination);
  //         }
  //       },
  //       (error) => {
  //         setIsLoading(false);
  //       }
  //     );
  //   } else {
  //     customApiRequest(
  //       getProductsWithFirstTerm(user?.token, {
  //         ...filterValues,
  //         work_place: value
  //       }),
  //       (res) => {
  //         setIsLoading(false);
  //         if (res.data.data.data.length >= 0) {
  //           const data = res.data.data.data;
  //           setAllFetchedProducts(data);
  //         }
  //         if (res?.data?.data?.pagination) {
  //           setTablePagination(res?.data?.data?.pagination);
  //         }
  //       },
  //       (error) => {
  //         setIsLoading(false);
  //       }
  //     );
  //   }
  // }

  // async function handleWarehousesChange(value) {
  //   setFilterValues({
  //     ...filterValues,
  //     stock_id: value,
  //     page: 1
  //   });
  //   setIsLoading(true);
  //   if (value === '') {
  //     customApiRequest(
  //       getProductsWithFirstTerm(user?.token, {
  //         ...filterValues,
  //         stock_id: ''
  //       }),
  //       (res) => {
  //         setIsLoading(false);
  //         if (res.data.data.data.length >= 0) {
  //           const data = res.data.data.data;
  //           setAllFetchedProducts(data);
  //         }
  //         if (res?.data?.data?.pagination) {
  //           setTablePagination(res?.data?.data?.pagination);
  //         }
  //       },
  //       (error) => {
  //         setIsLoading(false);
  //       }
  //     );
  //   } else {
  //     customApiRequest(
  //       getProductsWithFirstTerm(user?.token, {
  //         ...filterValues,
  //         stock_id: value
  //       }),
  //       (res) => {
  //         setIsLoading(false);
  //         if (res.data.data.data.length >= 0) {
  //           const data = res.data.data.data;
  //           setAllFetchedProducts(data);
  //         }
  //         if (res?.data?.data?.pagination) {
  //           setTablePagination(res?.data?.data?.pagination);
  //         }
  //       },
  //       (error) => {
  //         setIsLoading(false);
  //       }
  //     );
  //   }
  // }
  // const [form] = Form.useForm();

  return (
    <div className="custom-filter-add-section">
      {/* <div className="add-section">
        <div className="section-text">
          <p className="bold-font">المنتجــات</p>
        </div>
        <div
          className="add-btn"
          onClick={() => {
            // if selected product then reset it
            // open the product modal
            setSelectedProductId('');
            setSelectedProduct(null);
            setModalOpened(true);
          }}
        >
          <span className="text-span">إضافة منتج جديد</span>
        </div>
      </div> */}

      <div className="custom-table-fitler">
        <div className="form-excel-export-wrap">
          {/* <Form
            className="store-new-product-form custom-shared-form"
            fields={[
              {
                name: 'posOfferType',
                value: watch('posOfferType')
              }
            ]}
            form={form}
            layout="vertical"
            // onFinish={handleSubmit(onSubmit)}
          > */}
          <form className="filter-form" onSubmit={onSubmit}>
            <div className="table-search">
              <label>
                <SearchIcon />
                <input
                  placeholder={t(
                    'Inventory.FirstTermStock.enterProductNameOrBarcode'
                  )}
                  type="text"
                  name="searchText"
                  // onChange={handleTableSearch}
                  onChange={(v) => debouncedChangeHandler('searchText', v)}
                />
              </label>
            </div>
            <label className="entries-wrap">
              <Select
                defaultValue={null}
                style={{ width: 152 }}
                // onChange={(e) => handleWorkPlacesChange(e)}
                onChange={(v) => handleChange('work_place', v)}
                suffixIcon={<SelectDropDown />}
              >
                <Option value={null} disabled={isLoading}>
                  {t('Inventory.FirstTermStock.allWorkPlaces')}
                </Option>
                {allWorkPlaces?.length > 0 &&
                  allWorkPlaces.map((obj, index) => (
                    <Option key={index} value={obj.id} disabled={isLoading}>
                      {obj.name}
                    </Option>
                  ))}
              </Select>
            </label>
            <label className="entries-wrap">
              <Select
                defaultValue={null}
                style={{ width: 152 }}
                // onChange={(e) => handleWarehousesChange(e)}
                onChange={(v) => handleChange('stock_id', v)}
                suffixIcon={<SelectDropDown />}
              >
                <Option value={null} disabled={isLoading}>
                  {t('Inventory.FirstTermStock.allWarehouses')}
                </Option>
                {allWarehouses?.length > 0 &&
                  allWarehouses.map((obj, index) => (
                    <Option key={index} value={obj.id} disabled={isLoading}>
                      {obj.name}
                    </Option>
                  ))}
              </Select>
            </label>
            <label className="entries-wrap">
              <Select
                defaultValue={null}
                style={{ width: 152 }}
                // onChange={(e) => handleCategoriesChange(e)}
                onChange={(v) => handleChange('parent_cat', v)}
                suffixIcon={<SelectDropDown />}
              >
                <Option value={null} disabled={isLoading}>
                  {t('Inventory.FirstTermStock.allCategories')}
                </Option>
                {mainCategories?.length > 0 &&
                  mainCategories.map((obj, index) => (
                    <Option key={index} value={obj.id} disabled={isLoading}>
                      {obj.name}
                    </Option>
                  ))}
              </Select>
            </label>
            <button className="filter-submit-btn" type="submit">
              {t('Inventory.FirstTermStock.search')}
            </button>
          </form>
          {/* </Form> */}

          <div className="excel-btn-wrap">
            <a
              href={`${
                process.env.REACT_APP_BASE_URL
              }/Excel/exportProducts?client_id=${
                systemSettings?.id
              }&searchText=${
                tableFilter?.searchText ? tableFilter?.searchText : ''
              }&work_place=${
                tableFilter?.work_place ? tableFilter?.work_place : ''
              }&parent_cat=${
                tableFilter?.parent_cat ? tableFilter?.parent_cat : ''
              }&stock_id=${tableFilter?.stock_id ? tableFilter?.stock_id : ''}&first_quantity=1`}
              download
              className="excel-export-btn"
            >
              <img
                style={{ height: '42px' }}
                src={excelImg}
                alt="export to excel"
              />
            </a>
          </div>
        </div>
      </div>

      {/* {modalOpened && <StoreNewProductModalForProducts />}
      {excelModalOpened && (
        <ExcelModal
          modalOpened={excelModalOpened}
          setModalOpened={setExcelModalOpened}
        />
      )} */}
    </div>
  );
};

export default FirstTermStockTableFilter;
