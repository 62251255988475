/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import AddToCartIcon from '../../../common/icons/AddToCartIcon';
import AntdRadioGroup from '../../../common/antd-form-components/AntdRadioGroup';
import moment from 'moment';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import RenderProductsFields from './RenderProductFields';
import renderExtraPricesFields from './renderExtraPricesFields';
import CustomAntdDatePicker from '../../../common/antd-form-components/CustomAntdDatePicker';
import getIncommingInvoiceSchema from './tableSchema';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import {
  renderFieldsTotalValue,
  // renderReceiptTotalValueAfterTax,
  renderTotalPriceAfterExtraPrices
} from './receiptCalculations';
import UserContext from '../../../contexts/user-context/UserProvider';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import createIncomingPurchaseInvoiceApi from '../../../apis/store-apis/incoming-purchas-invoices-apis/CreateIncomingPurchaseInvoiceApi';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import StatisticsContext from '../../../contexts/statistics-context/StatisticsContext';
import useSuppliers from '../../../custom-hooks/useSuppliers';
import SuppliersContext from '../../../contexts/suppliers-context/SuppliersContext';
import InvoiceNewSupplierModal from '../../suppliers-page/PageModal';
import IncomingPurchaseInvoiceContext from '../../../contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import './TableForm.scss';
import { useTranslation } from 'react-i18next';
import MyDropZone from '../../../common/dorp-zone/MyDropZone';
import MyDropZonePreview from '../../../common/dorp-zone/MyDropZonePreview';
import { CameraFilled } from '@ant-design/icons';

const TableForm = ({ setModalVisible }) => {
  const systemSettings = useSystemSettings();
  const { t } = useTranslation();
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedReceipt,
    setSelectedReceipt,
    isSubmittingForm,
    setIsSubmittingForm,
    allProducts
  } = useContext(IncomingPurchaseInvoiceContext);
  const { allSuppliers, setAllSuppliersFetchCount } = useSuppliers();
  // const { allProducts } = useProducts();
  const { user } = useContext(UserContext);
  const {
    setModalOpened: setSupplierModalOpened,
    modalOpened: supplierModalOpened,
    selectedSupplierId
  } = useContext(SuppliersContext);

  const { setFetchStatistcsCount } = useContext(StatisticsContext);

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    getValues,
    clearErrors,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(getIncommingInvoiceSchema(selectedReceipt, t)),
    mode: 'all',
    defaultValues: {
      manualNum: '',
      supplier_id: '',
      products: [
        {
          name: '',
          price: '',
          totalPrice: '',
          desc: '',
          discount_type: 'no_discount',
          discount_price: '',
          discount_percentage: '',
          tax: '0'
        }
      ],
      due_date: null,
      discount_type: 'no_discount', // => 1 is fixed price && 2 is percentage price
      discount_price: '',
      discount_percentage: '',
      //
      // extra_prices: [
      //   {
      //     price: '',
      //     title: ''
      //   }
      // ],
      //
      pay_type: '',
      invoice_due_date: '',
      first_paid: 0,
      first_pay_type: '',
      invoice_image: '',
      first_pay_img: '',
      note: ''
      // installments: [
      //   {
      //     price: '',
      //     date: null,
      //     note: '',
      //     status: ''
      //   }
      // ],
      // cheque: {
      //   bankName: '',
      //   cheekNum: ''
      // },
      // bankAccount: {
      //   accountNumber: '',
      //   price: ''
      // }
    }
  });

  const {
    fields: productsFields,
    append: appendProductField,
    remove: removeProductField
  } = useFieldArray({
    control,
    name: 'products'
  });

  const {
    fields: extraPricesFields,
    append: appendExtraPriceField,
    remove: removeExtraPriceField
  } = useFieldArray({
    control,
    name: 'extra_prices'
  });

  useEffect(() => {
    if (selectedSupplierId) {
      setValue('supplier_id', String(selectedSupplierId));
    }
  }, [selectedSupplierId]);

  const renderClientsTabs = () => {
    return (
      <div className="prev-new-client-wrap">
        <div className="select-label-wrap">
          <p className="label-p">{t('purchaseInvoices.chooseSupplier')}</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`supplier_id`}
              errorMsg={errors?.supplier_id && errors.supplier_id.message}
              validateStatus={errors?.supplier_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder={t('purchaseInvoices.chooseSupplier')}
              options={
                allSuppliers?.length > 0 &&
                allSuppliers.map((role) => ({
                  title: role.name,
                  value: String(role.id)
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="incoming-invoice-form"
            />
          </div>
        </div>

        <div
          className="new-client-btn"
          onClick={() => {
            setSupplierModalOpened(true);
          }}
        >
          <AddToCartIcon />
        </div>
        {supplierModalOpened && (
          <InvoiceNewSupplierModal
            setAllSuppliersFetchCount={setAllSuppliersFetchCount}
          />
        )}
      </div>
    );
  };

  let [paymentMethods, setPaymentStatus] = useState([]);
  useEffect(() => {
    systemSettings?.invoiceSetting?.payment_methods_objects.map(
      (paymentMethod) => {
        setPaymentStatus((prv) => [
          ...prv,
          { title: paymentMethod.name, value: paymentMethod.id }
        ]);
      }
    );
  }, [systemSettings]);

  const [invoiceFileToUpload, setInvoiceFileToUpload] = useState(
    watch('invoice_image') ? watch('invoice_image') : null
  );
  const [invoiceFileToUploadDefferd, setInvoiceFileToUploadDefferd] = useState(
    watch('first_pay_img') ? watch('first_pay_img') : null
  );
  useEffect(() => {
    setValue('invoice_image', invoiceFileToUpload);
    if (watch('invoice_image')) {
      clearErrors('invoice_image');
    }
  }, [invoiceFileToUpload, invoiceFileToUpload?.length]);
  useEffect(() => {
    setValue('first_pay_img', invoiceFileToUploadDefferd);
    if (watch('first_pay_img')) {
      clearErrors('first_pay_img');
    }
  }, [invoiceFileToUploadDefferd, invoiceFileToUploadDefferd?.length]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handleFilesDrop = async (acceptedFiles, formFiles, setFormFiles) => {
    const duplicates = [];
    for (let i of acceptedFiles) {
      if (formFiles?.length) {
        for (let f of formFiles) {
          if (i.name === f.name) {
            duplicates.push(i);
          }
        }
      }
    }
    for (let i = 0; i < acceptedFiles.length; i++) {
      for (let f of duplicates) {
        if (acceptedFiles[i].name === f.name) {
          acceptedFiles.splice(i, 1);
        }
      }
    }
    acceptedFiles.forEach(async (file) => {
      const preview = await getBase64(file);
      Object.assign(file, {
        preview
      });
      setFormFiles((prevState) => [file]);
      if (formFiles?.length > 0) {
        setFormFiles((currState) => [file]);
      } else {
        setFormFiles([file]);
      }
    });
  };

  const clearFileFromUpload = (file, setFilesToUpload) => {
    setFilesToUpload((prevState) => {
      const filtered = prevState.filter((asset) => asset.name !== file.name);
      if (filtered?.length === 0) return null;
      return filtered;
    });
  };
  useEffect(() => {
    if (watch('pay_type')) {
      setValue('invoice_due_date', '');
      clearErrors('invoice_due_date');
      setValue('first_paid', 0);
      clearErrors('first_paid');
      setValue('first_pay_type', '');
      clearErrors('first_pay_type');
      setValue('invoice_image', null);
      clearErrors('invoice_image');
      setValue('first_pay_img', null);
      clearErrors('first_pay_img');
      setInvoiceFileToUpload(null);
    }
  }, [watch('pay_type')]);
  useEffect(() => {
    setValue('first_pay_img', null);
    clearErrors('first_pay_img');
  }, [watch('first_pay_type')]);
  useEffect(() => {
    if (watch('multiPaymentCash')) {
      clearErrors('multiPaymentVisa');
    }
  }, [watch('multiPaymentCash')]);
  useEffect(() => {
    if (watch('multiPaymentVisa')) {
      clearErrors('multiPaymentCash');
    }
  }, [watch('multiPaymentVisa')]);
  useEffect(() => {
    if (renderFieldsTotalValue(watch('products'))) {
      clearErrors('multiPaymentCash');
      clearErrors('multiPaymentVisa');
      setValue('multiPaymentCash', '');
      setValue('multiPaymentVisa', '');
    }
  }, [renderFieldsTotalValue(watch('products'))]);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    const mappedData = {};
    if (data.manualNum) mappedData.manualNum = data.manualNum;
    if (data.supplier_id) mappedData.supplier_id = data.supplier_id;
    if (data.note) mappedData.note = data.note;
    if (data.due_date)
      mappedData.dueDate = moment(data.due_date).format('YYYY-MM-DD');
    /////////////////

    // prdoucts
    let mappedProducts = data.products.map((pro) => {
      if (pro.discount_type == '1' || pro.discount_type == '2')
        return {
          name: pro.name,
          tax: pro.tax,
          desc: pro.desc,
          discountType: pro.discount_type,
          discountValue:
            pro.discount_type === '1'
              ? pro.discount_price
              : pro.discount_percentage,
          price: pro.price ? pro.price : '',
          totalPrice: pro.price ? pro.price * pro.quantity : '',
          quantity: selectedReceipt ? pro.returnQuantity : pro.quantity
        };
      else if (!(pro.discount_type == '1' || pro.discount_type == '2'))
        return {
          name: pro.name,
          tax: pro.tax,
          desc: pro.desc,
          price: pro.price ? pro.price : '',
          totalPrice: pro.price ? pro.price * pro.quantity : '',
          quantity: selectedReceipt ? pro.returnQuantity : pro.quantity
        };
      return null;
    });
    if (selectedReceipt) {
      mappedProducts = data.products.map((pro, index) => {
        if (pro.discount_type == '1' || pro.discount_type == '2')
          return {
            name: pro.name,
            tax: pro.tax,
            desc: pro.desc,
            discountType: pro.discount_type,
            discountValue:
              pro.discount_type === '1'
                ? pro.discount_price
                : pro.discount_percentage,
            price: pro.price ? pro.price : '',
            totalPrice:
              pro.price && pro.quantity ? pro.price * pro.quantity : '',
            quantity: selectedReceipt ? pro.returnQuantity : pro.quantity,
            parent_quantity:
              selectedReceipt?.products?.length > 0
                ? selectedReceipt.products[index]?.quantity
                : 0
          };
        else if (!(pro.discount_type == '1' || pro.discount_type == '2'))
          return {
            name: pro.name,
            tax: pro.tax,
            desc: pro.desc,
            price: pro.price ? pro.price : '',
            totalPrice:
              pro.price && pro.quantity ? pro.price * pro.quantity : '',
            quantity: selectedReceipt ? pro.returnQuantity : pro.quantity,
            parent_quantity:
              selectedReceipt?.products?.length > 0
                ? selectedReceipt.products[index]?.quantity
                : 0
          };
        return null;
      });
    }

    // if (mappedProducts?.length > 0 && mappedProducts[0] !== null) {
    //   mappedData.products = JSON.stringify(mappedProducts);
    // }
    mappedData.products =
      mappedProducts?.length > 0 ? JSON.stringify(mappedProducts) : [];
    mappedData.type = 1; // => buy invoice type
    // mappedData.invoice_type = 1; //  1 => incoming purchase invoice, 2 => outgoing purchase invoice
    /////////////////
    if (data.extra_prices?.length > 0) {
      const mappedExtraPrices = data.extra_prices.map((extra) => {
        if (extra.price && extra.title) {
          return {
            price: extra.price,
            desc: extra.title
          };
        }
        return null;
      });
      if (mappedExtraPrices?.length > 0 && mappedExtraPrices[0] !== null) {
        mappedData.addPrices = JSON.stringify(mappedExtraPrices);
      }
    }
    /////////////////
    if (data.discount_type) {
      if (data.discount_type == '1' || data.discount_type == '2') {
        mappedData.discountType = data.discount_type;
      }
      if (data.discount_price && data.discount_type === '1') {
        mappedData.discountValue = data.discount_price;
      } else if (data.discount_percentage && data.discount_type === '2') {
        mappedData.discountValue = data.discount_percentage;
      }
    }
    /////////////
    if (data.pay_type) {
      mappedData.paymentType = data.pay_type;
      if (data.pay_type == '2') {
        if (data?.invoice_due_date) {
          mappedData.invoice_due_date = moment(data.invoice_due_date).format(
            'YYYY-MM-DD'
          );
        }
        mappedData.first_paid = data.first_paid ? data.first_paid : 0;
        if (data?.first_pay_type) {
          mappedData.first_pay_type = data.first_pay_type;
          if (data?.first_pay_type == '5') {
            if (data?.first_pay_img?.length) {
              mappedData.first_pay_img = data.first_pay_img[0];
            }
          }
        }
        if (renderFieldsTotalValue(watch('products'))) {
          mappedData.deferred = renderFieldsTotalValue(watch('products'));
        }
      } else if (data.pay_type == '5') {
        if (data?.invoice_image?.length) {
          mappedData.bank_image = data.invoice_image[0];
        }
        if (renderFieldsTotalValue(watch('products'))) {
          mappedData.bank = renderFieldsTotalValue(watch('products'));
        }
      } else if (data.pay_type == '6') {
        mappedData.totalInvoicePrice = renderFieldsTotalValue(
          watch('products')
        );
        if (data?.multiPaymentVisa) {
          mappedData.visa = data.multiPaymentVisa;
        }
        if (data?.multiPaymentCash) {
          mappedData.cash = data.multiPaymentCash;
        }
      }
    }

    const successCallback = (res) => {
      setIsLoading(false);
      setIsSubmittingForm(false);
      if (res?.data?.data) {
        setModalOpened(false);
        setSelectedReceipt(null);
        setModalVisible && setModalVisible(false);
        setFetchCount((prevState) => prevState + 1);
        setFetchStatistcsCount((prev) => prev + 1);
        successNotification({
          title: t('purchaseInvoices.successNotification'),
          message: t('purchaseInvoices.invoiceReturned')
        });
      } else {
        errorNotification({
          title: t('purchaseInvoices.errorOccurred'),
          message: res?.data?.message
            ? res.data.message
            : t('purchaseInvoices.tryLaterMessage')
        });
      }
    };

    const errorCallback = (error) => {
      setIsLoading(false);
      setIsSubmittingForm(false);
      errorNotification({
        title: t('purchaseInvoices.errorOccurred'),
        message: t('purchaseInvoices.tryLaterMessage')
      });
    };

    setIsLoading(true);
    setIsSubmittingForm(true);

    if (selectedReceipt) {
      mappedData.parentInvoice = selectedReceipt.id;
      mappedData.type = 2; // => 2 is the type of return receipt
      const formData = new FormData();
      for (let key in mappedData) {
        formData.append(key, mappedData[key]);
      }
      customApiRequest(
        createIncomingPurchaseInvoiceApi(user?.token, formData, true),
        successCallback,
        errorCallback
      );
    } else {
      const formData = new FormData();
      for (let key in mappedData) {
        formData.append(key, mappedData[key]);
      }
      customApiRequest(
        createIncomingPurchaseInvoiceApi(user?.token, formData),
        successCallback,
        errorCallback
      );
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedReceipt) {
      if (selectedReceipt?.supplier?.id) {
        setValue('supplier_id', String(selectedReceipt.supplier.id));
        if (selectedReceipt?.dueDate)
          setValue('due_date', moment(selectedReceipt.dueDate, 'YYYY-MM-DD'));
      }
      if (selectedReceipt?.note) {
        setValue('note', selectedReceipt.note);
      }
      //
      if (selectedReceipt?.discountType) {
        if (
          selectedReceipt.discountType == '1' ||
          selectedReceipt.discountType == '2'
        ) {
          // 1 => fixed price discount
          // 2 => percentage discount
          setValue('discount_type', String(selectedReceipt.discountType));
        } else {
          setValue('discount_type', 'no_discount');
        }
      } else {
        setValue('discount_type', 'no_discount');
      }
      //
      if (
        String(selectedReceipt.discountValue) &&
        selectedReceipt.discountType == '1'
      ) {
        setValue('discount_price', selectedReceipt.discountValue);
      } else if (
        String(selectedReceipt.discountValue) &&
        selectedReceipt.discountType == '2'
      ) {
        setValue('discount_percentage', selectedReceipt.discountValue);
      }
      if (selectedReceipt?.paymentType) {
        setValue('paymentType', String(selectedReceipt.paymentType));
      }
      //
      if (selectedReceipt?.paymentType == '4') {
        if (selectedReceipt?.bankName) {
          setValue('cheque.bankName', selectedReceipt.bankName);
        }
        if (selectedReceipt?.cheekNum) {
          setValue('cheque.cheekNum', selectedReceipt.cheekNum);
        }
      } else if (selectedReceipt?.paymentType == '5') {
        if (selectedReceipt?.bankName) {
          setValue('bankAccount.accountNumber', selectedReceipt.bankName);
        }
        if (selectedReceipt?.cheekNum) {
          setValue('bankAccount.price', selectedReceipt.cheekNum);
        }
      }
    }
    /////////////////////////
    if (selectedReceipt) {
      const sharedReset = {
        supplier_id: selectedReceipt?.supplier?.id
          ? String(selectedReceipt.supplier.id)
          : '',
        due_date: selectedReceipt.dueDate
          ? moment(selectedReceipt.dueDate, 'YYYY-MM-DD')
          : null,
        note: selectedReceipt.note,
        //
        discount_type: selectedReceipt.discountType
          ? String(selectedReceipt.discountType)
          : 'no_discount',
        discount_price:
          selectedReceipt?.discountType == '1' &&
          String(selectedReceipt.discountValue)
            ? selectedReceipt.discountValue
            : '',
        discount_percentage:
          selectedReceipt?.discountType == '2' &&
          String(selectedReceipt.discountValue)
            ? selectedReceipt.discountValue
            : '',
        //
        paymentType: selectedReceipt.paymentType
          ? String(selectedReceipt.paymentType)
          : '',
        //
        cheque: {
          bankName:
            selectedReceipt?.bankName && selectedReceipt?.paymentType == '4'
              ? selectedReceipt.bankName
              : '',
          cheekNum:
            selectedReceipt?.cheekNum && selectedReceipt?.paymentType == '4'
              ? selectedReceipt.cheekNum
              : ''
        },
        bankAccount: {
          accountNumber:
            selectedReceipt?.bankName && selectedReceipt?.paymentType == '5'
              ? selectedReceipt.bankName
              : '',
          price:
            selectedReceipt?.cheekNum && selectedReceipt?.paymentType == '5'
              ? selectedReceipt.cheekNum
              : ''
        }
      };
      if (selectedReceipt.products?.length >= 0) {
        const { products } = selectedReceipt;
        let mappedProducts = [];
        let mappedInstallments = [];
        mappedProducts = products.map((product) => ({
          name: product.name,
          desc: product.desc,
          discount_type: product.discountType
            ? String(product.discountType)
            : 'no_discount',
          discount_price:
            product?.discountType == '1' && String(product.discountValue)
              ? product.discountValue
              : '',
          discount_percentage:
            product?.discountType == '2' && String(product.discountValue)
              ? product.discountValue
              : '',
          price: product?.price ? product.price : '',
          quantity: product?.quantity ? product.quantity : 1,
          tax: product.tax
        }));
        //

        const mappedExtraPrices =
          selectedReceipt?.addPrices?.length > 0 &&
          selectedReceipt.addPrices.map((obj) => ({
            price: obj.price,
            title: obj.desc
          }));

        if (
          selectedReceipt.paymentType == '2' &&
          selectedReceipt.Installment?.length > 0
        ) {
          mappedInstallments = selectedReceipt.Installment.map((ins) => ({
            price: ins.price,
            //  status: String(ins.status),
            note: ins.note,
            date: moment(ins.date, 'YYYY-MM-DD')
          }));
        }

        reset({
          ...sharedReset,
          products: products?.length > 0 ? [...mappedProducts] : [],
          extra_prices:
            selectedReceipt?.addPrices?.length > 0
              ? [...mappedExtraPrices]
              : [],
          installments:
            selectedReceipt.Installment?.length > 0
              ? [...mappedInstallments]
              : []
        });
      }
    }
  }, [selectedReceipt, selectedReceipt?.id, reset]);

  const [form] = Form.useForm();

  return (
    <Form
      className="create-receipt-form custom-shared-form incoming-invoice-form"
      form={form}
      layout="vertical"
      // initialValues={{
      //   products: watch('products')
      // }}
      fields={[
        {
          name: 'discount_type',
          value: watch('discount_type')
        },
        {
          name: 'paymentType',
          value: watch('paymentType')
        },
        {
          name: 'products',
          value: watch('products')
        },
        {
          name: 'installments',
          value: watch('installments')
        }
      ]}
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('purchaseInvoices.invoiceNumber')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              disabled={selectedReceipt ? true : false}
              className="form-text-field"
              name="manualNum"
              type="text"
              placeholder={t('purchaseInvoices.invoiceNumberPlaceholder')}
              errorMsg={errors?.manualNum && errors.manualNum.message}
              validateStatus={errors?.manualNum ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        {selectedReceipt && (
          <div className="supplier-name-view">
            <span>{t('purchaseInvoices.supplier')} : </span>
            <span className="supp-name">{selectedReceipt.supplier?.name}</span>
          </div>
        )}
        {!selectedReceipt && renderClientsTabs()}

        <div className="date-picker-wrap">
          <p className="label-p">{t('purchaseInvoices.issuingInvoiceDate')}</p>
          <CustomAntdDatePicker
            // disablePast
            control={control}
            name="due_date"
            className="receipt-date-picker"
            formClassName="incoming-invoice-form"
          />
          {errors?.due_date && (
            <p className="error-p">{errors.due_date.message}</p>
          )}
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('purchaseInvoices.notes')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="note"
              rows={8}
              placeholder={t('purchaseInvoices.notesPlaceholder')}
              errorMsg={errors?.note?.message}
              validateStatus={errors?.note ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div
          className="radios-field-array-wrap no-border"
          style={{ marginBottom: 0 }}
        >
          <div className="products-add-btn-wrap">
            {!selectedReceipt && (
              <div
                style={{
                  display: 'flex',
                  gap: 32,
                  justifyContent: 'space-between',
                  alignContent: 'center'
                }}
              >
                <div
                  className="add-new-field-btn"
                  style={{
                    marginBottom: 12
                  }}
                  onClick={() =>
                    appendProductField({
                      name: '',
                      discount_type: 'no_discount',
                      discount_price: '',
                      discount_percentage: '',
                      price: '',
                      quantity: 1,
                      tax: '0'
                    })
                  }
                >
                  <span>+ {t('purchaseInvoices.addService')}</span>
                </div>
              </div>
            )}
            <RenderProductsFields
              allProducts={allProducts}
              productsFields={productsFields}
              removeProductField={removeProductField}
              selectedReceipt={selectedReceipt}
              watch={watch}
              getValues={getValues}
              errors={errors}
              setValue={setValue}
              control={control}
              register={register}
              systemSettings={systemSettings}
              t={t}
            />
            {watch('products')?.length > 0 && watch(`products[0].price`) && (
              <div className="total-fields-price">
                <span className="total-title">
                  {t('purchaseInvoices.servicePrice')} :
                </span>
                <span className="total-value">
                  {renderFieldsTotalValue(watch('products'))}
                </span>
              </div>
            )}
          </div>
        </div>

        {/* extra prices section */}

        <div
          style={{
            paddingTop: 22,
            marginBottom: 18,
            borderTop: '1px solid rgba(0, 0, 0, .1)'
          }}
        >
          <div
            className="add-new-field-btn"
            onClick={() =>
              appendExtraPriceField({
                price: '',
                title: ''
              })
            }
          >
            <span>+ {t('purchaseInvoices.newExtraPrice')}</span>
          </div>
          {renderExtraPricesFields(
            control,
            setValue,
            errors,
            extraPricesFields,
            removeExtraPriceField,
            t
          )}

          {watch('extra_prices')?.length > 0 &&
            watch(`extra_prices[0].price`) && (
              <div className="total-fields-price">
                <span className="total-title">
                  {t('purchaseInvoices.priceAfterExtras')} :
                </span>
                <span className="total-value">
                  {renderTotalPriceAfterExtraPrices(
                    watch('products'),
                    watch('extra_prices')
                  )}
                </span>
              </div>
            )}
        </div>

        {/* ./extra prices section */}

        <div
          className="radios-field-array-wrap no-border"
          style={{ paddingBottom: '22px' }}
        >
          <AntdRadioGroup
            name="pay_type"
            className="form-radio-group"
            control={control}
            label={t('purchaseInvoices.choosePayType')}
            validateStatus={errors?.pay_type ? 'error' : ''}
            errorMsg={errors?.pay_type?.message}
            radios={paymentMethods}
            // isRadioButton
          />

          {String(watch('pay_type')) == '2' && (
            <div
              className="ingredients-add-btn-wrap"
              style={{
                display: 'grid',
                alignItems: 'start',
                gridTemplateColumns: '1fr 1fr',
                width: '100%',
                gap: '0 30px'
              }}
            >
              <div className="date-picker-wrap">
                <p style={{ marginBottom: '8px' }}>
                  {t('purchaseInvoices.dateOfPayment')} ( DD / MM / YYYY )
                </p>
                <CustomAntdDatePicker
                  // disablePast
                  control={control}
                  name={`invoice_due_date`}
                  className="receipt-date-picker"
                  formClassName="incoming-invoice-form"
                  errorMsg={
                    errors?.invoice_due_date && errors.invoice_due_date.message
                  }
                  validateStatus={errors?.invoice_due_date ? 'error' : ''}
                  setValue={setValue}
                  disablePast={true}
                />
              </div>
              <div className="text-field-label-wrap">
                <p className="label-p">{t('purchaseInvoices.firstPayment')}</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name={`first_paid`}
                    type="text"
                    placeholder={t('purchaseInvoices.firstPaymentPlaceholder')}
                    errorMsg={errors?.first_paid && errors?.first_paid?.message}
                    validateStatus={errors?.first_paid ? 'error' : ''}
                    control={control}
                    defaultValue={0}
                  />
                </div>
              </div>
              <div className="text-field-label-wrap">
                <div className="text-field-wrap">
                  <AntdRadioGroup
                    name="first_pay_type"
                    className="form-radio-group"
                    control={control}
                    label={t('purchaseInvoices.firstPaymentType')}
                    validateStatus={errors?.first_pay_type ? 'error' : ''}
                    errorMsg={errors?.first_pay_type?.message}
                    // radios={paymentMethods}
                    radios={[
                      {
                        title: t('purchaseInvoices.cash'),
                        value: '1'
                      },
                      {
                        title: t('purchaseInvoices.visa'),
                        value: '3'
                      },
                      {
                        title: t('purchaseInvoices.bankTransfer'),
                        value: '5'
                      }
                    ]}
                    // isRadioButton
                  />
                </div>
              </div>
            </div>
          )}
          {String(watch('first_pay_type')) == '5' && (
            <div className="product-img-wrap">
              <h3 style={{ marginBottom: '6px' }}>
                {t('purchaseInvoices.receiptImage')}
              </h3>

              <MyDropZone
                className="product-header-dropzone"
                multipleFiles={false}
                handleFilesDrop={(acceptedFiles) =>
                  handleFilesDrop(
                    acceptedFiles,
                    invoiceFileToUploadDefferd,
                    setInvoiceFileToUploadDefferd
                  )
                }
                filesToUpload={invoiceFileToUploadDefferd}
                id="avatar-input"
                dropzoneText={t('purchaseInvoices.productImage')}
                inputName="first_pay_img"
                icon={<CameraFilled className="dropzone-camera" />}
                dropZoneUrls={
                  watch('first_pay_img')?.file
                    ? [watch('first_pay_img')?.file]
                    : null
                }
              >
                {t('purchaseInvoices.dragImage')}
              </MyDropZone>

              <MyDropZonePreview
                filesToUpload={invoiceFileToUploadDefferd}
                clearFileFromUpload={(f) =>
                  clearFileFromUpload(f, setInvoiceFileToUploadDefferd)
                }
              />
              {errors?.first_pay_img && (
                <p
                  className="error-p"
                  style={{ color: '#ff4d4f', fontSize: '14px' }}
                >
                  {errors?.first_pay_img?.message}
                </p>
              )}
            </div>
          )}
          {String(watch('pay_type')) == '5' && (
            <div className="product-img-wrap">
              <h3 style={{ marginBottom: '6px' }}>
                {t('purchaseInvoices.receiptImage')}
              </h3>

              <MyDropZone
                className="product-header-dropzone"
                multipleFiles={false}
                handleFilesDrop={(acceptedFiles) =>
                  handleFilesDrop(
                    acceptedFiles,
                    invoiceFileToUpload,
                    setInvoiceFileToUpload
                  )
                }
                filesToUpload={invoiceFileToUpload}
                id="avatar-input"
                dropzoneText={t('purchaseInvoices.productImage')}
                inputName="invoice_image"
                icon={<CameraFilled className="dropzone-camera" />}
                dropZoneUrls={
                  watch('invoice_image')?.file
                    ? [watch('invoice_image')?.file]
                    : null
                }
              >
                {t('purchaseInvoices.dragImage')}
              </MyDropZone>

              <MyDropZonePreview
                filesToUpload={invoiceFileToUpload}
                clearFileFromUpload={(f) =>
                  clearFileFromUpload(f, setInvoiceFileToUpload)
                }
              />
              {errors?.invoice_image && (
                <p
                  className="error-p"
                  style={{ color: '#ff4d4f', fontSize: '14px' }}
                >
                  {errors?.invoice_image?.message}
                </p>
              )}
            </div>
          )}
          {String(watch('pay_type')) == '6' && (
            <div className="cheque-wrapper">
              <div className="text-field-label-wrap">
                <p className="label-p">{t('purchaseInvoices.cash')}</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name={`multiPaymentCash`}
                    type="text"
                    placeholder={t('purchaseInvoices.cashAmount')}
                    errorMsg={
                      errors?.multiPaymentCash &&
                      errors?.multiPaymentCash?.message
                    }
                    validateStatus={errors?.multiPaymentCash ? 'error' : ''}
                    control={control}
                    defaultValue={
                      watch('multiPaymentVisa')
                        ? renderFieldsTotalValue(watch('products')) -
                            parseFloat(watch('multiPaymentVisa')) >
                          0
                          ? Math.ceil(
                              parseFloat(
                                renderFieldsTotalValue(watch('products')) -
                                  parseFloat(watch('multiPaymentVisa'))
                              ) * 100
                            ) / 100
                          : 0
                        : null
                    }
                  />
                </div>
              </div>
              <div className="text-field-label-wrap">
                <p className="label-p">{t('purchaseInvoices.visa')}</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name={`multiPaymentVisa`}
                    type="text"
                    placeholder={t('purchaseInvoices.visaPlaceholder')}
                    errorMsg={
                      errors?.multiPaymentVisa &&
                      errors.multiPaymentVisa.message
                    }
                    validateStatus={errors?.multiPaymentVisa ? 'error' : ''}
                    control={control}
                    defaultValue={
                      watch('multiPaymentCash')
                        ? renderFieldsTotalValue(watch('products')) -
                            parseFloat(watch('multiPaymentCash')) >
                          0
                          ? Math.ceil(
                              parseFloat(
                                renderFieldsTotalValue(watch('products')) -
                                  parseFloat(watch('multiPaymentCash'))
                              ) * 100
                            ) / 100
                          : 0
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {t('purchaseInvoices.add')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
