import axios from 'axios';

const getPosSystemSettingsApi = async (token) => {
  try {
    const res = await axios({
      method: 'get',
      baseURL: process.env.REACT_APP_BASE_URL_POS,
      url: `/getSettings`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getPosSystemSettingsApi;
