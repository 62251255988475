import firebase from 'firebase/app';
import 'firebase/messaging';
// import firebase from 'firebase/compat/app'
// import messaging from 'firebase/compat/app'

const firebaseConfig = {
  apiKey: 'AIzaSyBXHl1wAp_1C52Lrn3ET2r54f2UxuGmuAE',
  authDomain: 'fatoorah-57408.firebaseapp.com',
  projectId: 'fatoorah-57408',
  storageBucket: 'fatoorah-57408.appspot.com',
  messagingSenderId: '1004719588433',
  appId: '1:1004719588433:web:f4a36d84854c5f69936470',
  measurementId: 'G-KM16FJBL3S'
};

firebase.initializeApp(firebaseConfig);
let messaging = null;
if (firebase?.messaging.isSupported()) {
  messaging = firebase.messaging();
}

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getCurrentToken = async (setIsTokenFound) => {
  let currentToken = '';
  if (messaging) {
    try {
      currentToken = await messaging.getToken({ vapidKey: publicKey });
      if (currentToken) {
        setIsTokenFound(true);
      } else {
        setIsTokenFound(false);
      }
    } catch (error) {
    }
  }

  return currentToken;
};

// foreground FCM
export const onMessageListener = () => {
  if (messaging) {
    return new Promise((resolve) => {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    });
  }
};
