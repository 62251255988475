import { DeleteOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Tooltip } from 'antd';
import { EditorState } from 'draft-js';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import getJournalAccountsListLeafApi from '../../../apis/accounting-apis/accounting-details-apis/getJournalAccountsListLeafApi';
import createJournalsLeafApi from '../../../apis/accounting-apis/createJournalsLeafApi';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import CustomAntdDatePicker from '../../../common/antd-form-components/CustomAntdDatePicker';
import CustomEditorFree from '../../../common/custom-editor/CustomEditorFree';
import JournalsContext from '../../../contexts/accounting-context/JournalsContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import './journalCreateModelForm.scss';
import './journals.scss';
import Cookies from 'js-cookie';

const JournalCreateModelForm = () => {
  const { user } = useContext(UserContext);
  const {
    setIsLoading,
    setModalOpenedCreate,
    setFetchCount,
    accountsLists,
    setAccountsLists,
    selectedJournals,
    selectedJournalId
  } = useContext(JournalsContext);

  const [currentDate, setCurrentDate] = useState(null);

  const { t } = useTranslation();
  const schema = Yup.object().shape({
    note: Yup.string().optional('ادخل الملاحظه'),
    date: Yup.string().required('ادخل تاريخ القيد'),
    transactions: Yup.array().of(
      Yup.object().shape({
        account_id: Yup.string().test(
          'transactions.index.account_id',
          t('Restrictions.AccountName'),
          (v, context) => {
            let result = true;
            if (!v) {
              result = false;
            }
            return result;
          }
        ),
        credit: Yup.string().test(
          'transactions.index.credit',
          t('Messages.OnlyNumbersAreaAllowed'),
          (v, context) => {
            let result = true;
            if (
              v &&
              // !v.match(/^[0-9]+$/) &&
              !v.match(/^(\d+)?(\.\d+)?$/)
            ) {
              result = false;
            }
            return result;
          }
        ),
        debit: Yup.string().test(
          'transactions.index.debit',
          t('Messages.OnlyNumbersAreaAllowed'),
          (v, context) => {
            let result = true;
            if (
              v &&
              // !v.match(/^[0-9]+$/) &&
              !v.match(/^(\d+)?(\.\d+)?$/)
            ) {
              result = false;
            }
            return result;
          }
        )
      })
    )
    // type: Yup.string().required('ادخل النوع'),
    // payment_method: Yup.string().required('اختر وسيلة الدفع'),
  });

  const [apiLoading, setApiLoading] = useState(false);
  const {
    control,
    watch,
    setValue,
    reset,
    clearErrors,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      note: '',
      date: selectedJournals ? moment(selectedJournals?.date) : '',
      transactions: [
        {
          account_id: '',
          credit: '',
          debit: ''
        },
        {
          account_id: '',
          credit: '',
          debit: ''
        }
      ]
    }
  });

  const {
    fields: transactionsFields,
    append: appendTransactionField,
    remove: removeProductField
  } = useFieldArray({
    control,
    name: 'transactions'
  });

  const [productDescEditorState, setProductDescEditorState] = useState(
    EditorState.createEmpty()
  );

  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let api, successMessage, errorMessage;
    api = getJournalAccountsListLeafApi(user?.token, user?.client_id);
    customApiRequest(api, (res) => {
      if (res?.data?.accounts) {
        setAccountsLists(res?.data?.accounts);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedJournals) {
      if (selectedJournals?.note) {
        setValue('note', selectedJournals?.note);
      }
      if (selectedJournals?.date) {
        setValue('date', moment(selectedJournals?.date, 'YYYY-MM-DD'));
      }
      const mappedTransactions = selectedJournals?.transactions?.map(
        (trans) => ({
          account_id: String(trans?.account_id),
          credit: trans?.credit,
          debit: trans?.debit
        })
      );
      reset({
        transactions:
          mappedTransactions?.length > 0 ? [...mappedTransactions] : []
      });

      // selectedJournals.transactions =
      // mappedTransactions?.length > 0 ? [...mappedTransactions] : []
    }
  }, [selectedJournals]);

  useEffect(() => {
    setCurrentDate(moment());
  }, []);

  const onSubmit = async (data) => {
    const mappedData = {};
    if (data.note) mappedData.note = data.note;
    if (data.date) mappedData.date = moment(data.date).format('YYYY-MM-DD');

    const mappedTransactions = data.transactions.map((transaction, index) => {
      if (transaction.account_id && +transaction.debit && +transaction.credit)
        return {
          account_id: transaction.account_id,
          debit: +transaction.debit > 0 ? transaction.debit : null,
          credit: +transaction.credit > 0 ? transaction.credit : null
        };
      else if (
        transaction.account_id &&
        +transaction.credit &&
        +transaction.debit == 0
      )
        return {
          account_id: transaction.account_id,
          credit: +transaction.credit,
          debit: ''
        };
      else if (
        transaction.account_id &&
        +transaction.debit &&
        +transaction.credit == 0
      )
        return {
          account_id: transaction.account_id,
          debit: +transaction.debit,
          credit: ''
        };
      return null;
    });

    mappedData.transactions =
      mappedTransactions?.length > 0 ? mappedTransactions : [];

    setApiLoading(true);
    if (selectedJournals) {
      let api = createJournalsLeafApi(
        user?.token,
        { ...data, client_id: user?.client_id },
        `/accounting/journal/update/${selectedJournalId}`
      );
      customApiRequest(
        api,
        (res) => {
          setApiLoading(false);
          if (checkRes(res)) {
            setModalOpenedCreate(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Messages.addedSuccessfully'),

              message: t('Messages.Modifiedsuccessfully')
            });
          } else {
            errorNotification({
              title: t('Messages.ErrorOccurred'),
              message: res?.data?.message
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setApiLoading(false);
          errorNotification({
            // title: 'حدث خطأ',
            title: t('Messages.ErrorOccurred'),

            message:
              error?.response?.data?.message || t('Messages.TryAgainLater')
          });
        }
      );
    } else {
      customApiRequest(
        createJournalsLeafApi(
          user?.token,
          { ...mappedData, client_id: user?.client_id },
          '/accounting/journal'
        ),
        (res) => {
          setApiLoading(false);
          if (checkRes(res)) {
            setModalOpenedCreate(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Messages.addedSuccessfully'),
              message: t('Messages.addsuccessfully')
            });
          } else {
            setApiLoading(false);
            errorNotification({
              // title: 'حدث خطأ',
              title: t('Messages.ErrorOccurred'),
              message: res?.data?.message || t('Messages.TryAgainLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setApiLoading(false);
          errorNotification({
            // title: 'حدث خطأ',
            title: t('Messages.ErrorOccurred'),
            message:
              error?.response?.data?.message || t('Messages.TryAgainLater')
          });
        }
      );
    }
  };
  const [form] = Form.useForm();

  return (
    <Form
      className="custom-shared-form accounting-form transactions_form"
      layout="inline"
      form={form}
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="text-field-label-wrap">
        {/* <p className="label-p">ملحوظة</p> */}
        {/* <div className="text-field-wrap">
                <AntdTextarea
                    setValue={setValue}
                    name="note"
                    className="form-text-area"
                    formClassName="accounting-form"
                    control={control}
                    rows={4}
                    placeholder="الملاحظات..."
                    errorMsg={errors?.note?.message}
                    validateStatus={errors?.note ? 'error' : ''}
                    value={selectedJournals?.note}
                />
            </div> */}
        <CustomEditorFree
          fieldName="note"
          editorTitle={`${t('Restrictions.Notes')}... `}
          setValue={setValue}
          watch={watch}
          setError={setError}
          clearErrors={clearErrors}
          errors={errors}
          required={false}
          errorMsg="ادخل الملاحظات..."
          editorFieldState={productDescEditorState}
          setEditorFieldState={setProductDescEditorState}
          fetchedData={selectedJournals?.note}
        />
      </div>
      <div className="date-picker-wrap" style={{ marginBottom: '20px' }}>
        <p className="label-p">{t('Restrictions.Date')}</p>
        <div
          style={{
            backgroundColor: '#f6f5f8',
            borderRadius: '10px',
            marginTop: '7px'
          }}
        >
          <CustomAntdDatePicker
            // disablePast
            setValue={setValue}
            control={control}
            name="date"
            className="receipt-date-picker"
            formClassName="custom-shared-form"
            defaultValue={
              selectedJournals?.date
                ? moment(selectedJournals?.date)
                : currentDate
            }
          />
        </div>
        {errors?.date && <p className="error-p">{errors.date.message}</p>}
      </div>
      {/* <div className="Journals-select-label-wrap Journals-select-label-wrap-payment">
            <div className="Journals-custom-select-wrap">
            <Field name="payment_method" as="select">
                <option value="" selected hidden>
                وسيله الدفع
                </option>
                <option value="Cash">كـاش</option>
                <option value="Bank">بنك</option>
                <option value="Card Swipe">كارت بنكى</option>
            </Field>
            </div>
        </div> */}
      {transactionsFields?.length > 0 && (
        <ul className="">
          {transactionsFields.map((item, index) => {
            return (
              <li
                className="Journals-form-body"
                key={item.id}
                style={{
                  marginBottom: '20px',
                  borderRadius: '10px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start'
                  }}
                >
                  <div className="select-label-wrap" style={{ width: '40%' }}>
                    <p className="label-p" style={{ marginBottom: '7px' }}>
                      {t('Restrictions.AccountName')}
                    </p>
                    <div style={{ width: '100%' }}>
                      {accountsLists?.length >= 0 && (
                        <AntdSelectOption
                          name={`transactions.${index}.account_id`}
                          errorMsg={
                            errors?.transactions &&
                            errors.transactions[index]?.account_id &&
                            errors.transactions[index].account_id.message
                          }
                          validateStatus={
                            errors?.transactions &&
                            errors.transactions[index]?.account_id &&
                            errors?.transactions[index]?.account_id
                              ? 'error'
                              : ''
                          }
                          control={control}
                          setValue={setValue}
                          placeholder={t('Restrictions.AccountName')}
                          options={
                            accountsLists?.length > 0 &&
                            accountsLists.map((p) => {
                              return {
                                title: p.holder,
                                value: String(p.id)
                              };
                            })
                          }
                          showSearch={true}
                          // onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          formClassName="accounting-form"
                          // onChange={(e) => {
                          //   handleSelectService(item, index, e);
                          // }}
                        />
                      )}
                    </div>
                  </div>

                  <div
                    className="debit-input"
                    style={{
                      display: 'flex',
                      alignItems: 'start'
                    }}
                  >
                    <div>
                      <p className="label-p" style={{ marginBottom: '7px' }}>
                        {t('Restrictions.Debtor')}
                      </p>
                      <AntdTextField
                        style={{ borderRadius: '7px' }}
                        name={`transactions.${index}.debit`}
                        type="text"
                        placeholder={`${t('Restrictions.Debtor')}...`}
                        errorMsg={
                          errors?.transactions &&
                          errors.transactions[index]?.debit &&
                          errors.transactions[index].debit.message
                        }
                        validateStatus={
                          errors?.transactions &&
                          errors.transactions[index]?.debit &&
                          errors?.transactions[index]?.debit
                            ? 'error'
                            : ''
                        }
                        setValue={setValue}
                        control={control}
                        defaultValue={
                          +watch(`transactions.${index}.credit`) && '0'
                        }
                        // onChange={(e) => handleChangeQuantity(item, index, e)}
                      />
                    </div>
                    <div>
                      <p className="label-p" style={{ marginBottom: '7px' }}>
                        {t('Restrictions.Creditor')}
                      </p>
                      <AntdTextField
                        name={`transactions.${index}.credit`}
                        type="text"
                        // placeholder="الدائن ..."
                        placeholder={`${t('Restrictions.Creditor')}...`}
                        errorMsg={
                          errors?.transactions &&
                          errors.transactions[index]?.credit &&
                          errors.transactions[index].credit.message
                        }
                        validateStatus={
                          errors?.transactions &&
                          errors.transactions[index]?.credit &&
                          errors?.transactions[index]?.credit
                            ? 'error'
                            : ''
                        }
                        setValue={setValue}
                        control={control}
                        defaultValue={
                          +watch(`transactions.${index}.debit`) && '0'
                        }
                        // onChange={(e) => handleChangeQuantity(item, index, e)}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 'auto',
                      marginBottom: 'auto'
                    }}
                  >
                    {transactionsFields?.length > 2 && (
                      <Tooltip title="حذف">
                        <Button
                          className="delete-field-btn"
                          size="large"
                          type="dashed"
                          shape="circle"
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            removeProductField(index);
                          }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      )}
      <button
        type="button"
        className="add-line-btn secondary"
        onClick={() =>
          appendTransactionField({
            account_id: '',
            credit: '',
            debit: ''
          })
        }
        style={{
          marginLeft: `${Cookies.get('i18next') == 'ar' ? '0' : 'auto'}`,
          marginRight: `${Cookies.get('i18next') == 'en' ? '0' : 'auto'}`
        }}
      >
        {t('Restrictions.AddLine')}
      </button>
      <Button
        className="submit-btn"
        htmlType="submit"
        type="default"
        loading={apiLoading}
        style={{
          marginLeft: `${Cookies.get('i18next') == 'ar' ? '0' : 'auto'}`,
          marginRight: `${Cookies.get('i18next') == 'en' ? '0' : 'auto'}`
        }}
      >
        {selectedJournals
          ? t('Restrictions.editRestrictions')
          : t('Restrictions.AddNewRestrictions')}
      </Button>
    </Form>
  );
};

export default JournalCreateModelForm;
