/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import getSingleInvoiceApi from '../../apis/sale-invoice-api/getSingleInvoiceApi';
import UserContext from '../../contexts/user-context/UserProvider';
import { Spin } from 'antd';
import InvoiceRender from './InvoiceRender';
import './PrintInvoicePage.scss';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import SharedLayoutBtns from '../../components/app/pos-section/SharedLayoutBtns';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import getReturnInvoiceApi from '../../apis/return-invoice-apis/getReturnInvoiceApi';
import { useHistory } from 'react-router-dom';
import Links from '../../components/app/Links';

const PrintInvoicePage = React.forwardRef((selectedInvoice) => {
  const params = useParams();
  const routerLinks = Links ();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedInvoice, setFetchedInvoice] = useState(null);

  const { user } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (selectedInvoice) {
        customApiRequest(
          getReturnInvoiceApi(user?.token || user?.employee?.token, params?.id , 2),
          (res) => {
            if (res?.status === 200 && res?.data?.data) {
              if (res.data.data) {
                const data = res.data.data;
                setFetchedInvoice(data);
                setIsLoading(false);
              }
            } else {
              history.push(routerLinks.posPage);
              setIsLoading(false);
            }
          },
          (error) => {}
        );
      } else {
        customApiRequest(
          getSingleInvoiceApi(user?.token || user?.employee?.token, {
            id: params?.id
          }),
          (res) => {
            if (res?.status === 200 && res?.data?.data) {
              if (res.data.data) {
                const data = res.data.data;
                setFetchedInvoice(data);
                setIsLoading(false);
              }
            } else {
              setIsLoading(false);
            }
          },
          (error) => {}
        );
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  if (isLoading) {
    return (
      <div style={{ minHeight: 182, display: 'grid', placeItems: 'center' }}>
        <Spin />
      </div>
    );
  } else if (!isLoading && fetchedInvoice) {
    return (
      <div className="print-layout">
        <div className="btns-wrapper">
          <SharedLayoutBtns />
        </div>
        <InvoiceRender fetchedInvoice={fetchedInvoice} />
      </div>
    );
  }
  return null;
});

export default PrintInvoicePage;
