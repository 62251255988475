import axios from 'axios';

const OtpResendCode = async (token, phone) => {
  try {
    const res = await axios.post(`Auth_general/resendOtp?phone=${phone}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default OtpResendCode;
