import { useState, useEffect, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  fetchedTransactionsDetails: null,
  setFetchedTransactionsDetails: (v) => {},
  selectedTransactions: null,
  setSelectedTransactions: (v) => {},
  singleTransaction: null , 
  setSingleTransaction: (v) => {},
  selectedTransactionId: null,
  setSelectedTransactionId: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  modalOpenedCreate: false,
  setModalOpenedCreate: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  accountsLists: null,
  setAccountsLists : (v) => {},
};

const TransactionsContext = createContext(INITIAL_VALUES);

export const TransactionsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [fetchedTransactionsDetails, setFetchedTransactionsDetails] = useState(
    INITIAL_VALUES.fetchedTransactionsDetails
  );
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [modalOpenedCreate, setModalOpenedCreate] = useState(INITIAL_VALUES.modalOpenedCreate);
  const [selectedTransactions, setSelectedTransactions] = useState(
    INITIAL_VALUES.selectedTransactions
  );
  const [selectedTransactionId, setSelectedTransactionId] = useState(
    INITIAL_VALUES.selectedTransactionId
  );
  const [singleTransaction, setSingleTransaction] = useState(
    INITIAL_VALUES.singleTransaction
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  const [accountsLists , setAccountsLists] = useState(
    INITIAL_VALUES.accountsLists
  );

  // const allTransactionsRoles = useTransactionsRoles();

  // useEffect(() => {
  //   if (allTransactionsRoles?.length > 0) {
  //     setTransactionsRoles(allTransactionsRoles);
  //   }
  // }, [allTransactionsRoles]);

  return (
    <TransactionsContext.Provider
      value={{
        // isLoading,
        setIsLoading,
        fetchCount,
        setFetchCount,
        fetchedTransactionsDetails,
        setFetchedTransactionsDetails,
        selectedTransactions,
        setSelectedTransactions,
        selectedTransactionId,
        setSelectedTransactionId,
        singleTransaction,
        setSingleTransaction,
        modalOpened,
        setModalOpened,
        modalOpenedCreate,
        setModalOpenedCreate,
        isSubmittingForm,
        setIsSubmittingForm,
        accountsLists,
        setAccountsLists
      }}
    >
      {children}
    </TransactionsContext.Provider>
  );
};

export default TransactionsContext;
