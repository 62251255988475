import axios from 'axios';

const getAllPosPackages = async (token) => {
  try {
    const response = await axios.get(`/package-pos/index`, {
      baseURL: process.env.REACT_APP_BASE_URL_WITH_API,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    
    
    return response;
    
  } catch (e) {
  }
};

export default getAllPosPackages;
