import { Button, Popover, Tooltip } from 'antd';
import countWords from '../../utils/countWords';
import trimWords from '../../utils/trimWords';
import { ReadOutlined } from '@ant-design/icons';
import { EyeOutlined } from '@ant-design/icons';

const tableColumns = (
  allFetchedSaleInvoices,
  setLaterInvoiceModalOpened,
  setFetchCountSaleInvoices,
  setSelectedInvoice,
  setIsLoadingAllSaleInvoices,
  setSelectedSaleInvoiceModalOpened,
  token,
  t,
  taxPage
) => {
  const handleViewReceiptClick = (record) => {
    if (taxPage) {
      setSelectedInvoice(record);
      setSelectedSaleInvoiceModalOpened(true);
    } else {
      const foundInvoice = allFetchedSaleInvoices.find(
        (receipt) => receipt.id === record.id
      );
      if (foundInvoice) {
        setSelectedInvoice(record);
        setSelectedSaleInvoiceModalOpened(true);
      }
    }
  };

  return [
    {
      title: t('CashiersalesInvoiceReport.serial'),
      dataIndex: 'manualNum',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span>{`${record.index}`}</span>
          </div>
        );
      }
    },

    {
      title: t('CashiersalesInvoiceReport.client'),
      dataIndex: 'client',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.name
              ? record.name
              : t('CashiersalesInvoiceReport.noClient')}
          </div>
        );
      }
    },

    {
      title: t('CashiersalesInvoiceReport.employee'),
      dataIndex: 'employee',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record.shift?.employee?.name
              ? record.shift?.employee?.name
              : '----'}
          </div>
        );
      }
    },
    {
      title: t('CashiersalesInvoiceReport.invoiceDate'),
      dataIndex: 'date',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.date ? record.date : '----'}
          </div>
        );
      }
    },

    {
      title: t('CashiersalesInvoiceReport.notes'),
      dataIndex: 'note',
      render: (_, record) => {
        if (record.note) {
          const wordsCount = countWords(String(record.note));
          if (wordsCount > 3) {
            return (
              <Popover
                title={t('CashiersalesInvoiceReport.readMore')}
                content={String(record.note)}
              >
                <Button size="large" type="text">
                  <p className="has-read-more">
                    <span>{trimWords(String(record.note), 4)}</span>
                    <ReadOutlined
                      className="read-more-book"
                      style={{ margin: '0 4px' }}
                    />
                  </p>
                </Button>
              </Popover>
            );
          } else
            return (
              <div className="row-cell row-text">{String(record.note)}</div>
            );
        } else {
          return '----';
        }
      }
    },

    {
      title: t('CashiersalesInvoiceReport.totalPrice'),
      dataIndex: 'totalPrice',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.type == 2 && record?.totalPrice > 0 && '-'}
            {record?.totalPrice ? record.totalPrice : '----'}
          </div>
        );
      }
    },

    {
      title: t('CashiersalesInvoiceReport.previewInvoice'),
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            <Tooltip title={t('CashiersalesInvoiceReport.previewInvoice')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => handleViewReceiptClick(record)}
              />
            </Tooltip>
          </div>
        );
      }
    }
  ];
};

export default tableColumns;
