import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import SuppliersContext from '../../../contexts/suppliers-context/SuppliersContext';
import IncomingPurchaseInvoiceContext from '../../../contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import TableFormProducts from './TableFormProducts';
import UserContext from '../../../contexts/user-context/UserProvider';
import { useTranslation } from 'react-i18next';

const PageModal = ({ modalVisible, setModalVisible }) => {
  const { modalOpened, setModalOpened, setSelectedReceipt, selectedReceipt } =
    useContext(IncomingPurchaseInvoiceContext);
  const { setSelectedSupplierId } = useContext(SuppliersContext);
  const { user } = useContext(UserContext);

  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        selectedReceipt
          ? t('purchaseInvoices.invoiceReturnReuest')
          : t('purchaseInvoices.addNewInvoice')
      }
      visible={modalOpened || modalVisible}
      onOk={() => {
        setModalOpened(false);
        setSelectedReceipt(null);
        setSelectedSupplierId('');
        setModalVisible && setModalVisible(false);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedReceipt(null);
        setSelectedSupplierId('');
        setModalVisible && setModalVisible(false);
      }}
      footer={false}
    >
      {user?.subscriptionType === 2 && (
        <TableForm setModalVisible={setModalVisible} />
      )}
      {user?.subscriptionType === 1 && (
        <TableFormProducts setModalVisible={setModalVisible} />
      )}
    </Modal>
  );
};

export default PageModal;
