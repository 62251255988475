/* eslint-disable react-hooks/exhaustive-deps */
import { FieldTimeOutlined } from '@ant-design/icons';
import createActivityDetector from 'activity-detector';
import Cookies from 'js-cookie';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useStopwatch } from 'react-timer-hook';
import signOutApi from '../../../../apis/auth/pos/signout.js';
import PosReportContext from '../../../../contexts/pos-report-context/PosReportContext';
import StopwatchContext from '../../../../contexts/stopwatch-context/StopwatchContext';
import UserContext from '../../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../../custom-hooks/useCustomApiRequest';
import errorNotification from '../../../../utils/errorNotification';
import successNotification from '../../../../utils/successNotification';
import Links from '../../Links';
const ShiftStopwatch = () => {
  const { t } = useTranslation();
  const routerLinks = Links();
  // console.log(routerLinks)
  const {
    user,
    removeCurrentUser,
    setLoggedIn,
    setIsNewLoggedIn,
    setOtpValue
  } = useContext(UserContext);
  const history = useHistory();
  const { stopwatchHours, stopwtachMinutes, stopwtachSeconds } =
    useContext(StopwatchContext);
  const stopwatchOffset = new Date();
  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + stopwtachSeconds);
  stopwatchOffset.setMinutes(stopwatchOffset.getMinutes() + stopwtachMinutes);
  stopwatchOffset.setHours(stopwatchOffset.getHours() + stopwatchHours);
  const { seconds, minutes, hours } = useStopwatch({
    autoStart: true,
    offsetTimestamp: stopwatchOffset
    // offsetTimestamp: diff.toDate()
  });
  const { setPosReport } = useContext(PosReportContext);
  const { setPosReportModalOpened, endTheShit } = useContext(UserContext);
  const { setStopwatchHours, setStopwatchMinutes, setStopwatchSeconds } =
    useContext(StopwatchContext);
  React.useEffect(() => {
    return () => {
      setStopwatchHours(hours);
      setStopwatchMinutes(minutes);
      setStopwatchSeconds(seconds);
    };
  }, []);

  const customApiRequest = useCustomApiRequest();
  const handleSignOut = (obj) => {
    if (obj?.endShift) {
      customApiRequest(
        signOutApi(user?.token || user?.employee?.token, obj),
        (res) => {
          if (res?.data?.status === 1) {
            if (obj?.endShift) {
              setPosReportModalOpened(true);
              setPosReport(res?.data?.data);
            }
            if (endTheShit) {
              removeCurrentUser();
              setIsNewLoggedIn(false);
              setOtpValue(true);
              setLoggedIn(
                Cookies.get('currentUser') ||
                  sessionStorage.getItem('currentUser')
                  ? true
                  : false
              );
              history.push(routerLinks.homePage);

              successNotification({
                title: t('LogOut.operationCompletedSuccessfully'),
                message: t('LogOut.logOutSuccess')
              });
              setStopwatchHours(0);
              setStopwatchMinutes(0);
              setStopwatchSeconds(0);
            }
          } else {
            errorNotification({
              title: t('LogOut.logOutError'),
              message: t('LogOut.tryLaterMessage')
            });
          }
        },
        (error) => {
          errorNotification({
            title: t('LogOut.logOutError'),
            message: t('LogOut.tryLaterMessage')
          });
        }
      );
    } else {
      customApiRequest(
        signOutApi(user?.token || user?.employee?.token),
        (res) => {
          if (res?.status === 200 && res?.data?.status === 1) {
            removeCurrentUser();
            setIsNewLoggedIn(false);
            setOtpValue(true);
            setLoggedIn(
              Cookies.get('currentUser') ||
                sessionStorage.getItem('currentUser')
                ? true
                : false
            );
            history.push(routerLinks.homePage);

            successNotification({
              title: t('LogOut.operationCompletedSuccessfully'),
              message: t('LogOut.logOutSuccess')
            });
            setStopwatchHours(0);
            setStopwatchMinutes(0);
            setStopwatchSeconds(0);
          } else {
            errorNotification({
              title: t('LogOut.logOutError'),
              message: t('LogOut.tryLaterMessage')
            });
          }
        },
        (error) => {
          errorNotification({
            title: t('LogOut.logOutError'),
            message: t('LogOut.tryLaterMessage')
          });
        }
      );
    }
  };

  React.useEffect(() => {
    const activityDetector = createActivityDetector({
      // timeToIdle: 300000, // wait 5mints of inactivity to consider the user is idle
      timeToIdle: 6000000000,
      // timeToIdle: 600000 * 3, // wait 10 * 3 mints of inactivity to consider the user is idle
      // timeToIdle: 300000, // wait 5mints of inactivity to consider the user is idle
      autoInit: true, // I don't want to start the activity detector yet.
      inactivityEvents: [],
      ignoredEventsWhenIdle: []
    });
    activityDetector.on('idle', () => {
      // await sleep(600000);
      handleSignOut();
      // setTimeout(handleSignOut, expiryTimestamp);
    });

    return () => {
      activityDetector.stop();
    };
  }, []);
  return (
    <div className="shift-timer-wrap">
      <FieldTimeOutlined />
      <div className="time-wrap">
        <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
      </div>
      {/* <button onClick={start}>Start</button>
      <button onClick={pause}>Pause</button> */}
    </div>
  );
};

export default ShiftStopwatch;
