import React, { useContext } from 'react';

import SearchIcon from '../../common/icons/SearchIcon';
import ClientsContext from '../../contexts/clients-context/ClientsContext';
import UserContext from '../../contexts/user-context/UserProvider';
import searchAllClientsApi from '../../apis/pos-api/clients-pos-apis/searchAllClientsApi';
import ClientsPosContext from '../../contexts/clients-context-pos/ClientsPosContext';
import { useTranslation } from 'react-i18next';

const TableFilter = () => {
  const {
    setNameSearchValue,
    setIsLoading,
    setAllFetchedClients,
    setTablePagination,
    tablePagination
  } = useContext(ClientsPosContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const handleTableSearch = async (e) => {
    // setNameSearchValue(e.target.value);
    try {
      setIsLoading(true);
      let res = await searchAllClientsApi(
        user?.token || user?.employee?.token,
        { page: tablePagination.current_page, searchText: e.target.value }
      );
      if (res) {
        setIsLoading(false);
        if (res.status === 200 && res?.data?.data?.data?.length >= 0) {
          const data = res.data.data.data;
          setAllFetchedClients(data);
        }
        if (res?.data?.data?.pagination) {
          setTablePagination(res.data.data.pagination);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="custom-table-fitler warehouse-table-filter">
      <div className="form-excel-export-wrap">
        <form className="filter-form">
          <div className="table-search">
            <label>
              <SearchIcon />
              <input
                placeholder={t('Customers.SearchCustomerByName')}
                type="text"
                name="table_search"
                onKeyUp={handleTableSearch}
              />
            </label>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TableFilter;
