/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import PageTable from './PageTable';
import { Descriptions } from 'antd';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import PageModal from './PageModal';
import SelectedReceiptModal from './SelectedReceiptModal';
import UserContext from '../../../contexts/user-context/UserProvider';
import SelectedReceiptModalProducts from './SelectedReceiptModalProducts';
import ConvertToInvoiceModal from './ConvertToInvoiceModal';

const SharedOutgoingPurchaseInvoice = ({
  allFetchedReceipts,
  withoutReturnBtn = false,
  total,
  isReportsPage
}) => {
  const systemSettings = useSystemSettings();
  const { modalOpened, selectedReceiptModalOpened, convertModalOpened } =
    useContext(OutgoingPurchaseInvoiceContext);
  const { user } = useContext(UserContext);

  const renderSystemSettings = (obj) => {
    const {
      name,
      phone,
      email,
      addressCode,
      buildingNum,
      streetName,
      district,
      city,
      anotherId,
      country,
      VATRegistrationNumber,
      tax_number,
      postalCode
    } = obj;
    return (
      <div
        style={{
          marginTop: 32
        }}
        className="system-settings-details-wrap"
      >
        <Descriptions title="" bordered column={2}>
          {name && (
            <Descriptions.Item label="الإســـم">{name}</Descriptions.Item>
          )}
          {phone && (
            <Descriptions.Item label="رقم الهاتف">{phone}</Descriptions.Item>
          )}
          {email && (
            <Descriptions.Item label="البريد الاكترونى">
              {email}
            </Descriptions.Item>
          )}
          {buildingNum && (
            <Descriptions.Item label="رقـــم المبنى">
              {buildingNum}
            </Descriptions.Item>
          )}
          {streetName && (
            <Descriptions.Item label="اســم الشارع">
              {streetName}
            </Descriptions.Item>
          )}
          {district && (
            <Descriptions.Item label="الحــى">{district}</Descriptions.Item>
          )}
          {city && (
            <Descriptions.Item label="المدينة">{city}</Descriptions.Item>
          )}
          {country && (
            <Descriptions.Item label="البـلد">{country}</Descriptions.Item>
          )}
          {postalCode && (
            <Descriptions.Item label="الرمز البريدى">
              {postalCode}
            </Descriptions.Item>
          )}
          {addressCode && (
            <Descriptions.Item label="الرقم الاضافى للعنوان">
              {addressCode}
            </Descriptions.Item>
          )}
          {VATRegistrationNumber && (
            <Descriptions.Item label="رقم ضريبة القيمة المضافة">
              {VATRegistrationNumber}
            </Descriptions.Item>
          )}
          {tax_number && (
            <Descriptions.Item label="رقم ضريبة القيمة المضافة">
              {tax_number}
            </Descriptions.Item>
          )}
          {anotherId && (
            <Descriptions.Item label="معرف أخر">{anotherId}</Descriptions.Item>
          )}
          {/* {String(total) && (
            <Descriptions.Item label="السعر الإجمالى">
              {total} ريـــال
            </Descriptions.Item>
          )} */}
          {/* {String(allFetchedReceipts?.total) && (
            <Descriptions.Item label="السعر الإجمالى">
              {allFetchedReceipts?.total} ريـــال
            </Descriptions.Item>
          )} */}
        </Descriptions>
      </div>
    );
  };

  return (
    <>
      {withoutReturnBtn &&
        systemSettings &&
        renderSystemSettings(systemSettings)}

      <PageTable
        withoutReturnBtn={withoutReturnBtn}
        allFetchedReceipts={allFetchedReceipts}
        isReportsPage={isReportsPage}
      />

      {modalOpened && <PageModal />}
      {selectedReceiptModalOpened &&
        (user.subscriptionType === 1 ? (
          <SelectedReceiptModalProducts />
        ) : (
          <SelectedReceiptModal systemSettings={systemSettings} />
        ))}
      {convertModalOpened && <ConvertToInvoiceModal />}
    </>
  );
};

export default SharedOutgoingPurchaseInvoice;
