import { createContext, useEffect, useState } from 'react';

const initState = {
  tagsData: '',
  setTagsData: () => {},
  previousTagsData: '',
  setPreviousTagsData: () => {},
  isLoadingTags: false,
  setIsLoadingTags: () => {},
  isModalOpend: false,
  setIsModalOpend: () => {},
  fetchCount: 0,
  setFetchCount: () => {},
  selectedTag: '',
  setSelectedTag: () => {},
  filter: '',
  setFilter: () => {},
  qrCodeModalOpened: false,
  setQrCodeModalOpened: (v) => {},
  tableFilter: false,
  setTableFilter: (v) => {}
};

const TagsContext = createContext(initState);

export const TagsProvider = ({ children }) => {
  const [tagsData, setTagsData] = useState(initState.tagsData);
  const [previousTagsData, setPreviousTagsData] = useState(
    initState.previousTagsData
  );
  const [isLoadingTags, setIsLoadingTags] = useState(initState.isLoadingTags);
  const [isModalOpend, setIsModalOpend] = useState(initState.isModalOpend);
  const [fetchCount, setFetchCount] = useState(initState.fetchCount);
  const [selectedTag, setSelectedTag] = useState(initState.selectedTag);
  const [tableFilter, setTableFilter] = useState(initState?.tableFilter);
  const [filter, setFilter] = useState(initState?.filter);

  const [qrCodeModalOpened, setQrCodeModalOpened] = useState(
    initState.qrCodeModalOpened
  );

  return (
    <TagsContext.Provider
      value={{
        tagsData,
        setTagsData,
        isLoadingTags,
        setIsLoadingTags,
        isModalOpend,
        setIsModalOpend,
        fetchCount,
        setFetchCount,
        selectedTag,
        setSelectedTag,
        qrCodeModalOpened,
        setQrCodeModalOpened,
        tableFilter,
        setTableFilter,
        previousTagsData,
        setPreviousTagsData,
        filter,
        setFilter
      }}
    >
      {children}
    </TagsContext.Provider>
  );
};
export default TagsContext;
