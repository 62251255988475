/* eslint-disable eqeqeq */
// helpers
export const calcProductIngredientsPrice = (ingredients) => {
  let total = 0;
  if (ingredients?.length > 0) {
    for (let ing of ingredients) {
      if (
        ing?.ingredientInCartQuantity &&
        parseFloat(ing.ingredientInCartQuantity) > 0 &&
        ing?.priceOfUnit &&
        parseFloat(ing.priceOfUnit) > 0
      ) {
        total =
          total +
          parseFloat(ing.priceOfUnit) *
            parseFloat(ing.ingredientInCartQuantity);
      }
    }
  }
  return total;
};

// end helpers

// calc product net price
export const calcProductNetPrice = (product) => {
  // salePrice * quantity
  let total = 0;
  if (
    product?.salePrice &&
    parseFloat(product.salePrice) >= 0 &&
    parseFloat(product.productInCartQuantity) >= 0
  ) {
    total =
      parseFloat(product.salePrice) * parseFloat(product.productInCartQuantity);
  }
  return total;
};

//////////////////////////////////////////////////
export const calcProductDiscount = (product, discountType, discountValue) => {
  let discount = 0;
  if (discountType == 'no_discount' || discountType == '0') {
    return 0;
  } else if (
    discountType == '1' &&
    discountValue &&
    parseFloat(discountValue) > 0
  ) {
    discount = parseFloat(discountValue);
  } else if (
    discountType == '2' &&
    discountValue &&
    parseFloat(discountValue) > 0
  ) {
    discount = (calcProductNetPrice(product) * parseFloat(discountValue)) / 100;
  }

  return discount;
};
//////////////////////////////////////////////////
// calc product tax
export const calcProductTax = (
  product,
  discountType,
  discountValue,
  systemTax
) => {
  let pTax = 0;
  if (product && product?.tax && parseFloat(product.tax) > 0) {
    pTax =
      ((calcProductNetPrice(product) -
        calcProductDiscount(product, discountType, discountValue)) *
        (parseFloat(product.tax))) /
      100;
  } else {
    pTax =
      ((calcProductNetPrice(product) -
        calcProductDiscount(product, discountType, discountValue)) *
        parseFloat(systemTax)) /
      100;
  }

  return pTax;
};

// calc product total price with discount
export const calcProductTotalPriceWithDiscount = (
  product,
  ingredients,
  discountType,
  discountValue
) => {
  return (
    calcProductNetPrice(product) +
    calcProductIngredientsPrice(ingredients) -
    calcProductDiscount(product, discountType, discountValue)
  );
};

// calc product total price with discount with tax
export const calcProductTotalPriceWithDiscounWithTax = (
  product,
  ingredients,
  discountType,
  discountValue,
  systemTax
) => {
  return (
    calcProductNetPrice(product) +
    calcProductIngredientsPrice(ingredients) -
    calcProductDiscount(product, discountType, discountValue) +
    calcProductTax(product, discountType, discountValue, systemTax)
  );
};
