/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import TableFilter from './TableFilter';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import SharedOutgoingPurchaseInvoice from './SharedOutgoingPurchaseInvoice';
import UserContext from '../../../contexts/user-context/UserProvider';
import { useLocation } from 'react-router-dom';
import getAllOutgoingPurchaseInvoicesApi from '../../../apis/outgoing-buy-invoice-apis/getAllOutgoingPurchaseInvoicesApi';
import queryString from 'query-string';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import './StoreReceiptPage.scss';
import getAllIncomingPurchaseInvoicesApi from '../../../apis/store-apis/incoming-purchas-invoices-apis/getAllIncomingPurchaseInvoicesApi';
import TableFilterProducts from './TableFilterProducts';
import getAllProducts from '../../../apis/store-apis/products/getAllProducts';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import getAllProductsWithSelect from '../../../apis/store-apis/products/getAllProductsWithSelect';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

const OutgoingPurchaseReturnedInvoicePage = ({
  reports,
  withoutReturnBtn,
  isReportsPage,
  flag
}) => {
  const { user } = useContext(UserContext);
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { t } = useTranslation();

  const {
    modalOpened,
    setModalOpened,
    setSelectedReceipt,
    allFetchedReceipts,
    setIsLoading,
    setAllFetchedReceipts,
    fetchCount,
    tableFilter,
    resetTableFilter,
    setTablePagination,
    tablePagination,
    setAllProducts,
    filterValues,
    setTableFilter,
    setFetchCount
  } = useContext(OutgoingPurchaseInvoiceContext);

  const [selectedPicker, setSelectedPicker] = React.useState(null);

  const { pathname } = useLocation();
  const user_id_from_payment = pathname?.split('/')[3];

  // reset table filter
  useEffect(() => {
    resetTableFilter();
  }, [pathname]);

  React.useEffect(() => {
    setFetchCount((prevState) => prevState + 1);
    if (modalOpened) {
      setModalOpened(true);
    }
    return () => {
      setModalOpened(false);
    };
  }, [modalOpened, pathname]);

  const location = useLocation();

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      if (user.subscriptionType === 1) {
        customApiRequest(
          getAllIncomingPurchaseInvoicesApi(user?.token, {
            ...tableFilter,
            invoice_type: 2,
            page: tablePagination?.current_page,
            type: 2
          }),
          (res) => {
            setIsLoading(false);
            if (res?.status === 200 && res?.data?.data) {
              const data = res?.data?.data?.invoices?.data;
              setAllFetchedReceipts(data);
            } else {
            }
            if (res?.data?.data?.invoices?.pagination) {
              setTablePagination(res?.data?.data?.invoices?.pagination);
            }
          },
          (error) => {
            setIsLoading(false);
          }
        );
      } else if (user.subscriptionType === 2) {
        customApiRequest(
          getAllOutgoingPurchaseInvoicesApi(user?.token, {
            ...tableFilter,
            ...values,
            type: 2
          }),
          (res) => {
            setIsLoading(false);
            if (res?.status === 200 && res?.data?.data?.data?.length >= 0) {
              const data = res.data.data.data;
              setAllFetchedReceipts(data);
            }
            if (res?.data?.data?.pagination) {
              setTablePagination(res.data.data.pagination);
            }
          },
          (error) => {
            setIsLoading(false);
          }
        );
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);
  // }, [fetchCount, tableFilter]);

  useEffect(() => {
    customApiRequest(
      getAllProductsWithSelect(user?.token, filterValues),
      (res) => {
        if (res?.status === 200 && res?.data?.data?.length >= 0) {
          const data = res.data.data;
          setAllProducts(data);
        }
      },
      (error) => {
        // setIsLoadingProducts(false);
      }
    );
  }, [fetchCount]);

  return (
    <div className="settings-categories-page">
      <div
        className="OutgoingPurchaseInvoicePage-page-head-wrap"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {/* <div className="page-title">
          {location.pathname === '/store/outgoing-purchase-invoice-reports' &&
          user.subscriptionType === 1 ? (
            <h2 className="bold-font">
              {t('Invoices.outgoingPurchaseInvoiceReports')}
            </h2>
          ) : (
            <h2 className="bold-font">{t('Invoices.SalesInvoices')}</h2>
          )}
          <p>{t('Invoices.ShowAndDisplayAllSalesInvoices')}</p>
          <h2 className="bold-font">{t('salesInvoiceReport.title')}</h2>
          ) : (
            <h2 className="bold-font">
              {t('salesInvoiceReport.saleInvoices')}
            </h2>
          )}
          <p>{t('salesInvoiceReport.subTitle')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            <h2 className="bold-font">{t('Invoices.ReturnedSaleInvoice')}</h2>
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.salesPage.title')}
            title3={t('Invoices.ReturnedSaleInvoice')}
            link3={'/store/outgoing-purchase-invoice-return'}
          />
        </div>
        <SearchNotificationsProfile />
      </div>

      <div className="custom-filter-add-section1" style={{ marginTop: '25px' }}>
        {/* {!reports && (
          <div className="add-section">
            <div
              className="add-btn"
              onClick={() => {
                setSelectedReceipt(null);
                setModalOpened(true);
              }}
            >
              <span className="text-span">
                {t('Invoices.AddNewSalesInvoice')}
              </span>
            </div>
          </div>
        )} */}
        {user.subscriptionType === 1 ? (
          <TableFilterProducts
            allFetchedReceipts={allFetchedReceipts}
            selectedPicker={selectedPicker}
            setSelectedPicker={setSelectedPicker}
          />
        ) : (
          <TableFilter
            allFetchedReceipts={allFetchedReceipts}
            isReportsPage={isReportsPage}
            selectedPicker={selectedPicker}
            setSelectedPicker={setSelectedPicker}
          />
        )}
      </div>
      <SharedOutgoingPurchaseInvoice
        allFetchedReceipts={allFetchedReceipts}
        withoutReturnBtn={withoutReturnBtn}
        total={allFetchedReceipts?.total}
        isReportsPage={isReportsPage}
        selectedPicker={selectedPicker}
        flag={'3'}
      />
      {/* {modalOpened && <PageModal />} */}
      {/* {selectedReceiptModalOpened && <SelectedReceiptModal />} */}
    </div>
  );
};

export default OutgoingPurchaseReturnedInvoicePage;
