/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, lazy, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
// import { MainAppBarProvider } from "../../contexts/main-app-bar-context/MainAppBarProvider";
import { ConfigProvider } from 'antd';
import axios from 'axios';
import { useEffect } from 'react';
import ReactNotification from 'react-notifications-component';
import getUserInfo from '../../apis/auth/myInfoApi';
import Loading from '../../common/loading/Loading';
import AlertModalContext from '../../contexts/alertModal-context/AlertModalContext';
import { ClientsProvider } from '../../contexts/clients-context/ClientsContext';
import MyNotificationsContext from '../../contexts/notifications-context/MyNotificationsContext';
import { SuppliersProvider } from '../../contexts/suppliers-context/SuppliersContext';
import { UpgradeProvider } from '../../contexts/upgrade-context/UpgradeContext';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
import { onMessageListener } from '../../firebase';
import '../../i18n';
import LoginPage from '../../pages/login-page/LoginPage';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import UpgradePage from '../../pages/upgrade-page/UpgradePage';
import userRoles from '../../userRoles';
import checkRes from '../../utils/checkRes';
import ForegroundNotifications from '../foreground-notification/ForegroundNotification';
import AccountantRoleRoutes from './AccountantRoleRoutes';
import DataEntryRoleRoutes from './DataEntryRoleRoutes';
import DelegateRoleRoutes from './DelegateRoleRoutes';
import AppLayout from './Layout';
import Links from './Links';
import ManagerRoleRoutes from './ManagerRoleRoutes';
import PartnerRoleRoutes from './PartnerRoleRoutes';
import Cookies from 'js-cookie';
import useSignOut from '../../custom-hooks/useSignout';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const HomePage = lazy(() => import('../../pages/home-page/HomePage'));

function App() {
  const { i18n } = useTranslation();
  // const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    document.body.dir = i18n.dir();
    // document.html.dir = i18n.dir() || 'rtl';
    document.documentElement.lang = i18n.dir() == 'ltr' ? 'en' : 'ar';
    document.documentElement.dir = i18n.dir();
    // window.location.reload();
  }, [i18n.dir()]);

  const { loggedIn, user, setCurrentUser, removeCurrentUser, otpValue , isAuthenticated} =
  useContext(UserContext);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    
    if (user) {
      let isMounted = true;
      if (isMounted) {
        customApiRequest(
          getUserInfo(user?.token),
          (res) => {
            if (
              checkRes(res) &&
              res?.data?.data
              // res.data.data?.role !== userRoles?.posUser
            ) {
              setCurrentUser({
                ...res?.data?.data,
                token: user?.token
              });
            } else {
              
            }
          },
          (error) => {}
        );
      }
    } 
  }, [i18n.dir()]);
  
  const { signMeOut } = useSignOut();
  const handleSignOut = () => {
    signMeOut();
  };
  useEffect(() => {
    // isAuthenticated && window.location.reload();
    // isAuthenticated && history.push(Links().signInPage);
    // isAuthenticated && handleSignOut();
    // sessionStorage.removeItem('currentUser');
    // Cookies.remove('currentUser');
    // removeCurrentUser();
  },[isAuthenticated])

  const renderRoutes = () => {
    if (user?.role === userRoles?.manager) {
      return <ManagerRoleRoutes />;
    } else if (user?.role === userRoles?.accountant) {
      return <AccountantRoleRoutes />;
    } else if (user?.role === userRoles?.partner) {
      return <PartnerRoleRoutes />;
    } else if (user?.role === userRoles?.dataEntry) {
      return <DataEntryRoleRoutes />;
    } else if (user?.role === userRoles?.delegate) {
      return <DelegateRoleRoutes />;
    } else if (user?.role === userRoles?.posUser) {
      return <ManagerRoleRoutes />;
    }
    return null;
  };

  // FCM
  const {
    setFcmNotificationFetchCount,
    fcmNotificationPayload,
    fcmNotificationFetchCount,
    setFcmNotificationPayload
  } = useContext(MyNotificationsContext);

  if (onMessageListener()) {
    onMessageListener()
      .then((payload) => {
        setFcmNotificationFetchCount((prev) => prev + 1);
        setFcmNotificationPayload({
          // title: payload.notification.title,
          // body: payload.notification.body
          ...payload
        });
      })
      .catch((err) => {});
  }

  const location = useLocation();
  const { rendered } = useContext(AlertModalContext);
  const systemSettings = useSystemSettings();
  const [commercialModalOpened, setCommercialModalOpened] = useState(false);
  useEffect(() => {
    if (
      systemSettings &&
      !systemSettings?.commercialRecord &&
      user &&
      location?.pathname !== '/settings/general-settings' &&
      !rendered
    )
      setCommercialModalOpened(!systemSettings?.commercialRecord);
  }, [
    systemSettings?.commercialRecord,
    user?.token,
    location?.pathname,
    rendered
  ]);

  return (
    // <div className={`app app-ltr`}>
    <div className={`app app-${i18n.dir()}`}>
      {/* {commercialModalOpened && otpValue && (
        <CommercialRecordModal
          modalOpened={commercialModalOpened}
          setModalOpened={setCommercialModalOpened}
        />
      )} */}
      <Suspense fallback={<Loading />}>
        <ConfigProvider direction={i18n.dir()}>
          <ReactNotification className={i18n.dir()} />
          {user && (
            <ForegroundNotifications
              fcmNotificationPayload={fcmNotificationPayload}
              fcmNotificationFetchCount={fcmNotificationFetchCount}
              setFcmNotificationFetchCount={setFcmNotificationFetchCount}
              setFcmNotificationPayload={setFcmNotificationPayload}
            />
          )}
          <Switch>
            <Route exact path={Links().signInPage}>
              {!user && !loggedIn ? (
                <LoginPage />
              ) : (!user && !otpValue) ||
                !sessionStorage.getItem('otpValue') ? (
                <LoginPage />
              ) : user?.expire === 1 ? (
                <UpgradeProvider>
                  <div className="custom-container">
                    <UpgradePage />
                  </div>
                </UpgradeProvider>
              ) : (
                <AppLayout>
                  <SuppliersProvider>
                    <ClientsProvider>
                      <HomePage />
                    </ClientsProvider>
                  </SuppliersProvider>
                </AppLayout>
              )}
            </Route>
            {/* <Route exact path={Links().generalOutgoingInvoiceDetailsPage()}>
              <GeneralOutgoingInvoiceDetailsPage />
            </Route>
            <Route exact path={Links().generalIncomingInvoiceDetailsPage()}>
              <GeneralIncomingInvoiceDetailsPage />
            </Route>

            <Route exact path={Links().generalVatReportsPage()}>
              <GeneralVatReportsPage />
            </Route> */}

            {/* <Routes /> */}
            {renderRoutes()}

            <Route path="*" component={NotFoundPage} />
          </Switch>
        </ConfigProvider>
      </Suspense>
    </div>
  );
}

export default App;
