/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Table, Empty } from 'antd';
import tableColumns from './tableColumns';
import ArchiveDocsContext from '../../../contexts/archive-contexts/ArchiveDocsProvider';
import getAllDocsApi from '../../../apis/settings-apis/documents-apis/getAllDocumentsAPi';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import { useTranslation } from 'react-i18next';
const PageTable = () => {
  const {
    isLoading,
    setIsLoading,
    allFetchedDocs,
    setAllFetchedDocs,
    fetchCount,
    setModalOpened,
    setFetchCount,
    setSelectedDoc,
    nameSearchValue
  } = useContext(ArchiveDocsContext);

  const { user } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();
  const { t } = useTranslation();

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllDocsApi(user?.token),
        (res) => {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data?.length >= 0) {
            setAllFetchedDocs(res.data.data);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedDocs?.length >= 0) {
      let mappedData = null;
      if (nameSearchValue.trim()) {
        const filtered = allFetchedDocs.filter((obj) =>
          obj.title
            .toLowerCase()
            .trim()
            .includes(nameSearchValue.toLowerCase().trim())
        );
        mappedData = mapDataSource(filtered);
      } else {
        mappedData = mapDataSource(allFetchedDocs);
      }
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedDocs, allFetchedDocs?.length, nameSearchValue]);

  return (
    <Table
      locale={{
        emptyText: (
          <Empty description={false}>{t('Messages.noDataAvailable')}</Empty>
        )
      }}
      pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
      className="antd-custom-table"
      dataSource={tableDataSource}
      columns={tableColumns(
        allFetchedDocs,
        setModalOpened,
        setFetchCount,
        setSelectedDoc,
        setIsLoading,
        user?.token
      )}
      loading={isLoading}
      // scroll={{ x: 400 }}
      footer={false}
    />
  );
};

export default PageTable;
