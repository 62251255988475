import { createContext, useState } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  allProducts: [],
  setAllProducts: (v) => {},
  filterValues: '',
  setFilterValues: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  convertModalOpened: false,
  setConvertModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  fetchNewCount: 0,
  setFetchNewCount: (v) => {},
  allFetchedReceipts: null,
  setAllFetchedReceipts: (v) => {},
  selectedReceipt: null,
  setSelectedReceipt: (v) => {},
  totalInvoiceValue: null,
  setTotalInvoiceValue: (v) => {},
  selectedReceiptModalOpened: false,
  setSelectedReceiptModalOpened: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  //
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {},
  // filter
  tableFilter: {
    page: 1,
    invoiceNumber: '',
    id: '',
    user_id: '',
    supplier_id: '',
    admin_id: '',
    type: 0,
    serviceOrPackage: null,
    from: '',
    to: ''
  },
  setTableFilter: (v) => {},
  resetTableFilter: (v) => {},
  allReceiptsData: null,
  setAllReceiptsData: (v) => {}
};

const OutgoingPurchaseInvoiceContext = createContext(INITIAL_VALUES);

export const OutgoingPurchaseInvoiceProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [convertModalOpened, setConvertModalOpened] = useState(
    INITIAL_VALUES.convertModalOpened
  );

  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [fetchNewCount, setFetchNewCount] = useState(INITIAL_VALUES.fetchNewCount);
  const [allFetchedReceipts, setAllFetchedReceipts] = useState(
    INITIAL_VALUES.allFetchedReceipts
  );
  const [allReceiptsData, setAllReceiptsData] = useState(
    INITIAL_VALUES.allReceiptsData
  );
  const [selectedReceipt, setSelectedReceipt] = useState(
    INITIAL_VALUES.selectedReceipt
  );
  const [totalInvoiceValue, setTotalInvoiceValue] = useState(
    INITIAL_VALUES.selectedReceipt
  );
  const [selectedReceiptModalOpened, setSelectedReceiptModalOpened] = useState(
    INITIAL_VALUES.selectedReceiptModalOpened
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  //
  const [tableFilter, setTableFilter] = useState(INITIAL_VALUES?.tableFilter);
  const [allProducts, setAllProducts] = useState(INITIAL_VALUES?.allProducts);
  const [filterValues, setFilterValues] = useState(INITIAL_VALUES.filterValues);

  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES?.tablePagination
  );

  return (
    <OutgoingPurchaseInvoiceContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        convertModalOpened,
        setConvertModalOpened,
        fetchCount,
        setFetchCount,
        fetchNewCount,
        setFetchNewCount,
        allFetchedReceipts,
        setAllFetchedReceipts,
        selectedReceipt,
        setSelectedReceipt,
        totalInvoiceValue,
        setTotalInvoiceValue,
        selectedReceiptModalOpened,
        setSelectedReceiptModalOpened,
        isSubmittingForm,
        setIsSubmittingForm,
        //
        tableFilter,
        setTableFilter,
        resetTableFilter: () => {
          setTableFilter({ ...INITIAL_VALUES?.tableFilter });
        },
        //
        tablePagination,
        setTablePagination,
        allProducts,
        setAllProducts,
        filterValues,
        setFilterValues,
        allReceiptsData, 
        setAllReceiptsData
      }}
    >
      {children}
    </OutgoingPurchaseInvoiceContext.Provider>
  );
};

export default OutgoingPurchaseInvoiceContext;
