import React from 'react';
import JournalsDetailsTable from './JournalsDetailsTable';
import { useEffect } from 'react';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useContext } from 'react';
import JournalsContext from '../../../contexts/accounting-context/JournalsContext';
import { useParams } from 'react-router-dom';
import UserContext from '../../../contexts/user-context/UserProvider';
import checkRes from '../../../utils/checkRes';
import getAccountingDetails from '../../../apis/accounting-apis/accounting-details-apis/getAccountingDetailsApi';
import getAllJournalsApi from '../../../apis/accounting-apis/accounting-details-apis/getAllJournalsApi';

const JournalsPage = () => {
  const { user } = useContext(UserContext);
  const {
    setIsLoading,
    fetchCount,
    setFetchedJournalsDetails,
    fetchedAccountingDetails,
    modalOpened,
    setSelectedAccounting,
    setModalOpened
  } = useContext(JournalsContext);

  const params = useParams();

  let paramIds = params?.id?.split('-');
  let typeOfAccount = paramIds?.length;

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllJournalsApi(user?.token, user?.client_id),
        (res) => {
          if (
            res?.status === 200 &&
            res?.data?.data &&
            res?.data?.data !== 'not found'
          ) {
            setIsLoading(false);
            setFetchedJournalsDetails(res?.data?.data);
          } else {
            setIsLoading(false);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);
  return <div>{<JournalsDetailsTable />}</div>;
};

export default JournalsPage;
