import React, { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  allFetchedShifts: null,
  setAllFetchedShifts: (v) => {},
  selectedShift: null,
  setSelectedShift: (v) => {},
  selectedShiftModalOpened: false,
  setSelectedShiftModalOpened: (v) => {},
  nameSearchValue: '',
  setNameSearchValue: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {}
};

const ShiftsContext = createContext(INITIAL_VALUES);

export const ShiftsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [allFetchedShifts, setAllFetchedShifts] = useState(
    INITIAL_VALUES.allFetchedShifts
  );
  const [selectedShift, setSelectedShift] = useState(
    INITIAL_VALUES.selectedShift
  );
  const [selectedShiftModalOpened, setSelectedShiftModalOpened] = useState(
    INITIAL_VALUES.selectedShiftModalOpened
  );
  const [nameSearchValue, setNameSearchValue] = useState(
    INITIAL_VALUES.nameSearchValue
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES?.tablePagination
  );
  const [filterValues, setFilterValues] = React.useState({
    status: null,
    from: null,
    to: null,
    pos_id: null,
    admin_id: null
  });

  return (
    <ShiftsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fetchCount,
        setFetchCount,
        modalOpened,
        setModalOpened,
        allFetchedShifts,
        setAllFetchedShifts,
        selectedShift,
        setSelectedShift,
        selectedShiftModalOpened,
        setSelectedShiftModalOpened,
        nameSearchValue,
        setNameSearchValue,
        isSubmittingForm,
        setIsSubmittingForm,
        tablePagination,
        setTablePagination,
        filterValues,
        setFilterValues
      }}
    >
      {children}
    </ShiftsContext.Provider>
  );
};

export default ShiftsContext;
