import axios from 'axios';

const getAllProducts = async (token, filterValues) => {
  // cat_id ==> sub category id
  // cat ==> main category id
  // parCode ==> filter by the key called parCode
  // searchText ==> filter by product name
  try {
    // const url = `/Product/all?cat=${
    //   filterValues?.mainCatId ? filterValues?.mainCatId : ''
    // }&cat_id=${filterValues?.subCatId ? filterValues?.subCatId : ''}`;
    const url = `/Product/all?cat_id=${
      filterValues?.subCatId ? filterValues?.subCatId : ''
    }&parCode=${filterValues?.parCode ? filterValues.parCode : ''}&searchText=${
      filterValues?.searchText ? filterValues.searchText : ''
    }&parent_cat=${
      filterValues?.parent_cat ? filterValues?.parent_cat : ''
    }&page=${filterValues?.page ? filterValues.page : 1}&per_page=${
      filterValues?.per_page ? filterValues.per_page : 20
    }&test=1`;
    const res = await axios.get(url, {
      baseURL: process.env.REACT_APP_BASE_URL_POS,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllProducts;
