import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedWarehouses: null,
  setAllFetchedWarehouses: (v) => {},
  selectedWarehouseId: '',
  setSelectedWarehouseId: (v) => {},
  selectedWarehouse: null,
  setSelectedWarehouse: (v) => {},
  nameSearchValue: '',
  setNameSearchValue: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {}
};

const SettingsWarehousesContext = createContext(INITIAL_VALUES);

export const SettingsWarehousesProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedWarehouses, setAllFetchedWarehouses] = useState(
    INITIAL_VALUES.allFetchedWarehouses
  );
  const [selectedWarehouse, setSelectedWarehouse] = useState(
    INITIAL_VALUES.selectedWarehouse
  );
  const [nameSearchValue, setNameSearchValue] = useState(
    INITIAL_VALUES.nameSearchValue
  );
  const [selectedWarehouseId, setSelectedWarehouseId] = useState(
    INITIAL_VALUES.selectedWarehouseId
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES?.tablePagination
  );
  return (
    <SettingsWarehousesContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedWarehouses,
        setAllFetchedWarehouses,
        selectedWarehouse,
        setSelectedWarehouse,
        selectedWarehouseId,
        setSelectedWarehouseId,
        nameSearchValue,
        setNameSearchValue,
        isSubmittingForm,
        setIsSubmittingForm,
        tablePagination,
        setTablePagination
      }}
    >
      {children}
    </SettingsWarehousesContext.Provider>
  );
};

export default SettingsWarehousesContext;
