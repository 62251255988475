/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Table, Empty, Pagination } from 'antd';
import tableColumns from './tableColumns';
import StoreProductsIngredientsContext from '../../../contexts/store-products-ingredients-context/StoreProductsIngredientsProvider';
import getAllIngredients from '../../../apis/store-apis/ingredients/getAllIngredients';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useHistory } from 'react-router-dom';
import storeRouterLinks from '../../../components/app/store-routes/storeRouterLinks';

const PageTable = () => {
  const { user } = useContext(UserContext);
  const {
    isLoading,
    setIsLoading,
    fetchCount,
    setFetchCount,
    allFetchedIngredients,
    setAllFetchedIngredients,
    setModalOpened,
    setSelectedIngredient,
    tablePagination,
    setTablePagination
  } = useContext(StoreProductsIngredientsContext);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllIngredients(user?.token,{page:tablePagination.current_page}),
        (res) => {
          setIsLoading(false);
          if (res.data.data.data.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedIngredients(data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
          if(res.data.data.pagination){
            setTablePagination(res.data.data.pagination)
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedIngredients?.length >= 0) {
      const mappedData = mapDataSource(allFetchedIngredients);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedIngredients, allFetchedIngredients?.length]);
  const history = useHistory()

  return (
    <>
      <Table
        locale={{
          emptyText: <Empty description={false}>لا يوجد بيانات متاحة</Empty>
        }}
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedIngredients,
          setModalOpened,
          setFetchCount,
          setSelectedIngredient,
          setIsLoading,
          user?.token
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />

      {tablePagination && (
        <Pagination
          style={{ display: "flex", justifyContent: "center" }}
          defaultCurrent={1}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}
          onChange={(page, pageSize) => {
            setFetchCount((prev) => prev + 1);
            setTablePagination({...tablePagination,current_page:page})
          }}
          hideOnSinglePage={true}
        />
      )}
    </>

  );
};

export default PageTable;
