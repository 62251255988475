/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { CameraFilled } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import createIncomingPurchaseInvoiceApi from '../../../apis/store-apis/incoming-purchas-invoices-apis/CreateIncomingPurchaseInvoiceApi';
import AntdRadioGroup from '../../../common/antd-form-components/AntdRadioGroup';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import CustomAntdDatePicker from '../../../common/antd-form-components/CustomAntdDatePicker';
import MyDropZone from '../../../common/dorp-zone/MyDropZone';
import MyDropZonePreview from '../../../common/dorp-zone/MyDropZonePreview';
import AddToCartIcon from '../../../common/icons/AddToCartIcon';
import RadioButtonEmpty from '../../../common/icons/RadioButtonEmpty';
import RadioButtonFilled from '../../../common/icons/RadioButtonFilled';
import NewClientModal from '../../../components/new-client-modal/NewClientModal';
import ClientsContext from '../../../contexts/clients-context/ClientsContext';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import SuppliersContext from '../../../contexts/suppliers-context/SuppliersContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useClients from '../../../custom-hooks/useClients';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import useIngredients from '../../../custom-hooks/useIngredients';
import useProducts from '../../../custom-hooks/useProducts';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import useWarehouses from '../../../custom-hooks/useWarehouses';
import userRoles from '../../../userRoles';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import RenderIngredientsFields from './RenderIngredientFields';
import RenderProductsFieldsProducts from './RenderProductsFieldsProducts';
import './TableForm.scss';
import {
  renderFieldsTotalValue,
  // renderReceiptTotalValueAfterDiscount,
  // renderReceiptTotalValueAfterTax,
  renderTotalPriceAfterExtraPrices
} from './receiptCalculations';
import renderExtraPricesFields from './renderExtraPricesFields';
import getOutgoingInvoiceSchema from './tableSchemaProducts';

const TableFormForProducts = (props) => {
  const { t } = useTranslation();
  const systemSettings = useSystemSettings();
  const {
    isLoading,
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedReceipt,
    isSubmittingForm,
    setIsSubmittingForm,
    allProducts,
    setFilterValues
  } = useContext(OutgoingPurchaseInvoiceContext);
  const { allClients, setAllClientsFetchCount } = useClients();
  const { user } = useContext(UserContext);
  const { setSupplierModalOpened, supplierModalOpened, selectedSupplierId } =
    useContext(SuppliersContext);
  const { setClientModalOpened, clientModalOpened, selectedClientId } =
    useContext(ClientsContext);
  const allIngredients = useIngredients();
  const allWarehouses = useWarehouses();
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    clearErrors,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(getOutgoingInvoiceSchema(selectedReceipt, t)),
    mode: 'all',
    defaultValues: {
      userOrSupplier: '2',
      user_id: '',
      products: [
        {
          product_id: '',
          returnQuantity: '',
          quantity: '',
          warehouse_id: '',
          expirationDate: '',
          discount_type: 'no_discount',
          discount_price: '',
          discount_percentage: '',
          price: '',
          tax: '0',
          desc: '',
          including_tax: ''
        }
      ],
      ingredients: [
        {
          ingredient_id: '',
          returnQuantity: '',
          quantity: '',
          warehouse_id: '',
          expirationDate: '',
          discount_type: 'no_discount',
          discount_price: '',
          discount_percentage: '',
          price: '',
          tax: '0'
        }
      ],
      details_type: '1',
      due_date: null,

      discount_type: '', // => 1 is fixed price && 2 is percentage price
      discount_price: '',
      discount_percentage: '',
      //
      // extra_prices: [
      //   {
      //     price: '',
      //     title: ''
      //   }
      // ],
      //
      pay_type: '',
      // installments: [
      //   {
      //     price: '',
      //     date: null,
      //     note: '',
      //     status: ''
      //   }
      // ],
      invoice_due_date: '',
      first_paid: 0,
      first_pay_type: '',
      invoice_image: '',
      first_pay_img: '',
      // deferred_invoice_client: '',
      bankAccount: {
        accountNumber: '',
        price: ''
      },
      //
      note: ''
    }
  });

  const {
    fields: productsFields,
    append: appendProductField,
    remove: removeProductField
  } = useFieldArray({
    control,
    name: 'products'
  });
  const {
    fields: ingredientsFields,
    append: appendIngredientField,
    remove: removeIngredientField
  } = useFieldArray({
    control,
    name: 'ingredients'
  });
  const {
    fields: extraPricesFields,
    append: appendExtraPriceField,
    remove: removeExtraPriceField
  } = useFieldArray({
    control,
    name: 'extra_prices'
  });

  // const {
  //   fields: installmentsFields,
  //   append: appendInstallmentField,
  //   remove: removeInstallmentField
  // } = useFieldArray({
  //   control,
  //   name: 'installments'
  // });

  const [receiptRadiosArr, setReceiptRadiosArr] = React.useState([]);
  useEffect(() => {
    if (selectedReceipt?.detailsType == '1') {
      setReceiptRadiosArr([
        {
          title: t('Quotations.products'),
          value: '1'
        }
      ]);
    } else if (selectedReceipt?.detailsType == '2') {
      setReceiptRadiosArr([
        {
          title: t('Quotations.productIngredients'),
          value: '2'
        }
      ]);
    } else {
      setReceiptRadiosArr([
        {
          title: t('Quotations.products'),
          value: '1'
        }
        // {
        //   title: 'مكونات منتجات',
        //   value: '2'
        // }
      ]);
    }
  }, [selectedReceipt]);

  const [invoiceFileToUpload, setInvoiceFileToUpload] = useState(
    watch('invoice_image') ? watch('invoice_image') : null
  );
  const [invoiceFileToUploadDefferd, setInvoiceFileToUploadDefferd] = useState(
    watch('first_pay_img') ? watch('first_pay_img') : null
  );
  useEffect(() => {
    setValue('invoice_image', invoiceFileToUpload);
    if (watch('invoice_image')) {
      clearErrors('invoice_image');
    }
  }, [invoiceFileToUpload, invoiceFileToUpload?.length]);
  useEffect(() => {
    setValue('first_pay_img', invoiceFileToUploadDefferd);
    if (watch('first_pay_img')) {
      clearErrors('first_pay_img');
    }
  }, [invoiceFileToUploadDefferd, invoiceFileToUploadDefferd?.length]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handleFilesDrop = async (acceptedFiles, formFiles, setFormFiles) => {
    const duplicates = [];
    for (let i of acceptedFiles) {
      if (formFiles?.length) {
        for (let f of formFiles) {
          if (i.name === f.name) {
            duplicates.push(i);
          }
        }
      }
    }
    for (let i = 0; i < acceptedFiles.length; i++) {
      for (let f of duplicates) {
        if (acceptedFiles[i].name === f.name) {
          acceptedFiles.splice(i, 1);
        }
      }
    }
    acceptedFiles.forEach(async (file) => {
      const preview = await getBase64(file);
      Object.assign(file, {
        preview
      });
      setFormFiles((prevState) => [file]);
      if (formFiles?.length > 0) {
        setFormFiles((currState) => [file]);
      } else {
        setFormFiles([file]);
      }
    });
  };

  const clearFileFromUpload = (file, setFilesToUpload) => {
    setFilesToUpload((prevState) => {
      const filtered = prevState.filter((asset) => asset.name !== file.name);
      if (filtered?.length === 0) return null;
      return filtered;
    });
  };
  useEffect(() => {
    if (watch('pay_type')) {
      setValue('invoice_due_date', '');
      clearErrors('invoice_due_date');
      setValue('first_paid', 0);
      clearErrors('first_paid');
      setValue('first_pay_type', '');
      clearErrors('first_pay_type');
      setValue('invoice_image', null);
      clearErrors('invoice_image');
      setValue('first_pay_img', null);
      clearErrors('first_pay_img');
      setInvoiceFileToUpload(null);
    }
  }, [watch('pay_type')]);
  useEffect(() => {
    setValue('first_pay_img', null);
    clearErrors('first_pay_img');
  }, [watch('first_pay_type')]);
  useEffect(() => {
    if (watch('multiPaymentCash')) {
      clearErrors('multiPaymentVisa');
    }
  }, [watch('multiPaymentCash')]);
  useEffect(() => {
    if (watch('multiPaymentVisa')) {
      clearErrors('multiPaymentCash');
    }
  }, [watch('multiPaymentVisa')]);
  useEffect(() => {
    if (renderFieldsTotalValue(watch('products'))) {
      clearErrors('multiPaymentCash');
      clearErrors('multiPaymentVisa');
      setValue('multiPaymentCash', '');
      setValue('multiPaymentVisa', '');
    }
  }, [renderFieldsTotalValue(watch('products'))]);

  useEffect(() => {
    if (selectedClientId) {
      setValue('user_id', String(selectedClientId));
    }
  }, [selectedClientId]);

  useEffect(() => {
    if (watch('userOrSupplier') == 1 || watch('userOrSupplier') == 2)
      setValue('user_id', '');
  }, [watch('userOrSupplier')]);

  const renderSuppliersTabs = () => {
    return (
      <div className="prev-new-client-wrap">
        <div className="select-label-wrap">
          <p className="label-p">
            {watch('userOrSupplier') == 1
              ? t('Invoices.InvoiceSimpleClient')
              : t('Invoices.InvoiceTaxClient')}
          </p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`user_id`}
              errorMsg={errors?.user_id && errors.user_id.message}
              validateStatus={errors?.user_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder={
                watch('userOrSupplier') == 1
                  ? t('Invoices.InvoiceSimpleClient')
                  : t('Invoices.InvoiceTaxClient')
              }
              options={
                allClients?.length > 0 &&
                allClients.map((role) => ({
                  title: role.name,
                  value: String(role.id)
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="outgoing-invoice-form"
            />
          </div>
        </div>

        {user?.role == userRoles?.manager && (
          <>
            <div
              className="new-client-btn"
              onClick={() => {
                setClientModalOpened(true);
              }}
            >
              <AddToCartIcon />
            </div>
            {clientModalOpened && (
              <NewClientModal
                setAllClientsFetchCount={setAllClientsFetchCount}
                isClient={true}
              />
            )}
          </>
        )}
      </div>
    );
  };

  let [paymentMethods, setPaymentStatus] = useState([]);
  useEffect(() => {
    systemSettings?.invoiceSetting?.payment_methods_objects.map(
      (paymentMethod) => {
        setPaymentStatus((prv) => [
          ...prv,
          { title: paymentMethod.name, value: paymentMethod.id }
        ]);
      }
    );
  }, [systemSettings]);

  const customApiRequest = useCustomApiRequest();

  const onSubmit = async (data) => {
    const mappedData = {};
    if (data.userOrSupplier) mappedData.userOrSupplier = data.userOrSupplier;
    if (data.user_id) mappedData.user_id = data.user_id;
    if (data.note) mappedData.note = data.note;
    if (data.due_date)
      mappedData.dueDate = moment(data.due_date).format('YYYY-MM-DD');
    if (data.details_type) mappedData.detailsType = data.details_type;
    /////////////////

    if (data.products?.length > 0 && data.details_type === '1') {
      // prdoucts
      const mappedProducts = data.products.map((pro, index) => {
        if (
          pro.product_id &&
          (pro.discount_type == '1' || pro.discount_type == '2')
        )
          return {
            id: pro.product_id,
            quantity: selectedReceipt ? pro.returnQuantity : pro.quantity,
            returnQuantity: selectedReceipt ? pro.returnQuantity : '',
            parent_quantity:
              selectedReceipt?.details?.length > 0
                ? selectedReceipt.details[index]?.quantity
                : 0,
            stock_id: pro.warehouse_id,
            discountType: pro.discount_type,
            discountValue:
              pro.discount_type === '1'
                ? pro.discount_price
                : pro.discount_percentage,
            expirationDate: pro.expirationDate
              ? moment(data.expirationDate).format('YYYY-MM-DD')
              : null,
            price: pro.price ? pro.price : '',
            tax: pro?.tax,
            desc: pro?.desc,
            including_tax: pro?.including_tax
          };
        else if (
          pro.product_id &&
          !(pro.discount_type == '1' || pro.discount_type == '2')
        )
          return {
            id: pro.product_id,
            quantity: selectedReceipt ? pro.returnQuantity : pro.quantity,
            returnQuantity: selectedReceipt ? pro.returnQuantity : '',
            parent_quantity:
              selectedReceipt?.details?.length > 0
                ? selectedReceipt.details[index]?.quantity
                : 0,
            stock_id: pro.warehouse_id,
            expirationDate: pro.expirationDate
              ? moment(data.expirationDate).format('YYYY-MM-DD')
              : null,
            price: pro.price ? pro.price : '',
            tax: pro?.tax,
            desc: pro?.desc,
            including_tax: pro?.including_tax
          };
        else if (pro.product_id && pro.discount_type == 'no_discount') {
          return {
            id: pro.product_id,
            quantity: selectedReceipt ? pro.returnQuantity : pro.quantity,
            returnQuantity: selectedReceipt ? pro.returnQuantity : '',
            parent_quantity:
              selectedReceipt?.details?.length > 0
                ? selectedReceipt.details[index]?.quantity
                : 0,
            stock_id: pro.warehouse_id,
            expirationDate: pro.expirationDate
              ? moment(data.expirationDate).format('YYYY-MM-DD')
              : null,
            price: pro.price ? pro.price : '',
            tax: pro?.tax,
            desc: pro?.desc,
            including_tax: pro?.including_tax
          };
        }
        return null;
      });
      if (mappedProducts?.length > 0 && mappedProducts[0] !== null) {
        mappedData.details = JSON.stringify(mappedProducts);
      }
    }
    /////////////////
    if (data.ingredients?.length > 0 && data.details_type == '2') {
      // ingredients
      const mappedIngredients = data.ingredients.map((ing, index) => {
        if (
          ing.ingredient_id &&
          (ing.discount_type == '1' || ing.discount_type == '2')
        )
          return {
            id: ing.ingredient_id,
            quantity: ing.quantity,
            parent_quantity:
              selectedReceipt?.details?.length > 0
                ? selectedReceipt.details[index]?.quantity
                : 0,
            stock_id: ing.warehouse_id,
            discountType: ing.discount_type,
            discountValue:
              ing.discount_type === '1'
                ? ing.discount_price
                : ing.discount_percentage,
            expirationDate: ing.expirationDate
              ? moment(data.expirationDate).format('YYYY-MM-DD')
              : null,
            price: ing.price ? ing.price : '',
            tax: ing?.tax
          };
        else if (
          ing.ingredient_id &&
          !(ing.discount_type == '1' || ing.discount_type == '2')
        )
          return {
            id: ing.ingredient_id,
            quantity: ing.quantity,
            parent_quantity:
              selectedReceipt?.details?.length > 0
                ? selectedReceipt.details[index]?.quantity
                : 0,
            stock_id: ing.warehouse_id,
            expirationDate: ing.expirationDate
              ? moment(data.expirationDate).format('YYYY-MM-DD')
              : null,
            price: ing.price ? ing.price : '',
            tax: ing?.tax
          };

        return null;
      });
      if (mappedIngredients?.length > 0 && mappedIngredients[0] !== null) {
        mappedData.details = JSON.stringify(mappedIngredients);
      }
    }
    /////////////////

    mappedData.type = 1; // => buy receipt type
    mappedData.invoice_type = 2; //  1 => incoming purchase invoice, 2 => outgoing purchase invoice
    /////////////////
    if (data.extra_prices?.length > 0) {
      const mappedExtraPrices = data.extra_prices.map((extra) => {
        if (extra.price && extra.title) {
          return {
            price: extra.price,
            desc: extra.title
          };
        }
        return null;
      });
      if (mappedExtraPrices?.length > 0 && mappedExtraPrices[0] !== null) {
        mappedData.addPrices = JSON.stringify(mappedExtraPrices);
      }
    }
    /////////////////
    if (data.discount_type) {
      if (data.discount_type == '1' || data.discount_type == '2') {
        mappedData.discountType = data.discount_type;
      }
      if (data.discount_price && data.discount_type === '1') {
        mappedData.discountValue = data.discount_price;
      } else if (data.discount_percentage && data.discount_type === '2') {
        mappedData.discountValue = data.discount_percentage;
      }
    }
    /////////////
    if (data.pay_type) {
      mappedData.paymentType = data.pay_type;
      if (data.pay_type == '2') {
        // const mappedInstallments = data.installments.map((ins) => {
        //   return {
        //     price: ins.price ? ins.price : null,
        //     note: ins.note ? ins.note : null,
        //     date: ins.date ? moment(ins.date).format('YYYY-MM-DD') : null,
        //     status: ins.status ? ins.status : null
        //   };
        // });
        // if (mappedInstallments?.length > 0 && mappedInstallments[0] !== null) {
        //   mappedData.installments = JSON.stringify(mappedInstallments);
        // }

        if (data?.invoice_due_date) {
          mappedData.invoice_due_date = moment(data.invoice_due_date).format(
            'YYYY-MM-DD'
          );
        }
        mappedData.first_paid = data.first_paid ? data.first_paid : 0;
        if (data?.first_pay_type) {
          mappedData.first_pay_type = data.first_pay_type;
          if (data?.first_pay_type == '5') {
            if (data?.first_pay_img?.length) {
              mappedData.first_pay_img = data.first_pay_img[0];
            }
          }
        }
        if (renderFieldsTotalValue(watch('products'))) {
          mappedData.deferred = renderFieldsTotalValue(watch('products'));
        }
      } else if (mappedData.paymentType == '5') {
        if (data?.invoice_image?.length) {
          mappedData.bank_image = data.invoice_image[0];
        }
        if (renderFieldsTotalValue(watch('products'))) {
          mappedData.bank = renderFieldsTotalValue(watch('products'));
        }
      } else if (mappedData.paymentType == '6') {
        mappedData.totalInvoicePrice = renderFieldsTotalValue(
          watch('products')
        );
        if (data?.multiPaymentVisa) {
          mappedData.visa = data.multiPaymentVisa;
        }
        if (data?.multiPaymentCash) {
          mappedData.cash = data.multiPaymentCash;
        }
      }
    }

    setIsSubmittingForm(true);
    setIsLoading(true);

    if (selectedReceipt) {
      mappedData.parentInvoice = selectedReceipt.id;
      mappedData.type = 2; // => 2 is the type of return receipt

      const formData = new FormData();
      for (let key in mappedData) {
        formData.append(key, mappedData[key]);
      }
      customApiRequest(
        createIncomingPurchaseInvoiceApi(user?.token, formData, true),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Invoices.successNotification'),
              message: t('Invoices.invoiceReturned')
            });
          } else {
            errorNotification({
              title: t('Invoices.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Invoices.tryLaterMessage')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
        }
      );
    } else {
      const formData = new FormData();
      for (let key in mappedData) {
        formData.append(key, mappedData[key]);
      }
      customApiRequest(
        createIncomingPurchaseInvoiceApi(user?.token, formData, true),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);

          if (res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Invoices.successNotification'),
              message: t('Invoices.invoiceAdded')
            });
          } else {
            errorNotification({
              title: t('Invoices.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Invoices.tryLaterMessage')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
        }
      );
    }
  };
  // handle initial values
  useEffect(() => {
    if (selectedReceipt) {
      if (selectedReceipt?.userOrSupplier) {
        setValue('userOrSupplier', String(selectedReceipt.userOrSupplier));
      }
      if (selectedReceipt?.user?.id) {
        setValue('user_id', String(selectedReceipt.user.id));
      }
      if (selectedReceipt?.dueDate)
        setValue('due_date', moment(selectedReceipt.dueDate, 'YYYY-MM-DD'));
      if (selectedReceipt?.note) {
        setValue('note', selectedReceipt.note);
      }
      //
      if (selectedReceipt?.detailsType) {
        setValue('details_type', String(selectedReceipt.detailsType));
      }
      //
      if (selectedReceipt?.discountType) {
        if (
          selectedReceipt.discountType == '1' ||
          selectedReceipt.discountType == '2'
        ) {
          // 1 => fixed price discount
          // 2 => percentage discount
          setValue('discount_type', String(selectedReceipt.discountType));
        } else {
          setValue('discount_type', 'no_discount');
        }
      } else {
        setValue('discount_type', 'no_discount');
      }
      //
      if (
        String(selectedReceipt.discountValue) &&
        selectedReceipt.discountType == '1'
      ) {
        setValue('discount_price', selectedReceipt.discountValue);
      } else if (
        String(selectedReceipt.discountValue) &&
        selectedReceipt.discountType == '2'
      ) {
        setValue('discount_percentage', selectedReceipt.discountValue);
      }
      if (selectedReceipt?.paymentType) {
        setValue('pay_type', String(selectedReceipt.paymentType));
      }
      // if (selectedReceipt?.paymentType == '2') {
      //   if (selectedReceipt?.bankName) {
      //     setValue('bankAccount.accountNumber', selectedReceipt.bankName);
      //   }
      //   if (selectedReceipt?.cheekNum) {
      //     setValue('bankAccount.price', selectedReceipt.cheekNum);
      //   }
      // }
      if (selectedReceipt?.paymentType == '5') {
        if (selectedReceipt?.bankName) {
          setValue('bankAccount.accountNumber', selectedReceipt.bankName);
        }
        if (selectedReceipt?.cheekNum) {
          setValue('bankAccount.price', selectedReceipt.cheekNum);
        }
      }
      if (selectedReceipt?.paymentType == '6') {
        if (selectedReceipt?.visa) {
          setValue('multiPaymentVisa', selectedReceipt.visa);
        }
        if (selectedReceipt?.cash) {
          setValue('multiPaymentCash', selectedReceipt.cash);
        }
      }

      //
    }
    /////////////////////////
    if (selectedReceipt) {
      // const { products, ingredients, extraPrices, installments } =
      //   selectedReceipt;

      const sharedReset = {
        userOrSupplier: selectedReceipt?.userOrSupplier
          ? String(selectedReceipt.userOrSupplier)
          : '',
        user_id: selectedReceipt?.user?.id
          ? String(selectedReceipt.user.id)
          : '',
        due_date: selectedReceipt.dueDate
          ? moment(selectedReceipt.dueDate, 'YYYY-MM-DD')
          : null,
        note: selectedReceipt.note,
        details_type: selectedReceipt.detailsType
          ? String(selectedReceipt.detailsType)
          : '',
        //
        discount_type: selectedReceipt.discountType
          ? String(selectedReceipt.discountType)
          : 'no_discount',
        discount_price:
          selectedReceipt?.discountType == '1' &&
          String(selectedReceipt.discountValue)
            ? selectedReceipt.discountValue
            : '',
        discount_percentage:
          selectedReceipt?.discountType == '2' &&
          String(selectedReceipt.discountValue)
            ? selectedReceipt.discountValue
            : '',
        //
        pay_type: selectedReceipt.paymentType
          ? String(selectedReceipt.paymentType)
          : '',
        first_pay_img: '',
        bankAccount: {
          accountNumber:
            selectedReceipt?.bankName && selectedReceipt?.paymentType == '5'
              ? selectedReceipt.bankName
              : '',
          price:
            selectedReceipt?.cheekNum && selectedReceipt?.paymentType == '5'
              ? selectedReceipt.cheekNum
              : ''
        }
      };
      if (selectedReceipt.details?.length > 0) {
        const { details } = selectedReceipt;
        let mappedProducts = [];
        let mappedIngredients = [];
        let mappedInstallments = [];
        if (selectedReceipt?.detailsType == '1') {
          mappedProducts = details.map((product) => ({
            product_id: String(product.model_id),
            quantity: product.quantity,
            warehouse_id: product.stock?.id ? String(product.stock.id) : '',
            expirationDate: product.expirationDate
              ? moment(product.expirationDate, 'YYYY-MM-DD')
              : null,
            discount_type: product.discountType
              ? String(product.discountType)
              : 'no_discount',
            discount_price:
              product?.discountType == '1' && String(product.discountValue)
                ? product.discountValue
                : '',
            discount_percentage:
              product?.discountType == '2' && String(product.discountValue)
                ? product.discountValue
                : '',
            price: product?.price ? product.price : '',
            tax: parseFloat(product?.tax) > 0 ? product.tax : '0'
          }));
        } else if (selectedReceipt?.detailsType == '2') {
          mappedIngredients = details.map((ingredient) => ({
            ingredient_id: String(ingredient.model_id),
            quantity: ingredient.quantity,
            warehouse_id: ingredient.stock?.id
              ? String(ingredient.stock.id)
              : '',
            expirationDate: ingredient.expirationDate
              ? moment(ingredient.expirationDate, 'YYYY-MM-DD')
              : null,
            discount_type: ingredient.discountType
              ? String(ingredient.discountType)
              : 'no_discount',
            discount_price:
              ingredient?.discountType == '1' &&
              String(ingredient.discountValue)
                ? ingredient.discountValue
                : '',
            discount_percentage:
              ingredient?.discountType == '2' &&
              String(ingredient.discountValue)
                ? ingredient.discountValue
                : '',
            price: ingredient?.price ? ingredient.price : '',
            tax: parseFloat(ingredient?.tax) > 0 ? ingredient.tax : '0'
          }));
        }
        //

        const mappedExtraPrices =
          selectedReceipt?.addPrices?.length > 0 &&
          selectedReceipt.addPrices.map((obj) => ({
            price: obj.price,
            title: obj.desc
          }));

        if (
          selectedReceipt.paymentType == '2' &&
          selectedReceipt.Installment?.length > 0
        ) {
          mappedInstallments = selectedReceipt.Installment.map((ins) => ({
            price: ins.price,
            //  status: String(ins.status),
            note: ins.note,
            date: moment(ins.date, 'YYYY-MM-DD')
          }));
        }

        reset({
          ...sharedReset,
          products: details?.length > 0 ? [...mappedProducts] : [],
          ingredients: details?.length > 0 ? [...mappedIngredients] : [],
          extra_prices:
            selectedReceipt?.addPrices?.length > 0
              ? [...mappedExtraPrices]
              : [],
          installments:
            selectedReceipt.Installment?.length > 0
              ? [...mappedInstallments]
              : []
        });
      }
    }
  }, [selectedReceipt, selectedReceipt?.id, reset]);

  const [form] = Form.useForm();
  return (
    <Form
      className="create-receipt-form custom-shared-form outgoing-invoice-form"
      form={form}
      layout="vertical"
      // initialValues={{
      //   products: watch('products')
      // }}
      fields={[
        {
          name: 'details_type',
          value: watch('details_type')
        },
        {
          name: 'discount_type',
          value: watch('discount_type')
        },
        {
          name: 'pay_type',
          value: watch('pay_type')
        },
        {
          name: 'products',
          value: watch('products')
        },
        {
          name: 'ingredients',
          value: watch('ingredients')
        },
        {
          name: 'installments',
          value: watch('installments')
        }
      ]}
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body sssss">
        {selectedReceipt?.user?.name && (
          <div className="supplier-name-view">
            <span> {t('SalesInvoices.InvoiceClient')}: </span>
            <span> ( {t('SalesInvoices.Simplified')} ) </span>
            <span className="supp-name">{selectedReceipt.user?.name}</span>
          </div>
        )}
        {selectedReceipt?.supplier?.name && (
          <div className="supplier-name-view">
            <span>
              {' '}
              ( {t('SalesInvoices.Taxer')} ){' '}
              {t('SalesInvoices.InvoiceSupplier')}:{' '}
            </span>
            <span className="supp-name">{selectedReceipt.supplier?.name}</span>
          </div>
        )}
        {/* {!selectedReceipt && (
          <div className="radios-wrap">
            <div className="labels-wrap">
              {[
                // {
                //   title: ' عميل ( فاتورة مبسطة)',
                //   value: '1'
                // },
                {
                  title: t('Quotations.taxInvoice'),
                  // title: ' عميل ( فاتورة ضريبية)',
                  value: '2'
                }
              ].map((obj, i) => (
                <label
                  key={i}
                  className={`radio-input-label ${
                    String(watch(`userOrSupplier`)) === String(obj.value)
                      ? 'selected'
                      : ''
                  }`}
                >
                  <input
                    type="radio"
                    value={obj.value}
                    {...register('userOrSupplier')}
                  />
                  {watch(`userOrSupplier`) == String(obj.value) ? (
                    <RadioButtonFilled />
                  ) : (
                    <RadioButtonEmpty />
                  )}
                  <span className="label-span">{obj.title}</span>
                </label>
              ))}
            </div>

            {errors?.userOrSupplier && (
              <p className="error-p">{errors.userOrSupplier.message}</p>
            )}
          </div>
        )} */}
        {!selectedReceipt && watch('userOrSupplier') == 1
          ? renderSuppliersTabs()
          : null}
        {!selectedReceipt && watch('userOrSupplier') == 2
          ? renderSuppliersTabs()
          : null}
        <div className="date-picker-wrap">
          {/* <p className="label-p">تاريخ الاستحقاق ( DD / MM / YYYY )</p> */}
          <p className="label-p">{t('Quotations.SupplyDate')}</p>
          <CustomAntdDatePicker
            // disablePast
            control={control}
            name="due_date"
            className="receipt-date-picker"
            formClassName="outgoing-invoice-form"
          />
          {errors?.due_date && (
            <p className="error-p">{errors.due_date.message}</p>
          )}
        </div>
        {/* <div className="text-field-label-wrap">
          <p className="label-p">{t('Quotations.Reviews')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="note"
              rows={8}
              placeholder={`${t('Quotations.Reviews')}`}
              errorMsg={errors?.note?.message}
              validateStatus={errors?.note ? 'error' : ''}
              control={control}
            />
          </div>
        </div> */}
        <div
          className="radios-field-array-wrap no-border"
          style={{ marginBottom: 0 }}
        >
          {/* <AntdRadioGroup
            name="details_type"
            className="form-radio-group"
            control={control}
            label={t('Quotations.SelectInvoiceType')}
            validateStatus={errors?.details_type ? 'error' : ''}
            errorMsg={errors?.details_type?.message}
            radios={receiptRadiosArr}
            // defaultValue={
            //   selectedReceipt?.detailsType
            //     ? String(selectedReceipt.detailsType)
            //     : ''
            // }
            // isRadioButton
          /> */}

          {String(watch('details_type')) == '1' && (
            <div className="products-add-btn-wrap">
              <RenderProductsFieldsProducts
                allProducts={allProducts}
                setFilterValues={setFilterValues}
                allWarehouses={allWarehouses}
                productsFields={productsFields}
                removeProductField={removeProductField}
                selectedReceipt={selectedReceipt}
                watch={watch}
                errors={errors}
                setValue={setValue}
                control={control}
                register={register}
                systemSettings={systemSettings}
                removeValidityData={true}
                setFetchCount={setFetchCount}
              />
              {watch('products')?.length > 0 &&
                watch(`products[0].product_id`) && (
                  <div className="total-fields-price">
                    {t('Quotations.ProductsPrice')} :{' '}
                    <span className="total-value">
                      {renderFieldsTotalValue(watch('products'))}
                    </span>
                  </div>
                )}
            </div>
          )}

          {String(watch('details_type')) === '2' && (
            <div className="ingredients-add-btn-wrap">
              <div
                className="add-new-field-btn"
                style={{
                  marginBottom: 12
                }}
                onClick={() =>
                  appendIngredientField({
                    ingredient_id: '',
                    quantity: '',
                    warehouse_id: '',
                    expirationDate: '',
                    discount_type: '',
                    discount_price: '',
                    discount_percentage: '',
                    price: '',
                    tax: '0',
                    including_tax: ''
                  })
                }
              >
                <span>+ {t('Quotations.NewComponent')}</span>
              </div>
              {RenderIngredientsFields(
                watch,
                allIngredients,
                allWarehouses,
                control,
                setValue,
                errors,
                ingredientsFields,
                removeIngredientField,
                selectedReceipt,
                systemSettings
              )}
              {watch('ingredients')?.length > 0 &&
                watch(`ingredients[0].ingredient_id`) && (
                  <div className="total-fields-price">
                    <span className="total-title">سعر المكونات : </span>
                    <span className="total-value">
                      {renderFieldsTotalValue(watch('ingredients'))}
                    </span>
                  </div>
                )}
            </div>
          )}
        </div>

        <div
          /*   style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '10px'
          }} */
          className="newPriceContainer"
        >
          {/* extra prices section */}
          {(watch('details_type') == '1' || watch('details_type') == '2') && (
            <div
              className="fields-array-ul-wrapper"
              /*  style={{
                borderTop: '1px solid rgba(0, 0, 0, .1)'
              }} */
            >
              <div
                className="add-new-field-btn"
                onClick={() =>
                  appendExtraPriceField({
                    price: '',
                    title: ''
                  })
                }
              >
                <span>+ {t('Quotations.NewExtraPrice')}</span>
              </div>

              {watch('extra_prices')?.length > 0 &&
                watch(`extra_prices[0].price`) && (
                  <div className="total-fields-price">
                    {t('Quotations.PriceAfterAdditions')} :{' '}
                    <span className="total-value">
                      {renderTotalPriceAfterExtraPrices(
                        watch('details_type'),
                        watch('products'),
                        watch('ingredients'),
                        watch('extra_prices')
                      )}
                    </span>
                  </div>
                )}
            </div>
          )}
          {/* ./extra prices section */}

          {/* add new price */}
          {!selectedReceipt && (
            <div
              className="add-new-field-btn"
              onClick={() =>
                appendProductField({
                  product_id: '',
                  quantity: '',
                  returnQuantity: '',
                  warehouse_id: '',
                  expirationDate: '',
                  discount_type: 'no_discount',
                  discount_price: '',
                  discount_percentage: '',
                  price: '',
                  tax: '0',
                  including_tax: ''
                })
              }
            >
              <span>+ {t('Quotations.NewProduct')}</span>
            </div>
          )}
          {/* ./add new price */}
        </div>
        <div>
          {renderExtraPricesFields(
            control,
            setValue,
            errors,
            extraPricesFields,
            removeExtraPriceField
          )}
        </div>
        {/* paytype section */}
        {(watch('details_type') == '1' || watch('details_type') == '2') && (
          <div
            className="radios-field-array-wrap no-border"
            style={{ paddingBottom: '22px' }}
          >
            <AntdRadioGroup
              name="pay_type"
              className="form-radio-group"
              control={control}
              label={t('Quotations.SelectPaymentType')}
              validateStatus={errors?.pay_type ? 'error' : ''}
              errorMsg={errors?.pay_type?.message}
              radios={paymentMethods}
            />
            {String(watch('pay_type')) == '2' && (
              <div
                className="ingredients-add-btn-wrap"
                style={{
                  display: 'grid',
                  alignItems: 'start',
                  gridTemplateColumns: '1fr 1fr',
                  width: '100%',
                  gap: '0 30px'
                }}
              >
                {/* <div
                  className="add-new-field-btn"
                  onClick={() =>
                    appendInstallmentField({
                      price: '',
                      date: null,
                      note: '',
                      status: ''
                    })
                  }
                >
                  <span>+ اضافة ايصال جديد</span>
                </div>
                {renderInstallmentsFields(
                  control,
                  setValue,
                  errors,
                  installmentsFields,
                  removeInstallmentField
                )} */}
                {/* <div className="" style={{ width: '45%' }}>
                  <p style={{ marginBottom: '8px' }}>إسم العميل</p>
                  <div className="custom-select-wrap without-icon">
                    <AntdSelectOption
                      name={`deferred_invoice_client`}
                      errorMsg={
                        errors?.deferred_invoice_client &&
                        errors.deferred_invoice_client.message
                      }
                      validateStatus={
                        errors?.deferred_invoice_client ? 'error' : ''
                      }
                      control={control}
                      setValue={setValue}
                      placeholder={'إختار إسم العميل'}
                      options={
                        allClients?.length > 0 &&
                        allClients.map((role) => ({
                          title: role.name,
                          value: String(role.id)
                        }))
                      }
                      showSearch={true}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      formClassName="outgoing-invoice-form"
                    />
                  </div>
                </div> */}

                <div className="date-picker-wrap">
                  <p style={{ marginBottom: '8px' }}>
                    {t('Quotations.dateOfPayment')}( DD / MM / YYYY )
                  </p>
                  <CustomAntdDatePicker
                    // disablePast
                    control={control}
                    name={`invoice_due_date`}
                    className="receipt-date-picker"
                    formClassName="create-receipt-form"
                    errorMsg={
                      errors?.invoice_due_date &&
                      errors.invoice_due_date.message
                    }
                    validateStatus={errors?.invoice_due_date ? 'error' : ''}
                    setValue={setValue}
                    disablePast={true}
                  />
                </div>
                <div className="text-field-label-wrap">
                  <p className="label-p">{t('Quotations.FirstInstallment')}</p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      setValue={setValue}
                      className="form-text-field"
                      name={`first_paid`}
                      type="text"
                      placeholder={t('Quotations.EnterFirstInstallmentAmount')}
                      errorMsg={
                        errors?.first_paid && errors?.first_paid?.message
                      }
                      validateStatus={errors?.first_paid ? 'error' : ''}
                      control={control}
                    />
                  </div>
                </div>
                <div className="text-field-label-wrap">
                  <div className="text-field-wrap">
                    <AntdRadioGroup
                      name="first_pay_type"
                      className="form-radio-group"
                      control={control}
                      label={t('Quotations.PaymentTypeForFirstInstallment')}
                      validateStatus={errors?.first_pay_type ? 'error' : ''}
                      errorMsg={errors?.first_pay_type?.message}
                      // radios={paymentMethods}
                      radios={[
                        {
                          title: t('Invoices.cash'),
                          value: '1'
                        },
                        {
                          title: t('Invoices.visa'),
                          value: '3'
                        },
                        {
                          title: t('Invoices.bankTransfer'),
                          value: '5'
                        }
                      ]}
                      // isRadioButton
                    />
                  </div>
                </div>
              </div>
            )}
            {String(watch('first_pay_type')) == '5' && (
              <div className="product-img-wrap">
                <h3 style={{ marginBottom: '6px' }}>
                  {t('Quotations.ReceiptImage')}
                </h3>
                <MyDropZone
                  className="product-header-dropzone"
                  multipleFiles={false}
                  handleFilesDrop={(acceptedFiles) =>
                    handleFilesDrop(
                      acceptedFiles,
                      invoiceFileToUploadDefferd,
                      setInvoiceFileToUploadDefferd
                    )
                  }
                  filesToUpload={invoiceFileToUploadDefferd}
                  id="avatar-input"
                  dropzoneText={t('Quotations.ProductImage')}
                  inputName="first_pay_img"
                  icon={<CameraFilled className="dropzone-camera" />}
                  dropZoneUrls={
                    watch('first_pay_img')?.file
                      ? [watch('first_pay_img')?.file]
                      : null
                  }
                >
                  {t('Quotations.DragAndDropImageHere')}
                </MyDropZone>

                <MyDropZonePreview
                  filesToUpload={invoiceFileToUploadDefferd}
                  clearFileFromUpload={(f) =>
                    clearFileFromUpload(f, setInvoiceFileToUploadDefferd)
                  }
                />
                {errors?.first_pay_img && (
                  <p
                    className="error-p"
                    style={{ color: '#ff4d4f', fontSize: '14px' }}
                  >
                    {errors?.first_pay_img?.message}
                  </p>
                )}
              </div>
            )}
            {String(watch('pay_type')) == '5' && (
              // <div className="cheque-wrapper">
              //   <div className="text-field-label-wrap">
              //     <p className="label-p">رقم الحساب</p>
              //     <div className="text-field-wrap">
              //       <AntdTextField
              //         setValue={setValue}
              //         className="form-text-field"
              //         name={`bankAccountNumber`}
              //         type="text"
              //         placeholder="رقم الحساب..."
              //         errorMsg={
              //           errors?.bankAccountNumber &&
              //           errors.bankAccountNumber.message
              //         }
              //         validateStatus={errors?.bankAccountNumber ? 'error' : ''}
              //         control={control}
              //       />
              //     </div>
              //   </div>
              //   <div className="text-field-label-wrap">
              //     <p className="label-p">المبلغ</p>
              //     <div className="text-field-wrap">
              //       <AntdTextField
              //         setValue={setValue}
              //         className="form-text-field"
              //         name={`bankPrice`}
              //         type="text"
              //         placeholder="المبلغ..."
              //         errorMsg={errors?.bankPrice && errors.bankPrice.message}
              //         validateStatus={errors?.bankPrice ? 'error' : ''}
              //         control={control}
              //       />
              //     </div>
              //   </div>
              // </div>
              <div className="product-img-wrap">
                <h3 style={{ marginBottom: '6px' }}>
                  {t('Quotations.ReceiptImage')}
                </h3>

                <MyDropZone
                  className="product-header-dropzone"
                  multipleFiles={false}
                  handleFilesDrop={(acceptedFiles) =>
                    handleFilesDrop(
                      acceptedFiles,
                      invoiceFileToUpload,
                      setInvoiceFileToUpload
                    )
                  }
                  filesToUpload={invoiceFileToUpload}
                  id="avatar-input"
                  dropzoneText={t('Quotations.ProductImage')}
                  inputName="invoice_image"
                  icon={<CameraFilled className="dropzone-camera" />}
                  dropZoneUrls={
                    watch('invoice_image')?.file
                      ? [watch('invoice_image')?.file]
                      : null
                  }
                >
                  {t('Quotations.DragAndDropImageHere')}
                </MyDropZone>

                <MyDropZonePreview
                  filesToUpload={invoiceFileToUpload}
                  clearFileFromUpload={(f) =>
                    clearFileFromUpload(f, setInvoiceFileToUpload)
                  }
                />
                {errors?.invoice_image && (
                  <p
                    className="error-p"
                    style={{ color: '#ff4d4f', fontSize: '14px' }}
                  >
                    {errors?.invoice_image?.message}
                  </p>
                )}
              </div>
            )}
            {String(watch('pay_type')) == '6' && (
              <div className="cheque-wrapper">
                <div className="text-field-label-wrap">
                  <p className="label-p">{t('Quotations.cash')}</p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      setValue={setValue}
                      className="form-text-field"
                      name={`multiPaymentCash`}
                      type="text"
                      placeholder={t('Quotations.cashAmount')}
                      errorMsg={
                        errors?.multiPaymentCash &&
                        errors?.multiPaymentCash?.message
                      }
                      validateStatus={errors?.multiPaymentCash ? 'error' : ''}
                      control={control}
                      defaultValue={
                        watch('multiPaymentVisa')
                          ? renderFieldsTotalValue(watch('products')) -
                              parseFloat(watch('multiPaymentVisa')) >
                            0
                            ? Math.ceil(
                                parseFloat(
                                  renderFieldsTotalValue(watch('products')) -
                                    parseFloat(watch('multiPaymentVisa'))
                                ) * 100
                              ) / 100
                            : 0
                          : null
                      }
                    />
                  </div>
                  {/* {Number(renderFieldsTotalValue(watch('products'))) &&
                  !errors.multiPaymentCash
                    ? renderFieldsTotalValue(watch('products')) <
                        watch('multiPaymentCash') && (
                        <p
                          className="error-p"
                          style={{ color: '#ff4d4f', fontSize: '14px' }}
                        >
                          من فضلك أدخل رقم أقل من قيمة الفاتورة
                        </p>
                      )
                    : null} */}
                </div>
                <div className="text-field-label-wrap">
                  <p className="label-p">{t('Quotations.Network')}</p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      setValue={setValue}
                      className="form-text-field"
                      name={`multiPaymentVisa`}
                      type="text"
                      placeholder={t('Quotations.EnterNetworkAmount')}
                      errorMsg={
                        errors?.multiPaymentVisa &&
                        errors.multiPaymentVisa.message
                      }
                      validateStatus={errors?.multiPaymentVisa ? 'error' : ''}
                      control={control}
                      defaultValue={
                        watch('multiPaymentCash')
                          ? renderFieldsTotalValue(watch('products')) -
                              parseFloat(watch('multiPaymentCash')) >
                            0
                            ? Math.ceil(
                                parseFloat(
                                  renderFieldsTotalValue(watch('products')) -
                                    parseFloat(watch('multiPaymentCash'))
                                ) * 100
                              ) / 100
                            : 0
                          : null
                      }
                    />
                  </div>
                  {/* {Number(renderFieldsTotalValue(watch('products'))) &&
                  !errors.multiPaymentVisa
                    ? renderFieldsTotalValue(watch('products')) <
                        watch('multiPaymentVisa') && (
                        <p
                          className="error-p"
                          style={{ color: '#ff4d4f', fontSize: '14px' }}
                        >
                          من فضلك أدخل رقم أقل من قيمة الفاتورة
                        </p>
                      )
                    : null} */}
                </div>
              </div>
            )}
          </div>
        )}
        {/* ./paytype section */}
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {t('Quotations.Add')}
        </Button>
      </div>
    </Form>
  );
};

export default TableFormForProducts;
