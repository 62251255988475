/* eslint-disable eqeqeq */
import { EyeOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import deleteSinglePriceOfferApiNew from '../../../apis/new-price-offers-apis/deleteSinglePriceOfferApiNew';
import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import { useTranslation } from 'react-i18next';

const TableColumns = (
  allFetchedReceipts,
  setModalOpened,
  setFetchCount,
  setSelectedReceipt,
  setIsLoading,
  setSelectedReceiptModalOpened,
  setConvertModalOpened,
  token
) => {
  const arr =
    allFetchedReceipts?.invoices?.length > 0
      ? allFetchedReceipts.invoices
      : allFetchedReceipts;
  const { t } = useTranslation();

  const handleViewReceiptClick = (record) => {
    const foundReceipt =
      arr?.length > 0 && arr.find((receipt) => receipt.id === record.id);
    if (foundReceipt) {
      setSelectedReceipt({ ...foundReceipt });
      setSelectedReceiptModalOpened(true);
    }
  };
  const handleEditProduct = (record) => {
    const found = allFetchedReceipts.find((place) => place.id === record.id);
    if (found) {
      setSelectedReceipt({ ...found });
      setModalOpened(true);
    }
  };
  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteSinglePriceOfferApiNew(
        token,
        id,
        `/OfferPriceService/delete`
      );

      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Quotations.SuccessOperation'),
          message: t('Quotations.ProductDeletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Quotations.ErrorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Quotations.PleaseTryAgainLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Quotations.ErrorOccurred'),
        message: t('Quotations.TryAgainLater')
      });
    }
  };
  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap">
        <div
          className="edit-btn"
          onClick={(e) => {
            handleEditProduct(record);
          }}
        >
          <EditIcon />
          {t('Quotations.update')}
        </div>

        <Popconfirm
          title={t('Quotations.ConfirmProductDeletion')}
          onConfirm={() => handleDeleteRequest(record.id)}
          okText={t('Quotations.ok')}
          cancelText={t('Quotations.no')}
        >
          <Tooltip title={t('Quotations.deleteProduct')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  const renderConvert = (record) => {
    return (
      <div className="row-cell row-circled-btn">
          {record?.type == 2 ? (
          <div
            style={{fontWeight:"bold"}}
          >
            {t('Quotations.offerConverted')}
          </div>
        ):(
          <div
          className="bt-target"
          onClick={() => {
            setConvertModalOpened(true);
            const found = allFetchedReceipts.find(
              (place) => place.id === record.id
            );
            if (found) {
              setSelectedReceipt({ ...found });
            }
          }}
        >
          {t('Quotations.Convert')}
        </div>
        )}
        
      </div>
    );
  };
  return [
    // {
    //   title: 'مسلسل',
    //   dataIndex: 'id',
    //   // width: 62
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-index bold-font">
    //         <span>{record.invoiceNumber}</span>
    //       </div>
    //     );
    //   }
    // },
    {
      title: t('Quotations.OfferNo'),
      dataIndex: 'manualNum',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.manualNum ? record.manualNum : '--'}
          </div>
        );
      }
    },
    {
      title: t('Quotations.ClientName'),
      dataIndex: 'user',
      render: (_, record) => {
        return (
          <div className="row-cell row-text desc-wrap">
            {/* {record.name ? record.name : '----'} */}
            {record.user?.name
              ? record.user.name
              : record?.supplier?.name
              ? record.supplier.name
              : '----'}
          </div>
        );
      }
    },
    {
      title: t('Quotations.PhoneNumber'),
      dataIndex: 'phone',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.user?.phone ? record?.user?.phone : '----'}
          </div>
        );
      }
    },
    {
      title: t('Quotations.ReleaseDate'),
      dataIndex: 'date',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.date ? record.date : '----'}
          </div>
        );
      }
    },

    {
      title: t('Quotations.OfferExpiryDate'),
      dataIndex: 'dueDate',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.dueDate ? record.dueDate : '----'}
          </div>
        );
      }
    },
    {
      title: t('Quotations.Time'),
      dataIndex: 'time',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.time ? record.time : '----'}
          </div>
        );
      }
    },

    // {
    //   title: 'ملاحظات',
    //   dataIndex: 'note',
    //   render: (_, record) => {
    //     if (record.note) {
    //       const wordsCount = countWords(String(record.note));
    //       if (wordsCount > 3) {
    //         return (
    //           <Popover title="اقرأ المزيد" content={String(record.note)}>
    //             <Button size="large" type="text">
    //               <p className="has-read-more">
    //                 <span>{trimWords(String(record.note), 4)}</span>
    //                 <ReadOutlined
    //                   className="read-more-book"
    //                   style={{ margin: '0 4px' }}
    //                 />
    //               </p>
    //             </Button>
    //           </Popover>
    //         );
    //       } else
    //         return (
    //           <div className="row-cell row-text">{String(record.note)}</div>
    //         );
    //     } else {
    //       return '----';
    //     }
    //   }
    // },

    {
      title: t('Quotations.TotalPrice'),
      dataIndex: 'totalPrice',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.totalPrice ? record.totalPrice : '----'}
          </div>
        );
      }
    },
    // {
    //   title: 'إيصالات عرض السعر',
    //   render: (_, record) => {
    //     return record?.paymentType == 2 ? (
    //       <RouterLink
    //         className="row-cell row-circled-btn"
    //         to={routerLinks.saleInvoiceInstallmentsPage(record.id)}
    //       >
    //         <Tooltip title="إيصالات عرض السعر">
    //           <Button
    //             className="circled-btn"
    //             type="dashed"
    //             shape="circle"
    //             icon={<EyeOutlined />}
    //           />
    //         </Tooltip>
    //       </RouterLink>
    //     ) : (
    //       '----'
    //     );
    //   }
    // },
    {
      title: t('Quotations.PreviewYourQuote'),
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            <Tooltip title={t('Quotations.PreviewYourQuote')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => handleViewReceiptClick(record)}
              />
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: t('Quotations.TransferToInvoice'),
      dataIndex: 'convert',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderConvert(record)}</div>
        );
      }
    },
    {
      title: t('Quotations.settings'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default TableColumns;
