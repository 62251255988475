import { useContext } from "react";
import UserContext from "../contexts/user-context/UserProvider";
import Cookies from "js-cookie";
import { useHistory } from 'react-router-dom';
import Links from "../components/app/Links";
import useSignOut from "./useSignout";
const checkSuccessResponse = (res) => {
  // return res?.status === 200 && res?.data?.status === 1;
  return (
    res?.status === 200
    // res?.status === 200 && res?.data?.status === 1
  );
};

const checkUnAuthenticated = (err) => {
  return err?.response?.status === 401;
};

const useCustomApiRequest = () => {
  const history = useHistory();
  // const { signMeOut } = useSignOut();
  // const handleSignOut = () => {
  //   signMeOut();
  // };
  const { isAuthenticated,setIsAuthenticated, setIsAuthenticatedCount } = useContext(UserContext);
  const customApiRequest = async (req, successCallback, errorCallback) => {
    try {
      const res = await req;
      if (checkSuccessResponse(res)) {
        successCallback &&
          typeof successCallback === 'function' &&
          successCallback(res);
      }
    } catch (error) {
      if (errorCallback && typeof errorCallback === 'function') {
        if (checkUnAuthenticated(error)) {
          setIsAuthenticated(true)
          // setIsAuthenticatedCount((prev) => prev + 1)
          // isAuthenticated && handleSignOut();
          window.location.reload();
          sessionStorage.removeItem('currentUser');
          Cookies.remove('currentUser');
          history.push(Links().signInPage);
          return;
        }
        errorCallback(error);
      }
    }
  };

  return customApiRequest;
};

export default useCustomApiRequest;
