import React, { useContext, useEffect, useState } from 'react';
import useCustomApiRequest from '../../../../custom-hooks/useCustomApiRequest';
import UserContext from '../../../../contexts/user-context/UserProvider';
import getSystemFeaturesApi from '../../../../apis/settings-apis/system-settings-api/getSystemFeaturesApi';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
const SystemFeatures = () => {
  const customApiRequest = useCustomApiRequest();
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [systemFeatures, setSystemFeatures] = useState();
  useEffect(() => {
    customApiRequest(
      getSystemFeaturesApi(user?.token),
      (res) => {
        if (res?.data?.data) {
          setSystemFeatures(res?.data?.data);
        }
      },
      (error) => {}
    );
  }, []);
  return (
    <div className="system-features">
      <h2>{t('SystemSettings.systemFeatures')}</h2>
      <div className="all-features">
        {systemFeatures?.length > 0 ? (
          systemFeatures?.map((feature) => (
            <div className="every-feature">
              <img src={feature?.icon} alt="" />
              <div>
                <h3>{feature?.title}</h3>
                <p>{feature?.body}</p>
              </div>
            </div>
          ))
        ) : (
          <div className="no-features">
            <Spin />
          </div>
        )}
      </div>
    </div>
  );
};

export default SystemFeatures;
