/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SearchNotificationsProfile from '../../components/search-notifications-profile/SearchNotificationsProfile';
import UserInstallmentModal from './UserInstallmentModal';
import warehouseImg from '../../assets/imgs/store/store-item-manager.png';
import StoreWelcomeSharedSection from '../store-pages/store-welcome-shared-section/StoreWelcomeSharedSection';
import InstallmentsPageContext from '../../contexts/installments-context/InstallmentsContext';
// import UserInstallmentsTableFilter from './UserInstallmentsTableFilter';
import UserInstallmentsPageTable from './UserInstallmentsPageTable';
import UserContext from '../../contexts/user-context/UserProvider';
import getAllUserInstallmentsApi from '../../apis/store-apis/installments-api/getAllUserInstallmentsApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import './userInstallmentsPage.scss';
import { useTranslation } from 'react-i18next';
const UserInstallmentsPage = () => {
  const { userId, supplierId } = useParams();
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  const {
    modalOpened,
    fetchCount,
    setIsLoading,
    allFetchedInstallments,
    setAllFetchedInstallments
  } = useContext(InstallmentsPageContext);
  const { user } = useContext(UserContext);

  const hanldeType = () => {
    if (userId) return 'user';
    else if (supplierId) return 'supplier';
    return '';
  };
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllUserInstallmentsApi(
          user?.token,
          { userId, supplierId },
          hanldeType()
        ),
        (res) => {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            const data = res.data.data;
            setAllFetchedInstallments(data);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  return (
    <div className="installments-page">
      <div className="page-head-wrap">
        <StoreWelcomeSharedSection
          img={warehouseImg}
          title={t('Receipts.title')}
          subTitle={t('Receipts.subTitle')}
        />
        <div className="search-wrap">
          <SearchNotificationsProfile noSearch />
        </div>
      </div>

      <div className="custom-filter-add-section">
        {/* <div className="add-section">
          <div
            className="add-btn"
            onClick={() => {
              // if selected unit then reset it
              // open the unit modal
              setSelectedIntallment(null);
              setModalOpened(true);
            }}
          >
            <span className="text-span">إضافة قسط جديد</span>
          </div>
        </div> */}
        {/* <UserInstallmentsTableFilter /> */}
      </div>

      <h2>{t('Receipts.purchaseReceipts')}</h2>
      <UserInstallmentsPageTable
        arr={allFetchedInstallments?.buyInstallment}
        arrType="buy"
        currentLang={currentLang}
      />

      <h2>{t('Receipts.saleReceipts')}</h2>
      <div className="UserInstallmentsPage-table-wrapper">
        <UserInstallmentsPageTable
          arr={allFetchedInstallments?.saleInstallment}
          arrType="sale"
          currentLang={currentLang}
        />
      </div>

      {modalOpened && <UserInstallmentModal />}
    </div>
  );
};

export default UserInstallmentsPage;
