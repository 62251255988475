/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { CameraFilled, DeleteOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Tooltip } from 'antd';
import { EditorState } from 'draft-js';
import React, { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import addNewProductApi from '../../apis/store-apis/products/addNewProductApi';
import AntdRadioGroup from '../../common/antd-form-components/AntdRadioGroup';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import CustomEditor from '../../common/custom-editor/CustomEditor';
import MyDropZone from '../../common/dorp-zone/MyDropZone';
import MyDropZonePreview from '../../common/dorp-zone/MyDropZonePreview';
import RadioButtonEmpty from '../../common/icons/RadioButtonEmpty';
import RadioButtonFilled from '../../common/icons/RadioButtonFilled';
import StoreNewProductModalContext from '../../contexts/store-new-product-context/StoreNewProductProvider';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import useIngredients from '../../custom-hooks/useIngredients';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
import errorNotification from '../../utils/errorNotification';
import successNotification from '../../utils/successNotification';

const StoreNewProductForm = ({ setAllProductsFetchCount }) => {
  const { user } = React.useContext(UserContext);
  const systemSettings = useSystemSettings();
  const [isEditModal, setIsEditModal] = useState(false);
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    type: Yup.string().required(t('services.chooseServiceTypeValidation')),
    product_name: Yup.string().required(t('services.enterServiceName')),
    product_selling_price: Yup.string()
      .required(t('services.enterSellingPrice'))
      .matches(/^(\d+)?(\.\d+)?$/, t('services.onlyNumbersValidation')),
    // product_description: Yup.string().test(
    //   'product_description',
    //   'من فضلك ادخل تفاصيل الخدمة',
    //   (v) => {
    //     let result = true;
    //     if (!v || v === '<p><br></p>') {
    //       result = false;
    //     }
    //     return result;
    //   }
    // ),
    tax: Yup.string()
      .matches(/^(\d+)?(\.\d+)?$/, t('services.onlyNumbersValidation'))
      .test('', t('services.taxRangeValidation'), (v) => v <= 100),
    ingredients: Yup.array()
      // test select options and radio buttons
      .of(
        Yup.object().shape({
          ingredient_id: Yup.string().test(
            'ingrediants.index.ingredient_id',
            t('services.ingredientValidation'),
            (v, context) => {
              let result = true;
              if (!v && context.from[1]?.value?.type == 2) result = false;
              return result;
            }
          )
        })
      ),
    extras: Yup.array()
      // test select options and radio buttons
      .of(
        Yup.object().shape({
          extra_name: Yup.string().test(
            'extras.index.extra_name',
            t('services.extraValidation'),
            (v, context) => {
              let result = true;
              if (!v && context?.parent?.extra_price) {
                result = false;
              }
              return result;
            }
          ),
          extra_price: Yup.string().test(
            'ingrediants.index.extra_price',
            t('services.extraPriceValidation'),
            (v, context) => {
              let result = true;
              if (!v && context?.parent?.extra_name) {
                result = false;
              }
              return result;
            }
          )
        })
      )
    // tax_deduction_rate
  });
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedProduct,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(StoreNewProductModalContext);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register,
    unregister,
    reset,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      type: '1',
      product_name: '',
      product_image: null,
      product_description: '',
      product_selling_price: '',
      tax: '',
      including_tax: String(systemSettings?.invoiceSetting?.including_tax),
      active_tax: '1',

      ingredients: [{ ingredient_id: '' }]
    }
  });
  useEffect(() => {
    setValue('including_tax', systemSettings?.invoiceSetting?.including_tax);
  }, [systemSettings?.invoiceSetting?.including_tax]);
  useEffect(() => {
    //  if (watch('including_tax') !== '1') {
    if (!isEditModal) {
      setValue('tax', '');
      setValue('tax_sale', '');
      setValue('salePriceWithTax', '');
      setValue('product_selling_price', '');
    }
    //  }
  }, [watch('including_tax')]);
  useEffect(() => {
    if (watch('tax') == '') {
      setValue('tax_sale', '');
      setValue('salePriceWithTax', '');
    }
  }, [watch('tax')]);

  useEffect(() => {
    if (watch('active_tax') == 2) {
      setValue('including_tax', '0');
      setValue('tax', '');
      setValue('tax_sale', '');
    }
  }, [watch(`active_tax`)]);
  const {
    fields: ingredientsFields,
    append: appendIngredientField,
    remove: removeIngredientField
  } = useFieldArray({
    control,
    name: 'ingredients'
  });

  const [productFileToUpload, setProductFileToUpload] = useState(
    watch('product_image') ? watch('product_image') : null
  );
  useEffect(() => {
    // if (orgainzationHeaderToUpload?.length > 0) {
    setValue('product_image', productFileToUpload);
    // reset({ ...watch(), organization_header: orgainzationHeaderToUpload });
    // }
  }, [productFileToUpload, productFileToUpload?.length]);
  useEffect(() => {
    register('product_image', {
      required: t('services.serviceImageValidation')
    });

    return () => unregister('product_image');
  }, []);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handleFilesDrop = async (acceptedFiles, formFiles, setFormFiles) => {
    const duplicates = [];
    for (let i of acceptedFiles) {
      if (formFiles?.length) {
        for (let f of formFiles) {
          if (i.name === f.name) {
            duplicates.push(i);
          }
        }
      }
    }
    for (let i = 0; i < acceptedFiles.length; i++) {
      for (let f of duplicates) {
        if (acceptedFiles[i].name === f.name) {
          acceptedFiles.splice(i, 1);
        }
      }
    }

    acceptedFiles.forEach(async (file) => {
      const preview = await getBase64(file);
      Object.assign(file, {
        preview
      });
      // setFormFiles((prevState) => [...prevState, file]);
      if (formFiles?.length > 0) {
        setFormFiles((currState) => [...currState, file]);
      } else {
        setFormFiles([file]);
      }
    });
  };

  const clearFileFromUpload = (file, setFilesToUpload) => {
    setFilesToUpload((prevState) => {
      const filtered = prevState.filter((asset) => asset.name !== file.name);
      if (filtered?.length === 0) return null;
      return filtered;
    });
  };

  const [productDescEditorState, setProductDescEditorState] = useState(
    EditorState.createEmpty()
  );

  const allIngredients = useIngredients();

  const renderIngredientFields = () => {
    return (
      ingredientsFields?.length > 0 && (
        <ul className="fields-array-ul with-border">
          {ingredientsFields.map((item, index) => {
            return (
              <li className="field-delete-li" key={item.id}>
                <div className="select-label-wrap">
                  <p className="label-p">{t('services.chooseIngredient')}</p>
                  <div className="custom-select-wrap without-icon">
                    <AntdSelectOption
                      name={`ingredients.${index}.ingredient_id`}
                      errorMsg={
                        errors?.ingredients &&
                        errors.ingredients[index]?.ingredient_id &&
                        errors.ingredients[index].ingredient_id.message
                      }
                      validateStatus={
                        errors?.ingredients &&
                        errors.ingredients[index]?.ingredient_id &&
                        errors?.ingredients[index]?.ingredient_id
                          ? 'error'
                          : ''
                      }
                      control={control}
                      setValue={setValue}
                      placeholder={t('services.chooseIngredient')}
                      options={
                        allIngredients?.length > 0 &&
                        allIngredients.map((p) => ({
                          title: p.name,
                          value: String(p.id)
                        }))
                      }
                      showSearch={true}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      formClassName="store-new-product-form"
                    />
                  </div>
                </div>

                {ingredientsFields.length > 1 && (
                  <Tooltip title={t('services.delete')}>
                    <Button
                      className="delete-field-btn"
                      size="large"
                      type="dashed"
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={() => removeIngredientField(index)}
                    />
                  </Tooltip>
                )}
              </li>
            );
          })}
        </ul>
      )
    );
  };

  // handle initial values
  useEffect(() => {
    if (selectedProduct) {
      console.log('selectedProduct: ', selectedProduct);
      setIsEditModal(true);
      setValue('type', selectedProduct.type ? selectedProduct.type : '');
      if (selectedProduct?.name) {
        setValue('product_name', selectedProduct.name);
      }
      if (selectedProduct?.active_tax) {
        setValue('active_tax', selectedProduct.active_tax);
      }
      if (selectedProduct?.salePrice) {
        setValue(
          'product_selling_price',
          parseFloat(selectedProduct.salePrice)
        );
        //
      }
      if (selectedProduct?.tax) {
        setValue('tax', parseFloat(selectedProduct.tax));
      }

      if (selectedProduct.materials?.length > 0) {
        const sharedReset = {
          type: selectedProduct.type ? selectedProduct.type : '',
          product_name: selectedProduct.name ? selectedProduct.name : '',
          product_selling_price: selectedProduct.salePrice
            ? parseFloat(selectedProduct.salePrice)
            : '',
          tax: selectedProduct.tax ? parseFloat(selectedProduct.tax) : ''
        };
        const mappedIngredients = selectedProduct.materials.map(
          (ingredient) => ({
            ingredient_id: String(ingredient.id)
          })
        );

        reset({
          ...sharedReset,
          ingredients:
            mappedIngredients?.length > 0 ? [...mappedIngredients] : []
        });
      }
    }
  }, [selectedProduct, selectedProduct?.id, reset]);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    const mappedData = {};
    if (data.type) mappedData.type = data.type;
    if (data.product_name) mappedData.name = data.product_name;
    if (data.product_selling_price)
      mappedData.salePrice = data.product_selling_price;
    if (data.tax) mappedData.tax = data.tax;
    mappedData.including_tax = data.including_tax;
    mappedData.active_tax = data.active_tax;
    if (watch('active_tax') == 2 && mappedData.salePrice) {
      mappedData.salePriceWithTax = mappedData.salePrice;
    }
    if (watch('active_tax') == 1) {
      mappedData.salePriceWithTax = data.salePriceWithTax
        ? data.salePriceWithTax
        : '';
    }
    if (data.product_image?.length > 0)
      mappedData.image = data.product_image[0];
    if (data.product_description) mappedData.desc = data.product_description;
    // if (data.product_discount_rate)
    //   mappedData.defaultDiscount = data.product_discount_rate;
    //
    if (data.ingredients?.length > 0 && data.type == 2) {
      // material
      const mappedIngredients = data.ingredients.map((ing) => {
        if (ing.ingredient_id) return { id: ing.ingredient_id };
        return null;
      });
      if (mappedIngredients?.length > 0 && mappedIngredients[0] !== null) {
        // mappedData.type = 2; // 1 ==> is normal product , 2 ==> product with ingredients
        mappedData.material = JSON.stringify(mappedIngredients);
      }
      // else {
      //   mappedData.type = 1;
      // }
    }
    //
    mappedData.defaultParCode = '123456789';

    const formData = new FormData();
    for (let key in mappedData) {
      formData.append(key, mappedData[key]);
    }

    const successCallback = (res) => {
      setIsSubmittingForm(false);
      setIsLoading(false);
      if (res?.data?.data) {
        setModalOpened(false);
        setFetchCount((prevState) => prevState + 1);
        if (setAllProductsFetchCount)
          setAllProductsFetchCount((prev) => prev + 1);
        successNotification({
          title: t('services.successNotification'),
          message: t('services.addServiceSuccessfully')
        });
      } else {
        errorNotification({
          title: t('services.errorOccurred'),
          message: res?.data?.message
            ? res.data.message
            : t('services.tryLater')
        });
      }
    };
    const successUpdateCallback = (res) => {
      setIsSubmittingForm(false);
      setIsLoading(false);
      if (res?.data?.data) {
        setModalOpened(false);
        setFetchCount((prevState) => prevState + 1);
        if (setAllProductsFetchCount)
          setAllProductsFetchCount((prev) => prev + 1);
        successNotification({
          title: t('services.successNotification'),
          message: t('services.updateServiceSuccessfully')
        });
      } else {
        errorNotification({
          title: t('services.errorOccurred'),
          message: res?.data?.message
            ? res.data.message
            : t('services.tryLater')
        });
      }
    };
    const errorCallback = (error) => {
      setIsSubmittingForm(false);
      setIsLoading(false);
      errorNotification({
        title: t('services.errorOccurred'),
        message: t('services.tryLater')
      });
    };
    //
    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedProduct) {
      formData.append('id', selectedProduct.id);
      customApiRequest(
        addNewProductApi(user?.token, formData, true),
        successUpdateCallback,
        errorCallback
      );
    } else {
      customApiRequest(
        addNewProductApi(user?.token, formData),
        successCallback,
        errorCallback
      );
    }
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="store-new-product-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <AntdRadioGroup
          name="type"
          className="form-radio-group"
          control={control}
          label={t('services.chooseServiceTypeValidation')}
          validateStatus={errors?.type ? 'error' : ''}
          errorMsg={errors?.type?.message}
          onChange={() => setValue('model_id', '')}
          radios={[
            {
              title: t('services.services'),
              value: '1'
            },
            {
              title: t('services.packages'),
              value: '2'
            }
          ]}
          defaultValue={
            selectedProduct?.type ? String(selectedProduct.type) : '1'
          }
          isRadioButton
        />

        <div className="text-field-label-wrap">
          <p className="label-p">{t('services.serviceName')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="product_name"
              type="text"
              placeholder={t('services.serviceNamePlaceholder')}
              errorMsg={errors?.product_name?.message}
              validateStatus={errors?.product_name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <CustomEditor
          fieldName="product_description"
          editorTitle={t('services.serviceDetails')}
          setValue={setValue}
          watch={watch}
          setError={setError}
          clearErrors={clearErrors}
          errors={errors}
          required={false}
          errorMsg={t('services.serviceDetailsErrorMsg')}
          editorFieldState={productDescEditorState}
          setEditorFieldState={setProductDescEditorState}
          fetchedData={selectedProduct?.desc}
        />

        <div
          className="buying-wrap"
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 18
          }}
        >
          {watch('active_tax') == 1 && watch('including_tax') == 1 && (
            <div className="text-field-label-wrap">
              <p className="label-p">
                {t('Inventory.Products.sellingPriceIncludesTax')}
              </p>
              <div className="text-field-wrap">
                <AntdTextField
                  setValue={setValue}
                  className="form-text-field"
                  name="salePriceWithTax"
                  type="text"
                  placeholder={t(
                    'Inventory.Products.sellingPriceIncludesTaxPlaceholder'
                  )}
                  errorMsg={errors?.salePriceWithTax?.message}
                  validateStatus={errors?.salePriceWithTax ? 'error' : ''}
                  control={control}
                  // defaultValue={
                  //   watch('active_tax') == 1
                  //     ? parseFloat(watch('tax')) > 0
                  //       ? parseFloat(watch('salePrice')) +
                  //         parseFloat(watch('tax'))
                  //       : parseFloat(watch('salePrice'))
                  //     : ''
                  // }
                  // readOnly={
                  //   watch('including_tax') == 1 ? false : true
                  // }
                />
              </div>
            </div>
          )}
          <div className="text-field-label-wrap">
            <p className="label-p">{t('services.sellingPrice')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="product_selling_price"
                type="text"
                setValue={setValue}
                placeholder={t('services.sellingPricePlaceholder')}
                errorMsg={errors?.product_selling_price?.message}
                validateStatus={errors?.product_selling_price ? 'error' : ''}
                control={control}
                defaultValue={
                  watch('including_tax') == 1 &&
                  parseFloat(watch('salePriceWithTax')) &&
                  parseFloat(watch('tax')) &&
                  (
                    (parseFloat(watch('salePriceWithTax')) /
                      (parseFloat(watch('tax') || 0) + 100)) *
                    100
                  ).toFixed(2)
                }
                readOnly={
                  watch('including_tax') == 1 && watch('active_tax') == 1
                    ? true
                    : false
                }
              />
            </div>
          </div>

          {watch('active_tax') == '1' && (
            <div className="text-field-label-wrap">
              <p className="label-p">{t('services.taxRate')}</p>
              <div className="text-field-wrap">
                <AntdTextField
                  className="form-text-field"
                  name="tax"
                  type="text"
                  placeholder={t('services.taxRatePlaceholder')}
                  errorMsg={errors?.tax?.message}
                  validateStatus={errors?.tax ? 'error' : ''}
                  control={control}
                />
              </div>
            </div>
          )}
          {watch('active_tax') == '1' && (
            <div className="text-field-label-wrap">
              <p className="label-p">{t('services.taxPrice')}</p>
              <div className="text-field-wrap">
                <AntdTextField
                  className="form-text-field"
                  name="tax_sale"
                  type="text"
                  placeholder={t('services.taxPricePlaceholder')}
                  errorMsg={errors?.tax_sale?.message}
                  validateStatus={errors?.tax_sale ? 'error' : ''}
                  control={control}
                  defaultValue={
                    watch('tax') && watch('product_selling_price')
                      ? (
                          (parseFloat(watch('tax')) *
                            parseFloat(watch('product_selling_price'))) /
                          100
                        ).toFixed(2)
                      : 0
                  }
                  setValue={setValue}
                  readOnly={true}
                />
              </div>
            </div>
          )}
          {watch('active_tax') == 1 && watch('including_tax') == 0 && (
            <div className="text-field-label-wrap">
              <p className="label-p">
                {t('Inventory.Products.sellingPriceIncludesTax')}
              </p>
              <div className="text-field-wrap">
                <AntdTextField
                  setValue={setValue}
                  className="form-text-field"
                  name="salePriceWithTax"
                  type="text"
                  placeholder={t(
                    'Inventory.Products.sellingPriceIncludesTaxPlaceholder'
                  )}
                  errorMsg={errors?.salePriceWithTax?.message}
                  validateStatus={errors?.salePriceWithTax ? 'error' : ''}
                  control={control}
                  defaultValue={
                    watch('active_tax') == 1
                      ? parseFloat(watch('tax_sale')) > 0 &&
                        parseFloat(watch('product_selling_price'))
                        ? parseFloat(watch('product_selling_price')) +
                          parseFloat(watch('tax_sale'))
                        : parseFloat(watch('product_selling_price'))
                      : ''
                  }
                  readOnly={watch('including_tax') == 1 ? false : true}
                />
              </div>
            </div>
          )}
        </div>
        {/* ///// */}
        <div className="buying-wrap">
          <div className="radios-wrap">
            <p className="radios-group-title">
              {t('Inventory.Products.activateTaxForService')}
            </p>
            <div className="labels-wrap">
              {[
                {
                  title: t('Inventory.Products.yes'),
                  value: '1'
                },
                {
                  title: t('Inventory.Products.no'),
                  value: '2'
                }
              ].map((obj, i) => (
                <label
                  key={i}
                  className={`radio-input-label ${
                    String(watch(`active_tax`)) === String(obj.value)
                      ? 'selected'
                      : ''
                  }`}
                >
                  <input
                    type="radio"
                    value={obj.value}
                    {...register(`active_tax`)}
                  />
                  <span className="label-span">{obj.title}</span>
                  {watch(`active_tax`) == String(obj.value) ? (
                    <RadioButtonFilled />
                  ) : (
                    <RadioButtonEmpty />
                  )}
                </label>
              ))}
            </div>

            {errors?.active_tax && (
              <p className="error-p">{errors.active_tax.message}</p>
            )}
          </div>
          {watch(`active_tax`) == 1 && (
            <div className="radios-wrap">
              <p className="radios-group-title">
                {t('Inventory.Products.doesSrIncludeTax')}
              </p>
              <div className="labels-wrap">
                {[
                  {
                    title: t('Inventory.Products.yes'),
                    value: '1'
                  },
                  {
                    title: t('Inventory.Products.no'),
                    value: '0'
                  }
                ].map((obj, i) => (
                  <label
                    key={i}
                    className={`radio-input-label ${
                      String(watch(`including_tax`)) === String(obj.value)
                        ? 'selected'
                        : ''
                    }`}
                  >
                    <input
                      type="radio"
                      value={obj.value}
                      {...register(`including_tax`)}
                    />
                    <span className="label-span">{obj.title}</span>
                    {watch(`including_tax`) == String(obj.value) ? (
                      <RadioButtonFilled />
                    ) : (
                      <RadioButtonEmpty />
                    )}
                  </label>
                ))}
              </div>

              {errors?.including_tax && (
                <p className="error-p">{errors.including_tax.message}</p>
              )}
            </div>
          )}
        </div>
        <div className="product-img-wrap">
          <h3>{t('services.serviceImage')}</h3>

          <MyDropZone
            className="product-header-dropzone"
            multipleFiles={false}
            handleFilesDrop={(acceptedFiles) =>
              handleFilesDrop(
                acceptedFiles,
                productFileToUpload,
                setProductFileToUpload
              )
            }
            filesToUpload={productFileToUpload}
            formName="store-new-product-form"
            id="avatar-input"
            dropzoneText={t('services.serviceImage')}
            inputName="product_image"
            icon={<CameraFilled className="dropzone-camera" />}
            dropZoneUrls={
              selectedProduct?.image ? [selectedProduct.image] : null
            }
          >
            {t('services.dragImage')}
          </MyDropZone>

          <MyDropZonePreview
            filesToUpload={productFileToUpload}
            clearFileFromUpload={(f) =>
              clearFileFromUpload(f, setProductFileToUpload)
            }
          />
          {errors?.product_image?.message && !watch('product_image') && (
            <p className="error-p">{errors.product_image.message}</p>
          )}
        </div>

        {/* ingredient fields */}
        {watch('type') == 2 && (
          <>
            <div
              className="add-new-field-btn"
              onClick={() =>
                appendIngredientField({
                  ingredient_id: ''
                })
              }
            >
              <span> {t('services.newIngredient')}</span>
            </div>
            {renderIngredientFields()}
          </>
        )}

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedProduct ? t('services.update') : t('services.add')}
        </Button>
      </div>
    </Form>
  );
};

export default StoreNewProductForm;
