import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedUnits: null,
  setAllFetchedUnits: (v) => {},
  selectedUnit: null,
  setSelectedUnit: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {}
};

const SettingsMeasurementUnitsContext = createContext(INITIAL_VALUES);

export const SettingsMeasurementUnitsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedUnits, setAllFetchedUnits] = useState(
    INITIAL_VALUES.allFetchedUnits
  );
  const [selectedUnit, setSelectedUnit] = useState(INITIAL_VALUES.selectedUnit);
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  return (
    <SettingsMeasurementUnitsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedUnits,
        setAllFetchedUnits,
        selectedUnit,
        setSelectedUnit,
        isSubmittingForm,
        setIsSubmittingForm
      }}
    >
      {children}
    </SettingsMeasurementUnitsContext.Provider>
  );
};

export default SettingsMeasurementUnitsContext;
