import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  filterValues: '',
  setFilterValues: (v) => {},
  setFetchCount: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  excelModalOpened: false,
  setExcelModalOpened: (v) => {},
  modalOpenedClintHome: false,
  setModalOpenedClintHome: (v) => {},
  allFetchedClients: null,
  setAllFetchedClients: (v) => {},
  selectedClient: null,
  setSelectedClient: (v) => {},
  nameSearchValue: '',
  setNameSearchValue: (v) => {},
  selectedClientId: '',
  setSelectedClientId: (v) => {},
  //
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {},
  clientName: '',
  setClientName: (v) => {},
  clientModalOpened: false,
  setClientModalOpened: (v) => {}
};

const ClientsContext = createContext(INITIAL_VALUES);

export const ClientsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [excelModalOpened, setExcelModalOpened] = useState(
    INITIAL_VALUES.excelModalOpened
  );

  const [filterValues, setFilterValues] = useState(INITIAL_VALUES.filterValues);
  const [modalOpenedClintHome, setModalOpenedClintHome] = useState(
    INITIAL_VALUES.modalOpenedClintHome
  );
  const [clientName, setClientName] = useState(INITIAL_VALUES.clientName);
  const [clientModalOpened, setClientModalOpened] = useState(
    INITIAL_VALUES.clientModalOpened
  );
  const [allFetchedClients, setAllFetchedClients] = useState(
    INITIAL_VALUES.allFetchedClients
  );
  const [selectedClient, setSelectedClient] = useState(
    INITIAL_VALUES.selectedClient
  );
  const [nameSearchValue, setNameSearchValue] = useState(
    INITIAL_VALUES.nameSearchValue
  );
  const [selectedClientId, setSelectedClientId] = useState(
    INITIAL_VALUES.selectedClientId
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES?.isSubmittingForm
  );
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES?.tablePagination
  );

  return (
    <ClientsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fetchCount,
        setFetchCount,
        modalOpened,
        excelModalOpened,
        setExcelModalOpened,
        filterValues,
        setFilterValues,
        setModalOpened,
        allFetchedClients,
        setAllFetchedClients,
        selectedClient,
        setSelectedClient,
        nameSearchValue,
        setNameSearchValue,
        //
        selectedClientId,
        setSelectedClientId,
        //
        isSubmittingForm,
        setIsSubmittingForm,
        tablePagination,
        setTablePagination,
        clientName,
        setClientName,
        clientModalOpened,
        setClientModalOpened,
        modalOpenedClintHome,
        setModalOpenedClintHome
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
};

export default ClientsContext;
