/* eslint-disable react-hooks/exhaustive-deps */
import queryString from 'query-string';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import getAllProductsWithSelect from '../../../apis/store-apis/products/getAllProductsWithSelect';
import SharedLayoutBtns from '../../../components/app/pos-section/SharedLayoutBtns';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import SharedOutgoingPurchaseInvoice from './SharedOutgoingPurchaseInvoice';
import './StoreReceiptPage.scss';
import TableFilter from './TableFilter';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

const PriceOfferPageNew = ({ reports, withoutReturnBtn, isReportsPage }) => {
  const { user } = useContext(UserContext);
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { t } = useTranslation();
  const {
    modalOpened,
    setModalOpened,
    setSelectedReceipt,
    allFetchedReceipts,
    setIsLoading,
    setAllFetchedReceipts,
    fetchCount,
    tableFilter,
    resetTableFilter,
    setTablePagination,
    setAllProducts,
    filterValues
  } = useContext(OutgoingPurchaseInvoiceContext);

  const { pathname } = useLocation();
  // reset table filter
  useEffect(() => {
    resetTableFilter();
  }, [pathname]);

  React.useEffect(() => {
    if (modalOpened) {
      setModalOpened(true);
    }
    return () => {
      setModalOpened(false);
    };
  }, [modalOpened, pathname]);
  const customApiRequest = useCustomApiRequest();
  // useEffect(() => {
  //   let isMounted = true;
  //   if (isMounted) {
  //     setIsLoading(true);
  //     customApiRequest(
  //       getAllPriceOffersApiNew(user?.token, {
  //         ...tableFilter,
  //         ...values
  //       }),
  //       (res) => {
  //         setIsLoading(false);
  //         if (res?.status === 200 && res?.data?.data?.data?.length >= 0) {
  //           const data = res.data.data.data;
  //           setAllFetchedReceipts(data);
  //         }
  //         if (res?.data?.data?.pagination) {
  //           setTablePagination(res.data.data.pagination);
  //         }
  //       },
  //       (error) => {
  //         setIsLoading(false);
  //       }
  //     );
  //   }

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [fetchCount]);
  // }, [fetchCount, tableFilter]);

  useEffect(() => {
    customApiRequest(
      getAllProductsWithSelect(user?.token, filterValues),
      (res) => {
        if (res?.status === 200 && res?.data?.data?.length >= 0) {
          const data = res.data.data;
          setAllProducts(data);
        }
      },
      (error) => {
        // setIsLoadingProducts(false);
      }
    );
  }, [fetchCount]);

  return (
    <div className="settings-categories-page">
      {user?.role === 5 && <SharedLayoutBtns />}
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">
            {user.subscriptionType === 1
              ? t('Quotations.Quotations')
              : t('Quotations.Quotations')}
          </h2>
          <p>كشف وعرض كافة عروض الاسعار</p>
          <p>{t('Quotations.ListAndViewAllQuotations')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('Quotations.Quotations')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.salesPage.title')}
            title3={t('Quotations.Quotations')}
            link3={'/sales/price-offer'}
          />
        </div>
        <SearchNotificationsProfile />
      </div>

      <div className="custom-filter-add-section1">
        {!reports && user.role !== 5 && (
          <div className="add-section">
            <div
              className="add-btn"
              onClick={() => {
                setSelectedReceipt(null);
                setModalOpened(true);
              }}
            >
              <span className="text-span">{t('Quotations.AddaQuote')}</span>
            </div>
          </div>
        )}
        <TableFilter
          allFetchedReceipts={allFetchedReceipts}
          isReportsPage={isReportsPage}
        />
      </div>
      <SharedOutgoingPurchaseInvoice
        allFetchedReceipts={allFetchedReceipts}
        withoutReturnBtn={withoutReturnBtn}
        total={allFetchedReceipts?.total}
        isReportsPage={isReportsPage}
      />
      {/* {modalOpened && <PageModal />}
      {selectedReceiptModalOpened && <SelectedReceiptModal />} */}
    </div>
  );
};

export default PriceOfferPageNew;
