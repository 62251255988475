import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  returnModalOpened: false,
  setReturnModalOpened: (v) => {}
};

const ReturnModalContext = createContext(INITIAL_VALUES);

export const ReturnModalProvider = ({ children }) => {
  const [returnModalOpened, setReturnModalOpened] = useState();
  return (
    <ReturnModalContext.Provider
      value={{
        returnModalOpened,
        setReturnModalOpened
      }}
    >
      {children}
    </ReturnModalContext.Provider>
  );
};

export default ReturnModalContext;
