import React, { useContext, useState, useEffect } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import SuppliersContext from '../../contexts/suppliers-context/SuppliersContext';
import './PageModal.scss';
import { useTranslation } from 'react-i18next';

const PageModal = ({ setAllSuppliersFetchCount }) => {
  const { modalOpened, setModalOpened, selectedSupplier, setSelectedSupplier } =
    useContext(SuppliersContext);
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  return (
    <Modal
      className="new-supplier-modal"
      width="90%"
      style={{ maxWidth: '642px' }}
      title={
        selectedSupplier
          ? t('addSupplierForm.updateSupplier')
          : t('addSupplierForm.addNew')
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedSupplier(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedSupplier(null);
      }}
      footer={false}
    >
      <TableForm setAllSuppliersFetchCount={setAllSuppliersFetchCount} />
    </Modal>
  );
};

export default PageModal;
