/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import PageModal from './PageModal';
import ArchiveCalendarContext from '../../../contexts/archive-contexts/ArchiveCalendarProvider';
import getAllReservationsApi from '../../../apis/settings-apis/settings-calendar-apis/getAllReservationsApi';
import './ArchiveCalendarPage.scss';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

const localizer = momentLocalizer(moment);

const ArchiveCalendarPage = () => {
  const {
    setIsLoading,
    modalOpened,
    setModalOpened,
    setSelectedCalendar,
    allFetchedReservations,
    setAllFetchedReservations
  } = useContext(ArchiveCalendarContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllReservationsApi(user?.token, {
          type: 3
        }),
        (res) => {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setAllFetchedReservations(res.data.data);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const [calendarEvents, setCalendarEvents] = React.useState([]);
  useEffect(() => {
    if (allFetchedReservations?.length > 0) {
      setCalendarEvents(
        allFetchedReservations.map((obj) => ({
          ...obj,
          start: obj?.dueDate ? new Date(obj?.dueDate) : null,
          end: obj?.dueDate ? new Date(obj?.dueDate) : null,
          title: obj.name,
          allDay: true
        }))
      );
    }
  }, [allFetchedReservations]);

  const handleSelectEvent = (b) => {
    setSelectedCalendar(b);
    setModalOpened(true);
  };

  // const eventStyleGetter = (event, start, end, isSelected) => {
  //   const backgroundColor = '#' + event.hexColor;
  //   // if (event.end.getTime() - new Date().getTime() > 0) {

  //   // }
  //   const sharedStyle = {
  //     borderRadius: '4px',
  //     // opacity: 0.8,
  //     // color: 'black',
  //     border: '0px',
  //     display: 'block'
  //   };
  //   const greenStyle = {
  //     backgroundColor: 'green',
  //     ...sharedStyle
  //   };
  //   const orangeStyle = {
  //     backgroundColor: 'orange',
  //     ...sharedStyle
  //   };
  //   const redStyle = {
  //     backgroundColor: 'red',
  //     ...sharedStyle
  //   };

  //   if (moment(event.end).isAfter()) {
  //     return {
  //       style: greenStyle
  //     };
  //   } else if (moment(event.end).isBefore()) {
  //     return {
  //       style: redStyle
  //     };
  //   } else if (moment(event.end).isSame()) {
  //     return {
  //       style: orangeStyle
  //     };
  //   }
  //   // return {
  //   //   style: sharedStyle
  //   // };
  // };

  return (
    <div className="archive-calendar-page">
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">{t('Calendar.Calendar')}</h2>
          <p>كشف وعرض كافة الحجوزات</p>
          <p>{t('Calendar.ShowAndDisplayAllReservations')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('AppBar.Products.archivePage.archiveCalendarPage')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.archivePage.title')}
            title3={t('AppBar.Products.archivePage.archiveCalendarPage')}
            link3={'/archive/calendar'}
          />
        </div>
      </div>

      <div className="calendar-wrap">
        <Calendar
          className="calendar-itself"
          selectable
          rtl={true}
          localizer={localizer}
          events={calendarEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 632 }}
          onSelectEvent={handleSelectEvent}
          // eventPropGetter={eventStyleGetter}
        />
      </div>

      {modalOpened && <PageModal />}
    </div>
  );
};

export default ArchiveCalendarPage;
