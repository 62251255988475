export const calcPackageTotalWithoutCode = (
  packagePrice,
  monthNumber,
  posPrice,
  posNum
) => {
  if (packagePrice) {
    const totalWithoutPos = parseFloat(packagePrice) * parseFloat(monthNumber);
    const posTotal =
      parseFloat(posPrice) * parseFloat(posNum) * parseFloat(monthNumber);

    return totalWithoutPos + posTotal;
  }
};
export const calcPosTotal = (posPrice, posNum) => {
  if (posPrice) {
    const posTotal = parseFloat(posPrice) * parseFloat(posNum);

    return posTotal;
  }
};

export const calcPackageTotalWithCode = (
  packagePrice,
  monthNumber,
  posPrice,
  posNum,
  discountValue,
  discountType
) => {
  if (packagePrice && discountValue && discountType) {
    const totalWithoutPos = parseFloat(packagePrice) * parseFloat(monthNumber);
    const posTotal =
      parseFloat(posPrice) * parseFloat(posNum) * parseFloat(monthNumber);

    if (discountType === 1) {
      // رقم صحيح
      return totalWithoutPos - discountValue + posTotal;
    } else if (discountType === 2) {
      // Percentage
      return (
        totalWithoutPos -
        (totalWithoutPos * parseFloat(discountValue)) / 100 +
        posTotal
      );
    }
  }
};
