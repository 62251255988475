import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react'
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { store } from 'react-notifications-component';
import useCustomApiRequest from '../../../../custom-hooks/useCustomApiRequest';
import UserContext from '../../../../contexts/user-context/UserProvider';
import { Button, Form } from 'antd';
import AntdCheckbox from '../../../../common/antd-form-components/AntdCheckbox';
import * as Yup from 'yup';

const schema = Yup.object().shape({
    // lang: Yup.string().optional('')
});
const UserPermission = () => {
    const customApiRequest = useCustomApiRequest();
    const { user } = useContext(UserContext);
    const {
        control,
        handleSubmit,
        // watch,
        formState: { errors, isSubmitting }
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            lang: '',
            change_invoice_num: '',
            qrcode: '',
            barcode: '',
            logo: '',
            template: '',
            terms: '',
        }
    });
    const onSubmit = async (data) => {
        // customApiRequest(
        //     addInvoicesSettingsApi(user?.token , data),
        //     (res) => {
        //         if (res?.data) {
        //             store.addNotification({
        //                 title: res?.data?.message,
        //                 message: res?.data?.message,
        //                 type: 'success',
        //                 insert: 'top',
        //                 container: 'top-right',
        //                 animationIn: ['animate__animated', 'animate__fadeIn'],
        //                 animationOut: ['animate__animated', 'animate__fadeOut'],
        //                 dismiss: {
        //                     duration: 3000,
        //                     showIcon: true,
        //                     onScreen: true
        //                 }
        //             });
        //         }
        //     },
        //     (error) => {}
        // );
    }
    const [form] = Form.useForm();
    return (
        <div className='user-permission'>
            <h2>صلاحية المستخدمين</h2>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit(onSubmit)}
            >
                <AntdCheckbox name="test1" label="تحديد نسبة أو مبلغ خصم للمستخدم" control={control} />
                <AntdCheckbox name="test2" label="السماح للمستخدم بالخصم" control={control} />
                <AntdCheckbox name="test3" label="إظهار الكمية المنتج للمستخدم في الكاشير" control={control} />
                <AntdCheckbox name="test4" label="إظهار تقرير نقطه البيع" control={control} />
                <AntdCheckbox name="test5" label="السماح للمستخد معمل مرتجع للفوايتر" control={control} />
                <AntdCheckbox name="test6" label="استلام نقدية جديدة" control={control} />
                <AntdCheckbox name="test7" label="صرف نقدية جديدة" control={control} />
                <AntdCheckbox name="test8" label="السماح للمستخدم عمل عرض سعر" control={control} />
                {/* <div className="btn">
                    <Button
                        className="submit-btn"
                        htmlType="submit"
                        type="primary"
                        // icon={<LoginOutlined />}
                        // loading={isSubmittingForm}
                    >
                    حفظ
                    </Button>
                </div> */}
            </Form>
        </div>
    )
}

export default UserPermission