/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import MyDropZone from '../../../common/dorp-zone/MyDropZone';
import MyDropZonePreview from '../../../common/dorp-zone/MyDropZonePreview';
import { CameraFilled } from '@ant-design/icons';
import getSystemSettingsApi from '../../../apis/settings-apis/system-settings-api/getSystemSettingsApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import updateSystemSettingsApi from '../../../apis/settings-apis/system-settings-api/updateSystemSettingsApi';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import RadioButtonFilled from '../../../common/icons/RadioButtonFilled';
import RadioButtonEmpty from '../../../common/icons/RadioButtonEmpty';
// import fatoorahViewTypes from '../../../fatoorahViewTypes';
import { subCategoriesArray } from './subCategoriesArray.js';
import './systemSettingsPageForm.scss';
import AlertModalContext from '../../../contexts/alertModal-context/AlertModalContext';
import { useTranslation } from 'react-i18next';

const GeneralSettingsPageForm = () => {
  const { t } = useTranslation();

  const fatoorahViewTypes = {
    sellerBuyer: {
      value: 1,
      label: t('GeneralSettings.fatoorahTypes.sellerBuyer'),
      seller: t('GeneralSettings.fatoorahTypes.seller'),
      buyer: t('GeneralSettings.fatoorahTypes.buyer')
    },
    renter: {
      value: 2,
      label: t('GeneralSettings.fatoorahTypes.renter'),
      mo2ger: t('GeneralSettings.fatoorahTypes.mo2ger'),
      mst2ger: t('GeneralSettings.fatoorahTypes.mst2ger')
    },
    senderReceiver: {
      value: 3,
      label: t('GeneralSettings.fatoorahTypes.senderReceiver'),
      sender: t('GeneralSettings.fatoorahTypes.sender'),
      receiver: t('GeneralSettings.fatoorahTypes.receiver')
    }
  };
  const schema = Yup.object().shape({
    system_name: Yup.string().required(
      t('GeneralSettings.facilityNameValidation')
    ),
    commercialRecord: Yup.string().required(
      t('GeneralSettings.commercialRegisterValidation')
    ),
    email: Yup.string()
      .required(t('GeneralSettings.emailValidation'))
      .email(t('GeneralSettings.correctEmailValidation')),
    address: Yup.string().required(t('GeneralSettings.addressValidation')),
    // system_tax: Yup.string()
    //   .required('ادخل سعر الضريبة')
    //   .matches(/^(\d+)?(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام'),
    /////////////////////////////
    phone: Yup.string()
      .required(t('GeneralSettings.enterPhoneValidation'))
      .matches(/^[0-9]+$/, t('GeneralSettings.phoneOnlyNumbersAllowed'))
      .min(10, t('GeneralSettings.onlyTenNumbersValidation'))
      .max(10, t('GeneralSettings.onlyTenNumbersValidation')),
    // .test(
    //   'phone',
    //   t('GeneralSettings.phoneBeginningValidation'),
    //   (v, context) => {
    //     let result = true;
    //     if (v && v.slice(0, 2) != '05') result = false;
    //     return result;
    //   }
    // )
    addressCode: Yup.string().required(
      t('GeneralSettings.addressCodeValidation')
    ),
    country: Yup.string().required(t('GeneralSettings.enterCountryValidation')),
    city: Yup.string().optional(t('GeneralSettings.enterCityValidation')),
    district: Yup.string().required(
      t('GeneralSettings.enterDistrictValidation')
    ),
    streetName: Yup.string().required(
      t('GeneralSettings.enterStreetValidation')
    ),
    buildingNum: Yup.string()
      .required(t('GeneralSettings.enterBuildingValidation'))
      .matches(/^[0-9]+$/, t('GeneralSettings.buildingNumValidation')),
    postalCode: Yup.string()
      .required(t('GeneralSettings.postalCodeValidation'))
      .matches(
        /^[0-9]+$/,
        t('GeneralSettings.postalCodeOnlyNumberaValidation')
      ),
    // tax_number: Yup.string()
    //   .required('ادخل الرقم الضريبى')
    //   .matches(/^[0-9]+$/, 'لا يسمح الا بكتابة الارقام'),
    tax_number: Yup.string(),
    anotherId: Yup.string(),
    fatoraViewType: Yup.string()
      .required(t('GeneralSettings.invoiceTypeValidation'))
      .nullable()
  });
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register,
    unregister,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      system_name: '',
      commercialRecord: '',
      activity: '',
      email: '',
      address: '',
      system_tax: '',
      system_img: null,
      ///////////////////
      phone: '',
      addressCode: '',
      country: '',
      city: '',
      district: '',
      streetName: '',
      buildingNum: '',
      anotherId: '',
      postalCode: '',
      tax_number: '',
      slogan: '',
      fatoraViewType: ''
    }
  });
  const { user } = useContext(UserContext);
  const { setRendered } = useContext(AlertModalContext);
  const [fetchCount, setFetchCount] = useState(0);
  const [fetchedSettings, setFetchedSettings] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [categories, setCategories] = useState('');
  const [subCategories, setSubCategories] = useState('');
  const [allCategories, setAllCategories] = useState('');
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getSystemSettingsApi(user?.token),
        (res) => {
          if (checkRes(res) && res?.data?.data) {
            const data = res.data.data;
            setFetchedSettings(data);
          }
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  const [systemFilesToUpload, setSystemFilesToUpload] = useState(
    watch('system_img') ? watch('system_img') : null
  );
  useEffect(() => {
    // if (orgainzationHeaderToUpload?.length > 0) {
    setValue('system_img', systemFilesToUpload);
    // reset({ ...watch(), organization_header: orgainzationHeaderToUpload });
    // }
  }, [systemFilesToUpload, systemFilesToUpload?.length]);
  useEffect(() => {
    register('system_img');
    return () => unregister('system_img');
  }, []);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleActivity = (param) => {
    switch (param) {
      case 'Airlines ':
        return t('GeneralSettings.airlines');
      case 'Car Rental ':
        return t('GeneralSettings.carRental');
      case 'Education ':
        return t('GeneralSettings.education');
      case 'Electronics ':
        return t('GeneralSettings.electronics');
      case 'Fashion ':
        return t('GeneralSettings.fashion');
      case 'Flowers Gifts ':
        return t('GeneralSettings.flowersAndGifts');
      case 'Food ':
        return t('GeneralSettings.food');
      case 'Government ':
        return t('GeneralSettings.government');
      case 'Grocery ':
        return t('GeneralSettings.grocery');
      case 'Lodging ':
        return t('GeneralSettings.lodging');
      case 'Miscellaneous Merchandise ':
        return t('GeneralSettings.miscellaneousGoods');
      case 'Services ':
        return t('GeneralSettings.services');
      case 'Travel ':
        return t('GeneralSettings.travel');
      case 'Utilities ':
        return t('GeneralSettings.utilities');
      default:
        return '';
    }
  };

  const handleFilesDrop = async (acceptedFiles, formFiles, setFormFiles) => {
    const duplicates = [];
    for (let i of acceptedFiles) {
      if (formFiles?.length) {
        for (let f of formFiles) {
          if (i.name === f.name) {
            duplicates.push(i);
          }
        }
      }
    }
    for (let i = 0; i < acceptedFiles.length; i++) {
      for (let f of duplicates) {
        if (acceptedFiles[i].name === f.name) {
          acceptedFiles.splice(i, 1);
        }
      }
    }

    acceptedFiles.forEach(async (file) => {
      const preview = await getBase64(file);
      Object.assign(file, {
        preview
      });
      // setFormFiles((prevState) => [...prevState, file]);
      if (formFiles?.length > 0) {
        setFormFiles((currState) => [...currState, file]);
      } else {
        setFormFiles([file]);
      }
    });
  };

  const clearFileFromUpload = (file, setFilesToUpload) => {
    setFilesToUpload((prevState) => {
      const filtered = prevState.filter((asset) => asset.name !== file.name);
      if (filtered?.length === 0) return null;
      return filtered;
    });
  };

  // Initial values

  const [systemImgUrl, setSystemImgUrl] = useState(null);
  useEffect(() => {
    if (fetchedSettings) {
      if (fetchedSettings?.name) {
        setValue('system_name', fetchedSettings.name);
      }
      if (fetchedSettings?.commercialRecord) {
        setValue('commercialRecord', fetchedSettings.commercialRecord);
      }
      if (fetchedSettings?.activity) {
        setValue('activity', fetchedSettings.activity);
      }
      if (fetchedSettings?.address) {
        setValue('address', fetchedSettings.address);
      }
      if (fetchedSettings?.email) {
        setValue('email', fetchedSettings.email);
      }
      if (fetchedSettings?.tax) {
        setValue('system_tax', fetchedSettings.tax);
      }
      if (fetchedSettings?.image) {
        setSystemImgUrl([fetchedSettings.image]);
      }
      //
      if (fetchedSettings?.phone) {
        setValue('phone', fetchedSettings.phone);
      }
      if (fetchedSettings?.addressCode) {
        setValue('addressCode', fetchedSettings.addressCode);
      }
      if (fetchedSettings?.country) {
        setValue('country', fetchedSettings.country);
      }
      if (fetchedSettings?.city) {
        setValue('city', fetchedSettings.city);
      }
      if (fetchedSettings?.district) {
        setValue('district', fetchedSettings.district);
      }
      if (fetchedSettings?.streetName) {
        setValue('streetName', fetchedSettings.streetName);
      }
      if (fetchedSettings?.buildingNum) {
        setValue('buildingNum', fetchedSettings.buildingNum);
      }
      if (fetchedSettings?.anotherId) {
        setValue('anotherId', fetchedSettings.anotherId);
      }
      if (fetchedSettings?.postalCode) {
        setValue('postalCode', fetchedSettings.postalCode);
      }
      if (fetchedSettings?.tax_number) {
        setValue('tax_number', fetchedSettings.tax_number);
      }
      if (fetchedSettings?.slogan) {
        setValue('slogan', fetchedSettings.slogan);
      }
      setValue(
        'fatoraViewType',
        fetchedSettings?.fatoraViewType
          ? String(fetchedSettings.fatoraViewType)
          : ''
      );
    }
  }, [fetchedSettings]);

  const onSubmit = async (data) => {
    // if (!watch('system_img') && !systemImgUrl) {
    //   setError('system_img', {
    //     type: 'required',
    //     message: 'اختار صورة النظام'
    //   });
    // }

    const mappedData = new FormData();
    if (data.system_name) mappedData.append('name', data.system_name);
    if (data.commercialRecord)
      mappedData.append('commercialRecord', data.commercialRecord);
    if (data.activity) mappedData.append('activity', allCategories);
    if (data.address) mappedData.append('address', data.address);
    if (data.email) mappedData.append('email', data.email);
    if (data.system_tax) mappedData.append('tax', data.system_tax);
    if (data.system_img) mappedData.append('image', data.system_img[0]);
    //
    if (data.phone) mappedData.append('phone', data.phone);
    if (data.addressCode) mappedData.append('addressCode', data.addressCode);
    if (data.country) mappedData.append('country', data.country);
    if (data.city) mappedData.append('city', data.city);
    if (data.district) mappedData.append('district', data.district);
    if (data.streetName) mappedData.append('streetName', data.streetName);
    if (data.buildingNum) mappedData.append('buildingNum', data.buildingNum);
    if (data.anotherId) mappedData.append('anotherId', data.anotherId);
    if (data.postalCode) mappedData.append('postalCode', data.postalCode);
    // if (data.tax_number) mappedData.append('tax_number', data.tax_number);
    mappedData.append('tax_number', data.tax_number);
    if (data.slogan) mappedData.append('slogan', data.slogan);
    if (data.fatoraViewType)
      mappedData.append('fatoraViewType', data.fatoraViewType);
    //
    setIsSubmittingForm(true);
    customApiRequest(
      updateSystemSettingsApi(user?.token, mappedData),
      (res) => {
        setIsSubmittingForm(false);
        if (checkRes(res)) {
          setFetchCount((prev) => prev + 1);
          setRendered(true);
          successNotification({
            title: t('GeneralSettings.operationCompletedSuccessfully'),
            message: t('GeneralSettings.dataUpdatedSuccessfully')
          });
          // setTimeout(() => {
          //   window.location.reload();
          // }, 2000);
        } else {
          errorNotification({
            title: t('GeneralSettings.errorOccurred'),
            message: res?.data?.message
              ? res.data.message
              : t('GeneralSettings.tryLater')
          });
        }
      },
      (error) => {
        setIsSubmittingForm(false);
        errorNotification({
          title: t('GeneralSettings.errorOccurred'),
          message:
            error?.response?.data?.message || t('GeneralSettings.tryLater')
        });
      }
    );
  };
  const renderSwitch = (param) => {
    switch (param) {
      case 'Airlines':
        return (
          <div>
            <select
              className="form-text-field"
              value={subCategories}
              required
              onChange={(e) => {
                setSubCategories(e.target.value);
                setAllCategories(`${categories} - ${e.target.value}`);
              }}
            >
              <option value="" hidden>
                {t('GeneralSettings.chooseTheSubactivity')}
              </option>
              {subCategoriesArray['Airlines'].map((val) => {
                return <option value={val}>{val}</option>;
              })}
            </select>
          </div>
        );
      case 'Car Rental':
        return (
          <div>
            <select
              className="form-text-field"
              value={subCategories}
              required
              onChange={(e) => {
                setSubCategories(e.target.value);
                setAllCategories(`${categories} - ${e.target.value}`);
              }}
            >
              <option value="" hidden>
                {t('GeneralSettings.chooseTheSubactivity')}
              </option>
              {subCategoriesArray['Car_Rental'].map((val) => {
                return <option value={val}>{val}</option>;
              })}
            </select>
          </div>
        );
      case 'Education':
        return (
          <div>
            <select
              className="form-text-field"
              value={subCategories}
              required
              onChange={(e) => {
                setSubCategories(e.target.value);
                setAllCategories(`${categories} - ${e.target.value}`);
              }}
            >
              <option value="" hidden>
                {t('GeneralSettings.chooseTheSubactivity')}
              </option>
              {subCategoriesArray['education'].map((val) => {
                return <option value={val}>{val}</option>;
              })}
            </select>
          </div>
        );
      case 'Electronics':
        return (
          <div>
            <select
              className="form-text-field"
              value={subCategories}
              required
              onChange={(e) => {
                setSubCategories(e.target.value);
                setAllCategories(`${categories} - ${e.target.value}`);
              }}
            >
              <option value="" hidden>
                {t('GeneralSettings.chooseTheSubactivity')}
              </option>
              {subCategoriesArray['electronics'].map((val) => {
                return <option value={val}>{val}</option>;
              })}
            </select>
          </div>
        );
      case 'Fashion':
        return (
          <div>
            <select
              className="form-text-field"
              value={subCategories}
              required
              onChange={(e) => {
                setSubCategories(e.target.value);
                setAllCategories(`${categories} - ${e.target.value}`);
              }}
            >
              <option value="" hidden>
                {t('GeneralSettings.chooseTheSubactivity')}
              </option>
              {subCategoriesArray['fashion'].map((val) => {
                return <option value={val}>{val}</option>;
              })}
            </select>
          </div>
        );
      case 'Flowers Gifts':
        return (
          <div>
            <select
              className="form-text-field"
              value={subCategories}
              required
              onChange={(e) => {
                setSubCategories(e.target.value);
                setAllCategories(`${categories} - ${e.target.value}`);
              }}
            >
              <option value="" hidden>
                {t('GeneralSettings.chooseTheSubactivity')}
              </option>
              {subCategoriesArray['flowers_gifts'].map((val) => {
                return <option value={val}>{val}</option>;
              })}
            </select>
          </div>
        );
      case 'Food':
        return (
          <div>
            <select
              className="form-text-field"
              value={subCategories}
              required
              onChange={(e) => {
                setSubCategories(e.target.value);
                setAllCategories(`${categories} - ${e.target.value}`);
              }}
            >
              <option value="" hidden>
                {t('GeneralSettings.chooseTheSubactivity')}
              </option>
              {subCategoriesArray['Food'].map((val) => {
                return <option value={val}>{val}</option>;
              })}
            </select>
          </div>
        );
      case 'Government':
        return (
          <div>
            <select
              className="form-text-field"
              value={subCategories}
              required
              onChange={(e) => {
                setSubCategories(e.target.value);
                setAllCategories(`${categories} - ${e.target.value}`);
              }}
            >
              <option value="" hidden>
                {t('GeneralSettings.chooseTheSubactivity')}
              </option>
              {subCategoriesArray['government'].map((val) => {
                return <option value={val}>{val}</option>;
              })}
            </select>
          </div>
        );
      case 'Grocery':
        return (
          <div>
            <select
              className="form-text-field"
              value={subCategories}
              required
              onChange={(e) => {
                setSubCategories(e.target.value);
                setAllCategories(`${categories} - ${e.target.value}`);
              }}
            >
              <option value="" hidden>
                {t('GeneralSettings.chooseTheSubactivity')}
              </option>
              {subCategoriesArray['Grocery'].map((val) => {
                return <option value={val}>{val}</option>;
              })}
            </select>
          </div>
        );
      case 'Lodging':
        return (
          <div>
            <select
              className="form-text-field"
              value={subCategories}
              required
              onChange={(e) => {
                setSubCategories(e.target.value);
                setAllCategories(`${categories} - ${e.target.value}`);
              }}
            >
              <option value="" hidden>
                {t('GeneralSettings.chooseTheSubactivity')}
              </option>
              {subCategoriesArray['loding'].map((val) => {
                return <option value={val}>{val}</option>;
              })}
            </select>
          </div>
        );
      case 'Miscellaneous Merchandise':
        return (
          <div>
            <select
              className="form-text-field"
              value={subCategories}
              required
              onChange={(e) => {
                setSubCategories(e.target.value);
                setAllCategories(`${categories} - ${e.target.value}`);
              }}
            >
              <option value="" hidden>
                {t('GeneralSettings.chooseTheSubactivity')}
              </option>
              {subCategoriesArray['Miscellaneous_Merchandise'].map((val) => {
                return <option value={val}>{val}</option>;
              })}
            </select>
          </div>
        );
      case 'Services':
        return (
          <div>
            <select
              className="form-text-field"
              value={subCategories}
              required
              onChange={(e) => {
                setSubCategories(e.target.value);
                setAllCategories(`${categories} - ${e.target.value}`);
              }}
            >
              <option value="" hidden>
                {t('GeneralSettings.chooseTheSubactivity')}
              </option>
              {subCategoriesArray['Services'].map((val) => {
                return <option value={val}>{val}</option>;
              })}
            </select>
          </div>
        );
      case 'Travel':
        return (
          <div>
            <select
              className="form-text-field"
              value={subCategories}
              required
              onChange={(e) => {
                setSubCategories(e.target.value);
                setAllCategories(`${categories} - ${e.target.value}`);
              }}
            >
              <option value="" hidden>
                {t('GeneralSettings.chooseTheSubactivity')}
              </option>
              {subCategoriesArray['Travel_Tourism'].map((val) => {
                return <option value={val}>{val}</option>;
              })}
            </select>
          </div>
        );
      case 'Utilities':
        return (
          <div>
            <select
              className="form-text-field"
              value={subCategories}
              required
              onChange={(e) => {
                setSubCategories(e.target.value);
                setAllCategories(`${categories} - ${e.target.value}`);
              }}
            >
              <option value="" hidden>
                {t('GeneralSettings.chooseTheSubactivity')}
              </option>
              {subCategoriesArray['Utilities'].map((val) => {
                return <option value={val}>{val}</option>;
              })}
            </select>
          </div>
        );
      default:
        return '';
    }
  };
  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form settings-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body first">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('GeneralSettings.facilityName')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="system_name"
              type="text"
              placeholder={t('GeneralSettings.facilityNamePlaceholder')}
              errorMsg={errors?.system_name?.message}
              validateStatus={errors?.system_name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">
            {t('GeneralSettings.activityName')}
            {fetchedSettings?.activity.includes('-') &&
              fetchedSettings?.activity && (
                <span className="activity-span">
                  {` ${handleActivity(
                    fetchedSettings?.activity.split('-')[0]
                  )} - ${fetchedSettings?.activity.split('-')[1]}`}
                </span>
              )}
          </p>
          {/*
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="activity"
              type="text"
              placeholder="اســـم النشاط..."
              errorMsg={errors?.activity?.message}
              validateStatus={errors?.activity ? 'error' : ''}
              control={control}
            />
          </div> */}
          <div className="selector-wrapper">
            <select
              className="form-text-field"
              name="activity"
              value={categories}
              onChange={(e) => {
                setCategories(e.target.value);
              }}
            >
              <option value="" disabled hidden>
                {t('GeneralSettings.chooseActivityName')}
              </option>
              <option value="Airlines"> {t('GeneralSettings.airlines')}</option>
              <option value="Car Rental">
                {t('GeneralSettings.carRental')}
              </option>
              <option value="Education">
                {' '}
                {t('GeneralSettings.education')}
              </option>
              <option value="Electronics">
                {t('GeneralSettings.electronics')}
              </option>
              <option value="Fashion">{t('GeneralSettings.fashion')}</option>
              <option value="Flowers Gifts">
                {t('GeneralSettings.flowersAndGifts')}
              </option>
              <option value="Food">{t('GeneralSettings.food')}</option>
              <option value="Government">
                {t('GeneralSettings.government')}
              </option>
              <option value="Grocery">{t('GeneralSettings.grocery')}</option>
              <option value="Lodging">{t('GeneralSettings.lodging')}</option>
              <option value="Miscellaneous Merchandise">
                {t('GeneralSettings.miscellaneousGoods')}
              </option>
              <option value="Services">{t('GeneralSettings.services')}</option>
              <option value="Travel">{t('GeneralSettings.travel')}</option>
              <option value="Utilities">
                {t('GeneralSettings.utilities')}
              </option>
            </select>
            {renderSwitch(categories)}
          </div>
        </div>
      </div>
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('GeneralSettings.phoneNumber')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="phone"
              type="text"
              placeholder={t('GeneralSettings.phoneNumberPlaceholder')}
              errorMsg={errors?.phone?.message}
              validateStatus={errors?.phone ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('GeneralSettings.email')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="email"
              type="text"
              placeholder={t('GeneralSettings.emailPlaceholder')}
              errorMsg={errors?.email?.message}
              validateStatus={errors?.email ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="triple-wrapper">
          <div className="text-field-label-wrap">
            <p className="label-p">
              {t(
                'GeneralSettings.commercialRegisterOrselfEmployeedCertificate'
              )}
            </p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="commercialRecord"
                type="text"
                placeholder={t(
                  'GeneralSettings.commercialRegisterOrselfEmployeedCertificatePlaceholder'
                )}
                errorMsg={errors?.commercialRecord?.message}
                validateStatus={errors?.commercialRecord ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">{t('GeneralSettings.address')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="address"
                type="text"
                placeholder={t('GeneralSettings.address')}
                errorMsg={errors?.address?.message}
                validateStatus={errors?.address ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">{t('GeneralSettings.regularTaxRate')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="system_tax"
                type="text"
                placeholder={t('GeneralSettings.regularTaxRatePlaceholder')}
                errorMsg={errors?.system_tax?.message}
                validateStatus={errors?.system_tax ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
        </div>

        <div className="country-city-region-wrap">
          <div className="text-field-label-wrap">
            <p className="label-p">{t('GeneralSettings.country')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="country"
                type="text"
                placeholder={t('GeneralSettings.countryPlaceholder')}
                errorMsg={errors?.country?.message}
                validateStatus={errors?.country ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">{t('GeneralSettings.city')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="city"
                type="text"
                placeholder={t('GeneralSettings.cityPlaceholder')}
                errorMsg={errors?.city?.message}
                validateStatus={errors?.city ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">{t('GeneralSettings.region')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="district"
                type="text"
                placeholder={t('GeneralSettings.regionPlaceholder')}
                errorMsg={errors?.district?.message}
                validateStatus={errors?.district ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
        </div>

        <div className="building-number-street-name">
          <div className="text-field-label-wrap">
            <p className="label-p">{t('GeneralSettings.buildingNumber')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="buildingNum"
                type="text"
                placeholder={t('GeneralSettings.buildingNumberPlaceholder')}
                errorMsg={errors?.buildingNum?.message}
                validateStatus={errors?.buildingNum ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">{t('GeneralSettings.streetName')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="streetName"
                type="text"
                placeholder={t('GeneralSettings.streetNamePlaceholder')}
                errorMsg={errors?.streetName?.message}
                validateStatus={errors?.streetName ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">{t('GeneralSettings.addressCode')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="addressCode"
                type="text"
                placeholder={t('GeneralSettings.addressCodePlaceholder')}
                errorMsg={errors?.addressCode?.message}
                validateStatus={errors?.addressCode ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
        </div>
        <div className="post-box-wrap1">
          <div className="text-field-label-wrap">
            <p className="label-p">{t('GeneralSettings.postalCode')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="postalCode"
                type="text"
                placeholder={t('GeneralSettings.postalCodePlaceholder')}
                errorMsg={errors?.postalCode?.message}
                validateStatus={errors?.postalCode ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">
              {t('GeneralSettings.VATRegistrationNumber')}
            </p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="tax_number"
                type="text"
                placeholder={t(
                  'GeneralSettings.VATRegistrationNumberPlaceholder'
                )}
                errorMsg={errors?.tax_number?.message}
                validateStatus={errors?.tax_number ? 'error' : ''}
                control={control}
              />
            </div>
          </div>

          <div className="text-field-label-wrap">
            <p className="label-p">{t('GeneralSettings.anotherID')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="anotherId"
                type="text"
                placeholder={t('GeneralSettings.anotherIDPlaceholder')}
                control={control}
              />
            </div>
          </div>
          <div
            className="slogan-types-wrap"
            style={{
              gridColumn: '1 / -1',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              alignItems: 'center',
              gap: 22
            }}
          >
            <div className="text-field-label-wrap">
              <p className="label-p">{t('GeneralSettings.logo')}</p>
              <div className="text-field-wrap">
                <AntdTextarea
                  className="form-text-area"
                  name="slogan"
                  rows={4}
                  placeholder={t('GeneralSettings.logoPlaceholder')}
                  errorMsg={errors?.slogan?.message}
                  validateStatus={errors?.slogan ? 'error' : ''}
                  control={control}
                />
              </div>
            </div>
            <div className="radios-wrap">
              <p className="radios-group-title">
                {t('GeneralSettings.chooseInvoicesType')}
              </p>
              <div className="labels-wrap fatoorah-types-wrapper">
                {[
                  {
                    title: fatoorahViewTypes?.sellerBuyer?.label,
                    value: fatoorahViewTypes?.sellerBuyer?.value
                  },
                  {
                    title: fatoorahViewTypes?.renter?.label,
                    value: fatoorahViewTypes?.renter?.value
                  },
                  {
                    title: fatoorahViewTypes?.senderReceiver?.label,
                    value: fatoorahViewTypes?.senderReceiver?.value
                  }
                ].map((obj, i) => (
                  <label
                    key={i}
                    className={`radio-input-label ${
                      String(watch(`fatoraViewType`)) == String(obj.value)
                        ? 'selected'
                        : ''
                    }`}
                  >
                    <input
                      type="radio"
                      value={obj.value}
                      {...register('fatoraViewType')}
                    />
                    {watch(`fatoraViewType`) == String(obj.value) ? (
                      <RadioButtonFilled />
                    ) : (
                      <RadioButtonEmpty />
                    )}
                    <span className="label-span">{obj.title}</span>
                  </label>
                ))}
              </div>

              {errors?.fatoraViewType && (
                <p className="error-p">{errors.fatoraViewType.message}</p>
              )}
            </div>
          </div>
        </div>

        <div className="product-img-wrap settings-form-img-wrap">
          <h3>{t('GeneralSettings.systemImage')}</h3>

          <MyDropZone
            className="product-header-dropzone"
            multipleFiles={false}
            handleFilesDrop={(acceptedFiles) =>
              handleFilesDrop(
                acceptedFiles,
                systemFilesToUpload,
                setSystemFilesToUpload
              )
            }
            filesToUpload={systemFilesToUpload}
            formName="store-new-product-form"
            id="avatar-input"
            dropzoneText={t('GeneralSettings.systemImage')}
            inputName="system_img"
            icon={<CameraFilled className="dropzone-camera" />}
            dropZoneUrls={systemImgUrl}
          >
            {t('GeneralSettings.dragImage')}
          </MyDropZone>

          <MyDropZonePreview
            filesToUpload={systemFilesToUpload}
            clearFileFromUpload={(f) =>
              clearFileFromUpload(f, setSystemFilesToUpload)
            }
          />
          {/* {errors?.system_img?.message && !watch('system_img') && (
            <p className="error-p">{errors.system_img.message}</p>
          )} */}
        </div>
      </div>
      <div className="btn">
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {t('GeneralSettings.save')}
        </Button>
      </div>
    </Form>
  );
};

export default GeneralSettingsPageForm;
