import HomeIcon from '../../common/icons/HomeIcon';
import StoreIcon from '../../common/icons/StoreIcon';
import ArchiveIcon from '../../common/icons/ArchiveIcon';
import ShoppingIcon from '../../common/icons/ShoppingIcon';
import Links from '../app/Links';
const routerLinks = Links();

const dataEntryLinks = (t) => {
  return [
    {
      id: 1,
      to: routerLinks.homePage,
      name: t('AppBar.Services.homePage'),
      isLink: true,
      icon: (color) => (color ? <HomeIcon color={color} /> : <HomeIcon />),
      subMenu: [],
      visibleSubMenu: false
    },
    {
      id: 6,
      to: routerLinks.storePage,
      name: t('Home.services'),
      icon: (color) => (color ? <StoreIcon color={color} /> : <StoreIcon />),
      visibleSubMenu: false,
      subMenu: [
        {
          id: 1,
          to: routerLinks.storeItemManagerPage,
          name: t('AppBar.Services.storePage.storeItemManagerPage')
        },
        {
          id: 2,
          to: routerLinks.storeProductsIngredientsPage,
          name: t('AppBar.Services.storePage.storeProductsIngredientsPage')
        }
      ]
    },
    {
      id: 4112121221,
      // to: routerLinks.salesPage,
      to: routerLinks.outgoingPurchaseInvoice,
      isLink: true,
      name: t('AppBar.Services.salesPage.title'),
      icon: (color) =>
        color ? <ShoppingIcon color={color} /> : <ShoppingIcon />,
      visibleSubMenu: false,
      subMenu: []
    },
    {
      id: 4118871267671,
      // to: routerLinks.salesPage,
      to: routerLinks.incomingPurchaseInvoice,
      isLink: true,
      name: t('AppBar.Services.incomingPurchaseInvoice.title'),
      icon: (color) =>
        color ? <ShoppingIcon color={color} /> : <ShoppingIcon />,
      visibleSubMenu: false,
      subMenu: []
    },
    {
      id: 4,
      to: routerLinks.clientPage,
      name: t('AppBar.Services.salesPage.clientPage'),
      icon: (color) =>
        color ? <ShoppingIcon color={color} /> : <ShoppingIcon />,
      visibleSubMenu: false,
      isLink: true,
      subMenu: []
    },
    {
      id: 48918293,
      to: routerLinks.suppliersPage,
      name: t('AppBar.Services.incomingPurchaseInvoice.suppliersPage'),
      icon: (color) =>
        color ? <ShoppingIcon color={color} /> : <ShoppingIcon />,
      visibleSubMenu: false,
      isLink: true,
      subMenu: []
    },
    {
      id: 11,
      to: routerLinks.archivePage,
      name: t('AppBar.Services.archivePage.title'),
      icon: (color) =>
        color ? <ArchiveIcon color={color} /> : <ArchiveIcon />,
      subMenu: [
        {
          id: 1,
          to: routerLinks.archiveNotesPage,
          name: t('AppBar.Services.archivePage.archiveNotesPage')
        },
        {
          id: 3,
          to: routerLinks.archiveDocsPage,
          name: t('AppBar.Services.archivePage.archiveDocsPage')
        }
      ],
      visibleSubMenu: false
    }
  ];
};

export default dataEntryLinks;
