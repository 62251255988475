import React from 'react';

const AddToCartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="328"
      height="328"
      viewBox="0 0 328 328"
    >
      <g transform="translate(8122 9622)">
        <circle
          cx="164"
          cy="164"
          r="164"
          transform="translate(-8122 -9622)"
          fill="#2e4652"
        />
        <g transform="translate(16.565 -215.435)">
          <line
            x2="157.608"
            transform="translate(-8052.935 -9242.131)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="37"
          />
          <line
            x2="157.608"
            transform="translate(-7974.13 -9320.935) rotate(90)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="37"
          />
        </g>
      </g>
    </svg>
  );
};

export default AddToCartIcon;
