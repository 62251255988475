/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Select, DatePicker } from 'antd';
import SelectDropDown from '../../../common/icons/SelectDropDown';
import UserContext from '../../../contexts/user-context/UserProvider';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import CalendarIcon from '../../../common/icons/CalendarIcon';
import excelImg from '../../../assets/imgs/icons/excel-btn.png';
// import Workbook from 'react-excel-workbook';
// import Workbook from 'react-excel-workbook';
import useClients from '../../../custom-hooks/useClients';
import useSuppliers from '../../../custom-hooks/useSuppliers';
import getAllOutgoingPurchaseInvoicesApi from '../../../apis/outgoing-buy-invoice-apis/getAllOutgoingPurchaseInvoicesApi';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import useUsers from '../../../custom-hooks/useUsers';
import storeRouterLinks from '../../../components/app/store-routes/storeRouterLinks';
import invoiceFilterTypes from '../../../invoiceFilterTypes';
import userRoles from '../../../userRoles';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import SearchIcon from '../../../common/icons/SearchIcon';
import useWorkPlaces from '../../../custom-hooks/useWorkPlaces';
import debounce from 'lodash.debounce';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import SyncSallaApi from '../../../apis/fatoorahStore-api/SyncSallaApi';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

// const receiptContent = [
//   {
//     title: 'فواتير خدمات',
//     value: '1'
//   },
//   {
//     title: 'فواتير مكونات',
//     value: '2'
//   }
// ];

// type === 1 => invoice is a normal buying invoice
// type === 2 => invoice is a return invoice
// type === 3 => invoice is a reservation invoice
// is_return === 1 => invoice of products
// today_reservations === 1 => get today reservations
// user_id === 2 => id of the client

const TableFilter = ({
  allFetchedReceipts,
  isReportsPage,
  selectedPicker,
  setSelectedPicker
}) => {
  const history = useHistory();
  const { user } = useContext(UserContext);
  const { Option } = Select;
  const { allClients } = useClients();
  const { allSuppliers } = useSuppliers();
  const allDelegateUsers = useUsers(userRoles?.delegate);
  const systemSettings = useSystemSettings();
  const allWorkPlaces = useWorkPlaces();
  const { pathname } = useLocation();
  const user_id_from_payment = pathname?.split('/')[3];
  const { t } = useTranslation();
  const receiptTypes = [
    {
      title: t('Invoices.saleInvoices'),
      value: '1'
    },
    {
      title: t('Invoices.returnInvoices'),
      value: '2'
    }
  ];
  const {
    setAllFetchedReceipts,
    setIsLoading,
    tableFilter,
    setTableFilter,
    setTablePagination
  } = useContext(OutgoingPurchaseInvoiceContext);

  const { search } = useLocation();
  const values = queryString.parse(search);

  const debouncedChangeHandler = useCallback(
    debounce(async (e) => {
      setTableFilter((prevState) => ({
        ...prevState,
        invoiceNumber: e.target.value
      }));
    }, 500),
    []
  );

  const customApiRequest = useCustomApiRequest();
  const handlePickerChange = (value, string) => {
    setSelectedPicker({
      moments: value,
      strings: string
    });
  };
  React.useEffect(() => {
    if (selectedPicker?.strings?.length > 0) {
      setTableFilter((prev) => ({
        ...prev,
        from: selectedPicker.strings[0],
        to: selectedPicker.strings[1]
      }));
    }
  }, [selectedPicker?.strings]);

  async function handleChange(filterType, value) {
    setTableFilter((prevState) => {
      if (filterType === 'invoiceNumber') {
        return {
          ...prevState,
          invoiceNumber: value.target.value
        };
      } else if (filterType === 'id') {
        return {
          ...prevState,
          id: value.target.value
        };
      } else if (filterType === 'user_id') {
        return {
          ...prevState,
          user_id: value || user_id_from_payment
        };
      } else if (filterType === 'supplier_id') {
        return {
          ...prevState,
          supplier_id: value
        };
      } else if (filterType === 'type') {
        return {
          ...prevState,
          type: value
        };
      } else if (filterType === 'serviceOrPackage') {
        return {
          ...prevState,
          serviceOrPackage: value
        };
      } else if (filterType === 'admin_id') {
        return {
          ...prevState,
          admin_id: value
        };
      }
    });
  }

  useEffect(() => {
    setIsLoading(true);
    customApiRequest(
      getAllOutgoingPurchaseInvoicesApi(
        user?.token,
        {
          ...tableFilter,
          ...values,
          page: 1,
          user_id_from_payment: user_id_from_payment
        },
        isReportsPage
      ),
      (res) => {
        if (isReportsPage) {
          history.push(
            `${storeRouterLinks?.outgoingPurchaseInvoiceReports}?page=1`
          );
        } else {
          // history.push(`${storeRouterLinks?.outgoingPurchaseInvoice}`);
        }
        setIsLoading(false);
        //reports

        if (isReportsPage) {
          if (checkRes(res) && res?.data?.data?.invoices?.data.length >= 0) {
            const data = res.data.data.invoices.data;
            setAllFetchedReceipts(data);
            if (res?.data?.data?.invoices.pagination) {
              setTablePagination(res.data.data.invoices.pagination);
            }
          }
        }
        if (checkRes(res) && res?.data?.data?.data.length >= 0) {
          const data = res.data.data.data;
          setAllFetchedReceipts(data);
          if (res?.data?.data?.pagination) {
            setTablePagination(res.data.data.pagination);
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }, [tableFilter]);

  // async function handleSubmit(e) {
  //   e.preventDefault();
  //   setIsLoading(true);
  //   customApiRequest(
  //     getAllOutgoingPurchaseInvoicesApi(user?.token, {
  //       ...tableFilter,
  //       ...values,
  //       page: 1
  //     }),
  //     (res) => {
  //       if (isReportsPage) {
  //         history.push(
  //           `${storeRouterLinks?.outgoingPurchaseInvoiceReports}?page=1`
  //         );
  //       } else {
  //         history.push(`${storeRouterLinks?.outgoingPurchaseInvoice}?page=1`);
  //       }
  //       setIsLoading(false);
  //       if (checkRes(res) && res?.data?.data?.data) {
  //         if (res.data.data.data.length >= 0) {
  //           const data = res.data.data.data;
  //           setAllFetchedReceipts(data);
  //         }
  //         if (res?.data?.data?.pagination) {
  //           setTablePagination(res.data.data.pagination);
  //         }
  //       } else {
  //       }
  //     },
  //     (error) => {
  //       setIsLoading(false);
  //     }
  //   );
  // }

  const syncSallaAuth = async () => {
    try {
      const res = await SyncSallaApi(
        user?.token,
        systemSettings?.sallaToken,
        'orders'
      );
      if (res?.status === 200 && res?.data?.status === 1) {
        // setIsLoading(false);
        // setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Messages.addedSuccessfully'),
          message:
            t('Messages.ordersshavebeenlinkedtosallasuccessfully') ||
            res.data.message
        });
      } else {
        // setIsLoading(false);
        errorNotification({
          title: t('Messages.ErrorOccurred'),
          message: res?.data?.message
            ? res.data.message
            : t('Messages.TryAgainLater')
        });
      }
    } catch (error) {
      // setIsLoading(false);
      errorNotification({
        title: t('Messages.ErrorOccurred'),
        message: t('Messages.TryAgainLater')
      });
    }
  };

  return (
    <div className="custom-table-fitler warehouse-table-filter">
      <div className="form-excel-export-wrap">
        <form
          className="filter-form receipt-filter-form"
          // onSubmit={handleSubmit}
        >
          <div className="search-label-wrapper">
            <p
              style={{
                marginBottom: 5
              }}
            >
              {t('Invoices.InvoiceNumber')}
            </p>
            <div className="table-search" style={{ width: '310px' }}>
              <label>
                <SearchIcon />
                <input
                  placeholder={t('Invoices.EnterSerialInvoiceNumber')}
                  type="text"
                  name="invoiceNumber"
                  onChange={(e) => debouncedChangeHandler(e)}
                />
              </label>
            </div>
          </div>

          <label className="receipt-filter-option-wrap">
            <span className="select-title">{t('Invoices.CustomerName')} </span>
            <Select
              defaultValue={user_id_from_payment || null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('user_id', v)}
              suffixIcon={<SelectDropDown />}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>{t('Invoices.AllCustomers')}</Option>
              {allClients?.length > 0 &&
                allClients.map((item) => (
                  <Option key={item.id} value={String(item.id)}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </label>

          <label className="receipt-filter-option-wrap">
            <span className="select-title">{t('Invoices.supplierName')}</span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('supplier_id', v)}
              suffixIcon={<SelectDropDown />}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>{t('Invoices.allSuppliers')}</Option>
              {allSuppliers?.length > 0 &&
                allSuppliers.map((item) => (
                  <Option key={item.id} value={String(item.id)}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </label>

          {user?.role !== userRoles?.delegate && (
            <label className="receipt-filter-option-wrap">
              <span className="select-title">{t('Invoices.EmployeeName')}</span>
              <Select
                defaultValue={null}
                style={{ width: 172 }}
                onChange={(v) => handleChange('admin_id', v)}
                suffixIcon={<SelectDropDown />}
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={null}>{t('Invoices.allEmployees')}</Option>
                {allDelegateUsers?.length > 0 &&
                  allDelegateUsers.map((item) => (
                    <Option key={item.id} value={String(item.id)}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </label>
          )}

          <label className="receipt-filter-option-wrap">
            <span className="select-title">{t('Invoices.InvoiceType')}</span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('type', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>{t('Invoices.allInvoices')}</Option>
              {receiptTypes.map((role, index) => (
                <Option key={index} value={role.value}>
                  {role.title}
                </Option>
              ))}
            </Select>
          </label>

          {/* <label className="receipt-filter-option-wrap">
            <span className="select-title">إســم الفرع</span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('admin_id', v)}
              suffixIcon={<SelectDropDown />}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>جميع الفروع</Option>
              {allWorkPlaces?.length > 0 &&
                allWorkPlaces.map((item) => (
                  <Option key={item.id} value={String(item.id)}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </label> */}
          {/* <label className="receipt-filter-option-wrap">
            <span className="select-title">محتوى الخدمة</span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('serviceOrPackage', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>جميع الخدمات</Option>
              {receiptContent.map((role, index) => (
                <Option key={index} value={role.value}>
                  {role.title}
                </Option>
              ))}
            </Select>
          </label> */}

          <div className="range-picker-wrap">
            <RangePicker
              value={selectedPicker?.moments && selectedPicker.moments}
              onChange={handlePickerChange}
              // format="YYYY-MM-DD"
              className={`filter-range-picker`}
              allowClear={true}
              suffixIcon={<CalendarIcon />}
              placeholder={[t('Invoices.dateFrom'), t('Invoices.dateTo')]}
            />
          </div>
          {/* <button className="filter-submit-btn" type="submit">
            بحث
          </button> */}
        </form>
        {/* {systemSettings?.sallaToken && (
          <div
            className="preview-download-upload add-btn"
            onClick={() => syncSallaAuth()}
          >
            <span className="text-span">{t('Invoices.TieWithSalla')}</span>
          </div>
        )} */}
        <div className="excel-btn-wrap">
          <a
            href={`${
              process.env.REACT_APP_BASE_URL
            }/exportExcelInvoice?client_id=${systemSettings?.id || ''}&type=${
              invoiceFilterTypes?.outgoingInvoice || ''
            }&today_reservations=${
              tableFilter?.today_reservations || ''
            }&user_id=${tableFilter?.user_id || ''}&supplier_id=${
              tableFilter?.supplier_id || ''
            }&from=${tableFilter?.from || ''}&to=${
              tableFilter?.to || ''
            }&pos_id=${tableFilter?.pos_id || ''}&admiin_id=${
              tableFilter?.admin_id || ''
            }&page=${tableFilter?.page || '1'}&invoiceNumber=${
              tableFilter?.invoiceNumber
            }&typeN=${tableFilter?.type}`}
            download
            className="excel-export-btn"
          >
            <img
              style={{ height: '42px' }}
              src={excelImg}
              alt="export to excel"
            />
          </a>
        </div>

        {/* <div className="excel-btn-wrap">
          <Workbook
            filename="outgoing_invoice.xlsx"
            element={
              <div className="excel-export-btn">
                <img
                  style={{ height: '42px' }}
                  src={excelImg}
                  alt="export to excel"
                />
              </div>
            }
          >
            <Workbook.Sheet
              data={
                allFetchedReceipts?.invoices
                  ? allFetchedReceipts?.invoices
                  : allFetchedReceipts
                  ? allFetchedReceipts
                  : []
              }
              name="Sheet A"
            >
              <Workbook.Column label="مسلسل" value="invoiceNumber" />
              <Workbook.Column
                label="العميل"
                value={(row) => (row?.user?.name ? row.user.name : row.name)}
              />
              <Workbook.Column
                label="تاريخ الفاتورة"
                value={(row) =>
                  row?.dueDate ? row.dueDate : row?.date ? row.date : '----'
                }
              />
              <Workbook.Column
                label="السعر الإجمالى"
                value={(row) => row.totalPrice}
              />
            </Workbook.Sheet>
          </Workbook>
        </div> */}
      </div>
    </div>
  );
};

export default TableFilter;
