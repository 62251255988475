/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import getAllCategoriesApi from '../apis/settings-apis/categories-apis/getAllCategoriesApis';
import UserContext from '../contexts/user-context/UserProvider';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';

const useSubCategories = (cat_id) => {
  const { user } = useContext(UserContext);
  const [allSubCategories, setAllSubCategories] = useState(null);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getAllCategoriesApi(
          user?.token,
          2, // level is 2 as its is a sub category
          cat_id // id of the main category
        ),
        (res) => {
          if (checkRes(res) && res?.data?.data?.length >= 0) {
            if (res.data.data.length >= 0) {
              const data = res.data.data;
              setAllSubCategories(data);
            }
          } else {
            //
          }
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, [cat_id]);

  return allSubCategories;
};

export default useSubCategories;
