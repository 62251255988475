import React, { useContext } from 'react';
import SettingsPosContext from '../../../contexts/settings-contexts/SettingsPosPage';
import PageTable from './PageTable';
import PageModal from './PageModal';
import UserContext from '../../../contexts/user-context/UserProvider';
import UpgradeContext from '../../../contexts/upgrade-context/UpgradeContext';
import { useEffect } from 'react';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useState } from 'react';
import upgradePosApi from '../../../apis/settings-apis/pos-apis/upgradePosApi';
import { Button } from 'antd';
import getPackagesToUpgradeApi from '../../../apis/upgrade/getPackagesToUpgradeApi';
import { store } from 'react-notifications-component';
import getAllPosPackages from '../../../apis/settings-apis/pos-apis/getAllPosPackages';
import { Grid } from '@mui/material';
import subscriptionPackagePosApi from '../../../apis/settings-apis/pos-apis/subscriptionPackagePosApi';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
const SettingsPosPage = () => {
  const { modalOpened } = useContext(SettingsPosContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const {
    selectedPackage,
    setPaymentVisible,
    paymentVisible,
    setPosNum,
    posNum,
    fetchedPackages,
    setFetchedPackages,
    discountCode,
    setDiscountCode,
    isCheckingDiscountCode,
    setIsCheckingDiscountCode,
    checkCodeRes,
    setCheckCodeRes,
    // setTotal,
    total
  } = useContext(UpgradeContext);

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (type) => {
    if (type === 'plus') {
      setPosNum((prev) => prev + 1);
    } else if (type === 'minus' && posNum > 0) {
      setPosNum((prev) => prev - 1);
    }
  };

  const customApiRequest = useCustomApiRequest();
  const [posPackages, setPosPackages] = useState();
  const [selectedPosPackages, setSelectedPosPackages] = useState();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getPackagesToUpgradeApi(),
        (res) => {
          if (res?.data?.data) {
            const data = res?.data?.data;
            setFetchedPackages(data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllPosPackages(user?.token),
        (res) => {
          if (res?.data?.data) {
            const data = res?.data?.data?.data;
            setPosPackages(data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  // pos_number
  // total
  // package_pos_id
  // package_name

  const handleAddPos = () => {
    customApiRequest(
      upgradePosApi(user?.token, { pos_number: posNum, total: total }),
      (res) => {
        if (res?.data?.status) {
          setIsLoading(false);
          store.addNotification({
            title: res?.data?.message,
            message: res?.data?.message,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              showIcon: true,
              onScreen: true
            }
          });
        } else {
          setIsLoading(false);
          store.addNotification({
            title: res?.data?.message,
            message: res?.data?.message,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              showIcon: true,
              onScreen: true
            }
          });
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };

  const submitSubscriptionPackages = (data) => {
    customApiRequest(
      subscriptionPackagePosApi(user?.token, { ...data }),
      (res) => {
        if (res?.data?.status) {
          setIsLoading(false);
          store.addNotification({
            title: res?.data?.message,
            message: res?.data?.message,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              showIcon: true,
              onScreen: true
            }
          });
        } else {
          setIsLoading(false);
          store.addNotification({
            title: res?.data?.message,
            message: res?.data?.message,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              showIcon: true,
              onScreen: true
            }
          });
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };

  return (
    <div className="system-settings-page">
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">{t('PointsOfSale.title')}</h2>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('PointsOfSale.title')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title3={t('PointsOfSale.title')}
            link3={'/store/services'}
          />
        </div>
        <SearchNotificationsProfile />
      </div>

      <div className="img-form-wrap">
        <div className="measurement-units-page">
          <div className="SettingsPosPage-table-wrapper">
            <PageTable />
          </div>

          {/* <div className="add-new-pos">
        <h2>إضافه نقطه بيع جديده</h2>
        {user?.subscriptionType === 1 && (
          <div className="pos-count-wrap">
            <div className="btns-labels-wrap">
              <span className="pos-label">لزيادة نقطة البيع</span>
              <div className="plus-minus-wrap">
                <div
                  className="plus-btn"
                  onClick={() => {
                    handleClick('plus');
                  }}
                >
                  <span>+</span>
                </div>
                <span className="pos-number">{posNum}</span>
                <div
                  className="minus-btn"
                  onClick={() => {
                    handleClick('minus');
                  }}
                >
                  <span>-</span>
                </div>
              </div>

              <div className="pos-price-wrap">
                <span className="price-span">
                  {fetchedPackages?.setting?.pos_price}
                </span>
                <span className="unit-span">ريال</span>
              </div>
            </div>

            <div className="total-labels-values-wrap">
              <div className="labels-wrap">
                <span>نقطة البيع :</span>
                <span className="pos-count">{posNum}</span>
                <span>نقط بيع</span>
              </div>
              <div className="values-wrap">
                <span>المجموع الكلى :</span>
                <span>{total}</span>
                <span>ريال</span>
              </div>
            </div>
            <Button onClick={() => handleAddPos()} className="add-pos-btn">
              إضافه جديد
            </Button>
          </div>
        )}
      </div> */}

          {/* <div className="pos-packages">
        <h2 className="pos-packages-title">باقات نقاط البيع </h2>
        <div className="pos-packages-content">
          <Grid container spacing={2}>
            {posPackages?.length > 0 &&
              posPackages?.map((pkg) => (
                <Grid item xs={6} md={4} key={pkg?.id}>
                  <div className="package-card-content">
                    <img src={pkg?.icon} alt="" />
                    <h2>{pkg?.name}</h2>
                    <p>{pkg?.description}</p>
                    <h4>سعر الباقه : {pkg?.price}</h4>
                    <h4>عدد نقاط البيع : {pkg?.number_pos}</h4>
                    <Button
                      className="buy-btn"
                      onClick={() => {
                        setSelectedPosPackages(pkg);
                        submitSubscriptionPackages(pkg);
                      }}
                    >
                      شراء الباقه
                    </Button>
                  </div>
                </Grid>
              ))}
          </Grid>
        </div>
      </div> */}

          {modalOpened && <PageModal />}
        </div>
      </div>
    </div>
  );
};

export default SettingsPosPage;
