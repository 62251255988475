import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
// import { CategoryPage } from '../../pages/category/CategoryPage';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import routerLinks from './routerLinks';
import StoreRoutes from './store-routes/StoreRoutes';
import SettingsRoutes from './settings-routes/SettingsRoutes';
import ArchiveRoutes from './archive-routes/ArchiveRoutes';
import AccountingRoutes from './accounting-routes/AccountingRoutes';
// import { StatisticsProvider } from '../../contexts/statistics-context/StatisticsContext';
import ProfilePage from '../../pages/profile-page/ProfilePage';
import ReportsRoutes from './reports-routes/ReportsRoutes';
import protectMe from '../../utils/protectMe';
import UserContext from '../../contexts/user-context/UserProvider';
import PriceOffersRoutes from './price-offers-routes/priceOffersRoutes';
import fatoorahStoreRouterLinks from './FatoorahStore/FatoorahStore';
import SalesRoutes from './sales-routes/SalesRoutes';
import PosRoutes from './pos-routes/PosRoutes';
import NfcTagsPage from '../../pages/QR-code/NfcTagsPage';
import { TagsProvider } from '../../contexts/nfcTags-context/tagsContext';
// const HomePage = lazy(() => import('../../pages/home-page/HomePage'));

const Routes = () => {
  const { user, loggedIn } = useContext(UserContext);

  return (
    <Switch>
      {/* <Route exact path={routerLinks.homePage}>
        <StatisticsProvider>
          <HomePage />
        </StatisticsProvider>
      </Route> */}

      {/* store routes */}
      {StoreRoutes()}
      {/* ./store routes */}

      {/* sales routes */}
      {SalesRoutes()}
      {/* sales routes */}

      {/* accounts routes */}
      {AccountingRoutes()}
      {/* ./accounts routes */}

      {/* price offers routes */}
      {PriceOffersRoutes()}
      {/* ./price offers routes */}

      {/* settings routes */}
      {SettingsRoutes()}
      {/* settings routes */}

      {/* Reports routes */}
      {ReportsRoutes()}
      {/* Reports routes */}
      {/* archive routes */}
      {ArchiveRoutes()}
      {/* archive routes */}
      {/* Pos routes */}
      {user.role === 5 && PosRoutes()}
      {/* pos routes */}

      {/* <Route exact path={routerLinks.ProfilePage} component={ProfilePage} /> */}
      {protectMe(
        routerLinks.ProfilePage,
        <ProfilePage />,
        65129076,
        routerLinks.signInPage,
        loggedIn,
        user?.expire
      )}

      {protectMe(
        routerLinks.QRPage,
        user.role !== 5 ? (
          <TagsProvider>
            <NfcTagsPage />
          </TagsProvider>
        ) : (
          <NotFoundPage />
        ),
        6512907011900,
        routerLinks.signInPage,
        loggedIn,
        user?.expire
      )}

      {/* not found page */}
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default Routes;
