import React, { useState, useEffect, useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Form, Button, Input, Select, Empty } from 'antd';
// import createTag from '../../apis/nfcTags/createTagApi';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/user-context/UserProvider';
import TagsContext from '../../contexts/nfcTags-context/tagsContext';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import createTag from '../../apis/nfcTags-api/createTagApi';
import getNfcPos from '../../apis/nfcTags-api/getNfcPos';
import ArrowDownIcon from '../../common/icons/ArrowDownIcon';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';

const TagForm = () => {
  const { user } = useContext(UserContext);

  // const { userData, setFetchProfileCount } = useContext(UserContext);
  const {
    setFetchCount,
    setIsModalOpend,
    selectedTag,
    setSelectedTag,
    setFilter
  } = useContext(TagsContext);
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    tag: Yup.string().required(t('NFCTags.tagValidation')),
    pos_id: Yup.string().required(t('NFCTags.posValidation'))
  });
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      tag: '',
      pos_id: ''
    }
  });
  const [form] = Form.useForm();
  // handle initial values
  useEffect(() => {
    if (selectedTag) {
      if (selectedTag?.pos_id) setValue('pos_id', String(selectedTag?.pos_id));
      if (selectedTag.tag) setValue('tag', selectedTag.tag);
    }
  }, []);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('tag', data?.tag);
    formData.append('pos_id', data?.pos_id);
    let url = 'NfcTags/create';
    if (selectedTag) {
      formData.append('id', selectedTag?.id);
      url = 'NfcTags/update';
    }

    try {
      const res = await createTag(user.token, formData, url);
      if (res?.status === 200 && res?.data?.status) {
        setFetchCount((prevState) => prevState + 1);
        setIsModalOpend(false);
        setSelectedTag('');
        setFilter('');
        selectedTag
          ? successNotification({
              title: t('Messages.addedSuccessfully'),
              message: t('Messages.Modifiedsuccessfully')
            })
          : successNotification({
              title: t('Messages.addedSuccessfully'),
              message: t('Messages.TagsAddedSuccessfully')
            });
      } else {
        errorNotification({
          title: t('Messages.ErrorOccurred'),
          message: res?.data?.message
            ? res.data.message
            : t('Messages.TryAgainLater')
        });
      }
    } catch (error) {}
  };

  const [branches, setBranches] = useState([]);
  useEffect(() => {
    const getBranchesCall = async () => {
      try {
        const fetchedData = await getNfcPos(user.token);
        if (fetchedData.status === 200 && fetchedData?.data?.data) {
          setBranches(fetchedData?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getBranchesCall();
  }, [user?.token]);

  return (
    <Form
      className="tag-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('NFCTags.tagName')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="tag"
              type="text"
              setValue={setValue}
              placeholder={t('NFCTags.tagName')}
              errorMsg={errors?.tag?.message}
              validateStatus={errors?.tag ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="select-label-wrap">
          <p className="label-p">{t('NFCTags.posName')}</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="pos_id"
              errorMsg={errors?.pos_id?.message}
              validateStatus={errors?.pos_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder={t('NFCTags.posName')}
              options={
                branches?.length > 0 &&
                branches.map((branch) => ({
                  title: branch.name,
                  value: branch.id
                }))
              }
              formClassName="tag-form"
            />
          </div>
        </div>

        <Button className="submit-btn" htmlType="submit" type="primary">
          {selectedTag ? t('NFCTags.update') : t('NFCTags.add')}
        </Button>
      </div>
    </Form>
  );
};

export default TagForm;
