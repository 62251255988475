/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker, Select } from 'antd';
import queryString from 'query-string';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CalendarIcon from '../../../common/icons/CalendarIcon';
import SelectDropDown from '../../../common/icons/SelectDropDown';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import UserContext from '../../../contexts/user-context/UserProvider';
// import Workbook from 'react-excel-workbook';
import { useTranslation } from 'react-i18next';
import getAllPriceOffersApiNew from '../../../apis/new-price-offers-apis/getAllPriceOffersApiNew';
import excelImg from '../../../assets/imgs/icons/excel-btn.png';
import storeRouterLinks from '../../../components/app/store-routes/storeRouterLinks';
import useClients from '../../../custom-hooks/useClients';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import useSuppliers from '../../../custom-hooks/useSuppliers';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import checkRes from '../../../utils/checkRes';
import "./TableFilterNew.scss"
const { RangePicker } = DatePicker;

// const receiptContent = [
//   {
//     title: 'فواتير خدمات',
//     value: '1'
//   },
//   {
//     title: 'فواتير مكونات',
//     value: '2'
//   }
// ];

// type === 1 => invoice is a normal buying invoice
// type === 2 => invoice is a return invoice
// type === 3 => invoice is a reservation invoice
// is_return === 1 => invoice of products
// today_reservations === 1 => get today reservations
// user_id === 2 => id of the client

const TableFilter = ({ allFetchedReceipts, isReportsPage }) => {
  const history = useHistory();
  const systemSettings = useSystemSettings();
  const { user } = useContext(UserContext);
  const { Option } = Select;
  const [selectedPicker, setSelectedPicker] = React.useState(null);
  const { allClients } = useClients();
  const { allSuppliers } = useSuppliers();
  const { t } = useTranslation();

  const {
    setAllFetchedReceipts,
    setIsLoading,
    tableFilter,
    setTableFilter,
    setTablePagination
  } = useContext(OutgoingPurchaseInvoiceContext);
  const { search } = useLocation();
  const values = queryString.parse(search);

  const customApiRequest = useCustomApiRequest();
  const handlePickerChange = (value, string) => {
    
    
    setSelectedPicker({
      moments: value,
      strings: string
    });
  };
  React.useEffect(() => {
    if (selectedPicker?.strings?.length > 0) {
      setTableFilter((prev) => ({
        ...prev,
        from: selectedPicker.strings[0],
        to: selectedPicker.strings[1]
      }));
    }
  }, [selectedPicker?.strings]);

  async function handleChange(filterType, value) {
    setTableFilter((prevState) => {
      if (filterType === 'invoiceNumber') {
        return {
          ...prevState,
          invoiceNumber: value.target.value
        };
      } else if (filterType === 'id') {
        return {
          ...prevState,
          id: value.target.value
        };
      } else if (filterType === 'user_id') {
        return {
          ...prevState,
          user_id: value
        };
      } else if (filterType === 'supplier_id') {
        return {
          ...prevState,
          supplier_id: value
        };
      } else if (filterType === 'type') {
        return {
          ...prevState,
          type: value
        };
      } else if (filterType === 'serviceOrPackage') {
        return {
          ...prevState,
          serviceOrPackage: value
        };
      } else if (filterType === 'admin_id') {
        return {
          ...prevState,
          admin_id: value
        };
      }
    });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    let url;
    if (user.subscriptionType === 1) {
      url = '/offerPrice/all';
    } else if (user.subscriptionType === 2) {
      url = '/OfferPriceService/all';
    }
    customApiRequest(
      getAllPriceOffersApiNew(url, user?.token, {
        ...tableFilter,
        ...values,
        page: 1
      }),

      (res) => {
        if (isReportsPage) {
          history.push(
            `${storeRouterLinks?.outgoingPurchaseInvoiceReports}?page=1`
          );
        } else {
          history.push(`${storeRouterLinks?.priceOfferRouteNew}?page=1`);
        }
        setIsLoading(false);
        if (checkRes(res) && res?.data?.data?.data) {
          if (res.data.data.data.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedReceipts(data);
          }
          if (res?.data?.data?.pagination) {
            setTablePagination(res.data.data.pagination);
          }
        } else {
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }

  return (
    <div className="custom-table-fitler warehouse-table-filter custom-table-fitler-new">
      <div className="form-excel-export-wrap">
        <form
          className="filter-form receipt-filter-form"
          onSubmit={handleSubmit}
        >
          {/* <div className="search-label-wrapper">
            <p
              style={{
                marginBottom: 5
              }}
            >
              رقم الفاتورة
            </p>
            <div className="table-search">
              <label>
                <SearchIcon />
                <input
                  placeholder="أدخل رقم الفاتورة التسلسلى"
                  type="text"
                  name="invoiceNumber"
                  onChange={(e) => handleChange('invoiceNumber', e)}
                />
              </label>
            </div>
          </div> */}

          {user.subscriptionType === 1 && (
            <label className="receipt-filter-option-wrap">
              <span className="select-title">{t('Quotations.ClientName')}</span>
              <Select
                defaultValue={null}
                style={{ width: 172 }}
                onChange={(v) => handleChange('user_id', v)}
                suffixIcon={<SelectDropDown />}
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={null}>{t('Quotations.AllCustomers')}</Option>
                {allClients?.length > 0 &&
                  allClients.map((item) => (
                    <Option key={item.id} value={String(item.id)}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </label>
          )}
          {user.subscriptionType === 2 && (
            <label className="receipt-filter-option-wrap">
              <span className="select-title">
                {t('Quotations.supplierName')}
              </span>
              <Select
                defaultValue={null}
                style={{ width: 172 }}
                onChange={(v) => handleChange('supplier_id', v)}
                suffixIcon={<SelectDropDown />}
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={null}>{t('Quotations.allSuppliers')}</Option>
                {allSuppliers?.length > 0 &&
                  allSuppliers.map((item) => (
                    <Option key={item.id} value={String(item.id)}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </label>
          )}
          {/* <label className="receipt-filter-option-wrap">
            <span className="select-title">محتوى الخدمة</span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('serviceOrPackage', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>جميع الخدمات</Option>
              {receiptContent.map((role, index) => (
                <Option key={index} value={role.value}>
                  {role.title}
                </Option>
              ))}
            </Select>
          </label> */}
          <div className="range-picker-wrap">
            <RangePicker
              value={selectedPicker?.moments && selectedPicker.moments}
              onChange={handlePickerChange}
              // format="YYYY-MM-DD"
              className={`filter-range-picker`}
              allowClear={true}
              suffixIcon={<CalendarIcon />}
              placeholder={[t('Quotations.DateOf'), t('Quotations.DateTo')]}
            />
          </div>
          <button className="filter-submit-btn" type="submit">
            {t('Quotations.search')}
          </button>
        </form>
        <div className="excel-btn-wrap">
          <a
            href={`${process.env.REACT_APP_BASE_URL}/Excel/offerPriceExport?client_id=${systemSettings?.id}&user_id=${tableFilter?.user_id}&from=${tableFilter?.from}&to=${tableFilter?.to}&type=1`}
            download
            className="excel-export-btn"
          >
            <img
              style={{ height: '42px' }}
              src={excelImg}
              alt="export to excel"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TableFilter;
