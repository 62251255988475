

import { EyeOutlined} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

const tableColumns = (
   allFetchedConversions,
   setModalOpened,
   setFetchCount,
   setSelectedConversion,
   setIsLoading,
   setSelectedConversionModalOpened,
  t,
  token
) => {
  // const handleDeleteRequest = async (id) => {
  //   try {
  //     setIsLoading(true);
  //     const res = await deleteConversionApi(token, id);
  //     if (res?.status === 200 && res?.data?.status === 1) {
  //       setIsLoading(false);
  //       setFetchCount((prevState) => prevState + 1);
  //       successNotification({
  //         title: 'العملية تمت بنجاح',
  //         message: 'تم حذف التحويل بنجاح'
  //       });
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     errorNotification({
  //       title: 'حدث خطأ',
  //       message: 'حاول فى وقت لاحق'
  //     });
  //   }
  // };

  // const renderActions = (obj) => {
  //   return (
  //     <div className="action-btns-wrap">
  //       {/* <div className="edit-btn" onClick={() => handleEditClick(obj)}>
  //         <EditIcon />
  //         تعديل
  //       </div> */}
  //       <Popconfirm
  //         title="هل أنت متأكد من حذف التحويل"
  //         onConfirm={() => handleDeleteRequest(obj.id)}
  //         okText="نعم"
  //         cancelText="لا"
  //       >
  //         <Tooltip title="الحذف">
  //           <div className="delete-btn">
  //             <DeleteIcon />
  //           </div>
  //         </Tooltip>
  //       </Popconfirm>
  //     </div>
  //   );
  // };
  
  const handleSelectedConversion = (record) => {
    
   const found = allFetchedConversions?.length > 0 && allFetchedConversions.find((conv) => conv.id === record.id )
   if(found){
    setSelectedConversion({...found})
    setSelectedConversionModalOpened(true);
   }
    
  };

  return [
    {
      title: t('Inventory.TransferStocks.code'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.TransferStocks.productComponent'),
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text desc-wrap">
            <span className="name-it-self">{record.name}</span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.TransferStocks.warehouseTransferredFrom'),
      dataIndex: 'fromStock',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record?.fromStock?.name}</span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.TransferStocks.warehouseTransferredTo'),
      dataIndex: 'toStock',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record?.intoStock?.name}</span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.TransferStocks.quantityTransferred'),
      dataIndex: 'quantity',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record.quantity}</span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.TransferStocks.preview'),
      width: 60,
      render: (_, record) => {
        return (
          <div
            className="row-cell row-circled-btn"
            onClick={() => {
             handleSelectedConversion(record)
            }}
          >
            <Tooltip title="معاينة التحويل">
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
              />
            </Tooltip>
          </div>
        );
      }
    }
    // {
    //   title: 'الاعدادات',
    //   dataIndex: 'actions',
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-actions">{renderActions(record)}</div>
    //     );
    //   }
    // }
  ];
};

export default tableColumns;
