const userRoles = {
  manager: 1,
  accountant: 2,
  partner: 3,
  dataEntry: 4,
  posUser: 5,
  delegate: 6
};

export default userRoles;
