import React, { useContext } from 'react';
import { Modal } from 'antd';
import JournalsContext from '../../../contexts/accounting-context/JournalsContext';
import JournalsDetailsModalForm from './JournalsDetailsModalForm';
import JournalCreateModelForm from './JournalCreateModelForm';
import { useTranslation } from 'react-i18next';

const JournalCreateModal = () => {
  const {
    modalOpenedCreate,
    setModalOpenedCreate,
    setSelectedJournals,
    selectedJournals,
    selectedAccounting
  } = useContext(JournalsContext);

  // const params = useParams();
  // let typeOfAccount = getLeafType(params.id);
  const { t } = useTranslation();

  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '95%' }}
      title={
        selectedJournals
          ? t('Restrictions.editRestrictions')
          : t('Restrictions.AddNewRestrictions')
      }
      visible={modalOpenedCreate}
      onOk={() => {
        setModalOpenedCreate(false);
        setSelectedJournals(null);
      }}
      onCancel={() => {
        setModalOpenedCreate(false);
        setSelectedJournals(null);
      }}
      footer={false}
    >
      {/* <JournalsDetailsModalForm /> */}
      <JournalCreateModelForm />
    </Modal>
  );
};

export default JournalCreateModal;
