import React from 'react';
import { Button, Modal, Form } from 'antd';
import { useForm } from 'react-hook-form';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import userImg from '../../assets/imgs/signin/user.png';
import { forgetPasswordApi } from '../../apis/auth/forgetPassApi';
import './pageModal.scss';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import errorNotification from '../../utils/errorNotification';
import checkRes from '../../utils/checkRes';
import successNotification from '../../utils/successNotification';

const schema = Yup.object().shape({
  name: Yup.string().required('ادخل البريد الاكترونى')
});
const ForgetPasswordForm = () => {
  const {
    control,
    handleSubmit,
    // watch,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: ''
    }
  });
  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    customApiRequest(
      forgetPasswordApi(data),
      (res) => {
        if (checkRes(res)) {
          successNotification({
            title: 'العملية تمت بنجاح',
            message: res?.data?.message || 'تم ارسال ايميل بكلمة المرور الجديدة'
          });
        } else {
          errorNotification({
            title: 'حدث خطأ',
            message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
          });
        }
      },
      (error) => {
        errorNotification({
          title: 'حدث خطأ',
          message: error?.response?.data?.message || 'حاول فى وقت لاحق'
        });
      }
    );
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="login-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-header">
        <p className="bold-font main-title">هل نسيت كلمة المرور؟ </p>
      </div>

      <div className="form-body">
        <AntdTextField
          name="name"
          type="text"
          placeholder={'ادخل البريد الالكترونى...'}
          // label="الاســــم"
          errorMsg={errors?.name?.message}
          validateStatus={errors?.name ? 'error' : ''}
          prefix={<img style={{ height: 22 }} src={userImg} alt="user" />}
          control={control}
        />

        {/* <AntdCheckbox name="remember" label="تذكرنى" control={control} /> */}
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmitting}
        >
          أرســـل
        </Button>
      </div>
    </Form>
  );
};

const ForgetPasswordModal = ({ modalOpened, setModalOpened }) => {
  return (
    <Modal
      className="forget-password-modal"
      width="90%"
      style={{ maxWidth: '642px' }}
      title="هل نسيت كلمة المرور؟"
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
      }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      <ForgetPasswordForm />
    </Modal>
  );
};

export default ForgetPasswordModal;
