/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React from 'react';
import { Tooltip, Button } from 'antd';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import { DeleteOutlined } from '@ant-design/icons';
import AntdRadioGroup from '../../../common/antd-form-components/AntdRadioGroup';
import { calcProductTotalValue } from './receiptCalculations';
import useProducts from '../../../custom-hooks/useProducts';

const RenderIngredientsFields = ({
  watch,
  control,
  setValue,
  errors,
  ingredientsFields,
  removeIngredientField,
  selectedReceipt
}) => {
  const { allProducts, setPType } = useProducts();
  const [fetchedAllProducts, setFetchedAllProducts] =
    React.useState(allProducts);

  React.useEffect(() => {
    if (allProducts) {
      setFetchedAllProducts(allProducts);
    }
    if (watch('serviceOrPackage')) {
      setPType(watch('serviceOrPackage'));
    }
  }, [allProducts, watch('serviceOrPackage')]);
  return (
    ingredientsFields?.length > 0 && (
      <ul className="fields-array-ul without-padding">
        {ingredientsFields.map((item, index) => {
          return (
            <li className="bill-field-delete-li" key={item.id}>
              <div className="li-fields">
                <div className="product-id-quantity-wrap">
                  <div className="select-label-wrap">
                    <p className="label-p">اختار البند</p>
                    <div className="custom-select-wrap without-icon">
                      <AntdSelectOption
                        disabled={selectedReceipt ? true : false}
                        name={`ingredients.${index}.ingredient_id`}
                        errorMsg={
                          errors?.ingredients &&
                          errors.ingredients[index]?.ingredient_id &&
                          errors.ingredients[index].ingredient_id.message
                        }
                        validateStatus={
                          errors?.ingredients &&
                          errors.ingredients[index]?.ingredient_id &&
                          errors?.ingredients[index]?.ingredient_id
                            ? 'error'
                            : ''
                        }
                        control={control}
                        setValue={setValue}
                        placeholder="اختار البند"
                        options={
                          allProducts?.length > 0 &&
                          allProducts.map((p) => ({
                            title: p.name,
                            value: String(p.id)
                          }))
                        }
                        showSearch={true}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        formClassName="create-receipt-form"
                      />
                    </div>
                  </div>
                </div>

                {watch(`ingredients.${index}.ingredient_id`) && (
                  <div className="prices-wrap">
                    <div className="text-field-label-wrap">
                      <p className="label-p">سعر بيع المكون</p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          disabled={selectedReceipt ? true : false}
                          setValue={setValue}
                          className="form-text-field"
                          name={`ingredients.${index}.price`}
                          type="text"
                          placeholder="سعر بيع المكون..."
                          errorMsg={
                            errors?.ingredients &&
                            errors.ingredients[index]?.price &&
                            errors.ingredients[index].price.message
                          }
                          validateStatus={
                            errors?.ingredients &&
                            errors.ingredients[index]?.price &&
                            errors?.ingredients[index]?.price
                              ? 'error'
                              : ''
                          }
                          control={control}
                          defaultValue={
                            item?.price
                              ? item.price
                              : fetchedAllProducts?.length > 0
                              ? fetchedAllProducts.find(
                                  (ing) =>
                                    // eslint-disable-next-line eqeqeq
                                    ing.id ==
                                    watch(`ingredients.${index}.ingredient_id`)
                                )?.price
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

                {watch(`ingredients.${index}.price`) && (
                  <div className="radios-field-array-wrap1">
                    <AntdRadioGroup
                      disabled={selectedReceipt ? true : false}
                      name={`ingredients.${index}.discount_type`}
                      className="form-radio-group"
                      control={control}
                      label="اختار نوع الخصم"
                      defaultValue={
                        selectedReceipt
                          ? item?.discount_type == '1' ||
                            item?.discount_type == '2'
                            ? String(item.discount_type)
                            : 'no_discount'
                          : ''
                      }
                      onChange={() => {
                        if (watch(`ingredients.${index}.discount_type`) == '1')
                          setValue(
                            `ingredients.${index}.discount_percentage`,
                            ''
                          );
                        else if (
                          watch(`ingredients.${index}.discount_type`) == '2'
                        ) {
                          setValue(`ingredients.${index}.discount_price`, '');
                        } else {
                          // no discount
                          setValue(
                            `ingredients.${index}.discount_percentage`,
                            ''
                          );
                          setValue(`ingredients.${index}.discount_price`, '');
                        }
                      }}
                      errorMsg={
                        errors?.ingredients &&
                        errors.ingredients[index]?.discount_type &&
                        errors.ingredients[index].discount_type.message
                      }
                      validateStatus={
                        errors?.ingredients &&
                        errors.ingredients[index]?.discount_type
                          ? 'error'
                          : ''
                      }
                      radios={[
                        {
                          title: 'رقم ثابت',
                          value: '1'
                        },
                        {
                          title: 'نسبة',
                          value: '2'
                        },
                        {
                          title: 'بدون خصم',
                          value: 'no_discount'
                        }
                      ]}
                      // isRadioButton
                    />
                    {String(watch(`ingredients.${index}.discount_type`)) ==
                      '1' && (
                      <div className="before-after-wrap">
                        <div className="text-field-label-wrap">
                          <p className="label-p">سعر الخصم</p>
                          <div className="text-field-wrap">
                            <AntdTextField
                              disabled={selectedReceipt ? true : false}
                              className="form-text-field"
                              name={`ingredients.${index}.discount_price`}
                              type="text"
                              placeholder="سعر الخصم..."
                              errorMsg={
                                errors?.ingredients &&
                                errors.ingredients[index]?.discount_price &&
                                errors.ingredients[index].discount_price.message
                              }
                              validateStatus={
                                errors?.ingredients &&
                                errors.ingredients[index]?.discount_price
                                  ? 'error'
                                  : ''
                              }
                              control={control}
                            />
                          </div>
                        </div>
                        {!isNaN(
                          parseInt(watch(`ingredients.${index}.price`))
                        ) &&
                          !isNaN(
                            parseInt(
                              watch(`ingredients.${index}.discount_price`)
                            )
                          ) &&
                          !isNaN(
                            parseInt(watch(`ingredients.${index}.quantity`))
                          ) && (
                            <div className="after-wrap">
                              <div className="after-title">السعر بعد الخصم</div>
                              <span className="after-value">
                                {!isNaN(watch(`ingredients.${index}.price`)) &&
                                !isNaN(watch(`ingredients.${index}.quantity`))
                                  ? calcProductTotalValue(
                                      watch(
                                        `ingredients.${index}.discount_type`
                                      ),
                                      watch(`ingredients.${index}.price`),
                                      watch(
                                        `ingredients.${index}.discount_price`
                                      ),
                                      watch(
                                        `ingredients.${index}.discount_percentage`
                                      ),
                                      watch(`ingredients.${index}.quantity`)
                                    )
                                  : null}
                              </span>
                            </div>
                          )}
                      </div>
                    )}
                    {String(watch(`ingredients.${index}.discount_type`)) ==
                      '2' && (
                      <div className="before-after-wrap">
                        <div className="text-field-label-wrap">
                          <p className="label-p">نسبة الخصم ( % )</p>
                          <div className="text-field-wrap">
                            <AntdTextField
                              disabled={selectedReceipt ? true : false}
                              className="form-text-field"
                              name={`ingredients.${index}.discount_percentage`}
                              type="text"
                              placeholder="نسبة الخصم ( % )"
                              errorMsg={
                                errors?.ingredients &&
                                errors.ingredients[index]
                                  ?.discount_percentage &&
                                errors.ingredients[index].discount_percentage
                                  .message
                              }
                              validateStatus={
                                errors?.ingredients &&
                                errors.ingredients[index]?.discount_percentage
                                  ? 'error'
                                  : ''
                              }
                              control={control}
                            />
                          </div>
                        </div>
                        {!isNaN(
                          parseInt(watch(`ingredients.${index}.price`))
                        ) &&
                          !isNaN(
                            parseInt(
                              watch(`ingredients.${index}.discount_percentage`)
                            )
                          ) &&
                          !isNaN(
                            parseInt(watch(`ingredients.${index}.quantity`))
                          ) && (
                            <div className="after-wrap">
                              <div className="after-title">السعر بعد الخصم</div>
                              <span className="after-value">
                                {!isNaN(watch(`ingredients.${index}.price`)) &&
                                !isNaN(watch(`ingredients.${index}.quantity`))
                                  ? calcProductTotalValue(
                                      watch(
                                        `ingredients.${index}.discount_type`
                                      ),
                                      watch(`ingredients.${index}.price`),
                                      watch(
                                        `ingredients.${index}.discount_price`
                                      ),
                                      watch(
                                        `ingredients.${index}.discount_percentage`
                                      ),
                                      watch(`ingredients.${index}.quantity`)
                                    )
                                  : null}
                              </span>
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                )}
              </div>

              {ingredientsFields?.length >= 1 && !selectedReceipt && (
                <Tooltip title="حذف">
                  <Button
                    className="delete-field-btn"
                    size="large"
                    type="dashed"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => removeIngredientField(index)}
                  />
                </Tooltip>
              )}
            </li>
          );
        })}
      </ul>
    )
  );
};

export default RenderIngredientsFields;
