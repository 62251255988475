import axios from 'axios';

const SaveTokenApi = async (values) => {
    try {
        let url;
        url = '/saveToken';
        const res = await axios({
            method: 'post',
            url,
            baseURL: process.env.REACT_APP_BASE_TEST_URL,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'applications/json',
                // Authorization: 'Bearer ' + token,
                lang: 'ar'
            },
            data: {...values}
        });
    
        return res;
    } catch (error) {
    throw error;
    }
}

export default SaveTokenApi;