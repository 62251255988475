import React from 'react';
import { useController } from 'react-hook-form';
import { DatePicker } from 'antd';
import moment from 'moment';

const CustomAntdDatePicker = ({
  control,
  name,
  label,
  placeholder,
  validateStatus,
  defaultValue,
  errorMsg,
  className,
  formClassName,
  disablePast,
  style,
  setValue
}) => {
  const {
    // field: { ...inputProps }
    field
  } = useController({
    name,
    control
  });

  const handleDisabledDate = (curr) => {
    if (disablePast) {
      return curr && moment(curr) < moment().add(-1, 'days');
      // return curr && curr <= moment();
      // return curr && moment(curr).isBefore();
    }
    return null;
  };
  React.useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue]);
  return (
    <div className="custom-picker-wrap" style={{ position: 'relative' }}>
      <DatePicker
        disabledDate={handleDisabledDate}
        {...field}
        className={className}
        format="YYYY-MM-DD"
        placeholder={placeholder}
        size="large"
        defaultValue={defaultValue}
        bordered={false}
        getPopupContainer={() => document.querySelector(`.${formClassName}`)}
        // value={defaultValue ? defaultValue : field.value}
      />

      {errorMsg && <p className="error-p">{errorMsg}</p>}
    </div>
  );
};

export default CustomAntdDatePicker;
