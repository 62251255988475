import React, { useContext } from 'react';
// import { Select } from 'antd';
import PageModal from './PageModal';
import './TableFilter.scss';
import CustomersPaymentsContext from '../../contexts/customers-payments-context/CustomersPaymentsContext';

const TableFilter = () => {
  // const { Option } = Select;
  const {
    modalOpened,
  } = useContext(CustomersPaymentsContext);

  // const handleTableSearch = async (e) => {
  //   setNameSearchValue(e.target.value);
  //   try {
  //     setIsLoading(true);
  //     let res = await searchAllSuppliersApi(user?.token, {
  //       page: tablePagination.current_page,
  //       searchText: e.target.value
  //     });

  //     if (res) {
  //       setIsLoading(false);
  //       if (checkRes(res) && res?.data?.data?.data?.length >= 0) {
  //         const data = res.data.data.data;
  //         setAllFetchedCustomersPayments(data);
  //       }
  //       if (res?.data?.data?.pagination) {
  //         setTablePagination(res.data.data.pagination);
  //       }
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //   }
  // };

  // const handleExcel = async () => {
  //   let data;
  //   try {
  //     const res = await getAllSuppliersApi(
  //       user?.token,
  //       {
  //         searchText: nameSearchValue ? nameSearchValue : '',
  //         page: ''
  //       },
  //       true
  //     );
  //     if (res.status === 200) {
  //       data = res?.data?.data;
  //       exportToExcel(data, 'الموردين');
  //     }
  //   } catch (error) {
  //   }
  // };

  return (
    <div className="custom-filter-add-section">
      <div className="add-section">
        {/* <div
          className="add-btn"
          onClick={() => {
            // if selected supplier then reset it
            // open the supplier modal
            setSelectedCustomerPayment(null);
            setModalOpened(true);
          }}
        >
          <span className="text-span">تسديد دفعة جديدة</span>
        </div> */}
      </div>

      {/* <div className="custom-table-fitler">
        <div className="form-excel-export-wrap">
          <form className="filter-form">
            <div className="table-search">
              <label>
                <SearchIcon />
                <input
                  placeholder="أدخل اسم المدفوع للبحث"
                  type="text"
                  name="table_search"
                  onKeyUp={handleTableSearch}
                />
              </label>
            </div>
          </form>
          <div className="excel-btn-wrap" onClick={() => handleExcel()}>
            <img
              style={{ height: '42px' }}
              src={excelImg}
              alt="export to excel"
            />
          </div>
          <div className="excel-btn-wrap">
            <a
              href={`${process.env.REACT_APP_BASE_URL}/Excel/exportSuppliers?client_id=${systemSettings?.id}`}
              download
              className="excel-export-btn"
            >
              <img
                style={{ height: '42px' }}
                src={excelImg}
                alt="export to excel"
              />
            </a>
          </div>
        </div>
      </div> */}

      {modalOpened && <PageModal />}
    </div>
  );
};

export default TableFilter;
