export const getLeafType = (id) => {
  let typeOfAccount;
  if (id) {
    if (id?.toString().includes('-')) {
      let arrayOfIds = id.split('-');
      typeOfAccount = arrayOfIds.length;
    } else {
      typeOfAccount = 1;
    }
  }

  return typeOfAccount;
};
