const storeRouterLinks = {
  storePage: '/store',
  storeVariantsPage: '/store/Variants',
  storeItemManagerPage: '/store/services',
  storeItemManagerProductsPage: '/store/products',
  storeProductsIngredientsPage: '/store/products/ingredients',
  storeIngredientsProductsPage: '/store/products/ingredientsProducts',
  //
  taxInvoiceReportsPage: '/reports/tax-invoice-report',
  storeConversionPage: '/store/store-conversion',
  priceOfferRouteNew: '/sales/price-offer',
  storeInventory: '/store/inventory',
  outgoingPurchaseInvoice: '/store/outgoing-purchase-invoice',
  outgoingPurchaseInvoiceReturned: '/store/outgoing-purchase-invoice-return',
  outgoingPurchaseInvoices: (id) => {
    if (id) return `/store/outgoing-purchase-invoice/${id}`;
    return '/store/outgoing-purchase-invoice/:id';
  },
  outgoingPurchaseInvoiceReports: '/store/outgoing-purchase-invoice-reports',
  incomingPurchaseInvoice: '/store/incoming-purchase-invoice',
  incomingPurchaseInvoiceReturned: '/store/incoming-purchase-invoice-return',
  incomingPurchaseInvoices: (id) => {
    if (id) return `/store/incoming-purchase-invoice/${id}`;
    return '/store/incoming-purchase-invoice/:id';
  },
  incomingPurchaseInvoiceReports: '/store/incoming-purchase-invoice-reports',
  installmentsPage: (id) => {
    if (id) return `/store/receipt/installment/${id}`;
    return '/store/receipt/installment/:id';
  },
  saleInvoiceInstallmentsPage: (id) => {
    if (id) return `/store/receipt/sale-installment/${id}`;
    return '/store/receipt/sale-installment/:id';
  },
  userInstallmentsRoute: (userId) => {
    if (userId) return `/users/installments/${userId}`;
    return '/users/installments/:userId';
  },
  supplierInstallmentsRoute: (supplierId) => {
    if (supplierId) return `/suppliers/installments/${supplierId}`;
    return '/suppliers/installments/:supplierId';
  },
  storeSupplieresRecords: '/store/suppliers-records',
  storeClientsRecords: '/store/clients-records',
  clientPage: '/clients',
  fatoorahStorePage: '/fatoorahStorePage',
  suppliersPage: '/store/suppliers',
  suppliersPaymentsPage: '/store/suppliers-payments-page',
  singleSupplierPaymentsPage: (id) => {
    if (id) return `/store/suppliers-payments-page/${id}`;
    return '/store/suppliers-payments-page/:id';
  },
  productLabelPage: '/store/product-label',
  purchaseOrderPage: '/store/purchase-order',
  firstTermStock: '/store/first-term-stock',
  currentStock: '/store/current-stock',
  viewPosPage: '/view-pos',
  paymentVoucher: '/store/payment-voucher', // سند صرف
  receiptVoucher: '/store/receipt-voucher' // سند قبض
};

export default storeRouterLinks;
