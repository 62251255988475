/* eslint-disable eqeqeq */
import React from 'react';
import { Descriptions, Table, Empty } from 'antd';
import QRCode from 'qrcode.react';
import './SelectedSaleInvoiceModal.scss';
import { useTranslation } from 'react-i18next';

const SaleInvoiceDetailsComponentReport = React.forwardRef((props, ref) => {
  const { selectedInvoice } = props;
  const { t } = useTranslation();
  const renderDiscountValue = (record) => {
    if (record.discountType == '1') {
      // fixed discount price
      return record.discountPrice;
    } else if (record.discountType == '2') {
      // percentage discount
      return `${record.discountPrice}`;
    } else {
      // no discount
      return t('SalesInvoices.WithoutDiscount');
    }
  };
  const renderClient = () => {
    const {
      user: { name, phone, email, address }
    } = selectedInvoice;
    return (
      <div className="supplier-details-wrap">
        <Descriptions title={t('taxInvoiceReports.client')} bordered column={1}>
          {name && (
            <Descriptions.Item label={t('taxInvoiceReports.name')}>
              {name}
            </Descriptions.Item>
          )}
          {phone && (
            <Descriptions.Item label={t('taxInvoiceReports.phone')}>
              {phone}
            </Descriptions.Item>
          )}
          {email && (
            <Descriptions.Item label={t('taxInvoiceReports.email')}>
              {email}
            </Descriptions.Item>
          )}
          {address && (
            <Descriptions.Item label={t('taxInvoiceReports.address')}>
              {address}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  };

  const renderModels = () => {
    const products = selectedInvoice.products;
    return (
      <div className="models-wrap">
        <div className="models-title bold-font">
          {t('taxInvoiceReports.productDescription')}
        </div>
        <Table
          bordered
          locale={{
            emptyText: (
              <Empty description={false}>{t('Messages.noDataAvailable')}</Empty>
            )
          }}
          pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
          // className="antd-custom-table"
          dataSource={products.map((item) => ({
            ...item,
            key: item.id
          }))}
          columns={[
            {
              title: t('taxInvoiceReports.code'),
              dataIndex: 'id',
              render: (_, record) => {
                return (
                  <div className="row-cell row-index bold-font">
                    <span>{record.id}</span>
                  </div>
                );
              }
            },
            {
              title: t('taxInvoiceReports.productName'),
              dataIndex: 'name',
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>{record.product?.name}</span>
                  </div>
                );
              }
            },
            {
              title: t('taxInvoiceReports.productQuantity'),
              dataIndex: 'quantity',
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>
                      {String(record.quantity) ? record.quantity : '----'}
                    </span>
                  </div>
                );
              }
            },
            // {
            //   title: 'الكمية داخل المستودع',
            //   dataIndex: 'stock_quantity',
            //   render: (_, record) => {
            //     return (
            //       <div className="row-cell row-text">
            //         <span>
            //           {String(record.product?.quantityInPosStock)
            //             ? record.product.quantityInPosStock
            //             : '----'}
            //         </span>
            //       </div>
            //     );
            //   }
            // },
            {
              title: t('taxInvoiceReports.stock'),
              dataIndex: 'warehouse',
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>
                      {record?.stock?.name ? record.stock.name : '----'}
                    </span>
                  </div>
                );
              }
            },
            {
              title: t('taxInvoiceReports.itemPrice'),
              dataIndex: 'price',
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>{record.price}</span>
                  </div>
                );
              }
            },
            {
              title: t('taxInvoiceReports.beforeDiscount'),
              dataIndex: 'priceBeforeDiscount',
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>{record.priceBeforeDiscount}</span>
                  </div>
                );
              }
            },
            {
              title: t('taxInvoiceReports.discount'),
              dataIndex: 'discount',
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>{renderDiscountValue(record)}</span>
                  </div>
                );
              }
            },
            {
              title: t('taxInvoiceReports.discountPrice'),
              dataIndex: 'discount',
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>{record.discountPrice}</span>
                  </div>
                );
              }
            },
            {
              title: t('taxInvoiceReports.total'),
              dataIndex: 'discount',
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>{record.totalPrice}</span>
                  </div>
                );
              }
            }
          ]}
          // loading={isLoading}
          // scroll={{ x: 400 }}
          footer={false}
        />
      </div>
    );
  };

  const renderExtraPrices = () => {
    const addPrices = selectedInvoice.addPrices;
    if (addPrices?.length > 0) {
      return (
        <div className="models-wrap">
          <div className="models-title bold-font">
            {t('taxInvoiceReports.additionalPrices')}
          </div>
          <Table
            showHeader={false}
            bordered
            locale={{
              emptyText: (
                <Empty description={false}>
                  {t('Messages.noDataAvailable')}
                </Empty>
              )
            }}
            pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            // className="antd-custom-table"
            dataSource={addPrices.map((item) => ({
              ...item,
              key: item.id
            }))}
            columns={[
              // {
              //   title: 'الكود',
              //   dataIndex: 'id',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-index bold-font">
              //         <span># {record.id}</span>
              //       </div>
              //     );
              //   }
              // },

              {
                title: t('taxInvoiceReports.description'),
                dataIndex: 'desc',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text extra-prices-cell">
                      <span>{record.desc}</span>
                    </div>
                  );
                }
              },
              {
                title: t('taxInvoiceReports.price'),
                dataIndex: 'price',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text extra-prices-cell">
                      <span>{record.price}</span>
                    </div>
                  );
                }
              }
            ]}
            // loading={isLoading}
            // scroll={{ x: 400 }}
            footer={false}
          />
        </div>
      );
    }
    return null;
  };

  const renderInovicePrices = () => {
    const {
      productPrice,
      discountType,
      discountValue,
      discountPrice,
      additionPrice,
      totalBeforeTax,
      taxPrice,
      totalPrice
    } = selectedInvoice;
    return (
      <div className="supplier-details-wrap">
        <Descriptions
          title={t('taxInvoiceReports.totalAmounts')}
          bordered
          column={1}
        >
          {productPrice && (
            <Descriptions.Item
              label={t('taxInvoiceReports.itemsPriceAfterDescount')}
            >
              {productPrice}
            </Descriptions.Item>
          )}
          {discountValue && (
            <Descriptions.Item label={t('taxInvoiceReports.discount')}>
              {renderDiscountValue({
                discountType,
                discountValue
              })}
            </Descriptions.Item>
          )}
          {discountPrice && (
            <Descriptions.Item label={t('taxInvoiceReports.discountPrice')}>
              {discountPrice}
            </Descriptions.Item>
          )}
          {additionPrice && (
            <Descriptions.Item label={t('taxInvoiceReports.additionalPrices')}>
              {additionPrice}
            </Descriptions.Item>
          )}
          {totalBeforeTax && (
            <Descriptions.Item label={t('taxInvoiceReports.totalBeforeTax')}>
              {totalBeforeTax}
            </Descriptions.Item>
          )}
          {taxPrice && (
            <Descriptions.Item label={t('taxInvoiceReports.taxPrice')}>
              {taxPrice}
            </Descriptions.Item>
          )}
          {totalPrice && (
            <Descriptions.Item
              label={t('taxInvoiceReports.totalWithTax')}
              style={{
                fontSize: '18px',
                color: '#000',
                fontWeight: 'bold'
              }}
            >
              {totalPrice}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  };

  return (
    <div
      className="invoice-details-wrap"
      ref={ref}
      style={{
        padding: 12
        // display: 'grid',
        // gap: '16px'
      }}
    >
      {/* <p
        style={{
          fontSize: 16,
          color: '#000',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: 6
        }}
      >
        {selectedInvoice?.setting?.ref_id}
      </p> */}
      <p
        style={{
          fontSize: 20,
          color: '#000',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: 12
        }}
      >
        <span>{t('CashiersalesInvoiceReport.simplifiedTaxInvoice')}</span>

        {/* <span> - {selectedReceipt?.setting?.id}</span> */}
      </p>

      <div
        className="inovice-nums-qrcode inovice-nums-qrcode-ltr"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          gap: '22px',
          alignItems: 'center',
          direction: 'rtl'
        }}
      >
        <Descriptions title="" bordered column={1}>
          {selectedInvoice?.id && (
            <Descriptions.Item label={t('taxInvoiceReports.invoiceNumber')}>
              {selectedInvoice?.id}
            </Descriptions.Item>
          )}
          {selectedInvoice?.date && (
            <Descriptions.Item label={t('taxInvoiceReports.invoiceDate')}>
              {/* {moment(selectedInvoice.date).format('DD-MM-YYYY')} */}
              {selectedInvoice.date}
            </Descriptions.Item>
          )}
          {selectedInvoice?.time && (
            <Descriptions.Item label={t('taxInvoiceReports.invoiceTime')}>
              {selectedInvoice.time}
            </Descriptions.Item>
          )}
          {selectedInvoice?.dueDate && (
            <Descriptions.Item label={t('taxInvoiceReports.dueDate')}>
              {selectedInvoice.dueDate}
            </Descriptions.Item>
          )}
        </Descriptions>
        <div className="qr-code-wrap">
          {/* <img className="qr-code-img" src={qrcodeImg} alt="qrcode" /> */}
          <QRCode
            value={selectedInvoice?.qrString ? selectedInvoice.qrString : ''}
            width={92}
            height={92}
            renderAs="svg"
          />
          {/* <QRCode
            value={
              selectedInvoice?.encryptId
                ? `${
                    process.env.REACT_APP_FATOORAH_URL
                  }/sale-invoice-details/${String(selectedInvoice.encryptId)}`
                : ''
            }
          /> */}
        </div>
      </div>

      {selectedInvoice?.user && renderClient()}
      {selectedInvoice?.products?.length > 0 && renderModels()}
      {renderExtraPrices()}
      {renderInovicePrices()}
    </div>
  );
});

export default SaleInvoiceDetailsComponentReport;
