import { useState, useEffect, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  fetchedBalanceSheetDetails: null,
  setFetchedBalanceSheetDetails: (v) => {},
  selectedBalanceSheet: null,
  setSelectedBalanceSheet: (v) => {},
  singleJournal: null,
  setSingleJournal: (v) => {},
  selectedJournalId: null,
  setSelectedJournalId: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  modalOpenedCreate: false,
  setModalOpenedCreate: (v) => {},
  modalOpenedUpdate: false,
  setModalOpenedUpdate: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  accountsLists: null,
  setAccountsLists : (v) => {},
  accountsListId: null,
  setAccountsListId : (v) => {},
  tableFilter: {
    from: '',
    to: ''
  },
  setTableFilter: (v) => {},
};

const BalanceSheetContext = createContext(INITIAL_VALUES);

export const BalanceSheetProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [fetchedBalanceSheetDetails, setFetchedBalanceSheetDetails] = useState(
    INITIAL_VALUES.fetchedBalanceSheetDetails
  );
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [modalOpenedCreate, setModalOpenedCreate] = useState(INITIAL_VALUES.modalOpenedCreate);
  const [modalOpenedUpdate, setModalOpenedUpdate] = useState(INITIAL_VALUES.modalOpenedUpdate);
  const [selectedBalanceSheet, setSelectedBalanceSheet] = useState(
    INITIAL_VALUES.selectedBalanceSheet
  );
  const [selectedJournalId, setSelectedJournalId] = useState(
    INITIAL_VALUES.selectedJournalId
  );
  const [singleJournal, setSingleJournal] = useState(
    INITIAL_VALUES.singleJournal
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  const [accountsLists , setAccountsLists] = useState(
    INITIAL_VALUES.accountsLists
  );
  const [accountsListId , setAccountsListId] = useState(
    INITIAL_VALUES.accountsListId
  );
  const [tableFilter, setTableFilter] = useState(INITIAL_VALUES?.tableFilter);
  // useEffect(() => {
  //   if (journalsTree?.length >= 0) {
  //     setMappedBalanceSheetTree({
  //       name: 'شجرة الحســابات',
  //       toggled: true,
  //       fakeLeaf: true,
  //       children: [...journalsTree]
  //     });
  //   }
  // }, [journalsTree]);

  // const allBalanceSheetRoles = useBalanceSheetRoles();

  // useEffect(() => {
  //   if (allBalanceSheetRoles?.length > 0) {
  //     setBalanceSheetRoles(allBalanceSheetRoles);
  //   }
  // }, [allBalanceSheetRoles]);

  return (
    <BalanceSheetContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fetchCount,
        setFetchCount,
        fetchedBalanceSheetDetails,
        setFetchedBalanceSheetDetails,
        selectedBalanceSheet,
        setSelectedBalanceSheet,
        singleJournal,
        setSingleJournal,
        selectedJournalId,
        setSelectedJournalId,
        modalOpened,
        setModalOpened,
        modalOpenedCreate,
        setModalOpenedCreate,
        modalOpenedUpdate,
        setModalOpenedUpdate,
        isSubmittingForm,
        setIsSubmittingForm,
        accountsLists,
        setAccountsLists,
        accountsListId,
        setAccountsListId,
        tableFilter,
        setTableFilter,
      }}
    >
      {children}
    </BalanceSheetContext.Provider>
  );
};

export default BalanceSheetContext;
