import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './apis/axios-global';
import Loading from './common/loading/Loading';
import App from './components/app/App';
import { MainAppBarProvider } from './contexts/main-app-bar-context/MainAppBarProvider';
import { OutgoingPurchaseInvoiceProvider } from './contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import { StatisticsProvider } from './contexts/statistics-context/StatisticsContext';
import { StopwatchProvider } from './contexts/stopwatch-context/StopwatchContext';
import { UserProvider } from './contexts/user-context/UserProvider';
import './i18n';

import 'animate.css/animate.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-notifications-component/dist/theme.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
// import 'antd/dist/antd.css';
import 'antd/dist/antd.less';
import 'react-big-calendar/lib/sass/styles.scss';
import { IncomingPurchaseInvoiceProvider } from './contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import { StoreNewProductModalProvider } from './contexts/store-new-product-context/StoreNewProductProvider';
import './scss/index.scss';

import { AlertModalProvider } from './contexts/alertModal-context/AlertModalContext';
import { ClientsPosProvider } from './contexts/clients-context-pos/ClientsPosContext';
import { MyNotificationsProvider } from './contexts/notifications-context/MyNotificationsContext';
import { PosInvoiceProvider } from './contexts/pos-invoice-context/PosInvoiceContext';
import { PosReportProvider } from './contexts/pos-report-context/PosReportContext';
import { ReactHookFormProvider } from './contexts/react-hook-form-context/ReactHookFormContext';
import { ReturnModalProvider } from './contexts/return-modal-context/ReturnModalContext';

import { CustomersPaymentsProvider } from './contexts/customers-payments-context/CustomersPaymentsContext';
import { PosCashPaymentsProvider } from './contexts/pos-cash-payments/PosCashPaymentsContext';
import { SettingsPosProvider } from './contexts/settings-contexts/SettingsPosPage';
import { SettingsSystemUsersProvider } from './contexts/settings-contexts/SettingsSystemUsersProvider';
import { SettingsWorkPlacesProvider } from './contexts/settings-contexts/SettingsWorkPlacesProvider';
import { ShiftReportsProvider } from './contexts/shift-reports-context/ShiftReportsContext';
import { StockProvider } from './contexts/stock-context/StockProvider';
import { TechnicalSupportProvider } from './contexts/technical-support-context/TechnicalSupportContext';
import { UpgradeProvider } from './contexts/upgrade-context/UpgradeContext';
import { ProfitLosesReportsProvider } from './contexts/profit-loses-reports-context/ProfitLosesReportsContext';

ReactDOM.render(
  <Suspense fallback={<Loading />}>
    <StopwatchProvider>
      <UserProvider>
        <MyNotificationsProvider>
          <StatisticsProvider>
            <Router>
              <IncomingPurchaseInvoiceProvider>
                <OutgoingPurchaseInvoiceProvider>
                  <StoreNewProductModalProvider>
                    <MainAppBarProvider>
                      <AlertModalProvider>
                        <ReactHookFormProvider>
                          <ClientsPosProvider>
                            <CustomersPaymentsProvider>
                              <ReturnModalProvider>
                                <PosReportProvider>
                                  <SettingsWorkPlacesProvider>
                                    <PosInvoiceProvider>
                                      <PosCashPaymentsProvider>
                                        <SettingsSystemUsersProvider>
                                          <SettingsPosProvider>
                                            <UpgradeProvider>
                                              <TechnicalSupportProvider>
                                                {/* <WhatsAppComponent> */}
                                                <ShiftReportsProvider>
                                                  <StockProvider>
                                                    <ProfitLosesReportsProvider>
                                                      <App />
                                                    </ProfitLosesReportsProvider>
                                                  </StockProvider>
                                                </ShiftReportsProvider>
                                                {/* </WhatsAppComponent> */}
                                              </TechnicalSupportProvider>
                                            </UpgradeProvider>
                                          </SettingsPosProvider>
                                        </SettingsSystemUsersProvider>
                                      </PosCashPaymentsProvider>
                                    </PosInvoiceProvider>
                                  </SettingsWorkPlacesProvider>
                                </PosReportProvider>
                              </ReturnModalProvider>
                            </CustomersPaymentsProvider>
                          </ClientsPosProvider>
                        </ReactHookFormProvider>
                      </AlertModalProvider>
                    </MainAppBarProvider>
                  </StoreNewProductModalProvider>
                </OutgoingPurchaseInvoiceProvider>
              </IncomingPurchaseInvoiceProvider>
            </Router>
          </StatisticsProvider>
        </MyNotificationsProvider>
      </UserProvider>
    </StopwatchProvider>
  </Suspense>,
  document.getElementById('root')
);
