/* eslint-disable eqeqeq */
import { Popconfirm, Tooltip } from 'antd';
import toggleUserStatusApi from '../../../../apis/settings-apis/system-settings-api/toggleUserStatusApi';
import deleteUserApi from '../../../../apis/settings-apis/users-apis/deleteUserApi';
import DeleteIcon from '../../../../common/icons/DeleteIcon';
import EditIcon from '../../../../common/icons/EditIcon';
//import roles from '../../../../roles';
import errorNotification from '../../../../utils/errorNotification';
import successNotification from '../../../../utils/successNotification';

const statusTypes = {
  active: 1,
  unactive: 0
};

const tableColumns = (
  allFetchedUsers,
  setModalOpened,
  setFetchCount,
  setSelectedUser,
  setIsLoading,
  user,
  t,
  customApiRequest
) => {
  const handleEditClick = (record) => {
    const found = allFetchedUsers.find((place) => place.id === record.id);
    if (found) {
      setSelectedUser({ ...found });
      setModalOpened(true);
    }
  };
  const roles = [
    { title: t('SystemSettings.manager'), value: '1' },
    { title: t('SystemSettings.accountant'), value: '2' },
    // { title: 'مشترك', value: '3' },
    { title: t('SystemSettings.dataEntry'), value: '4' },
    { title: t('SystemSettings.pointOfSaleEmployee'), value: '5' },
    { title: t('SystemSettings.representative'), value: '6' }
  ];
  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteUserApi(user?.token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('SystemSettings.operationCompletedSuccessfully'),
          message: t('SystemSettings.userDeletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('SystemSettings.errorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('SystemSettings.tryLaterMessage')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('SystemSettings.errorOccurred'),
        message: t('SystemSettings.tryLater')
      });
    }
  };

  const handleToggleActive = (record) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('admin_id', record?.id);
    customApiRequest(
      toggleUserStatusApi(user?.token, formData),
      (res) => {
        setIsLoading(false);
        setFetchCount((prev) => prev + 1);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };
  const renderUserStatusBtn = (record) => {
    if (record?.id == user?.id) {
      return '---';
    } else {
      if (record?.status == statusTypes?.active)
        return (
          <button
            onClick={() => {
              handleToggleActive(record);
            }}
            className="active toggle-user-status-btn"
          >
            {t('SystemSettings.enabled')}
          </button>
        );
      else if (record?.status == statusTypes?.unactive) {
        return (
          <button
            onClick={() => {
              handleToggleActive(record);
            }}
            className="not-active toggle-user-status-btn"
          >
            {t('SystemSettings.notEnabled')}
          </button>
        );
      }
    }
  };

  const renderActions = (obj) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(obj)}>
          <EditIcon />
          {t('SystemSettings.update')}
        </div>
        <Popconfirm
          title={t('SystemSettings.areyouSureToDelete')}
          onConfirm={() => handleDeleteRequest(obj.id)}
          okText={t('SystemSettings.yes')}
          cancelText={t('SystemSettings.no')}
        >
          <Tooltip title={t('SystemSettings.delete')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  const renderUserRole = (role) => {
    if (role) {
      const foundRole =
        roles && roles?.find((r) => String(r.value) === String(role));
      if (foundRole) {
        return foundRole.title;
      }
    }
    return '-----';
  };

  return [
    {
      title: t('SystemSettings.userCode'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('SystemSettings.name'),
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.name ? record.name : '----'}
            </span>
          </div>
        );
      }
    },
    // {
    //   title: 'رقم الهاتف',
    //   dataIndex: 'phone',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         <span className="name-it-self">
    //           {record.phone ? record.phone : '-----'}
    //         </span>
    //       </div>
    //     );
    //   }
    // },
    // {
    //   title: 'البريد الاكترونى',
    //   dataIndex: 'email',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         <span className="name-it-self">
    //           {record.email ? record.email : '-----'}
    //         </span>
    //       </div>
    //     );
    //   }
    // },
    {
      title: t('SystemSettings.status'),
      dataIndex: 'userStatus',
      render: (_, record) => {
        return renderUserStatusBtn(record);
      }
    },
    {
      title: t('SystemSettings.userAuthority'),
      dataIndex: 'role',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{renderUserRole(record?.role)}</span>
          </div>
        );
      }
    },
    {
      title: t('SystemSettings.settings'),
      // dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
