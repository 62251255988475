/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { DatePicker, Select } from 'antd';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Form, Button, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import AntdRadioGroup from '../../common/antd-form-components/AntdRadioGroup';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import AntdTextPosField from '../../common/antd-form-components/AntdTextPosField';
import './InvoiceExtraPrices.scss';
import { CameraFilled } from '@ant-design/icons';
import moment from 'moment';
import MyDropZone from '../../common/dorp-zone/MyDropZone';
import MyDropZonePreview from '../../common/dorp-zone/MyDropZonePreview';
import SelectDropDown from '../../common/icons/SelectDropDown';
import useClients from '../../custom-hooks/useClients';
import { useTranslation } from 'react-i18next';

const InvoiceExtraPrices = () => {
  const [payType, setPayType] = useState();
  const [invoiceDateError, setInvoiceDateError] = useState(false);
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    extra_prices: Yup.array().of(
      Yup.object().shape({
        price: Yup.string()
          .test(
            'extra_prices.index.price',
            t('Pos.Main.enterPrice'),
            (v, context) => {
              let result = true;
              if (!v && context.parent.desc) {
                result = false;
              }
              return result;
            }
          )
          .test(
            'extra_prices.index.price',
            t('Pos.Main.onlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (v && !v.match(/^(\d+)?(\.\d+)?$/)) {
                result = false;
              }
              return result;
            }
          ),
        desc: Yup.string().test(
          'extra_prices.index.desc',
          t('Pos.Main.enterPriceStatement'),
          (v, context) => {
            let result = true;
            if (!v && context.parent.price) {
              result = false;
            }
            return result;
          }
        )
      })
    ),
    pay_type: Yup.string().required(t('Pos.Main.choosePaymentType')),
    deferred_invoice_client: Yup.string().required(
      t('Pos.Main.pleaseEnterCustomerName')
    ),
    pay_type_multiple_cash: Yup.string()
      .required(t('Pos.Main.pleaseEnterCashAmount'))
      .matches(/^(\d+)?(\.\d+)?$/, t('Pos.Main.onlyNumbersAllowed'))
      .test(
        'pay_type_multiple_cash',
        t('Pos.Main.enterNumberLessThanInvoiceValue'),
        (v, context) => {
          let result = true;
          if (!v || parseFloat(v) > invoiceTotalAfterExtraPrices) {
            result = false;
          }
          return result;
        }
      ),
    pay_type_multiple_visa: Yup.string(t('Pos.Main.onlyNumbersAllowed'))
      .required(t('Pos.Main.pleaseEnterNetworkAmount'))
      .matches(/^(\d+)?(\.\d+)?$/, t('Pos.Main.onlyNumbersAllowed'))
      .test(
        'pay_type_multiple_visa',
        t('Pos.Main.enterNumberLessThanInvoiceValue'),
        (v, context) => {
          let result = true;
          if (!v || parseFloat(v) > invoiceTotalAfterExtraPrices) {
            result = false;
          }
          return result;
        }
      )
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    clearErrors,
    setError,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      note: '',
      extra_prices: [
        {
          price: '',
          desc: ''
        }
      ],
      pay_type: '',
      pay_type_multiple_cash: '',
      pay_type_multiple_visa: '',
      deferred_invoice_client: '',
      invoice_image: null
    }
  });

  const {
    fields: extraPricesFields,
    append: appendExtraPriceField,
    remove: removeExtraPriceField
  } = useFieldArray({
    control,
    name: 'extra_prices'
  });

  const {
    invoiceNote,
    setInvoiceNote,
    selectedInvoice,
    setInvoiceExtraPrices,
    invoicePayType,
    setInvoicePayType,
    setInvoiceMultiplePaymentCash,
    setInvoiceMultiplePaymentVisa,
    setInvoiceDeferredPayment,
    invoiceDeferredPayment,
    setInvoiceBankPayment,
    invoiceBankPayment,
    invoiceTotalAfterExtraPrices,
    systemSettings
  } = useContext(PosInvoiceContext);
  // console.log(selectedInvoice)
  let [paymentMethods, setPaymentStatus] = useState([]);
  const { Option } = Select;
  const allClients = useClients();

  const handlePickerChange = (date, dateString) => {
    setInvoiceDeferredPayment((prv) => ({
      ...prv,
      invoice_due_date: dateString
    }));
    if (dateString == '') {
      setInvoiceDateError(true);
      setInvoiceDeferredPayment((prv) => ({
        ...prv,
        errors: true
      }));
    } else {
      setInvoiceDateError(false);
      setInvoiceDeferredPayment((prv) => ({
        ...prv,
        errors: false
      }));
    }
  };

  useEffect(() => {
    if (selectedInvoice) {
      systemSettings?.invoiceSetting?.payment_methods_objects.map(
        (paymentMethod) => {
          paymentMethod.id != 2 &&
            paymentMethod.id != 6 &&
            setPaymentStatus((prv) => [
              ...prv,
              { title: paymentMethod.name, value: paymentMethod.id }
            ]);
        }
      );
    } else {
      systemSettings?.invoiceSetting?.payment_methods_objects.map(
        (paymentMethod) => {
          setPaymentStatus((prv) => [
            ...prv,
            { title: paymentMethod.name, value: paymentMethod.id }
          ]);
        }
      );
    }
  }, []);

  const [invoiceFileToUpload, setInvoiceFileToUpload] = useState(
    watch('invoice_image') ? watch('invoice_image') : null
  );
  const [invoiceFileToUploadDefferd, setInvoiceFileToUploadDefferd] = useState(
    watch('first_pay_img') ? watch('first_pay_img') : null
  );
  useEffect(() => {
    setValue('invoice_image', invoiceFileToUpload);
    if (watch('invoice_image')) {
      clearErrors('invoice_image');
    }
  }, [invoiceFileToUpload, invoiceFileToUpload?.length]);
  useEffect(() => {
    setValue('first_pay_img', invoiceFileToUploadDefferd);
    if (watch('first_pay_img')) {
      clearErrors('first_pay_img');
    }
  }, [invoiceFileToUploadDefferd, invoiceFileToUploadDefferd?.length]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handleFilesDrop = async (acceptedFiles, formFiles, setFormFiles) => {
    const duplicates = [];
    for (let i of acceptedFiles) {
      if (formFiles?.length) {
        for (let f of formFiles) {
          if (i.name === f.name) {
            duplicates.push(i);
          }
        }
      }
    }
    for (let i = 0; i < acceptedFiles.length; i++) {
      for (let f of duplicates) {
        if (acceptedFiles[i].name === f.name) {
          acceptedFiles.splice(i, 1);
        }
      }
    }
    acceptedFiles.forEach(async (file) => {
      const preview = await getBase64(file);
      Object.assign(file, {
        preview
      });
      setFormFiles((prevState) => [file]);
      if (formFiles?.length > 0) {
        setFormFiles((currState) => [file]);
      } else {
        setFormFiles([file]);
      }
    });
  };
  const onSubmit = () => {};

  const clearFileFromUpload = (file, setFilesToUpload) => {
    setFilesToUpload((prevState) => {
      const filtered = prevState.filter((asset) => asset.name !== file.name);
      if (filtered?.length === 0) return null;
      return filtered;
    });
  };

  const handleClientsChange = async (value) => {
    setInvoiceDeferredPayment((prv) => ({
      ...prv,
      deferred_invoice_client: value
    }));
    if (value == '') {
      // setInvoiceClientError(true);
      setInvoiceDeferredPayment((prv) => ({
        ...prv,
        deferred_invoice_client_errors: true
      }));
    } else {
      // setInvoiceClientError(false);
      setInvoiceDeferredPayment((prv) => ({
        ...prv,
        deferred_invoice_client_errors: false
      }));
    }
  };

  useEffect(() => {
    if (watch('note')) {
      setInvoiceNote(watch('note'));
    } else {
      setInvoiceNote('');
    }

    if (watch('pay_type')) {
      setInvoicePayType({ value: watch('pay_type') });
      if (watch('pay_type') != '2') {
        setInvoiceDateError(false);
        setInvoiceDeferredPayment((prv) => ({
          ...prv,
          errors: false
        }));
        setValue('deferred_invoice_client', '');
        clearErrors('deferred_invoice_client');
      }
      setInvoiceBankPayment((prv) => ({ ...prv, errors: false }));
      clearErrors('invoice_image');
      setInvoiceMultiplePaymentCash({
        value: '',
        errors: false
      });
      setInvoiceMultiplePaymentVisa({
        value: '',
        errors: false
      });
      if (watch('pay_type') != '6') {
        setValue('pay_type_multiple_cash', '');
        setValue('pay_type_multiple_visa', '');
        clearErrors('pay_type_multiple_cash');
        clearErrors('pay_type_multiple_visa');
      }
    } else {
      setInvoicePayType({ value: '' });
    }

    if (watch('first_paid')) {
      setInvoiceDeferredPayment((prv) => ({
        ...prv,
        first_paid: watch('first_paid')
      }));
    }
    if (watch('first_pay_img')) {
      setInvoiceDeferredPayment((prv) => ({
        ...prv,
        first_pay_img: watch('first_pay_img')
      }));
    }
    if (watch('first_pay_type')) {
      setInvoiceDeferredPayment((prv) => ({
        ...prv,
        first_pay_type: watch('first_pay_type')
      }));
    }

    if (watch('pay_type_multiple_cash')) {
      setInvoiceMultiplePaymentCash({
        value: watch('pay_type_multiple_cash'),
        errors: errors?.pay_type_multiple_cash
      });
      if (
        errors?.pay_type_multiple_cash?.message ==
          t('Pos.Main.pleaseEnterCashAmount') &&
        watch('pay_type_multiple_cash')
      ) {
        clearErrors('pay_type_multiple_cash');
      }
    } else {
      setInvoiceMultiplePaymentCash({
        value: '',
        errors: errors?.pay_type_multiple_cash
      });
      setError(
        'pay_type_multiple_cash',
        {
          types: {
            required: ''
          }
        },
        { shouldFocus: true }
      );
    }

    if (watch('pay_type_multiple_visa')) {
      setInvoiceMultiplePaymentVisa({
        value: watch('pay_type_multiple_visa'),
        errors: errors?.pay_type_multiple_visa
      });
      if (
        errors?.pay_type_multiple_visa?.message ==
          t('Pos.Main.pleaseEnterNetworkAmount') &&
        watch('pay_type_multiple_visa')
      ) {
        clearErrors('pay_type_multiple_visa');
      }
    } else {
      setInvoiceMultiplePaymentVisa({
        value: '',
        errors: errors?.pay_type_multiple_visa
      });
    }

    if (watch('invoice_image')) {
      setInvoiceBankPayment((prv) => ({
        ...prv,
        file: watch('invoice_image')
      }));
    } else {
      setInvoiceBankPayment((prv) => ({ ...prv, file: null }));
    }
  }, [
    watch('note'),
    watch('pay_type'),
    watch('pay_type_multiple_cash'),
    watch('pay_type_multiple_visa'),
    watch('invoice_image'),
    watch('first_pay_img'),
    watch('deferred_invoice_client'),
    watch('first_paid'),
    watch('first_pay_type')
  ]);

  // handle initial values
  useEffect(() => {
    const sharedReset = {
      // due_date: selectedReceipt.dueDate
      //   ? moment(selectedReceipt.dueDate, 'MM/DD/YYYY')
      //   : null,
      note: selectedInvoice?.note
        ? selectedInvoice.note
        : invoiceNote
        ? invoiceNote
        : '',
      //
      pay_type: selectedInvoice?.paymentType
        ? String(selectedInvoice.paymentType)
        : String(invoicePayType)
        ? String(invoicePayType)
        : ''
    };

    reset({
      ...sharedReset,
      extra_prices:
        selectedInvoice?.addPrices?.length > 0
          ? [...selectedInvoice?.addPrices]
          : InvoiceExtraPrices?.length > 0
          ? [...InvoiceExtraPrices]
          : []
    });
  }, [selectedInvoice]);
  const [form] = Form.useForm();
  return (
    <Form
      className="create-receipt-form custom-shared-form"
      form={form}
      layout="vertical"
      fields={[
        {
          name: 'pay_type',
          value: watch('pay_type')
        },
        {
          name: 'extra_prices',
          value: watch('extra_prices')
        }
      ]}
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        {/* Pay Type section */}
        {/* {!selectedInvoice && ( */}
        <>
          <div
            className="radios-field-array-wrap no-border"
            style={{ margin: '0px' }}
          >
            <AntdRadioGroup
              name="pay_type"
              className="form-radio-group"
              control={control}
              label={t('Pos.Main.choosePaymentType')}
              validateStatus={errors?.pay_type ? 'error' : ''}
              errorMsg={errors?.pay_type?.message}
              defaultValue="1"
              onChange={(e) => setPayType(e)}
              radios={paymentMethods}
            />
          </div>
        </>
        {/* )} */}

        {!selectedInvoice && watch('pay_type') == 2 && (
          <>
            <div
              style={{
                width: 'fit-content',
                display: 'flex',
                alignItems: 'center',
                gap: '0 20px'
              }}
            >
              <label className="entries-wrap">
                <div style={{ marginBottom: '6px' }}>
                  {t('Pos.Main.customerName')}
                </div>
                <Select
                  // defaultValue="كل المستودعات"
                  style={{
                    width: '150px',
                    borderRadius: '10px',
                    height: '100%'
                  }}
                  onChange={handleClientsChange}
                  suffixIcon={<SelectDropDown />}
                >
                  {allClients?.allClients?.length > 0 &&
                    allClients?.allClients?.map((obj, index) => (
                      <Option key={index} value={obj.id}>
                        {obj.name}
                      </Option>
                    ))}
                </Select>
                {invoiceDeferredPayment?.deferred_invoice_client_errors && (
                  <p
                    className="error-p"
                    style={{ color: '#ff4d4f', fontSize: '14px' }}
                  >
                    {t('Pos.Main.pleaseEnterCustomerName')}
                  </p>
                )}
              </label>

              <div className="custom-picker-wrap">
                <div style={{ marginBottom: '6px' }}>
                  {t('Pos.Main.dueDate')}
                </div>
                <DatePicker
                  name="invoice_due_date"
                  format="YYYY-MM-DD"
                  placeholder=""
                  size="large"
                  onChange={handlePickerChange}
                  validateStatus={errors?.invoice_due_date ? 'error' : ''}
                  disabledDate={(current) =>
                    current.isBefore(moment().subtract(1, 'day'))
                  }
                  style={{ height: '33px' }}
                  // value={defaultValue ? defaultValue : field.value}
                />

                {invoiceDateError ? (
                  <p
                    className="error-p"
                    style={{ color: '#ff4d4f', fontSize: '14px' }}
                  >
                    {t('Pos.Main.pleaseEnterDueDate')}{' '}
                  </p>
                ) : invoiceDeferredPayment?.errors ? (
                  <p
                    className="error-p"
                    style={{ color: '#ff4d4f', fontSize: '14px' }}
                  >
                    {t('Pos.Main.pleaseEnterDueDate')}{' '}
                  </p>
                ) : null}
              </div>
            </div>
            <div
              className="text-field-label-wrap"
              style={{ marginTop: '8px', width: 'fit-content' }}
            >
              <p className="label-p" style={{ marginBottom: '6px' }}>
                {t('Pos.Main.firstBatch')}
              </p>
              <div className="text-field-wrap">
                <AntdTextField
                  setValue={setValue}
                  className="form-text-field"
                  name={`first_paid`}
                  type="text"
                  placeholder={t('Pos.Main.enterDownPaymentAmount')}
                  errorMsg={errors?.first_paid && errors?.first_paid?.message}
                  validateStatus={errors?.first_paid ? 'error' : ''}
                  control={control}
                  defaultValue={0}
                />
              </div>
            </div>
            <div className="text-field-label-wrap">
              <div className="text-field-wrap">
                <AntdRadioGroup
                  name="first_pay_type"
                  className="form-radio-group"
                  control={control}
                  label={t('Pos.Main.paymentTypeForDownPayment')}
                  validateStatus={errors?.first_pay_type ? 'error' : ''}
                  errorMsg={errors?.first_pay_type?.message}
                  // radios={paymentMethods}
                  radios={[
                    {
                      title: t('Pos.Main.cash'),
                      value: '1'
                    },
                    {
                      title: t('Pos.Main.visa'),
                      value: '3'
                    },
                    {
                      title: t('Pos.Main.bankTransfer'),
                      value: '5'
                    }
                  ]}
                  // isRadioButton
                />
              </div>
            </div>
          </>
        )}
        {String(watch('first_pay_type')) == '5' && (
          <div className="product-img-wrap">
            <h3 style={{ marginBottom: '6px' }}>
              {t('Pos.Main.copyOfReceipt')}
            </h3>

            <MyDropZone
              className="product-header-dropzone"
              multipleFiles={false}
              handleFilesDrop={(acceptedFiles) =>
                handleFilesDrop(
                  acceptedFiles,
                  invoiceFileToUploadDefferd,
                  setInvoiceFileToUploadDefferd
                )
              }
              filesToUpload={invoiceFileToUploadDefferd}
              id="avatar-input"
              dropzoneText={t('Pos.Main.productImage')}
              inputName="first_pay_img"
              icon={<CameraFilled className="dropzone-camera" />}
              dropZoneUrls={
                watch('first_pay_img')?.file
                  ? [watch('first_pay_img')?.file]
                  : null
              }
            >
              {t('Pos.Main.dragImageAndPlaceItHere')}
            </MyDropZone>

            <MyDropZonePreview
              filesToUpload={invoiceFileToUploadDefferd}
              clearFileFromUpload={(f) =>
                clearFileFromUpload(f, setInvoiceFileToUploadDefferd)
              }
            />
            {errors?.first_pay_img && (
              <p
                className="error-p"
                style={{ color: '#ff4d4f', fontSize: '14px' }}
              >
                {errors?.first_pay_img?.message}
              </p>
            )}
          </div>
        )}
        {watch('pay_type') == 5 && (
          <div className="product-img-wrap">
            <h3 style={{ marginBottom: '6px' }}>
              {t('Pos.Main.copyOfReceipt')}
            </h3>

            <MyDropZone
              className="product-header-dropzone"
              multipleFiles={false}
              handleFilesDrop={(acceptedFiles) =>
                handleFilesDrop(
                  acceptedFiles,
                  invoiceFileToUpload,
                  setInvoiceFileToUpload
                )
              }
              filesToUpload={invoiceFileToUpload}
              id="avatar-input"
              dropzoneText={t('Pos.Main.productImage')}
              formName={''}
              inputName="invoice_image"
              icon={<CameraFilled className="dropzone-camera" />}
              dropZoneUrls={
                watch('invoice_image')?.file
                  ? [watch('invoice_image')?.file]
                  : null
              }
            >
              {t('Pos.Main.dragImageAndPlaceItHere')}
            </MyDropZone>

            <MyDropZonePreview
              filesToUpload={invoiceFileToUpload}
              clearFileFromUpload={(f) =>
                clearFileFromUpload(f, setInvoiceFileToUpload)
              }
            />
            {/* {invoiceBankPayment?.errors && !watch('invoice_image') && (
            <p
              className="error-p"
              style={{ color: '#ff4d4f', fontSize: '14px' }}
            >
              من فضلك أدخل صورة الحوالة البنكية
            </p>
          )} */}
          </div>
        )}
        {!selectedInvoice && watch('pay_type') == 6 && (
          <div style={{ display: 'flex', gap: '16px' }}>
            <div
              className="text-field-wrap "
              style={{
                width: '100px',
                paddingBottom: '15px'
              }}
            >
              <AntdTextField
                className="form-text-field text-field-invoice"
                name="pay_type_multiple_cash"
                type="text"
                validateStatus={errors?.pay_type_multiple_cash ? 'error' : ''}
                errorMsg={errors?.pay_type_multiple_cash?.message}
                control={control}
                label={t('Pos.Main.cash')}
                setValue={setValue}
                defaultValue={
                  watch('pay_type_multiple_visa')
                    ? invoiceTotalAfterExtraPrices -
                        parseFloat(watch('pay_type_multiple_visa')) >
                      0
                      ? Math.ceil(
                          parseFloat(
                            invoiceTotalAfterExtraPrices -
                              parseFloat(watch('pay_type_multiple_visa'))
                          ) * 100
                        ) / 100
                      : 0
                    : null
                }

                // prefix={<UserIcon />}
              />
            </div>
            <div
              className="text-field-wrap "
              style={{
                width: '100px',
                paddingBottom: '15px'
              }}
            >
              <AntdTextField
                className="form-text-field text-field-invoice"
                name="pay_type_multiple_visa"
                type="text"
                validateStatus={errors?.pay_type_multiple_visa ? 'error' : ''}
                errorMsg={errors?.pay_type_multiple_visa?.message}
                control={control}
                setValue={setValue}
                defaultValue={
                  watch('pay_type_multiple_cash')
                    ? invoiceTotalAfterExtraPrices -
                        parseFloat(watch('pay_type_multiple_cash')) >
                      0
                      ? Math.ceil(
                          parseFloat(
                            invoiceTotalAfterExtraPrices -
                              parseFloat(watch('pay_type_multiple_cash'))
                          ) * 100
                        ) / 100
                      : 0
                    : null
                }
                label={t('Pos.Main.visa')}
                // prefix={<UserIcon />}
              />
            </div>
          </div>
        )}

        <div className="text-area-label-wrap">
          <p className="label-p">{t('Pos.Main.notes')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area white-bg"
              name="note"
              rows={5}
              placeholder={t('Pos.Main.notesPlaceholder')}
              errorMsg={errors?.note?.message}
              validateStatus={errors?.note ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        {/* ./extra prices section */}
        <div
          style={{
            padding: '22px 0px',
            marginBottom: 18,
            borderTop: '1px solid rgba(0, 0, 0, .1)'
          }}
        >
          <div
            className="add-new-field-btn"
            onClick={() =>
              appendExtraPriceField({
                price: '',
                desc: ''
              })
            }
          >
            <span>{t('Pos.Main.newAdditionalPrice')}</span>
          </div>
          {extraPricesFields?.length > 0 && (
            <ul className="fields-array-ul without-padding">
              {extraPricesFields.map((item, index) => {
                return (
                  <li className="field-delete-li" key={item.id}>
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Pos.Main.statementOfadditionalPrice')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextPosField
                          className="form-text-field white-bg"
                          name={`extra_prices.${index}.desc`}
                          type="text"
                          placeholder={t(
                            'Pos.Main.statementOfadditionalPricePlaceholder'
                          )}
                          errorMsg={
                            errors?.extra_prices &&
                            errors.extra_prices[index]?.desc &&
                            errors.extra_prices[index].desc.message
                          }
                          validateStatus={
                            errors?.extra_prices &&
                            errors.extra_prices[index]?.desc &&
                            errors?.extra_prices[index]?.desc
                              ? 'error'
                              : ''
                          }
                          control={control}
                          onInputChange={() => {
                            setInvoiceExtraPrices(watch('extra_prices'));
                          }}
                        />
                      </div>
                    </div>
                    <div className="text-field-label-wrap">
                      <p className="label-p">{t('Pos.Main.extraPrice')}</p>
                      <div className="text-field-wrap">
                        <AntdTextPosField
                          className="form-text-field white-bg"
                          name={`extra_prices.${index}.price`}
                          type="text"
                          placeholder={t('Pos.Main.extraPricePlaceholder')}
                          errorMsg={
                            errors?.extra_prices &&
                            errors.extra_prices[index]?.price &&
                            errors.extra_prices[index].price.message
                          }
                          validateStatus={
                            errors?.extra_prices &&
                            errors.extra_prices[index]?.price &&
                            errors?.extra_prices[index]?.price
                              ? 'error'
                              : ''
                          }
                          control={control}
                          onInputChange={() => {
                            setInvoiceExtraPrices(watch('extra_prices'));
                          }}
                        />
                      </div>
                    </div>

                    {extraPricesFields?.length >= 1 && (
                      <Tooltip title={t('Pos.Main.delete')}>
                        <Button
                          className="delete-field-btn"
                          size="large"
                          type="dashed"
                          shape="circle"
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            removeExtraPriceField(index);
                            setInvoiceExtraPrices(watch('extra_prices'));
                          }}
                        />
                      </Tooltip>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
        {/* ./extra prices section */}
      </div>
    </Form>
  );
};

export default InvoiceExtraPrices;
