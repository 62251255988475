import DeleteIcon from '../../common/icons/DeleteIcon';
import EditIcon from '../../common/icons/EditIcon';
import { ReadOutlined } from '@ant-design/icons';
import countWords from '../../utils/countWords';
import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import trimWords from '../../utils/trimWords';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import deleteUnitApi from '../../apis/settings-apis/measuremnet-units-apis/deleteUnitApi';
import { EyeOutlined } from '@ant-design/icons';
import deleteTicketApi from '../../apis/technical-support-page/deleteTicketApi';
import { useContext } from 'react';
import Links from '../../components/app/Links';
import { Link as RouterLink } from 'react-router-dom';

const tableColumns = (
  allFetchedTickets,
  setModalOpened,
  setShowModalOpened,
  setFetchCount,
  setSelectedTicket,
  setIsLoading,
  token
) => {

  let routerLinks = Links();

  const handleEditClick = (record) => {
    const found = allFetchedTickets.find((place) => place.id === record.id);
    if (found) {
      setSelectedTicket({ ...found });
      setModalOpened(true);
    }
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteTicketApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف التذكرة بنجاح'
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: 'حدث خطأ اثناء العملية',
          message: res?.data?.message
            ? res.data.message
            : 'من فضلك حاول فى وقت لاحق'
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: 'حدث خطأ',
        message: 'حاول فى وقت لاحق'
      });
    }
  };

  const renderActions = (obj) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(obj)}>
          <EditIcon />
          تعديل
        </div>
        <Popconfirm
          title="هل أنت متأكد من حذف الاجابة"
          onConfirm={() => {
            handleDeleteRequest(obj.id);
          }}
          okText="نعم"
          cancelText="لا"
        >
          <Tooltip title="حذف الاجابة">
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      title: 'العميل',
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record?.admin?.id}</span>
          </div>
        );
      }
    },
    {
      title: 'الأولوية',
      dataIndex: 'priority',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record?.priority}</span>
          </div>
        );
      }
    },

    {
      title: 'التاريخ',
      dataIndex: 'date',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record?.created_at}</span>
          </div>
        );
      }
    },
    {
      title: 'الحالة',
      dataIndex: 'status',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span
              className="name-it-self"
              style={{
                backgroundColor: `${
                  record?.status == 'pending'
                    ? 'green'
                    : record?.status == 'open'
                    ? '#40a9ff'
                    : record?.status == 'close'
                    ? 'red'
                    : ''
                }`,
                padding: '10px',
                borderRadius: '10px',
                color: 'white'
              }}
            >
              {record?.status}
            </span>
          </div>
        );
      }
    },
    {
      title: 'معاينة التذكرة',
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            <RouterLink
              // onClick={() => { setSelectedTicket(record) }}
              className="settings-link"
              to={routerLinks.chatSupportPage(record?.id)}
            >
              <Tooltip>
                <Button
                  className="circled-btn"
                  type="dashed"
                  shape="circle"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    setSelectedTicket(record);
                    // setShowModalOpened(true);
                  }}
                />
              </Tooltip>
            </RouterLink>
            
          </div>
        );
      }
    },
    {
      title: 'الاعدادات',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
