import './TechnicalSupportPage.scss';

import React, { useContext, useEffect, useRef } from 'react';
import SearchNotificationsProfile from '../../components/search-notifications-profile/SearchNotificationsProfile';
import TechnicalSupportContext from '../../contexts/technical-support-context/TechnicalSupportContext';
import * as Yup from 'yup';
import ShowModal from './ShowModal';
import { Button, Descriptions, Form } from 'antd';
import SendIcon from '../../common/icons/SendIcon';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { MessageBox, MessageList } from 'react-chat-elements';
import errorNotification from '../../utils/errorNotification';
import sendMsgTicketApi from '../../apis/technical-support-page/sendMsgTicketApi';
import getSingleTicketApi from '../../apis/technical-support-page/getSingleTicketApi';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import UserContext from '../../contexts/user-context/UserProvider';
import Forbidden from "../../assets/imgs/ticket/forbidden.svg"
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
const schema = Yup.object().shape({
  content: Yup.string().optional('ادخل الرسالة'),
});
const ChatSupportPage = () => {
  const {
    setSelectedTicket,
    setFetchCount,
    selectedTicket,
    allMsgs, 
    setAllMsgs
  } = useContext(TechnicalSupportContext);
  const { user } = useContext(UserContext);
  const a4Ref = useRef();
  const params = useParams();
  const customApiRequest = useCustomApiRequest();
    const {
      control,
      setValue,
      handleSubmit,
      watch,
      clearErrors,
      formState: { errors, isSubmitting }
    } = useForm({
      resolver: yupResolver(schema),
      mode: 'all',
      defaultValues: {
        content: '',
        ticket_id: selectedTicket?.id,
        to_id: ''
      }
    });
    useEffect(() => {
      let isMounted = true;
      if (isMounted) {
        // setIsSingleLoading(true);
        customApiRequest(
          getSingleTicketApi(user?.token, params?.id),
          (res) => {
            // setIsSingleLoading(false);
            if (res?.status === 200 && res?.data?.data) {
              const data = res?.data?.data;
              setFetchCount((prevState) => prevState + 1);
              setSelectedTicket(data);
            }
          },
          (error) => {
            // setIsSingleLoading(false);
          }
        );
      }
      
      return () => {
        isMounted = false;
      };
    }, [allMsgs]);
    const renderModalBody = () => {
      return (
        <div
          className="invoice-details-wrap"
          ref={a4Ref}
          style={{
            padding: '28px 28px',
            direction: 'rtl',
            display: 'grid',
            gap: '16px',
            marginTop: '20px',
            backgroundColor: '#f9f9f9',
            boxShadow: '0px 0px 3px 0px #c2c2c2',
            borderRadius: '15px'
          }}
        >
          <div
            style={{
              display: 'grid',
              justifyContent: 'space-between',
              gridTemplateColumns: '1fr 1fr',
              gap:'20px'
            }}
          >
            <div>
              {selectedTicket && renderCompanyData(selectedTicket)}
            </div>
            {selectedTicket?.file && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'start',
                  flexDirection: 'column'
                }}
              >
                <h3>صوره التذكرة</h3>
                <img
                  className="system-logo   "
                  src={selectedTicket?.file}
                  alt="logo"
                  style={{
                    width: '100%',
                    height: '200px',
                    marginTop: '8px',
                  }}
                />
              </div>
            )}
          </div>
        </div>
      );
    };
    const renderCompanyData = (obj) => {

      return (
        <div className="supplier-details-wrap">
          <div
            style={{
              marginBottom: '8px',
              fontWeight: 'bold'
            }}
          >
            بيانات التذكرة
          </div>
          <Descriptions title={''} bordered column={1}>
            {selectedTicket?.admin?.name && (
              <Descriptions.Item
                label="الإسم"
                style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
              >
                {selectedTicket?.admin?.name}
              </Descriptions.Item>
            )}
            {selectedTicket?.subject && (
              <Descriptions.Item
                label="العنوان"
                style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
              >
                {selectedTicket?.subject}
              </Descriptions.Item>
            )}
            {selectedTicket?.priority && (
              <Descriptions.Item
                label="الأولولية"
                style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
              >
                {selectedTicket?.priority}
              </Descriptions.Item>
            )}
            {selectedTicket?.description && (
              <Descriptions.Item
                label="التفاصيل"
                style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
              >
                {selectedTicket?.description}
              </Descriptions.Item>
            )}
            {selectedTicket?.status && (
              <Descriptions.Item
                label="الحالة"
                style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
              >
                {selectedTicket?.status}
              </Descriptions.Item>
            )}
             {selectedTicket?.created_at && (
              <Descriptions.Item
                label="التاريخ"
                style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
              >
                {selectedTicket?.created_at}
              </Descriptions.Item>
            )}
          </Descriptions>
        </div>
      );
    };
    const messageListReferance = React.createRef();
    const onSubmit = async (data) => {
      customApiRequest(
        sendMsgTicketApi(user?.token, data),
        (res) => {
          // setIsLoading(false);
          // setIsSubmittingForm(false);
          setFetchCount((prevState) => prevState + 1);
          if (res?.status === 200 && res?.data?.data) {
            setAllMsgs(res?.data?.data)
            setValue('content' , '')
          } else {
            
          }
        },
        (error) => {
          // setIsLoading(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    }
    const [form] = Form.useForm();
    const renderChatBox = () => {
      return (
        <div className='chat-box'>
          <div className='chat-box-content'>
            <MessageBox
              reply={{
                photoURL: 'https://facebook.github.io/react/img/logo.svg',
                title: 'فاتورة',
                titleColor: '#8717ae',
                message: 'مرحبا بك ف الدعم الفنى لفاتورة',
              }}
              position={'left'}  
              type={'text'}
              text={'مرحبا بك ف الدعم الفنى لفاتورة'}
            />
          
            {selectedTicket?.messages && <MessageList
              referance={messageListReferance}
              className='message-list'
              lockable={true}
              toBottomHeight={'100%'}
              dataSource={selectedTicket?.messages}
              // dataSource={[
              //   {
              //       position: 'left',
              //       type: 'text',
              //       text: allMsgs?.content,
              //       date: new Date(),
              //   },
              // ]} 
            />}
          </div>
          {selectedTicket?.status === 'open'?
           <Form
            className="message-shared-form"
            form={form}
            // layout="vertical"
            onFinish={handleSubmit(onSubmit)}
          >
            <div className="text-field-label-wrap">
              <div className="text-field-wrap">
                <AntdTextField
                  setValue={setValue}
                  className="form-text-field"
                  name="content"
                  type="text"
                  placeholder="إكتب رسالتك..."
                  errorMsg={errors?.content?.message}
                  validateStatus={errors?.content ? 'error' : ''}
                  control={control}
                />
              </div>
            </div>
            <Button
              disabled={watch("content") ? false : true}
              className="submit-btn"
              htmlType="submit"
              type="primary"
              // loading={isSubmittingForm}
            >
              <SendIcon />
            </Button>
          </Form>:
          <div className='no-chat-cont'>
             <div className='no-chat'>
                <img src={Forbidden} alt='img' />
                لا يمكنك إرسال رسالة الان
             </div>
          </div>
           }
        </div>
      )
    }
    return (
      <div className="measurement-units-page">
        <div className="page-head-wrap">
          <div className="page-title">
            <h2 className="bold-font">رسائل الدعم</h2>
            <p>كشف وعرض كافة رسائل الدعم</p>
          </div>
          <SearchNotificationsProfile />
        </div>

        {/* <div className="add-section">
          <div
            className="add-account-btn"
            onClick={() => {
              // if selected unit then reset it
              // open the unit modal
              setSelectedTicket(null);
              setModalOpened(true);
            }}
          >
            <span className="text-span">إضافة تذكرة جديدة</span>
          </div>
        </div> */}

        {renderModalBody()}
        { renderChatBox()}
      </div>
    );
};

export default ChatSupportPage;
