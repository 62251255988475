/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import MyDropZone from '../../common/dorp-zone/MyDropZone';
import MyDropZonePreview from '../../common/dorp-zone/MyDropZonePreview';
import { CameraFilled } from '@ant-design/icons';
import UserContext from '../../contexts/user-context/UserProvider';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import getUserInfo from '../../apis/auth/myInfoApi';
import updateProfileApi from '../../apis/auth/updateProfileApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';

import { useTranslation } from 'react-i18next';

const ProfilePageForm = () => {
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    // username: Yup.string().required('ادخل إســـم المستخدم'),
    name: Yup.string().required(t('UserProfile.nameValidation')),
    phone: Yup.string()
      .required(t('UserProfile.phoneValidation'))
      .matches(/^[0-9]+$/, t('UserProfile.onlyNumbersAllowed'))
      .min(9, t('UserProfile.phoneLengthValidation')),
    email: Yup.string()
      .required(t('UserProfile.emailValidation'))
      .email(t('UserProfile.correctEmailValidation'))
  });
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register,
    unregister,
    // setError,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      // username: '',
      name: '',
      phone: '',
      email: '',
      address: '',
      image: null
    }
  });

  const { user, setCurrentUser } = useContext(UserContext);
  const [fetchCount, setFetchCount] = useState(0);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getUserInfo(user?.token),
        (res) => {
          if (checkRes(res) && res?.data?.data) {
            setCurrentUser({
              ...res.data.data,
              token: user.token
            });
          }
        },
        (error) => {}
      );
    }
    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  const [systemFilesToUpload, setSystemFilesToUpload] = useState(
    watch('image') ? watch('image') : null
  );
  useEffect(() => {
    // if (orgainzationHeaderToUpload?.length > 0) {
    setValue('image', systemFilesToUpload);
    // reset({ ...watch(), organization_header: orgainzationHeaderToUpload });
    // }
  }, [systemFilesToUpload, systemFilesToUpload?.length]);
  useEffect(() => {
    register('image');
    return () => unregister('image');
  }, []);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handleFilesDrop = async (acceptedFiles, formFiles, setFormFiles) => {
    const duplicates = [];
    for (let i of acceptedFiles) {
      if (formFiles?.length) {
        for (let f of formFiles) {
          if (i.name === f.name) {
            duplicates.push(i);
          }
        }
      }
    }
    for (let i = 0; i < acceptedFiles.length; i++) {
      for (let f of duplicates) {
        if (acceptedFiles[i].name === f.name) {
          acceptedFiles.splice(i, 1);
        }
      }
    }

    acceptedFiles.forEach(async (file) => {
      const preview = await getBase64(file);
      Object.assign(file, {
        preview
      });
      // setFormFiles((prevState) => [...prevState, file]);
      if (formFiles?.length > 0) {
        setFormFiles((currState) => [...currState, file]);
      } else {
        setFormFiles([file]);
      }
    });
  };

  const clearFileFromUpload = (file, setFilesToUpload) => {
    setFilesToUpload((prevState) => {
      const filtered = prevState.filter((asset) => asset.name !== file.name);
      if (filtered?.length === 0) return null;
      return filtered;
    });
  };

  // handle Initial values
  const [systemImgUrl, setSystemImgUrl] = useState(null);
  useEffect(() => {
    if (user) {
      if (user?.name) {
        setValue('name', user.name);
      }
      if (user?.username) {
        setValue('username', user.username);
      }
      if (user?.phone) {
        setValue('phone', user.phone);
      }
      if (user?.email) {
        setValue('email', user.email);
      }
      if (user?.image) {
        setSystemImgUrl([user.image]);
      }
    }
  }, [user]);

  const onSubmit = (data) => {
    // if (!watch('image') && !systemImgUrl) {
    //   setError('image', {
    //     type: 'required',
    //     message: 'اختار صورة البروفايل'
    //   });
    // }

    const mappedData = new FormData();
    // if (data.username) mappedData.append('username', data.username);
    if (data.name) mappedData.append('name', data.name);
    if (data.phone) mappedData.append('phone', data.phone);
    if (data.email) mappedData.append('email', data.email);
    if (data.password) mappedData.append('password', data.password);
    if (data.image) mappedData.append('image', data.image[0]);
    setIsSubmittingForm(true);
    customApiRequest(
      updateProfileApi(user?.token, mappedData),
      (res) => {
        setIsSubmittingForm(false);
        if (checkRes(res) && res?.data?.data) {
          setFetchCount((prev) => prev + 1);
          successNotification({
            title: t('UserProfile.operationCompletedSuccessfully'),
            message: t('UserProfile.dataUpdatedSuccessfully')
          });
        } else {
          errorNotification({
            title: t('UserProfile.errorOccurred'),
            message: res?.data?.message
              ? res.data.message
              : t('UserProfile.tryLater')
          });
        }
      },
      (error) => {
        setIsSubmittingForm(false);
        errorNotification({
          title: t('UserProfile.errorOccurred'),
          message: error?.response?.data?.message || t('UserProfile.tryLater')
        });
      }
    );
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        {/* <div className="text-field-label-wrap">
          <p className="label-p">اســم المستخدم</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="username"
              type="text"
              placeholder="اســم المستخدم..."
              errorMsg={errors?.username?.message}
              validateStatus={errors?.username ? 'error' : ''}
              control={control}
            />
          </div>
        </div> */}
        <div className="text-field-label-wrap">
          <p className="label-p">{t('UserProfile.name')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="name"
              type="text"
              placeholder={t('UserProfile.namePlaceholder')}
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('UserProfile.phoneNumber')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="phone"
              type="text"
              placeholder={t('UserProfile.phoneNumberPlaceholder')}
              errorMsg={errors?.phone?.message}
              validateStatus={errors?.phone ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('UserProfile.email')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="email"
              type="text"
              placeholder={t('UserProfile.emailPlaceholder')}
              errorMsg={errors?.email?.message}
              validateStatus={errors?.email ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
       {/*  <div className="text-field-label-wrap">
          <p className="label-p">{t('UserProfile.password')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="password"
              type="text"
              placeholder={t('UserProfile.passwordPlaceholder')}
              errorMsg={errors?.password?.message}
              validateStatus={errors?.password ? 'error' : ''}
              control={control}
            />
          </div>
        </div> */}
        <div className="product-img-wrap">
          <h3>{t('UserProfile.userImage')}</h3>

          <MyDropZone
            className="product-header-dropzone"
            multipleFiles={false}
            handleFilesDrop={(acceptedFiles) =>
              handleFilesDrop(
                acceptedFiles,
                systemFilesToUpload,
                setSystemFilesToUpload
              )
            }
            filesToUpload={systemFilesToUpload}
            formName="store-new-product-form"
            id="avatar-input"
            dropzoneText={t('UserProfile.userImage')}
            inputName="image"
            icon={<CameraFilled className="dropzone-camera" />}
            dropZoneUrls={systemImgUrl}
          >
            {t('UserProfile.dragTheImageAndPlaceItHere')}
          </MyDropZone>

          <MyDropZonePreview
            filesToUpload={systemFilesToUpload}
            clearFileFromUpload={(f) =>
              clearFileFromUpload(f, setSystemFilesToUpload)
            }
          />
          {errors?.image?.message && !watch('image') && (
            <p className="error-p">{errors.image.message}</p>
          )}
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {t('UserProfile.save')}
        </Button>
      </div>
    </Form>
  );
};

export default ProfilePageForm;
