import React, { useContext } from 'react';
import { Descriptions, Modal } from 'antd';
import ReservationContext from '../../contexts/reservation-context/ReservationContext';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import useSingleInvoice from '../../custom-hooks/useSingleInvoice';
import moment from 'moment';

const PageModal = () => {
  const { modalOpened, setModalOpened, setSelectedCalendar, selectedCalendar } =
    useContext(ReservationContext);
  const singleInvoice = useSingleInvoice(selectedCalendar?.id);
  const { setSelectedSaleInvoiceModalOpened, setSelectedInvoice } =
    useContext(PosInvoiceContext);
  const renderModalContent = () => {
    return (
      <Descriptions bordered column={1}>
        {selectedCalendar.id && (
          <Descriptions.Item label="كود الفاتورة">
            {selectedCalendar.id}
          </Descriptions.Item>
        )}
        {selectedCalendar.name && (
          <Descriptions.Item label="إســـم العميل">
            {selectedCalendar.name}
          </Descriptions.Item>
        )}
        {selectedCalendar.phone && (
          <Descriptions.Item label="رقم الهاتف : ">
            {selectedCalendar.phone}
          </Descriptions.Item>
        )}
        {selectedCalendar.address && (
          <Descriptions.Item label="العنوان : ">
            {selectedCalendar.address}
          </Descriptions.Item>
        )}
        {selectedCalendar.date && moment(selectedCalendar.date) && (
          <Descriptions.Item label="تاريخ الفاتورة : ">
            {selectedCalendar.date}
          </Descriptions.Item>
        )}
        {selectedCalendar.dueDate && moment(selectedCalendar.dueDate) && (
          <Descriptions.Item label="تاريخ الاستحقاق : ">
            {selectedCalendar.dueDate}
          </Descriptions.Item>
        )}
        {selectedCalendar.totalPrice && (
          <Descriptions.Item label="السعر الإجمالى شامل للضريبة : ">
            {selectedCalendar.totalPrice}
          </Descriptions.Item>
        )}
      </Descriptions>
    );
  };

  const handleViewInvoiceDetailsModal = () => {
    setSelectedSaleInvoiceModalOpened(true);
    if (singleInvoice) setSelectedInvoice({ ...singleInvoice });
  };

  return (
    <Modal
      transitionName=""
      className="shared-custom-modal sticky-modal reservation-modal"
      width="90%"
      style={{ maxWidth: '532px' }}
      title="تفاصيل الحجز"
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedCalendar(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedCalendar(null);
      }}
      footer={false}
    >
      {renderModalContent()}
      <button
        className="invoice-print-btn"
        onClick={() => handleViewInvoiceDetailsModal()}
      >
        تفاصيل الفاتورة
      </button>
    </Modal>
  );
};

export default PageModal;
