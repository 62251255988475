import { Button } from 'antd';
import React, { useState } from 'react';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useTranslation } from 'react-i18next';

const GenerateMultiBarCode = (props) => {
  const { t } = useTranslation();
  const [barCode, setBarCodeNumSub] = useState();
  const randomNumberInRange = (length) => {
    return Math.floor(
      Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)
    );
  };
  const handleClickBarSub = () => {
    setBarCodeNumSub(randomNumberInRange(13));
  };
  return (
    <div className="text-field-label-wrap">
      <p
        className="label-p"
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <span>{t('Inventory.Products.productBarcode')}</span>
        <b
          style={{ border: 'none', cursor: 'pointer', color: '#4d71db' }}
          onClick={() => handleClickBarSub()}
        >
          {t('Inventory.Products.generateNewBarcode')}
        </b>
      </p>
      <div className="text-field-wrap">
        <AntdTextField
          setValue={props.setValue}
          className="form-text-field"
          name={`multiProducts.${props.index}.defaultParCode`}
          type="text"
          placeholder={t('Inventory.Products.productBarcodePlaceholder')}
          errorMsg={
            props.errors.defaultParCode &&
            props.errors.subProducts[props.index]?.defaultParCode &&
            props.errors.subProducts[props.index]?.defaultParCode.message
          }
          validateStatus={
            props.errors.defaultParCode &&
            props.errors.subProducts[props.index]?.defaultParCode &&
            props.errors.subProducts[props.index]?.defaultParCode
              ? 'error'
              : ''
          }
          defaultValue={barCode || props?.selectedProduct?.defaultParCode}
          control={props.control}
        />
      </div>
    </div>
  );
};

export default GenerateMultiBarCode;
