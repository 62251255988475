/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo } from 'react';
import PosStepperForm from '../../components/pos-stepper-from/PosStepperForm';
import './HomePage.scss';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import ProductIngredientsModal from '../../components/pos-stepper-from/ProductIngredientsModal';
import UserContext from '../../contexts/user-context/UserProvider';
import LaterInvoiceModal from '../../components/pos-stepper-from/LaterInvoiceModal';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import ProductDiscountModal from '../../components/pos-stepper-from/ProductDiscountModal';
import ClientsPosContext from '../../contexts/clients-context-pos/ClientsPosContext';
import ReturnModal from '../../components/return-modal/ReturnModal';
import ReturnModalContext from '../../contexts/return-modal-context/ReturnModalContext';
import BarcodeModal from '../../components/pos-stepper-from/BarcodeModal';
import PosReportModal from '../../components/app/pos-section/pos-report-modal/PosReportModal';
import getAllProducts from '../../apis/pos-api/pos-products/getAllProducts copy';
import getPosShiftReport from '../../apis/pos-api/getPosShiftReport';
import Links from '../../components/app/Links';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import PosBoxModal from '../../components/app/pos-section/pos-box-modal/PosBoxModal';

const HomePage = () => {
  const routerLinks = Links();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const {
    fetchCount,
    setIsLoading,
    setAllFetchedClients,
    setSelectedClientId
  } = useContext(ClientsPosContext);
  const {
    productDiscountModalOpened,
    productIngredientsModalOpened,
    selectedInvoice,
    selectedProducts,
    setSelectedProducts,
    setInvoiceDiscountType,
    setInvoiceDiscountValue,
    setInvoiceExtraPrices,
    laterInvoiceModalOpened,
    barcodeModalOpened,
    fetchProductsCount,
    itemCode,
    barCodeQuantity,
    setAllProducts,
    setLoadingProducts,
    setSystemTax,
    setSystemSettings,
    productsFilter,
    setProductsPagination,
    setBarcodeModalOpened,
    setSearchTextState,
    searchTextState
  } = useContext(PosInvoiceContext);

  const { returnModalOpened } = useContext(ReturnModalContext);
  const { user, posReportModalOpened, posBoxModalOpened } =
    useContext(UserContext);
  const { loggedIn } = useContext(UserContext);
  const history = useHistory();
  const { pathname } = useLocation();

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    if (
      user
    ) {
      let isMounted = true;
      if (isMounted) {
        customApiRequest(
          getPosShiftReport(user?.token || user?.employee?.token),
          (res) => {
            if (!res?.data?.data) {
              // removeCurrentUser();
              history.push(routerLinks.posPage);
            }
          },
          (error) => {}
        );
      }

      return () => {
        isMounted = false;
      };
    }
  }, [pathname]);

  const apiRequestParams = useMemo(() => {
    return getAllProducts(user?.token || user?.employee?.token, {
      ...productsFilter,
      ...values,
    });
  }, [user?.token, productsFilter]);

  useEffect(() => {
    let isMounted = true;

    if (user && isMounted) {
      setLoadingProducts(true);
      customApiRequest(
        getAllProducts(user?.token || user?.employee?.token, {
          // mainCatId: selectedMainCategory,
          ...productsFilter,
          ...values
        }),
        (res) => {
          setLoadingProducts(false);
          if (res?.data?.data?.data?.length >= 0) {
            const data = res?.data?.data?.data;
            if (res?.data?.data?.data?.length == 1 && searchTextState) {
              // setSelectedProducts(data)
              let p = data.find((item) => item);
              setSelectedProducts((prev) => {
                if (true) {
                  const found = selectedProducts.find((obj) => obj.id === p.id);
                  if (found) {
                    // return selectedProducts.filter((item) => item.id !== p.id);
                    return prev.map((q) =>
                      q.id === p.id
                        ? {
                            ...q,
                            productInCartQuantity: (
                              parseFloat(q.productInCartQuantity) + 1
                            ).toFixed(2)
                          }
                        : { ...q }
                    );
                    // } else {
                  } else {
                    return [
                      ...prev,
                      {
                        ...p,
                        productDiscountType: '0',
                        productDiscountValue: '0',
                        // productInCartQuantity: p.quantityInPosStock == 0 ? 0 : 1,
                        productInCartQuantity:
                          (p?.parCode == itemCode ? barCodeQuantity : '') || 1,
                        productIngredients:
                          p.materials?.length > 0
                            ? p.materials
                                .map((m) =>
                                  m.quantityInPosStock
                                    ? {
                                        ...m,
                                        ingredientInCartQuantity: 0
                                        // ingredientInCartQuantity:
                                        //   m.quantityInPosStock === 0 ? 0 : 1
                                      }
                                    : null
                                )
                                .filter((i) => i)
                            : []
                      }
                    ];
                  }
                }
              });
              setSearchTextState('');
            } else {
              setAllProducts(data);
              setBarcodeModalOpened(false);
            }
          }
          if (res?.data?.data?.pagination) {
            setProductsPagination(res.data.data.pagination);
          }
        },
        (error) => {
          setLoadingProducts(false);
        }
      );
    } else {
      setLoadingProducts(false);
      setAllProducts([]);
    }

    return () => {
      isMounted = false;
    };
  }, [fetchProductsCount, productsFilter, loggedIn]);

  const systemSettings = useSystemSettings();

  useEffect(() => {
    if (systemSettings && String(systemSettings?.tax)) {
      setSystemTax(systemSettings.tax);
      setSystemSettings(systemSettings);
    }
  }, [systemSettings]);

  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      setSelectedClientId('');
      customApiRequest(
        apiRequestParams,
        (res) => {
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            if (res.data.data.length >= 0) {
              const data = res.data.data;
              setAllFetchedClients(data);
            }
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // handle initial values
  React.useEffect(() => {
    if (selectedInvoice) {
      // client inital value
      if (selectedInvoice.user?.id) {
        setSelectedClientId(String(selectedInvoice.user.id));
      }
      // selected products inital values
      if (selectedInvoice.products?.length > 0) {
        const mappedSelectedProducts = selectedInvoice.products.map(
          (invoiceProduct) => {
            if (
              invoiceProduct?.product &&
              Object.keys(invoiceProduct.product)?.length > 0
            ) {
              return {
                ...invoiceProduct.product,
                //
                apiDiscountType: invoiceProduct?.discountType,
                apiDiscountValue: invoiceProduct?.discountValue,
                productDiscountType: invoiceProduct?.discountType,
                productDiscountValue: invoiceProduct?.discountValue,
                tax: invoiceProduct?.tax,
                //
                productInCartQuantity: invoiceProduct.quantity,
                returnProductInCartQuantity: '',
                productIngredients:
                  invoiceProduct.materials?.length > 0
                    ? invoiceProduct.materials.map((add) => ({
                        ...add,
                        id: add.model_id,
                        ingredientInCartQuantity: add.quantity,
                        checked: true
                      }))
                    : []
              };
            }
            return [];
          }
        );
        setSelectedProducts([...mappedSelectedProducts]);
      }
      // discountType intial values
      if (
        selectedInvoice.discountType ||
        String(selectedInvoice.discountType)
      ) {
        setInvoiceDiscountType(String(selectedInvoice.discountType));
      }
      // discount value intial values
      if (
        selectedInvoice.discountValue ||
        String(selectedInvoice.discountValue)
      ) {
        setInvoiceDiscountValue(String(selectedInvoice.discountValue));
      }
      // invoice extraprices initial values
      if (selectedInvoice.addPrices?.length > 0) {
        setInvoiceExtraPrices([...selectedInvoice.addPrices]);
      }
    }
  }, [selectedInvoice]);

  return (
    <div className="home-page">
      {/* <div className="shared-pos-page-header">
        <div className="header-links">
          <Button onClick={() => setModalOpened(true)}>إضافة عميل</Button>
          <Button>الحجوزات</Button>
          <Button>المرتجعات</Button>
          <Button onClick={() => history.push(routerLinks.clientsPage)}>
            العملاء
          </Button>
        </div>
        <NotificationsProfile />
      </div> */}
      <PosStepperForm />
      {/* {<PosReportModal />} */}
      {posReportModalOpened && <PosReportModal />}
      {posBoxModalOpened && <PosBoxModal />}
      {/* {modalOpened && <PagePosModal />} */}
      {productDiscountModalOpened && <ProductDiscountModal />}
      {productIngredientsModalOpened && <ProductIngredientsModal />}
      {laterInvoiceModalOpened && <LaterInvoiceModal />}
      {returnModalOpened && <ReturnModal />}
      {barcodeModalOpened && <BarcodeModal />}
    </div>
  );
};

export default HomePage;
