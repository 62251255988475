import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import { Popconfirm, Tooltip } from 'antd';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import deleteIngredientApi from '../../../apis/store-apis/ingredients/deleteIngredientApi';

const tableColumns = (
  allFetchedIngredients,
  setModalOpened,
  setFetchCount,
  setSelectedIngredient,
  setIsLoading,
  token,
  t
) => {
  const handleEditClick = (record) => {
    const found = allFetchedIngredients.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedIngredient({ ...found });
      setModalOpened(true);
    }
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteIngredientApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Ingredient.operationCompletedSuccessfully'),
          message: t('Ingredient.deletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Ingredient.errorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Ingredient.tryLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Ingredient.errorOccurred'),
        message: t('Ingredient.tryLater')
      });
    }
  };

  const renderActions = (obj) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(obj)}>
          <EditIcon />
          {t('Ingredient.update')}
        </div>
        <Popconfirm
          title={t('Ingredient.deleteConfirm')}
          onConfirm={() => handleDeleteRequest(obj.id)}
          okText={t('Ingredient.yes')}
          cancelText={t('Ingredient.no')}
        >
          <Tooltip title={t('Ingredient.delete')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      title: t('Ingredient.code'),
      dataIndex: 'index',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('Ingredient.ingredientName'),
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text desc-wrap">
            <span className="name-it-self">{record.name}</span>
          </div>
        );
      }
    },
    {
      title: t('Ingredient.price'),
      dataIndex: 'price',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.price ? record.price : '-----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('Ingredient.settings'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
