import axios from 'axios';

// type === 1 => receipt is a buying receipt
// type === 2 => receipt is a return receipt
// detailsType === 1 => receipt of products
// detailsType === 2 => receipt of materials
// filterValues = { supplier_id: '', type: '', detailsType: ''}
const getAllIncomingPurchaseInvoicesApi = async (token, filterValues) => {
 // console.log(filterValues)
  try {
    const url = `/BuyInvoice/all?invoice_type=${
      filterValues?.invoice_type ? filterValues.invoice_type : '1'
    }&invoiceNumber=${
      filterValues?.invoiceNumber ? filterValues.invoiceNumber : ''
    }&id=${filterValues?.id ? filterValues.id : ''}&manualNum=${
      filterValues?.manualNum ? filterValues.manualNum : ''
    }&paymentType=${filterValues?.pay_id || ''}&supplier_id=${
      filterValues?.supplier_id
        ? filterValues?.supplier_id
        : filterValues?.supplier_id_from_payment
        ? filterValues?.supplier_id_from_payment
        : ''
    }
    &type=${filterValues?.type ? filterValues.type : ''}&detailsType=${
      filterValues?.detailsType ? filterValues.detailsType : ''
    }&from=${filterValues?.from ? filterValues.from : ''}&to=${
      filterValues?.to ? filterValues.to : ''
    }&admin_id=${filterValues?.admin_id ? filterValues.admin_id : ''}&page=${
      filterValues?.page ? filterValues.page : ''
    }&test=1&user_id=${
      filterValues?.user_id
        ? filterValues?.user_id
        : filterValues?.user_id_from_payment
        ? filterValues?.user_id_from_payment
        : ''
    }`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllIncomingPurchaseInvoicesApi;
