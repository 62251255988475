/* eslint-disable eqeqeq */
import React, { useRef } from 'react';
import { Button, Descriptions, Empty, Modal, Table, Tooltip } from 'antd';
import moment from 'moment';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import StorePurchaseOrderContext from '../../../contexts/store-purchase-order-context/StorePurchaseOrderProvider';
import './SelectedPurchaseOrderModal.scss';
import { useTranslation } from 'react-i18next';
const SelectedPurchaseOrderModal = ({ currentLang }) => {
  const {
    selectedOrder,
    setSelectedOrder,
    setSelectedOrderModalOpened,
    selectedOrderModalOpened
  } = React.useContext(StorePurchaseOrderContext);
  const { t } = useTranslation();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
    @media print {
      html, body { height: initial !important; overflow: initial !important; }

      tbody { display: table-row-group !important; }
      thead {
        display: table-row-group !important;
      }
      tfoot {
        display: table-row-group !important;
      }
      @page {
        size: auto;
        margin: none;
      }
      .pagebreak {
        display: block;
        size: auto;
      }
      .invoice-details-wrap {
        padding: 0;
      }
      .ant-descriptions-item-content,
        .ant-descriptions-item-label {
          padding: 4px 12px !important;
        }
      }
      .ant-descriptions-header {
        margin: 4px 0 !important;
        marginTop: 12px;
      }
    `
  });

  const renderModalBody = () => {
    return (
      <div
        className="invoice-details-wrap"
        ref={componentRef}
        style={{
          padding: 12
          // display: 'grid',
          // gap: '16px'
        }}
      >
        <div>
          <Descriptions title="" bordered column={1}>
            {selectedOrder?.id && (
              <Descriptions.Item label={t('PurchaseOrder.orderNumber')}>
                {selectedOrder?.id}
              </Descriptions.Item>
            )}
            {selectedOrder?.date && moment(selectedOrder.date) && (
              <Descriptions.Item label={t('PurchaseOrder.releaseDate')}>
                {moment(selectedOrder.date).format('DD-MM-YYYY')}
              </Descriptions.Item>
            )}
            {selectedOrder?.dueDate && moment(selectedOrder.dueDate) && (
              <Descriptions.Item label={t('PurchaseOrder.dueDateCell')}>
                {moment(selectedOrder.dueDate).format('DD-MM-YYYY')}
              </Descriptions.Item>
            )}
          </Descriptions>
        </div>
        {renderSupplier()}

        {renderMaterials()}
      </div>
    );
  };

  const renderSupplier = () => {
    const {
      supplier: {
        name,
        phone,
        address,
        addressCode,
        VATRegistrationNumber,
        tax_number,
        taxNumber
      }
    } = selectedOrder;
    return (
      <div className="supplier-details-wrap">
        <Descriptions title={t('PurchaseOrder.supplier')} bordered column={1}>
          {name && (
            <Descriptions.Item label={t('PurchaseOrder.name')}>
              {name}
            </Descriptions.Item>
          )}
          {phone && (
            <Descriptions.Item label={t('PurchaseOrder.phone')}>
              {phone}
            </Descriptions.Item>
          )}
          {address && (
            <Descriptions.Item label={t('PurchaseOrder.address')}>
              {address}
            </Descriptions.Item>
          )}
          {!address && addressCode && (
            <Descriptions.Item label={t('PurchaseOrder.address')}>
              {addressCode}
            </Descriptions.Item>
          )}
          {VATRegistrationNumber && (
            <Descriptions.Item label={t('PurchaseOrder.VATNumber')}>
              {VATRegistrationNumber}
            </Descriptions.Item>
          )}
          {tax_number && (
            <Descriptions.Item label={t('PurchaseOrder.VATNumber')}>
              {tax_number}
            </Descriptions.Item>
          )}
          {taxNumber && (
            <Descriptions.Item label={t('PurchaseOrder.VATNumber')}>
              {taxNumber}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  };

  const renderMaterials = () => {
    const buyOrderMaterial = selectedOrder.buyOrderMaterial;
    if (buyOrderMaterial?.length > 0) {
      return (
        <div className="models-wrap pagebreak">
          <div
            className="models-title bold-font"
            style={{
              direction: currentLang == 'ar' ? 'rtl' : 'ltr',
              color: 'rgba(0, 0, 0, 0.85)',
              fontWeight: 'bold',
              fontSize: '16px',
              marginTop: '8px',
              marginBottom: '2px'
            }}
          >
            {t('PurchaseOrder.description')}
          </div>
          <Table
            locale={{
              emptyText: (
                <Empty description={false}>
                  {t('PurchaseOrder.description')}
                </Empty>
              )
            }}
            pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            // className="antd-custom-table"
            dataSource={buyOrderMaterial.map((item) => ({
              ...item,
              key: item.id
            }))}
            columns={[
              {
                title: t('PurchaseOrder.code'),
                dataIndex: 'id',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-index bold-font">
                      <span>{record.model_id}</span>
                    </div>
                  );
                }
              },
              {
                title: t('PurchaseOrder.name'),
                dataIndex: 'name',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.name ? record.name : '-----'}</span>
                    </div>
                  );
                }
              },
              {
                title: t('PurchaseOrder.quantity'),
                dataIndex: 'quantity',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.quantity}</span>
                    </div>
                  );
                }
              }
            ]}
            // loading={isLoading}
            // scroll={{ x: 400 }}
            footer={false}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <Modal
      className="shared-custom-modal selected-order-modal"
      width="90%"
      style={{ maxWidth: '942px' }}
      title={
        <>
          {t('PurchaseOrder.orderDetails')}
          {/* <Tooltip title="طباعة الفاتورة"> */}
          <Tooltip title="">
            <Button
              onClick={handlePrint}
              className="circled-btn"
              type="dashed"
              shape="circle"
              icon={<PrinterOutlined />}
            />
          </Tooltip>
        </>
      }
      visible={selectedOrderModalOpened}
      onOk={() => {
        setSelectedOrderModalOpened(false);
        setSelectedOrder(null);
      }}
      onCancel={() => {
        setSelectedOrderModalOpened(false);
        setSelectedOrder(null);
      }}
      footer={false}
    >
      {renderModalBody()}

      {/* <pre style={{ direction: 'ltr' }}>
        {JSON.stringify(selectedReceipt, null, 2)}
      </pre> */}
    </Modal>
  );
};

export default SelectedPurchaseOrderModal;
