/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker, Select } from 'antd';
import React, { useCallback, useContext, useEffect } from 'react';
import excelImg from '../../../assets/imgs/icons/excel-btn.png';
import CalendarIcon from '../../../common/icons/CalendarIcon';
import SelectDropDown from '../../../common/icons/SelectDropDown';
import UserContext from '../../../contexts/user-context/UserProvider';
// import Workbook from 'react-excel-workbook';
import debounce from 'lodash.debounce';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import getAllIncomingPurchaseInvoicesReturnApi from '../../../apis/store-apis/incoming-purchas-invoices-return-apis/getAllIncomingPurchaseInvoicesReturnApi';
import SearchIcon from '../../../common/icons/SearchIcon';
import IncomingPurchaseInvoiceContext from '../../../contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import useSuppliers from '../../../custom-hooks/useSuppliers';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import useUsers from '../../../custom-hooks/useUsers';
import userRoles from '../../../userRoles';
import checkRes from '../../../utils/checkRes';
const { RangePicker } = DatePicker;

// const receiptContent = [
//   {
//     title: 'فواتير خدمات',
//     value: '1'
//   },
//   {
//     title: 'فواتير مكونات',
//     value: '2'
//   }
// ];

// type === 1 => invoice is a normal buying invoice
// type === 2 => invoice is a return invoice
// type === 3 => invoice is a reservation invoice
// is_return === 1 => invoice of products
// today_reservations === 1 => get today reservations
// supplier_id === 2 => id of the client

const TableFilter = ({ allFetchedReceipts }) => {
  const { user } = useContext(UserContext);
  const { Option } = Select;
  const [selectedPicker, setSelectedPicker] = React.useState(null);
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { allSuppliers } = useSuppliers();
  const allDelegateUsers = useUsers(userRoles?.delegate);
  const systemSettings = useSystemSettings();
  const customApiRequest = useCustomApiRequest();
  const { pathname } = useLocation();
  const user_id_from_payment = pathname?.split('/')[3];

  const { t } = useTranslation();
  const receiptTypes = [
    {
      title: t('purchaseInvoices.purchesInvoices'),
      value: '1'
    },
    {
      title: t('purchaseInvoices.returnInvoices'),
      value: '2'
    }
  ];
  const {
    setAllFetchedReceipts,
    setIsLoading,
    tableFilter,
    setTableFilter,
    setTablePagination
  } = useContext(IncomingPurchaseInvoiceContext);

  const handlePickerChange = (value, string) => {
    setSelectedPicker({
      moments: value,
      strings: string
    });
  };
  React.useEffect(() => {
    if (selectedPicker?.strings?.length > 0) {
      setTableFilter((prev) => ({
        ...prev,
        from: selectedPicker.strings[0],
        to: selectedPicker.strings[1]
      }));
    }
  }, [selectedPicker?.strings]);

  const debouncedChangeHandler = useCallback(
    debounce(async (e) => {
      setTableFilter((prevState) => ({
        ...prevState,
        manualNum: e.target.value
      }));
    }, 500),
    []
  );

  async function handleChange(filterType, value) {
    setTableFilter((prevState) => {
      // if (filterType === 'invoiceNumber') {
      //   return {
      //     ...prevState,
      //     invoiceNumber: value.target.value
      //   };
      // }
      if (filterType === 'manualNum') {
        return {
          ...prevState,
          manualNum: value.target.value
        };
      } else if (filterType === 'pay_id') {
        return {
          ...prevState,
          pay_id: value
        };
      } else if (filterType === 'id') {
        return {
          ...prevState,
          id: value.target.value
        };
      } else if (filterType === 'supplier_id') {
        return {
          ...prevState,
          supplier_id: value || user_id_from_payment
        };
      } else if (filterType === 'type') {
        return {
          ...prevState,
          type: value
        };
      } else if (filterType === 'serviceOrPackage') {
        return {
          ...prevState,
          serviceOrPackage: value
        };
      } else if (filterType === 'admin_id') {
        return {
          ...prevState,
          admin_id: value
        };
      }
    });
  }

  useEffect(() => {
    setIsLoading(true);
    customApiRequest(
      getAllIncomingPurchaseInvoicesReturnApi(user?.token, {
        ...tableFilter,
        ...values,
        page: 1,
        supplier_id_from_payment: user_id_from_payment
      }),
      (res) => {
        if (checkRes(res) && res?.data?.data) {
          const data = res.data.data.invoices.data;
          setAllFetchedReceipts(data);
          setIsLoading(false);
        }
        if (res?.data?.data?.invoices) {
          setTablePagination(res.data.data.invoices.pagination);
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }, [tableFilter]);

  // async function handleSubmit(e) {
  //   e.preventDefault();
  //   setIsLoading(true);
  //   customApiRequest(
  //     getAllIncomingPurchaseInvoicesApi(user?.token, {
  //       ...tableFilter,
  //       ...values,
  //       page: 1
  //     }),
  //     (res) => {
  //       if (checkRes(res) && res?.data?.data) {
  //         const data = res.data.data.invoices.data;
  //         setAllFetchedReceipts(data);
  //         setIsLoading(false);
  //       }
  //       if (res?.data?.data?.invoices) {
  //         setTablePagination(res.data.data.invoices.pagination);
  //       }
  //     },
  //     (error) => {
  //       setIsLoading(false);
  //     }
  //   );
  // }

  // const syncSallaAuth = async () => {
  //   try {
  //     const res = await SyncSallaApi(
  //       user?.token,
  //       systemSettings?.sallaToken,
  //       'orders'
  //     );
  //     if (res?.status === 200 && res?.data?.status === 1) {
  //       // setIsLoading(false);
  //       // setFetchCount((prevState) => prevState + 1);
  //       successNotification({
  //         title: t('purchaseInvoices.successNotification'),
  //         message: res.data.message
  //       });
  //     } else {
  //       // setIsLoading(false);
  //       errorNotification({
  //         title: t('purchaseInvoices.errorNotification'),
  //         message: res?.data?.message
  //           ? res.data.message
  //           : t('purchaseInvoices.tryLaterMessage')
  //       });
  //     }
  //   } catch (error) {
  //     // setIsLoading(false);
  //     errorNotification({
  //       title: t('purchaseInvoices.errorOccurred'),
  //       message: t('purchaseInvoices.tryLaterMessage')
  //     });
  //   }
  // };

  return (
    <div className="custom-table-fitler warehouse-table-filter">
      <div className="form-excel-export-wrap">
        <form
          className="filter-form receipt-filter-form"
          // onSubmit={handleSubmit}
        >
          <div className="search-label-wrapper">
            <p
              style={{
                marginBottom: 5
              }}
            >
              {t('purchaseInvoices.invoiceNumber')}
            </p>
            <div className="table-search" style={{ width: '310px' }}>
              <label>
                <SearchIcon />
                <input
                  placeholder={t('purchaseInvoices.EnterInvoiveNumber')}
                  type="text"
                  name="manualNum"
                  onChange={(e) => debouncedChangeHandler(e)}
                />
              </label>
            </div>
          </div>

          <label className="receipt-filter-option-wrap">
            <span className="select-title">
              {t('purchaseInvoices.supplierName')}
            </span>
            <Select
              defaultValue={user_id_from_payment || null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('supplier_id', v)}
              suffixIcon={<SelectDropDown />}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>{t('purchaseInvoices.allSuppliers')}</Option>
              {allSuppliers?.length > 0 &&
                allSuppliers.map((item) => (
                  <Option key={item.id} value={String(item.id)}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </label>

          {user?.role !== userRoles?.delegate && (
            <label className="receipt-filter-option-wrap">
              <span className="select-title">
                {t('purchaseInvoices.employeeName')}
              </span>
              <Select
                defaultValue={null}
                style={{ width: 172 }}
                onChange={(v) => handleChange('admin_id', v)}
                suffixIcon={<SelectDropDown />}
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={null}>
                  {t('purchaseInvoices.allEmployees')}
                </Option>
                {allDelegateUsers?.length > 0 &&
                  allDelegateUsers.map((item) => (
                    <Option key={item.id} value={String(item.id)}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </label>
          )}

          {/* <label className="receipt-filter-option-wrap">
            <span className="select-title">
              {t('purchaseInvoices.invoiceType')}
            </span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('type', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>{t('purchaseInvoices.allInvoices')}</Option>
              {receiptTypes.map((role, index) => (
                <Option key={index} value={role.value}>
                  {role.title}
                </Option>
              ))}
            </Select>
          </label> */}

          {/* <label className="receipt-filter-option-wrap">
            <span className="select-title">محتوى الخدمة</span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('serviceOrPackage', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>جميع الخدمات</Option>
              {receiptContent.map((role, index) => (
                <Option key={index} value={role.value}>
                  {role.title}
                </Option>
              ))}
            </Select>
          </label> */}
          <label className="receipt-filter-option-wrap">
            <span className="select-title">
              {t('purchaseInvoices.paymentType')}
            </span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('pay_id', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>
                {t('purchaseInvoices.allPaymentsTypes')}
              </Option>
              <Option value={1}>{t('purchaseInvoices.cash')}</Option>
              <Option value={2}>{t('purchaseInvoices.postpaid')}</Option>
              <Option value={3}>{t('purchaseInvoices.visa')}</Option>
              <Option value={5}>{t('purchaseInvoices.bankTransfer')}</Option>
              <Option value={6}>{t('purchaseInvoices.multiplePayment')}</Option>
              {/* {receiptTypes.map((role, index) => (
                <Option key={index} value={role.value}>
                  {role.title}
                </Option>
              ))} */}
            </Select>
          </label>
          <div className="range-picker-wrap">
            <RangePicker
              value={selectedPicker?.moments && selectedPicker.moments}
              onChange={handlePickerChange}
              // format="YYYY-MM-DD"
              className={`filter-range-picker`}
              allowClear={true}
              suffixIcon={<CalendarIcon />}
              placeholder={[
                t('purchaseInvoices.dateFrom'),
                t('purchaseInvoices.dateTo')
              ]}
            />
          </div>
          {/* <button className="filter-submit-btn" type="submit">
            بحث
          </button> */}
          {/* {systemSettings?.sallaToken && (
          <div className="preview-download-upload add-btn" onClick={() => syncSallaAuth()}>
            <span className="text-span">ربط مع سلة</span>
          </div>
        )} */}
          <div className="excel-btn-wrap">
            <a
              href={`${
                process.env.REACT_APP_BASE_URL
              }/exportExcelInvoice?client_id=${
                systemSettings?.id || ''
              }&type=${2}&today_reservations=${
                tableFilter?.today_reservations || ''
              }&user_id=${tableFilter?.user_id || ''}&supplier_id=${
                tableFilter?.supplier_id || ''
              }&from=${tableFilter?.from || ''}&to=${
                tableFilter?.to || ''
              }&pos_id=${tableFilter?.pos_id || ''}&paymentType=${
                tableFilter?.pay_id || ''
              }&admin_id=${tableFilter?.admin_id || ''}&page=${
                tableFilter?.page || '1'
              }&manualNum=${tableFilter?.manualNum}&typeN=${2}`}
              download
              className="excel-export-btn"
            >
              <img
                style={{ height: '42px' }}
                src={excelImg}
                alt="export to excel"
              />
            </a>
          </div>
        </form>

        {/* <div className="excel-btn-wrap">
          <Workbook
            filename="incoming_invoice.xlsx"
            element={
              <div className="excel-export-btn">
                <img
                  style={{ height: '42px' }}
                  src={excelImg}
                  alt="export to excel"
                />
              </div>
            }
          >
            <Workbook.Sheet
              data={
                allFetchedReceipts?.invoices
                  ? allFetchedReceipts?.invoices
                  : allFetchedReceipts
                  ? allFetchedReceipts
                  : []
              }
              name="Sheet A"
            >
              <Workbook.Column label="مسلسل" value="invoiceNumber" />
              <Workbook.Column
                label="المورد"
                value={(row) => (row?.user?.name ? row.user.name : row.name)}
              />
              <Workbook.Column
                label="تاريخ الفاتورة"
                value={(row) => row.dueDate}
              />
              <Workbook.Column
                label="السعر الإجمالى"
                value={(row) => row.totalPrice}
              />
            </Workbook.Sheet>
          </Workbook>
        </div> */}
      </div>
    </div>
  );
};

export default TableFilter;
