/* eslint-disable eqeqeq */
import { Empty, Table, Descriptions } from 'antd';
import QRCode from 'qrcode.react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import WaterMark from '../../../common/water-mark/WaterMark';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';

const ReceiptDetailsComponentProductsV2 = React.forwardRef((props, ref) => {
  const { selectedReceipt } = props;
  const systemSettings = useSystemSettings();
  const { t } = useTranslation();

  const renderDiscountValue = (record) => {
    if (record.discountType == '1') {
      // fixed discount price
      return record.discountPrice;
    } else if (record.discountType == '2') {
      // percentage discount
      return `${record.discountPrice}`;
    } else {
      // no discount
      return t('SalesInvoices.WithoutDiscount');
    }
  };

  const rowWrapStyles = {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gap: '8px'
  };

  const renderUser = (obj, type) => {
    const {
      name,
      phone,
      address,
      addressCode,
      VATRegistrationNumber,
      tax_number,
      taxNumber,
      commercialRecord
    } = obj;
    return (
      <div
        className="supplier-details-wrap"
        style={{
          display: 'grid'
          /* width: 'fit-content' */
        }}
      >
        <div
          style={{
            marginBottom: 2,
            fontWeight: 'bold'
          }}
        ></div>
        <div
          style={{
            display: 'grid'
          }}
        >
          {name && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">{t('Quotations.Name')} :</div>
              <div className="row-value">{name}</div>
            </div>
          )}
          {phone && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">{t('Quotations.PhoneNumber')} :</div>
              <div className="row-value">{phone}</div>
            </div>
          )}
          {address && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">{t('Quotations.Address')} :</div>
              <div className="row-value">{address}</div>
            </div>
          )}
          {!address && addressCode && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">{t('Quotations.Address')} :</div>
              <div className="row-value">{addressCode}</div>
            </div>
          )}
          {VATRegistrationNumber && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">{t('Quotations.VATNumber')} :</div>
              <div className="row-value">{VATRegistrationNumber}</div>
            </div>
          )}
          {tax_number && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">{t('Quotations.VATNumber')} :</div>
              <div className="row-value">{tax_number}</div>
            </div>
          )}
          {taxNumber && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">{t('Quotations.VATNumber')} :</div>
              <div className="row-value">{taxNumber}</div>
            </div>
          )}
          {commercialRecord && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">
                {t('Quotations.CommercialRegistrationNumber')} :
              </div>
              <div className="row-value">{commercialRecord}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderModels = () => {
    const details = selectedReceipt?.details;
    if (details?.length > 0) {
      return (
        <div className="models-wrap pagebreak">
          <div
            className="models-title bold-font"
            style={{
              marginBottom: 2,
              fontWeight: 'bold'
            }}
          >
            {t('Quotations.ServiceDescription')}:
          </div>
          <Table
            className="v2-invoice-table"
            bordered
            // scroll={{ x: 1032 }}
            locale={{
              emptyText: (
                <Empty description={false}>
                  {' '}
                  {t('Quotations.NoDataAvailable')}
                </Empty>
              )
            }}
            pagination={false}
            // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            // className="antd-custom-table"
            dataSource={details.map((item, index) => ({
              ...item,
              key: item.id,
              index
            }))}
            columns={[
              {
                title: 'م',
                dataIndex: 'id',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-index bold-font">
                      <span>{record.index + 1}</span>
                      {/* <span># {record.id}</span> */}
                    </div>
                  );
                }
              },
              {
                title: t('Quotations.Name'),
                dataIndex: 'name',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text desc-wrap">
                      <span>{record?.name}</span>
                    </div>
                  );
                }
              },
              {
                title: t('Quotations.description'),
                dataIndex: 'desc',
                render: (_, record) => {
                  const recordText = String(record.desc);
                  if (record?.desc) {
                    return (
                      <div className="row-cell row-text desc-wrap">
                        {recordText}
                      </div>
                    );
                  } else {
                    return '----';
                  }
                }
              },
              {
                title: t('Quotations.quantity'),
                dataIndex: 'quantity',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.quantity}</span>
                    </div>
                  );
                }
              },
              // {
              //   title: 'المستودع',
              //   dataIndex: 'warehouse',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record?.stock?.name}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: t('Quotations.Price'),
                dataIndex: 'price',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.price}</span>
                    </div>
                  );
                }
              },
              // {
              //   title: 'السعر',
              //   dataIndex: 'priceBeforeDiscount',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record.priceBeforeDiscount}</span>
              //       </div>
              //     );
              //   }
              // },
              // {
              //   title: 'الوصف',
              //   dataIndex: 'priceBeforeDiscount',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record.desc}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: t('Quotations.rebate'),
                dataIndex: 'discount',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{renderDiscountValue(record)}</span>
                    </div>
                  );
                }
              },
              // {
              //   title: 'سعر الخصم',
              //   dataIndex: 'discount',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record.discountPrice}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: t('Quotations.Tax'),
                dataIndex: 'tax',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.tax} %</span>
                    </div>
                  );
                }
              },
              {
                title: t('Quotations.TaxRate'),
                dataIndex: 'totalTax',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.totalTax}</span>
                    </div>
                  );
                }
              },
              {
                title: t('Quotations.Total'),
                dataIndex: 'discount',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.totalPrice}</span>
                    </div>
                  );
                }
              }
            ]}
            // loading={isLoading}
            // scroll={{ x: 400 }}
            footer={false}
          />
        </div>
      );
    }
    return null;
  };

  const renderExtraPrices = () => {
    const addPrices = selectedReceipt.addPrices;
    if (addPrices?.length > 0) {
      return (
        <div className="models-wrap">
          <div className="models-title bold-font">
            {t('Quotations.AdditionalPrices')} :
          </div>
          <Table
            className="invoice-extra-prices-table"
            bordered
            showHeader={false}
            locale={{
              emptyText: (
                <Empty description={false}>
                  {t('Quotations.AdditionalPrices')}
                </Empty>
              )
            }}
            pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            // className="antd-custom-table"
            dataSource={addPrices.map((item) => ({
              ...item,
              key: item.id
            }))}
            columns={[
              // {
              //   title: 'الكود',
              //   dataIndex: 'id',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-index bold-font">
              //         <span># {record.id}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: t('Quotations.description'),

                dataIndex: 'desc',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text extra-prices-cell">
                      <span>{record.desc}</span>
                    </div>
                  );
                }
              },
              {
                title: t('Quotations.Price'),

                dataIndex: 'price',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text extra-prices-cell">
                      <span>{record.price}</span>
                    </div>
                  );
                }
              }
            ]}
            // loading={isLoading}
            // scroll={{ x: 400 }}
            footer={false}
          />
        </div>
      );
    }
    return null;
  };

  const renderInovicePrices = () => {
    const {
      productPrice,
      discountValue,
      discountPrice,
      totalBeforeTax,
      taxPrice,
      totalPrice
    } = selectedReceipt;
    return (
      <div
        className="supplier-details-wrap"
        style={{
          display: 'grid'
          /*  width: 'fit-content' */
        }}
      >
        <div
          style={{
            marginBottom: 2,
            fontWeight: 'bold'
          }}
        ></div>
        <div
          style={{
            display: 'grid'
          }}
        >
          {productPrice && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">
                {t('Quotations.TotalUnitPriceAfterDiscount')} :
              </div>
              <div className="row-value">{productPrice}</div>
            </div>
          )}
          {discountPrice && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">{t('Quotations.rebate')} : </div>
              <div className="row-value">{discountPrice}</div>
            </div>
          )}
          {totalBeforeTax && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">
                {t('Quotations.TotalPriceBeforeVATAdded')} :
              </div>
              <div className="row-value">{totalBeforeTax}</div>
            </div>
          )}
          {taxPrice && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label"> {t('Invoices.taxPrice')} :</div>
              <div className="row-value">{taxPrice}</div>
            </div>
          )}
        </div>

        {totalPrice && (
          <div
            className="row-wrap"
            style={{
              ...rowWrapStyles,
              fontSize: '18px',
              color: '#000',
              fontWeight: 'bold'
            }}
          >
            <div className="row-label">
              {' '}
              {t('purchaseInvoices.priceAfterTax')} :{' '}
            </div>
            <div className="row-value">{totalPrice}</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="invoice-details-wrap"
      ref={ref}
      style={{
        padding: '28px 28px',
        paddingTop: 0,
        marginTop: '-16px',
        /*  direction: 'rtl', */
        display: 'grid',
        gap: '16px',
        position: 'relative'
      }}
    >
      {systemSettings?.package_id == 1 && (
        <WaterMark text={t('freeTrial.priceAfterTax')} />
      )}

      <div
        className="img-wrap"
        style={{
          display: 'grid',
          justifyContent: 'start',
          alignItems: 'center',
          // minWidth: '92px'
          minHeight: '100px'
        }}
      >
        {selectedReceipt?.setting?.image ? (
          <img
            className="system-logo"
            src={selectedReceipt?.setting.image}
            alt="logo"
            style={{
              // width: '92px',
              height: '60px'
            }}
          />
        ) : null}
      </div>

      <div
        className="invoice-heading-wrap"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto 1fr',
          gap: 14
        }}
      >
        <div className="logo-settings-wrap">
          <div>{renderUser(selectedReceipt?.setting, 1)}</div>
        </div>
        <div
          className="qr-code-label-wrap"
          style={{
            alignSelf: 'end',
            display: 'grid',
            justifyItems: 'center'
          }}
        >
          {/* <QRCode
            value={selectedReceipt?.qrString ? selectedReceipt.qrString : ''}
            width={112}
            height={112}
            renderAs="svg"
          /> */}
          <p
            style={{
              fontSize: 20,
              color: '#000',
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 12
            }}
          >
            <span>{t('Quotations.ShowPrice')} </span>
          </p>
        </div>
        <div
          className="buyer-supplier-wrap"
          style={{
            display: 'grid',
            justifyContent: 'end'
          }}
        >
          {selectedReceipt?.user && renderUser(selectedReceipt?.user, 2)}
          {selectedReceipt?.supplier &&
            renderUser(selectedReceipt?.supplier, 2)}

          <div
            style={{
              display: 'grid'
            }}
          >
            {selectedReceipt?.invoiceNumber && (
              <div className="row-wrap" style={rowWrapStyles}>
                <div className="row-label">{t('Quotations.OfferNo')} :</div>
                <div className="row-value">
                  {selectedReceipt?.invoiceNumber}
                </div>
              </div>
            )}
            {selectedReceipt?.dueDate && (
              <div className="row-wrap" style={rowWrapStyles}>
                <div className="row-label">{t('Quotations.IssueDate')}:</div>
                <div className="row-value">{selectedReceipt?.dueDate}</div>
              </div>
            )}
            {selectedReceipt?.expirationDate && (
              <div className="row-wrap" style={rowWrapStyles}>
                <div className="row-label">
                  {' '}
                  {t('Quotations.OfferExpiryDate')} :
                </div>
                <div className="row-value">
                  {selectedReceipt?.expirationDate}
                </div>
              </div>
            )}
            {selectedReceipt?.time && (
              <div className="row-wrap" style={rowWrapStyles}>
                <div className="row-label">
                  {t('Quotations.OfferReleaseTime')} :
                </div>
                <div className="row-value">{selectedReceipt?.time}</div>
              </div>
            )}
            {selectedReceipt?.commercialRecord && (
              <div className="row-wrap" style={rowWrapStyles}>
                <div className="row-label">
                  {t('Quotations.CommercialRegistrationNumber')} :
                </div>
                <div className="row-value">
                  {selectedReceipt?.commercialRecord}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {renderModels()}
      {renderExtraPrices()}
      {renderInovicePrices()}
      {selectedReceipt?.note && (
        <>
          <h3 className="note-modal-box">{t('Quotations.Notes')}</h3>
          <div className="note">
            <p>{selectedReceipt?.note}</p>
          </div>
        </>
      )}

      <div>
        <Descriptions
          title=""
          bordered
          column={1}
          labelStyle={{ fontWeight: 'bold', width: '150px' }}
        >
          {selectedReceipt?.setting?.invoiceSetting?.terms && (
            <Descriptions.Item label={t('SystemSettings.termsAndConditions')}>
              {selectedReceipt?.setting?.invoiceSetting?.terms_text
                ?.toString()
                .split('\r\n')
                .map((item) => (
                  <p>{item}</p>
                ))}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
      <div
        style={{
          textAlign: 'center',
          padding: '10px'
        }}
      >
        {selectedReceipt?.setting?.slogan ? selectedReceipt.setting.slogan : ''}
      </div>
    </div>
  );
});

export default ReceiptDetailsComponentProductsV2;
