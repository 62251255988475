import { useContext } from 'react';
import { ArchiveDocsProvider } from '../../../contexts/archive-contexts/ArchiveDocsProvider';
import { ArchiveNotesProvider } from '../../../contexts/archive-contexts/ArchiveNotesProvider';
import UserContext from '../../../contexts/user-context/UserProvider';
import ArchiveDocsPage from '../../../pages/archive-pages/archive-docs-page/ArchiveDocsPage';
import ArchiveNotesPage from '../../../pages/archive-pages/archive-notes-page/ArchiveNotesPage';
import protectMe from '../../../utils/protectMe';
// import routerLinks from '../routerLinks';
import { ArchiveCalendarProvider } from '../../../contexts/archive-contexts/ArchiveCalendarProvider';
// import ArchiveCalendarPage from '../../../pages/archive-pages/archive-calendar-page/ArchiveCalendarPage';
import ArchiveCalendarPage from '../../../pages/archive-pages/archive-calendar-page/ArchiveCalendarPage'
import Links from '../Links';

const ArchiveRoutes = () => {
  const { loggedIn, user } = useContext(UserContext);
  const routerLinks = Links();
  return [
    protectMe(
      routerLinks.archiveNotesPage,
      <ArchiveNotesProvider>
        <ArchiveNotesPage />
      </ArchiveNotesProvider>,
      1,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.archiveCalendarPage,
      <ArchiveCalendarProvider>
        <ArchiveCalendarPage />
      </ArchiveCalendarProvider>,
      2,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.archiveDocsPage,
      <ArchiveDocsProvider>
        <ArchiveDocsPage />
      </ArchiveDocsProvider>,
      3,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    )
    //
    //
  ];
};

export default ArchiveRoutes;
