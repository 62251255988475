import HomeIcon from '../../common/icons/HomeIcon';
import AnalysisIcon from '../../common/icons/AnalysisIcon';
import AccountingNewIcon from '../../common/icons/AccountingNewIcon';
import storeRouterLinks from '../app/store-routes/storeRouterLinks';
import Links from '../app/Links';
import accountingRouterLinks from '../app/accounting-routes/accountingRouterLinks';
const routerLinks = Links();
const accountantLinks = (t) => {
  return [
    {
      id: 1,
      to: routerLinks.homePage,
      name: t('AppBar.Services.homePage'),
      isLink: true,
      icon: (color) => (color ? <HomeIcon color={color} /> : <HomeIcon />),
      subMenu: [],
      visibleSubMenu: false
    },

    {
      id: 5,
      to: accountingRouterLinks.accountingTreePage,
      name: t('AppBar.Services.accountingPage.title'),
      isLink: true,
      icon: (color) =>
        color ? <AccountingNewIcon color={color} /> : <AccountingNewIcon />,
      visibleSubMenu: false,
      subMenu: []
      // subMenu: [
      //   {
      //     id: 1,
      //     to: routerLinks.accountsPage,
      //     name: 'الحسابات'
      //   },
      //   {
      //     id: 2,
      //     to: routerLinks.accountsTransactionsPage,
      //     name: 'المعاملات'
      //   },
      //   {
      //     id: 3,
      //     to: routerLinks.accountsRevenuesPage,
      //     name: 'الايرادات'
      //   },
      //   {
      //     id: 4,
      //     to: routerLinks.accountsExpensesPage,
      //     name: 'المصروفات'
      //   }
      // ]
    },
    {
      id: 123847823,
      to: storeRouterLinks?.priceOfferRouteNew,
      name: t('AppBar.Services.accountingPage.priceOffer'),
      isLink: true,
      icon: (color) =>
        color ? <AnalysisIcon color={color} /> : <AnalysisIcon />,
      subMenu: [],
      visibleSubMenu: false
    },
    {
      id: 91,
      to: routerLinks.analysisPage,
      name: t('AppBar.Products.analysisPage.title'),
      icon: (color) =>
        color ? <AnalysisIcon color={color} /> : <AnalysisIcon />,
      subMenu: [
        {
          id: 888,
          name: t(
            'AppBar.Products.analysisPage.outgoingPurchaseInvoiceReports'
          ),
          to: storeRouterLinks.outgoingPurchaseInvoiceReports
        },
        {
          id: 6765448,
          name: t(
            'AppBar.Products.analysisPage.incomingPurchaseInvoiceReports'
          ),
          to: storeRouterLinks.incomingPurchaseInvoiceReports
        },
        {
          id: 772,
          name: t('AppBar.Products.analysisPage.taxInvoiceReportsPage'),
          to: storeRouterLinks.taxInvoiceReportsPage
        }
      ],
      visibleSubMenu: false
    }
  ];
};

export default accountantLinks;
