/* eslint-disable eqeqeq */
import { Popconfirm, Tooltip } from 'antd';
import toggleUserStatusApi from '../../../apis/settings-apis/system-settings-api/toggleUserStatusApi';
import deleteUserApi from '../../../apis/settings-apis/users-apis/deleteUserApi';
import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import roles from '../../../roles';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import { useTranslation } from 'react-i18next';

const statusTypes = {
  active: 1,
  unactive: 0
};

const TableColumns = (
  allFetchedUsers,
  setModalOpened,
  setFetchCount,
  setSelectedUser,
  setIsLoading,
  user,
  customApiRequest
) => {
  const { t } = useTranslation();

  const handleEditClick = (record) => {
    const found = allFetchedUsers.find((place) => place.id === record.id);
    if (found) {
      setSelectedUser({ ...found });
      setModalOpened(true);
    }
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteUserApi(user?.token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Messages.operation'),
          message: t('Messages.DeletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Messages.ErrorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Messages.TryAgainLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Messages.ErrorOccurred'),
        message: t('Messages.TryAgainLater')
      });
    }
  };

  const handleToggleActive = (record) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('admin_id', record?.id);
    customApiRequest(
      toggleUserStatusApi(user?.token, formData),
      (res) => {
        setIsLoading(false);
        setFetchCount((prev) => prev + 1);
      },
      (error) => {
        setIsLoading(false);
      }
    );
  };
  const renderUserStatusBtn = (record) => {
    if (record?.id == user?.id) {
      return '---';
    } else {
      if (record?.status == statusTypes?.active)
        return (
          <button
            onClick={() => {
              handleToggleActive(record);
            }}
            className="active toggle-user-status-btn"
          >
            {t('Users.Active')}
          </button>
        );
      else if (record?.status == statusTypes?.unactive) {
        return (
          <button
            onClick={() => {
              handleToggleActive(record);
            }}
            className="not-active toggle-user-status-btn"
          >
            {t('Users.Inactive')}
          </button>
        );
      }
    }
  };

  const renderActions = (obj) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(obj)}>
          <EditIcon />
          {t('Notes.edit')}
        </div>
        <Popconfirm
          title={t('Messages.areyouSureToDelete')}
          onConfirm={() => handleDeleteRequest(obj.id)}
          okText={t('Messages.yes')}
          cancelText={t('Messages.no')}
        >
          <Tooltip title={t('Users.Delete')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  const renderUserRole = (role) => {
    if (role) {
      const foundRole = roles.find((r) => String(r.value) === String(role));
      if (foundRole) {
        return foundRole.title;
      }
    }
    return '-----';
  };

  return [
    {
      title: t('Users.UserCode'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('Users.Name'),
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.name ? record.name : '----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('Users.PhoneNumber'),
      dataIndex: 'phone',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.phone ? record.phone : '-----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('Users.Email'),
      dataIndex: 'email',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.email ? record.email : '-----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('Users.Status'),
      dataIndex: 'userStatus',
      render: (_, record) => {
        return renderUserStatusBtn(record);
      }
    },
    {
      title: t('Users.UserPermission'),
      dataIndex: 'role',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{renderUserRole(record.role)}</span>
          </div>
        );
      }
    },
    {
      title: t('Users.UserSettings'),
      // dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default TableColumns;
