/* eslint-disable eqeqeq */
import { Descriptions, Empty, Table } from 'antd';
import QRCode from 'qrcode.react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import WaterMark from '../../../common/water-mark/WaterMark';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import './TaxSalesInvoices.scss';

const TaxSalesInvoiceCompnent = React.forwardRef((props, ref) => {
  const { selectedReceipt } = props;
  const { t } = useTranslation();

  const systemSettings = useSystemSettings();

  const renderInvoiceHeading = () => {
    if (selectedReceipt?.type == 2) {
      return <p>{t('purchaseInvoices.returnedInvoice')}</p>;
    } else if (selectedReceipt?.type == 1) {
      return <p>{t('Quotations.TaxSaleInvoice')}</p>;
    }
  };

  const renderDiscountValue = (record) => {
    if (record.discountType == '1') {
      // fixed discount price
      return record.discountPrice;
    } else if (record.discountType == '2') {
      // percentage discount
      return `${record.discountPrice}`;
    } else {
      // no discount
      return t('SalesInvoices.WithoutDiscount');
    }
  };

  const renderTitle = (invoice_type) => {
    if (invoice_type === 1) {
      return 'المشترى : ';
    } else if (invoice_type === 2) {
      return 'المورد : ';
    }
    return '';
  };

  const renderUser = (obj, type) => {
    const { name, address, addressCode, commercialRecord, tax_number } = obj;
    return (
      <div className="supplier-details-wrap">
        <div
          className="supplier-details-wrap-billed"
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            gap: 2
          }}
        >
          <div className="supplier-details-wrap-billed-from">
            <div style={{ marginBottom: 20 }}>
              <span
                style={{
                  backgroundColor: '#615a5a',
                  color: '#fff',
                  fontSize: 16,
                  fontWeight: 'bold',
                  padding: '15px'
                }}
              >
                {' '}
                {t('Quotations.Postpaidby')}{' '}
              </span>
              {name && (
                <span
                  style={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    margin: '0px 20px'
                  }}
                >
                  {name}
                </span>
              )}
            </div>
            <Descriptions title="" column={1}>
              {address && (
                <Descriptions.Item label={t('Quotations.Address')}>
                  {address}
                </Descriptions.Item>
              )}
              {tax_number && (
                <Descriptions.Item label={t('Quotations.VATNumber')}>
                  {tax_number}
                </Descriptions.Item>
              )}
              {commercialRecord && (
                <Descriptions.Item
                  label={t('Quotations.CommercialRegistrationNumber')}
                >
                  {commercialRecord}
                </Descriptions.Item>
              )}
            </Descriptions>
          </div>
          <div className="supplier-details-wrap-billed-from">
            <div style={{ marginBottom: 20 }}>
              <span
                style={{
                  backgroundColor: '#615a5a',
                  color: '#fff',
                  fontSize: 16,
                  fontWeight: 'bold',
                  padding: '15px'
                }}
              >
                {t('Quotations.PostpaidTo')}
              </span>
              {selectedReceipt?.user?.name && (
                <span
                  style={{
                    fontSize: 16,
                    fontWeight: 'bold',
                    margin: '0px 20px'
                  }}
                >
                  {selectedReceipt?.user?.name}
                </span>
              )}
            </div>
            <Descriptions title="" column={1}>
              {selectedReceipt?.user?.address && (
                <Descriptions.Item label={t('Quotations.Address')}>
                  {selectedReceipt?.user?.address}
                </Descriptions.Item>
              )}
              {selectedReceipt?.user?.taxNumber && (
                <Descriptions.Item label={t('Quotations.VATNumber')}>
                  {selectedReceipt?.user?.taxNumber}
                </Descriptions.Item>
              )}
            </Descriptions>
          </div>
        </div>
      </div>
    );
  };

  // توصيف الخدمة
  const renderModels = () => {
    const products = selectedReceipt.products;
    const hasDiscounts = selectedReceipt.products.some(
      (product) => product.discountPrice && +product.discountPrice > 0
    );
    if (products?.length > 0) {
      return (
        <div className="models-wrap pagebreak">
          <div className="models-title bold-font">
            {t('Quotations.ServiceDescription')} :
          </div>
          <Table
            bordered
            locale={{
              emptyText: (
                <Empty description={false}>
                  {t('Messages.noDataAvailable')}
                </Empty>
              )
            }}
            pagination={false}
            // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            // className="antd-custom-table"
            dataSource={products.map((item, index) => ({
              ...item,
              key: item.id,
              index
            }))}
            columns={[
              {
                title: t('Quotations.M'),
                dataIndex: 'id',
                width: 100,
                render: (_, record) => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                        // height: '100px'
                      }}
                      className="row-cell row-index bold-font"
                    >
                      <span>{record.index + 1}</span>
                      {/* <span># {record.id}</span> */}
                    </div>
                  );
                }
              },
              {
                title: t('Quotations.description'),
                dataIndex: 'desc',
                width: 100,
                render: (_, record) => {
                  const recordText = String(record.desc);
                  // const wordsCount = countWords(String(record.desc));
                  // if (record.desc && wordsCount > 0) {
                  //   if (wordsCount > 3) {
                  //     return (
                  //       <Popover title="اقرأ المزيد" content={record.desc}>
                  //         <Button size="large" type="text">
                  //           <p className="has-read-more">
                  //             <span>{trimWords(recordText, 4)}</span>
                  //             <ReadOutlined
                  //               className="read-more-book"
                  //               style={{ margin: '0 4px' }}
                  //             />
                  //           </p>
                  //         </Button>
                  //       </Popover>
                  //     );
                  //   } else
                  //     return (
                  //       <div className="row-cell row-text">{recordText}</div>
                  //     );
                  // }
                  if (record?.desc) {
                    return (
                      <div className="row-cell row-text">{recordText}</div>
                    );
                  } else {
                    return '----';
                  }
                }
              },
              {
                title: t('Quotations.quantity'),
                dataIndex: 'quantity',
                width: 100,
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>
                        {selectedReceipt.type == 2 &&
                          record.quantity > 0 &&
                          '-'}
                        {record.quantity}
                      </span>
                    </div>
                  );
                }
              },

              {
                title: t('Quotations.UnitPrice'),
                dataIndex: 'price',
                width: 100,
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.price}</span>
                    </div>
                  );
                }
              },
              ...(hasDiscounts
                ? [
                    {
                      title: t('Quotations.rebate'),
                      dataIndex: 'discount',
                      render: (_, record) => (
                        <div className="row-cell row-text">
                          {renderDiscountValue(record)}
                        </div>
                      )
                    }
                  ]
                : []),

              {
                title: t('Quotations.TotalBeforeTax'),

                width: 100,
                dataIndex: 'priceBeforeDiscount',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>
                        {selectedReceipt.type == 2 &&
                          record.priceBeforeDiscount > 0 &&
                          '-'}
                        {record.priceBeforeDiscount}
                      </span>
                    </div>
                  );
                }
              },
              {
                title: t('Quotations.Tax'),
                width: 100,
                dataIndex: 'tax',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.tax} %</span>
                    </div>
                  );
                }
              },
              {
                title: t('Quotations.TaxRate'),
                width: 100,
                dataIndex: 'totalTax',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>
                        {selectedReceipt.type == 2 &&
                          record.totalTax > 0 &&
                          '-'}
                        {record.totalTax}
                      </span>
                    </div>
                  );
                }
              },
              {
                title: t('Quotations.Total'),
                width: 100,
                dataIndex: 'discount',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>
                        {selectedReceipt.type == 2 &&
                          record.totalPrice > 0 &&
                          '-'}
                        {record.totalPrice}
                      </span>
                    </div>
                  );
                }
              }
            ]}
            // loading={isLoading}
            // scroll={{ x: 400 }}
            footer={false}
          />
        </div>
      );
    }
    return null;
  };

  const renderInvoicesNotes = () => {
    const { note } = selectedReceipt;
    return (
      <>
        <p style={{ marginBottom: 4, fontSize: 16, fontWeight: 'bold' }}>
          {t('Quotations.Notes')}
        </p>
        {note ? (
          <div>
            <p
              style={{
                padding: '8px 12px',
                borderRadius: '4px',
                border: '1px solid #888'
              }}
            >
              {selectedReceipt.note}
            </p>
          </div>
        ) : (
          <div>{t('Quotations.notFoundNotes')}</div>
        )}
      </>
    );
  };

  const renderInovicePrices = () => {
    const {
      productPrice,
      discountType,
      discountValue,
      additionPrice,
      totalBeforeTax,
      taxPrice,
      totalPrice,
      type
    } = selectedReceipt;
    return (
      <div className="tax-supplier-details-wrap" style={{ margintop: 20 }}>
        <Descriptions title="" column={1}>
          {totalBeforeTax && (
            <Descriptions.Item label={t('Quotations.TotalPriceBeforeVATAdded')}>
              {type == 2 && totalBeforeTax > 0 && '-'}
              {totalBeforeTax}
            </Descriptions.Item>
          )}
          {taxPrice && (
            <Descriptions.Item label={t('Quotations.TaxRate')}>
              {type == 2 && taxPrice > 0 && '-'}
              {taxPrice}
            </Descriptions.Item>
          )}
          {totalPrice && (
            <Descriptions.Item label={t('Quotations.TotalPriceInclusiveOfTax')}>
              {type == 2 && totalPrice > 0 && '-'}
              {totalPrice}
            </Descriptions.Item>
          )}
          <Descriptions.Item
            className="tax-supplier-details-wrap-total"
            style={{
              fontSize: '18px',
              color: '#000',
              fontWeight: 'bold'
            }}
            label={t('Quotations.AmountDue')}
          >
            {type == 2 && totalPrice > 0 && '-'}
            {totalPrice}
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  };

  const renderPaymentType = () => {
    const { paymentType } = selectedReceipt;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 20,
          width: ' fit-content',
          minWidth: '200px',
          padding: '20px',
          borderRadius: 10,
          backgroundColor: '#eee'
        }}
      >
        <h3>{t('Invoices.PaymentMethod')}</h3>
        <b> : </b>
        <h3>
          {paymentType == 1 && <span>{t('Invoices.cash')}</span>}
          {paymentType == 2 && <span>{t('Invoices.postpaid')}</span>}
          {paymentType == 3 && <span>{t('Invoices.visa')}</span>}
          {paymentType == 4 && <span>{t('Invoices.multiplePayment')}</span>}
          {paymentType == 5 && <span>{t('Invoices.bankTransfer')}</span>}
        </h3>
      </div>
    );
  };

  return (
    <div
      className="invoice-details-wrap"
      ref={ref}
      style={{ padding: '12px 12px', position: 'relative' }}
    >
      {systemSettings?.package_id == 1 && (
        <WaterMark text={t('Quotations.freeTrial')} />
      )}
      <div
        className="invoice-details-wrap-header"
        style={{
          display: 'flex',
          gap: '22px',
          width: '100%',
          justifyContent: 'space-between'
        }}
      >
        <div
          className="img-wrap"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 30,
            borderTop: '15px solid #cd8a0e'
          }}
        >
          <div>
            <h3 style={{ color: '#cd8a0e', fontSize: 20, fontWeight: 'bold' }}>
              {selectedReceipt?.setting.name}
            </h3>
          </div>
          {selectedReceipt?.setting?.image ? (
            <img
              className="system-logo"
              src={selectedReceipt?.setting.image}
              alt="logo"
              style={{
                // minWidth: '92px',
                // minHeight: '120px',
                paddingTop: 20,
                width: '120px',
                height: '120px'
              }}
            />
          ) : null}
        </div>

        <div
          className="invoices-data"
          style={{
            display: 'flex',
            gap: '22px',
            alignItems: 'end',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          {selectedReceipt?.is_return == 1 ? (
            <p> {t('Quotations.returnedInvoice')}</p>
          ) : null}
          <span
            style={{
              backgroundColor: '#615a5a',
              color: '#fff',
              fontSize: 20,
              fontWeight: 'bold',
              width: '100%',
              textAlign: 'center',
              padding: '15px'
            }}
          >
            {renderInvoiceHeading()}
          </span>

          <div>
            <Descriptions
              className="invoice-details-wrap-header-table"
              title=""
              column={1}
            >
              {selectedReceipt?.invoiceNumber && (
                <Descriptions.Item label={t('Quotations.InvoiceNumber')}>
                  {selectedReceipt.invoiceNumber}
                </Descriptions.Item>
              )}
              {selectedReceipt?.desc && (
                <Descriptions.Item label="وصف الفاتورة">
                  {selectedReceipt.desc}
                </Descriptions.Item>
              )}
              {selectedReceipt?.date && (
                <Descriptions.Item label={t('Quotations.InvoiceIssueDate')}>
                  {selectedReceipt.date}
                </Descriptions.Item>
              )}
              {selectedReceipt?.dueDate && (
                <Descriptions.Item
                  label={t('Quotations.Dateofterminationofservice')}
                >
                  {selectedReceipt.dueDate}
                </Descriptions.Item>
              )}
              {selectedReceipt?.address && (
                <Descriptions.Item label={t('Quotations.Address')}>
                  {selectedReceipt?.address}
                </Descriptions.Item>
              )}
            </Descriptions>
          </div>
        </div>
      </div>
      <div className="supp-buyer-wrap">
        {selectedReceipt?.setting && (
          <div>
            <div>{renderUser(selectedReceipt?.setting, 1)}</div>
            {/* <span></span> */}
          </div>
        )}

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 2
          }}
        >
          {selectedReceipt?.supplier &&
            renderUser(selectedReceipt?.supplier, 2)}
          <div>
            <div
              style={{
                marginBottom: 2,
                opacity: 0,
                visibility: 'hidden'
              }}
            >
              {'--'}
            </div>
          </div>
        </div>
      </div>

      {renderModels()}

      <div
        className="tax-invoices"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div className="tax-invoices-note">
          {/* Notes */}
          {renderInvoicesNotes()}
        </div>

        <div className="tax-invoices-prices">{renderInovicePrices()}</div>
      </div>

      {/* QR Code */}
      <div
        className="qr-code"
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '40px 0px 20px'
        }}
      >
        <div className="qr-code-wrap">
          <QRCode
            value={selectedReceipt?.qrString ? selectedReceipt.qrString : ''}
            width={140}
            height={140}
            renderAs="svg"
          />
        </div>
      </div>

      <div
        className="paymentType-invoices"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 20,
          margin: '20px 0px'
        }}
      >
        {renderPaymentType()}
      </div>

      <div>
        <Descriptions
          title=""
          bordered
          column={1}
          labelStyle={{ fontWeight: 'bold', width: '150px' }}
        >
          {selectedReceipt?.setting?.invoiceSetting?.terms && (
            <Descriptions.Item label={t('SystemSettings.termsAndConditions')}>
              {selectedReceipt?.setting?.invoiceSetting?.terms_text
                ?.toString()
                .split('\r\n')
                .map((item) => (
                  <p>{item}</p>
                ))}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>

      <div
        style={{
          textAlign: 'center',
          padding: '10px'
        }}
      >
        {selectedReceipt?.setting?.slogan ? selectedReceipt.setting.slogan : ''}
      </div>
    </div>
  );
});

export default TaxSalesInvoiceCompnent;
