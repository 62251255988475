import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Modal } from 'antd';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// import productFormatFile from '../../../assets/files/productFormat.xlsx';
import { CloudDownloadOutlined } from '@ant-design/icons';
import importExcelApi from '../../../apis/store-apis/products/importExcelApi';
import productFormatFile from '../../../assets/files/productFormat.xlsx';
import FileInput from '../../../common/file-input/FileInput';
import LoadingModal from '../../../common/loading-modal/LoadingModal';
import StoreNewProductModalContext from '../../../contexts/store-new-product-context/StoreNewProductProvider';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import { useTranslation } from 'react-i18next';

const ExcelModal = ({ modalOpened, setModalOpened }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const schema = Yup.object().shape({
    file: Yup.mixed().required(
      t('Inventory.Products.pleaseSelectProductProfile')
    ),
    type: Yup.string().required(t('Inventory.Products.pleaseSelectProductType'))
  });

  const { setFetchCount } = useContext(StoreNewProductModalContext);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register,
    unregister,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      file: null,
      type: '1'
    }
  });
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    const formData = new FormData();

    if (data?.type) formData.append('type', data.type);
    if (data.file?.length > 0) formData.append('file', data.file[0]);
    setIsLoading(true);
    setIsSubmittingForm(true);
    customApiRequest(
      importExcelApi(user?.token, formData, '/Product/importExcel'),
      (res) => {
        setIsLoading(false);
        setIsSubmittingForm(false);
        if (res?.data?.status === 1) {
          setModalOpened(false);
          setFetchCount((prevState) => prevState + 1);
          successNotification({
            title: t('Inventory.Products.addedSuccessfully'),
            message: t('Inventory.Products.productAddedSuccessfully')
          });
        } else {
          errorNotification({
            title: t('Inventory.Products.errorOccurred'),
            message: res?.data?.message
              ? res.data.message
              : t('Inventory.Products.pleaseTryLater')
          });
        }
      },
      (error) => {
        setIsSubmittingForm(false);
        setIsLoading(false);
        errorNotification({
          title: t('Inventory.Products.errorOccurred'),
          message:
            error?.response?.data?.message ||
            t('Inventory.Products.pleaseTryLater')
        });
      }
    );
  };

  const [form] = Form.useForm();
  return (
    <Modal
      transitionName=""
      className="shared-custom-modal"
      width="90%"
      style={{ maxWidth: '542px' }}
      title={t('Inventory.Products.uploadProductsFile')}
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
      }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      <div className="excel-download-btns-wrap">
        <a className="excel-download-btn" href={productFormatFile}>
          <CloudDownloadOutlined />
          <span className="label-span">
            {t('Inventory.Products.downloadProductDefinitionFile')}
          </span>
        </a>

        {/* <a className="excel-download-btn" href={productMaterialFormatFile}>
          <CloudDownloadOutlined />
          <span className="label-span">تحميل ملف منتج بالمكونات التعريفى </span>
        </a> */}
      </div>

      <Form
        className="store-new-product-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body" style={{ marginTop: 22 }}>
          {/* <AntdRadioGroup
            name="type"
            className="form-radio-group"
            control={control}
            label="اختار نوع السلعة"
            validateStatus={errors?.type ? 'error' : ''}
            errorMsg={errors?.type?.message}
            onChange={() => setValue('model_id', '')}
            radios={[
              {
                title: 'منتجات',
                value: '1'
              },
              {
                title: 'مكونات منتجات',
                value: '2'
              }
            ]}
            isRadioButton
          /> */}
          <FileInput
            name="file"
            // label="ارفع ملف الاسئلة المطلوبة"
            label={null}
            // accept="image/png, image/jpg, image/jpeg, image/gif, .pdf"
            accept=".xlsx"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText={t('Inventory.Products.clickHereToDownloadFile')}
            className="product-images-dropzone"
            //  dropzoneUrls={
            //    selectedCategory?.image ? [{ url: selectedCategory.image }] : []
            //  }
            canDelete={false}
          />
          {errors?.file && (
            <p className="error-p">
              {errors?.file?.message && errors.file.message}
            </p>
          )}
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isSubmittingForm}
          >
            {t('Inventory.Products.uploadNow')}
          </Button>
        </div>
      </Form>

      {isLoading && <LoadingModal />}
    </Modal>
  );
};

export default ExcelModal;
