/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import getSystemSettingsApi from '../apis/settings-apis/system-settings-api/getSystemSettingsApi';
import UserContext from '../contexts/user-context/UserProvider';
// import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';
const useSystemSettings = () => {
  const [systemSettings, setSystemSettings] = useState(null);
  const { user } = useContext(UserContext);

  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;
    if (isMounted && user) {
      customApiRequest(
        getSystemSettingsApi(user?.token),
        (res) => {
          if (!isMounted) return;
          if (!res?.data?.data) return;
          setSystemSettings(res.data.data);
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, [user]);

  return systemSettings;
};

export default useSystemSettings;
