/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import UserIcon from '../../common/icons/UserIcon';
import PhoneIcon from '../../common/icons/PhoneIcon';
import EmailIcon from '../../common/icons/EmailIcon';
import LocationIcon from '../../common/icons/LocationIcon';
import CustomAntdDatePicker from '../../common/antd-form-components/CustomAntdDatePicker';
// import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { useContext } from 'react';
import { useEffect } from 'react';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import UserContext from '../../contexts/user-context/UserProvider';
import addNewSupplierApi from '../../apis/store-apis/suppliers/addNewSupplierApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import useClients from '../../custom-hooks/useClients';
import addNewCustomerPaymentApi from '../../apis/store-apis/customer-payments/addNewCustomerPaymentApi';
import moment from 'moment';
import CustomersPaymentsContext from '../../contexts/customers-payments-context/CustomersPaymentsContext';
import getDeferredInvoicesApi from '../../apis/store-apis/customer-payments/getDeferredInvoicesApi';
import useSuppliers from '../../custom-hooks/useSuppliers';
import { useTranslation } from 'react-i18next';
const TableForm = ({ setAllSuppliersFetchCount }) => {
  const { user } = React.useContext(UserContext);
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    user_id: Yup.string().required(t('SupplierPays.enterSupplierName')),
    amount: Yup.string()
      .required(t('SupplierPays.enterQuantity'))
      .matches(/^(\d+)?(\.\d+)?$/, t('SupplierPays.onlyNumbersAllowed')),
    date: Yup.string().required(t('SupplierPays.enterDate'))
    // invoice_type: Yup.string().required('ادخل نوع الفاتورة')
  });
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedCustomerPayment,
    setSelectedCustomerPaymentId,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(CustomersPaymentsContext);
  const { allSuppliers } = useSuppliers();

  const [deferredInvoices, setDeferredInvoices] = useState([]);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      user_id: '',
      invoice_type: '',
      amount: '',
      date: '',
      invoice_id: ''
    }
  });
  const customApiRequest = useCustomApiRequest();

  // handle initial values
  // useEffect(() => {
  //   if (selectedSupplier) {
  //     if (selectedSupplier?.name) {
  //       setValue('name', selectedSupplier.name);
  //     }
  //     if (selectedSupplier?.phone) {
  //       setValue('phone', selectedSupplier.phone);
  //     }
  //   }
  // }, [selectedSupplier]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getDeferredInvoicesApi(
          user?.token,
          watch('user_id'),
          1,
          watch('invoice_type')
        ),
        (res) => {
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data?.length) {
            const data = res.data.data;
            setDeferredInvoices(data);
          }
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [watch('user_id'), watch('invoice_type')]);

  const onSubmit = async (data) => {
    const mappedData = {};
    mappedData.type = 1;
    if (data.user_id) mappedData.user_id = data.user_id;
    if (data.amount) mappedData.amount = data.amount;
    if (data.notes) mappedData.notes = data.notes;
    if (data.date) mappedData.date = moment(data.date).format('MM/DD/YYYY');
    if (data.invoice_id) mappedData.invoice_id = data.invoice_id;
    if (data.invoice_type) mappedData.invoice_type = data.invoice_type;

    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedCustomerPayment) {
      mappedData.id = selectedCustomerPayment.id;
      customApiRequest(
        addNewCustomerPaymentApi(user?.token, mappedData, true),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (res?.status === 200 && res?.data?.data) {
            setIsLoading(false);
            setModalOpened(false);
            setSelectedCustomerPaymentId(res?.data?.data?.id);
            setFetchCount((prevState) => prevState + 1);
            if (setAllSuppliersFetchCount)
              setAllSuppliersFetchCount((prev) => prev + 1);
            successNotification({
              title: t('SupplierPays.operationCompletedSuccessfully'),
              message: t('SupplierPays.supplierUpatedSuccessfully')
            });
          } else {
            setIsLoading(false);
            errorNotification({
              title: t('SupplierPays.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('SupplierPays.tryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('SupplierPays.errorOccurred'),
            message:
              error?.response?.data?.message || t('SupplierPays.tryLater')
          });
        }
      );
    } else {
      customApiRequest(
        addNewCustomerPaymentApi(user?.token, mappedData),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setSelectedCustomerPaymentId(res?.data?.data?.id);
            setFetchCount((prevState) => prevState + 1);
            if (setAllSuppliersFetchCount)
              setAllSuppliersFetchCount((prev) => prev + 1);
            successNotification({
              title: t('SupplierPays.operationCompletedSuccessfully'),
              message: t('SupplierPays.supplierAddedSuccessfully')
            });
          } else {
            errorNotification({
              title: t('SupplierPays.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('SupplierPays.tryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('SupplierPays.errorOccurred'),
            message:
              error?.response?.data?.message || t('SupplierPays.tryLater')
          });
        }
      );
    }
  };

  const [form] = Form.useForm();

  return (
    <Form
      className="customers-payments-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="select-label-wrap">
          <p className="label-p">{t('SupplierPays.supplierName')}</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              disabled={selectedCustomerPayment ? true : false}
              name="user_id"
              errorMsg={errors?.user_id?.message}
              validateStatus={errors?.user_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder={t('SupplierPays.supplierName')}
              options={
                allSuppliers?.length > 0 &&
                allSuppliers.map((role) => ({
                  title: role.name,
                  value: String(role.id)
                }))
              }
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="customers-payments-form"
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('SupplierPays.amount')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="amount"
              type="text"
              placeholder={t('SupplierPays.amountPlaceholder')}
              errorMsg={errors?.amount?.message}
              validateStatus={errors?.amount ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="date-picker-wrap">
          <p className="label-p">{t('SupplierPays.date')}</p>
          <CustomAntdDatePicker
            // disablePast
            control={control}
            name="date"
            className="receipt-date-picker"
            formClassName="customers-payments-form"
            // disablePast={true}
          />
          {errors?.date && <p className="error-p">{errors.date.message}</p>}
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('SupplierPays.notes')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="notes"
              rows={8}
              placeholder={t('SupplierPays.notesPlaceholder')}
              errorMsg={errors?.notes?.message}
              validateStatus={errors?.notes ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        {/* <div className="select-label-wrap">
          <p className="label-p"> نوع الفاتورة </p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="invoice_type"
              errorMsg={errors?.invoice_type?.message}
              validateStatus={errors?.invoice_type ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="نوع الفاتورة "
              options={[
                {
                  title: 'فاتورة شراء',
                  value: '2'
                },
                {
                  title: 'فاتورة بيع',
                  value: '1'
                }
              ]}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="customers-payments-form"
            />
          </div>
        </div> */}
        {watch('user_id') && (
          <div className="select-label-wrap">
            <p className="label-p">{t('SupplierPays.clientInvoice')}</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name="invoice_id"
                errorMsg={errors?.invoice_id?.message}
                validateStatus={errors?.invoice_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder={t('SupplierPays.clientInvoice')}
                options={
                  deferredInvoices?.length > 0 &&
                  deferredInvoices.map((role) => ({
                    title: `${t('SupplierPays.invoiceNumber')} ${
                      role.invoiceNumber
                    } [ ${t('SupplierPays.total')} : ${role.totalPrice} ] [ ${t(
                      'SupplierPays.Residual'
                    )}  : ${role.rest ? role?.rest : 0} ]`,
                    value: String(role.id)
                  }))
                }
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="customers-payments-form"
              />
            </div>
          </div>
        )}

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {t('SupplierPays.add')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
