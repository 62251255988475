import axios from 'axios';

const updatePosApi = async (token, values) => {
  const formData = new FormData()
  const keys = Object.keys(values)
  keys.forEach(key => {
    formData.append(key,values[key])
  });
  try {
    const res = await axios.post('/Pos/update', formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default updatePosApi;
