import axios from 'axios';

const createPayments = async (token, values, edit = false) => {
  try {
    let url;
    if (!edit) url = 'receipts/create';
    else url = '/receipts/update';

    const res = await axios.post(url, values, {
      baseURL: process.env.REACT_APP_BASE_URL_POS,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default createPayments;
