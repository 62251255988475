import { Modal } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AccountingContext from '../../../contexts/accounting-context/AccountingContext';
import { getLeafType } from '../../../custom-hooks/getLeafType';
import AccountingForm from './AccountingForm';

const PageModal = ({ typeOfAccount }) => {
  const {
    accountingModalOpened,
    setAccountingModalOpened,
    setSelectedAccountNode,
    selectedAccountNode,
    editMode
  } = useContext(AccountingContext);

  let leafType = getLeafType(selectedAccountNode?.id) || typeOfAccount;
  const { t } = useTranslation();

  if (leafType === 0) {
    return <></>;
  } else {
    return (
      <Modal
        transitionName=""
        className="work-places-modal shared-custom-modal"
        width="90%"
        style={{ maxWidth: '742px' }}
        title={editMode ? t('Accounts.EditAccount') : t('Accounts.AddAccount')}
        visible={accountingModalOpened}
        onOk={() => {
          setAccountingModalOpened(false);
          setSelectedAccountNode(null);
        }}
        onCancel={() => {
          setAccountingModalOpened(false);
          setSelectedAccountNode(null);
        }}
        footer={false}
      >
        <AccountingForm />
      </Modal>
    );
  }
};

export default PageModal;
