import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import PageTable from './PageTable';
import PageModal from './PageModal';
import StoreProductsIngredientsContext from '../../../contexts/store-products-ingredients-context/StoreProductsIngredientsProvider';
import excelImg from '../../../assets/imgs/icons/excel-btn.png';

import './StoreIngredientsPage.scss';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
const StoreIngredientsPage = () => {
  const { modalOpened, setModalOpened, setSelectedIngredient } = useContext(
    StoreProductsIngredientsContext
  );
  const systemSettings = useSystemSettings();
  const { t } = useTranslation();
  return (
    <div className="store-ingredients-page">
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">{t('Ingredient.title')}</h2>
          <p>{t('Ingredient.subTitle')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('AppBar.Services.storePage.storeProductsIngredientsPage')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Services.storePage.title')}
            title3={t('AppBar.Services.storePage.storeProductsIngredientsPage')}
            link3={'/store/services'}
          />
        </div>
        <SearchNotificationsProfile />
      </div>

      <div
        className="add-export-wrapper"
        style={{
          marginTop: 32,
          display: 'flex',
          gap: 32,
          alignItems: 'center'
        }}
      >
        <div className="add-section">
          {/* <div className="section-text">
          <p className="bold-font">وحدات القياس</p>
        </div> */}
          <div
            className="add-account-btn"
            onClick={() => {
              // if selected unit then reset it
              // open the unit modal
              setSelectedIngredient(null);
              setModalOpened(true);
            }}
          >
            <span className="text-span">
              {t('Ingredient.addNewIngredient')}
            </span>
          </div>
        </div>

        <div className="excel-btn-wrap">
          <a
            href={`${process.env.REACT_APP_BASE_URL}/Excel/exportStockMaterials?client_id=${systemSettings?.id}`}
            download
            className="excel-export-btn"
          >
            <img
              style={{ height: '42px' }}
              src={excelImg}
              alt="export to excel"
            />
          </a>
        </div>
      </div>

      <PageTable />

      {modalOpened && <PageModal />}
    </div>
  );
};

export default StoreIngredientsPage;
