export const weightTypes = {
  byWeight: '1',
  byQuantity: '2'
};

export const getWeightTypes = (t) => {
  return [
    {
      id: '1',
      name: 'المنتج قابل للوزن',
      value: '1'
    },
    {
      id: '2',
      name: 'المنتج غير قابل للوزن',
      value: '2'
    }
  ];
};
