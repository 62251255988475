import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import { EyeOutlined } from '@ant-design/icons';
import trimWords from '../../../utils/trimWords';
import countWords from '../../../utils/countWords';
import { ReadOutlined } from '@ant-design/icons';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import deleteNoteApi from '../../../apis/settings-apis/settings-notes-apis/deleteNoteApi';
import { useTranslation } from 'react-i18next';

const TableColumns = (
  setModalOpened,
  setFetchCount,
  setSelectedNote,
  setStickyNoteModalOpened,
  setIsLoading,
  token
) => {
  const handleEditClick = (obj) => {
    setSelectedNote(obj);
    setModalOpened(true);
  };
  const { t } = useTranslation();

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteNoteApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title:t('Messages.DeletedSuccessfully'),
          message: t('Messages.DeletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Messages.ErrorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Messages.TryAgainLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Messages.ErrorOccurred'),
        message: t('Messages.TryAgainLater')
      });
    }
  };

  const renderActions = (obj) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(obj)}>
          <EditIcon />
          {t('Notes.edit')}
        </div>
        <Popconfirm
          title={t('Messages.areyouSureToDelete')}
          onConfirm={() => handleDeleteRequest(obj.id)}
          okText={t('Messages.yes')}
          cancelText={t('Messages.no')}
        >
          <Tooltip title={t('Notes.DeleteNote')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      // title: 'الرقــم',
      title: t('Notes.NoteNumber'),

      dataIndex: 'index',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      // title: 'عنوان الملاحظة',
      title: t('Notes.NoteTitle'),

      dataIndex: 'title',
      // width: 192,
      render: (_, record) => {
        if (record.title) {
          const wordsCount = countWords(String(record.title));
          if (wordsCount > 3) {
            return (
              <Popover title={t('Notes.readMore')} content={String(record.title)}>
                <Button size="large" type="text">
                  <p className="has-read-more">
                    <span>{trimWords(String(record.title), 3)}</span>
                    <ReadOutlined
                      className="read-more-book"
                      style={{ margin: '0 4px' }}
                    />
                  </p>
                </Button>
              </Popover>
            );
          } else
            return (
              <div className="row-cell row-text">{String(record.title)}</div>
            );
        } else {
          return '----';
        }
      }
    },
    {
      // title: 'تفاصيل الملاحظة',
      title: t('Notes.NoteDetails'),

      dataIndex: 'note',
      // width: 192,
      render: (_, record) => {
        return record.note ? (
          <div className="row-cell row-circled-btn">
            <Tooltip title={t('Notes.NoteDetails')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => {
                  setSelectedNote(record ? record : null);
                  setStickyNoteModalOpened(true);
                }}
              />
            </Tooltip>
          </div>
        ) : (
          '----'
        );
      }
    },
    {
      // title: 'الاعدادات',
      title: t('Notes.Settings'),

      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default TableColumns;
