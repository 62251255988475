/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import UserIcon from '../../common/icons/UserIcon';
import PhoneIcon from '../../common/icons/PhoneIcon';
import EmailIcon from '../../common/icons/EmailIcon';
import LocationIcon from '../../common/icons/LocationIcon';
// import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { useContext } from 'react';
import { useEffect } from 'react';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import UserContext from '../../contexts/user-context/UserProvider';
import SuppliersContext from '../../contexts/suppliers-context/SuppliersContext';
import addNewSupplierApi from '../../apis/store-apis/suppliers/addNewSupplierApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import { useTranslation } from 'react-i18next';

const TableForm = ({ setAllSuppliersFetchCount }) => {
  const { user } = React.useContext(UserContext);
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    name: Yup.string().required(t('addSupplierForm.enterNameValidation')),
    phone: Yup.string()
      .optional(t('addSupplierForm.enterPhoneValidation'))
      .test(
        'phone',
        t('addSupplierForm.onlyNumbersValidation'),
        (v, context) => {
          let result = true;
          if (v && !v.match(/^[0-9]+$/)) {
            result = false;
          }
          return result;
        }
      ),
    email: Yup.string()
      // .required('البريد الألكتروني')

      .email(t('addSupplierForm.trueEmailValidation')),
    // addressCode: Yup.string().required('ادخل الرمز الاضافى للعنوان'),
    country: Yup.string().optional(t('addSupplierForm.enterCountryValidation')),
    city: Yup.string().optional(t('addSupplierForm.enterCityValidation'))
    // district: Yup.string().required('ادخل الحى'),
    // streetName: Yup.string().required('ادخل اسـم الشارع'),
    // buildingNum: Yup.string()
    //   .required('ادخل رقم المبنى')
    //   .matches(/^[0-9]+$/, 'رقم المبنى ارقام فقط'),
    // postalCode: Yup.string()
    //   .required('ادخل الرمز البريدى')
    //   .matches(/^[0-9]+$/, 'لا يسمح الا بكتابة الارقام'),
    // VATRegistrationNumber: Yup.string(),
    // VATRegistrationNumber: Yup.string()
    //   .required('ادخل الرقم الضريبى')
    //   .matches(/^[0-9]+$/, 'لا يسمح الا بكتابة الارقام'),
    // anotherId: Yup.string()
    // supplier_type: Yup.string().required('اختار نوع المورد')
  });
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedSupplier,
    setSelectedSupplierId,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(SuppliersContext);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      addressCode: '',
      country: '',
      city: '',
      district: '',
      streetName: '',
      buildingNum: '',
      anotherId: '',
      anotherIdName: '',
      postalCode: '',
      VATRegistrationNumber: ''
      // supplier_type: ''
    }
  });

  // handle initial values
  useEffect(() => {
    if (selectedSupplier) {
      if (selectedSupplier?.name) {
        setValue('name', selectedSupplier.name);
      }
      if (selectedSupplier?.phone) {
        setValue('phone', selectedSupplier.phone);
      }
      if (selectedSupplier?.email) {
        setValue('email', selectedSupplier.email);
      }
      if (selectedSupplier?.addressCode) {
        setValue('addressCode', selectedSupplier.addressCode);
      }
      if (selectedSupplier?.country) {
        setValue('country', selectedSupplier.country);
      }
      if (selectedSupplier?.city) {
        setValue('city', selectedSupplier.city);
      }
      if (selectedSupplier?.district) {
        setValue('district', selectedSupplier.district);
      }
      if (selectedSupplier?.streetName) {
        setValue('streetName', selectedSupplier.streetName);
      }
      if (selectedSupplier?.buildingNum) {
        setValue('buildingNum', selectedSupplier.buildingNum);
      }
      if (selectedSupplier?.anotherId) {
        setValue('anotherId', selectedSupplier.anotherId);
      }
      if (selectedSupplier?.anotherIdName) {
        setValue('anotherIdName', selectedSupplier.anotherIdName);
      }
      if (selectedSupplier?.postalCode) {
        setValue('postalCode', selectedSupplier.postalCode);
      }
      if (selectedSupplier?.VATRegistrationNumber) {
        setValue(
          'VATRegistrationNumber',
          selectedSupplier.VATRegistrationNumber
        );
      }
      if (selectedSupplier?.note) {
        setValue('note', selectedSupplier.note);
      }
      if (selectedSupplier?.commercialRecord) {
        setValue('commercialRecord', selectedSupplier.commercialRecord);
      }
      // if (selectedSupplier?.supplierType) {
      //   setValue('supplier_type', String(selectedSupplier.supplierType));
      // }
    }
  }, [selectedSupplier]);
  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    const mappedData = {};
    if (data.name) mappedData.name = data.name;
    if (data.phone) mappedData.phone = data.phone;
    if (data.email) mappedData.email = data.email;
    if (data.addressCode) mappedData.addressCode = data.addressCode;
    if (data.country) mappedData.country = data.country;
    if (data.city) mappedData.city = data.city;
    if (data.district) mappedData.district = data.district;
    if (data.streetName) mappedData.streetName = data.streetName;
    if (data.buildingNum) mappedData.buildingNum = data.buildingNum;
    if (data.anotherId) mappedData.anotherId = data.anotherId;
    if (data.anotherIdName) mappedData.anotherIdName = data.anotherIdName;
    if (data.postalCode) mappedData.postalCode = data.postalCode;
    if (data.VATRegistrationNumber)
      mappedData.VATRegistrationNumber = data.VATRegistrationNumber;
    if (data.note) mappedData.note = data.note;
    if (data.commercialRecord)
      mappedData.commercialRecord = data.commercialRecord;

    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedSupplier) {
      mappedData.id = selectedSupplier.id;
      customApiRequest(
        addNewSupplierApi(user?.token, mappedData, true),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (res?.status === 200 && res?.data?.data) {
            setIsLoading(false);
            setModalOpened(false);
            setSelectedSupplierId(res?.data?.data?.id);
            setFetchCount((prevState) => prevState + 1);
            if (setAllSuppliersFetchCount)
              setAllSuppliersFetchCount((prev) => prev + 1);
            successNotification({
              title: t('addSupplierForm.successNotification'),
              message: t('addSupplierForm.updateSupplierSuccessMsg')
            });
          } else {
            setIsLoading(false);
            errorNotification({
              title: t('addSupplierForm.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('addSupplierForm.tryLaterMessage')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('addSupplierForm.errorOccurred'),
            message:
              error?.response?.data?.message ||
              t('addSupplierForm.tryLaterMessage')
          });
        }
      );
    } else {
      customApiRequest(
        addNewSupplierApi(user?.token, mappedData),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setSelectedSupplierId(res?.data?.data?.id);
            setFetchCount((prevState) => prevState + 1);
            if (setAllSuppliersFetchCount)
              setAllSuppliersFetchCount((prev) => prev + 1);
            successNotification({
              title: t('addSupplierForm.successNotification'),
              message: t('addSupplierForm.addSuupplierSuccessMsg')
            });
          } else {
            errorNotification({
              title: t('addSupplierForm.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('addSupplierForm.tryLaterMessage')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('addSupplierForm.errorOccurred'),
            message:
              error?.response?.data?.message ||
              t('addSupplierForm.tryLaterMessage')
          });
        }
      );
    }
  };

  const [form] = Form.useForm();

  return (
    <Form
      className="store-new-supplier-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('addSupplierForm.name')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="name"
              type="text"
              placeholder={t('addSupplierForm.namePlaceholder')}
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              control={control}
              prefix={<UserIcon />}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('addSupplierForm.phoneNumber')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="phone"
              type="text"
              placeholder={t('addSupplierForm.phonePlaceholder')}
              errorMsg={errors?.phone?.message}
              validateStatus={errors?.phone ? 'error' : ''}
              control={control}
              prefix={<PhoneIcon />}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('addSupplierForm.email')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="email"
              type="text"
              placeholder={t('addSupplierForm.emailPlaceholder')}
              errorMsg={errors?.email?.message}
              validateStatus={errors?.email ? 'error' : ''}
              control={control}
              prefix={<EmailIcon />}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('addSupplierForm.supplierAddressCode')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="addressCode"
              type="text"
              placeholder={t('addSupplierForm.supplierAddressCodePlaceholder')}
              errorMsg={errors?.addressCode?.message}
              validateStatus={errors?.addressCode ? 'error' : ''}
              control={control}
              prefix={<LocationIcon />}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">
            {t('addSupplierForm.supplierCommercialNum')}
          </p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="commercialRecord"
              type="text"
              placeholder={t(
                'addSupplierForm.supplierCommercialNumPlaceholder'
              )}
              errorMsg={errors?.commercialRecord?.message}
              validateStatus={errors?.commercialRecord ? 'error' : ''}
              control={control}
              prefix={<UserIcon />}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('addSupplierForm.notes')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="note"
              rows={8}
              placeholder={t('addSupplierForm.notesPlaceholder')}
              errorMsg={errors?.note?.message}
              validateStatus={errors?.note ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="country-city-region-wrap">
          <div className="text-field-label-wrap">
            <p className="label-p">{t('addSupplierForm.country')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="country"
                type="text"
                placeholder={t('addSupplierForm.countryPlaceholder')}
                errorMsg={errors?.country?.message}
                validateStatus={errors?.country ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">{t('addSupplierForm.city')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="city"
                type="text"
                placeholder={t('addSupplierForm.cityPlaceholder')}
                errorMsg={errors?.city?.message}
                validateStatus={errors?.city ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">{t('addSupplierForm.district')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="district"
                type="text"
                placeholder={t('addSupplierForm.districtPlaceholder')}
                errorMsg={errors?.district?.message}
                validateStatus={errors?.district ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
        </div>
        <div className="building-number-street-name">
          <div className="text-field-label-wrap">
            <p className="label-p">{t('addSupplierForm.buildingNumber')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="buildingNum"
                type="text"
                placeholder={t('addSupplierForm.buildingNumPlaceholder')}
                errorMsg={errors?.buildingNum?.message}
                validateStatus={errors?.buildingNum ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">{t('addSupplierForm.streetName')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="streetName"
                type="text"
                placeholder={t('addSupplierForm.streetNamePlaceholder')}
                errorMsg={errors?.streetName?.message}
                validateStatus={errors?.streetName ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('addSupplierForm.idName')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="anotherIdName"
              type="text"
              placeholder={t('addSupplierForm.idNamePlaceholder')}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('addSupplierForm.idNumber')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="anotherId"
              type="text"
              placeholder={t('addSupplierForm.idNumberPlaceholder')}
              control={control}
            />
          </div>
        </div>

        <div className="post-box-wrap">
          <div className="text-field-label-wrap">
            <p className="label-p">{t('addSupplierForm.postalCode')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="postalCode"
                type="text"
                placeholder={t('addSupplierForm.postalCodePlaceholder')}
                errorMsg={errors?.postalCode?.message}
                validateStatus={errors?.postalCode ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">{t('addSupplierForm.VATRegisterNumber')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="VATRegistrationNumber"
                type="text"
                placeholder={t('addSupplierForm.VATRegisterNumberPlaceholder')}
                errorMsg={errors?.VATRegistrationNumber?.message}
                validateStatus={errors?.VATRegistrationNumber ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
        </div>
        {/* <div className="select-label-wrap">
          <p className="label-p">نوع المورد</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="supplier_type"
              errorMsg={errors?.supplier_type?.message}
              validateStatus={errors?.supplier_type ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="فئة المنتج الرئيسية"
              options={[
                { title: 'مورد', value: '1' },
                { title: 'مشترك', value: '2' }
              ]}
              formClassName="store-new-supplier-form"
            />
          </div>
        </div> */}

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedSupplier
            ? t('addSupplierForm.update')
            : t('addSupplierForm.add')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
