import axios from 'axios';

const deleteClientApi = async (token, id) => {
    // const formDate = new FormData();
    // for (let id of ids) {
    //   formDate.append('id[]', id);
    // }
    try {
        const res = await axios.post(
        '/User/delete',
        { id },
        {
            baseURL: process.env.REACT_APP_BASE_URL_POS,
            headers: {
            Accept: 'application/json',
            'Content-Type': 'applications/json',
            Authorization: 'Bearer ' + token,
            lang: 'ar'
            }
        }
        );
        return res;
    } catch (error) {
        throw error;
    }
};

export default deleteClientApi;
