import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import EditIcon from '../../../common/icons/EditIcon';
import DeleteIcon from '../../../common/icons/DeleteIcon';
import { EyeOutlined, PrinterOutlined } from '@ant-design/icons';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import deletePayment from '../../../apis/pos-api/cash-payments/deletePayment';
import deletePaymentProducts from '../../../apis/cash-payments-products/deletePaymentProducts';

const tableColumns = (
  allFetchedCashIn,
  setSelectedCash,
  setIsLoading,
  setModalOpenedCashIn,
  setFetchCount,
  setModalOpened,
  printModalCash,
  setPrintModalCash,
  t,
  token
) => {
  const handleViewReceiptClick = (record) => {
    const foundInvoice = allFetchedCashIn.find(
      (receipt) => receipt.id === record.id
    );
    if (foundInvoice) {
      setSelectedCash({ ...foundInvoice });
      setPrintModalCash(true);
    }
  };
  const handleEditProduct = (record) => {
    const found = allFetchedCashIn.find((place) => place.id === record.id);
    if (found) {
      setSelectedCash({ ...found });
      setModalOpenedCashIn(true);
    }
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deletePaymentProducts(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Inventory.CashIn.addedSuccessfully'),
          message: t('Inventory.CashIn.cashInSuccessfullyDeleted')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Inventory.CashIn.errorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Inventory.CashIn.pleaseTryLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Inventory.CashIn.errorOccurred'),
        message: t('Inventory.CashIn.pleaseTryLater')
      });
    }
  };

  const handlePrintClick = (record) => {};

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditProduct(record)}>
          <EditIcon />
          {t('Inventory.CashIn.edit')}
        </div>
        <Popconfirm
          title={t('Inventory.CashIn.areyouSureToDelete')}
          onConfirm={() => handleDeleteRequest(record.id)}
          okText={t('Inventory.CashIn.yes')}
          cancelText={t('Inventory.CashIn.no')}
        >
          <Tooltip title={t('Inventory.CashIn.delete')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      title: t('Inventory.CashIn.serialNumber'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span>{record.id}</span>
          </div>
        );
      }
    },

    {
      title: t('Inventory.CashIn.section'),
      dataIndex: 'department',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">{record?.department?.name}</div>
        );
      }
    },
    {
      title: t('Inventory.CashIn.name'),
      dataIndex: 'username_reciept',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">{record?.username_reciept}</div>
        );
      }
    },
    {
      title: t('Inventory.CashIn.tableAmount'),
      dataIndex: 'amount',
      render: (_, record) => {
        return <div className="row-cell row-text">{record.amount}</div>;
      }
    },

    {
      title: t('Inventory.CashIn.note'),
      dataIndex: 'title',
      render: (_, record) => {
        return <div className="row-cell row-text">{record.title}</div>;
      }
    },

    {
      title: t('Inventory.CashIn.tableDueDate'),
      dataIndex: 'date',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.date ? record.date : '----'}
          </div>
        );
      }
    },
    {
      title: t('Inventory.CashIn.invoicePreview'),
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            <Tooltip title={t('Inventory.CashIn.invoicePreview')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => handleViewReceiptClick(record)}
              />
            </Tooltip>
          </div>
        );
      }
    },
    // {
    //   title: 'طباعة',
    //   render: (_, record) => {
    //     return (
    //       <div
    //         className="row-cell row-circled-btn"
    //         onClick={() => {
    //           handlePrintClick(record);
    //         }}
    //       >
    //         <Tooltip title="طباعة">
    //           <Button
    //             className="circled-btn"
    //             type="dashed"
    //             shape="circle"
    //             icon={<PrinterOutlined />}
    //           />
    //         </Tooltip>
    //       </div>
    //     );
    //   }
    // },
    {
      title: t('Inventory.CashIn.settings'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
