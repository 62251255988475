const posRouterLinks = {
  homePage: '/',
  posPage: '/pos',
  clientsPage: '/pos/clients',
  saleInvoicesPosPage: '/pos/sale-invoices',
  reservationsPage: '/pos/reservations',
  notificationsPage: '/pos/notifications',
  priceOfferRouteNew: '/pos/price-offer',
  cashOutPage: '/pos/cash-out',
  cashInPage: '/pos/cash-in',
  ProfilePage: '/profile',
  printInvoicePage: (id) => {
    if (id) return `/print/${id}`;
    return '/print/:id';
  },
  generalInvoiceDetailsPage: (id) => {
    if (id) return `/invoice-details/${id}`;
    return '/invoice-details/:id';
  },
  ////////////////////////////////
  notFound: '/not-found'
};
export default posRouterLinks;
