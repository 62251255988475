/* eslint-disable eqeqeq */
import React, { useRef, useState } from 'react';
import { Button, Descriptions, Empty, Form, Modal, Spin, Table, Tooltip } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import * as Yup from 'yup';
import CustomersPaymentsContext from '../../contexts/customers-payments-context/CustomersPaymentsContext';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { useEffect } from 'react';
import getSingleCustomerPayment from '../../apis/store-apis/customer-payments/getSingleCustomerPayment';
import { useContext } from 'react';
import UserContext from '../../contexts/user-context/UserProvider';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
import TechnicalSupportContext from '../../contexts/technical-support-context/TechnicalSupportContext';
import getSingleTicketApi from '../../apis/technical-support-page/getSingleTicketApi';
// MessageBox component
import { MessageBox, MessageList } from 'react-chat-elements'
import 'react-chat-elements/dist/main.css'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import SendIcon from '../../common/icons/SendIcon';
import sendMsgTicketApi from '../../apis/technical-support-page/sendMsgTicketApi';
import errorNotification from '../../utils/errorNotification';
const schema = Yup.object().shape({
  content: Yup.string().optional('ادخل الرسالة'),
});
const ShowModal = () => {
  const systemSettings = useSystemSettings();
  const {
    selectedTicket,
    setSelectedTicket,
    setShowModalOpened,
    showModalOpened,
    setIsSingleLoading,
    isSingleLoading,
    fetchCount,
    setFetchCount,
    fetchCountMsg, 
    setFetchCountMsg,
    allMsgs, 
    setAllMsgs
  } = React.useContext(TechnicalSupportContext);
  const { user } = useContext(UserContext);
  const a4Ref = useRef();
  const customApiRequest = useCustomApiRequest();
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      content: '',
      ticket_id: selectedTicket?.id,
      to_id: ''
    }
  });
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // setIsSingleLoading(true);
      customApiRequest(
        getSingleTicketApi(user?.token, selectedTicket?.id),
        (res) => {
          // setIsSingleLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            const data = res?.data?.data;
            setFetchCount((prevState) => prevState + 1);
            setSelectedTicket(data);
          }
        },
        (error) => {
          // setIsSingleLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [allMsgs]);

  const handlePrintA4 = useReactToPrint({
    content: () => a4Ref.current,
    pageStyle: `
      @media print {
        html, body { height: initial !important; overflow: initial !important; }
        // tr { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
        // td { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
        tr { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
        td { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
        tbody { display: table-row-group !important; }
        thead {
          display: table-row-group !important;
        }
        tfoot {
          display: table-row-group !important;
        }
      @page {
        // content:url('../../../assets/imgs/Watermark.png') !important;
        size: auto;
        margin: none;
      }
      .qr-code-wrap canvas {
        width: 128px !important;
        height: 128px !important;
      }
      .pagebreak {
        display: block;
        size: auto;
      }
      .invoice-details-wrap {
        
        padding: 0;
      }
      .ant-descriptions-item-content,
        .ant-descriptions-item-label {
          padding: 4px 12px !important;
        }
      }
      .ant-descriptions-header {
        margin: 4px 0 !important;
      }
      .models-title {
        direction: rtl;
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .ant-table-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
      }
      th {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      .invoice-extra-prices-table .ant-table-cell {
        padding: 4px 12px !important;
        text-align: right;
      }
    `
  });

  const renderModels = (details) => {
    return (
      <div className="models-wrap pagebreak" style={{ marginTop: '30px' }}>
        <h3 style={{ marginBottom: '8px' }}>توصيف الدفعة</h3>
        <div className="receiptDetails-wrapper">
          <Table
            bordered
            // scroll={{ x: 1032 }}
            locale={{
              emptyText: <Empty description={false}>لا يوجد بيانات متاحة</Empty>
            }}
            pagination={false}
            // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            // className="antd-custom-table"
            dataSource={[
              {
                invoice_id: details?.invoice_id,
                amount: details?.amount,
                date: details?.date,
                date_printed: details?.date_printed,
                balance: details?.user?.balance,
                paid: details?.user?.paid,
                unpaid: details?.user?.unpaid
              }
            ]}
            columns={[
              {
                title: 'رقم الدفعة',
                dataIndex: 'invoice_id',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record?.invoice_id}</span>
                    </div>
                  );
                }
              },
              {
                title: 'مبلغ الدفعة',
                dataIndex: 'amount',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record?.amount}</span>
                    </div>
                  );
                }
              },
              {
                title: 'تاريخ الدفعة',
                dataIndex: 'date',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record?.date || '----'}</span>
                    </div>
                  );
                }
              },
              // {
              //   title: 'تاريخ طباعة الإيصال',
              //   dataIndex: 'date_printed',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record?.date_printed || '-----'}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: 'رصيد الفاتورة',
                dataIndex: 'balance',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record?.balance}</span>
                    </div>
                  );
                }
              },
              {
                title: 'المدفوع',
                dataIndex: 'paid',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record?.paid}</span>
                    </div>
                  );
                }
              },
              {
                title: 'المستحق من العميل',
                dataIndex: 'unpaid',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record?.unpaid}</span>
                    </div>
                  );
                }
              }
            ]}
            // loading={isLoading}
            // scroll={{x:false}}
            footer={false}
          />
        </div>
      </div>
    );
  };
  const renderCompanyData = (obj) => {
    return (
      <div className="supplier-details-wrap">
        {/* <div
          style={{
            marginBottom: '8px',
            fontWeight: 'bold'
          }}
        >
          بيانات التذكرة
        </div> */}
        <Descriptions title={''} bordered column={1}>
          {selectedTicket?.admin?.name && (
            <Descriptions.Item
              label="الإسم"
              style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
            >
              {selectedTicket?.admin?.name}
            </Descriptions.Item>
          )}
          {selectedTicket?.subject && (
            <Descriptions.Item
              label="العنوان"
              style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
            >
              {selectedTicket?.subject}
            </Descriptions.Item>
          )}
          {selectedTicket?.priority && (
            <Descriptions.Item
              label="الأولولية"
              style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
            >
              {selectedTicket?.priority}
            </Descriptions.Item>
          )}
          {selectedTicket?.description && (
            <Descriptions.Item
              label="التفاصيل"
              style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
            >
              {selectedTicket?.description}
            </Descriptions.Item>
          )}
          {selectedTicket?.status && (
            <Descriptions.Item
              label="الحالة"
              style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
            >
              {selectedTicket?.status}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  };

  const renderInvoiceHeading = () => {
    return 'تفاصيل التذكرة';
  };

  const renderModalBody = () => {
    return (
      <div
        className="invoice-details-wrap"
        ref={a4Ref}
        style={{
          padding: '28px 28px',
          direction: 'rtl',
          display: 'grid',
          gap: '16px'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <div style={{ minWidth: '40%' }}>
            {selectedTicket && renderCompanyData(selectedTicket)}
          </div>
          {selectedTicket?.file && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <img
                className="system-logo   "
                src={selectedTicket?.file}
                alt="logo"
                style={{
                  width: '60%'
                }}
              />
            </div>
          )}
          {/* {selectedTicket &&
            renderClientData({
              ...selectedTicket?.user,
              date_printed: selectedTicket?.date_printed
            })} */}
        </div>
        {/* {selectedTicket && renderModels(selectedTicket)} */}
      </div>
    );
  };
  
  const messageListReferance = React.createRef();
  const onSubmit = async (data) => {
    customApiRequest(
      sendMsgTicketApi(user?.token, data),
      (res) => {
        // setIsLoading(false);
        // setIsSubmittingForm(false);
        setFetchCount((prevState) => prevState + 1);
        if (res?.status === 200 && res?.data?.data) {
          setAllMsgs(res?.data?.data)
          setValue('content' , '')
        } else {
          
        }
      },
      (error) => {
        // setIsLoading(false);
        errorNotification({
          title: 'حدث خطأ',
          message: error?.response?.data?.message || 'حاول فى وقت لاحق'
        });
      }
    );
  }
  const [form] = Form.useForm();
  const renderChatBox = () => {
    return (
      <div className='chat-box'>
        <div className='chat-box-content'>
          <MessageBox
            reply={{
              photoURL: 'https://facebook.github.io/react/img/logo.svg',
              title: 'فاتورة',
              titleColor: '#8717ae',
              message: 'مرحبا بك ف الدعم الفنى لفاتورة',
            }}
            position={'right'}  
            type={'text'}
            text={'مرحبا بك ف الدعم الفنى لفاتورة'}
          />
        
          {selectedTicket?.messages && <MessageList
            referance={messageListReferance}
            className='message-list'
            lockable={true}
            toBottomHeight={'100%'}
            dataSource={selectedTicket?.messages}
            // dataSource={[
            //   {
            //       position: 'left',
            //       type: 'text',
            //       text: allMsgs?.content,
            //       date: new Date(),
            //   },
            // ]} 
          />}
        </div>
        <Form
          className="message-shared-form"
          form={form}
          // layout="vertical"
          onFinish={handleSubmit(onSubmit)}
        >
          <div className="text-field-label-wrap">
            <div className="text-field-wrap">
              <AntdTextField
                setValue={setValue}
                className="form-text-field"
                name="content"
                type="text"
                placeholder="إكتب رسالتك..."
                errorMsg={errors?.content?.message}
                validateStatus={errors?.content ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <Button
            disabled={watch("content") ? false : true}
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // loading={isSubmittingForm}
          >
            <SendIcon />
          </Button>
        </Form>
      </div>
    )
  }

  return (
    <Modal
      className="shared-custom-modal selected-inovice-modal"
      width="90%"
      style={{ maxWidth: '942px' }}
      title={
        <>
          <div className="title-wrapper">
            {renderInvoiceHeading()}
          </div>
        </>
      }
      visible={showModalOpened}
      onOk={() => {
        setSelectedTicket(false);
        setShowModalOpened(null);
      }}
      onCancel={() => {
        setShowModalOpened(false);
        setSelectedTicket(null);
      }}
      footer={false}
    >
      {isSingleLoading ? (
        <div
          style={{
            height: '400px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Spin />
        </div>
      ) : (
        <>
          {renderModalBody()}
          {renderChatBox()}
        </>
      )}
    </Modal>
  );
};

export default ShowModal;
