import axios from 'axios';

const getPackagesToUpgradeApi = async () => {
  try {
    const res = await axios({
      method: 'get',
      baseURL: process.env.REACT_APP_BASE_URL_WITH_API,
      url: '/packageTOUpgrade',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        lang: 'ar'
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default getPackagesToUpgradeApi;
