import React from 'react';
import './WaterMark.scss';
const WaterMark = ({ text, fontSize }) => {
  return (
    <div
      className="water-mark"
      style={{ fontSize: fontSize ? `${fontSize}px` : '120px' }}
    >
      {text}
      <p style={{ fontSize: '50px', fontWeight: 'bold' }}>Fatoorah.sa</p>
    </div>
  );
};

export default WaterMark;
