/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import UserContext from '../contexts/user-context/UserProvider';
import useCustomApiRequest from './useCustomApiRequest';
import getJournalAccountsListLeafApi from '../apis/accounting-apis/accounting-details-apis/getJournalAccountsListLeafApi';
import BalanceSheetContext from '../contexts/accounting-context/BalanceSheetContext';
import { useCallback } from 'react';
import getAccountsList from '../apis/accounting-apis/accounting-details-apis/getAccountsList';

const useAccounts = () => {
  const [allAccounts, setAllAccounts] = useState(null);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const { user } = useContext(UserContext);
  const { fetchCount} = useContext(BalanceSheetContext);
  const customApiRequest = useCustomApiRequest();
  const incrementViews = useCallback(() => {
    customApiRequest(
      getAccountsList(user?.token, user?.client_id),
      (res) => {
        if (res?.data?.accounts) {
          setIsInitialRender(false);
          setAllAccounts(res?.data?.accounts);
        }
      },
      (error) => {}
    );
  }, []);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      incrementViews()
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return allAccounts;
};

export default useAccounts;
