import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedNotes: null,
  setAllFetchedNotes: (v) => {},
  selectedNote: null,
  setSelectedNote: (v) => {},
  // sticky notes modal
  stickyNoteModalOpened: false,
  setStickyNoteModalOpened: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {}
};

const ArchiveNotesContext = createContext(INITIAL_VALUES);

export const ArchiveNotesProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedNotes, setAllFetchedNotes] = useState(
    INITIAL_VALUES.allFetchedNotes
  );
  const [selectedNote, setSelectedNote] = useState(INITIAL_VALUES.selectedNote);
  const [stickyNoteModalOpened, setStickyNoteModalOpened] = useState(
    INITIAL_VALUES.stickyNoteModalOpened
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  return (
    <ArchiveNotesContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedNotes,
        setAllFetchedNotes,
        selectedNote,
        setSelectedNote,
        stickyNoteModalOpened,
        setStickyNoteModalOpened,
        isSubmittingForm,
        setIsSubmittingForm
      }}
    >
      {children}
    </ArchiveNotesContext.Provider>
  );
};

export default ArchiveNotesContext;
