import axios from 'axios';

const addAmountBox = async (token, values) => {
  try {
    let url = 'addsShiftMoney';

    const res = await axios.post(url, values, {
      baseURL: process.env.REACT_APP_BASE_URL_POS,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default addAmountBox;
