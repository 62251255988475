/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Table, Empty, Pagination } from 'antd';
import tableColumns from './tableColumns';
import UserContext from '../../contexts/user-context/UserProvider';
import ClientsContext from '../../contexts/clients-context/ClientsContext';
import getAllClientsApi from '../../apis/clients-apis/getAllClientsApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import { useHistory, useLocation } from 'react-router-dom';
import storeRouterLinks from '../../components/app/store-routes/storeRouterLinks';
import queryString from 'query-string';
import tableColumnsProducts from './tableColumnsProducts';
import { useTranslation } from 'react-i18next';

const PageTable = () => {
  const { t } = useTranslation();

  const {
    allFetchedClients,
    setAllFetchedClients,
    isLoading,
    setIsLoading,
    fetchCount,
    setFetchCount,
    setModalOpened,
    setSelectedClient,
    nameSearchValue,
    tablePagination,
    setTablePagination
  } = useContext(ClientsContext);
  const { user, filterValues, setFilterValues } = useContext(UserContext);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  const customApiRequest = useCustomApiRequest();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const handleTableColumns = (sub) => {
    if (sub === 1) {
      return tableColumnsProducts(
        allFetchedClients,
        setModalOpened,
        setFetchCount,
        setSelectedClient,
        setIsLoading,
        user?.token
      );
    } else if (sub === 2) {
      return tableColumns(
        allFetchedClients,
        setModalOpened,
        setFetchCount,
        setSelectedClient,
        setIsLoading,
        user?.token
      );
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllClientsApi(
          user?.token,
          {
            ...filterValues,
            page: values.page ? values.page : 1,
            searchText: nameSearchValue
          },
          false
        ),
        (res) => {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data?.data?.length >= 0) {
            setFilterValues({
              ...filterValues,
              page: tablePagination.current_page
            });
            const data = res.data.data.data;
            setAllFetchedClients(data);
          }
          if (res?.data?.data?.pagination) {
            setTablePagination(res.data.data.pagination);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  useEffect(() => {
    if (values.page) {
      setTablePagination({ ...tablePagination, current_page: +values.page });
    }
  }, [search]);

  const history = useHistory();

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedClients?.length >= 0) {
      let mappedData = null;
      if (nameSearchValue.trim()) {
        const filtered = allFetchedClients.filter((user) => {
          if (
            user.name
              .toLowerCase()
              .trim()
              .includes(nameSearchValue.toLowerCase().trim())
          ) {
            return true;
          } else if (user.id) {
            return true;
          } else if (user.email) {
            return true;
          } else if (user.phone) {
            return true;
          }
        });
        mappedData = mapDataSource(filtered);
      } else {
        mappedData = mapDataSource(allFetchedClients);
      }
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    fetchCount,
    allFetchedClients,
    allFetchedClients?.length,
    nameSearchValue
  ]);

  return (
    <section>
      <Table
        locale={{
          emptyText: (
            <Empty description={false}>{t('Messages.noDataAvailable')}</Empty>
          )
        }}
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={handleTableColumns(user.subscriptionType)}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
      {tablePagination && (
        <Pagination
          style={{ display: 'flex', justifyContent: 'center' }}
          defaultCurrent={1}
          showSizeChanger={false}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}

          onChange={(page, pageSize) => {
            setTablePagination({ ...tablePagination, current_page: page });
            // setTablePagination(...tablePagination , {current_page:page})
            setFetchCount((prev) => prev + 1);
            history.push(`${storeRouterLinks?.clientPage}?page=${page}`);
            // if (isReportsPage) {
            //   history.push(
            //     `${storeRouterLinks?.outgoingPurchaseInvoiceReports}?page=${page}`
            //   );
            // } else {
            //   history.push(
            //     `${storeRouterLinks?.outgoingPurchaseInvoice}?page=${page}`
            //   );
            // }
          }}
          hideOnSinglePage={true}
        />
      )}
    </section>
  );
};

export default PageTable;
