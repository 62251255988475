import { useContext } from 'react';
import { AccountingProvider } from '../../../contexts/accounting-context/AccountingContext';
import { AccountingDetailsProvider } from '../../../contexts/accounting-context/AccountingDetailsContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import AccountingDetailsPage from '../../../pages/accounting-pages/accounting-details-page/AccountingDetailsPage';
import AccountingTreePage from '../../../pages/accounting-pages/accounting-tree-page/AccountingTreePage';
import protectMe from '../../../utils/protectMe';
import routerLinks from '../routerLinks';
import { JournalsProvider } from '../../../contexts/accounting-context/JournalsContext';
import AccountingJournalsPage from '../../../pages/accounting-pages/accounting-tree-page/AccountingJournalsPage';
import { TransactionsProvider } from '../../../contexts/accounting-context/TransactionsContext';
import AccountingTransactionsPage from '../../../pages/accounting-pages/accounting-tree-page/AccountingTransactionsPage';
import { BalanceSheetProvider } from '../../../contexts/accounting-context/BalanceSheetContext';
import BalanceSheetPage from '../../../pages/accounting-pages/Balance-sheet/BalanceSheetPage';
import SummaryPage from '../../../pages/accounting-pages/Summary/SummaryPage';
import AccountStatementPage from '../../../pages/accounting-pages/Account-statement/AccountStatementPage';
import { SummaryProvider } from '../../../contexts/accounting-context/SummaryContext';
import { AccountStatementProvider } from '../../../contexts/accounting-context/AccountStatementContext';
import Links from '../Links';

const AccountingRoutes = () => {
  const { loggedIn, user } = useContext(UserContext);
  const routerLinks = Links();
  return [
    protectMe(
      routerLinks.accountingTreePage,
      <AccountingProvider>
        <AccountingTreePage />
      </AccountingProvider>,
      1,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.balanceSheetPage,
      <BalanceSheetProvider>
        <BalanceSheetPage />
      </BalanceSheetProvider>,
      1,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.summaryPage,
      <SummaryProvider>
        <SummaryPage />
      </SummaryProvider>,
      1,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.accountStatementPage,
      <AccountStatementProvider>
        <AccountStatementPage />
      </AccountStatementProvider>,
      1,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.accountingJournalsPage,
      <JournalsProvider>
        <AccountingJournalsPage />
      </JournalsProvider>,
      1,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.accountingTransactionsPage,
      <TransactionsProvider>
        <AccountingTransactionsPage />
      </TransactionsProvider>,
      1,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.accountingDetailsPage(),
      <AccountingDetailsProvider>
        <AccountingDetailsPage />
      </AccountingDetailsProvider>,
      2,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    )
  ];
};

export default AccountingRoutes;
