import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import SettingsMeasurementUnitsContext from '../../../contexts/settings-contexts/SettingsMeasurementUnitsPage';
import { useTranslation } from 'react-i18next';

const SettingsWorkPlaceModal = () => {
  const { modalOpened, setModalOpened, setSelectedUnit, selectedUnit } =
    useContext(SettingsMeasurementUnitsContext);
  const { t } = useTranslation();

  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        selectedUnit
          ? t('Inventory.MeasurementUnits.modifyUnitMeasurement')
          : t('Inventory.MeasurementUnits.addNewUnitMeasurement')
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedUnit(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedUnit(null);
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default SettingsWorkPlaceModal;
