import React from 'react';

const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.731"
      height="14.364"
      viewBox="0 0 13.731 14.364"
    >
      <g id="user_3_" data-name="user (3)" transform="translate(-11.328)">
        <g
          id="Group_859"
          data-name="Group 859"
          transform="translate(11.328 7.201)"
        >
          <g id="Group_858" data-name="Group 858" transform="translate(0)">
            <path
              id="Path_2924"
              data-name="Path 2924"
              d="M18.193,257.323a6.873,6.873,0,0,0-6.865,6.865.3.3,0,0,0,.6,0,6.268,6.268,0,1,1,12.537,0,.3.3,0,0,0,.6,0A6.873,6.873,0,0,0,18.193,257.323Z"
              transform="translate(-11.328 -257.323)"
            />
          </g>
        </g>
        <g id="Group_861" data-name="Group 861" transform="translate(14.91)">
          <g id="Group_860" data-name="Group 860" transform="translate(0)">
            <path
              id="Path_2925"
              data-name="Path 2925"
              d="M142.611,0a3.283,3.283,0,1,0,3.283,3.283A3.287,3.287,0,0,0,142.611,0Zm0,5.97A2.686,2.686,0,1,1,145.3,3.283,2.69,2.69,0,0,1,142.611,5.97Z"
              transform="translate(-139.328)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default UserIcon;
