import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import SettingsCategoriesContext from '../../../contexts/settings-contexts/SettingsCategoriesProvider';
import SettingsSubCategoriesContext from '../../../contexts/settings-contexts/SettingsSubCategoriesProvider';
import PageTable from './PageTable';
import PageModal from './PageModal';
import SubCategoriesModal from './SubCategoriesModal';
import './SettingsCategoriesPage.scss';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import SyncSallaApi from '../../../apis/fatoorahStore-api/SyncSallaApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from 'antd';
import { TbSlash } from 'react-icons/tb';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

const SettingsCategoriesPage = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const { modalOpened, setModalOpened, setSelectedCategory } = useContext(
    SettingsCategoriesContext
  );
  const systemSettings = useSystemSettings();

  const {
    modalOpened: subCatModalOpened,
    setModalOpened: setSubCatModlOpened,
    setSelectedSubCategory
  } = useContext(SettingsSubCategoriesContext);

  const syncSallaAuth = async () => {
    try {
      const res = await SyncSallaApi(
        user?.token,
        systemSettings?.sallaToken,
        'categories'
      );
      if (res?.status === 200 && res?.data?.status === 1) {
        // setIsLoading(false);
        // setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Inventory.Categories.addedSuccessfully'),
          message:
            t('Inventory.Categories.categoriesBasketLinkedSuccessfully') ||
            res.data.message
        });
      } else {
        // setIsLoading(false);
        errorNotification({
          title: t('Inventory.Categories.errorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Inventory.Categories.pleaseTryLater')
        });
      }
    } catch (error) {
      // setIsLoading(false);
      errorNotification({
        title: t('Inventory.Categories.errorOccurred'),
        message: t('Inventory.Categories.tryLater')
      });
    }
  };

  return (
    <div className="settings-categories-page">
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">{t('Inventory.Categories.title')}</h2>
          <p>{t('Inventory.Categories.subTitle')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('AppBar.Products.storePage.settingsCategoriesPage')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.storePage.title')}
            title3={t('AppBar.Products.storePage.settingsCategoriesPage')}
            link3={'/settings/categories'}
          />
          <SearchNotificationsProfile />
        </div>
      </div>

      <div className="custom-filter-add-section">
        <div className="add-section">
          <div
            className="add-btn"
            onClick={() => {
              setSelectedCategory(null);
              setModalOpened(true);
            }}
          >
            <span className="text-span">
              {t('Inventory.Categories.addNewCategory')}
            </span>
          </div>
          <div
            className="add-btn"
            onClick={() => {
              setSelectedSubCategory(null);
              setSubCatModlOpened(true);
            }}
          >
            <span className="text-span">
              {t('Inventory.Categories.addNewSubcategory')}
            </span>
          </div>
          {/* {systemSettings?.sallaToken && (
            <div
              className="preview-download-upload add-btn"
              onClick={() => syncSallaAuth()}
            >
              <span className="text-span">
                {t('Inventory.Categories.connectWithSalla')}
              </span>
            </div>
          )} */}
        </div>
      </div>

      <PageTable />

      {modalOpened && <PageModal />}
      {subCatModalOpened && <SubCategoriesModal addModal={true} />}
    </div>
  );
};

export default SettingsCategoriesPage;
