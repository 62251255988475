const roles = [
  { title: 'مدير', value: '1' },
  { title: 'محاسب', value: '2' },
  // { title: 'مشترك', value: '3' },
  { title: 'مدخل بيانات', value: '4' },
  { title: 'موظف نقطة بيع', value: '5' },
  { title: 'مندوب', value: '6' }
];

export default roles;
