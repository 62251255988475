import React, { Suspense, useEffect, useState } from 'react';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
import { FieldTimeOutlined } from '@ant-design/icons';
import './countdown.scss';
import { Link, useLocation } from 'react-router-dom';
import routerLinks from '../app/routerLinks';
import { useTranslation } from 'react-i18next';

const Countdown = () => {
  const { t } = useTranslation();
  const systemSettings = useSystemSettings();
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [checkTime, setCheckTime] = useState(0);
  const location = useLocation();

  const deadline = systemSettings?.end_date;

  const getTime = () => {
    const time = (deadline && Date.parse(deadline)) - Date.now();
    setCheckTime(time);
    if (time > 0) {
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    } else {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    }
  };

  useEffect(() => {
    if (systemSettings?.end_date) {
      const interval = setInterval(() => getTime(deadline), 1000);
      return () => clearInterval(interval);
    }
  }, [systemSettings?.end_date]);
  return (
    <React.Fragment>
      <div className="countdown-container">
        {Date.now() > Date.parse(deadline) ? (
          <p className="time-end">{t('Countdown.packageExpired')}</p>
        ) : (
          <fieldset className="time-wrapper">
            <legend className="title">
              {t('Countdown.remainingTimeOfPackage')}{' '}
            </legend>
            <div className="timer" role="timer">
              <div className="col-4">
                <div className="box">
                  <p id="day">{days < 10 ? '0' + days : days}</p>
                  <span className="text">{t('Countdown.days')}</span>
                </div>
              </div>
              <div className="colon">
                <p>:</p>
              </div>
              <div className="col-4">
                <div className="box">
                  <p id="hour">{hours < 10 ? '0' + hours : hours}</p>
                  <span className="text">{t('Countdown.hours')}</span>
                </div>
              </div>
              <div className="colon">
                <p>:</p>
              </div>
              <div className="col-4">
                <div className="box">
                  <p id="minute">{minutes < 10 ? '0' + minutes : minutes}</p>
                  <span className="text">{t('Countdown.minutes')}</span>
                </div>
              </div>
              <div className="colon">
                <p>:</p>
              </div>
              <div className="col-4">
                <div className="box">
                  <p id="second">{seconds < 10 ? '0' + seconds : seconds}</p>
                  <span className="text">{t('Countdown.seconds')}</span>
                </div>
              </div>
            </div>
          </fieldset>
        )}
        {location.pathname !== '/upgrade' && (
          <Link to={routerLinks.upgradePage}>
            <div className="btn-wrapper">
              <span>{t('Countdown.upgradeSystemNow')} !</span>
            </div>
          </Link>
        )}
      </div>
    </React.Fragment>
  );
};

export default Countdown;
