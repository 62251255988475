import './MeasurementUnitsPage.scss';

import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import SettingsMeasurementUnitsContext from '../../../contexts/settings-contexts/SettingsMeasurementUnitsPage';
import PageTable from './PageTable';
import PageModal from './PageModal';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

const MeasurementUnitsPage = () => {
  const { modalOpened, setModalOpened, setSelectedUnit } = useContext(
    SettingsMeasurementUnitsContext
  );
  const { t } = useTranslation();

  return (
    <div className="measurement-units-page">
      <div className="page-head-wrap">
        <div className="page-title">
          <h2 className="bold-font">{t('Inventory.MeasurementUnits.title')}</h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.storePage.title')}
            title3={t('Inventory.MeasurementUnits.title')}
            link3={'/settings/measure-units'}
          />
          {/* <p>{t('Inventory.MeasurementUnits.subTitle')}</p> */}
        </div>
        <SearchNotificationsProfile />
      </div>

      <div className="add-section">
        {/* <div className="section-text">
          <p className="bold-font">وحدات القياس</p>
        </div> */}
        <div
          className="add-account-btn"
          onClick={() => {
            // if selected unit then reset it
            // open the unit modal
            setSelectedUnit(null);
            setModalOpened(true);
          }}
        >
          <span className="text-span">
            {t('Inventory.MeasurementUnits.addNewUnitMeasurement')}
          </span>
        </div>
      </div>

      <PageTable />

      {modalOpened && <PageModal />}
    </div>
  );
};

export default MeasurementUnitsPage;
