import Modal from 'antd/lib/modal/Modal';
import React, { useContext } from 'react';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import ReturnModalContext from '../../contexts/return-modal-context/ReturnModalContext';
import ReturnModalForm from './ReturnModalForm';
import './ReturnModal.scss';
import { useTranslation } from 'react-i18next';

const ReturnModal = () => {
  const { selectedInvoice } = useContext(PosInvoiceContext);
  const { t } = useTranslation();

  const { returnModalOpened, setReturnModalOpened } =
    useContext(ReturnModalContext);
  const [invoiceId, setInvoiceId] = React.useState('');
  React.useEffect(() => {
    if (selectedInvoice) setInvoiceId(selectedInvoice?.id);
    else setInvoiceId('');
  }, [selectedInvoice]);
  return (
    <Modal
      transitionName=""
      className="return-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '642px' }}
      title={t('SalesInvoices.InvoiceReturnRequest')}
      visible={returnModalOpened}
      invoiceId={invoiceId}
      onOk={() => {
        setReturnModalOpened(false);
        setInvoiceId('');
      }}
      onCancel={() => {
        setReturnModalOpened(false);
        setInvoiceId('');
      }}
      footer={false}
    >
      <ReturnModalForm />
    </Modal>
  );
};

export default ReturnModal;
