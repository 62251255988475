import axios from 'axios';

const addNewUnitApi = async (token, values, edit = false) => {
  //const mappedValues = {};
  const formData = new FormData();
  if (values.unit_name) formData.append('name', values.unit_name); // mappedValues.name = values.unit_name;
  if (values.unit_note)  formData.append('note', values.unit_note);  //mappedValues.note = values.unit_note;
  if (values.id) formData.append('id', values.id);  //mappedValues.id = values.id;
  try {
    let url;
    if (!edit) url = '/MajorUnit/create';
    else url = '/MajorUnit/update';
    const res = await axios.post(url, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default addNewUnitApi;
