import axios from 'axios';

const getAllListApi = async (token , client_id) => {
    try {
        const res = await axios({
            method: 'get',
            baseURL: process.env.REACT_APP_BASE_TEST_URL,
            // baseURL: 'https://testclient-api.fatoorah.sa/apiAdmin/',
            url: `integration/list?client_id=${client_id}`,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'applications/json',
                Authorization: 'Bearer ' + token,
                lang: 'ar'
            }
        });
    
        return res;
    } catch (error) {
        throw error;
    }
}

export default getAllListApi
