import React, { useContext } from 'react';
import BalanceSheetContext from '../../../contexts/accounting-context/BalanceSheetContext';
import { Spin } from 'antd';
import TransactionTable from './TransactionTable';
import { useTranslation } from 'react-i18next';

const BalanceSheetDetailsPage = () => {
  const {
    isLoading,
    setApiLoading,
    setIsLoading,
    fetchedBalanceSheetDetails,
    setFetchedBalanceSheetDetails,
    setModalOpenedCreate,
    setFetchCount
  } = useContext(BalanceSheetContext);
  const { t } = useTranslation();

  const handleFetchDataForAccount = () => {
    if (isLoading) {
      <div className="spin-tree-wrap">
        <Spin />
      </div>;
    } else if (fetchedBalanceSheetDetails) {
      return (
        <div className="view-balance-content">
          <div>
            <h3>{t('ProfessorBook.AccountName2')}</h3>
            <span> : </span>
            <span>
              {`${fetchedBalanceSheetDetails[0]?.holder}  -  ${fetchedBalanceSheetDetails[0]?.acn}` ||
                t('ProfessorBook.nothing')}
            </span>
          </div>
          {/* <div>
                        <h3>الـتـاريـخ</h3>
                        <span> : </span>
                        <span>{(fetchedBalanceSheetDetails?.current_balance && fetchedBalanceSheetDetails?.current_balance) || 'لا يوجد'}</span>
                    </div> */}
          {/* <div>
                        <h3>الرصيد الحالي</h3>
                        <span> : </span>
                        <span>{(fetchedBalanceSheetDetails?.current_balance && fetchedBalanceSheetDetails?.current_balance) || 'لا يوجد'}</span>
                    </div>
                    <div>
                        <h3>الرصيد الاولي</h3>
                        <span> : </span>
                        <span>{(fetchedBalanceSheetDetails?.initial_balance && fetchedBalanceSheetDetails?.initial_balance) || 'لا يوجد'}</span>
                    </div>
                    <div>
                        <h3>نوع الحساب</h3>
                        <span> : </span>
                        <span>{(fetchedBalanceSheetDetails?.type && fetchedBalanceSheetDetails?.type) || 'لا يوجد'}</span>
                    </div> */}
        </div>
      );
    } else {
      <h2 style={{ textAlign: 'center' }}>لا يوجد تفاصيل</h2>;
    }
  };
  return (
    <>
      <div className="view-balance">
        {handleFetchDataForAccount()}
        {fetchedBalanceSheetDetails && <TransactionTable />}
      </div>
    </>
  );
};

export default BalanceSheetDetailsPage;
