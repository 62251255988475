import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import Links from '../app/Links';
import './categorySwiper.scss';
import { useTranslation } from 'react-i18next';

const CategorySwiper = () => {
  const routerLinks = Links();
  const history = useHistory();
  const { t } = useTranslation();
  const {
    setLoadingCats,
    setSelectedMainCategory,
    setProductsFilter,
    allMainCatgories,
    setSearchTextState
  } = useContext(PosInvoiceContext);

  const handleChange = async (value) => {
    history.push(`${routerLinks?.posPage}?page=1`);
    setProductsFilter((prev) => ({
      ...prev,
      searchText: '',
      parent_cat: value,
      page: 1
    }));
  };

  return (
    <>
      {allMainCatgories?.length > 0 && (
        <div className="categoryswiper-container">
          <span className="title">{t('Pos.Main.categoriesList')}</span>
          <div className="swiper-container">
            <Swiper
              dir="rtl"
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              // pagination={{
              //   clickable: true
              // }}

              navigation={true}
              breakpoints={{
                320: {
                  slidesPerView: 'auto',
                  spaceBetween: 10
                },
                480: {
                  slidesPerView: 'auto',
                  spaceBetween: 10
                },
                540: {
                  slidesPerView: 'auto',
                  spaceBetween: 10
                },
                768: {
                  slidesPerView: 'auto',
                  spaceBetween: 20
                },
                1024: {
                  slidesPerView: 'auto',
                  spaceBetween: 20
                }
              }}
              className="mySwiper pos_main_swiper"
            >
              {allMainCatgories?.length > 0 && (
                <SwiperSlide>
                  <div
                    style={{ backgroundColor: 'red' }}
                    className="category-container"
                    onClick={() => handleChange(String())}
                  >
                    <div className="category-name">
                      <p style={{ color: 'white' }}>{t('Pos.Main.all')}</p>
                    </div>
                  </div>
                </SwiperSlide>
              )}
              {allMainCatgories?.length > 0 &&
                allMainCatgories.map((op, index) => (
                  <SwiperSlide key={op.id}>
                    <div
                      className="category-container"
                      onClick={() => handleChange(String(op.id))}
                    >
                      <div className="category-name">
                        <p>{op.name}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      )}
    </>
    // <div className="categoryswiper-container">
    //   {allMainCatgories?.length > 0 &&
    //     allMainCatgories.map((op, index) => (
    //       <div
    //         className="category-container"
    //         onClick={() => handleChange('main_cat', String(op.id))}
    //       >
    //         <div className="category-name">
    //           <p>{op.name}</p>
    //         </div>
    //       </div>
    //     ))}

    //   {/* <Swiper
    //     slidesPerView={'auto'}
    //     spaceBetween={10}
    //     pagination={{
    //       clickable: true
    //     }}
    //     className="mySwiper"
    //   >
    //     {/* {allMainCatgories?.length > 0 &&
    //       allMainCatgories.map((op, index) => (
    //         <SwiperSlide key={op.id}>
    //           <div
    //             className="category-container"
    //             onClick={() => handleChange('main_cat', String(op.id))}
    //           >
    //             <div className="category-name">
    //               <p>{op.name}</p>
    //             </div>
    //           </div>
    //         </SwiperSlide>
    //       ))} */}
    //   {/* </Swiper>  */}
    // </div>
  );
};

export default CategorySwiper;
