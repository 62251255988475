import React, { useContext } from 'react';
import { Modal } from 'antd';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import LaterInvoiceModalForm from './LaterInvoiceModalForm';

const LaterInvoiceModal = () => {
  const { laterInvoiceModalOpened, setLaterInvoiceModalOpened } =
    useContext(PosInvoiceContext);

  return (
    <Modal
      transitionName=""
      className="settings-users-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '442px' }}
      title="إحجز لاحقا"
      visible={laterInvoiceModalOpened}
      onOk={() => {
        setLaterInvoiceModalOpened(false);
      }}
      onCancel={() => {
        setLaterInvoiceModalOpened(false);
      }}
      footer={false}
    >
      <LaterInvoiceModalForm />
    </Modal>
  );
};

export default LaterInvoiceModal;
