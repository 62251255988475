import React, { useContext } from 'react';
import SearchIcon from '../../../common/icons/SearchIcon';
// import { Select, DatePicker } from 'antd';
import './StoreItemMangerTableFilter.scss';
// import SelectDropDown from '../../../common/icons/SelectDropDown';
// import CalendarIcon from '../../../common/icons/CalendarIcon';
import { CloudDownloadOutlined } from '@ant-design/icons';
import excelImg from '../../../assets/imgs/icons/excel-btn.png';
import StoreNewProductModalContext from '../../../contexts/store-new-product-context/StoreNewProductProvider';
import StoreNewProductModal from '../../../components/store-new-product-modal/StoreNewProductModal';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import getAllProducts from '../../../apis/store-apis/products/getAllProducts';
import UserContext from '../../../contexts/user-context/UserProvider';
import storeRouterLinks from '../../../components/app/store-routes/storeRouterLinks';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import productFormatFile from '../../../assets/files/products.xlsx';
import ExcelModal from './ExcelModal';
const StoreItemManagerTableFilter = () => {
  // const { Option } = Select;
  // const { RangePicker } = DatePicker;
  const systemSettings = useSystemSettings();
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const {
    modalOpened,
    setModalOpened,
    setSelectedProduct,
    setSelectedProductId,
    nameSearchValue,
    setNameSearchValue,
    // isLoading,
    setIsLoading,
    setAllFetchedProducts,
    setTablePagination,
    tablePagination
  } = useContext(StoreNewProductModalContext);
  const [excelModalOpened, setExcelModalOpened] = React.useState(false);
  let history = useHistory();

  const handleTableSearch = async (e) => {
    setNameSearchValue(e.target.value);
    try {
      setIsLoading(true);
      const res = await getAllProducts(user?.token, {
        ...tablePagination,
        page: tablePagination.current_page,
        searchText: e.target.value
      });
      // is response is success
      if (res?.status === 200 && res?.data?.data?.data) {
        setIsLoading(false);
        if (res.data.data.data.length >= 0) {
          const data = res.data.data.data;
          setAllFetchedProducts(data);
        }
        if (res?.data?.data?.pagination) {
          setTablePagination(res.data.data.pagination);
          history.push(`${storeRouterLinks?.storeItemManagerPage}?page=1`);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="custom-filter-add-section">
      <div className="add-section">
        {/* <div className="section-text">
          <p className="bold-font">الخدمـــات</p>
        </div> */}
        <div
          className="add-btn"
          onClick={() => {
            // if selected product then reset it
            // open the product modal
            setSelectedProductId('');
            setSelectedProduct(null);
            setModalOpened(true);
          }}
        >
          <span>{t('services.addNew')}</span>
        </div>
      </div>

      <div className="custom-table-fitler">
        <div className="form-excel-export-wrap  service-excell-wrap">
          <form className="filter-form">
            <div className="table-search">
              <label>
                <SearchIcon />
                <input
                  placeholder={t('services.searchPlaceholder')}
                  type="text"
                  name="table_search"
                  onKeyUp={handleTableSearch}
                />
              </label>
            </div>
            {/* <label className="entries-wrap">
            <Select
              defaultValue="10"
              style={{ width: 72 }}
              onChange={handleChange}
              suffixIcon={<SelectDropDown />}
            >
              <Option value="10">10</Option>
              <Option value="15">15</Option>
              <Option value="20">20</Option>
            </Select>
            <span className="select-title">إدخالات</span>
          </label>

          <div className="range-picker-wrap">
            <RangePicker
              value={selectedPicker?.moments && selectedPicker.moments}
              onChange={handlePickerChange}
              className={`filter-range-picker`}
              allowClear={false}
              suffixIcon={<CalendarIcon />}
              placeholder={['تاريخ من', 'تاريخ الى']}
            />
          </div>

          <button className="filter-submit-btn" type="submit">
            بحث
          </button> */}
          </form>
          <div className="excel-btn-wrap">
            <a
              href={`${process.env.REACT_APP_BASE_URL}/Excel/exportProducts?client_id=${systemSettings?.id}&searchText=${nameSearchValue}`}
              download
              className="excel-export-btn"
            >
              <img
                style={{ height: '42px' }}
                src={excelImg}
                alt="export to excel"
              />
            </a>
          </div>
          <div
            className="preview-download-upload add-btn"
            onClick={(e) => {
              setExcelModalOpened(true);
              e.stopPropagation();
            }}
          >
            <span className="text-span">
              {t('Inventory.Products.uploadServicesFile')}
            </span>
          </div>
          {/* <div className="excel-download-btns-wrap products-down">
            <a className="excel-download-btn" href={productFormatFile}>
              <CloudDownloadOutlined />
              <span className="label-span">
                {t('Inventory.Products.downloadProductDefinitionFile')}
              </span>
            </a>
          </div> */}
        </div>
      </div>

      {modalOpened && <StoreNewProductModal />}
      {excelModalOpened && (
        <ExcelModal
          modalOpened={excelModalOpened}
          setModalOpened={setExcelModalOpened}
        />
      )}
    </div>
  );
};

export default StoreItemManagerTableFilter;
