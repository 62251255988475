import React from 'react';

const AnalysisIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.827"
      height="19.441"
      viewBox="0 0 23.827 19.441"
    >
      <g id="analysis" transform="translate(-4.25 -15.25)">
        <path
          id="Path_1449"
          data-name="Path 1449"
          d="M26.133,15.25H6.194A1.946,1.946,0,0,0,4.25,17.194V32.547a2.146,2.146,0,0,0,2.143,2.143h19.54a2.146,2.146,0,0,0,2.143-2.143V17.194A1.946,1.946,0,0,0,26.133,15.25Zm1.246,1.944v2.329H13.948l1.691-3.576H26.133A1.248,1.248,0,0,1,27.379,17.194ZM6.194,15.948h8.673l-1.692,3.576H4.948V17.194A1.248,1.248,0,0,1,6.194,15.948Zm19.74,18.045H6.393a1.447,1.447,0,0,1-1.446-1.446V20.221H27.379V32.547A1.447,1.447,0,0,1,25.934,33.993Z"
          fill={color ? color : '#b5b5b5'}
        />
        <path
          id="Path_1450"
          data-name="Path 1450"
          d="M16.966,24.259a1.146,1.146,0,1,0-1.146-1.146A1.146,1.146,0,0,0,16.966,24.259Zm0-1.6a.449.449,0,1,1-.449.449A.449.449,0,0,1,16.966,22.664Z"
          transform="translate(-9.263 -5.377)"
          fill={color ? color : '#b5b5b5'}
        />
        <path
          id="Path_1451"
          data-name="Path 1451"
          d="M33.022,24.259a1.146,1.146,0,1,0-1.146-1.146A1.146,1.146,0,0,0,33.022,24.259Zm0-1.6a.449.449,0,1,1-.449.449A.449.449,0,0,1,33.022,22.664Z"
          transform="translate(-22.118 -5.377)"
          fill={color ? color : '#b5b5b5'}
        />
        <path
          id="Path_1452"
          data-name="Path 1452"
          d="M66.121,26.664h8.922a.349.349,0,0,0,0-.7H66.121a.349.349,0,1,0,0,.7Z"
          transform="translate(-49.255 -8.579)"
          fill={color ? color : '#b5b5b5'}
        />
        <path
          id="Path_1453"
          data-name="Path 1453"
          d="M24.1,58.537h-.847V52.961a.349.349,0,0,0-.349-.349H20.655a.349.349,0,0,0-.349.349v5.576h-.9V51.166a.349.349,0,0,0-.349-.349H16.809a.349.349,0,0,0-.349.349v7.371H15.232V49.315a.349.349,0,1,0-.7,0v9.222H13.515a.349.349,0,1,0,0,.7H24.1a.349.349,0,0,0,0-.7Zm-6.941,0V51.515h1.55v7.022Zm3.846,0V53.31h1.55v5.227Z"
          transform="translate(-7.138 -26.993)"
          fill={color ? color : '#b5b5b5'}
        />
        <path
          id="Path_1454"
          data-name="Path 1454"
          d="M85.067,55.716H79.285a.349.349,0,0,0,0,.7h5.782a.349.349,0,1,0,0-.7Z"
          transform="translate(-59.794 -32.397)"
          fill={color ? color : '#b5b5b5'}
        />
        <path
          id="Path_1455"
          data-name="Path 1455"
          d="M85.067,67.216H79.285a.349.349,0,0,0,0,.7h5.782a.349.349,0,1,0,0-.7Z"
          transform="translate(-59.794 -41.604)"
          fill={color ? color : '#b5b5b5'}
        />
        <path
          id="Path_1456"
          data-name="Path 1456"
          d="M85.067,78.716H79.285a.349.349,0,0,0,0,.7h5.782a.349.349,0,1,0,0-.7Z"
          transform="translate(-59.794 -50.811)"
          fill={color ? color : '#b5b5b5'}
        />
        <path
          id="Path_1457"
          data-name="Path 1457"
          d="M85.067,90.216H79.285a.349.349,0,0,0,0,.7h5.782a.349.349,0,1,0,0-.7Z"
          transform="translate(-59.794 -60.019)"
          fill={color ? color : '#b5b5b5'}
        />
      </g>
    </svg>
  );
};

export default AnalysisIcon;
