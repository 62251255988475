/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Table, Empty, Pagination } from 'antd';
import tableColumns from './tableColumns';
import { useEffect } from 'react';
// import catsTableData from './catsTableData';
import { useState } from 'react';
import UserContext from '../../../contexts/user-context/UserProvider';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import { useHistory, useLocation } from 'react-router-dom';
import storeRouterLinks from '../../../components/app/store-routes/storeRouterLinks';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import getAllPriceOffersApiNew from '../../../apis/new-price-offers-apis/getAllPriceOffersApiNew';
import queryString from 'query-string';
import productsTableColumns from './productsTableColumns';
import { useTranslation } from 'react-i18next';

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const PageTable = ({ allFetchedReceipts, withoutReturnBtn, isReportsPage }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const {
    fetchCount,
    isLoading,
    setIsLoading,
    setModalOpened,
    setFetchCount,
    setSelectedReceipt,
    setAllFetchedReceipts,
    setSelectedReceiptModalOpened,
    setTablePagination,
    setInstallmentModalOpened,
    tablePagination,
    tableFilter,
    setConvertModalOpened
  } = useContext(OutgoingPurchaseInvoiceContext);
  const { user } = useContext(UserContext);
  const [tableDataSource, setTableDataSource] = useState([]);

  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  const { search } = useLocation();
  const values = queryString.parse(search);

  const handleTableColumns = (us) => {
    if (us === 1) {
      return productsTableColumns(
        allFetchedReceipts,
        setModalOpened,
        setFetchCount,
        setSelectedReceipt,
        setIsLoading,
        setSelectedReceiptModalOpened,
        setConvertModalOpened,
        user?.token
      );
    } else if (us === 2) {
      return tableColumns(
        allFetchedReceipts,
        setModalOpened,
        setFetchCount,
        setSelectedReceipt,
        setIsLoading,
        setSelectedReceiptModalOpened,
        setConvertModalOpened,
        user?.token
      );
    }
  };

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let url;
    if (user.subscriptionType === 1) {
      url = '/offerPrice/all';
    } else if (user.subscriptionType === 2) {
      url = '/OfferPriceService/all';
    }
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllPriceOffersApiNew(url, user?.token, {
          ...tableFilter,
          page: values.page ? values.page : 1
        }),
        (res) => {
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data?.data?.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedReceipts(data);
          }
          if (res?.data?.data?.pagination) {
            setTablePagination(res.data.data.pagination);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedReceipts?.invoices?.length >= 0) {
      if (isMounted) {
        setTableDataSource(mapDataSource(allFetchedReceipts?.invoices));
      }
    }
    if (allFetchedReceipts?.length >= 0) {
      if (isMounted) {
        setTableDataSource(mapDataSource(allFetchedReceipts));
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedReceipts, allFetchedReceipts?.length]);

  return (
    <section>
      <Table
        locale={{
          emptyText: (
            <Empty description={false}>{t('Quotations.NoDataAvailable')}</Empty>
          )
        }}
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={handleTableColumns(user.subscriptionType)}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
      {tablePagination && (
        <Pagination
          style={{ display: 'flex', justifyContent: 'center' , marginTop:'20px' }}
          defaultCurrent={1}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}
          onChange={(page, pageSize) => {
            setTablePagination({ ...tablePagination, current_page: page });
            setFetchCount((prev) => prev + 1);
            history.push(
              `${storeRouterLinks?.priceOfferRouteNew}?page=${page}`
            );
            // if (isReportsPage) {
            //   history.push(
            //     `${storeRouterLinks?.outgoingPurchaseInvoiceReports}?page=${page}`
            //   );
            // } else {
            // else {
            //   history.push(
            //     `${storeRouterLinks?.outgoingPurchaseInvoice}?page=${page}`
            //   );
            // }
          }}
          hideOnSinglePage={true}
        />
      )}
    </section>
  );
};

export default PageTable;
