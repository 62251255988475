/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Select, DatePicker } from 'antd';
import SelectDropDown from '../../common/icons/SelectDropDown';
import UserContext from '../../contexts/user-context/UserProvider';
import useClients from '../../custom-hooks/useClients';
import getAllSaleInvoicesApi from '../../apis/sales-apis/getAllSaleInvoicesApi';
import SaleInvoicesContext from '../../contexts/sale-invoices-context/SaleInvoicesContext';
import CalendarIcon from '../../common/icons/CalendarIcon';
import usePos from '../../custom-hooks/usePos';
import excelImg from '../../assets/imgs/icons/excel-btn.png';
// import Workbook from 'react-excel-workbook';
// import Workbook from 'react-excel-workbook';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import queryString from 'query-string';
import useUsers from '../../custom-hooks/useUsers';
import userRoles from '../../userRoles';
import salesRouterLinks from '../../components/app/sales-routes/salesRouterLinks';
import invoiceFilterTypes from '../../invoiceFilterTypes';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
import { useTranslation } from 'react-i18next';
const { RangePicker } = DatePicker;

const TableFilter = () => {
  const history = useHistory();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const allDelegateUsers = useUsers(userRoles?.delegate);
  const { user } = useContext(UserContext);
  const systemSettings = useSystemSettings();
  const { tableFilter, setTableFilter, setTablePagination } =
    useContext(SaleInvoicesContext);
  const allPos = usePos();
  const { Option } = Select;
  const [selectedPicker, setSelectedPicker] = React.useState(null);

  const { setAllFetchedSaleInvoice, setIsLoadingAllSaleInvoices } =
    useContext(SaleInvoicesContext);
  const { allClients } = useClients();
  const { t } = useTranslation();
  const receiptTypes = [
    {
      title: t('CashiersalesInvoiceReport.saleInvoices'),
      value: '1'
    },
    {
      title: t('CashiersalesInvoiceReport.returnInvoices'),
      value: '2'
    },
    {
      title: t('CashiersalesInvoiceReport.bookingInvoices'),
      value: '3'
    }
  ];
  const handlePickerChange = (value, string) => {
    setSelectedPicker({
      moments: value,
      strings: string
    });
  };

  React.useEffect(() => {
    if (selectedPicker?.strings?.length > 0) {
      setTableFilter((prev) => ({
        ...prev,
        from: selectedPicker.strings[0],
        to: selectedPicker.strings[1]
      }));
    }
  }, [selectedPicker?.strings]);

  async function handleChange(filterType, value) {
    setTableFilter((prevState) => {
      if (filterType === 'client') {
        return {
          ...prevState,
          user_id: value
        };
      } else if (filterType === 'pay_id') {
        return {
          ...prevState,
          pay_id: value
        };
      } else if (filterType === 'type') {
        return {
          ...prevState,
          type: value
        };
      } else if (filterType === 'pos_id') {
        return {
          ...prevState,
          pos_id: value
        };
      } else if (filterType === 'admin_id') {
        return {
          ...prevState,
          admin_id: value
        };
      }
    });
  }

  const customApiRequest = useCustomApiRequest();

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoadingAllSaleInvoices(true);
    customApiRequest(
      getAllSaleInvoicesApi(user?.token, {
        ...tableFilter,
        ...values,
        page: 1
      }),
      (res) => {
        history.push(`${salesRouterLinks?.salesPageReports}?page=1`);
        setIsLoadingAllSaleInvoices(false);
        if (res?.status === 200 && res?.data?.data?.data) {
          if (res.data.data.data.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedSaleInvoice(data);
          }
          if (res?.data?.data?.pagination) {
            setTablePagination(res.data.data.pagination);
          }
        } else {
        }
      },
      (error) => {
        setIsLoadingAllSaleInvoices(false);
      }
    );
  }

  return (
    <div className="custom-table-fitler warehouse-table-filter">
      <div className="form-excel-export-wrap">
        <form
          className="filter-form receipt-filter-form"
          onSubmit={handleSubmit}
        >
          <label className="receipt-filter-option-wrap">
            <span className="select-title">
              {t('CashiersalesInvoiceReport.clientName')}
            </span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('client', v)}
              suffixIcon={<SelectDropDown />}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>
                {t('CashiersalesInvoiceReport.allClients')}
              </Option>
              {allClients?.length > 0 &&
                allClients.map((item) => (
                  <Option key={item.id} value={String(item.id)}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </label>

          {user?.role !== userRoles?.delegate && (
            <label className="receipt-filter-option-wrap">
              <span className="select-title">
                {t('CashiersalesInvoiceReport.representativeName')}
              </span>
              <Select
                defaultValue={null}
                style={{ width: 172 }}
                onChange={(v) => handleChange('admin_id', v)}
                suffixIcon={<SelectDropDown />}
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={null}>
                  {t('CashiersalesInvoiceReport.allRepresentatives')}
                </Option>
                {allDelegateUsers?.length > 0 &&
                  allDelegateUsers.map((item) => (
                    <Option key={item.id} value={String(item.id)}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </label>
          )}

          <label className="receipt-filter-option-wrap">
            <span className="select-title">
              {t('CashiersalesInvoiceReport.invoiceType')}
            </span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('type', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>
                {t('CashiersalesInvoiceReport.allInvoices')}
              </Option>
              {receiptTypes.map((role, index) => (
                <Option key={index} value={role.value}>
                  {role.title}
                </Option>
              ))}
            </Select>
          </label>

          <label className="receipt-filter-option-wrap">
            <span className="select-title">
              {t('CashiersalesInvoiceReport.salePoints')}
            </span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('pos_id', v)}
              suffixIcon={<SelectDropDown />}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>
                {t('CashiersalesInvoiceReport.allSalePoints')}
              </Option>
              {allPos?.length > 0 &&
                allPos.map((item) => (
                  <Option key={item.id} value={String(item.id)}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </label>
          <label className="receipt-filter-option-wrap">
            <span className="select-title">
              {t('CashiersalesInvoiceReport.paymentType')}
            </span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('pay_id', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>
                {t('CashiersalesInvoiceReport.allPaymentsTypes')}
              </Option>
              <Option value={1}>{t('CashiersalesInvoiceReport.cash')}</Option>
              <Option value={2}>
                {t('CashiersalesInvoiceReport.postpaid')}
              </Option>
              <Option value={3}>{t('CashiersalesInvoiceReport.visa')}</Option>
              <Option value={5}>
                {t('CashiersalesInvoiceReport.bankTransfer')}
              </Option>
              <Option value={6}>
                {t('CashiersalesInvoiceReport.multiplePayment')}
              </Option>
              {/* {receiptTypes.map((role, index) => (
                <Option key={index} value={role.value}>
                  {role.title}
                </Option>
              ))} */}
            </Select>
          </label>
          <div className="range-picker-wrap">
            <RangePicker
              value={selectedPicker?.moments && selectedPicker.moments}
              onChange={handlePickerChange}
              // format="YYYY-MM-DD"
              className={`filter-range-picker`}
              allowClear={true}
              suffixIcon={<CalendarIcon />}
              placeholder={[
                t('CashiersalesInvoiceReport.dateFrom'),
                t('CashiersalesInvoiceReport.dateTo')
              ]}
            />
          </div>
          <button className="filter-submit-btn" type="submit">
            {t('CashiersalesInvoiceReport.search')}
          </button>
        </form>

        <div className="excel-btn-wrap">
          <a
            href={`${
              process.env.REACT_APP_BASE_URL
            }/exportExcelInvoice?client_id=${systemSettings?.id || ''}
            &type=${1}
            &today_reservations=${tableFilter?.today_reservations || ''}
            &user_id=${tableFilter?.user_id || ''}
            &from=${tableFilter?.from || ''}
            &to=${tableFilter?.to || ''}&paymentType=${
              tableFilter?.pay_id || ''
            }
            &admin_id=${tableFilter?.admin_id || ''}
            &page=${tableFilter?.page || '1'}
            &typeN=${tableFilter?.type || ''}
            &pos_id=${tableFilter?.pos_id || ''}`}
            download
            className="excel-export-btn"
          >
            <img
              style={{ height: '42px' }}
              src={excelImg}
              alt="export to excel"
            />
          </a>

          {/* <a
            href={`${
              process.env.REACT_APP_BASE_URL
            }/exportExcelInvoice?client_id=${systemSettings?.id || ''}&type=${
              invoiceFilterTypes?.outgoingInvoice || ''
            }&manualNum=${tableFilter?.manualNum || ''}&supplier_id=${
              tableFilter?.supplier_id || ''
            }&detailsType=${tableFilter?.detailsType || ''}&from=${
              tableFilter?.from || ''
            }&to=${tableFilter?.to || ''}&admin_id=${
              tableFilter?.admin_id || ''
            }&page=${tableFilter?.page || '1'}`}
            className="excel-export-btn"
          >
            <img
              style={{ height: '42px' }}
              src={excelImg}
              alt="export to excel"
            />
          </a> */}
        </div>

        {/* <div className="excel-btn-wrap">
          <Workbook
            filename="sales_invoice.xlsx"
            element={
              <div className="excel-export-btn">
                <img
                  style={{ height: '42px' }}
                  src={excelImg}
                  alt="export to excel"
                />
              </div>
            }
          >
            <Workbook.Sheet
              data={allFetchedSaleInvoices ? allFetchedSaleInvoices : []}
              name="Sheet A"
            >
              <Workbook.Column label="مسلسل" value="invoiceNumber" />
              <Workbook.Column label="العميل" value={(row) => row?.name} />
              <Workbook.Column
                label="الموظف"
                value={(row) => row?.shift?.employee?.name}
              />
              <Workbook.Column
                label="تاريخ الفاتورة"
                value={(row) => row.date}
              />
              <Workbook.Column
                label="السعر الإجمالى"
                value={(row) => row.totalPrice}
              />
            </Workbook.Sheet>
          </Workbook>
        </div> */}
      </div>
    </div>
  );
};

export default TableFilter;
