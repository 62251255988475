/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ReactHookFormStepper from '../../common/react-hook-form-stepper/ReactHookFormStepper';
import PosStepOne from './PosStepOne';
import PosStepTwo from './PosStepTwo';
// import PosStepThree from './PosStepThree';
import './PosSteppterForm.scss';

const PosStepperForm = () => {
  return (
    // <h2>A&A</h2>
    // <>
    //   <PosStepOne stepTitle="step one" />
    //   <PosStepTwo stepTitle="step two" />
    // </>
    <ReactHookFormStepper
      className="pos-stepper-form-wrap"
      onSubmit={async (values) => {
        try {
        } catch (error) {}
      }}
    >
      <PosStepOne stepTitle="step one" />

      <PosStepTwo stepTitle="step two" />

      {/* <PosStepThree stepTitle="step three" /> */}
    </ReactHookFormStepper>
  );
};

export default PosStepperForm;
