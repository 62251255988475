/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import StoreProductsIngredientsContext from '../../../contexts/store-products-ingredients-context/StoreProductsIngredientsProvider';
import useUnits from '../../../custom-hooks/useUnits';
import useMainCategories from '../../../custom-hooks/useMainCategories';
import useSubCategories from '../../../custom-hooks/useSubCategories';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import addNewIngredientApi from '../../../apis/store-apis/ingredients/addNewIngredientApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';

const schema = Yup.object().shape({
  ingredient_name: Yup.string().required('ادخل اسم المكون'),
  ingredient_measuring_unit: Yup.string().required('اختار وحدة القياس'),
  sub_unit_id: Yup.string().required('اختار وحدة القياس الفرعية'),
  ingredient_quantity_per_unit: Yup.string()
    .required('ادخل عدد الوحدات')
    .matches(/^[0-9]+$/, 'لا يسمح الا بكتابة الارقام'),
  price: Yup.string()
    .required('ادخل سعر الوحدة')
    .matches(/^(\d+)?(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام'),
  average_price: Yup.string()
    .required('ادخل سعر الوحدة')
    .matches(/^(\d+)?(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام'),
  // .matches(/^[+-]?\d+(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام'),
  cat: Yup.string().required('اختار الفئة الاساسية'),
  sub_cat: Yup.string().required('اختار الفئة الفرعية')
});

const TableForm = () => {
  const { user } = useContext(UserContext);
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedIngredient,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(StoreProductsIngredientsContext);

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      ingredient_name: '',
      ingredient_measuring_unit: '',
      sub_unit_id: '',
      ingredient_quantity_per_unit: '',
      price: '',
      average_price: '',
      cat: '',
      sub_cat: '',
      tax: ''
    }
  });

  const allUnits = useUnits();
  const allMainCats = useMainCategories();
  const allSubCats = useSubCategories(watch('cat'));
  useEffect(() => {
    if (watch('cat') && watch('sub_cat')) {
      if (
        allSubCats?.length >= 0 &&
        allSubCats[0]?.cat_id !== parseInt(watch('cat'))
      ) {
        setValue('sub_cat', '');
      }
    }
  }, [watch('cat'), watch('sub_cat')]);

  // handle initial values
  useEffect(() => {
    if (selectedIngredient) {
      if (selectedIngredient?.name) {
        setValue('ingredient_name', selectedIngredient.name);
      }
      if (String(selectedIngredient?.unit_id)) {
        setValue(
          'ingredient_measuring_unit',
          String(selectedIngredient.unit_id)
        );
      }
      if (String(selectedIngredient?.sub_unit_id)) {
        setValue('sub_unit_id', String(selectedIngredient.sub_unit_id));
      }
      if (selectedIngredient?.unitNumbers) {
        setValue(
          'ingredient_quantity_per_unit',
          selectedIngredient.unitNumbers
        );
      }
      if (String(selectedIngredient?.price)) {
        setValue('price', String(selectedIngredient.price));
      }

      if (selectedIngredient?.tax) {
        setValue('tax', parseFloat(selectedIngredient.tax));
      }

      if (String(selectedIngredient?.priceOfUnit)) {
        setValue('average_price', String(selectedIngredient.priceOfUnit));
      }

      if (selectedIngredient?.cat) {
        setValue('cat', String(selectedIngredient?.cat?.cat_id));
      }
      if (selectedIngredient?.cat) {
        setValue('sub_cat', String(selectedIngredient?.cat?.id));
      }
    }
  }, [selectedIngredient]);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    const mappedData = {};
    if (data.ingredient_name) mappedData.name = data.ingredient_name;
    if (data.ingredient_measuring_unit)
      mappedData.unit_id = data.ingredient_measuring_unit;
    if (data.sub_unit_id) mappedData.sub_unit_id = data.sub_unit_id;
    if (data.ingredient_quantity_per_unit)
      mappedData.unitNumbers = data.ingredient_quantity_per_unit;
    if (data.price) mappedData.price = data.price;
    if (data.average_price) mappedData.priceOfUnit = data.average_price;
    if (data.sub_cat) mappedData.cat_id = data.sub_cat;
    if (data.tax) mappedData.tax = data.tax;

    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedIngredient) {
      mappedData.id = selectedIngredient.id;
      customApiRequest(
        addNewIngredientApi(user?.token, mappedData, true),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم اضافة المنتج بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message
                ? res.data.message
                : 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    } else {
      customApiRequest(
        addNewIngredientApi(user?.token, mappedData),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم اضافة المنتج بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message
                ? res.data.message
                : 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form ingredients-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">اســم المكون</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="ingredient_name"
              type="text"
              placeholder="اسـم المكون..."
              errorMsg={errors?.ingredient_name?.message}
              validateStatus={errors?.ingredient_name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="select-label-wrap">
          <p className="label-p">وحدة القياس الأساسية</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="ingredient_measuring_unit"
              errorMsg={errors?.ingredient_measuring_unit?.message}
              validateStatus={errors?.ingredient_measuring_unit ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="وحدة القياس الأساسية"
              options={
                allUnits?.length > 0 &&
                allUnits.map((unit) => ({
                  title: unit.name,
                  value: unit.id
                }))
              }
              formClassName="ingredients-form"
            />
          </div>
        </div>
        <div className="select-label-wrap">
          <p className="label-p">وحدة القياس الفرعية</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="sub_unit_id"
              errorMsg={errors?.sub_unit_id?.message}
              validateStatus={errors?.sub_unit_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="وحدة القياس الفرعية"
              options={
                allUnits?.length > 0 &&
                allUnits.map((unit) => ({
                  title: unit.name,
                  value: unit.id
                }))
              }
              formClassName="ingredients-form"
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">عدد الوحدات</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="ingredient_quantity_per_unit"
              type="text"
              placeholder="عدد الوحدات..."
              errorMsg={errors?.ingredient_quantity_per_unit?.message}
              validateStatus={
                errors?.ingredient_quantity_per_unit ? 'error' : ''
              }
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">سعر وحدة القياس الأساسية</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="price"
              type="text"
              placeholder="سعر وحدة القياس الأساسية..."
              errorMsg={errors?.price?.message}
              validateStatus={errors?.price ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">السعر التقريبى للوحدة الفرعية</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="average_price"
              type="text"
              placeholder="السعر التقريبى للوحدة الفرعية..."
              errorMsg={errors?.average_price?.message}
              validateStatus={errors?.average_price ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="cat-subcat-wrap">
          <div className="select-label-wrap">
            <p className="label-p">الفئة الرئيسية</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name="cat"
                errorMsg={errors?.cat?.message}
                validateStatus={errors?.cat ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder="الفئة الرئيسية"
                options={
                  allMainCats?.length > 0 &&
                  allMainCats.map((unit) => ({
                    title: unit.name,
                    value: unit.id
                  }))
                }
                formClassName="ingredients-form"
              />
            </div>
          </div>
          <div className="select-label-wrap">
            <p className="label-p">الفئة الفرعية</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name="sub_cat"
                errorMsg={errors?.sub_cat?.message}
                validateStatus={errors?.sub_cat ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder="الفئة الفرعية"
                options={
                  allSubCats?.length > 0 &&
                  allSubCats.map((unit) => ({
                    title: unit.name,
                    value: unit.id
                  }))
                }
                formClassName="ingredients-form"
              />
            </div>
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">الضريبة</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="tax"
              type="text"
              placeholder="الضريبة..."
              errorMsg={errors?.tax?.message}
              validateStatus={errors?.tax ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          إضافة
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
