/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { Form, Button, DatePicker } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import useUnits from '../../../custom-hooks/useUnits';
import useSubCategories from '../../../custom-hooks/useSubCategories';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import addNewIngredientApi from '../../../apis/store-apis/ingredients/addNewIngredientApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import CustomAntdDatePicker from '../../../common/antd-form-components/CustomAntdDatePicker';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import PosCashPaymentsContext from '../../../contexts/pos-cash-payments/PosCashPaymentsContext';
import createPayments from '../../../apis/pos-api/cash-payments/createPayments';
import moment from 'moment';
import updatePayment from '../../../apis/pos-api/cash-payments/updatePayment';
import getAllClientsApi from '../../../apis/clients-apis/getAllClientsApi';
import getAccountsListLeafApi from '../../../apis/accounting-apis/accounting-details-apis/getAccountsListLeafApi';
import getAllSuppliersApi from '../../../apis/store-apis/suppliers/getAllSuppliersApi';
import checkRes from '../../../utils/checkRes';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';

const schema = Yup.object().shape({
  title: Yup.string().required('ادخل ملاحظات الدفع '),
  account_id: Yup.string().required('اختر الحساب '),
  payment_method_id: Yup.string().optional('اختر طريقة الدفع '),
  supplier_id: Yup.string().required('اختر المورد '),
  username: Yup.string().required('ادخل الإسم'),
  price_word: Yup.string().required('ادخل المبلغ بالأحرف'),
  department_id: Yup.string().required('ادخل القسم'),
  date: Yup.mixed().required('اختار تاريخ الصرف'),
  amount: Yup.string()
    .required('ادخل المبلغ')
    .matches(/^(\d+)?(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام')
});

const TableForm = ({ departments }) => {
  const { user } = useContext(UserContext);
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedCash,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(PosCashPaymentsContext);

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      date: selectedCash ? moment(selectedCash?.date) : null,
      title: '',
      amount: '',
      department_id: '',
      supplier_id: '',
      payment_method_id: '',
      account_id: '',
      price_word: '',
      username: '',
    }
  });


  // handle initial values
  useEffect(() => {
    if (selectedCash) {
      if (selectedCash?.date) {
        setValue('date', selectedCash?.date);
      }
      if (selectedCash?.title) {
        setValue('title', selectedCash.title);
      }
      if (selectedCash?.amount) {
        setValue('amount', selectedCash.amount);
      }
      if (selectedCash?.department) {
        setValue('department_id', selectedCash.department.name);
      }
    }
  }, [selectedCash]);

  const systemSettings = useSystemSettings();
  const payments = systemSettings?.invoiceSetting?.payment_methods_objects
  const [accountsList , setAccountsLists] = useState()
  const [allSuppliers , setAllSuppliers] = useState()
  useEffect(() => {
    let api = getAccountsListLeafApi(user?.token, user?.client_id);
    customApiRequest(api, (res) => {
        if (res?.data?.accounts) {
            setAccountsLists(res?.data?.accounts);
        }
    });
  }, []);

  useEffect(() => {
      customApiRequest(
        getAllSuppliersApi(user?.token),
        (res) => {
          if (checkRes(res) && res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllSuppliers(data);
          }
        },
        (error) => {
        }
      );
  }, []);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    // const mappedData = {};
    const mappedData = new FormData();
    if (data.amount) mappedData.append('amount', data.amount);
    if (data.title) mappedData.append('title', data.title);
    if (data.date) mappedData.append('date', moment(data.date).format('YYYY-MM-DD'));
    if (data.department_id) mappedData.append('department_id', data.department_id);
    if (data.supplier_id) mappedData.append('supplier_id', data.supplier_id);
    if (data.payment_method_id) mappedData.append('payment_method_id', data.payment_method_id);
    if (data.account_id) mappedData.append('account_id', data.account_id);
    if (data.price_word) mappedData.append('price_word', data.price_word);
    mappedData.append('type', 2);
    // mappedData.append('type', 1);

    // receipt = 1 & expense = 2
    // mappedData.type = 1;
    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedCash) {
      mappedData.append('id', selectedCash.id);
      // mappedData.id = selectedCash.id;
      customApiRequest(
        createPayments(user?.token, mappedData, true),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم التعديل علي سند صرف بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message
                ? res.data.message
                : 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    } else {
      customApiRequest(
        createPayments(user?.token, mappedData),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم اضافة سند صرف بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message
                ? res.data.message
                : 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  const [form] = Form.useForm();
  const { field } = useController({
    name: 'date',
    control
  });
  return (
    <Form
      className="custom-shared-form ingredients-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">المبلغ</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="amount"
              type="text"
              placeholder="المبلغ..."
              errorMsg={errors?.amount && errors.amount.message}
              validateStatus={errors?.amount ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">أدخل المبلغ بالأحرف</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="price_word"
              type="text"
              placeholder="أدخل المبلغ بالأحرف..."
              errorMsg={errors?.price_word && errors.price_word.message}
              validateStatus={errors?.price_word ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">أدخل الإسم</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="username"
              type="text"
              placeholder="أدخل الإسم..."
              errorMsg={errors?.username && errors.username.message}
              validateStatus={errors?.username ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">ملاحظات</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="title"
              rows={4}
              placeholder="ملاحظات..."
              errorMsg={errors?.title?.message}
              validateStatus={errors?.title ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="date-picker-wrap">
          <p className="label-p">تاريخ الصرف ( DD / MM / YYYY )</p>
          <DatePicker
            name="date"
            // format="YYYY-MM-DD"
            size="large"
            {...field}
            // control={control}
            defaultValue={selectedCash?.date && moment(selectedCash?.date)}
            // bordered={false}
            setValue={setValue}
            placeholder="تاريخ الصرف..."
            className="receipt-date-picker"
            formClassName="create-receipt-form"
          />
          {errors?.date && <p className="error-p">{errors.date.message}</p>}
        </div>

        <div className="select-label-wrap">
          <p className="label-p">اختر نوع الحساب</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="account_id"
              errorMsg={errors?.account_id?.message}
              validateStatus={errors?.account_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="اختر نوع الحساب"
              options={
                accountsList?.length > 0 &&
                accountsList.map((acc) => ({
                  title: acc.holder,
                  value: acc.id
                }))
              }
              formClassName="ingredients-form"
            />
          </div>
        </div>
        <div className="select-label-wrap">
          <p className="label-p">اختر المورد</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="supplier_id"
              errorMsg={errors?.supplier_id?.message}
              validateStatus={errors?.supplier_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="اختر المورد"
              options={
                allSuppliers?.length > 0 &&
                allSuppliers.map((client) => ({
                  title: client.name,
                  value: client.id
                }))
              }
              formClassName="ingredients-form"
            />
          </div>
        </div>
        <div className="select-label-wrap">
          <p className="label-p">طريقه الدفع</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="payment_method_id"
              errorMsg={errors?.payment_method_id?.message}
              validateStatus={errors?.payment_method_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="ادخل طريقه الدفع"
              options={
                payments?.length > 0 &&
                payments.map((pay) => ({
                  title: pay.name,
                  value: pay.id
                }))
              }
              formClassName="ingredients-form"
            />
          </div>
        </div>
        <div className="select-label-wrap">
          <p className="label-p">القسم</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="department_id"
              errorMsg={errors?.department_id?.message}
              validateStatus={errors?.department_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="القسم"
              options={
                departments?.length > 0 &&
                departments.map((department) => ({
                  title: department.name,
                  value: department.id
                }))
              }
              formClassName="ingredients-form"
            />
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedCash ? 'تعديل' : 'إضافة'}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
