import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedPos: null,
  setAllFetchedPos: (v) => {},
  selectedPos: null,
  setSelectedPos: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {}
};

const SettingsPosContext = createContext(INITIAL_VALUES);

export const SettingsPosProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedPos, setAllFetchedPos] = useState(
    INITIAL_VALUES.allFetchedPos
  );
  const [selectedPos, setSelectedPos] = useState(INITIAL_VALUES.selectedPos);
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );

  return (
    <SettingsPosContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedPos,
        setAllFetchedPos,
        selectedPos,
        setSelectedPos,
        isSubmittingForm,
        setIsSubmittingForm
      }}
    >
      {children}
    </SettingsPosContext.Provider>
  );
};

export default SettingsPosContext;
