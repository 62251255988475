/* eslint-disable react-hooks/exhaustive-deps */
import { UploadOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import createNewDocumentApi from '../../../apis/settings-apis/documents-apis/createNewDocumentApi';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import ArchiveDocsContext from '../../../contexts/archive-contexts/ArchiveDocsProvider';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';

// const SUPPORTED_FORMATS = {
//   pdf: ['application/pdf'],
//   pdf: ['application/pdf'],
//   images: ['image/jpg', 'image/jpeg', 'image/png'],
//   pdfImgs: ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png']
// };

const FILE_SIZE = 1024 * 1024 * 20; // 1024 * 1024 bytes = 1024 kbytes = 1 megabytes

const TableForm = () => {
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedDoc,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(ArchiveDocsContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  let schema;
  if (selectedDoc) {
    schema = Yup.object().shape({
      name: Yup.string().required(t('Documents.EnterDocumentTitle')),
      file: Yup.mixed()
        // .test('fileFormat', 'صيغة الملف غير مدعومة', (value) => {
        //   let result = true;
        //   if (value?.length > 0) {
        //     if (
        //       value[0] &&
        //       // typeof value.arrayBuffer === "function" &&
        //       !SUPPORTED_FORMATS.pdfImgs.includes(value[0].type)
        //     ) {
        //       result = false;
        //     }
        //   }
        //   return result;
        // })
        .test('fileSize', 'حجم الملف كبير', (value) => {
          let result = true;
          if (value?.length > 0) {
            if (value[0] && value[0].size > FILE_SIZE) {
              result = false;
            }
          }
          return result;
        })
    });
  } else
    schema = Yup.object().shape({
      name: Yup.string().required(t('Documents.EnterDocumentTitle')),
      file: Yup.mixed()
        .required(t('Documents.ChooseDocumentFile'))
        // .test('fileFormat', 'صيغة الملف غير مدعومة', (value) => {
        //   let result = true;
        //   if (value?.length > 0) {
        //     if (
        //       value[0] &&
        //       // typeof value.arrayBuffer === "function" &&
        //       !SUPPORTED_FORMATS.pdfImgs.includes(value[0].type)
        //     ) {
        //       result = false;
        //     }
        //   }
        //   return result;
        // })
        .test('fileSize', 'حجم الملف كبير', (value) => {
          let result = true;
          if (value?.length > 0) {
            if (value[0] && value[0].size > FILE_SIZE) {
              result = false;
            }
          }
          return result;
        })
    });

  const {
    control,
    setValue,
    handleSubmit,
    register,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      file: null
    }
  });

  useEffect(() => {
    register('file');
  }, []);

  const customApiRequest = useCustomApiRequest();

  const onSubmit = async (data) => {
    const formData = new FormData();
    if (data.name) {
      formData.append('title', data.name);
    }
    if (data.file?.length > 0) {
      formData.append('document', data.file[0]);
    }
    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedDoc) {
      formData.append('id', selectedDoc.id);
      customApiRequest(
        createNewDocumentApi(user?.token, formData, true),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Messages.addedSuccessfully'),

              message: t('Messages.Modifiedsuccessfully')
            });
          } else {
            errorNotification({
              title: t('Messages.ErrorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Messages.TryAgainLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message:
              error?.response?.data?.message || t('Messages.TryAgainLater')
          });
        }
      );
    } else {
      customApiRequest(
        createNewDocumentApi(user?.token, formData),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Messages.addedSuccessfully'),
              message: t('Messages.addsuccessfully')
            });
          } else {
            errorNotification({
              title: t('Messages.ErrorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Messages.TryAgainLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message:
              error?.response?.data?.message || t('Messages.TryAgainLater')
          });
        }
      );
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedDoc) {
      setValue('name', selectedDoc?.title ? selectedDoc.title : '');
    }
  }, [selectedDoc, selectedDoc?.id]);

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Documents.DocumentTitle')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="name"
              type="text"
              placeholder={`${t('Documents.DocumentTitle')}... `}
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="upload-doc-wrap">
          <div className="title-input-wrap">
            <div className="input-title">{t('Documents.DocumentFile')}</div>
            <label className="upload-file-label">
              {/* <Field name="cv" type="file" /> */}
              <input
                id="file"
                type="file"
                {...register('file')}
                // onChange={(e) => handleFileUpload('file', e)}
              />
              {watch('file')?.length ? (
                watch('file')[0]?.name &&
                watch('file')[0]
                  .name.split(/(\\|\/)/g)
                  .pop()
              ) : (
                <>
                  <span className="icon-text">
                    <UploadOutlined />
                    {t('Documents.UploadFile')}
                  </span>
                </>
              )}
            </label>
          </div>
          {errors?.file?.message && (
            <p className="error-p">{errors?.file.message}</p>
          )}
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {t('Documents.addition')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
