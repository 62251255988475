/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Table, Empty } from 'antd';
import tableColumns from './tableColumns';
import SettingsSubCategoriesContext from '../../../contexts/settings-contexts/SettingsSubCategoriesProvider';
import { useEffect } from 'react';
import { useState } from 'react';
import getAllCategoriesApi from '../../../apis/settings-apis/categories-apis/getAllCategoriesApis';
import { useParams } from 'react-router-dom';
import UserContext from '../../../contexts/user-context/UserProvider';
import checkRes from '../../../utils/checkRes';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useTranslation } from 'react-i18next';
// import pageData from './pageData';
// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const PageTable = ({ setAddModal }) => {
  const { catId } = useParams();
  const { t } = useTranslation();
  const {
    isLoading,
    setIsLoading,
    allFetchedSubCategories,
    setAllFetchedSubCategories,
    setModalOpened,
    fetchCount,
    setFetchCount,
    setSelectedSubCategory
  } = useContext(SettingsSubCategoriesContext);
  const { user } = useContext(UserContext);
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: index + 1,
      name: obj?.name ? obj.name : '',
      categoryDescription: obj?.description ? obj.description : ''
    }));
  };

  const customApiRequest = useCustomApiRequest();
  // level 2 ===> sub category
  // cat_id ===> id of the main category
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllCategoriesApi(user?.token, 2, catId),
        (res) => {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            const data = res.data.data;
            setAllFetchedSubCategories(data);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedSubCategories?.length >= 0) {
      const mappedData = mapDataSource(allFetchedSubCategories);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedSubCategories, allFetchedSubCategories?.length]);

  return (
    <>
      <Table
        locale={{
          emptyText: (
            <Empty description={false}>
              {t('Subcategories.noDataAvailable')}
            </Empty>
          )
        }}
        pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedSubCategories,
          setModalOpened,
          setFetchCount,
          setSelectedSubCategory,
          setAddModal,
          setIsLoading,
          user?.token,
          t
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
    </>
  );
};

export default PageTable;
