import { useContext } from 'react';
import UserContext from '../../../contexts/user-context/UserProvider';
import protectMe from '../../../utils/protectMe';
// import routerLinks from '../routerLinks';
import TaxInvoiceReportsPage from '../../../pages/reports-pages/taxinvoice-reports-page/TaxInvoiceReportsPage';
import { OutgoingPurchaseInvoiceProvider } from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import { IncomingPurchaseInvoiceProvider } from '../../../contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import { TaxInvoiceReportsProvider } from '../../../contexts/reports-contexts/TaxInvoiceReportsContext';
import { Route, Switch } from 'react-router-dom';
import NotFoundPage from '../../../pages/not-found-page/NotFoundPage';
import PrintInvoicePage from '../../../pages/print-invoice-page/PrintInvoicePage';
import SaleInvoicesPage from '../../../pages/sale-invoices-page-pos/SaleInvoicesPage';
import ReservationPage from '../../../pages/reservation-page/ReservationPage';
import { ReservationProvider } from '../../../contexts/reservation-context/ReservationContext';
import Links from '../Links';
import { ReactHookFormProvider } from '../../../contexts/react-hook-form-context/ReactHookFormContext';
import HomePage from '../../../pages/pos-page/HomePage';
import { StopwatchProvider } from '../../../contexts/stopwatch-context/StopwatchContext';
import PosInvoiceContext, { PosInvoiceProvider } from '../../../contexts/pos-invoice-context/PosInvoiceContext';
import { PosReportProvider } from '../../../contexts/pos-report-context/PosReportContext';
import ClientsPosPage from '../../../pages/clients-page-pos/ClientsPosPage';
import { ClientsPosProvider } from '../../../contexts/clients-context-pos/ClientsPosContext';
import PosShiftsPage from '../../../pages/pos-shift-page/PosShiftsPage';
import PriceOfferPageNew from '../../../pages/store-pages/price-offer-page-new/PriceOfferPageNew';
import CashOut from '../../../pages/cash-payments-pos/cash-out/CashOut';
import { PosCashPaymentsProvider } from '../../../contexts/pos-cash-payments/PosCashPaymentsContext';
import CashIn from '../../../pages/cash-payments-pos/cash-in/CashIn';

const PosRoutes = () => {
  const { loggedIn, user } = useContext(UserContext);
  const routerLinks = Links();
  const {
    selectedInvoice,
  } = useContext(PosInvoiceContext);
  return [
    protectMe(
      routerLinks.posPage,
      <HomePage />,
      1,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.notFound,
      <NotFoundPage />,
      191,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.printInvoicePage(),
      <PosInvoiceProvider>
        <PrintInvoicePage selectedInvoice={selectedInvoice}/>
      </PosInvoiceProvider>,
      201,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.posPage,
      <PosShiftsPage />,
      2,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),

    protectMe(
      routerLinks.saleInvoicesPosPage,
      <SaleInvoicesPage />,
      3,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),

    protectMe(
      routerLinks.clientsPage,
      <ClientsPosProvider>
        <ClientsPosPage />
      </ClientsPosProvider>,
      4,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),

    protectMe(
      routerLinks.reservationsPage,
      <ReservationProvider>
        <ReservationPage />
      </ReservationProvider>,
      5,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.cashOutPage,
      <PosCashPaymentsProvider>
        <CashOut />
      </PosCashPaymentsProvider>,
      6,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.cashInPage,
      <PosCashPaymentsProvider>
        <CashIn/>
      </PosCashPaymentsProvider>,
      7,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),

    protectMe(
      routerLinks?.priceOfferRouteNew,
      <IncomingPurchaseInvoiceProvider>
        <PriceOfferPageNew />
      </IncomingPurchaseInvoiceProvider>,
      'price_offer_page_new_key',
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    )

    // // Page not found
    // <Route path="*" component={NotFoundPage} />
  ];
};

export default PosRoutes;
