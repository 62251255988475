/* eslint-disable react-hooks/exhaustive-deps */
import { Descriptions, Empty, Modal, Spin, Table } from 'antd';
import FileSaver from 'file-saver';
import parse from 'html-react-parser';
import React, { useContext } from 'react';
import getSingleProductApi from '../../../apis/store-apis/products/getSingleProductApi';
import StoreNewProductModalContext from '../../../contexts/store-new-product-context/StoreNewProductProvider';
import UserContext from '../../../contexts/user-context/UserProvider';
import './SelectedProudctModal.scss';
import { useTranslation } from 'react-i18next';

const saveFile = (url, title) => {
  FileSaver.saveAs(url, title);
};
const SelectedProductModal = () => {
  const { t } = useTranslation();
  const [requestLoading, setRequestLoading] = React.useState(false);
  const { user } = useContext(UserContext);
  const {
    selectedProduct,
    setSelectedProduct,
    selectedProductModalOpened,
    setSelectedProductModalOpened,
    setSelectedProductId,
    selectedProductId
  } = React.useContext(StoreNewProductModalContext);

  React.useEffect(() => {
    if (selectedProductModalOpened) {
      const fetchSingleProduct = async () => {
        try {
          setRequestLoading(true);
          const res = await getSingleProductApi(selectedProductId, user?.token);
          if (res?.status === 200 && res?.data?.data) {
            setRequestLoading(false);
            setSelectedProduct({ ...res.data.data });
          }
        } catch (err) {
          setRequestLoading(false);
        }
      };
      fetchSingleProduct();
    }
  }, [selectedProductModalOpened]);

  if (selectedProduct && !requestLoading) {
    const renderMaterialsTable = (arr) => {
      return (
        <Table
          locale={{
            emptyText: (
              <Empty description={false}>
                {t('Inventory.Products.noDataAvailable')}
              </Empty>
            )
          }}
          pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
          className="antd-custom-table"
          dataSource={selectedProduct?.productData?.materials.map(
            (material) => ({
              ...material,
              key: material.id
            })
          )}
          columns={[
            {
              title: t('Inventory.Products.ingredientCode'),
              dataIndex: selectedProduct?.productData?.id,
              render: (_, record) => {
                return (
                  <div className="row-cell row-index bold-font">
                    <span># {record.id}</span>
                  </div>
                );
              }
            },
            {
              title: t('Inventory.Products.ingredientName'),
              dataIndex: selectedProduct?.productData?.name,
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>{record.name ? record.name : '-----'}</span>
                  </div>
                );
              }
            },
            {
              title: t('Inventory.Products.quantity'),
              dataIndex: selectedProduct?.productData?.quantity,
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>{record.quantity ? record.quantity : '-----'}</span>
                  </div>
                );
              }
            },
            {
              title: t('Inventory.Products.ingredientPrice'),
              dataIndex: 'price',
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>{record.price ? record.price : '-----'}</span>
                  </div>
                );
              }
            },
            {
              title: t('Inventory.Products.category'),
              dataIndex: 'cat',
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>{record.cat?.name ? record.cat.name : '-----'}</span>
                  </div>
                );
              }
            }
          ]}
          // loading={isLoading}
          // scroll={{ x: 400 }}
          footer={false}
        />
      );
    };

    const renderIngredientsTable = (arr) => {
      const sum = arr.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue?.buyPrice), 0);
      return (
        <div className="models-wrap pagebreak" style={{ marginTop: '30px' }}>
          <h3 style={{ marginBottom: '8px' }}>
            {t('Inventory.Products.ingredientsDetails')}
          </h3>
          <div className="receiptDetails-wrapper">
            <Table
              bordered
              // scroll={{ x: 1032 }}
              locale={{
                emptyText: (
                  <Empty description={false}>
                    {t('Inventory.Products.noDataAvailable')}
                  </Empty>
                )
              }}
              pagination={false}
              // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
              // className="antd-custom-table"
              dataSource={
                arr &&
                arr.map((material) => ({
                  ...material,
                  key: material.id
                }))
              }
              columns={[
                {
                  title: t('Inventory.Products.productCode'),
                  dataIndex: 'id',
                  render: (_, record) => {
                    return (
                      <div className="row-cell row-text">
                        <span># {record?.id}</span>
                      </div>
                    );
                  }
                },
                {
                  title: t('Inventory.Products.name'),
                  dataIndex: 'name',
                  render: (_, record) => {
                    return (
                      <div className="row-cell row-text">
                        <span>{record?.name || '----'}</span>
                      </div>
                    );
                  }
                },
                {
                  title: t('Inventory.Products.quantity'),
                  dataIndex: 'quantity',
                  render: (_, record) => {
                    return (
                      <div className="row-cell row-text">
                        <span>{record?.quantity || '----'}</span>
                      </div>
                    );
                  }
                },

                {
                  title: t('Inventory.Products.productPrice'),
                  dataIndex: 'salePrice',
                  render: (_, record) => {
                    return (
                      <div className="row-cell row-text">
                        <span>{record?.buyPrice || '----'}</span>
                      </div>
                    );
                  }
                }
              ]}
              // loading={isLoading}
              // scroll={{x:false}}
              footer={false}
            />
          </div>
          <Descriptions
            bordered
            column={1}
            
          >
            <Descriptions.Item style={{height: '50px'}} label={t('Inventory.Products.total')}>
              <h2 style={{textAlign:'center'}}>{sum}</h2>
            </Descriptions.Item>
          </Descriptions>
        </div>
      );
    };
    const renderComplexProductsTable = (arr) => {
      return (
        <div className="models-wrap pagebreak" style={{ marginTop: '30px' }}>
          <h3 style={{ marginBottom: '8px' }}>
            {t('Inventory.Products.productsDetails')}
          </h3>
          <div className="receiptDetails-wrapper">
            <Table
              bordered
              // scroll={{ x: 1032 }}
              locale={{
                emptyText: (
                  <Empty description={false}>
                    {t('Inventory.Products.noDataAvailable')}
                  </Empty>
                )
              }}
              pagination={false}
              // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
              // className="antd-custom-table"
              dataSource={
                arr &&
                arr.map((material) => ({
                  ...material,
                  key: material.id
                }))
              }
              columns={[
                {
                  title: t('Inventory.Products.productCode'),
                  dataIndex: 'id',
                  render: (_, record) => {
                    return (
                      <div className="row-cell row-text">
                        <span># {record?.id}</span>
                      </div>
                    );
                  }
                },
                {
                  title: t('Inventory.Products.name'),
                  dataIndex: 'name',
                  render: (_, record) => {
                    return (
                      <div className="row-cell row-text">
                        <span>{record?.name || '----'}</span>
                      </div>
                    );
                  }
                },
                {
                  title: t('Inventory.Products.quantity'),
                  dataIndex: 'quantity',
                  render: (_, record) => {
                    return (
                      <div className="row-cell row-text">
                        <span>{record?.quantity || '----'}</span>
                      </div>
                    );
                  }
                },

                {
                  title: t('Inventory.Products.productPrice'),
                  dataIndex: 'salePrice',
                  render: (_, record) => {
                    return (
                      <div className="row-cell row-text">
                        <span>{record?.salePrice || '----'}</span>
                      </div>
                    );
                  }
                }
              ]}
              // loading={isLoading}
              // scroll={{x:false}}
              footer={false}
            />
          </div>
        </div>
      );
    };
    const renderModalBody = () => {
      return (
        <div className="product-details-wrap">
          <Descriptions
            title={t('Inventory.Products.productDetails')}
            bordered
            column={1}
          >
            {selectedProduct?.productData?.id && (
              <Descriptions.Item label={t('Inventory.Products.code')}>
                {selectedProduct?.productData?.id}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.name && (
              <Descriptions.Item label={t('Inventory.Products.productName')}>
                {selectedProduct?.productData?.name}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.cat?.parent && (
              <Descriptions.Item label={t('Inventory.Products.mainCategory')}>
                {selectedProduct?.productData?.cat?.parent}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.cat?.name && (
              <Descriptions.Item label={t('Inventory.Products.subCategory')}>
                {selectedProduct?.productData?.cat?.name}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.product_type != 2 &&
              String(selectedProduct?.productData?.quantity) && (
                <Descriptions.Item
                  label={t('Inventory.Products.productQuantity')}
                >
                  {selectedProduct?.productData?.quantity.toFixed(2)}
                </Descriptions.Item>
              )}
            {String(selectedProduct?.productData?.unit?.name) && (
              <Descriptions.Item label={t('Inventory.Products.measruingUnit')}>
                {selectedProduct?.productData?.unit?.name}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.product_type != 2 &&
              String(selectedProduct?.productData?.buyPrice) && (
                <Descriptions.Item
                  label={t('Inventory.Products.purchasingPrice')}
                >
                  {selectedProduct?.productData?.buyPrice}
                </Descriptions.Item>
              )}
            {String(selectedProduct?.productData?.salePrice) && (
              <Descriptions.Item label={t('Inventory.Products.sellingPrice')}>
                {selectedProduct?.productData?.salePrice}
              </Descriptions.Item>
            )}
            {String(selectedProduct?.productData?.wholesalePrice) && (
              <Descriptions.Item label={t('Inventory.Products.wholePrice')}>
                {selectedProduct?.productData?.wholesalePrice}
              </Descriptions.Item>
            )}
            {String(selectedProduct?.productData?.alarmQuantity) && (
              <Descriptions.Item label={t('Inventory.Products.alertQuantity')}>
                {selectedProduct?.productData?.alarmQuantity}
              </Descriptions.Item>
            )}
            {String(selectedProduct?.productData?.tax) && (
              <Descriptions.Item label={t('Inventory.Products.tax')}>
                {selectedProduct?.productData?.tax}
                {/* {selectedProduct?.productData?.tax + ' %'} */}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.including_tax ? (
              <Descriptions.Item
                label={t('Inventory.Products.productIncludeTax')}
              >
                {t('Inventory.Products.yes')}
              </Descriptions.Item>
            ) : (
              <Descriptions.Item
                label={t('Inventory.Products.productIncludeTax')}
              >
                {t('Inventory.Products.no')}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.defaultParCode && (
              <Descriptions.Item label={t('Inventory.Products.defaultBarcode')}>
                {selectedProduct?.productData?.defaultParCode}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.parCode && (
              <Descriptions.Item
                label={t('Inventory.Products.generatedBarcode')}
              >
                {selectedProduct?.productData?.parCode}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.parCode && (
              <Descriptions.Item
                label={t('Inventory.Products.productDescription')}
              >
                {parse(
                  selectedProduct?.productData?.desc
                    ? selectedProduct?.productData?.desc
                    : ''
                )}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.end_date && (
              <Descriptions.Item
                label={t('Inventory.Products.offerExpirationDate')}
              >
                {parse(
                  selectedProduct?.productData?.end_date
                    ? selectedProduct?.productData?.end_date
                    : ''
                )}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.parCode && (
              <Descriptions.Item label={t('Inventory.Products.productImage')}>
                <img
                  style={{ maxHeight: 182, objectFit: 'contain' }}
                  src={selectedProduct?.productData?.image}
                  alt={selectedProduct?.productData?.name}
                  onClick={() =>
                    saveFile(
                      selectedProduct?.productData?.image,
                      selectedProduct?.productData?.name
                    )
                  }
                />
              </Descriptions.Item>
            )}
          </Descriptions>
          {selectedProduct?.productData?.type === 2 &&
            selectedProduct?.productData?.materials?.length > 0 &&
            renderMaterialsTable(selectedProduct?.productData?.materials)}
          {selectedProduct?.productData?.product_type === 7 &&
            selectedProduct?.productData?.ingredients?.length > 0 &&
            renderIngredientsTable(selectedProduct?.productData?.ingredients)}
          {selectedProduct?.productData?.product_type === 5 &&
            selectedProduct?.productData?.complex_products?.length > 0 &&
            renderComplexProductsTable(
              selectedProduct?.productData?.complex_products
            )}
        </div>
      );
    };

    return (
      <Modal
        className="shared-custom-modal selected-product-modal"
        width="90%"
        style={{ maxWidth: '942px' }}
        title={t('Inventory.Products.productDetails')}
        visible={selectedProductModalOpened}
        onOk={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        onCancel={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        footer={false}
      >
        {renderModalBody()}
        {/* <pre style={{ direction: 'ltr' }}>
        {JSON.stringify(selectedProduct, null, 2)}
      </pre> */}
      </Modal>
    );
  } else if (requestLoading && !selectedProduct)
    return (
      <Modal
        className="shared-custom-modal selected-product-modal"
        width="90%"
        style={{ maxWidth: '942px' }}
        title={t('Inventory.Products.productDetails')}
        visible={selectedProductModalOpened}
        onOk={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        onCancel={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        footer={false}
      >
        <div style={{ height: 232, display: 'grid', placeItems: 'center' }}>
          <Spin />
        </div>
      </Modal>
    );
  else {
    return (
      <Modal
        className="shared-custom-modal selected-product-modal"
        width="90%"
        style={{ maxWidth: '942px' }}
        title={t('Inventory.Products.productDetails')}
        visible={selectedProductModalOpened}
        onOk={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        onCancel={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        footer={false}
      >
        {
          !selectedProduct ? 
          <div style={{ height: 232, display: 'grid', placeItems: 'center' }}>
            <Spin />
          </div>
          :
          <div style={{ height: 132, display: 'grid', placeItems: 'center' }}>
            {t('Inventory.Products.noDataAvailable')}
          </div>
        }
      </Modal>
    );
  }
};

export default SelectedProductModal;
