import React from 'react';

const HomeIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.156"
      height="19.158"
      viewBox="0 0 20.156 19.158"
    >
      <path
        id="Path_2669"
        data-name="Path 2669"
        d="M19.818,21.187l-1.7-1.552V14.491a.593.593,0,0,0-.593-.593h-4.1a.593.593,0,0,0-.593.593v.33l-2.056-1.874a1.029,1.029,0,0,0-1.392,0L.339,21.187a1.034,1.034,0,0,0,.7,1.8H2.479v8.256a.593.593,0,0,0,.593.593H8.029a.593.593,0,0,0,.593-.593V26.228h2.913v5.013a.593.593,0,0,0,.593.593h4.957a.593.593,0,0,0,.593-.593V22.985h1.445a1.034,1.034,0,0,0,.7-1.8Zm-2.734.612a.593.593,0,0,0-.593.593v8.256H12.72V25.635a.593.593,0,0,0-.593-.593h-4.1a.593.593,0,0,0-.593.593v5.013H3.665V22.392a.593.593,0,0,0-.593-.593H1.427l8.651-7.883L13.024,16.6a.593.593,0,0,0,.992-.438V15.084H16.93V19.9a.593.593,0,0,0,.194.438L18.729,21.8Z"
        transform="translate(0 -12.676)"
        fill={color ? color : '#b5b5b5'}
      />
    </svg>
  );
};

export default HomeIcon;
