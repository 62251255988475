/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import ceateNewClientApi from '../../../apis/clients-apis/createNewClientApi';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import EmailIcon from '../../../common/icons/EmailIcon';
import LocationIcon from '../../../common/icons/LocationIcon';
import PhoneIcon from '../../../common/icons/PhoneIcon';
import UserIcon from '../../../common/icons/UserIcon';
import ClientsContext from '../../../contexts/clients-context/ClientsContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';

const InvoiceClientForm = ({ setAllClientsFetchCount }) => {
  const {
    setIsLoading,
    setModalOpened,
    selectedClient,
    setFetchCount,
    setSelectedClientId,
    setModalOpenedClintHome,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(ClientsContext);
  const { t } = useTranslation();
  // const { setTableFilter, setFetchCountInvoice=setFetchCount } = useContext(
  //   OutgoingPurchaseInvoiceContext
  // );
  const { user } = useContext(UserContext);
  // console.log(user)
  // let schema;
  // if (!selectedClient) {
  //   schema = Yup.object().shape({
  //     name: Yup.string().required('ادخل اســم العميل'),
  //     phone: Yup.string()
  //       .required('ادخل رقم هاتف العميل')
  //       .matches(/^[0-9]+$/, 'لا يسمح الا بكتابة الارقام')
  //       .min(10, 'أقل حد 10 ارقام'),
  //     email: Yup.string().email(t('UserProfile.emailValidation'))
  //   });
  // } else {
  //   schema = Yup.object().shape({
  //     name: Yup.string().required('ادخل اسم المستخدم'),
  //     phone: Yup.number()
  //       .required('ادخل رقم الهاتف')
  //       .typeError('لا يسمح الا بكتابة ارقام')
  //   });
  // }
  const schema = Yup.object().shape({
    name: Yup.string().required(t('Customers.EnterCustomerName')),
    phone: Yup.string()
      .required(t('UserProfile.phoneValidation'))
      .matches(/^[0-9]+$/, t('UserProfile.onlyNumbersAllowed'))
      .min(10, t('UserProfile.Minimumdigits')),
    email: Yup.string().email(t('UserProfile.emailValidation')),

    // buildingNum: Yup.string()
    //   .required('ادخل رقم المبنى')
    //   .matches(/^[0-9]+$/, 'رقم المبنى ارقام فقط'),
    // taxNumber: Yup.string()
    //   .required('ادخل الرقم الضريبى')
    //   .matches(/^[0-9]+$/, 'لا يسمح الا بكتابة الارقام')
    taxNumber: Yup.string().test(
      'taxNumber',
      t('Messages.noDataAvailable'),
      (v, context) => {
        let result = true;
        if (v && !v.match(/^[0-9]+$/)) {
          result = false;
        }
        return result;
      }
    ),
    commercialRecord: Yup.string().test(
      'commercialRecord',
      t('Messages.noDataAvailable'),
      (v, context) => {
        let result = true;
        if (v && !v.match(/^[0-9]+$/)) {
          result = false;
        }
        return result;
      }
    )
    // canLogin: Yup.string().required('اختار نوع العميل')
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    // defaultValues: {
    //   name: '',
    //   phone: '',
    //   email: ''
    // }
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      address: '',
      country: '',
      city: '',
      area: '',
      company: '',
      activity: '',
      taxNumber: '',
      commercialRecord: '',
      note: '',
      commercialNumber: ''
      // canLogin: ''
    }
  });
  const customApiRequest = useCustomApiRequest();
  // const onSubmit = async (data) => {
  //   const apiData = {
  //     name: data?.name,
  //     phone: data?.phone,
  //     email: data?.email,
  //     address: data?.address
  //   };
  //   setIsLoading(true);
  //   setIsSubmittingForm(true);
  //   if (selectedClient) {
  //     customApiRequest(
  //       ceateNewClientApi(
  //         user?.token,
  //         { ...apiData, id: selectedClient.id },
  //         true
  //       ),
  //       (res) => {
  //         setIsLoading(false);
  //         setIsSubmittingForm(false);
  //         if (checkRes(res) && res?.data?.data) {
  //           setModalOpened(false);
  //           setSelectedClientId(res?.data?.data?.id);
  //           setAllClientsFetchCount((prevState) => prevState + 1);
  //           successNotification({
  //             title: 'العملية تمت بنجاح',
  //             message: 'تم تعديل العميل بنجاح'
  //           });
  //         } else {
  //           errorNotification({
  //             title: 'حدث خطأ',
  //             message: res?.data?.message || 'حاول فى وقت لاحق'
  //           });
  //         }
  //       },
  //       (error) => {
  //         setIsLoading(false);
  //         setIsSubmittingForm(false);
  //         errorNotification({
  //           title: 'حدث خطأ',
  //           message: error?.response?.data?.message || 'حاول فى وقت لاحق'
  //         });
  //       }
  //     );
  //   } else {
  //     customApiRequest(
  //       ceateNewClientApi(user?.token, { ...apiData }),
  //       (res) => {
  //         setIsLoading(false);
  //         setIsSubmittingForm(false);
  //         if (checkRes(res) && res?.data?.data) {
  //           setModalOpened(false);
  //           setSelectedClientId(res?.data?.data?.id);
  //           setAllClientsFetchCount((prevState) => prevState + 1);
  //           successNotification({
  //             title: 'العملية تمت بنجاح',
  //             message: 'تم اضافة العميل بنجاح'
  //           });
  //         } else {
  //           errorNotification({
  //             title: 'حدث خطأ',
  //             message: res?.data?.message || 'حاول فى وقت لاحق'
  //           });
  //         }
  //       },
  //       (error) => {
  //         setIsLoading(false);
  //         setIsSubmittingForm(false);
  //         errorNotification({
  //           title: 'حدث خطأ',
  //           message: error?.response?.data?.message || 'حاول فى وقت لاحق'
  //         });
  //       }
  //     );
  //   }
  // };

  const onSubmit = async (data) => {
    const mappedData = {};
    if (data.name) mappedData.name = data.name;
    if (data.phone) mappedData.phone = data.phone;
    if (data.email) mappedData.email = data.email;
    if (data.address) mappedData.address = data.address;
    if (data.country) mappedData.country = data.country;
    if (data.city) mappedData.city = data.city;
    if (data.area) mappedData.area = data.area;
    if (data.company) mappedData.company = data.company;
    if (data.activity) mappedData.activity = data.activity;
    if (data.taxNumber) mappedData.taxNumber = data.taxNumber;
    if (data.commercialRecord)
      mappedData.commercialRecord = data.commercialRecord;
    if (data.note) mappedData.note = data.note;
    if (data.commercialNumber)
      mappedData.commercialNumber = data.commercialNumber;

    const successCallback = (res) => {
      setIsSubmittingForm(false);
      setIsLoading(false);
      if (res?.data?.data) {
        setModalOpened(false);
        setModalOpenedClintHome(false);
        setFetchCount((prevState) => prevState + 1);
        // setFetchCountInvoice((prevState) => prevState + 1);
        setAllClientsFetchCount((prevState) => prevState + 1);

        successNotification({
          title: t('Messages.addedSuccessfully'),
          message: t('Messages.addsuccessfully')
        });
      } else {
        errorNotification({
          title: t('Messages.ErrorOccurred'),
          message: res?.data?.message
            ? res.data.message
            : t('Messages.TryAgainLater')
        });
      }
    };
    const errorCallback = (error) => {
      setIsSubmittingForm(false);
      setIsLoading(false);
      errorNotification({
        title: t('Messages.ErrorOccurred'),
        message: t('Messages.TryAgainLater')
      });
    };
    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedClient) {
      mappedData.id = selectedClient.id;
      customApiRequest(
        ceateNewClientApi(user?.token, mappedData, true),
        successCallback,
        errorCallback
      );
    } else {
      customApiRequest(
        ceateNewClientApi(user?.token, mappedData),
        successCallback,
        errorCallback
      );
    }
  };

  // handle initial values
  // useEffect(() => {
  //   if (selectedClient) {
  //     setValue('name', selectedClient?.name ? selectedClient.name : '');
  //     setValue('phone', selectedClient?.phone ? selectedClient.phone : '');
  //     setValue('email', selectedClient?.email ? selectedClient.email : '');
  //   }
  // }, [selectedClient, selectedClient?.id]);

  useEffect(() => {
    if (selectedClient) {
      if (selectedClient?.name) {
        setValue('name', selectedClient.name);
      }
      if (selectedClient?.phone) {
        setValue('phone', selectedClient.phone);
      }
      if (selectedClient?.email) {
        setValue('email', selectedClient.email);
      }
      if (selectedClient?.address) {
        setValue('address', selectedClient.address);
      }
      if (selectedClient?.country) {
        setValue('country', selectedClient.country);
      }
      if (selectedClient?.city) {
        setValue('city', selectedClient.city);
      }
      if (selectedClient?.area) {
        setValue('area', selectedClient.area);
      }
      if (selectedClient?.company) {
        setValue('company', selectedClient.company);
      }
      if (selectedClient?.activity) {
        setValue('activity', selectedClient.activity);
      }
      if (selectedClient?.taxNumber) {
        setValue('taxNumber', selectedClient.taxNumber);
      }
      if (selectedClient?.commercialRecord) {
        setValue('commercialRecord', selectedClient.commercialRecord);
      }
      if (selectedClient?.note) {
        setValue('note', selectedClient.note);
      }
      if (selectedClient?.commercialNumber) {
        setValue('commercialNumber', selectedClient.commercialNumber);
      }
      // if (selectedClient?.supplierType) {
      //   setValue('canLogin', String(selectedClient.supplierType));
      // }
    }
  }, [selectedClient]);
  const [form] = Form.useForm();
  return (
    <Form
      className="system-users-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      {/* <div className="form-body" style={{ margin: '0 12px' }}>
        <div className="text-field-label-wrap">
          <p className="label-p">اســم العميــل</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="name"
              type="text"
              placeholder="اسـم العميــل..."
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">رقم </p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="phone"
              type="text"
              placeholder="رقم الهاتف..."
              errorMsg={errors?.phone?.message}
              validateStatus={errors?.phone ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">البريد الالكترونى</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="email"
              type="text"
              placeholder="البريد الالكترونى..."
              errorMsg={errors?.email?.message}
              validateStatus={errors?.email ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">العنوان</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="address"
              type="text"
              placeholder="العنوان..."
              errorMsg={errors?.address?.message}
              validateStatus={errors?.address ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          إضافة
        </Button>
      </div> */}
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Quotations.Name')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="name"
              type="text"
              placeholder={`${t('Quotations.Name')}...`}
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              control={control}
              prefix={<UserIcon />}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Quotations.Phone')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="phone"
              type="text"
              placeholder={`${t('Quotations.Phone')}...`}
              errorMsg={errors?.phone?.message}
              validateStatus={errors?.phone ? 'error' : ''}
              control={control}
              prefix={<PhoneIcon />}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Quotations.Email')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="email"
              type="text"
              placeholder={`${t('Quotations.Email')}...`}
              errorMsg={errors?.email?.message}
              validateStatus={errors?.email ? 'error' : ''}
              control={control}
              prefix={<EmailIcon />}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Quotations.AdditionalAddressCode')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="address"
              type="text"
              placeholder={`${t('Quotations.AdditionalAddressCode')}...`}
              errorMsg={errors?.address?.message}
              validateStatus={errors?.address ? 'error' : ''}
              control={control}
              prefix={<LocationIcon />}
            />
          </div>
        </div>
        {user.subscriptionType === 2 && (
          <>
            <div className="text-field-label-wrap">
              <p className="label-p">
                {t('SalesInvoices.CommercialRegistrationNumber')}
              </p>
              <div className="text-field-wrap">
                <AntdTextField
                  className="form-text-field"
                  name="commercialNumber"
                  type="text"
                  // placeholder="رقم السجل التجارى..."
                  placeholder={
                    t('SalesInvoices.CommercialRegistrationNumber') + '...'
                  }
                  errorMsg={errors?.commercialNumber?.message}
                  validateStatus={errors?.commercialNumber ? 'error' : ''}
                  control={control}
                  prefix={<UserIcon />}
                />
              </div>
            </div>
            <div className="text-field-label-wrap">
              <p className="label-p">{t('Quotations.City')}</p>
              <div className="text-field-wrap">
                <AntdTextarea
                  className="form-text-area"
                  name="note"
                  rows={8}
                  placeholder={`${t('Quotations.City')}...`}
                  errorMsg={errors?.note?.message}
                  validateStatus={errors?.note ? 'error' : ''}
                  control={control}
                />
              </div>
            </div>
          </>
        )}
        <div className="country-city-region-wrap">
          <div className="text-field-label-wrap">
            <p className="label-p">{t('Quotations.Country')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="country"
                type="text"
                placeholder={`${t('Quotations.Country')}...`}
                errorMsg={errors?.country?.message}
                validateStatus={errors?.country ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">{t('Quotations.City')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="city"
                type="text"
                placeholder={`${t('Quotations.City')}...`}
                errorMsg={errors?.city?.message}
                validateStatus={errors?.city ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">{t('Quotations.Neighborhood')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="area"
                type="text"
                placeholder={`${t('Quotations.Neighborhood')}...`}
                errorMsg={errors?.area?.message}
                validateStatus={errors?.area ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
        </div>
        <div className="country-city-region-wrap">
          <div className="text-field-label-wrap">
            <p className="label-p">{t('Quotations.Company')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="company"
                type="text"
                placeholder={`${t('Quotations.Company')}...`}
                errorMsg={errors?.company?.message}
                validateStatus={errors?.company ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">{t('Quotations.Activity')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="activity"
                type="text"
                placeholder={`${t('Quotations.Activity')}...`}
                errorMsg={errors?.activity?.message}
                validateStatus={errors?.activity ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">{t('Quotations.TaxNumber')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="taxNumber"
                type="text"
                placeholder={`${t('Quotations.TaxNumber')}...`}
                errorMsg={errors?.taxNumber?.message}
                validateStatus={errors?.taxNumber ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">
              {t('Quotations.CommercialRegistrationNumber')}
            </p>{' '}
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="commercialRecord"
                type="text"
                placeholder={`${t(
                  'Quotations.CommercialRegistrationNumber'
                )}...`}
                errorMsg={errors?.commercialRecord?.message}
                validateStatus={errors?.commercialRecord ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          {/* <div className="select-label-wrap">
          <p className="label-p">نوع العميل</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="canLogin"
              errorMsg={errors?.canLogin?.message}
              validateStatus={errors?.canLogin ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="فئة المنتج الرئيسية"
              options={[
                { title: 'مورد', value: '1' },
                { title: 'مشترك', value: '2' }
              ]}
              formClassName="store-new-supplier-form"
            />
          </div>
        </div> */}
        </div>
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedClient ? t('Quotations.Edit') : t('Quotations.Add')}
        </Button>
      </div>
    </Form>
  );
};

export default InvoiceClientForm;
