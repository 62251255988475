import { Modal } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CustomersPaymentsProvider from '../../contexts/customers-payments-context/CustomersPaymentsContext';
import './PageModal.scss';
import TableForm from './TableForm';

const PageModal = ({ setAllSuppliersFetchCount }) => {
  const {
    modalOpened,
    setModalOpened,
    selectedCustomerPayment,
    setSelectedCustomerPayment
  } = useContext(CustomersPaymentsProvider);
  const { t } = useTranslation();

  return (
    <Modal
      className="new-supplier-modal"
      width="90%"
      style={{ maxWidth: '642px' }}
      // title={'تسديد دفعة جديدة'}
      title={t('ClientPayments.makeNewPayment')}
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedCustomerPayment(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedCustomerPayment(null);
      }}
      footer={false}
    >
      <TableForm setAllSuppliersFetchCount={setAllSuppliersFetchCount} />
    </Modal>
  );
};

export default PageModal;
