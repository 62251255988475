/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useContext } from 'react';
import { useEffect } from 'react';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import UserContext from '../../contexts/user-context/UserProvider';
import InstallmentsPageContext from '../../contexts/installments-context/InstallmentsContext';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import CustomAntdDatePicker from '../../common/antd-form-components/CustomAntdDatePicker';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import moment from 'moment';
import createInstallmentApi from '../../apis/store-apis/installments-api/createInstallmentApi';
import { useParams } from 'react-router-dom';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
// import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { useTranslation } from 'react-i18next';

const UserInstallmentModalForm = () => {
  const { user } = React.useContext(UserContext);
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    price: Yup.string()
      .required(t('Receipts.priceValidation'))
      .matches(/^(\d+)?(\.\d+)?$/, t('Receipts.onlyNumbersAllowed')),
    date: Yup.mixed().required(t('Receipts.paymentDateValidation')),
    status: Yup.string().required(t('Receipts.paymentStatusValidation'))
  });
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedInstallment,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(InstallmentsPageContext);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      price: '',
      date: '',
      status: '',
      note: ''
    }
  });

  const getUrl = (formType) => {
    if (selectedInstallment?.installmentType === 'sale') {
      if (formType === 'create') {
        return '/InstallmentSale/create';
      } else if (formType === 'update') {
        return '/InstallmentSale/update';
      }
    } else if (selectedInstallment?.installmentType === 'buy') {
      if (formType === 'create') {
        return '/Installment/create';
      } else if (formType === 'update') {
        return '/Installment/update';
      }
    }
    return '';
  };

  // handle initial values
  useEffect(() => {
    if (selectedInstallment) {
      if (selectedInstallment?.price) {
        setValue('price', selectedInstallment.price);
      }
      if (selectedInstallment?.status) {
        setValue('status', String(selectedInstallment.status));
      }
      if (selectedInstallment?.note) {
        setValue('note', selectedInstallment.note);
      }
      if (selectedInstallment?.date)
        setValue('date', moment(selectedInstallment.date, 'YYYY-MM-DD'));
    }
  }, [selectedInstallment]);
  const { id } = useParams();
  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    const mappedData = {};
    mappedData.invoice_id = id;
    if (data.price) mappedData.price = data.price;
    if (data.status) mappedData.status = data.status;
    if (data.note) mappedData.note = data.note;
    if (data.date) mappedData.date = moment(data.date).format('YYYY-MM-DD');

    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedInstallment) {
      mappedData.Installment_id = selectedInstallment.id;
      customApiRequest(
        createInstallmentApi(user?.token, mappedData, true, getUrl('update')),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Receipts.operationCompletedSuccessfully'),
              message: t('Receipts.receiptUpdatedSuccessfully')
            });
          } else {
            errorNotification({
              title: t('Receipts.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Receipts.tryLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: 'Something went wrong',
            message: error?.response?.data?.message || 'Try again later'
          });
        }
      );
    } else {
      customApiRequest(
        createInstallmentApi(user?.token, mappedData, false, getUrl('create')),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Receipts.operationCompletedSuccessfully'),
              message: t('Receipts.receiptAdded')
            });
          } else {
            errorNotification({
              title: t('Receipts.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Receipts.tryLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: 'Something went wrong',
            message: error?.response?.data?.message || 'Try again later'
          });
        }
      );
    }
  };

  const [form] = Form.useForm();

  return (
    <Form
      className="installments-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Receipts.receiptPrice')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              // disabled={selectedInstallment}
              className="form-text-field"
              name="price"
              type="text"
              placeholder={t('Receipts.receiptPricePlaceholder')}
              errorMsg={errors?.price?.message}
              validateStatus={errors?.price ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <label className="custom-date-input" style={{ marginBottom: 24 }}>
          <span className="label-span">{t('Receipts.receiptDate')}</span>
          <CustomAntdDatePicker
            // disablePast
            control={control}
            name="date"
            className="receipt-date-picker"
            formClassName="installments-form"
          />
        </label>

        <div className="select-label-wrap">
          <p className="label-p">{t('Receipts.receiptStatus')}</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="status"
              errorMsg={errors?.status && errors.status.message}
              validateStatus={errors?.status ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder={t('Receipts.receiptStatus')}
              options={[
                { title: t('Receipts.receiptPaid'), value: '1' },
                { title: t('Receipts.receiptUnPaid'), value: '2' }
              ]}
              formClassName="installments-form"
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('Receipts.notes')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="note"
              rows={4}
              placeholder={t('Receipts.notesPlaceholder')}
              errorMsg={errors?.note && errors.note.message}
              validateStatus={errors?.note ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedInstallment ? t('Receipts.update') : t('Receipts.add')}
        </Button>
      </div>
    </Form>
  );
};

export default UserInstallmentModalForm;
