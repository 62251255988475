import React, { useContext } from 'react';
import SearchIcon from '../../../common/icons/SearchIcon';
import ArchiveDocsContext from '../../../contexts/archive-contexts/ArchiveDocsProvider';
import { useTranslation } from 'react-i18next';

const TableFilter = () => {
  const { setNameSearchValue } = useContext(ArchiveDocsContext);
  const { t } = useTranslation();

  const handleTableSearch = (e) => {
    setNameSearchValue(e.target.value);
  };

  return (
    <div className="custom-table-fitler warehouse-table-filter">
      <div className="form-excel-export-wrap">
        <form className="filter-form">
          <div className="table-search">
            <label>
              <SearchIcon />
              <input
                placeholder={t('Documents.EnterDocumentNameToSearch')}
                type="text"
                name="table_search"
                onKeyUp={handleTableSearch}
              />
            </label>
          </div>

          {/* <button className="filter-submit-btn" type="submit">
            بحث
          </button> */}
        </form>
      </div>
    </div>
  );
};

export default TableFilter;
