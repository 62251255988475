/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Table, Empty } from 'antd';
import AccountingDetailsContext from '../../../contexts/accounting-context/AccountingDetailsContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import TableColumns from './TableColumns';
import JournalsContext from '../../../contexts/accounting-context/JournalsContext';
import BalanceSheetContext from '../../../contexts/accounting-context/BalanceSheetContext';
import { useTranslation } from 'react-i18next';

const TransactionTable = ({ type }) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const {
    fetchedBalanceSheetDetails,
    fetchCount,
    setIsLoading,
    setFetchCount,
    setModalOpened,
    setSelectedJournals,
    isLoading,
    setModalOpenedUpdate,
    setSelectedJournalId
  } = useContext(BalanceSheetContext);

  // map all data to dataSource
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: index + 1,
      name: obj?.name ? obj.name : '',
      description: obj?.name ? obj.name : '',
      title: obj?.title ? obj.title : '',
      the_amount: obj?.balance
        ? obj.balance
        : obj.initial_balance
        ? obj.initial_balance
        : ''
    }));
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setTableDataSource(fetchedBalanceSheetDetails);
    }
    return () => {
      isMounted = false;
    };
  }, [fetchCount, fetchedBalanceSheetDetails]);

  return (
    <>
      <Table
        locale={{
          emptyText: (
            <Empty description={false}>
              {t('ProfessorBook.noDataAvailable')}
            </Empty>
          )
        }}
        pagination={{ defaultPageSize: 15, hideOnSinglePage: true }}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={TableColumns(
          fetchedBalanceSheetDetails,
          setModalOpened,
          setSelectedJournalId,
          setModalOpenedUpdate,
          setFetchCount,
          setSelectedJournals,
          setIsLoading,
          user?.token,
          type
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
    </>
  );
};

export default TransactionTable;
