import axios from 'axios';

const cashPayments = async (token, values) => {
  try {
    let url = `/receipts?filter=${values.filter}&page=${values.page}`;
    const res = await axios.get(url, {
      baseURL: process.env.REACT_APP_BASE_URL_POS,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default cashPayments;
