import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import EditIcon from '../../../common/icons/EditIcon';
import DeleteIcon from '../../../common/icons/DeleteIcon';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import deletePayment from '../../../apis/pos-api/cash-payments/deletePayment';
import { EyeOutlined, PrinterOutlined } from '@ant-design/icons';

const tableColumns = (
  allFetchedCashOut,
  setSelectedCash,
  setIsLoading,
  setModalOpened,
  setFetchCount,
  printModalCash,
  setPrintModalCash,
  token
  ) => {
  const handleViewReceiptClick = (record) => {
    const foundInvoice = allFetchedCashOut.find(
      (receipt) => receipt.id === record.id
    );
    if (foundInvoice) {
      setSelectedCash({ ...foundInvoice });
      setPrintModalCash(true);
    }
  };

  // const handleViewReceiptClick = (record) => {
  //   const foundInvoice = allFetchedSaleInvoices.find(
  //     (receipt) => receipt.id === record.id
  //   );
  //   if (foundInvoice) {
  //     setSelectedInvoice({ ...foundInvoice });
  //     setSelectedSaleInvoiceModalOpened(true);
  //   }
  // };

  // const handleReturnClick = (record) => {
  //   const foundInvoice = allFetchedSaleInvoices.find(
  //     (receipt) => receipt.id === record.id
  //   );
  //   if (foundInvoice) {
  //     setSelectedInvoice({ ...foundInvoice });
  //     setReturnModalOpened(true);
  //   }
  // };

  // const renderActions = (record) => {
  //   if (record?.is_return === 0) {
  //     if (parseFloat(record?.totalPrice) == 0) {
  //       return (
  //         <div
  //           className="edit-btn"
  //           style={{
  //             opacity: '.6',
  //             cursor: 'not-allowed'
  //           }}
  //         >
  //           <EditIcon />
  //           طلب ارتجاع
  //         </div>
  //       );
  //     }
  //     return (
  //       <div className="edit-btn" onClick={() => handleReturnClick(record)}>
  //         <EditIcon />
  //         طلب ارتجاع
  //       </div>
  //     );
  //   } else {
  //     return 'تم ارتجاع الفاتورة';
  //   }
  // };

  const handleEditProduct = (record) => {
    const found = allFetchedCashOut.find((place) => place.id === record.id);
    if (found) {
      setSelectedCash({ ...found });
      setModalOpened(true);
    }
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deletePayment(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف النقدية بنجاح'
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: 'حدث خطأ اثناء العملية',
          message: res?.data?.message
            ? res.data.message
            : 'من فضلك حاول فى وقت لاحق'
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: 'حدث خطأ',
        message: 'حاول فى وقت لاحق'
      });
    }
  };

  // const handleViewReceiptClick = (record) => {
  //   const foundInvoice = allFetchedSaleInvoices.find(
  //     (receipt) => receipt.id === record.id
  //   );
  //   if (foundInvoice) {
  //     setSelectedInvoice({ ...foundInvoice });
  //     setSelectedSaleInvoiceModalOpened(true);
  //   }
  // };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditProduct(record)}>
          <EditIcon />
          تعديل
        </div>
        <Popconfirm
          title="هل أنت متأكد من حذف النقدية"
          onConfirm={() => handleDeleteRequest(record.id)}
          okText="نعم"
          cancelText="لا"
        >
          <Tooltip title="حذف النقدية">
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      title: 'مسلسل',
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span>{record.id}</span>
          </div>
        );
      }
    },

    {
      title: 'القسم',
      dataIndex: 'department',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">{record?.department?.name}</div>
        );
      }
    },
    {
      title: 'الاسم',
      dataIndex: 'username_reciept',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">{record?.username_reciept}</div>
        );
      }
    },
    {
      title: 'المبلغ',
      dataIndex: 'amount',
      render: (_, record) => {
        return <div className="row-cell row-text">{record.amount}</div>;
      }
    },

    {
      title: 'الملاحظة',
      dataIndex: 'title',
      render: (_, record) => {
        return <div className="row-cell row-text">{record.title}</div>;
      }
    },

    {
      title: 'التاريخ',
      dataIndex: 'date',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.date ? record.date : '----'}
          </div>
        );
      }
    },
    {
      title: 'معاينة الفاتورة',
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            <Tooltip title="معاينة الفاتورة">
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => handleViewReceiptClick(record)}
              />
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: 'الاعدادات',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }

    // {
    //   title: 'معاينة الفاتورة',
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-circled-btn">
    //         <Tooltip title="معاينة الفاتورة">
    //           <Button
    //             className="circled-btn"
    //             type="dashed"
    //             shape="circle"
    //             icon={<EyeOutlined />}
    //             onClick={() => handleViewReceiptClick(record)}
    //           />
    //         </Tooltip>
    //       </div>
    //     );
    //   }
    // },
  ];
};

export default tableColumns;
