import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  stopwatchHours: 0,
  setStopwatchHours: (v) => {},
  stopwtachMinutes: 0,
  setStopwatchMinutes: (v) => {},
  stopwtachSeconds: 0,
  setStopwatchSeconds: (v) => {}
};

const StopwatchContext = createContext(INITIAL_VALUES);

export const StopwatchProvider = ({ children }) => {
  const [stopwatchHours, setStopwatchHours] = useState(
    INITIAL_VALUES.stopwatchHours
  );
  const [stopwtachMinutes, setStopwatchMinutes] = useState(
    INITIAL_VALUES.stopwtachMinutes
  );
  const [stopwtachSeconds, setStopwatchSeconds] = useState(
    INITIAL_VALUES.stopwtachSeconds
  );

  return (
    <StopwatchContext.Provider
      value={{
        stopwatchHours,
        setStopwatchHours,
        stopwtachMinutes,
        setStopwatchMinutes,
        stopwtachSeconds,
        setStopwatchSeconds
      }}
    >
      {children}
    </StopwatchContext.Provider>
  );
};

export default StopwatchContext;
