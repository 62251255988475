import React from 'react';

import img1 from '../../../assets/imgs/settings/help/1.jpg';
import img2 from '../../../assets/imgs/settings/help/2.jpg';
import img3 from '../../../assets/imgs/settings/help/3.jpg';
import img4 from '../../../assets/imgs/settings/help/4.jpg';
import img5 from '../../../assets/imgs/settings/help/5.jpg';
import img6 from '../../../assets/imgs/settings/help/6.jpg';
import img7 from '../../../assets/imgs/settings/help/7.jpg';
import img8 from '../../../assets/imgs/settings/help/8.jpg';
import img9 from '../../../assets/imgs/settings/help/9.jpg';
import img10 from '../../../assets/imgs/settings/help/10.jpg';
import img11 from '../../../assets/imgs/settings/help/11.jpg';
import img12 from '../../../assets/imgs/settings/help/12.jpg';
import img13 from '../../../assets/imgs/settings/help/13.jpg';
import img14 from '../../../assets/imgs/settings/help/14.jpg';
import img15 from '../../../assets/imgs/settings/help/15.jpg';
import img16 from '../../../assets/imgs/settings/help/16.jpg';
import img17 from '../../../assets/imgs/settings/help/17.jpg';
import img18 from '../../../assets/imgs/settings/help/18.jpg';
import img19 from '../../../assets/imgs/settings/help/19.jpg';
import img20 from '../../../assets/imgs/settings/help/20.jpg';
import img21 from '../../../assets/imgs/settings/help/21.jpg';
import img22 from '../../../assets/imgs/settings/help/22.jpg';
import img23 from '../../../assets/imgs/settings/help/23.jpg';
import { useTranslation } from 'react-i18next';

const HelpPageLinks = () => {
  const { t } = useTranslation();

  const helpLinks = [
    {
      id: 1,
      name: t('help.InvoiceSystem'),
      img: img1,
      subMenu: null,
      selected: true,
      visibleSubMenu: false
    },
    {
      id: 2,
      name: t('help.SubscriptionTypes'),
      img: img2,
      selected: false,
      visibleSubMenu: false,
      subMenu: {
        parent_id: 2,
        subLinks: [
          {
            id: '001',
            name: t('help.MonthlyAnd3MonthsPackages'),
            img: img2,
            selected: false
          },
          {
            id: '002',
            name: t('help.6MonthsAnd1YearPackages'),
            img: img3,
            selected: false
          }
        ]
      }
    },
    {
      id: 3,
      name: t('help.ControlPanel'),
      img: img4,
      subMenu: null,
      selected: false,
      visibleSubMenu: false
    },
    {
      id: 4,
      name: t('help.Branches'),
      img: img5,
      subMenu: null,
      selected: false,
      visibleSubMenu: false
    },
    {
      id: 5,
      name: t('help.AddWarehouses'),
      img: img6,
      subMenu: null,
      selected: false,
      visibleSubMenu: false
    },
    {
      id: 6,
      name: t('help.AddCategories'),
      img: img7,
      subMenu: null,
      selected: false,
      visibleSubMenu: false
    },
    {
      id: 7,
      name: t('help.AddMeasurementUnits'),
      img: img8,
      subMenu: null,
      selected: false,
      visibleSubMenu: false
    },
    {
      id: 8,
      name: t('help.Inventory'),
      img: img9,
      selected: false,
      visibleSubMenu: false,
      subMenu: {
        parent_id: 8,
        subLinks: [
          {
            id: '003',
            name: t('help.AddProducts'),
            img: img9,
            selected: false
          },
          // {
          //   id: '004',
          //   name: 'مكونات المنتجات',
          //   img: img10,
          //   selected: false
          // },
          {
            id: '005',
            name: t('help.Suppliers'),
            img: img11,
            selected: false
          },
          {
            id: '006',
            name: t('help.PurchaseInvoice'),
            img: img12,
            selected: false
          },
          {
            id: '007',
            name: t('help.SalesInvoice'),
            img: img13,
            selected: false
          },
          {
            id: '008',
            name: t('help.PurchaseOrder'),
            img: img14,
            selected: false
          },
          {
            id: '009',
            name: t('help.InventoryTransfer'),
            img: img15,
            selected: false
          },
          {
            id: '010',
            name: t('help.StoreInventory'),
            img: img16,
            selected: false
          }
        ]
      }
    },
    {
      id: 9,
      name: t('help.Users'),
      subMenu: null,
      img: img17,
      selected: false,
      visibleSubMenu: false
    },
    {
      id: 10,
      name: t('help.PointOfSale'),
      img: img18,
      subMenu: null,
      selected: false,
      visibleSubMenu: false
    },
    {
      id: 11,
      name: t('help.Sales'),
      img: img19,
      subMenu: null,
      selected: false,
      visibleSubMenu: false
    },
    {
      id: 12,
      name: t('help.Accounts'),
      img: img20,
      subMenu: null,
      selected: false,
      visibleSubMenu: false
    },
    {
      id: 13,
      name: t('help.Archive'),
      img: img21,
      subMenu: null,
      selected: false,
      visibleSubMenu: false
    },
    {
      id: 14,
      name: t('help.DataAndReports'),
      img: img22,
      subMenu: null,
      selected: false,
      visibleSubMenu: false
    },
    {
      id: 15,
      name: t('help.UserProfile'),
      img: img23,
      subMenu: null,
      selected: false,
      visibleSubMenu: false
    }
  ];
  return helpLinks;
};

export default HelpPageLinks;
