import React, { useContext, useState } from 'react';
import { Select } from 'antd';

// import SearchIcon from '../../../common/icons/SearchIcon';
import SelectDropDown from '../../../common/icons/SelectDropDown';
import UserContext from '../../../contexts/user-context/UserProvider';
import getAllInventoriesApi from '../../../apis/store-apis/inventory-apis/getAllInventoryApi';
import StoreInventoryContext from '../../../contexts/store-inventory-context/StoreInventoryProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import './StoreInventoryPage.scss';
import { useTranslation } from 'react-i18next';
import excelImg from '../../../assets/imgs/icons/excel-btn.png';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';

const TableFilter = () => {
  const { Option } = Select;
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const systemSettings = useSystemSettings()
  const { setAllFetchedInventories, setIsLoading } = useContext(
    StoreInventoryContext
  );
  const[filterValue,setFilterValue]=useState('all')
  // const handleTableSearch = (e) => {
  //   setNameSearchValue(e.target.value);
  // };

  const customApiRequest = useCustomApiRequest();
  async function handleChange(value) {
    setIsLoading(true);
    setFilterValue(value)
    if (value === 'all') {
      customApiRequest(
        getAllInventoriesApi(user?.token),
        (res) => {
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllFetchedInventories(data);
          } else {
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    } else {
      customApiRequest(
        getAllInventoriesApi(user?.token, value),

        (res) => {
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllFetchedInventories(data);
          } else {
            setIsLoading(false);
          }
        },
        (error) => {}
      );
    }
  }

  return (
    <div className="custom-table-fitler warehouse-table-filter stocking">
      <div className="form-excel-export-wrap">
        <form className="filter-form">
          {/* <div className="table-search">
            <label>
              <SearchIcon />
              <input
                placeholder="أدخل اسم المستخدم للبحث"
                type="text"
                name="table_search"
                onKeyUp={handleTableSearch}
              />
            </label>
          </div> */}
          <label className="entries-wrap">
            <span className="select-title">
              {t('Inventory.WarehouseInventory.inventoryType')}
            </span>
            <Select
              defaultValue="all"
              style={{ width: 152 }}
              onChange={handleChange}
              suffixIcon={<SelectDropDown />}
            >
              <Option value="all">
                {t('Inventory.WarehouseInventory.allTypes')}
              </Option>
              <Option value="1">
                {t('Inventory.WarehouseInventory.products')}
              </Option>
              {/* <Option value="2">مكونات منتجات</Option> */}
            </Select>
          </label>

          {/* <button className="filter-submit-btn" type="submit">
            بحث
          </button> */}

            <div className="excel-btn-wrap">
              <a
                href={`${
                  process.env.REACT_APP_BASE_URL
                }/exportExcelInventory?type=1&client_id=${systemSettings?.id}`}
                download
                className="excel-export-btn"
              >
                <img
                  style={{ height: '42px' }}
                  src={excelImg}
                  alt="export to excel"
                />
              </a>
            </div>
        </form>
      </div>
    </div>
  );
};

export default TableFilter;
