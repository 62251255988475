import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../../components/search-notifications-profile/SearchNotificationsProfile';
import PageTable from './PageTable';
import PageModal from './PageModal';
import TableFilter from './TableFilter';
import SettingsSystemUsersContext from '../../../../contexts/settings-contexts/SettingsSystemUsersProvider';
import './SystemUsersPage.scss';
import UserPermission from './UserPermission';
import { useTranslation } from 'react-i18next';
const SettingsSystemUsersPage = () => {
  const { modalOpened, setModalOpened, setSelectedUser } = useContext(
    SettingsSystemUsersContext
  );
  const { t } = useTranslation();
  return (
    <div className="settings-system-users-page">
      <div className="page-head-wrap">
        <h2 className="bold-font">{t('SystemSettings.systemUser')}</h2>
      </div>

      <div className="custom-filter-add-section" style={{ flexWrap: 'wrap' }}>
        <div className="add-section">
          <div
            className="add-btn"
            onClick={() => {
              setSelectedUser(null);
              setModalOpened(true);
            }}
          >
            <span className="text-span">{t('SystemSettings.addNewUser')}</span>
          </div>
        </div>
        <TableFilter />
      </div>
      <div className="SettingsSystemUsersPage-table-wrapper">
        <PageTable />
      </div>
      {/* <UserPermission /> */}

      {modalOpened && <PageModal />}
    </div>
  );
};

export default SettingsSystemUsersPage;
