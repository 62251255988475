import React, { useContext, useEffect } from 'react';
import './ViewJournal.scss';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import UserContext from '../../../contexts/user-context/UserProvider';
import { Spin } from 'antd';
import { Table, Empty } from 'antd';
import getSingleJournalApi from '../../../apis/accounting-apis/accounting-details-apis/getSingleJournalApi';
import BalanceSheetContext from '../../../contexts/accounting-context/BalanceSheetContext';
import { useTranslation } from 'react-i18next';

const ViewJournal = () => {
  const { user } = useContext(UserContext);
  const {
    selectedJournalId,
    setIsLoading,
    isLoading,
    singleJournal,
    setSingleJournal,
    fetchCount
  } = useContext(BalanceSheetContext);
  const customApiRequest = useCustomApiRequest();
  const { t } = useTranslation();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getSingleJournalApi(user?.token, selectedJournalId),
        (res) => {
          setIsLoading(false);
          setSingleJournal(res?.data?.data);
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  const tableJournalColumn = [
    {
      title: t('ProfessorBook.account'),
      dataIndex: 'account_name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.account_name ? record.account_name : '----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('ProfessorBook.Debtor'),
      dataIndex: 'debit',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.debit ? record.debit : '-----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('ProfessorBook.Creditor'),
      dataIndex: 'credit',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.credit ? record.credit : '-----'}
            </span>
          </div>
        );
      }
    },
    // {
    //     title: 'دافع',
    //     dataIndex: 'payer',
    //     // width: 192,
    //     render: (_, record) => {
    //     return (
    //         <div className="row-cell row-text">
    //         <span className="name-it-self">
    //             {record.payer ? record.payer : '-----'}
    //         </span>
    //         </div>
    //     );
    //     }

    // },
    // {
    //     title: ' طريقة الدفع',
    //     dataIndex: 'method',
    //     // width: 192,
    //     render: (_, record) => {
    //     return (
    //         <div className="row-cell row-text">
    //         <span className="name-it-self">
    //             {record.method ? record.method : '-----'}
    //         </span>
    //         </div>
    //     );
    //     }
    // },
    {
      title: t('ProfessorBook.note'),
      dataIndex: 'note',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {String(record.note).replace(/<[^>]*>?/gm, '')}
            </span>
          </div>
        );
      }
    }
  ];

  // const handleJournalTable = () => {
  //     return (
  //         <Table
  //             locale={{
  //                 emptyText: <Empty description={false}>{t("Messages.noDataAvailable")}</Empty>
  //             }}
  //             pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
  //             className="antd-custom-table"
  //             dataSource={'tableDataSource'}
  //             columns={tableJournalColumn}
  //             loading={isLoading}
  //             // scroll={{ x: 400 }}
  //             footer={false}
  //         />
  //     )
  // }

  const handleFetchDataForTrans = () => {
    return (
      <div className="view-transaction-content">
        <div>
          <h4>{t('ProfessorBook.Statement')}</h4>
          <span> : </span>
          <span>{String(singleJournal?.note).replace(/<[^>]*>?/gm, '')}</span>
        </div>
        <div>
          <h4>{t('ProfessorBook.date')}</h4>
          <span> : </span>
          <span>{singleJournal?.date && singleJournal?.date}</span>
        </div>
      </div>
    );
  };
  return (
    <>
      {!isLoading ? (
        <div className="view-transaction">
          {/* <h2>تفاصيل الـقـيـود</h2>
                <hr style={{marginBottom: '20px'}}/> */}
          {handleFetchDataForTrans()}
          {/* {handleJournalTable()} */}
          <Table
            locale={{
              emptyText: (
                <Empty description={false}>
                  {t('Messages.noDataAvailable')}
                </Empty>
              )
            }}
            pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            className="antd-custom-table"
            dataSource={singleJournal?.transactions}
            columns={tableJournalColumn}
            loading={isLoading}
            // scroll={{ x: 400 }}
            footer={false}
          />
          <div className="journal-amount">
            <h3>{t('ProfessorBook.total')}</h3>
            <h3>{singleJournal?.amount && singleJournal?.amount}</h3>
            <h3>{singleJournal?.amount && singleJournal?.amount}</h3>
            <h3></h3>
          </div>
        </div>
      ) : (
        <div
          className="spin-tree-wrap"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Spin />
        </div>
      )}
      {/* {
            singleJournal &&
            <div className='view-transaction'>
                <h2>تفاصيل الـقـيـود</h2>
                <hr style={{marginBottom: '20px'}}/>
                {handleFetchDataForTrans()}
                <Table
                    locale={{
                        emptyText: <Empty description={false}>{t("Messages.noDataAvailable")}</Empty>
                    }}
                    pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
                    className="antd-custom-table"
                    dataSource={singleJournal?.transactions}
                    columns={tableJournalColumn}
                    loading={isLoading}
                    // scroll={{ x: 400 }}
                    footer={false}
                />
            </div>
        } */}
    </>
  );
};

export default ViewJournal;
