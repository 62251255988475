/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import moment from 'moment';
import CustomAntdDatePicker from '../../common/antd-form-components/CustomAntdDatePicker';
import { useTranslation } from 'react-i18next';

const LaterInvoiceModalForm = () => {
  const {
    selectedInvoice,
    setLaterInvoiceModalOpened,
    laterInvoiceName,
    laterInvoicePhone,
    laterInvoiceAddress,
    laterInvoiceDueDate,
    setLaterInvoiceName,
    setLoaterInvoicePhone,
    setLaterInvoiceAddress,
    setLaterInvoiceDueDate
  } = useContext(PosInvoiceContext);
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    name: Yup.string().required(t('Pos.Main.enterCustomerName')),
    phone: Yup.string()
      .required(t('Pos.Main.enterCustomerPhoneNumber'))
      .matches(/^[0-9]+$/, t('Pos.Main.onlyNumbersAllowed'))
      .min(10, t('Pos.Main.minimumNumbers')),
    address: Yup.string().required(t('Pos.Main.enterAddress')),
    dueDate: Yup.mixed().required(t('Pos.Main.chooseDueDate'))
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      phone: '',
      address: '',
      dueDate: null
    }
  });
  const onSubmit = async (data) => {
    setLaterInvoiceName(data.name);
    setLoaterInvoicePhone(data.phone);
    setLaterInvoiceAddress(data.address);
    setLaterInvoiceDueDate(data.dueDate);
    setLaterInvoiceModalOpened(false);
  };

  const cancelReservation = () => {
    setLaterInvoiceName('');
    setLoaterInvoicePhone('');
    setLaterInvoiceAddress('');
    setLaterInvoiceDueDate(null);
    setLaterInvoiceModalOpened(false);
  };

  // React.useEffect(() => {
  //   if (watch('name')) {
  //     setLaterInvoiceName(watch('name'));
  //   } else {
  //     setLaterInvoiceName('');
  //   }
  // }, [watch('name')]);
  // React.useEffect(() => {
  //   if (watch('phone')) {
  //     setLoaterInvoicePhone(watch('phone'));
  //   } else {
  //     setLoaterInvoicePhone('');
  //   }
  // }, [watch('phone')]);
  // React.useEffect(() => {
  //   if (watch('address')) {
  //     setLaterInvoiceAddress(watch('address'));
  //   } else {
  //     setLaterInvoiceAddress('');
  //   }
  // }, [watch('address')]);
  // React.useEffect(() => {
  //   if (watch('dueDate')) {
  //     setLaterInvoiceDueDate(watch('dueDate'));
  //   } else {
  //     setLaterInvoiceDueDate('');
  //   }
  // }, [watch('dueDate')]);

  // handle initial values
  useEffect(() => {
    setValue(
      'name',
      selectedInvoice?.name
        ? selectedInvoice.name
        : laterInvoiceName
        ? laterInvoiceName
        : ''
    );
    setValue(
      'phone',
      selectedInvoice?.phone
        ? selectedInvoice.phone
        : laterInvoicePhone
        ? laterInvoicePhone
        : ''
    );
    setValue(
      'address',
      selectedInvoice?.address
        ? selectedInvoice.address
        : laterInvoiceAddress
        ? laterInvoiceAddress
        : ''
    );
    setValue(
      'dueDate',
      selectedInvoice?.dueDate
        ? moment(selectedInvoice.dueDate, 'MM/DD/YYYY')
        : laterInvoiceDueDate
        ? laterInvoiceDueDate
        : null
    );
  }, [selectedInvoice]);

  const [form] = Form.useForm();
  return (
    <Form
      className="later-invoice-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Pos.Main.clientName')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="name"
              type="text"
              placeholder={t('Pos.Main.clientNamePlaceholder')}
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Pos.Main.phoneNumber')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="phone"
              type="text"
              placeholder={t('Pos.Main.phoneNumberPlaceholder')}
              errorMsg={errors?.phone?.message}
              validateStatus={errors?.phone ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('Pos.Main.address')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="address"
              type="text"
              placeholder={t('Pos.Main.addressPlaceholder')}
              errorMsg={errors?.address?.message}
              validateStatus={errors?.address ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="date-picker-wrap">
          <p className="label-p">{t('Pos.Main.dueDateWithFormat')}</p>
          <CustomAntdDatePicker
            disablePast
            control={control}
            name="dueDate"
            className="receipt-date-picker"
            formClassName="later-invoice-form"
          />
          {errors?.dueDate && (
            <p className="error-p">{errors.dueDate.message}</p>
          )}
        </div>

        <div
          className="action-buttons"
          style={{ justifySelf: 'center', marginTop: 14 }}
        >
          <Button
            className="submit-btn"
            type="primary"
            onClick={cancelReservation}
            style={{ marginLeft: 22, backgroundColor: '#ccc', color: '#444' }}
          >
            {t('Pos.Main.cancellationReservation')}
          </Button>
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isSubmitting}
          >
            {t('Pos.Main.save')}
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default LaterInvoiceModalForm;
