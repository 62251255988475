import React from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
// import settingsImg from '../../../assets/imgs/settings/settings-img.png';
import './SystemSettingsPage.scss';
import GeneralSettingsPageForm from './GeneralSettingsPageForm';
import { useTranslation } from 'react-i18next';
const GeneralSettingsPage = () => {
  const { t } = useTranslation();
  return (
    <div className="system-settings-page">
      <div className="page-head-wrap">
        <div className="page-title">
          <h2 className="bold-font">{t('GeneralSettings.title')}</h2>
        </div>
        <SearchNotificationsProfile />
      </div>

      <div className="img-form-wrap">
        <GeneralSettingsPageForm />
        {/* <img src={settingsImg} alt="settings" /> */}
      </div>
    </div>
  );
};

export default GeneralSettingsPage;
