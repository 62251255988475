import { Button, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const ComplexProductsComponent = (props) => {
  const [getProductComplexId, setGetProductComplexId] = useState();
  const [ProductForComplexCount, setAddNewProductForComplex] = useState(0);
  const { t } = useTranslation();
  // useEffect(() => {
  //   if (props?.item?.new) {
  //     props.setValue(`complex_products.${props.index}.unique_id`, '');
  //     props.setValue(`complex_products.${props.index}.salePrice`, '33');
  //   }
  // }, [props?.item?.new]);
  return (
    <>
      <div className="field-delete-li field-delete-li-products">
        <div className="select-label-wrap" style={{ marginTop: '10px' }}>
          <p className="label-p">{t('Inventory.Products.selectProduct')}</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`complex_products.${props.index}.unique_id`}
              // errorMsg={errors?.parent_id?.message}
              errorMsg={
                props.errors?.unique_id &&
                props.errors.complex_products[props.index]?.unique_id &&
                props.errors.complex_products[props.index].unique_id.message
              }
              validateStatus={
                props.errors?.unique_id &&
                props.errors.complex_products[props.index]?.unique_id &&
                props.errors.complex_products[props.index].unique_id
                  ? 'error'
                  : ''
              }
              control={props.control}
              setValue={props.setValue}
              placeholder={t('Inventory.Products.selectProduct')}
              options={
                props.allProductsWithoutType?.length > 0
                  ? props.allProductsWithoutType.map((prod) => ({
                      title: prod.name,
                      value: String(prod.id)
                    }))
                  : null
              }
              showSearch={true}
              filterOption={(input, option) =>
                option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) => setGetProductComplexId(e)}
              formClassName="store-new-product-form"
              // disabled={props.selectedProduct ? true : false}
            />
          </div>
        </div>
        <div className="text-field-label-wrap" style={{ marginTop: '10px' }}>
          <p className="label-p">{t('Inventory.Products.productPrice')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              setValue={props.setValue}
              className="form-text-field"
              name={`complex_products.${props.index}.salePrice`}
              type="text"
              placeholder={t('Inventory.Products.productPricePlaceholder')}
              errorMsg={
                props.errors.salePrice && t('Inventory.Products.onlyNumbersAllowed')
              }
              validateStatus={
                props.errors.salePrice 
                  ? t('Inventory.Products.onlyNumbersAllowed')
                  : ''
              }
              // defaultValue={getProductComplexPrice[index]?.salePrice}
              defaultValue={
                getProductComplexId
                  ? (props.allProductsWithoutType?.length > 0 &&
                      props.allProductsWithoutType
                        ?.filter((getId) => getId?.id == getProductComplexId)
                        ?.map((getPrice) =>
                          getPrice?.salePrice
                            ? getPrice?.salePrice
                            : getPrice?.buyPrice
                        )) ||
                    ''
                  : props.selectedProduct?.complex_products?.find(
                      (complex) =>
                        complex.unique_id ==
                        `complex_products.${props.index}.unique_id`
                    )
              }
              control={props.control}
              readOnly={true}
            />
          </div>
        </div>
        <div className="text-field-label-wrap" style={{ marginTop: '10px' }}>
          <p className="label-p">{t('Inventory.Products.quantityAvailable')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              setValue={props.setValue}
              className="form-text-field"
              name={`complex_products.${props.index}.quant`}
              type="text"
              placeholder={t('Inventory.Products.quantityAvailablePlaceholder')}
              errorMsg={
                props.errors.quant &&
                props.errors.complex_products[props.index]?.quant &&
                props.errors.complex_products[props.index]?.quant.message
              }
              validateStatus={
                props.errors.quant &&
                props.errors.complex_products[props.index]?.quant &&
                props.errors.complex_products[props.index]?.quant
                  ? 'error'
                  : ''
              }
              // defaultValue={getProductComplexPrice[index]?.salePrice}
              defaultValue={
                getProductComplexId
                  ? (props.allProductsWithoutType?.length > 0 &&
                      props.allProductsWithoutType
                        ?.filter((getId) => getId?.id == getProductComplexId)
                        ?.map((getQuantity) => getQuantity?.quantity)) ||
                    ''
                  : props.selectedProduct?.complex_products?.find(
                      (complex) =>
                        complex.unique_id ==
                        `complex_products.${props.index}.unique_id`
                    )
              }
              control={props.control}
              readOnly={true}
            />
          </div>
        </div>
        <div className="text-field-wrap" style={{ marginTop: '40px' }}>
          <div className="btn-process">
            <Button
              className="btn-process-plus"
              onClick={() =>
                setAddNewProductForComplex(ProductForComplexCount + 1)
              }
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16 12.75H12.75V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 12.41 16.41 12.75 16 12.75Z"
                  fill="#2A9D94"
                />
              </svg>
            </Button>
            <AntdTextField
              setValue={props.setValue}
              className="form-text-field form-text-field-count"
              name={`complex_products.${props.index}.quantity`}
              type="text"
              style={{ height: '40px', textAlign: 'center' }}
              errorMsg={
                props.errors.quantity &&
                props.errors.complex_products[props.index]?.quantity &&
                props.errors.complex_products[props.index]?.quantity.message
              }
              validateStatus={
                props.errors.quantity &&
                props.errors.complex_products[props.index]?.quantity &&
                props.errors.complex_products[props.index]?.quantity
                  ? 'error'
                  : ''
              }
              defaultValue={ProductForComplexCount}
              // defaultValue={allProducts?.length > 0 && allProducts?.filter( getId => getId?.unique_id == getProductComplexId)?.map((getPrice) => getPrice?.salePrice)}
              control={props.control}
            />
            {ProductForComplexCount > 0 ? (
              <Button
                className="btn-process-minus"
                onClick={() =>
                  setAddNewProductForComplex(ProductForComplexCount - 1)
                }
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.92 12.75H7.92C7.51 12.75 7.17 12.41 7.17 12C7.17 11.59 7.51 11.25 7.92 11.25H15.92C16.33 11.25 16.67 11.59 16.67 12C16.67 12.41 16.34 12.75 15.92 12.75Z"
                    fill="#2A9D94"
                  />
                </svg>
              </Button>
            ) : (
              <Button
                disabled
                className="btn-process-minus"
                onClick={() =>
                  setAddNewProductForComplex(ProductForComplexCount - 1)
                }
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.92 12.75H7.92C7.51 12.75 7.17 12.41 7.17 12C7.17 11.59 7.51 11.25 7.92 11.25H15.92C16.33 11.25 16.67 11.59 16.67 12C16.67 12.41 16.34 12.75 15.92 12.75Z"
                    fill="#9DD2CE"
                  />
                </svg>
              </Button>
            )}
          </div>
        </div>
      </div>
      {props.complexProductsFields.length > 1 && (
        <Tooltip title={t('Inventory.Products.delete')}>
          <Button
            style={{ marginTop: '15px', color: 'red' }}
            className="delete-field-btn"
            size="large"
            type="dashed"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={() => props.removeProductsComplexField(props.index)}
          />
        </Tooltip>
      )}
    </>
  );
};

export default ComplexProductsComponent;
