/* eslint-disable eqeqeq */
import { Button, Tooltip } from 'antd';
import EditIcon from '../../../common/icons/EditIcon';
import { EyeOutlined } from '@ant-design/icons';
import { Link as RouterLink } from 'react-router-dom';
import routerLinks from '../../../components/app/routerLinks';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import errorNotification from '../../../utils/errorNotification';
import getReturnInvoiceApi from '../../../apis/return-invoice-apis/getReturnInvoiceApi';

const TableColumns = (
  allFetchedReceipts,
  setModalOpened,
  setFetchCount,
  setSelectedReceipt,
  setIsLoading,
  setSelectedReceiptModalOpened,
  setInstallmentModalOpened,
  withoutReturnBtn,
  token,
  taxPage
) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();
  const arr =
    allFetchedReceipts?.invoices?.length > 0
      ? allFetchedReceipts.invoices
      : allFetchedReceipts;
  const handleViewReceiptClick = (record) => {
    const foundReceipt =
      arr?.length > 0 && arr.find((receipt) => receipt.id === record.id);
    if (taxPage) {
      setSelectedReceipt(record);
      setSelectedReceiptModalOpened(true);
    }
    if (foundReceipt) {
      setSelectedReceipt({ ...foundReceipt });
      setSelectedReceiptModalOpened(true);
    }
  };
  const handleReturnClick = (record) => {
    let id =
      record?.parentInvoice != 0 && record?.parentInvoice != undefined
        ? record?.parentInvoice
        : record.id;
    customApiRequest(
      getReturnInvoiceApi(user?.token, id, 2),
      (res) => {
        setIsLoading(false);
        if (res?.data?.data) {
          setModalOpened(true);
          setSelectedReceipt(res?.data?.data);
          // setFetchCount((prevState) => prevState + 1);
        } else {
          errorNotification({
            title: t('Invoices.errorOccurred'),
            message: res?.data?.message
              ? res.data.message
              : t('Invoices.tryLaterMessage')
          });
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
    // const foundReceipt =
    //   arr?.length > 0 && arr.find((receipt) => receipt.id === record.id);
    // if (foundReceipt) {
    //   setSelectedReceipt({ ...foundReceipt });
    //   setModalOpened(true);
    // }
  };

  const handleShareApi = async (invoiceId) => {
    try {
      const res = await axios
        .get(`/saleInvoice/share?invoice_id=${invoiceId}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            lang: 'ar'
          }
        })
        .then((res) => {
          if (res?.data?.data?.url) {
            window.location.href = res?.data?.data?.url;
          }
        })
        .catch((err) => {});

      return res;
    } catch (error) {
      throw error;
    }
  };
  const handleShareEmailApi = async (invoiceId) => {
    try {
      const res = await axios
        .post(
          `/saleInvoice/email?invoice_id=${invoiceId}`,
          {},
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
              lang: 'ar'
            }
          }
        )
        .then((res) => {
          if (res?.data?.data?.url) {
            window.location.href = res?.data?.data?.url;
          }
        })
        .catch((err) => {});

      return res;
    } catch (error) {
      throw error;
    }
  };

  const renderShareBtn = (invoiceId) => {
    return (
      <div className="row-cell row-circled-btn">
        <div
          className="bt-target"
          onClick={() => {
            handleShareApi(invoiceId);
          }}
          style={{
            color: 'white',
            fontSize: '15px'
          }}
        >
          مشاركة{' '}
        </div>
      </div>
    );
  };

  const renderShareEmailBtn = (invoiceId) => {
    return (
      <div className="row-cell row-circled-btn">
        <div
          className="bt-target"
          onClick={() => {
            handleShareEmailApi(invoiceId);
          }}
          style={{
            color: 'white',
            fontSize: '15px'
          }}
        >
          إرسال إيميل
        </div>
      </div>
    );
  };

  const renderActions = (record) => {
    if (record?.is_return === 0) {
      if (parseFloat(record?.totalPrice) == 0) {
        return (
          <div
            className="edit-btn"
            style={{
              opacity: '.6',
              cursor: 'not-allowed'
            }}
          >
            <EditIcon />
            {t('Invoices.ReturnRequest')}
          </div>
        );
      }
      return (
        <div className="edit-btn" onClick={() => handleReturnClick(record)}>
          <EditIcon />
          {t('Invoices.ReturnRequest')}
        </div>
      );
    } else if (record?.is_return === 1) {
      return (
        <div
          className="edit-btn"
          style={{
            opacity: '.6',
            cursor: 'not-allowed'
          }}
        >
          {t('Invoices.invoiceReturned')}
        </div>
      );
    } else if (record?.is_return === 2) {
      return (
        <div className="edit-btn" onClick={() => handleReturnClick(record)}>
          <EditIcon />
          {t('Invoices.ReturnRequest')}
        </div>
      );
    } else {
      return t('Invoices.invoiceReturned');
    }
  };

  return [
    {
      title: t('Invoices.Serial'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span>{record.invoiceNumber}</span>
          </div>
        );
      }
    },

    {
      title: t('Invoices.client'),
      dataIndex: 'user',
      render: (_, record) => {
        return (
          <div className="row-cell row-text desc-wrap">
            {/* {record.name ? record.name : '----'} */}
            {record.user?.name
              ? record.user.name
              : record?.supplier?.name
              ? record.supplier.name
              : '----'}
          </div>
        );
      }
    },

    {
      title: t('Invoices.InvoiceDate'),
      dataIndex: 'date',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.date ? record.date : '----'}
          </div>
        );
      }
    },
    {
      title: t('Invoices.SupplyDate'),
      dataIndex: 'dueDate',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.dueDate ? record.dueDate : '----'}
          </div>
        );
      }
    },
    {
      title: t('Invoices.time'),
      dataIndex: 'time',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.time ? record.time : '----'}
          </div>
        );
      }
    },

    // {
    //   title: 'ملاحظات',
    //   dataIndex: 'note',
    //   render: (_, record) => {
    //     if (record.note) {
    //       const wordsCount = countWords(String(record.note));
    //       if (wordsCount > 3) {
    //         return (
    //           <Popover title="اقرأ المزيد" content={String(record.note)}>
    //             <Button size="large" type="text">
    //               <p className="has-read-more">
    //                 <span>{trimWords(String(record.note), 4)}</span>
    //                 <ReadOutlined
    //                   className="read-more-book"
    //                   style={{ margin: '0 4px' }}
    //                 />
    //               </p>
    //             </Button>
    //           </Popover>
    //         );
    //       } else
    //         return (
    //           <div className="row-cell row-text">{String(record.note)}</div>
    //         );
    //     } else {
    //       return '----';
    //     }
    //   }
    // },

    {
      title: t('Invoices.TotalPrice'),
      dataIndex: 'totalPrice',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.type == 2 && record?.totalPrice > 0 && ' - '}
            {record?.totalPrice ? record.totalPrice : '----'}
          </div>
        );
      }
    },
    // {
    //   title: 'إيصالات الفاتورة',
    //   render: (_, record) => {
    //     return record?.paymentType == 2 ? (
    //       <RouterLink
    //         className="row-cell row-circled-btn"
    //         to={routerLinks.saleInvoiceInstallmentsPage(record.id)}
    //       >
    //         <Tooltip title="إيصالات الفاتورة">
    //           <Button
    //             className="circled-btn"
    //             type="dashed"
    //             shape="circle"
    //             icon={<EyeOutlined />}
    //           />
    //         </Tooltip>
    //       </RouterLink>
    //     ) : (
    //       '----'
    //     );
    //   }
    // },
    // {
    //   title: 'إرسال إيميل',
    //   width: 135,
    //   dataIndex: 'totalPrice',
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         {renderShareEmailBtn(record?.id)}
    //       </div>
    //     );
    //   }
    // },
    // {
    //   title: 'مشاركة الفاتورة',
    //   dataIndex: 'totalPrice',
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">{renderShareBtn(record?.id)}</div>
    //     );
    //   }
    // },
    {
      title: t('Invoices.PreviewInvoice'),
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            <Tooltip title={t('Invoices.PreviewInvoice')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => handleViewReceiptClick(record)}
              />
            </Tooltip>
          </div>
        );
      }
    },
    withoutReturnBtn
      ? {}
      : {
          title: t('Invoices.ReturnRequest'),
          dataIndex: 'totalPrice',
          render: (_, record) => {
            return (
              <div className="row-cell row-actions">
                {record?.type == 2 &&
                record?.parentInvoice != 0 &&
                record?.parentInvoice != undefined ? (
                  <div className="row-cell row-actions">
                    <h3>{t('Invoices.returnedInvoice')}</h3>
                  </div>
                ) : record?.type == 1 &&
                  (record?.parentInvoice == 0 ||
                    record?.parentInvoice == undefined) ? (
                  <div className="row-cell row-actions">
                    {renderActions(record)}
                  </div>
                ) : null}
              </div>
            );
          }
        }
  ];
};

export default TableColumns;
