/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { CameraFilled, DeleteOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DatePicker, Form, Tooltip } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import {
  useController,
  useFieldArray,
  useForm,
  useWatch
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import addNewProductApi from '../../apis/store-apis/products/addNewProductApi';
import addNewSubProductApi from '../../apis/store-apis/products/addNewSubProductApi';
import getAllProductsIngredients from '../../apis/store-apis/products/getAllProductsIngredients';
import getAllProductsWithoutPage from '../../apis/store-apis/products/getAllProductsWithoutPage';
import getAllVariants from '../../apis/store-apis/products/getAllVariants';
import AntdRadioGroup from '../../common/antd-form-components/AntdRadioGroup';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import AntdTextPosField from '../../common/antd-form-components/AntdTextPosField';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import MyDropZone from '../../common/dorp-zone/MyDropZone';
import MyDropZonePreview from '../../common/dorp-zone/MyDropZonePreview';
import RadioButtonEmpty from '../../common/icons/RadioButtonEmpty';
import RadioButtonFilled from '../../common/icons/RadioButtonFilled';
import SettingsCategoriesContext from '../../contexts/settings-contexts/SettingsCategoriesProvider';
import SettingsMeasurementUnitsContext from '../../contexts/settings-contexts/SettingsMeasurementUnitsPage';
import StoreNewProductModalContext from '../../contexts/store-new-product-context/StoreNewProductProvider';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import useMainCategories from '../../custom-hooks/useMainCategories';
import useSubCategories from '../../custom-hooks/useSubCategories';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
import useUnits from '../../custom-hooks/useUnits';
import useWarehouses from '../../custom-hooks/useWarehouses';
import productTaxTypes from '../../productTaxTypes';
import errorNotification from '../../utils/errorNotification';
import successNotification from '../../utils/successNotification';
import ComplexProductsComponent from './ComplexProductsComponent';
import GenerateMultiBarCode from './GenerateMultiBarCode';
import GenerateSubBarCode from './GenerateSubBarCode';
import HandleIngredientsComponent from './HandleIngredientsComponent';
import HandleMultiImage from './HandleMultiImage';
import RenderFunction from './RenderFunction';
const StoreNewProductForm = () => {
  const { t } = useTranslation();
  const { user } = React.useContext(UserContext);
  const systemSettings = useSystemSettings();
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedProduct,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(StoreNewProductModalContext);
  const { setModalOpened: setCatModalOpened } = useContext(
    SettingsCategoriesContext
  );
  const { setModalOpened: setUnitModalOpened } = useContext(
    SettingsMeasurementUnitsContext
  );
  const [isSchema, setIsSchema] = useState();

  const schema = Yup.object().shape({
    type: Yup.string().optional(t('Inventory.Products.chooseTypeItem')),
    product_name: Yup.string()
      .required(t('Inventory.Products.enterProductName'))
      .max('100', t('Inventory.Products.maxProductNameLength')),
    product_category: Yup.string().required(
      t('Inventory.Products.selectMainProductCategory')
    ),
    stock_id: Yup.string().test(
      'stock_id',
      t('Inventory.Products.chooseYourMainWarehouse'),
      (v, context) => {
        let result = true;
        if (!v && context.parent.type == 2) result = false;
        return result;
      }
    ),

    product_sub_category: Yup.string().optional(
      t('Inventory.Products.selectProductSubcategory')
    ),
    // product_image: Yup.mixed().required('اختار صورة المنتج'),
    product_buying_price: Yup.string()
      // .required('ادخل سعر الشراء')
      .matches(/^(\d+)?(\.\d+)?$/, t('Inventory.Products.onlyNumbersAllowed')),
    tax: Yup.string()
      .test('tax', t('Inventory.Products.onlyNumbersAllowed'), (v, context) => {
        let result = true;
        if (!v) {
          result = true;
        } else if (v && !v.match(/^(\d+)?(\.\d+)?$/)) {
          result = false;
        }
        return result;
      })
      .test('tax', t('Inventory.Products.enterTax'), (v, context) => {
        let result = true;
        if (
          (!v || (v && parseFloat(v) === 0)) &&
          context?.parent?.taxType == productTaxTypes?.withTax
        ) {
          result = false;
        }
        return result;
      }),
    salePrice: Yup.string()
      .optional(t('Inventory.Products.enterSellingPrice'))
      .matches(/^(\d+)?(\.\d+)?$/, t('Inventory.Products.onlyNumbersAllowed')),
    // .test('salePrice', 'ادخل سعر البيع.', (v, context) => {
    //   let result = true;
    //   if (
    //     (!v || parseFloat(v) == 0) &&
    //     context?.parent?.taxType == productTaxTypes?.withoutTax
    //   ) {
    //     result = false;
    //   }
    //   return result;
    // }),
    first_quantity: Yup.string().matches(
      /^(\d+)?(\.\d+)?$/,
      t('Inventory.Products.onlyNumbersAllowed')
    ),
    unit_price: Yup.string()
      .optional('ادخل سعر البيع الوحدة الأساسيه')
      .matches(/^(\d+)?(\.\d+)?$/, t('Inventory.Products.onlyNumbersAllowed')),
    salePriceWithTax: Yup.string()
      // .required('ادخل سعر البيع بعد الضريبة')
      .matches(/^(\d+)?(\.\d+)?$/, t('Inventory.Products.onlyNumbersAllowed'))
      .test(
        'salePriceWithTax',
        t('Inventory.Products.enterSellingPriceTax'),
        (v, context) => {
          let result = true;
          if (
            (!v || parseFloat(v) == 0) &&
            context?.parent?.taxType == productTaxTypes?.withTax
          ) {
            result = false;
          } else {
            result = true;
          }
          return result;
        }
      ),
    wholesale_price: Yup.string()
      .optional('ادخل سعر الجملة')
      .matches(/^(\d+)?(\.\d+)?$/, t('Inventory.Products.onlyNumbersAllowed')),
    // profit_margin: Yup.string()
    //   .optional('ادخل هامش الربح')
    //   .matches(/^(\d+)?(\.\d+)?$/, t('Inventory.Products.onlyNumbersAllowed')),
    // profit_margin_ratio: Yup.string()
    //   .optional('ادخل نسبه هامش الربح')
    //   .matches(/^(\d+)?(\.\d+)?$/, t('Inventory.Products.onlyNumbersAllowed')),
    // product_alert_quantity: Yup.string()
    //   .required('ادخل كمية التنبيه')
    //   .matches(/^[0-9]+$/, 'لا يسمح الا بالارقام'),
    // measruing_unit: Yup.string().required('اختار وحدة القياس'),
    // product_discount_rate: Yup.string().matches(
    //   /^(\d+)?(\.\d+)?$/,
    //   t('Inventory.Products.onlyNumbersAllowed')
    // ),
    product_description: Yup.string().max(
      '250',
      t('Inventory.Products.maxProductDetailsLength')
    ),
    // .test(
    //   'product_description',
    //   'من فضلك ادخل تفاصيل المنتج',
    //   (v) => {
    //     let result = true;
    //     if (!v || v === '<p><br></p>') {
    //       result = false;
    //     }
    //     return result;
    //   }
    // )
    ingredients: Yup.array()
      // test select options and radio buttons
      .of(
        Yup.object().shape({
          id: Yup.string()
            .test(
              'ingrediants.index.id',
              t('Inventory.Products.chooseIngredientName'),
              (v, context) => {
                let result = true;
                if (!v && context.from[1]?.value?.type == 2) result = false;
                return result;
              }
            )
            .test(
              'ingrediants.index.id',
              t('Inventory.Products.chooseIngredientName'),
              (v, context) => {
                let result = true;
                if (!v && context?.parent?.id) {
                  result = false;
                }
                return result;
              }
            ),
          quantity: Yup.string()
            .matches(
              /^(\d+)?(\.\d+)?$/,
              t('Inventory.Products.onlyNumbersAllowed')
            )
            .test(
              'ingrediants.index.quantity',
              t('Inventory.Products.enterQuantity'),
              (v, context) => {
                let result = true;
                if (!v && context.from[1]?.value?.type == 2) result = false;
                return result;
              }
            )
            .test(
              'ingrediants.index.quantity',
              t('Inventory.Products.enterQuantity'),
              (v, context) => {
                let result = true;
                if (!v && context?.parent?.quantity) {
                  result = false;
                }
                return result;
              }
            )
            .test(
              'ingrediants.index.quantity',
              t('Inventory.Products.onlyNumbersAllowed'),
              (v, context) => {
                let result = true;
                if (v && !v.match(/^(\d+)?(\.\d+)?$/)) {
                  result = false;
                }
                return result;
              }
            ),
          result: Yup.string().test(
            'ingrediants.index.result',
            t('Inventory.Products.enterQuantity'),
            (v, context) => {
              let result = true;
              if (!v && context.from[1]?.value?.type == 2) result = false;
              return result;
            }
          )
        })
      ),
    // ingredients: Yup.array()
    //   // test select options and radio buttons
    //   .of(
    //     Yup.object().shape({
    //       ingredient_id: Yup.string()
    //         .test(
    //           'ingrediants.index.ingredient_quantity',
    //           'اختار اسم المكون',
    //           (v, context) => {
    //             let result = true;
    //             if (!v && context.from[1]?.value?.type == 2) result = false;
    //             return result;
    //           }
    //         )
    //         .test(
    //           'ingrediants.index.ingredient_quantity',
    //           'اختار اسم المكون',
    //           (v, context) => {
    //             let result = true;
    //             if (!v && context?.parent?.ingredient_quantity) {
    //               result = false;
    //             }
    //             return result;
    //           }
    //         ),
    //       ingredient_quantity: Yup.string()
    //         .test(
    //           'ingrediants.index.ingredient_quantity',
    //           'ادخل الكمية',
    //           (v, context) => {
    //             let result = true;
    //             if (!v && context.from[1]?.value?.type == 2) result = false;
    //             return result;
    //           }
    //         )
    //         .test(
    //           'ingrediants.index.ingredient_quantity',
    //           'ادخل الكمية',
    //           (v, context) => {
    //             let result = true;
    //             if (!v && context?.parent?.ingredient_id) {
    //               result = false;
    //             }
    //             return result;
    //           }
    //         )
    //         .test(
    //           'ingrediants.index.ingredient_quantity',
    //           t('Inventory.Products.onlyNumbersAllowed'),
    //           (v, context) => {
    //             let result = true;
    //             if (v && !v.match(/^(\d+)?(\.\d+)?$/)) {
    //               result = false;
    //             }
    //             return result;
    //           }
    //         )
    //     })
    //   ),
    subProducts: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().test(
          'subProducts.index.name',
          t('Inventory.Products.chooseUnitName'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1]?.value?.type == 2) result = false;
            return result;
          }
        ),
        saleSubPrice: Yup.string().test(
          'subProducts.index.saleSubPrice',
          t('Inventory.Products.enterUnitPrice'),
          (v, context) => {
            let result = true;
            // if (!v && context.from[1]?.value?.type == 2) result = false;
            return result;
          }
        ),
        quantity: Yup.string()
          .matches(
            /^(\d+)?(\.\d+)?$/,
            t('Inventory.Products.onlyNumbersAllowed')
          )
          .test(
            'subProducts.index.quantity',
            t('Inventory.Products.enterNumberOfUnits'),
            (v, context) => {
              let result = true;
              if (!v && context.from[1]?.value?.type == 2) result = false;
              return result;
            }
          ),
        barCode: Yup.string().test(
          'subProducts.index.barCode',
          t('Inventory.Products.enterBeginningInventory'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1]?.value?.type == 2) result = false;
            return result;
          }
        )
      })
    ),
    // subProducts: Yup.array()
    //   .of(
    //     Yup.object().shape({
    //       name: Yup.string()
    //         .test(
    //           'subProducts.index.name',
    //           'اختار اسم الوحدة',
    //           (v, context) => {
    //             let result = true;
    //             if (!v && context.from[1]?.value?.type == 2) result = false;
    //             return result;
    //           }
    //         ),
    //       weight: Yup.string()
    //         .test(
    //           'subProducts.index.weight',
    //           'ادخل الوزن',
    //           (v, context) => {
    //             let result = true;
    //             if (!v && context.from[1]?.value?.type == 2) result = false;
    //             return result;
    //           }
    //         )
    //         .test(
    //           'subProducts.index.weight',
    //           'ادخل الكمية',
    //           (v, context) => {
    //             let result = true;
    //             if (!v && context?.parent?.weight) {
    //               result = false;
    //             }
    //             return result;
    //           }
    //         )
    //         .test(
    //           'subProducts.index.weight',
    //           t('Inventory.Products.onlyNumbersAllowed'),
    //           (v, context) => {
    //             let result = true;
    //             if (v && !v.match(/^(\d+)?(\.\d+)?$/)) {
    //               result = false;
    //             }
    //             return result;
    //           }
    //         ),
    //       saleSubPrice: Yup.string()
    //         .test(
    //           'subProducts.index.saleSubPrice',
    //           'أدخل سعر الوحدة',
    //           (v, context) => {

    //             let result = true;
    //             // if (!v && context.from[1]?.value?.type == 2) result = false;
    //             return result;
    //           }
    //         ),
    //       first_quantity: Yup.string()
    //         .test(
    //           'subProducts.index.first_quantity',
    //           'أدخل مخزون اول مده',
    //           (v, context) => {
    //             let result = true;
    //             if (!v && context.from[1]?.value?.type == 2) result = false;
    //             return result;
    //           }
    //         ),
    //       stock_id: Yup.string()
    //         .test(
    //           'subProducts.index.first_quantity',
    //           'أدخل المستودع',
    //           (v, context) => {
    //             let result = true;
    //             if (!v && context.from[1]?.value?.type == 2) result = false;
    //             return result;
    //           }
    //         ),
    //     })
    //   ),

    complex_products: Yup.array().of(
      Yup.object().shape({
        quantity: Yup.string()
          .matches(
            /^(\d+)?(\.\d+)?$/,
            t('Inventory.Products.onlyNumbersAllowed')
          )
          .test(
            'complex_products.index.quantity',
            t('Inventory.Products.enterProductQuantity'),
            (v, context) => {
              let result = true;
              if (!v && context.from[1]?.value?.type == 2) result = false;
              return result;
            }
          ),
        unique_id: Yup.string().test(
          'complex_products.index.id',
          t('Inventory.Products.selectProduct'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1]?.value?.type == 2) result = false;
            return result;
          }
        )
      })
    ),
    extras: Yup.array().of(
      Yup.object().shape({
        extra_name: Yup.string().test(
          'extras.index.extra_name',
          t('Inventory.Products.enterExtensionName'),
          (v, context) => {
            let result = true;
            if (!v && context?.parent?.extra_price) {
              result = false;
            }
            return result;
          }
        ),
        extra_price: Yup.string().test(
          'ingrediants.index.extra_price',
          t('Inventory.Products.enterAddOnPrice'),
          (v, context) => {
            let result = true;
            if (!v && context?.parent?.extra_name) {
              result = false;
            }
            return result;
          }
        )
      })
    ),
    multiProducts: Yup.array().of(
      Yup.object().shape({
        variants: Yup.array().of(
          Yup.object().shape({
            id: Yup.string().test(
              'multiProducts.index.variants.index.id',
              t('Inventory.Products.chooseTypeOfMultiplayer'),
              (v, context) => {
                let result = true;
                if (!v && context.from[1]?.value?.type == 2) result = false;
                return result;
              }
            ),
            value: Yup.string().test(
              'multiProducts.index.variants.index.value',
              t('Inventory.Products.chooseMultiplicityValue'),
              (v, context) => {
                let result = true;
                if (!v && context.from[1]?.value?.type == 2) result = false;
                return result;
              }
            )
          })
        ),
        name: Yup.string().test(
          'multiProducts.index.name',
          t('Inventory.Products.chooseUnitName'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1]?.value?.type == 2) result = false;
            return result;
          }
        ),
        // salePrice : Yup.string().optional('أدخل سعر الوحدة'),
        saleMultiPrice: Yup.string().test(
          'multiProducts.index.saleMultiPrice',
          t('Inventory.Products.enterUnitPrice'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1]?.value?.type == 2) result = false;
            return result;
          }
        ),
        tax: Yup.string().test(
          'multiProducts.index.tax',
          t('Inventory.Products.enterTax'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1]?.value?.type == 2) result = false;
            return result;
          }
        ),
        first_quantity: Yup.string()
          .test(
            'multiProducts.index.first_quantity',
            t('Inventory.Products.enterFirstStock'),
            (v, context) => {
              let result = true;
              if (!v && context.from[1]?.value?.type == 2) result = false;
              return result;
            }
          )
          .matches(
            /^(\d+)?(\.\d+)?$/,
            t('Inventory.Products.onlyNumbersAllowed')
          ),
        wholesalePrice: Yup.string().test(
          'multiProducts.index.wholesalePrice',
          t('Inventory.Products.enterWholesale'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1]?.value?.type == 2) result = false;
            return result;
          }
        ),
        stock_id: Yup.string().test(
          'multiProducts.index.stock_id',
          t('Inventory.Products.enterStock'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1]?.value?.type == 2) result = false;
            return result;
          }
        ),
        image: Yup.mixed().test(
          'multiProducts.index.image',
          t('Inventory.Products.enterProductImage'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1]?.value?.type == 2) result = false;
            return result;
          }
        )
      })
    ),

    multiple: Yup.string().optional(
      t('Inventory.Products.chooseTypeOfMultiplayer')
    ),
    parent_id: Yup.string().optional(t('Inventory.Products.chooseProduct')),
    digital_value: Yup.mixed()
  });

  const schemaSub = Yup.object().shape({
    subProducts: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().test(
          'subProducts.index.name',
          t('Inventory.Products.chooseUnitName'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1]?.value?.type == 2) result = false;
            return result;
          }
        ),
        weight: Yup.string()
          .test(
            'subProducts.index.weight',
            t('Inventory.Products.enterWeight'),
            (v, context) => {
              let result = true;
              if (!v && context.from[1]?.value?.type == 2) result = false;
              return result;
            }
          )
          .test(
            'subProducts.index.weight',
            t('Inventory.Products.enterQuantity'),
            (v, context) => {
              let result = true;
              if (!v && context?.parent?.weight) {
                result = false;
              }
              return result;
            }
          )
          .test(
            'subProducts.index.weight',
            t('Inventory.Products.onlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (v && !v.match(/^(\d+)?(\.\d+)?$/)) {
                result = false;
              }
              return result;
            }
          ),
        extra_price: Yup.string().test(
          'ingrediants.index.extra_price',
          t('Inventory.Products.enterAddOnPrice'),
          (v, context) => {
            let result = true;
            if (!v && context?.parent?.extra_name) {
              result = false;
            }
            return result;
          }
        )
      })
    ),
    multiProducts: Yup.array().of(
      Yup.object().shape({
        variants: Yup.array().of(
          Yup.object().shape({
            id: Yup.string().test(
              'multiProducts.index.variants.index.id',
              t('Inventory.Products.chooseTypeOfMultiplayer'),
              (v, context) => {
                let result = true;
                if (!v && context.from[1]?.value?.type == 2) result = false;
                return result;
              }
            ),
            value: Yup.string().test(
              'multiProducts.index.variants.index.value',
              t('Inventory.Products.chooseMultiplicityValue'),
              (v, context) => {
                let result = true;
                if (!v && context.from[1]?.value?.type == 2) result = false;
                return result;
              }
            )
          })
        ),
        name: Yup.string().test(
          'multiProducts.index.name',
          t('Inventory.Products.chooseUnitName'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1]?.value?.type == 2) result = false;
            return result;
          }
        ),
        saleMultiPrice: Yup.string().test(
          'multiProducts.index.saleMultiPrice',
          t('Inventory.Products.enterUnitPrice'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1]?.value?.type == 2) result = false;
            return result;
          }
        ),
        tax: Yup.string().test(
          'multiProducts.index.tax',
          t('Inventory.Products.enterTax'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1]?.value?.type == 2) result = false;
            return result;
          }
        ),
        first_quantity: Yup.string()
          .test(
            'multiProducts.index.first_quantity',
            t('Inventory.Products.enterBeginningInventory'),
            (v, context) => {
              let result = true;
              if (!v && context.from[1]?.value?.type == 2) result = false;
              return result;
            }
          )
          .matches(
            /^(\d+)?(\.\d+)?$/,
            t('Inventory.Products.onlyNumbersAllowed')
          ),
        wholesalePrice: Yup.string().test(
          'multiProducts.index.wholesalePrice',
          t('Inventory.Products.enterWholesale'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1]?.value?.type == 2) result = false;
            return result;
          }
        ),
        stock_id: Yup.string().test(
          'multiProducts.index.stock_id',
          t('Inventory.Products.enterStock'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1]?.value?.type == 2) result = false;
            return result;
          }
        ),
        image: Yup.mixed().test(
          'multiProducts.index.image',
          t('Inventory.Products.enterProductImage'),
          (v, context) => {
            let result = true;
            if (!v && context.from[1]?.value?.type == 2) result = false;
            return result;
          }
        )
      })
    ),

    multiple: Yup.string().optional(
      t('Inventory.Products.chooseTypeOfMultiplayer')
    ),
    parent_id: Yup.string().optional(t('Inventory.Products.chooseProduct')),
    // digital_value: Yup.string().optional('اختار نوع المنتج')
    digital_value: Yup.mixed()
  });
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register,
    unregister,
    reset,
    formState: { errors, isSubmitting }
  } = useForm(
    isSchema == 2
      ? {
          resolver: yupResolver(schemaSub || schema),
          mode: 'all',
          defaultValues: {
            parent_id: '',
            subProducts: [
              {
                name: '',
                saleSubPrice: '',
                first_quantity: '',
                stock_id: '',
                weight: ''
              }
            ],
            multiProducts: [
              {
                name: '',
                saleMultiPrice: '',
                tax: '',
                first_quantity: '',
                wholesalePrice: '',
                stock_id: '',
                defaultParCode: '',
                image: ''
              }
            ]
          }
        }
      : {
          resolver: yupResolver(schema),
          mode: 'all',
          defaultValues: {
            type: '1',
            add_product: '1',
            end_date: '',
            product_type: '1',
            accept_discount: '1',
            active_tax: '1',
            including_tax: String(
              systemSettings?.invoiceSetting?.including_tax
            ),
            is_ingredient: '2',
            digital_type: '',
            digital_value: '',
            isWeight: '2',
            product_name: '',
            product_category: '',
            all_product: '',
            stock_id: '',
            product_sub_category: '',
            product_buying_price: '',
            product_alert_quantity: '',
            first_quantity: '',
            product_image: null,
            multi_image0: null,
            multi_image1: null,
            multi_image2: null,
            multi_image3: null,
            multi_image4: null,
            multi_image5: null,
            multi_image6: null,
            multi_image7: null,
            multi_image8: null,
            multi_image9: null,
            product_description: '',
            defaultParCode: '',
            salePrice: '',
            salePriceWithTax: '',
            buyPriceWithTax: '',
            unit_price: '',
            taxType: productTaxTypes?.withoutTax, // 2 => product price with tax, 1 => without tax
            buy_tax: '',
            tax: '',
            posOfferType: 'no_discount',
            posOfferDiscountPrice: '',
            posOfferDiscountPercentage: '',
            wholesale_price: '',
            // profit_margin: '',
            // profit_margin_ratio: '',
            measruing_unit: '',
            subProducts: [
              { name: '', saleSubPrice: '', quantity: '', defaultParCode: '' }
            ],
            // subProducts : [{name : '', saleSubPrice: '', first_quantity: '', stock_id : '',  weight  : ''}],
            parent_id: '',
            multiple: '',
            variants0: [{ id: '', value: '' }],
            variants1: [{ id: '', value: '' }],
            variants2: [{ id: '', value: '' }],
            variants3: [{ id: '', value: '' }],
            variants4: [{ id: '', value: '' }],
            variants5: [{ id: '', value: '' }],
            variants6: [{ id: '', value: '' }],
            variants7: [{ id: '', value: '' }],
            variants8: [{ id: '', value: '' }],
            variants9: [{ id: '', value: '' }],
            // ingredients: [{ingredient_id: '', ingredient_quantity: ''}],
            complex_products: [{ unique_id: '', quantity: '0' }],
            ingredients: [
              { unique_id: '', quantity: '', result: '', waste: '', price: '' }
            ],

            multiProducts: [
              {
                saleMultiPrice: '',
                tax: '',
                first_quantity: '',
                wholesalePrice: '',
                stock_id: '',
                defaultParCode: '',
                image: ''
              }
            ]
          }
        }
  );
  useEffect(() => {
    setIsSchema(watch('add_product') == 1);
  }, [watch('add_product')]);

  const {
    fields: subProductsFields,
    append: appendSubProductsField,
    remove: removeSubProductsField
  } = useFieldArray({
    control,
    name: 'subProducts'
  });

  const {
    fields: multiProductsFields,
    append: appendMultiProductsField,
    remove: removeMultiProductsField
  } = useFieldArray({
    control,
    name: 'multiProducts'
  });

  const {
    fields: complexProductsFields,
    append: appendProductsComplexField,
    remove: removeProductsComplexField
  } = useFieldArray({
    control,
    name: 'complex_products'
  });

  const {
    fields: ingredientsFields,
    append: appendIngredientsField,
    remove: removeIngredientsField
  } = useFieldArray({
    control,
    name: 'ingredients'
  });

  const [barCodeNum, setBarCodeNum] = useState(0);
  const randomNumberInRange = (length) => {
    return Math.floor(
      Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)
    );
  };
  const handleClick = () => {
    setBarCodeNum(randomNumberInRange(13));
  };

  const allWarehouses = useWarehouses();
  const [productFileToUpload, setProductFileToUpload] = useState(
    watch('product_image') ? watch('product_image') : null
  );
  useEffect(() => {
    setValue('product_image', productFileToUpload);
  }, [productFileToUpload, productFileToUpload?.length]);
  useEffect(() => {
    register('product_image', {
      required: t('Inventory.Products.chooseProductImageFirst')
    });

    return () => unregister('product_image');
  }, []);

  const [productFileToUpload1] = useState(
    watch('multi_image0') ? watch('multi_image0') : null
  );
  useEffect(() => {
    setValue(`multi_image`, productFileToUpload1);
  }, [productFileToUpload1, productFileToUpload1?.length]);

  useEffect(() => {
    register('multi_image', {
      required: t('Inventory.Products.chooseProductImageFirst')
    });

    return () => unregister('multi_image');
  }, []);

  // reset parcode
  useEffect(() => {
    if (watch('isWeight') == '1') {
      setValue('defaultParCode', '');
    }
  }, [watch('isWeight')]);

  useEffect(() => {
    if (watch('active_tax') == 2) {
      setValue('tax', '');
      setValue('salePriceWithTax', '');
      setValue('buyPriceWithTax', '');
      setValue('including_tax', '0');
    }
  }, [watch('active_tax')]);
  useEffect(() => {
    // if (systemSettings?.invoiceSetting?.including_tax == 1) {
    //   setValue('including_tax', 1);
    // } else if (systemSettings?.invoiceSetting?.including_tax == 0){
    //   setValue('including_tax', 0);
    // }
    if (watch('including_tax') && !selectedProduct) {
      setValue('tax', '');
      setValue('buy_tax', '');
      setValue('salePrice', '');
      setValue('salePriceWithTax', '');
      setValue('buyPriceWithTax', '');
      setValue('product_buying_price', '');
    }
  }, [watch('including_tax'), systemSettings?.invoiceSetting?.including_tax]);
  useEffect(() => {
    setValue('including_tax', systemSettings?.invoiceSetting?.including_tax);
  }, [systemSettings?.invoiceSetting?.including_tax]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handleFilesDrop = async (acceptedFiles, formFiles, setFormFiles) => {
    const duplicates = [];
    for (let i of acceptedFiles) {
      if (formFiles?.length) {
        for (let f of formFiles) {
          if (i.name === f.name) {
            duplicates.push(i);
          }
        }
      }
    }
    for (let i = 0; i < acceptedFiles.length; i++) {
      for (let f of duplicates) {
        if (acceptedFiles[i].name === f.name) {
          acceptedFiles.splice(i, 1);
        }
      }
    }

    acceptedFiles.forEach(async (file) => {
      const preview = await getBase64(file);
      Object.assign(file, {
        preview
      });
      // setFormFiles((prevState) => [...prevState, file]);
      if (formFiles?.length > 0) {
        setFormFiles((currState) => [...currState, file]);
      } else {
        setFormFiles([file]);
      }
    });
  };

  const clearFileFromUpload = (file, setFilesToUpload) => {
    setFilesToUpload((prevState) => {
      const filtered = prevState.filter((asset) => asset.name !== file.name);
      if (filtered?.length === 0) return null;
      return filtered;
    });
  };

  const mainCategories = useMainCategories();
  const subCategories = useSubCategories(String(watch('product_category')));
  const allUnits = useUnits();
  useEffect(() => {
    if (watch('product_category') && watch('product_sub_category')) {
      if (
        subCategories?.length >= 0 &&
        subCategories[0]?.cat_id !== parseInt(watch('product_category'))
      ) {
        setValue('product_sub_category', '');
      }
    }
  }, [watch('product_category'), watch('product_sub_category')]);

  const renderUnitsFields = () => {
    return (
      subProductsFields?.length > 0 && (
        <div className="fields-array-ul-content">
          <ul className="fields-array-ul with-border">
            {subProductsFields.map((item, index) => {
              return (
                <li key={item.id}>
                  <h3>{t('Inventory.Products.basicUnit')}</h3>
                  <div className="field-delete-li">
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.unitName')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          className="form-text-field"
                          name={`subProducts.${index}.name`}
                          type="text"
                          placeholder={t(
                            'Inventory.Products.unitNamePlaceholder'
                          )}
                          errorMsg={
                            errors?.name &&
                            errors.subProducts[index]?.name &&
                            errors.subProducts[index].name.message
                          }
                          validateStatus={
                            errors?.name &&
                            errors.subProducts[index]?.name &&
                            errors.subProducts[index].name
                              ? 'error'
                              : ''
                          }
                          control={control}
                        />
                      </div>
                    </div>
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.numberOfUnits')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          className="form-text-field"
                          name={`subProducts.${index}.all_units`}
                          type="text"
                          placeholder={t(
                            'Inventory.Products.numberOfUnitsPlaceholder'
                          )}
                          errorMsg={
                            errors?.all_units &&
                            errors.subProducts[index]?.all_units &&
                            errors.subProducts[index].all_units.message
                          }
                          validateStatus={
                            errors?.all_units &&
                            errors.subProducts[index]?.all_units &&
                            errors.subProducts[index].all_units
                              ? 'error'
                              : ''
                          }
                          control={control}
                          // onChange={(e) => setQuantity(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.enterPrice')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          setValue={setValue}
                          className="form-text-field"
                          name={`subProducts.${index}.saleSubPrice`}
                          type="text"
                          placeholder={t(
                            'Inventory.Products.enterPricePlaceholder'
                          )}
                          errorMsg={
                            errors?.subProducts &&
                            errors?.subProducts[index]?.saleSubPrice &&
                            errors?.subProducts[index].saleSubPrice.message
                          }
                          validateStatus={
                            errors?.subProducts &&
                            errors?.subProducts[index]?.saleSubPrice &&
                            errors?.subProducts[index].saleSubPrice
                              ? 'error'
                              : ''
                          }
                          control={control}
                          defaultValue={
                            watch('including_tax') == 0
                              ? parseFloat(watch(`salePrice`)) *
                                  parseFloat(
                                    watch(`subProducts.${index}.all_units`)
                                  ) || ''
                              : parseFloat(watch(`salePriceWithTax`)) *
                                  parseFloat(
                                    watch(`subProducts.${index}.all_units`)
                                  ) || ''
                          } // make sure to set up defaultValue
                          readOnly={true}
                        />
                      </div>
                    </div>
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.quantity')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          setValue={setValue}
                          className="form-text-field"
                          name={`subProducts.${index}.quantity`}
                          type="text"
                          placeholder={t(
                            'Inventory.Products.quantityPlaceholder'
                          )}
                          errorMsg={errors?.quantity?.message}
                          validateStatus={errors?.quantity ? 'error' : ''}
                          control={control}
                          defaultValue={
                            parseFloat(watch(`first_quantity`)) /
                              parseFloat(
                                watch(`subProducts.${index}.all_units`)
                              ) || ''
                          }
                          readOnly={true}
                        />
                      </div>
                    </div>
                    <GenerateSubBarCode
                      index={index}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      t={t}
                    />

                    {subProductsFields.length > 1 && (
                      <Tooltip title={t('Inventory.Products.delete')}>
                        <Button
                          className="delete-field-btn"
                          size="large"
                          type="dashed"
                          shape="circle"
                          icon={<DeleteOutlined />}
                          onClick={() => removeSubProductsField(index)}
                        />
                      </Tooltip>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )
    );
  };
  const [allVariants, setAllVariants] = useState();
  useEffect(() => {
    customApiRequest(
      getAllVariants(user?.token, watch('product_type')),
      (res) => {
        if (res?.status === 200 && res?.data?.data?.data?.length >= 0) {
          const data = res?.data?.data?.data;
          setAllVariants(data);
        }
      },
      (error) => {}
    );
  }, [watch('product_type')]);

  // المنتج المتعدد
  const renderMultiFields = () => {
    return (
      multiProductsFields?.length > 0 && (
        <div className="fields-array-ul-content">
          <ul
            className={
              !selectedProduct
                ? 'fields-array-ul with-border'
                : 'fields-array-ul-update'
            }
          >
            {multiProductsFields.map((item, index) => {
              return (
                <li className="field-comp" key={item.id}>
                  {!selectedProduct && (
                    <RenderFunction
                      index={index}
                      control={control}
                      setValue={setValue}
                      watch={watch}
                      errors={errors}
                      allVariants={allVariants}
                      register={register}
                    />
                  )}
                  {/* <MultiProductsComponent index={index} watch={watch} control={control} setValue={setValue} errors={errors} selectedProduct={selectedProduct} multiProductsFields={multiProductsFields} register={register} unregister={unregister} removeMultiProductsField={removeMultiProductsField}/> */}
                  <div className="field-comp">
                    <div className="field-delete-li-products">
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Inventory.Products.sellingPriceWithoutTax')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            name={`multiProducts.${index}.saleMultiPrice`}
                            type="text"
                            placeholder={t(
                              'Inventory.Products.sellingPriceWithoutTaxPlaceholder'
                            )}
                            errorMsg={
                              errors.multiProducts &&
                              errors.multiProducts[index]?.saleMultiPrice &&
                              errors.multiProducts[index]?.saleMultiPrice
                                .message
                            }
                            validateStatus={
                              errors.multiProducts &&
                              errors.multiProducts[index]?.saleMultiPrice &&
                              errors.multiProducts[index]?.saleMultiPrice
                                ? 'error'
                                : ''
                            }
                            setValue={setValue}
                            control={control}
                            defaultValue={
                              selectedProduct && selectedProduct?.salePrice
                            }
                          />
                        </div>
                      </div>
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Inventory.Products.salesTax')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextPosField
                            setValue={setValue}
                            className="form-text-field"
                            name={`multiProducts.${index}.tax`}
                            type="text"
                            placeholder={t(
                              'Inventory.Products.salesTaxPlaceholder'
                            )}
                            errorMsg={
                              errors?.tax &&
                              errors.multiProducts[index]?.tax &&
                              errors.multiProducts[index].tax.message
                            }
                            validateStatus={
                              errors?.tax &&
                              errors.multiProducts[index]?.tax &&
                              errors.multiProducts[index].tax
                                ? 'error'
                                : ''
                            }
                            control={control}
                            readOnly={true}
                            defaultValue={
                              systemSettings?.invoiceSetting?.tax > 0 &&
                              watch(`multiProducts.${index}.saleMultiPrice`)
                                ? parseFloat(
                                    (systemSettings?.invoiceSetting?.tax *
                                      watch(
                                        `multiProducts.${index}.saleMultiPrice`
                                      )) /
                                      100
                                  ).toFixed(3)
                                : ''
                            }
                            value={systemSettings?.invoiceSetting?.buy_tax}
                          />
                        </div>
                      </div>

                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Inventory.Products.sellingPriceIncludesTax')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            setValue={setValue}
                            className="form-text-field"
                            // name="salePriceWithTax"
                            name={`multiProducts.${index}.salePriceWithTax`}
                            type="text"
                            placeholder={t(
                              'Inventory.Products.sellingPriceIncludesTaxPlaceholder'
                            )}
                            errorMsg={errors?.salePriceWithTax?.message}
                            validateStatus={
                              errors?.salePriceWithTax ? 'error' : ''
                            }
                            control={control}
                            defaultValue={
                              parseFloat(watch(`multiProducts.${index}.tax`)) >
                              0
                                ? parseFloat(
                                    watch(
                                      `multiProducts.${index}.saleMultiPrice`
                                    )
                                  ) +
                                  parseFloat(
                                    watch(`multiProducts.${index}.tax`)
                                  )
                                : parseFloat(
                                    watch(
                                      `multiProducts.${index}.saleMultiPrice`
                                    )
                                  )
                                ? parseFloat(
                                    watch(
                                      `multiProducts.${index}.saleMultiPrice`
                                    )
                                  )
                                : ''
                            }
                            readOnly={true}
                          />
                        </div>
                      </div>
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Inventory.Products.firstTermStock')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            name={`multiProducts.${index}.first_quantity`}
                            type="text"
                            placeholder={t(
                              'Inventory.Products.firstTermStockPlaceholder'
                            )}
                            errorMsg={
                              errors?.first_quantity &&
                              errors.multiProducts[index]?.first_quantity &&
                              errors.multiProducts[index].first_quantity.message
                            }
                            validateStatus={
                              errors?.first_quantity &&
                              errors.multiProducts[index]?.first_quantity &&
                              errors.multiProducts[index].first_quantity
                                ? 'error'
                                : ''
                            }
                            setValue={setValue}
                            control={control}
                            defaultValue={
                              selectedProduct && selectedProduct?.first_quantity
                            }
                            // defaultValue={watch(`ingredients.${index}.ingredient_quantity`)} // make sure to set up defaultValue
                          />
                        </div>
                      </div>
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Inventory.Products.wholePrice')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            name={`multiProducts.${index}.wholesalePrice`}
                            type="text"
                            placeholder={t(
                              'Inventory.Products.wholePricePlaceholder'
                            )}
                            errorMsg={
                              errors?.wholesalePrice &&
                              errors.multiProducts[index]?.wholesalePrice &&
                              errors.multiProducts[index].wholesalePrice.message
                            }
                            validateStatus={
                              errors?.wholesalePrice &&
                              errors.multiProducts[index]?.wholesalePrice &&
                              errors.multiProducts[index].wholesalePrice
                                ? 'error'
                                : ''
                            }
                            control={control}
                            setValue={setValue}
                            defaultValue={
                              selectedProduct && selectedProduct?.wholesalePrice
                            }
                          />
                        </div>
                      </div>
                      <div className="select-label-wrap">
                        <p className="label-p">
                          {t('Inventory.Products.itsMainWarehouse')}
                        </p>
                        <div className="custom-select-wrap without-icon">
                          <AntdSelectOption
                            name={
                              selectedProduct?.stock_id
                                ? 'stock_id'
                                : `multiProducts.${index}.stock_id`
                            }
                            errorMsg={
                              errors?.stock_id &&
                              errors.multiProducts[index]?.stock_id &&
                              errors.multiProducts[index].stock_id.message
                            }
                            validateStatus={
                              errors?.stock_id &&
                              errors.multiProducts[index]?.stock_id &&
                              errors.multiProducts[index].stock_id
                                ? 'error'
                                : ''
                            }
                            control={control}
                            setValue={setValue}
                            placeholder={t(
                              'Inventory.Products.itsMainWarehouse'
                            )}
                            options={
                              allWarehouses?.length > 0
                                ? allWarehouses.map((obj) => ({
                                    title: obj.name,
                                    value: String(obj.id)
                                  }))
                                : null
                            }
                            showSearch={true}
                            filterOption={(input, option) =>
                               option.children.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            formClassName="store-new-product-form"
                            defaultValue={
                              selectedProduct && selectedProduct?.stock_id
                            }
                          />
                        </div>
                      </div>
                      <GenerateMultiBarCode
                        index={index}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        selectedProduct={selectedProduct}
                      />
                    </div>
                    <HandleMultiImage
                      index={index}
                      control={control}
                      setValue={setValue}
                      watch={watch}
                      errors={errors}
                      register={register}
                      unregister={unregister}
                    />
                    {multiProductsFields.length > 1 && (
                      <Tooltip title={t('Inventory.Products.delete')}>
                        <Button
                          className="delete-field-btn"
                          size="large"
                          type="dashed"
                          shape="circle"
                          icon={<DeleteOutlined />}
                          onClick={() => removeMultiProductsField(index)}
                        />
                      </Tooltip>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      )
    );
  };

  const [allProductsWithoutType, setAllProductsWithoutType] = useState();
  useEffect(() => {
    customApiRequest(
      getAllProductsWithoutPage(user?.token),
      (res) => {
        if (res?.status === 200 && res?.data?.data?.length >= 0) {
          const data = res?.data?.data;
          setAllProductsWithoutType(data);
        }
      },
      (error) => {}
    );
  }, [watch('product_type')]);

  const [allProductsIngredients, setAllProductsIngredients] = useState();
  useEffect(() => {
    customApiRequest(
      getAllProductsIngredients(user?.token, 1),
      (res) => {
        if (res?.status === 200 && res?.data?.data?.length >= 0) {
          const data = res?.data?.data;
          setAllProductsIngredients(data);
        }
      },
      (error) => {}
    );
  }, [watch('product_type')]);
  // المنتج المجمع
  const renderProductsFields = () => {
    return (
      complexProductsFields?.length > 0 && (
        <div className="fields-array-ul-content">
          <ul className="fields-array-ul with-border">
            {complexProductsFields?.map((item, index) => {
              return (
                <li key={item.id}>
                  <ComplexProductsComponent
                    item={item}
                    index={index}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    errors={errors}
                    selectedProduct={selectedProduct}
                    allProductsWithoutType={allProductsWithoutType}
                    complexProductsFields={complexProductsFields}
                    removeProductsComplexField={removeProductsComplexField}
                  />
                  {/* {complexProductsFields.length > 1 && (
                      <Tooltip title="حذف">
                        <Button
                          style={{ marginTop: '15px', color: 'red' }}
                          className="delete-field-btn"
                          size="large"
                          type="dashed"
                          shape="circle"
                          icon={<DeleteOutlined />}
                          onClick={() => removeProductsComplexField(index)}
                        />
                      </Tooltip>
                    )} */}
                </li>
              );
            })}
          </ul>
        </div>
      )
    );
  };
  // let sum = 0;
  // totalAmountForIngredients?.map((result, number) => {
  //   sum += result.value;
  // });

  function getTotal(payload) {
    let total = 0;
    for (let item of payload) {
      total =
        total +
        (parseFloat(item?.price) && parseFloat(item?.quantity)
          ? parseFloat(item?.price) * parseFloat(item?.quantity)
          : 0);
    }
    return parseFloat(total).toFixed(2);
  }

  function TotalPrice({ control }) {
    const ingredientsValues = useWatch({
      name: 'ingredients',
      control
    });
    if (getTotal(ingredientsValues)) {
      return (
        <>
          <h2>{t('Inventory.Products.total')}</h2>:
          <h2>{getTotal(ingredientsValues)}</h2>
        </>
      );
    } else {
      return null;
    }
  }
  // المنتج المكون
  const renderIngredientsFields = () => {
    return (
      ingredientsFields?.length > 0 && (
        <div className="fields-array-ul-content">
          <ul className="fields-array-ul with-border">
            {ingredientsFields?.map((item, index) => {
              return (
                <li key={item.id}>
                  <HandleIngredientsComponent
                    index={index}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    errors={errors}
                    allProductsIngredients={allProductsIngredients}
                    ingredientsFields={ingredientsFields}
                    removeIngredientsField={removeIngredientsField}
                    selectedProduct={selectedProduct}
                    getTotal={getTotal}
                  />
                  {/* {ingredientsFields.length > 1 && (
                    <Tooltip title="حذف">
                    <Button
                        style={{marginTop : '15px', color:'red'}}
                        className="delete-field-btn"
                        size="large"
                        type="dashed"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => removeIngredientsField(index)}
                    />
                    </Tooltip>
                  )} */}
                </li>
              );
            })}
          </ul>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '20px'
            }}
          >
            <TotalPrice control={control} />
          </div>
        </div>
      )
    );
  };

  useEffect(() => {
    if (!selectedProduct) {
      setValue('salePrice', '');
      setValue('salePriceWithTax', '');
    }
  }, [watch('taxType')]);

  useEffect(() => {
    if (selectedProduct) {
      if (selectedProduct?.end_date) {
        setValue(
          'end_date',
          moment(selectedProduct.end_date).format('YYYY-MM-DD')
        );
      }
      setValue('type', selectedProduct.type ? selectedProduct.type : '');
      setValue(
        'add_product',
        selectedProduct.add_product ? selectedProduct.add_product : ''
      );
      setValue(
        'product_type',
        selectedProduct.product_type ? selectedProduct.product_type : ''
      );
      setValue(
        'defaultParCode',
        selectedProduct.defaultParCode ? selectedProduct.defaultParCode : ''
      );
      if (selectedProduct?.name) {
        setValue('product_name', selectedProduct.name);
      }
      if (selectedProduct?.main_cat) {
        setValue('product_category', String(selectedProduct.main_cat?.id));
      }
      if (selectedProduct?.cat) {
        setValue('product_sub_category', String(selectedProduct.cat?.id));
      }
      if (selectedProduct?.stock_id) {
        setValue('stock_id', String(selectedProduct.stock_id));
      }
      if (selectedProduct?.buyPrice) {
        setValue('product_buying_price', parseFloat(selectedProduct.buyPrice));
      }
      if (selectedProduct?.salePrice) {
        setValue('salePrice', parseFloat(selectedProduct.salePrice));
      }
      if (selectedProduct?.salePriceWithTax) {
        setValue(
          'salePriceWithTax',
          parseFloat(selectedProduct.salePriceWithTax)
        );
      }
      if (selectedProduct?.buyPriceWithTax) {
        setValue(
          'buyPriceWithTax',
          parseFloat(selectedProduct.buyPriceWithTax)
        );
      }
      if (selectedProduct?.unit_price) {
        setValue('unit_price', parseFloat(selectedProduct.unit_price));
      }
      if (selectedProduct?.taxType) {
        setValue('taxType', parseFloat(selectedProduct.taxType));
      }
      setValue(
        'accept_discount',
        selectedProduct?.accept_discount == 0
          ? '1'
          : selectedProduct?.accept_discount
      );
      if (selectedProduct?.is_ingredient) {
        setValue(
          'is_ingredient',
          selectedProduct?.is_ingredient == 0
            ? '1'
            : selectedProduct?.is_ingredient
        );
      }
      if (selectedProduct?.active_tax) {
        setValue(
          'active_tax',
          selectedProduct?.active_tax == 0 ? '1' : selectedProduct?.active_tax
        );
      }
      if (selectedProduct?.including_tax) {
        setValue(
          'including_tax',
          selectedProduct?.including_tax ? selectedProduct?.including_tax : ''
        );
      }
      setValue(
        'digital_type',
        selectedProduct?.digital_type == 0 ? '1' : selectedProduct?.digital_type
      );
      if (selectedProduct?.digital_value) {
        setValue(
          'digital_value',
          selectedProduct?.digital_value == ''
            ? '1'
            : selectedProduct?.digital_value
        );
      }
      setValue(
        'isWeight',
        selectedProduct?.isWeight == 0 ? '1' : selectedProduct?.isWeight
      );
      setValue(
        'posOfferType',
        selectedProduct?.posOfferType == 0
          ? 'no_discount'
          : String(selectedProduct?.posOfferType)
      );
      if (selectedProduct?.posOfferType == '1') {
        setValue('posOfferDiscountPrice', selectedProduct?.posOffer);
      }
      if (selectedProduct?.posOfferType == '2') {
        setValue('posOfferDiscountPercentage', selectedProduct?.posOffer);
      }
      if (selectedProduct?.wholesalePrice) {
        setValue('wholesale_price', parseFloat(selectedProduct.wholesalePrice));
      }
      if (selectedProduct?.alarmQuantity) {
        setValue(
          'product_alert_quantity',
          parseFloat(selectedProduct.alarmQuantity)
        );
      }
      if (selectedProduct?.first_quantity) {
        setValue('first_quantity', parseFloat(selectedProduct.first_quantity));
      }
      if (selectedProduct?.tax) {
        setValue('tax', systemSettings?.invoiceSetting?.tax);
        // setValue('tax', parseFloat(selectedProduct.tax));
      }
      if (selectedProduct?.buy_tax) {
        setValue('buy_tax', systemSettings?.invoiceSetting?.buy_tax);
        // setValue('tax', parseFloat(selectedProduct.tax));
      }
      if (selectedProduct?.defaultDiscount) {
        setValue('product_discount_rate', selectedProduct.defaultDiscount);
      }
      if (selectedProduct?.desc) {
        setValue('product_description', selectedProduct.desc);
      }
      if (selectedProduct?.unit) {
        setValue('measruing_unit', String(selectedProduct.unit.id));
      }
      if (selectedProduct.materials?.length > 0) {
        const sharedReset = {
          type: selectedProduct.type ? selectedProduct.type : '',
          product_type: selectedProduct.product_type
            ? selectedProduct.product_type
            : '',
          product_name: selectedProduct.name ? selectedProduct.name : '',
          stock_id: selectedProduct.stock_id
            ? String(selectedProduct.stock_id)
            : '',
          product_category: selectedProduct.cat
            ? String(selectedProduct.cat?.main_cat_id)
            : '',
          product_sub_category: selectedProduct.cat
            ? String(selectedProduct.cat?.id)
            : '',
          product_buying_price: selectedProduct.buyPrice
            ? parseFloat(selectedProduct.buyPrice)
            : '',
          salePrice: selectedProduct.salePrice
            ? parseFloat(selectedProduct.salePrice)
            : '',
          salePriceWithTax: selectedProduct.salePriceWithTax
            ? parseFloat(selectedProduct.salePriceWithTax)
            : '',
          buyPriceWithTax: selectedProduct.buyPriceWithTax
            ? parseFloat(selectedProduct.buyPriceWithTax)
            : '',
          unit_price: selectedProduct.unit_price
            ? parseFloat(selectedProduct.unit_price)
            : '',
          taxType: selectedProduct.taxType
            ? parseFloat(selectedProduct.taxType)
            : '',
          accept_discount:
            selectedProduct?.accept_discount == 0
              ? '1'
              : selectedProduct?.accept_discount,
          active_tax:
            selectedProduct?.active_tax == 0
              ? '1'
              : selectedProduct?.active_tax,
          digital_type:
            selectedProduct?.digital_type == 0
              ? '1'
              : selectedProduct?.digital_type,
          isWeight:
            selectedProduct?.isWeight == 0 ? '1' : selectedProduct?.isWeight,
          posOfferType:
            selectedProduct?.posOfferType == 0
              ? 'no_discount'
              : String(selectedProduct?.posOfferType),
          posOfferDiscountPrice:
            selectedProduct?.posOfferType == 1
              ? String(selectedProduct?.posOffer)
              : '',
          posOfferDiscountPercentage:
            selectedProduct?.posOfferType == 2
              ? String(selectedProduct?.posOffer)
              : '',
          wholesale_price: selectedProduct.wholesalePrice
            ? parseFloat(selectedProduct.wholesalePrice)
            : '',
          product_alert_quantity: selectedProduct.alarmQuantity
            ? parseFloat(selectedProduct.alarmQuantity)
            : '',
          first_quantity: selectedProduct.first_quantity
            ? parseFloat(selectedProduct.first_quantity)
            : '',
          tax: selectedProduct.tax ? parseFloat(selectedProduct.tax) : '',
          buy_tax: selectedProduct.buy_tax
            ? parseFloat(selectedProduct.buy_tax)
            : '',
          // product_discount_rate: selectedProduct.defaultDiscount
          //   ? parseFloat(selectedProduct.defaultDiscount)
          //   : '',
          measruing_unit: selectedProduct.unit
            ? String(selectedProduct.unit.id)
            : ''
        };
        const mappedIngredients = selectedProduct.materials.map(
          (ingredient) => ({
            ingredient_id: String(ingredient.id),
            ingredient_quantity: ingredient.quantity
          })
        );

        reset({
          ...sharedReset,
          ingredients:
            mappedIngredients?.length > 0 ? [...mappedIngredients] : []
        });
      }
      if (
        selectedProduct?.subProducts?.length > 0 &&
        watch('product_type') == 3
      ) {
        const sharedReset = {
          type: selectedProduct.type ? selectedProduct.type : '',
          product_type: selectedProduct.product_type
            ? selectedProduct.product_type
            : '',
          product_name: selectedProduct.name ? selectedProduct.name : '',
          stock_id: selectedProduct.stock_id
            ? String(selectedProduct.stock_id)
            : '',
          product_category: selectedProduct.cat
            ? String(selectedProduct.cat?.main_cat_id)
            : '',
          product_sub_category: selectedProduct.cat
            ? String(selectedProduct.cat?.id)
            : '',
          product_buying_price: selectedProduct.buyPrice
            ? parseFloat(selectedProduct.buyPrice)
            : '',
          salePrice: selectedProduct.salePrice
            ? parseFloat(selectedProduct.salePrice)
            : '',
          salePriceWithTax: selectedProduct.salePriceWithTax
            ? parseFloat(selectedProduct.salePriceWithTax)
            : '',
          buyPriceWithTax: selectedProduct.buyPriceWithTax
            ? parseFloat(selectedProduct.buyPriceWithTax)
            : '',
          unit_price: selectedProduct.unit_price
            ? parseFloat(selectedProduct.unit_price)
            : '',
          taxType: selectedProduct.taxType
            ? parseFloat(selectedProduct.taxType)
            : '',
          accept_discount:
            selectedProduct?.accept_discount == 0
              ? '1'
              : selectedProduct?.accept_discount,
          is_ingredient:
            selectedProduct?.is_ingredient == 2
              ? '2'
              : selectedProduct?.is_ingredient,
          active_tax:
            selectedProduct?.active_tax == 0
              ? '1'
              : selectedProduct?.active_tax,
          digital_type:
            selectedProduct?.digital_type == 0
              ? '1'
              : selectedProduct?.digital_type,
          isWeight:
            selectedProduct?.isWeight == 0 ? '1' : selectedProduct?.isWeight,
          posOfferType:
            selectedProduct?.posOfferType == 0
              ? 'no_discount'
              : String(selectedProduct?.posOfferType),
          posOfferDiscountPrice:
            selectedProduct?.posOfferType == 1
              ? String(selectedProduct?.posOffer)
              : '',
          posOfferDiscountPercentage:
            selectedProduct?.posOfferType == 2
              ? String(selectedProduct?.posOffer)
              : '',
          wholesale_price: selectedProduct.wholesalePrice
            ? parseFloat(selectedProduct.wholesalePrice)
            : '',
          // profit_margin: selectedProduct.profit_margin
          //   ? parseFloat(selectedProduct.profit_margin)
          //   : '',
          // profit_margin_ratio: selectedProduct.profit_margin_ratio
          //   ? parseFloat(selectedProduct.profit_margin_ratio)
          //   : '',
          product_alert_quantity: selectedProduct.alarmQuantity
            ? parseFloat(selectedProduct.alarmQuantity)
            : '',
          first_quantity: selectedProduct.first_quantity
            ? parseFloat(selectedProduct.first_quantity)
            : '',
          tax: selectedProduct.tax ? parseFloat(selectedProduct.tax) : '',
          buy_tax: selectedProduct.buy_tax
            ? parseFloat(selectedProduct.buy_tax)
            : '',
          // product_discount_rate: selectedProduct.defaultDiscount
          //   ? parseFloat(selectedProduct.defaultDiscount)
          //   : '',
          measruing_unit: selectedProduct.unit
            ? String(selectedProduct.unit.id)
            : ''
        };
        const mappedSubProducts = selectedProduct?.subProducts?.map((sub) => ({
          name: sub?.name,
          saleSubPrice: sub?.saleSubPrice,
          first_quantity: sub?.first_quantity,
          stock_id: sub?.stock_id,
          weight: sub?.weight
        }));
        reset({
          ...sharedReset,
          subProducts:
            mappedSubProducts?.length > 0 ? [...mappedSubProducts] : []
        });
      }
      if (
        selectedProduct?.multiProducts?.length > 0 &&
        watch('product_type') == 6
      ) {
        const sharedReset = {
          type: selectedProduct.type ? selectedProduct.type : '',
          product_type: selectedProduct.product_type
            ? selectedProduct.product_type
            : '',
          product_name: selectedProduct.name ? selectedProduct.name : '',
          stock_id: selectedProduct.stock_id
            ? String(selectedProduct.stock_id)
            : '',
          product_category: selectedProduct.cat
            ? String(selectedProduct.cat?.main_cat_id)
            : '',
          product_sub_category: selectedProduct.cat
            ? String(selectedProduct.cat?.id)
            : '',
          product_buying_price: selectedProduct.buyPrice
            ? parseFloat(selectedProduct.buyPrice)
            : '',
          salePrice: selectedProduct.salePrice
            ? parseFloat(selectedProduct.salePrice)
            : '',
          salePriceWithTax: selectedProduct.salePriceWithTax
            ? parseFloat(selectedProduct.salePriceWithTax)
            : '',
          unit_price: selectedProduct.unit_price
            ? parseFloat(selectedProduct.unit_price)
            : '',
          taxType: selectedProduct.taxType
            ? parseFloat(selectedProduct.taxType)
            : '',
          accept_discount:
            selectedProduct?.accept_discount == 0
              ? '1'
              : selectedProduct?.accept_discount,
          active_tax:
            selectedProduct?.active_tax == 0
              ? '1'
              : selectedProduct?.active_tax,
          digital_type:
            selectedProduct?.digital_type == 0
              ? '1'
              : selectedProduct?.digital_type,
          digital_value:
            selectedProduct?.digital_value == ''
              ? ''
              : selectedProduct?.digital_value,
          isWeight:
            selectedProduct?.isWeight == 0 ? '1' : selectedProduct?.isWeight,
          posOfferType:
            selectedProduct?.posOfferType == 0
              ? 'no_discount'
              : String(selectedProduct?.posOfferType),
          posOfferDiscountPrice:
            selectedProduct?.posOfferType == 1
              ? String(selectedProduct?.posOffer)
              : '',
          posOfferDiscountPercentage:
            selectedProduct?.posOfferType == 2
              ? String(selectedProduct?.posOffer)
              : '',
          wholesale_price: selectedProduct.wholesalePrice
            ? parseFloat(selectedProduct.wholesalePrice)
            : '',
          product_alert_quantity: selectedProduct.alarmQuantity
            ? parseFloat(selectedProduct.alarmQuantity)
            : '',
          first_quantity: selectedProduct.first_quantity
            ? parseFloat(selectedProduct.first_quantity)
            : '',
          tax: selectedProduct.tax ? parseFloat(selectedProduct.tax) : '',
          buy_tax: selectedProduct.buy_tax
            ? parseFloat(selectedProduct.buy_tax)
            : '',
          measruing_unit: selectedProduct.unit
            ? String(selectedProduct.unit.id)
            : ''
        };
        const mappedMultiProducts = selectedProduct?.multiProducts?.map(
          (sub) => ({
            name: sub?.name,
            saleMultiPrice: sub?.saleMultiPrice,
            tax: sub?.tax,
            first_quantity: sub?.first_quantity,
            wholesalePrice: sub?.wholesalePrice,
            stock_id: sub?.stock_id,
            defaultParCode: sub?.defaultParCode,
            image: sub?.image
          })
        );
        reset({
          ...sharedReset,
          multiProducts:
            mappedMultiProducts?.length > 0 ? [...mappedMultiProducts] : []
        });
      }
      if (
        selectedProduct?.complex_products?.length > 0 &&
        watch('product_type') == 5
      ) {
        const sharedReset = {
          type: selectedProduct.type ? selectedProduct.type : '',
          product_type: selectedProduct.product_type
            ? selectedProduct.product_type
            : '',
          defaultParCode: selectedProduct.defaultParCode
            ? selectedProduct.defaultParCode
            : '',
          product_name: selectedProduct.name ? selectedProduct.name : '',
          stock_id: selectedProduct.stock_id
            ? String(selectedProduct.stock_id)
            : '',
          product_category: selectedProduct.main_cat
            ? String(selectedProduct.main_cat?.id)
            : '',
          product_sub_category: selectedProduct.cat
            ? String(selectedProduct.cat?.id)
            : '',
          product_buying_price: selectedProduct.buyPrice
            ? parseFloat(selectedProduct.buyPrice)
            : '',
          salePrice: selectedProduct.salePrice
            ? parseFloat(selectedProduct.salePrice)
            : '',
          salePriceWithTax: selectedProduct.salePriceWithTax
            ? parseFloat(selectedProduct.salePriceWithTax)
            : '',
          unit_price: selectedProduct.unit_price
            ? parseFloat(selectedProduct.unit_price)
            : '',
          taxType: selectedProduct.taxType
            ? parseFloat(selectedProduct.taxType)
            : '',
          accept_discount:
            selectedProduct?.accept_discount == 0
              ? '1'
              : selectedProduct?.accept_discount,
          active_tax:
            selectedProduct?.active_tax == 0
              ? '1'
              : selectedProduct?.active_tax,
          digital_type:
            selectedProduct?.digital_type == 0
              ? '1'
              : selectedProduct?.digital_type,
          digital_value:
            selectedProduct?.digital_value == 0
              ? '1'
              : selectedProduct?.digital_value,
          isWeight:
            selectedProduct?.isWeight == 0 ? '1' : selectedProduct?.isWeight,
          posOfferType:
            selectedProduct?.posOfferType == 0
              ? 'no_discount'
              : String(selectedProduct?.posOfferType),
          posOfferDiscountPrice:
            selectedProduct?.posOfferType == 1
              ? String(selectedProduct?.posOffer)
              : '',
          posOfferDiscountPercentage:
            selectedProduct?.posOfferType == 2
              ? String(selectedProduct?.posOffer)
              : '',
          wholesale_price: selectedProduct.wholesalePrice
            ? parseFloat(selectedProduct.wholesalePrice)
            : '',
          // profit_margin: selectedProduct.profit_margin
          //   ? parseFloat(selectedProduct.profit_margin)
          //   : '',
          // profit_margin_ratio: selectedProduct.profit_margin_ratio
          //   ? parseFloat(selectedProduct.profit_margin_ratio)
          //   : '',
          product_alert_quantity: selectedProduct.alarmQuantity
            ? parseFloat(selectedProduct.alarmQuantity)
            : '',
          first_quantity: selectedProduct.first_quantity
            ? parseFloat(selectedProduct.first_quantity)
            : '',
          tax: selectedProduct.tax ? parseFloat(selectedProduct.tax) : '',
          buy_tax: selectedProduct.buy_tax
            ? parseFloat(selectedProduct.buy_tax)
            : '',
          // product_discount_rate: selectedProduct.defaultDiscount
          //   ? parseFloat(selectedProduct.defaultDiscount)
          //   : '',
          measruing_unit: selectedProduct.unit
            ? String(selectedProduct.unit.id)
            : ''
        };
        const mappedComplexProducts = selectedProduct?.complex_products?.map(
          (sub) => ({
            unique_id: String(sub?.unique_id),
            salePrice: sub?.salePrice,
            quant: sub?.quantity,
            quantity: sub?.quantity
          })
        );
        reset({
          ...sharedReset,
          complex_products:
            mappedComplexProducts?.length > 0 ? [...mappedComplexProducts] : []
        });
      }
      if (
        selectedProduct?.ingredients?.length > 0 &&
        watch('product_type') == 7
      ) {
        const sharedReset = {
          type: selectedProduct.type ? selectedProduct.type : '',
          product_type: selectedProduct.product_type
            ? selectedProduct.product_type
            : '',
          defaultParCode: selectedProduct.defaultParCode
            ? selectedProduct.defaultParCode
            : '',
          product_name: selectedProduct.name ? selectedProduct.name : '',
          stock_id: selectedProduct.stock_id
            ? String(selectedProduct.stock_id)
            : '',
          product_category: selectedProduct.main_cat
            ? String(selectedProduct.main_cat?.id)
            : '',
          product_sub_category: selectedProduct.cat
            ? String(selectedProduct.cat?.id)
            : '',
          product_buying_price: selectedProduct.buyPrice
            ? parseFloat(selectedProduct.buyPrice)
            : '',
          salePrice: selectedProduct.salePrice
            ? parseFloat(selectedProduct.salePrice)
            : '',
          salePriceWithTax: selectedProduct.salePriceWithTax
            ? parseFloat(selectedProduct.salePriceWithTax)
            : '',
          unit_price: selectedProduct.unit_price
            ? parseFloat(selectedProduct.unit_price)
            : '',
          taxType: selectedProduct.taxType
            ? parseFloat(selectedProduct.taxType)
            : '',
          accept_discount:
            selectedProduct?.accept_discount == 0
              ? '1'
              : selectedProduct?.accept_discount,
          active_tax:
            selectedProduct?.active_tax == 0
              ? '1'
              : selectedProduct?.active_tax,
          digital_type:
            selectedProduct?.digital_type == 0
              ? '1'
              : selectedProduct?.digital_type,
          digital_value:
            selectedProduct?.digital_value == 0
              ? '1'
              : selectedProduct?.digital_value,
          isWeight:
            selectedProduct?.isWeight == 0 ? '1' : selectedProduct?.isWeight,
          posOfferType:
            selectedProduct?.posOfferType == 0
              ? 'no_discount'
              : String(selectedProduct?.posOfferType),
          posOfferDiscountPrice:
            selectedProduct?.posOfferType == 1
              ? String(selectedProduct?.posOffer)
              : '',
          posOfferDiscountPercentage:
            selectedProduct?.posOfferType == 2
              ? String(selectedProduct?.posOffer)
              : '',
          wholesale_price: selectedProduct.wholesalePrice
            ? parseFloat(selectedProduct.wholesalePrice)
            : '',
          // profit_margin: selectedProduct.profit_margin
          //   ? parseFloat(selectedProduct.profit_margin)
          //   : '',
          // profit_margin_ratio: selectedProduct.profit_margin_ratio
          //   ? parseFloat(selectedProduct.profit_margin_ratio)
          //   : '',
          product_alert_quantity: selectedProduct.alarmQuantity
            ? parseFloat(selectedProduct.alarmQuantity)
            : '',
          first_quantity: selectedProduct.first_quantity
            ? parseFloat(selectedProduct.first_quantity)
            : '',
          tax: selectedProduct.tax ? parseFloat(selectedProduct.tax) : '',
          buy_tax: selectedProduct.buy_tax
            ? parseFloat(selectedProduct.buy_tax)
            : '',
          // product_discount_rate: selectedProduct.defaultDiscount
          //   ? parseFloat(selectedProduct.defaultDiscount)
          //   : '',
          measruing_unit: selectedProduct.unit
            ? String(selectedProduct.unit.id)
            : ''
        };
        const mappedIngredients = selectedProduct?.ingredients?.map((sub) => ({
          unique_id: String(sub?.unique_id),
          quantity: sub?.quantity,
          price: sub?.buyPrice,
          first_quantity: sub?.first_quantity
        }));

        reset({
          ...sharedReset,
          ingredients:
            mappedIngredients?.length > 0 ? [...mappedIngredients] : []
        });
      }
    }
  }, [selectedProduct, selectedProduct?.id, reset]);

  const customApiRequest = useCustomApiRequest();
  // const [allProducts, setAllProducts] = useState();
  // useEffect(() => {
  //   customApiRequest(
  //     getAllProducts(user?.token, watch('product_type')),
  //     (res) => {
  //       if (res?.status === 200 && res?.data?.data?.data?.length >= 0) {
  //         const data = res?.data?.data?.data;
  //         setAllProducts(data);
  //       }
  //     },
  //     (error) => {}
  //   );
  // }, [watch('product_type')]);

  const onSubmit = async (data) => {
    const mappedData = {};
    const mappedDataSub = {};
    mappedData.type = 1;
    if (data.end_date)
      mappedData.end_date = moment(data.end_date).format('YYYY-MM-DD');
    if (data.add_product) mappedData.add_product = data.add_product;
    if (data.product_type) mappedData.product_type = data.product_type;
    if (data.product_name) mappedData.name = data.product_name;
    mappedData.defaultParCode = data.defaultParCode ? data.defaultParCode : '';
    mappedData.main_cat_id = data.product_category;
    // mappedData.cat_id = data.product_sub_category;
    if (data.accept_discount) mappedData.accept_discount = data.accept_discount;
    if (data.active_tax) mappedData.active_tax = data.active_tax;
    mappedData.including_tax = data.including_tax;
    if (data.is_ingredient) mappedData.is_ingredient = data.is_ingredient;
    mappedData.digital_type = data.digital_type;
    mappedData.digital_value = data.digital_value;
    mappedData.isWeight = data.isWeight;
    if (data.stock_id) mappedData.stock_id = data.stock_id;
    if (data.product_buying_price)
      mappedData.buyPrice = data.product_buying_price || 0;
    if (data.unit_price) mappedData.unit_price = data.unit_price;
    // mappedData.tax = systemSettings?.invoiceSetting?.tax;
    mappedData.tax = mappedData.tax = data?.tax;
    if (data?.salePriceWithTax)
      mappedData.salePriceWithTax = data?.salePriceWithTax;
    if (data?.active_tax == '2') mappedData.salePriceWithTax = data?.salePrice;
    if (data?.active_tax == '2')
      mappedData.buyPriceWithTax = data?.product_buying_price;
    // "rrrrrrrr"

    if (data?.buyPriceWithTax)
      mappedData.buyPriceWithTax = data?.buyPriceWithTax;
    if (data?.taxType == productTaxTypes?.withTax) {
      // product sale price with tax
      mappedData.taxType = productTaxTypes?.withTax;
      // if (data?.salePriceWithTax) {
      //   mappedData.salePriceWithTax = data?.salePriceWithTax;
      //   // const p =
      //   //   parseFloat(data?.salePriceWithTax) -
      //   //   (parseFloat(data?.salePriceWithTax) * frontEndTax) / 100;
      //   const p =
      //     (parseFloat(data?.salePriceWithTax) * 100) / (100 + systemSettings?.invoiceSetting?.tax);
      //   mappedData.salePrice = p;
      // }
    }
    if (data?.taxType == productTaxTypes?.withoutTax) {
      mappedData.taxType = productTaxTypes?.withoutTax;
      if (data.salePrice) mappedData.salePrice = data.salePrice;
    }
    if (data.salePrice) mappedData.salePrice = data.salePrice;
    if (data.product_alert_quantity)
      mappedData.alarmQuantity = data.product_alert_quantity;
    if (data.first_quantity) mappedData.first_quantity = data.first_quantity;
    if (
      selectedProduct?.real_quantity &&
      selectedProduct?.parent_id &&
      watch('product_type') == 3
    )
      mappedData.quantity = data.quantity;
    if (data.product_image?.length > 0)
      mappedData.image = data.product_image[0];
    if (data.multi_image0 && data.multi_image0?.length > 0)
      mappedData.multi_image0 = data.multi_image0[0];
    if (data.multi_image1 && data.multi_image1?.length > 0)
      mappedData.multi_image1 = data.multi_image1[0];
    if (data.multi_image2 && data.multi_image2?.length > 0)
      mappedData.multi_image2 = data.multi_image2[0];
    if (data.multi_image3 && data.multi_image3?.length > 0)
      mappedData.multi_image3 = data.multi_image3[0];
    if (data.multi_image4 && data.multi_image4?.length > 0)
      mappedData.multi_image4 = data.multi_image4[0];
    if (data.multi_image5 && data.multi_image5?.length > 0)
      mappedData.multi_image5 = data.multi_image5[0];
    if (data.multi_image6 && data.multi_image6?.length > 0)
      mappedData.multi_image6 = data.multi_image6[0];
    if (data.multi_image7 && data.multi_image7?.length > 0)
      mappedData.multi_image7 = data.multi_image7[0];
    if (data.multi_image8 && data.multi_image8?.length > 0)
      mappedData.multi_image8 = data.multi_image8[0];
    if (data.multi_image9 && data.multi_image9?.length > 0)
      mappedData.multi_image9 = data.multi_image9[0];
    if (data.product_description) mappedData.desc = data.product_description;
    if (data.wholesale_price) mappedData.wholesalePrice = data.wholesale_price;
    if (data.measruing_unit) mappedData.unit_id = data.measruing_unit;
    if (data.ingredients?.length > 0 && watch('product_type') == 7) {
      const mappedIngredients = data?.ingredients?.map((ing) => {
        if (ing?.unique_id && ing?.quantity)
          return { unique_id: ing?.unique_id, quantity: ing?.quantity };
        return null;
      });
      if (mappedIngredients?.length > 0 && mappedIngredients[0] !== null) {
        mappedData.ingredients = JSON.stringify(mappedIngredients);
      }
    }
    if (data.complex_products?.length > 0 && watch('product_type') == 5) {
      const mappedComplexProducts = data?.complex_products?.map((ing) => {
        if (ing?.unique_id && ing?.quantity)
          return { unique_id: ing?.unique_id, quantity: ing?.quantity };
        return null;
      });
      if (
        mappedComplexProducts?.length > 0 &&
        mappedComplexProducts[0] !== null
      ) {
        mappedData.complex_products = JSON.stringify(mappedComplexProducts);
      }
    }
    if (data.multiProducts?.length > 0 && watch('product_type') == 6) {
      const mappedMultiProducts = data?.multiProducts?.map((ing) => {
        if (
          (ing?.saleMultiPrice && ing?.tax) ||
          ing?.first_quantity ||
          ing?.stock_id
        ) {
          return {
            saleMultiPrice: ing?.saleMultiPrice,
            wholesale_price: ing?.wholesalePrice,
            first_quantity: ing?.first_quantity,
            stock_id: ing?.stock_id,
            tax: ing?.taxef
          };
        }
      });
      if (mappedMultiProducts?.length > 0 && mappedMultiProducts[0] !== null) {
        mappedData.multiProducts = JSON.stringify(mappedMultiProducts);
      }
    }
    if (
      data.variants0 &&
      data.variants0?.length > 0 &&
      watch('product_type') == 6
    ) {
      const mappedVariantsProducts0 = data?.variants0?.map((variant) => {
        if (variant?.id && variant?.value) {
          return { id: variant?.id, value: variant?.value };
        }
      });
      if (
        mappedVariantsProducts0?.length > 0 &&
        mappedVariantsProducts0[0] !== null
      ) {
        mappedData.variants0 = JSON.stringify(mappedVariantsProducts0);
      }
    }
    if (
      data.variants1 &&
      data.variants1?.length > 0 &&
      watch('product_type') == 6
    ) {
      const mappedVariantsProducts1 = data?.variants1?.map((variant) => {
        if (variant?.id && variant?.value) {
          return { id: variant?.id, value: variant?.value };
        }
      });
      if (
        mappedVariantsProducts1?.length > 0 &&
        mappedVariantsProducts1[0] !== null
      ) {
        mappedData.variants1 = JSON.stringify(mappedVariantsProducts1);
      }
    }
    if (
      data.variants2 &&
      data.variants2?.length > 0 &&
      watch('product_type') == 6
    ) {
      const mappedVariantsProducts2 = data?.variants2?.map((variant) => {
        if (variant?.id && variant?.value) {
          return { id: variant?.id, value: variant?.value };
        }
      });
      if (
        mappedVariantsProducts2?.length > 0 &&
        mappedVariantsProducts2[0] !== null
      ) {
        mappedData.variants2 = JSON.stringify(mappedVariantsProducts2);
      }
    }
    if (
      data.variants3 &&
      data.variants3?.length > 0 &&
      watch('product_type') == 6
    ) {
      const mappedVariantsProducts = data?.variants3?.map((variant) => {
        if (variant?.id && variant?.value) {
          return { id: variant?.id, value: variant?.value };
        }
      });
      if (
        mappedVariantsProducts?.length > 0 &&
        mappedVariantsProducts[0] !== null
      ) {
        mappedData.variants3 = JSON.stringify(mappedVariantsProducts);
      }
    }
    if (
      data.variants4 &&
      data.variants4?.length > 0 &&
      watch('product_type') == 6
    ) {
      const mappedVariantsProducts = data?.variants4?.map((variant) => {
        if (variant?.id && variant?.value) {
          return { id: variant?.id, value: variant?.value };
        }
      });
      if (
        mappedVariantsProducts?.length > 0 &&
        mappedVariantsProducts[0] !== null
      ) {
        mappedData.variants4 = JSON.stringify(mappedVariantsProducts);
      }
    }
    if (
      data.variants5 &&
      data.variants5?.length > 0 &&
      watch('product_type') == 6
    ) {
      const mappedVariantsProducts = data?.variants5?.map((variant) => {
        if (variant?.id && variant?.value) {
          return { id: variant?.id, value: variant?.value };
        }
      });
      if (
        mappedVariantsProducts?.length > 0 &&
        mappedVariantsProducts[0] !== null
      ) {
        mappedData.variants5 = JSON.stringify(mappedVariantsProducts);
      }
    }
    if (
      data.variants6 &&
      data.variants6?.length > 0 &&
      watch('product_type') == 6
    ) {
      const mappedVariantsProducts = data?.variants6?.map((variant) => {
        if (variant?.id && variant?.value) {
          return { id: variant?.id, value: variant?.value };
        }
      });
      if (
        mappedVariantsProducts?.length > 0 &&
        mappedVariantsProducts[0] !== null
      ) {
        mappedData.variants6 = JSON.stringify(mappedVariantsProducts);
      }
    }
    if (data.subProducts?.length > 0 && watch('add_product') == 2) {
      const mappedSubProducts = data?.subProducts?.map((ing) => {
        if (ing?.name && ing?.saleSubPrice)
          return {
            name: ing?.name,
            saleSubPrice: ing?.saleSubPrice,
            first_quantity: ing?.first_quantity,
            stock_id: ing?.stock_id,
            weight: ing?.weight
          };
        return null;
      });
      if (mappedSubProducts?.length > 0 && mappedSubProducts[0] !== null) {
        // mappedData.type = 2; // 1 ==> is normal product , 2 ==> product with ingredients
        mappedDataSub.subProducts = JSON.stringify(mappedSubProducts);
      }
      // else {
      //   mappedData.type = 1;
      // }
    }
    if (data.subProducts?.length > 0 && watch('product_type') == 3) {
      const mappedSubProducts = data?.subProducts?.map((ing) => {
        if (
          ing?.name &&
          ing?.saleSubPrice &&
          ing?.all_units &&
          ing?.defaultParCode
        )
          return {
            name: ing?.name,
            saleSubPrice: ing?.saleSubPrice,
            quantity: ing?.all_units,
            defaultParCode: ing?.defaultParCode
          };
        return null;
      });
      if (mappedSubProducts?.length > 0 && mappedSubProducts[0] !== null) {
        // mappedData.type = 2; // 1 ==> is normal product , 2 ==> product with ingredients
        mappedData.subProducts = JSON.stringify(mappedSubProducts);
      }
    }
    if (data.parent_id) mappedDataSub.parent_id = data.parent_id;
    if (data.multiple) mappedData.multiple = data.multiple;

    if (data?.posOfferType == '1') {
      mappedData.posOffer = data.posOfferDiscountPrice;
      mappedData.posOfferType = 1;
    } else if (data?.posOfferType == '2') {
      mappedData.posOffer = data.posOfferDiscountPercentage;
      mappedData.posOfferType = 2;
    } else if (data?.posOfferType == 'no_discount') {
      mappedData.posOfferType = 0;
    }

    const formData = new FormData();
    const formDataSub = new FormData();
    for (let key in mappedData) {
      formData.append(key, mappedData[key]);
    }
    for (let key in mappedDataSub) {
      formDataSub.append(key, mappedDataSub[key]);
    }
    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedProduct) {
      formData.append('id', selectedProduct.id);
      customApiRequest(
        addNewProductApi(user?.token, formData, true),
        (res) => {
          if (res?.data?.status === 1) {
            setIsLoading(false);
            setIsSubmittingForm(false);
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Inventory.Products.addedSuccessfully'),
              message: t('Inventory.Products.productModifiedSuccessfully')
            });
            reset();
          } else {
            setIsLoading(false);
            errorNotification({
              title: t('Inventory.Products.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Inventory.Products.pleaseTryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          errorNotification({
            title: t('Inventory.Products.errorOccurred'),
            message:
              error?.response?.data?.message ||
              t('Inventory.Products.pleaseTryLater')
          });
        }
      );
    } else if (watch('parent_id')) {
      customApiRequest(
        addNewSubProductApi(user?.token, formDataSub),
        (res) => {
          if (res?.data?.status === 1) {
            setIsLoading(false);
            setModalOpened(false);
            setIsSubmittingForm(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Inventory.Products.addedSuccessfully'),
              message: t('Inventory.Products.productAddedSuccessfully')
            });
            reset();
          } else {
            setIsLoading(false);
            setIsSubmittingForm(false);
            errorNotification({
              title: t('Inventory.Products.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Inventory.Products.pleaseTryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('Inventory.Products.errorOccurred'),
            message:
              error?.response?.data?.message ||
              t('Inventory.Products.pleaseTryLater')
          });
        }
      );
    } else {
      customApiRequest(
        addNewProductApi(user?.token, formData),
        (res) => {
          if (res?.data?.status === 1) {
            setIsLoading(false);
            setIsSubmittingForm(false);
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Inventory.Products.addedSuccessfully'),
              message: t('Inventory.Products.productAddedSuccessfully')
            });
          } else {
            setIsLoading(false);
            setIsSubmittingForm(false);
            errorNotification({
              title: t('Inventory.Products.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Inventory.Products.pleaseTryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('Inventory.Products.errorOccurred'),
            message:
              error?.response?.data?.message ||
              t('Inventory.Products.pleaseTryLater')
          });
        }
      );
    }
  };
  const [form] = Form.useForm();

  const { field } = useController({
    name: 'end_date',
    control
  });

  // console.log("ssssss",watch(`active_tax`))
  // console.log('سعر البيع شامل الضريبة', watch(`salePriceWithTax`));
  // console.log('سعر البيع بدون الضريبة', watch(`salePrice`));
  return (
    <>
      <Form
        className="store-new-product-form custom-shared-form"
        fields={[
          {
            name: 'posOfferType',
            value: watch('posOfferType')
          }
        ]}
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          {!selectedProduct && (
            <AntdRadioGroup
              disabled={selectedProduct}
              name="product_type"
              setValue={setValue}
              className="form-radio-group"
              control={control}
              label={t('Inventory.Products.chooseTypeOfProduct')}
              validateStatus={errors?.product_type ? 'error' : ''}
              errorMsg={errors?.product_type?.message}
              // onChange={() => reset()}
              radios={[
                {
                  title: t('Inventory.Products.easyProduct'),
                  value: '1'
                },
                {
                  title: t('Inventory.Products.serviceProduct'),
                  value: '2'
                },
                {
                  title: t('Inventory.Products.balancedProduct'),
                  value: '3'
                },
                {
                  title: t('Inventory.Products.digitalProduct'),
                  value: '4'
                },
                {
                  title: t('Inventory.Products.assembledProduct'),
                  value: '5'
                },
                {
                  title: t('Inventory.Products.multipleProduct'),
                  value: '6'
                },
                {
                  title: t('Inventory.Products.productComponent'),
                  value: '7'
                }
              ]}
              value="1"
              defaultValue="1"
              isRadioButton
            />
          )}

          {/* المنتج السهل */}
          <>
            {(watch('product_type') == 1 ||
              watch('product_type') == undefined) && (
              <>
                <div className="name-default-barcode-wrap">
                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.productName')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name="product_name"
                        type="text"
                        placeholder={t(
                          'Inventory.Products.productNamePlaceholder'
                        )}
                        errorMsg={errors?.product_name?.message}
                        validateStatus={errors?.product_name ? 'error' : ''}
                        control={control}
                      />
                    </div>
                  </div>
                  <div className="text-field-label-wrap">
                    <p
                      className="label-p"
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span>{t('Inventory.Products.productBarcode')}</span>
                      <b
                        style={{
                          border: 'none',
                          cursor: 'pointer',
                          color: '#4d71db'
                        }}
                        onClick={() => handleClick()}
                      >
                        {t('Inventory.Products.generateNewBarcode')}
                      </b>
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        setValue={setValue}
                        disabled={watch('isWeight') == '1'}
                        className="form-text-field"
                        name="defaultParCode"
                        type="text"
                        placeholder={t(
                          'Inventory.Products.productBarcodePlaceholder'
                        )}
                        errorMsg={errors?.defaultParCode?.message}
                        validateStatus={errors?.defaultParCode ? 'error' : ''}
                        control={control}
                        defaultValue={barCodeNum}
                      />
                    </div>
                  </div>

                  <div className="select-label-wrap">
                    <p
                      className="label-p"
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span>{t('Inventory.Products.mainProductCategory')}</span>
                      <b
                        style={{
                          border: 'none',
                          cursor: 'pointer',
                          color: '#4d71db'
                        }}
                        onClick={() => {
                          setCatModalOpened(true);
                        }}
                      >
                        {t('Inventory.Products.addNewCategory')}
                      </b>
                    </p>
                    <div className="custom-select-wrap without-icon">
                      <AntdSelectOption
                        name="product_category"
                        errorMsg={errors?.product_category?.message}
                        validateStatus={errors?.product_category ? 'error' : ''}
                        control={control}
                        setValue={setValue}
                        placeholder={t(
                          'Inventory.Products.mainProductCategory'
                        )}
                        options={
                          mainCategories?.length > 0
                            ? mainCategories.map((cat) => ({
                                title: cat.name,
                                value: String(cat.id)
                              }))
                            : null
                        }
                        showSearch={true}
                        filterOption={(input, option) =>
                            option.children.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 
                        }
                        formClassName="store-new-product-form"
                      />
                    </div>
                  </div>
                  <div className="unit-isWeight-wrapper">
                    <div className="discount-units-wrap">
                      <div className="select-label-wrap">
                        <p
                          className="label-p"
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span>{t('Inventory.Products.measruingUnit')}</span>
                          <b
                            style={{
                              border: 'none',
                              cursor: 'pointer',
                              color: '#4d71db'
                            }}
                            onClick={() => {
                              setUnitModalOpened(true);
                            }}
                          >
                            {t('Inventory.Products.addNewMeasruingUnit')}
                          </b>
                        </p>
                        <div className="custom-select-wrap without-icon">
                          <AntdSelectOption
                            name="measruing_unit"
                            errorMsg={errors?.measruing_unit?.message}
                            validateStatus={
                              errors?.measruing_unit ? 'error' : ''
                            }
                            control={control}
                            setValue={setValue}
                            placeholder={t('Inventory.Products.measruingUnit')}
                            options={
                              allUnits?.length > 0
                                ? allUnits.map((unit) => ({
                                    title: unit.name,
                                    value: String(unit.id)
                                  }))
                                : null
                            }
                            showSearch={true}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            formClassName="store-new-product-form"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="select-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.itsMainWarehouse')}
                    </p>
                    <div className="custom-select-wrap without-icon">
                      <AntdSelectOption
                        name="stock_id"
                        errorMsg={errors?.stock_id?.message}
                        validateStatus={errors?.stock_id ? 'error' : ''}
                        control={control}
                        setValue={setValue}
                        placeholder={t('Inventory.Products.itsMainWarehouse')}
                        options={
                          allWarehouses?.length > 0
                            ? allWarehouses.map((obj) => ({
                                title: obj.name,
                                value: String(obj.id)
                              }))
                            : null
                        }
                        showSearch={true}
                        filterOption={(input, option) =>
                          option.children.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        formClassName="store-new-product-form"
                      />
                    </div>
                  </div>
                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.firstTermStock')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name="first_quantity"
                        type="text"
                        placeholder={t(
                          'Inventory.Products.firstTermStockPlaceholder'
                        )}
                        errorMsg={
                          errors?.first_quantity &&
                          errors.first_quantity.message
                        }
                        validateStatus={
                          errors?.first_quantity && errors?.first_quantity
                            ? 'error'
                            : ''
                        }
                        control={control}
                        // defaultValue={watch(`ingredients.${index}.ingredient_quantity`)} // make sure to set up defaultValue
                      />
                    </div>
                  </div>
                </div>
                <div className="buying-wrap">
                  <div className="radios-wrap">
                    <p className="radios-group-title">
                      {t('Inventory.Products.activateTaxForProduct')}
                    </p>
                    <div className="labels-wrap">
                      {[
                        {
                          title: t('Inventory.Products.yes'),
                          value: '1'
                        },
                        {
                          title: t('Inventory.Products.no'),
                          value: '2'
                        }
                      ].map((obj, i) => (
                        <label
                          key={i}
                          className={`radio-input-label ${
                            String(watch(`active_tax`)) === String(obj.value)
                              ? 'selected'
                              : ''
                          }`}
                        >
                          <input
                            type="radio"
                            value={obj.value}
                            {...register(`active_tax`)}
                          />
                          <span className="label-span">{obj.title}</span>
                          {watch(`active_tax`) == String(obj.value) ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonEmpty />
                          )}
                        </label>
                      ))}
                    </div>

                    {errors?.active_tax && (
                      <p className="error-p">{errors.active_tax.message}</p>
                    )}
                  </div>
                  {watch('active_tax') == 1 && (
                    <div className="radios-wrap">
                      <p className="radios-group-title">
                        {t('Inventory.Products.doesproductIncludeTax')}
                      </p>
                      <div className="labels-wrap">
                        {[
                          {
                            title: t('Inventory.Products.yes'),
                            value: '1'
                          },
                          {
                            title: t('Inventory.Products.no'),
                            value: '0'
                          }
                        ].map((obj, i) => (
                          <label
                            key={i}
                            className={`radio-input-label ${
                              String(watch(`including_tax`)) ===
                              String(obj.value)
                                ? 'selected'
                                : ''
                            }`}
                          >
                            <input
                              type="radio"
                              value={obj.value}
                              {...register(`including_tax`)}
                            />
                            <span className="label-span">{obj.title}</span>
                            {watch(`including_tax`) == String(obj.value) ? (
                              <RadioButtonFilled />
                            ) : (
                              <RadioButtonEmpty />
                            )}
                          </label>
                        ))}
                      </div>

                      {errors?.including_tax && (
                        <p className="error-p">
                          {errors.including_tax.message}
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className="price-tax-wrap"
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: 18
                  }}
                >
                  {watch('active_tax') == 1 && watch('including_tax') == 1 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.purchasePriceIncludesTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          setValue={setValue}
                          className="form-text-field"
                          name="buyPriceWithTax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.purchasePriceIncludesTax'
                          )}
                          errorMsg={errors?.buyPriceWithTax?.message}
                          validateStatus={
                            errors?.buyPriceWithTax ? 'error' : ''
                          }
                          control={control}
                          // defaultValue={
                          //   watch('active_tax') == 1
                          //     ? parseFloat(watch('tax')) > 0
                          //       ? parseFloat(watch('salePrice')) +
                          //         parseFloat(watch('tax'))
                          //       : parseFloat(watch('salePrice'))
                          //     : ''
                          // }
                          // readOnly={
                          //   watch('including_tax') == 1 ? false : true
                          // }
                        />
                      </div>
                    </div>
                  )}
                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.purchasePriceWithoutTax')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name="product_buying_price"
                        type="text"
                        setValue={setValue}
                        placeholder={t('Inventory.Products.purchasePrice')}
                        errorMsg={errors?.product_buying_price?.message}
                        validateStatus={
                          errors?.product_buying_price ? 'error' : ''
                        }
                        control={control}
                        defaultValue={
                          watch('including_tax') == 1 &&
                          watch('buyPriceWithTax')
                            ? (
                                parseFloat(watch('buyPriceWithTax') * 100) /
                                (parseFloat(
                                  systemSettings?.invoiceSetting?.tax
                                ) +
                                  100)
                              ).toFixed(3)
                            : ''
                        }
                        readOnly={
                          watch('including_tax') == 1 &&
                          watch('active_tax') == 1
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>

                  {watch('active_tax') == 1 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.purchaseTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          // disabled={true}
                          setValue={setValue}
                          className="form-text-field"
                          name="buy_tax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.purchaseTaxPlaceholder'
                          )}
                          errorMsg={errors?.buy_tax?.message}
                          validateStatus={errors?.buy_tax ? 'error' : ''}
                          control={control}
                          // defaultValue={parseFloat(
                          //   (systemSettings?.invoiceSetting?.buy_tax *
                          //     watch('product_buying_price')) /
                          //     100
                          // )}
                          defaultValue={
                            systemSettings?.invoiceSetting?.tax > 0 &&
                            watch('product_buying_price')
                              ? parseFloat(
                                  (systemSettings?.invoiceSetting?.tax *
                                    watch('product_buying_price')) /
                                    100
                                ).toFixed(3)
                              : ''
                          }
                          value={systemSettings?.invoiceSetting?.buy_tax}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  )}
                  {watch('active_tax') == 1 && watch('including_tax') == 0 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.purchasePriceIncludesTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          setValue={setValue}
                          className="form-text-field"
                          name="buyPriceWithTax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.purchasePriceIncludesTax'
                          )}
                          errorMsg={errors?.buyPriceWithTax?.message}
                          validateStatus={
                            errors?.buyPriceWithTax ? 'error' : ''
                          }
                          control={control}
                          defaultValue={
                            watch('active_tax') == 1
                              ? parseFloat(watch('buy_tax')) > 0 &&
                                watch('product_buying_price')
                                ? parseFloat(
                                    parseFloat(watch('product_buying_price')) +
                                      parseFloat(watch('buy_tax'))
                                  ).toFixed(3)
                                : parseFloat(watch('product_buying_price'))
                              : ''
                          }
                          readOnly={watch('including_tax') == 1 ? false : true}
                        />
                      </div>
                    </div>
                  )}

                  {watch(`is_ingredient`) == 2 && (
                    <>
                      {watch('active_tax') == 1 &&
                        watch('including_tax') == 1 && (
                          <div className="text-field-label-wrap">
                            <p className="label-p">
                              {t('Inventory.Products.sellingPriceIncludesTax')}
                            </p>
                            <div className="text-field-wrap">
                              <AntdTextField
                                setValue={setValue}
                                className="form-text-field"
                                name="salePriceWithTax"
                                type="text"
                                placeholder={t(
                                  'Inventory.Products.sellingPriceIncludesTaxPlaceholder'
                                )}
                                errorMsg={errors?.salePriceWithTax?.message}
                                validateStatus={
                                  errors?.salePriceWithTax ? 'error' : ''
                                }
                                control={control}
                                // defaultValue={
                                //   watch('active_tax') == 1
                                //     ? parseFloat(watch('tax')) > 0
                                //       ? parseFloat(watch('salePrice')) +
                                //         parseFloat(watch('tax'))
                                //       : parseFloat(watch('salePrice'))
                                //     : ''
                                // }
                                // readOnly={
                                //   watch('including_tax') == 1 ? false : true
                                // }
                              />
                            </div>
                          </div>
                        )}
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Inventory.Products.sellingPriceWithoutTax')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            name="salePrice"
                            type="text"
                            setValue={setValue}
                            placeholder={t(
                              'Inventory.Products.sellingPriceWithoutTaxPlaceholder'
                            )}
                            errorMsg={errors?.salePrice?.message}
                            validateStatus={errors?.salePrice ? 'error' : ''}
                            control={control}
                            // defaultValue={(parseFloat(watch('salePriceWithTax')) * 100) / (parseFloat(systemSettings?.invoiceSetting?.tax) + 100) }
                            defaultValue={
                              watch('including_tax') == 1 &&
                              watch('salePriceWithTax') > 0 &&
                              (
                                (parseFloat(watch('salePriceWithTax')) * 100) /
                                (parseFloat(
                                  systemSettings?.invoiceSetting?.tax
                                ) +
                                  100)
                              ).toFixed(3)
                            }
                            readOnly={
                              watch('including_tax') == 1 &&
                              watch('active_tax') == 1
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                      {watch('active_tax') == 1 && (
                        <div className="text-field-label-wrap">
                          <p className="label-p">
                            {t('Inventory.Products.salesTax')}
                          </p>
                          <div className="text-field-wrap">
                            <AntdTextField
                              // disabled={true}
                              setValue={setValue}
                              className="form-text-field"
                              name="tax"
                              type="text"
                              placeholder={t(
                                'Inventory.Products.salesTaxPlaceholder'
                              )}
                              errorMsg={errors?.tax?.message}
                              validateStatus={errors?.tax ? 'error' : ''}
                              control={control}
                              defaultValue={
                                watch('active_tax') == 1
                                  ? systemSettings?.invoiceSetting?.tax > 0 &&
                                    watch('salePrice')
                                    ? parseFloat(
                                        (systemSettings?.invoiceSetting?.tax *
                                          watch('salePrice')) /
                                          100
                                      ).toFixed(3)
                                    : ''
                                  : ''
                              }
                              value={systemSettings?.invoiceSetting?.tax}
                              readOnly={true}
                            />
                          </div>
                        </div>
                      )}
                      {/* systemSettings?.invoiceSetting?.tax > 0 && watch('salePrice')
                              ? (parseFloat((systemSettings?.invoiceSetting?.tax *
                                watch('salePrice')) /
                                100)).toFixed(3)
                                
                              :'' */}
                      {watch('active_tax') == 1 &&
                        watch('including_tax') == 0 && (
                          <div className="text-field-label-wrap">
                            <p className="label-p">
                              {t('Inventory.Products.sellingPriceIncludesTax')}
                            </p>
                            <div className="text-field-wrap">
                              <AntdTextField
                                setValue={setValue}
                                className="form-text-field"
                                name="salePriceWithTax"
                                type="text"
                                placeholder={t(
                                  'Inventory.Products.sellingPriceIncludesTaxPlaceholder'
                                )}
                                errorMsg={errors?.salePriceWithTax?.message}
                                validateStatus={
                                  errors?.salePriceWithTax ? 'error' : ''
                                }
                                control={control}
                                defaultValue={
                                  watch('active_tax') == 1
                                    ? parseFloat(watch('tax')) > 0 &&
                                      watch('salePrice')
                                      ? parseFloat(
                                          parseFloat(watch('salePrice')) +
                                            parseFloat(watch('tax'))
                                        ).toFixed(3)
                                      : parseFloat(watch('salePrice'))
                                    : ''
                                }
                                readOnly={
                                  watch('including_tax') == 1 ? false : true
                                }
                              />
                            </div>
                          </div>
                        )}

                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Inventory.Products.wholePrice')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            name="wholesale_price"
                            type="text"
                            placeholder={t(
                              'Inventory.Products.wholePricePlaceholder'
                            )}
                            errorMsg={errors?.wholesale_price?.message}
                            validateStatus={
                              errors?.wholesale_price ? 'error' : ''
                            }
                            control={control}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="buying-wrap">
                  <div className="radios-wrap">
                    <p className="radios-group-title">
                      {t('Inventory.Products.doesTheProductAcceptDiscount')}
                    </p>
                    <div className="labels-wrap">
                      {[
                        {
                          title: t('Inventory.Products.yes'),
                          value: '1'
                        },
                        {
                          title: t('Inventory.Products.no'),
                          value: '2'
                        }
                      ].map((obj, i) => (
                        <label
                          key={i}
                          className={`radio-input-label ${
                            String(watch(`accept_discount`)) ===
                            String(obj.value)
                              ? 'selected'
                              : ''
                          }`}
                        >
                          <input
                            type="radio"
                            value={obj.value}
                            {...register(`accept_discount`)}
                          />
                          <span className="label-span">{obj.title}</span>
                          {watch(`accept_discount`) == String(obj.value) ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonEmpty />
                          )}
                        </label>
                      ))}
                    </div>

                    {errors?.accept_discount && (
                      <p className="error-p">
                        {errors.accept_discount.message}
                      </p>
                    )}
                  </div>

                  <div className="radios-wrap">
                    <p className="radios-group-title">
                      {t('Inventory.Products.isTheProductMadeUpOrNot')}
                    </p>
                    <div className="labels-wrap">
                      {[
                        {
                          title: t('Inventory.Products.yes'),
                          value: '1'
                        },
                        {
                          title: t('Inventory.Products.no'),
                          value: '2'
                        }
                      ].map((obj, i) => (
                        <label
                          key={i}
                          className={`radio-input-label ${
                            String(watch(`is_ingredient`)) === String(obj.value)
                              ? 'selected'
                              : ''
                          }`}
                        >
                          <input
                            type="radio"
                            value={obj.value}
                            {...register(`is_ingredient`)}
                          />
                          <span className="label-span">{obj.title}</span>
                          {watch(`is_ingredient`) == String(obj.value) ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonEmpty />
                          )}
                        </label>
                      ))}
                    </div>

                    {errors?.is_ingredient && (
                      <p className="error-p">{errors.is_ingredient.message}</p>
                    )}
                  </div>
                </div>
                <div className="date-picker-wrap">
                  <p className="label-p">
                    {t('Inventory.Products.productExpirationDate')}
                  </p>
                  <DatePicker
                    name="end_date"
                    // format="YYYY-MM-DD"
                    size="large"
                    {...field}
                    control={control}
                    // defaultValue={selectedCash?.date && moment(selectedCash?.date)}
                    // bordered={false}
                    setValue={setValue}
                    placeholder={t(
                      'Inventory.Products.productExpirationDatePlaceholder'
                    )}
                    className="receipt-date-picker"
                    formClassName="create-receipt-form"
                  />
                  {/* {errors?.date && <p className="error-p">{errors.date.message}</p>} */}
                </div>
                {/* <CustomEditor
                  fieldName="product_description"
                  editorTitle={t('Inventory.Products.productDetails')}
                  setValue={setValue}
                  watch={watch}
                  setError={setError}
                  clearErrors={clearErrors}
                  errors={errors}
                  required={false}
                  errorMsg={t('Inventory.Products.productDetails')}
                  editorFieldState={productDescEditorState}
                  setEditorFieldState={setProductDescEditorState}
                  fetchedData={selectedProduct?.desc}
                /> */}
                <div className="text-field-label-wrap">
                  <p className="label-p">
                    {t('Inventory.Products.productDetails')}
                  </p>
                  <div className="text-field-wrap">
                    <AntdTextarea
                      className="form-text-area"
                      name="product_description"
                      rows={8}
                      placeholder={t('Inventory.Products.productDetails')}
                      errorMsg={errors?.product_description?.message}
                      validateStatus={
                        errors?.product_description ? 'error' : ''
                      }
                      control={control}
                    />
                  </div>
                </div>

                <div className="product-img-wrap">
                  <h3>{t('Inventory.Products.productImage')}</h3>

                  <MyDropZone
                    className="product-header-dropzone"
                    multipleFiles={false}
                    handleFilesDrop={(acceptedFiles) =>
                      handleFilesDrop(
                        acceptedFiles,
                        productFileToUpload,
                        setProductFileToUpload
                      )
                    }
                    filesToUpload={productFileToUpload}
                    formName="store-new-product-form"
                    id="avatar-input"
                    dropzoneText={t('Inventory.Products.productImage')}
                    inputName="product_image"
                    icon={<CameraFilled className="dropzone-camera" />}
                    dropZoneUrls={
                      selectedProduct?.image ? [selectedProduct.image] : null
                    }
                  >
                    {t('Inventory.Products.dragTheImageAndPlaceItHere')}
                  </MyDropZone>

                  <MyDropZonePreview
                    filesToUpload={productFileToUpload}
                    clearFileFromUpload={(f) =>
                      clearFileFromUpload(f, setProductFileToUpload)
                    }
                  />
                  {errors?.product_image?.message &&
                    !watch('product_image') && (
                      <p className="error-p">{errors.product_image.message}</p>
                    )}
                </div>
              </>
            )}
          </>

          {/* المنتج خدمه */}
          <>
            {watch('product_type') == 2 && (
              <>
                <div className="name-default-barcode-wrap">
                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.productName')}
                    </p>
                    <div className="text-field-wrap ">
                      <AntdTextField
                        className="form-text-field"
                        name="product_name"
                        type="text"
                        placeholder={t(
                          'Inventory.Products.productNamePlaceholder'
                        )}
                        errorMsg={errors?.product_name?.message}
                        validateStatus={errors?.product_name ? 'error' : ''}
                        control={control}
                      />
                    </div>
                  </div>
                  <div className="text-field-label-wrap">
                    <p
                      className="label-p"
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span>{t('Inventory.Products.productBarcode')}</span>
                      <b
                        style={{
                          border: 'none',
                          cursor: 'pointer',
                          color: '#4d71db'
                        }}
                        onClick={() => handleClick()}
                      >
                        {t('Inventory.Products.generateNewBarcode')}
                      </b>
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        setValue={setValue}
                        disabled={watch('isWeight') == '1'}
                        className="form-text-field"
                        name="defaultParCode"
                        type="text"
                        placeholder={t(
                          'Inventory.Products.productBarcodePlaceholder'
                        )}
                        errorMsg={errors?.defaultParCode?.message}
                        validateStatus={errors?.defaultParCode ? 'error' : ''}
                        control={control}
                        defaultValue={barCodeNum}
                      />
                    </div>
                  </div>
                  <div className="select-label-wrap">
                    <p
                      className="label-p"
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span>{t('Inventory.Products.mainProductCategory')}</span>
                      <b
                        style={{
                          border: 'none',
                          cursor: 'pointer',
                          color: '#4d71db'
                        }}
                        onClick={() => {
                          setCatModalOpened(true);
                        }}
                      >
                        {t('Inventory.Products.addNewCategory')}
                      </b>
                    </p>
                    <div className="custom-select-wrap without-icon">
                      <AntdSelectOption
                        name="product_category"
                        errorMsg={errors?.product_category?.message}
                        validateStatus={errors?.product_category ? 'error' : ''}
                        control={control}
                        setValue={setValue}
                        placeholder={t(
                          'Inventory.Products.mainProductCategory'
                        )}
                        options={
                          mainCategories?.length > 0
                            ? mainCategories.map((cat) => ({
                                title: cat.name,
                                value: String(cat.id)
                              }))
                            : null
                        }
                        showSearch={true}
                        filterOption={(input, option) =>
                          option.children.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        formClassName="store-new-product-form"
                      />
                    </div>
                  </div>
                  <div className="unit-isWeight-wrapper">
                    <div className="discount-units-wrap">
                      <div className="select-label-wrap">
                        <p
                          className="label-p"
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span>{t('Inventory.Products.measruingUnit')}</span>
                          <b
                            style={{
                              border: 'none',
                              cursor: 'pointer',
                              color: '#4d71db'
                            }}
                            onClick={() => {
                              setUnitModalOpened(true);
                            }}
                          >
                            {t('Inventory.Products.addNewMeasruingUnit')}
                          </b>
                        </p>

                        <div className="custom-select-wrap without-icon">
                          <AntdSelectOption
                            name="measruing_unit"
                            errorMsg={errors?.measruing_unit?.message}
                            validateStatus={
                              errors?.measruing_unit ? 'error' : ''
                            }
                            control={control}
                            setValue={setValue}
                            placeholder={t('Inventory.Products.measruingUnit')}
                            options={
                              allUnits?.length > 0
                                ? allUnits.map((unit) => ({
                                    title: unit.name,
                                    value: String(unit.id)
                                  }))
                                : null
                            }
                            showSearch={true}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            formClassName="store-new-product-form"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="select-label-wrap">
                    <p className="label-p">المستودع الرئيسى التابع له</p>
                    <div className="custom-select-wrap without-icon">
                      <AntdSelectOption
                        name='stock_id'
                        errorMsg={errors?.stock_id?.message}
                        validateStatus={errors?.stock_id ? 'error' : ''}
                        control={control}
                        setValue={setValue}
                        placeholder="المستودع الرئيسى التابع له"
                        options={
                          allWarehouses?.length > 0
                            ? allWarehouses.map((obj) => ({
                                title: obj.name,
                                value: String(obj.id)
                              }))
                            : null
                        }
                        formClassName="store-new-product-form"
                      />
                    </div>
                  </div>
                  <div className="text-field-label-wrap">
                    <p className="label-p">مخزون أول مده</p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name='first_quantity'
                        type="text"
                        placeholder="مخزون أول مده..."
                        errorMsg={
                          errors?.ingredients &&
                          errors.first_quantity.message
                        }
                        validateStatus={
                          errors?.first_quantity &&
                          errors?.first_quantity
                            ? 'error'
                            : ''
                        }
                        control={control}
                        // defaultValue={watch(`ingredients.${index}.ingredient_quantity`)} // make sure to set up defaultValue
                      />
                    </div>
                  </div> */}
                </div>
                <div className="buying-wrap">
                  <div className="radios-wrap">
                    <p className="radios-group-title">
                      {t('Inventory.Products.activateTaxForProduct')}
                    </p>
                    <div className="labels-wrap">
                      {[
                        {
                          title: t('Inventory.Products.yes'),
                          value: '1'
                        },
                        {
                          title: t('Inventory.Products.no'),
                          value: '2'
                        }
                      ].map((obj, i) => (
                        <label
                          key={i}
                          className={`radio-input-label ${
                            String(watch(`active_tax`)) === String(obj.value)
                              ? 'selected'
                              : ''
                          }`}
                        >
                          <input
                            type="radio"
                            value={obj.value}
                            {...register(`active_tax`)}
                          />
                          <span className="label-span">{obj.title}</span>
                          {watch(`active_tax`) == String(obj.value) ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonEmpty />
                          )}
                        </label>
                      ))}
                    </div>

                    {errors?.active_tax && (
                      <p className="error-p">{errors.active_tax.message}</p>
                    )}
                  </div>

                  {watch('active_tax') == 1 && (
                    <div className="radios-wrap">
                      <p className="radios-group-title">
                        {t('Inventory.Products.doesproductIncludeTax')}
                      </p>
                      <div className="labels-wrap">
                        {[
                          {
                            title: t('Inventory.Products.yes'),
                            value: '1'
                          },
                          {
                            title: t('Inventory.Products.no'),
                            value: '0'
                          }
                        ].map((obj, i) => (
                          <label
                            key={i}
                            className={`radio-input-label ${
                              String(watch(`including_tax`)) ===
                              String(obj.value)
                                ? 'selected'
                                : ''
                            }`}
                          >
                            <input
                              type="radio"
                              value={obj.value}
                              {...register(`including_tax`)}
                            />
                            <span className="label-span">{obj.title}</span>
                            {watch(`including_tax`) == String(obj.value) ? (
                              <RadioButtonFilled />
                            ) : (
                              <RadioButtonEmpty />
                            )}
                          </label>
                        ))}
                      </div>

                      {errors?.including_tax && (
                        <p className="error-p">
                          {errors.including_tax.message}
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className="price-tax-wrap"
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: 18
                  }}
                >
                  {/* <div className="text-field-label-wrap">
                    <p className="label-p">{t('Inventory.Products.purchasePriceWithoutTax')}</p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name="product_buying_price"
                        type="text"
                        placeholder={t('Inventory.Products.purchasePrice')}
                        errorMsg={errors?.product_buying_price?.message}
                        validateStatus={errors?.product_buying_price ? 'error' : ''}
                        control={control}
                      />
                    </div>
                </div>
                <div className="text-field-label-wrap">
                  <p className="label-p">ضريبة الشراء</p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      // disabled={true}
                      setValue={setValue}
                      className="form-text-field"
                      name="buy_tax"
                      type="text"
                      placeholder="ضريبة الشراء..."
                      errorMsg={errors?.buy_tax?.message}
                      validateStatus={errors?.buy_tax ? 'error' : ''}
                      control={control}
                      defaultValue={parseFloat((systemSettings?.invoiceSetting?.buy_tax * watch('product_buying_price')) / 100)}
                      value={systemSettings?.invoiceSetting?.buy_tax}
                      readOnly={true}
                    />
                  </div>
                </div> */}

                  {watch('active_tax') == 1 && watch('including_tax') == 1 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.sellingPriceIncludesTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          setValue={setValue}
                          className="form-text-field"
                          name="salePriceWithTax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.sellingPriceIncludesTaxPlaceholder'
                          )}
                          errorMsg={errors?.salePriceWithTax?.message}
                          validateStatus={
                            errors?.salePriceWithTax ? 'error' : ''
                          }
                          control={control}
                          // defaultValue={
                          //   watch('active_tax') == 1
                          //     ? parseFloat(watch('tax')) > 0
                          //       ? parseFloat(watch('salePrice')) +
                          //         parseFloat(watch('tax'))
                          //       : parseFloat(watch('salePrice'))
                          //     : ''
                          // }
                          // readOnly={
                          //   watch('including_tax') == 1 ? false : true
                          // }
                        />
                      </div>
                    </div>
                  )}
                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.sellingPriceWithoutTax')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name="salePrice"
                        setValue={setValue}
                        type="text"
                        placeholder={t(
                          'Inventory.Products.sellingPriceWithoutTaxPlaceholder'
                        )}
                        errorMsg={errors?.salePrice?.message}
                        validateStatus={errors?.salePrice ? 'error' : ''}
                        control={control}
                        defaultValue={
                          watch('including_tax') == 1 &&
                          watch('salePriceWithTax') > 0 &&
                          (
                            (parseFloat(watch('salePriceWithTax')) * 100) /
                            (parseFloat(systemSettings?.invoiceSetting?.tax) +
                              100)
                          ).toFixed(3)
                        }
                        readOnly={
                          watch('including_tax') == 1 &&
                          watch('active_tax') == 1
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                  {watch('active_tax') == 1 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.salesTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          // disabled={true}
                          setValue={setValue}
                          className="form-text-field"
                          name="tax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.salesTaxPlaceholder'
                          )}
                          errorMsg={errors?.tax?.message}
                          validateStatus={errors?.tax ? 'error' : ''}
                          control={control}
                          defaultValue={
                            systemSettings?.invoiceSetting?.tax > 0 &&
                            watch('salePrice')
                              ? parseFloat(
                                  (systemSettings?.invoiceSetting?.tax *
                                    watch('salePrice')) /
                                    100
                                ).toFixed(3)
                              : ''
                          }
                          value={systemSettings?.invoiceSetting?.tax}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  )}
                  {watch('active_tax') == 1 && watch('including_tax') == 0 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.sellingPriceIncludesTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          setValue={setValue}
                          className="form-text-field"
                          name="salePriceWithTax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.sellingPriceIncludesTaxPlaceholder'
                          )}
                          errorMsg={errors?.salePriceWithTax?.message}
                          validateStatus={
                            errors?.salePriceWithTax ? 'error' : ''
                          }
                          control={control}
                          defaultValue={
                            parseFloat(watch('tax')) > 0 && watch('salePrice')
                              ? parseFloat(
                                  parseFloat(watch('salePrice')) +
                                    parseFloat(watch('tax'))
                                ).toFixed(3)
                              : parseFloat(watch('salePrice'))
                          }
                          readOnly={watch('including_tax') == 1 ? false : true}
                        />
                      </div>
                    </div>
                  )}
                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.wholePrice')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name="wholesale_price"
                        type="text"
                        placeholder={t(
                          'Inventory.Products.wholePricePlaceholder'
                        )}
                        errorMsg={errors?.wholesale_price?.message}
                        validateStatus={errors?.wholesale_price ? 'error' : ''}
                        control={control}
                      />
                    </div>
                  </div>
                </div>
                <div className="buying-wrap">
                  <div className="radios-wrap">
                    <p className="radios-group-title">
                      {t('Inventory.Products.doesTheProductAcceptDiscount')}
                    </p>
                    <div className="labels-wrap">
                      {[
                        {
                          title: t('Inventory.Products.yes'),
                          value: '1'
                        },
                        {
                          title: t('Inventory.Products.no'),
                          value: '2'
                        }
                      ].map((obj, i) => (
                        <label
                          key={i}
                          className={`radio-input-label ${
                            String(watch(`accept_discount`)) ===
                            String(obj.value)
                              ? 'selected'
                              : ''
                          }`}
                        >
                          <input
                            type="radio"
                            value={obj.value}
                            {...register(`accept_discount`)}
                          />
                          <span className="label-span">{obj.title}</span>
                          {watch(`accept_discount`) == String(obj.value) ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonEmpty />
                          )}
                        </label>
                      ))}
                    </div>

                    {errors?.accept_discount && (
                      <p className="error-p">
                        {errors.accept_discount.message}
                      </p>
                    )}
                  </div>

                  <div className="radios-wrap">
                    <p className="radios-group-title">
                      {t('Inventory.Products.isTheProductMadeUpOrNot')}
                    </p>
                    <div className="labels-wrap">
                      {[
                        {
                          title: t('Inventory.Products.yes'),
                          value: '1'
                        },
                        {
                          title: t('Inventory.Products.no'),
                          value: '2'
                        }
                      ].map((obj, i) => (
                        <label
                          key={i}
                          className={`radio-input-label ${
                            String(watch(`is_ingredient`)) === String(obj.value)
                              ? 'selected'
                              : ''
                          }`}
                        >
                          <input
                            type="radio"
                            value={obj.value}
                            {...register(`is_ingredient`)}
                          />
                          <span className="label-span">{obj.title}</span>
                          {watch(`is_ingredient`) == String(obj.value) ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonEmpty />
                          )}
                        </label>
                      ))}
                    </div>

                    {errors?.is_ingredient && (
                      <p className="error-p">{errors.is_ingredient.message}</p>
                    )}
                  </div>
                </div>
                <div className="date-picker-wrap">
                  <p className="label-p">
                    {t('Inventory.Products.productExpirationDate')}
                  </p>
                  <DatePicker
                    name="end_date"
                    // format="YYYY-MM-DD"
                    size="large"
                    {...field}
                    control={control}
                    // defaultValue={selectedCash?.date && moment(selectedCash?.date)}
                    // bordered={false}
                    setValue={setValue}
                    placeholder={t(
                      'Inventory.Products.productExpirationDatePlaceholder'
                    )}
                    className="receipt-date-picker"
                    formClassName="create-receipt-form"
                  />
                  {/* {errors?.date && <p className="error-p">{errors.date.message}</p>} */}
                </div>
                {/* <CustomEditor
                  fieldName="product_description"
                  editorTitle={t('Inventory.Products.productDetails')}
                  setValue={setValue}
                  watch={watch}
                  setError={setError}
                  clearErrors={clearErrors}
                  errors={errors}
                  required={false}
                  errorMsg={t('Inventory.Products.productDetails')}
                  editorFieldState={productDescEditorState}
                  setEditorFieldState={setProductDescEditorState}
                  fetchedData={selectedProduct?.desc}
                /> */}
                <div className="text-field-label-wrap">
                  <p className="label-p">
                    {t('Inventory.Products.productDetails')}
                  </p>
                  <div className="text-field-wrap">
                    <AntdTextarea
                      className="form-text-area"
                      name="product_description"
                      rows={8}
                      placeholder={t('Inventory.Products.productDetails')}
                      errorMsg={errors?.product_description?.message}
                      validateStatus={
                        errors?.product_description ? 'error' : ''
                      }
                      control={control}
                    />
                  </div>
                </div>
                <div className="product-img-wrap">
                  <h3>{t('Inventory.Products.productImage')}</h3>
                  <MyDropZone
                    className="product-header-dropzone"
                    multipleFiles={false}
                    handleFilesDrop={(acceptedFiles) =>
                      handleFilesDrop(
                        acceptedFiles,
                        productFileToUpload,
                        setProductFileToUpload
                      )
                    }
                    filesToUpload={productFileToUpload}
                    formName="store-new-product-form"
                    id="avatar-input"
                    dropzoneText={t('Inventory.Products.productImage')}
                    inputName="product_image"
                    icon={<CameraFilled className="dropzone-camera" />}
                    dropZoneUrls={
                      selectedProduct?.image ? [selectedProduct.image] : null
                    }
                  >
                    {t('Inventory.Products.dragTheImageAndPlaceItHere')}
                  </MyDropZone>

                  <MyDropZonePreview
                    filesToUpload={productFileToUpload}
                    clearFileFromUpload={(f) =>
                      clearFileFromUpload(f, setProductFileToUpload)
                    }
                  />
                  {errors?.product_image?.message &&
                    !watch('product_image') && (
                      <p className="error-p">{errors.product_image.message}</p>
                    )}
                </div>
              </>
            )}
          </>
          {/* المنتج الموزون */}
          <>
            {watch('product_type') == 3 && (
              <>
                {/* {!selectedProduct &&
              <>
                <h2 style={{marginBottom : '10px'}}>ماذا تريد ان تضيف ؟</h2>
                <AntdRadioGroup
                  disabled={selectedProduct}
                  name="add_product"
                  className="form-radio-group"
                  control={control}
                  validateStatus={errors?.add_product ? 'error' : ''}
                  errorMsg={errors?.add_product?.message}
                  onChange={() => setValue('add_product_id', '')}
                  radios={[
                    {
                      title: 'أضافه حزمه جديده',
                      value: '1'
                    },
                    {
                      title: 'أضافه وحدات لحزمه حاليه',
                      value: '2'
                    }
                  ]}
                  defaultValue='1'
                  isRadioButton
                />
              </>
              } */}

                {/* (watch('add_product') == 1 || watch('add_product') == undefined) && */}
                <>
                  <div className="name-default-barcode-wrap">
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.productName')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          className="form-text-field"
                          name="product_name"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.productNamePlaceholder'
                          )}
                          errorMsg={errors?.product_name?.message}
                          validateStatus={errors?.product_name ? 'error' : ''}
                          control={control}
                        />
                      </div>
                    </div>
                    <div className="text-field-label-wrap">
                      <p
                        className="label-p"
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <span>{t('Inventory.Products.productBarcode')}</span>
                        <b
                          style={{
                            border: 'none',
                            cursor: 'pointer',
                            color: '#4d71db'
                          }}
                          onClick={() => handleClick()}
                        >
                          {t('Inventory.Products.generateNewBarcode')}
                        </b>
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          setValue={setValue}
                          disabled={watch('isWeight') == '1'}
                          className="form-text-field"
                          name="defaultParCode"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.productBarcodePlaceholder'
                          )}
                          errorMsg={errors?.defaultParCode?.message}
                          validateStatus={errors?.defaultParCode ? 'error' : ''}
                          control={control}
                          defaultValue={barCodeNum}
                        />
                      </div>
                    </div>
                    {selectedProduct?.parent_id > 0 && (
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Inventory.Products.quantity')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            setValue={setValue}
                            className="form-text-field"
                            name={`quantity`}
                            type="text"
                            placeholder={t(
                              'Inventory.Products.quantityPlaceholder'
                            )}
                            errorMsg={errors?.quantity?.message}
                            validateStatus={errors?.quantity ? 'error' : ''}
                            control={control}
                            defaultValue={selectedProduct?.real_quantity}
                            // readOnly={true}
                          />
                        </div>
                      </div>
                    )}
                    {!selectedProduct?.parent_id && (
                      <>
                        <div className="select-label-wrap">
                          <p
                            className="label-p"
                            style={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between'
                            }}
                          >
                            <span>
                              {t('Inventory.Products.mainProductCategory')}
                            </span>
                            <b
                              style={{
                                border: 'none',
                                cursor: 'pointer',
                                color: '#4d71db'
                              }}
                              onClick={() => {
                                setCatModalOpened(true);
                              }}
                            >
                              {t('Inventory.Products.addNewCategory')}
                            </b>
                          </p>
                          <div className="custom-select-wrap without-icon">
                            <AntdSelectOption
                              name="product_category"
                              errorMsg={errors?.product_category?.message}
                              validateStatus={
                                errors?.product_category ? 'error' : ''
                              }
                              control={control}
                              setValue={setValue}
                              placeholder={t(
                                'Inventory.Products.mainProductCategory'
                              )}
                              options={
                                mainCategories?.length > 0
                                  ? mainCategories.map((cat) => ({
                                      title: cat.name,
                                      value: String(cat.id)
                                    }))
                                  : null
                              }
                              showSearch={true}
                              filterOption={(input, option) =>
                                option.children.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              formClassName="store-new-product-form"
                            />
                          </div>
                        </div>
                        <div className="unit-isWeight-wrapper">
                          <div className="discount-units-wrap">
                            <div className="select-label-wrap">
                              <p
                                className="label-p"
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between'
                                }}
                              >
                                <span>
                                  {t('Inventory.Products.measruingUnit')}
                                </span>
                                <b
                                  style={{
                                    border: 'none',
                                    cursor: 'pointer',
                                    color: '#4d71db'
                                  }}
                                  onClick={() => {
                                    setUnitModalOpened(true);
                                  }}
                                >
                                  {t('Inventory.Products.addNewMeasruingUnit')}
                                </b>
                              </p>
                              <div className="custom-select-wrap without-icon">
                                <AntdSelectOption
                                  name="measruing_unit"
                                  errorMsg={errors?.measruing_unit?.message}
                                  validateStatus={
                                    errors?.measruing_unit ? 'error' : ''
                                  }
                                  control={control}
                                  setValue={setValue}
                                  placeholder={t(
                                    'Inventory.Products.measruingUnit'
                                  )}
                                  options={
                                    allUnits?.length > 0
                                      ? allUnits.map((unit) => ({
                                          title: unit.name,
                                          value: String(unit.id)
                                        }))
                                      : null
                                  }
                                  showSearch={true}
                                  // onSearch={onSearch}
                                  filterOption={(input, option) =>
                                    option.children.props.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  formClassName="store-new-product-form"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="select-label-wrap">
                          <p className="label-p">
                            {t('Inventory.Products.itsMainWarehouse')}
                          </p>
                          <div className="custom-select-wrap without-icon">
                            <AntdSelectOption
                              name="stock_id"
                              errorMsg={errors?.stock_id?.message}
                              validateStatus={errors?.stock_id ? 'error' : ''}
                              control={control}
                              setValue={setValue}
                              placeholder={t(
                                'Inventory.Products.itsMainWarehouse'
                              )}
                              options={
                                allWarehouses?.length > 0
                                  ? allWarehouses.map((obj) => ({
                                      title: obj.name,
                                      value: String(obj.id)
                                    }))
                                  : null
                              }
                              showSearch={true}
                              filterOption={(input, option) =>
                                option.children.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              formClassName="store-new-product-form"
                            />
                          </div>
                        </div>
                        <div className="text-field-label-wrap">
                          <p className="label-p">
                            {t('Inventory.Products.firstTermStock')}
                          </p>
                          <div className="text-field-wrap">
                            <AntdTextField
                              className="form-text-field"
                              name="first_quantity"
                              type="text"
                              placeholder={t(
                                'Inventory.Products.firstTermStockPlaceholder'
                              )}
                              errorMsg={
                                errors?.first_quantity &&
                                errors.first_quantity.message
                              }
                              validateStatus={
                                errors?.first_quantity && errors?.first_quantity
                                  ? 'error'
                                  : ''
                              }
                              control={control}
                              // defaultValue={watch(`ingredients.${index}.ingredient_quantity`)} // make sure to set up defaultValue
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="buying-wrap">
                      <div className="radios-wrap">
                        <p className="radios-group-title">
                          {t('Inventory.Products.activateTaxForProduct')}
                        </p>
                        <div className="labels-wrap">
                          {[
                            {
                              title: t('Inventory.Products.yes'),
                              value: '1'
                            },
                            {
                              title: t('Inventory.Products.no'),
                              value: '2'
                            }
                          ].map((obj, i) => (
                            <label
                              key={i}
                              className={`radio-input-label ${
                                String(watch(`active_tax`)) ===
                                String(obj.value)
                                  ? 'selected'
                                  : ''
                              }`}
                            >
                              <input
                                type="radio"
                                value={obj.value}
                                {...register(`active_tax`)}
                              />
                              <span className="label-span">{obj.title}</span>
                              {watch(`active_tax`) == String(obj.value) ? (
                                <RadioButtonFilled />
                              ) : (
                                <RadioButtonEmpty />
                              )}
                            </label>
                          ))}
                        </div>

                        {errors?.active_tax && (
                          <p className="error-p">{errors.active_tax.message}</p>
                        )}
                      </div>
                      {watch('active_tax') == 1 && (
                        <div className="radios-wrap">
                          <p className="radios-group-title">
                            {t('Inventory.Products.doesproductIncludeTax')}
                          </p>
                          <div className="labels-wrap">
                            {[
                              {
                                title: t('Inventory.Products.yes'),
                                value: '1'
                              },
                              {
                                title: t('Inventory.Products.no'),
                                value: '0'
                              }
                            ].map((obj, i) => (
                              <label
                                key={i}
                                className={`radio-input-label ${
                                  String(watch(`including_tax`)) ===
                                  String(obj.value)
                                    ? 'selected'
                                    : ''
                                }`}
                              >
                                <input
                                  type="radio"
                                  value={obj.value}
                                  {...register(`including_tax`)}
                                />
                                <span className="label-span">{obj.title}</span>
                                {watch(`including_tax`) == String(obj.value) ? (
                                  <RadioButtonFilled />
                                ) : (
                                  <RadioButtonEmpty />
                                )}
                              </label>
                            ))}
                          </div>

                          {errors?.including_tax && (
                            <p className="error-p">
                              {errors.including_tax.message}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {!selectedProduct?.parent_id && (
                    <div
                      className="price-tax-wrap"
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr',
                        gap: 18
                      }}
                    >
                      {watch('active_tax') == 1 &&
                        watch('including_tax') == 1 && (
                          <div className="text-field-label-wrap">
                            <p className="label-p">
                              {t('Inventory.Products.purchasePriceIncludesTax')}
                            </p>
                            <div className="text-field-wrap">
                              <AntdTextField
                                setValue={setValue}
                                className="form-text-field"
                                name="buyPriceWithTax"
                                type="text"
                                placeholder={t(
                                  'Inventory.Products.purchasePriceIncludesTax'
                                )}
                                errorMsg={errors?.buyPriceWithTax?.message}
                                validateStatus={
                                  errors?.buyPriceWithTax ? 'error' : ''
                                }
                                control={control}
                                // defaultValue={
                                //   watch('active_tax') == 1
                                //     ? parseFloat(watch('tax')) > 0
                                //       ? parseFloat(watch('salePrice')) +
                                //         parseFloat(watch('tax'))
                                //       : parseFloat(watch('salePrice'))
                                //     : ''
                                // }
                                // readOnly={
                                //   watch('including_tax') == 1 ? false : true
                                // }
                              />
                            </div>
                          </div>
                        )}
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Inventory.Products.purchasePriceWithoutTax')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            name="product_buying_price"
                            type="text"
                            setValue={setValue}
                            placeholder={t('Inventory.Products.purchasePrice')}
                            errorMsg={errors?.product_buying_price?.message}
                            validateStatus={
                              errors?.product_buying_price ? 'error' : ''
                            }
                            control={control}
                            defaultValue={
                              watch('including_tax') == 1 &&
                              true &&
                              watch('buyPriceWithTax')
                                ? (
                                    parseFloat(watch('buyPriceWithTax') * 100) /
                                    (parseFloat(
                                      systemSettings?.invoiceSetting?.tax
                                    ) +
                                      100)
                                  ).toFixed(2)
                                : ''
                            }
                            readOnly={
                              watch('including_tax') == 1 &&
                              watch('active_tax') == 1
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>

                      {watch('active_tax') == 1 && (
                        <div className="text-field-label-wrap">
                          <p className="label-p">
                            {t('Inventory.Products.purchaseTax')}
                          </p>
                          <div className="text-field-wrap">
                            <AntdTextField
                              // disabled={true}
                              setValue={setValue}
                              className="form-text-field"
                              name="buy_tax"
                              type="text"
                              placeholder={t(
                                'Inventory.Products.purchaseTaxPlaceholder'
                              )}
                              errorMsg={errors?.buy_tax?.message}
                              validateStatus={errors?.buy_tax ? 'error' : ''}
                              control={control}
                              // defaultValue={parseFloat(
                              //   (systemSettings?.invoiceSetting?.buy_tax *
                              //     watch('product_buying_price')) /
                              //     100
                              // )}
                              defaultValue={
                                systemSettings?.invoiceSetting?.tax > 0 &&
                                watch('product_buying_price')
                                  ? parseFloat(
                                      (systemSettings?.invoiceSetting?.tax *
                                        watch('product_buying_price')) /
                                        100
                                    ).toFixed(3)
                                  : ''
                              }
                              value={systemSettings?.invoiceSetting?.buy_tax}
                              readOnly={true}
                            />
                          </div>
                        </div>
                      )}

                      {watch('active_tax') == 1 &&
                        watch('including_tax') == 0 && (
                          <div className="text-field-label-wrap">
                            <p className="label-p">
                              {t('Inventory.Products.purchasePriceIncludesTax')}
                            </p>
                            <div className="text-field-wrap">
                              <AntdTextField
                                setValue={setValue}
                                className="form-text-field"
                                name="buyPriceWithTax"
                                type="text"
                                placeholder={t(
                                  'Inventory.Products.purchasePriceIncludesTax'
                                )}
                                errorMsg={errors?.buyPriceWithTax?.message}
                                validateStatus={
                                  errors?.buyPriceWithTax ? 'error' : ''
                                }
                                control={control}
                                defaultValue={
                                  watch('active_tax') == 1
                                    ? parseFloat(watch('buy_tax')) > 0
                                      ? parseFloat(
                                          parseFloat(
                                            watch('product_buying_price')
                                          ) + parseFloat(watch('buy_tax'))
                                        ).toFixed(3)
                                      : parseFloat(
                                          watch('product_buying_price')
                                        )
                                    : ''
                                }
                                readOnly={
                                  watch('including_tax') == 1 ? false : true
                                }
                              />
                            </div>
                          </div>
                        )}

                      {watch('active_tax') == 1 &&
                        watch('including_tax') == 1 && (
                          <div className="text-field-label-wrap">
                            <p className="label-p">
                              {t('Inventory.Products.sellingPriceIncludesTax')}
                            </p>
                            <div className="text-field-wrap">
                              <AntdTextField
                                setValue={setValue}
                                className="form-text-field"
                                name="salePriceWithTax"
                                type="text"
                                placeholder={t(
                                  'Inventory.Products.sellingPriceIncludesTaxPlaceholder'
                                )}
                                errorMsg={errors?.salePriceWithTax?.message}
                                validateStatus={
                                  errors?.salePriceWithTax ? 'error' : ''
                                }
                                control={control}
                                // defaultValue={
                                //   watch('active_tax') == 1
                                //     ? parseFloat(watch('tax')) > 0
                                //       ? parseFloat(watch('salePrice')) +
                                //         parseFloat(watch('tax'))
                                //       : parseFloat(watch('salePrice'))
                                //     : ''
                                // }
                                // readOnly={
                                //   watch('including_tax') == 1 ? false : true
                                // }
                              />
                            </div>
                          </div>
                        )}

                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Inventory.Products.sellingPriceWithoutTax')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            name="salePrice"
                            setValue={setValue}
                            type="text"
                            placeholder={t(
                              'Inventory.Products.sellingPriceWithoutTaxPlaceholder'
                            )}
                            errorMsg={errors?.salePrice?.message}
                            validateStatus={errors?.salePrice ? 'error' : ''}
                            control={control}
                            defaultValue={
                              watch('including_tax') == 1 &&
                              watch('salePriceWithTax') > 0 &&
                              (
                                (parseFloat(watch('salePriceWithTax')) * 100) /
                                (parseFloat(
                                  systemSettings?.invoiceSetting?.tax
                                ) +
                                  100)
                              ).toFixed(3)
                            }
                            readOnly={
                              watch('including_tax') == 1 &&
                              watch('active_tax') == 1
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                      {watch('active_tax') == 1 && (
                        <div className="text-field-label-wrap">
                          <p className="label-p">
                            {t('Inventory.Products.salesTax')}
                          </p>
                          <div className="text-field-wrap">
                            <AntdTextField
                              // disabled={true}
                              setValue={setValue}
                              className="form-text-field"
                              name="tax"
                              type="text"
                              placeholder={t(
                                'Inventory.Products.salesTaxPlaceholder'
                              )}
                              errorMsg={errors?.tax?.message}
                              validateStatus={errors?.tax ? 'error' : ''}
                              control={control}
                              defaultValue={
                                systemSettings?.invoiceSetting?.tax > 0 &&
                                watch('salePrice')
                                  ? parseFloat(
                                      (systemSettings?.invoiceSetting?.tax *
                                        watch('salePrice')) /
                                        100
                                    ).toFixed(3)
                                  : ''
                              }
                              value={systemSettings?.invoiceSetting?.tax}
                              readOnly={true}
                            />
                          </div>
                        </div>
                      )}
                      {watch('active_tax') == 1 &&
                        watch('including_tax') == 0 && (
                          <div className="text-field-label-wrap">
                            <p className="label-p">
                              {t('Inventory.Products.sellingPriceIncludesTax')}
                            </p>
                            <div className="text-field-wrap">
                              <AntdTextField
                                setValue={setValue}
                                className="form-text-field"
                                name="salePriceWithTax"
                                type="text"
                                placeholder={t(
                                  'Inventory.Products.sellingPriceIncludesTaxPlaceholder'
                                )}
                                errorMsg={errors?.salePriceWithTax?.message}
                                validateStatus={
                                  errors?.salePriceWithTax ? 'error' : ''
                                }
                                control={control}
                                defaultValue={
                                  parseFloat(watch('tax')) > 0 &&
                                  watch('salePrice')
                                    ? parseFloat(
                                        parseFloat(watch('salePrice')) +
                                          parseFloat(watch('tax'))
                                      ).toFixed(3)
                                    : parseFloat(watch('salePrice'))
                                }
                                readOnly={
                                  watch('including_tax') == 1 ? false : true
                                }
                              />
                            </div>
                          </div>
                        )}
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Inventory.Products.wholePrice')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            name="wholesale_price"
                            type="text"
                            placeholder={t(
                              'Inventory.Products.wholePricePlaceholder'
                            )}
                            errorMsg={errors?.wholesale_price?.message}
                            validateStatus={
                              errors?.wholesale_price ? 'error' : ''
                            }
                            control={control}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {!selectedProduct?.parent_id && (
                    <div className="buying-wrap">
                      <div className="radios-wrap">
                        <p className="radios-group-title">
                          {t('Inventory.Products.doesTheProductAcceptDiscount')}
                        </p>
                        <div className="labels-wrap">
                          {[
                            {
                              title: t('Inventory.Products.yes'),
                              value: '1'
                            },
                            {
                              title: t('Inventory.Products.no'),
                              value: '2'
                            }
                          ].map((obj, i) => (
                            <label
                              key={i}
                              className={`radio-input-label ${
                                String(watch(`accept_discount`)) ===
                                String(obj.value)
                                  ? 'selected'
                                  : ''
                              }`}
                            >
                              <input
                                type="radio"
                                value={obj.value}
                                {...register(`accept_discount`)}
                              />
                              <span className="label-span">{obj.title}</span>
                              {watch(`accept_discount`) == String(obj.value) ? (
                                <RadioButtonFilled />
                              ) : (
                                <RadioButtonEmpty />
                              )}
                            </label>
                          ))}
                        </div>

                        {errors?.accept_discount && (
                          <p className="error-p">
                            {errors.accept_discount.message}
                          </p>
                        )}
                      </div>

                      <div className="radios-wrap">
                        <p className="radios-group-title">
                          {t('Inventory.Products.isTheProductMadeUpOrNot')}
                        </p>
                        <div className="labels-wrap">
                          {[
                            {
                              title: t('Inventory.Products.yes'),
                              value: '1'
                            },
                            {
                              title: t('Inventory.Products.no'),
                              value: '2'
                            }
                          ].map((obj, i) => (
                            <label
                              key={i}
                              className={`radio-input-label ${
                                String(watch(`is_ingredient`)) ===
                                String(obj.value)
                                  ? 'selected'
                                  : ''
                              }`}
                            >
                              <input
                                type="radio"
                                value={obj.value}
                                {...register(`is_ingredient`)}
                              />
                              <span className="label-span">{obj.title}</span>
                              {watch(`is_ingredient`) == String(obj.value) ? (
                                <RadioButtonFilled />
                              ) : (
                                <RadioButtonEmpty />
                              )}
                            </label>
                          ))}
                        </div>

                        {errors?.is_ingredient && (
                          <p className="error-p">
                            {errors.is_ingredient.message}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="date-picker-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.productExpirationDate')}
                    </p>
                    <DatePicker
                      name="end_date"
                      // format="YYYY-MM-DD"
                      size="large"
                      {...field}
                      control={control}
                      // defaultValue={selectedCash?.date && moment(selectedCash?.date)}
                      // bordered={false}
                      setValue={setValue}
                      placeholder={t(
                        'Inventory.Products.productExpirationDatePlaceholder'
                      )}
                      className="receipt-date-picker"
                      formClassName="create-receipt-form"
                    />
                    {/* {errors?.date && <p className="error-p">{errors.date.message}</p>} */}
                  </div>
                  {/* <CustomEditor
                    fieldName="product_description"
                    editorTitle={t('Inventory.Products.productDetails')}
                    setValue={setValue}
                    watch={watch}
                    setError={setError}
                    clearErrors={clearErrors}
                    errors={errors}
                    required={false}
                    errorMsg={t('Inventory.Products.productDetails')}
                    editorFieldState={productDescEditorState}
                    setEditorFieldState={setProductDescEditorState}
                    fetchedData={selectedProduct?.desc}
                  /> */}
                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.productDetails')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextarea
                        className="form-text-area"
                        name="product_description"
                        rows={8}
                        placeholder={t('Inventory.Products.productDetails')}
                        errorMsg={errors?.product_description?.message}
                        validateStatus={
                          errors?.product_description ? 'error' : ''
                        }
                        control={control}
                      />
                    </div>
                  </div>
                  {!selectedProduct?.parent_id && (
                    <div className="product-img-wrap">
                      <h3>{t('Inventory.Products.productImage')}</h3>

                      <MyDropZone
                        className="product-header-dropzone"
                        multipleFiles={false}
                        handleFilesDrop={(acceptedFiles) =>
                          handleFilesDrop(
                            acceptedFiles,
                            productFileToUpload,
                            setProductFileToUpload
                          )
                        }
                        filesToUpload={productFileToUpload}
                        formName="store-new-product-form"
                        id="avatar-input"
                        dropzoneText={t('Inventory.Products.productImage')}
                        inputName="product_image"
                        icon={<CameraFilled className="dropzone-camera" />}
                        dropZoneUrls={
                          selectedProduct?.image
                            ? [selectedProduct.image]
                            : null
                        }
                      >
                        {t('Inventory.Products.dragTheImageAndPlaceItHere')}
                      </MyDropZone>

                      <MyDropZonePreview
                        filesToUpload={productFileToUpload}
                        clearFileFromUpload={(f) =>
                          clearFileFromUpload(f, setProductFileToUpload)
                        }
                      />
                      {errors?.product_image?.message &&
                        !watch('product_image') && (
                          <p className="error-p">
                            {errors.product_image.message}
                          </p>
                        )}
                    </div>
                  )}

                  {/* sub products fields */}
                  {!selectedProduct && (
                    <div className="product-desc-feilds">
                      <h2>{t('Inventory.Products.productsDetails')}</h2>
                      {renderUnitsFields()}
                      <div
                        className="add-new-field-btn"
                        onClick={() =>
                          appendSubProductsField({
                            name: '',
                            saleSubPrice: '',
                            quantity: '',
                            defaultParCode: ''
                          })
                        }
                      >
                        <span>{t('Inventory.Products.addNewUnit')}</span>
                      </div>
                    </div>
                  )}
                </>

                {/* {
                watch('add_product') == 2 &&
                <>
                  <h2>تفاصيل المنتج</h2>
                  <div className=''>
                    <div className="select-label-wrap" style={{marginTop : '10px' , marginBottom : '30px'}}>
                        <p className="label-p">إختر المنتج</p>
                        <div className="custom-select-wrap without-icon">
                          <AntdSelectOption
                            name="parent_id"
                            errorMsg={errors?.parent_id?.message}
                            validateStatus={errors?.parent_id ? 'error' : ''}
                            control={control}
                            setValue={setValue}
                            placeholder="إختر المنتج"
                            options={
                              allProducts?.length > 0
                                ? allProducts.map((prod) => ({
                                    title: prod.name,
                                    value: String(prod.id)
                                  }))
                                : null
                            }
                            formClassName="store-new-product-form"
                          />
                        </div>
                    </div>

                    <div className='product-desc-feilds'>
                      <h2>تفاصيل المنتجات</h2>

                      {renderUnitsFields()}

                      <div
                        className="add-new-field-btn"
                        onClick={() =>
                          appendSubProductsField({
                            name: '',
                            salePrice: '',
                            stock_id: '',
                            first_quantity: '',
                            weight: '',
                          })
                        }
                      >
                        <span>{t('Inventory.Products.addNewUnit')}</span>
                      </div>

                    </div>
                  </div>
                </>
              } */}
              </>
            )}
          </>
          {/* المنتج الرقمى */}
          <>
            {watch('product_type') == 4 && (
              <>
                <div className="name-default-barcode-wrap">
                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.productName')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name="product_name"
                        type="text"
                        placeholder={t(
                          'Inventory.Products.productNamePlaceholder'
                        )}
                        errorMsg={errors?.product_name?.message}
                        validateStatus={errors?.product_name ? 'error' : ''}
                        control={control}
                      />
                    </div>
                  </div>
                  <div className="text-field-label-wrap">
                    <p
                      className="label-p"
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span>{t('Inventory.Products.productBarcode')}</span>
                      <b
                        style={{
                          border: 'none',
                          cursor: 'pointer',
                          color: '#4d71db'
                        }}
                        onClick={() => handleClick()}
                      >
                        {t('Inventory.Products.generateNewBarcode')}
                      </b>
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        setValue={setValue}
                        disabled={watch('isWeight') == '1'}
                        className="form-text-field"
                        name="defaultParCode"
                        type="text"
                        placeholder={t(
                          'Inventory.Products.productBarcodePlaceholder'
                        )}
                        errorMsg={errors?.defaultParCode?.message}
                        validateStatus={errors?.defaultParCode ? 'error' : ''}
                        control={control}
                        defaultValue={barCodeNum}
                      />
                    </div>
                  </div>
                  <div className="select-label-wrap">
                    <p
                      className="label-p"
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span>{t('Inventory.Products.mainProductCategory')}</span>
                      <b
                        style={{
                          border: 'none',
                          cursor: 'pointer',
                          color: '#4d71db'
                        }}
                        onClick={() => {
                          setCatModalOpened(true);
                        }}
                      >
                        {t('Inventory.Products.addNewCategory')}
                      </b>
                    </p>
                    <div className="custom-select-wrap without-icon">
                      <AntdSelectOption
                        name="product_category"
                        errorMsg={errors?.product_category?.message}
                        validateStatus={errors?.product_category ? 'error' : ''}
                        control={control}
                        setValue={setValue}
                        placeholder={t(
                          'Inventory.Products.mainProductCategory'
                        )}
                        options={
                          mainCategories?.length > 0
                            ? mainCategories.map((cat) => ({
                                title: cat.name,
                                value: String(cat.id)
                              }))
                            : null
                        }
                        showSearch={true}
                        filterOption={(input, option) =>
                          option.children.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        formClassName="store-new-product-form"
                      />
                    </div>
                  </div>
                  <div className="unit-isWeight-wrapper">
                    <div className="discount-units-wrap">
                      <div className="select-label-wrap">
                        <p
                          className="label-p"
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span>{t('Inventory.Products.measruingUnit')}</span>
                          <b
                            style={{
                              border: 'none',
                              cursor: 'pointer',
                              color: '#4d71db'
                            }}
                            onClick={() => {
                              setUnitModalOpened(true);
                            }}
                          >
                            {t('Inventory.Products.addNewMeasruingUnit')}
                          </b>
                        </p>
                        <div className="custom-select-wrap without-icon">
                          <AntdSelectOption
                            name="measruing_unit"
                            errorMsg={errors?.measruing_unit?.message}
                            validateStatus={
                              errors?.measruing_unit ? 'error' : ''
                            }
                            control={control}
                            setValue={setValue}
                            placeholder={t('Inventory.Products.measruingUnit')}
                            options={
                              allUnits?.length > 0
                                ? allUnits.map((unit) => ({
                                    title: unit.name,
                                    value: String(unit.id)
                                  }))
                                : null
                            }
                            showSearch={true}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            formClassName="store-new-product-form"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="select-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.itsMainWarehouse')}
                    </p>
                    <div className="custom-select-wrap without-icon">
                      <AntdSelectOption
                        name="stock_id"
                        errorMsg={errors?.stock_id?.message}
                        validateStatus={errors?.stock_id ? 'error' : ''}
                        control={control}
                        setValue={setValue}
                        placeholder={t('Inventory.Products.itsMainWarehouse')}
                        options={
                          allWarehouses?.length > 0
                            ? allWarehouses.map((obj) => ({
                                title: obj.name,
                                value: String(obj.id)
                              }))
                            : null
                        }
                        showSearch={true}
                        filterOption={(input, option) =>
                          option.children.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        formClassName="store-new-product-form"
                      />
                    </div>
                  </div>
                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.firstTermStock')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name="first_quantity"
                        type="text"
                        placeholder={t(
                          'Inventory.Products.firstTermStockPlaceholder'
                        )}
                        errorMsg={
                          errors?.first_quantity &&
                          errors.first_quantity.message
                        }
                        validateStatus={
                          errors?.first_quantity && errors?.first_quantity
                            ? 'error'
                            : ''
                        }
                        control={control}
                        // defaultValue={watch(`ingredients.${index}.ingredient_quantity`)} // make sure to set up defaultValue
                      />
                    </div>
                  </div>
                </div>

                <div className="buying-wrap">
                  <div className="radios-wrap">
                    <p className="radios-group-title">
                      {t('Inventory.Products.activateTaxForProduct')}
                    </p>
                    <div className="labels-wrap">
                      {[
                        {
                          title: t('Inventory.Products.yes'),
                          value: '1'
                        },
                        {
                          title: t('Inventory.Products.no'),
                          value: '2'
                        }
                      ].map((obj, i) => (
                        <label
                          key={i}
                          className={`radio-input-label ${
                            String(watch(`active_tax`)) === String(obj.value)
                              ? 'selected'
                              : ''
                          }`}
                        >
                          <input
                            type="radio"
                            value={obj.value}
                            {...register(`active_tax`)}
                          />
                          <span className="label-span">{obj.title}</span>
                          {watch(`active_tax`) == String(obj.value) ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonEmpty />
                          )}
                        </label>
                      ))}
                    </div>

                    {errors?.active_tax && (
                      <p className="error-p">{errors.active_tax.message}</p>
                    )}
                  </div>
                  {watch('active_tax') == 1 && (
                    <div className="radios-wrap">
                      <p className="radios-group-title">
                        {t('Inventory.Products.doesproductIncludeTax')}
                      </p>
                      <div className="labels-wrap">
                        {[
                          {
                            title: t('Inventory.Products.yes'),
                            value: '1'
                          },
                          {
                            title: t('Inventory.Products.no'),
                            value: '0'
                          }
                        ].map((obj, i) => (
                          <label
                            key={i}
                            className={`radio-input-label ${
                              String(watch(`including_tax`)) ===
                              String(obj.value)
                                ? 'selected'
                                : ''
                            }`}
                          >
                            <input
                              type="radio"
                              value={obj.value}
                              {...register(`including_tax`)}
                            />
                            <span className="label-span">{obj.title}</span>
                            {watch(`including_tax`) == String(obj.value) ? (
                              <RadioButtonFilled />
                            ) : (
                              <RadioButtonEmpty />
                            )}
                          </label>
                        ))}
                      </div>

                      {errors?.including_tax && (
                        <p className="error-p">
                          {errors.including_tax.message}
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <div
                  className="price-tax-wrap"
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: 18
                  }}
                >
                  {watch('active_tax') == 1 && watch('including_tax') == 1 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.purchasePriceIncludesTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          setValue={setValue}
                          className="form-text-field"
                          name="buyPriceWithTax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.purchasePriceIncludesTax'
                          )}
                          errorMsg={errors?.buyPriceWithTax?.message}
                          validateStatus={
                            errors?.buyPriceWithTax ? 'error' : ''
                          }
                          control={control}
                          // defaultValue={
                          //   watch('active_tax') == 1
                          //     ? parseFloat(watch('tax')) > 0
                          //       ? parseFloat(watch('salePrice')) +
                          //         parseFloat(watch('tax'))
                          //       : parseFloat(watch('salePrice'))
                          //     : ''
                          // }
                          // readOnly={
                          //   watch('including_tax') == 1 ? false : true
                          // }
                        />
                      </div>
                    </div>
                  )}
                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.purchasePriceWithoutTax')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name="product_buying_price"
                        type="text"
                        placeholder={`${t(
                          'Inventory.Products.purchasePriceWithoutTax'
                        )}...`}
                        errorMsg={errors?.product_buying_price?.message}
                        validateStatus={
                          errors?.product_buying_price ? 'error' : ''
                        }
                        control={control}
                        setValue={setValue}
                        defaultValue={
                          watch('including_tax') == 1 &&
                          watch('buyPriceWithTax')
                            ? (
                                parseFloat(watch('buyPriceWithTax') * 100) /
                                (parseFloat(
                                  systemSettings?.invoiceSetting?.tax
                                ) +
                                  100)
                              ).toFixed(2)
                            : ''
                        }
                        readOnly={
                          watch('including_tax') == 1 &&
                          watch('active_tax') == 1
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                  {watch('active_tax') == 1 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.purchaseTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          // disabled={true}
                          setValue={setValue}
                          className="form-text-field"
                          name="buy_tax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.purchaseTaxPlaceholder'
                          )}
                          errorMsg={errors?.buy_tax?.message}
                          validateStatus={errors?.buy_tax ? 'error' : ''}
                          control={control}
                          // defaultValue={parseFloat(
                          //   (systemSettings?.invoiceSetting?.buy_tax *
                          //     watch('product_buying_price')) /
                          //     100
                          // )}
                          defaultValue={
                            systemSettings?.invoiceSetting?.tax > 0 &&
                            watch('product_buying_price')
                              ? parseFloat(
                                  (systemSettings?.invoiceSetting?.tax *
                                    watch('product_buying_price')) /
                                    100
                                ).toFixed(3)
                              : ''
                          }
                          value={systemSettings?.invoiceSetting?.buy_tax}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  )}

                  {watch('active_tax') == 1 && watch('including_tax') == 0 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.purchasePriceIncludesTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          setValue={setValue}
                          className="form-text-field"
                          name="buyPriceWithTax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.purchasePriceIncludesTax'
                          )}
                          errorMsg={errors?.buyPriceWithTax?.message}
                          validateStatus={
                            errors?.buyPriceWithTax ? 'error' : ''
                          }
                          control={control}
                          defaultValue={
                            watch('active_tax') == 1
                              ? parseFloat(watch('buy_tax')) > 0
                                ? (
                                    parseFloat(watch('product_buying_price')) +
                                    parseFloat(watch('buy_tax'))
                                  ).toFixed(3)
                                : parseFloat(watch('product_buying_price'))
                              : ''
                          }
                          readOnly={watch('including_tax') == 1 ? false : true}
                        />
                      </div>
                    </div>
                  )}
                  {watch('active_tax') == 1 && watch('including_tax') == 1 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.sellingPriceIncludesTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          setValue={setValue}
                          className="form-text-field"
                          name="salePriceWithTax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.sellingPriceIncludesTaxPlaceholder'
                          )}
                          errorMsg={errors?.salePriceWithTax?.message}
                          validateStatus={
                            errors?.salePriceWithTax ? 'error' : ''
                          }
                          control={control}
                          // defaultValue={
                          //   watch('active_tax') == 1
                          //     ? parseFloat(watch('tax')) > 0
                          //       ? parseFloat(watch('salePrice')) +
                          //         parseFloat(watch('tax'))
                          //       : parseFloat(watch('salePrice'))
                          //     : ''
                          // }
                          // readOnly={
                          //   watch('including_tax') == 1 ? false : true
                          // }
                        />
                      </div>
                    </div>
                  )}

                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.sellingPriceWithoutTax')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name="salePrice"
                        setValue={setValue}
                        type="text"
                        placeholder={t(
                          'Inventory.Products.sellingPriceWithoutTaxPlaceholder'
                        )}
                        errorMsg={errors?.salePrice?.message}
                        validateStatus={errors?.salePrice ? 'error' : ''}
                        control={control}
                        defaultValue={
                          watch('including_tax') == 1 &&
                          watch('salePriceWithTax') > 0 &&
                          (
                            (parseFloat(watch('salePriceWithTax')) * 100) /
                            (parseFloat(systemSettings?.invoiceSetting?.tax) +
                              100)
                          ).toFixed(3)
                        }
                        readOnly={
                          watch('including_tax') == 1 &&
                          watch('active_tax') == 1
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>

                  {watch('active_tax') == 1 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.salesTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          // disabled={true}
                          setValue={setValue}
                          className="form-text-field"
                          name="tax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.salesTaxPlaceholder'
                          )}
                          errorMsg={errors?.tax?.message}
                          validateStatus={errors?.tax ? 'error' : ''}
                          control={control}
                          defaultValue={
                            systemSettings?.invoiceSetting?.tax > 0 &&
                            watch('salePrice')
                              ? parseFloat(
                                  (systemSettings?.invoiceSetting?.tax *
                                    watch('salePrice')) /
                                    100
                                ).toFixed(3)
                              : ''
                          }
                          value={systemSettings?.invoiceSetting?.tax}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  )}
                  {watch('active_tax') == 1 && watch('including_tax') == 0 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.sellingPriceIncludesTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          setValue={setValue}
                          className="form-text-field"
                          name="salePriceWithTax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.sellingPriceIncludesTaxPlaceholder'
                          )}
                          errorMsg={errors?.salePriceWithTax?.message}
                          validateStatus={
                            errors?.salePriceWithTax ? 'error' : ''
                          }
                          control={control}
                          defaultValue={
                            parseFloat(watch('tax')) > 0 && watch('salePrice')
                              ? parseFloat(
                                  parseFloat(watch('salePrice')) +
                                    parseFloat(watch('tax'))
                                ).toFixed(3)
                              : parseFloat(watch('salePrice'))
                          }
                          readOnly={watch('including_tax') == 1 ? false : true}
                        />
                      </div>
                    </div>
                  )}
                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.wholePrice')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name="wholesale_price"
                        type="text"
                        placeholder={t(
                          'Inventory.Products.wholePricePlaceholder'
                        )}
                        errorMsg={errors?.wholesale_price?.message}
                        validateStatus={errors?.wholesale_price ? 'error' : ''}
                        control={control}
                      />
                    </div>
                  </div>
                </div>
                <div className="buying-wrap">
                  <div className="radios-wrap">
                    <p className="radios-group-title">
                      {t('Inventory.Products.doesTheProductAcceptDiscount')}
                    </p>
                    <div className="labels-wrap">
                      {[
                        {
                          title: t('Inventory.Products.yes'),
                          value: '1'
                        },
                        {
                          title: t('Inventory.Products.no'),
                          value: '2'
                        }
                      ].map((obj, i) => (
                        <label
                          key={i}
                          className={`radio-input-label ${
                            String(watch(`accept_discount`)) ===
                            String(obj.value)
                              ? 'selected'
                              : ''
                          }`}
                        >
                          <input
                            type="radio"
                            value={obj.value}
                            {...register(`accept_discount`)}
                          />
                          <span className="label-span">{obj.title}</span>
                          {watch(`accept_discount`) == String(obj.value) ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonEmpty />
                          )}
                        </label>
                      ))}
                    </div>

                    {errors?.accept_discount && (
                      <p className="error-p">
                        {errors.accept_discount.message}
                      </p>
                    )}
                  </div>

                  <div className="radios-wrap">
                    <p className="radios-group-title">
                      {t('Inventory.Products.isTheProductMadeUpOrNot')}
                    </p>
                    <div className="labels-wrap">
                      {[
                        {
                          title: t('Inventory.Products.yes'),
                          value: '1'
                        },
                        {
                          title: t('Inventory.Products.no'),
                          value: '2'
                        }
                      ].map((obj, i) => (
                        <label
                          key={i}
                          className={`radio-input-label ${
                            String(watch(`is_ingredient`)) === String(obj.value)
                              ? 'selected'
                              : ''
                          }`}
                        >
                          <input
                            type="radio"
                            value={obj.value}
                            {...register(`is_ingredient`)}
                          />
                          <span className="label-span">{obj.title}</span>
                          {watch(`is_ingredient`) == String(obj.value) ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonEmpty />
                          )}
                        </label>
                      ))}
                    </div>

                    {errors?.is_ingredient && (
                      <p className="error-p">{errors.is_ingredient.message}</p>
                    )}
                  </div>
                </div>
                <div
                  className="price-tax-wrap"
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 18
                  }}
                >
                  <div className="radios-wrap">
                    <p className="radios-group-title">
                      {t('Inventory.Products.productType')}
                    </p>
                    <div className="labels-wrap">
                      {[
                        {
                          title: t('Inventory.Products.electronicProduct'),
                          value: '1'
                        },
                        {
                          title: t('Inventory.Products.electronicCard'),
                          value: '2'
                        }
                      ].map((obj, i) => (
                        <label
                          key={i}
                          className={`radio-input-label ${
                            String(watch(`digital_type`)) === String(obj.value)
                              ? 'selected'
                              : ''
                          }`}
                        >
                          <input
                            type="radio"
                            value={obj.value}
                            {...register(`digital_type`)}
                          />
                          <span className="label-span">{obj.title}</span>
                          {watch(`digital_type`) == String(obj.value) ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonEmpty />
                          )}
                        </label>
                      ))}
                    </div>

                    {errors?.digital_type && (
                      <p className="error-p">{errors.digital_type.message}</p>
                    )}
                  </div>
                  {watch(`digital_type`) == 1 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.productLink')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          className="form-text-field"
                          name="digital_value"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.productLinkPlaceholder'
                          )}
                          errorMsg={errors?.digital_value?.message}
                          validateStatus={errors?.digital_value ? 'error' : ''}
                          control={control}
                        />
                      </div>
                    </div>
                  )}
                  {watch(`digital_type`) == 2 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.productCardNum')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          className="form-text-field"
                          name="digital_value"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.electronicCardPlaceholder'
                          )}
                          errorMsg={errors?.digital_value?.message}
                          validateStatus={errors?.digital_value ? 'error' : ''}
                          control={control}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="date-picker-wrap">
                  <p className="label-p">
                    <p className="radios-group-title">
                      {t('Inventory.Products.productExpirationDate')}
                    </p>
                  </p>
                  <DatePicker
                    name="end_date"
                    // format="YYYY-MM-DD"
                    size="large"
                    {...field}
                    control={control}
                    // defaultValue={selectedCash?.date && moment(selectedCash?.date)}
                    // bordered={false}
                    setValue={setValue}
                    placeholder={t(
                      'Inventory.Products.productExpirationDatePlaceholder'
                    )}
                    className="receipt-date-picker"
                    formClassName="create-receipt-form"
                  />
                  {/* {errors?.date && <p className="error-p">{errors.date.message}</p>} */}
                </div>
                {/* <CustomEditor
                  fieldName="product_description"
                  editorTitle={t('Inventory.Products.productDetails')}
                  setValue={setValue}
                  watch={watch}
                  setError={setError}
                  clearErrors={clearErrors}
                  errors={errors}
                  required={false}
                  errorMsg={t('Inventory.Products.productDetails')}
                  editorFieldState={productDescEditorState}
                  setEditorFieldState={setProductDescEditorState}
                  fetchedData={selectedProduct?.desc}
                /> */}
                <div className="text-field-label-wrap">
                  <p className="label-p">
                    {t('Inventory.Products.productDetails')}
                  </p>
                  <div className="text-field-wrap">
                    <AntdTextarea
                      className="form-text-area"
                      name="product_description"
                      rows={8}
                      placeholder={t('Inventory.Products.productDetails')}
                      errorMsg={errors?.product_description?.message}
                      validateStatus={
                        errors?.product_description ? 'error' : ''
                      }
                      control={control}
                    />
                  </div>
                </div>
                <div className="product-img-wrap">
                  <h3>{t('Inventory.Products.productImage')}</h3>

                  <MyDropZone
                    className="product-header-dropzone"
                    multipleFiles={false}
                    handleFilesDrop={(acceptedFiles) =>
                      handleFilesDrop(
                        acceptedFiles,
                        productFileToUpload,
                        setProductFileToUpload
                      )
                    }
                    filesToUpload={productFileToUpload}
                    formName="store-new-product-form"
                    id="avatar-input"
                    dropzoneText={t('Inventory.Products.productImage')}
                    inputName="product_image"
                    icon={<CameraFilled className="dropzone-camera" />}
                    dropZoneUrls={
                      selectedProduct?.image ? [selectedProduct.image] : null
                    }
                  >
                    {t('Inventory.Products.dragTheImageAndPlaceItHere')}
                  </MyDropZone>

                  <MyDropZonePreview
                    filesToUpload={productFileToUpload}
                    clearFileFromUpload={(f) =>
                      clearFileFromUpload(f, setProductFileToUpload)
                    }
                  />
                  {errors?.product_image?.message &&
                    !watch('product_image') && (
                      <p className="error-p">{errors.product_image.message}</p>
                    )}
                </div>
              </>
            )}
          </>
          {/* المنتج المجمع */}
          <>
            {watch('product_type') == 5 && (
              <>
                <div className="name-default-barcode-wrap">
                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.productName')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name="product_name"
                        type="text"
                        placeholder={t(
                          'Inventory.Products.productNamePlaceholder'
                        )}
                        errorMsg={errors?.product_name?.message}
                        validateStatus={errors?.product_name ? 'error' : ''}
                        control={control}
                      />
                    </div>
                  </div>
                  <div className="text-field-label-wrap">
                    <p
                      className="label-p"
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span>{t('Inventory.Products.productBarcode')}</span>
                      <b
                        style={{
                          border: 'none',
                          cursor: 'pointer',
                          color: '#4d71db'
                        }}
                        onClick={() => handleClick()}
                      >
                        {t('Inventory.Products.generateNewBarcode')}
                      </b>
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        setValue={setValue}
                        disabled={watch('isWeight') == '1'}
                        className="form-text-field"
                        name="defaultParCode"
                        type="text"
                        placeholder={t(
                          'Inventory.Products.productBarcodePlaceholder'
                        )}
                        errorMsg={errors?.defaultParCode?.message}
                        validateStatus={errors?.defaultParCode ? 'error' : ''}
                        control={control}
                        defaultValue={barCodeNum}
                      />
                    </div>
                  </div>
                  <div className="select-label-wrap">
                    <p
                      className="label-p"
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span>{t('Inventory.Products.mainProductCategory')}</span>
                      <b
                        style={{
                          border: 'none',
                          cursor: 'pointer',
                          color: '#4d71db'
                        }}
                        onClick={() => {
                          setCatModalOpened(true);
                        }}
                      >
                        {t('Inventory.Products.addNewCategory')}
                      </b>
                    </p>
                    <div className="custom-select-wrap without-icon">
                      <AntdSelectOption
                        name="product_category"
                        errorMsg={errors?.product_category?.message}
                        validateStatus={errors?.product_category ? 'error' : ''}
                        control={control}
                        setValue={setValue}
                        placeholder={t(
                          'Inventory.Products.mainProductCategory'
                        )}
                        options={
                          mainCategories?.length > 0
                            ? mainCategories.map((cat) => ({
                                title: cat.name,
                                value: String(cat.id)
                              }))
                            : null
                        }
                        showSearch={true}
                        filterOption={(input, option) =>
                          option.children.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        formClassName="store-new-product-form"
                      />
                    </div>
                  </div>
                  <div className="unit-isWeight-wrapper">
                    <div className="discount-units-wrap">
                      <div className="select-label-wrap">
                        <p
                          className="label-p"
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span>{t('Inventory.Products.measruingUnit')}</span>
                          <b
                            style={{
                              border: 'none',
                              cursor: 'pointer',
                              color: '#4d71db'
                            }}
                            onClick={() => {
                              setUnitModalOpened(true);
                            }}
                          ></b>
                        </p>
                        <div className="custom-select-wrap without-icon">
                          <AntdSelectOption
                            name="measruing_unit"
                            errorMsg={errors?.measruing_unit?.message}
                            validateStatus={
                              errors?.measruing_unit ? 'error' : ''
                            }
                            control={control}
                            setValue={setValue}
                            placeholder={t('Inventory.Products.measruingUnit')}
                            options={
                              allUnits?.length > 0
                                ? allUnits.map((unit) => ({
                                    title: unit.name,
                                    value: String(unit.id)
                                  }))
                                : null
                            }
                            showSearch={true}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            formClassName="store-new-product-form"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="select-label-wrap">
                    <p className="label-p">المستودع الرئيسى التابع له</p>
                    <div className="custom-select-wrap without-icon">
                      <AntdSelectOption
                        name='stock_id'
                        errorMsg={errors?.stock_id?.message}
                        validateStatus={errors?.stock_id ? 'error' : ''}
                        control={control}
                        setValue={setValue}
                        placeholder="المستودع الرئيسى التابع له"
                        options={
                          allWarehouses?.length > 0
                            ? allWarehouses.map((obj) => ({
                                title: obj.name,
                                value: String(obj.id)
                              }))
                            : null
                        }
                        formClassName="store-new-product-form"
                      />
                    </div>
                  </div> */}
                  {/* <div className="text-field-label-wrap">
                    <p className="label-p">مخزون أول مده</p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name='first_quantity'
                        type="text"
                        placeholder="مخزون أول مده..."
                        errorMsg={
                          errors?.ingredients &&
                          errors.first_quantity.message
                        }
                        validateStatus={
                          errors?.first_quantity &&
                          errors?.first_quantity
                            ? 'error'
                            : ''
                        }
                        control={control}
                        // defaultValue={watch(`ingredients.${index}.ingredient_quantity`)} // make sure to set up defaultValue
                      />
                    </div>
                  </div> */}
                </div>
                <div className="buying-wrap">
                  <div className="radios-wrap">
                    <p className="radios-group-title">
                      {t('Inventory.Products.activateTaxForProduct')}
                    </p>
                    <div className="labels-wrap">
                      {[
                        {
                          title: t('Inventory.Products.yes'),
                          value: '1'
                        },
                        {
                          title: t('Inventory.Products.no'),
                          value: '2'
                        }
                      ].map((obj, i) => (
                        <label
                          key={i}
                          className={`radio-input-label ${
                            String(watch(`active_tax`)) === String(obj.value)
                              ? 'selected'
                              : ''
                          }`}
                        >
                          <input
                            type="radio"
                            value={obj.value}
                            {...register(`active_tax`)}
                          />
                          <span className="label-span">{obj.title}</span>
                          {watch(`active_tax`) == String(obj.value) ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonEmpty />
                          )}
                        </label>
                      ))}
                    </div>

                    {errors?.active_tax && (
                      <p className="error-p">{errors.active_tax.message}</p>
                    )}
                  </div>
                  {watch('active_tax') == 1 && (
                    <div className="radios-wrap">
                      <p className="radios-group-title">
                        {t('Inventory.Products.doesproductIncludeTax')}
                      </p>
                      <div className="labels-wrap">
                        {[
                          {
                            title: t('Inventory.Products.yes'),
                            value: '1'
                          },
                          {
                            title: t('Inventory.Products.no'),
                            value: '0'
                          }
                        ].map((obj, i) => (
                          <label
                            key={i}
                            className={`radio-input-label ${
                              String(watch(`including_tax`)) ===
                              String(obj.value)
                                ? 'selected'
                                : ''
                            }`}
                          >
                            <input
                              type="radio"
                              value={obj.value}
                              {...register(`including_tax`)}
                            />
                            <span className="label-span">{obj.title}</span>
                            {watch(`including_tax`) == String(obj.value) ? (
                              <RadioButtonFilled />
                            ) : (
                              <RadioButtonEmpty />
                            )}
                          </label>
                        ))}
                      </div>

                      {errors?.including_tax && (
                        <p className="error-p">
                          {errors.including_tax.message}
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <div className="buying-wrap">
                  <div className="radios-wrap">
                    <p className="radios-group-title">
                      {t('Inventory.Products.doesTheProductAcceptDiscount')}
                    </p>
                    <div className="labels-wrap">
                      {[
                        {
                          title: t('Inventory.Products.yes'),
                          value: '1'
                        },
                        {
                          title: t('Inventory.Products.no'),
                          value: '2'
                        }
                      ].map((obj, i) => (
                        <label
                          key={i}
                          className={`radio-input-label ${
                            String(watch(`accept_discount`)) ===
                            String(obj.value)
                              ? 'selected'
                              : ''
                          }`}
                        >
                          <input
                            type="radio"
                            value={obj.value}
                            {...register(`accept_discount`)}
                          />
                          <span className="label-span">{obj.title}</span>
                          {watch(`accept_discount`) == String(obj.value) ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonEmpty />
                          )}
                        </label>
                      ))}
                    </div>

                    {errors?.accept_discount && (
                      <p className="error-p">
                        {errors.accept_discount.message}
                      </p>
                    )}
                  </div>

                  <div className="radios-wrap">
                    <p className="radios-group-title">
                      {t('Inventory.Products.isTheProductMadeUpOrNot')}
                    </p>
                    <div className="labels-wrap">
                      {[
                        {
                          title: t('Inventory.Products.yes'),
                          value: '1'
                        },
                        {
                          title: t('Inventory.Products.no'),
                          value: '2'
                        }
                      ].map((obj, i) => (
                        <label
                          key={i}
                          className={`radio-input-label ${
                            String(watch(`is_ingredient`)) === String(obj.value)
                              ? 'selected'
                              : ''
                          }`}
                        >
                          <input
                            type="radio"
                            value={obj.value}
                            {...register(`is_ingredient`)}
                          />
                          <span className="label-span">{obj.title}</span>
                          {watch(`is_ingredient`) == String(obj.value) ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonEmpty />
                          )}
                        </label>
                      ))}
                    </div>

                    {errors?.is_ingredient && (
                      <p className="error-p">{errors.is_ingredient.message}</p>
                    )}
                  </div>
                </div>
                <div
                  className="price-tax-wrap"
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: 18
                  }}
                >
                  {/* <div className="text-field-label-wrap">
                      <p className="label-p">{t('Inventory.Products.purchasePriceWithoutTax')}</p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          className="form-text-field"
                          name="product_buying_price"
                          type="text"
                          placeholder={t('Inventory.Products.purchasePrice')}
                          errorMsg={errors?.product_buying_price?.message}
                          validateStatus={errors?.product_buying_price ? 'error' : ''}
                          control={control}
                        />
                      </div>
                  </div>
                  <div className="text-field-label-wrap">
                    <p className="label-p">ضريبة الشراء</p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        // disabled={true}
                        setValue={setValue}
                        className="form-text-field"
                        name="buy_tax"
                        type="text"
                        placeholder="ضريبة الشراء..."
                        errorMsg={errors?.buy_tax?.message}
                        validateStatus={errors?.buy_tax ? 'error' : ''}
                        control={control}
                        defaultValue={parseFloat((systemSettings?.invoiceSetting?.buy_tax * watch('product_buying_price')) / 100)}
                        value={systemSettings?.invoiceSetting?.buy_tax}
                        readOnly={true}
                      />
                    </div>
                  </div> */}
                  {watch('active_tax') == 1 && watch('including_tax') == 1 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.sellingPriceIncludesTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          setValue={setValue}
                          className="form-text-field"
                          name="salePriceWithTax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.sellingPriceIncludesTaxPlaceholder'
                          )}
                          errorMsg={errors?.salePriceWithTax?.message}
                          validateStatus={
                            errors?.salePriceWithTax ? 'error' : ''
                          }
                          control={control}
                          // defaultValue={
                          //   watch('active_tax') == 1
                          //     ? parseFloat(watch('tax')) > 0
                          //       ? parseFloat(watch('salePrice')) +
                          //         parseFloat(watch('tax'))
                          //       : parseFloat(watch('salePrice'))
                          //     : ''
                          // }
                          // readOnly={
                          //   watch('including_tax') == 1 ? false : true
                          // }
                        />
                      </div>
                    </div>
                  )}
                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.sellingPriceWithoutTax')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name="salePrice"
                        setValue={setValue}
                        type="text"
                        placeholder={t(
                          'Inventory.Products.sellingPriceWithoutTaxPlaceholder'
                        )}
                        errorMsg={errors?.salePrice?.message}
                        validateStatus={errors?.salePrice ? 'error' : ''}
                        control={control}
                        defaultValue={
                          watch('including_tax') == 1 &&
                          watch('salePriceWithTax') > 0 &&
                          (
                            (parseFloat(watch('salePriceWithTax')) * 100) /
                            (parseFloat(systemSettings?.invoiceSetting?.tax) +
                              100)
                          ).toFixed(3)
                        }
                        readOnly={
                          watch('including_tax') == 1 &&
                          watch('active_tax') == 1
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                  {watch('active_tax') == 1 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.salesTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          // disabled={true}
                          setValue={setValue}
                          className="form-text-field"
                          name="tax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.salesTaxPlaceholder'
                          )}
                          errorMsg={errors?.tax?.message}
                          validateStatus={errors?.tax ? 'error' : ''}
                          control={control}
                          // defaultValue={(parseFloat(
                          //   (systemSettings?.invoiceSetting?.tax *
                          //     watch('salePrice')) /
                          //     100) || systemSettings?.invoiceSetting?.tax
                          // )}
                          defaultValue={
                            systemSettings?.invoiceSetting?.tax > 0 &&
                            watch('salePrice')
                              ? parseFloat(
                                  (systemSettings?.invoiceSetting?.tax *
                                    watch('salePrice')) /
                                    100
                                ).toFixed(3)
                              : ''
                          }
                          value={systemSettings?.invoiceSetting?.tax}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  )}
                  {watch('active_tax') == 1 && watch('including_tax') == 0 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.sellingPriceIncludesTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          setValue={setValue}
                          className="form-text-field"
                          name="salePriceWithTax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.sellingPriceIncludesTaxPlaceholder'
                          )}
                          errorMsg={errors?.salePriceWithTax?.message}
                          validateStatus={
                            errors?.salePriceWithTax ? 'error' : ''
                          }
                          control={control}
                          defaultValue={
                            parseFloat(watch('tax')) > 0 && watch('salePrice')
                              ? parseFloat(
                                  parseFloat(watch('salePrice')) +
                                    parseFloat(watch('tax'))
                                ).toFixed(3)
                              : parseFloat(watch('salePrice'))
                          }
                          readOnly={watch('including_tax') == 1 ? false : true}
                        />
                      </div>
                    </div>
                  )}
                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.wholePrice')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name="wholesale_price"
                        type="text"
                        placeholder={t(
                          'Inventory.Products.wholePricePlaceholder'
                        )}
                        errorMsg={errors?.wholesale_price?.message}
                        validateStatus={errors?.wholesale_price ? 'error' : ''}
                        control={control}
                      />
                    </div>
                  </div>
                </div>

                <div className="date-picker-wrap">
                  <p className="label-p">
                    {t('Inventory.Products.productExpirationDate')}
                  </p>
                  <DatePicker
                    name="end_date"
                    // format="YYYY-MM-DD"
                    size="large"
                    {...field}
                    control={control}
                    // defaultValue={selectedCash?.date && moment(selectedCash?.date)}
                    // bordered={false}
                    setValue={setValue}
                    placeholder={t(
                      'Inventory.Products.productExpirationDatePlaceholder'
                    )}
                    className="receipt-date-picker"
                    formClassName="create-receipt-form"
                  />
                  {/* {errors?.date && <p className="error-p">{errors.date.message}</p>} */}
                </div>
                {/* sub products fields */}
                <div className="product-desc-feilds">
                  <h2>{t('Inventory.Products.Products')}</h2>
                  {renderProductsFields()}
                  <div
                    className="add-new-field-btn"
                    onClick={() =>
                      appendProductsComplexField({
                        unique_id: '',
                        salePrice: '',
                        quantity: ''
                        // new: true
                      })
                    }
                  >
                    <span>{t('Inventory.Products.addNewProduct')}</span>
                  </div>
                </div>

                {/* <CustomEditor
                  fieldName="product_description"
                  editorTitle={t('Inventory.Products.productDetails')}
                  setValue={setValue}
                  watch={watch}
                  setError={setError}
                  clearErrors={clearErrors}
                  errors={errors}
                  required={false}
                  errorMsg={t('Inventory.Products.productDetails')}
                  editorFieldState={productDescEditorState}
                  setEditorFieldState={setProductDescEditorState}
                  fetchedData={selectedProduct?.desc}
                /> */}
                <div className="text-field-label-wrap">
                  <p className="label-p">
                    {t('Inventory.Products.productDetails')}
                  </p>
                  <div className="text-field-wrap">
                    <AntdTextarea
                      className="form-text-area"
                      name="product_description"
                      rows={8}
                      placeholder={t('Inventory.Products.productDetails')}
                      errorMsg={errors?.product_description?.message}
                      validateStatus={
                        errors?.product_description ? 'error' : ''
                      }
                      control={control}
                    />
                  </div>
                </div>
                <div className="product-img-wrap">
                  <h3>{t('Inventory.Products.productImage')}</h3>

                  <MyDropZone
                    className="product-header-dropzone"
                    multipleFiles={false}
                    handleFilesDrop={(acceptedFiles) =>
                      handleFilesDrop(
                        acceptedFiles,
                        productFileToUpload,
                        setProductFileToUpload
                      )
                    }
                    filesToUpload={productFileToUpload}
                    formName="store-new-product-form"
                    id="avatar-input"
                    dropzoneText={t('Inventory.Products.productImage')}
                    inputName="product_image"
                    icon={<CameraFilled className="dropzone-camera" />}
                    dropZoneUrls={
                      selectedProduct?.image ? [selectedProduct.image] : null
                    }
                  >
                    {t('Inventory.Products.dragTheImageAndPlaceItHere')}
                  </MyDropZone>

                  <MyDropZonePreview
                    filesToUpload={productFileToUpload}
                    clearFileFromUpload={(f) =>
                      clearFileFromUpload(f, setProductFileToUpload)
                    }
                  />
                  {errors?.product_image?.message &&
                    !watch('product_image') && (
                      <p className="error-p">{errors.product_image.message}</p>
                    )}
                </div>
              </>
            )}
          </>
          {/* المنتج المتعدد */}
          <>
            {watch('product_type') == 6 && (
              <>
                {!selectedProduct && (
                  <>
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.productName')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          className="form-text-field"
                          name="product_name"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.productNamePlaceholder'
                          )}
                          errorMsg={errors?.product_name?.message}
                          validateStatus={errors?.product_name ? 'error' : ''}
                          control={control}
                        />
                      </div>
                    </div>
                    <div className="name-default-barcode-wrap">
                      <div className="select-label-wrap">
                        <p
                          className="label-p"
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span>
                            {t('Inventory.Products.mainProductCategory')}
                          </span>
                          <b
                            style={{
                              border: 'none',
                              cursor: 'pointer',
                              color: '#4d71db'
                            }}
                            onClick={() => {
                              setCatModalOpened(true);
                            }}
                          >
                            {t('Inventory.Products.addNewCategory')}
                          </b>
                        </p>
                        <div className="custom-select-wrap without-icon">
                          <AntdSelectOption
                            name="product_category"
                            errorMsg={errors?.product_category?.message}
                            validateStatus={
                              errors?.product_category ? 'error' : ''
                            }
                            control={control}
                            setValue={setValue}
                            placeholder={t(
                              'Inventory.Products.mainProductCategory'
                            )}
                            options={
                              mainCategories?.length > 0
                                ? mainCategories.map((cat) => ({
                                    title: cat.name,
                                    value: String(cat.id)
                                  }))
                                : null
                            }
                            showSearch={true}
                            filterOption={(input, option) =>
                              option.children.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            formClassName="store-new-product-form"
                          />
                        </div>
                      </div>
                      <div className="unit-isWeight-wrapper">
                        <div className="discount-units-wrap">
                          <div className="select-label-wrap">
                            <p
                              className="label-p"
                              style={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <span>
                                {t('Inventory.Products.measruingUnit')}
                              </span>
                              <b
                                style={{
                                  border: 'none',
                                  cursor: 'pointer',
                                  color: '#4d71db'
                                }}
                                onClick={() => {
                                  setUnitModalOpened(true);
                                }}
                              >
                                {t('Inventory.Products.addNewMeasruingUnit')}
                              </b>
                            </p>
                            <div className="custom-select-wrap without-icon">
                              <AntdSelectOption
                                name="measruing_unit"
                                errorMsg={errors?.measruing_unit?.message}
                                validateStatus={
                                  errors?.measruing_unit ? 'error' : ''
                                }
                                control={control}
                                setValue={setValue}
                                placeholder={t(
                                  'Inventory.Products.measruingUnit'
                                )}
                                options={
                                  allUnits?.length > 0
                                    ? allUnits.map((unit) => ({
                                        title: unit.name,
                                        value: String(unit.id)
                                      }))
                                    : null
                                }
                                showSearch={true}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                  option.children.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                formClassName="store-new-product-form"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="buying-wrap">
                      <div className="radios-wrap">
                        <p
                          className="radios-group-title"
                          //  style={{ marginBottom: '18px' }}
                        >
                          {t('Inventory.Products.doesTheProductAcceptDiscount')}
                        </p>
                        <div className="labels-wrap">
                          {[
                            {
                              title: t('Inventory.Products.yes'),
                              value: '1'
                            },
                            {
                              title: t('Inventory.Products.no'),
                              value: '2'
                            }
                          ].map((obj, i) => (
                            <label
                              key={i}
                              className={`radio-input-label ${
                                String(watch(`accept_discount`)) ===
                                String(obj.value)
                                  ? 'selected'
                                  : ''
                              }`}
                            >
                              <input
                                type="radio"
                                value={obj.value}
                                {...register(`accept_discount`)}
                              />
                              <span className="label-span">{obj.title}</span>
                              {watch(`accept_discount`) == String(obj.value) ? (
                                <RadioButtonFilled />
                              ) : (
                                <RadioButtonEmpty />
                              )}
                            </label>
                          ))}
                        </div>

                        {errors?.accept_discount && (
                          <p className="error-p">
                            {errors.accept_discount.message}
                          </p>
                        )}
                      </div>
                      <div className="radios-wrap">
                        <p
                          className="radios-group-title"
                          //  style={{ marginBottom: '18px' }}
                        >
                          {t('Inventory.Products.activateTaxForProduct')}
                        </p>
                        <div className="labels-wrap">
                          {[
                            {
                              title: t('Inventory.Products.yes'),
                              value: '1'
                            },
                            {
                              title: t('Inventory.Products.no'),
                              value: '2'
                            }
                          ].map((obj, i) => (
                            <label
                              key={i}
                              className={`radio-input-label ${
                                String(watch(`active_tax`)) ===
                                String(obj.value)
                                  ? 'selected'
                                  : ''
                              }`}
                            >
                              <input
                                type="radio"
                                value={obj.value}
                                {...register(`active_tax`)}
                              />
                              <span className="label-span">{obj.title}</span>
                              {watch(`active_tax`) == String(obj.value) ? (
                                <RadioButtonFilled />
                              ) : (
                                <RadioButtonEmpty />
                              )}
                            </label>
                          ))}
                        </div>

                        {errors?.active_tax && (
                          <p className="error-p">{errors.active_tax.message}</p>
                        )}
                      </div>
                      {watch('active_tax') == 1 && (
                        <div className="radios-wrap">
                          <p className="radios-group-title">
                            {t('Inventory.Products.doesproductIncludeTax')}
                          </p>
                          <div className="labels-wrap">
                            {[
                              {
                                title: t('Inventory.Products.yes'),
                                value: '1'
                              },
                              {
                                title: t('Inventory.Products.no'),
                                value: '0'
                              }
                            ].map((obj, i) => (
                              <label
                                key={i}
                                className={`radio-input-label ${
                                  String(watch(`including_tax`)) ===
                                  String(obj.value)
                                    ? 'selected'
                                    : ''
                                }`}
                              >
                                <input
                                  type="radio"
                                  value={obj.value}
                                  {...register(`including_tax`)}
                                />
                                <span className="label-span">{obj.title}</span>
                                {watch(`including_tax`) == String(obj.value) ? (
                                  <RadioButtonFilled />
                                ) : (
                                  <RadioButtonEmpty />
                                )}
                              </label>
                            ))}
                          </div>

                          {errors?.including_tax && (
                            <p className="error-p">
                              {errors.including_tax.message}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="date-picker-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.productExpirationDate')}
                      </p>
                      <DatePicker
                        name="end_date"
                        // format="YYYY-MM-DD"
                        size="large"
                        {...field}
                        control={control}
                        // defaultValue={selectedCash?.date && moment(selectedCash?.date)}
                        // bordered={false}
                        setValue={setValue}
                        placeholder={t(
                          'Inventory.Products.productExpirationDate'
                        )}
                        className="receipt-date-picker"
                        formClassName="create-receipt-form"
                      />
                      {/* {errors?.date && <p className="error-p">{errors.date.message}</p>} */}
                    </div>
                    {/* <CustomEditor
                      fieldName="product_description"
                      editorTitle={t('Inventory.Products.productDetails')}
                      setValue={setValue}
                      watch={watch}
                      setError={setError}
                      clearErrors={clearErrors}
                      errors={errors}
                      required={false}
                      errorMsg={t('Inventory.Products.productDetails')}
                      editorFieldState={productDescEditorState}
                      setEditorFieldState={setProductDescEditorState}
                      fetchedData={selectedProduct?.desc}
                    /> */}
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.productDetails')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextarea
                          className="form-text-area"
                          name="product_description"
                          rows={8}
                          placeholder={t('Inventory.Products.productDetails')}
                          errorMsg={errors?.product_description?.message}
                          validateStatus={
                            errors?.product_description ? 'error' : ''
                          }
                          control={control}
                        />
                      </div>
                    </div>
                    {/* sub products fields */}
                    <div className={!selectedProduct && 'product-desc-feilds'}>
                      {<h2>{t('Inventory.Products.detailsOfsubUnits')}</h2>}
                      {renderMultiFields()}
                      <div
                        className="add-new-field-btn"
                        onClick={() => {
                          appendMultiProductsField({
                            saleMultiPrice: '',
                            tax: '',
                            salePriceWithTax: '',
                            wholesalePrice: '',
                            first_quantity: '',
                            stock_id: '',
                            defaultParCode: ''
                            // image: ''
                          });
                        }}
                      >
                        <span>{t('Inventory.Products.addNewUnit')}</span>
                      </div>
                    </div>
                  </>
                )}
                {selectedProduct && (
                  <>
                    <div className="name-default-barcode-wrap">
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Inventory.Products.productName')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            name="product_name"
                            type="text"
                            placeholder={t(
                              'Inventory.Products.productNamePlaceholder'
                            )}
                            errorMsg={errors?.product_name?.message}
                            validateStatus={errors?.product_name ? 'error' : ''}
                            control={control}
                          />
                        </div>
                      </div>

                      {watch('active_tax') == 1 &&
                        watch('including_tax') == 1 && (
                          <div className="text-field-label-wrap">
                            <p className="label-p">
                              {t('Inventory.Products.sellingPriceIncludesTax')}
                            </p>
                            <div className="text-field-wrap">
                              <AntdTextField
                                setValue={setValue}
                                className="form-text-field"
                                name="salePriceWithTax"
                                type="text"
                                placeholder={t(
                                  'Inventory.Products.sellingPriceIncludesTaxPlaceholder'
                                )}
                                errorMsg={errors?.salePriceWithTax?.message}
                                validateStatus={
                                  errors?.salePriceWithTax ? 'error' : ''
                                }
                                control={control}
                                // defaultValue={
                                //   watch('active_tax') == 1
                                //     ? parseFloat(watch('tax')) > 0
                                //       ? parseFloat(watch('salePrice')) +
                                //         parseFloat(watch('tax'))
                                //       : parseFloat(watch('salePrice'))
                                //     : ''
                                // }
                                // readOnly={
                                //   watch('including_tax') == 1 ? false : true
                                // }
                              />
                            </div>
                          </div>
                        )}

                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Inventory.Products.sellingPriceWithoutTax')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            name="salePrice"
                            setValue={setValue}
                            type="text"
                            placeholder={t(
                              'Inventory.Products.sellingPriceWithoutTaxPlaceholder'
                            )}
                            errorMsg={errors?.salePrice?.message}
                            validateStatus={errors?.salePrice ? 'error' : ''}
                            control={control}
                            defaultValue={
                              watch('including_tax') == 1 &&
                              watch('salePriceWithTax') > 0 &&
                              (
                                (parseFloat(watch('salePriceWithTax')) * 100) /
                                (parseFloat(
                                  systemSettings?.invoiceSetting?.tax
                                ) +
                                  100)
                              ).toFixed(3)
                            }
                            readOnly={
                              watch('including_tax') == 1 &&
                              watch('active_tax') == 1
                                ? true
                                : false
                            }
                          />
                        </div>
                      </div>
                      {watch('active_tax') == 1 && (
                        <div className="text-field-label-wrap">
                          <p className="label-p">
                            {t('Inventory.Products.salesTax')}
                          </p>
                          <div className="text-field-wrap">
                            <AntdTextField
                              // disabled={true}
                              setValue={setValue}
                              className="form-text-field"
                              name="tax"
                              type="text"
                              placeholder={t(
                                'Inventory.Products.salesTaxPlaceholder'
                              )}
                              errorMsg={errors?.tax?.message}
                              validateStatus={errors?.tax ? 'error' : ''}
                              control={control}
                              defaultValue={
                                systemSettings?.invoiceSetting?.tax > 0 &&
                                watch('salePrice')
                                  ? parseFloat(
                                      (systemSettings?.invoiceSetting?.tax *
                                        watch('salePrice')) /
                                        100
                                    ).toFixed(3)
                                  : ''
                              }
                              value={systemSettings?.invoiceSetting?.tax}
                              readOnly={true}
                            />
                          </div>
                        </div>
                      )}
                      {watch('active_tax') == 1 &&
                        watch('including_tax') == 0 && (
                          <div className="text-field-label-wrap">
                            <p className="label-p">
                              {t('Inventory.Products.sellingPriceIncludesTax')}
                            </p>
                            <div className="text-field-wrap">
                              <AntdTextField
                                setValue={setValue}
                                className="form-text-field"
                                name="salePriceWithTax"
                                type="text"
                                placeholder={t(
                                  'Inventory.Products.sellingPriceIncludesTaxPlaceholder'
                                )}
                                errorMsg={errors?.salePriceWithTax?.message}
                                validateStatus={
                                  errors?.salePriceWithTax ? 'error' : ''
                                }
                                control={control}
                                defaultValue={
                                  parseFloat(watch('tax')) > 0 &&
                                  watch('salePrice')
                                    ? parseFloat(
                                        parseFloat(watch('salePrice')) +
                                          parseFloat(watch('tax'))
                                      ).toFixed(3)
                                    : parseFloat(watch('salePrice'))
                                }
                                readOnly={
                                  watch('including_tax') == 1 ? false : true
                                }
                              />
                            </div>
                          </div>
                        )}
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Inventory.Products.wholePrice')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            name="wholesale_price"
                            type="text"
                            placeholder={t(
                              'Inventory.Products.wholePricePlaceholder'
                            )}
                            errorMsg={errors?.wholesale_price?.message}
                            validateStatus={
                              errors?.wholesale_price ? 'error' : ''
                            }
                            control={control}
                          />
                        </div>
                      </div>
                      <div className="select-label-wrap">
                        <p className="label-p">
                          {t('Inventory.Products.itsMainWarehouse')}
                        </p>
                        <div className="custom-select-wrap without-icon">
                          <AntdSelectOption
                            name="stock_id"
                            errorMsg={errors?.stock_id?.message}
                            validateStatus={errors?.stock_id ? 'error' : ''}
                            control={control}
                            setValue={setValue}
                            placeholder={t(
                              'Inventory.Products.itsMainWarehouse'
                            )}
                            options={
                              allWarehouses?.length > 0
                                ? allWarehouses.map((obj) => ({
                                    title: obj.name,
                                    value: String(obj.id)
                                  }))
                                : null
                            }
                            showSearch={true}
                            filterOption={(input, option) =>
                              option.children.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            formClassName="store-new-product-form"
                          />
                        </div>
                      </div>
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Inventory.Products.firstTermStock')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            name="first_quantity"
                            type="text"
                            placeholder={t(
                              'Inventory.Products.firstTermStockPlaceholder'
                            )}
                            errorMsg={
                              errors?.first_quantity &&
                              errors.first_quantity.message
                            }
                            validateStatus={
                              errors?.first_quantity && errors?.first_quantity
                                ? 'error'
                                : ''
                            }
                            control={control}
                            // defaultValue={watch(`ingredients.${index}.ingredient_quantity`)} // make sure to set up defaultValue
                          />
                        </div>
                      </div>
                    </div>
                    <div className="product-img-wrap">
                      <h3>{t('Inventory.Products.productImage')}</h3>

                      <MyDropZone
                        className="product-header-dropzone"
                        multipleFiles={false}
                        handleFilesDrop={(acceptedFiles) =>
                          handleFilesDrop(
                            acceptedFiles,
                            productFileToUpload,
                            setProductFileToUpload
                          )
                        }
                        filesToUpload={productFileToUpload}
                        formName="store-new-product-form"
                        id="avatar-input"
                        dropzoneText={t('Inventory.Products.productImage')}
                        inputName="product_image"
                        icon={<CameraFilled className="dropzone-camera" />}
                        dropZoneUrls={
                          selectedProduct?.image
                            ? [selectedProduct.image]
                            : null
                        }
                      >
                        {t('Inventory.Products.dragTheImageAndPlaceItHere')}
                      </MyDropZone>

                      <MyDropZonePreview
                        filesToUpload={productFileToUpload}
                        clearFileFromUpload={(f) =>
                          clearFileFromUpload(f, setProductFileToUpload)
                        }
                      />
                      {errors?.product_image?.message &&
                        !watch('product_image') && (
                          <p className="error-p">
                            {errors.product_image.message}
                          </p>
                        )}
                    </div>
                  </>
                )}
              </>
            )}
          </>
          {/* مكون منتج */}
          <>
            {watch('product_type') == 7 && (
              <>
                <div className="name-default-barcode-wrap">
                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.productName')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name="product_name"
                        type="text"
                        placeholder={t(
                          'Inventory.Products.productNamePlaceholder'
                        )}
                        errorMsg={errors?.product_name?.message}
                        validateStatus={errors?.product_name ? 'error' : ''}
                        control={control}
                      />
                    </div>
                  </div>
                  <div className="text-field-label-wrap">
                    <p
                      className="label-p"
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span>{t('Inventory.Products.productBarcode')}</span>
                      <b
                        style={{
                          border: 'none',
                          cursor: 'pointer',
                          color: '#4d71db'
                        }}
                        onClick={() => handleClick()}
                      >
                        {t('Inventory.Products.generateNewBarcode')}
                      </b>
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        setValue={setValue}
                        disabled={watch('isWeight') == '1'}
                        className="form-text-field"
                        name="defaultParCode"
                        type="text"
                        placeholder={t(
                          'Inventory.Products.productBarcodePlaceholder'
                        )}
                        errorMsg={errors?.defaultParCode?.message}
                        validateStatus={errors?.defaultParCode ? 'error' : ''}
                        control={control}
                        defaultValue={barCodeNum}
                      />
                    </div>
                  </div>
                  <div className="select-label-wrap">
                    <p
                      className="label-p"
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <span>{t('Inventory.Products.mainProductCategory')}</span>
                      <b
                        style={{
                          border: 'none',
                          cursor: 'pointer',
                          color: '#4d71db'
                        }}
                        onClick={() => {
                          setCatModalOpened(true);
                        }}
                      >
                        {t('Inventory.Products.addNewCategory')}
                      </b>
                    </p>
                    <div className="custom-select-wrap without-icon">
                      <AntdSelectOption
                        name="product_category"
                        errorMsg={errors?.product_category?.message}
                        validateStatus={errors?.product_category ? 'error' : ''}
                        control={control}
                        setValue={setValue}
                        placeholder={t(
                          'Inventory.Products.mainProductCategory'
                        )}
                        options={
                          mainCategories?.length > 0
                            ? mainCategories.map((cat) => ({
                                title: cat.name,
                                value: String(cat.id)
                              }))
                            : null
                        }
                        showSearch={true}
                        filterOption={(input, option) =>
                          option.children.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        formClassName="store-new-product-form"
                      />
                    </div>
                  </div>
                  <div className="unit-isWeight-wrapper">
                    <div className="discount-units-wrap">
                      <div className="select-label-wrap">
                        <p
                          className="label-p"
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span>{t('Inventory.Products.measruingUnit')}</span>
                          <b
                            style={{
                              border: 'none',
                              cursor: 'pointer',
                              color: '#4d71db'
                            }}
                            onClick={() => {
                              setUnitModalOpened(true);
                            }}
                          >
                            {t('Inventory.Products.addNewMeasruingUnit')}
                          </b>
                        </p>
                        <div className="custom-select-wrap without-icon">
                          <AntdSelectOption
                            name="measruing_unit"
                            errorMsg={errors?.measruing_unit?.message}
                            validateStatus={
                              errors?.measruing_unit ? 'error' : ''
                            }
                            control={control}
                            setValue={setValue}
                            placeholder={t('Inventory.Products.measruingUnit')}
                            options={
                              allUnits?.length > 0
                                ? allUnits.map((unit) => ({
                                    title: unit.name,
                                    value: String(unit.id)
                                  }))
                                : null
                            }
                            showSearch={true}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            formClassName="store-new-product-form"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="buying-wrap">
                    <div className="radios-wrap">
                      <p className="radios-group-title">
                        {t('Inventory.Products.activateTaxForProduct')}
                      </p>
                      <div className="labels-wrap">
                        {[
                          {
                            title: t('Inventory.Products.yes'),
                            value: '1'
                          },
                          {
                            title: t('Inventory.Products.no'),
                            value: '2'
                          }
                        ].map((obj, i) => (
                          <label
                            key={i}
                            className={`radio-input-label ${
                              String(watch(`active_tax`)) === String(obj.value)
                                ? 'selected'
                                : ''
                            }`}
                          >
                            <input
                              type="radio"
                              value={obj.value}
                              {...register(`active_tax`)}
                            />
                            <span className="label-span">{obj.title}</span>
                            {watch(`active_tax`) == String(obj.value) ? (
                              <RadioButtonFilled />
                            ) : (
                              <RadioButtonEmpty />
                            )}
                          </label>
                        ))}
                      </div>

                      {errors?.active_tax && (
                        <p className="error-p">{errors.active_tax.message}</p>
                      )}
                    </div>
                    {watch('active_tax') == 1 && (
                      <div className="radios-wrap">
                        <p className="radios-group-title">
                          {t('Inventory.Products.doesproductIncludeTax')}
                        </p>
                        <div className="labels-wrap">
                          {[
                            {
                              title: t('Inventory.Products.yes'),
                              value: '1'
                            },
                            {
                              title: t('Inventory.Products.no'),
                              value: '0'
                            }
                          ].map((obj, i) => (
                            <label
                              key={i}
                              className={`radio-input-label ${
                                String(watch(`including_tax`)) ===
                                String(obj.value)
                                  ? 'selected'
                                  : ''
                              }`}
                            >
                              <input
                                type="radio"
                                value={obj.value}
                                {...register(`including_tax`)}
                              />
                              <span className="label-span">{obj.title}</span>
                              {watch(`including_tax`) == String(obj.value) ? (
                                <RadioButtonFilled />
                              ) : (
                                <RadioButtonEmpty />
                              )}
                            </label>
                          ))}
                        </div>

                        {errors?.including_tax && (
                          <p className="error-p">
                            {errors.including_tax.message}
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  {/* <div className="select-label-wrap">
                    <p className="label-p">المستودع الرئيسى التابع له</p>
                    <div className="custom-select-wrap without-icon">
                      <AntdSelectOption
                        name='stock_id'
                        errorMsg={errors?.stock_id?.message}
                        validateStatus={errors?.stock_id ? 'error' : ''}
                        control={control}
                        setValue={setValue}
                        placeholder="المستودع الرئيسى التابع له"
                        options={
                          allWarehouses?.length > 0
                            ? allWarehouses.map((obj) => ({
                                title: obj.name,
                                value: String(obj.id)
                              }))
                            : null
                        }
                        formClassName="store-new-product-form"
                      />
                    </div>
                  </div> */}
                  {/* <div className="text-field-label-wrap">
                    <p className="label-p">مخزون أول مده</p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name='first_quantity'
                        type="text"
                        placeholder="مخزون أول مده..."
                        errorMsg={
                          errors?.ingredients &&
                          errors.first_quantity.message
                        }
                        validateStatus={
                          errors?.first_quantity &&
                          errors?.first_quantity ? 'error' : ''
                        }
                        control={control}
                      />
                    </div>
                  </div> */}
                </div>
                <div
                  className="price-tax-wrap"
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 18
                  }}
                >
                  {/* <div className="text-field-label-wrap">
                      <p className="label-p">{t('Inventory.Products.purchasePriceWithoutTax')}</p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          className="form-text-field"
                          name="product_buying_price"
                          type="text"
                          placeholder={t('Inventory.Products.purchasePrice')}
                          errorMsg={errors?.product_buying_price?.message}
                          validateStatus={errors?.product_buying_price ? 'error' : ''}
                          control={control}
                        />
                      </div>
                  </div> */}
                  {/* <div className="text-field-label-wrap">
                    <p className="label-p">ضريبة الشراء</p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        // disabled={true}
                        setValue={setValue}
                        className="form-text-field"
                        name="buy_tax"
                        type="text"
                        placeholder="ضريبة الشراء..."
                        errorMsg={errors?.buy_tax?.message}
                        validateStatus={errors?.buy_tax ? 'error' : ''}
                        control={control}
                        defaultValue={parseFloat((systemSettings?.invoiceSetting?.buy_tax * watch('product_buying_price')) / 100)}
                        value={systemSettings?.invoiceSetting?.buy_tax}
                        readOnly={true}
                      />
                    </div>
                  </div> */}

                  {watch('active_tax') == 1 && watch('including_tax') == 1 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.sellingPriceIncludesTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          setValue={setValue}
                          className="form-text-field"
                          name="salePriceWithTax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.sellingPriceIncludesTaxPlaceholder'
                          )}
                          errorMsg={errors?.salePriceWithTax?.message}
                          validateStatus={
                            errors?.salePriceWithTax ? 'error' : ''
                          }
                          control={control}
                          // defaultValue={
                          //   watch('active_tax') == 1
                          //     ? parseFloat(watch('tax')) > 0
                          //       ? parseFloat(watch('salePrice')) +
                          //         parseFloat(watch('tax'))
                          //       : parseFloat(watch('salePrice'))
                          //     : ''
                          // }
                          // readOnly={
                          //   watch('including_tax') == 1 ? false : true
                          // }
                        />
                      </div>
                    </div>
                  )}

                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.sellingPriceWithoutTax')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name="salePrice"
                        setValue={setValue}
                        type="text"
                        placeholder={t(
                          'Inventory.Products.sellingPriceWithoutTaxPlaceholder'
                        )}
                        errorMsg={errors?.salePrice?.message}
                        validateStatus={errors?.salePrice ? 'error' : ''}
                        control={control}
                        defaultValue={
                          watch('including_tax') == 1 &&
                          watch('salePriceWithTax') > 0 &&
                          true &&
                          (
                            (parseFloat(watch('salePriceWithTax')) * 100) /
                            (parseFloat(systemSettings?.invoiceSetting?.tax) +
                              100)
                          ).toFixed(3)
                        }
                        readOnly={
                          watch('including_tax') == 1 &&
                          watch('active_tax') == 1
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                  {watch('active_tax') == 1 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.salesTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          // disabled={true}
                          setValue={setValue}
                          className="form-text-field"
                          name="tax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.salesTaxPlaceholder'
                          )}
                          errorMsg={errors?.tax?.message}
                          validateStatus={errors?.tax ? 'error' : ''}
                          control={control}
                          defaultValue={
                            systemSettings?.invoiceSetting?.tax > 0 &&
                            watch('salePrice')
                              ? parseFloat(
                                  (systemSettings?.invoiceSetting?.tax *
                                    watch('salePrice')) /
                                    100
                                ).toFixed(3)
                              : ''
                          }
                          value={systemSettings?.invoiceSetting?.tax}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  )}
                  {watch('active_tax') == 1 && watch('including_tax') == 0 && (
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.sellingPriceIncludesTax')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          setValue={setValue}
                          className="form-text-field"
                          name="salePriceWithTax"
                          type="text"
                          placeholder={t(
                            'Inventory.Products.sellingPriceIncludesTaxPlaceholder'
                          )}
                          errorMsg={errors?.salePriceWithTax?.message}
                          validateStatus={
                            errors?.salePriceWithTax ? 'error' : ''
                          }
                          control={control}
                          defaultValue={
                            parseFloat(watch('tax')) > 0 && watch('salePrice')
                              ? parseFloat(
                                  parseFloat(watch('salePrice')) +
                                    parseFloat(watch('tax'))
                                ).toFixed(3)
                              : parseFloat(watch('salePrice'))
                          }
                          readOnly={watch('including_tax') == 1 ? false : true}
                        />
                      </div>
                    </div>
                  )}
                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('Inventory.Products.wholePrice')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        name="wholesale_price"
                        type="text"
                        placeholder={t(
                          'Inventory.Products.wholePricePlaceholder'
                        )}
                        errorMsg={errors?.wholesale_price?.message}
                        validateStatus={errors?.wholesale_price ? 'error' : ''}
                        control={control}
                      />
                    </div>
                  </div>
                </div>
                <div className="buying-wrap">
                  <div className="radios-wrap">
                    <p className="radios-group-title">
                      {t('Inventory.Products.doesTheProductAcceptDiscount')}
                    </p>
                    <div className="labels-wrap">
                      {[
                        {
                          title: t('Inventory.Products.yes'),
                          value: '1'
                        },
                        {
                          title: t('Inventory.Products.no'),
                          value: '2'
                        }
                      ].map((obj, i) => (
                        <label
                          key={i}
                          className={`radio-input-label ${
                            String(watch(`accept_discount`)) ===
                            String(obj.value)
                              ? 'selected'
                              : ''
                          }`}
                        >
                          <input
                            type="radio"
                            value={obj.value}
                            {...register(`accept_discount`)}
                          />
                          <span className="label-span">{obj.title}</span>
                          {watch(`accept_discount`) == String(obj.value) ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonEmpty />
                          )}
                        </label>
                      ))}
                    </div>

                    {errors?.accept_discount && (
                      <p className="error-p">
                        {errors.accept_discount.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="date-picker-wrap">
                  <p className="label-p">
                    {t('Inventory.Products.productExpirationDate')}
                  </p>
                  <DatePicker
                    name="end_date"
                    // format="YYYY-MM-DD"
                    size="large"
                    {...field}
                    control={control}
                    // defaultValue={selectedCash?.date && moment(selectedCash?.date)}
                    // bordered={false}
                    setValue={setValue}
                    placeholder={t(
                      'Inventory.Products.productExpirationDatePlaceholder'
                    )}
                    className="receipt-date-picker"
                    formClassName="create-receipt-form"
                  />
                  {/* {errors?.date && <p className="error-p">{errors.date.message}</p>} */}
                </div>
                {/* <CustomEditor
                  fieldName="product_description"
                  editorTitle={t('Inventory.Products.productDetails')}
                  setValue={setValue}
                  watch={watch}
                  setError={setError}
                  clearErrors={clearErrors}
                  errors={errors}
                  required={false}
                  errorMsg={t('Inventory.Products.productDetails')}
                  editorFieldState={productDescEditorState}
                  setEditorFieldState={setProductDescEditorState}
                  fetchedData={selectedProduct?.desc}
                /> */}
                <div className="text-field-label-wrap">
                  <p className="label-p">
                    {t('Inventory.Products.productDetails')}
                  </p>
                  <div className="text-field-wrap">
                    <AntdTextarea
                      className="form-text-area"
                      name="product_description"
                      rows={8}
                      placeholder={t('Inventory.Products.productDetails')}
                      errorMsg={errors?.product_description?.message}
                      validateStatus={
                        errors?.product_description ? 'error' : ''
                      }
                      control={control}
                    />
                  </div>
                </div>
                <div className="product-img-wrap">
                  <h3>{t('Inventory.Products.productImage')}</h3>

                  <MyDropZone
                    className="product-header-dropzone"
                    multipleFiles={false}
                    handleFilesDrop={(acceptedFiles) =>
                      handleFilesDrop(
                        acceptedFiles,
                        productFileToUpload,
                        setProductFileToUpload
                      )
                    }
                    filesToUpload={productFileToUpload}
                    formName="store-new-product-form"
                    id="avatar-input"
                    dropzoneText={t('Inventory.Products.productImage')}
                    inputName="product_image"
                    icon={<CameraFilled className="dropzone-camera" />}
                    dropZoneUrls={
                      selectedProduct?.image ? [selectedProduct.image] : null
                    }
                  >
                    {t('Inventory.Products.dragTheImageAndPlaceItHere')}
                  </MyDropZone>

                  <MyDropZonePreview
                    filesToUpload={productFileToUpload}
                    clearFileFromUpload={(f) =>
                      clearFileFromUpload(f, setProductFileToUpload)
                    }
                  />
                  {errors?.product_image?.message &&
                    !watch('product_image') && (
                      <p className="error-p">{errors.product_image.message}</p>
                    )}
                </div>
                {/* sub products fields */}
                <div className="product-desc-feilds">
                  <h2>{t('Inventory.Products.productsDetails')}</h2>
                  {renderIngredientsFields()}
                  <div
                    className="add-new-field-btn"
                    onClick={() =>
                      appendIngredientsField({
                        unique_id: '',
                        quantity: '',
                        unit_name: '',
                        first_quantity: '',
                        result: '',
                        price: ''
                      })
                    }
                  >
                    <span>{t('Inventory.Products.addNewUnit')}</span>
                  </div>
                </div>
              </>
            )}
          </>

          {watch('add_product') !== 2 && (
            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              // icon={<LoginOutlined />}
              loading={isSubmittingForm}
            >
              {selectedProduct
                ? t('Inventory.Products.edit')
                : t('Inventory.Products.add')}
            </Button>
          )}

          {watch('add_product') == 2 && (
            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              // icon={<LoginOutlined />}
              loading={isSubmitting}
            >
              {selectedProduct
                ? t('Inventory.Products.edit')
                : t('Inventory.Products.add')}
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default StoreNewProductForm;
