import { useContext } from 'react';
import UserContext from '../../../contexts/user-context/UserProvider';
import protectMe from '../../../utils/protectMe';
// import routerLinks from '../routerLinks';
import TaxInvoiceReportsPage from '../../../pages/reports-pages/taxinvoice-reports-page/TaxInvoiceReportsPage';
import { OutgoingPurchaseInvoiceProvider } from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import { IncomingPurchaseInvoiceProvider } from '../../../contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import { TaxInvoiceReportsProvider } from '../../../contexts/reports-contexts/TaxInvoiceReportsContext';
import Links from '../Links';
import ProfitAndLosesPage from '../../../pages/reports-pages/profit-loses-page/ProfitAndLosesPage';
import { ProfitLosesReportsProvider } from '../../../contexts/profit-loses-reports-context/ProfitLosesReportsContext';
const ReportsRoutes = () => {
  const { loggedIn, user } = useContext(UserContext);
  const routerLinks = Links();
  return [
    // protectMe(
    //   routerLinks.purchasesReportsPage,
    //   <PurchasesReportsPage />,
    //   1,
    //   routerLinks.signInPage,
    //   loggedIn
    // ),
    protectMe(
      routerLinks.profitAndLosesReportsPage,
      <ProfitLosesReportsProvider>
        <ProfitAndLosesPage />
      </ProfitLosesReportsProvider>,
      2,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),

    // protectMe(
    //   routerLinks.taxInvoiceReportsPage,
    //   <SaleInvoiceProvider>
    //     <TaxInvoiceReportsProvider>
    //       <TaxInvoiceReportsPage />
    //     </TaxInvoiceReportsProvider>
    //   </SaleInvoiceProvider>,
    //   3,
    //   routerLinks.signInPage,
    //   loggedIn
    // )
    protectMe(
      routerLinks.taxInvoiceReportsPage,
      <OutgoingPurchaseInvoiceProvider>
        <IncomingPurchaseInvoiceProvider>
          <TaxInvoiceReportsProvider>
            <TaxInvoiceReportsPage taxPage={true} />
          </TaxInvoiceReportsProvider>
        </IncomingPurchaseInvoiceProvider>
      </OutgoingPurchaseInvoiceProvider>,
      3,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    )
    //
    //
  ];
};

export default ReportsRoutes;
