/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Table, Empty, Pagination } from 'antd';
import tableColumns from './tableColumns';
import UserContext from '../../contexts/user-context/UserProvider';
import getAllSuppliersApi from '../../apis/store-apis/suppliers/getAllSuppliersApi';
import SuppliersContext from '../../contexts/suppliers-context/SuppliersContext';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import storeRouterLinks from '../../components/app/store-routes/storeRouterLinks';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
const PageTable = ({ currentLang }) => {
  const {
    allFetchedSuppliers,
    setAllFetchedSuppliers,
    isLoading,
    setIsLoading,
    fetchCount,
    setFetchCount,
    setModalOpened,
    setSelectedSupplier,
    nameSearchValue,
    tablePagination,
    setTablePagination
  } = useContext(SuppliersContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();

  const { search } = useLocation();
  const values = queryString.parse(search);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllSuppliersApi(
          user?.token,
          { page: values.page ? values.page : 1, searchText: nameSearchValue },
          false
        ),
        (res) => {
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data?.data) {
            if (res.data.data.data.length >= 0) {
              const data = res.data.data.data;
              setAllFetchedSuppliers(data);
            }
            if (res?.data?.data?.pagination) {
              setTablePagination(res.data.data.pagination);
            }
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedSuppliers?.length >= 0) {
      let mappedData = null;
      if (nameSearchValue.trim()) {
        const filtered = allFetchedSuppliers.filter((user) =>
          user.name
            .toLowerCase()
            .trim()
            .includes(nameSearchValue.toLowerCase().trim())
        );
        mappedData = mapDataSource(filtered);
      } else {
        mappedData = mapDataSource(allFetchedSuppliers);
      }
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    fetchCount,
    allFetchedSuppliers,
    allFetchedSuppliers?.length,
    nameSearchValue
  ]);

  useEffect(() => {
    if (values.page) {
      setTablePagination({ ...tablePagination, current_page: +values.page });
    }
  }, [search]);

  return (
    <section>
      <Table
        locale={{
          emptyText: <Empty description={false}>{t('suppliers.noData')}</Empty>
        }}
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedSuppliers,
          setModalOpened,
          setFetchCount,
          setSelectedSupplier,
          setIsLoading,
          user?.token,
          t
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
      {tablePagination && (
        <Pagination
          style={{ display: 'flex', justifyContent: 'center' }}
          defaultCurrent={1}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}

          onChange={(page, pageSize) => {
            setTablePagination({ ...tablePagination, current_page: page });
            // setTablePagination(...tablePagination , {current_page:page})
            setFetchCount((prev) => prev + 1);
            history.push(`${storeRouterLinks?.suppliersPage}?page=${page}`);
          }}
          hideOnSinglePage={true}
        />
      )}
    </section>
  );
};

export default PageTable;
