
import { EyeOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

const tableColumns = (
  allFetchedCustomersPayments,
  setCustomerPaymentModalOpened,
  setSelectedCustomerPaymentId,
  setFetchCount,
  setSelectedCustomerPayment,
  setIsLoading,
  token,
  t
) => {
  // const handleEditUser = (record) => {
  //   const found = allFetchedCustomersPayments.find(
  //     (obj) => obj.id === record.id
  //   );
  //   if (found) {
  //     setSelectedCustomerPayment({ ...found });
  //     setCustomerPaymentModalOpened(true);
  //   }
  // };

  // const handleDeleteRequest = async (id) => {
  //   try {
  //     setIsLoading(true);
  //     const res = await deleteClientApi(token, id);
  //     if (res?.status === 200 && res?.data?.status === 1) {
  //       setIsLoading(false);
  //       setFetchCount((prevState) => prevState + 1);
  //       successNotification({
  //         title: 'العملية تمت بنجاح',
  //         message: 'تم حذف العميل بنجاح'
  //       });
  //     } else {
  //       setIsLoading(false);
  //       errorNotification({
  //         title: 'حدث خطأ اثناء العملية',
  //         message: res?.data?.message
  //           ? res.data.message
  //           : 'من فضلك حاول فى وقت لاحق'
  //       });
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     errorNotification({
  //       title: 'حدث خطأ',
  //       message: 'حاول فى وقت لاحق'
  //     });
  //   }
  // };

  // const handleViewReceiptClick = (record) => {
  //   setCustomerPaymentModalOpened(true);
  // };

  // const renderActions = (record) => {
  //   return (
  //     <div className="action-btns-wrap">
  //       <div className="edit-btn" onClick={() => handleEditUser(record)}>
  //         <EditIcon />
  //         تعديل
  //       </div>
  //       <Popconfirm
  //         title="هل أنت متأكد من حذف العميل"
  //         onConfirm={() => handleDeleteRequest(record.id)}
  //         okText="نعم"
  //         cancelText="لا"
  //       >
  //         <Tooltip title="حذف العميل">
  //           <div className="delete-btn">
  //             <DeleteIcon />
  //           </div>
  //         </Tooltip>
  //       </Popconfirm>
  //     </div>
  //   );
  // };

  return [
    // {
    //   title: 'كود المورد',
    //   dataIndex: 'user_id',
    //   // width: 62
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-index bold-font">
    //         <span># {record?.user?.id}</span>
    //       </div>
    //     );
    //   }
    // },
    {
      title: t('SupplierPays.supplierName'),
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record?.user?.name || '----'}</span>
          </div>
        );
      }
      // sorter: (a, b) => {
      //   let nameA = a.name.toUpperCase(); // ignore upper and lowercase
      //   let nameB = b.name.toUpperCase(); // ignore upper and lowercase
      //   if (nameA < nameB) {
      //     return -1;
      //   }
      //   if (nameA > nameB) {
      //     return 1;
      //   }

      //   // names must be equal
      //   return 0;
      // }
    },
    // {
    //   title: 'البريد الاكترونى',
    //   dataIndex: 'email',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         <span className="name-it-self">
    //           {record?.user?.email || '----'}
    //         </span>
    //       </div>
    //     );
    //   }
    // },
    // {
    //   title: 'رقم الهاتف',
    //   dataIndex: 'phone',
    //   // width: 192,
    //   render: (_, record) => {
    //     return <div className="row-cell row-text">{record?.user?.phone}</div>;
    //   }
    // },
    {
      title: t('SupplierPays.amount'),
      dataIndex: 'amount',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record?.amount || 0}</span>
          </div>
        );
      }
    },
    {
      title: t('SupplierPays.date'),
      dataIndex: 'date',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record?.date || '----'}</span>
            {/* {record?.date !== '0000-00-00' && null ? record?.date : '----'} */}
          </div>
        );
      }
    },
    {
      title: t('SupplierPays.notes'),
      dataIndex: 'notes',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record?.notes || '----'}</span>
          </div>
        );
      }
    },
    {
      title: t('SupplierPays.paidPreview'),
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            <Tooltip title={t('SupplierPays.paidPreview')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => {
                  setSelectedCustomerPaymentId(record?.id);
                  setCustomerPaymentModalOpened(true);
                }}
              />
            </Tooltip>
          </div>
        );
      }
    }
    // {
    //   title: 'الاعدادات',
    //   dataIndex: 'actions',
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-actions">{renderActions(record)}</div>
    //     );
    //   }
    // }
  ];
};

export default tableColumns;
