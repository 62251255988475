import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import PosCashPaymentsContext from '../../../contexts/pos-cash-payments/PosCashPaymentsContext';

const SettingsWorkPlaceModal = ({ departments }) => {
  const { modalOpened, setModalOpened, selectedCash, setSelectedCash } =
    useContext(PosCashPaymentsContext);

  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '642px' }}
      title={selectedCash ? 'تعديل سند صرف' : 'إضافة صرف نقدية جديد'}
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedCash(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedCash(null);
      }}
      footer={false}
    >
      <TableForm departments={departments} />
    </Modal>
  );
};

export default SettingsWorkPlaceModal;
