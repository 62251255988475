/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Select, DatePicker } from 'antd';
import SelectDropDown from '../../common/icons/SelectDropDown';
import UserContext from '../../contexts/user-context/UserProvider';
import useClients from '../../custom-hooks/useClients';
import getAllSaleInvoicesApi from '../../apis/sales-apis/getAllSaleInvoicesApi';
import SaleInvoicesContext from '../../contexts/sale-invoices-context/SaleInvoicesContext';
import CalendarIcon from '../../common/icons/CalendarIcon';
import usePos from '../../custom-hooks/usePos';
import excelImg from '../../assets/imgs/icons/excel-btn.png';
// import Workbook from 'react-excel-workbook';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import queryString from 'query-string';
import useUsers from '../../custom-hooks/useUsers';
import userRoles from '../../userRoles';
import salesRouterLinks from '../../components/app/sales-routes/salesRouterLinks';
import invoiceFilterTypes from '../../invoiceFilterTypes';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
import ShiftReportsContext from '../../contexts/shift-reports-context/ShiftReportsContext';
import getAllShiftReportsApi from '../../apis/shift-reports-apis/getAllShiftReportsApi';
import { useTranslation } from 'react-i18next';
const { RangePicker } = DatePicker;

const TableFilter = () => {
  const history = useHistory();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const allPosUsers = useUsers(userRoles?.posUser);
  const { user } = useContext(UserContext);
  const systemSettings = useSystemSettings();
  const { t } = useTranslation();
  const receiptTypes = [
    {
      title: t('ShiftReports.saleInvoices'),
      value: '1'
    },
    {
      title: t('ShiftReports.returnInvoices'),
      value: '2'
    },
    {
      title: t('ShiftReports.bookingInvoices'),
      value: '3'
    }
  ];
  const {
    tableFilter,
    setTableFilter,
    setTablePagination,
    setIsLoadingAllShiftReports,
    setAllFetchedShiftReports
  } = useContext(ShiftReportsContext);
  const allPos = usePos();
  const { Option } = Select;
  const [selectedPicker, setSelectedPicker] = React.useState(null);

  const { allClients } = useClients();

  const handlePickerChange = (value, string) => {
    setSelectedPicker({
      moments: value,
      strings: string
    });
  };

  React.useEffect(() => {
    if (selectedPicker?.strings?.length > 0) {
      setTableFilter((prev) => ({
        ...prev,
        from: selectedPicker.strings[0],
        to: selectedPicker.strings[1]
      }));
    }
  }, [selectedPicker?.strings]);

  async function handleChange(filterType, value) {
    setTableFilter((prevState) => {
      if (filterType === 'pos_id') {
        return {
          ...prevState,
          pos_id: value
        };
      } else if (filterType === 'admin_id') {
        return {
          ...prevState,
          admin_id: value
        };
      }
    });
  }

  const customApiRequest = useCustomApiRequest();

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoadingAllShiftReports(true);
    customApiRequest(
      getAllShiftReportsApi(
        user?.token,
        {
          ...tableFilter,
          ...values,
          page: 1
        },
        systemSettings?.id
      ),
      (res) => {
        // history.push(`${salesRouterLinks?.shiftPageReports}?page=1`);
        setIsLoadingAllShiftReports(false);

        if (res?.status === 200 && res?.data?.data?.data) {
          if (res?.data?.data?.data.length >= 0) {
            const data = res?.data?.data?.data;
            setAllFetchedShiftReports(data);
          }
          if (res?.data?.data?.pagination) {
            setTablePagination(res?.data?.data?.pagination);
          }
        } else {
        }
      },
      (error) => {
        setIsLoadingAllShiftReports(false);
      }
    );
  }

  return (
    <div className="custom-table-fitler warehouse-table-filter">
      <div className="form-excel-export-wrap">
        <form
          className="filter-form receipt-filter-form"
          onSubmit={handleSubmit}
        >
          {user?.role !== userRoles?.delegate && (
            <label className="receipt-filter-option-wrap">
              <span className="select-title">
                {t('ShiftReports.employeeName')}
              </span>
              <Select
                defaultValue={null}
                style={{ width: 172 }}
                onChange={(v) => handleChange('admin_id', v)}
                suffixIcon={<SelectDropDown />}
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={null}>{t('ShiftReports.allEmployees')}</Option>
                {allPosUsers?.length > 0 &&
                  allPosUsers.map((item) => (
                    <Option key={item.id} value={String(item.id)}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </label>
          )}

          <label className="receipt-filter-option-wrap">
            <span className="select-title">{t('ShiftReports.salePoint')}</span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('pos_id', v)}
              suffixIcon={<SelectDropDown />}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                0`1`````````
              }
            >
              <Option value={null}>{t('ShiftReports.allSalePoints')}</Option>
              {allPos?.length > 0 &&
                allPos.map((item) => (
                  <Option key={item.id} value={String(item.id)}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </label>

          <div className="range-picker-wrap">
            <RangePicker
              value={selectedPicker?.moments && selectedPicker.moments}
              onChange={handlePickerChange}
              // format="YYYY-MM-DD"
              className={`filter-range-picker`}
              allowClear={true}
              suffixIcon={<CalendarIcon />}
              placeholder={[
                t('ShiftReports.dateFrom'),
                t('ShiftReports.dateTo')
              ]}
            />
          </div>
          <button className="filter-submit-btn" type="submit">
            {t('ShiftReports.search')}
          </button>
        </form>
        <div className="excel-btn-wrap">
          <a
            href={`${
              process.env.REACT_APP_BASE_URL
            }/exportExcelShift?admin_id=${
              tableFilter?.admin_id || ''
            }&client_id=${systemSettings?.id}&from=${
              tableFilter?.from || ''
            }&to=${tableFilter?.to || ''}&pos_id=${tableFilter?.pos_id || ''}`}
            download
            className="excel-export-btn"
          >
            <img
              style={{ height: '42px' }}
              src={excelImg}
              alt="export to excel"
            />
          </a>
        </div>
        {/* <div className="excel-btn-wrap">
          <a
            href={`${
              process.env.REACT_APP_BASE_URL
            }/exportExcelInvoice?client_id=${systemSettings?.id || ''}
            &type=${1}
            &today_reservations=${tableFilter?.today_reservations || ''}
            &user_id=${tableFilter?.user_id || ''}
            &from=${tableFilter?.from || ''}
            &to=${tableFilter?.to || ''}&paymentType=${
              tableFilter?.pay_id || ''
            }
            &admin_id=${tableFilter?.admin_id || ''}
            &page=${tableFilter?.page || '1'}
            &typeN=${tableFilter?.type || ''}
            &pos_id=${tableFilter?.pos_id || ''}`}
            download
            className="excel-export-btn"
          >
            <img
              style={{ height: '42px' }}
              src={excelImg}
              alt="export to excel"
            />
          </a>
        </div> */}

        {/* <div className="excel-btn-wrap">
          <Workbook
            filename="sales_invoice.xlsx"
            element={
              <div className="excel-export-btn">
                <img
                  style={{ height: '42px' }}
                  src={excelImg}
                  alt="export to excel"
                />
              </div>
            }
          >
            <Workbook.Sheet
              data={allFetchedSaleInvoices ? allFetchedSaleInvoices : []}
              name="Sheet A"
            >
              <Workbook.Column label="مسلسل" value="invoiceNumber" />
              <Workbook.Column label="العميل" value={(row) => row?.name} />
              <Workbook.Column
                label="الموظف"
                value={(row) => row?.shift?.employee?.name}
              />
              <Workbook.Column
                label="تاريخ الفاتورة"
                value={(row) => row.date}
              />
              <Workbook.Column
                label="السعر الإجمالى"
                value={(row) => row.totalPrice}
              />
            </Workbook.Sheet>
          </Workbook>
        </div> */}
      </div>
    </div>
  );
};

export default TableFilter;
