import axios from 'axios';

const signOutApi = async (token, data) => {
  try {
    const signOutRes = await axios({
      method: 'post',
      baseURL: process.env.REACT_APP_BASE_URL_POS,
      url: '/logout',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      data: data ? data : null
    });
    return signOutRes;
  } catch (error) {
    throw error;
  }
};

export default signOutApi;
