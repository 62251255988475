/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Empty, Pagination, Table } from 'antd';
import tableColumns from './tableColumns';
import UserContext from '../../contexts/user-context/UserProvider';
import ShiftsContext from '../../contexts/shifts-context/ShiftsContext';
import getAllShiftsApi from '../../apis/shifts-apis/getAllShiftsApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { useTranslation } from 'react-i18next';
const PageTable = () => {
  const { user } = useContext(UserContext);
  const {t} = useTranslation()
  const {
    allFetchedShifts,
    setAllFetchedShifts,
    isLoading,
    setIsLoading,
    fetchCount,
    setModalOpened,
    setSelectedShift,
    setSelectedShiftModalOpened,
    nameSearchValue,
    tablePagination,
    setTablePagination,
    setFetchCount,
    filterValues,
    setFilterValues
  } = useContext(ShiftsContext);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllShiftsApi(user?.token, {
          ...filterValues,
          page: tablePagination.current_page
        }),
        (res) => {
          // setFilterValues({
          //   ...filterValues,
          //   page: tablePagination.current_page
          // });
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data?.data?.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedShifts(data);
          } else {
          }
          if (res.data.data.pagination) {
            setTablePagination(res.data.data.pagination);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedShifts?.length >= 0) {
      let mappedData = null;
      mappedData = mapDataSource(allFetchedShifts);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedShifts, allFetchedShifts?.length, nameSearchValue]);

  return (
    <>
      <Table
        locale={{
          emptyText: <Empty description={false}>{t('Shift.noData')}</Empty>
        }}
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          setModalOpened,
          setSelectedShift,
          setSelectedShiftModalOpened,
          t
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
      {tablePagination && (
        <Pagination
          style={{ display: 'flex', justifyContent: 'center' }}
          defaultCurrent={1}
          showSizeChanger={false}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}
          onChange={(page, pageSize) => {
            setFetchCount((prev) => prev + 1);
            setTablePagination({ ...tablePagination, current_page: page });
          }}
          hideOnSinglePage={true}
        />
      )}
    </>
  );
};

export default PageTable;
