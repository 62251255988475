/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import getAccountsListApi from '../../../apis/accounting-apis/accounting-details-apis/getAccountsListApi';
import createAccountingLeafApi from '../../../apis/accounting-apis/createAccountingLeafApi';
import updateAccountingLeafApi from '../../../apis/accounting-apis/updateAccountingLeafApi';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import AccountingContext from '../../../contexts/accounting-context/AccountingContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import { getLeafType } from '../../../custom-hooks/getLeafType';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
const options = [];
for (let i = 10; i < 36; i++) {
  options?.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i
  });
}

const AccountingForm = () => {
  const { user } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();
  const { t } = useTranslation();

  const {
    selectedAccountNode,
    setIsLoading,
    setAccountingModalOpened,
    setFetchCount,
    editMode,
    accountsLists,
    setAccountsLists
  } = useContext(AccountingContext);
  const [schema, setSchema] = useState({});
  const [apiLoading, setApiLoading] = useState(false);
  let leaf_type = selectedAccountNode?.leaf_type;
  let leafType = getLeafType(selectedAccountNode?.id);
  let selectedNodeId = selectedAccountNode?.id;
  let selectedNodeIdArr =
    typeof selectedNodeId === 'string'
      ? selectedNodeId?.split('-')
      : selectedNodeId;

  useEffect(() => {
    let api;
    let acc_id =
      (typeof selectedAccountNode?.id ||
        typeof selectedAccountNode?.subtype_id) === 'number'
        ? +selectedAccountNode?.id || +selectedAccountNode?.subtype_id
        : +selectedAccountNode?.id?.split('-').at(-1) ||
          +selectedAccountNode?.subtype_id?.split('-').at(-1);
    if (selectedAccountNode && editMode) {
      api = getAccountsListApi(user?.token, true, acc_id);
      customApiRequest(api, (res) => {
        if (res?.data?.accounts) {
          setAccountsLists(res?.data?.accounts);
        }
      });
    } else {
      api = getAccountsListApi(user?.token);
      customApiRequest(api, (res) => {
        if (res?.data?.accounts) {
          setAccountsLists(res?.data?.accounts);
        }
      });
    }
  }, []);

  // useEffect(() => {
  //   let api, successMessage, errorMessage;
  //   api = getAccountsListApi(user?.token, true);
  //   customApiRequest(api, (res) => {
  //     if (res?.data?.accounts) {
  //       setAccountsListsUpdates(res?.data?.accounts);
  //     }
  //   });
  // }, []);

  const [form] = Form.useForm();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {}
  });

  const onSubmit = async (data) => {
    setApiLoading(true);
    let api, successMessage, errorMessage;
    if (editMode) {
      successMessage = t('Messages.Modifiedsuccessfully');
      errorMessage = t('Messages.erroroccurredwhileediting');

      if (leaf_type === 'account') {
        api = updateAccountingLeafApi(
          user?.token,
          { ...data },
          `/accounting/account/update/${
            (typeof selectedAccountNode?.id ||
              typeof selectedAccountNode?.subtype_id) === 'number'
              ? +selectedAccountNode?.id || +selectedAccountNode?.subtype_id
              : +selectedAccountNode?.id?.split('-').at(-1) ||
                +selectedAccountNode?.subtype_id?.split('-').at(-1)
          }`
        ); // Here update
      } else if (leaf_type === 'subtype') {
        api = updateAccountingLeafApi(
          user?.token,
          { ...data },
          `/accounting/account/update/${+selectedAccountNode?.id
            ?.split('-')
            .at(-1)}`
        );
      } else if (leaf_type === 'subchild') {
        api = updateAccountingLeafApi(
          user?.token,
          { ...data },
          `/accounting/account/update/${+selectedAccountNode?.id
            ?.split('-')
            .at(-1)}`
        );
      } else {
        api = updateAccountingLeafApi(
          user?.token,
          { ...data },
          `/accounting/account/update/${
            +selectedNodeIdArr[0] || +selectedNodeIdArr
          }`
        );
      }
    } else {
      successMessage = t('Messages.Theaccountbranchhasbeenaddedsuccessfully');
      errorMessage = t('Messages.rroroccurredwhileaddingtheaccountbranch');
      if (
        leaf_type === 'type' ||
        leaf_type === 'subtype' ||
        leaf_type === 'subchild' ||
        leaf_type === 'account'
      ) {
        api = createAccountingLeafApi(
          user?.token,
          {
            ...data,
            client_id: user?.ref_key.split('_')[1],
            parent_id:
              selectedAccountNode?.id !== 'fake-leaf-id'
                ? (typeof selectedAccountNode?.id ||
                    typeof selectedAccountNode?.subtype_id) === 'number'
                  ? +selectedAccountNode?.id || +selectedAccountNode?.subtype_id
                  : +selectedAccountNode?.id?.split('-').at(-1) ||
                    +selectedAccountNode?.subtype_id?.split('-').at(-1)
                : null
          },
          '/accounting/account'
        );
        // api = createAccountingLeafApi(user?.token, { ...data, client_id: user?.ref_key.split("_")[1], parent_id: ((typeof selectedAccountNode?.id) === 'number') ? selectedAccountNode?.id : +selectedAccountNode?.id?.split('-').at(-1)}, "/accounting/account")
      }
    }
    // if (
    //   (leaf_type === 'subtype' && editMode) ||
    //   (leaf_type === 'subchild' && editMode) ||
    //   (leaf_type !== 'account' && editMode)
    // ) {
    //   api = updateAccountingLeafApi(
    //     user?.token,
    //     { ...data },
    //     `/accounting/account/update/${+selectedAccountNode?.id
    //       ?.split('-')
    //       .at(-1)}`
    //   );
    // }
    customApiRequest(
      api,
      (res) => {
        setApiLoading(false);
        if (checkRes(res)) {
          setAccountingModalOpened(false);
          setFetchCount((prevState) => prevState + 1);
          successNotification({
            title: t('Messages.addedSuccessfully'),
            message: successMessage
          });
        } else {
          setApiLoading(false);
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message: res?.data?.message || t('Messages.TryAgainLater')
          });
        }
      },
      (error) => {
        setIsLoading(false);
        setApiLoading(false);
        errorNotification({
          title: t('Messages.ErrorOccurred'),
          message: error?.response?.data?.message || t('Messages.TryAgainLater')
        });
      }
    );
  };

  useEffect(() => {
    if (editMode) {
      setSchema(
        Yup.object().shape({
          holder: Yup.string().required(t('Accounts.SelectAccountBranch')),
          acn: Yup.string().required(t('Accounts.EnterAccountCode')),
          // initial_balance: Yup.number().required('ادخل الرصيد المبدأى   '),
          // analytical: Yup.number().required('اختر نعم أم لا'),
          // type: Yup.string().required('اختر النوع'),
          parent_id: Yup.string().required(t('Accounts.SelectMainAccount'))
        })
      );
    } else {
      setSchema(
        Yup.object().shape({
          holder: Yup.string().required(t('Accounts.SelectAccountBranch')),
          acn: Yup.string().required(t('Accounts.EnterAccountCode')),
          // initial_balance: Yup.number().required('ادخل الرصيد المبدأى   '),
          // analytical: Yup.number().required('اختر نعم أم لا'),
          // type: Yup.string().required('اختر النوع'),
          parent_id: Yup.string().optional(t('Accounts.SelectMainAccount'))
        })
      );
    }
  }, []);

  // handle initial values
  useEffect(() => {
    if (editMode && selectedAccountNode) {
      const {
        name,
        holder,
        acn,
        initial_balance,
        analytical,
        type,
        method,
        payer,
        note,
        debit,
        account,
        cat,
        parent_id
      } = selectedAccountNode;

      if (
        leaf_type === 'type' ||
        leaf_type === 'subtype' ||
        (leaf_type === 'subchild' && editMode)
      ) {
        setValue('holder', name || '');
        setValue('acn', acn || '');
        setValue('type', type || '');
        if (parent_id) setValue('parent_id', parent_id || null);
      } else if (leaf_type === 'account') {
        setValue('holder', holder || '');
        setValue('anc', acn || '');
        setValue('initial_balance', initial_balance || '');
        setValue('analytical', analytical || '');
        setValue('type', type || '');
        setValue('parent_id', parent_id || null);
      } else if (leafType === 5) {
        setValue('anc', acn || '');
        setValue('parent_id', parent_id || null);
        setValue('payer', payer || '');
        setValue('type', type || '');
        setValue('method', method || '');
        setValue('note', note || '');
        setValue('debit', debit || '');
        setValue('account', account || '');
        setValue('cat', cat || '');
      } else {
        setValue('holder', name || '');
        setValue('anc', acn || '');
        setValue('type', type || '');
        if (parent_id) setValue('parent_id', parent_id || null);
      }
    }
  }, [selectedAccountNode, selectedAccountNode?.id]);

  return (
    <Form
      className="custom-shared-form accounting-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        {editMode && (
          <div className="select-label-wrap">
            <p className="label-p">{t('Accounts.SelectMainAccount')}</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name={`parent_id`}
                errorMsg={errors?.parent_id && errors?.parent_id?.message}
                validateStatus={errors?.parent_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder={t('Accounts.SelectMainAccount')}
                options={
                  accountsLists?.length > 0
                    ? accountsLists.map((obj) => ({
                        title: obj.holder,
                        value: String(obj.id)
                      }))
                    : null
                }
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                formClassName="accounting-form"
              />
            </div>
          </div>
        )}
        {selectedAccountNode?.fakeLeaf && (
          <div className="select-label-wrap">
            <p className="label-p">{t('Accounts.SelectMainAccount')}</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name={`parent_id`}
                errorMsg={errors?.parent_id && errors?.parent_id?.message}
                validateStatus={errors?.parent_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder={t('Accounts.SelectMainAccount')}
                // options={[
                //   {
                //     title: 'الدائن',
                //     value: 'credit'
                //   },
                //   {
                //     title: 'المدين',
                //     value: 'debit'
                //   }
                // ]}
                options={
                  accountsLists?.length > 0
                    ? accountsLists.map((obj) => ({
                        title: obj.holder,
                        value: obj.id == null ? null : String(obj.id)
                      }))
                    : null
                }
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                formClassName="accounting-form"
              />
            </div>
          </div>
        )}

        <div className="text-field-label-wrap">
          <p className="label-p">{t('Accounts.AccountCode')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="acn"
              type="text"
              setValue={setValue}
              placeholder={`${t('Accounts.AccountCode')}...`}
              errorMsg={errors?.acn?.message}
              validateStatus={errors?.acn ? 'error' : ''}
              // defaultValue={selectedAccountNode && selectedAccountNode}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('Accounts.AccountName')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="holder"
              type="text"
              setValue={setValue}
              placeholder={`${t('Accounts.AccountName')}...`}
              errorMsg={errors?.holder?.message}
              validateStatus={errors?.holder ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        {/* <div className="text-field-label-wrap">
          <p className="label-p">الرصيد المبدأى</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="initial_balance"
              type="text"
              placeholder="الرصيد المبدأى..."
              errorMsg={errors?.initial_balance?.message}
              validateStatus={errors?.initial_balance ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="select-label-wrap">
          <p className="label-p">هل الحساب تحليلى؟</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="analytical"
              errorMsg={errors?.analytical && errors.analytical.message}
              validateStatus={errors?.analytical ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="هل الحساب تحليلى؟"
              options={[
                {
                  title: 'نعم',
                  value: 1
                },
                {
                  title: 'لا',
                  value: 0
                }
              ]}
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              formClassName="accounting-form"
            />
          </div>
        </div> */}

        {/* <div className="select-label-wrap">
          <p className="label-p">إختار النـــوع</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`type`}
              errorMsg={errors?.type && errors?.type?.message}
              validateStatus={errors?.type ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="إختار النـــوع"
              options={[
                {
                  title: 'الدائن',
                  value: 'credit'
                },
                {
                  title: 'المدين',
                  value: 'debit'
                }
              ]}
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                0
              }
              formClassName="accounting-form"
            />
          </div>
        </div> */}

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={apiLoading}
        >
          {!editMode ? t('Accounts.Add') : t('Accounts.Edit')}
        </Button>
      </div>
    </Form>
  );
};

export default AccountingForm;
