/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Table, Empty } from 'antd';
import tableColumns from './tableColumns';
// import pageData from './pageData';
import getAllUnits from '../../apis/settings-apis/measuremnet-units-apis/getAllUnits';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import getAllTicketsApi from '../../apis/technical-support-page/getAllTicketsApi';
import TechnicalSupportContext from '../../contexts/technical-support-context/TechnicalSupportContext';
// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const PageTable = () => {
  const { user } = useContext(UserContext);
  const {
    isLoading,
    setIsLoading,
    setModalOpened,
    allFetchedTickets,
    fetchCount,
    setAllFetchedTickets,
    setFetchCount,
    setSelectedTicket,
    setShowModalOpened
  } = useContext(TechnicalSupportContext);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: index + 1,
      name: obj?.name ? obj.name : '',
      title: obj?.title ? obj.title : ''
    }));
  };

  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllTicketsApi(user?.token),
        (res) => {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data?.data) {
            setAllFetchedTickets(res?.data?.data?.data);
          }
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (allFetchedTickets?.length >= 0) {
      const mappedData = mapDataSource(allFetchedTickets);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedTickets, allFetchedTickets?.length]);

  return (
    <Table
      locale={{
        emptyText: <Empty description={false}>لا يوجد بيانات متاحة</Empty>
      }}
      pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
      className="antd-custom-table"
      dataSource={tableDataSource}
      columns={tableColumns(
        allFetchedTickets,
        setModalOpened,
        setShowModalOpened,
        setFetchCount,
        setSelectedTicket,
        setIsLoading,
        user?.token
      )}
      loading={isLoading}
      // scroll={{ x: 400 }}
      footer={false}
    />
  );
};

export default PageTable;
