import axios from 'axios';

// level 1=>main , 2=>sub
// cat_id ==> when createing sub category send the cat_id of the main category

// main roles
// 1 => manager ===> to be edied later
// 2 => partner
// 3 => accountant
// 4 => dataEntry
// 5 => posEmployee

const addNewUserApi = async (token, values, edit = false) => {
  const mappedValues = {};
  if (values.name) mappedValues.name = values.name;
  if (values.username) mappedValues.username = values.username;
  if (values.phone) mappedValues.phone = values.phone;
  if (values.email) mappedValues.email = values.email;
  if (values.password) mappedValues.password = values.password;
  if (values.role) mappedValues.role = values.role;
  if (values.pos) mappedValues.pos_id = values.pos;
  // for editing user expecting to have the id proerty in the values object
  const formData = new FormData();
  for (let key in mappedValues) {
    formData.append(key, mappedValues[key]);
  }

  try {
    let url;
    if (!edit) url = '/Admin/create';
    else url = `/Admin/update?id=${values.id}`;
    const res = await axios.post(url, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default addNewUserApi;
