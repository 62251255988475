import React from 'react';

const MenuIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={color ? color : '#b5b5b5'}
        d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"
      ></path>
    </svg>
  );
};

export default MenuIcon;
