/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import storeItemManagerImg from '../../assets/imgs/store/store-item-manager.png';
import SearchNotificationsProfile from '../../components/search-notifications-profile/SearchNotificationsProfile';
import StoreWelcomeSharedSection from '../store-pages/store-welcome-shared-section/StoreWelcomeSharedSection';
import TableFilter from './TableFilter';
import PageTable from './PageTable';
import ShiftsContext from '../../contexts/shifts-context/ShiftsContext';
import SelectedShiftModal from './SelectedShiftModal';
import './ShiftsPage.scss';
import { useTranslation } from 'react-i18next';
const ShiftsPage = () => {
  const { selectedShiftModalOpened } = useContext(ShiftsContext);
  const {t} = useTranslation()
  return (
    <div className="shifts-page">
      <div className="top-section-wrap">
        <StoreWelcomeSharedSection
          img={storeItemManagerImg}
          title= {t('Shift.title')}
          subTitle={t('Shift.subTitle')}
        />
        <div className="search-wrap">
          <SearchNotificationsProfile noSearch />
        </div>
      </div>

      <TableFilter />
      <div className="table-wrapper">
        <PageTable />
      </div>
      {selectedShiftModalOpened && <SelectedShiftModal />}
    </div>
  );
};

export default ShiftsPage;
