import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  fetchedAccountingDetails: null,
  setFetchedAccountingDetails: (v) => {},
  selectedAccounting: null,
  setSelectedAccounting: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {}
};

const AccountingDetailsContext = createContext(INITIAL_VALUES);

export const AccountingDetailsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [fetchedAccountingDetails, setFetchedAccountingDetails] = useState(
    INITIAL_VALUES.fetchedAccountingDetails
  );
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [selectedAccounting, setSelectedAccounting] = useState(
    INITIAL_VALUES.selectedAccounting
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );

  return (
    <AccountingDetailsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fetchCount,
        setFetchCount,
        fetchedAccountingDetails,
        setFetchedAccountingDetails,
        selectedAccounting,
        setSelectedAccounting,
        modalOpened,
        setModalOpened,
        isSubmittingForm,
        setIsSubmittingForm
      }}
    >
      {children}
    </AccountingDetailsContext.Provider>
  );
};

export default AccountingDetailsContext;
