import React from 'react';
import { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { IncomingPurchaseInvoiceProvider } from '../../contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import { OutgoingPurchaseInvoiceProvider } from '../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import UserContext from '../../contexts/user-context/UserProvider';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import IncomingPurchaseInvoicePage from '../../pages/store-pages/store-incoming-purchase-invoices-page/IncomingPurchaseInvoicePage';
import OutgoingPurchaseInvoicePage from '../../pages/store-pages/store-outgoing-purchase-invoices-page/OutgoingPurchaseInvoicePage';
import protectMe from '../../utils/protectMe';
import AccountingRoutes from './accounting-routes/AccountingRoutes';
import PriceOffersRoutes from './price-offers-routes/priceOffersRoutes';
import ReportsRoutes from './reports-routes/ReportsRoutes';
import routerLinks from './routerLinks';
import Links from './Links';

const AccountantRoleRoutes = () => {
  const { user, loggedIn } = useContext(UserContext);
  const routerLinks = Links();
  return (
    <Switch>
      {/* accounts routes */}
      {AccountingRoutes()}
      {/* ./accounts routes */}
      {protectMe(
        routerLinks.incomingPurchaseInvoiceReports,
        <IncomingPurchaseInvoiceProvider>
          <IncomingPurchaseInvoicePage reports={true} withoutReturnBtn={true} />
        </IncomingPurchaseInvoiceProvider>,
        409090,
        routerLinks.signInPage,
        loggedIn,
        user?.expire
      )}

      {protectMe(
        routerLinks.outgoingPurchaseInvoiceReports,
        <OutgoingPurchaseInvoiceProvider>
          <OutgoingPurchaseInvoicePage
            reports={true}
            withoutReturnBtn={true}
            isReportsPage={true}
          />
        </OutgoingPurchaseInvoiceProvider>,
        9997771,
        routerLinks.signInPage,
        loggedIn,
        user?.expire
      )}

      {/* price offers routes */}
      {PriceOffersRoutes()}
      {/* ./price offers routes */}

      {/* Reports routes */}
      {ReportsRoutes()}
      {/* Reports routes */}
      {/* not found page */}
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default AccountantRoleRoutes;
