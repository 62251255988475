import axios from 'axios';

const createListIntegrationApi = async (token, values, edit = false, id) => {
  try {
    let url;
    let requestData = { ...values };

    if (!edit) {
      url = '/integration/create';
    } else {
      url = `/integration/update/${id}`;

      // If it's an edit request, send data as FormData
      const formData = new FormData();
      Object.keys(values).forEach(key => {
        formData.append(key, values[key]);
      });
      requestData = formData;
    }
    const res = await axios({
      method: 'post',
      url,
      baseURL: process.env.REACT_APP_BASE_TEST_URL,
      // baseURL: 'https://testclient-api.fatoorah.sa/apiAdmin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data', // Use multipart/form-data for FormData
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      },
      data: requestData // Send either JSON or FormData based on the request type
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export default createListIntegrationApi;
