import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedOrders: null,
  setAllFetchedOrders: (v) => {},
  selectedOrder: null,
  setSelectedOrder: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  selectedOrderModalOpened: false,
  setSelectedOrderModalOpened: (v) => {},
  nameSearchValue: '',
  setNameSearchValue: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {}
};

const StorePurchaseOrderContext = createContext(INITIAL_VALUES);

export const StorePurchaseOrderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedOrders, setAllFetchedOrders] = useState(
    INITIAL_VALUES.allFetchedOrders
  );
  const [selectedOrder, setSelectedOrder] = useState(
    INITIAL_VALUES.selectedOrder
  );
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [selectedOrderModalOpened, setSelectedOrderModalOpened] = useState(
    INITIAL_VALUES.selectedOrderModalOpened
  );
  const [nameSearchValue, setNameSearchValue] = useState(
    INITIAL_VALUES.nameSearchValue
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES?.tablePagination
  );

  const [tableDataSource, setTableDataSource] = useState([]);

  return (
    <StorePurchaseOrderContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fetchCount,
        setFetchCount,
        allFetchedOrders,
        setAllFetchedOrders,
        selectedOrder,
        setSelectedOrder,
        modalOpened,
        setModalOpened,
        selectedOrderModalOpened,
        setSelectedOrderModalOpened,
        nameSearchValue,
        setNameSearchValue,
        isSubmittingForm,
        setIsSubmittingForm,
        tablePagination,
        setTablePagination,
        tableDataSource,
        setTableDataSource
      }}
    >
      {children}
    </StorePurchaseOrderContext.Provider>
  );
};

export default StorePurchaseOrderContext;
