import axios from 'axios';

export const signinApi = async (values, langIsoCode) => {
  try {
    const signinRes = await axios.post('/Auth_general/login', values, {
      headers: {
        lang: langIsoCode
      }
    });
    return signinRes;
  } catch (error) {
    throw error;
  }
};
