/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import getSingleWarehouseApi from '../../../apis/settings-apis/settings-warehouses-apis/getSingleWarehouseApi';
import { Descriptions, Table, Spin, Empty } from 'antd';
import UserContext from '../../../contexts/user-context/UserProvider';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import './SettingsWarehousesDetailsPage.scss';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useTranslation } from 'react-i18next';

const SettingsWarehousesDetailsPage = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = React.useState(null);
  const { user } = useContext(UserContext);
  const { id } = useParams();

  const customApiRequest = useCustomApiRequest();

  React.useEffect(() => {
    let isMounted = true;
    if (id && isMounted) {
      setIsLoading(true);
      customApiRequest(
        getSingleWarehouseApi(id, user?.token),
        (res) => {
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setSelectedWarehouse({ ...res.data.data });
          } else {
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }
    return () => {
      isMounted = false;
    };
  }, [id]);

  const renderStockData = () => {
    const { id, name, allQuantity, note, workplace } =
      selectedWarehouse.stockData;
    return (
      <div className="supplier-details-wrap">
        <Descriptions
          title={t('Inventory.Warehouses.inventoryData')}
          bordered
          column={1}
        >
          {id && (
            <Descriptions.Item label={t('Inventory.Warehouses.code')}>
              {id}
            </Descriptions.Item>
          )}
          {name && (
            <Descriptions.Item label={t('Inventory.Warehouses.name')}>
              {name}
            </Descriptions.Item>
          )}
          {allQuantity && (
            <Descriptions.Item
              label={t('Inventory.Warehouses.quantityInsideWarehouse')}
            >
              {allQuantity}
            </Descriptions.Item>
          )}
          {note && (
            <Descriptions.Item label={t('Inventory.Warehouses.note')}>
              {note}
            </Descriptions.Item>
          )}
          {workplace && workplace.name && (
            <Descriptions.Item label={t('Inventory.Warehouses.workplace')}>
              <div>
                <span>{t('Inventory.Warehouses.name')} : </span>
                <span>{workplace.name}</span>
              </div>
              {workplace.address && (
                <div>
                  <span>{t('Inventory.Warehouses.address')} : </span>
                  <span>{workplace.address}</span>
                </div>
              )}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  };

  const renderStockDetails = () => {
    const details = selectedWarehouse.StockDetails;
    if (details?.length > 0) {
      return (
        <div className="models-wrap" style={{ marginTop: 32 }}>
          <div className="models-title bold-font">
            {t('Inventory.Warehouses.descriptionOfProductOrService')}
          </div>
          <Table
            locale={{
              emptyText: (
                <Empty description={false}>
                  {t('Inventory.Warehouses.noDataAvailable')}
                </Empty>
              )
            }}
            pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            className="antd-custom-table"
            dataSource={details.map((item) => ({
              ...item,
              key: item.id
            }))}
            columns={[
              {
                title: t('Inventory.Warehouses.code'),
                dataIndex: 'id',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-index bold-font">
                      <span># {record.id}</span>
                    </div>
                  );
                }
              },
              {
                title: t('Inventory.Warehouses.name'),
                dataIndex: 'name',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.name ? record.name : '----'}</span>
                    </div>
                  );
                }
              },
              {
                title: t('Inventory.Warehouses.type'),
                dataIndex: 'type',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>
                        {record.type
                          ? record.type == '1'
                            ? t('Inventory.Warehouses.product')
                            : t('Inventory.Warehouses.productIngredient')
                          : '---'}{' '}
                      </span>
                    </div>
                  );
                }
              },
              {
                title: t('Inventory.Warehouses.quantity'),
                dataIndex: 'quantity',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.quantity ? record.quantity : '-----'}</span>
                    </div>
                  );
                }
              },
              {
                title: t('Inventory.Warehouses.basicQuantityOfIngredient'),
                width: 142,
                dataIndex: 'basicQuantity',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>
                        {record.type == '2' && record.basicQuantity
                          ? parseFloat(record.basicQuantity).toFixed(2)
                          : '-----'}
                      </span>
                    </div>
                  );
                }
              }
            ]}
            // loading={isLoading}
            // scroll={{ x: 400 }}
            footer={false}
          />
        </div>
      );
    }
    return null;
  };

  if (!isLoading && selectedWarehouse) {
    return (
      <div className="settings-warehouses-details-page">
        <div className="page-head-wrap">
          <div className="page-title">
            <h2 className="bold-font">{selectedWarehouse?.stockData?.name}</h2>
          </div>
          <SearchNotificationsProfile />
        </div>
        {renderStockData()}
        {renderStockDetails()}
      </div>
    );
  } else if (isLoading && !selectedWarehouse) {
    return (
      <div className="settings-warehouses-details-page">
        <Spin />
      </div>
    );
  } else {
    return (
      <div className="settings-warehouses-details-page">
        <div className="page-head-wrap">
          <div className="page-title">
            <h2 className="bold-font">
              {t('Inventory.Warehouses.measurementUnits')}
            </h2>
            <p>
              {t('Inventory.Warehouses.detectDisplayAllUnitsOfMeasurement')}
            </p>
          </div>
          <SearchNotificationsProfile />
        </div>
        <div>{t('Inventory.Warehouses.noDataAvailable')}</div>
      </div>
    );
  }
};

export default SettingsWarehousesDetailsPage;
