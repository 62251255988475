export const subCategoriesArray = {
  Airlines: [
    "الخطوط الجوية المتحدة" ,
    "أمريكان إيرلاينز" ,
    'للبلدان الأمريكية',
    "ترانس وورلد إيرلاينز" ,
    'الخطوط الجوية البريطانية',
    "الخطوط الجوية اليابانية" ,
    'الخطوط الجوية الفرنسية',
    "لوفتهانزا" ,
    'الطيران الكندي',
    " (الخطوط الجوية الملكية الهولندية)" ,
    "كوانتاس" ,
    "أليتاليا" ,
    "الخطوط الجوية العربية السعودية" ,
    "الخطوط الجوية لجنوب إفريقيا",
    "فاريج (البرازيل)" ,
    "طيران الهند",
    "الخطوط الجوية الجزائرية",
    "الخطوط الجوية الفلبينية" ,
    "مكسيكانا",
    "باكستان الدولية",
    "طيران نيوزيلندا",
    "طيران مالطا",
    "سابينا",
    "الخطوط الجوية الأرجنتينية" ,
    "الخطوط الجوية الأولمبية" ,
    'العال',
    "خطوط أنسيت الجوية" ,
    "الخطوط الجوية الأسترالية" ,
    "تاب (البرتغال)" ,
    "فازب (البرازيل)" ,
    'الخطوط المصرية',
    "الخطوط الجوية الكويتية",
    "أفيانكا" ,
    "طيران الخليج (البحرين" ,
    "البلقان البلغارية الخطوط الجوية" ,
    "فينير",
    "إير لينجوس",
    "طيران لانكا",
    "الخطوط الجوية النيجيرية",
    "الخطوط الجوية البرازيليه" ,
    'الخطوط الجوية التركية',
    "الخطوط الملكية المغربية" ,
    "الخطوط الجوية التونسية",
    "آيسلاندر",
    'الخطوط الجوية النمساوية',
    "لانشيل",
    "أفياكو (إسبانيا)" ,
    "لاديكو (تشيلي)" ,
    "لاب (بوليفيا)" ,
    "كيبيكير" ,
    "إيست ويست إيرلاينز (أستراليا)" ,
    "دلتا" ,
    'الشمال الغربي',
    "كونتيننتال" ,
    'الغربي',
    "طيراننا" ,
    "Airinter" ,
    'جنوب غرب',
    "طيران كولومبيا البريطانية" ,
    'خطوط الطيران السنغافورية',
    "طيران المكسيك",
    'الخطوط الجوية التايلاندية',
    "الخطوط الجوية الصينية" ,
    "نوردير" ,
    "الخطوط الجوية الكورية" ,
    "طيران افريقيا" ,
    "إيفا إيرلاينز" ,
    "ميدويست إكسبريس إيرلاينز إنك" ,
    "مترو ايرلاينز" ,
    "الخطوط الجوية الكرواتية" ,
    "ترانسايرو",
    "الخطوط الجوية الزامبية",
    "طيران زيمبابوي",
    'كاثي باسيفيك',
    "نظام الخطوط الجوية الماليزي" ,
    "أيبيريا",
    'جارودا اندونيسيا)',
    'Braathens S.A.F.E. (النرويج)',
    "وينغز إيرويز" ,
    "ميدلاند البريطانية" ,
    "جزيرة ويندوارد" ,
    "فياسا",
    "فالي إيرلاينز" ,
    "تان",
    "تالير",
    "Taca International",
    "خطوط سورينام الجوية",
    "صن وورلد إنترناشيونال" ,
    "Sunbelt Airlines" ,
    "الخطوط الجوية السودانية",
    "سينجلتون",
    "سيمونز إيرلاينز" ,
    "سينيك ايرلاينز",
    'فيرجين أتلانتيك',
    "خطوط سان خوان الجوية" ,
    "لوكسير",
    "طيران زائير",
    "Princeville",
    "Pba" ,
    'جميع الخطوط الجوية نيبون',
    "نورونتير",
    "هليكوبتر نيويورك" ,
    "نونت كوك",
    "الخطوط الجوية الكندية الدولية" ,
    "Nationair",
    "خطوط ميتروفلايت الجوية" ,
    "ميسا للطيران" ,
    "ماليف",
    "لوت (بولندا)" ,
    "ليات",
    "لاف (فنزويلا)" ,
    "لاب (باراجواي)" ,
    "لاكسا (كوستاريكا)" ,
    "يوغوسلاف إير",
    "آيلاند إيرلاينز" ,
    "إيران للطيران",
    "الخطوط الجوية الهندية" ,
    "طيران هاواي",
    "خطوط هافاسو الجوية" ,
    "Fuyana Airways",
    "جولدن باسيفيك اير" ,
    "طيران الحرية" ,
    "دومينيكانا" ,
    "الخطوط الجوية الإقليمية Braathens" ,
    "دان للطيران للخدمات",
    "كمبرلاند إيرلاينز" ,
    "Csa" ,
    "كراون للطيران" ,
    "كوبا",
    "كومبانيا فوسيت" ,
    "ينقل إيروس ميليتاريس إكواتورانوس" ,
    "كوماند إيرويز" ,
    "كومير",
    "خطوط كايمان الجوية" ,
    "Saeta Sociaedad Ecuadoratorianos De Transportes",
    "Sasha Servicio Aero De Honduras" ,
    "كابيتول إير",
    "بويا",
    "طيران بروكواي",
    "بيميدجي إيرلاينز" ,
    "خطوط بار هاربور الجوية" ,
    "جزر الباهاما",
    "Aviateca (غواتيمالا)" ,
    "أفينسا",
    "الخطوط الجوية النمساوية",
    "ألوها إيرلاينز" ,
    "ألم" ,
    "أمريكا الغربية" ,
    "ترامب للطيران" ,
    "خطوط ألاسكا الجوية" ,
    "أمريكان ترانس إير" ,
    'الطيران الصيني',
    "طيران رينو" ,
    "طيران سيشل",
    "طيران بنما",
    "طيران جامايكا",
    "طيران جيبوتي",
    "جزر آيرو فيرجن" ,
    "ايرو بيرو" ,
    "أيرو كوتش أفاياتيون" ,
    "أريانا أفغان" ,
    "الخطوط الجوية القبرصية" ,
    "أكواتوريانا",
    "الخطوط الجوية الإثيوبية" ,
    "الخطوط الجوية الكينية" ,
    "طيران موريشيوس",
    "طيران فلاي بي"
  ],
  Car_Rental: [
    "شركة تابعة لتأجير السيارات",
    "الأمريكية الدولية لتأجير السيارات" ,
    "بروكس لتأجير السيارات" ,
    "أكشن لتاجير السيارات",
    'سيكست لتأجير السيارات' ,
    "هيرتز" ,
    "بايليس لتأجير السيارات",
    "سنابي لتأجير السيارات",
    "الخطوط الجوية لتأجير السيارات",
    ' ألترا لتأجير السيارات' ,
    'وكالة تأجير السيارات' ,
    "بدجت لتأجير السيارات",
    "هوليداي لتأجير السيارات",
    'إيجار الحطام',
    "أكسنت لتأجير السيارات" ,
    'أجاكس لتأجير السيارات' ,
    "المثلث لتأجير السيارات",
    "أوروب كار" ,
    "تروبيكال لتأجير السيارات",
    "عرض تأجير السيارات",
    "ألامو لتأجير السيارات" ,
    "التجار لتأجير السيارات" ,
    "أفيس لتأجير السيارات" ,
    "دولار لتأجير السيارات",
    "أوروبا بالسيارة" ,
    "الوطنية لتأجير السيارات",
    "مجموعة كيموول لتأجير السيارات",
    "ثريفتي لتأجير السيارات",
    "تيلدن لتأجير السيارات" ,
    "إيكونو لتأجير السيارات" ,
    "استضافة السيارات لتأجير السيارات",
    "إنتربرايز لتأجير السيارات",
    "تأجير السيارات العامة",
    "أ -1 لتأجير السيارات",
    "جودفري ناتل لتأجير السيارات" ,
    "ألفا لتأجير السيارات" ,
    "أنسا الدولية لتأجير السيارات" ,
    "أفكار لتأجير السيارات" ,
    "أتمتة لتأجير السيارات" ,
    "أفون لتأجير السيارات" ,
    "كاري لتأجير السيارات" ,
    "التأمين لتأجير السيارات",
    "الرئيسية لتأجير السيارات",
    "استبدال لتأجير السيارات",
    "حجز لتأجير السيارات",
    "البطة القبيحة لتأجير السيارات" ,
    "الولايات المتحدة لتأجير السيارات",
    "القيمة لتأجير السيارات",
    "اوتوهانزا لتأجير السيارات" ,
    'استشهد لتأجير السيارات',
    "إنترنت لتأجير السيارات",
    "ميلفيل لتأجير السيارات" ,
    "ادفانتج لتأجير السيارات"
  ],
  education: [
    "قاعة رقص , استوديوهات , مدارس" ,
    "الابتدائية والثانوية",
    "الكليات والجامعات",
    "مدارس المراسلة" ,
    "مدارس الأعمال / السكرتارية",
    "المدارس المهنية / التجارية",
    'خدمات تعليمية',
    "خدمات رعاية الطفل"
  ],
  electronics: [
    "أجهزة الكمبيوتر والأجهزة الطرفية والبرامج" ,
    "قطع غيار ومعدات كهربائية",
    "الأجهزة والمعدات واللوازم",
    'محل بيع الاجهزة الالكترونية',
    "متاجر الإلكترونيات",
    "متاجر مستلزمات الكاميرات والتصوير الفوتوغرافي",
    "محلات الحلاقة الكهربائية"
  ],
  fashion: [
    "زي موحد , ملابس تجارية",
    "الأحذية التجارية",
    "محلات الملابس والاكسسوارات الرجالية والصبية" ,
    "محلات الملابس النسائية الجاهزة" ,
    "الإكسسوارات النسائية والمتاجر المتخصصة" ,
    "متاجر ملابس الأطفال والرضع" ,
    "متاجر الملابس العائلية",
    "متاجر الملابس الرياضية وركوب الخيل",
    'محلات الأحذية',
    "تجار الفراء ومحلات الفراء",
    "متاجر الملابس الرجالية والنسائية" ,
    "خياطون , تعديلات",
    "متاجر الباروكة والشعر المستعار" ,
    "محلات الملابس والإكسسوارات المتنوعة",
    "محلات المجوهرات والساعات والساعات والأدوات الفضية",
    "التسويق المباشر - تاجر الكتالوج" ,
    "التسويق المباشر - كتالوج المجموعة وتاجر التجزئة"
  ],
  flowers_gifts: [
    "مستلزمات بائعي الزهور ومخزون الحضانة والزهور",
    "محلات الهوايات والألعاب والألعاب" ,
    "محلات الهدايا , والبطاقات , والتحف , والهدايا التذكارية" ,
    "باعة زهور"
  ],
  Food: [
    "زي موحد , ملابس تجارية",
    "الأحذية التجارية",
    "محلات الملابس والاكسسوارات الرجالية والصبية" ,
    "محلات الملابس النسائية الجاهزة" ,
    "الإكسسوارات النسائية والمتاجر المتخصصة" ,
    "متاجر ملابس الأطفال والرضع" ,
    "متاجر الملابس العائلية",
    "متاجر الملابس الرياضية وركوب الخيل",
    'محلات الأحذية',
    "تجار الفراء ومحلات الفراء",
    "متاجر الملابس الرجالية والنسائية" ,
    "خياطون , تعديلات",
    "متاجر الباروكة والشعر المستعار" ,
    "محلات الملابس والإكسسوارات المتنوعة",
    "محلات المجوهرات والساعات والساعات والأدوات الفضية",
    "التسويق المباشر - تاجر الكتالوج" ,
    "التسويق المباشر - كتالوج المجموعة وتاجر التجزئة"
  ],
  government: [
    "غرامات - جهات إدارية حكومية",
    "مدفوعات الضرائب - الجهات الحكومية",
    "مدفوعات الضرائب - الجهات الحكومية",
    "خدمات حكومية (غير مصنفة في مكان آخر)" ,
    "خدمات بريدية - حكومية فقط",
    'نحن. الوكالات أو الإدارات الحكومية الاتحادية ',
    "اليانصيب المملوك للحكومة (لغير الأمريكيين)" ,
    "المشتريات داخل الشركة"
  ],
  Grocery: [
    "الأدويه ومشتقات الأدويه",
    "محلات البقالة , السوبر ماركت" ,
    "مزودي اللحوم المجمدة والخزانات" ,
    "محلات الحلوى والمكسرات والحلويات" ,
    "محلات منتجات الألبان",
    "مخابز",
    "متاجر المواد الغذائية المتنوعة - المتاجر الصغيرة والأسواق المتخصصة" ,
    "مخازن الأدوية والصيدليات",
    "مخازن العبوات - البيرة والنبيذ والمشروبات الكحولية"
  ],
  loding: [
    "هوليداي إن إكسبريس / هوليداي إن" ,
    "فنادق بيست ويسترن",
    "فنادق شيراتون",
    "فنادق هيلتون",
    "فنادق فورتي",
    "فنادق جولدن توليب",
    "فنادق الصداقة",
    "كواليتي إنز / كواليتي سويتس",
    "ماريوت" ,
    "دايز إن / داي ستوب" ,
    "فنادق أرابيلا",
    "فنادق إنتركونتيننتال" ,
    "فنادق ويستن",
    "Amerisuites" ,
    "رودواي إنز",
    "لا كوينتا موتور إنز" ,
    "فنادق أمريكانا",
    "فنادق سول",
    'فنادق بولمان الدولية' ,
    "فنادق ميريديان",
    'منتجع رويال لاهينا' ,
    "فندق طوكيو" ,
    "فنادق بينينسولا",
    'فنادق أهلا',
    "فنادق دنفي" ,
    "فنادق برنس",
    'فنادق داون تاونر باسبورت',
    "فنادق ريد ليون / فنادق ريد لايون" ,
    "فنادق السى بى",
    "فنادق رينيسانس" ,
    "منتجع شاطئ كاواي كوكونت" ,
    'منتجع رويال كونا' ,
    "فندق IBIS" ,
    "فنادق جنوب المحيط الهادئ",
    'هيلتون العالمية' ,
    "فنادق أمفاك" ,
    "فنادق آنا",
    "فنادق كونكورد",
    " فندق سامرفيلد سويتس" ,
    "فنادق إيبروتيل",
    "فندق أوكورا" ,
    "فنادق رويال",
    "فنادق فورسيزونز",
    "فنادق سيجا",
    "شانغريلا الدولية" ,
    'فنادق سيرا سيوتس',
    'منتجع ذا بريكرز' ,
    "فنادق ميليا" ,
    "Auberge Des Governeurs" ,
    'Regal 8 Inns',
    'فندق وكازينو ميراج' ,
    "فنادق الساحل",
    "باركس إنز إنترناشيونال",
    "منتجع بينهورست" ,
    "فندق وكازينو Treasure Island" ,
    'منتجع بارتون كريك' ,
    "مانهاتن إيست للأجنحة الفندقية",
    "فنادق جولي",
    'أجنحة كاندلوود' ,
    "منتجع وكازينو علاء الدين" ,
    'الكتلة الذهبية',
    "كومفورت إنز",
    "Journey's End فنادق" ,
    "Sam's Town فندق و كازينو" ,
    "ريلاكس إنز",
    "فندق جاردن بليس" ,
    "فندق سوهو جراند" ,
    "فنادق Ladbroke",
    "فندق جراند تريبيكا" ,
    "فنادق المنتدى",
    "منتجع جراند وايليا" ,
    "فنادق مياكو",
    "فنادق ساندمان",
    "فينشر إن" ,
    "فنادق فاجابوند",
    'منتجع لا كوينتا' ,
    "فندق ماندارين أورينتال" ,
    "Frankenmuth Bavarian" ,
    "فندق ميركيور" ,
    "فندق ديل كورونادو" ,
    "دلتا للفنادق" ,
    "فندق وكازينو كاليفورنيا" ,
    "فنادق ساس",
    " فندق برنسيس انترناشيونال" ,
    "فنادق المجر",
    'فندق سوكوس' ,
    "فنادق دورال",
    "فنادق هيلمسلي" ,
    'منتجع دورال للجولف' ,
    "فنادق فيرمونت",
    "فنادق سونستا",
    'فنادق أومني',
    "فنادق كونارد",
    "أريزونا بيلتمور" ,
    "هوسبيتاليتي إنز",
    "وين لاس فيغاس" ,
    'فندق وكازينو منتجع ريفرسايد' ,
    "فنادق ريجنت الدولية" ,
    "فنادق بانونيا",
    'منتجع سادلبروك - تامبا' ,
    'منتجع تريد ويندز' ,
    "فندق هدسون" ,
    "فندق نوح (مليبن)" ,
    "هيلتون جاردن إن" ,
    "مجموعة فنادق Jurys Doyle (M / C فقط)" ,
    "فندق جيفرسون (M / C فقط)" ,
    "منتجعات فاونتنبلو",
    "جايلورد أوبريلاند" ,
    "جايلورد بالمز" ,
    'فنادق موفنبيك',
    "Microtel Inns & Suites",
    "AmericInn (MC فقط اعتبارًا من 6/2006)" ,
    "ترافيلودج" ,
    "نادي بينيون للحصان" ,
    ' فندق ميرلين (بيرث)' ,
    "Dorint فنادق",
    'فندق وكازينو ليدي لاك' ,
    "فندق يونيفرسال" ,
    'فندق وكازينو إكسكاليبور' ,
    "فنادق دان",
    "سليب إن" ,
    "الفينيقي" ,
    "تصنيف الفنادق",
    "سويسوتيل" ,
    "فنادق ريسو",
    "فنادق ساروفا",
    "رمادا إنز" ,
    "Ho Jo Inn / Howard Johnson" ,
    "ماونت شارلوت ثيسل" ,
    "فنادق حياة",
    "فنادق سوفيتيل",
    'نوفوتيل سيه (أكور)' ,
    "فنادق شتايجنبرجر",
    "Econo Lodges",
    "منازل كوينز خندق" ,
    "فنادق السنونو",
    "فندق هوسا",
    "De Vere فنادق",
    "فنادق راديسون" ,
    "ريد روف إنز",
    "إمبريال لندن هوتيلز",
    "فنادق السفارة",
    "فنادق بنتا",
    "Loews فنادق",
    'فنادق سكانديك',
    'فنادق سارة' ,
    "فنادق أوبروي",
    "فنادق أوتاني",
    'فنادق تاج الدولية' ,
    "نايتس إن" ,
    "فنادق متروبول",
    'فندق وكازينو سيركس سيركس' ,
    "فنادق الرئيس" ,
    "فلاج إن" ,
    "هامبتون إن",
    "فنادق ستاكيس",
    'فندق وكازينو الأقصر' ,
    'فنادق ماريتيم',
    'فندق وكازينو الدورادو' ,
    "فنادق أركيد",
    "فنادق أركتيا",
    "فنادق كامبانيل",
    "فنادق إبوز",
    "فنادق رانتاسيبي",
    "إنترهوتيل سيدوك" ,
    'فندق وكازينو مونتي كارلو' ,
    "Climat De France فنادق",
    "فنادق كومولوس",
    'فندق وكازينو سيلفر لاغاسي' ,
    "حوطي أوتان" ,
    "فنادق آدمز مارك" ,
    'فندق وكازينو صحارى' ,
    "أجنحة برادبري" ,
    "بدجت هوست إنز",
    'Budgetel Inns',
    'شاليهات سوس',
    "فندق كلاريون" ,
    "فندق كومبري" ,
    "فندق كونسورت" ,
    "كورتيارد باي ماريوت" ,
    "ديلون إن" ,
    "أجنحة ضيوف مزدوجة / فنادق دبل تري)" ,
    "دروري إن" ,
    "إيكونومي إنز أوف أمريكا" ,
    "أجنحة السفارة" ,
    "إكسيل إن" ,
    "فندق فيرفيلد" ,
    "فندق هارلي" ,
    "ميدواي موتور لودج" ,
    "موتيل 6",
    "لا مانشن ديل ريو" ,
    "فنادق السجل" ,
    "ريزيدنس إنز",
    "فندق رويس" ,
    "ساندمان إن" ,
    "شيلو إن" ,
    "Shoney's Inn" , "فندق وكازينو فيرجن ريفر" ,
    "سوبر 8 موتيل" ,
    "الريتز كارلتون" ,
    'فلاج إنز (أستراليا)',
    "فندق وكازينو بافالو بيل" ,
    "فندق كواليتي باسيفيك" ,
    "فندق فور سيزونز (أستراليا)" ,
    "فيرفيلد إن" ,
    "فنادق كارلتون" ,
    "فنادق سيتي لودج",
    "فنادق كاروس",
    "فنادق بروتيا",
    "فنادق جنوب صن",
    "هيلتون كونراد" ,
    "ويندهام" ,
    "فنادق ريكا",
    "فنادق إنتر نور",
    "مزرعة سي باينز / منتجع سي باينز" ,
    "أجنحة ريو" ,
    "فندق برودمور" ,
    "فندق وكازينو بالي" ,
    "John Ascuaga's Nugget , MGM Grand فندق ",
    "فنادق وكازينوهات حارة" ,
    "فندق أوبريلاند" ,
    'منتجع بوكا راتون' ,
    "فنادق هارفي / بريستول" ,
    'Masters Economy Inns',
    "كولورادو بيل / منتجع إيدجووتر" ,
    'فندق وكازينو ريفييرا' ,
    'منتجع وكازينو تروبيكانا' ,
    "وودسايد للفنادق والمنتجعات" ,
    "تاون بليس سويتس",
    "فنادق ميلينيوم",
    'نادي ميد',
    'فندق وأجنحة بيلتمور' ,
    "منتجعات كيرفري",
    'فندق سانت ريجيس' ,
    'فندق إليوت' ,
    "نادى Corp / نادى Resorts" ,
    "ويلسلي إنز",
    'فندق بيفرلي هيلز' ,
    'فنادق كراون بلازا',
    'أجنحة هوموود' ,
    "فنادق بيبودي",
    "منتجعات Greenbriar" ,
    'مزرعة جزيرة أميليا' ,
    "العزبة",
    "منتجعات البحار الجنوبية" ,
    "كانيون رانش",
      "فندق كاهالا ماندارين أورينتال" ,
    "الأوركيد في ماونا لاني" ,
    'فندق Halekulani / Waikiki Parc',
    'فندق وكازينو Primadonna' ,
    "فندق وكازينو ويسكي بيت" ,
    "شاتو إيلان وينري آند ريزورت" ,
    'فندق وكازينو بوريفاج' ,
    "بيلاجيو",
    'فندق وكازينو فريمونت' ,
    'فندق وكازينو مين ستريت ستيشن' ,
    'فندق وكازينو سيلفر ستار' ,
    'فندق وكازينو ستراتوسفير' ,
    'أجنحة سبرينغهيل' ,
    'فندق وكازينو سيزرز' ,
    "نيماكولين وودلاندز" ,
    "كازينو فندق منتجع فينيتيان" ,
    "نيويورك - فندق وكازينو نيويورك" ,
    "منتجع ساندز" ,
    "Nevele Grande Resort And Country نادى" ,
    "منتجع ماندالاي باي" ,
    'فنادق فور بوينتس' ,
    "فنادق دبليو",
    "منتجعات ديزني" ,
    "فنادق باتريشيا جراند ريزورت" ,
    "Rosen فنادق And Resorts" ,
    "منتجع تاون آند كانتري" ,
    "الضيافة الأولى للفنادق" ,
    "فنادق ومنتجعات Outrigger" ,
    "فنادق أوهانا في هاواي" ,
    "منتجعات كاريبي رويال" ,
    "فنادق علاء موانا",
    "منتجع نوتش للمهربين" ,
    "فنادق رافلز",
    'أجنحة ستايبريدج' ,
    "فندق كلاريدج كازينو" ,
    "فنادق فلامنجو",
    "جراند كازينو الفنادق",
    "فندق باريس لاس فيغاس" ,
    "فندق كازينو بيبرميل" ,
    "منتجعات أتلانتيك سيتي هيلتون" ,
    'منتجع أمباسي فاكيشن' ,
    "فندق هيل كوا" ,
    'منتجعات هوشينو' ,
    "فنادق كيمبتون"
  ],
  Miscellaneous_Merchandise: [
    "المستودعات العامة والتخزين - المنتجات الزراعية , البضائع المبردة , السلع المنزلية" ,
    "مستلزمات المركبات وقطع الغيار الجديدة",
    "أثاث مكتبي وتجاري",
    "مواد البناء (غير مصنفة في مكان آخر)" ,
    "معدات ولوازم التصوير الفوتوغرافي والنسخ والميكروفيلم",
    "معدات تجارية (غير مصنفة في مكان آخر)" ,
    "المعدات واللوازم الطبية وطب الأسنان والعيون والمستشفيات" ,
    "معدات ولوازم السباكة والتدفئة",
    "التوريدات الصناعية (غير مصنفة في مكان آخر)" ,
    "أحجار ومعادن ثمينة , ساعات ومجوهرات",
    "البضائع المعمرة (غير مصنفة في مكان آخر)" ,
    "قرطاسية , لوازم مكتبية , ورق طباعة وكتابة",
    "السلع المقطوعة والمفاهيم والسلع الجافة الأخرى" ,
    "الكتب والدوريات والصحف",
    "الدهانات والورنيشات والتجهيزات",
    "البضائع غير المعمرة (غير مصنفة في مكان آخر)" ,
    "مخازن تموين المنازل",
    "أخشاب ومخازن مواد بناء",
    "محلات الزجاج والدهانات وورق الحائط",
    'مشاتل ومحلات مستلزمات الحدائق' ,
    "سوق على الإنترنت" ,
    "تجار المنازل المتنقلة",
    "نوادي البيع بالجملة",
    "المتاجر الحرة",
    'محلات التخفيضات',
    'المتاجر',
    "متاجر متنوعة",
    "بضائع عامة متنوعة" ,
    "تجار السيارات والشاحنات (الجديدة والمستعملة) المبيعات والخدمة وإصلاح قطع الغيار والتأجير" ,
    "تجار السيارات والشاحنات (المستعملة فقط) المبيعات والخدمة وإصلاح قطع الغيار والتأجير" ,
    "متاجر توريد السيارات والمنزلية",
    "متاجر إطارات السيارات" ,
    "محلات قطع غيار واكسسوارات السيارات",
    "تجار القوارب" ,
    'محلات الدراجات النارية' , 'تجار' ,
    "محلات وتجار الدراجات النارية",
    "تجار منازل السيارات",
    "تجار الثلج" ,
    "تجار سيارات متنوعون" ,
    "محلات الأثاث والمفروشات والمعدات عدا الأجهزة" ,
    "محلات كسوة الأرضيات",
    "محلات الستائر وأغطية النوافذ والمفروشات",
    "الموقد , وشاشات الموقد , ومتاجر الملحقات" ,
    "متاجر متنوعة لتأثيث المنزل",
    "محلات الأجهزة المنزلية",
    "متاجر الموسيقى - آلات موسيقية , بيانو , ونوتة موسيقية",
    "متاجر برامج الكمبيوتر",
    "مخازن التسجيلات",
    "البضائع المستعملة والمتاجر المستعملة" ,
    "محلات التحف",
    "محلات البيدق",
    ' ساحات التحطيم والإنقاذ' ,
    "النسخ العتيقة" ,
    "محلات دراجات",
    "متاجر السلع الرياضية",
    "متاجر الكتب",
    "محلات القرطاسية , والمكاتب , ومخازن اللوازم المدرسية",
    "متاجر الأمتعة والمنتجات الجلدية",
    "محلات الخياطة والتطريز والأقمشة والقطع" ,
    "الأواني الزجاجية , محلات الكريستال",
    "محلات توريد الفنانين والحرف اليدوية" ,
    "تجار الفن ومعارضه" ,
    "متاجر الطوابع والعملات المعدنية",
    "محلات السلع الدينية",
    "مبيعات أجهزة السمع ولوازمها",
    "مستلزمات تقويم العظام - الأجهزة التعويضية",
    "محلات مستحضرات التجميل",
    'متاجر الآلات الكاتبة',
    "محلات السيجار والمواقف" ,
    "تجار الأخبار وأكشاك الصحف",
    "محلات الحيوانات الأليفة وأغذية الحيوانات الأليفة ومستلزماتها",
    "مبيعات حمامات السباحة",
    "محلات الخيام والمظلات",
    "التجزئة المتخصصة المتنوعة",
    "تأجير المعدات",
    "تأجير مقطورات الشاحنات / المرافق",
    "تأجير المركبات الترفيهية"
  ],
  Services: [
    'المقاولون العام',
    "تدفئة , سباكة , مكيفات ",
    'مقاولون كهرباء',
    "البناء والحجر والجص" ,
    "مقاولو النجارة",
    "تسقيف / انحياز , صفائح معدنية",
    'مقاولو أعمال خرسانة',
    "مقاولي التجارة الخاصة",
    "نشر وطباعة متنوعة",
    "التنضيد وصنع الألواح والخدمات ذات الصلة",
    "تنظيف متخصص",
    "خدمات شبكة الكمبيوتر",
    "أسلاك , حوالات مالية",
    "مراكز خدمة المعادن",
    "الكيماويات والمنتجات المماثلة (غير مصنفة في مكان آخر)" ,
    "البترول والمنتجات البترولية",
    "محطات الخدمة",
    "موزعات الوقود الآلية" ,
    "شحن السيارة الكهربائية" ,
    "التسويق المباشر - خدمات التأمين",
    "التسويق المباشر - السفر",
    "مبيعات من الباب إلى الباب",
    "التسويق المباشر - Outbound Tele" ,
    "التسويق المباشر - Inbound Tele" ,
    "التسويق المباشر - الاشتراك",
    "التسويق المباشر - غير ذلك",
    "تجار الوقود (غير السيارات)" ,
    "الصرف النقدي اليدوي",
    "الصرف النقدي الآلي" ,
    'المؤسسات المالية',
    "أوامر مالية غير مالية",
    "وسطاء / تجار الأمن" ,
    "اكتتاب التأمين , أقساط التأمين" ,
    "التأمين - الافتراضي",
    "وكلاء ومدراء عقارات - تأجير",
    "المشاركة بالوقت",
    "المعسكرات الرياضية / الترفيهية",
    'حدائق المقطورات' , 'المخيمات' ,
    "الغسيل , خدمات التنظيف",
    "مغاسل",
    'الغسيل الجاف',
    "تنظيف السجاد والمفروشات",
    "استوديوهات التصوير الفوتوغرافي",
    "صالونات التجميل والحلاقة",
    "إصلاح الأحذية / تنظيف القبعة",
    "خدمات الجنازات , محارق الجثث" ,
    "خدمات المواعدة / المرافقة",
    "خدمات إعداد الضرائب",
    'خدمات استشارية',
    "خدمات الشراء / التسوق" ,
    "تأجير الملابس",
    "صالات التدليك",
    "منتجعات الصحة والجمال",
    "خدمات عامة متنوعة",
    'الخدمات الإعلانية',
    "وكالات التقارير الائتمانية",
    "التصوير التجاري والفن والرسومات",
    "النسخ السريع , و Repro , و Blueprint" ,
    "خدمات السكرتارية المساندة",
    "خدمات الإبادة",
    'التنظيف والصيانة',
    "وكالات التوظيف / المؤقت",
    'برمجة الحاسب الآلي',
    "خدمات استرجاع المعلومات",
    "إصلاح الكمبيوتر",
    "استشارات, علاقات عامة",
    'وكالات المباحث' ,
    "تطوير الصور",
    "خدمات أعمال متنوعة",
    'توقف شاحنة',
    "مواقف سيارات , جراجات",
    "محلات إصلاح هياكل السيارات",
    "تجديد وإصلاح الإطارات",
    "محلات طلاء السيارات",
    "محلات خدمة السيارات",
    "غسيل سيارات",
    "خدمات القطر",
    "محلات تصليح الإلكترونيات",
    "تكييف , إصلاح التبريد",
    "إصلاح الأجهزة الصغيرة",
    "إصلاح الساعات / المجوهرات",
    "إصلاح الأثاث وإعادة صقله",
    "إصلاح اللحام",
    "محلات تصليح متنوعة",
    "إنتاج الصور / الفيديو",
    "مسارح الصور المتحركة",
    "محلات تأجير أشرطة الفيديو",
    "وكالات التذاكر المسرحية",
    "فرق , أوركسترا" ,
    "مؤسسات البلياردو / البلياردو",
    "صالات بولينج",
    "الأندية / الملاعب الرياضية",
    "مناطق الجذب السياحي والمعارض",
    "ملاعب الجولف - عامة",
    "لوازم ألعاب فيديو تسلية",
    "ممرات ألعاب الفيديو",
    "المراهنة / كازينو القمار" ,
    "مدن الملاهي / الكرنفالات",
    "النوادي الريفية",
    "أحواض السمك",
    "خدمات ترفيهية متنوعة",
    'الأطباء',
    "أطباء الأسنان وتقويم الأسنان",
    "أخصائي تقويم العظام",
    "مقومين العظام",
    "فاحصي النظر , طبيب عيون",
    "بصريات, نظارات",
    "أخصائيي تقويم العمود الفقري , وأطباء الأقدام" ,
    "التمريض / العناية الشخصية",
    "المستشفيات" ,
    "مختبرات الطب والأسنان",
    "الخدمات الطبية",
    "خدمات قانونية , محامون",
    "منظمات الخدمة الخيرية والاجتماعية - جمع التبرعات",
    "جمعيات مدنية , اجتماعية , أخوية",
    "المنظمات السياسية",
    "المنظمات الدينية",
    "جمعيات السيارات",
    "منظمات عضوية",
    "مختبرات الاختبار",
    "الهندسة المعمارية / خدمات المساحة",
    "خدمات المحاسبة / مسك الدفاتر",
    'خدمات احترافية',
    "تكاليف المحكمة , بما في ذلك النفقة وإعالة الطفل - المحاكم" ,
    "مدفوعات الكفالة والسندات (الدفع لكفالة السند , وليس السند الفعلي)" ,
    "خدمات بيطرية",
    "تعاونية زراعية",
    "خدمات تنسيق الحدائق"
  ],
  Travel_Tourism: [
    'سكك حديدية',
    "نقل الركاب والعبارات",
    "سكك حديد الركاب",
    "خدمات الإسعاف",
    "سيارات الأجرة / سيارات الليموزين",
    'خطوط الحافلات',
    "ناقلات شحن السيارات والشاحنات - النقل والتخزين المحلية والطويلة" ,
    'خدمات الشحن',
    'خطوط الرحلات البحرية',
    "تأجير القوارب وإيجاراتها",
    "المراسي والخدمات واللوازم",
    "الخطوط الجوية , شركات النقل الجوي" ,
    "مطارات , حقول طيران",
    "وكالات السفر ومنظمو الرحلات السياحية",
    "TUI Travel - ألمانيا" ,
    "رسوم التعرفة / الجسر",
    "خدمات النقل (غير مصنفة في مكان آخر)" ,
    "الفنادق والموتيلات والمنتجعات" ,
    "شركات تأجير السيارات (غير مدرجة في مكان آخر)"
  ],
  Utilities: [
    "معدات الاتصالات السلكية واللاسلكية ومبيعات الهاتف",
    "اتصالات خاصة",
    "خدمات الاتصالات",
    "خدمات التلغراف",
    "الكابلات والأقمار الصناعية والتلفزيون والراديو بمقابل آخر" ,
    'خدمات'
  ]
};
