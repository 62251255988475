import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import PageTable from './PageTable';
import ProductBarCodeModal from './ProductsBarCodeModal';
import SelectedProductModal from './SelectedProductModal';
import TableFilter from './TableFilter';
import FirstTermStockTableFilter from './FirstTermStockTableFilter';
import StockContext from '../../../contexts/stock-context/StockProvider';
import './CurrentStockPage.scss';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
const CurrentStockPage = () => {
  const { modalOpened, setModalOpened, barCodeModalOpened } =
    useContext(StockContext);
  const { t } = useTranslation();
  return (
    <div className="measurement-units-page">
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">{t('Inventory.CurrentStock.title')}</h2>
          <div className="sub-title" style={{ fontSize: '18px' }}>
            {t('Inventory.CurrentStock.subTitle')}
          </div>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font">{t('Inventory.CurrentStock.title')}</h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.storePage.title')}
            title3={t('Inventory.CurrentStock.title')}
            link3={'/store/current-stock'}
          />
        </div>
        {/* <Countdown /> */}
        <SearchNotificationsProfile />
      </div>

      <div className="custom-filter-add-section">
        {/* <div className="add-section">
          <div
            className="add-account-btn"
            onClick={() => {
              // if selected unit then reset it
              // open the unit modal
              setSelectedInventory(null);
              setModalOpened(true);
            }}
          >
            <span className="text-span">إضافة جديد</span>
          </div>
        </div> */}
        <FirstTermStockTableFilter />

        {/* <TableFilter /> */}
      </div>
      <PageTable />
      <SelectedProductModal />
      {barCodeModalOpened && <ProductBarCodeModal />}

      {/* {modalOpened && <PageModal />} */}
    </div>
  );
};

export default CurrentStockPage;
