import axios from 'axios';

const addNewWorkPlaceApi = async (token, values, edit = false) => {
  const formData = new FormData()
  //const mappedValues = {};
  if (values.place_name) formData.append('name', values.place_name) // mappedValues.name = values.place_name;
  if (values.place_address)formData.append('address', values.place_address)// mappedValues.address = values.place_address;
  if (values.WorkPalace_id) formData.append('WorkPalace_id', values.WorkPalace_id) // mappedValues.WorkPalace_id = values.WorkPalace_id;
  if (values.address_url) formData.append('address_url', values.address_url) 

  try {
    let url;
    if (!edit) url = '/WorkPalace/create';
    else url = '/WorkPalace/update';
    const res = await axios.post(url, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default addNewWorkPlaceApi;
