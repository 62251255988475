import React, { useContext } from 'react';
import { Modal } from 'antd';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import ProductIngredientsForm from './ProductIngredientsForm';

const ProductIngredientsModal = () => {
  const {
    foundProduct,
    setFoundProduct,
    productIngredientsModalOpened,
    setproductIngredientsModalOpened
  } = useContext(PosInvoiceContext);

  return (
    <Modal
      transitionName=""
      className="settings-users-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '442px' }}
      title={foundProduct ? 'إضافات المنتج' : 'إضافات للمنتج'}
      visible={productIngredientsModalOpened}
      onOk={() => {
        setproductIngredientsModalOpened(false);
        setFoundProduct(null);
      }}
      onCancel={() => {
        setproductIngredientsModalOpened(false);
        setFoundProduct(null);
      }}
      footer={false}
    >
      <ProductIngredientsForm />
    </Modal>
  );
};

export default ProductIngredientsModal;
