import axios from 'axios';

const deletePayment = async (token, id) => {
  try {
    const res = await axios.post(
      '/receipts/delete',
      { id },
      {
        baseURL: process.env.REACT_APP_BASE_URL_POS,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token,
        }
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export default deletePayment;
