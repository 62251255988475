import React, { useContext } from 'react';
import SearchIcon from '../../../common/icons/SearchIcon';
// import { Select, DatePicker } from 'antd';
import './StoreItemMangerTableFilter.scss';
// import SelectDropDown from '../../../common/icons/SelectDropDown';
// import CalendarIcon from '../../../common/icons/CalendarIcon';
import searchAllProducts from '../../../apis/store-apis/products/searchAllProducts';
import excelImg from '../../../assets/imgs/icons/excel-btn.png';
import StoreNewProductModalForProducts from '../../../components/store-new-product-modal-for-products/StoreNewProductModalForProducts';
import StoreNewProductModalContext from '../../../contexts/store-new-product-context/StoreNewProductProvider';
import UserContext from '../../../contexts/user-context/UserProvider';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import ExcelModal from './ExcelModal';
import './ExcelModal.scss';
import SyncSallaApi from '../../../apis/fatoorahStore-api/SyncSallaApi';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import { useTranslation } from 'react-i18next';

const StoreItemManagerTableFilter = ({product_type}) => {
  const { t } = useTranslation();
  const systemSettings = useSystemSettings();
  const [excelModalOpened, setExcelModalOpened] = React.useState(false);
  const { user, filterValues, setFilterValues } = useContext(UserContext);

  const {
    modalOpened,
    setModalOpened,
    setSelectedProduct,
    setSelectedProductId,
    setIsLoading,
    setTablePagination,
    setAllFetchedProducts
  } = useContext(StoreNewProductModalContext);

  const handleTableSearch = async (e) => {
    // setNameSearchValue(e.target.value);
    try {
      setIsLoading(true);
      const res = await searchAllProducts(user?.token, {
        ...filterValues,
        page: 1,
        searchText: e.target.value
      });
      // is response is success
      if (res?.status === 200 && res?.data?.data?.data) {
        setIsLoading(false);
        setFilterValues({ ...filterValues, searchText: e.target.value });
        if (res.data.data.data.length >= 0) {
          const data = res.data.data.data;
          setAllFetchedProducts(data);
        }
        if (res?.data?.data?.pagination) {
          setTablePagination(res?.data?.data?.pagination);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const syncSallaAuth = async () => {
    try {
      const res = await SyncSallaApi(
        user?.token,
        systemSettings?.sallaToken,
        'products'
      );
      if (res?.status === 200 && res?.data?.status === 1) {
        // setIsLoading(false);
        // setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Inventory.Products.addedSuccessfully'),
          message:
            t('Inventory.Products.productsLinkedWithSallaSuccessfully') ||
            res.data.message
        });
      } else {
        // setIsLoading(false);
        errorNotification({
          title: t('Inventory.Products.errorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Inventory.Products.pleaseTryLater')
        });
      }
    } catch (error) {
      // setIsLoading(false);
      errorNotification({
        title: t('Inventory.Products.errorOccurred'),
        message: t('Inventory.Products.pleaseTryLater')
      });
    }
  };

  return (
    <div className="custom-filter-add-section">
      <div className="add-section">
        {/* <div className="section-text">
          <p className="bold-font">المنتجــات</p>
        </div> */}
        <div
          className="add-btn"
          onClick={() => {
            // if selected product then reset it
            // open the product modal
            setSelectedProductId('');
            setSelectedProduct(null);
            setModalOpened(true);
          }}
        >
          <span className="text-span">
            {t('Inventory.Products.addNewProduct')}
          </span>
        </div>
      </div>

      <div className="custom-table-fitler">
        <div className="form-excel-export-wrap">
          <form className="filter-form">
            <div className="table-search">
              <label>
                <SearchIcon />
                <input
                  placeholder={t(
                    'Inventory.Products.enterProductNameforSearch'
                  )}
                  type="text"
                  name="table_search"
                  onKeyUp={handleTableSearch}
                />
              </label>
            </div>
            {/* <label className="entries-wrap">
            <Select
              defaultValue="10"
              style={{ width: 72 }}
              onChange={handleChange}
              suffixIcon={<SelectDropDown />}
            >
              <Option value="10">10</Option>
              <Option value="15">15</Option>
              <Option value="20">20</Option>
            </Select>
            <span className="select-title">إدخالات</span>
          </label>

          <div className="range-picker-wrap">
            <RangePicker
              value={selectedPicker?.moments && selectedPicker.moments}
              onChange={handlePickerChange}
              className={`filter-range-picker`}
              allowClear={false}
              suffixIcon={<CalendarIcon />}
              placeholder={['تاريخ من', 'تاريخ الى']}
            />
          </div>

          <button className="filter-submit-btn" type="submit">
            بحث
          </button> */}
            {/* <div className="excel-btn-wrap">
              <CSVLink
                data={allFetchedProducts || []}
                filename={'my-file.csv'}
                className="excel-export-btn"
                target="_blank"
              >
                <img
                  style={{ height: '42px' }}
                  src={excelImg}
                  alt="export to excel"
                />
              </CSVLink>
            </div> */}
            <div className="excel-btn-wrap">
              <a
                // href={`path/to/save/${process.env.REACT_APP_BASE_URL}/Excel/exportProducts?client_id=${systemSettings?.id}`}
                href={`${
                  process.env.REACT_APP_BASE_URL
                }/Excel/exportProducts?client_id=${
                  systemSettings?.id
                }&searchText=${
                  filterValues?.searchText ? filterValues?.searchText : ''
                }&product_type=${product_type}`}
                download
                className="excel-export-btn"
              >
                <img
                  style={{ height: '42px' }}
                  src={excelImg}
                  alt="export to excel"
                />
              </a>
            </div>
          </form>
          {/* <div className="excel-export-btn">
            <img src={excelImg} alt="export to excel" />
          </div> */}

          <div
            className="preview-download-upload add-btn"
            onClick={(e) => {
              setExcelModalOpened(true);
              e.stopPropagation();
            }}
          >
            <span className="text-span">
              {t('Inventory.Products.uploadProductsData')}
            </span>
          </div>
          {/* ================ */}
          {/* {systemSettings?.sallaToken && (
            <div
              className="preview-download-upload add-btn"
              onClick={() => syncSallaAuth()}
            >
              <span className="text-span">
                {t('Inventory.Products.connectWithSalla')}
              </span>
            </div>
          )} */}
        </div>
      </div>

      {modalOpened && <StoreNewProductModalForProducts />}
      {excelModalOpened && (
        <ExcelModal
          modalOpened={excelModalOpened}
          setModalOpened={setExcelModalOpened}
        />
      )}
    </div>
  );
};

export default StoreItemManagerTableFilter;
