/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Empty, Pagination, Table } from 'antd';
import tableColumns from './tableColumns';
import StoreNewProductModalContext from '../../../contexts/store-new-product-context/StoreNewProductProvider';
import { useEffect } from 'react';
import { useState } from 'react';
import getAllProducts from '../../../apis/store-apis/products/getAllProducts';
import UserContext from '../../../contexts/user-context/UserProvider';
import { useHistory } from 'react-router-dom';
import storeRouterLinks from '../../../components/app/store-routes/storeRouterLinks';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
const StoreItemManagerTable = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const {
    isLoading,
    setIsLoading,
    fetchCount,
    setFetchCount,
    setModalOpened,
    allFetchedProducts,
    setAllFetchedProducts,
    setSelectedProduct,
    setSelectedProductId,
    setSelectedProductModalOpened,
    nameSearchValue,
    setBarCodeModalOpned,
    tablePagination,
    setTablePagination
  } = useContext(StoreNewProductModalContext);

  const history = useHistory();

  const { search } = useLocation();
  const values = queryString.parse(search);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: index + 1,
      name: obj?.name ? obj.name : '',
      title: obj?.title ? obj.title : ''
    }));
  };

  useEffect(() => {
    if (values.page) {
      setTablePagination({
        ...tablePagination,
        current_page: parseFloat(values.page)
      });
    }
  }, [search]);

  useEffect(() => {
    let isMounted = true;
    const fetchUnits = async () => {
      try {
        setIsLoading(true);
        const res = await getAllProducts(user?.token, {
          ...tablePagination,
          page: values.page,
          searchText: nameSearchValue
        });
        if (isMounted) {
          // is response is success
          if (res?.status === 200 && res?.data?.data?.data) {
            setIsLoading(false);
            if (res.data.data.data.length >= 0) {
              const data = res.data.data.data;
              setAllFetchedProducts(data);
            }
            if (res?.data?.data?.pagination) {
              setTablePagination(res.data.data.pagination);
            }
          } else {
            setIsLoading(false);
          }
        }
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchUnits();

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedProducts?.length >= 0) {
      let mappedData = null;
      if (nameSearchValue.trim()) {
        const filtered = allFetchedProducts.filter((obj) =>
          obj.name
            ?.toLowerCase()
            .trim()
            .includes(nameSearchValue.toLowerCase().trim())
        );
        mappedData = mapDataSource(filtered);
      } else {
        mappedData = mapDataSource(allFetchedProducts);
      }
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    fetchCount,
    allFetchedProducts,
    allFetchedProducts?.length,
    nameSearchValue
  ]);

  return (
    <section>
      <Table
        locale={{
          emptyText: <Empty description={false}>{t('services.noData')}</Empty>
        }}
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedProducts,
          setModalOpened,
          setFetchCount,
          setSelectedProduct,
          setSelectedProductId,
          setIsLoading,
          setSelectedProductModalOpened,
          setBarCodeModalOpned,
          user?.token,
          t
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
      {tablePagination && (
        <Pagination
          style={{ display: 'flex', justifyContent: 'center' }}
          defaultCurrent={1}
          showSizeChanger={false}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}
          onChange={(page, pageSize) => {
            setTablePagination({ ...tablePagination, current_page: page });
            setFetchCount((prev) => prev + 1);
            history.push(
              `${storeRouterLinks?.storeItemManagerPage}?page=${page}`
            );
            // if (isReportsPage) {
            //   history.push(
            //     `${storeRouterLinks?.outgoingPurchaseInvoiceReports}?page=${page}`
            //   );
            // } else {
            //   history.push(
            //     `${storeRouterLinks?.outgoingPurchaseInvoice}?page=${page}`
            //   );
            // }
          }}
          hideOnSinglePage={true}
        />
      )}
    </section>
  );
};

export default StoreItemManagerTable;
