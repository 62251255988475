import axios from 'axios';

const getSingleTicketApi = async (token, userId) => {
  try {
    const res = await axios.get(`tickets/${userId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default getSingleTicketApi;
