/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import UpgradePageBoxs from './UpgradePageBoxs';
import { Spin } from 'antd';
import useCustomApiRequest from '../../../../custom-hooks/useCustomApiRequest';
import getPackagesToUpgradeApi from '../../../../apis/upgrade/getPackagesToUpgradeApi';
import PosCount from './PosCount';
import UpgradeContext from '../../../../contexts/upgrade-context/UpgradeContext';
import PaymentSelection from './PaymentSelection';
import './UpgradePage.scss';
import DocTitleScrollTop from '../../../../utils/DocTitleScrollTop';
import UserContext from '../../../../contexts/user-context/UserProvider';
import SystemFeatures from './SystemFeatures';
import { useTranslation } from 'react-i18next';
const UpgradeSettingPage = () => {
  DocTitleScrollTop();
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const { setFetchedPackages, fetchedPackages } = useContext(UpgradeContext);
  const [isLoading, setIsLoading] = useState(false);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;

    const successCallback = (res) => {
      if (res?.data?.data) {
        const data = res?.data?.data;
        setFetchedPackages(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };

    const errorCallback = (error) => {
      setIsLoading(false);
    };

    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getPackagesToUpgradeApi(),
        successCallback,
        errorCallback
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const renderPackages = () => {
    if (isLoading) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 332
          }}
        >
          <Spin />
        </div>
      );
    }

    if (fetchedPackages?.packages?.length === 0) {
      return <p>{t('SystemSettings.noPackages')}</p>;
    }
    if (fetchedPackages?.packages?.length > 0) {
      return <UpgradePageBoxs data={fetchedPackages} />;
    }
    return null;
  };

  return (
    <div className={`upgrade-page ${user?.expire === 1 ? 'expired' : ''}`}>
      <div className="page-head-wrap">
        <h2 className="bold-font">{t('SystemSettings.FatoorahPackages')}</h2>
      </div>

      <div className="page-body">
        {renderPackages()}
        <PosCount />
        <PaymentSelection />
        <SystemFeatures />
      </div>
    </div>
  );
};

export default UpgradeSettingPage;
