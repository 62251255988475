const lngs = [
	{
		code: "en", // iso code
		name: "English",
		country_code: "us"
	},
	{
		code: "ar", // iso code
		name: "العربية",
		country_code: "sa"
		// dir: "rtl"
	},
	// {
	// 	code: "ur", // iso code
	// 	name: "Urdu",
	// 	country_code: "urdu"
	// 	// dir: "rtl"
	// }
];

export default lngs;
