import { EyeOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import deleteJournalApi from '../../../apis/accounting-apis/accounting-details-apis/deleteJournalApi';
import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import JournalsContext from '../../../contexts/accounting-context/JournalsContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';

const TableColumns = (
  fetchedAccountingDetails,
  setModalOpened,
  setModalOpenedCreate,
  setFetchCount,
  setSelectedJournals,
  setIsLoading,
  token,
  type
) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const params = useParams();

  const { setSelectedJournalId } = useContext(JournalsContext);

  const handleEditClick = (record) => {
    setSelectedJournals(record);
    setModalOpenedCreate(true);
    setSelectedJournalId(record.id);
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteJournalApi(user?.token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Messages.addedSuccessfully'),
          message: t('Messages.DeletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Messages.ErrorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Messages.TryAgainLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Messages.ErrorOccurred'),
        message: t('Messages.TryAgainLater')
      });
    }
  };

  const handlePreview = (id) => {
    setSelectedJournalId(id);
    setModalOpened(true);
  };

  const renderActions = (obj) => {
    if (!obj.model_id) {
      return (
        <div className="action-btns-wrap">
          <div className="edit-btn" onClick={() => handleEditClick(obj)}>
            <EditIcon />
            {t('Restrictions.edit')}
          </div>

          <Popconfirm
            title={t('Restrictions.Areyousureyouwanttodeletethebranch')}
            onConfirm={() => handleDeleteRequest(obj.id)}
            okText={t('Messages.yes')}
            cancelText={t('Messages.no')}
          >
            <Tooltip title={t('Restrictions.edit')}>
              <div className="delete-btn">
                <DeleteIcon />
              </div>
            </Tooltip>
          </Popconfirm>
        </div>
      );
    }
    return '------';
  };

  return [
    {
      // title: 'التاريــخ',
      title: t('Restrictions.RestrictionsDate'),

      dataIndex: 'date',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.date ? record.date : '-----'}
              {/* {record.date ? moment(record.date?.split('T')[0]).format("MM/DD/YYYY") : '-----'} */}
            </span>
          </div>
        );
      }
    },
    {
      // title: 'ملحوظه',
      title: t('Restrictions.Notes'),

      dataIndex: 'note',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text ltr-text">
            {/* {String(record.note)} */}
            {String(record.note).replace(/<[^>]*>?/gm, '')}
          </div>
        );
        // if (record.note) {
        //   const wordsCount = countWords(String(record.note));
        //   if (wordsCount > 8) {
        //     return (
        //       <Popover title="اقرأ المزيد" content={String(record.note)}>
        //         <Button size="large" type="text">
        //           <p className="has-read-more">
        //             <span className="read-more-span ltr-text">
        //               {trimWords(String(record.note), 8)}
        //             </span>
        //             <ReadOutlined
        //               className="read-more-book"
        //               style={{ margin: '0 4px' }}
        //             />
        //           </p>
        //         </Button>
        //       </Popover>
        //     );
        //   } else
        //     return (
        //       <div className="row-cell row-text ltr-text">
        //         {String(record.note)}
        //       </div>
        //     );
        // } else {
        //   return '----';
        // }
      }
    },
    {
      // title: 'مجموع المدين / الدائن',
      title: t('Restrictions.TotalDebitCredit'),

      dataIndex: 'amount',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span>{record.amount}</span>
          </div>
        );
      }
    },
    {
      // title: 'عدد الأسطر',
      title: t('Restrictions.NumberOfLines'),

      dataIndex: 'lines',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.total_lines ? record.total_lines : '-----'}
            </span>
          </div>
        );
      }
      // sorter: (a, b) => parseFloat(a.total_lines) - parseFloat(b.total_lines)
    },
    {
      // title: 'معاينة',
      title: t('Restrictions.Preview'),

      // width: 222,
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">
            <div className="circled-btn-wrap">
              <Tooltip title={t('Restrictions.Preview')}>
                <Button
                  id={record?.id}
                  onClick={() => handlePreview(record?.id)}
                  className="circled-btn"
                  type="dashed"
                  shape="circle"
                  icon={<EyeOutlined />}
                />
              </Tooltip>
            </div>
          </div>
        );
      }
    },
    {
      // title: 'الإعدادات',
      title: t('Restrictions.Settings'),

      width: 222,
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default TableColumns;
