import React, { useContext } from 'react';
import SearchIcon from '../../../common/icons/SearchIcon';
// import { DatePicker } from 'antd';
// import excelImg from '../../../assets/imgs/icons/excel-btn.png';
import './TableFilter.scss';
// import CalendarIcon from '../../../common/icons/CalendarIcon';
import StorePurchaseOrderContext from '../../../contexts/store-purchase-order-context/StorePurchaseOrderProvider';
import { exportToExcel } from 'react-json-to-excel';
import excelImg from '../../../assets/imgs/icons/excel-btn.png';
import { useTranslation } from 'react-i18next';
const TableFilter = () => {
  // const { RangePicker } = DatePicker;
  const {
    setNameSearchValue,
    setModalOpened,
    setSelectedOrder,
    tableDataSource
  } = useContext(StorePurchaseOrderContext);
  const { t } = useTranslation();
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      supplier: obj.supplier.name
    }));
  };
  const handleTableSearch = (e) => {
    setNameSearchValue(e.target.value);
  };

  return (
    <div className="custom-filter-add-section">
      <div className="add-section">
        {/* <div className="section-text">
          <p className="bold-font">طلب شراء</p>
        </div> */}
        <div
          className="add-btn"
          onClick={() => {
            setSelectedOrder(null);
            setModalOpened(true);
          }}
        >
          <span className="text-span">
            {t('PurchaseOrder.addPurchaseOrder')}
          </span>
        </div>
      </div>

      <div className="custom-table-fitler">
        <div className="form-excel-export-wrap">
          <form className="filter-form">
            <div className="table-search">
              <label>
                <SearchIcon />
                <input
                  placeholder={t('PurchaseOrder.enterSupplierName')}
                  type="text"
                  name="table_search"
                  onKeyUp={handleTableSearch}
                />
              </label>
            </div>

            {/* <div className="range-picker-wrap">
            <RangePicker
              value={selectedPicker?.moments && selectedPicker.moments}
              onChange={handlePickerChange}
              className={`filter-range-picker`}
              allowClear={false}
              suffixIcon={<CalendarIcon />}
              placeholder={['تاريخ من', 'تاريخ الى']}
            />
          </div>

          <button className="filter-submit-btn" type="submit">
            بحث
          </button> */}
          </form>

          <div
            className="excel-btn-wrap"
            onClick={() =>
              exportToExcel(
                mapDataSource(tableDataSource),
                t('PurchaseOrder.purchaseOrder')
              )
            }
          >
            <img
              style={{ height: '42px' }}
              src={excelImg}
              alt="export to excel"
            />
          </div>

          {/* <div className="excel-export-btn">
          <img src={excelImg} alt="export to excel" />
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default TableFilter;
