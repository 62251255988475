import { Button } from '@mui/material';
import { Descriptions, Spin } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import PosReportContext from '../../../../contexts/pos-report-context/PosReportContext';
import UserContext from '../../../../contexts/user-context/UserProvider';
import './PosReportModal.scss';

const PosReportModal = () => {
  const componentRef = useRef();
  const { t } = useTranslation();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @media print {
      html, body { height: initial !important; overflow: initial !important; }

      tbody { display: table-row-group !important; }
      thead {
        display: table-row-group !important;
      }
      tfoot {
        display: table-row-group !important;
      }
      @page {
        size: auto;
        margin: none;
      }
      .qr-code-wrap canvas {
        width: 94px !important;
        height: 94px !important;
      }
      .pagebreak {
        display: block;
        size: auto;
      }
      .invoice-details-wrap {
        padding: 0;
      }
      .ant-descriptions-item-content,
        .ant-descriptions-item-label {
          padding: 4px 12px !important;
        }
      }
      .ant-descriptions-header {
        margin: 4px 0 !important;
      }
      .models-title {
        direction: rtl;
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .ant-table-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
      }
      th {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      .invoice-extra-prices-table .ant-table-cell {
        padding: 4px 12px !important;
        text-align: right;
      }
    `
  });
  const { posReportIsLoading, posReport } = useContext(PosReportContext);

  const {
    posReportModalOpened,
    setPosReportModalOpened,
    setPosBoxModalOpened,
    setEndTheShit
  } = useContext(UserContext);

  const renderReportDetails = () => {
    return (
      <div className="report-modal-content" ref={componentRef}>
        <Descriptions title={t('ShiftReport.EmployeeData')} bordered column={1}>
          {posReport.employee?.name && (
            <Descriptions.Item label={t('ShiftReport.EmployeeName')}>
              {posReport.employee.name}
            </Descriptions.Item>
          )}
          {posReport.employee?.phone && (
            <Descriptions.Item label={t('ShiftReport.PhoneNumber')}>
              {posReport.employee.phone}
            </Descriptions.Item>
          )}
        </Descriptions>

        <Descriptions
          style={{ marginTop: 14 }}
          title={t('ShiftReport.PointOfSaleData')}
          bordered
          column={1}
        >
          {posReport.pos?.name && (
            <Descriptions.Item label={t('ShiftReport.PointOfSale')}>
              {posReport.pos.name}
            </Descriptions.Item>
          )}
          {/* {posReport.pos?.balance && (
            <Descriptions.Item label={t('ShiftReport.TotalCashAccount')}>
              {posReport.pos.balance}
            </Descriptions.Item>
          )}
          {posReport.pos?.cash && (
            <Descriptions.Item label={t('ShiftReport.CashAccount')}>
              {posReport.pos.cash}
            </Descriptions.Item>
          )}
          {posReport.pos?.visa && (
            <Descriptions.Item label={t('ShiftReport.CreditCardAccount')}>
              {posReport.pos.visa}
            </Descriptions.Item>
          )} */}
        </Descriptions>

        <Descriptions
          style={{ marginTop: 14 }}
          title={t('ShiftReport.ShiftData')}
          bordered
          column={1}
        >
          {posReport.start_shift && (
            <Descriptions.Item label={t('ShiftReport.ShiftStart')}>
              {posReport.start_shift}
            </Descriptions.Item>
          )}
          {posReport.end_shift && (
            <Descriptions.Item label={t('ShiftReport.ShiftEnd')}>
              {posReport.end_shift}
            </Descriptions.Item>
          )}
          {posReport.shiftCash && (
            <Descriptions.Item label={t('ShiftReport.Cash')}>
              {posReport.shiftCash}
            </Descriptions.Item>
          )}
          {posReport.shiftVisa && (
            <Descriptions.Item label={t('ShiftReport.Credit')}>
              {posReport.shiftVisa}
            </Descriptions.Item>
          )}
          
          {posReport?.shiftBank && (
            <Descriptions.Item label={t('ShiftReport.shiftBank')}>
              {posReport.shiftBank}
            </Descriptions.Item>
          )}
          {posReport?.shiftMoney && (
            <Descriptions.Item label={t('ShiftReport.TillAccount')}>
              {posReport?.shiftMoney}
            </Descriptions.Item>
          )}
          {posReport?.totalAmount && (
            <Descriptions.Item label={t('ShiftReport.TotalAccount')}>
              {parseFloat(posReport?.totalAmount).toFixed(2)}
            </Descriptions.Item>
          )}
        </Descriptions>

        <Descriptions
          style={{ marginTop: 14 }}
          title={t('ShiftReport.ShiftEndData')}
          bordered
          column={1}
        >
          {posReport.start_shift && (
            <Descriptions.Item label={t('ShiftReport.OpeningDate')}>
              {posReport.start_shift}
            </Descriptions.Item>
          )}
          {/* {posReport.end_shift && (
            <Descriptions.Item label="تاريخ الغلق">
              {posReport.end_shift}
            </Descriptions.Item>
          )} */}
          {posReport.first_invoice_number && (
            <Descriptions.Item label={t('ShiftReport.Firstinvoicenumber')}>
              {posReport.first_invoice_number}
            </Descriptions.Item>
          )}
          {posReport.last_invoice_number && (
            <Descriptions.Item label={t('ShiftReport.endinvoicenumber')}>
              {posReport.last_invoice_number}
            </Descriptions.Item>
          )}
          {posReport?.return_amount && (
            <Descriptions.Item label={t('ShiftReport.ReturnValue')}>
              {parseFloat(posReport?.return_amount).toFixed(2)}
            </Descriptions.Item>
          )}
          {posReport?.invoices_count && (
            <Descriptions.Item label={t('ShiftReport.InvoiceCount')}>
              {posReport?.invoices_count}
            </Descriptions.Item>
          )}
        </Descriptions>
        <div className="end-shift">
          <span
            onClick={() => {
              setEndTheShit(true);
            }}
            className="end-shift-btn"
          >
            {t('ShiftReport.ShiftFinished')}
          </span>
        </div>
      </div>
    );
  };

  const renderModalContent = () => {
    if (!posReportIsLoading && posReport) return renderReportDetails();
    else if (posReportIsLoading) {
      return (
        <div style={{ minHeight: 182, display: 'grid', placeItems: 'center' }}>
          <Spin />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Modal
      transitionName=""
      className="shared-custom-modal pos-report-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        <div className={'model-header model-header-pos'}>
          <h3>{t('ShiftReport.ShiftReport')}</h3>
          <div style={{ display: 'flex', gap: '12px' }}>
            <Button
              onClick={handlePrint}
              className="circled-btn"
              type="dashed"
              shape="circle"
            >
              {t('ShiftReport.PrintReport')}
            </Button>
            <Button
              onClick={() => {
                setPosReportModalOpened(false);
                setPosBoxModalOpened(true);
              }}
              className="circled-btn"
              type="dashed"
              shape="circle"
            >
              {t('ShiftReport.OpenTill')}
            </Button>
          </div>
        </div>
      }
      visible={posReportModalOpened}
      onOk={() => {
        setPosReportModalOpened(false);
      }}
      onCancel={() => {
        setPosReportModalOpened(false);
      }}
      footer={false}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default PosReportModal;
// ShiftReport.OpenTill
