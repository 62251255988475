/* eslint-disable react-hooks/exhaustive-deps */
import {
  ApiOutlined,
  CaretDownOutlined,
  FileTextOutlined,
  InboxOutlined,
  LoadingOutlined,
  LogoutOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Avatar, Button, Dropdown, Menu } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import signOutApi from '../../../../apis/auth/pos/signout.js';
import getPosShiftReport from '../../../../apis/pos-api/getPosShiftReport';
import PosInvoiceContext from '../../../../contexts/pos-invoice-context/PosInvoiceContext';
import PosReportContext from '../../../../contexts/pos-report-context/PosReportContext';
import StopwatchContext from '../../../../contexts/stopwatch-context/StopwatchContext';
import UserContext from '../../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../../custom-hooks/useCustomApiRequest';
import errorNotification from '../../../../utils/errorNotification';
import successNotification from '../../../../utils/successNotification';
import Links from '../../Links';
const MainAppProfileMenu = () => {
  const routerLinks = Links();
  const history = useHistory();
  const { t } = useTranslation();
  const {
    user,
    setPosBoxModalOpened,
    removeCurrentUser,
    setIsNewLoggedIn,
    setOtpValue
  } = useContext(UserContext);

  const {
    fetchReportCount,
    setFetchReportCount,
    setPosReport,
    setPosReportIsLoading
  } = useContext(PosReportContext);

  const { setPosReportModalOpened, endTheShit, setEndTheShit } =
    useContext(UserContext);
  const { resetPos } = useContext(PosInvoiceContext);
  const { setStopwatchHours, setStopwatchMinutes, setStopwatchSeconds } =
    useContext(StopwatchContext);
  const [loadingSignOut, setLoadingSignOut] = useState(false);
  const customApiRequest = useCustomApiRequest();

  const handleSignOut = () => {
    // setPosReportModalOpened(true);
    setLoadingSignOut(true);

    customApiRequest(
      signOutApi(user?.token || user?.employee?.token),
      (res) => {
        setLoadingSignOut(false);
        if (res?.status === 200 && res?.data?.status === 1) {
          removeCurrentUser();
          setIsNewLoggedIn(false);
          setOtpValue(true);
          history.push(routerLinks.homePage);
          // setLoggedIn(
          //   Cookies.get('currentUser') ||
          //     sessionStorage.getItem('currentUser')
          //     ? true
          //     : false
          // );
          // if (obj?.endShift) {
          //   setPosReportModalOpened(true);
          //   setPosReport(res?.data?.data);
          // }
          successNotification({
            title: t('LogOut.operationCompletedSuccessfully'),
            message: t('LogOut.logOutSuccess')
          });
          setStopwatchHours(0);
          setStopwatchMinutes(0);
          setStopwatchSeconds(0);
        } else {
          errorNotification({
            title: t('LogOut.logOutError'),
            message: t('LogOut.tryLaterMessage')
          });
        }
      },
      (error) => {
        setLoadingSignOut(false);
        errorNotification({
          title: t('LogOut.logOutError'),
          message: t('LogOut.tryLaterMessage')
        });
      }
    );
  };
  // const handleEndShift = (obj) => {
  //   // setPosReportModalOpened(true);
  //   setLoadingSignOut(true);
  //   if (endTheShit) {
  //     customApiRequest(
  //       signOutApi(user?.token || user?.employee?.token, obj),
  //       (res) => {
  //         setLoadingSignOut(false);

  //         setPosReport(res?.data?.data);
  //         if (res?.data?.status === 1) {
  //           removeCurrentUser();
  //           setIsNewLoggedIn(false);
  //           setOtpValue(true);
  //           history.push(routerLinks.homePage);
  //           setStopwatchHours(0);
  //           setStopwatchMinutes(0);
  //           setStopwatchSeconds(0);
  //           successNotification({
  //             title: t('LogOut.operationCompletedSuccessfully'),
  //             message: t('LogOut.logOutSuccess')
  //           });
  //           resetPos();
  //         } else {
  //           errorNotification({
  //             title: t('LogOut.logOutError'),
  //             message:  t('LogOut.tryLaterMessage')
  //           });
  //         }
  //       },
  //       (error) => {

  //         setLoadingSignOut(false);
  //         errorNotification({
  //           title:  t('LogOut.logOutError'),
  //           message:t('LogOut.tryLaterMessage')
  //         });
  //       }
  //     );
  //   }
  // };

  useEffect(() => {
    if (endTheShit) {
      customApiRequest(
        signOutApi(user?.token || user?.employee?.token, { endShift: 1 }),
        (res) => {
          setLoadingSignOut(false);

          setPosReport(res?.data?.data);
          if (res?.data?.status === 1) {
            removeCurrentUser();
            setIsNewLoggedIn(false);
            setOtpValue(true);
            history.push(routerLinks.homePage);
            setStopwatchHours(0);
            setStopwatchMinutes(0);
            setStopwatchSeconds(0);
            setEndTheShit(false);
            successNotification({
              title: t('LogOut.operationCompletedSuccessfully'),
              message: t('LogOut.logOutSuccess')
            });
            resetPos();
          } else {
            errorNotification({
              title: t('LogOut.logOutError'),
              message: t('LogOut.tryLaterMessage')
            });
          }
        },
        (error) => {
          setLoadingSignOut(false);
          errorNotification({
            title: t('LogOut.logOutError'),
            message: t('LogOut.tryLaterMessage')
          });
        }
      );
    }
  }, [endTheShit]);
  React.useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setPosReportIsLoading(true);
      customApiRequest(
        getPosShiftReport(user?.token || user?.employee?.token),
        (res) => {
          setLoadingSignOut(false);
          setPosReportIsLoading(false);
          if (res?.status === 200 && res?.data?.status === 1) {
            setPosReport(res?.data?.data);
          } else {
          }
        },
        (error) => {
          setPosReportIsLoading(false);
          setLoadingSignOut(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchReportCount]);
  return (
    <div className="avatar-wrapper">
      <Dropdown
        arrow
        trigger={['click']}
        disabled={loadingSignOut}
        overlay={
          <Menu>
            {/* <Menu.Item key="1" icon={<UserOutlined />}>
              <RouterLink to={routerLinks.ProfilePage}>
                الملــف الشخصى
              </RouterLink>
            </Menu.Item> */}
            <Menu.Item
              key="1"
              icon={<FileTextOutlined />}
              onClick={() => {
                setFetchReportCount((prev) => prev + 1);
                history.push('/pos');
                setPosReportModalOpened(true);
              }}
            >
              <div>{t('LogOut.salePointReport')}</div>
            </Menu.Item>
            {/* <Menu.Item
              key="1"
              icon={<FileTextOutlined />}
              onClick={() => {
                setFetchReportCount((prev) => prev + 1);
                setPosReportModalOpened(true);
              }}
            >
              <div>تقارير الشيفت</div>
            </Menu.Item> */}
            <Menu.Item
              key="3"
              icon={<InboxOutlined />}
              onClick={(e) => {
                history.push('/pos');
                setPosBoxModalOpened(true);
              }}
            >
              {t('ShiftReport.OpenTill')}
            </Menu.Item>
            <Menu.Item
              key="2"
              icon={<LogoutOutlined />}
              onClick={() => handleSignOut()}
            >
              {t('LogOut.logOut')}
            </Menu.Item>
            <Menu.Item
              key="3"
              icon={<ApiOutlined />}
              onClick={() => {
                history.push('/pos');
                setPosReportModalOpened(true);
              }}
            >
              {t('LogOut.shiftEnd')}
            </Menu.Item>
          </Menu>
        }
      >
        <Button className="profile-menu-btn" type="text">
          <Avatar size={38} icon={<UserOutlined />} src={user?.image} />
          {loadingSignOut ? <LoadingOutlined /> : <CaretDownOutlined />}
        </Button>
      </Dropdown>
    </div>
  );
};

export default MainAppProfileMenu;
