/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Pagination, Table } from 'antd';
import tableColumns from './tableColumns';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import getAllClientsApi from '../../apis/pos-api/clients-pos-apis/getAllClientsApi';
import ClientsPosContext from '../../contexts/clients-context-pos/ClientsPosContext';
// import pageData from './pageData';

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const PageTable = () => {
  const {
    allFetchedClients,
    setAllFetchedClients,
    isLoading,
    setIsLoading,
    setModalOpened,
    fetchCount,
    setFetchCount,
    setSelectedClient,
    nameSearchValue,
    tablePagination,
    setTablePagination
  } = useContext(ClientsPosContext);
  const { user } = useContext(UserContext);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: index + 1
    }));
  };
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllClientsApi(user?.token || user?.employee?.token, { test1: true, page: tablePagination?.current_page }),
        (res) => {
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data?.data?.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedClients(data);
            setTablePagination(res.data.data.pagination)
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedClients?.length >= 0) {
      let mappedData = null;
      if (nameSearchValue.trim()) {
        const filtered = allFetchedClients.filter((user) =>
          user.name
            .toLowerCase()
            .trim()
            .includes(nameSearchValue.toLowerCase().trim())
        );
        mappedData = mapDataSource(filtered);
      } else {
        mappedData = mapDataSource(allFetchedClients);
      }
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    fetchCount,
    allFetchedClients,
    allFetchedClients?.length,
    nameSearchValue
  ]);

  return (
    <>

      <Table
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedClients,
          setModalOpened,
          setFetchCount,
          setSelectedClient,
          setIsLoading,
          user?.token
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
      {tablePagination && (
        <Pagination
          style={{ display: "flex", justifyContent: "center" }}
          defaultCurrent={1}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}

          onChange={(page, pageSize) => {
            setTablePagination({ ...tablePagination, current_page: page })
            // setTablePagination(...tablePagination , {current_page:page})
            setFetchCount((prev) => prev + 1);
           
            // if (isReportsPage) {
            //   history.push(
            //     `${storeRouterLinks?.outgoingPurchaseInvoiceReports}?page=${page}`
            //   );
            // } else {
            //   history.push(
            //     `${storeRouterLinks?.outgoingPurchaseInvoice}?page=${page}`
            //   );
            // }
          }}
          hideOnSinglePage={true}
        />
      )}
    </>
  );
};

export default PageTable;
