/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Table, Empty } from 'antd';
import tableColumns from './tableColumns';
import SettingsWarehousesContext from '../../../contexts/settings-contexts/SettinsWarehousesProvider';
// import pageData from './pageData';
import { useEffect } from 'react';
import { useState } from 'react';
import getAllWarehousesApi from '../../../apis/settings-apis/settings-warehouses-apis/getAllWarehouses';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useTranslation } from 'react-i18next';
// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const PageTable = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const {
    isLoading,
    setIsLoading,
    setModalOpened,
    fetchCount,
    setFetchCount,
    allFetchedWarehouses,
    setAllFetchedWarehouses,
    setSelectedWarehouse,
    nameSearchValue
  } = useContext(SettingsWarehousesContext);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: index + 1,
      name: obj?.name ? obj.name : '',
      title: obj?.title ? obj.title : ''
    }));
  };
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllWarehousesApi(user?.token),
        (res) => {
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data?.length >= 0) {
            const data = res.data.data;
            const pageData = data.map((obj) => ({
              ...obj,
              key: obj.id,
              name: obj.name,
              description: obj.note,
              workplace: obj?.workPlace
            }));
            setAllFetchedWarehouses(pageData);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedWarehouses?.length >= 0) {
      let mappedData = null;
      if (nameSearchValue.trim()) {
        const filtered = allFetchedWarehouses.filter((user) =>
          user.name
            .toLowerCase()
            .trim()
            .includes(nameSearchValue.toLowerCase().trim())
        );
        mappedData = mapDataSource(filtered);
      } else {
        mappedData = mapDataSource(allFetchedWarehouses);
      }
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    fetchCount,
    allFetchedWarehouses,
    allFetchedWarehouses?.length,
    nameSearchValue
  ]);

  return (
    <Table
      locale={{
        emptyText: (
          <Empty description={false}>
            {t('Inventory.Warehouses.noDataAvailable')}
          </Empty>
        )
      }}
      pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
      className="antd-custom-table"
      dataSource={tableDataSource}
      columns={tableColumns(
        allFetchedWarehouses,
        setModalOpened,
        setFetchCount,
        setSelectedWarehouse,
        setIsLoading,
        t,
        user?.token
      )}
      loading={isLoading}
      // scroll={{ x: 400 }}
      footer={false}
    />
  );
};

export default PageTable;
