/* eslint-disable eqeqeq */
import { Descriptions } from 'antd';
import React from 'react';
import './ReportsBoxs.scss';
import { useTranslation } from 'react-i18next';
const ReportsBoxs = React.forwardRef(
  ({ boxs, selectedPicker, currentLang }, ref) => {
    const { t } = useTranslation();
    // const systemSettings = useSystemSettings();
    const renderTaxLabel = () => {
      if (parseFloat(boxs?.tax) < 0) return t('taxInvoiceReports.taxDue');
      else if (parseFloat(boxs?.tax) > 0) return t('taxInvoiceReports.taxPaid');
      else if (parseFloat(boxs?.tax) == 0) return t('taxInvoiceReports.taxDue');
    };

    const renderTaxItself = () => {
      if (parseFloat(boxs?.tax) > 0) return boxs.tax;
      else if (parseFloat(boxs?.tax) < 0) return -1 * parseFloat(boxs.tax);
      else if (parseFloat(boxs?.tax) == 0)
        return t('taxInvoiceReports.withoutTaxDue');
    };

    const renderSystemSettings = (obj) => {
      const {
        name,
        phone,
        email,
        addressCode,
        buildingNum,
        streetName,
        district,
        city,
        anotherId,
        country,
        VATRegistrationNumber,
        tax_number,
        postalCode,
        image
      } = obj;
      return (
        <div className="system-settings-details-wrap">
          <Descriptions title="" bordered column={1}>
            <Descriptions.Item label={t('taxInvoiceReports.Logo')}>
              <div
                style={{
                  display: 'flex',
                  justifyItems: 'center',
                  textAlign: 'center'
                }}
              >
                <img
                  style={{
                    width: '140px'
                  }}
                  src={boxs?.setting?.image}
                  alt=""
                />
              </div>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions title="" bordered column={2}>
            {name && (
              <Descriptions.Item label={t('taxInvoiceReports.name')}>
                {name}
              </Descriptions.Item>
            )}
            {phone && (
              <Descriptions.Item label={t('taxInvoiceReports.phone')}>
                {phone}
              </Descriptions.Item>
            )}
            {email && (
              <Descriptions.Item label={t('taxInvoiceReports.email')}>
                {email}
              </Descriptions.Item>
            )}
            {buildingNum && (
              <Descriptions.Item label={t('taxInvoiceReports.buildingNumber')}>
                {buildingNum}
              </Descriptions.Item>
            )}
            {streetName && (
              <Descriptions.Item label={t('taxInvoiceReports.streetName')}>
                {streetName}
              </Descriptions.Item>
            )}
            {district && (
              <Descriptions.Item label={t('taxInvoiceReports.district')}>
                {district}
              </Descriptions.Item>
            )}
            {city && (
              <Descriptions.Item label={t('taxInvoiceReports.city')}>
                {city}
              </Descriptions.Item>
            )}
            {country && (
              <Descriptions.Item label={t('taxInvoiceReports.country')}>
                {country}
              </Descriptions.Item>
            )}
            {postalCode && (
              <Descriptions.Item label={t('taxInvoiceReports.postalCode')}>
                {postalCode}
              </Descriptions.Item>
            )}
            {addressCode && (
              <Descriptions.Item label={t('taxInvoiceReports.addressCode')}>
                {addressCode}
              </Descriptions.Item>
            )}
            {VATRegistrationNumber && (
              <Descriptions.Item label={t('taxInvoiceReports.vatNumber')}>
                {VATRegistrationNumber}
              </Descriptions.Item>
            )}
            {tax_number && (
              <Descriptions.Item label={t('taxInvoiceReports.vatNumber')}>
                {tax_number}
              </Descriptions.Item>
            )}
            {anotherId && (
              <Descriptions.Item label={t('taxInvoiceReports.anotherId')}>
                {anotherId}
              </Descriptions.Item>
            )}
          </Descriptions>
        </div>
      );
    };

    return (
      <div
        className="taxs-settings-wrap"
        ref={ref}
        style={{
          padding: '32px 22px',
          display: 'grid',
          justifyContent: 'start',
          direction: currentLang == 'ar' ? 'rtl' : 'ltr'
        }}
      >
        <div
          className="custom-hidden-content"
          style={{
            display: 'grid',
            justifyItems: 'center',
            textAlign: 'center'
          }}
        >
          {/* <QRCode
          value={`${
            process.env.REACT_APP_FATOORAH_URL
          }${routerLinks.generalVatReportsPage()}?client_id=${
            boxs?.setting?.id ? boxs?.setting.id : ''
          }&from=${
            selectedPicker?.strings[0] ? selectedPicker.strings[0] : ''
          }&to=${selectedPicker?.strings[1] ? selectedPicker?.strings[1] : ''}`}
        /> */}
          {selectedPicker?.strings[0] && (
            <div
              style={{
                marginTop: '8px',
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                fontSize: '18px'
              }}
            >
              ( <p>{selectedPicker?.strings[0]}</p>
              ----
              <p>{selectedPicker?.strings[1]}</p> )
            </div>
          )}
        </div>

        {boxs?.setting && renderSystemSettings(boxs?.setting)}

        <div className="taxs-boxes-wrap">
          <div className="purchases-sales-boxs-wrap">
            <div className="purchases-desc-wrap">
              <Descriptions bordered column={1}>
                <Descriptions.Item
                  style={{ fontWeight: 'bold' }}
                  label={t('taxInvoiceReports.purchases')}
                >
                  {t('taxInvoiceReports.value')}
                </Descriptions.Item>
                <Descriptions.Item
                  label={t('taxInvoiceReports.totalPurchasesWithoutVAT')}
                >
                  {boxs?.buyPrice}
                </Descriptions.Item>
                <Descriptions.Item label={t('taxInvoiceReports.VATValue')}>
                  {boxs?.buyTax}
                </Descriptions.Item>
                <Descriptions.Item
                  label={t('taxInvoiceReports.totalPurchases')}
                >
                  {boxs?.buyTotal}
                </Descriptions.Item>
              </Descriptions>
            </div>
            <div className="sales-desc-wrap">
              <Descriptions bordered column={1}>
                <Descriptions.Item
                  style={{ fontWeight: 'bold' }}
                  label={t('taxInvoiceReports.sales')}
                >
                  {t('taxInvoiceReports.value')}
                </Descriptions.Item>
                <Descriptions.Item
                  label={t('taxInvoiceReports.totalSalesWithoutVAT')}
                >
                  {boxs?.salePrice}
                </Descriptions.Item>
                <Descriptions.Item label={t('taxInvoiceReports.VATValue')}>
                  {boxs?.saleTax}
                </Descriptions.Item>
                <Descriptions.Item label={t('taxInvoiceReports.totalSales')}>
                  {boxs?.saleTotal}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
          <Descriptions bordered column={1}>
            <Descriptions.Item label={renderTaxLabel()}>
              {renderTaxItself()}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
    );
  }
);

export default ReportsBoxs;
