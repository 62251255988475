import React, { useContext } from 'react';
import SaleInvoicesContext from '../../contexts/sale-invoices-context/SaleInvoicesContext';
import PageTable from './PageTable';
import SelectedSaleInvoiceModal from './SelectedSaleInvoiceModal';
import { useTranslation } from 'react-i18next';
const SharedSalesInvoice = ({
  allFetchedSaleInvoices,
  isReportsPage,
  taxPage,
  selectedPicker
}) => {
  const { selectedSaleInvoiceModalOpned } = useContext(SaleInvoicesContext);

  return (
    <>
      <PageTable
        allFetchedSaleInvoices={allFetchedSaleInvoices}
        isReportsPage={isReportsPage}
        taxPage={taxPage}
        selectedPicker={selectedPicker}
      />

      {selectedSaleInvoiceModalOpned && <SelectedSaleInvoiceModal />}
    </>
  );
};

export default SharedSalesInvoice;
