import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import SettingsWarehousesContext from '../../../contexts/settings-contexts/SettinsWarehousesProvider';
import { useTranslation } from 'react-i18next';

const SettingsWorkPlaceModal = () => {
  const {
    modalOpened,
    setModalOpened,
    setSelectedWarehouse,
    selectedWarehouse
  } = useContext(SettingsWarehousesContext);
  const { t } = useTranslation();

  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        selectedWarehouse
          ? t('Inventory.Warehouses.modifyWarehouseData')
          : t('Inventory.Warehouses.addNewWarehouse')
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedWarehouse(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedWarehouse(null);
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default SettingsWorkPlaceModal;
