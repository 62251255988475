import { useState, useEffect, createContext } from 'react';

const INITIAL_VALUES = {
  rendered: false,
  setRendered: (v) => {}
};

const AlertModalContext = createContext(INITIAL_VALUES);

export const AlertModalProvider = ({ children }) => {
  const [rendered, setRendered] = useState(INITIAL_VALUES.rendered);
  return (
    <AlertModalContext.Provider value={{ rendered, setRendered }}>
      {children}
    </AlertModalContext.Provider>
  );
};

export default AlertModalContext;
