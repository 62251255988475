import { DatePicker } from 'antd';
import React, { useCallback, useContext } from 'react';
import excelImg from '../../../assets/imgs/icons/excel-btn.png';
import CalendarIcon from '../../../common/icons/CalendarIcon';
import UserContext from '../../../contexts/user-context/UserProvider';
// import Workbook from 'react-excel-workbook';
import debounce from 'lodash.debounce';
import { useState } from 'react';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import './TableFilter.scss';
import SummaryContext from '../../../contexts/accounting-context/SummaryContext';
import getAllSummaryApi from '../../../apis/accounting-apis/accounting-details-apis/getAllSummaryApi';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
const { RangePicker } = DatePicker;

const TableFilter = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const systemSettings = useSystemSettings();


  //   const [selectedPicker, setSelectedPicker] = React.useState(null);
  const [selectedPicker, setSelectedPicker] = useState();

  const {
    setAllSummary,
    setIsLoading,
    tableFilter,
    setTableFilter,
    
  } = useContext(SummaryContext);

  const handlePickerChange = (value, string) => {
    setSelectedPicker({
      moments: value,
      strings: string
    });
  };
  React.useEffect(() => {
    setTableFilter((prev) => ({
      ...prev,
      from: selectedPicker?.strings[0] || moment("2023-01-01").format("YYYY-MM-DD"),
      to: selectedPicker?.strings[1] || moment(date).format("YYYY-MM-DD")
    }));
  }, [selectedPicker?.strings]);

  // const handleSearchFilter = (e) => {
  //   debouncedChangeHandler(e);
  // };

  const debouncedChangeHandler = useCallback(
    debounce(async (e) => {
      setTableFilter((prevState) => ({
        ...prevState,
        invoiceNumber: e.target.value
      }));
    }, 500),
    []
  );
  const date = new Date();
  
  // useEffect(() => {
  //   setText({
      
  //     Picker: [moment("2020-03-09 13:00"), moment("2020-03-27 13:17")]
  //   });
  // }, []);

  // useEffect(() => {
  //   setIsLoading(true);
  //   customApiRequest(
  //     getAllSummaryApi(user?.token, {
  //       ...tableFilter
  //     }),
  //     (res) => {
  //       setIsLoading(false);
  //       if (res?.status === 200 && res?.data?.data) {
  //         const data = res.data.data.invoices.data;
  //         setAllFetchedReceipts(data);
  //         setTablePagination(res?.data?.data?.invoices?.pagination);
  //       } else {
  //       }
  //     },
  //     (error) => {
  //       setIsLoading(false);
  //     }
  //   );
  // }, [tableFilter]);

  const customApiRequest = useCustomApiRequest();
  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    customApiRequest(
      getAllSummaryApi(user?.token, {
        ...tableFilter
      }),
      (res) => {
        setIsLoading(false);
        if (res?.status === 200 && res?.data?.data) {
          const data = res.data.data;
          // setFetchCount((prev) => prev + 1)
          setAllSummary(data);
        } else {
          setIsLoading(false);
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }

  return (
    <div
      style={{ marginTop: '15px' }}
      className="custom-table-fitler summary-table-filter"
    >
      <div className="form-excel-export-summary">
        <form
          className="filter-form receipt-filter-form"
          onSubmit={handleSubmit}
        >
          <div className="range-picker-wrap">
            <RangePicker
              value={selectedPicker?.moments && selectedPicker.moments}
              onChange={handlePickerChange}
              // format="YYYY-MM-DD"
              className={`filter-range-picker`}
              allowClear={true}
              suffixIcon={<CalendarIcon />}
              placeholder={[
                t('FinancialTerms.DateFrom'),
                t('FinancialTerms.DateTo')
              ]}
              defaultValue={[moment("2023-01-01"), moment(date)]}
            />
          </div>
          <button className="filter-sum-btn" type="submit">
            {t('FinancialTerms.Search')}
          </button>
          <div className="excel-btn-wrap">
            <a
              href={`${
                process.env.REACT_APP_BASE_URL
              }/Excel/exportTrialBalance?client_id=${
                systemSettings?.id
              }&from_date=${
                tableFilter?.from ? tableFilter?.from : ''
              }&to_date=${tableFilter?.to ? tableFilter?.to : ''}`}
              download
              className="excel-export-btn"
            >
              <img
                style={{ height: '45px' }}
                src={excelImg}
                alt="export to excel"
              />
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TableFilter;
