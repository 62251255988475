/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import PosInvoice from './PosInvoice';
import useMainCategories from '../../custom-hooks/useMainCategories';
import useSubCategories from '../../custom-hooks/useSubCategories';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import { WarningOutlined } from '@ant-design/icons';
import RenderProducts from './RenderProducts';
import { Spin } from 'antd';
import PosMainFilter from './PosMainFilter';
import './PosStepOne.scss';
import NotificationsProfile from '../app/pos-section/notifications-profile/NotificationsProfile';
import CategorySwiper from '../pos-category-swiper/CategorySwiper';
import { useTranslation } from 'react-i18next';

const PosStepOne = () => {
  const {
    selectedMainCategory,
    selectedSubCategory,
    setSelectedSubCategory,
    allSubCategories,
    setAllMainCategories,
    setAllSubCategories,
    loadingProducts,
    allProducts
  } = useContext(PosInvoiceContext);
  const { t } = useTranslation();
  const allMainCats = useMainCategories();
  const allSubCats = useSubCategories(selectedMainCategory);
  useEffect(() => {
    setAllMainCategories(allMainCats);
  }, [allMainCats]);
  useEffect(() => {
    setAllSubCategories(allSubCats);
  }, [allSubCats]);

  useEffect(() => {
    if (selectedMainCategory && selectedSubCategory) {
      if (
        allSubCategories?.length >= 0 &&
        allSubCategories[0]?.cat_id != parseInt(selectedMainCategory)
      ) {
        setSelectedSubCategory('');
      }
    }
  }, [selectedMainCategory, selectedSubCategory]);

  const [productsAppearance, setProductsAppearance] = React.useState('window');

  const renderProducts = () => {
    // if (loadingProducts && (!allProducts || allProducts?.length === 0)) {
    if (loadingProducts) {
      return (
        <div
          style={{
            padding: 32,
            display: 'grid',
            placeItems: 'center',
            minHeight: 142
          }}
        >
          <Spin />
        </div>
      );
    } else if (!loadingProducts && allProducts?.length > 0) {
      return (
        <RenderProducts
          productsAppearance={productsAppearance}
          setProductsAppearance={setProductsAppearance}
          products={allProducts}
          step={1}
        />
      );
    } else if (
      !loadingProducts &&
      // selectedSubCategory &&
      allProducts?.length === 0
    )
      return (
        <div
          style={{
            padding: 32,
            display: 'grid',
            placeItems: 'center',
            minHeight: 142
          }}
        >
          <p
            style={{
              backgroundColor: '#fcebb8',
              padding: '12px 28px',
              borderRadius: '8px',
              border: '1px solid #ffc107',
              fontSize: 18
            }}
          >
            <WarningOutlined /> {t('Pos.Main.noProductsAvailable')}
          </p>
        </div>
      );
    return null;
  };

  const [scrollY, setScrollY] = React.useState(
    window.scrollY || document.documentElement.scrollTop
  );
  const [setScrollDir] = React.useState(null);
  useEffect(() => {
    const handleScroll = () => {
      // if ((window.scrollY || document.documentElement.scrollTop) > scrollY) {
      //   setScrollDir('scroll-down');
      // } else {
      //   setScrollDir('scroll-up');
      // }
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollY]);

  return (
    <div className="stepper-step pos-step-one">
      <PosInvoice />
      <div className="step-main-content">
        {scrollY < 100 ? (
          <div className="pos-setp-one-filter-notifications-wrap">
            <PosMainFilter />
            <NotificationsProfile />
          </div>
        ) : (
          <div className="pos-setp-one-filter-notifications-wrap">
            <PosMainFilter
              style={{
                boxShadow: '0 4px 6px rgba(0, 0, 0, .12)'
              }}
            />
          </div>
        )}
        <CategorySwiper />

        <div className={`step-field-wrap ${productsAppearance}`}>
          {renderProducts()}
        </div>
      </div>
    </div>
  );
};

export default PosStepOne;
