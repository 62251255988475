import axios from 'axios';

const getProductsWithFirstTerm = async (token, values, first_quantity) => {
  try {
    const res = await axios.get(
      `/Product/all?searchText=${values?.searchText ? values.searchText : ''}
      &first_quantity=${first_quantity ? first_quantity : ''}&parent_cat=${
        values?.parent_cat ? values.parent_cat : ''
      }&stock_id=${values?.stock_id ? values.stock_id : ''}&work_place=${
        values?.work_place ? values.work_place : ''
      }&page=${values.page ? values.page : 1}&test=1`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token,
          lang: 'ar'
        }
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export default getProductsWithFirstTerm;
