import { Button, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { DeleteOutlined } from '@ant-design/icons';
import StoreNewProductModalContext from '../../contexts/store-new-product-context/StoreNewProductProvider';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
const HandleIngredientsComponent = (props) => {
  const {
    setTotalAmountForIngredients,
    modalOpened,
    totalAmountForIngredients
  } = useContext(StoreNewProductModalContext);
  const [getProductComplexId, setGetProductComplexId] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    props.setValue(
      `ingredients.${props.index}.price`,
      props.watch(`ingredients.${props.index}.price`) &&
        props.watch(`ingredients.${props.index}.price`)
    );
    props.setValue(
      `ingredients.${props.index}.quantity`,
      props.watch(`ingredients.${props.index}.quantity`) &&
        props.watch(`ingredients.${props.index}.quantity`)
    );
    props.setValue(
      `ingredients.${props.index}.unique_id`,
      props.watch(`ingredients.${props.index}.unique_id`) &&
        props.watch(`ingredients.${props.index}.unique_id`)
    );
  }, [
    props.watch(`ingredients.${props.index}.price`),
    props.watch(`ingredients.${props.index}.unique_id`),
    props.watch(`ingredients.${props.index}.quantity`)
  ]);
  // useEffect(() => {
  //   if (modalOpened) {
  //     // arr.push(props.watch(`ingredients.${props.index}.price`));
  //     props.watch(`ingredients.${props.index}.price`) > 0 &&
  //       setTotalAmountForIngredients((current) => [
  //         ...current,
  //         {
  //           id: props.watch(`ingredients.${props.index}.id`),
  //           value: +props.watch(`ingredients.${props.index}.price`)
  //         }
  //       ]);
  //   }
  // }, [props.watch(`ingredients.${props.index}.price`)]);
  return (
    <div>
      <div className="field-delete-li field-delete-li-ingredients">
        <div className="select-label-wrap">
          <p className="label-p">{t('Inventory.Products.selectComponent')}</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`ingredients.${props.index}.unique_id`}
              errorMsg={
                props.errors?.unique_id &&
                props.errors.ingredients[props.index]?.unique_id &&
                props.errors.ingredients[props.index].unique_id.message
              }
              validateStatus={
                props.errors?.unique_id &&
                props.errors.ingredients[props.index]?.unique_id &&
                props.errors.ingredients[props.index].unique_id
                  ? 'error'
                  : ''
              }
              control={props.control}
              setValue={props.setValue}
              placeholder={t('Inventory.Products.selectComponent')}
              options={
                props?.allProductsIngredients?.length > 0
                  ? props?.allProductsIngredients?.map((prod) => ({
                      title: prod?.name,
                      value: String(prod?.id)
                    }))
                  : null
              }
              showSearch={true}
              filterOption={(input, option) =>
                 option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) => setGetProductComplexId(e)}
              // value={props?.selectedProduct?.name}
              formClassName="store-new-product-form"
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.Products.quantityUsed')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name={`ingredients.${props.index}.quantity`}
              type="text"
              placeholder={t('Inventory.Products.quantityUsedPlaceholder')}
              errorMsg={
                props.errors.ingredients &&
                props.errors.ingredients[props.index]?.quantity &&
                props.errors.ingredients[props.index]?.quantity.message
              }
              validateStatus={
                props.errors.ingredients &&
                props.errors.ingredients[props.index]?.quantity &&
                props.errors.ingredients[props.index]?.quantity
                  ? 'error'
                  : ''
              }
              // defaultValue={
              //   parseFloat(
              //     props.allProductsIngredients?.length > 0 &&
              //       props.allProductsIngredients
              //         ?.filter((getId) => getId?.id == getProductComplexId)
              //         ?.map((getQuantity) =>
              //           parseFloat(getQuantity?.quantity)
              //             ? parseFloat(getQuantity?.quantity)
              //             : 0
              //         )
              //   ) || ''
              // }
              control={props.control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.Products.productPrice')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              setValue={props.setValue}
              className="form-text-field"
              name={`ingredients.${props.index}.price`}
              type="text"
              placeholder={t('Inventory.Products.productPricePlaceholder')}
              errorMsg={
                props.errors.ingredients &&
                props.errors.ingredients[props.index]?.price &&
                props.errors.ingredients[props.index]?.price.message
              }
              validateStatus={
                props.errors.ingredients &&
                props.errors.ingredients[props.index]?.price &&
                props.errors.ingredients[props.index]?.price
                  ? 'error'
                  : ''
              }
              defaultValue={
                parseFloat(
                  props.allProductsIngredients?.length > 0 &&
                    props.allProductsIngredients
                      ?.filter((getId) => getId?.id == getProductComplexId)
                      ?.map((getPrice) =>
                        parseFloat(getPrice?.buyPrice)
                          ? parseFloat(getPrice?.buyPrice)
                          : 0
                      )
                ) || ''
              }
              control={props.control}
              readOnly={true}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.Products.measruingUnit')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              setValue={props.setValue}
              className="form-text-field"
              name={`ingredients.${props.index}.unit_name`}
              type="text"
              placeholder={t('Inventory.Products.measruingUnitPlaceholder')}
              errorMsg={
                props.errors.ingredients &&
                props.errors.ingredients[props.index]?.unit_name &&
                props.errors.ingredients[props.index]?.unit_name.message
              }
              validateStatus={
                props.errors.ingredients &&
                props.errors.ingredients[props.index]?.unit_name &&
                props.errors.ingredients[props.index]?.unit_name
                  ? 'error'
                  : ''
              }
              defaultValue={
                (props.allProductsIngredients?.length > 0 &&
                  props.allProductsIngredients
                    ?.filter((getId) => getId?.id == getProductComplexId)
                    ?.map((getUnit) => getUnit?.unit?.name)) ||
                ''
              }
              control={props.control}
              readOnly={true}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.Products.inventory')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              setValue={props.setValue}
              className="form-text-field"
              name={`ingredients.${props.index}.first_quantity`}
              type="text"
              placeholder={t('Inventory.Products.inventoryPlaceholder')}
              errorMsg={
                props.errors.ingredients &&
                props.errors.ingredients[props.index]?.first_quantity &&
                props.errors.ingredients[props.index]?.first_quantity.message
              }
              validateStatus={
                props.errors.ingredients &&
                props.errors.ingredients[props.index]?.first_quantity &&
                props.errors.ingredients[props.index]?.first_quantity
                  ? 'error'
                  : ''
              }
              defaultValue={
                (props.allProductsIngredients?.length > 0 &&
                  props.allProductsIngredients
                    ?.filter((getId) => getId?.id == getProductComplexId)
                    ?.map((getQuantity) => getQuantity?.quantity)) ||
                ''
              }
              control={props.control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.Products.estimatedOutput')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              setValue={props.setValue}
              className="form-text-field"
              name={`ingredients.${props.index}.result`}
              type="text"
              placeholder={t('Inventory.Products.estimatedOutputPlaceholder')}
              errorMsg={
                props.errors.ingredients &&
                props.errors.ingredients[props.index]?.result &&
                props.errors.ingredients[props.index]?.result.message
              }
              validateStatus={
                props.errors.ingredients &&
                props.errors.ingredients[props.index]?.result &&
                props.errors.ingredients[props.index]?.result
                  ? 'error'
                  : ''
              }
              control={props.control}
              // defaultValue={((watch(`ingredients.${props.index}.quantity`) !== '') && parseFloat(watch(`ingredients.${props.index}.first_quantity`)) / parseFloat(watch(`ingredients.${props.index}.quantity`))) || ''}
              // defaultValue={getProductComplexId && parseFloat(watch(`ingredients.${props.index}.first_quantity`)) + parseFloat(watch(`ingredients.${props.index}.quantity`))}
              defaultValue={
                parseFloat(
                  props.allProductsIngredients?.length > 0 &&
                    props.allProductsIngredients
                      ?.filter((getId) => getId?.id == getProductComplexId)
                      ?.map((getQuantity) => getQuantity?.quantity)
                ) /
                  parseFloat(
                    props.watch(`ingredients.${props.index}.quantity`)
                  ) || ''
              }
              readOnly={true}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.Products.waste')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              //   setValue={setValue}
              className="form-text-field"
              name={`ingredients.${props.index}.waste`}
              type="text"
              placeholder={t('Inventory.Products.wastePlaceholder')}
              errorMsg={
                props.errors.ingredients &&
                props.errors.ingredients[props.index]?.waste &&
                props.errors.ingredients[props.index]?.waste.message
              }
              validateStatus={
                props.errors.ingredients &&
                props.errors.ingredients[props.index]?.waste &&
                props.errors.ingredients[props.index]?.waste
                  ? 'error'
                  : ''
              }
              control={props.control}
            />
          </div>
        </div>
        {props.ingredientsFields.length > 1 && (
          <Tooltip title={t('Inventory.Products.delete')}>
            <Button
              style={{ marginTop: '15px', color: 'red' }}
              className="delete-field-btn"
              size="large"
              type="dashed"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => {
                // let totalAmountAfterDelete = totalAmountForIngredients.filter(
                //   (p) => {
                //     return p.id != props.watch(`ingredients.${props.index}.id`);
                //   }
                // );
                // setTotalAmountForIngredients([...totalAmountAfterDelete]);
                props.removeIngredientsField(props.index);
              }}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default HandleIngredientsComponent;
