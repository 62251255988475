import React, { useContext, useEffect } from 'react'
import './ViewTransaction.scss'
import TransactionsContext from '../../../contexts/accounting-context/TransactionsContext'
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest'
import UserContext from '../../../contexts/user-context/UserProvider'
import getAllTransactionsApi from '../../../apis/accounting-apis/accounting-details-apis/getAllTransactionsApi'
import getSingleTransactionApi from '../../../apis/accounting-apis/accounting-details-apis/getSingleTransactionApi'
import { useState } from 'react'
import { Spin } from 'antd'
const ViewTransaction = () => {
    const { user } = useContext(UserContext);
    const {
        selectedTransactionId,
        setIsLoading,
        isLoading,
        singleTransaction,
        setSingleTransaction,
        fetchCount,
    } = useContext(TransactionsContext)

    const customApiRequest = useCustomApiRequest();
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setIsLoading(true);
            customApiRequest(
                getSingleTransactionApi(user?.token, selectedTransactionId),
                (res) => {
                    setIsLoading(false);
                    setSingleTransaction(res?.data?.data);
                },
                (error) => {
                    setIsLoading(false);
                }
            );
        }

        return () => {
        isMounted = false;
        };
    }, [fetchCount]);

    const handleFetchDataForTrans = () => {
        if (isLoading) {
            <div className="spin-tree-wrap">
                <Spin />
            </div>
        } else if (singleTransaction) {
            return(
                <div className='view-transaction-content'>
                    <div>
                        <h4>اسـم الحـسـاب</h4>
                        <span> : </span>
                        <span>{singleTransaction?.account_name}</span>
                    </div>
                    <div>
                        <h4>الـدائـن</h4>
                        <span> : </span>
                        <span>{singleTransaction?.credit && singleTransaction?.credit}</span>
                    </div>
                    <div>
                        <h4>الـمـديـن</h4>
                        <span> : </span>
                        <span>{singleTransaction?.debit && singleTransaction?.debit}</span>
                    </div>
                    <div>
                        <h4>مـلـحـوظـه</h4>
                        <span> : </span>
                        <span>{singleTransaction?.note && singleTransaction?.note}</span>
                    </div>
                    <div>
                        <h4>طـريـقـه الـدفـع</h4>
                        <span> : </span>
                        <span>{singleTransaction?.method && singleTransaction?.method}</span>
                    </div>
                    <div>
                        <h4>النـــوع</h4>
                        <span> : </span>
                        <span>{singleTransaction?.type && singleTransaction?.type}</span>
                    </div>
                </div>
            )
        }
    }

    return (
        <div className='view-transaction'>
            <h2>تفاصيل المعامله</h2>
            <hr style={{marginBottom: '20px'}}/>
            {handleFetchDataForTrans()}
        </div>
    )
}

export default ViewTransaction