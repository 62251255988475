import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import { Button } from 'antd';
import TransactionsContext from '../../../contexts/accounting-context/TransactionsContext';
import AccountStatementContext from '../../../contexts/accounting-context/AccountStatementContext';
import TableFilter from './TableFilter';
import { useEffect } from 'react';
import getAccountsListLeafApi from '../../../apis/accounting-apis/accounting-details-apis/getAccountsListLeafApi';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import UserContext from '../../../contexts/user-context/UserProvider';
import AccountStatementDetailsTable from './AccountStatementDetailsTable';

const AccountStatementPage = () => {
    const {user} = useContext(UserContext)
    const { 
        modalOpened,
        apiLoading,
        modalOpenedCreate,
        setModalOpenedCreate,
        setAccountsLists,
        accountsLists,
        fetchedAccountStatementDetails
    } = useContext(AccountStatementContext);

    const customApiRequest = useCustomApiRequest();
    useEffect(() => {
        let api, successMessage, errorMessage;
        api = getAccountsListLeafApi(user?.token, user?.client_id);
        customApiRequest(api, (res) => {
            if (res?.data?.accounts) {
                setAccountsLists(res?.data?.accounts);
            }
        });
    }, []);

    return (
    <div className="accounting-page">
        <div className="page-head-wrap">
            <div
                className="page-title"
                style={{
                display: 'flex',
                alignItems: 'center',
                gap: 32
                }}
            >
                <div>
                    <h2 className="bold-font">كـشـف حـسـاب</h2>
                    <p>كشف وعرض كـشـف الحـسـاب</p>
                </div>
            </div>
            <SearchNotificationsProfile />
        </div>
        <TableFilter />
        {fetchedAccountStatementDetails && <AccountStatementDetailsTable />}
        {/* <AccountingTree /> */}
        {/* {<TransactionsDetailsPage />} */}

        {/* {modalOpened && <TransactionsDetailsModal />}
        {modalOpenedCreate && <TransactionsCreateModal />} */}
    </div>
    );
}

export default AccountStatementPage;
