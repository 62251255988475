import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedIngredients: null,
  setAllFetchedIngredients: (v) => {},
  selectedIngredient: null,
  setSelectedIngredient: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {},
};

const StoreProductsIngredientsContext = createContext(INITIAL_VALUES);

export const StoreProductsIngredientsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedIngredients, setAllFetchedIngredients] = useState(
    INITIAL_VALUES.allFetchedIngredients
  );
  const [selectedIngredient, setSelectedIngredient] = useState(
    INITIAL_VALUES.selectedIngredient
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES?.tablePagination
  );

  return (
    <StoreProductsIngredientsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedIngredients,
        setAllFetchedIngredients,
        selectedIngredient,
        setSelectedIngredient,
        isSubmittingForm,
        setIsSubmittingForm,
        tablePagination,
        setTablePagination
      }}
    >
      {children}
    </StoreProductsIngredientsContext.Provider>
  );
};

export default StoreProductsIngredientsContext;
