/* eslint-disable eqeqeq */
import { PrinterOutlined } from '@ant-design/icons';
import { Button, Descriptions, Empty, Modal, Spin, Table, Tooltip } from 'antd';
import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import getSingleCustomerPayment from '../../apis/store-apis/customer-payments/getSingleCustomerPayment';
import CustomersPaymentsContext from '../../contexts/customers-payments-context/CustomersPaymentsContext';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import useSystemSettings from '../../custom-hooks/useSystemSettings';

const SelectedCustomerPayment = () => {
  const systemSettings = useSystemSettings();
  const {
    selectedCustomerPayment,
    setSelectedCustomerPayment,
    setCustomerPaymentModalOpened,
    customerPaymentModalOpened,
    setIsSingleLoading,
    isSingleLoading,
    selectedCustomerPaymentId
  } = React.useContext(CustomersPaymentsContext);

  const { user } = useContext(UserContext);

  const a4Ref = useRef();

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsSingleLoading(true);
      customApiRequest(
        getSingleCustomerPayment(user?.token, selectedCustomerPaymentId),
        (res) => {
          setIsSingleLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            const data = res?.data?.data;
            setSelectedCustomerPayment(data);
          }
        },
        (error) => {
          setIsSingleLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const handlePrintA4 = useReactToPrint({
    content: () => a4Ref.current,
    pageStyle: `
      @media print {
        html, body { height: initial !important; overflow: initial !important; }
        // tr { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
        // td { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
        tr { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
        td { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
        tbody { display: table-row-group !important; }
        thead {
          display: table-row-group !important;
        }
        tfoot {
          display: table-row-group !important;
        }
      @page {
        // content:url('../../../assets/imgs/Watermark.png') !important;
        size: auto;
        margin: none;
      }
      .qr-code-wrap canvas {
        width: 128px !important;
        height: 128px !important;
      }
      .pagebreak {
        display: block;
        size: auto;
      }
      .invoice-details-wrap {

        padding: 0;
      }
      .ant-descriptions-item-content,
        .ant-descriptions-item-label {
          padding: 4px 12px !important;
        }
      }
      .ant-descriptions-header {
        margin: 4px 0 !important;
      }
      .models-title {
        direction: rtl;
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .ant-table-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
      }
      th {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      .invoice-extra-prices-table .ant-table-cell {
        padding: 4px 12px !important;
        text-align: right;
      }
    `
  });
  const { t } = useTranslation();

  const renderModels = (details) => {
    return (
      <div className="models-wrap pagebreak" style={{ marginTop: '30px' }}>
        <h3 style={{ marginBottom: '8px' }}>
          {t('ClientPayments.BatchDescription')}
        </h3>
        <div className="receiptDetails-wrapper">
          <Table
            bordered
            // scroll={{ x: 1032 }}
            locale={{
              emptyText: (
                <Empty description={false}>
                  {t('Messages.noDataAvailable')}
                </Empty>
              )
            }}
            pagination={false}
            // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            // className="antd-custom-table"
            dataSource={[
              {
                invoice_id: details?.invoice_id,
                amount: details?.amount,
                date: details?.date,
                date_printed: details?.date_printed,
                balance: details?.user?.balance,
                paid: details?.user?.paid,
                unpaid: details?.user?.unpaid
              }
            ]}
            columns={[
              {
                title: t('ClientPayments.PaymentReceiptNumber'),
                dataIndex: 'invoice_id',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record?.invoice_id}</span>
                    </div>
                  );
                }
              },
              {
                title: t('ClientPayments.PaymentAmount'),
                dataIndex: 'amount',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record?.amount}</span>
                    </div>
                  );
                }
              },
              {
                title: t('ClientPayments.PaymentDate'),
                dataIndex: 'date',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record?.date || '----'}</span>
                    </div>
                  );
                }
              },
              // {
              //   title: 'تاريخ طباعة الإيصال',
              //   dataIndex: 'date_printed',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record?.date_printed || '-----'}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: t('ClientPayments.InvoiceBalance'),
                dataIndex: 'balance',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record?.balance}</span>
                    </div>
                  );
                }
              },
              {
                title: t('ClientPayments.AmountPaid'),
                dataIndex: 'paid',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record?.paid}</span>
                    </div>
                  );
                }
              },
              {
                title: t('ClientPayments.DueFromCustomer'),
                dataIndex: 'unpaid',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record?.unpaid}</span>
                    </div>
                  );
                }
              }
            ]}
            // loading={isLoading}
            // scroll={{x:false}}
            footer={false}
          />
        </div>
      </div>
    );
  };
  const renderCompanyData = (obj) => {
    return (
      <div className="supplier-details-wrap">
        <div
          style={{
            marginBottom: '8px',
            fontWeight: 'bold'
          }}
        >
          {t('ClientPayments.CompanyDetails')}
        </div>
        <Descriptions title={''} bordered column={1}>
          {systemSettings?.name && (
            <Descriptions.Item
              label={t('ClientPayments.Name')}
              style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
            >
              {systemSettings?.name}
            </Descriptions.Item>
          )}
          {systemSettings?.phone && (
            <Descriptions.Item
              label={t('ClientPayments.PhoneNumber')}
              style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
            >
              {systemSettings?.phone}
            </Descriptions.Item>
          )}
          {systemSettings?.address && (
            <Descriptions.Item
              label={t('ClientPayments.Address')}
              style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
            >
              {systemSettings?.address}
            </Descriptions.Item>
          )}
          {systemSettings?.VATRegistrationNumber && (
            <Descriptions.Item
              label={t('ClientPayments.taxNumber')}
              style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
            >
              {systemSettings?.VATRegistrationNumber}
            </Descriptions.Item>
          )}
          {systemSettings?.commercialRecord && (
            <Descriptions.Item
              label={t('ClientPayments.CommercialRegistrationNumber')}
              style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
            >
              {systemSettings?.commercialRecord}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  };
  const renderClientData = (obj) => {
    const {
      name,
      phone,
      city,
      country,
      VATRegistrationNumber,
      commercialRecord,
      date_printed
      // address,
      // addressCode,
      // tax_number,
      // taxNumber,
    } = obj;
    return (
      <div className="supplier-details-wrap">
        <div
          style={{
            marginBottom: '8px',
            fontWeight: 'bold'
          }}
        >
          {t('ClientPayments.CustomerInformation')}
        </div>
        <Descriptions title={''} bordered column={1}>
          {name && (
            <Descriptions.Item
              label={t('ClientPayments.Name')}
              style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
            >
              {name}
            </Descriptions.Item>
          )}
          {phone && (
            <Descriptions.Item
              label={t('ClientPayments.PhoneNumber')}
              style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
            >
              {phone}
            </Descriptions.Item>
          )}
          {city && (
            <Descriptions.Item
              label={t('ClientPayments.Address')}
              style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
            >{`${country} , ${city}`}</Descriptions.Item>
          )}
          {VATRegistrationNumber && (
            <Descriptions.Item
              label={t('ClientPayments.taxNumber')}
              style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
            >
              {VATRegistrationNumber}
            </Descriptions.Item>
          )}
          {commercialRecord && (
            <Descriptions.Item
              label={t('ClientPayments.CommercialRegistrationNumber')}
              style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
            >
              {commercialRecord}
            </Descriptions.Item>
          )}
          {date_printed && (
            <Descriptions.Item
              label={t('ClientPayments.ReceiptPrintDate')}
              style={{ height: '40px', fontWeight: '500', fontSize: '16px' }}
            >
              {date_printed}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  };

  const renderInvoiceHeading = () => {
    return t('supplierPayments.clientPaymentsDetails');
  };

  const renderModalBody = () => {
    return (
      <div
        className="invoice-details-wrap"
        ref={a4Ref}
        style={{
          padding: '28px 28px',
          direction: 'rtl',
          display: 'grid',
          gap: '16px'
        }}
      >
        <div
          className="qr-code-logo-wrap"
          style={{
            display: 'flex',
            gap: '22px',
            alignItems: 'center',
            direction: 'rtl',
            justifyContent: 'space-between'
            // gridTemplateColumns: 'auto 1fr auto'
          }}
        >
          <div
            className="img-wrap"
            style={{
              display: 'grid',
              placeItems: 'center',
              minWidth: '92px'
              // minHeight: '142px'
            }}
          >
            {systemSettings?.image ? (
              <img
                className="system-logo   "
                src={systemSettings?.image}
                alt="logo"
                style={{
                  // width: '92px',
                  height: '60px'
                }}
              />
            ) : null}
          </div>
          <div
            style={{
              fontSize: 20,
              color: '#000',
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 12
            }}
          >
            <span style={{ marginLeft: '100px' }}>
              {t('ClientPayments.PaymentReceipt')}
            </span>
          </div>
          <div
            style={{
              visibility: 'hidden'
            }}
          >
            -
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 50
          }}
        >
          {selectedCustomerPayment &&
            renderCompanyData(selectedCustomerPayment)}
          {selectedCustomerPayment &&
            renderClientData({
              ...selectedCustomerPayment?.user,
              date_printed: selectedCustomerPayment?.date_printed
            })}
        </div>
        {selectedCustomerPayment && renderModels(selectedCustomerPayment)}
      </div>
    );
  };

  // const renderModalBody = () => {
  //   return (
  //     <div
  //       className="report-modal-content"
  //       ref={a4Ref}
  //       style={{ padding: '24px', minHeight: '400px' }}
  //     >
  //       <Descriptions bordered column={1}>
  //         {selectedCustomerPayment?.user?.name && (
  //           <Descriptions.Item
  //             label="إســـم العميل"
  //             style={{ height: '50px', fontWeight: '500', fontSize: '18px' }}
  //           >
  //             {selectedCustomerPayment?.user?.name}
  //           </Descriptions.Item>
  //         )}
  //         {selectedCustomerPayment?.user?.phone && (
  //           <Descriptions.Item
  //             label={t("ClientPayments.PhoneNumber")}
  //             style={{ height: '50px', fontWeight: '500', fontSize: '18px' }}
  //           >
  //             {selectedCustomerPayment?.user?.phone}
  //           </Descriptions.Item>
  //         )}
  //         {selectedCustomerPayment?.user?.email && (
  //           <Descriptions.Item
  //             label="البريد الإلكتروني"
  //             style={{ height: '50px', fontWeight: '500', fontSize: '18px' }}
  //           >
  //             {selectedCustomerPayment?.user?.email}
  //           </Descriptions.Item>
  //         )}
  //         {selectedCustomerPayment?.amount && (
  //           <Descriptions.Item
  //             label="المبلغ"
  //             style={{ height: '50px', fontWeight: '500', fontSize: '18px' }}
  //           >
  //             {selectedCustomerPayment?.amount}
  //           </Descriptions.Item>
  //         )}
  //         {selectedCustomerPayment?.user?.balance && (
  //           <Descriptions.Item
  //             label="الرصيد"
  //             style={{ height: '50px', fontWeight: '500', fontSize: '18px' }}
  //           >
  //             {selectedCustomerPayment?.user?.balance}
  //           </Descriptions.Item>
  //         )}
  //         {selectedCustomerPayment?.user?.paid && (
  //           <Descriptions.Item
  //             label="المدفوع"
  //             style={{ height: '50px', fontWeight: '500', fontSize: '18px' }}
  //           >
  //             {selectedCustomerPayment?.user?.paid}
  //           </Descriptions.Item>
  //         )}
  //         {selectedCustomerPayment?.user?.unpaid && (
  //           <Descriptions.Item
  //             label="المستحق من العميل"
  //             style={{ height: '50px', fontWeight: '500', fontSize: '18px' }}
  //           >
  //             {selectedCustomerPayment?.user?.unpaid}
  //           </Descriptions.Item>
  //         )}
  //         {selectedCustomerPayment?.notes && (
  //           <Descriptions.Item
  //             label="الملاحظات"
  //             style={{ height: '50px', fontWeight: '500', fontSize: '18px' }}
  //           >
  //             {selectedCustomerPayment?.notes}
  //           </Descriptions.Item>
  //         )}
  //       </Descriptions>
  //     </div>
  //   );
  // };

  return (
    <Modal
      className="shared-custom-modal selected-inovice-modal"
      width="90%"
      style={{ maxWidth: '942px' }}
      title={
        <>
          <div className="title-wrapper">
            {renderInvoiceHeading()}
            <div className="title-wrapper-icons">
              <Tooltip title="Print A4">
                <Button
                  onClick={handlePrintA4}
                  className="circled-btn"
                  type="dashed"
                  shape="circle"
                  icon={<PrinterOutlined />}
                />
              </Tooltip>
            </div>
          </div>
        </>
      }
      visible={customerPaymentModalOpened}
      onOk={() => {
        setCustomerPaymentModalOpened(false);
        setSelectedCustomerPayment(null);
      }}
      onCancel={() => {
        setCustomerPaymentModalOpened(false);
        setSelectedCustomerPayment(null);
      }}
      footer={false}
    >
      {isSingleLoading ? (
        <div
          style={{
            height: '400px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Spin />
        </div>
      ) : (
        renderModalBody()
      )}
    </Modal>
  );
};

export default SelectedCustomerPayment;
