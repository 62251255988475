import React from 'react';
// import MainAppNotifications from './MainAppNotifications';
import MainAppProfileMenu from './MainAppProfileMenu';
import ShiftStopwatch from './ShiftStopwatch';
import './NotificationsProfile.scss';
import LanguageButton from '../../../../common/language-button/LanguageButton';

const NotificationsProfile = (props) => {
  // const time = new Date();
  // time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
  return (
    <div className="search-notifications-profile-wrap">
      <ShiftStopwatch />
      {/* <ShiftStopwatch expiryTimestamp={1000 * 10 * 60} /> */}
      {/* <MainAppNotifications /> */}
      <LanguageButton />

      <MainAppProfileMenu />
    </div>
  );
};

export default NotificationsProfile;
