/* eslint-disable eqeqeq */
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import CustomAntdDatePicker from '../../../common/antd-form-components/CustomAntdDatePicker';
import RadioButtonEmpty from '../../../common/icons/RadioButtonEmpty';
import RadioButtonFilled from '../../../common/icons/RadioButtonFilled';
import { calcProductTotalValue } from './receiptCalculations';

const RenderProductsFieldsProducts = ({
  allProducts,
  allWarehouses,
  productsFields,
  removeProductField,
  selectedReceipt,
  watch,
  setValue,
  control,
  errors,
  register,
  systemSettings,
  allProductsWithoutPage,
  removeValidityData,
  setFilterValues,
  setFetchCount
}) => {
  const { t } = useTranslation();

  const getTaxDefaultValue = (index) => {
    if (allProducts?.length > 0 && !selectedReceipt) {
      const found = allProducts?.find((p) => {
        return p.id == watch(`products.${index}.product_id`);
      });
      if (found?.tax && parseFloat(found.tax) > 0) {
        return found.tax;
      } else {
        return systemSettings?.tax;
      }
    }
  };

  let selectWarehouseFun = (data, index) => {
    return (
      <div className="select-label-wrap">
        <p className="label-p">{t('Quotations.ChooseRepository')}</p>
        <div className="custom-select-wrap without-icon">
          <AntdSelectOption
            name={`products.${index}.warehouse_id`}
            errorMsg={
              errors?.products &&
              errors.products[index]?.warehouse_id &&
              errors.products[index].warehouse_id.message
            }
            validateStatus={
              errors?.products &&
              errors.products[index]?.warehouse_id &&
              errors?.products[index]?.warehouse_id
                ? 'error'
                : ''
            }
            onChange={(e) => {
              setFilterValues({ stock_id: e });
              setFetchCount((prevState) => prevState + 1);
            }}
            control={control}
            setValue={setValue}
            placeholder={t('Quotations.ChooseRepository')}
            options={
              allWarehouses?.length > 0 &&
              allWarehouses.map((p) => ({
                title: p.name,
                value: String(p.id)
              }))
            }
            showSearch={true}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            formClassName="create-receipt-form"
          />
        </div>
      </div>
    );
  };

  return (
    productsFields?.length > 0 && (
      <ul className="fields-array-ul without-padding">
        {productsFields.map((item, index) => {
          return (
            <li className="bill-field-delete-li" key={item.id}>
              <div className="li-fields" style={{ paddingBottom: '20px' }}>
                <div
                  className={`product-id-quantity-tax-wrap ${
                    selectedReceipt ? 'isReturn' : ''
                  }`}
                >
                  {selectWarehouseFun(item, index)}

                  {watch(`products.${index}.warehouse_id`) && (
                    <>
                      <div className="select-label-wrap">
                        <p className="label-p">
                          {t('Quotations.SelectProduct')}
                        </p>{' '}
                        <div className="custom-select-wrap without-icon">
                          {(allProducts?.length >= 0 &&
                            allProducts.find(
                              (p) => p.id == item?.product_id
                            )) ||
                          !selectedReceipt ? (
                            <AntdSelectOption
                              disabled={selectedReceipt ? true : false}
                              name={`products.${index}.product_id`}
                              errorMsg={
                                errors?.products &&
                                errors.products[index]?.product_id &&
                                errors.products[index].product_id.message
                              }
                              validateStatus={
                                errors?.products &&
                                errors.products[index]?.product_id &&
                                errors?.products[index]?.product_id
                                  ? 'error'
                                  : ''
                              }
                              control={control}
                              setValue={setValue}
                              placeholder={t('Quotations.SelectProduct')}
                              options={
                                allProducts?.length > 0 &&
                                allProducts?.map((p) => {
                                  return {
                                    title: `${p?.name} ( ${t(
                                      'Quotations.Quantity'
                                    )} : ${p?.quantity} ) `,
                                    value: String(p.id)
                                  };
                                })
                              }
                              showSearch={true}
                              // onSearch={onSearch}
                              filterOption={(input, option) =>
                                option.children.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                              formClassName="create-receipt-form"
                            />
                          ) : (
                            <div
                              style={{
                                display: 'grid',
                                justifyItems: 'start',
                                alignItems: 'center',
                                paddingRight: 8
                              }}
                            >
                              <p>
                                {selectedReceipt?.details?.length > 0 &&
                                  selectedReceipt?.details[index]?.name}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Quotations.ProductQuantity')}
                        </p>{' '}
                        <div className="text-field-wrap">
                          <AntdTextField
                            // disabled={
                            //   selectedReceipt &&
                            //   !(
                            //     allProducts?.length > 0 &&
                            //     allProducts.find((p) => p.id == item?.product_id)
                            //   )
                            // }
                            className="form-text-field"
                            disabled={selectedReceipt}
                            name={`products.${index}.quantity`}
                            type="text"
                            placeholder={
                              t('Quotations.ProductQuantity') + '...'
                            }
                            errorMsg={
                              errors?.products &&
                              errors.products[index]?.quantity &&
                              errors.products[index].quantity.message
                            }
                            validateStatus={
                              errors?.products &&
                              errors.products[index]?.quantity &&
                              errors?.products[index]?.quantity
                                ? 'error'
                                : ''
                            }
                            control={control}
                            onChange={() => {
                              if (selectedReceipt) {
                                if (
                                  parseFloat(
                                    watch(`products.${index}.quantity`)
                                  ) <=
                                  parseFloat(
                                    selectedReceipt?.details[index]?.quantity
                                  )
                                ) {
                                  setValue(
                                    `products.${index}.quantity`,
                                    watch(`products.${index}.quantity`)
                                  );
                                } else {
                                  setValue(
                                    `products.${index}.quantity`,
                                    selectedReceipt?.details[index]?.quantity
                                  );
                                }
                              }
                            }}
                            // defaultValue={watch(`products.${index}.quantity`)} // make sure to set up defaultValue
                          />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="q-return-q-tax-wrapper">
                    {selectedReceipt && (
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Quotations.QuantityToeRetrieved')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            // disabled={
                            //   selectedReceipt &&
                            //   !(
                            //     allProducts?.length > 0 &&
                            //     allProducts.find((p) => p.id == item?.product_id)
                            //   )
                            // }
                            className="form-text-field"
                            name={`products.${index}.returnQuantity`}
                            type="text"
                            placeholder={
                              t('Quotations.QuantityToeRetrieved') + '...'
                            }
                            errorMsg={
                              errors?.products &&
                              errors.products[index]?.returnQuantity &&
                              errors.products[index].returnQuantity.message
                            }
                            validateStatus={
                              errors?.products &&
                              errors.products[index]?.returnQuantity &&
                              errors?.products[index]?.returnQuantity
                                ? 'error'
                                : ''
                            }
                            control={control}
                            onChange={() => {
                              if (selectedReceipt) {
                                const handleReturnQuantity = () => {
                                  return (
                                    parseFloat(
                                      selectedReceipt?.products[index]?.quantity
                                    ) -
                                    parseFloat(
                                      watch(`products.${index}.returnQuantity`)
                                    )
                                  );
                                };
                                if (selectedReceipt.products.length > 1) {
                                  if (
                                    parseFloat(
                                      watch(`products.${index}.returnQuantity`)
                                    ) >= 0 &&
                                    parseFloat(
                                      watch(`products.${index}.returnQuantity`)
                                    ) <=
                                      parseFloat(
                                        selectedReceipt?.products[index]
                                          ?.quantity
                                      )
                                  )
                                    setValue(
                                      `products.${index}.quantity`,
                                      handleReturnQuantity()
                                    );
                                  else if (
                                    parseFloat(
                                      watch(`products.${index}.returnQuantity`)
                                    ) >= 0 &&
                                    parseFloat(
                                      watch(`products.${index}.returnQuantity`)
                                    ) >
                                      parseFloat(
                                        selectedReceipt?.products[index]
                                          ?.quantity
                                      )
                                  ) {
                                    setValue(
                                      `products.${index}.returnQuantity`,
                                      selectedReceipt?.products[index]?.quantity
                                    );
                                    setValue(`products.${index}.quantity`, 0);
                                  } else {
                                    setValue(
                                      `products.${index}.returnQuantity`,
                                      ''
                                    );
                                    setValue(
                                      `products.${index}.quantity`,
                                      selectedReceipt?.products[index]?.quantity
                                    );
                                  }
                                } else {
                                  if (
                                    parseFloat(
                                      watch(`products.${index}.returnQuantity`)
                                    ) >= 0 &&
                                    parseFloat(
                                      watch(`products.${index}.returnQuantity`)
                                    ) <=
                                      parseFloat(
                                        selectedReceipt?.products[index]
                                          ?.quantity
                                      )
                                  )
                                    setValue(
                                      `products.${index}.quantity`,
                                      handleReturnQuantity()
                                    );
                                  else if (
                                    parseFloat(
                                      watch(`products.${index}.returnQuantity`)
                                    ) > 0 &&
                                    parseFloat(
                                      watch(`products.${index}.returnQuantity`)
                                    ) >
                                      parseFloat(
                                        selectedReceipt?.products[index]
                                          ?.quantity
                                      )
                                  ) {
                                    setValue(
                                      `products.${index}.returnQuantity`,
                                      selectedReceipt?.products[index]?.quantity
                                    );
                                    setValue(`products.${index}.quantity`, 0);
                                  } else {
                                    setValue(
                                      `products.${index}.returnQuantity`,
                                      ''
                                    );
                                    setValue(
                                      `products.${index}.quantity`,
                                      selectedReceipt?.products[index]?.quantity
                                    );
                                  }
                                }
                              }
                            }}
                            // defaultValue={watch(`products.${index}.quantity`)} // make sure to set up defaultValue
                          />
                        </div>
                      </div>
                    )}

                    {watch(`products.${index}.product_id`) && (
                      <div className="text-field-label-wrap">
                        <p className="label-p">{t('Quotations.Tax')}</p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            disabled
                            setValue={setValue}
                            name={`products.${index}.tax`}
                            type="text"
                            placeholder={t('Quotations.Tax') + '...'}
                            errorMsg={
                              errors?.products &&
                              errors.products[index]?.tax &&
                              errors.products[index].tax.message
                            }
                            validateStatus={
                              errors?.products &&
                              errors.products[index]?.tax &&
                              errors?.products[index]?.tax
                                ? 'error'
                                : ''
                            }
                            control={control}
                            // defaultValue={systemSettings?.tax}
                            // defaultValue={watch(`products.${index}.tax`)} // make sure to set up defaultValue
                            defaultValue={
                              allProducts?.length > 0
                                ? allProducts.find(
                                    (p) =>
                                      p.id ==
                                      watch(`products.${index}.product_id`)
                                  )?.tax
                                : watch(`products.${index}.tax`)
                            }
                            // defaultValue={watch(`products.${index}.tax`)}
                          />
                        </div>
                      </div>
                    )}
                    {watch(`products.${index}.product_id`) && (
                      <div className="text-field-label-wrap">
                        <p className="label-p">{t('Quotations.description')}</p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            disabled={selectedReceipt ? true : false}
                            setValue={setValue}
                            className="form-text-field"
                            name={`products.${index}.desc`}
                            type="text"
                            placeholder={t('Quotations.description') + '...'}
                            errorMsg={
                              errors?.products &&
                              errors.products[index]?.desc &&
                              errors.products[index].desc.message
                            }
                            validateStatus={
                              errors?.products &&
                              errors.products[index]?.desc &&
                              errors?.products[index]?.desc
                                ? 'error'
                                : ''
                            }
                            control={control}
                            defaultValue={
                              item?.desc
                                ? item.desc
                                : allProducts?.length > 0
                                ? allProducts.find(
                                    (p) =>
                                      // eslint-disable-next-line eqeqeq
                                      p.id ==
                                      watch(`products.${index}.product_id`)
                                  )?.desc
                                : ''
                            }
                            // defaultValue={item?.desc ? item.desc : ''}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="warehoues-expiration-date-wrap">
                  {removeValidityData === true ? (
                    ''
                  ) : (
                    <label className="custom-date-input">
                      <span className="label-span">
                        {t('Quotations.ExpirationDate')}
                      </span>
                      <CustomAntdDatePicker
                        // disablePast
                        control={control}
                        name={`products.${index}.expirationDate`}
                        className="receipt-date-picker"
                        formClassName="create-receipt-form"
                        errorMsg={
                          errors?.products &&
                          errors.products[index]?.expirationDate &&
                          errors.products[index].expirationDate.message
                        }
                      />
                    </label>
                  )}
                </div>
                {watch(`products.${index}.product_id`) && (
                  <div className="prices-wrap">
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Quotations.NetProductPrice')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          disabled={selectedReceipt ? true : false}
                          setValue={setValue}
                          className="form-text-field"
                          name={`products.${index}.price`}
                          type="text"
                          placeholder={t('Quotations.NetProductPrice') + '...'}
                          errorMsg={
                            errors?.products &&
                            errors.products[index]?.price &&
                            errors.products[index].price.message
                          }
                          validateStatus={
                            errors?.products &&
                            errors.products[index]?.price &&
                            errors?.products[index]?.price
                              ? 'error'
                              : ''
                          }
                          control={control}
                          defaultValue={
                            // handleDefaultValue(item, index)
                            item?.price
                              ? item.price
                              : allProducts?.length > 0
                              ? allProducts.find(
                                  (p) =>
                                    p.id ==
                                    watch(`products.${index}.product_id`)
                                )?.salePrice
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

                {watch(`products.${index}.price`) && (
                  <div className="radios-wrap">
                    <p className="radios-group-title">
                      {t('Quotations.SelectDiscountType')}
                    </p>{' '}
                    <div className="labels-wrap">
                      {[
                        {
                          title: t('Quotations.FixedNumber'),
                          value: '1'
                        },
                        {
                          // title: 'نسبة',
                          title: t('Quotations.Percentage'),
                          value: '2'
                        },
                        {
                          // title: 'بدون خصم',
                          title: t('Quotations.NoDiscount'),
                          value: 'no_discount'
                        }
                      ].map((obj, i) => (
                        <label
                          key={i}
                          className={`radio-input-label ${
                            String(watch(`products.${index}.discount_type`)) ===
                            String(obj.value)
                              ? 'selected'
                              : ''
                          }`}
                        >
                          <input
                            type="radio"
                            value={obj.value}
                            {...register(`products.${index}.discount_type`)}
                            // onChange={() => {
                            //   if (
                            //     watch(`products.${index}.discount_type`) == '1'
                            //   )
                            //     // fixed discount price
                            //     setValue(
                            //       `products.${index}.discount_percentage`,
                            //       ''
                            //     );
                            //   else if (
                            //     // precentage discount
                            //     watch(`products.${index}.discount_type`) == '2'
                            //   ) {
                            //     setValue(
                            //       `products.${index}.discount_price`,
                            //       ''
                            //     );
                            //   } else {
                            //     // no discount
                            //     setValue(
                            //       `products.${index}.discount_percentage`,
                            //       ''
                            //     );
                            //     setValue(
                            //       `products.${index}.discount_price`,
                            //       ''
                            //     );
                            //   }
                            // }}
                          />
                          <span className="label-span">{obj.title}</span>
                          {watch(`products.${index}.discount_type`) ==
                          String(obj.value) ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonEmpty />
                          )}
                        </label>
                      ))}
                    </div>
                    {errors?.discount_type && (
                      <p className="error-p">{errors.discount_type.message}</p>
                    )}
                    {String(watch(`products.${index}.discount_type`)) ==
                      '1' && (
                      <div className="before-after-wrap">
                        <div className="text-field-label-wrap">
                          <p className="label-p">
                            {t('Quotations.DiscountPrice')}
                          </p>{' '}
                          <div className="text-field-wrap">
                            <AntdTextField
                              // disabled={selectedReceipt ? true : false}
                              // disabled={selectedReceipt}
                              className="form-text-field"
                              name={`products.${index}.discount_price`}
                              type="text"
                              placeholder={`${t(
                                'Quotations.DiscountPrice'
                              )}...`}
                              errorMsg={
                                errors?.products &&
                                errors.products[index]?.discount_price &&
                                errors.products[index].discount_price.message
                              }
                              validateStatus={
                                errors?.products &&
                                errors.products[index]?.discount_price
                                  ? 'error'
                                  : ''
                              }
                              control={control}
                            />
                          </div>
                        </div>
                        {!isNaN(parseInt(watch(`products.${index}.price`))) &&
                          !isNaN(
                            parseInt(watch(`products.${index}.discount_price`))
                          ) &&
                          (!selectedReceipt ? !isNaN(watch(`products.${index}.quantity`)) :
                                watch(`products.${index}.returnQuantity`) > 0)&& (
                            <div className="after-wrap">
                              <div className="after-title">
                                {t('Quotations.FinalPriceAfterDiscount')}
                              </div>{' '}
                              <span className="after-value">
                                {!isNaN(watch(`products.${index}.price`)) &&
                                !isNaN(watch(`products.${index}.quantity`))
                                  ? calcProductTotalValue(
                                      watch(`products.${index}.discount_type`),
                                      watch(`products.${index}.price`),
                                      watch(`products.${index}.discount_price`),
                                      watch(
                                        `products.${index}.discount_percentage`
                                      ),
                                      selectedReceipt
                                        ? watch(
                                            `products.${index}.returnQuantity`
                                          )
                                        : watch(`products.${index}.quantity`),
                                      watch(`products.${index}.tax`)
                                    )
                                  : null}
                              </span>
                            </div>
                          )}
                      </div>
                    )}
                    {String(watch(`products.${index}.discount_type`)) ==
                      '2' && (
                      <div className="before-after-wrap">
                        <div className="text-field-label-wrap">
                          <p className="label-p">
                            {t('Quotations.DiscountPercentage')} ( % )
                          </p>{' '}
                          <div className="text-field-wrap">
                            <AntdTextField
                              // disabled={selectedReceipt ? true : false}
                              // disabled={selectedReceipt}
                              className="form-text-field"
                              name={`products.${index}.discount_percentage`}
                              type="text"
                              placeholder={`${t(
                                'Quotations.DiscountPercentage'
                              )} ( % )`}
                              errorMsg={
                                errors?.products &&
                                errors.products[index]?.discount_percentage &&
                                errors.products[index].discount_percentage
                                  .message
                              }
                              validateStatus={
                                errors?.products &&
                                errors.products[index]?.discount_percentage
                                  ? 'error'
                                  : ''
                              }
                              control={control}
                            />
                          </div>
                        </div>
                        {!isNaN(parseInt(watch(`products.${index}.price`))) &&
                          !isNaN(
                            parseInt(
                              watch(`products.${index}.discount_percentage`)
                            )
                          ) &&
                          (!selectedReceipt ? !isNaN(watch(`products.${index}.quantity`)) :
                                watch(`products.${index}.returnQuantity`) > 0) && (
                            <div className="after-wrap">
                              <div className="after-title">
                                {t('Quotations.FinalPriceAfterDiscount')}
                              </div>{' '}
                              <span className="after-value">
                                {!isNaN(watch(`products.${index}.price`)) &&
                                !isNaN(watch(`products.${index}.quantity`))
                                  ? calcProductTotalValue(
                                      watch(`products.${index}.discount_type`),
                                      watch(`products.${index}.price`),
                                      watch(`products.${index}.discount_price`),
                                      watch(
                                        `products.${index}.discount_percentage`
                                      ),
                                      selectedReceipt
                                        ? watch(
                                            `products.${index}.returnQuantity`
                                          )
                                        : watch(`products.${index}.quantity`),
                                      watch(`products.${index}.tax`)
                                    )
                                  : null}
                              </span>
                            </div>
                          )}
                      </div>
                    )}
                    {String(watch(`products.${index}.discount_type`)) ==
                      'no_discount' && (
                      <div className="before-after-wrap-without-discount">
                        {!isNaN(parseInt(watch(`products.${index}.price`))) &&
                          !isNaN(
                            parseInt(watch(`products.${index}.quantity`))
                          ) && (
                            <div className="after-wrap">
                              <div className="after-title">
                                {' '}
                                {t('Quotations.Price')}
                              </div>{' '}
                              <span className="after-value">
                                {!isNaN(watch(`products.${index}.price`)) &&
                                !isNaN(watch(`products.${index}.quantity`))
                                  ? calcProductTotalValue(
                                      watch(`products.${index}.discount_type`),
                                      watch(`products.${index}.price`),
                                      watch(`products.${index}.discount_price`),
                                      watch(
                                        `products.${index}.discount_percentage`
                                      ),
                                      selectedReceipt
                                        ? watch(
                                            `products.${index}.returnQuantity`
                                          )
                                        : watch(`products.${index}.quantity`),
                                      watch(`products.${index}.tax`)
                                    )
                                  : null}
                              </span>
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                )}

                {watch(`products.${index}.product_id`) && (
                  <div
                    className="text-field-label-wrap"
                    style={{ display: 'none' }}
                  >
                    <p className="label-p">{t('Quotations.Tax')}</p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        className="form-text-field"
                        disabled
                        setValue={setValue}
                        name={`products.${index}.including_tax`}
                        type="text"
                        placeholder={t('Quotations.Tax') + '...'}
                        errorMsg={
                          errors?.products &&
                          errors.products[index]?.including_tax &&
                          errors.products[index].including_tax.message
                        }
                        validateStatus={
                          errors?.products &&
                          errors.products[index]?.including_tax &&
                          errors?.products[index]?.including_tax
                            ? 'error'
                            : ''
                        }
                        control={control}
                        // defaultValue={systemSettings?.tax}
                        // defaultValue={watch(`products.${index}.tax`)} // make sure to set up defaultValue
                        defaultValue={String(
                          allProducts.find(
                            (p) => p.id == watch(`products.${index}.product_id`)
                          )?.including_tax
                        )}
                        // defaultValue={watch(`products.${index}.tax`)}
                      />
                    </div>
                  </div>
                )}
              </div>
              {productsFields?.length >= 1 && !selectedReceipt && (
                <Tooltip title="حذف">
                  <Button
                    className="delete-field-btn"
                    size="large"
                    type="dashed"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => removeProductField(index)}
                  />
                </Tooltip>
              )}
            </li>
          );
        })}
      </ul>
    )
  );
};

export default RenderProductsFieldsProducts;
