import axios from 'axios';

const getAccountingDetails = async (token, id, typeOfAccount , email) => {
  let url;
  let testEmail = `ahmedamirr56@gmail.com`

  if (typeOfAccount === 1) {
    url = `/accounting/company/get_company_by_email?email=${email}`
  }
  else if (typeOfAccount === 2) {
    url = `/accounting/type/get_single_type/${id}`
  }

  else if (typeOfAccount === 3) {
    url = `/accounting/subtype/get_full_subtype_by_id/${id}`
  }

  else if (typeOfAccount === 4) {
    url = `/accounting/account/get_full_account_by_id/${id}`
  }




  try {
    const res = await axios({
      method: 'get',
      url: url,
      baseURL: process.env.REACT_APP_BASE_TEST_URL,
      // baseURL: "https://testclient-api.fatoorah.sa/apiAdmin",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAccountingDetails;
