/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../../common/antd-form-components/AntdTextField';
import successNotification from '../../../../utils/successNotification';
import errorNotification from '../../../../utils/errorNotification';
import UserContext from '../../../../contexts/user-context/UserProvider';
import SettingsPosContext from '../../../../contexts/settings-contexts/SettingsPosPage';
import AntdSelectOption from '../../../../common/antd-form-components/AntdSelectOption';
import useWarehouses from '../../../../custom-hooks/useWarehouses';
import useCustomApiRequest from '../../../../custom-hooks/useCustomApiRequest';
import updatePosApi from '../../../../apis/pos-api/pos-settings-apis/updatePosApi';

const schema = Yup.object().shape({
  name: Yup.string().required('ادخل اسم نقطة البيع'),
  stock_id: Yup.string().required('إختار المستودع')
});

const TableForm = () => {
  const { user } = useContext(UserContext);
  const {
    selectedPos,
    setIsLoading,
    setModalOpened,
    setFetchCount,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(SettingsPosContext);
  const allWarehouses = useWarehouses();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      stock_id: '',
      visa: '',
      cash: '',
      address: ''
    }
  });

  const customApiRequest = useCustomApiRequest();

  const onSubmit = async (data) => {
    if (selectedPos) {
      setIsLoading(true);
      setIsSubmittingForm(true);
      customApiRequest(
        updatePosApi(
          user?.token,
          {
            ...data,
            id: selectedPos.id
          },
          true
        ),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم تعديل نقطة البيع بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message
                ? res.data.message
                : 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    } else {
      setIsLoading(true);
      customApiRequest(
        updatePosApi(user?.token, data),
        (res) => {
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setIsSubmittingForm(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم إضافة نقطة البيع بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message
                ? res.data.message
                : 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedPos) {
      setValue('name', selectedPos?.name ? selectedPos.name : '');
      setValue('address', selectedPos?.address ? selectedPos.address : '');
      setValue('cash', selectedPos?.cash ? selectedPos.cash : '');
      setValue('visa', selectedPos?.visa ? selectedPos.visa : '');
      setValue(
        'stock_id',
        selectedPos.stock?.id ? String(selectedPos.stock.id) : ''
      );
    }
  }, [selectedPos, selectedPos?.id]);

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form settings-pos-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">اســم نقطة البيع</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="name"
              type="text"
              placeholder="اسـم نقطة البيع..."
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">عنوان نقطة البيع</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="address"
              type="text"
              placeholder="عنوان نقطة البيع..."
              errorMsg={errors?.address?.message}
              validateStatus={errors?.address ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">حساب الكاش</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="cash"
              type="text"
              placeholder="حساب الكاش..."
              errorMsg={errors?.cash?.message}
              validateStatus={errors?.cash ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">حساب الفيزا</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="visa"
              type="text"
              placeholder="حساب الفيزا..."
              // errorMsg={errors?.name?.message}
              // validateStatus={errors?.name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="select-label-wrap">
          <p className="label-p">اختار المستودع</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="stock_id"
              errorMsg={errors?.stock_id?.message}
              validateStatus={errors?.stock_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              options={
                allWarehouses?.length > 0
                  ? allWarehouses.map((p) => ({
                      title: p?.name,
                      value: p?.id
                    }))
                  : []
              }
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="settings-pos-form"
            />
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          تعديل
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
