/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import { useParams } from 'react-router-dom';
import SettingsSubCategoriesContext from '../../../contexts/settings-contexts/SettingsSubCategoriesProvider';
import useMainCategories from '../../../custom-hooks/useMainCategories';
import addNewCategoryApi from '../../../apis/settings-apis/categories-apis/addNewCategoryApi';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import { useTranslation } from 'react-i18next';

const SubCategoriesTableForm = ({ addModal }) => {
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    category_name: Yup.string()
      .required(t('Inventory.Categories.enterCategoryName'))
      .max('100', t('Inventory.Products.maxProductNameLength')),
    main_category_id: Yup.string().required(
      t('Inventory.Categories.chooseMainCategory')
    ),
    category_description: Yup.string().max(
      '250',
      t('Inventory.Products.maxProductNameLength')
    )
  });
  const {
    setIsLoading,
    selectedSubCategory,
    setFetchCount,
    setIsSubmittingForm,
    setModalOpened
  } = useContext(SettingsSubCategoriesContext);
  const params = useParams();
  const { user } = useContext(UserContext);
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      category_name: '',
      category_description: '',
      main_category_id: params?.catId ? params.catId : ''
    }
  });
  const allMainCategories = useMainCategories();

  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedSubCategory) {
      const fromData = new FormData();
      // const mappedData = { ...data };

      if (data.main_category_id)
        fromData.append('cat_id', data.main_category_id);
      if (data.category_description)
        fromData.append('description', data.category_description);
      if (data.category_name) fromData.append('name', data.category_name);
      if (selectedSubCategory.id) fromData.append('id', selectedSubCategory.id);
      fromData.append('level', 2);

      // mappedData.level = 2; // 2 is sub categroy type
      // mappedData.cat_id = data.main_category_id; // id of the main category
      // mappedData.id = selectedSubCategory.id;
      customApiRequest(
        addNewCategoryApi(user?.token, fromData, true),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          setModalOpened(false);
          if (checkRes(res) && res?.data?.data) {
            setFetchCount((prev) => prev + 1);
            successNotification({
              title: t('Inventory.Categories.addedSuccessfully'),
              message: t('Inventory.Categories.categoryModifiedSuccessfully')
            });
          } else {
            setModalOpened(false);
            errorNotification({
              title: t('Inventory.Categories.errorOccurred'),
              message: res?.data?.message || t('Inventory.Categories.tryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('Inventory.Categories.errorOccurred'),
            message:
              error?.response?.data?.message ||
              t('Inventory.Categories.tryLater')
          });
        }
      );
    } else {
      const fromData = new FormData();
      if (data.main_category_id)
        fromData.append('cat_id', data.main_category_id);
      if (data.category_description)
        fromData.append('description', data.category_description);
      if (data.category_name) fromData.append('name', data.category_name);

      fromData.append('level', 2);
      // const mappedData = { ...data };
      // mappedData.level = 2; // 2 is sub categroy type
      // mappedData.cat_id = data.main_category_id; // id of the main category

      customApiRequest(
        addNewCategoryApi(user?.token, fromData),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          setModalOpened(false);
          if (checkRes(res) && res?.data?.data) {
            setFetchCount((prev) => prev + 1);
            successNotification({
              title: t('Inventory.Categories.addedSuccessfully'),
              message: t('Inventory.Categories.categoryAddedSuccessfully')
            });
          } else {
            setModalOpened(false);
            errorNotification({
              title: t('Inventory.Categories.errorOccurred'),
              message: res?.data?.message || t('Inventory.Categories.tryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('Inventory.Categories.errorOccurred'),
            message:
              error?.response?.data?.message ||
              t('Inventory.Categories.tryLater')
          });
        }
      );
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedSubCategory) {
      setValue(
        'category_name',
        selectedSubCategory?.name ? selectedSubCategory.name : ''
      );
      setValue(
        'category_description',
        selectedSubCategory?.description ? selectedSubCategory.description : ''
      );
    }
  }, [selectedSubCategory, selectedSubCategory?.id]);

  const [form] = Form.useForm();

  return (
    <Form
      className="settings-warehouse-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p"> {t('Inventory.Categories.categoryName')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="category_name"
              type="text"
              placeholder={t('Inventory.Categories.categoryNamePlaceholder')}
              errorMsg={errors?.category_name?.message}
              validateStatus={errors?.category_name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.Categories.categoryDetails')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="category_description"
              rows={8}
              placeholder={t('Inventory.Categories.categoryNamePlaceholder')}
              errorMsg={errors?.category_description?.message}
              validateStatus={errors?.category_description ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        {addModal && (
          <div className="select-label-wrap">
            <p className="label-p">{t('Inventory.Categories.mainCategory')}</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name="main_category_id"
                errorMsg={errors?.main_category_id?.message}
                validateStatus={errors?.main_category_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder={t('Inventory.Categories.mainCategory')}
                options={
                  allMainCategories?.length > 0
                    ? allMainCategories.map((cat) => ({
                        title: cat?.name,
                        value: cat?.id
                      }))
                    : []
                }
                showSearch={true}
                // filterOption={(input, option) =>
                //   option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                //   0
                // }
                filterOption={(input, option) =>
                  option?.children?.props?.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                formClassName="settings-warehouse-form"
              />
            </div>
          </div>
        )}

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmitting}
        >
          {t('Inventory.Categories.add')}
        </Button>
      </div>
    </Form>
  );
};

export default SubCategoriesTableForm;
