import { EyeOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

const tableColumns = (
  setModalOpened,
  setSelectedShift,
  setSelectedShiftModalOpened,
  t
) => {
  return [
    {
      title: t('Shift.code'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('Shift.employeeName'),
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.employee?.name ? record.employee.name : '----'}
            </span>
          </div>
        );
      },
      sorter: (a, b) => {
        let nameA = a.employee.name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.employee.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }
    },
    {
      title:t('Shift.beginningOfShift'),
      dataIndex: 'start_shift',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.start_shift ? record.start_shift : '----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('Shift.endOfShift'),
      dataIndex: 'end_shift',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.end_shift ? record.end_shift :t('Shift.notFinished')}
            </span>
          </div>
        );
      }
    },
    {
      title: t('Shift.shiftCash'),
      dataIndex: 'shiftCash',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record.shiftCash ? record.shiftCash : '----'}
          </div>
        );
      }
    },
    {
      title: t('Shift.visaShift'),
      dataIndex: 'shiftVisa',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record.shiftVisa ? record.shiftVisa : '----'}
          </div>
        );
      }
    },
    {
      title: t('Shift.warehouse'),
      dataIndex: 'stock',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record.pos?.stock?.name ? record.pos.stock.name : '----'}
          </div>
        );
      }
    },
    {
      title: t('Shift.shiftDetails'),
      render: (_, record) => {
        return (
          <div
            className="row-cell row-circled-btn"
            onClick={() => {
              setSelectedShift(record);
              setSelectedShiftModalOpened(true);
            }}
          >
            <Tooltip title={t('Shift.shiftDetails')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
              />
            </Tooltip>
          </div>
        );
      }
    }
  ];
};

export default tableColumns;
