import axios from 'axios';

// level === 1 <= main category
// level === 2 <= sub category
// cat_id <= id of the main parent

const getAllCategoriesApi = async (token, level, cat_id) => {
  try {
    const res = await axios.get(
      `/Category/all?level=${level ? level : ''}&cat_id=${cat_id ? cat_id : ''}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token,
          lang: 'ar'
        }
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllCategoriesApi;
