/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, Modal } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import RadioButtonFilled from '../../common/icons/RadioButtonFilled';
import RadioButtonEmpty from '../../common/icons/RadioButtonEmpty';
import { getWeightTypes, weightTypes } from '../../weightTypes';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/user-context/UserProvider';
import getAllProducts from '../../apis/pos-api/pos-products/getAllProducts copy';
import useScanDetection from 'use-scan-detection';

const BarcodeModal = ({ invoiceId }) => {
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    barcode: Yup.string().required(t('Pos.Main.enterProductBarcode')),
    weightType: Yup.string().required(t('Pos.Main.chooseProductType'))
  });
  const { user } = useContext(UserContext);
  const {
    setProductBarCode,
    productBarCode,
    setItemCode,
    setBarCodeQuantity,
    loadingProducts,
    barcodeModalOpened,
    setBarcodeModalOpened,
    setSelectedProducts,
    setAllProducts
  } = useContext(PosInvoiceContext);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      weightType: weightTypes?.byQuantity,
      barcode: ''
    }
  });

  useEffect(() => {
    setProductBarCode(watch('barcode'));
  }, [watch('barcode')]);

  const [barCodeScan, setBarCodeScan] = useState();
  useScanDetection({
    onComplete: setBarCodeScan,
    stopPropagation: true,
    preventDefault: true,
    minLength: 1
  });

  const onSubmit = async (data) => {
    setProductBarCode('');
    setItemCode('');
    setBarCodeQuantity('');
    const scannedBarCode = productBarCode; // '2000001001550'
    let code = '';
    let weight = '';

    try {
      if (
        scannedBarCode &&
        scannedBarCode?.length == 13 &&
        watch('weightType') == weightTypes?.byWeight
      ) {
        code = scannedBarCode?.substr(2, 5); // '00001'
        weight = scannedBarCode?.substr(7, 5); // '00155'
        setItemCode(parseInt(code));
        setBarCodeQuantity(parseInt(weight) / 1000);
        const res = await getAllProducts(user?.token || user?.employee?.token, {
          parCode: parseInt(code)
        });
        if (res?.status === 200) {
          setSelectedProducts(res?.data?.data);
          setBarcodeModalOpened(false);
        }
      } else {
        const res = await getAllProducts(user?.token || user?.employee?.token, {
          parCode: productBarCode
        });
        if (res?.status === 200) {
          setSelectedProducts(res?.data?.data);
          setAllProducts(res?.data?.data);
          setBarcodeModalOpened(false);
        }
      }
    } catch (error) {}
  };

  // const onSubmit = (data) => {
  //   setProductBarCode('');
  //   setItemCode('');
  //   setBarCodeQuantity('');
  //   const scannedBarCode = 2000001001550; //'2000001001550'
  //   let code = '';
  //   let weight = '';
  //   if (
  //     scannedBarCode &&
  //     scannedBarCode?.length == 13 &&
  //     watch('weightType') == weightTypes?.byWeight
  //   ) {
  //     code = scannedBarCode?.substr(2, 5); // '00001'
  //     weight = scannedBarCode?.substr(7, 5); // '00155'
  //     setItemCode(parseInt(code));
  //     setBarCodeQuantity(parseInt(weight) / 1000);
  //     history.push(`${routerLinks?.homePage}?page=1`);
  //     setProductsFilter((prev) => ({
  //       ...prev,
  //       subCatId: '',
  //       searchText: '',
  //       parCode: parseInt(code)
  //     }));
  //   } else {
  //     history.push(`${routerLinks?.homePage}?page=1`);
  //     setProductsFilter((prev) => ({
  //       ...prev,
  //       subCatId: '',
  //       searchText: '',
  //       parCode: productBarCode
  //     }));
  //   }
  // };

  const [form] = Form.useForm();

  return (
    <Modal
      transitionName=""
      className="return-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '642px' }}
      title={t('Pos.Main.enterProductBarcode')}
      visible={barcodeModalOpened}
      invoiceId={invoiceId}
      onOk={() => {
        setBarcodeModalOpened(false);
        setProductBarCode('');
      }}
      onCancel={() => {
        setBarcodeModalOpened(false);
        setProductBarCode('');
      }}
      footer={false}
    >
      <Form
        className="system-users-form custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className="radios-wrap">
            <p className="radios-group-title">
              {t('Pos.Main.chooseProductType')}
            </p>
            <div className="labels-wrap">
              {getWeightTypes(t)?.length > 0 &&
                getWeightTypes(t).map((obj, i) => (
                  <label
                    onClick={() => {
                      // if (watch('weightType') == obj?.value) {
                      //   setValue('weightType', '');
                      // }
                    }}
                    key={i}
                    className={`radio-input-label ${
                      String(watch(`weightType`)) == String(obj.value)
                        ? 'selected'
                        : ''
                    }`}
                  >
                    <input
                      type="radio"
                      value={obj.value}
                      {...register('weightType')}
                    />
                    {watch(`weightType`) == String(obj.value) ? (
                      <RadioButtonFilled color="#2e4652" />
                    ) : (
                      <RadioButtonEmpty color="#2e4652" />
                    )}
                    <span className="label-span">{obj.name}</span>
                  </label>
                ))}
            </div>

            {errors?.weightType && (
              <p className="error-p">{errors.weightType.message}</p>
            )}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            <h3>{t('Pos.Main.barcodeScanningOutput')} </h3>
            <h2>{barCodeScan}</h2>
          </div>
          <div
            className="text-field-label-wrap"
            style={{
              marginTop: 22
            }}
          >
            <p className="label-p">{t('Pos.Main.productBarcode')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                setValue={setValue}
                className="form-text-fieldcl"
                name="barcode"
                type="text"
                placeholder={t('Pos.Main.productBarcode')}
                errorMsg={errors?.barcode?.message}
                validateStatus={errors?.barcode ? 'error' : ''}
                control={control}
                autoFocus
                defaultValue={barCodeScan && barCodeScan}
              />
            </div>
          </div>

          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={loadingProducts}
          >
            {t('Pos.Main.search')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default BarcodeModal;
