import React, { useContext } from 'react';
import { Select } from 'antd';
import SelectDropDown from '../../../common/icons/SelectDropDown';
import UserContext from '../../../contexts/user-context/UserProvider';
import { useParams } from 'react-router-dom';
import getAllInstallmentsApi from '../../../apis/store-apis/installments-api/getAllInstallmentsApi';
import InstallmentsPageContext from '../../../contexts/installments-context/InstallmentsContext';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';

const InstallmentsTableFilter = () => {
  const { user } = useContext(UserContext);
  const { Option } = Select;
  const params = useParams();
  const [filterValues, setFilterValues] = React.useState({
    invoice_id: params.id,
    status: null
  });
  const { setAllFetchedInstallments, setIsLoading } = useContext(
    InstallmentsPageContext
  );

  async function handleChange(filterType, value) {
    
    setFilterValues((prevState) => {
      if (filterType === 'status') {
        return {
          ...prevState,
          status: value
        };
      }
    });
  }

  const customApiRequest = useCustomApiRequest();
  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    customApiRequest(
      getAllInstallmentsApi(user?.token, filterValues),
      (res) => {
        setIsLoading(false);
        if (res?.status === 200 && res?.data?.data) {
          if (res.data.data.length >= 0) {
            const data = res.data.data;
            setAllFetchedInstallments(data);
            setIsLoading(false);
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }

  return (
    <div className="custom-table-fitler warehouse-table-filter">
      <div className="form-excel-export-wrap">
        <form
          className="filter-form receipt-filter-form"
          onSubmit={handleSubmit}
        >
          <label className="receipt-filter-option-wrap">
            <span className="select-title">حالة القسط</span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('status', v)}
              suffixIcon={<SelectDropDown />}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>جميع الاقســاط</Option>
              <Option value="1">تم دفع القسط</Option>
              <Option value="2">لم يتم الدفع</Option>
            </Select>
          </label>

          <button className="filter-submit-btn" type="submit">
            بحث
          </button>
        </form>
      </div>
    </div>
  );
};

export default InstallmentsTableFilter;
