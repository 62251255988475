/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Form, Button, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdRadioGroup from '../../../common/antd-form-components/AntdRadioGroup';
import StorePurchaseOrderContext from '../../../contexts/store-purchase-order-context/StorePurchaseOrderProvider';
import moment from 'moment';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import useSuppliers from '../../../custom-hooks/useSuppliers';
import CustomAntdDatePicker from '../../../common/antd-form-components/CustomAntdDatePicker';
import addNewPurchaseOrderApi from '../../../apis/store-apis/purchase-order-apis/addNewPurchaseOrderApi';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import useProducts from '../../../custom-hooks/useProducts';
import useIngredients from '../../../custom-hooks/useIngredients';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import useProductsWithSelect from '../../../custom-hooks/useProductsWithSelect';
import { useTranslation } from 'react-i18next';

const TableForm = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    supplier_id: Yup.string().required(t('PurchaseOrder.supplierValidation')),
    purchase_order_type: Yup.string().required(
      t('PurchaseOrder.purchaseTypeValidation')
    ),
    products: Yup.array().of(
      Yup.object().shape({
        product_id: Yup.string().test(
          'products.index.product_id',
          t('PurchaseOrder.productValidation'),
          (v, context) => {
            let result = true;
            if (
              !v &&
              String(context.from[1].value.purchase_order_type) === '1'
            ) {
              result = false;
            }
            return result;
          }
        ),
        product_quantity: Yup.string()
          .test(
            'products.index.product_quantity',
            t('PurchaseOrder.quantityValidation'),
            (v, context) => {
              let result = true;
              if (
                !v &&
                String(context.from[1].value.purchase_order_type) === '1'
              ) {
                result = false;
              }
              return result;
            }
          )
          .test(
            'products.index.product_quantity',
            t('PurchaseOrder.onlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (
                v &&
                !v.match(/^[0-9]+$/) &&
                String(context.from[1].value.purchase_order_type) === '1'
              ) {
                result = false;
              }
              return result;
            }
          )
      })
    ),
    ingredients: Yup.array().of(
      Yup.object().shape({
        ingredient_id: Yup.string().test(
          'ingredients.index.ingredient_id',
          t('PurchaseOrder.ingredientValidation'),
          (v, context) => {
            let result = true;
            if (
              !v &&
              String(context.from[1].value.purchase_order_type) === '2'
            ) {
              result = false;
            }
            return result;
          }
        ),
        ingredient_quantity: Yup.string()
          .test(
            'ingredients.index.ingredient_quantity',
            t('PurchaseOrder.quantityValidation'),
            (v, context) => {
              let result = true;
              if (
                !v &&
                String(context.from[1].value.purchase_order_type) === '2'
              ) {
                result = false;
              }
              return result;
            }
          )
          .test(
            'ingredients.index.ingredient_quantity',
            t('PurchaseOrder.onlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (
                v &&
                !v.match(/^[0-9]+$/) &&
                String(context.from[1].value.purchase_order_type) === '2'
              ) {
                result = false;
              }
              return result;
            }
          )
      })
    ),
    due_date: Yup.mixed().required(t('PurchaseOrder.dueDateValidation'))
  });
  const {
    setFetchCount,
    setModalOpened,
    setIsLoading,
    selectedOrder,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(StorePurchaseOrderContext);
  const { allProducts } = useProductsWithSelect();
  const allIngredients = useIngredients();
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      supplier_id: '',
      purchase_order_type: '1', // <= 1 is product type , 2 is ingredient type
      products: [
        {
          product_id: '',
          product_quantity: ''
        }
      ],
      ingredients: [
        {
          ingredient_id: '',
          ingredient_quantity: ''
        }
      ],
      due_date: null,
      note: ''
    }
  });
  const { allSuppliers } = useSuppliers();
  const {
    fields: productsFields,
    append: appendProductField,
    remove: removeProductField
  } = useFieldArray({
    control,
    name: 'products'
  });
  const {
    fields: ingredientsFields,
    append: appendIngredientField,
    remove: removeIngredientField
  } = useFieldArray({
    control,
    name: 'ingredients'
  });

  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    const mappedData = {};
    if (data.supplier_id) mappedData.supplier_id = data.supplier_id;
    if (data.note) mappedData.note = data.note;
    if (data.due_date)
      mappedData.dueDate = moment(data.due_date).format('YYYY-MM-DD');

    if (data.purchase_order_type) mappedData.type = data.purchase_order_type;

    if (data.products?.length > 0 && data.purchase_order_type === '1') {
      // products
      const mappedProducts = data.products.map((pro) => {
        if (pro.product_id && pro.product_quantity)
          return { id: pro.product_id, quantity: pro.product_quantity };
        return null;
      });
      if (mappedProducts?.length > 0 && mappedProducts[0] !== null) {
        mappedData.materials = JSON.stringify(mappedProducts);
      }
    }

    if (data.ingredients?.length > 0 && data.purchase_order_type === '2') {
      // ingredients
      const mappedIngredients = data.ingredients.map((ing) => {
        if (ing.ingredient_id && ing.ingredient_quantity)
          return { id: ing.ingredient_id, quantity: ing.ingredient_quantity };
        return null;
      });
      if (mappedIngredients?.length > 0 && mappedIngredients[0] !== null) {
        mappedData.materials = JSON.stringify(mappedIngredients);
      }
    }
    const formData = new FormData();
    for (let key in mappedData) {
      formData.append(key, mappedData[key]);
    }

    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedOrder) {
      formData.append('id', selectedOrder.id);
      customApiRequest(
        addNewPurchaseOrderApi(user?.token, formData, true),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('PurchaseOrder.operationCompletedSuccessfully'),
              message: t('PurchaseOrder.orderUpdatedSuccessfully')
            });
          } else {
            errorNotification({
              title: t('PurchaseOrder.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('PurchaseOrder.tryLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('PurchaseOrder.errorOccurred'),
            message:
              error?.response?.data?.message || t('PurchaseOrder.tryLater')
          });
        }
      );
    } else {
      customApiRequest(
        addNewPurchaseOrderApi(user?.token, formData),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('PurchaseOrder.operationCompletedSuccessfully'),
              message: t('PurchaseOrder.orderAddedSuccessfully')
            });
          } else {
            errorNotification({
              title: t('PurchaseOrder.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('PurchaseOrder.tryLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('PurchaseOrder.errorOccurred'),
            message:
              error?.response?.data?.message || t('PurchaseOrder.tryLater')
          });
        }
      );
    }
  };

  const renderProductsFields = () => {
    return (
      productsFields?.length > 0 && (
        <ul className="fields-array-ul without-padding">
          {productsFields.map((item, index) => {
            return (
              <li className="field-delete-li" key={item.id}>
                <div className="select-label-wrap">
                  <p className="label-p">{t('PurchaseOrder.chooseProduct')}</p>
                  <div className="custom-select-wrap without-icon">
                    <AntdSelectOption
                      name={`products.${index}.product_id`}
                      errorMsg={
                        errors?.products &&
                        errors.products[index]?.product_id &&
                        errors.products[index].product_id.message
                      }
                      validateStatus={
                        errors?.products &&
                        errors.products[index]?.product_id &&
                        errors?.products[index]?.product_id
                          ? 'error'
                          : ''
                      }
                      control={control}
                      setValue={setValue}
                      placeholder={t('PurchaseOrder.chooseProduct')}
                      options={
                        allProducts?.length > 0 &&
                        allProducts.map((p) => ({
                          title: p.name,
                          value: String(p.id)
                        }))
                      }
                      showSearch={true}
                      // onSearch={onSearch}
                      // filterOption={(input, option) =>
                      //   option.children
                      //     .toLowerCase()
                      //     .indexOf(input.toLowerCase()) >= 0
                      // }
                                          filterOption={(input, option) =>
                option?.children?.props?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
               }
                      formClassName="purchases-order-form"
                    />
                  </div>
                </div>

                <div className="text-field-label-wrap">
                  <p className="label-p">
                    {t('PurchaseOrder.productQuantity')}
                  </p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      className="form-text-field"
                      name={`products.${index}.product_quantity`}
                      type="text"
                      placeholder={t(
                        'PurchaseOrder.productQuantityPlaceholder'
                      )}
                      errorMsg={
                        errors?.products &&
                        errors.products[index]?.product_quantity &&
                        errors.products[index].product_quantity.message
                      }
                      validateStatus={
                        errors?.products &&
                        errors.products[index]?.product_quantity &&
                        errors?.products[index]?.product_quantity
                          ? 'error'
                          : ''
                      }
                      control={control}
                      // defaultValue={watch(`products.${index}.product_quantity`)} // make sure to set up defaultValue
                    />
                  </div>
                </div>

                {productsFields?.length > 1 && (
                  <Tooltip title={t('PurchaseOrder.delete')}>
                    <Button
                      className="delete-field-btn"
                      size="large"
                      type="dashed"
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={() => removeProductField(index)}
                    />
                  </Tooltip>
                )}
              </li>
            );
          })}
        </ul>
      )
    );
  };

  const renderIngredientsFields = () => {
    return (
      ingredientsFields?.length > 0 && (
        <ul className="fields-array-ul without-padding">
          {ingredientsFields.map((item, index) => {
            return (
              <li className="field-delete-li" key={item.id}>
                <div className="select-label-wrap">
                  <p className="label-p">
                    {t('PurchaseOrder.chooseIngredient')}
                  </p>
                  <div className="custom-select-wrap without-icon">
                    <AntdSelectOption
                      name={`ingredients.${index}.ingredient_id`}
                      errorMsg={
                        errors?.ingredients &&
                        errors.ingredients[index]?.ingredient_id &&
                        errors.ingredients[index].ingredient_id.message
                      }
                      validateStatus={
                        errors?.ingredients &&
                        errors.ingredients[index]?.ingredient_id &&
                        errors?.ingredients[index]?.ingredient_id
                          ? 'error'
                          : ''
                      }
                      control={control}
                      setValue={setValue}
                      placeholder={t('PurchaseOrder.chooseIngredient')}
                      options={
                        allIngredients?.length > 0 &&
                        allIngredients.map((ing) => ({
                          title: ing.name,
                          value: String(ing.id)
                        }))
                      }
                      showSearch={true}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      formClassName="purchases-order-form"
                    />
                  </div>
                </div>

                <div className="text-field-label-wrap">
                  <p className="label-p">
                    {t('PurchaseOrder.productQuantity')}
                  </p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      className="form-text-field"
                      name={`ingredients.${index}.ingredient_quantity`}
                      type="text"
                      placeholder={t(
                        'PurchaseOrder.productQuantityPlaceholder'
                      )}
                      errorMsg={
                        errors?.ingredients &&
                        errors.ingredients[index]?.ingredient_quantity &&
                        errors.ingredients[index].ingredient_quantity.message
                      }
                      validateStatus={
                        errors?.ingredients &&
                        errors.ingredients[index]?.ingredient_quantity &&
                        errors?.ingredients[index]?.ingredient_quantity
                          ? 'error'
                          : ''
                      }
                      control={control}
                    />
                  </div>
                </div>

                {ingredientsFields?.length > 1 && (
                  <Tooltip title={t('PurchaseOrder.delete')}>
                    <Button
                      className="delete-field-btn"
                      size="large"
                      type="dashed"
                      shape="circle"
                      icon={<DeleteOutlined />}
                      onClick={() => removeIngredientField(index)}
                    />
                  </Tooltip>
                )}
              </li>
            );
          })}
        </ul>
      )
    );
  };

  // handle initial values
  useEffect(() => {
    if (selectedOrder) {
      if (selectedOrder?.supplier?.id) {
        setValue('supplier_id', selectedOrder.supplier.id.toString());
        // setValue('supplier_id', String(selectedOrder.supplier.id));
      }
      if (selectedOrder?.note) {
        setValue('note', selectedOrder.note);
      }
      if (selectedOrder?.dueDate)
        // setValue('due_date', moment(selectedOrder.dueDate, 'YYYY-MM-DD'));
        setValue('due_date', moment(selectedOrder.dueDate, 'YYYY-MM-DD'));

      if (String(selectedOrder?.type))
        setValue('purchase_order_type', String(selectedOrder.type));
      ///////////////

      if (
        selectedOrder?.buyOrderMaterial?.length > 0 &&
        selectedOrder.type === 1
      ) {
        const mappedProducts = selectedOrder.buyOrderMaterial.map(
          (product) => ({
            product_id: String(product.model_id),
            product_quantity: product.quantity
          })
        );
        reset({
          supplier_id: selectedOrder.supplier.id.toString(),
          note: selectedOrder.note,
          due_date: selectedOrder.dueDate
            ? moment(selectedOrder.dueDate, 'YYYY-MM-DD')
            : null,
          purchase_order_type: String(selectedOrder.type),
          products: [...mappedProducts]
        });
      }

      if (
        selectedOrder?.buyOrderMaterial?.length > 0 &&
        selectedOrder.type === 2
      ) {
        const mappedIngredients = selectedOrder.buyOrderMaterial.map(
          (ingredient) => ({
            ingredient_id: String(ingredient.model_id),
            ingredient_quantity: ingredient.quantity
          })
        );
        reset({
          supplier_id: selectedOrder.supplier.id.toString(),
          note: selectedOrder.note,
          due_date: selectedOrder.dueDate
            ? moment(selectedOrder.dueDate, 'YYYY-MM-DD')
            : null,
          purchase_order_type: String(selectedOrder.type),
          ingredients: [...mappedIngredients]
        });
      }
    }
  }, [selectedOrder, selectedOrder?.id, reset]);

  //

  const [form] = Form.useForm();
  return (
    <Form
      className="purchases-order-form custom-shared-form"
      form={form}
      layout="vertical"
      // initialValues={{
      //   purchase_order_type: selectedOrder?.orderType
      //     ? String(selectedOrder.orderType)
      //     : ''
      // }}
      fields={[
        {
          name: 'purchase_order_type',
          value: watch('purchase_order_type')
        },
        {
          name: 'products',
          value: watch('products')
        }
      ]}
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="select-label-wrap">
          <p className="label-p">{t('PurchaseOrder.chooseMerchant')}</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="supplier_id"
              errorMsg={errors?.supplier_id?.message}
              validateStatus={errors?.supplier_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder={t('PurchaseOrder.chooseMerchant')}
              options={
                allSuppliers?.length > 0 &&
                allSuppliers.map((sup) => ({
                  title: sup.name,
                  value: String(sup.id)
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              // filterOption={(input, option) =>
              //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              // }
                                filterOption={(input, option) =>
                option?.children?.props?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
              formClassName="purchases-order-form"
            />
          </div>
        </div>

        <div className="date-picker-wrap">
          <p className="label-p">{t('PurchaseOrder.dueDate')}</p>
          {/* <DatePicker
            onChange={onDateChange}
            allowClear={false}
            format="YYYY-MM-DD"
            value={handleDateValue()}
            getPopupContainer={() =>
              document.querySelector('.purchases-order-form')
            }
          /> */}
          <CustomAntdDatePicker
            // disablePast
            control={control}
            name="due_date"
            className="receipt-date-picker"
            formClassName="purchases-order-form"
          />
          {errors?.due_date && (
            <p className="error-p">{errors.due_date.message}</p>
          )}
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('PurchaseOrder.notes')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="note"
              rows={8}
              placeholder={t('PurchaseOrder.notesPlaceholder')}
              errorMsg={errors?.note?.message}
              validateStatus={errors?.note ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        {!selectedOrder && (
          <AntdRadioGroup
            name="purchase_order_type"
            className="purchase-order-radios"
            control={control}
            label={t('PurchaseOrder.purchaseTypeValidation')}
            validateStatus={errors?.purchase_order_type ? 'error' : ''}
            errorMsg={errors?.purchase_order_type?.message}
            radios={[
              {
                title: t('PurchaseOrder.products'),
                value: '1'
              }
              // {
              //   title: 'مكون منتج',
              //   value: '2'
              // }
            ]}
            // isRadioButton
          />
        )}

        {String(watch('purchase_order_type')) === '1' && (
          <div className="products-add-btn-wrap">
            <div
              className="add-new-field-btn"
              onClick={() =>
                appendProductField({
                  product_id: '',
                  product_quantity: ''
                })
              }
            >
              <span>{t('PurchaseOrder.newProduct')}</span>
            </div>
            {renderProductsFields()}
          </div>
        )}

        {String(watch('purchase_order_type')) === '2' && (
          <div className="ingredients-add-btn-wrap">
            <div
              className="add-new-field-btn"
              onClick={() =>
                appendIngredientField({
                  ingredient_id: '',
                  ingredient_quantity: ''
                })
              }
            >
              <span>{t('PurchaseOrder.newIngredient')}</span>
            </div>
            {renderIngredientsFields()}
          </div>
        )}

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          { selectedOrder? t('PurchaseOrder.update'): t('PurchaseOrder.add')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
