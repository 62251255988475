import axios from 'axios';

const deleteTag = async (token, id) => {
  const url = 'NfcTags/delete';
  try {
    const res = await axios.post(
      url,
      { id },
      {
        headers: {
          baseUrl: process.env.REACT_APP_BASE_URL,
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token
        }
      }
    );
    return res;
  } catch (error) {}
};

export default deleteTag;
