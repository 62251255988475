/* eslint-disable eqeqeq */
import React from 'react';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import * as Yup from 'yup';

const sharedProductsSchemaObj = (t) => {
  return {
    product_id: Yup.string()
      .test(
        'products.index.product_id',
        t(t('schema.ChooseProduct')),
        (v, context) => {
          let result = true;
          if (!v && context.from[1].value.details_type == '1') {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.product_id',
        t(t('schema.ChooseAnotherProduct')),
        (v, context) => {
          let result = true;
          if (
            v &&
            context.from[1]?.value?.products?.length > 0 &&
            context.from[1]?.value?.products.filter((p) => p.product_id == v)
              ?.length > 1
          ) {
            result = false;
          }
          return result;
        }
      ),
    quantity: Yup.string()
      .test(
        'products.index.quantity',
        t('schema.EnterQuantity'),
        (v, context) => {
          let result = true;
          if (!v && context.from[1].value.details_type == '1') {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.quantity',
        t('schema.OnlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            // !v.match(/^[0-9]+$/) &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[1].value.details_type == '1'
          ) {
            result = false;
          }
          return result;
        }
      ),

    tax: Yup.string().test(
      'products.index.tax',
      t('schema.OnlyNumbersAllowedArabic'),
      (v, context) => {
        let result = true;
        if (v && !v.match(/^(\d+)?(\.\d+)?$/)) {
          result = false;
        }
        return result;
      }
    ),
    warehouse_id: Yup.string().test(
      'products.index.warehouse_id',
      t('schema.ChooseWarehouse'),
      (v, context) => {
        let result = true;
        if (!v && context.from[1].value.details_type == '1') {
          result = false;
        }
        return result;
      }
    ),
    // expirationDate: Yup.mixed().test(
    //   'products.index.expirationDate',
    //   'اختار تاريخ انتهاء الصلاحية',
    //   (v, context) => {
    //     let result = true;
    //     if (!v && context.from[1].value.details_type == '1') {
    //       result = false;
    //     }
    //     return result;
    //   }
    // ),
    price: Yup.string()
      .test(
        'products.index.price',
        t('schema.EnterProductPrice'),
        (v, context) => {
          let result = true;
          // if the bill of type products === 1 and no value of price
          if ((!v || v == 0) && context.from[1].value.details_type == '1')
            result = false;
          return result;
        }
      )
      .test(
        'products.index.price',
        t('schema.OnlyNumbersAllowedPrice'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[1].value.details_type == '1'
          )
            result = false;
          return result;
        }
      ),
    discount_type: Yup.string().test(
      'products.index.discount_type',
      t('schema.ChooseDiscountType'),
      (v, context) => {
        let result = true;
        if (!v && context.from[1].value.details_type == '1') result = false;
        return result;
      }
    ),
    discount_price: Yup.string()
      .test(
        'products.index.discount_price',
        t('schema.EnterDiscountPrice'),
        (v, context) => {
          let result = true;
          if (
            !v &&
            context.from[1].value.details_type == '1' &&
            context.parent.discount_type == '1'
          ) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.discount_price',
        t('schema.OnlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[1].value.details_type == '1' &&
            context.parent.discount_type == '1'
          ) {
            result = false;
          }
          return result;
        }
      ),
    // .test(
    //   'products.index.discount_price',
    //   'سعر الخصم اعلى من سعر المنتج',
    //   (v, context) => {
    //     let result = true;
    //     if (
    //       v &&
    //       context.from[1].value.details_type === '1' &&
    //       context.parent.discount_type == '1' &&
    //       parseInt(v) > parseInt(context.parent.price)
    //     )
    //       result = false;
    //     return result;
    //   }
    // ),
    discount_percentage: Yup.string()
      .test(
        'products.index.discount_percentage',
        t('schema.EnterDiscountPercentage'),
        (v, context) => {
          let result = true;
          if (
            !v &&
            context.from[1].value.details_type == '1' &&
            context.parent.discount_type == '2'
          ) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.discount_percentage',
        t('schema.OnlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[1].value.details_type == '1' &&
            context.parent.discount_type == '2'
          ) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.discount_price',
        t('schema.DiscountPercentageHigher'),
        (v, context) => {
          let result = true;
          if (
            v &&
            context.from[1].value.details_type === '1' &&
            context.parent.discount_type == '2' &&
            parseInt(v) > 100
          )
            result = false;
          return result;
        }
      )
  };
};

const sharedIngredientSchemaObj = (t) => {
  return {
    ingredient_id: Yup.string()
      .test(
        'ingredients.index.ingredient_id',
        t('schema.ChooseProductComponent'),
        (v, context) => {
          let result = true;
          if (!v && context.from[1].value.details_type == '2') {
            result = false;
          }
          return result;
        }
      )
      .test(
        'ingredients.index.ingredient_id',
        t('schema.ChooseAnotherComponent'),
        (v, context) => {
          let result = true;
          if (
            v &&
            context.from[1]?.value?.ingredients?.length > 0 &&
            context.from[1]?.value?.ingredients.filter(
              (p) => p.ingredient_id == v
            )?.length > 1
          ) {
            result = false;
          }
          return result;
        }
      ),

    quantity: Yup.string()
      .test(
        'ingredients.index.quantity',
        t('schema.EnterQuantity'),
        (v, context) => {
          let result = true;
          if (!v && context.from[1].value.details_type == '2') {
            result = false;
          }
          return result;
        }
      )
      .test(
        'ingredients.index.quantity',
        t('schema.OnlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            // !v.match(/^[0-9]+$/) &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[1].value.details_type == '2'
          ) {
            result = false;
          }
          return result;
        }
      ),
    warehouse_id: Yup.string().test(
      'ingredients.index.warehouse_id',
      t('schema.ChooseWarehouse'),
      (v, context) => {
        let result = true;
        if (!v && context.from[1].value.details_type == '2') {
          result = false;
        }
        return result;
      }
    ),
    // expirationDate: Yup.mixed().test(
    //   'ingredients.index.expirationDate',
    //   'اختار تاريخ انتهاء الصلاحية',
    //   (v, context) => {
    //     let result = true;
    //     if (!v && context.from[1].value.details_type == '2') {
    //       result = false;
    //     }
    //     return result;
    //   }
    // ),
    price: Yup.string()
      .test(
        'ingredients.index.price',
        t('schema.EnterCashAmount'),
        (v, context) => {
          let result = true;
          // if the bill of type ingredients === 2 and no value of price
          if (!v && context.from[1].value.details_type == '2') result = false;
          return result;
        }
      )
      .test(
        'ingredients.index.price',
        t('schema.OnlyNumbersAllowedPrice'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[1].value.details_type == '2'
          )
            result = false;
          return result;
        }
      ),
    discount_type: Yup.string().test(
      'ingredients.index.discount_type',
      t('schema.ChooseDiscountType'),
      (v, context) => {
        let result = true;
        if (!v && context.from[1].value.details_type == '2') result = false;
        return result;
      }
    ),
    discount_price: Yup.string()
      .test(
        'ingredients.index.discount_price',
        t('schema.EnterDiscountPrice'),
        (v, context) => {
          let result = true;
          if (
            !v &&
            context.from[1].value.details_type == '2' &&
            context.parent.discount_type == '1'
          ) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'ingredients.index.discount_price',
        t('schema.OnlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[1].value.details_type == '2' &&
            context.parent.discount_type == '1'
          ) {
            result = false;
          }
          return result;
        }
      ),
    // .test(
    //   'ingredients.index.discount_price',
    //   'سعر الخصم اعلى من سعر المكون',
    //   (v, context) => {
    //     let result = true;
    //     if (
    //       v &&
    //       context.from[1].value.details_type === '2' &&
    //       context.parent.discount_type == '1' &&
    //       parseInt(v) > parseInt(context.parent.price)
    //     )
    //       result = false;
    //     return result;
    //   }
    // ),
    discount_percentage: Yup.string()
      .test(
        'ingredients.index.discount_percentage',
        t('schema.EnterDiscountPercentage'),
        (v, context) => {
          let result = true;
          if (
            !v &&
            context.from[1].value.details_type == '2' &&
            context.parent.discount_type == '2'
          ) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'ingredients.index.discount_percentage',
        t('schema.OnlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[1].value.details_type == '2' &&
            context.parent.discount_type == '2'
          ) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'ingredients.index.discount_price',
        t('schema.DiscountPercentageHigher'),
        (v, context) => {
          let result = true;
          if (
            v &&
            context.from[1].value.details_type === '2' &&
            context.parent.discount_type == '2' &&
            parseInt(v) > 100
          )
            result = false;
          return result;
        }
      )
  };
};

const sharedSchema = (t) => {
  return {
    userOrSupplier: Yup.string().optional(t('schema.ChoosePaymentType')),
    user_id: Yup.string()
      .test(
        'user_id',
        t('schema.ChooseSimplifiedInvoiceClient'),
        (v, context) => {
          let result = true;
          if (!v && context?.parent?.userOrSupplier == 1) {
            result = false;
          }
          return result;
        }
      )
      .test('user_id', t('schema.ChooseTaxInvoiceClient'), (v, context) => {
        let result = true;
        if (!v && context?.parent?.userOrSupplier == 2) {
          result = false;
        }
        return result;
      }),
    details_type: Yup.string().required(t('schema.ChooseInvoiceType')),
    products: Yup.array().of(
      Yup.object().shape({
        ...sharedProductsSchemaObj(t)
      })
    ),
    ingredients: Yup.array().of(
      Yup.object().shape({
        ...sharedIngredientSchemaObj(t)
      })
    ),
    // due_date: Yup.mixed().required('اختار تاريخ الاستحقاق'),
    // discount_type: Yup.string().required('اختار نوع الخصم'),
    discount_price: Yup.string()
      .test('discount_price', t('schema.EnterDiscountPrice'), (v, context) => {
        let result = true;
        if (!v && context.parent?.discount_type === '1') result = false;
        return result;
      })
      .test(
        'discount_price',
        t('schema.OnlyNumbersAllowedPrice'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.parent?.discount_type === '1'
          )
            result = false;
          return result;
        }
      ),
    discount_percentage: Yup.string()
      .test(
        'discount_percentage',
        t('schema.EnterDiscountPrice'),
        (v, context) => {
          let result = true;
          if (!v && context.parent?.discount_type === '2') result = false;
          return result;
        }
      )
      .test(
        'discount_percentage',
        t('schema.OnlyNumbersAllowedPrice'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.parent?.discount_type === '2'
          )
            result = false;
          return result;
        }
      ),
    extra_prices: Yup.array().of(
      Yup.object().shape({
        price: Yup.string()
          .test(
            'extra_prices.index.price',
            t('schema.EnterPrice'),
            (v, context) => {
              let result = true;
              if (!v && context.parent.title) {
                result = false;
              }
              return result;
            }
          )
          .test(
            'extra_prices.index.price',
            t('schema.OnlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (v && !v.match(/^(\d+)?(\.\d+)?$/)) {
                result = false;
              }
              return result;
            }
          ),
        title: Yup.string().test(
          'extra_prices.index.title',
          t('schema.EnterPriceStatement'),
          (v, context) => {
            let result = true;
            if (!v && context.parent.price) {
              result = false;
            }
            return result;
          }
        )
      })
    ),
    pay_type: Yup.string().required(t('schema.ChoosePaymentType')),
    multiPaymentVisa: Yup.string()
      .test(
        'multiPaymentVisa',
        t('schema.EnterNetworkAmount'),
        (v, context) => {
          let result = true;
          if (!v && context.from[0].value.pay_type == '6') {
            result = false;
          }
          return result;
        }
      )
      .test(
        'multiPaymentVisa',
        t('schema.OnlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[0].value.pay_type == '6'
          ) {
            result = false;
          }
          return result;
        }
      ),
    multiPaymentCash: Yup.string()
      .test('multiPaymentCash', t('schema.EnterCashAmount'), (v, context) => {
        let result = true;
        if (!v && context.from[0].value.pay_type == '6') {
          result = false;
        }
        return result;
      })
      .test(
        'multiPaymentCash',
        t('schema.OnlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[0].value.pay_type == '6'
          ) {
            result = false;
          }
          return result;
        }
      ),
    // bankAccountNumber: Yup.string().test(
    //   'bankAccountNumber',
    //   t('schema.OnlyNumbersAllowed'),
    //   (v, context) => {
    //     let result = true;
    //     if (
    //       v &&
    //       !v.match(/^(\d+)?(\.\d+)?$/) &&
    //       context.from[0].value.pay_type == '5'
    //     ) {
    //       result = false;
    //     }
    //     return result;
    //   }
    // ),
    // bankPrice: Yup.string()
    //   .test('bankPrice', ' من فضلك أدخل مبلغ الدفع', (v, context) => {
    //     let result = true;
    //     if (!v && context.from[0].value.pay_type == '5') {
    //       result = false;
    //     }
    //     return result;
    //   })
    //   .test('bankPrice', t('schema.OnlyNumbersAllowed'), (v, context) => {
    //     let result = true;
    //     if (
    //       v &&
    //       !v.match(/^(\d+)?(\.\d+)?$/) &&
    //       context.from[0].value.pay_type == '5'
    //     ) {
    //       result = false;
    //     }
    //     return result;
    //   }),
    invoice_due_date: Yup.mixed().test(
      'invoice_due_date',
      t('schema.PleaseEnterPaymentDate'),
      (v, context) => {
        let result = true;
        if (!v && context.from[0].value.pay_type == '2') {
          result = false;
        }
        return result;
      }
    ),
    // first_paid: Yup.mixed().test(
    //   'first_paid',
    //   'من فضلك أدخل الدفعة الأولى',
    //   (v, context) => {
    //     let result = true;
    //     if (!v && context.from[0].value.pay_type == '2') {
    //       result = false;
    //     }
    //     return result;
    //   }
    // ),
    invoice_image: Yup.mixed(),
    first_pay_img: Yup.mixed()
    // deferred_invoice_client: Yup.string().test(
    //   'deferred_invoice_client',
    //   'من فضلك أدخل إسم العميل ',
    //   (v, context) => {
    //     let result = true;
    //     if (!v && context.from[0].value.pay_type == '2') {
    //       result = false;
    //     }
    //     return result;
    //   }
    // )
    // installments: Yup.array().of(
    //   Yup.object().shape({
    //     price: Yup.string()
    //       .test(
    //         'installments.index.price',
    //         ' من فضلك أدخل السعر',
    //         (v, context) => {
    //           let result = true;
    //           if (!v && context.from[1].value.pay_type == '2') {
    //             result = false;
    //           }
    //           return result;
    //         }
    //       )
    //       .test(
    //         'installments.index.price',
    //         t('schema.OnlyNumbersAllowed'),
    //         (v, context) => {
    //           let result = true;
    //           if (
    //             v &&
    //             !v.match(/^(\d+)?(\.\d+)?$/) &&
    //             context.from[1].value.pay_type == '2'
    //           ) {
    //             result = false;
    //           }
    //           return result;
    //         }
    //       ),
    //     date: Yup.mixed().test(
    //       'installments.index.date',
    //       'اختار تاريخ السداد',
    //       (v, context) => {
    //         let result = true;
    //         if (!v && context.from[1].value.pay_type == '2') {
    //           result = false;
    //         }
    //         return result;
    //       }
    //     )
    //     // status: Yup.mixed().test(
    //     //   'installments.index.status',
    //     //   'اختار حالة الايصال',
    //     //   (v, context) => {
    //     //     let result = true;
    //     //     if (!v && context.from[1].value.pay_type == '2') {
    //     //       result = false;
    //     //     }
    //     //     return result;
    //     //   }
    //     // )
    //   })
    // )
  };
};

const newInvoiceSchema = (t) => {
  return Yup.object().shape({ ...sharedSchema(t) });
};

const editInvoiceSchema = (t) => {
  return Yup.object().shape({
    ...sharedSchema(t),

    products: Yup.array().of(
      Yup.object().shape({
        ...sharedProductsSchemaObj(t),
        returnQuantity: Yup.string()
          .test(
            'products.index.returnQuantity',
            t('schema.EnterQuantityToReturn'),
            (v, context) => {
              let result = true;
              if (!v && context.from[1].value.details_type == '1') {
                result = false;
              }
              return result;
            }
          )
          .test(
            'products.index.returnQuantity',
            t('schema.OnlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (
                v &&
                // !v.match(/^[0-9]+$/) &&
                !v.match(/^(\d+)?(\.\d+)?$/)
              ) {
                result = false;
              }
              return result;
            }
          )
      })
    ),
    ingredients: Yup.array().of(
      Yup.object().shape({
        ...sharedIngredientSchemaObj(t),
        returnQuantity: Yup.string()
          .test(
            'products.index.returnQuantity',
            t('schema.EnterQuantityToReturn'),
            (v, context) => {
              let result = true;
              if (!v && context.from[1].value.details_type == '2') {
                result = false;
              }
              return result;
            }
          )
          .test(
            'products.index.returnQuantity',
            t('schema.OnlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (
                v &&
                context.from[1].value.details_type == '2' &&
                // !v.match(/^[0-9]+$/) &&
                !v.match(/^(\d+)?(\.\d+)?$/)
              ) {
                result = false;
              }
              return result;
            }
          )
      })
    )
  });
};

const getOutgoingInvoiceSchema = (selectedReceipt, t) => {
  if (!selectedReceipt) return newInvoiceSchema(t);
  return editInvoiceSchema(t);
};

export default getOutgoingInvoiceSchema;
