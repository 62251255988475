/* eslint-disable react/jsx-no-target-blank */
import { DownloadOutlined } from '@ant-design/icons';
import { Popconfirm, Tooltip } from 'antd';
import FileSaver from 'file-saver';
import { useTranslation } from 'react-i18next';
import deleteDocApi from '../../../apis/settings-apis/documents-apis/deleteDocumentApi';
import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
const saveFile = (url, title) => {
  FileSaver.saveAs(url, title);
};

const TableColumns = (
  allFetchedDocs,
  setModalOpened,
  setFetchCount,
  setSelectedDoc,
  setIsLoading,
  token
) => {
  const handleEditClick = (record) => {
    const found = allFetchedDocs.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedDoc({ ...found });
      setModalOpened(true);
    }
  };
  const { t } = useTranslation();

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteDocApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Messages.addedSuccessfully'),
          message: t('Messages.DeletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Messages.ErrorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Messages.TryAgainLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Messages.ErrorOccurred'),
        message: t('Messages.TryAgainLater')
      });
    }
  };

  const renderActions = (obj) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(obj)}>
          <EditIcon />
          {t('Documents.Edit')}
        </div>
        <Popconfirm
          title={t('Messages.areyouSureToDelete')}
          onConfirm={() => handleDeleteRequest(obj.id)}
          okText={t('Messages.yes')}
          cancelText={t('Messages.no')}
        >
          <Tooltip title={t('Documents.DeleteDocuments')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      // title: 'كود الوثيقة',
      title: t('Documents.DocumentCode'),

      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      // title: 'عنوان الوثيقة',
      title: t('Documents.DocumentTitle'),

      dataIndex: 'title',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record.title}</span>
          </div>
        );
      }
    },
    {
      // title: 'ملف الوثيقة',
      title: t('Documents.DocumentFile'),

      dataIndex: 'document',
      // width: 192,
      render: (_, record) => {
        return record?.document ? (
          <div
            className="row-cell row-action-btn"
            onClick={() => saveFile(record.document, record.name)}
          >
            <div className="custom-btn">
              <DownloadOutlined />
              {t('Documents.Download')}
            </div>
          </div>
        ) : (
          '-----'
        );
      }
    },
    {
      // title: 'الاعدادات',
      title: t('Documents.Settings'),

      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default TableColumns;
