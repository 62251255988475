import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import PageTable from './PageTable';
import PageModal from './PageModal';
import ArchiveDocsContext from '../../../contexts/archive-contexts/ArchiveDocsProvider';
import TableFilter from './TableFilter';
import './ArchiveDocsPage.scss';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

const ArchiveDocsPage = () => {
  const { modalOpened, setModalOpened, setSelectedDoc } =
    useContext(ArchiveDocsContext);
  const { t } = useTranslation();

  return (
    <div className="archive-docs-page">
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">{t('Documents.Documents')}</h2>
          <p>كشف وعرض كافة الوثائق</p>
          <p>{t('Documents.ShowAndDisplayAllDocuments')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('Documents.Documents')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.archivePage.title')}
            title3={t('Documents.Documents')}
            link3={'/archive/docs'}
          />
        </div>
        <SearchNotificationsProfile />
      </div>

      <div className="custom-filter-add-section">
        <div className="add-section">
          <div
            className="Archive-add-btn"
            onClick={() => {
              // if selected unit then reset it
              // open the unit modal
              setSelectedDoc(null);
              setModalOpened(true);
            }}
          >
            <span className="text-span">{t('Documents.AddNewDocument')}</span>
          </div>
        </div>
        <TableFilter />
      </div>

      <PageTable />

      {modalOpened && <PageModal />}
    </div>
  );
};

export default ArchiveDocsPage;
