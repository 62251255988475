import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import signOutApi from '../apis/auth/signout';
import routerLinks from '../components/app/routerLinks';
import StopwatchContext from '../contexts/stopwatch-context/StopwatchContext';
import UserContext from '../contexts/user-context/UserProvider';
import checkRes from '../utils/checkRes';
import errorNotification from '../utils/errorNotification';
import successNotification from '../utils/successNotification';
import useCustomApiRequest from './useCustomApiRequest';
import Links from '../components/app/Links';
  const useSignOut = () => {
  const history = useHistory();
  const { setStopwatchHours, setStopwatchMinutes, setStopwatchSeconds } =
    useContext(StopwatchContext);
  const { user, removeCurrentUser, setOtpValue } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();
  const [isLoadingSignOut, setIsLoadingSignOut] = React.useState(false);
  const { t } = useTranslation();
  const signMeOut = () => {
    setIsLoadingSignOut(true);
    customApiRequest(
      signOutApi(user?.token),
      (res) => {
        setIsLoadingSignOut(false);
        if (checkRes) {
          removeCurrentUser();
          setOtpValue(false);
          sessionStorage.removeItem('otpValue');
          successNotification({
            title: 'Operation done successfully',
            message: res?.data?.message || t('LogOut.logOutSuccess')
          });
          //
          setStopwatchHours(0);
          setStopwatchMinutes(0);
          setStopwatchSeconds(0);
          //
          history.push(routerLinks?.signInPage);
        } else {
          errorNotification({
            title: t('LogOut.logOutError'),
            message: res?.data?.message || t('LogOut.tryLaterMessage')
          });
        }
      },

      (error) => {
        setIsLoadingSignOut(false);
        errorNotification({
          title: t('LogOut.logOutError'),
          message: error?.response?.data?.message || t('LogOut.tryLaterMessage')
        });
      }
    );
  };

  return { signMeOut, isLoadingSignOut, setIsLoadingSignOut };
};

export default useSignOut;
