import axios from 'axios';
import Cookies from 'js-cookie';

axios.interceptors.request.use(
  (req) => {
    // Do something before request is sent
    // if (!req.url.includes("login")) {

    //   let token = localStorage.getItem("AccessTokenGL") || null
    //   if (token !== null) {
    //     req.headers.Authorization = `Bearer ${token}`
    //     req.headers.Locale = localStorage.getItem("language") || "en"
    //   }
    // }
    req.headers.lang = Cookies.get('i18next') || 'ar';
    // console.log(req)
    return req;
  },
  (error) => {
    console.log(error);
    // Do something with request error
    return Promise.reject(error);
  }
);
