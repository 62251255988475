/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import UserIcon from '../../common/icons/UserIcon';
import PhoneIcon from '../../common/icons/PhoneIcon';
import EmailIcon from '../../common/icons/EmailIcon';
import LocationIcon from '../../common/icons/LocationIcon';
import CustomAntdDatePicker from '../../common/antd-form-components/CustomAntdDatePicker';
// import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { useContext } from 'react';
import { useEffect } from 'react';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import UserContext from '../../contexts/user-context/UserProvider';
import addNewSupplierApi from '../../apis/store-apis/suppliers/addNewSupplierApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import useClients from '../../custom-hooks/useClients';
import addNewCustomerPaymentApi from '../../apis/store-apis/customer-payments/addNewCustomerPaymentApi';
import moment from 'moment';
import CustomersPaymentsContext from '../../contexts/customers-payments-context/CustomersPaymentsContext';
import getDeferredInvoicesApi from '../../apis/store-apis/customer-payments/getDeferredInvoicesApi';

const schema = Yup.object().shape({
  user_id: Yup.string().required('ادخل اسم العميل'),
  amount: Yup.string()
    .required('ادخل الكمية')
    .matches(/^(\d+)?(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام'),
  date: Yup.string().required('ادخل التاريخ')
  // invoice_type: Yup.string().required('ادخل نوع الفاتورة')
});

const TableForm = ({ setAllSuppliersFetchCount }) => {
  const { user } = React.useContext(UserContext);
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedCustomerPayment,
    setSelectedCustomerPaymentId,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(CustomersPaymentsContext);
  const { allClients } = useClients();
  const [deferredInvoices, setDeferredInvoices] = useState([]);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      user_id: '',
      invoice_type: '',
      amount: '',
      date: '',
      invoice_id: ''
    }
  });
  const customApiRequest = useCustomApiRequest();

  // handle initial values
  // useEffect(() => {
  //   if (selectedSupplier) {
  //     if (selectedSupplier?.name) {
  //       setValue('name', selectedSupplier.name);
  //     }
  //     if (selectedSupplier?.phone) {
  //       setValue('phone', selectedSupplier.phone);
  //     }
  //   }
  // }, [selectedSupplier]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getDeferredInvoicesApi(user?.token, watch('user_id'), 2),
        (res) => {
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data?.length) {
            const data = res.data.data;
            setDeferredInvoices(data);
          }
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [watch('user_id')]);

  useEffect(() => {
    if (selectedCustomerPayment) {
      if (selectedCustomerPayment?.user?.id) {
        setValue('user_id', String(selectedCustomerPayment?.user?.id));
      }
      if (selectedCustomerPayment?.invoice_type) {
        setValue('invoice_type', String(selectedCustomerPayment?.invoice_type));
      }
      if (selectedCustomerPayment?.invoice_id) {
        setValue('invoice_id', String(selectedCustomerPayment?.invoice_id));
      }
      if (selectedCustomerPayment?.amount) {
        setValue('amount', String(selectedCustomerPayment?.amount));
      }
      if (selectedCustomerPayment?.notes) {
        setValue('notes', String(selectedCustomerPayment?.notes));
      }
      if (selectedCustomerPayment?.date) {
        setValue('date', moment(selectedCustomerPayment?.date, 'YYYY-MM-DD'));
      }
    }
  }, [selectedCustomerPayment]);
  const onSubmit = async (data) => {
    const mappedData = {};
    mappedData.type = 2;
    if (data.user_id) mappedData.user_id = data.user_id;
    if (data.amount) mappedData.amount = data.amount;
    if (data.notes) mappedData.notes = data.notes;
    if (data.date) mappedData.date = moment(data.date).format('MM/DD/YYYY');
    if (data.invoice_id) mappedData.invoice_id = data.invoice_id;
    if (data.invoice_type) mappedData.invoice_type = data.invoice_type;

    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedCustomerPayment) {
      mappedData.id = selectedCustomerPayment.id;
      customApiRequest(
        addNewCustomerPaymentApi(user?.token, mappedData, true),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (res?.status === 200 && res?.data?.data) {
            setIsLoading(false);
            setModalOpened(false);
            setSelectedCustomerPaymentId(res?.data?.data?.id);
            setFetchCount((prevState) => prevState + 1);
            if (setAllSuppliersFetchCount)
              setAllSuppliersFetchCount((prev) => prev + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم تعديل المورد بنجاح'
            });
          } else {
            setIsLoading(false);
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message
                ? res.data.message
                : 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    } else {
      customApiRequest(
        addNewCustomerPaymentApi(user?.token, mappedData, false),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setSelectedCustomerPaymentId(res?.data?.data?.id);
            setFetchCount((prevState) => prevState + 1);
            if (setAllSuppliersFetchCount)
              setAllSuppliersFetchCount((prev) => prev + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم اضافة المورد بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message
                ? res.data.message
                : 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  const [form] = Form.useForm();

  return (
    <Form
      className="customers-payments-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="select-label-wrap">
          <p className="label-p">اسم العميل</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              disabled={selectedCustomerPayment ? true : false}
              name="user_id"
              errorMsg={errors?.user_id?.message}
              validateStatus={errors?.user_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="اسم العميل"
              options={
                allClients?.length > 0 &&
                allClients.map((role) => ({
                  title: role.name,
                  value: String(role.id)
                }))
              }
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="customers-payments-form"
              // defaultValues={
              //   selectedCustomerPayment?.user_id
              //     ? selectedCustomerPayment?.user_id
              //     : null
              // }
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">المبلغ</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="amount"
              type="text"
              placeholder="المبلغ ..."
              errorMsg={errors?.amount?.message}
              validateStatus={errors?.amount ? 'error' : ''}
              control={control}
              setValue={setValue}
              defaultValue={
                selectedCustomerPayment?.amount
                  ? selectedCustomerPayment?.amount
                  : null
              }
            />
          </div>
        </div>
        <div className="date-picker-wrap">
          <p className="label-p">التاريخ</p>
          <CustomAntdDatePicker
            // disablePast
            control={control}
            name="date"
            className="receipt-date-picker"
            formClassName="customers-payments-form"
            // disablePast={true}
            // defaultValue={
            //   selectedCustomerPayment?.date
            //     ? selectedCustomerPayment?.date
            //     : null
            // }
          />
          {errors?.date && <p className="error-p">{errors.date.message}</p>}
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p"> الملاحظات</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="notes"
              rows={8}
              placeholder=" الملاحظات..."
              errorMsg={errors?.notes?.message}
              validateStatus={errors?.notes ? 'error' : ''}
              control={control}
              defaultValue={
                selectedCustomerPayment?.notes
                  ? selectedCustomerPayment?.notes
                  : null
              }
            />
          </div>
        </div>
        {/* <div className="select-label-wrap">
          <p className="label-p"> نوع الفاتورة </p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="invoice_type"
              errorMsg={errors?.invoice_type?.message}
              validateStatus={errors?.invoice_type ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="نوع الفاتورة "
              options={[
                {
                  title: 'فاتورة شراء',
                  value: '2'
                },
                {
                  title: 'فاتورة بيع',
                  value: '1'
                }
              ]}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="customers-payments-form"
              // defaultValue={
              //   selectedCustomerPayment?.invoice_type
              //     ? selectedCustomerPayment?.invoice_type
              //     : null
              // }
            />
          </div>
        </div> */}
        {watch('user_id') && watch('invoice_type') && (
          <div className="select-label-wrap">
            <p className="label-p"> فاتورة العميل </p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name="invoice_id"
                errorMsg={errors?.invoice_id?.message}
                validateStatus={errors?.invoice_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder="فاتورة العميل"
                options={
                  deferredInvoices?.length > 0 &&
                  deferredInvoices.map((role) => ({
                    title: `رقم الفاتورة ${role.invoiceNumber} [ الإجمالي : ${
                      role.totalPrice
                    } ] [ المتبقي : ${role.rest ? role?.rest : 0} ]`,
                    value: String(role.id)
                  }))
                }
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="customers-payments-form"
              />
            </div>
          </div>
        )}

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedCustomerPayment ? 'تعديل' : 'إضافة'}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
