import axios from 'axios';

const getAccountInfoApi = async (token, id) => {
  let url;
  url = `/accounting/account/${id}`

  try {
    const res = await axios({
      method: 'get',
      url: url,
      baseURL: process.env.REACT_APP_BASE_TEST_URL,
      // baseURL: "https://testclient-api.fatoorah.sa/apiAdmin",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAccountInfoApi;
