/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import { useContext } from 'react';
import { useEffect } from 'react';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import UserContext from '../../../contexts/user-context/UserProvider';
import InstallmentsPageContext from '../../../contexts/installments-context/InstallmentsContext';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import CustomAntdDatePicker from '../../../common/antd-form-components/CustomAntdDatePicker';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import moment from 'moment';
import createInstallmentApi from '../../../apis/store-apis/installments-api/createInstallmentApi';
import { useParams } from 'react-router-dom';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
// import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';

const schema = Yup.object().shape({
  price: Yup.string()
    .required('أدخل السعر')
    .matches(/^(\d+)?(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام'),
  date: Yup.mixed().required('أدخل تاريخ السداد'),
  status: Yup.string().required('اختار حالة السداد')
});

const InstallmentModalForm = () => {
  const { user } = React.useContext(UserContext);
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedInstallment,
    setIsSubmittingForm,
    isSubmittingForm
  } = useContext(InstallmentsPageContext);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      price: '',
      date: '',
      status: '',
      note: ''
    }
  });

  // handle initial values
  useEffect(() => {
    if (selectedInstallment) {
      if (selectedInstallment?.price) {
        setValue('price', selectedInstallment.price);
      }
      if (selectedInstallment?.status) {
        setValue('status', String(selectedInstallment.status));
      }
      if (selectedInstallment?.note) {
        setValue('note', selectedInstallment.note);
      }
      if (selectedInstallment?.date)
        setValue('date', moment(selectedInstallment.date, 'YYYY-MM-DD'));
    }
  }, [selectedInstallment]);
  const { id } = useParams();
  const customApiRequest = useCustomApiRequest();

  const onSubmit = async (data) => {
    const mappedData = {};
    mappedData.invoice_id = id;
    if (data.price) mappedData.price = data.price;
    if (data.status) mappedData.status = data.status;
    if (data.note) mappedData.note = data.note;
    if (data.date) mappedData.date = moment(data.date).format('YYYY-MM-DD');

    setIsLoading(true);
    setIsSubmittingForm(true);
    if (selectedInstallment) {
      mappedData.Installment_id = selectedInstallment.id;
      customApiRequest(
        createInstallmentApi(
          user?.token,
          mappedData,
          true,
          '/Installment/update'
        ),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (checkRes(res) && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم اضافة الايصال بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    } else {
      customApiRequest(
        createInstallmentApi(
          user?.token,
          mappedData,
          false,
          '/Installment/create'
        ),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (checkRes(res) && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم اضافة الايصال بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message || 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  const [form] = Form.useForm();

  return (
    <Form
      className="installments-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">سعر الايصال</p>
          <div className="text-field-wrap">
            <AntdTextField
              // disabled={selectedInstallment}
              className="form-text-field"
              name="price"
              type="text"
              placeholder="سعر الايصال..."
              errorMsg={errors?.price?.message}
              validateStatus={errors?.price ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <label className="custom-date-input" style={{ marginBottom: 24 }}>
          <span className="label-span">تاريخ الايصال</span>
          <CustomAntdDatePicker
            // disablePast
            control={control}
            name="date"
            className="receipt-date-picker"
            formClassName="installments-form"
          />
        </label>

        <div className="select-label-wrap">
          <p className="label-p">حالة الايصال</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="status"
              errorMsg={errors?.status && errors.status.message}
              validateStatus={errors?.status ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="حالة الايصال"
              options={[
                { title: 'تم دفع الايصال', value: '1' },
                { title: 'لم يتم دفع الايصال', value: '2' }
              ]}
              formClassName="installments-form"
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">ملاحظات</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="note"
              rows={4}
              placeholder="ملاحظات..."
              errorMsg={errors?.note && errors.note.message}
              validateStatus={errors?.note ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedInstallment ? 'تعديل' : 'إضافة'}
        </Button>
      </div>
    </Form>
  );
};

export default InstallmentModalForm;
