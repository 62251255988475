import axios from 'axios';

// type === 1 => invoice is a normal buying invoice
// type === 2 => invoice is a return invoice
// type === 3 => invoice is a reservation invoice
// is_return === 1 => invoice of products
// today_reservations === 1 => get today reservations
// user_id === 2 => id of the client
const getAllSaleInvoicesApi = async (token, filterValues , is_report = false) => {
  try {
    const url = `/SaleInvoice/all?type=${
      filterValues?.type ? filterValues?.type : ''
    }&invoiceNumber=${
      filterValues?.invoiceNumber ? filterValues.invoiceNumber : ''
    }&id=${filterValues?.id ? filterValues.id : ''}&is_return=${
      filterValues?.is_return ? filterValues.is_return : ''
    }&today_reservations=${
      filterValues?.today_reservations ? filterValues.today_reservations : ''
    }&user_id=${
      (filterValues?.user_id ? filterValues.user_id : '') ||
      (filterValues?.user_id_from_payment
        ? filterValues?.user_id_from_payment
        : '')
    }&supplier_id=${
      filterValues?.supplier_id ? filterValues.supplier_id : ''
    }&from=${filterValues?.from ? filterValues.from : ''}&to=${
      filterValues?.to ? filterValues.to : ''
    }&pos_id=${filterValues?.pos_id ? filterValues.pos_id : ''}&admin_id=${
      filterValues?.admin_id ? filterValues.admin_id : ''
    }&page=${filterValues?.page ? filterValues.page : ''}&test=1&is_report=${is_report}`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllSaleInvoicesApi;
