import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import { ReadOutlined } from '@ant-design/icons';
import countWords from '../../../utils/countWords';
import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import trimWords from '../../../utils/trimWords';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import deleteUnitApi from '../../../apis/settings-apis/measuremnet-units-apis/deleteUnitApi';

const tableColumns = (
  allFetchedUnits,
  setModalOpened,
  setFetchCount,
  setSelectedUnit,
  setIsLoading,
  t,
  token
) => {
  const handleEditClick = (record) => {
    const found = allFetchedUnits.find((place) => place.id === record.id);
    if (found) {
      setSelectedUnit({ ...found });
      setModalOpened(true);
    }
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteUnitApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Inventory.MeasurementUnits.addedSuccessfully'),
          message: t(
            'Inventory.MeasurementUnits.unitMeasurementSuccessfullyDeleted'
          )
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Inventory.MeasurementUnits.errorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Inventory.MeasurementUnits.pleaseTryLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Inventory.MeasurementUnits.errorOccurred'),
        message: t('Inventory.MeasurementUnits.pleaseTryLater')
      });
    }
  };

  const renderActions = (obj) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(obj)}>
          <EditIcon />
          {t('Inventory.MeasurementUnits.edit')}
        </div>
        <Popconfirm
          title={t('Inventory.MeasurementUnits.areyouSureToDelete')}
          onConfirm={() => handleDeleteRequest(obj.id)}
          okText={t('Inventory.MeasurementUnits.yes')}
          cancelText={t('Inventory.MeasurementUnits.no')}
        >
          <Tooltip title={t('Inventory.MeasurementUnits.delete')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      title: t('Inventory.MeasurementUnits.code'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.MeasurementUnits.nameUnitMeasurement'),
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record.name}</span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.MeasurementUnits.note'),
      dataIndex: 'note',
      // width: 192,
      render: (_, record) => {
        if (record.note) {
          const wordsCount = countWords(String(record.note));
          if (wordsCount > 5) {
            return (
              <Popover
                title={t('Inventory.MeasurementUnits.readMore')}
                content={String(record.note)}
              >
                <Button size="large" type="text">
                  <p className="has-read-more">
                    <span>{trimWords(String(record.note), 5)}</span>
                    <ReadOutlined
                      className="read-more-book"
                      style={{ margin: '0 4px' }}
                    />
                  </p>
                </Button>
              </Popover>
            );
          } else
            return (
              <div className="row-cell row-text">{String(record.note)}</div>
            );
        } else {
          return '----';
        }
      }
    },
    {
      title: t('Inventory.MeasurementUnits.settings'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
