import axios from 'axios';

const searchAllSuppliersApi = async (token,values) => {
  try {
    const res = await axios.get(`/Supplier/all?page=${values?.page ? values.page : ""}&searchText=${values?.searchText ? values.searchText : ''}&test=1`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default searchAllSuppliersApi;
