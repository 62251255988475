/* eslint-disable react-hooks/exhaustive-deps */
import queryString from 'query-string';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import getAllShiftReportsApi from '../../apis/shift-reports-apis/getAllShiftReportsApi';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import SearchNotificationsProfile from '../../components/search-notifications-profile/SearchNotificationsProfile';
import ShiftReportsContext from '../../contexts/shift-reports-context/ShiftReportsContext';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import PageTable from './PageTable';
import './SaleInvoicesPage.scss';
import SelectedShiftReportModal from './SelectedShiftReportModal';
import TableFilter from './TableFilter';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
import PosReportModal from './PosReportModal';
const ShiftReportsPage = (reports) => {
  const { user } = useContext(UserContext);
  const systemSettings = useSystemSettings();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { t } = useTranslation();
  const {
    allFetchedShiftReports,
    setIsLoadingAllShiftReports,
    setAllFetchedShiftReports,
    fetchCountShiftReports,
    tableFilter,
    setTablePagination,
    tablePagination,
    selectedShiftReportsModalOpned,
  } = useContext(ShiftReportsContext);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoadingAllShiftReports(true);
      customApiRequest(
        getAllShiftReportsApi(user?.token, {
          ...tableFilter,
          ...values,
          page: tablePagination?.current_page
        },systemSettings?.id),
        (res) => {
          setIsLoadingAllShiftReports(false);
          if (res?.status === 200 && res?.data?.data?.data?.length >= 0) {
            const data = res?.data?.data?.data;
            setAllFetchedShiftReports(data);
          }
          if (res?.data?.data?.pagination) {
            setTablePagination(res?.data?.data?.pagination);
          }
        },
        (error) => {
          setIsLoadingAllShiftReports(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCountShiftReports]);

  return (
    <div className="sale-invoices-page">
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">{t('ShiftReports.title')}</h2>
          <p>{t('ShiftReports.subTitle')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('AppBar.Products.analysisPage.shiftPageReports')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.analysisPage.title')}
            title3={t('AppBar.Products.analysisPage.shiftPageReports')}
            link3={'/shift-reports'}
          />
        </div>
        <SearchNotificationsProfile />
      </div>

      <div className="custom-filter-add-section1">
        <TableFilter allFetchedSaleInvoices={allFetchedShiftReports} />
      </div>

      <div className="table-wrapper">
        <>
          <PageTable
            allFetchedSaleInvoices={allFetchedShiftReports}
            isReportsPage={true}
            // taxPage={taxPage}
            // selectedPicker={selectedPicker}
          />

          {selectedShiftReportsModalOpned && <PosReportModal />}
        </>
      </div>
    </div>
  );
};

export default ShiftReportsPage;
