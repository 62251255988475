import axios from 'axios';

const searchAllProducts = async (token,values) => {
  try {
    let url = `/Product/all?page=${values?.page ? values.page : ""}&searchText=${values?.searchText ? values.searchText : ''}&test=1`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default searchAllProducts;
