import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  isSingleLoading: false,
  setIsSingleLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  customerPaymentModalOpened: false,
  setCustomerPaymentModalOpened: (v) => {},
  allFetchedCustomersPayments: null,
  setAllFetchedCustomersPayments: (v) => {},
  selectedCustomerPayment: null,
  setSelectedCustomerPayment: (v) => {},
  nameSearchValue: '',
  SearchTargetValue: '',
  setNameSearchValue: (v) => {},

  //
  selectedCustomerPaymentId: '',
  setSelectedCustomerPaymentId: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {}
};

const CustomersPaymentsContext = createContext(INITIAL_VALUES);

export const CustomersPaymentsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [isSingleLoading, setIsSingleLoading] = useState(
    INITIAL_VALUES.isLoading
  );
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [customerPaymentModalOpened, setCustomerPaymentModalOpened] = useState(
    INITIAL_VALUES.customerPaymentModalOpened
  );

  const [allFetchedCustomersPayments, setAllFetchedCustomersPayments] =
    useState(INITIAL_VALUES.allFetchedCustomersPayments);
  const [selectedCustomerPayment, setSelectedCustomerPayment] = useState(
    INITIAL_VALUES.selectedCustomerPayment
  );
  const [nameSearchValue, setNameSearchValue] = useState(
    INITIAL_VALUES.nameSearchValue
  );
  const [SearchTargetValue, setSearchTargetValue] = useState(
    INITIAL_VALUES.SearchTargetValue
  );
  //
  const [selectedCustomerPaymentId, setSelectedCustomerPaymentId] = useState(
    INITIAL_VALUES.selectedCustomerPaymentId
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES?.tablePagination
  );

  return (
    <CustomersPaymentsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isSingleLoading,
        setIsSingleLoading,
        fetchCount,
        setFetchCount,
        modalOpened,
        setModalOpened,
        customerPaymentModalOpened,
        setCustomerPaymentModalOpened,
        allFetchedCustomersPayments,
        setAllFetchedCustomersPayments,
        selectedCustomerPayment,
        setSelectedCustomerPayment,
        nameSearchValue,
        setNameSearchValue,
        //
        SearchTargetValue,
        setSearchTargetValue,
        //
        selectedCustomerPaymentId,
        setSelectedCustomerPaymentId,
        isSubmittingForm,
        setIsSubmittingForm,
        tablePagination,
        setTablePagination
      }}
    >
      {children}
    </CustomersPaymentsContext.Provider>
  );
};

export default CustomersPaymentsContext;
