import axios from 'axios';

// filterValues = { invoice_id: '', status: '' }
const getAllInstallmentsApi = async (token, filterValues) => {
  try {
    const url = `/Installment/all?invoice_id=${
      filterValues?.invoice_id || ''
    }&status=${filterValues?.status || ''}&user_id=${
      filterValues?.userId || ''
    }&supplier_id=${filterValues?.supplierId || ''}`;

    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllInstallmentsApi;
