import axios from 'axios';

const createTransactionsLeafApi = async (token, values, url , edit = false) => {


  try {
    const res = await axios({
      method: 'post',
      url,
      baseURL: process.env.REACT_APP_BASE_TEST_URL,
      // baseURL: 'https://testclient-api.fatoorah.sa/apiAdmin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      },
      data: { ...values }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default createTransactionsLeafApi;
