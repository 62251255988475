import React, { useContext } from 'react';
import { Modal } from 'antd';
import StoreNewProductForm from './StoreNewProductModalForm';
import StoreNewProductModalContext from '../../contexts/store-new-product-context/StoreNewProductProvider';
import './StoreNewProductModal.scss';
import { useTranslation } from 'react-i18next';

const StoreNewProductModal = ({ setAllProductsFetchCount }) => {
  const {
    modalOpened,
    setModalOpened,
    selectedProduct,
    setSelectedProductId,
    setSelectedProduct
  } = useContext(StoreNewProductModalContext);
  const { t } = useTranslation();

  return (
    <Modal
      className="new-product-modal"
      width="90%"
      style={{ maxWidth: '942px' }}
      title={
        selectedProduct
          ? t('services.updateServices')
          : t('services.addNewService')
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedProduct(null);
        setSelectedProductId('');
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedProduct(null);
        setSelectedProductId('');
      }}
      footer={false}
    >
      <StoreNewProductForm
        setAllProductsFetchCount={setAllProductsFetchCount}
      />
    </Modal>
  );
};

export default StoreNewProductModal;
