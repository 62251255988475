/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Tabs } from 'antd';
import SharedIncomingPurchaseInvoice from '../../store-pages/store-incoming-purchase-invoices-page/SharedIncomingPurchaseInvoice';
import IncomingPurchaseInvoiceContext from '../../../contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import TaxInvoiceReportsContext from '../../../contexts/reports-contexts/TaxInvoiceReportsContext';
import SaleInvoicesContext from '../../../contexts/sale-invoices-context/SaleInvoicesContext';
import SharedSalesInvoice from '../../sale-invoices-page/SharedSalesInvoice';
import SharedOutgoingPurchaseInvoice from '../../store-pages/store-outgoing-purchase-invoices-page/SharedOutgoingPurchaseInvoice';
import SaleInvoicesProductsContext from '../../../contexts/sale-invoices-context/SaleInvoicesProductsContext';
import { useTranslation } from 'react-i18next';
const { TabPane } = Tabs;
const TaxInvoiceReportsTablesForProducts = ({ taxPage, selectedPicker }) => {
  const { setAllFetchedReceipts } = useContext(IncomingPurchaseInvoiceContext);
  const { setAllFetchedSaleInvoice } = useContext(SaleInvoicesProductsContext);

  const { fetchedReports } = useContext(TaxInvoiceReportsContext);
  useEffect(() => {
    setAllFetchedReceipts(fetchedReports.buyInvoice);
    setAllFetchedSaleInvoice(fetchedReports?.saleInvoice);
  }, [fetchedReports]);
 const {t} = useTranslation()
  const renderTabs = () => {
    return (
      <Tabs defaultActiveKey="1">
        <TabPane tab={t('taxInvoiceReports.incomingPurchaseInvoices')} key="1">
          <div className="tax-invoice-reports-purchases-wrap">
            <SharedIncomingPurchaseInvoice
              allFetchedReceipts={fetchedReports?.buyInvoice}
              total={fetchedReports?.buyTotal}
              withoutReturnBtn={true}
              taxPage={true}
              selectedPicker={selectedPicker}
            />
          </div>
        </TabPane>
        <TabPane tab={t('taxInvoiceReports.salesInvoicesIssued')} key="2">
          <div className="tax-invoice-reports-purchases-wrap">
            <SharedOutgoingPurchaseInvoice
              allFetchedReceipts={fetchedReports?.buySader}
              total={fetchedReports?.saderTotal}
              withoutReturnBtn={true}
              taxPage={true}
              selectedPicker={selectedPicker}
            />
          </div>
        </TabPane> 
        <TabPane tab={t('taxInvoiceReports.salesInvoices')} key="3">
          <div className="tax-invoice-reports-sales-wrap">
            <div className="table-wrapper">
              <SharedSalesInvoice
                allFetchedSaleInvoices={fetchedReports?.saleInvoice}
                taxPage={true}
                selectedPicker={selectedPicker}
              />
            </div>
          </div>
        </TabPane>
      </Tabs>
    );
  };
  return <div className="tax-invoie-reports-tables-wrap">{renderTabs()}</div>;
};

export default TaxInvoiceReportsTablesForProducts;
