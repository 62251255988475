import axios from 'axios';
const addNewVariantApi = async (token, values, edit = false) => {
  const formData = new FormData();
  const mappedValues = {};
  if (values.unit_variant)  formData.append('name', values.unit_variant); //mappedValues.name = values.unit_variant;
  // if (values.unit_note) mappedValues.note = values.unit_note;
  if (values.id)  formData.append('id',values.id); //mappedValues.id = values.id;
  try {
    let url;
    if (!edit) url = '/variants/create';
    else url = '/variants/update';
    const res = await axios.post(url, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default addNewVariantApi;
