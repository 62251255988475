/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Table, Empty } from 'antd';
import tableColumns from './tableColumns';
import SettingsCategoriesContext from '../../../contexts/settings-contexts/SettingsCategoriesProvider';
import { useEffect } from 'react';
// import catsTableData from './catsTableData';
import { useState } from 'react';
import getAllCategoriesApi from '../../../apis/settings-apis/categories-apis/getAllCategoriesApis';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import { useTranslation } from 'react-i18next';

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const PageTable = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const {
    fetchCount,
    isLoading,
    setIsLoading,
    setModalOpened,
    setFetchCount,
    allFetchedCategories,
    setAllFetchedCategories,
    setSelectedCategory
  } = useContext(SettingsCategoriesContext);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: index + 1,
      name: obj?.name ? obj.name : '',
      categoryDescription: obj?.description ? obj.description : ''
    }));
  };
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    // level 1 ===> main category
    // cat_id ===> 0 main category
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllCategoriesApi(user?.token, 1, 0),
        (res) => {
          setIsLoading(false);
          if (checkRes(res) && res.data.data.length >= 0) {
            const data = res.data.data;
            setAllFetchedCategories(data);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedCategories?.length >= 0) {
      const mappedData = mapDataSource(allFetchedCategories);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedCategories, allFetchedCategories?.length]);

  return (
    <Table
      locale={{
        emptyText: (
          <Empty description={false}>
            {t('Inventory.Categories.noDataAvailable')}
          </Empty>
        )
      }}
      pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
      className="antd-custom-table"
      dataSource={tableDataSource}
      columns={tableColumns(
        setModalOpened,
        setFetchCount,
        setSelectedCategory,
        setIsLoading,
        t,
        user?.token
      )}
      loading={isLoading}
      // scroll={{ x: 400 }}
      footer={false}
    />
  );
};

export default PageTable;
