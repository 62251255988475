/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react';
import { ClientsProvider } from '../../../contexts/clients-context/ClientsContext';
import { IncomingPurchaseInvoiceProvider } from '../../../contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import { SaleInvoiceProvider } from '../../../contexts/sale-invoices-context/SaleInvoicesContext';
// import { ShiftsProvider } from '../../../contexts/shifts-context/ShiftsContext';
// import { ShiftsProvider } from '../../../contexts/sale-invoices-context/SaleInvoicesContext'
import UserContext from '../../../contexts/user-context/UserProvider';
import ClientsPage from '../../../pages/clients-page/ClientsPage';
import PriceOfferPageNew from '../../../pages/store-pages/price-offer-page-new/PriceOfferPageNew';
import userRoles from '../../../userRoles';
import protectMe from '../../../utils/protectMe';
// import routerLinks from '../routerLinks';
import salesRouterLinks from './salesRouterLinks';
import SaleInvoicesPage from '../../../pages/sale-invoices-page/SaleInvoicesPage';

import Links from '../Links';
import { ShiftsProvider } from '../../../contexts/shifts-context/ShiftsContext';
import ShiftsPage from '../../../pages/shifts-page/ShiftsPage';
import CustomerPaymentsPage from '../../../pages/customer-payments-page/CustomerPaymentsPage';
import ClientPaymentsPage from '../../../pages/client-payments-page/ClientPaymentsPage';
import ShiftReportsPage from '../../../pages/shift-reports-page/ShiftReportsPage';
import { ShiftReportsProvider } from '../../../contexts/shift-reports-context/ShiftReportsContext';

const SalesRoutes = () => {
  const { loggedIn, user } = useContext(UserContext);
  const routerLinks = Links();
  const [routesArr, setRoutesArr] = useState([]);
  useEffect(() => {
    if (user?.role === userRoles?.dataEntry) {
      setRoutesArr([
        protectMe(
          routerLinks.salesPage,
          <SaleInvoiceProvider>
            <SaleInvoicesPage />
          </SaleInvoiceProvider>,
          'sale_invoice_route_key',
          routerLinks.signInPage,
          loggedIn,
          user?.expire
        ),
        protectMe(
          routerLinks.salesPageReports,
          <SaleInvoiceProvider>
            <SaleInvoicesPage />
          </SaleInvoiceProvider>,
          'sale_invoice_reports_key',
          routerLinks.signInPage,
          loggedIn,
          user?.expire
        ),
        protectMe(
          routerLinks.shiftPageReports,
          <SaleInvoiceProvider>
            <ShiftReportsPage />
          </SaleInvoiceProvider>,
          'shift_reports_key',
          routerLinks.signInPage,
          loggedIn,
          user?.expire
        ),
        protectMe(
          salesRouterLinks?.priceOfferRouteNew,
          <IncomingPurchaseInvoiceProvider>
            <PriceOfferPageNew />
          </IncomingPurchaseInvoiceProvider>,
          'price_offer_page_new_key',
          routerLinks.signInPage,
          loggedIn,
          user?.expire
        ),
        protectMe(
          routerLinks.clientPage,
          <ClientsProvider>
            <ClientsPage />
          </ClientsProvider>,
          'clients_page_key',
          routerLinks.signInPage,
          loggedIn,
          user?.expire
        )
      ]);
    } else {
      setRoutesArr([
        protectMe(
          routerLinks.salesPage,
          <SaleInvoiceProvider>
            <SaleInvoicesPage />
          </SaleInvoiceProvider>,
          1,
          routerLinks.signInPage,
          loggedIn,
          user?.expire
        ),
        protectMe(
          routerLinks.customerPaymentsPage,
          <SaleInvoiceProvider>
            <CustomerPaymentsPage />
          </SaleInvoiceProvider>,
          6565,
          routerLinks.signInPage,
          loggedIn,
          user?.expire
        ),
        protectMe(
          routerLinks.clientPaymentsPage(),
          <SaleInvoiceProvider>
            <ClientPaymentsPage />
          </SaleInvoiceProvider>,
          6565,
          routerLinks.signInPage,
          loggedIn,
          user?.expire
        ),
        protectMe(
          routerLinks.salesPageReports,
          <SaleInvoiceProvider>
            <SaleInvoicesPage />
          </SaleInvoiceProvider>,
          10909090,
          routerLinks.signInPage,
          loggedIn,
          user?.expire
        ),
        protectMe(
          routerLinks.shiftPageReports,
          <ShiftReportsProvider>
            <ShiftReportsPage />
          </ShiftReportsProvider>,
          12121212,
          routerLinks.signInPage,
          loggedIn,
          user?.expire
        ),
        protectMe(
          routerLinks.clientPage,
          <ClientsProvider>
            <ClientsPage />
          </ClientsProvider>,
          2,
          routerLinks.signInPage,
          loggedIn,
          user?.expire
        ),
        protectMe(
          routerLinks.shiftsPage,
          <ShiftsProvider>
            <ShiftsPage />
          </ShiftsProvider>,
          3,
          routerLinks.signInPage,
          loggedIn,
          user?.expire
        ),
        protectMe(
          salesRouterLinks?.priceOfferRouteNew,
          <IncomingPurchaseInvoiceProvider>
            <PriceOfferPageNew />
          </IncomingPurchaseInvoiceProvider>,
          'price_offer_page_new_key',
          routerLinks.signInPage,
          loggedIn,
          user?.expire
        )
      ]);
    }
  }, [user]);

  return routesArr;
};

export default SalesRoutes;
