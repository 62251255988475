/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import getAllUnits from '../apis/settings-apis/measuremnet-units-apis/getAllUnits';
import UserContext from '../contexts/user-context/UserProvider';
import useCustomApiRequest from './useCustomApiRequest';
import SettingsMeasurementUnitsContext from '../contexts/settings-contexts/SettingsMeasurementUnitsPage';

const useUnits = () => {
  const [allUnits, setAllUnits] = useState(null);
  const { user } = useContext(UserContext);
  const { fetchCount } = useContext(SettingsMeasurementUnitsContext);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getAllUnits(user?.token),
        (res) => {
          if (res?.status === 200 && res?.data?.data?.length > 0) {
            const data = res.data.data;
            setAllUnits(data);
          } else {
            //
          }
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  return allUnits;
};

export default useUnits;
