import axios from 'axios';

const getDeferredInvoicesApi = async (token, user_id, type, invoice_type) => {
  const url = `/payments/getDeferredInvoices?user_id=${
    user_id ? user_id : null
  }&type=${type ? type : null}&invoice_type=${
    invoice_type ? invoice_type : null
  }`;
  try {
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });

    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export default getDeferredInvoicesApi;
