/* eslint-disable eqeqeq */
import React from 'react';
import { useTranslation } from 'react-i18next';
import WaterMark from '../../../common/water-mark/WaterMark';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import './PosDetailsComponent.scss';

const KitchenDetailsComponent = React.forwardRef((props, ref) => {
  const { selectedReceipt } = props;
  const systemSettings = useSystemSettings();
  const { t } = useTranslation();

  const renderProducts = () => {
    return (
      <div className="products-table-total-wrap">
        <table
          className="invoice-products-table"
          style={{
            fontFamily: 'DINNEXTLTARABIC-Regular',
            width: 'calc(100% - 36px)',
            margin: '0 10px',
            padding: 0,
            textAlign: 'right',
            borderCollapse: 'separate',
            borderCpacing: '4px 10px'
          }}
        >
          <thead>
            <tr className="invoice-products-head">
              <th
                style={{
                  minWidth: '118px',
                  padding: '2px',
                  fontSize: '15px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-name-cell"
              >
                الصنف <br />
                Category
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '15px',
                  fontWeight: 'normal',
                  color: '#000',
                  textAlign: 'center'
                }}
                className="invoice-table-cell product-quantity-cell"
              >
                كمية <br />
                Quantity
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '15px',
                  fontWeight: 'normal',
                  color: '#000',
                  textAlign: 'center'
                }}
                className="invoice-table-cell product-price-cell"
              >
                السعر <br />
                Price
              </th>
              {/* <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-price-cell"
              >
                المجموع
              </th> */}
            </tr>
          </thead>
          <tbody>
            {selectedReceipt?.products?.length > 0 &&
              selectedReceipt.products.map((p) => renderInoviceProduct(p))}
          </tbody>
        </table>
        {selectedReceipt.addPrices?.length > 0 &&
          selectedReceipt.addPrices[0].desc &&
          parseFloat(selectedReceipt.addPrices[0].price) > 0 && (
            <table
              className="prices-table"
              style={{
                width: 'calc(100% - 36px)',
                margin: '0 10px',
                padding: 0,
                borderTop: '1px dashed #000',
                textAlign: 'right',
                borderCollapse: 'separate',
                borderSpacing: '4px 8px',
                color: '#000'
              }}
            >
              <tbody>
                {selectedReceipt.addPrices.map((extra, index) =>
                  renderInvoiceExtraPrice(extra, index)
                )}
              </tbody>
            </table>
          )}
        {/* <table
          className="prices-table"
          style={{
            width: 'calc(100% - 36px)',
            borderTop: '1px dashed #000',
            margin: 'auto',
            textAlign: 'right',
            borderCollapse: 'separate',
            borderSpacing: '4px 8px',
            color: '#000'
          }}
        >
          <tbody>
            {selectedReceipt.productPrice > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  السعر الإجمالى للمنتجات :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.productPrice}
                </td>
              </tr>
            )}
            {selectedReceipt.discountPrice > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px 4px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  سعر الخصم :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.discountPrice}
                </td>
              </tr>
            )}
            {selectedReceipt.additionPrice > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  اسعار اضافية :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.additionPrice}
                </td>
              </tr>
            )}
            {selectedReceipt.totalBeforeTax > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  الإجمالى قبل الضريبة :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.totalBeforeTax}
                </td>
              </tr>
            )}
            {selectedReceipt.taxPrice > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  سعــر الضريبة :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.taxPrice}
                </td>
              </tr>
            )}
            {selectedReceipt.totalPrice > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  السعر الإجمالى :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.totalPrice}
                </td>
              </tr>
            )}
            {selectedReceipt.returnPrice &&
              parseFloat(selectedReceipt.returnPrice) > 0 && (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px',
                      width: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    المبلغ المرتجع :{' '}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px' }}
                    className="invoice-table-cell discount-value"
                  >
                    {selectedReceipt.returnPrice}
                  </td>
                </tr>
              )}
            {selectedReceipt.totalPrice > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  طريقة الدفــع :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.paymentType == 1 && 'كاش'}
                  {selectedReceipt.paymentType == 2 && 'آجل'}
                  {selectedReceipt.paymentType == 3 && 'شبكة'}
                  {selectedReceipt.paymentType == 5 && 'تحويل بنكي'}
                  {selectedReceipt.paymentType == 6 && 'دفع متعدد'}
                </td>
              </tr>
            )}
            {selectedReceipt.payments?.length > 0 &&
              selectedReceipt.payments?.map((pay, index) => (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px',
                      width: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    {`الدفعه رقم ${index + 1} :`}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px', color: '#000' }}
                    className="invoice-table-cell discount-value"
                  >
                    {pay?.amount}
                  </td>
                </tr>
              ))}

            {selectedReceipt.paymentType === 2 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  المتبقي :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.rest}
                </td>
              </tr>
            )}
            {selectedReceipt.paymentType === 6 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  الكاش :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.cash}
                </td>
              </tr>
            )}
            {selectedReceipt.paymentType === 6 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  الشبكة :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.visa}
                </td>
              </tr>
            )}
            {selectedReceipt.returnTax &&
              parseFloat(selectedReceipt.returnTax) > 0 && (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px',
                      width: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    الضريبة المرتجعة :{' '}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px' }}
                    className="invoice-table-cell discount-value"
                  >
                    {selectedReceipt.returnTax}
                  </td>
                </tr>
              )}
          </tbody>
        </table> */}
        {/* {invoiceTotalPriceAfterTax > 0 && (
          <div className="grand-total-invoice-price">
            <span>المجموع الكلى</span>
            <span className="bold-font">{invoiceTotalPriceAfterTax}</span>
          </div>
        )} */}

        {/* <div className="qr-code-img-wrap">
          <img className="qrcode-img" src={qrCodeImg} alt="qrcode" />
        </div> */}
        {/* <div
          className="qr-code-img-wrap"
          style={{
            display: 'grid',
            placeItems: 'center',
            padding: '18px',
            paddingTop: '0px',
            marginTop: '8px',
            borderBottom: '1px dashed rgba(0, 0, 0, 0.1)',
            marginBottom: 8
          }}
        >
          <QRCode
            value={selectedReceipt?.qrString ? selectedReceipt.qrString : ''}
            width={92}
            height={92}
            renderAs="svg"
          />
        </div> */}

        {/* <div
          style={{
            textAlign: 'center',
            padding: '0 8px 10px 8px'
          }}
        >
          {selectedReceipt?.setting?.slogan ? selectedReceipt.setting.slogan : ''}
        </div> */}
      </div>
    );
  };

  const renderInoviceProduct = (p) => {
    if (p?.adds?.length > 0) {
      return (
        <tr key={p.id} className="invoice-product-li">
          <td
            style={{ padding: '2px', fontSize: '13px', color: '#000' }}
            className="invoice-table-cell product-name-cell"
          >
            {p?.name ? p?.name : p?.product?.name}
            {p?.adds.map((ing) =>
              ing.name ? (
                <div key={ing.id}> == ( إضافة {ing.name} )</div>
              ) : (
                '---'
              )
            )}
          </td>
          <td
            style={{ padding: '2px', color: '#000' }}
            className="invoice-table-cell product-quantity-cell"
          >
            {p.quantity ? p.quantity : '----'}
            {p.adds?.length > 0 &&
              p.adds.map((ing) =>
                ing.quantity ? <div key={ing.id}>{ing.quantity}</div> : '---'
              )}
          </td>
          <td
            style={{ padding: '2px', color: '#000' }}
            className="invoice-table-cell product-price-cell"
          >
            {p.price ? parseFloat(p.price) : '----'}
            {p.adds.map((ing) =>
              String(ing.priceOfUnit) ? (
                <div key={ing.id}>{parseFloat(ing.priceOfUnit)}</div>
              ) : (
                '---'
              )
            )}
          </td>
          <td
            style={{ padding: '2px', color: '#000' }}
            className="invoice-table-cell product-price-cell"
          >
            {p.priceBeforeDiscount ? p.priceBeforeDiscount : '----'}
            {p.adds?.length > 0 &&
              p.adds.map((ing) =>
                String(ing.price) ? (
                  <div key={ing.id}>{parseFloat(ing.price)}</div>
                ) : (
                  '---'
                )
              )}
          </td>
        </tr>
      );
    }
    return (
      <tr key={p.id} className="invoice-product-li">
        <td
          style={{
            padding: '2px',
            color: '#000',
            width: '150px',
            textWrap: 'wrap',
            wordBreak: 'break-word'
          }}
          className="invoice-table-cell product-name-cell"
        >
          {p?.name ? p?.name : p?.product?.name}
        </td>
        <td
          style={{ padding: '2px', color: '#000', textAlign: 'center' }}
          className="invoice-table-cell product-quantity-cell"
        >
          {p.quantity ? p.quantity : '----'}
        </td>
        <td
          style={{ padding: '2px', color: '#000', textAlign: 'center' }}
          className="invoice-table-cell product-price-cell"
        >
          {selectedReceipt?.type == 2 &&
            p.price &&
            parseFloat(p.price) > 0 &&
            '-'}
          {p.price ? parseFloat(p.price) : '----'}
        </td>
        {/* <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-price-cell"
        >
          {p.priceBeforeDiscount ? p.priceBeforeDiscount : ''}
        </td> */}
      </tr>
    );
  };

  const renderInvoiceExtraPrice = (extraPriceObj, index) => {
    return (
      <tr key={index} className="invoice-product-li">
        <th
          className="invoice-table-cell col-head extra-prices-cell"
          style={{
            fontWeight: 'normal',
            padding: '2px 4px',
            width: '194px',
            color: '#000'
          }}
        >
          {extraPriceObj.desc ? extraPriceObj.desc : ''}
        </th>
        {/* <td className="style={{padding: '2px 4px', , color: '#000', width: '70px'}} fake-td invoice-table-cell">
          ====
        </td> */}
        <td
          style={{ padding: '2px 4px', textAlign: 'center' }}
          className="invoice-table-cell product-quantity-cell extra-prices-cell"
        >
          {extraPriceObj.price ? extraPriceObj.price : ''}
        </td>
      </tr>
    );
  };

  return (
    <div
      ref={ref}
      className="print-pos-whole-receipt-wrap"
      style={{
        position: 'relative'
      }}
    >
      {/* <p
        style={{
          fontSize: 16,
          color: '#000',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: 6
        }}
      >
        {selectedReceipt?.setting?.ref_id}
      </p> */}
      {systemSettings?.package_id == 1 && <WaterMark text={'تجربة مجانية'} />}

      <div
        className="pos-receipt-wrap"
        style={{
          fontFamily: 'DINNEXTLTARABIC-Regular',
          height: 'max-content',
          color: '#000'
        }}
      >
        <div
          style={{
            fontSize: 16,
            color: '#000',
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: 6
          }}
        >
          {selectedReceipt?.is_return == 1 ? <p> فاتورة مرتجعة</p> : null}
          <span>{t('Invoices.KitchenInvoice')}</span>
          {/* <span> - {selectedReceipt?.setting?.id}</span> */}
        </div>
        <div
          className="store-name"
          style={{
            fontSize: 16,
            color: '#000',
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: 6
          }}
        >
          {selectedReceipt?.setting?.name ? selectedReceipt?.setting.name : ''}
        </div>

        <div
          className="store-name"
          style={{
            fontSize: 16,
            color: '#000',
            fontWeight: 'bold',
            marginBottom: 6,
            padding: ' 5px 20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <h3>كـاشـيـر</h3>
          <h3>Cashier</h3>
        </div>
        {/* <div
          className="store-logo-name-wrap"
          style={{
            display: 'grid',
            placeItems: 'center',
            minWidth: '92px',
            minHeight: '92px'
          }}
        >
          {selectedReceipt?.setting?.image ? (
            <img
              className="system-logo"
              src={selectedReceipt?.setting.image}
              alt="logo"
              style={{
                // width: '92px',
                height: '92px'
              }}
            />
          ) : null}


        </div> */}

        <div className="invoice-content">
          {/* <div
                className="invoice-number"
                style={{
                  marginTop: '2px'
                }}
              >
                <p
                  className="number-span"
                  style={{
                    padding: '0 4px',
                    textAlign: 'center'
                  }}
                >
                  كود : {selectedReceipt?.id ? selectedReceipt.id : ''}
                </p>
              </div> */}
          {/* <div className="invoice-number">
            <p className="number-span" style={{ color: '#000' }}>
              رقم الفاتورة :{' '}
              {selectedReceipt?.invoiceNumber
                ? '00' + selectedReceipt.invoiceNumber
                : ''}
            </p>
          </div> */}
          <div
            className="system-info"
            style={{
              margin: '4px 10px',
              paddingTop: '12px',
              paddingBottom: '12px',
              marginBottom: '0',
              borderBottom: '1px dashed #000',
              borderTop: '1px dashed #000',
              gap: '12px 4px',
              color: '#000',
              fontSize: '13px'
            }}
          >
            {/* {selectedReceipt?.setting?.address && (
              <div className="cell-wrap address-wrap">
                <span className="info-cell-title">عنوان المتجر : </span>
                {selectedReceipt?.setting?.address && (
                  <span className="info-cell-value">
                    {selectedReceipt?.setting.address}
                  </span>
                )}
              </div>
            )}
            <div className="cell-wrap vat-wrap">
              <span className="info-cell-title">رقم ضريبى : </span>
              <span className="info-cell-value">
                {selectedReceipt?.setting?.tax_number
                  ? selectedReceipt?.setting.tax_number
                  : ''}
              </span>
            </div>
            <div className="cell-wrap vat-wrap">
              <span className="info-cell-title">رقم السجل التجارى : </span>
              <span className="info-cell-value">
                {selectedReceipt?.setting?.commercialRecord
                  ? selectedReceipt?.setting.commercialRecord
                  : ''}
              </span>
            </div> */}
            <div className="date-time-wrap">
              <div className="cell-wrap date-wrap">
                <span className="info-cell-title">التاريخ : </span>
                <span className="info-cell-value">
                  {selectedReceipt.date ? selectedReceipt.date : ''}
                </span>
              </div>
              <div className="cell-wrap time-wrap">
                <span className="info-cell-title" style={{ fontSize: '13' }}>
                  الوقت :{' '}
                </span>
                <span className="info-cell-value">
                  {selectedReceipt?.time ? selectedReceipt.time : ''}
                </span>
              </div>
            </div>
          </div>

          {renderProducts()}
        </div>
      </div>
    </div>
  );
});

export default KitchenDetailsComponent;
