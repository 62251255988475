import { Button, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import MyDropZonePreview from '../../common/dorp-zone/MyDropZonePreview';
import MyDropZone from '../../common/dorp-zone/MyDropZone';
import GenerateSubBarCode from './GenerateSubBarCode';
import AntdTextPosField from '../../common/antd-form-components/AntdTextPosField';
import useWarehouses from '../../custom-hooks/useWarehouses';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
import { CameraFilled, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const HandleMultiImage = (props) => {
  const { t } = useTranslation();
  const [productFileToUpload, setProductFileToUpload] = useState(
    props.watch(`multi_image${props.index}`)
      ? props.watch(`multi_image${props.index}`)
      : null
  );
  useEffect(() => {
    props.setValue(`multi_image${props.index}`, productFileToUpload);
  }, [productFileToUpload, productFileToUpload?.length]);

  useEffect(() => {
    props.register(`multi_image${props.index}`, {
      required: t('Inventory.Products.pleaseChooseProductImageFirst')
    });

    return () => props.unregister(`multi_image${props.index}`);
  }, []);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handleFilesDrop = async (acceptedFiles, formFiles, setFormFiles) => {
    const duplicates = [];
    try {
      for (let i of acceptedFiles) {
        if (formFiles?.length) {
          for (let f of formFiles) {
            if (i.name === f.name) {
              duplicates.push(i);
            }
          }
        }
      }
      for (let i = 0; i < acceptedFiles.length; i++) {
        for (let f of duplicates) {
          if (acceptedFiles[i].name === f.name) {
            acceptedFiles.splice(i, 1);
          }
        }
      }

      acceptedFiles.forEach(async (file) => {
        const preview = await getBase64(file);
        Object.assign(file, {
          preview
        });
        // setFormFiles((prevState) => [...prevState, file]);
        if (formFiles?.length > 0) {
          setFormFiles((currState) => [...currState, file]);
        } else {
          setFormFiles([file]);
        }
      });
    } catch (error) {
    }
  };
  const clearFileFromUpload = (file, setFilesToUpload) => {
    setFilesToUpload((prevState) => {
      const filtered = prevState.filter((asset) => asset.name !== file.name);
      if (filtered?.length === 0) return null;
      return filtered;
    });
  };

  return (
    <div className="product-img-wrap" style={{ width: '100%' }}>
      <h3>{t('Inventory.Products.productImage')}</h3>
      <MyDropZone
        className="product-header-dropzone"
        multipleFiles={false}
        handleFilesDrop={(acceptedFiles) => {
          handleFilesDrop(
            acceptedFiles,
            productFileToUpload,
            setProductFileToUpload
          );
        }}
        filesToUpload={productFileToUpload}
        formName="store-new-product-form"
        id="avatar-input"
        dropzoneText={t('Inventory.Products.productImage')}
        inputName={`multi_image${props.index}`}
        icon={<CameraFilled className="dropzone-camera" />}
        dropZoneUrls={
          props.selectedProduct?.image ? [props.selectedProduct.image] : null
        }
      >
        {t('Inventory.Products.dragTheImageAndPlaceItHere')}
      </MyDropZone>

      <MyDropZonePreview
        filesToUpload={productFileToUpload}
        clearFileFromUpload={(f) =>
          clearFileFromUpload(f, setProductFileToUpload)
        }
      />
      {props.errors?.image &&
        props.errors.multiProducts[props.index]?.image &&
        !props.watch(`multiProducts.${props.index}.image`) && (
          <p className="error-p">
            {props.errors.multiProducts[props.index]?.image?.message}
          </p>
        )}
    </div>
  );
};

export default HandleMultiImage;
