import { Popconfirm, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import deleteClientApi from '../../apis/clients-apis/deleteClientApi';
import DeleteIcon from '../../common/icons/DeleteIcon';
import EditIcon from '../../common/icons/EditIcon';
import errorNotification from '../../utils/errorNotification';
import successNotification from '../../utils/successNotification';

const TableColumnsProducts = (
  allFetchedClients,
  setModalOpened,
  setFetchCount,
  setSelectedClient,
  setIsLoading,
  token
) => {
  const { t } = useTranslation();

  const handleEditUser = (record) => {
    const found = allFetchedClients.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedClient({ ...found });
      setModalOpened(true);
    }
  };
  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteClientApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Messages.addedSuccessfully'),
          message: t('Messages.DeletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Messages.ErrorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Messages.TryAgainLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Messages.ErrorOccurred'),
        message: t('Messages.TryAgainLater')
      });
    }
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditUser(record)}>
          <EditIcon />
          {t('Clients.edit')}
        </div>
        <Popconfirm
          title={t('Messages.areyouSureToDelete')}
          onConfirm={() => handleDeleteRequest(record.id)}
          okText={t('Messages.yes')}
          cancelText={t('Messages.no')}
        >
          <Tooltip title={t('Clients.DeleteClient')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      // title: 'الكود',
      title: t('Clients.ClientCode'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      // title: 'إسم العميل',
      title: t('Clients.ClientName'),

      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record.name}</span>
          </div>
        );
      },
      sorter: (a, b) => {
        let nameA = a.name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }
    },
    {
      // title: 'البريد الاكترونى',
      title: t('Clients.Email'),

      dataIndex: 'email',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record.email || '----'}</span>
          </div>
        );
      }
    },
    {
      // title: 'رقم الهاتف',
      title: t('Clients.Phone'),

      dataIndex: 'phone',
      // width: 192,
      render: (_, record) => {
        return <div className="row-cell row-text">{record.phone}</div>;
      }
    },
    {
      // title: 'الاعدادات',
      title: t('Clients.Settings'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default TableColumnsProducts;
