import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  modalOpened: false,
  setModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  selectedCalendar: null,
  setSelectedCalendar: (v) => {},
  allFetchedReservations: [],
  setAllFetchedReservations: (v) => {},
  isLoading: false,
  setIsLoading: (v) => {}
};

const ReservationContext = createContext(INITIAL_VALUES);

export const ReservationProvider = ({ children }) => {
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [selectedCalendar, setSelectedCalendar] = useState(
    INITIAL_VALUES.selectedCalendar
  );
  const [allFetchedReservations, setAllFetchedReservations] = useState(
    INITIAL_VALUES.allFetchedReservations
  );
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  return (
    <ReservationContext.Provider
      value={{
        modalOpened,
        setModalOpened,
        fetchCount,
        setFetchCount,
        selectedCalendar,
        setSelectedCalendar,
        allFetchedReservations,
        setAllFetchedReservations,
        isLoading,
        setIsLoading
      }}
    >
      {children}
    </ReservationContext.Provider>
  );
};

export default ReservationContext;
