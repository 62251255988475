import { useState, createContext } from 'react';
const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  allFetchedInventories: null,
  setAllFetchedInventories: (v) => {},
  selectedInventory: null,
  setSelectedInventory: (v) => {},
  selectedReceiptModalOpened: false,
  setSelectedReceiptModalOpened: (v) => {},
  selectedReceipt: null,
  setSelectedReceipt: (v) => {},
  allFetchedReceipts: null,
  setAllFetchedReceipts: (v) => {}
  //
};

const StoreInventoryContext = createContext(INITIAL_VALUES);

export const StoreInventoryProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [selectedInventory, setSelectedInventory] = useState(
    INITIAL_VALUES.selectedInventory
  );
  const [allFetchedInventories, setAllFetchedInventories] = useState(
    INITIAL_VALUES.allFetchedInventories
  );
    const [selectedReceiptModalOpened, setSelectedReceiptModalOpened] =
      useState(INITIAL_VALUES.selectedReceiptModalOpened);
        const [selectedReceipt, setSelectedReceipt] = useState(
          INITIAL_VALUES.selectedReceipt
        );
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
    const [allFetchedReceipts, setAllFetchedReceipts] = useState(
      INITIAL_VALUES.allFetchedReceipts
    );
  return (
    <StoreInventoryContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fetchCount,
        setFetchCount,
        modalOpened,
        setModalOpened,
        allFetchedInventories,
        setAllFetchedInventories,
        selectedInventory,
        setSelectedInventory,
        selectedReceiptModalOpened,
        setSelectedReceiptModalOpened,
        allFetchedReceipts,
        setAllFetchedReceipts,
        selectedReceipt,
        setSelectedReceipt
      }}
    >
      {children}
    </StoreInventoryContext.Provider>
  );
};

export default StoreInventoryContext;
