/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import UserIcon from '../../common/icons/UserIcon';
import PhoneIcon from '../../common/icons/PhoneIcon';
import EmailIcon from '../../common/icons/EmailIcon';
import LocationIcon from '../../common/icons/LocationIcon';
import { useContext } from 'react';
import { useEffect } from 'react';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import UserContext from '../../contexts/user-context/UserProvider';
import ClientsContext from '../../contexts/clients-context/ClientsContext';
import ceateNewClientApi from '../../apis/clients-apis/createNewClientApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import createListIntegrationApi from '../../apis/fatoorahStore-api/createListIntegrationApi';
import AuthWithSallaStore from '../../apis/fatoorahStore-api/AuthWithSallaStore';
import { store } from 'react-notifications-component';
import SaveSallaToken from '../../apis/fatoorahStore-api/SaveTokenApi';
import AuthWithZidStore from '../../apis/fatoorahStore-api/AuthWithZidStore';
import AuthWithZapierStore from '../../apis/fatoorahStore-api/AuthWithZapierStore';
import { useTranslation } from 'react-i18next';

const TableForm = () => {
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    name: Yup.string().required(t('ShopInvoice.EnterCustomerName'))
    // app_key: Yup.string().required('ادخل ApiKey العميل'),
    // secret_key: Yup.string().required('ادخل SecretKey العميل')

    // buildingNum: Yup.string()
    //   .required('ادخل رقم المبنى')
    //   .matches(/^[0-9]+$/, 'رقم المبنى ارقام فقط'),
    // taxNumber: Yup.string()
    //   .required('ادخل الرقم الضريبى')
    //   .matches(/^[0-9]+$/, 'لا يسمح الا بكتابة الارقام')
  });
  const { user } = React.useContext(UserContext);
  // const [clientName , setClientName] = useState('')
  const [btnIsLoading, setBtnIsLoading] = useState(false);
  const {
    setIsLoading,
    isLoading,
    setModalOpened,
    setFetchCount,
    selectedClient,
    isSubmittingForm,
    setIsSubmittingForm,
    setClientName,
    clientName
  } = useContext(ClientsContext);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      app_key: '',
      secret_key: '',
      client_id: ''
    }
  });

  // handle initial values
  useEffect(() => {
    if (selectedClient) {
      if (selectedClient?.name) {
        setValue('name', String(selectedClient.name));
      }
      // if (selectedClient?.app_key) {
      //   setValue('app_key', selectedClient.app_key);
      // }
      // if (selectedClient?.secret_key) {
      //   setValue('secret_key', selectedClient.secret_key);
      // }
    }
  }, [selectedClient]);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    const mappedData = {};
    mappedData.client_id = user?.client_id;
    if (data.name) mappedData.name = data.name;
    if (data.app_key) mappedData.app_key = data.app_key;
    if (data.secret_key) mappedData.secret_key = data.secret_key;

    const successCallback = (res) => {
      setIsSubmittingForm(false);
      setIsLoading(false);
      if (res?.data?.data) {
        setModalOpened(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title:t('Messages.addedSuccessfully'),
          message: t('Messages.clientAdded')
        });
      } else {
        errorNotification({
          title: t('Messages.ErrorOccurred'),
          message: res?.data?.message ? res.data.message : t('Messages.pleaseTryLater')
        });
      }
    };
    const errorCallback = (error) => {
      setIsSubmittingForm(false);
      setIsLoading(false);
      errorNotification({
        title: t('Messages.ErrorOccurred'),
        message: t('Messages.pleaseTryLater')
      });
    };
    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedClient) {
      mappedData.id = selectedClient.id;
      customApiRequest(
        createListIntegrationApi(user?.token, mappedData, true, mappedData.id),
        successCallback,
        errorCallback
      );
    } else {
      customApiRequest(
        createListIntegrationApi(user?.token, mappedData),
        successCallback,
        errorCallback
      );
    }
  };


  const handleAuthSallaStore = async () => {
    customApiRequest(
      AuthWithSallaStore(user?.token,'salla'),
      (res) => {
        setBtnIsLoading(true);
        if (res?.data?.status === 'failed' || res?.data?.status === false) {
          errorNotification({
            title: t('Messages.linkingError'),
            message: t('Messages.incorrectData')
          });
        } else if (res?.data?.status === 'success') {
          let url = res?.data?.url;
          window.open(url, "_blank", "noreferrer");
          setBtnIsLoading(false);
          // store.addNotification({
          //   title: 'تم الربط بنجاح',
          //   message: "---------",
          //   type: 'success',
          //   insert: 'top',
          //   container: 'top-right',
          //   animationIn: ['animate__animated', 'animate__fadeIn'],
          //   animationOut: ['animate__animated', 'animate__fadeOut'],
          //   dismiss: {
          //     duration: 3000,
          //     showIcon: true,
          //     onScreen: true
          //   }
          // });
        } else {
          setBtnIsLoading(false);
          errorNotification({
            title:  t('Messages.linkingError'),
            message:  t('Messages.tryAgain')
            // message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
          });
        }
      },
      (error) => {
        errorNotification({
          title: t('Messages.linkingError'),
          message:  t('Messages.tryAgain')
          // message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
        });
      }
    );
  };
  const handleAuthZidStore = async () => {
    customApiRequest(
      AuthWithZidStore(user?.token , 'zid'),
      (res) => {
        setBtnIsLoading(true);
        if (res?.data?.status === 'failed' || res?.data?.status === false) {
          errorNotification({
            title:  t('Messages.linkingError'),
            message: t('Messages.incorrectData')
          });
          setBtnIsLoading(false);
        } else if (res?.data?.status === 'success') {
          setBtnIsLoading(false);
          window.location.href = res?.data?.url;
          // store.addNotification({
          //   title: 'تم الربط بنجاح',
          //   message: "---------",
          //   type: 'success',
          //   insert: 'top',
          //   container: 'top-right',
          //   animationIn: ['animate__animated', 'animate__fadeIn'],
          //   animationOut: ['animate__animated', 'animate__fadeOut'],
          //   dismiss: {
          //     duration: 3000,
          //     showIcon: true,
          //     onScreen: true
          //   }
          // });
        } else {
          setBtnIsLoading(false);
          errorNotification({
            title: t('Messages.linkingError'),
            message:  t('Messages.tryAgain')
            // message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
          });
        }
      },
      (error) => {
        errorNotification({
          title:  t('Messages.linkingError'),
          message:  t('Messages.tryAgain')
          // message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
        });
      }
    );
  };
  const handleAuthZapierStore = async () => {
    customApiRequest(
      AuthWithZapierStore(),
      (res) => {
        setBtnIsLoading(true);
        if (res?.data?.status === 'failed' || res?.data?.status === false) {
          errorNotification({
            title: t('Messages.linkingError'),
            message: t('Messages.incorrectData')
          });
          setBtnIsLoading(false);
        } else if (res?.data?.status === 'success') {
          window.location.href = res?.data?.url;
          // store.addNotification({
          //   title: 'تم الربط بنجاح',
          //   message: "---------",
          //   type: 'success',
          //   insert: 'top',
          //   container: 'top-right',
          //   animationIn: ['animate__animated', 'animate__fadeIn'],
          //   animationOut: ['animate__animated', 'animate__fadeOut'],
          //   dismiss: {
          //     duration: 3000,
          //     showIcon: true,
          //     onScreen: true
          //   }
          // });
          setBtnIsLoading(false);
        } else {
          setBtnIsLoading(false);
          errorNotification({
            title:t('Messages.linkingError'),
            message:  t('Messages.tryAgain')
            // message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
          });
        }
      },
      (error) => {
        errorNotification({
          title: t('Messages.linkingError'),
          message:  t('Messages.tryAgain')
          // message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
        });
      }
    );
  };

  const [form] = Form.useForm();

  return (
    <Form
      className="store-new-supplier-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="select-label-wrap">
          <p className="label-p">{t('ShopInvoice.StoreType')}</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="name"
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder={t('ShopInvoice.StoreName')}
              options={[
                { title: 'Zid', value: 'Zid' },
                { title: 'Salla', value: 'Salla' },
                { title: 'Zapier', value: 'Zapier' }
              ]}
              onChange={(e) => setClientName(e)}
              formClassName="store-new-supplier-form"
            />
          </div>
        </div>
        {/* <div className="text-field-label-wrap">
          <p className="label-p">App key</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="app_key"
              type="text"
              placeholder="App key..."
              errorMsg={errors?.app_key?.message}
              validateStatus={errors?.app_key ? 'error' : ''}
              control={control}
              // prefix={<PhoneIcon />}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">Secret key</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="secret_key"
              type="text"
              placeholder="Secret key..."
              errorMsg={errors?.secret_key?.message}
              validateStatus={errors?.secret_key ? 'error' : ''}
              control={control}
              // prefix={<PhoneIcon />}
            />
          </div>
        </div> */}

        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={isSubmittingForm}
          >
            {selectedClient ? t('ShopInvoice.edit') : t('ShopInvoice.addition')}
          </Button>
          {clientName === 'Zid' && (
            <Button
              className="submit-btn"
              // htmlType="submit"
              type="primary"
              // icon={<LoginOutlined />}
              loading={btnIsLoading}
              onClick={() => handleAuthZidStore()}
            >
              {t('ShopInvoice.connectWithZid')}
            </Button>
          )}
          {clientName === 'Salla' && (
            <Button
              className="submit-btn"
              type="primary"
              // icon={<LoginOutlined />}
              loading={btnIsLoading}
              // htmlType="submit"
              onClick={() => handleAuthSallaStore()}
            >
              {t('ShopInvoice.connectWithSalla')}
            </Button>
          )}
          {clientName === 'Zapier' && (
            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              // icon={<LoginOutlined />}
              loading={btnIsLoading}
              onClick={() => handleAuthZapierStore()}
            >
              {t('ShopInvoice.connectWithZapier')}
            </Button>
          )}
        </div>
      </div>
    </Form>
  );
};

export default TableForm;
