import axios from 'axios';

const createAccountingLeafApi = async (token, values, url , edit = false) => {
  // console.log(token)
  const formData = new FormData();
  const keys = Object.keys(values)
  keys.forEach(elem => {
    formData.append(elem,values[elem])
  });
  try {
    const res = await axios({
      method: 'post',
      url,
      baseURL: process.env.REACT_APP_BASE_TEST_URL,
      // baseURL: 'https://testclient-api.fatoorah.sa/apiAdmin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      },
      data:formData
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default createAccountingLeafApi;

//  successNotification({
//    title: t('Accounts.Emptytreecreatedsuccessfully'),
//    message: 'successMessage'
//  });
//setIsLoading(false);
//    errorNotification({
//      title: t('Messages.ErrorOccurred'),
//      message: res?.data?.message || t('Messages.TryAgainLater')
//    });