import CreateSaleInvoicePosApi from '../../apis/pos-api/sale-invoice-pos-api/CreateSaleInvoicePosApi';
import errorNotification from '../../utils/errorNotification';
import successNotification from '../../utils/successNotification';

// if (invoicePayType == 5) {
//   if (invoiceBankPayment.file.length) {
//     requestData.bank = invoiceTotalAfterExtraPrices;
//     requestData.bank_image = invoiceBankPayment.file[0];
//     setInvoiceBankPayment((prv) => ({ ...prv, errors: false }));
//     valid = true;
//   } else {
//     setInvoiceBankPayment((prv) => ({ ...prv, errors: true }));
//     valid = false;
//   }
// }

// if (invoicePayType == 6) {
//   if (
//     !invoiceMultiplePaymentCash?.errors?.message &&
//     !invoiceMultiplePaymentVisa?.errors?.message &&
//     invoiceMultiplePaymentVisa.value &&
//     invoiceMultiplePaymentCash.value
//   ) {
//     requestData.visa = invoiceMultiplePaymentVisa.value;
//     requestData.cash = invoiceMultiplePaymentCash.value;
//     valid = true;
//   } else {
//     valid = false;
//   }
// }

// if (invoicePayType == 2) {
//   if (
//     // !invoiceMultiplePaymentCash?.errors?.message &&
//     // !invoiceMultiplePaymentVisa?.errors?.message &&
//     invoiceDeferredPayment.invoice_due_date
//   ) {
//     requestData.deferred = invoiceTotalAfterExtraPrices;
//     requestData.invoice_due_date = invoiceDeferredPayment.invoice_due_date;
//     setInvoiceDeferredPayment((prv) => ({ ...prv, errors: false }));
//     valid = true;
//   } else {
//     setInvoiceDeferredPayment((prv) => ({ ...prv, errors: true }));
//     valid = false;
//   }
// }

const invoiceSubmitFunction = async (
  user,
  requestData,
  setIsSubmittingOrder,
  setSucessOrder
) => {
  try {
    setIsSubmittingOrder(true);
    let res;
    res = await CreateSaleInvoicePosApi(
      user?.token || user?.employee.token,
      requestData
    );

    if (res?.status === 200 && res?.data?.data) {
      setIsSubmittingOrder(false);
      successNotification({
        title: res?.data?.message,
        message: res?.data?.message
      });
      //
      setSucessOrder(res.data.data);
    } else {
      setIsSubmittingOrder(false);
      errorNotification({
        title: 'حدث خطأ',
        message: res?.data?.message ? res.data.message : 'حاول فى وقت لاحق'
      });
    }
  } catch (error) {
    setIsSubmittingOrder(false);
    errorNotification({
      title: 'حدث خطأ',
      message: 'حاول فى وقت لاحق'
    });
  }
};
export default invoiceSubmitFunction;
