/* eslint-disable eqeqeq */
import { Button, Popover } from 'antd';
import EditIcon from '../../common/icons/EditIcon';
import countWords from '../../utils/countWords';
import trimWords from '../../utils/trimWords';
import { ReadOutlined } from '@ant-design/icons';

const UserInstallmentsTableColumns = (
  allFetchedInstallments,
  arrType,
  setModalOpened,
  setFetchCount,
  setSelectedIntallment,
  setIsLoading,
  token,
  t
) => {
  const handleEditClick = (record) => {
    const found = allFetchedInstallments.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedIntallment({ ...found, installmentType: arrType });
      setModalOpened(true);
    }
  };

  const renderActions = (obj) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(obj)}>
          <EditIcon />
          {t('Receipts.update')}
        </div>
        {/* <Popconfirm
          title="هل أنت متأكد من الحذف"
          onConfirm={() => handleDeleteRequest(obj.id)}
          okText="نعم"
          cancelText="لا"
        >
          <Tooltip title="الحذف">
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm> */}
      </div>
    );
  };

  // const handleDeleteRequest = async (id) => {
  //   try {
  //     setIsLoading(true);
  //     const res = await deleteInstallmentApi(token, id);
  //     if (res?.status === 200 && res?.data?.status === 1) {
  //       setIsLoading(false);
  //       setFetchCount((prevState) => prevState + 1);
  //       successNotification({
  //         title: 'العملية تمت بنجاح',
  //         message: 'تم حذف الايصال بنجاح'
  //       });
  //     } else {
  //       setIsLoading(false);
  //       errorNotification({
  //         title: 'حدث خطأ اثناء العملية',
  //         message: res?.data?.message
  //           ? res.data.message
  //           : 'من فضلك حاول فى وقت لاحق'
  //       });
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     errorNotification({
  //       title: 'حدث خطأ',
  //       message: 'حاول فى وقت لاحق'
  //     });
  //   }
  // };

  return [
    {
      title: t('Receipts.code'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('Receipts.dueDate'),
      dataIndex: 'date',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.date ? record.date : '----'}
          </div>
        );
      }
    },
    {
      title: t('Receipts.status'),
      dataIndex: 'status',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.status
              ? record.status == 1
                ? t('Receipts.paid')
                : t('Receipts.unpaid')
              : '----'}
          </div>
        );
      }
    },

    {
      title: t('Receipts.notes'),
      dataIndex: 'note',
      render: (_, record) => {
        if (record.note) {
          const wordsCount = countWords(String(record.note));
          if (wordsCount > 3) {
            return (
              <Popover
                title={t('Receipts.readMore')}
                content={String(record.note)}
              >
                <Button size="large" type="text">
                  <p className="has-read-more">
                    <span>{trimWords(String(record.note), 4)}</span>
                    <ReadOutlined
                      className="read-more-book"
                      style={{ margin: '0 4px' }}
                    />
                  </p>
                </Button>
              </Popover>
            );
          } else
            return (
              <div className="row-cell row-text">{String(record.note)}</div>
            );
        } else {
          return '----';
        }
      }
    },

    {
      title: t('Receipts.price'),
      dataIndex: 'price',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.price ? record.price : '----'}
          </div>
        );
      }
    },
    {
      title: t('Receipts.settings'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default UserInstallmentsTableColumns;
