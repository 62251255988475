/* eslint-disable eqeqeq */
import React from 'react';
import { Empty, Table } from 'antd';
import QRCode from 'qrcode.react';
import './receiptDetailsComponentV2.scss';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import { useTranslation } from 'react-i18next';

const ReceiptDetailsComponentV2 = React.forwardRef((props, ref) => {
  const systemSettings = useSystemSettings();
  const { t } = useTranslation();
  const { selectedReceipt } = props;
  const renderDiscountValue = (record) => {
    if (record.discountType == '1') {
      // fixed discount price
      return record.discountPrice;
    } else if (record.discountType == '2') {
      // percentage discount
      return `${record.discountPrice}`;
    } else {
      // no discount
      return 'بدون خصم';
    }
  };

  const rowWrapStyles = {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gap: '8px'
  };

  const renderUser = (obj, type) => {
    const { receipt_holder, serial_number, date, username_reciept } = obj;
    return (
      <div
        className="supplier-details-wrap"
        style={{
          display: 'flex',
          justifyContent: 'start',
          gap: '20%',
          width: '100%'
        }}
      >
        <div
          style={{
            display: 'grid',
            gap: '10px'
          }}
        >
          {receipt_holder && (
            <div className="row-wrap" style={rowWrapStyles}>
              <h3 className="row-label">
                {t('Inventory.CashOut.receiptName')}
              </h3>
              <h3 className="row-value">{receipt_holder}</h3>
            </div>
          )}

          {serial_number && (
            <div className="row-wrap" style={rowWrapStyles}>
              <h3 className="row-label">
                {t('Inventory.CashOut.receiptCode')}
              </h3>
              <h3 className="row-value">{serial_number}</h3>
            </div>
          )}
          {date && (
            <div className="row-wrap" style={rowWrapStyles}>
              <h3 className="row-label">
                {t('Inventory.CashOut.receiptDueDate')}
              </h3>
              <h3 className="row-value">{date}</h3>
            </div>
          )}
          {username_reciept && (
            <div className="row-wrap" style={rowWrapStyles}>
              <h3 className="row-label">
                {t('Inventory.CashOut.receiptUserName')}
              </h3>
              <h3 className="row-value">{username_reciept}</h3>
            </div>
          )}
        </div>
        {/* <div
          style={{
            display: 'grid',
            gap: '10px'
          }}
        >
          {receipt_holder && (
            <div className="row-wrap" style={rowWrapStyles}>
              <h3 className="row-label">الاسـم :</h3>
              <h3 className="row-value">{receipt_holder}</h3>
            </div>
          )}

          {serial_number && (
            <div className="row-wrap" style={rowWrapStyles}>
              <h3 className="row-label">الكود :</h3>
              <h3 className="row-value">{serial_number}</h3>
            </div>
          )}
          {date && (
            <div className="row-wrap" style={rowWrapStyles}>
              <h3 className="row-label">التاريخ :</h3>
              <h3 className="row-value">{date}</h3>
            </div>
          )}
          {username_reciept && (
            <div className="row-wrap" style={rowWrapStyles}>
              <h3 className="row-label">إسم المستخدم :</h3>
              <h3 className="row-value">{username_reciept}</h3>
            </div>
          )}
        </div> */}
      </div>
    );
  };
  const columns = [
    {
      title: t('Inventory.CashOut.payWay'),
      dataIndex: 'pay_way'
    },
    {
      title: t('Inventory.CashOut.paymentAccount'),
      dataIndex: 'pay_acc'
    },
    {
      title: t('Inventory.CashOut.tableAmount'),
      dataIndex: 'amount'
    },
    {
      title: t('Inventory.CashOut.notes'),
      dataIndex: 'note'
    },
    {
      title: t('Inventory.CashOut.signature'),
      dataIndex: 'sign'
    }
  ];
  const data = [
    {
      pay_acc: `${selectedReceipt?.account?.holder || '---'}`,
      pay_way: `${selectedReceipt?.payment_method?.name || '---'}`,
      amount: `${selectedReceipt.amount || '---'}`,
      note: `${selectedReceipt.note || '---'}`,
      sign: ''
    }
  ];
  const renderModels = () => {
    if (selectedReceipt) {
      return (
        <div className="receipt_table">
          <div
            className="models-title bold-font"
            style={{
              marginBottom: 5,
              fontWeight: 'bold'
            }}
          >
            {t('Inventory.CashOut.bondDescription')}
          </div>
          <Table
            pagination={false}
            bordered
            columns={columns}
            dataSource={data}
            size="large"
          />
        </div>
      );
    }
    return null;
  };

  const renderInovicePrices = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontWeight: '900'
        }}
      >
        {selectedReceipt?.amount && (
          <div className="row-wrap" style={rowWrapStyles}>
            <h2 className="row-label">{t('Inventory.CashOut.total')}</h2>
            <h2 className="row-value">{selectedReceipt?.amount}</h2>
          </div>
        )}
        {selectedReceipt?.price_word && (
          <div className="row-wrap" style={rowWrapStyles}>
            <h2 className="row-value">{selectedReceipt?.price_word}</h2>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="invoice-details-wrap"
      ref={ref}
      style={{
        padding: '28px 28px',
        paddingTop: 0,
        marginTop: '-16px',
        direction: 'rtl',
        display: 'grid',
        gap: '16px'
      }}
    >
      <div
        className="invoice-heading-wrap"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: 14
        }}
      >
        <div
          className="inovice-nums-qrcode"
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gap: '120px',
            alignItems: 'center',
            direction: 'rtl'
          }}
        >
          <div
            className="qr-code-logo-wrap"
            style={{
              display: 'flex',
              gap: '22px',
              alignItems: 'center',
              direction: 'rtl',
              justifyContent: 'space-between'
              // gridTemplateColumns: 'auto 1fr auto'
            }}
          >
            <div
              className="img-wrap"
              style={{
                display: 'grid',
                placeItems: 'center',
                minWidth: '92px'
                // minHeight: '142px'
              }}
            >
              <img
                className="system-logo   "
                src={systemSettings?.image}
                alt="logo"
                style={{
                  // width: '92px',
                  height: '60px'
                }}
              />
            </div>
            <div
              style={{
                fontSize: 22,
                color: '#000',
                fontWeight: 'bold',
                textAlign: 'center',
                marginTop: 15,
                marginBottom: 15
              }}
            >
              {selectedReceipt?.type == 1 ? (
                <h2>{t('Inventory.CashOut.catchReceipt')}</h2>
              ) : (
                <h2>{t('Inventory.CashOut.title')}</h2>
              )}
            </div>
            <div
              style={{
                visibility: 'hidden'
              }}
            >
              -
            </div>
          </div>
        </div>
        <div className="buyer-supplier-wrap">
          {selectedReceipt && renderUser(selectedReceipt)}
          {/* {selectedReceipt?.supplier && renderUser(selectedReceipt?.supplier, 2)} */}
        </div>
      </div>

      {renderModels()}
      {renderInovicePrices()}
    </div>
  );
});

export default ReceiptDetailsComponentV2;
