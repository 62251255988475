import { createContext, useState } from 'react';

const INTIAL_VALUES = {
  isLoadingAllShiftReports: false,
  setIsLoadingAllShiftReports: (v) => {},
  fetchCountShiftReports: 0,
  setFetchCountShiftReports: (v) => {},
  allFetchedShiftReports: [],
  setAllFetchedShiftReports: (v) => {},
  selectedShiftReport: null,
  setSelectedShiftReport: (v) => {},
  selectedShiftReportsModalOpned: false,
  setSelectedShiftReportsModalOpened: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {},
  // filter
  tableFilter: {
    page: 1,
    user_id: null,
    type: null,
    from: '',
    to: '',
    pos_id: ''
  },
  setTableFilter: (v) => {}
};

const ShiftReportsContext = createContext({ INTIAL_VALUES });

export const ShiftReportsProvider = ({ children }) => {
  const [isLoadingAllShiftReports, setIsLoadingAllShiftReports] = useState(
    INTIAL_VALUES.isLoadingAllShiftReports
  );
  const [fetchCountShiftReports, setFetchCountShiftReports] = useState(
    INTIAL_VALUES.fetchCountShiftReports
  );
  const [allFetchedShiftReports, setAllFetchedShiftReports] = useState(
    INTIAL_VALUES.allFetchedShiftReports
  );
  const [selectedShiftReport, setSelectedShiftReport] = useState(
    INTIAL_VALUES.selectedShiftReport
  );

  const [selectedShiftReportsModalOpned, setSelectedShiftReportsModalOpened] =
    useState(INTIAL_VALUES.selectedShiftReportsModalOpned);

  const [tableFilter, setTableFilter] = useState(INTIAL_VALUES?.tableFilter);

  const [tablePagination, setTablePagination] = useState(
    INTIAL_VALUES?.tablePagination
  );

  return (
    <ShiftReportsContext.Provider
      value={{
        isLoadingAllShiftReports,
        setIsLoadingAllShiftReports,
        fetchCountShiftReports,
        setFetchCountShiftReports,
        allFetchedShiftReports,
        setAllFetchedShiftReports,
        selectedShiftReport,
        setSelectedShiftReport,
        selectedShiftReportsModalOpned,
        setSelectedShiftReportsModalOpened,
        //
        tableFilter,
        setTableFilter,
        //
        tablePagination,
        setTablePagination
      }}
    >
      {children}
    </ShiftReportsContext.Provider>
  );
};

export default ShiftReportsContext;
