import React, { useContext } from 'react';
import SettingsWarehousesContext from '../../../contexts/settings-contexts/SettinsWarehousesProvider';
// import { Select } from 'antd';

import SearchIcon from '../../../common/icons/SearchIcon';
import { useTranslation } from 'react-i18next';
// import SelectDropDown from '../../../common/icons/SelectDropDown';

const TableFilter = () => {
  const { t } = useTranslation();
  const { setNameSearchValue } = useContext(SettingsWarehousesContext);
  // const { Option } = Select;

  // function handleChange(value) {
  
  // }

  const handleTableSearch = (e) => {
    setNameSearchValue(e.target.value);
  };

  return (
    <div className="custom-table-fitler warehouse-table-filter">
      <div className="form-excel-export-wrap">
        <form className="filter-form">
          <div className="table-search">
            <label>
              <SearchIcon />
              <input
                placeholder={t(
                  'Inventory.Warehouses.enterWarehousNameToSearch'
                )}
                type="text"
                name="table_search"
                onKeyUp={handleTableSearch}
              />
            </label>
          </div>
          {/* <label className="entries-wrap">
            <Select
              defaultValue="1"
              style={{ width: 122 }}
              onChange={handleChange}
              suffixIcon={<SelectDropDown />}
            >
              <Option value="1">الموقع الاول</Option>
              <Option value="2">الموقع الثانى</Option>
              <Option value="3">الموقع الثالث</Option>
            </Select>
            <span className="select-title">مواقع العمل</span>
          </label>

          <button className="filter-submit-btn" type="submit">
            بحث
          </button> */}
        </form>
      </div>
    </div>
  );
};

export default TableFilter;
