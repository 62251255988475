import React from 'react';

const PhoneIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.366"
      height="14.365"
      viewBox="0 0 14.366 14.365"
    >
      <path
        id="Path_2926"
        data-name="Path 2926"
        d="M14.989,12.1l-2.908-1.939a.849.849,0,0,0-1.136.185L10.1,11.434a.362.362,0,0,1-.464.1l-.161-.089A9.737,9.737,0,0,1,6.935,9.45,9.776,9.776,0,0,1,4.942,6.913l-.088-.161a.362.362,0,0,1,.1-.466l1.088-.847A.849.849,0,0,0,6.222,4.3L4.283,1.395a.844.844,0,0,0-1.138-.256l-1.216.73a1.715,1.715,0,0,0-.782,1.015c-.438,1.6-.108,4.349,3.947,8.4,3.226,3.226,5.628,4.094,7.279,4.094a4.241,4.241,0,0,0,1.125-.147,1.713,1.713,0,0,0,1.015-.782l.731-1.215A.844.844,0,0,0,14.989,12.1Zm-.157.893L14.1,14.208a1.237,1.237,0,0,1-.73.566c-1.473.4-4.046.069-7.939-3.824S1.2,4.484,1.609,3.011a1.239,1.239,0,0,1,.567-.731L3.392,1.55a.366.366,0,0,1,.494.111L4.939,3.243l.884,1.326a.368.368,0,0,1-.08.493l-1.089.847a.838.838,0,0,0-.222,1.075l.086.157A10.156,10.156,0,0,0,6.594,9.789a10.166,10.166,0,0,0,2.647,2.075l.157.087a.838.838,0,0,0,1.075-.222l.847-1.089a.369.369,0,0,1,.493-.08L14.721,12.5A.366.366,0,0,1,14.832,12.993Z"
        transform="translate(-1 -1.018)"
      />
    </svg>
  );
};

export default PhoneIcon;
