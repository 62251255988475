import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchReportsCount: 0,
  setFetchReportsCount: (v) => {},
  fetchedReports: null,
  setFetchedReports: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {}
};

const TaxInvoiceReportsContext = createContext(INITIAL_VALUES);

export const TaxInvoiceReportsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchReportsCount, setFetchReportsCount] = useState(
    INITIAL_VALUES.fetchReportsCount
  );
  const [fetchedReports, setFetchedReports] = useState(
    INITIAL_VALUES.fetchedReports
  );
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES?.tablePagination
  );

  return (
    <TaxInvoiceReportsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fetchReportsCount,
        setFetchReportsCount,
        fetchedReports,
        setFetchedReports,
        tablePagination,
        setTablePagination
      }}
    >
      {children}
    </TaxInvoiceReportsContext.Provider>
  );
};

export default TaxInvoiceReportsContext;
