import { EyeOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import deleteCustomerPaymentApi from '../../apis/store-apis/customer-payments/deleteCustomerPaymentApi';
import DeleteIcon from '../../common/icons/DeleteIcon';
import errorNotification from '../../utils/errorNotification';
import successNotification from '../../utils/successNotification';

const TableColumns = (
  allFetchedCustomersPayments,
  setCustomerPaymentModalOpened,
  setSelectedCustomerPaymentId,
  setFetchCount,
  setSelectedCustomerPayment,
  setModalOpened,
  setIsLoading,
  token
) => {
  const { t } = useTranslation();

  const handleEditUser = (record) => {
    const found = allFetchedCustomersPayments.find(
      (obj) => obj.id === record.id
    );
    if (found) {
      setSelectedCustomerPayment({ ...found });
      setModalOpened(true);
    }
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteCustomerPaymentApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Messages.addedSuccessfully'),
          message: t('Messages.DeletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Messages.ErrorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Messages.TryAgainLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Messages.ErrorOccurred'),
        message: t('Messages.TryAgainLater')
      });
    }
  };

  const handleViewReceiptClick = (record) => {
    setCustomerPaymentModalOpened(true);
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap">
        {/* <div className="edit-btn" onClick={() => handleEditUser(record)}>
          <EditIcon />
          تعديل
        </div> */}
        <Popconfirm
          title="هل أنت متأكد من حذف الدفعة"
          onConfirm={() => handleDeleteRequest(record.id)}
          okText="نعم"
          cancelText="لا"
        >
          <Tooltip title="حذف العميل">
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    // {
    //   title: 'كود العميل',
    //   dataIndex: 'user_id',
    //   // width: 62
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-index bold-font">
    //         <span># {record?.user?.id}</span>
    //       </div>
    //     );
    //   }
    // },
    {
      title: t('ClientPayments.ClientName'),

      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record?.user?.name || '----'}</span>
          </div>
        );
      }
      // sorter: (a, b) => {
      //   let nameA = a.name.toUpperCase(); // ignore upper and lowercase
      //   let nameB = b.name.toUpperCase(); // ignore upper and lowercase
      //   if (nameA < nameB) {
      //     return -1;
      //   }
      //   if (nameA > nameB) {
      //     return 1;
      //   }

      //   // names must be equal
      //   return 0;
      // }
    },
    // {
    //   title: 'البريد الاكترونى',
    //   dataIndex: 'email',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         <span className="name-it-self">
    //           {record?.user?.email || '----'}
    //         </span>
    //       </div>
    //     );
    //   }
    // },
    // {
    //   title: 'رقم الهاتف',
    //   dataIndex: 'phone',
    //   // width: 192,
    //   render: (_, record) => {
    //     return <div className="row-cell row-text">{record?.user?.phone}</div>;
    //   }
    // },
    {
      title: t('ClientPayments.AmountLabel'),

      dataIndex: 'amount',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record?.amount || 0}</span>
          </div>
        );
      }
    },
    {
      title: t('ClientPayments.DateLabel'),

      dataIndex: 'date',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record?.date || '----'}</span>
          </div>
        );
      }
    },
    {
      title: t('ClientPayments.NotesLabel'),

      dataIndex: 'notes',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record?.notes || '----'}</span>
          </div>
        );
      }
    },
    // {
    //   title: 'نوع الفاتورة',
    //   dataIndex: 'invoice_type',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         <span className="name-it-self">
    //           {record?.invoice_type || '----'}
    //         </span>
    //       </div>
    //     );
    //   }
    // },
    {
      title: t('ClientPayments.ViewPayments'),

      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            <Tooltip title={t('ClientPayments.ViewPayments')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => {
                  setSelectedCustomerPaymentId(record?.id);
                  setCustomerPaymentModalOpened(true);
                }}
              />
            </Tooltip>
          </div>
        );
      }
    }
    // {
    //   title: 'الاعدادات',
    //   dataIndex: 'actions',
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-actions">{renderActions(record)}</div>
    //     );
    //   }
    // }
  ];
};

export default TableColumns;
