/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Table, Empty, Pagination } from 'antd';
import tableColumns from './tableColumns';
// import pageData from './pageData';
import getAllConversionsApi from '../../../apis/store-apis/store-conversion-apis/getAllConversionsApi';
import StoreConversionContext from '../../../contexts/store-conversion-context/StoreConversionProvider';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useHistory } from 'react-router-dom';
import storeRouterLinks from '../../../components/app/store-routes/storeRouterLinks';
import { useTranslation } from 'react-i18next';
// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const PageTable = () => {
  const { user } = useContext(UserContext);

  const {
    isLoading,
    setIsLoading,
    setModalOpened,
    allFetchedConversions,
    fetchCount,
    setAllFetchedCoversions,
    setFetchCount,
    setSelectedConversion,
    selectedConversion,
    tablePagination,
    setSelectedConversionModalOpened
  } = useContext(StoreConversionContext);
  const { t } = useTranslation();
  const customApiRequest = useCustomApiRequest();
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllConversionsApi(user?.token),
        (res) => {
          setIsLoading(false);
          if (res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllFetchedCoversions(data);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (allFetchedConversions?.length >= 0) {
      const mappedData = mapDataSource(allFetchedConversions);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedConversions, allFetchedConversions?.length]);
  const history = useHistory();

  return (
    <>
      <Table
        locale={{
          emptyText: (
            <Empty description={false}>
              {t('Inventory.TransferStocks.noDataAvailable')}
            </Empty>
          )
        }}
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedConversions,
          setModalOpened,
          setFetchCount,
          setSelectedConversion,
          setIsLoading,
          setSelectedConversionModalOpened,
          t,
          user?.token
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
      {tablePagination && (
        <Pagination
          style={{ display: 'flex', justifyContent: 'center' }}
          defaultCurrent={1}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}
          onChange={(page, pageSize) => {
            setFetchCount((prev) => prev + 1);
            history.push(
              `${storeRouterLinks?.storeConversionPage}?page=${page}`
            );
          }}
          hideOnSinglePage={true}
        />
      )}
    </>
  );
};

export default PageTable;
