/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import StoreProductsIngredientsContext from '../../../contexts/store-products-ingredients-context/StoreProductsIngredientsProvider';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import addNewIngredientApi from '../../../apis/store-apis/ingredients/addNewIngredientApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import { useTranslation } from 'react-i18next';

const TableForm = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    ingredient_name: Yup.string().required(
      t('Ingredient.ingredientNameValidation')
    ),
    price: Yup.string()
      .required(t('Ingredient.ingredientPriceValidation'))
      .matches(/^(\d+)?(\.\d+)?$/, t('Ingredient.onlyNumbersValidation'))
  });
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedIngredient,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(StoreProductsIngredientsContext);

  const customApiRequest = useCustomApiRequest();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      ingredient_name: '',
      price: ''
    }
  });

  // handle initial values
  useEffect(() => {
    if (selectedIngredient) {
      if (selectedIngredient?.name) {
        setValue('ingredient_name', selectedIngredient.name);
      }
      if (String(selectedIngredient?.price)) {
        setValue('price', String(selectedIngredient.price));
      }
      if (String(selectedIngredient?.desc)) {
        setValue('desc', selectedIngredient.desc);
      }
    }
  }, [selectedIngredient]);

  const onSubmit = async (data) => {
    const mappedData = {};
    if (data.ingredient_name) mappedData.name = data.ingredient_name;
    if (data.price) mappedData.price = data.price;
    if (data.desc) mappedData.desc = data.desc;

    setIsLoading(true);
    setIsSubmittingForm(true);

    if (selectedIngredient) {
      mappedData.id = selectedIngredient.id;
      customApiRequest(
        addNewIngredientApi(user?.token, mappedData, true),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Ingredient.operationCompletedSuccessfully'),
              message: t('Ingredient.inredientUpdatedSuccessfully')
            });
          } else {
            errorNotification({
              title: t('Ingredient.errorOccurred'),
              message: res?.data?.message || t('Ingredient.tryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('Ingredient.errorOccurred'),
            messag: error?.response?.data?.message || t('Ingredient.tryLater')
          });
        }
      );
    } else {
      customApiRequest(
        addNewIngredientApi(user?.token, mappedData),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Ingredient.operationCompletedSuccessfully'),
              message: t('Ingredient.addedSuccessfully')
            });
          } else {
            errorNotification({
              title: t('Ingredient.errorOccurred'),
              message: res?.data?.message || t('Ingredient.tryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('Ingredient.errorOccurred'),
            messag: error?.response?.data?.message || t('Ingredient.tryLater')
          });
        }
      );
    }
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form ingredients-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Ingredient.ingredientName')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="ingredient_name"
              type="text"
              placeholder={t('Ingredient.ingredientNamePlaceholder')}
              errorMsg={errors?.ingredient_name?.message}
              validateStatus={errors?.ingredient_name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Ingredient.ingredientPrice')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="price"
              type="text"
              placeholder={t('Ingredient.ingredientPricePlaceholder')}
              errorMsg={errors?.price?.message}
              validateStatus={errors?.price ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Ingredient.details')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="desc"
              rows={8}
              placeholder={t('Ingredient.detailsPlaceholder')}
              errorMsg={errors?.desc?.message}
              validateStatus={errors?.desc ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {t('Ingredient.add')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
