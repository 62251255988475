/* eslint-disable eqeqeq */
import React, { useRef, useState, useEffect } from 'react';
import { Button, Modal, Tabs, Tooltip, Descriptions } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import ReceiptDetailsComponent from './ReceiptDetailsComponent';
import posPrintIcon from '../../../assets/imgs/icons/pos-print-icon.png';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import './SelectedReceiptModal.scss';
import PosDetailsComponent from './PosDetailsComponent';
import ReceiptDetailsComponentV2 from './ReceiptDetailsComponentV2';
import TaxSalesInvoiceCompnent from './TaxSalesInvoiceCompnent';
import { useTranslation } from 'react-i18next';
import KitchenDetailsComponent from './KitchenDetailsComponent';

const SelectedReceiptModalProducts = ({ systemSettings }) => {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  const {
    selectedReceipt,
    setSelectedReceipt,
    setSelectedReceiptModalOpened,
    selectedReceiptModalOpened
  } = React.useContext(OutgoingPurchaseInvoiceContext);

  const a4Ref = useRef();
  const a4RefV2 = useRef();
  const taxSalesRef = useRef();
  const kitchenRef = useRef();
  const posRef = useRef();

  const handlePrintA4 = useReactToPrint({
    content: () => taxSalesRef.current,
    pageStyle: `
      @media print {
        html, body { height: initial !important; overflow: initial !important; }
        // tr { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
        // td { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
        tr { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
        td { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
        tbody { display: table-row-group !important; }
        thead {
          display: table-row-group !important;
        }
        tfoot {
          display: table-row-group !important;
        }
      @page {
        size: auto;
        margin: none;
      }
      .qr-code-wrap canvas {
        width: 128px !important;
        height: 128px !important;
      }
      .pagebreak {
        display: block;
        size: auto;
      }
      .invoice-details-wrap {
        padding: 0;
      }
      .ant-descriptions-item-content,
        .ant-descriptions-item-label {
          padding: 4px 12px !important;
        }
      }
      .ant-descriptions-header {
        margin: 4px 0 !important;
      }
      .models-title {
        direction:${currentLang == 'ar' ? 'rtl' : 'ltr'};
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .ant-table-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
      }
      th {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      .invoice-extra-prices-table .ant-table-cell {
        padding: 4px 12px !important;
        text-align: right;
      }
      .invoice-details-wrap-header,
      .supplier-details-wrap-billed,
      .tax-invoices,
      .paymentType-invoices{
        direction:${currentLang == 'ar' ? 'rtl' : 'ltr'};
      }
    `
  });

  const handlePrint = useReactToPrint({
    content: () => a4Ref.current,
    pageStyle: `
      @media print {
      html, body { height: initial !important; overflow: initial !important; }

      tbody { display: table-row-group !important; }
      thead {
        display: table-row-group !important;
      }
      tfoot {
        display: table-row-group !important;
      }
      @page {
        size: auto;
        margin: none;
      }
      .qr-code-wrap canvas {
        width: 94px !important;
        height: 94px !important;
      }
      .pagebreak {
        display: block;
        size: auto;
      }
      .invoice-details-wrap {
        padding: 0;
      }
      .ant-descriptions-item-content,
        .ant-descriptions-item-label {
          padding: 4px 12px !important;
        }
      }
      .ant-descriptions-header {
        margin: 4px 0 !important;
      }
      .models-title {
        direction:${currentLang == 'ar' ? 'rtl' : 'ltr'};
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .ant-table-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
      }
      th {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      .invoice-extra-prices-table .ant-table-cell {
        padding: 4px 12px !important;
        text-align: right;
      }
      .supp-buyer-wrap{
        direction:${currentLang == 'ar' ? 'rtl' : 'ltr'};
      }
    `
  });

  const handlePrintA4V2 = useReactToPrint({
    content: () => a4RefV2.current,
    pageStyle: `
      @media print {
        html, body { height: initial !important; overflow: initial !important; }

      tbody { display: table-row-group !important; }
      thead {
        display: table-row-group !important;
      }
      tfoot {
        display: table-row-group !important;
      }
      @page {
        size: auto;
        margin: none;
      }
      .qr-code-wrap canvas {
        width: 128px !important;
        height: 128px !important;
      }
      .pagebreak {
        display: block;
        size: auto;
      }
      .invoice-details-wrap {
        padding: 0;
      }
      .ant-descriptions-item-content,
        .ant-descriptions-item-label {
          padding: 4px 12px !important;
        }
      }
      .ant-descriptions-header {
        margin: 4px 0 !important;
      }
      .models-title {
        direction:${currentLang == 'ar' ? 'rtl' : 'ltr'};
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .ant-table-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
      }
      th {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      .invoice-extra-prices-table .ant-table-cell {
        padding: 4px 12px !important;
        text-align: right;
      }
      .invoice-heading-wrap,
      .supplier-details-wrap{
        direction:${currentLang == 'ar' ? 'rtl' : 'ltr'};
      }
    `
  });
  const handlePrintPOS = useReactToPrint({
    content: () => posRef.current,
    pageStyle: `
    @media print {
      html, body { height: initial !important; overflow: initial !important; }
      // tr { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
      // td { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
      tr { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
      td { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
      tbody { display: table-row-group !important; }
      thead {
        display: table-row-group !important;
      }
      tfoot {
        display: table-row-group !important;
      }
      @page {
        size: 200mm auto;
        margin: none;
      }

      .pos-receipt-wrap {
        direction: rtl;
        background-color: #fff;
        border-radius: 10px;
        color: "#000 !important";
      }

      .store-logo-name-wrap {
        padding-top: 6px;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .store-name {
        margin-top: 2px;
        color: #000;
        font-size: 18px;
        padding: 0 4px;
        text-align: center;
      }

      .number-span {
        padding: 0 4px;
        text-align: center;
      }
      .invoice-number {
        margin-top: 2px;
      }

      .system-info {
        padding-bottom: 4px;
        margin: 4px;
        margin-bpttom: 0px;
        border-bottom: 1px dashed #000;
        gap: 5px 4px;
        color: #000;
        font-size: 15px;
      }

      .cell-wrap {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 2px;
      }
      .date-time-wrap {
        display: flex;
        justify-content: space-between;
      }
      .date-time-wrap .cell-wrap  {
        margin-left: 28px;
      }
      .info-cell-value {
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
        text-overflow: ellipsis;
      }

      .info-cell-title {
        font-size: 12px;
      }
      
      .system-info,
      .products-table-total-wrap{
        direction:${currentLang == 'ar' ? 'rtl' : 'ltr'};
      }
    `
  });
  const handlePrintKitchen = useReactToPrint({
    content: () => kitchenRef.current,
    pageStyle: `
    @media print {
      html, body { height: initial !important; overflow: initial !important; }
      // tr { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
      // td { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
      tr { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
      td { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
      tbody { display: table-row-group !important; }
      thead {
        display: table-row-group !important;
      }
      tfoot {
        display: table-row-group !important;
      }
      @page {
        size: 200mm auto;
        margin: none;
      }

      .pos-receipt-wrap {
        direction: rtl;
        background-color: #fff;
        border-radius: 10px;
        color: "#000 !important";
      }

      .store-logo-name-wrap {
        padding-top: 6px;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .store-name {
        margin-top: 2px;
        color: #000;
        font-size: 18px;
        padding: 0 4px;
        text-align: center;
      }

      .number-span {
        padding: 0 4px;
        text-align: center;
      }
      .invoice-number {
        margin-top: 2px;
      }

      .system-info {
        padding-bottom: 4px;
        margin: 4px;
        margin-bpttom: 0px;
        border-bottom: 1px dashed #000;
        gap: 5px 4px;
        color: #000;
        font-size: 15px;
      }

      .cell-wrap {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 2px;
      }
      .date-time-wrap {
        display: flex;
        justify-content: space-between;
      }
      .date-time-wrap .cell-wrap  {
        margin-left: 28px;
      }
      .info-cell-value {
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
        text-overflow: ellipsis;
      }

      .info-cell-title {
        font-size: 12px;
      }
    `
  });

  const renderModalBody = () => {
    return (
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="A4 Invoice" key="1" forceRender={true}>
          <ReceiptDetailsComponent
            ref={a4Ref}
            selectedReceipt={selectedReceipt}
          />
        </Tabs.TabPane>

        <Tabs.TabPane tab="A4 Invoice.V2" key="2" forceRender={true}>
          <ReceiptDetailsComponentV2
            ref={a4RefV2}
            selectedReceipt={selectedReceipt}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="POS Invoice" key="3" forceRender={true}>
          <PosDetailsComponent
            systemSettings={systemSettings}
            ref={posRef}
            selectedReceipt={selectedReceipt}
          />
        </Tabs.TabPane>
        {selectedReceipt?.user?.taxNumber ? (
          <Tabs.TabPane tab="Tax sales invoice" key="4" forceRender={true}>
            <TaxSalesInvoiceCompnent
              systemSettings={systemSettings}
              ref={taxSalesRef}
              selectedReceipt={selectedReceipt}
            />
          </Tabs.TabPane>
        ) : null}

        {/* <Tabs.TabPane tab="Kitchen Invoice" key="4" forceRender={true}>
          <KitchenDetailsComponent
            systemSettings={systemSettings}
            ref={kitchenRef}
            selectedReceipt={selectedReceipt}
          />
        </Tabs.TabPane> */}
      </Tabs>
    );
  };

  const renderModalTitle = () => {
    if (selectedReceipt?.type == 2) {
      return t('Invoices.InvoiceDetailsRefund');
    } else if (selectedReceipt?.type == 1) {
      if (selectedReceipt?.is_return == 1) {
        if (selectedReceipt?.userOrSupplier == 1 || selectedReceipt?.user) {
          return t('Invoices.TaxSaleRefundInvoice');
        } else if (
          selectedReceipt?.userOrSupplier == 2 ||
          selectedReceipt?.supplier
        ) {
          return t('Invoices.TaxSaleRefundInvoice');
        }
        return t('Invoices.InvoiceDetailsRefund');
      } else {
        if (selectedReceipt?.userOrSupplier == 1 || selectedReceipt?.user) {
          return t('Invoices.TaxInvoice');
        } else if (
          selectedReceipt?.userOrSupplier == 2 ||
          selectedReceipt?.supplier
        ) {
          return t('Invoices.TaxSaleInvoice');
        }
        return t('Invoices.InvoiceDetails');
      }
    }
  };

  return (
    <Modal
      className="shared-custom-modal selected-inovice-modal"
      width="90%"
      style={{ maxWidth: '942px' }}
      title={
        <>
          {renderModalTitle()}

          {/* <Tooltip title="طباعة الفاتورة"> */}
          <Tooltip title="">
            <Button
              onClick={handlePrint}
              className="circled-btn"
              type="dashed"
              shape="circle"
              icon={<PrinterOutlined />}
            />
          </Tooltip>
          <Tooltip title="Print A4 V2">
            <Button
              onClick={handlePrintA4V2}
              className="circled-btn"
              type="dashed"
              shape="circle"
              icon={<PrinterOutlined />}
            />
          </Tooltip>
          <Tooltip title="Print Receipt">
            <Button
              onClick={handlePrintPOS}
              className="circled-btn"
              type="dashed"
              shape="circle"
            >
              <span>
                <img src={posPrintIcon} alt="print invoice" />
              </span>
            </Button>
          </Tooltip>
          {selectedReceipt?.user?.taxNumber ? (
            <Tooltip title="Print Tax sales">
              <Button
                onClick={handlePrintA4}
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<PrinterOutlined />}
              />
            </Tooltip>
          ) : null}

          {/* <Tooltip title="Print Kitchen Invoice">
            <Button
              onClick={handlePrintKitchen}
              className="circled-btn"
              type="dashed"
              shape="circle"
              icon={<PrinterOutlined />}
            />
          </Tooltip> */}
        </>
      }
      visible={selectedReceiptModalOpened}
      onOk={() => {
        setSelectedReceiptModalOpened(false);
        setSelectedReceipt(null);
      }}
      onCancel={() => {
        setSelectedReceiptModalOpened(false);
        setSelectedReceipt(null);
      }}
      footer={false}
    >
      {renderModalBody()}

      {/* <pre style={{ direction: 'ltr' }}>
        {JSON.stringify(selectedReceipt, null, 2)}
      </pre> */}
    </Modal>
  );
};

export default SelectedReceiptModalProducts;
