import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  modalOpenedCashIn: false,
  setModalOpenedCashIn: (v) => {},
  printModalCash : false,
  setPrintModalCash: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedCashOut: null,
  setAllFetchedCashOut: (v) => {},
  allFetchedCashIn: null,
  setAllFetchedCashIn: (v) => {},
  selectedCashId: '',
  setSelectedCashId: (v) => {},
  selectedCash: '',
  setSelectedCash: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  nameSearchValue: '',
  setNameSearchValue: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {}
};

const PosCashPaymentsContext = createContext(INITIAL_VALUES);

export const PosCashPaymentsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [modalOpenedCashIn, setModalOpenedCashIn] = useState(
    INITIAL_VALUES.modalOpened
  );
  const [printModalCash , setPrintModalCash] = useState(
    INITIAL_VALUES.printModalCash 
  );
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedCashIn, setAllFetchedCashIn] = useState(
    INITIAL_VALUES.allFetchedCashIn
  );
  const [allFetchedCashOut, setAllFetchedCashOut] = useState(
    INITIAL_VALUES.allFetchedCashOut
  );
  const [nameSearchValue, setNameSearchValue] = useState(
    INITIAL_VALUES.nameSearchValue
  );

  const [selectedCashId, setSelectedCashId] = useState(
    INITIAL_VALUES.selectedCashId
  );
  const [selectedCash, setSelectedCash] = useState(INITIAL_VALUES.selectedCash);
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES?.tablePagination
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );

  return (
    <PosCashPaymentsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedCashOut,
        setAllFetchedCashOut,
        allFetchedCashIn,
        setAllFetchedCashIn,
        selectedCashId,
        setSelectedCashId,
        nameSearchValue,
        setNameSearchValue,
        tablePagination,
        setTablePagination,
        selectedCash,
        setSelectedCash,
        isSubmittingForm,
        setIsSubmittingForm,
        modalOpenedCashIn,
        setModalOpenedCashIn,
        printModalCash , 
        setPrintModalCash
      }}
    >
      {children}
    </PosCashPaymentsContext.Provider>
  );
};

export default PosCashPaymentsContext;
