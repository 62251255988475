import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Checkbox, Divider, Form, Spin, Switch } from 'antd';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import AntdCheckbox from '../../../common/antd-form-components/AntdCheckbox';
import AntdRadioGroup from '../../../common/antd-form-components/AntdRadioGroup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import { store } from 'react-notifications-component';
import addTaxSettingsApi from '../../../apis/settings-apis/system-settings-api/addTaxSettingsApi';
import { useContext } from 'react';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useHistory } from 'react-router-dom';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import { useTranslation } from 'react-i18next';
import { watch } from 'fs';

const TaxSettingsPage = () => {
  const history = useHistory();
  const systemSettings = useSystemSettings();
  const customApiRequest = useCustomApiRequest();
  const { user } = useContext(UserContext);
  const [taxChecked, setTaxChecked] = useState();
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    tax_name: Yup.string().required(t('SystemSettings.enterTaxName')),
    // sale_tax: Yup.string()
    //   .required('ادخل نسبة ضريبة البيع')
    //   .test('sale_tax', 'لا يسمح الا بالارقام', (v, context) => {
    //     let result = true;
    //     if (!v) {
    //       result = true;
    //     } else if (v && !v.match(/^(\d+)?(\.\d+)?$/)) {
    //       result = false;
    //     }
    //     return result;
    //   }),
    // buy_tax: Yup.string()
    //   .required('ادخل نسبة ضريبة الشراء')
    //   .test('buy_tax', 'لا يسمح الا بالارقام', (v, context) => {
    //     let result = true;
    //     if (!v) {
    //       result = true;
    //     } else if (v && !v.match(/^(\d+)?(\.\d+)?$/)) {
    //       result = false;
    //     }
    //     return result;
    //   }),

    tax: Yup.string()
      .required(t('SystemSettings.enterTaxRate'))
      .test('buy_tax', t('SystemSettings.onlyNumbersAllowed'), (v, context) => {
        let result = true;
        if (!v) {
          result = true;
        } else if (v && !v.match(/^(\d+)?(\.\d+)?$/)) {
          result = false;
        }
        return result;
      }),
    tax_num: Yup.string().required(t('SystemSettings.enterCommercialRegister'))
    // active_tax: Yup.string().required(''),
  });
  const {
    control,
    handleSubmit,
    setValue,
    // watch,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      tax_name: '',
      tax_num: '',
      // sale_tax: systemSettings?.invoiceSetting?.sale_tax,
      // buy_tax: systemSettings?.invoiceSetting?.buy_tax,
      tax: systemSettings?.tax,
      including_tax: systemSettings?.including_tax
        ? systemSettings?.including_tax
        : ''
    }
  });
  useEffect(() => {
    if (systemSettings) {
      setValue(
        'including_tax',
        systemSettings?.invoiceSetting?.including_tax ? '1' : '0'
      );
    }
  }, [systemSettings]);
  const onSubmit = async (data) => {
    customApiRequest(
      addTaxSettingsApi(user?.token, data, taxChecked),
      (res) => {
        if (res?.data?.status) {
          store.addNotification({
            title: res?.data?.message,
            message: res?.data?.message,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              showIcon: true,
              onScreen: true
            }
          });
          // history.push('/');
        }
      },
      (error) => {}
    );
  };

  const [form] = Form.useForm();
  return (
    <div className="tax-settings">
      {systemSettings ? (
        <>
          <h2 style={{ marginBottom: '20px' }}>
            {t('SystemSettings.taxSettings')}
          </h2>
          {/* <h2 style={{marginBottom: '20px'}}>تفعيل الضريبه فى كل الفواتير على النظام</h2> */}
          {/* <div className="tax-settings-content">
            <h3>تفعيل الفاتوره</h3>
            <Switch
              name="active_tax"
              control={control}
              size="large"
              checkedChildren="مفعل"
              unCheckedChildren="غير مفعل"
              defaultChecked={systemSettings?.invoiceSetting?.active_tax}
              onChange={(e) => setTaxChecked(e)}
            />
          </div> */}
          <div>
            <Form
              className="custom-shared-form settings-form"
              form={form}
              layout="vertical"
              onFinish={handleSubmit(onSubmit)}
            >
              <div className="text-field-label-wrap">
                <p className="label-p">{t('SystemSettings.taxRate')}</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name="tax"
                    type="text"
                    placeholder={t('SystemSettings.taxRatePlaceholder')}
                    errorMsg={errors?.tax?.message}
                    validateStatus={errors?.tax ? 'error' : ''}
                    control={control}
                    defaultValue={systemSettings ? systemSettings?.tax : '0'}
                    value={systemSettings ? systemSettings?.tax : '0'}
                  />
                </div>
              </div>
              {/* <div className="text-field-label-wrap">
                <p className="label-p">نسبة ضريبة البيع</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name="sale_tax"
                    type="text"
                    placeholder="نسبة ضريبة البيع ..."
                    errorMsg={errors?.sale_tax?.message}
                    validateStatus={errors?.sale_tax ? 'error' : ''}
                    control={control}
                    defaultValue={
                      systemSettings?.invoiceSetting
                        ? systemSettings?.invoiceSetting?.sale_tax
                        : '0'
                    }
                    value={
                      systemSettings?.invoiceSetting
                        ? systemSettings?.invoiceSetting?.sale_tax
                        : '0'
                    }
                  />
                </div>
              </div> */}
              {/* <div className="text-field-label-wrap">
                <p className="label-p">نسبة ضريبة الشراء </p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name="buy_tax"
                    type="text"
                    placeholder="نسبة ضريبة الشراء ..."
                    errorMsg={errors?.buy_tax?.message}
                    validateStatus={errors?.buy_tax ? 'error' : ''}
                    control={control}
                    defaultValue={
                      systemSettings?.invoiceSetting
                        ? systemSettings?.invoiceSetting?.buy_tax
                        : '0'
                    }
                    value={
                      systemSettings?.invoiceSetting
                        ? systemSettings?.invoiceSetting?.buy_tax
                        : '0'
                    }
                  />
                </div>
              </div> */}

             <div className="text-field-label-wrap">
                <p className="label-p">
                  {t('SystemSettings.enterTaxNumberForYourBusiness')}
                </p>
                <div className="text-field-wrap">
                  <AntdTextField
                    className="form-text-field"
                    name="tax_num"
                    type="text"
                    placeholder={t(
                      'SystemSettings.enterTaxNumberForYourBusinessPlaceholder'
                    )}
                    errorMsg={errors?.tax_num?.message}
                    validateStatus={errors?.tax_num ? 'error' : ''}
                    control={control}
                    setValue={setValue}
                    defaultValue={systemSettings?.invoiceSetting?.tax_num}
                  />
                </div>
              </div> 

              <div className="text-field-label-wrap">
                <p className="label-p">
                  {t('SystemSettings.taxNameInInvoice')}
                </p>
                <div className="text-field-wrap">
                  <AntdTextField
                    className="form-text-field"
                    name="tax_name"
                    type="text"
                    placeholder={t(
                      'SystemSettings.taxNameInInvoicePlaceholder'
                    )}
                    errorMsg={errors?.tax_name?.message}
                    validateStatus={errors?.tax_name ? 'error' : ''}
                    control={control}
                    setValue={setValue}
                    defaultValue={systemSettings?.invoiceSetting?.tax_name}
                  />
                </div>
              </div>
              <div className="text-field-label-wrap">
                <p className="label-p">
                  {t('SystemSettings.doesproductIncludeTax')}
                </p>
                <div className="text-field-wrap">
                  <AntdRadioGroup
                    name="including_tax"
                    className="form-radio-group"
                    control={control}
                    validateStatus={errors?.template ? 'error' : ''}
                    errorMsg={errors?.template?.message}
                    defaultValue={
                      systemSettings?.invoiceSetting?.including_tax ? '1' : '0'
                    }
                    radios={[
                      {
                        title: t('SystemSettings.yes'),
                        value: '1'
                      },
                      {
                        title: t('SystemSettings.no'),
                        value: '0'
                      }
                    ]}
                  />
                </div>
              </div>
              <div className="radios-field-lang no-border">
                {/* <AntdRadioGroup
                  name="including_tax"
                  className="form-radio-group"
                  control={control}
                  label={t('SystemSettings.doesproductIncludeTax')}
                  validateStatus={errors?.template ? 'error' : ''}
                  errorMsg={errors?.template?.message}
                  defaultValue={'0'}
                  radios={[
                    {
                      title: t('SystemSettings.yes'),
                      value: '1'
                    },
                    {
                      title: t('SystemSettings.no'),
                      value: '0'
                    }
                  ]}
                /> */}
              </div>

              <div className="btn">
                <Button
                  className="submit-btn"
                  htmlType="submit"
                  type="primary"
                  // icon={<LoginOutlined />}
                  // loading={isSubmittingForm}
                >
                  {t('SystemSettings.save')}
                </Button>
              </div>
            </Form>
          </div>
        </>
      ) : (
        <div
          style={{
            height: '250px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Spin />
        </div>
      )}
    </div>
  );
};

export default TaxSettingsPage;
