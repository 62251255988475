/* eslint-disable react-hooks/exhaustive-deps */
import { Empty, Pagination, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import getProductsWithFirstTerm from '../../../apis/store-apis/products/getProductsWithFirstTerm';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import tableColumns from './tableColumns';
import StockContext from '../../../contexts/stock-context/StockProvider';
// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
import { useTranslation } from 'react-i18next';

const PageTable = () => {
  const { user, filterValues, setFilterValues } = useContext(UserContext);
  const { t } = useTranslation();
  const {
    isLoading,
    setIsLoading,
    setModalOpened,
    allFetchedProducts,
    setAllFetchedProducts,
    fetchCount,
    setSelectedProductId,
    setSelectedProduct,
    setAllFetchedInventories,
    setFetchCount,
    setSelectedInventory,
    setBarCodeModalOpned,

    tablePagination,
    setSelectedProductModalOpened,

    setTablePagination
  } = useContext(StockContext);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: index + 1,
      name: obj?.name ? obj.name : '',
      title: obj?.title ? obj.title : ''
    }));
  };
  const customApiRequest = useCustomApiRequest();
  // useEffect(() => {
  //   let isMounted = true;
  //   if (isMounted) {
  //     setIsLoading(true);
  //     customApiRequest(
  //       getAllInventoriesApi(user?.token),
  //       (res) => {
  //         setIsLoading(false);
  //         if (res?.data?.data) {
  //           const data = res.data.data;
  //           setAllFetchedInventories(data);
  //         }
  //       },
  //       (errror) => {
  //         setIsLoading(false);
  //       }
  //     );
  //   }

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [fetchCount]);

  useEffect(async () => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getProductsWithFirstTerm(user?.token, {
          ...filterValues,
          page: tablePagination.current_page
        }),
        (res) => {
          setIsLoading(false);
          setFilterValues({
            ...filterValues,
            page: tablePagination.current_page,
            stock_id: '',
            parent_cat: ''
          });
          if (res?.status === 200 && res?.data?.data?.data.length >= 0) {
            const data = res?.data?.data?.data;
            setAllFetchedProducts(data);
          }
          if (res?.data.data.pagination) {
            setTablePagination(res?.data.data.pagination);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (allFetchedProducts?.length >= 0) {
      const mappedData = mapDataSource(allFetchedProducts);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedProducts, allFetchedProducts?.length]);
  const history = useHistory();

  return (
    <>
      <Table
        locale={{
          emptyText: (
            <Empty description={false}>
              {t('Inventory.CurrentStock.noDataAvailable')}
            </Empty>
          )
        }}
        //pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedProducts,
          setModalOpened,
          setFetchCount,
          setSelectedProduct,
          setSelectedProductId,
          // setSelectedInventory,
          setIsLoading,
          setSelectedProductModalOpened,
          setBarCodeModalOpned,
          t,
          user?.token
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
      {tablePagination && (
        <Pagination
          style={{ display: 'flex', justifyContent: 'center' }}
          defaultCurrent={1}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          onChange={(page, pageSize) => {
            setFetchCount((prev) => prev + 1);
            setTablePagination({ ...tablePagination, current_page: page });
          }}
          hideOnSinglePage={true}
        />
      )}
    </>
  );
};

export default PageTable;
