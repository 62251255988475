import { Button, Tooltip } from 'antd';
// import routerLinks from '../../components/app/routerLinks';
import { EyeOutlined } from '@ant-design/icons';
import { Link as RouterLink } from 'react-router-dom';
import Links from '../../components/app/Links';

const tableColumns = (
  allFetchedCustomersPayments,
  setModalOpened,
  setCustomerPaymentModalOpened,
  // setSelectedCustomerPaymentId,
  setFetchCount,
  setSelectedCustomerPayment,
  setIsLoading,
  t,
  token
) => {
  const routerLinks = Links();
  // const handleEditUser = (record) => {
  //   const found = allFetchedCustomersPayments.find(
  //     (obj) => obj.id === record.id
  //   );
  //   if (found) {
  //     setSelectedCustomerPayment({ ...found });
  //     setCustomerPaymentModalOpened(true);
  //   }
  // };

  // const handleDeleteRequest = async (id) => {
  //   try {
  //     setIsLoading(true);
  //     const res = await deleteClientApi(token, id);
  //     if (res?.status === 200 && res?.data?.status === 1) {
  //       setIsLoading(false);
  //       setFetchCount((prevState) => prevState + 1);
  //       successNotification({
  //         title: 'العملية تمت بنجاح',
  //         message: 'تم حذف العميل بنجاح'
  //       });
  //     } else {
  //       setIsLoading(false);
  //       errorNotification({
  //         title: 'حدث خطأ اثناء العملية',
  //         message: res?.data?.message
  //           ? res.data.message
  //           : 'من فضلك حاول فى وقت لاحق'
  //       });
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     errorNotification({
  //       title: 'حدث خطأ',
  //       message: 'حاول فى وقت لاحق'
  //     });
  //   }
  // };

  const handleViewReceiptClick = (record) => {
    setCustomerPaymentModalOpened(true);
  };
  const handleCustomerPaymentAdd = (el) => {
    setSelectedCustomerPayment(el);
    setModalOpened(true);
  };
  // const renderActions = (record) => {
  //   return (
  //     <div className="action-btns-wrap">
  //       <div className="edit-btn" onClick={() => handleEditUser(record)}>
  //         <EditIcon />
  //         تعديل
  //       </div>
  //       <Popconfirm
  //         title="هل أنت متأكد من حذف العميل"
  //         onConfirm={() => handleDeleteRequest(record.id)}
  //         okText="نعم"
  //         cancelText="لا"
  //       >
  //         <Tooltip title="حذف العميل">
  //           <div className="delete-btn">
  //             <DeleteIcon />
  //           </div>
  //         </Tooltip>
  //       </Popconfirm>
  //     </div>
  //   );
  // };

  return [
    {
      title: t('supplierPayments.code'),
      dataIndex: 'user_id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record?.id}</span>
          </div>
        );
      }
    },
    {
      title: t('supplierPayments.name'),
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text desc-wrap">
            <span className="name-it-self">{record?.name || '----'}</span>
          </div>
        );
      }
      // sorter: (a, b) => {
      //   let nameA = a.name.toUpperCase(); // ignore upper and lowercase
      //   let nameB = b.name.toUpperCase(); // ignore upper and lowercase
      //   if (nameA < nameB) {
      //     return -1;
      //   }
      //   if (nameA > nameB) {
      //     return 1;
      //   }

      //   // names must be equal
      //   return 0;
      // }
    },
    // {
    //   title: 'البريد الاكترونى',
    //   dataIndex: 'email',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         <span className="name-it-self">
    //           {record?.user?.email || '----'}
    //         </span>
    //       </div>
    //     );
    //   }
    // },
    // {
    //   title: 'رقم الهاتف',
    //   dataIndex: 'phone',
    //   // width: 192,
    //   render: (_, record) => {
    //     return <div className="row-cell row-text">{record?.user?.phone}</div>;
    //   }
    // },
    {
      title: t('supplierPayments.balance'),
      dataIndex: 'balance',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record?.balance || 0}</span>
          </div>
        );
      }
    },
    {
      title: t('supplierPayments.paidUp'),
      dataIndex: 'paid',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record?.paid || 0}</span>
          </div>
        );
      }
    },
    {
      title: t('supplierPayments.dueFromSupplier'),
      dataIndex: 'unnpaid',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.unpaid ? record?.unpaid?.toFixed(2) : 0}
            </span>
          </div>
        );
      }
    },
    {
      title: t('supplierPayments.makePayment'),
      // تسديد دفعة
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            {record?.unpaid !== 0 ? (
              <button
                className="bt-target"
                onClick={() => {
                  handleCustomerPaymentAdd(record);
                }}
              >
                {t('ClientPayments.Payment')}
              </button>
            ) : (
              <button
                className="bt-target"
                style={{
                  cursor: 'not-allowed',
                                    backgroundColor: '#58879e'

                }}
                disabled={true}
              >
                {t('ClientPayments.Payment')}
              </button>
            )}
          </div>
        );
      }
    },
    {
      title: t('supplierPayments.invoices'),
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            <RouterLink
              className="row-cell row-circled-btn"
              to={routerLinks?.incomingPurchaseInvoices(record?.id)}
            >
              <Tooltip>
                <Button
                  className="circled-btn"
                  type="dashed"
                  shape="circle"
                  icon={<EyeOutlined />}
                />
              </Tooltip>
            </RouterLink>
          </div>
        );
      }
    },
    {
      title: t('supplierPayments.previewPayments'),
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            {/* <Tooltip title="معاينة الفاتورة">
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => {
                  setSelectedCustomerPaymentId(record?.id);
                  setCustomerPaymentModalOpened(true);
                }}
              />
            </Tooltip> */}
            <RouterLink
              className="row-cell row-circled-btn"
              to={routerLinks?.singleSupplierPaymentsPage(record?.id)}
            >
              <Tooltip>
                <Button
                  className="circled-btn"
                  type="dashed"
                  shape="circle"
                  icon={<EyeOutlined />}
                />
              </Tooltip>
            </RouterLink>
          </div>
        );
      }
    }
    // {
    //   title: 'الاعدادات',
    //   dataIndex: 'actions',
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-actions">{renderActions(record)}</div>
    //     );
    //   }
    // }
  ];
};

export default tableColumns;
