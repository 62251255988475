import React from 'react';
import SearchNotificationsProfile from '../../components/search-notifications-profile/SearchNotificationsProfile';
import settingsImg from '../../assets/imgs/settings/settings-img.png';
import ProfilePageForm from './ProfilePageForm';
import './ProfilePage.scss';
import { useTranslation } from 'react-i18next';
const ProfilePage = () => {
  const { t } = useTranslation();
  return (
    <div className="profile-page">
      <div className="page-head-wrap">
        <div className="page-title">
          <h2 className="bold-font">{t('UserProfile.title')}</h2>
        </div>
        <SearchNotificationsProfile />
      </div>

      <div className="img-form-wrap">
        <ProfilePageForm />
        <img className="settingsImg" src={settingsImg} alt="settings" />
      </div>
    </div>
  );
};

export default ProfilePage;
