/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Table, Empty } from 'antd';
import AccountingDetailsContext from '../../../contexts/accounting-context/AccountingDetailsContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import TableColumns from './TableColumns';
import { useTranslation } from 'react-i18next';
// import TableColumns from './TableColumns';

const AccountingDetailsTable = ({ type }) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const {
    fetchedAccountingDetails,
    fetchCount,
    setIsLoading,
    setFetchCount,
    setModalOpened,
    setSelectedAccounting,
    isLoading
  } = useContext(AccountingDetailsContext);

  // map all data to dataSource
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: index + 1,
      name: obj?.name ? obj.name : '',
      description: obj?.name ? obj.name : '',
      title: obj?.title ? obj.title : '',
      the_amount: obj?.balance
        ? obj.balance
        : obj.initial_balance
        ? obj.initial_balance
        : ''
    }));
  };

  useEffect(() => {
    let isMounted = true;

    if (type === 'types') {
      if (fetchedAccountingDetails?.subtypes.length >= 0) {
        const mappedData = mapDataSource(fetchedAccountingDetails?.subtypes);
        if (isMounted) {
          setTableDataSource(mappedData);
        }
      }
    } else if (type === 'subtypes') {
      if (fetchedAccountingDetails?.accounts?.length >= 0) {
        const mappedData = mapDataSource(fetchedAccountingDetails?.accounts);
        if (isMounted) {
          setTableDataSource(mappedData);
        }
      }
    } else if (type === 'account') {
      if (fetchedAccountingDetails?.transactions?.length >= 0) {
        const mappedData = mapDataSource(
          fetchedAccountingDetails?.transactions
        );
        if (isMounted) {
          setTableDataSource(mappedData);
        }
      }
    } else if (type === 'company') {
      if (fetchedAccountingDetails?.types?.length >= 0) {
        const mappedData = mapDataSource(fetchedAccountingDetails?.types);
        if (isMounted) {
          setTableDataSource(mappedData);
        }
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, fetchedAccountingDetails]);
  return (
    <Table
      locale={{
        emptyText: (
          <Empty description={false}>{t('Messages.noDataAvailable')}</Empty>
        )
      }}
      pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
      className="antd-custom-table"
      dataSource={tableDataSource}
      columns={TableColumns(
        fetchedAccountingDetails,
        setModalOpened,
        setFetchCount,
        setSelectedAccounting,
        setIsLoading,
        user?.token,
        type
      )}
      loading={isLoading}
      // scroll={{ x: 400 }}
      footer={false}
    />
  );
};

export default AccountingDetailsTable;
