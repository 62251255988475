const salesRouterLinks = {
  salesPage: '/sales',
  salesPageReports: '/sales-reports',
  shiftPageReports: '/shift-reports',
  shiftsPage: '/shifts',
  clientPage: '/clients',
  priceOfferRouteNew: '/sales/price-offer',
  customerPaymentsPage: '/sales/customer-payments-page',
  clientPaymentsPage: (id) => {
    if (id) return `/sales/customer-payments-page/${id}`;
    return '/sales/customer-payments-page/:id';
  }
};

export default salesRouterLinks;
