/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import storeItemManagerImg from '../../assets/imgs/store/store-item-manager.png';
import SearchNotificationsProfile from '../../components/search-notifications-profile/SearchNotificationsProfile';
import StoreWelcomeSharedSection from '../store-pages/store-welcome-shared-section/StoreWelcomeSharedSection';
import TableFilter from './TableFilter';
import PageTable from './PageTable';
import './CustomerPaymentsPage';
import CustomersPaymentsContext from '../../contexts/customers-payments-context/CustomersPaymentsContext';
import SelectedCustomerPayment from './SelectedCustomerPayment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';

const CustomerPaymentsPage = () => {
  const { customerPaymentModalOpened } = React.useContext(
    CustomersPaymentsContext
  );
  const path = useHistory();
  const { t } = useTranslation();

  return (
    <div className="clients-page">
      <div className="top-section-wrap">
        <div className="settings-categories-page">
          <div className="OutgoingPurchaseInvoicePage-page-head-wrap">
            {/* <div className="page-title">
              <h2 className="bold-font">{t('ClientPayments.Payments')}</h2>
              <p>{t('ClientPayments.ListAndDisplayAllPayments')}</p>
            </div> */}
            <div className="page-title">
              <h2 className="bold-font" style={{ fontSize: '42px' }}>
                {t('ClientPayments.Payments')}
              </h2>
              <Breadcrumbs
                title1={t('AppBar.Products.homePage')}
                link1={'/'}
                title2={t('AppBar.Products.salesPage.title')}
                title3={t('ClientPayments.Payments')}
                link3={'/sales/customer-payments-page'}
              />
            </div>
          </div>
        </div>

        <div className="search-wrap">
          <SearchNotificationsProfile noSearch />
        </div>
      </div>
      <TableFilter />
      <div className="suppliersPage-table-wrapper">
        <PageTable />
      </div>
      {customerPaymentModalOpened && <SelectedCustomerPayment />}
    </div>
  );
};

export default CustomerPaymentsPage;
