/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { Form, Button, DatePicker } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PaymentDepartmentContext from '../../contexts/payment-departments-context/PaymentDepartmentContext';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import createDepartments from '../../apis/payment-departments/createDepartments';
import { useTranslation } from 'react-i18next';

const TableForm = () => {
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    name: Yup.string().required(t('SystemSettings.enterDepartment'))
    // department_id: Yup.string().required('ادخل القسم'),
    // date: Yup.mixed().required('اختار تاريخ الاستحقاق'),
    // amount: Yup.string()
    //   .required('ادخل المبلغ')
    //   .matches(/^(\d+)?(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام')
  });
  const { user } = useContext(UserContext);
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedDepartment,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(PaymentDepartmentContext);

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: ''
    }
  });

  useEffect(() => {
    if (selectedDepartment) {
      if (selectedDepartment?.name) {
        setValue('name', selectedDepartment.name);
      }
    }
  }, [selectedDepartment]);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    const mappedData = new FormData();
    if (data.name) mappedData.append('name', data.name);

    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedDepartment) {
      mappedData.append('id', selectedDepartment.id);
      customApiRequest(
        createDepartments(user?.token, mappedData, true),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('SystemSettings.operationCompletedSuccessfully'),
              message: t('SystemSettings.departmentUpdatedSuccessfully')
            });
          } else {
            errorNotification({
              title: t('SystemSettings.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('SystemSettings.tryLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('SystemSettings.errorOccurred'),
            message:
              error?.response?.data?.message || t('SystemSettings.tryLater')
          });
        }
      );
    } else {
      customApiRequest(
        createDepartments(user?.token, mappedData),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('SystemSettings.operationCompletedSuccessfully'),
              message: t('SystemSettings.departmentAddedSuccessfully')
            });
          } else {
            errorNotification({
              title: t('SystemSettings.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('SystemSettings.tryLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('SystemSettings.errorOccurred'),
            message:
              error?.response?.data?.message || t('SystemSettings.tryLater')
          });
        }
      );
    }
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form ingredients-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('SystemSettings.department')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="name"
              type="text"
              placeholder={t('SystemSettings.departmentPlaceholder')}
              errorMsg={errors?.name && errors.name.message}
              validateStatus={errors?.name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedDepartment
            ? t('SystemSettings.update')
            : t('SystemSettings.add')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
