import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import AccountingContext from '../../../contexts/accounting-context/AccountingContext';
import AccountingTree from './AccountingTree';
import PageModal from './PageModal';
import './AccountingPage.scss';
import AccountInfoModal from './AccountInfoModal';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

const AccountingTreePage = () => {
  const { accountingModalOpened, accountInfoModalOpened } =
    useContext(AccountingContext);
  const { t } = useTranslation();

  return (
    <div className="accounting-page">
      <div className="page-head-wrap">
        {/* <div
          className="page-title"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 32
          }}
        >
          <div>
            <h2 className="bold-font">{t('Accounts.Accounts')}</h2>
            <p>{t('Accounts.ShowAndDisplayAllAccounts')}</p>
          </div>

          <RouterLink
            className="add-price-offer-link"
            to={routerLinks?.priceOfferRoute}
          >
            عرض سعر
          </RouterLink>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('AppBar.Products.accountingPage.accountingTreePage')}
          </h2>
          
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.accountingPage.title')}
            title3={t('AppBar.Products.accountingPage.accountingTreePage')}
            link3={'/accounting/accounting-tree'}
          />
        </div>
        <SearchNotificationsProfile />
      </div>

      <AccountingTree />
      {accountingModalOpened && <PageModal />}
      {accountInfoModalOpened && <AccountInfoModal />}
    </div>
  );
};

export default AccountingTreePage;
