/* eslint-disable eqeqeq */
import React, { useRef, useEffect, useState } from 'react';
import { Button, Modal, Tabs, Tooltip, Descriptions } from 'antd';
import StoreReceiptContext from '../../../contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import './SelectedReceiptModalProducts.scss';
import ReceiptDetailsComponentProducts from './ReceiptDetailsComponentProducts';
import ReceiptDetailsComponentV2Products from './ReceiptDetailsComponentV2Products';
import { useTranslation } from 'react-i18next';
const SelectedReceiptModalProducts = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  const renderDiscountValue = (record) => {
    if (record.discountType == '1') {
      // fixed discount price
      return record.discountPrice;
    } else if (record.discountType == '2') {
      // percentage discount
      return `${record.discountPrice}`;
    } else {
      // no discount
      return t('purchaseInvoices.withoutDiscount');
    }
  };
  const {
    selectedReceipt,
    setSelectedReceipt,
    setSelectedReceiptModalOpened,
    selectedReceiptModalOpened
  } = React.useContext(StoreReceiptContext);
  const a4Ref = useRef();
  const a4RefV2 = useRef();
  const handlePrint = useReactToPrint({
    content: () => a4Ref.current,
    pageStyle: `
      @media print {
        html, body { height: initial !important; overflow: initial !important; }

      tbody { display: table-row-group !important; }
      thead {
        display: table-row-group !important;
      }
      tfoot {
        display: table-row-group !important;
      }
      @page {
        size: auto;
        margin: none;
      }
      .qr-code-wrap canvas {
        width: 94px !important;
        height: 94px !important;
      }
      .pagebreak {
        display: block;
        size: auto;
      }
      .invoice-details-wrap {
        padding: 0;
      }
      .ant-descriptions-item-content,
        .ant-descriptions-item-label {
          padding: 4px 12px !important;
        }
      }
      .ant-descriptions-header {
        margin: 4px 0 !important;
      }
      .models-title {
        direction:${currentLang == 'ar' ? 'rtl' : 'ltr'};

        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .ant-table-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
      }
      th {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      .invoice-extra-prices-table .ant-table-cell {
        padding: 4px 12px !important;
        text-align: right;
      }
    `
  });

  const handlePrintA4V2 = useReactToPrint({
    content: () => a4RefV2.current,
    pageStyle: `
      @media print {
        html, body { height: initial !important; overflow: initial !important; }

      tbody { display: table-row-group !important; }
      thead {
        display: table-row-group !important;
      }
      tfoot {
        display: table-row-group !important;
      }
      @page {
        size: auto;
        margin: none;
      }
      .qr-code-wrap canvas {
        width: 128px !important;
        height: 128px !important;
      }
      .pagebreak {
        display: block;
        size: auto;
      }
      .invoice-details-wrap {
        padding: 0;
      }
      .ant-descriptions-item-content,
        .ant-descriptions-item-label {
          padding: 4px 12px !important;
        }
      }
      .ant-descriptions-header {
        margin: 4px 0 !important;
      }
      .models-title {
        direction:${currentLang == 'ar' ? 'rtl' : 'ltr'};
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .ant-table-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
      }
      th {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      .invoice-extra-prices-table .ant-table-cell {
        padding: 4px 12px !important;
        text-align: right;
      }
    `
  });

  const renderModalBody = () => {
    return (
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="A4 Invoice" key="1" forceRender={true}>
          <ReceiptDetailsComponentProducts
            ref={a4Ref}
            selectedReceipt={selectedReceipt}
          />
        </Tabs.TabPane>

        <Tabs.TabPane tab="A4 Invoice.V2" key="2" forceRender={true}>
          <ReceiptDetailsComponentV2Products
            ref={a4RefV2}
            selectedReceipt={selectedReceipt}
            currentLang={currentLang}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  };

  return (
    <Modal
      className="shared-custom-modal selected-inovice-modal"
      width="90%"
      style={{ maxWidth: '942px' }}
      title={
        <>
          {selectedReceipt?.type == 2 &&
            t('purchaseInvoices.invoiceDetailsReturn')}
          {selectedReceipt?.type == 1 && t('purchaseInvoices.invoiceDetails')}
          {selectedReceipt?.is_return == 2 && t('Invoices.isReturnd')}
          {/* <Tooltip title="طباعة الفاتورة"> */}
          <Tooltip title="">
            <Button
              onClick={handlePrint}
              className="circled-btn"
              type="dashed"
              shape="circle"
              icon={<PrinterOutlined />}
            />
          </Tooltip>
          <Tooltip title="Print A4 V2">
            <Button
              onClick={handlePrintA4V2}
              className="circled-btn"
              type="dashed"
              shape="circle"
              icon={<PrinterOutlined />}
            />
          </Tooltip>
        </>
      }
      visible={selectedReceiptModalOpened}
      onOk={() => {
        setSelectedReceiptModalOpened(false);
        setSelectedReceipt(null);
      }}
      onCancel={() => {
        setSelectedReceiptModalOpened(false);
        setSelectedReceipt(null);
      }}
      footer={false}
    >
      {renderModalBody()}

      {/* <pre style={{ direction: 'ltr' }}>
        {JSON.stringify(selectedReceipt, null, 2)}
      </pre> */}
    </Modal>
  );
};

export default SelectedReceiptModalProducts;
