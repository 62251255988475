import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import { CategoryPage } from '../../pages/category/CategoryPage';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import StoreRoutes from './store-routes/StoreRoutes';
import ArchiveRoutes from './archive-routes/ArchiveRoutes';
import SettingsRoutes from './settings-routes/SettingsRoutes';
// import { StatisticsProvider } from '../../contexts/statistics-context/StatisticsContext';
// const HomePage = lazy(() => import('../../pages/home-page/HomePage'));

const DataEntryRoleRoutes = () => {
  return (
    <Switch>
      {/* store routes */}
      {StoreRoutes()}
      {/* ./store routes */}

      {/* archive routes */}
      {ArchiveRoutes()}
      {/* archive routes */}

      {/* settings routes */}
      {SettingsRoutes()}
      {/* settings routes */}

      {/* not found page */}
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default DataEntryRoleRoutes;
