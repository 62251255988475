import { Button, Tooltip } from 'antd';
import React, { useState } from 'react';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { DeleteOutlined } from '@ant-design/icons';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RenderVariant from './RenderVariant';

const VariantsComponentV2 = (props) => {
  const { t } = useTranslation();

  let control = props?.control;
  const {
    fields: variantsFields2,
    append: appendVariantsField2,
    remove: removeVariantsField2
  } = useFieldArray({
    control,
    name: `variants2`
  });

  return (
    <div className="fields-array-ul-content">
      <ul className="fields-array-ul with-border">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <h2>{t('Inventory.Products.multiplicityDetails')}</h2>
          <div
            className="add-new-field-btn"
            onClick={() => {
              appendVariantsField2({
                id: '',
                value: ''
              });
            }}
          >
            <span>{t('Inventory.Products.addNewMultiType')}</span>
          </div>
        </div>
        <>
          {variantsFields2?.length > 0 &&
            variantsFields2?.map((item, index) => {
              return (
                // <li key={item?.id}>
                //     <RenderVariant item={item} index={index} control={control} setValue={props.setValue} watch={props.watch} errors={props.errors} allVariants={props.allVariants} variantsFields2={variantsFields2} removeVariantsField2={removeVariantsField2}/>
                // </li>
                <li key={index}>
                  <div className="field-delete-li">
                    <div className="select-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.multiplicityType')}
                      </p>
                      <div className="custom-select-wrap without-icon">
                        <AntdSelectOption
                          name={`variants2.${index}.id`}
                          // name={`multiProducts.${index}.variants.${index}.id`}
                          errorMsg={
                            props.errors?.variant?.id &&
                            props.errors.multiProducts[props.index]?.variant
                              .id &&
                            props.errors.multiProducts[props.index].variant.id
                              .message
                          }
                          validateStatus={
                            props.errors?.variant?.id &&
                            props.errors.multiProducts[props.index].variant.id
                              ? 'error'
                              : ''
                          }
                          control={control}
                          setValue={props.setValue}
                          placeholder={t('Inventory.Products.multiplicityType')}
                          options={
                            props.allVariants?.length > 0
                              ? props.allVariants.map((obj) => ({
                                  title: obj.name,
                                  value: String(obj.id)
                                }))
                              : null
                          }
                          formClassName="store-new-product-form"
                        />
                      </div>
                    </div>
                    <div className="text-field-label-wrap">
                      <p className="label-p">
                        {t('Inventory.Products.valueTypeMultiplicity')}
                      </p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          className="form-text-field"
                          name={`variants2.${index}.value`}
                          // name={`multiProducts.${index}.variants.${index}.value`}
                          type="text"
                          placeholder={t(
                            'Inventory.Products.valueTypeMultiplicityPlaceholder'
                          )}
                          errorMsg={
                            props.errors?.variant?.value &&
                            props.errors.multiProducts[props.index]?.variant
                              .value &&
                            props.errors.multiProducts[props.index].variant
                              .value.message
                          }
                          validateStatus={
                            props.errors?.variant?.value &&
                            props.errors.multiProducts[props.index]?.variant
                              .value &&
                            props.errors.multiProducts[props.index].variant
                              .value
                              ? 'error'
                              : ''
                          }
                          control={control}
                        />
                      </div>
                    </div>

                    {variantsFields2.length > 1 && (
                      <Tooltip title={t('Inventory.Products.delete')}>
                        <Button
                          className="delete-field-btn"
                          size="large"
                          type="dashed"
                          shape="circle"
                          icon={<DeleteOutlined />}
                          onClick={() => removeVariantsField2(index)}
                        />
                      </Tooltip>
                    )}
                  </div>
                </li>
              );
            })}
        </>
      </ul>
    </div>
  );
};

export default VariantsComponentV2;
