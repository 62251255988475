/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import getAllClientsApi from '../apis/clients-apis/getAllClientsApi';
import UserContext from '../contexts/user-context/UserProvider';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';

const useClients = () => {
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [allClientsFetchCount, setAllClientsFetchCount] = useState(null);
  const [allClients, setAllClients] = useState(null);
  const { user } = useContext(UserContext);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoadingClients(true);
      customApiRequest(
        getAllClientsApi(user?.token, false, false),
        (res) => {
          setIsLoadingClients(false);
          if (checkRes(res) && res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllClients(data);
          }
        },
        (error) => {
          setIsLoadingClients(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [allClientsFetchCount]);

  return {
    allClients,
    isLoadingClients,
    allClientsFetchCount,
    setAllClientsFetchCount
  };
};

export default useClients;
