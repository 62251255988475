import React, { useContext } from 'react';
import PageTable from './PageTable';
import './paymentDepartment.scss';
import PageModal from './PageModal';
import PaymentDepartmentContext from '../../contexts/payment-departments-context/PaymentDepartmentContext';
import { useTranslation } from 'react-i18next';
const PaymentDepartment = () => {
  const { setSelectedDepartment, modalOpened, setModalOpened } = useContext(
    PaymentDepartmentContext
  );
  const { t } = useTranslation();
  return (
    <div className="PaymentDepartment-page">
      <div className="page-head-wrap">
        <h2 className="bold-font">
          {t('SystemSettings.cashDisbursementAndReceiptDepartments')}
        </h2>
      </div>

      <div className="add-section">
        {/* <div className="section-text">
          <p className="bold-font">وحدات القياس</p>
        </div> */}
        <div
          className="add-account-btn"
          onClick={() => {
            // if selected unit then reset it
            // open the unit modal
            setSelectedDepartment(null);
            setModalOpened(true);
          }}
        >
          <span className="text-span">
            {t('SystemSettings.addNewDepartment')}
          </span>
        </div>
      </div>

      {/* <div className="custom-filter-add-section1">
        <div className="add-section">
          <div
            className="add-btn"
            onClick={() => {
              setSelectedInvoice(null);
              setStep(0);
              resetPos();
              history.push(routerLinks.posPage);
            }}
          >
            <span className="text-span">إضافة  فاتورة بيع جديدة</span>
          </div>
        </div>
        <TableFilter />
      </div> */}
      <PageTable />
      {modalOpened && <PageModal />}
      {/* {returnModalOpened && <ReturnModal />}

      {selectedSaleInvoiceModalOpned && <SelectedSaleInvoiceModal />}
      {laterInvoiceModalOpened && <LaterInvoiceModal />} */}
    </div>
  );
};

export default PaymentDepartment;
