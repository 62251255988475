import React, { useState } from 'react';
import LoginForm from './LoginForm';
import signinImg from '../../assets/imgs/signin/signin-img.png';
import './LoginPage.scss';
import Logo from '../../common/logo/Logo';
import OtpVerification from './OtpVerification';
import { useContext } from 'react';
import UserContext from '../../contexts/user-context/UserProvider';
import { useTranslation } from 'react-i18next';
const LoginPage = () => {
  const { isNewLoggedIn } = useContext(UserContext);
  const { t } = useTranslation();
  return (
    <div className="login-page">
      <div className="page-wrapper">
        <div className="form-top-level-wrapper">
          {/* <LoginForm /> */}
          {!isNewLoggedIn ? <LoginForm /> : <OtpVerification />}
        </div>
        <div className="img-wrap">
          {/* <img className="extra-logo-img" src={extraLogo} alt="extra logo" /> */}

          <Logo isExternalLink={true} className="extra-logo-img" />
          <div className="text-section">
            <h1>{t('LoginPage.welcome')}</h1>
            <div className="sp-line"></div>
            <p>{t('LoginPage.description')}</p>
          </div>
          <img className="main-img" src={signinImg} alt="signin" />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
