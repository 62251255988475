/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useRef } from 'react';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import { Select, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import ArrowDownIcon from '../../common/icons/ArrowDownIcon';
import BarcodeImg from '../../assets/imgs/icons/bx-barcode-reader.svg';
import Links from '../app/Links';
import debounce from 'lodash.debounce';
import { useCallback } from 'react';
import UserContext from '../../contexts/user-context/UserProvider';
import { useTranslation } from 'react-i18next';

// const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

const { Option } = Select;
const PosMainFilter = (props) => {
  const { t } = useTranslation();
  const routerLinks = Links();
  const history = useHistory();
  const autoFocusInput = useRef();
  const {
    loadingCats,
    setLoadingCats,
    selectedMainCategory,
    setSelectedMainCategory,
    productsFilter,
    setProductsFilter,
    allSubCategories,
    allMainCatgories,
    setProductBarCode,
    productBarCode,
    setItemCode,
    setBarCodeQuantity,
    setBarcodeModalOpened,
    searchTextState,
    setSearchTextState,
    setSelectedProducts,
    selectedProducts,
    allProducts
  } = useContext(PosInvoiceContext);
  const { posReportModalOpened } = useContext(UserContext);
  // const { setSelectedClientId } = useContext(ClientsContext);

  useEffect(() => {
    autoFocusInput.current.focus();
  }, [posReportModalOpened]);

  const handleChange = async (name, value) => {
    if (name === 'main_cat') {
      // setLoadingCats(true);
      setSelectedMainCategory(value);
      // await sleep(100);
      // setLoadingCats(false);
    } else if (name === 'sub_cat') {
      history.push(`${routerLinks?.posPage}?page=1`);
      setProductsFilter((prev) => ({
        ...prev,
        subCatId: value,
        page: 1
      }));
    } else {
      setLoadingCats(false);
    }
  };

  const renderSubCategories = () => {
    return allSubCategories.map((sub) => {
      return sub?.name ? (
        <li
          key={sub.id}
          className={`sub-cat-tag ${
            productsFilter?.subCatId == sub.id ? 'selected' : ''
          }`}
          onClick={() => {
            history.push(`${routerLinks?.posPage}?page=1`);
            setProductsFilter((prev) => ({
              ...prev,
              subCatId: String(sub.id)
            }));
          }}
        >
          <span className="sub-cat-name">{sub.name}</span>
          {productsFilter?.subCatId == sub.id && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                handleChange('sub_cat', '');
              }}
              className="remove-sub-cat-btn"
            >
              x
            </span>
          )}
        </li>
      ) : null;
    });
  };

  // const [searchTextState, setSearchTextState] = React.useState('');
  // const handleProductBarCodeChange = (e) => {
  //   setProductBarCode(e.target.value);
  // };

  const handleProductNameChange = (e) => {
    setSearchTextState(e.target.value);
    debouncedChangeHandler(e);
  };
  // const handleProudctNameChange = (e) => {};

  // const productBarCodeRef = useRef();
  // useEffect(() => {
  //   if (productBarCodeRef) {
  //     productBarCodeRef.current.focus();
  //   }
  // }, []);
  // useEffect(() => {} , [])
  const handleBarcodeNameSubmit = async (e) => {
    setSearchTextState(e.target.value);
    setProductBarCode('');
    setItemCode('');
    setBarCodeQuantity('');
    const scannedBarCode = productBarCode; //'2000001001550'
    let code = '';
    let weight = '';
    if (scannedBarCode && scannedBarCode?.length == 13) {
      code = scannedBarCode?.substr(2, 5); // '00001'
      weight = scannedBarCode?.substr(7, 5); // '00155'
      setItemCode(parseInt(code));
      setBarCodeQuantity(parseInt(weight) / 1000);
      history.push(`${routerLinks?.posPage}?page=1`);
      setProductsFilter((prev) => ({
        ...prev,
        searchText: e.target.value,
        parCode: parseInt(code)
        // parCode: barCodeState
      }));
      // if (productBarCodeRef) {
      //   productBarCodeRef.current.blur();
      // }
    } else {
      history.push(`${routerLinks?.posPage}?page=1`);
      setProductsFilter((prev) => ({
        ...prev,
        searchText: e.target.value,
        parCode: productBarCode
      }));
    }
  };

  const debouncedChangeHandler = useCallback(
    debounce(async (e) => {
      setSearchTextState(e.target.value);
      setProductBarCode('');
      setItemCode('');
      setBarCodeQuantity('');
      const scannedBarCode = productBarCode; //'2000001001550'
      let code = '';
      let weight = '';
      if (scannedBarCode && scannedBarCode?.length == 13) {
        code = scannedBarCode?.substr(2, 5); // '00001'
        weight = scannedBarCode?.substr(7, 5); // '00155'
        setItemCode(parseInt(code));
        setBarCodeQuantity(parseInt(weight) / 1000);
        history.push(`${routerLinks?.posPage}?page=1`);
        setProductsFilter((prev) => ({
          ...prev,
          searchText: e.target.value,
          parCode: parseInt(code)
          // parCode: barCodeState
        }));
        // if (productBarCodeRef) {
        //   productBarCodeRef.current.blur();
        // }
      } else {
        history.push(`${routerLinks?.posPage}?page=1`);
        setProductsFilter((prev) => ({
          ...prev,
          searchText: e.target.value,
          parCode: productBarCode
        }));
      }
    }, 800),
    []
  );

  // const handleClinetChange = (v) => {
  //   // const foundClient =
  //   //   allFetchedClients?.length > 0 && allFetchedClients.find((c) => c.id == v);
  //   // if (foundClient) setSelectedClient(foundClient);
  //   setSelectedClientId(v);
  // };

  return (
    <div className="barcode-btn-main-filter-wrapper">
      {/* <button
        className="barcode-modal-btn"
        onClick={() => {
          setBarcodeModalOpened(true);
        }}
        type="button"
      >
        <img src={BarcodeImg} alt="scan barcode" />
      </button> */}
      <div className="barcode-cats-wrap" {...props}>
        <div className="barcode-name-wrap">
          {/* <div className="select-label-wrap client-select-wrap">
          <p className="label-p">إختار العميــل</p>
          <div className="custom-select-wrap without-icon">
            <Select
              // onClear={hanldeClear}
              onChange={(v) => handleClinetChange(v)}
              size="large"
              suffixIcon={<ArrowDownIcon />}
              value={selectedClientId}
              // allowClear
              getPopupContainer={() =>
                document.querySelector('.cats-sub-cats-wrap')
              }
              showSearch={true}
              filterOption={(input, option) => {
                return (
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                );
              }}
            >
              {allFetchedClients?.length > 0 &&
                allFetchedClients.map((op, index) => (
                  <Option key={index} value={String(op.id)}>
                    {op.name}
                  </Option>
                ))}
            </Select>
          </div>
        </div> */}

          {/* <form
          onSubmit={handleBarcodeNameSubmit}
          className="product-barcode-form"
        >
          <label>
            <span>باركود المنتج</span>
            <input
              ref={productBarCodeRef}
              type="text"
              name="product_barcode"
              value={productBarCode}
              onChange={(e) => handleProductBarCodeChange(e)}
            />
          </label>
          <button className="shared">
            <SearchOutlined />
          </button>
        </form> */}
          <form
            onSubmit={(e) => e.preventDefault()}
            className="product-name-form"
            style={{ marginTop: 'auto' }}
          >
            <label>
              {/* <span style={{ color: 'transparent' }}>.</span> */}
              <input
                ref={autoFocusInput}
                type="text"
                name="search_product_name"
                placeholder={t('Pos.Main.enterProductNameOrCode')}
                // defaultValue={searchTextState}
                value={searchTextState}
                onChange={handleProductNameChange}
                style={{ fontSize: '12px' }}
              />
            </label>
            <button className="shared">
              <SearchOutlined />
            </button>
          </form>
        </div>

        {/* <div className="cats-tags-wrap">
          <div className="cats-sub-cats-wrap">
            <div className="select-label-wrap">
              <span className="label-p">الفئة الرئيسية</span>
              <div className="custom-select-wrap without-icon">
                <Select
                  // onClear={() => {
                  //   handleChange('main_cat', '');
                  //   handleChange('sub_cat', '');
                  // }}
                  onChange={(v) => handleChange('main_cat', v)}
                  size="large"
                  suffixIcon={<ArrowDownIcon />}
                  value={selectedMainCategory}
                  allowClear
                  getPopupContainer={() =>
                    document.querySelector('.cats-sub-cats-wrap')
                  }
                  showSearch={true}
                  filterOption={(input, option) => {
                    return (
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {allMainCatgories?.length > 0 &&
                    allMainCatgories.map((op, index) => (
                      <Option key={index} value={String(op.id)}>
                        {op.name}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>
            <div className="select-label-wrap">
              <span className="label-p">الفئة الفرعية</span>
              <div className="custom-select-wrap without-icon">
                <Select
                  // onClear={hanldeClear}
                  value={productsFilter?.subCatId}
                  onChange={(v) => handleChange('sub_cat', v)}
                  size="large"
                  suffixIcon={<ArrowDownIcon />}
                  // allowClear
                  getPopupContainer={() =>
                    document.querySelector('.cats-sub-cats-wrap')
                  }
                  showSearch={true}
                  filterOption={(input, option) => {
                    return (
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {allSubCategories?.length > 0 &&
                    allSubCategories.map((op, index) => (
                      <Option key={index} value={String(op.id)}>
                        {op.name}
                      </Option>
                    ))}
                </Select>
              </div>
            </div>
          </div>
        </div> */}

        {loadingCats ? (
          <Spin style={{ margin: '32px auto', width: '100%' }} />
        ) : allSubCategories?.length > 0 ? (
          <ul className="sub-cats-ul-wrap">{renderSubCategories()}</ul>
        ) : null}
      </div>
    </div>
  );
};

export default PosMainFilter;
