import axios from 'axios';

const addNewSupplierApi = async (token, values, edit = false) => {
  try {
    let url;
    if (!edit) url = '/Supplier/create';
    else url = '/Supplier/update';

    const res = await axios.post(url, values, {
      headers: {
        lang: 'ar',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default addNewSupplierApi;
