/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext } from 'react';
import UpgradeContext from '../../contexts/upgrade-context/UpgradeContext';
import DocTitleScrollTop from '../../utils/DocTitleScrollTop';
import debounce from 'lodash.debounce';
import { LoadingOutlined } from '@ant-design/icons';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import errorNotification from '../../utils/errorNotification';
import successNotification from '../../utils/successNotification';
import upgradeUserCheckCodeApi from '../../apis/upgrade/upgradePackageCheckCode';
import UserContext from '../../contexts/user-context/UserProvider';
import { useTranslation } from 'react-i18next';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
const PosCount = () => {
  DocTitleScrollTop();
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const systemSettings = useSystemSettings();
  const {
    selectedPackage,
    setPaymentVisible,
    paymentVisible,
    setPosNum,
    posNum,
    fetchedPackages,
    discountCode,
    setDiscountCode,
    isCheckingDiscountCode,
    setIsCheckingDiscountCode,
    checkCodeRes,
    setCheckCodeRes,
    // setTotal,
    total
  } = useContext(UpgradeContext);
  const handleClick = (type) => {
    if (type === 'plus') {
      setPosNum((prev) => prev + 1);
    } else if (type === 'minus' && posNum > 0) {
      setPosNum((prev) => prev - 1);
    }
  };

  const customApiRequest = useCustomApiRequest();
  const checkDiscountCode = () => {
    setIsCheckingDiscountCode(true);
    customApiRequest(
      upgradeUserCheckCodeApi({
        code: discountCode,
        package_id: selectedPackage?.id,
        subscription_type: systemSettings?.subscriptionType,
        phone: systemSettings?.phone,
        ref_key: systemSettings?.ref_key
      }),
      (res) => {
        setIsCheckingDiscountCode(false);
        if (
          res?.status === 200 &&
          res?.data?.response_code === 200 &&
          res?.data?.success &&
          res?.data?.data
        ) {
          setCheckCodeRes(res.data.data);
          // setPaymentVisible(true);
          successNotification({
            title: t('FatoorahPackages.operationCompletedSuccessfully'),
            message: t('FatoorahPackages.codeActivatedSuccessfully')
          });
        } else {
          errorNotification({
            title: t('FatoorahPackages.errorOccurred'),
            message: res?.data?.message || t('FatoorahPackages.tryLater')
          });
        }
      },
      (error) => {
        setIsCheckingDiscountCode(false);
        errorNotification({
          title: t('FatoorahPackages.errorOccurred'),
          message:
            error?.response?.data?.message || t('FatoorahPackages.tryLater')
        });
      }
    );
  };

  const changeHandler = async (event) => {
    setDiscountCode(event.target.value);
  };
  React.useEffect(() => {
    if (discountCode) checkDiscountCode();
  }, [discountCode]);

  // const debouncedCallback = debounce(callback, waitTime);
  const debouncedChangeHandler = useCallback(debounce(async (event) => {
    setDiscountCode(event.target.value);
  }, 500), []);

  if (!paymentVisible && selectedPackage)
    return (
      <div>
        {user?.subscriptionType === 1 && (
          <div className="pos-count-wrap">
            <div className="btns-labels-wrap">
              <span className="pos-label">
                {t('FatoorahPackages.increaseSalePoint')}
              </span>
              <div className="plus-minus-wrap">
                <div
                  className="plus-btn"
                  onClick={() => {
                    handleClick('plus');
                  }}
                >
                  <span>+</span>
                </div>
                <span className="pos-number">{posNum}</span>
                <div
                  className="minus-btn"
                  onClick={() => {
                    handleClick('minus');
                  }}
                >
                  <span>-</span>
                </div>
              </div>

              <div className="pos-price-wrap">
                <span className="price-span">
                  {fetchedPackages?.setting?.pos_price}
                </span>
                <span className="unit-span">{t('FatoorahPackages.sar')}</span>
              </div>
            </div>

            <div className="total-labels-values-wrap">
              <div className="labels-wrap">
                <span>{t('FatoorahPackages.salePoint')}</span>
                <span className="pos-count">{posNum}</span>
                <span>{t('FatoorahPackages.salePoints')}</span>
              </div>
              <div className="values-wrap">
                <span>{t('FatoorahPackages.tatal')}</span>
                <span>{total}</span>
                <span>{t('FatoorahPackages.sar')}</span>
              </div>
            </div>
          </div>
        )}

        <div className="continue-back-btns-wrap">
          <div className="code-continue-btn-wrap">
            <label>
              <span>{t('FatoorahPackages.discountCode')}</span>
              <input
                type="text"
                name="code"
                // value={discountCode}
                // onChange={(e) => setDiscountCode(e.target.value)}
                onChange={(e) => debouncedChangeHandler(e)}
              />
            </label>
            <div className="continue-back-btns-wrap">
              <button
                className="continue-btn"
                onClick={() => setPaymentVisible(true)}
                // onClick={() => {
                //   if (discountCode) {
                //     checkDiscountCode();
                //   } else {
                //     setPaymentVisible(true);
                //   }
                // }}
                disabled={
                  isCheckingDiscountCode || (discountCode && !checkCodeRes)
                }
              >
                {t('FatoorahPackages.followProcess')}
                {isCheckingDiscountCode ? <LoadingOutlined /> : null}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  return null;
};

export default PosCount;
