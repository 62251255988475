/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
import { useState, useEffect, createContext } from 'react';
import {
  calcProductIngredientsPrice,
  calcProductTotalPriceWithDiscount,
  calcProductTotalPriceWithDiscounWithTax,
  calcProductTax
} from './calcProductPrice';

import { calcProductNetPrice } from './calcProductPrice-old-without-product-discount';
const INITIAL_VALUES = {
  loadingInvoice: false,
  setLoadingInvoice: (v) => {},
  systemSettings: null,
  setSystemSettings: (v) => {},
  systemTax: 0,
  setSystemTax: (v) => {},
  loadingCats: false,
  setLoadingCats: (v) => {},
  allMainCatgories: null,
  setAllMainCategories: (v) => {},
  allSubCategories: null,
  setAllSubCategories: (v) => {},
  selectedMainCategory: null,
  setSelectedMainCategory: (v) => {},
  selectedSubCategory: null,
  setSelectedSubCategory: (v) => {},
  productBarCode: '',
  setProductBarCode: (v) => {},
  itemCode: '',
  setItemCode: (v) => {},
  barCodeQuantity: '',
  setBarCodeQuantity: (v) => {},
  barcodeModalOpened: false,
  setBarcodeModalOpened: (v) => {},
  productSearchText: '',
  setProductSearchText: (v) => {},
  fetchProductsCount: 0,
  setFetchProductsCount: (v) => {},
  loadingProducts: false,
  setLoadingProducts: (v) => {},
  allProducts: [],
  setAllProducts: (v) => {},
  selectedProducts: [],
  productsFilter: {
    subCatId: '',
    parCode: '',
    searchText: '',
    parent_cat: '',
    page: 1
  },
  setProductsFilter: (v) => {},
  //
  productsPagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setProductsPagination: (v) => {},
  //
  foundProduct: null,
  setFoundProduct: (v) => {},
  setSelectedProducts: (v) => {},
  invoiceTotalProductsPrice: 0,
  setInvoiceTotalProductsPrice: (v) => {},
  invoiceTotalProductsNetPrice: 0,
  setInvoiceTotalProductsNetPrice: (v) => {},
  invoiceTotalProductsPriceAfterDiscount: 0,
  setInvoiceTotalProductsPriceAfterDiscount: (v) => {},
  invoiceTotalProductsPriceAfterTax: 0,
  setInvoiceTotalProductsPriceAfterTax: (v) => {},
  invoiceTotalAfterExtraPrices: 0,
  setInvoiceTotalAfterExtraPrices: (v) => {},
  //
  invoiceTotalPriceBeforeDiscount: 0,
  setInvoiceTotalPriceBeforeDiscount: (v) => {},
  invoiceDiscountType: '0',
  setInvoiceDiscountType: (v) => {},
  invoiceDiscountValue: 0,
  setInvoiceDiscountValue: (v) => {},
  invoiceDiscountPrice: 0,
  setInvoiceDiscountPrice: (v) => {},
  invoiceTotalPriceAfterDiscount: 0,
  setInvoiceTotalPriceAfterDiscount: (v) => {},
  invoiceTotalPriceAfterTax: 0,
  setInvoiceTotalPriceAfterTax: (v) => {},
  //
  laterInvoiceModalOpened: false,
  setLaterInvoiceModalOpened: (v) => {},
  //
  clientId: '',
  setClientId: (v) => {},
  invoiceNote: '',
  setInvoiceNote: (v) => {},
  invoiceDeferredPayment: '',
  setInvoiceDeferredPayment: (v) => {},
  invoiceBankPayment: '',
  setInvoiceBankPayment: (v) => {},
  invoiceMultiplePaymentCash: '',
  setInvoiceMultiplePaymentCash: (v) => {},
  invoiceMultiplePaymentVisa: '',
  setInvoiceMultiplePaymentVisa: (v) => {},
  invoiceExtraPrices: [],
  setInvoiceExtraPrices: (v) => {},
  invoicePayType: '',
  setInvoicePayType: (v) => {},
  //
  productDiscountModalOpened: false,
  setproductDiscountModalOpened: (v) => {},
  productIngredientsModalOpened: false,
  setproductIngredientsModalOpened: (v) => {},
  laterInvoiceName: '',
  setLaterInvoiceName: (v) => {},
  laterInvoicePhone: '',
  setLoaterInvoicePhone: (v) => {},
  laterInvoiceAddress: '',
  setLaterInvoiceAddress: (v) => {},
  laterInvoiceDueDate: null,
  setLaterInvoiceDueDate: (v) => {},
  //
  selectedInvoice: null,
  setSelectedInvoice: (v) => {},
  // all invoices
  isLoadingAllSaleInvoices: false,
  setIsLoadingAllSaleInvoices: (v) => {},
  fetchCountSaleInvoices: 0,
  setFetchCountSaleInvoices: (v) => {},
  allFetchedSaleInvoices: [],
  setAllFetchedSaleInvoice: (v) => {},
  selectedSaleInvoiceModalOpned: false,
  setSelectedSaleInvoiceModalOpened: (v) => {},
  //
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {},
  // filter
  tableFilter: {
    page: 1,
    user_id: null,
    type: null,
    serviceOrPackage: null,
    from: '',
    to: ''
  },
  setTableFilter: (v) => {},
  //

  //
  isSubmittingOrder: false,
  setIsSubmittingOrder: (v) => {},
  successOrder: null,
  setSucessOrder: (v) => {},
  //
  resetPos: () => {},
  totalTax: '',
  setTotalTax: (v) => {},
  resetPosStepOne: false,
  setResetPosStepOne: (v) => {}
};

const PosInvoiceContext = createContext(INITIAL_VALUES);

export const PosInvoiceProvider = ({ children }) => {
  const [resetPosStepOne, setResetPosStepOne] = useState(
    INITIAL_VALUES.resetPosStepOne
  );
  const [loadingInvoice, setLoadingInvoice] = useState(
    INITIAL_VALUES.loadingInvoice
  );
  const [systemTax, setSystemTax] = useState(INITIAL_VALUES.systemTax);
  const [systemSettings, setSystemSettings] = useState(
    INITIAL_VALUES.systemSettings
  );
  const [loadingCats, setLoadingCats] = useState(INITIAL_VALUES.loadingCats);
  const [allMainCatgories, setAllMainCategories] = useState(
    INITIAL_VALUES.allMainCatgories
  );
  const [allSubCategories, setAllSubCategories] = useState(
    INITIAL_VALUES.allMainCatgories
  );
  const [selectedMainCategory, setSelectedMainCategory] = useState(
    INITIAL_VALUES.selectedMainCategory
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    INITIAL_VALUES.selectedSubCategory
  );
  const [productBarCode, setProductBarCode] = useState(
    INITIAL_VALUES.productBarCode
  );
  const [itemCode, setItemCode] = useState(INITIAL_VALUES.itemCode);
  const [barCodeQuantity, setBarCodeQuantity] = useState(
    INITIAL_VALUES.barCodeQuantity
  );
  const [barcodeModalOpened, setBarcodeModalOpened] = useState(
    INITIAL_VALUES?.barcodeModalOpened
  );
  const [productSearchText, setProductSearchText] = useState(
    INITIAL_VALUES.productSearchText
  );
  const [fetchProductsCount, setFetchProductsCount] = useState(
    INITIAL_VALUES.fetchProductsCount
  );
  const [loadingProducts, setLoadingProducts] = useState(
    INITIAL_VALUES.loadingProducts
  );
  //
  const [allProducts, setAllProducts] = useState(INITIAL_VALUES.allProducts);
  const [productsFilter, setProductsFilter] = useState(
    INITIAL_VALUES?.productsFilter
  );
  const [productsPagination, setProductsPagination] = useState(
    INITIAL_VALUES.productsPagination
  );

  const [selectedProducts, setSelectedProducts] = useState(
    INITIAL_VALUES.selectedProducts
  );
  const [foundProduct, setFoundProduct] = useState(INITIAL_VALUES.foundProduct);
  const [invoiceTotalProductsPrice, setInvoiceTotalProductsPrice] = useState(
    INITIAL_VALUES.invoiceTotalProductsPrice
  );
  const [invoiceTotalProductsNetPrice, setInvoiceTotalProductsNetPrice] =
    useState(INITIAL_VALUES.invoiceTotalProductsPrice);
  const [
    invoiceTotalProductsPriceAfterDiscount,
    setInvoiceTotalProductsPriceAfterDiscount
  ] = useState(INITIAL_VALUES.invoiceTotalProductsPriceAfterDiscount);
  const [
    invoiceTotalProductsPriceAfterTax,
    setInvoiceTotalProductsPriceAfterTax
  ] = useState(INITIAL_VALUES.invoiceTotalProductsPriceAfterTax);
  const [invoiceTotalAfterExtraPrices, setInvoiceTotalAfterExtraPrices] =
    useState(INITIAL_VALUES.invoiceTotalAfterExtraPrices);
  const [invoiceTotalPriceBeforeDiscount, setInvoiceTotalPriceBeforeDiscount] =
    useState(INITIAL_VALUES.invoiceTotalPriceBeforeDiscount);
  const [invoiceDiscountType, setInvoiceDiscountType] = useState(
    INITIAL_VALUES.invoiceDiscountType
  );
  const [invoiceDiscountValue, setInvoiceDiscountValue] = useState(
    INITIAL_VALUES.invoiceDiscountType
  );
  const [invoiceDiscountPrice, setInvoiceDiscountPrice] = useState(
    INITIAL_VALUES.invoiceDiscountPrice
  );
  const [invoiceTotalPriceAfterDiscount, setInvoiceTotalPriceAfterDiscount] =
    useState(INITIAL_VALUES.invoiceTotalPriceAfterDiscount);
  const [invoiceTotalPriceAfterTax, setInvoiceTotalPriceAfterTax] = useState(
    INITIAL_VALUES.invoiceTotalPriceAfterTax
  );

  const [productDiscountModalOpened, setproductDiscountModalOpened] = useState(
    INITIAL_VALUES.productDiscountModalOpened
  );
  const [productIngredientsModalOpened, setproductIngredientsModalOpened] =
    useState(INITIAL_VALUES.productIngredientsModalOpened);
  //

  const [invoiceNote, setInvoiceNote] = useState(INITIAL_VALUES.invoiceNote);

  const [invoiceDeferredPayment, setInvoiceDeferredPayment] = useState(
    INITIAL_VALUES.invoiceDeferredPayment
  );
  const [invoiceBankPayment, setInvoiceBankPayment] = useState(
    INITIAL_VALUES.invoiceBankPayment
  );

  const [invoiceMultiplePaymentCash, setInvoiceMultiplePaymentCash] = useState(
    INITIAL_VALUES.invoiceMultiplePaymentCash
  );
  const [invoiceMultiplePaymentVisa, setInvoiceMultiplePaymentVisa] = useState(
    INITIAL_VALUES.invoiceMultiplePaymentVisa
  );
  const [invoiceExtraPrices, setInvoiceExtraPrices] = useState(
    INITIAL_VALUES.invoiceExtraPrices
  );
  const [invoicePayType, setInvoicePayType] = useState(
    INITIAL_VALUES.invoicePayType
  );
  const [clientId, setClientId] = useState(INITIAL_VALUES.clientId);
  //
  const [laterInvoiceModalOpened, setLaterInvoiceModalOpened] = useState(
    INITIAL_VALUES.laterInvoiceModalOpened
  );
  const [laterInvoiceName, setLaterInvoiceName] = useState(
    INITIAL_VALUES.laterInvoiceName
  );
  const [laterInvoicePhone, setLoaterInvoicePhone] = useState(
    INITIAL_VALUES.laterInvoicePhone
  );
  const [laterInvoiceAddress, setLaterInvoiceAddress] = useState(
    INITIAL_VALUES.laterInvoiceAddress
  );
  const [laterInvoiceDueDate, setLaterInvoiceDueDate] = useState(
    INITIAL_VALUES.laterInvoiceDueDate
  );
  //

  const [selectedInvoice, setSelectedInvoice] = useState(
    INITIAL_VALUES.selectedInvoice
  );
  //
  const [isLoadingAllSaleInvoices, setIsLoadingAllSaleInvoices] = useState(
    INITIAL_VALUES.isLoadingAllSaleInvoices
  );
  const [fetchCountSaleInvoices, setFetchCountSaleInvoices] = useState(
    INITIAL_VALUES.fetchCountSaleInvoices
  );
  const [allFetchedSaleInvoices, setAllFetchedSaleInvoice] = useState(
    INITIAL_VALUES.allFetchedSaleInvoices
  );
  const [selectedSaleInvoiceModalOpned, setSelectedSaleInvoiceModalOpened] =
    useState(INITIAL_VALUES.selectedSaleInvoiceModalOpned);
  //

  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES?.tablePagination
  );
  const [tableFilter, setTableFilter] = useState(INITIAL_VALUES?.tableFilter);

  const [isSubmittingOrder, setIsSubmittingOrder] = useState(
    INITIAL_VALUES.isSubmittingOrder
  );
  const [successOrder, setSucessOrder] = useState(INITIAL_VALUES.successOrder);
  const [searchTextState, setSearchTextState] = useState('');
  const [totalTax, setTotalTax] = useState(INITIAL_VALUES.totalTax);

  // calc products net price with ingredients
  useEffect(() => {
    if (selectedProducts?.length > 0) {
      let total = 0;
      for (let item of selectedProducts) {
        total +=
          calcProductNetPrice(item) +
          calcProductIngredientsPrice(item?.productIngredients);
      }
      setInvoiceTotalProductsNetPrice(total.toFixed(2));
    }
  }, [selectedProducts, selectedProducts?.length]);

  // handle products discount
  useEffect(() => {
    if (selectedProducts?.length > 0) {
      let total = 0;
      for (let item of selectedProducts) {
        total += calcProductTotalPriceWithDiscount(
          item,
          item?.productIngredients,
          item?.productDiscountType,
          item?.productDiscountValue
        );
      }
      setInvoiceTotalProductsPriceAfterDiscount(total.toFixed(2));
    }
  }, [selectedProducts, selectedProducts?.length]);

  // handle products Tax
  useEffect(() => {
    if (selectedProducts?.length > 0) {
      let total = 0;
      for (let item of selectedProducts) {
        total += calcProductTax(
          item,
          item?.productDiscountType,
          item?.productDiscountValue,
          item?.tax
        );
      }
      setTotalTax(total.toFixed(2));
    }
  }, [selectedProducts, selectedProducts?.length]);

  // handle products total price after product discount and tax
  useEffect(() => {
    if (selectedProducts?.length > 0) {
      let total = 0;
      for (let item of selectedProducts) {
        // let check = item?.product_type != 2;
        total += calcProductTotalPriceWithDiscounWithTax(
          item,
          item?.productIngredients,
          item?.productDiscountType,
          item?.productDiscountValue,
          item?.tax
        );
      }

      setInvoiceTotalProductsPriceAfterTax(total.toFixed(2));
    }
  }, [selectedProducts, selectedProducts?.length]);

  // handle invoice extra prices
  useEffect(() => {
    let total = 0;
    if (selectedProducts?.length > 0) {
      for (let item of selectedProducts) {
        total += calcProductTotalPriceWithDiscounWithTax(
          item,
          item?.productIngredients,
          item?.productDiscountType,
          item?.productDiscountValue,
          item?.tax
        );
      }
      if (
        parseFloat(invoiceTotalProductsPriceAfterTax) > 0 &&
        invoiceExtraPrices?.length > 0
      ) {
        for (let extraPriceObj of invoiceExtraPrices) {
          if (parseFloat(extraPriceObj.price) > 0 && extraPriceObj.desc) {
            total = parseFloat(total) + parseFloat(extraPriceObj.price);
          }
        }
      }
    }
    setInvoiceTotalAfterExtraPrices(total.toFixed(2));
  }, [selectedProducts, selectedProducts?.length, invoiceExtraPrices]);

  const handleResetPos = () => {
    setResetPosStepOne(true);
    setFetchProductsCount((prev) => prev + 1);
    setSelectedInvoice(INITIAL_VALUES.selectedInvoice);
    setSelectedProducts(INITIAL_VALUES.selectedProducts);
    setLaterInvoiceName(INITIAL_VALUES.laterInvoiceName);
    setLoaterInvoicePhone(INITIAL_VALUES.laterInvoicePhone);
    setLaterInvoiceAddress(INITIAL_VALUES.laterInvoiceAddress);
    setLaterInvoiceDueDate(INITIAL_VALUES.laterInvoiceDueDate);
    setInvoiceExtraPrices(INITIAL_VALUES.invoiceExtraPrices);
    setInvoiceTotalProductsPrice(INITIAL_VALUES.invoiceTotalProductsPrice);
    setInvoiceTotalProductsNetPrice(
      INITIAL_VALUES.invoiceTotalProductsNetPrice
    );
    setInvoiceTotalProductsPriceAfterDiscount(
      INITIAL_VALUES.invoiceTotalProductsPriceAfterDiscount
    );
    setInvoiceTotalPriceBeforeDiscount(
      INITIAL_VALUES.invoiceTotalPriceBeforeDiscount
    );
    setInvoiceDiscountType(INITIAL_VALUES.invoiceDiscountType);
    setInvoiceDiscountValue(INITIAL_VALUES.invoiceDiscountValue);
    setInvoiceDiscountPrice(invoiceDiscountPrice);
    setInvoiceTotalPriceAfterDiscount(
      INITIAL_VALUES.invoiceTotalPriceAfterDiscount
    );
    setInvoiceTotalPriceAfterTax(INITIAL_VALUES.invoiceTotalPriceAfterTax);
    setClientId(INITIAL_VALUES.clientId);
    setInvoiceNote(INITIAL_VALUES.invoiceNote);
    setInvoiceBankPayment(INITIAL_VALUES.invoiceBankPayment);
    setInvoiceDeferredPayment(INITIAL_VALUES.invoiceDeferredPayment);
    setInvoiceMultiplePaymentCash(INITIAL_VALUES.invoiceMultiplePaymentCash);
    setInvoiceMultiplePaymentVisa(INITIAL_VALUES.invoiceMultiplePaymentVisa);
    setInvoicePayType(INITIAL_VALUES.invoicePayType);
    setProductBarCode(INITIAL_VALUES?.productBarCode);
    setItemCode(INITIAL_VALUES?.itemCode);
    setBarCodeQuantity(INITIAL_VALUES?.barCodeQuantity);
  };

  return (
    <PosInvoiceContext.Provider
      value={{
        resetPosStepOne,
        setResetPosStepOne,
        loadingInvoice,
        setLoadingInvoice,
        systemSettings,
        setSystemSettings,
        systemTax,
        setSystemTax,
        loadingCats,
        setLoadingCats,
        allMainCatgories,
        setAllMainCategories,
        allSubCategories,
        setAllSubCategories,
        selectedMainCategory,
        setSelectedMainCategory,
        selectedSubCategory,
        setSelectedSubCategory,
        productBarCode,
        setProductBarCode,
        itemCode,
        setItemCode,
        barCodeQuantity,
        setBarCodeQuantity,
        barcodeModalOpened,
        setBarcodeModalOpened,
        productSearchText,
        setProductSearchText,
        fetchProductsCount,
        setFetchProductsCount,
        loadingProducts,
        setLoadingProducts,
        allProducts,
        setAllProducts,
        productsFilter,
        setProductsFilter,
        productsPagination,
        setProductsPagination,
        selectedProducts,
        setSelectedProducts,
        foundProduct,
        setFoundProduct,
        invoiceTotalProductsPrice,
        setInvoiceTotalProductsPrice,
        invoiceTotalProductsNetPrice,
        setInvoiceTotalProductsNetPrice,
        invoiceTotalProductsPriceAfterDiscount,
        setInvoiceTotalProductsPriceAfterDiscount,
        invoiceTotalProductsPriceAfterTax,
        setInvoiceTotalProductsPriceAfterTax,
        invoiceTotalAfterExtraPrices,
        setInvoiceTotalAfterExtraPrices,
        invoiceTotalPriceBeforeDiscount,
        setInvoiceTotalPriceBeforeDiscount,
        invoiceDiscountType,
        setInvoiceDiscountType,
        invoiceDiscountValue,
        setInvoiceDiscountValue,
        invoiceDiscountPrice,
        setInvoiceDiscountPrice,
        invoiceTotalPriceAfterDiscount,
        setInvoiceTotalPriceAfterDiscount,
        invoiceTotalPriceAfterTax,
        setInvoiceTotalPriceAfterTax,
        productDiscountModalOpened,
        setproductDiscountModalOpened,
        productIngredientsModalOpened,
        setproductIngredientsModalOpened,
        //
        invoiceNote,
        setInvoiceNote,
        invoiceMultiplePaymentCash,
        setInvoiceMultiplePaymentCash,
        invoiceDeferredPayment,
        setInvoiceDeferredPayment,
        invoiceBankPayment,
        setInvoiceBankPayment,
        invoiceMultiplePaymentVisa,
        setInvoiceMultiplePaymentVisa,
        invoiceExtraPrices,
        setInvoiceExtraPrices,
        invoicePayType,
        setInvoicePayType,
        clientId,
        setClientId,
        //
        laterInvoiceModalOpened,
        setLaterInvoiceModalOpened,
        laterInvoiceName,
        setLaterInvoiceName,
        laterInvoicePhone,
        setLoaterInvoicePhone,
        laterInvoiceAddress,
        setLaterInvoiceAddress,
        laterInvoiceDueDate,
        setLaterInvoiceDueDate,
        //
        selectedInvoice,
        setSelectedInvoice,
        //
        isLoadingAllSaleInvoices,
        setIsLoadingAllSaleInvoices,
        fetchCountSaleInvoices,
        setFetchCountSaleInvoices,
        allFetchedSaleInvoices,
        setAllFetchedSaleInvoice,
        selectedSaleInvoiceModalOpned,
        setSelectedSaleInvoiceModalOpened,
        tablePagination,
        setTablePagination,
        tableFilter,
        setTableFilter,
        //
        isSubmittingOrder,
        setIsSubmittingOrder,
        successOrder,
        setSucessOrder,
        resetPos: handleResetPos,
        //
        searchTextState,
        setSearchTextState,
        totalTax,
        setTotalTax
      }}
    >
      {children}
    </PosInvoiceContext.Provider>
  );
};

export default PosInvoiceContext;
