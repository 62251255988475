import axios from 'axios';

const registerComplexTreeApi = async (client_id , token) => {
  let url = `accounting/settings/generate_accounts_complex?client_id=${client_id}`

  try {
    // let url;
    // if (!edit) url = '/AccountTree/create';
    // else url = '/AccountTree/update';
    const res = await axios({
      method: 'post',
      url : url,
      baseURL: process.env.REACT_APP_BASE_TEST_URL,
      // baseURL: 'https://testclient-api.fatoorah.sa/apiAdmin',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token,
          lang: 'ar'
      },
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default registerComplexTreeApi;
