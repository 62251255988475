import { createContext, useState } from 'react';

const INITIAL_VALUES = {
  fetchReportCount: 0,
  setFetchReportCount: (v) => {},
  posReportModalOpened: false,
  setPosReportModalOpened: (v) => {},
  posReportIsLoading: false,
  setPosReportIsLoading: (v) => {},
  posReport: null,
  setPosReport: (v) => {}
};

const PosReportContext = createContext(INITIAL_VALUES);

export const PosReportProvider = ({ children }) => {
  const [posReportModalOpened, setPosReportModalOpened] = useState(
    INITIAL_VALUES.posReportModalOpened
  );
  const [posReport, setPosReport] = useState(INITIAL_VALUES.posReport);
  const [posReportIsLoading, setPosReportIsLoading] = useState(
    INITIAL_VALUES.posReportIsLoading
  );
  const [fetchReportCount, setFetchReportCount] = useState(
    INITIAL_VALUES.fetchReportCount
  );
  return (
    <PosReportContext.Provider
      value={{
        fetchReportCount,
        setFetchReportCount,
        posReportModalOpened,
        setPosReportModalOpened,
        posReportIsLoading,
        setPosReportIsLoading,
        posReport,
        setPosReport
      }}
    >
      {children}
    </PosReportContext.Provider>
  );
};

export default PosReportContext;
