import axios from 'axios';

const CreateSaleInvoicePosApi = async (token, values, edit = false) => {
  try {
    let url = '/saleInvoice/create';
    // if (!edit) url = '/saleInvoice/create';
    // else url = '/saleInvoice/update';
    const res = await axios({
      method: 'post',
      baseURL: process.env.REACT_APP_BASE_URL_POS,
      url,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      },
      data: values
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default CreateSaleInvoicePosApi;
