import { useController } from 'react-hook-form';
import { Form, Checkbox } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';

const AntdCheckbox = ({
  control,
  name,
  label,
  validateStatus,
  errorMsg,
  checked
}) => {
  const {
    // field: { ...inputProps }
    field
  } = useController({
    name,
    control
  });

  return (
    <Form.Item help={errorMsg} validateStatus={validateStatus} colon={false}>
      <Checkbox
        {...field}
        // checked={field.value}
        // defaultChecked={defaultChecked ? defaultChecked : ''}
        checked={checked ? checked : null}
      >
        {label}
      </Checkbox>
    </Form.Item>
  );
};

export default AntdCheckbox;
