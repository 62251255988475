import React, { useContext } from 'react';
import { Button, Tooltip, Popconfirm } from 'antd';
import {
  ScanOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined
} from '@ant-design/icons';
import UserContext from '../../contexts/user-context/UserProvider';
import TagsContext from '../../contexts/nfcTags-context/tagsContext';
import deleteTag from '../../apis/nfcTags-api/deleteTagApi';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';

const TagsColumns = (t) => {
  const { user } = useContext(UserContext);
  const {
    setFetchCount,
    tagsData,
    setSelectedTag,
    setIsModalOpend,
    setQrCodeModalOpened
  } = useContext(TagsContext);
  const data = tagsData?.length > 0 ? tagsData : [];

  const showQRCode = (record) => {
    const found = data.find((item) => item.id === record.id);
    if (found) {
      setSelectedTag({ ...found });
      setQrCodeModalOpened(true);
    }
  };
  const handleDeleteTag = async (id) => {
    try {
      const deleteRes = await deleteTag(user?.token, id);
      if (deleteRes?.status == 200) {
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Messages.addedSuccessfully'),
          message: t('Messages.DeletedSuccessfully')
        });
      } else {
        errorNotification({
          title: t('Messages.ErrorOccurred'),
          message: deleteRes?.data?.message
            ? deleteRes.data.message
            : t('Messages.TryAgainLater')
        });
      }
    } catch (error) {}
  };

  // const data = tagsData?.length > 0 ? tagsData : [];
  const handleUpdateTag = (record) => {
    const found = data.find((item) => item.id === record.id);
    if (found) {
      setSelectedTag({ ...found });
      setIsModalOpend(true);
    }
  };
  return [
    {
      title: '#',
      dataIndex: 'index',
      // width: 62
      render: (_, record) => {
        return record?.index;
      }
    },
    {
      title: t('NFCTags.tagName'),
      dataIndex: 'name',
      // width: 62
      render: (_, record) => {
        return record?.tag;
      }
    },
    {
      title: t('NFCTags.posName'),
      dataIndex: 'name',
      // width: 62
      render: (_, record) => {
        return record?.pos?.name;
      }
    },
    {
      title: t('NFCTags.address'),
      dataIndex: 'address',
      // width: 62
      render: (_, record) => {
        return record?.pos?.address ? record?.pos?.address : '';
      }
    },
    {
      title: t('NFCTags.browse'),
      dataIndex: 'browse',
      // width: 62
      render: (_, record) => {
        return record?.browse;
      }
    },
    {
      title: t('NFCTags.download'),
      dataIndex: 'download',
      // width: 62
      render: (_, record) => {
        return record?.download;
      }
    },
    {
      title: t('NFCTags.shares'),
      dataIndex: 'shares',
      // width: 62
      render: (_, record) => {
        return record?.shares;
      }
    },

    {
      title: t('NFCTags.invoices'),
      dataIndex: 'invoices',
      // width: 62
      render: (_, record) => {
        return record?.invoices;
      }
    },
    {
      title: t('NFCTags.QRcode'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            <Tooltip title={t('NFCTags.QRcode')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<ScanOutlined />}
                onClick={() => showQRCode(record)}
              />
            </Tooltip>
          </div>
        );
      }
    },
    // {
    //   title: "معاينة",
    //   dataIndex: 'id',
    //   // width: 62
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-circled-btn">
    //       <Tooltip title="معاينة">
    //         <Button
    //           className="circled-btn"
    //           type="dashed"
    //           shape="circle"
    //           icon={<EyeOutlined />}
    //           /* onClick={()=>} */
    //         />
    //       </Tooltip>
    //     </div>
    //     );
    //   }
    // },
    {
      title: t('NFCTags.update'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            <Tooltip title={t('NFCTags.update')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => {
                  handleUpdateTag(record);
                }}
              />
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: t('NFCTags.delete'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            <Popconfirm
              title={t('NFCTags.conformDelete')}
              okText={t('NFCTags.yes')}
              cancelText={t('NFCTags.no')}
              onConfirm={() => handleDeleteTag(record?.id)}
            >
              <Tooltip title={t('NFCTags.delete')}>
                <Button
                  className="circled-btn"
                  type="dashed"
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      }
    }
  ];
};
export default TagsColumns;
