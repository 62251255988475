/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import TaxInvoiceReportsContext from '../../../contexts/reports-contexts/TaxInvoiceReportsContext';
import SharedOutgoingPurchaseInvoice from '../../store-pages/store-outgoing-purchase-invoices-page/SharedOutgoingPurchaseInvoice';
import SharedIncomingPurchaseInvoice from '../../store-pages/store-incoming-purchase-invoices-page/SharedIncomingPurchaseInvoice';
import IncomingPurchaseInvoiceContext from '../../../contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import { useTranslation } from 'react-i18next';
const { TabPane } = Tabs;
const TaxInvoiceReportsTables = ({ taxPage, selectedPicker }) => {
  const { fetchedReports } = useContext(TaxInvoiceReportsContext);
  const { t } = useTranslation();
  const {
    setAllFetchedReceipts: setAllIncomingFetchedInvoices,
    // resetTableFilter: resetIncommingInvoicesTableFilter,
    setTablePagination
  } = useContext(IncomingPurchaseInvoiceContext);
  const {
    setAllFetchedReceipts: setAllOutgoingFetchedInvoices
    // resetTableFilter: resetOutgoingInvoicesTableFilter
  } = useContext(OutgoingPurchaseInvoiceContext);
  const { user } = useContext(UserContext);

  useEffect(() => {
    setAllIncomingFetchedInvoices(fetchedReports.buyInvoice);
    setAllOutgoingFetchedInvoices(fetchedReports.saleInvoice);
    setTablePagination(fetchedReports.buyInvoice.pagination);
  }, [fetchedReports]);
  const [flag, setFlag] = useState('1');
  const onChange = (key) => {
    setFlag(key);
  };
  const renderTabs = () => {
    return (
      <Tabs defaultActiveKey="1" onChange={onChange}>
        <TabPane tab={t('taxInvoiceReports.salePointsinvoices')} key="1">
          <div className="tax-invoice-reports-purchases-wrap">
            <SharedOutgoingPurchaseInvoice
              allFetchedReceipts={fetchedReports?.saleInvoice}
              withoutReturnBtn={true}
              total={fetchedReports?.saleTotal}
              isReportsPage={true}
              taxPage={taxPage}
              selectedPicker={selectedPicker}
              flag={flag}
            />
          </div>
        </TabPane>
        {user.subscriptionType === 2 && (
          <TabPane tab={t('taxInvoiceReports.purchaseInvoices')} key="2">
            <div className="tax-invoice-reports-purchases-wrap">
              <SharedIncomingPurchaseInvoice
                allFetchedReceipts={fetchedReports?.buyInvoice?.data}
                withoutReturnBtn={true}
                total={fetchedReports?.buyTotal}
                taxPage={taxPage}
                selectedPicker={selectedPicker}
                flag={flag}
                pagination={fetchedReports?.buyInvoice?.pagination}
              />
            </div>
          </TabPane>
        )}
        {user.subscriptionType === 1 && (
          <>
            <TabPane
              tab={t('taxInvoiceReports.incomingPurchaseInvoices')}
              key="4"
            >
              <div className="tax-invoice-reports-purchases-wrap">
                <SharedIncomingPurchaseInvoice
                  allFetchedReceipts={fetchedReports?.buyInvoice}
                  total={fetchedReports?.buyTotal}
                  withoutReturnBtn={true}
                  taxPage={true}
                  selectedPicker={selectedPicker}
                  flag={flag}
                />
              </div>
            </TabPane>
            <TabPane tab={t('taxInvoiceReports.salesInvoicesIssued')} key="3">
              <div className="tax-invoice-reports-purchases-wrap">
                <SharedOutgoingPurchaseInvoice
                  allFetchedReceipts={fetchedReports?.buySader}
                  total={fetchedReports?.saderTotal}
                  withoutReturnBtn={true}
                  taxPage={true}
                  selectedPicker={selectedPicker}
                  flag={flag}
                />
              </div>
            </TabPane>
          </>
        )}
      </Tabs>
    );
  };
  return <div className="tax-invoie-reports-tables-wrap">{renderTabs()}</div>;
};

export default TaxInvoiceReportsTables;
