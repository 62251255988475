/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import ArchiveNotesContext from '../../../contexts/archive-contexts/ArchiveNotesProvider';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import addNewNoteApi from '../../../apis/settings-apis/settings-notes-apis/addNewNoteApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import { useTranslation } from 'react-i18next';

const TableForm = () => {
  const {
    selectedNote,
    setIsLoading,
    setModalOpened,
    setFetchCount,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(ArchiveNotesContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    title: Yup.string().required(t('Notes.EnterNoteTitle'))
  });
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      title: '',
      note: ''
    }
  });

  const customApiRequest = useCustomApiRequest();

  const onSubmit = (data) => {
    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedNote) {
      customApiRequest(
        addNewNoteApi(
          user?.token,
          {
            ...data,
            id: selectedNote.id
          },
          true
        ),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Messages.Modifiedsuccessfully'),

              message: t('Messages.Modifiedsuccessfully')
            });
          } else {
            errorNotification({
              title: t('Messages.ErrorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Messages.TryAgainLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            // title: 'Something went wrong',
            title: t('Messages.ErrorOccurred'),
            message:
              error?.response?.data?.message || t('Messages.TryAgainLater')
          });
        }
      );
    } else {
      customApiRequest(
        addNewNoteApi(user?.token, data),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Messages.addedSuccessfully'),

              message: t('Messages.addsuccessfully')
            });
          } else {
            errorNotification({
              title: t('Messages.ErrorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Messages.TryAgainLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message:
              error?.response?.data?.message || t('Messages.TryAgainLater')
          });
        }
      );
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedNote) {
      setValue('title', selectedNote?.title ? selectedNote.title : '');
      setValue('note', selectedNote?.note ? selectedNote.note : '');
    }
  }, [selectedNote, selectedNote?.id]);

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Notes.NoteTitle')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="title"
              type="text"
              placeholder={`${t('Notes.NoteTitle')} `}
              errorMsg={errors?.title?.message}
              validateStatus={errors?.title ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('Notes.NoteDetails')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="note"
              rows={8}
              placeholder={`${t('Notes.NoteDetails')}... `}
              errorMsg={errors?.note?.message}
              validateStatus={errors?.note ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {t('Notes.addition')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
