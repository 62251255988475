/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import UpgradeBoxIcon from '../../../../common/icons/UpgradeBoxIcon';
import UpgradeContext from '../../../../contexts/upgrade-context/UpgradeContext';
import DocTitleScrollTop from '../../../../utils/DocTitleScrollTop';
import { useTranslation } from 'react-i18next';
const UpgradePageBoxs = ({ data }) => {
  DocTitleScrollTop();
  const { setSelectedPackage, selectedPackage, paymentVisible } =
    useContext(UpgradeContext);
  const { t } = useTranslation();
  const renderBoxs = () => {
    if (data?.packages?.length > 0) {
      return (
        <ul className="boxs-ul">
          {data?.packages.map((box) => {
            return (
              <li
                key={box.id}
                className={`box-li ${
                  selectedPackage?.id && selectedPackage.id == box.id
                    ? 'selected'
                    : ''
                }`}
                onClick={() => {
                  setSelectedPackage(box);
                }}
              >
                <div className="box-icon">
                  <div className="icon-wrap">
                    <UpgradeBoxIcon />
                  </div>
                </div>
                <div className="box-rest-content">
                  <div className="name-price-wrap">
                    {box?.price ? (
                      <p className="price">
                        {box?.price} {t('FatoorahPackages.sar')} -{' '}
                      </p>
                    ) : null}
                    <p className="name">{box?.name}</p>
                  </div>
                  <p className="tax-p">
                    {t('FatoorahPackages.priceIncludesTax')}
                  </p>
                  {box?.description && (
                    <p className="desc-p">{box.description}</p>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  if (!paymentVisible)
    return (
      <section className="upgrade-page-boxs-section">{renderBoxs()}</section>
    );
  return null;
};

export default UpgradePageBoxs;
