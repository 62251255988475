import { createContext, useState, useEffect } from 'react';
import {
  calcPackageTotalWithCode,
  calcPackageTotalWithoutCode,
  calcPosTotal
} from '../../pages/upgrade-page/calcPackageTotal';

const INITIAL_VALUES = {
  selectedPackage: null,
  setSelectedPackage: (v) => {},
  posNum: 0,
  setPosNum: (v) => {},
  total: 0,
  setTotal: (v) => {},
  discountPrice: 0,
  setDiscountPrice: (v) => {},
  paymentVisible: false,
  setPaymentVisible: (v) => {},
  fetchedPackages: null,
  setFetchedPackages: (v) => {},
  selectedPaymentType: '',
  setSelectedPaymentType: (v) => {},
  discountCode: '',
  setDiscountCode: (v) => {},
  isCheckingDiscountCode: false,
  setIsCheckingDiscountCode: (v) => {},
  checkCodeRes: null,
  setCheckCodeRes: (v) => {}
};

const UpgradeContext = createContext(INITIAL_VALUES);

export const UpgradeProvider = ({ children }) => {
  const [selectedPackage, setSelectedPackage] = useState(
    INITIAL_VALUES.selectedPackage
  );
  const [posNum, setPosNum] = useState(INITIAL_VALUES.posNum);
  const [total, setTotal] = useState(INITIAL_VALUES.total);
  const [discountPrice, setDiscountPrice] = useState(
    INITIAL_VALUES.discountPrice
  );
  const [paymentVisible, setPaymentVisible] = useState(
    INITIAL_VALUES.paymentVisible
  );
  const [fetchedPackages, setFetchedPackages] = useState(
    INITIAL_VALUES.fetchedPackages
  );
  const [selectedPaymentType, setSelectedPaymentType] = useState(
    INITIAL_VALUES.selectedPaymentType
  );
  const [discountCode, setDiscountCode] = useState(INITIAL_VALUES.discountCode);

  const [isCheckingDiscountCode, setIsCheckingDiscountCode] = useState(
    INITIAL_VALUES.isCheckingDiscountCode
  );
  const [checkCodeRes, setCheckCodeRes] = useState(INITIAL_VALUES.checkCodeRes);

  useEffect(() => {
    if (
      selectedPackage?.price &&
      selectedPackage?.monthNumber &&
      !checkCodeRes
    ) {
      setTotal(
        calcPackageTotalWithoutCode(
          selectedPackage.price,
          selectedPackage.monthNumber,
          fetchedPackages?.setting?.pos_price,
          posNum
        )
      );
    } else if (
      !checkCodeRes
    ) {
      setTotal(
        calcPosTotal(
          fetchedPackages?.setting?.pos_price,
          posNum
        )
      );
    }
    else if (
      selectedPackage?.price &&
      selectedPackage?.monthNumber &&
      checkCodeRes
    ) {
      setTotal(
        calcPackageTotalWithCode(
          selectedPackage.price,
          selectedPackage.monthNumber,
          fetchedPackages?.setting?.pos_price,
          posNum,
          checkCodeRes?.discount,
          checkCodeRes?.type
        )
      );
      setDiscountPrice(
        parseFloat(
          calcPackageTotalWithoutCode(
            selectedPackage.price,
            selectedPackage.monthNumber,
            fetchedPackages?.setting?.pos_price,
            posNum
          ) -
            calcPackageTotalWithCode(
              selectedPackage.price,
              selectedPackage.monthNumber,
              fetchedPackages?.setting?.pos_price,
              posNum,
              checkCodeRes?.discount,
              checkCodeRes?.type
            )
        )
      );
    }
  }, [
    selectedPackage?.price,
    selectedPackage?.monthNumber,
    checkCodeRes,
    posNum,
    fetchedPackages?.setting?.pos_price
  ]);

  return (
    <UpgradeContext.Provider
      value={{
        selectedPackage,
        setSelectedPackage,
        posNum,
        setPosNum,
        total,
        setTotal,
        discountPrice,
        setDiscountPrice,
        paymentVisible,
        setPaymentVisible,
        fetchedPackages,
        setFetchedPackages,
        selectedPaymentType,
        setSelectedPaymentType,
        discountCode,
        setDiscountCode,
        isCheckingDiscountCode,
        setIsCheckingDiscountCode,
        checkCodeRes,
        setCheckCodeRes
      }}
    >
      {children}
    </UpgradeContext.Provider>
  );
};

export default UpgradeContext;
