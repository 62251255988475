import { useContext } from 'react';
import protectMe from '../../../utils/protectMe';
import UserContext from '../../../contexts/user-context/UserProvider';
// import routerLinks from '../routerLinks';
import storeRouterLinks from '../store-routes/storeRouterLinks';
import PriceOfferPageNew from '../../../pages/store-pages/price-offer-page-new/PriceOfferPageNew';
import { OutgoingPurchaseInvoiceProvider } from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import Links from '../Links';

const PriceOffersRoutes = () => {
  const { loggedIn, user } = useContext(UserContext);
  const routerLinks = Links();
  return [
    //
    // protectMe(
    //   priceOfferRouterLinks?.priceOfferRoute,
    //   <PriceOfferProvider>
    //     <PriceOfferPage />
    //   </PriceOfferProvider>,
    //   309098,
    //   routerLinks.signInPage,
    //   loggedIn,
    //   user?.expire
    // )
    protectMe(
      storeRouterLinks?.priceOfferRouteNew,
      <OutgoingPurchaseInvoiceProvider>
        <PriceOfferPageNew />
      </OutgoingPurchaseInvoiceProvider>,
      'price_offer_page_new_key',
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    )
  ];
};

export default PriceOffersRoutes;
