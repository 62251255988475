/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Table, Empty } from 'antd';
import tableColumns from './tableColumns';
import UserContext from '../../../contexts/user-context/UserProvider';
import SettingsPosContext from '../../../contexts/settings-contexts/SettingsPosPage';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import getAllPosApi from '../../../apis/settings-apis/pos-apis/getAllPosApi';
// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
import { useTranslation } from 'react-i18next';
const PageTable = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const {
    isLoading,
    setIsLoading,
    setModalOpened,
    allFetchedPos,
    fetchCount,
    setAllFetchedPos,
    setFetchCount,
    setSelectedPos
  } = useContext(SettingsPosContext);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: index + 1,
      name: obj?.name ? obj.name : '',
      title: obj?.title ? obj.title : ''
    }));
  };
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllPosApi(user?.token),
        (res) => {
          if (res?.status === 200 && res?.data?.data?.length >= 0) {
            setIsLoading(false);
            const data = res.data.data;
            const pageData = data.map((obj) => ({
              ...obj,
              key: obj.id,
              name: obj.name,
              note: obj.note
            }));

            setAllFetchedPos(pageData);
          } else {
            setIsLoading(false);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (allFetchedPos?.length >= 0) {
      const mappedData = mapDataSource(allFetchedPos);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedPos, allFetchedPos?.length]);

  return (
    <Table
      locale={{
        emptyText: <Empty description={false}>{t('PointsOfSale.noData')}</Empty>
      }}
      pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
      className="antd-custom-table"
      dataSource={tableDataSource}
      columns={tableColumns(
        allFetchedPos,
        setModalOpened,
        setFetchCount,
        setSelectedPos,
        setIsLoading,
        user?.token,
        t
      )}
      loading={isLoading}
      // scroll={{ x: 400 }}
      footer={false}
    />
  );
};

export default PageTable;
