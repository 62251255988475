import React, { useContext } from 'react';
import { Modal } from 'antd';
import InstallmentsPageContext from '../../../contexts/installments-context/InstallmentsContext';
import './InstallmentModal.scss';
import InstallmentModalForm from './InstallmentModalForm';

const InstallmentModal = () => {
  const {
    modalOpened,
    setModalOpened,
    selectedInstallment,
    setSelectedIntallment
  } = useContext(InstallmentsPageContext);

  return (
    <Modal
      className="installment-modal"
      width="90%"
      style={{ maxWidth: '642px' }}
      title={selectedInstallment ? 'تعديل القسط' : 'إضافة قسط جديد'}
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedIntallment(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedIntallment(null);
      }}
      footer={false}
    >
      <InstallmentModalForm />
    </Modal>
  );
};

export default InstallmentModal;
