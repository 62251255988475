import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import ActiveAppBarLink from '../../utils/ActiveAppBarLink';
import DocTitleScrollTop from '../../utils/DocTitleScrollTop';
import MainAppBar from '../main-app-bar/MainAppBar';

import UserContext from '../../contexts/user-context/UserProvider';
import './Layout.scss';

const AppLayout = ({ children }) => {
  const { user } = useContext(UserContext);
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (user.role == 5 && pathname == '/') {
      history.push('/pos');
    }
  }, [user.role == 5]);

  const { i18n } = useTranslation();

  ActiveAppBarLink();
  DocTitleScrollTop();

  return (
    <div
      className={`app-layout app-${i18n.dir()} ${
        pathname.includes('settings/help') ? 'in-help-page' : ''
      }`}
    >
      <div className="custom-container">
        <div className="layout-content">
          {!pathname.includes('invoice-details') &&
            !pathname.includes('settings/help') &&
            !pathname.includes('/pos') &&
            user.role !== 5 && <MainAppBar />}
          {children}
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
