/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Table, Empty } from 'antd';
import { useEffect } from 'react';
// import catsTableData from './catsTableData';
import { useState } from 'react';
import getAllInstallmentsApi from '../../../apis/store-apis/installments-api/getAllInstallmentsApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import InstallmentsPageContext from '../../../contexts/installments-context/InstallmentsContext';
import installmentsTableColumns from './InstallmentsTableColumns';
import { useParams } from 'react-router-dom';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const InstallmentsPageTable = () => {
  const { id } = useParams();
  const {
    fetchCount,
    isLoading,
    setIsLoading,
    setModalOpened,
    setFetchCount,
    allFetchedInstallments,
    setAllFetchedInstallments,
    setSelectedIntallment
  } = useContext(InstallmentsPageContext);
  const { user } = useContext(UserContext);
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getAllInstallmentsApi(user?.token, {
          invoice_id: id
        }),
        (res) => {
          if (checkRes(res) && res?.data?.data) {
            const data = res.data.data;
            setAllFetchedInstallments(data);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedInstallments?.length >= 0) {
      if (isMounted) {
        setTableDataSource(mapDataSource(allFetchedInstallments));
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedInstallments, allFetchedInstallments?.length]);

  return (
    <Table
      locale={{
        emptyText: <Empty description={false}>لا يوجد بيانات متاحة</Empty>
      }}
      pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
      className="antd-custom-table"
      dataSource={tableDataSource}
      columns={installmentsTableColumns(
        allFetchedInstallments,
        setModalOpened,
        setFetchCount,
        setSelectedIntallment,
        setIsLoading,
        user?.token
      )}
      loading={isLoading}
      // scroll={{ x: 400 }}
      footer={false}
    />
  );
};

export default InstallmentsPageTable;
