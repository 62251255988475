import React from 'react';

const EmailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.272"
      height="12.229"
      viewBox="0 0 16.272 12.229"
    >
      <path
        id="mail"
        d="M1.847,7.979H14.425a1.849,1.849,0,0,0,1.847-1.847V-2.4A1.849,1.849,0,0,0,14.425-4.25H1.847A1.849,1.849,0,0,0,0-2.4V6.132A1.849,1.849,0,0,0,1.847,7.979Zm0-.55a1.289,1.289,0,0,1-.785-.266L4.823,3.316a.275.275,0,1,0-.394-.385L.7,6.743A1.291,1.291,0,0,1,.55,6.132V-1.8L6.945,3.618a1.841,1.841,0,0,0,2.389,0L15.722-1.8V6.132a1.289,1.289,0,0,1-.154.613L11.819,2.931a.275.275,0,0,0-.392.386l3.782,3.848a1.289,1.289,0,0,1-.784.264Zm0-11.129H14.425a1.3,1.3,0,0,1,1.292,1.181L8.977,3.2A1.293,1.293,0,0,1,7.3,3.2L.555-2.513A1.3,1.3,0,0,1,1.847-3.7Zm0,0"
        transform="translate(0 4.25)"
      />
    </svg>
  );
};

export default EmailIcon;
