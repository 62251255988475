import axios from 'axios';

const getPosShiftReport = async (token, userId) => {
  try {
    const res = await axios({
      method: 'get',
      baseURL: process.env.REACT_APP_BASE_URL_POS,
      url: `shiftReport`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getPosShiftReport;
