import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedUsers: null,
  setAllFetchedUsers: (v) => {},
  selectedUser: null,
  setSelectedUser: (v) => {},
  nameSearchValue: '',
  setNameSearchValue: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {}
};

const SettingsSystemUsersContext = createContext(INITIAL_VALUES);

export const SettingsSystemUsersProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedUsers, setAllFetchedUsers] = useState(
    INITIAL_VALUES.allFetchedUsers
  );
  const [selectedUser, setSelectedUser] = useState(INITIAL_VALUES.selectedUser);
  const [nameSearchValue, setNameSearchValue] = useState(
    INITIAL_VALUES.nameSearchValue
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );

  return (
    <SettingsSystemUsersContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedUsers,
        setAllFetchedUsers,
        selectedUser,
        setSelectedUser,
        nameSearchValue,
        setNameSearchValue,
        isSubmittingForm,
        setIsSubmittingForm
      }}
    >
      {children}
    </SettingsSystemUsersContext.Provider>
  );
};

export default SettingsSystemUsersContext;
