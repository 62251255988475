import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import AccountingContext from '../../../contexts/accounting-context/AccountingContext';
import AccountingTree from './AccountingTree';
import PageModal from './PageModal';
import './AccountingPage.scss';
import JournalsContext from '../../../contexts/accounting-context/JournalsContext';
import JournalsDetailsModal from '../journals-details-page/JournalsDetailsModal';
import { Button } from 'antd';
import JournalCreateModal from '../journals-details-page/JournalCreateModal';
import JournalsUpdateModal from '../journals-details-page/JournalsUpdateModal';
import JournalsPage from '../journals-details-page/JournalsPage';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

const AccountingJournalsPage = () => {
  const {
    modalOpened,
    apiLoading,
    modalOpenedCreate,
    setModalOpenedCreate,
    modalOpenedUpdate
  } = useContext(JournalsContext);
  const { t } = useTranslation();

  const handleCreateJournal = () => {
    setModalOpenedCreate(true);
  };

  return (
    <div className="accounting-page">
      <div className="page-head-wrap">
        
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('Restrictions.Restrictions')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.accountingPage.title')}
            title3={t('Restrictions.Restrictions')}
            link3={'/accounting/accounting-journals'}
          />
        </div>
        <SearchNotificationsProfile />
      </div>
      <div
          className="page-head-wrap"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 32
          }}
        >
          <div>
            <Button
              onClick={() => handleCreateJournal()}
              className="submit-btn"
              htmlType="submit"
              type="default"
              loading={apiLoading}
            >
              {t('Restrictions.AddNewRestrictions')}
            </Button>
          </div>
      </div> 
      <div className="Journals-table-wrapper">{<JournalsPage />}</div>

      {/* <AccountingTree /> */}

      {modalOpened && <JournalsDetailsModal />}
      {modalOpenedCreate && <JournalCreateModal />}
      {/* {modalOpenedUpdate && <JournalsUpdateModal />} */}
    </div>
  );
};

export default AccountingJournalsPage;
