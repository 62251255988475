import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedClients: null,
  setAllFetchedClients: (v) => {},
  selectedClient: null,
  setSelectedClient: (v) => {},
  selectedClientId: '',
  setSelectedClientId: (v) => {},
  nameSearchValue: '',
  setNameSearchValue: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {}
};

const ClientsPosContext = createContext(INITIAL_VALUES);

export const ClientsPosProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedClients, setAllFetchedClients] = useState(
    INITIAL_VALUES.allFetchedClients
  );
  const [selectedClient, setSelectedClient] = useState(
    INITIAL_VALUES.selectedClient
  );
  const [nameSearchValue, setNameSearchValue] = useState(
    INITIAL_VALUES.nameSearchValue
  );

  const [selectedClientId, setSelectedClientId] = useState(
    INITIAL_VALUES.selectedClientId
  );
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES?.tablePagination
  );

  return (
    <ClientsPosContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedClients,
        setAllFetchedClients,
        selectedClient,
        setSelectedClient,
        selectedClientId,
        setSelectedClientId,
        nameSearchValue,
        setNameSearchValue,
        tablePagination,
        setTablePagination
      }}
    >
      {children}
    </ClientsPosContext.Provider>
  );
};

export default ClientsPosContext;
