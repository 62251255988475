/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import SettingsMeasurementUnitsContext from '../../../contexts/settings-contexts/SettingsMeasurementUnitsPage';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import addNewUnitApi from '../../../apis/settings-apis/measuremnet-units-apis/addNewUnitApi';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import { useTranslation } from 'react-i18next';

const TableForm = () => {
  const { user } = useContext(UserContext);
  const {
    selectedUnit,
    setIsLoading,
    setModalOpened,
    setFetchCount,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(SettingsMeasurementUnitsContext);
  const { t } = useTranslation();

  const customApiRequest = useCustomApiRequest();
  const schema = Yup.object().shape({
    unit_name: Yup.string()
      .required(t('Inventory.MeasurementUnits.enterNameUnitMeasurement'))
      .max('100', t('Inventory.Products.maxProductNameLength'))
  });
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      unit_name: '',
      unit_note: ''
    }
  });

  const onSubmit = async (data) => {
    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedUnit) {
      customApiRequest(
        addNewUnitApi(
          user?.token,
          {
            ...data,
            id: selectedUnit.id
          },
          true
        ),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Inventory.MeasurementUnits.addedSuccessfully'),
              message: t(
                'Inventory.MeasurementUnits.operationCompletedSuccessfully'
              )
            });
            setIsLoading(false);
          } else {
            errorNotification({
              title: t('Inventory.MeasurementUnits.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Inventory.MeasurementUnits.addedSuccessfully')
            });
            setIsSubmittingForm(false);
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('Inventory.MeasurementUnits.errorOccurred'),
            message:
              error?.response?.data?.message ||
              t('Inventory.MeasurementUnits.pleaseTryLater')
          });
        }
      );
    } else {
      customApiRequest(
        addNewUnitApi(user?.token, data),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Inventory.MeasurementUnits.addedSuccessfully'),
              message: t(
                'Inventory.MeasurementUnits.unitMeasurementAddedSuccessfully'
              )
            });
          } else {
            errorNotification({
              title: t('Inventory.MeasurementUnits.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Inventory.MeasurementUnits.pleaseTryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          errorNotification({
            title: t('Inventory.MeasurementUnits.errorOccurred'),
            message:
              error?.response?.data?.message ||
              t('Inventory.MeasurementUnits.pleaseTryLater')
          });
        }
      );
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedUnit) {
      setValue('unit_name', selectedUnit?.name ? selectedUnit.name : '');
    }
    if (selectedUnit) {
      setValue('unit_note', selectedUnit?.note ? selectedUnit.note : '');
    }
  }, [selectedUnit, selectedUnit?.id]);

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.MeasurementUnits.unitName')}</p>
          <div className="text-field-wrap ">
            <AntdTextField
              className="form-text-field"
              name="unit_name"
              type="text"
              placeholder={t('Inventory.MeasurementUnits.unitNamePlaceholder')}
              errorMsg={errors?.unit_name?.message}
              validateStatus={errors?.unit_name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.MeasurementUnits.notes')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="unit_note"
              rows={8}
              placeholder={t('Inventory.MeasurementUnits.notesPlaceholder')}
              errorMsg={errors?.unit_note?.message}
              validateStatus={errors?.unit_note ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedUnit
            ? t('Inventory.MeasurementUnits.edit')
            : t('Inventory.MeasurementUnits.add')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
