/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import settingsRouterLinks from '../app/settings-routes/settingsRouterLinks';
import notificationClickActionTypes from '../../notificationClickActionTypes';
// import successNotification from '../../utils/successNotification';
// import { store } from 'react-notifications-component';

const duration = 10 * 60;
const ForegroundNotifications = ({
  fcmNotificationPayload,
  fcmNotificationFetchCount,
  setFcmNotificationFetchCount,
  setFcmNotificationPayload
}) => {
  const history = useHistory();
  useEffect(() => {
    if (fcmNotificationFetchCount > 0 && fcmNotificationPayload) {
      notification.destroy();
      notification.success({
        message: fcmNotificationPayload?.notification?.title,
        description: fcmNotificationPayload?.notification?.body,
        duration,
        onClick: () => {
          if (
            fcmNotificationPayload?.notification?.click_action ==
            notificationClickActionTypes()?.expired
          ) {
            history.push(settingsRouterLinks?.upgradePage);
            notification.destroy();
          }
        }
      });
    }

    setTimeout(() => {
      // store.removeNotification('fcm-notification');

      setFcmNotificationFetchCount(0);
      setFcmNotificationPayload(null);
    }, duration * 1000);
  }, [fcmNotificationFetchCount, fcmNotificationPayload]);
  return null;
};

export default ForegroundNotifications;
