import React, { useContext, useState, useEffect } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import StorePurchaseOrderContext from '../../../contexts/store-purchase-order-context/StorePurchaseOrderProvider';
import { useTranslation } from 'react-i18next';
const PageModal = () => {
  const { modalOpened, setModalOpened, setSelectedOrder, selectedOrder } =
    useContext(StorePurchaseOrderContext);
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  return (
    <Modal
      transitionName=""
      className="shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        selectedOrder
          ? t('PurchaseOrder.updateOrder')
          : t('PurchaseOrder.addNewOrder')
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedOrder(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedOrder(null);
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default PageModal;
