import axios from 'axios';

const AuthWithStore = async (token, type) => {
    try {
        const res = await axios({
        method: 'get',
        baseURL: process.env.REACT_APP_BASE_TEST_URL,
        // baseURL: 'https://testclient-api.fatoorah.sa/apiAdmin/',
        url: `getToken?type=${type}`,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'applications/json',
            Authorization: 'Bearer ' + token,
            lang: 'ar'
        }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default AuthWithStore;
