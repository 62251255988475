import { EyeOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import moment from 'moment';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import deleteJournalApi from '../../../apis/accounting-apis/accounting-details-apis/deleteJournalApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import { useTranslation } from 'react-i18next';
const TableColumns = (
  fetchedSummaryDetails,
  setModalOpened,
  setSelectedJournalId,
  setModalOpenedUpdate,
  setFetchCount,
  setSelectedSummary,
  setIsLoading,
  token,
  type
) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();


  const handleEditClick = (record) => {
    setSelectedSummary(record);
    setModalOpenedUpdate(true);
    setSelectedJournalId(record.id);
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteJournalApi(user?.token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Quotations.SuccessOperation'),
          message: t('Quotations.ProductDeletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Quotations.ErrorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Quotations.PleaseTryAgainLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Quotations.ErrorOccurred'),
        message: t('Quotations.TryAgainLater')
      });
    }
  };

  const handlePreview = (id) => {
    setSelectedJournalId(id);
    setModalOpened(true);
  };

  // const renderActions = (obj) => {
  //   if (!obj.model_id) {
  //     return (
  //       <div className="action-btns-wrap">
  //         <div className="circled-btn-wrap">
  //           <Tooltip title="معاينة فرع الحسابات">
  //               <Button
  //                 id={obj?.id}
  //                 onClick={() => handlePreview(obj?.id)}
  //                 className="circled-btn"
  //                 type="dashed"
  //                 shape="circle"
  //                 icon={<EyeOutlined />}
  //               />
  //           </Tooltip>
  //         </div>

  //         {/* <div className="edit-btn" onClick={() => handleEditClick(obj)}>
  //           <EditIcon />
  //           تعديل
  //         </div> */}
  //         <Popconfirm
  //           title="هل أنت متأكد من حذف الفرع"
  //           onConfirm={() => handleDeleteRequest(obj.id)}
  //           okText="نعم"
  //           cancelText="لا"
  //         >
  //           <Tooltip title="حذف الفرع">
  //             <div className="delete-btn">
  //               <DeleteIcon />
  //             </div>
  //           </Tooltip>
  //         </Popconfirm>
  //       </div>
  //     );
  //   }
  //   return '------';
  // };

  return [
    {
      title: t('ProfessorBook.AccountName'),

      dataIndex: 'note',
      // width: 192,
      render: (_, record) => {
        return (
          <div
            className="row-cell row-text"
            style={{ display: 'flex', justifyContent: 'start' }}
          >
            {record.note ? (
              <span className="name-it-self">
                {record?.account_title ? String(record.account_title) : ''}
                {record?.note
                  ? String(record.note).replace(/<[^>]*>?/gm, '')
                  : ''}
              </span>
            ) : (
              <h3 className="name-it-self" style={{ color: '#000' }}>
                {record?.account_title ? String(record.account_title) : ''}
                {record?.note
                  ? String(record.note).replace(/<[^>]*>?/gm, '')
                  : ''}
              </h3>
            )}
          </div>
        );
      }
    },
    {
      title: t('ProfessorBook.Date'),

      dataIndex: 'date',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.date ? moment(record.date).format('DD/MM/YYYY') : '----'}
            </span>
          </div>
        );
      }
    },
    // {
    //   title: 'بيان',
    //   dataIndex: 'note',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         <span className="name-it-self">
    //           {record.note ? String(record.note).replace(/<[^>]*>?/gm, '') : '----'}
    //         </span>
    //       </div>
    //     );
    //   }
    // },
    {
      title: t('ProfessorBook.Debtor'),

      dataIndex: 'debit',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.debit ? record.debit : '-----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('ProfessorBook.Creditor'),
      dataIndex: 'credit',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.credit ? record.credit : '-----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('ProfessorBook.Balance'),
      dataIndex: 'balance',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.balance ? record.balance : '-----'}
            </span>
          </div>
        );
      }
    },

    {
      title: t('ProfessorBook.Display'),

      // width: 222,
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">
            {record?.journal_id && (
              <div className="circled-btn-wrap">
                <Tooltip title={t('ProfessorBook.Display')}>
                  <Button
                    id={record?.journal_id}
                    onClick={() => handlePreview(record?.journal_id)}
                    className="circled-btn"
                    type="dashed"
                    shape="circle"
                    icon={<EyeOutlined />}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        );
      }
    }
  ];
};

export default TableColumns;
