/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Table, Empty, Pagination } from 'antd';
import tableColumns from './tableColumns';
import StoreProductsIngredientsContext from '../../../contexts/store-products-ingredients-context/StoreProductsIngredientsProvider';
import getAllIngredients from '../../../apis/store-apis/ingredients/getAllIngredients';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import storeRouterLinks from '../../../components/app/store-routes/storeRouterLinks';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';

const PageTable = () => {
  const { user } = useContext(UserContext);
  const {
    isLoading,
    setIsLoading,
    fetchCount,
    setFetchCount,
    allFetchedIngredients,
    setAllFetchedIngredients,
    setModalOpened,
    setSelectedIngredient,
    tablePagination,
    setTablePagination
  } = useContext(StoreProductsIngredientsContext);
  const { t } = useTranslation();
  const { search } = useLocation();
  const values = queryString.parse(search);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllIngredients(user?.token, values.page ? values.page : 1),
        (res) => {
          if (checkRes(res) && res?.data?.data?.data) {
            const data = res.data.data.data;
            setAllFetchedIngredients(data);
            setIsLoading(false);
          }
          if (res?.data?.data?.pagination) {
            setTablePagination(res.data.data.pagination);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedIngredients?.length >= 0) {
      const mappedData = mapDataSource(allFetchedIngredients);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedIngredients, allFetchedIngredients?.length]);

  useEffect(() => {
    if (values.page) {
      setTablePagination({ ...tablePagination, current_page: +values.page });
    }
  }, [search]);

  const history = useHistory();

  return (
    <>
      <Table
        locale={{
          emptyText: <Empty description={false}>{t('Ingredient.noData')}</Empty>
        }}
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedIngredients,
          setModalOpened,
          setFetchCount,
          setSelectedIngredient,
          setIsLoading,
          user?.token,
          t
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
      {tablePagination && (
        <Pagination
          style={{ display: 'flex', justifyContent: 'center' }}
          defaultCurrent={1}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}
          onChange={(page, pageSize) => {
            setFetchCount((prev) => prev + 1);

            history.push(
              `${storeRouterLinks?.storeProductsIngredientsPage}?page=${page}`
            );
          }}
          hideOnSinglePage={true}
        />
      )}
    </>
  );
};

export default PageTable;
