/* eslint-disable eqeqeq */
import { Descriptions, Empty, Table } from 'antd';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
// import useSystemSettings from '../../../custom-hooks/useSystemSettings';
let finalDate = (date) => {
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = (dateObj.getMonth() + 1).toString();
  const day = dateObj.getDate().toString();

  const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${
    day < 10 ? '0' + day : day
  }`;
  return formattedDate;
};

const ReceiptDetailsComponentProducts = React.forwardRef((props, ref) => {
  const { selectedReceipt } = props;
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);








  const renderInovicePrices = () => {
    const {
      name,
      quantity,
      real_quantity,
    } = selectedReceipt;
    return (
      <div className="supplier-details-wrap">
        <Descriptions
          // title={`${t('purchaseInvoices.totalAmounts')} : `}
          bordered
          column={1}
        >
          {name && (
            <Descriptions.Item label={t('Inventory.WarehouseInventory.name')}>
              {name}
            </Descriptions.Item>
          )}

          {quantity && (
            <Descriptions.Item
              label={t('Inventory.WarehouseInventory.quantityStock')}
            >
              {quantity}
            </Descriptions.Item>
          )}
          {real_quantity && (
            <Descriptions.Item
              label={t('Inventory.WarehouseInventory.inventoryResult')}
            >
              {real_quantity}
            </Descriptions.Item>
          )}
          {selectedReceipt?.stock?.name && (
            <Descriptions.Item
              label={t('Inventory.WarehouseInventory.inventory')}
            >
              {selectedReceipt?.stock?.name}
            </Descriptions.Item>
          )}
          {selectedReceipt?.created_at && (
            <Descriptions.Item
              label={t('Inventory.WarehouseInventory.created_at')}
            >
              {finalDate(selectedReceipt?.created_at)}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  };
  return (
    <div
      className="invoice-details-wrap"
      ref={ref}
      style={{
        padding: '32px 32px',
        direction: currentLang == 'ar' ? 'rtl' : 'ltr',
        position: 'relative'
        // display: 'grid',
        // gap: '16px'
      }}
    >

      {renderInovicePrices()}


    </div>
  );
});

export default ReceiptDetailsComponentProducts;
