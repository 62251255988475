import React from 'react';

const LocationIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.606"
      height="17.062"
      viewBox="0 0 12.606 17.062"
    >
      <g id="pin" transform="translate(-48.08)">
        <g id="Group_865" data-name="Group 865" transform="translate(48.08)">
          <g id="Group_864" data-name="Group 864" transform="translate(0)">
            <path
              id="Path_2927"
              data-name="Path 2927"
              d="M54.383,0a6.31,6.31,0,0,0-6.3,6.3,7.272,7.272,0,0,0,.535,2.427,10.164,10.164,0,0,0,.729,1.363l4.322,6.548a.822.822,0,0,0,1.436,0l4.322-6.548a10.123,10.123,0,0,0,.729-1.363A7.27,7.27,0,0,0,60.686,6.3,6.31,6.31,0,0,0,54.383,0Zm5.085,8.442A9.312,9.312,0,0,1,58.8,9.684l-4.322,6.548c-.085.129-.112.129-.2,0L49.962,9.684A9.318,9.318,0,0,1,49.3,8.441,6.6,6.6,0,0,1,48.822,6.3a5.561,5.561,0,0,1,11.123,0A6.606,6.606,0,0,1,59.468,8.442Z"
              transform="translate(-48.08)"
            />
            <path
              id="Path_2928"
              data-name="Path 2928"
              d="M115.417,64.008a3.337,3.337,0,1,0,3.337,3.337A3.341,3.341,0,0,0,115.417,64.008Zm0,5.932a2.6,2.6,0,1,1,2.6-2.6A2.6,2.6,0,0,1,115.417,69.94Z"
              transform="translate(-109.114 -61.042)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LocationIcon;
