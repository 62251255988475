import { createContext, useState } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  filterValues: '',
  setFilterValues: (v) => {},
  allProducts: [],
  setAllProducts: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedReceipts: null,
  setAllFetchedReceipts: (v) => {},
  selectedReceipt: null,
  setSelectedReceipt: (v) => {},
  userIdFromPayment: null,
  setUserIdFromPayment: (v) => {},
  selectedReceiptModalOpened: false,
  setSelectedReceiptModalOpened: (v) => {},
  //
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {},
  tableFilter: {
    invoiceNumber: '',
    id: '',
    supplier_id: null,
    type: 0,
    serviceOrPackage: null,
    from: '',
    to: '',
    admin_id: ''
  },
  setTableFilter: (v) => {},
  resetTableFilter: (v) => {},
  allReceiptsData: null,
  setAllReceiptsData: (v) => {},
};

const IncomingPurchaseInvoiceContext = createContext(INITIAL_VALUES);

export const IncomingPurchaseInvoiceProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [filterValues, setFilterValues] = useState(INITIAL_VALUES.filterValues);
  const [allFetchedReceipts, setAllFetchedReceipts] = useState(
    INITIAL_VALUES.allFetchedReceipts
  );
  const [allReceiptsData, setAllReceiptsData] = useState(
    INITIAL_VALUES.allReceiptsData
  );
  const [selectedReceipt, setSelectedReceipt] = useState(
    INITIAL_VALUES.selectedReceipt
  );
  const [selectedReceiptModalOpened, setSelectedReceiptModalOpened] = useState(
    INITIAL_VALUES.selectedReceiptModalOpened
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  const [allProducts, setAllProducts] = useState(INITIAL_VALUES?.allProducts);
  const [userIdFromPayment, setUserIdFromPayment] = useState(INITIAL_VALUES?.userIdFromPayment);
  const [tableFilter, setTableFilter] = useState(INITIAL_VALUES.tableFilter);
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES?.tablePagination
  );
  return (
    <IncomingPurchaseInvoiceContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedReceipts,
        setAllFetchedReceipts,
        selectedReceipt,
        setSelectedReceipt,
        selectedReceiptModalOpened,
        setSelectedReceiptModalOpened,
        isSubmittingForm,
        setIsSubmittingForm,
        tableFilter,
        setTableFilter,
        resetTableFilter: () => {
          setTableFilter({ ...INITIAL_VALUES?.tableFilter });
        },
        tablePagination,
        setTablePagination,
        allProducts,
        setAllProducts,
        filterValues,
        setFilterValues,
        userIdFromPayment,
        setUserIdFromPayment,
        allReceiptsData,
        setAllReceiptsData
      }}
    >
      {children}
    </IncomingPurchaseInvoiceContext.Provider>
  );
};

export default IncomingPurchaseInvoiceContext;
