/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import storeItemManagerImg from '../../assets/imgs/store/store-item-manager.png';
import SearchNotificationsProfile from '../../components/search-notifications-profile/SearchNotificationsProfile';
import StoreWelcomeSharedSection from '../store-pages/store-welcome-shared-section/StoreWelcomeSharedSection';
import TableFilter from './TableFilter';
import PageTable from './PageTable';
import './SuppliersPage.scss';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
const SuppliersPage = () => {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  return (
    <div className="clients-page">
      <div className="top-section-wrap">
        {/* <StoreWelcomeSharedSection
          img={storeItemManagerImg}
          title={t('suppliers.title')}
          subTitle={t('suppliers.subTitle')}
        /> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('suppliers.title')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.incomingPurchaseInvoice.title')}
            title3={t('suppliers.title')}
            link3={'/store/suppliers'}
          />
        </div>
        <div className="search-wrap">
          <SearchNotificationsProfile noSearch />
        </div>
      </div>
      <TableFilter />
      <div className="suppliersPage-table-wrapper">
        <PageTable currentLang={currentLang} />
      </div>
    </div>
  );
};

export default SuppliersPage;
