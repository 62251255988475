import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import StoreProductsIngredientsContext from '../../../contexts/store-products-ingredients-context/StoreProductsIngredientsProvider';
import { useTranslation } from 'react-i18next';
const SettingsWorkPlaceModal = () => {
  const {
    modalOpened,
    setModalOpened,
    setSelectedIngredient,
    selectedIngredient
  } = useContext(StoreProductsIngredientsContext);
  const { t } = useTranslation();
  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '642px' }}
      title={
        selectedIngredient
          ? t('Ingredient.updateInredient')
          : t('Ingredient.addNewIngredient')
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedIngredient(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedIngredient(null);
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default SettingsWorkPlaceModal;
