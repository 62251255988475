import React, { useEffect, useContext, useState } from 'react';
import { Button, Table, Empty } from 'antd';
import TagsColumns from './TagsColumns';
import { useTranslation } from 'react-i18next';
import UserContext from '../../contexts/user-context/UserProvider';
import getAllTagsApi from '../../apis/nfcTags-api/getAllTagsApi';
import TagsContext from '../../contexts/nfcTags-context/tagsContext';

const TagsTable = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const {
    tagsData,
    setTagsData,
    isLoadingTags,
    setIsLoadingTags,
    fetchCount,
    setPreviousTagsData,
    tableFilter
  } = useContext(TagsContext);
  const [tagsSource, setTagsSource] = useState([]);

  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: index + 1
    }));
  };

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (tagsData?.length > 0) {
      if (isMounted) {
        setTagsSource(mapDataSource(tagsData));
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, tagsData, tagsData?.length]);

  useEffect(() => {
    const getTags = async () => {
      setIsLoadingTags(true);
      try {
        const tagsRes = await getAllTagsApi(user?.token);
        if (tagsRes?.status === 200 && tagsRes?.data?.data) {
          setIsLoadingTags(false);
          setTagsData(tagsRes?.data?.data);
          setPreviousTagsData(tagsRes?.data?.data);
        }
      } catch (error) {
        setIsLoadingTags(false);
      }
    };

    getTags();
  }, [fetchCount, user?.token]);

  return (
    <div>
      <Table
        locale={{
          emptyText: <Empty description={false}>{t('NFCTags.noData')}</Empty>
        }}
        scroll={{ x: 400 }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableFilter ? tagsData : tagsSource}
        columns={TagsColumns(t)}
        footer={false}
        loading={isLoadingTags}
      />
    </div>
  );
};
export default TagsTable;
