import axios from 'axios';

const getAllClientsApi = async (token , values) => {
  let url ;
  if(values?.test1){
    url = `/User/all?test=1&page=${values.page}`
  }
  else{
    url = `/User/all`
  }
  try {
    const res = await axios({
      method: 'get',
      baseURL: process.env.REACT_APP_BASE_URL_POS,
      url: url,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllClientsApi;
