const accountingRouterLinks = {
  accountingPage: '/accounting',
  accountingTreePage: '/accounting/accounting-tree',
  balanceSheetPage: '/accounting/balance-sheet',
  summaryPage: '/accounting/summary',
  accountStatementPage: '/accounting/account-statement',
  accountingJournalsPage: '/accounting/accounting-journals',
  accountingTransactionsPage: '/accounting/accounting-Transactions',
  accountingDetailsPage: (id) => {
    if (id) return `/accounting/accounting-details/${id}`;
    return '/accounting/accounting-details/:id';
  }
  // accountsPage: '/accounts',
  // accountsTransactionsPage: '/accounts/transactions',
  // accountsRevenuesPage: '/accounts/revenues',
  // accountsExpensesPage: '/accounts/expenses'
};

export default accountingRouterLinks;
