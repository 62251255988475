import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import { ReadOutlined } from '@ant-design/icons';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import countWords from '../../../utils/countWords';
import trimWords from '../../../utils/trimWords';
import moment from 'moment';
import deleteAccountingApi from '../../../apis/accounting-apis/deleteAccountingApi';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import UserContext from '../../../contexts/user-context/UserProvider';

const TableColumns = (
  fetchedAccountingDetails,
  setModalOpened,
  setFetchCount,
  setSelectedAccounting,
  setIsLoading,
  token,
  type
) => {
  const { user } = useContext(UserContext);

  const params = useParams()


  const handleEditClick = (record) => {
    setSelectedAccounting(record);
    setModalOpened(true);
  };

  const handleDeleteRequest = async (id) => {

    try {
      setIsLoading(true);
      const res = await deleteAccountingApi(user?.token, params.id + "-" + id,"subtype");


      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف الفرع بنجاح'
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: 'حدث خطأ اثناء العملية',
          message: res?.data?.message
            ? res.data.message
            : 'من فضلك حاول فى وقت لاحق'
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: 'حدث خطأ',
        message: 'حاول فى وقت لاحق'
      });
    }
  };


  const renderActions = (obj) => {
    if (!obj.model_id) {
      return (
        <div className="action-btns-wrap">
          <div className="edit-btn" onClick={() => handleEditClick(obj)}>
            <EditIcon />
            تعديل
          </div>
          <Popconfirm
            title="هل أنت متأكد من حذف الفرع"
            onConfirm={() => handleDeleteRequest(obj.id)}
            okText="نعم"
            cancelText="لا"
          >
            <Tooltip title="حذف الفرع">
              <div className="delete-btn">
                <DeleteIcon />
              </div>
            </Tooltip>
          </Popconfirm>
        </div>
      );
    }
    return '------';
  };

  if (type === 'company') {
    return [
      {
        title: 'الكود',
        dataIndex: 'id',
        // width: 62
        render: (_, record) => {
          return (
            <div className="row-cell row-index bold-font">
              <span># {record.id}</span>
            </div>
          );
        }
      },

      {
        title: 'الاسم',
        dataIndex: 'desc',
        // width: 192,
        render: (_, record) => {
          if (record.description) {
            const wordsCount = countWords(String(record.description));
            if (wordsCount > 8) {
              return (
                <Popover title="اقرأ المزيد" content={String(record.description)}>
                  <Button size="large" type="text">
                    <p className="has-read-more">
                      <span className="read-more-span ltr-text">
                        {trimWords(String(record.description), 8)}
                      </span>
                      <ReadOutlined
                        className="read-more-book"
                        style={{ margin: '0 4px' }}
                      />
                    </p>
                  </Button>
                </Popover>
              );
            } else
              return (
                <div className="row-cell row-text ltr-text">
                  {String(record.description)}
                </div>
              );
          } else {
            return '----';
          }
        }
      },
      {
        title: 'التاريــخ',
        dataIndex: 'date',
        // width: 192,
        render: (_, record) => {
          return (
            <div className="row-cell row-text">
              <span className="name-it-self">
                {record.created_at ? moment(record.created_at).format("MM/DD/YYYY HH:mm:ss") : '-----'}
              </span>
            </div>
          );
        }
      },
      // {
      //   title: 'الحساب',
      //   dataIndex: 'balance',
      //   // width: 192,
      //   render: (_, record) => {
      //     return (
      //       <div className="row-cell row-text">
      //         <span className="name-it-self">
      //           {record.the_amount ? record.the_amount : '-----'}
      //         </span>
      //       </div>
      //     );
      //   },
      //   sorter: (a, b) => parseFloat(a.the_amount) - parseFloat(b.the_amount)
      // },

    ];
  }

  else if (type === 'types') {
    return [
      {
        title: 'الكود',
        dataIndex: 'id',
        // width: 62
        render: (_, record) => {
          return (
            <div className="row-cell row-index bold-font">
              <span># {record.id}</span>
            </div>
          );
        }
      },

      {
        title: 'الاسم',
        dataIndex: 'desc',
        // width: 192,
        render: (_, record) => {
          if (record.description) {
            const wordsCount = countWords(String(record.description));
            if (wordsCount > 8) {
              return (
                <Popover title="اقرأ المزيد" content={String(record.description)}>
                  <Button size="large" type="text">
                    <p className="has-read-more">
                      <span className="read-more-span ltr-text">
                        {trimWords(String(record.description), 8)}
                      </span>
                      <ReadOutlined
                        className="read-more-book"
                        style={{ margin: '0 4px' }}
                      />
                    </p>
                  </Button>
                </Popover>
              );
            } else
              return (
                <div className="row-cell row-text ltr-text">
                  {String(record.description)}
                </div>
              );
          } else {
            return '----';
          }
        }
      },
      {
        title: 'التاريــخ',
        dataIndex: 'date',
        // width: 192,
        render: (_, record) => {
          return (
            <div className="row-cell row-text">
              <span className="name-it-self">
                {record.created_at ? moment(record.created_at).format("MM/DD/YYYY HH:mm:ss") : '-----'}
              </span>
            </div>
          );
        }
      },
      // {
      //   title: 'الحساب',
      //   dataIndex: 'balance',
      //   // width: 192,
      //   render: (_, record) => {
      //     return (
      //       <div className="row-cell row-text">
      //         <span className="name-it-self">
      //           {record.the_amount ? record.the_amount : '-----'}
      //         </span>
      //       </div>
      //     );
      //   },
      //   sorter: (a, b) => parseFloat(a.the_amount) - parseFloat(b.the_amount)
      // },
      {
        title: 'الاعدادات',
        width: 222,
        dataIndex: 'actions',
        render: (_, record) => {
          return (
            <div className="row-cell row-actions">{renderActions(record)}</div>
          );
        }
      }
    ];
  }

  else if (type === 'subtypes') {
    return [
      {
        title: 'الكود',
        dataIndex: 'id',
        // width: 62
        render: (_, record) => {
          return (
            <div className="row-cell row-index bold-font">
              <span># {record.id}</span>
            </div>
          );
        }
      },
      {
        title: 'الاسم',
        dataIndex: 'desc',
        // width: 192,
        render: (_, record) => {
          if (record.description) {
            const wordsCount = countWords(String(record.description));
            if (wordsCount > 8) {
              return (
                <Popover title="اقرأ المزيد" content={String(record.description)}>
                  <Button size="large" type="text">
                    <p className="has-read-more">
                      <span className="read-more-span ltr-text">
                        {trimWords(String(record.description), 8)}
                      </span>
                      <ReadOutlined
                        className="read-more-book"
                        style={{ margin: '0 4px' }}
                      />
                    </p>
                  </Button>
                </Popover>
              );
            } else
              return (
                <div className="row-cell row-text ltr-text">
                  {String(record.description)}
                </div>
              );
          } else {
            return '----';
          }
        }
      },
      {
        title: 'التاريــخ',
        dataIndex: 'date',
        // width: 192,
        render: (_, record) => {
          return (
            <div className="row-cell row-text">
              <span className="name-it-self">
                {record.created_at ? moment(record.created_at).format("MM/DD/YYYY HH:mm:ss") : '-----'}
              </span>
            </div>
          );
        }
      },
      {
        title: 'الحساب',
        dataIndex: 'balance',
        // width: 192,
        render: (_, record) => {
          return (
            <div className="row-cell row-text">
              <span className="name-it-self">
                {record.the_amount ? record.the_amount : '-----'}
              </span>
            </div>
          );
        },
        sorter: (a, b) => parseFloat(a.the_amount) - parseFloat(b.the_amount)
      },
      {
        title: 'النوع',
        dataIndex: 'type',
        // width: 192,
        render: (_, record) => {
          return (
            <div className="row-cell row-text">
              <span className="name-it-self">
                {record.type === "credit" ? "دائن" : record.type === "debit" ? " مدين" : ""}
              </span>
            </div>
          );
        }
      },
      {
        title: 'احصائي',
        dataIndex: 'analytical',
        // width: 192,
        render: (_, record) => {
          return (
            <div className="row-cell row-text">
              <span className="name-it-self">
                {record.analytical == "1" ? "نعم" : "لا"}
              </span>
            </div>
          );
        }
      },

    ];
  }

  else if (type === 'account') {
    return [
      {
        title: 'الكود',
        dataIndex: 'id',
        // width: 62
        render: (_, record) => {
          return (
            <div className="row-cell row-index bold-font">
              <span># {record.id}</span>
            </div>
          );
        }
      },

      {
        title: 'الحساب',
        dataIndex: 'account',
        // width: 192,

      },
      {
        title: 'التاريــخ',
        dataIndex: 'date',
        // width: 192,
        render: (_, record) => {
          return (
            <div className="row-cell row-text">
              <span className="name-it-self">
                {record.created_at ? moment(record.created_at).format("MM/DD/YYYY HH:mm:ss") : '-----'}
              </span>
            </div>
          );
        }
      },
      {
        title: 'النوع',
        dataIndex: 'type',
        // width: 192,
        render: (_, record) => {
          return (
            <div className="row-cell row-text">
              <span className="name-it-self">
                {record.type.toLowerCase() === "income" ? "ايرادات" : record.type.toLowerCase() === "expense" ? "مصروفات" : record.type.toLowerCase() === "transfer" ? "نقل" : ""}
              </span>
            </div>
          );
        },
      },
      {
        title: 'الفئة',
        dataIndex: 'cat',
        // width: 192,


      },
      {
        title: 'المدين',
        dataIndex: 'debit',
        // width: 192,
        render: (_, record) => {
          return (
            <div className="row-cell row-text">
              <span className="name-it-self">
                {record.debit ? record.debit : '-----'}
              </span>
            </div>
          );
        }

      }
      ,
      {
        title: 'الدائن',
        dataIndex: 'credit',
        // width: 192,
        render: (_, record) => {
          return (
            <div className="row-cell row-text">
              <span className="name-it-self">
                {record.credit ? record.credit : '-----'}
              </span>
            </div>
          );
        }

      },
      {
        title: 'اسم المشتري',
        dataIndex: 'payer',
        // width: 192,
        render: (_, record) => {
          return (
            <div className="row-cell row-text">
              <span className="name-it-self">
                {record.payer ? record.payer : '-----'}
              </span>
            </div>
          );
        }

      },
      {
        title: ' طريقة الدفع',
        dataIndex: 'method',
        // width: 192,
        render: (_, record) => {
          return (
            <div className="row-cell row-text">
              <span className="name-it-self">
                {record.method ? record.method : '-----'}
              </span>
            </div>
          );
        }
      },
      {
        title: ' الملاحظات ',
        dataIndex: 'note',
        // width: 192,
        render: (_, record) => {
          return (
            <div className="row-cell row-text">
              <span className="name-it-self">
                {record.note ? record.note : '-----'}
              </span>
            </div>
          );
        }
      }

    ];
  }

  return [
    {
      title: 'الكود',
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },

    {
      title: 'التفاصيل',
      dataIndex: 'desc',
      // width: 192,
      render: (_, record) => {
        if (record.description) {
          const wordsCount = countWords(String(record.description));
          if (wordsCount > 8) {
            return (
              <Popover title="اقرأ المزيد" content={String(record.description)}>
                <Button size="large" type="text">
                  <p className="has-read-more">
                    <span className="read-more-span ltr-text">
                      {trimWords(String(record.description), 8)}
                    </span>
                    <ReadOutlined
                      className="read-more-book"
                      style={{ margin: '0 4px' }}
                    />
                  </p>
                </Button>
              </Popover>
            );
          } else
            return (
              <div className="row-cell row-text ltr-text">
                {String(record.description)}
              </div>
            );
        } else {
          return '----';
        }
      }
    },
    {
      title: 'التاريــخ',
      dataIndex: 'date',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.created_at ? moment(record.created_at).format("MM/DD/YYYY HH:mm:ss") : '-----'}
            </span>
          </div>
        );
      }
    },
    {
      title: 'الحساب',
      dataIndex: 'balance',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.the_amount ? record.the_amount : '-----'}
            </span>
          </div>
        );
      },
      sorter: (a, b) => parseFloat(a.the_amount) - parseFloat(b.the_amount)
    },
    {
      title: 'الاعدادات',
      width: 222,
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default TableColumns;
