import React from 'react';
import { useController } from 'react-hook-form';
import { Empty, Form, Select } from 'antd';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import { useTranslation } from 'react-i18next';
const AntdSelectOption = ({
  control,
  name,
  label,
  placeholder,
  setValue,
  validateStatus,
  errorMsg,
  options,
  showSearch,
  onSearch,
  filterOption,
  formClassName,
  // onSelect
  onChange,
  mode,
  disabled,
  maxWidth
}) => {
  const {
    // field: { ...inputProps }
    field
  } = useController({
    name,
    control
  });
  const { Option } = Select;

  const hanldeClear = () => {
    setValue(name, '');
  };
  const { t } = useTranslation();
  return (
    <Form.Item
      label={label}
      help={errorMsg}
      validateStatus={validateStatus}
      style={{ maxWidth: maxWidth || 'auto' }}
    >
      <Select
        disabled={disabled ? disabled : false}
        onClear={hanldeClear}
        // onChange={onChange && onChange()}
        {...field}
        onChange={(e) => {
          field.onChange(e);
          onChange && onChange(e);
        }}
        mode={mode ? mode : null}
        // onChange={field.onChange}
        // onBlur={field.onBlur}
        notFoundContent={
          <Empty description={false}>{t('purchaseInvoices.noData')}</Empty>
        }
        placeholder={placeholder}
        size="large"
        suffixIcon={<ArrowDownIcon />}
        // allowClear
        getPopupContainer={() => document.querySelector(`.${formClassName}`)}
        showSearch={showSearch ? showSearch : false}
        filterOption={filterOption ? filterOption : true}
        onSearch={onSearch ? onSearch : null}
      >
        {options?.length > 0 &&
          options.map((op, index) => (
            <Option key={index} value={String(op.value)} >
              <div style={{ display: "flex", alignItems: "center" ,height:"100%",width:'100%' }}>
                {op.title}
              </div>
            </Option>
          ))}
      </Select>
    </Form.Item>
  );
};

export default AntdSelectOption;
