import React, { useContext } from 'react';
import warehouseImg from '../../../assets/imgs/store/store-item-manager.png';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import InstallmentsPageContext from '../../../contexts/installments-context/InstallmentsContext';
import StoreWelcomeSharedSection from '../../store-pages/store-welcome-shared-section/StoreWelcomeSharedSection';
import InstallmentsPageTable from '../store-incoming-purchase-invoices-page/InstallmentsPageTable';
import InstallmentModal from './InstallmentModal';
import InstallmentsTableFilter from './InstallmentsTableFilter';
import './installmentsPage.scss';

const SaleInvoiceInstallmentsPage = () => {
  const { modalOpened } = useContext(InstallmentsPageContext);

  return (
    <div className="installments-page">
      <div className="page-head-wrap">
        <StoreWelcomeSharedSection
          img={warehouseImg}
          title="إدارة الايصالات !"
          subTitle="من الجيد عودتك مرة اخرى لدينا."
        />
        <div className="search-wrap">
          <SearchNotificationsProfile noSearch />
        </div>
      </div>
      <div className="custom-filter-add-section">
        {/* <div className="add-section">
          <div
            className="add-btn"
            onClick={() => {
              // if selected unit then reset it
              // open the unit modal
              setSelectedIntallment(null);
              setModalOpened(true);
            }}
          >
            <span className="text-span">إضافة قسط جديد</span>
          </div>
        </div> */}
        <InstallmentsTableFilter />
      </div>
      <div className="saleInvoiceInstallmentsPage-table-wrapper">
        <InstallmentsPageTable />
      </div>
      {modalOpened && <InstallmentModal />}
    </div>
  );
};

export default SaleInvoiceInstallmentsPage;
