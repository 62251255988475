import { createContext, useState } from 'react';

const INITIAL_VALUES = {
  isLoadingAllProfitLosesReports: false,
  setIsLoadingAllProfitLosesReports: (v) => {},
  fetchCountProfitLosesReports: 0,
  setFetchCountProfitLosesReports: (v) => {},
  allFetchedProfitLosesReports: [],
  setAllFetchedProfitLosesReports: (v) => {},
  selectedProfitLosesReport: null,
  setSelectedProfitLosesReport: (v) => {},
  selectedProfitLosesReportsModalOpned: false,
  setSelectedProfitLosesReportsModalOpened: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {},
  // filter
  tableFilter: {
    period: 1,
    from: '',
    to: '',
  },
  setTableFilter: (v) => {}
};

const ProfitLosesReportsContext = createContext({ INITIAL_VALUES });

export const ProfitLosesReportsProvider = ({ children }) => {
  const [isLoadingAllProfitLosesReports, setIsLoadingAllProfitLosesReports] = useState(
    INITIAL_VALUES.isLoadingAllProfitLosesReports
  );
  const [fetchCountProfitLosesReports, setFetchCountProfitLosesReports] = useState(
    INITIAL_VALUES.fetchCountProfitLosesReports
  );
  const [allFetchedProfitLosesReports, setAllFetchedProfitLosesReports] = useState(
    INITIAL_VALUES.allFetchedProfitLosesReports
  );
  const [selectedProfitLosesReport, setSelectedProfitLosesReport] = useState(
    INITIAL_VALUES.selectedProfitLosesReport
  );

  const [selectedProfitLosesReportsModalOpned, setSelectedProfitLosesReportsModalOpened] =
    useState(INITIAL_VALUES.selectedProfitLosesReportsModalOpned);

  const [tableFilter, setTableFilter] = useState(INITIAL_VALUES?.tableFilter);

  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES?.tablePagination
  );

  return (
    <ProfitLosesReportsContext.Provider
      value={{
        isLoadingAllProfitLosesReports,
        setIsLoadingAllProfitLosesReports,
        fetchCountProfitLosesReports,
        setFetchCountProfitLosesReports,
        allFetchedProfitLosesReports,
        setAllFetchedProfitLosesReports,
        selectedProfitLosesReport,
        setSelectedProfitLosesReport,
        selectedProfitLosesReportsModalOpned,
        setSelectedProfitLosesReportsModalOpened,
        //
        tableFilter,
        setTableFilter,
        //
        tablePagination,
        setTablePagination
      }}
    >
      {children}
    </ProfitLosesReportsContext.Provider>
  );
};

export default ProfitLosesReportsContext;
