/* eslint-disable react-hooks/exhaustive-deps */
import { Empty, Table } from 'antd';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import getAllListApi from '../../apis/fatoorahStore-api/getAllListApi';
import ClientsContext from '../../contexts/clients-context/ClientsContext';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
import tableColumns from './tableColumns';
import { useTranslation } from 'react-i18next';

const stores = [
  {id : '11' , name : 'salla - سلة' , date : '1-1-2024', type: 'salla'},
  {id : '12' , name : 'zid - زيد' , date : '1-1-2024', type: 'zid'},
  {id : '13' , name : 'zapier - زابير' , date : '1-1-2024', type: 'zapier'}
]

const PageTable = (props) => {
  const {
    allFetchedClients,
    setAllFetchedClients,
    isLoading,
    setIsLoading,
    fetchCount,
    setFetchCount,
    setModalOpened,
    setSelectedClient,
    nameSearchValue,
    tablePagination,
    setTablePagination
  } = useContext(ClientsContext);
  const { user } = useContext(UserContext);
  const systemSettings = useSystemSettings();
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    // if (data !== undefined && data != []) {
    //   return data?.map((obj, index) => ({
    //     ...obj,
    //     key: obj.id
    //   }));
    // }
  };
  const { t } = useTranslation();

  const customApiRequest = useCustomApiRequest();
  const { search } = useLocation();
  const values = queryString.parse(search);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllListApi(user?.token, user?.client_id),
        (res) => {
          setIsLoading(false);
          if (res?.data?.data?.length >= 0 && res?.data?.data !== 'fail') {
            const data = res?.data?.data;
            setAllFetchedClients(data);
          }
          if (res?.data?.data?.pagination) {
            setTablePagination(res.data.data.pagination);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  useEffect(() => {
    if (values.page) {
      setTablePagination({ ...tablePagination, current_page: +values.page });
    }
  }, [search]);

  const history = useHistory();

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedClients?.length >= 0) {
      let mappedData = null;
      if (nameSearchValue.trim()) {
        const filtered = allFetchedClients.filter((user) =>
          user.name
            .toLowerCase()
            .trim()
            .includes(nameSearchValue.toLowerCase().trim())
        );
        mappedData = mapDataSource(filtered);
      } else {
        mappedData = mapDataSource(allFetchedClients);
      }
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    fetchCount,
    allFetchedClients,
    allFetchedClients?.length,
    nameSearchValue
  ]);

  return (
    <section>
      <Table
        locale={{
          emptyText: (
            <Empty description={false}>{t('Messages.noDataAvailable')}</Empty>
          )
        }}
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }} tableDataSource
        pagination={false}
        className="antd-custom-table"
        dataSource={stores}
        columns={tableColumns(
          stores,
          setModalOpened,
          setFetchCount,
          setSelectedClient,
          setIsLoading,
          user?.token,
          systemSettings?.sallaToken
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
      {/* {tablePagination && (
        <Pagination
          style={{display:"flex" , justifyContent:"center"}}
          defaultCurrent={1}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}

          onChange={(page, pageSize) => {
            setTablePagination({...tablePagination ,current_page:page })
            // setTablePagination(...tablePagination , {current_page:page})
            setFetchCount((prev) => prev + 1);
              history.push(
                `${storeRouterLinks?.clientPage}?page=${page}`
              );
            // if (isReportsPage) {
            //   history.push(
            //     `${storeRouterLinks?.outgoingPurchaseInvoiceReports}?page=${page}`
            //   );
            // } else {
            //   history.push(
            //     `${storeRouterLinks?.outgoingPurchaseInvoice}?page=${page}`
            //   );
            // }
          }}
          hideOnSinglePage={true}
        />

      )} */}
    </section>
  );
};

export default PageTable;
