import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import UserContext from '../contexts/user-context/UserProvider';

const DocTitleScrollTop = (docTitle) => {
  const { user } = useContext(UserContext);
  const { pathname } = useLocation();
  return useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${docTitle ? `${docTitle} |` : ''} ${
      user && user?.subscriptionType === 1
        ? process.env.REACT_APP_TITLE_PRODUCTS
        : user && user?.subscriptionType === 2
        ? process.env.REACT_APP_TITLE_SERVICES
        : process.env.REACT_APP_TITLE
    }`;

    return () => {
      document.title =
        user && user?.subscriptionType === 1
          ? process.env.REACT_APP_TITLE_PRODUCTS
          : user && user?.subscriptionType === 2
          ? process.env.REACT_APP_TITLE_SERVICES
          : process.env.REACT_APP_TITLE;
    };
  }, [docTitle, pathname, user?.subscriptionType]);
};

export default DocTitleScrollTop;
