import React from 'react';

const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.571"
      height="11.78"
      viewBox="0 0 9.571 11.78"
    >
      <g
        id="Group_982"
        data-name="Group 982"
        transform="translate(-122.714 -433.61)"
      >
        <path
          id="Path_2389"
          data-name="Path 2389"
          d="M56.651,1.473H54.626V1.1a1.106,1.106,0,0,0-1.1-1.1H52.049a1.106,1.106,0,0,0-1.1,1.1v.368H48.92a.921.921,0,0,0-.92.92V3.681a.368.368,0,0,0,.368.368h.2l.318,6.679a1.1,1.1,0,0,0,1.1,1.052h5.59a1.1,1.1,0,0,0,1.1-1.052L57,4.049h.2a.368.368,0,0,0,.368-.368V2.393A.921.921,0,0,0,56.651,1.473ZM51.681,1.1a.369.369,0,0,1,.368-.368h1.473a.369.369,0,0,1,.368.368v.368H51.681ZM48.736,2.393a.184.184,0,0,1,.184-.184h7.731a.184.184,0,0,1,.184.184v.92h-8.1Zm7.212,8.3a.368.368,0,0,1-.368.351H49.99a.368.368,0,0,1-.368-.351l-.316-6.644h6.959Z"
          transform="translate(74.714 433.61)"
          fill="#fff"
        />
        <path
          id="Path_2390"
          data-name="Path 2390"
          d="M240.368,213.522a.368.368,0,0,0,.368-.368v-4.786a.368.368,0,0,0-.736,0v4.786A.368.368,0,0,0,240.368,213.522Z"
          transform="translate(-112.868 230.396)"
          fill="#fff"
        />
        <path
          id="Path_2391"
          data-name="Path 2391"
          d="M320.368,213.522a.368.368,0,0,0,.368-.368v-4.786a.368.368,0,1,0-.736,0v4.786A.368.368,0,0,0,320.368,213.522Z"
          transform="translate(-191.027 230.396)"
          fill="#fff"
        />
        <path
          id="Path_2392"
          data-name="Path 2392"
          d="M160.368,213.522a.368.368,0,0,0,.368-.368v-4.786a.368.368,0,0,0-.736,0v4.786A.368.368,0,0,0,160.368,213.522Z"
          transform="translate(-34.708 230.396)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default DeleteIcon;
