import React from 'react';

const CogIcon = ({ color }) => {
  return (
    <svg
      id="Group_797"
      data-name="Group 797"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        id="Path_2670"
        data-name="Path 2670"
        d="M22.433,9.753,20.923,8.6a5.64,5.64,0,0,0,.036-.6,5.47,5.47,0,0,0-.036-.6l1.512-1.151a.709.709,0,0,0,.179-.919L21.043,2.68a.737.737,0,0,0-.908-.318l-1.782.7a6.152,6.152,0,0,0-1.07-.6L17.011.613A.721.721,0,0,0,16.285,0H13.136a.719.719,0,0,0-.724.608L12.14,2.455a6.342,6.342,0,0,0-1.068.6l-1.787-.7a.752.752,0,0,0-.9.313L6.811,5.323a.7.7,0,0,0,.178.923L8.5,7.4A5.138,5.138,0,0,0,8.5,8.6L6.988,9.755a.709.709,0,0,0-.178.919L8.38,13.319a.736.736,0,0,0,.908.318l1.782-.7a6.206,6.206,0,0,0,1.069.6l.271,1.84a.72.72,0,0,0,.726.615h3.149a.72.72,0,0,0,.725-.608l.272-1.847a6.371,6.371,0,0,0,1.068-.605l1.787.7a.753.753,0,0,0,.268.049.726.726,0,0,0,.633-.361l1.577-2.66A.707.707,0,0,0,22.433,9.753Zm-7.722.913A2.668,2.668,0,1,1,17.449,8,2.706,2.706,0,0,1,14.711,10.667Z"
        transform="translate(-6.71)"
        fill={color ? color : '#bcbccb'}
      />
    </svg>
  );
};

export default CogIcon;
