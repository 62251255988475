/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import SettingsCategoriesContext from '../../../contexts/settings-contexts/SettingsCategoriesProvider';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import addNewCategoryApi from '../../../apis/settings-apis/categories-apis/addNewCategoryApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import { useTranslation } from 'react-i18next';

const TableForm = () => {
  const { setIsLoading, setModalOpened, setFetchCount, selectedCategory } =
    useContext(SettingsCategoriesContext);
  const [, setIsSubmittingForm] = React.useState(false);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    category_name: Yup.string()
      .required(t('Inventory.Categories.enterCategoryName'))
      .max('100', t('Inventory.Products.maxProductNameLength')),
    category_description: Yup.string().max(
      '250',
      t('Inventory.Products.maxProductNameLength')
    )
  });
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      category_name: '',
      category_description: ''
    }
  });

  const customApiRequest = useCustomApiRequest();

  const onSubmit = async (data) => {
    setIsLoading(true);
    if (selectedCategory) {
      const mappedData = { ...data };
      mappedData.level = 1;
      mappedData.cat_id = 0;
      mappedData.id = selectedCategory.id;
      const fromData = new FormData();
      // const mappedData = { ...data };

      // if (data.main_category_id) fromData.append('cat_id', 0);
      if (data.category_description)
        fromData.append('description', data.category_description);
      if (data.category_name) fromData.append('name', data.category_name);
      if (selectedCategory.id) fromData.append('id', selectedCategory.id);
      fromData.append('level', 1);

      customApiRequest(
        addNewCategoryApi(user?.token, fromData, true),
        (res) => {
          setIsLoading(false);
          setModalOpened(false);
          if (checkRes(res) && res.data.data) {
            setIsSubmittingForm(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Inventory.Categories.addedSuccessfully'),
              message: t('Inventory.Categories.categoryModifiedSuccessfully')
            });
          } else {
            setModalOpened(false);
            errorNotification({
              title: t('Inventory.Categories.errorOccurred'),
              message: res?.data.message || t('Inventory.Categories.tryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('Inventory.Categories.errorOccurred'),
            message:
              error?.response?.data?.message ||
              t('Inventory.Categories.tryLater')
          });
        }
      );
    } else {
      const fromData = new FormData();
      // const mappedData = { ...data };

      // if (data.main_category_id) fromData.append('cat_id', 0);
      if (data.category_description)
        fromData.append('description', data.category_description);
      if (data.category_name) fromData.append('name', data.category_name);
      fromData.append('level', 1);

      const mappedData = { ...data };
      mappedData.level = 1;
      mappedData.cat_id = 0;
      setIsLoading(true);
      setIsSubmittingForm(true);
      customApiRequest(
        addNewCategoryApi(user?.token, fromData),
        (res) => {
          setIsLoading(false);
          setModalOpened(false);
          if (checkRes(res) && res.data.data) {
            setIsSubmittingForm(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Inventory.Categories.addedSuccessfully'),
              message: t('Inventory.Categories.operationCompletedSuccessfully')
            });
          } else {
            setModalOpened(false);
            errorNotification({
              title: t('Inventory.Categories.errorOccurred'),
              message: res?.data.message || t('Inventory.Categories.tryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('Inventory.Categories.errorOccurred'),
            message:
              error?.response?.data?.message ||
              t('Inventory.Categories.tryLater')
          });
        }
      );
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedCategory) {
      setValue(
        'category_name',
        selectedCategory?.name ? selectedCategory.name : ''
      );
      setValue(
        'category_description',
        selectedCategory?.categoryDescription
          ? selectedCategory.categoryDescription
          : ''
      );
    }
  }, [selectedCategory, selectedCategory?.id]);

  const [form] = Form.useForm();
  return (
    <Form
      className="settings-warehouse-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.Categories.categoryName')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="category_name"
              type="text"
              placeholder={t('Inventory.Categories.categoryNamePlaceholder')}
              errorMsg={errors?.category_name?.message}
              validateStatus={errors?.category_name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.Categories.categoryDetails')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="category_description"
              rows={8}
              placeholder={t('Inventory.Categories.categoryDetailsPlaceholder')}
              errorMsg={errors?.category_description?.message}
              validateStatus={errors?.category_description ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmitting}
        >
          {selectedCategory
            ? t('Inventory.Categories.edit')
            : t('Inventory.Categories.add')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
