/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import getAllClientsApi from '../apis/clients-apis/getAllClientsApi';
import UserContext from '../contexts/user-context/UserProvider';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';

const useClientsWithTax = () => {
  const [isLoadingClients, setIsLoadingClients] = useState(false);
  const [allClientsTaxFetchCount, setAllClientsTaxFetchCount] = useState(null);
  const [allClientWithTax, setAllClientWithTax] = useState([]);
  const { user } = useContext(UserContext);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoadingClients(true);
      customApiRequest(
        getAllClientsApi(user?.token, false, false, 1),
        (res) => {
          setIsLoadingClients(false);
          if (checkRes(res) && res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllClientWithTax(data);
          }
        },
        (error) => {
          setIsLoadingClients(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [allClientsTaxFetchCount]);

  return {
    allClientWithTax,
    isLoadingClients,
    allClientsTaxFetchCount,
    setAllClientsTaxFetchCount
  };
};

export default useClientsWithTax;
