import React, { useContext } from 'react';
import { Modal } from 'antd';
import SuppliersContext from '../../contexts/suppliers-context/SuppliersContext';
import StoreNewSupplierForm from './StoreNewClientForm';
import './NewSupplierModal.scss';
import ClientsContext from '../../contexts/clients-context/ClientsContext';
import StoreNewClientForm from './StoreNewClientForm';
import { useTranslation } from 'react-i18next';
import TableForm from '../../pages/clients-page/TableForm';

const NewClientModal = ({ setAllClientsFetchCount, isClient = false }) => {
  const {
    clientModalOpened,
    setClientModalOpened,
    selectedClient,
    setSelectedClient
  } = useContext(ClientsContext);
  const { t } = useTranslation();

  const renderModalTitle = () => {
    if (selectedClient) {
      return t('Quotations.EditSupplierData');
    } else {
      if (isClient) {
        return t('Quotations.AddNewClient');
      }
      return t('Quotations.AddNewSupplier');
    }
  };

  return (
    <Modal
      className="new-supplier-modal"
      width="90%"
      style={{ maxWidth: '642px' }}
      // title={selectedClient ? t('Quotations.EditSupplierData') : t('Quotations.AddNewSupplier')}
      title={renderModalTitle()}
      visible={clientModalOpened}
      onOk={() => {
        setClientModalOpened(false);
        setSelectedClient(null);
      }}
      onCancel={() => {
        setClientModalOpened(false);
        setSelectedClient(null);
      }}
      footer={false}
    >
      {/* <StoreNewClientForm
        setAllClientsFetchCount={setAllClientsFetchCount}
        isClient={isClient}
      /> */}
      <TableForm setAllClientsFetchCount={setAllClientsFetchCount} />
    </Modal>
  );
};

export default NewClientModal;
