/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import SearchNotificationsProfile from '../../components/search-notifications-profile/SearchNotificationsProfile';
import UpgradePageBoxs from './UpgradePageBoxs';
import { Spin } from 'antd';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import getPackagesToUpgradeApi from '../../apis/upgrade/getPackagesToUpgradeApi';
import PosCount from './PosCount';
import UpgradeContext from '../../contexts/upgrade-context/UpgradeContext';
import PaymentSelection from './PaymentSelection';
import './UpgradePage.scss';
import DocTitleScrollTop from '../../utils/DocTitleScrollTop';
import UserContext from '../../contexts/user-context/UserProvider';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import Countdown from '../../components/countdown/Countdown';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
const UpgradePage = () => {
  DocTitleScrollTop();
  const { user } = useContext(UserContext);
  const { setFetchedPackages, fetchedPackages } = useContext(UpgradeContext);
  const systemSettings = useSystemSettings();
  const [isLoading, setIsLoading] = useState(false);
  const customApiRequest = useCustomApiRequest();
  const { t } = useTranslation();

  useEffect(() => {
    let isMounted = true;

    const successCallback = (res) => {
      if (res?.data?.data) {
        const data = res?.data?.data;
        setFetchedPackages(data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };

    const errorCallback = (error) => {
      setIsLoading(false);
    };

    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getPackagesToUpgradeApi(),
        successCallback,
        errorCallback
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const renderPackages = () => {
    if (isLoading) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 332
          }}
        >
          <Spin />
        </div>
      );
    }

    if (fetchedPackages?.packages?.length === 0) {
      return <p>{t('FatoorahPackages.noPackages')}</p>;
    }
    if (fetchedPackages?.packages?.length > 0) {
      return <UpgradePageBoxs data={fetchedPackages} />;
    }
    return null;
  };

  return (
    <div className={`upgrade-page ${user?.expire === 1 ? 'expired' : ''}`}>
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">{t('FatoorahPackages.title')}</h2>
          <p>{t('FatoorahPackages.subTitle')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('AppBar.Products.upgradePage')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title3={t('AppBar.Products.upgradePage')}
            link3={'/archive/calendar'}
          />
          <p>{t('FatoorahPackages.subTitle')}</p>
        </div>
        <SearchNotificationsProfile />
      </div>
      {fetchedPackages && (user?.packageId == 2 || user?.packageId == 3 || user?.packageId == 4 || user?.packageId == 5) && (
        <div className="countdown">
          <Countdown />
          <p className="expiry-date">
            {t('Countdown.PackageExpiryDate')}:
            <span>
              {systemSettings?.end_date?.substring(0,10)}
              {/* {systemSettings?.end_date.substring(
                0,
                systemSettings  .end_date.indexOf(' ')
              )} */}
            </span>
          </p>
        </div>
      )}

      <div className="page-body">
        {renderPackages()}
        <PosCount />
        <PaymentSelection />
      </div>
    </div>
  );
};

export default UpgradePage;
