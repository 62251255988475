/* eslint-disable react-hooks/exhaustive-deps */
import { Descriptions, Modal,Button,Tooltip } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import React, { useContext, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import StoreConversionContext from '../../../contexts/store-conversion-context/StoreConversionProvider';


const SelectedConversionModal = () => {
  const { t } = useTranslation();
  const dataRef = useRef()
  const {
    setSelectedConversion,
    selectedConversion,
    setSelectedConversionModalOpened,
    selectedConversionModalOpened,
  } = useContext(StoreConversionContext);

  const handlePrint = useReactToPrint({
    content: () => dataRef.current,
    pageStyle: `
      @media print {
        html, body { height: initial !important; overflow: initial !important; }
        tr { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
        td { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
        tbody { display: table-row-group !important; }
        thead {
          display: table-row-group !important;
        }
        tfoot {
          display: table-row-group !important;
        }
      @page {
        size: auto;
        margin: none;
      }
      .pagebreak {
        display: block;
        size: auto;
      }
      .ant-descriptions-item-content,
        .ant-descriptions-item-label {
          padding: 4px 12px !important;
        }
      }
      .ant-descriptions-header {
        margin: 4px 0 !important;
      }
      .models-title {
        direction: rtl;
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .ant-table-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
      }
      th {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      .conversion-details{
        padding:40px;
      }
      .ant-descriptions-title{
        margin-bottom:20px;
      }
    `
  });
  const renderModalBody = () => {
    return (
      <div className="conversion-details" ref={dataRef}>
        <Descriptions
          title={t('Inventory.TransferStocks.transferDetails')}
          bordered
          column={1}
          labelStyle={{width:"250px" , height:"40px"}}
          contentStyle={{height:"40px"}}
          
        >
          {selectedConversion?.name && (
            <Descriptions.Item  label={t('Inventory.TransferStocks.product')}>
              {selectedConversion?.name}
            </Descriptions.Item>
          )}
          {selectedConversion?.quantity && (
            <Descriptions.Item label={t('Inventory.TransferStocks.quantityTransferred')}>
              {selectedConversion?.quantity}
            </Descriptions.Item>
          )}
          {selectedConversion?.fromStock?.name && (
            <Descriptions.Item label={t('Inventory.TransferStocks.warehouseTransferredFrom')}>
              {selectedConversion?.fromStock?.name }
            </Descriptions.Item>
          )}
          {selectedConversion?.intoStock?.name && (
            <Descriptions.Item label={t('Inventory.TransferStocks.warehouseTransferredTo')}>
              {selectedConversion?.intoStock?.name }
            </Descriptions.Item>
          )}
         
        </Descriptions>
      </div>
    );
  };

  return (
    <Modal
      className="shared-custom-modal selected-product-modal"
      width="90%"
      style={{ maxWidth: '942px' }}
      title={
        <>
          {t('Inventory.TransferStocks.transferDetails')}
          <Tooltip title="">
          <Button
          onClick={handlePrint}
            className="circled-btn"
            type="dashed"
            shape="circle"
            icon={<PrinterOutlined />}
            style={{margin: "0 10px"}}
          />
        </Tooltip>
        </>
    }
      visible={selectedConversionModalOpened}
      onOk={() => {
        setSelectedConversionModalOpened(false);
        setSelectedConversion(null);
      }}
      onCancel={() => {
        setSelectedConversionModalOpened(false);
        setSelectedConversion(null);
      }}
      footer={false}
    >

     { renderModalBody()}

    </Modal>
  );
};

export default  SelectedConversionModal ;
