import React, { useState, createContext, useEffect, useContext } from 'react';
import mainAppBarLinks from '../../components/main-app-bar/mainAppBarLinks';
import userRoles from '../../userRoles';
import UserContext from '../user-context/UserProvider';
import accountantLinks from '../../components/main-app-bar/accountantLinks';
import dataEntryLinks from '../../components/main-app-bar/dataEntryLinks';
import delegateLinks from '../../components/main-app-bar/delegateLinks';
import partnerLinks from '../../components/main-app-bar/partnerLinks';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

let defaultLink = [
  {
    homePage: '/',
    signInPage: '/'
  }
];
const INITIAL_VALUES = {
  links: [defaultLink],
  setLinks: (v) => {},
  selectedLink: null,
  setSelectedLink: (v) => {},
  activeLink: null, // to handle the color of the current nav link
  setActiveLink: (v) => {}, // to handle the color of the current nav link
  menuVisible: false,
  setMenuVisible: (v) => {}
};

const MainAppBarContext = createContext(INITIAL_VALUES);

export const MainAppBarProvider = ({ children }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [links, setLinks] = useState(INITIAL_VALUES.links);
  const [selectedLink, setSelectedLink] = useState(INITIAL_VALUES.selectedLink);
  const [activeLink, setActiveLink] = useState(INITIAL_VALUES.activeLink);
  const [menuVisible, setMenuVisible] = useState(INITIAL_VALUES.menuVisible);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMenuVisible(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  // check roles
  useEffect(() => {
    if (user) {
      // manager
      if (user?.role === userRoles?.manager) {
        mainAppBarLinks(t) !== undefined && setLinks([...mainAppBarLinks(t)]);
      }
      // accountant
      if (user?.role === userRoles?.accountant) {
        setLinks([...accountantLinks(t)]);
      }
      // partner
      if (user?.role === userRoles?.partner) {
        setLinks([...mainAppBarLinks(t)]);
      }
      // data entry
      if (user?.role === userRoles?.dataEntry) {
        setLinks([...dataEntryLinks(t)]);
      }
      // pos user
      if (user?.role === userRoles?.posUser) {
        setLinks([...mainAppBarLinks(t)]);
      }
      // delegate user
      if (user?.role === userRoles?.delegate) {
        setLinks([...delegateLinks(t)]);
      }
    }
  }, [user, Cookies.get('i18next')]);

  return (
    <MainAppBarContext.Provider
      value={{
        links,
        setLinks,
        selectedLink,
        setSelectedLink,
        activeLink,
        setActiveLink,
        menuVisible,
        setMenuVisible,
        useOutsideAlerter
      }}
    >
      {children}
    </MainAppBarContext.Provider>
  );
};

export default MainAppBarContext;
