/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import SettingsMeasurementUnitsContext from '../../contexts/settings-contexts/SettingsMeasurementUnitsPage';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import addNewUnitApi from '../../apis/settings-apis/measuremnet-units-apis/addNewUnitApi';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import MyDropZone from '../../common/dorp-zone/MyDropZone';
import { CameraFilled } from '@ant-design/icons';
import MyDropZonePreview from '../../common/dorp-zone/MyDropZonePreview';
import useClients from '../../custom-hooks/useClients';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
import ceateNewTicketApi from '../../apis/technical-support-page/createNewTicketApi';
import TechnicalSupportContext from '../../contexts/technical-support-context/TechnicalSupportContext';
const schema = Yup.object().shape({
  description: Yup.string().required('ادخل الوصف'),
  subject: Yup.string().required('ادخل العنوان'),
  priority: Yup.string().required('من فضلك أدخل الأولوية'),
  attach: Yup.mixed()
});

const TableForm = () => {
  const { user } = useContext(UserContext);
  const {
    selectedTicket,
    setIsLoading,
    setModalOpened,
    setFetchCount,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(TechnicalSupportContext);
  const customApiRequest = useCustomApiRequest();
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    clearErrors,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      subject: selectedTicket?.subject ? selectedTicket?.subject : '',
      priority: selectedTicket?.priority ? selectedTicket?.priority : '',
      description: selectedTicket?.description
        ? selectedTicket?.description
        : ''
    }
  });

  const [invoiceFileToUpload, setInvoiceFileToUpload] = useState(
    watch('attach') ? watch('attach') : null
  );
  useEffect(() => {
    setValue('attach', invoiceFileToUpload);
    if (watch('attach')) {
      clearErrors('attach');
    }
  }, [invoiceFileToUpload, invoiceFileToUpload?.length]);

  function getBase64(attach) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(attach);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handleFilesDrop = async (acceptedFiles, formFiles, setFormFiles) => {
    const duplicates = [];
    for (let i of acceptedFiles) {
      if (formFiles?.length) {
        for (let f of formFiles) {
          if (i.name === f.name) {
            duplicates.push(i);
          }
        }
      }
    }
    for (let i = 0; i < acceptedFiles.length; i++) {
      for (let f of duplicates) {
        if (acceptedFiles[i].name === f.name) {
          acceptedFiles.splice(i, 1);
        }
      }
    }
    acceptedFiles.forEach(async (file) => {
      const preview = await getBase64(file);
      Object.assign(file, {
        preview
      });
      setFormFiles((prevState) => [file]);
      if (formFiles?.length > 0) {
        setFormFiles((currState) => [file]);
      } else {
        setFormFiles([file]);
      }
    });
  };

  const clearFileFromUpload = (file, setFilesToUpload) => {
    setFilesToUpload((prevState) => {
      const filtered = prevState.filter((asset) => asset.name !== file.name);
      if (filtered?.length === 0) return null;
      return filtered;
    });
  };

  const onSubmit = async (data) => {
    const mappedData = {};
    if (data.description) mappedData.description = data.description;
    if (data.priority) mappedData.priority = data.priority;
    if (data.attach && !selectedTicket) mappedData.attach = data.attach[0];
    if (data.subject) mappedData.subject = data.subject;
    const formData = new FormData();
    for (let key in mappedData) {
      formData.append(key, mappedData[key]);
    }
    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedTicket) {
      customApiRequest(
        ceateNewTicketApi(user?.token, formData, selectedTicket?.id, true),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم تعديل التذكرة بنجاح'
            });
            setIsLoading(false);
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message
                ? res.data.message
                : 'حاول فى وقت لاحق'
            });
            setIsSubmittingForm(false);
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    } else {
      customApiRequest(
        ceateNewTicketApi(user?.token, formData),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: 'العملية تمت بنجاح',
              message: 'تم اضافه التذكرة بنجاح'
            });
          } else {
            errorNotification({
              title: 'حدث خطأ',
              message: res?.data?.message
                ? res.data.message
                : 'حاول فى وقت لاحق'
            });
          }
        },
        (error) => {
          setIsLoading(false);
          errorNotification({
            title: 'حدث خطأ',
            message: error?.response?.data?.message || 'حاول فى وقت لاحق'
          });
        }
      );
    }
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="select-label-wrap">
          <p className="label-p">الأولوية</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="priority"
              errorMsg={errors?.priority?.message}
              validateStatus={errors?.priority ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="الأولوية"
              options={[
                {
                  title: 'Low',
                  value: 'low'
                },
                {
                  title: 'Medium',
                  value: 'medium'
                },
                {
                  title: 'High',
                  value: 'high'
                }
              ]}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="custom-shared-form"
            />
          </div>
          {/* selectedTicket?.priority ? selectedTicket?.priority : '' */}
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">عنوان التذكرة</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="subject"
              type="text"
              placeholder="عنوان التذكرة..."
              errorMsg={errors?.subject?.message}
              validateStatus={errors?.subject ? 'error' : ''}
              control={control}
              setValue={setValue}
              defaultValue={
                selectedTicket?.subject ? selectedTicket?.subject : ''
              }
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">الوصف</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="description"
              rows={8}
              placeholder="الوصف..."
              errorMsg={errors?.description?.message}
              validateStatus={errors?.description ? 'error' : ''}
              control={control}
              setValue={setValue}
              defaultValue={
                selectedTicket?.description ? selectedTicket?.description : ''
              }
            />
          </div>
        </div>
        {!selectedTicket ? (
          <div className="product-img-wrap">
            <h3 style={{ marginBottom: '6px' }}>إرفاق الملف</h3>

            <MyDropZone
              className="product-header-dropzone"
              multipleFiles={false}
              handleFilesDrop={(acceptedFiles) =>
                handleFilesDrop(
                  acceptedFiles,
                  invoiceFileToUpload,
                  setInvoiceFileToUpload
                )
              }
              filesToUpload={invoiceFileToUpload}
              id="avatar-input"
              dropzoneText="إرفاق الملف"
              inputName="attach"
              icon={<CameraFilled className="dropzone-camera" />}
              dropZoneUrls={watch('attach') ? [watch('attach')] : null}
            >
              اسحب الصورة وضعها هنا
            </MyDropZone>

            <MyDropZonePreview
              filesToUpload={invoiceFileToUpload}
              clearFileFromUpload={(f) =>
                clearFileFromUpload(f, setInvoiceFileToUpload)
              }
            />
            {errors?.file && (
              <p
                className="error-p"
                style={{ color: '#ff4d4f', fontSize: '14px' }}
              >
                {errors?.file?.message}
              </p>
            )}
          </div>
        ) : null}
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedTicket ? 'تعديل' : 'إضافة'}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
