import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import SettingsWorkPlacesContext from '../../../contexts/settings-contexts/SettingsWorkPlacesProvider';
import SettingsWorkPlaceModal from './PageModal';
import PageTable from './PageTable';
import './WorkPlacesPage.scss';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

const WorkPlacesPage = () => {
  const { modalOpened, setModalOpened, setSelectedPlace } = useContext(
    SettingsWorkPlacesContext
  );
  const { t } = useTranslation();

  return (
    <div className="work-places-page">
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">{t('Inventory.WorkPlaces.title')}</h2>
          <p>{t('Inventory.WorkPlaces.subTitle')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('Inventory.WorkPlaces.title')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.storePage.title')}
            title3={t('Inventory.WorkPlaces.title')}
            link3={'/settings/work-places'}
          />
        </div>
        <SearchNotificationsProfile />
      </div>

      <div className="custom-filter-add-section">
        <div className="add-section">
          {/*
          <div className="section-text">
            <p className="bold-font">أماكن العمل</p>
          </div> 
          */}
          <div
            className="add-btn"
            onClick={() => {
              // if selected place then reset it
              // open the place modal
              setSelectedPlace(null);
              setModalOpened(true);
            }}
          >
            <span className="text-span">
              {t('Inventory.WorkPlaces.addNewWorkPlace')}
            </span>
          </div>
        </div>
      </div>

      <PageTable />

      {modalOpened && <SettingsWorkPlaceModal />}
    </div>
  );
};

export default WorkPlacesPage;
