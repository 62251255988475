import axios from 'axios';

const deleteJournalApi = async (token, id) => {
  let url;
  // let arrOfIds = id.split('-');
  // let realId = arrOfIds[arrOfIds.length-1]
  url = `/accounting/journal/delete/${id}`


  // if(leafType === 'subtype' || leafType === 'subchild'){
  //   url = `/accounting/subtype/delete/${realId}`
  // }
  // else if(leafType === 'account'){
  //   url = `/accounting/account/delete_account/${realId}`
  // } 


  try {
    const res = await axios({
      method: 'post',
      url: url,
      baseURL: process.env.REACT_APP_BASE_TEST_URL,
      // baseURL: 'https://testclient-api.fatoorah.sa/apiAdmin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      },
      // data: { id }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default deleteJournalApi;
