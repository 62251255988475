import axios from 'axios';
import moment from 'moment';

const getProfitLosesReportsApi = async (token , filterValues) => {
  try {
    const url = `/getProfitsAndLoses?client_id=${filterValues.client_id ? filterValues.client_id : ''}&period=${filterValues.period ? filterValues.period : ''}&from_date=${filterValues?.from ? moment(filterValues?.from).format("MM/DD/YYYY") : ''}&to_date=${filterValues?.to ? moment(filterValues.to).format("MM/DD/YYYY") : ''}`;

    const res = await axios.get(url, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'applications/json',
            Authorization: 'Bearer ' + token,
            lang: 'ar'
        }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default getProfitLosesReportsApi;
