import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import PageTable from './PageTable';
import PageModal from './PageModal';
import excelImg from '../../../assets/imgs/icons/excel-btn.png';
import StoreProductsIngredientsContext from '../../../contexts/store-products-ingredients-context/StoreProductsIngredientsProvider';
import './StoreIngredientsPage.scss';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';

const StoreIngredientsProductsPage = () => {
  const systemSettings = useSystemSettings();
  const { modalOpened, setModalOpened, setSelectedIngredient } = useContext(
    StoreProductsIngredientsContext
  );

  return (
    <div className="store-ingredients-page">
      <div className="page-head-wrap">
        <div className="page-title">
          <h2 className="bold-font">مكونات المنتجات</h2>
          <p>كشف وعرض كافة المكونات</p>
        </div>
        {/* <Countdown /> */}

        <SearchNotificationsProfile />
      </div>

      <div className="add-section-export-btn">
        <div className="add-section">
          {/* <div className="section-text">
          <p className="bold-font">وحدات القياس</p>
        </div> */}
          <div
            className="add-account-btn"
            onClick={() => {
              // if selected unit then reset it
              // open the unit modal
              setSelectedIngredient(null);
              setModalOpened(true);
            }}
          >
            <span className="text-span">إضافة مكون جديد</span>
          </div>
        </div>

        <div className="excel-btn-wrap">
          <a
            href={`${process.env.REACT_APP_BASE_URL}/Excel/exportStockMaterials?client_id=${systemSettings?.id}`}
            download
            className="excel-export-btn"
          >
            <img
              style={{ height: '42px' }}
              src={excelImg}
              alt="export to excel"
            />
          </a>
        </div>
      </div>
      <div className="table-wrapper ">
        <PageTable />
      </div>

      {modalOpened && <PageModal />}
    </div>
  );
};

export default StoreIngredientsProductsPage;
