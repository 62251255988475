import React, { useContext } from 'react';
import getAllTaxInvoiceReportsApi from '../../../apis/reports-apis/getAllTaxInvoiceReportsApi';
import { Button, DatePicker } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import CalendarIcon from '../../../common/icons/CalendarIcon';
import TaxInvoiceReportsContext from '../../../contexts/reports-contexts/TaxInvoiceReportsContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import { useTranslation } from 'react-i18next';
const { RangePicker } = DatePicker;

const PageReportFilter = ({
  handleClick,
  selectedPicker,
  setSelectedPicker
}) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  // const [selectedPicker, setSelectedPicker] = React.useState(null);
  const [filterValues, setFilterValues] = React.useState({
    from: null,
    to: null
  });
  const { setIsLoading, setFetchedReports } = useContext(
    TaxInvoiceReportsContext
  );

  const handlePickerChange = (value, string) => {
    setSelectedPicker({
      moments: value,
      strings: string
    });
  };

  React.useEffect(() => {
    if (selectedPicker?.strings?.length > 0) {
      setFilterValues((prev) => ({
        ...prev,
        from: selectedPicker.strings[0],
        to: selectedPicker.strings[1]
      }));
    }
  }, [selectedPicker?.strings]);

  const customApiRequest = useCustomApiRequest();
  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    customApiRequest(
      getAllTaxInvoiceReportsApi(user?.token, filterValues),
      (res) => {
        setIsLoading(false);
        if (checkRes(res) && res?.data.data) {
          const data = res.data.data;
          setFetchedReports(data);
        } else {
          errorNotification({
            title: t('taxInvoiceReports.errorOccurred'),
            message:
              res?.data?.message || t('taxInvoiceReports.tryLaterMessage')
          });
        }
      },
      (error) => {
        setIsLoading(false);
        errorNotification({
          title: t('taxInvoiceReports.errorOccurred'),
          message:
            error?.response?.data?.message ||
            t('taxInvoiceReports.tryLaterMessage')
        });
      }
    );
  }

  return (
    <div className="page-reports-filter">
      <form className="filter-form" onSubmit={handleSubmit}>
        <div className="range-picker-wrap">
          <RangePicker
            value={selectedPicker?.moments && selectedPicker.moments}
            onChange={handlePickerChange}
            // format="YYYY-MM-DD"
            className={`filter-range-picker`}
            allowClear={true}
            suffixIcon={<CalendarIcon />}
            placeholder={[
              t('taxInvoiceReports.dateFrom'),
              t('taxInvoiceReports.dateTo')
            ]}
          />
        </div>

        <div className="form-btns">
          <Button className="filter-submit-btn" htmlType="submit">
            {t('taxInvoiceReports.search')}
          </Button>
          <Button
            className="filter-submit-btn"
            htmlType="button"
            icon={<PrinterOutlined />}
            onClick={handleClick}
          >
            {t('taxInvoiceReports.printTaxInvoice')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default PageReportFilter;
