import axios from 'axios';

const getAllTicketsApi = async (token, filterValues) => {
  let url;
  if (!filterValues) {
    // url = `User/all?is_tax=${is_tax ? is_tax : ''}`;
    url = `tickets`;
  } else {
    url = `tickets?test=1&page=${
      filterValues.page ? filterValues.page : ''
    }&searchText=${filterValues.searchText ? filterValues.searchText : ''}`;
  }
  try {
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllTicketsApi;
