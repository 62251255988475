import { Popconfirm, Tooltip } from 'antd';
import deleteWorkPlaceApi from '../../../apis/settings-apis/work-places-apis/deleteWorkPlaceApi';
import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';

const tableColumns = (
  allFetchedPlaces,
  setModalOpened,
  setFetchCount,
  setSelectedPlace,
  setIsLoading,
  t,
  token
) => {
  const handleEditClick = (record) => {
    const foundPlace = allFetchedPlaces.find((place) => place.id === record.id);
    if (foundPlace) {
      setSelectedPlace({ ...foundPlace });
      setModalOpened(true);
    }
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteWorkPlaceApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Inventory.WorkPlaces.addedSuccessfully'),
          message: t('Inventory.WorkPlaces.workplaceSuccessfullyDeleted')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Inventory.WorkPlaces.errorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Inventory.WorkPlaces.pleaseTryLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Inventory.WorkPlaces.errorOccurred'),
        message: t('Inventory.WorkPlaces.pleaseTryLater')
      });
    }
  };

  const renderActions = (placeObj) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(placeObj)}>
          <EditIcon />
          {t('Inventory.WorkPlaces.edit')}
        </div>
        <Popconfirm
          title={t('Inventory.WorkPlaces.areyouSureToDelete')}
          onConfirm={() => handleDeleteRequest(placeObj.id)}
          okText={t('Inventory.WorkPlaces.yes')}
          cancelText={t('Inventory.WorkPlaces.no')}
        >
          <Tooltip title={t('Inventory.WorkPlaces.delete')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      title: t('Inventory.WorkPlaces.workplaceCode'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.WorkPlaces.nameWorkplace'),
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record.name}</span>
          </div>
        );
      },
      sorter: (a, b) => {
        let nameA = a.name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }
    },
    {
      title: t('Inventory.WorkPlaces.workplaceAddress'),
      dataIndex: 'address',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record.address}</span>
          </div>
        );
      }
    },
    
    {
      title: t('Inventory.WorkPlaces.addressUrl'),
      dataIndex: 'address_url',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record?.address_url}</span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.WorkPlaces.settings'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
