/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import ClientsPosContext from '../../contexts/clients-context-pos/ClientsPosContext';
import createNewClientApi from '../../apis/pos-api/clients-pos-apis/createNewClientApi';
import { useTranslation } from 'react-i18next';

const TableForm = () => {
  const { setIsLoading, setModalOpened, setFetchCount, selectedClient } =
    useContext(ClientsPosContext);
  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  let schema;
  if (!selectedClient) {
    schema = Yup.object().shape({
      name: Yup.string().required(t('Customers.ChooseCustomer')),
      phone: Yup.string().optional('ادخل رقم هاتف العميل'),
      // .matches(/^[0-9]+$/, 'لا يسمح الا بكتابة الارقام')
      // .min(10, 'أقل حد 10 ارقام'),
      email: Yup.string().email('ادخل بريد الكترونى صحيح'),
      taxNumber: Yup.string().optional('ادخل الرقم الضريبي صحيح'),
      address: Yup.string().optional('ادخل العنوان صحيح')
    });
  } else {
    schema = Yup.object().shape({
      name: Yup.string().required('ادخل اسم المستخدم'),
      phone: Yup.number()
        .required('ادخل رقم الهاتف')
        .typeError('لا يسمح الا بكتابة ارقام')
    });
  }
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      taxNumber: '',
      address: ''
    }
  });
  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    const apiData = {
      name: data.name,
      phone: data.phone,
      email: data.email,
      taxNumber: data.taxNumber,
      address: data.address
    };

    setIsLoading(true);
    if (selectedClient) {
      customApiRequest(
        createNewClientApi(
          user?.token || user?.employee?.token,
          { ...apiData, id: selectedClient.id },
          true
        ),
        (res) => {
          if (res?.status === 200 && res?.data?.data) {
            setIsLoading(false);
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Messages.addedSuccessfully'),

              message: t('Messages.Modifiedsuccessfully')
            });
          } else {
            setIsLoading(false);
            errorNotification({
              title: t('Messages.ErrorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Messages.TryAgainLater')
            });
          }
        },
        (error) => {
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message:
              error?.response?.data?.message || t('Messages.TryAgainLater')
          });
        }
      );
    } else {
      customApiRequest(
        createNewClientApi(user?.token || user?.employee?.token, {
          ...apiData
        }),
        (res) => {
          if (res?.status === 200 && res?.data?.data) {
            setIsLoading(false);
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Messages.addedSuccessfully'),
              message: t('Messages.addsuccessfully')
            });
          } else {
            setIsLoading(false);
            errorNotification({
              title: t('Messages.ErrorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Messages.TryAgainLater')
            });
          }
        },
        (error) => {
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message:
              error?.response?.data?.message || t('Messages.TryAgainLater')
          });
        }
      );
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedClient) {
      setValue('name', selectedClient?.name ? selectedClient.name : '');
      setValue('phone', selectedClient?.phone ? selectedClient.phone : '');
      setValue('email', selectedClient?.email ? selectedClient.email : '');
      setValue(
        'taxNumber',
        selectedClient?.taxNumber ? selectedClient.taxNumber : ''
      );
      setValue(
        'address',
        selectedClient?.address ? selectedClient.address : ''
      );
    }
  }, [selectedClient, selectedClient?.id]);

  const [form] = Form.useForm();
  return (
    <Form
      className="system-users-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body" style={{ margin: '0 12px' }}>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Customers.CustomerName')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="name"
              type="text"
              placeholder={t('Customers.CustomerName') + '...'}
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p"> {t('Customers.PhoneNumber')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="phone"
              type="text"
              placeholder={t('Customers.PhoneNumber') + '...'}
              errorMsg={errors?.phone?.message}
              validateStatus={errors?.phone ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('Customers.Email')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="email"
              type="text"
              placeholder={t('Customers.Email') + '...'}
              errorMsg={errors?.email?.message}
              validateStatus={errors?.email ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('Customers.Address')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="address"
              type="text"
              placeholder={t('Customers.Address') + '...'}
              errorMsg={errors?.address?.message}
              validateStatus={errors?.address ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('Customers.TaxNumber')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="taxNumber"
              type="text"
              placeholder={t('Customers.TaxNumber') + '...'}
              errorMsg={errors?.taxNumber?.message}
              validateStatus={errors?.taxNumber ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmitting}
        >
          {selectedClient ? t('Customers.edit') : t('Customers.add')}
        </Button>
      </div>
    </Form>
  );
};
export default TableForm;
