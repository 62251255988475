import React, { useCallback, useContext } from 'react';
// import { Select } from 'antd';
import SearchIcon from '../../common/icons/SearchIcon';
import ClientsContext from '../../contexts/clients-context/ClientsContext';
import UserContext from '../../contexts/user-context/UserProvider';
import checkRes from '../../utils/checkRes';
import PageModal from './PageModal';
import './TableFilter.scss';
import { exportToExcel } from 'react-json-to-excel';
import getAllClientsApi from '../../apis/clients-apis/getAllClientsApi';
import excelImg from '../../assets/imgs/icons/excel-btn.png';
import debounce from 'lodash.debounce';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
import errorNotification from '../../utils/errorNotification';
import successNotification from '../../utils/successNotification';
import SyncSallaApi from '../../apis/fatoorahStore-api/SyncSallaApi';
import ExcelModal from './ExcelModal';
import { useTranslation } from 'react-i18next';

const TableFilter = () => {
  // const { Option } = Select;
  const systemSettings = useSystemSettings();
  const { t } = useTranslation();

  const {
    // setNameSearchValue,
    modalOpened,
    setModalOpened,
    setSelectedClient,
    tablePagination,
    setTablePagination,
    setIsLoading,
    setAllFetchedClients,
    setNameSearchValue,
    nameSearchValue,
    excelModalOpened,
    setExcelModalOpened
  } = useContext(ClientsContext);
  const { user, filterValues, setFilterValues } = useContext(UserContext);

  const syncSallaAuth = async () => {
    try {
      const res = await SyncSallaApi(
        user?.token,
        systemSettings?.sallaToken,
        'customers'
      );
      if (res?.status === 200 && res?.data?.status === 1) {
        successNotification({
          title: t('Messages.addedSuccessfully'),

          message:
            t('Messages.Customershavebeenlinkedtosallasuccessfully') ||
            res.data.message
        });
      } else {
        errorNotification({
          title: t('Messages.ErrorOccurred'),
          message: res?.data?.message
            ? res.data.message
            : t('Messages.TryAgainLater')
        });
      }
    } catch (error) {
      // setIsLoading(false);
      errorNotification({
        title: t('Messages.ErrorOccurred'),
        message: t('Messages.TryAgainLater')
      });
    }
  };

  const debouncedChangeHandler = useCallback(
    debounce(async (e) => {
      handleTableSearch(e);
    }, 500),
    []
  );

  const handleTableSearch = async (e) => {
    setNameSearchValue(e.target.value);
    try {
      setIsLoading(true);
      let res = await getAllClientsApi(user?.token, {
        ...filterValues,
        page: tablePagination.current_page,
        searchText: e.target.value
      });
      if (res) {
        setIsLoading(false);
        if (checkRes(res)) {
          setFilterValues({ ...filterValues, searchText: e.target.value });
          const data = res.data.data.data;
          setAllFetchedClients(data);
        }
        if (res?.data?.data?.pagination) {
          setTablePagination(res.data.data.pagination);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleExcel = async () => {
    let data;
    try {
      const res = await getAllClientsApi(
        user?.token,
        {
          searchText: nameSearchValue ? nameSearchValue : '',
          page: ''
        },
        true
      );
      if (res.status === 200 && res?.data?.data) {
        data = res?.data?.data;
        exportToExcel(data, 'العملاء');
      }
    } catch (error) {
    }
  };

  return (
    <div className="custom-filter-add-section clients-filter-section">
      <div className="add-section">
        <div
          className="add-btn"
          onClick={() => {
            // if selected supplier then reset it
            // open the supplier modal
            setSelectedClient(null);
            setModalOpened(true);
          }}
        >
          <span className="text-span">{t('Clients.AddNewClient')}</span>
        </div>
      </div>

      <div className="custom-table-fitler">
        <div className="form-excel-export-wrap">
          <form className="filter-form client-form">
            <div className="table-search" style={{ width: '330px' }}>
              <label>
                <SearchIcon />
                <input
                  placeholder={t('Clients.SearchClient')}
                  type="text"
                  name="table_search"
                  onChange={(e) => {
                    debouncedChangeHandler(e);
                  }}
                />
              </label>
            </div>
          </form>

          {/* <div className="excel-btn-wrap">
            <a
              href={`${process.env.REACT_APP_BASE_URL}/Excel/exportUsers?client_id=${systemSettings?.id}`}
              download
              className="excel-export-btn"
            >
              <img
                style={{ height: '42px' }}
                src={excelImg}
                alt="export to excel"
              />
            </a>
          </div> */}
        </div>
      </div>
      <div className="excel-btn-wrap" onClick={() => handleExcel()}>
        <img style={{ height: '42px' }} src={excelImg} alt="export to excel" />
      </div>
      {/* {systemSettings?.sallaToken && (
        <div
          className="preview-download-upload add-btn"
          onClick={() => syncSallaAuth()}
        >
          <span className="text-span">{t('Clients.LinkToCart')}</span>
        </div>
      )} */}
      <div
        className="preview-download-upload add-btn"
        onClick={(e) => {
          setExcelModalOpened(true);
          e.stopPropagation();
        }}
      >
        <span className="text-span">{t('Clients.UploadClientData')}</span>
      </div>
      {modalOpened && <PageModal />}
      {excelModalOpened && <ExcelModal />}
    </div>
  );
};

export default TableFilter;
