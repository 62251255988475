import React, { useContext } from 'react';
import { Modal } from 'antd';
import AccountingDetailsContext from '../../../contexts/accounting-context/AccountingDetailsContext';
import AccountingDetailsModalForm from './TransactionsDetailsModalForm';
import { useParams } from 'react-router-dom';
// import AccountingForm from '../accounting-tree-page/AccountingForm';
import { getLeafType } from '../../../custom-hooks/getLeafType';
import ViewTransaction from './ViewTransaction';
import TransactionsContext from '../../../contexts/accounting-context/TransactionsContext';
import TransactionsDetailsModalForm from './TransactionsDetailsModalForm';

const TransactionsCreateModal = () => {
  const {
    modalOpenedCreate,
    setModalOpenedCreate,
    setSelectedTransactions,
    selectedAccounting
  } = useContext(TransactionsContext);

  const params = useParams();
  let typeOfAccount = getLeafType(params.id);




  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '850px' }}
      title={'إضافه معامله جديده'}
      visible={modalOpenedCreate}
      onOk={() => {
        setModalOpenedCreate(false);
        setSelectedTransactions(null);
      }}
      onCancel={() => {
        setModalOpenedCreate(false);
        setSelectedTransactions(null);
      }}
      footer={false}
    >
      <TransactionsDetailsModalForm />
    </Modal>
  );
};

export default TransactionsCreateModal;
