/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Table, Empty, Pagination } from 'antd';
import tableColumns from './tableColumns';
import { useEffect } from 'react';
import { useState } from 'react';
import UserContext from '../../contexts/user-context/UserProvider';
import SaleInvoicesContext from '../../contexts/sale-invoices-context/SaleInvoicesContext';
import { useHistory } from 'react-router-dom';
import salesRouterLinks from '../../components/app/sales-routes/salesRouterLinks';
import getAllTaxInvoiceReportsApi from '../../apis/reports-apis/getAllTaxInvoiceReportsApi';
import getAllSaleInvoicesApi from '../../apis/sales-apis/getAllSaleInvoicesApi';
import ShiftReportsContext from '../../contexts/shift-reports-context/ShiftReportsContext';
// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
import { useTranslation } from 'react-i18next';

const PageTable = ({
  isReportsPage
  // taxPage,
  // selectedPicker
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    setIsLoadingAllShiftReports,
    setAllFetchedShiftReports,
    fetchCountShiftReports,
    tableFilter,
    setTablePagination,
    tablePagination,
    setSelectedShiftReportsModalOpened,
    setFetchCountShiftReports,
    setSelectedShiftReport,
    isLoadingAllShiftReports,
    allFetchedShiftReports
  } = useContext(ShiftReportsContext);
  const { user } = useContext(UserContext);
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedShiftReports?.length >= 0) {
      if (isMounted) {
        setTableDataSource(mapDataSource(allFetchedShiftReports));
      }
    }
    // else if (taxPage) {
    //   setTableDataSource(mapDataSource(allFetchedSaleInvoices.data));
    //   setTablePagination(allFetchedSaleInvoices.pagination);
    // } else {
    // }
    return () => {
      isMounted = false;
    };
  }, [
    fetchCountShiftReports,
    allFetchedShiftReports,
    allFetchedShiftReports?.length
  ]);

  return (
    <>
      <Table
        locale={{
          emptyText: (
            <Empty description={false}>{t('ShiftReports.noData')}</Empty>
          )
        }}
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedShiftReports,
          setFetchCountShiftReports,
          setSelectedShiftReport,
          setIsLoadingAllShiftReports,
          setSelectedShiftReportsModalOpened,
          t,
          user?.token
        )}
        loading={isLoadingAllShiftReports}
        // scroll={{ x: 400 }}
        footer={false}
      />
      {tablePagination && (
        <Pagination
          defaultCurrent={1}
          showSizeChanger={false}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          style={{ display: 'flex', justifyContent: 'center' }}
          // itemRender={itemRender}
          onChange={(page, pageSize) => {
            setFetchCountShiftReports((prev) => prev + 1);
            setTablePagination({ ...tablePagination, current_page: page });
          }}
          hideOnSinglePage={true}
        />
      )}
    </>
  );
};

export default PageTable;
