/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Table, Empty } from 'antd';
import tableColumns from './tableColumns';
import SettingsSystemUsersContext from '../../../contexts/settings-contexts/SettingsSystemUsersProvider';
import getAllUsersApi from '../../../apis/settings-apis/users-apis/getAllUsersApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useTranslation } from 'react-i18next';
// import pageData from './pageData';

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const PageTable = () => {
  const {
    allFetchedUsers,
    setAllFetchedUsers,
    isLoading,
    setIsLoading,
    setModalOpened,
    fetchCount,
    setFetchCount,
    setSelectedUser,
    nameSearchValue
  } = useContext(SettingsSystemUsersContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: index + 1
    }));
  };

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllUsersApi(user?.token),
        (res) => {
          setIsLoading(false);
          if (res?.data?.data?.length >= 0) {
            setAllFetchedUsers(res.data.data);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedUsers?.length >= 0) {
      let mappedData = null;
      if (nameSearchValue.trim()) {
        const filtered = allFetchedUsers.filter((user) =>
          user.name
            .toLowerCase()
            .trim()
            .includes(nameSearchValue.toLowerCase().trim())
        );
        mappedData = mapDataSource(filtered);
      } else {
        mappedData = mapDataSource(allFetchedUsers);
      }
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedUsers, allFetchedUsers?.length, nameSearchValue]);

  return (
    <Table
      locale={{
        emptyText: (
          <Empty description={false}>{t('Messages.noDataAvailable')}</Empty>
        )
      }}
      pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
      className="antd-custom-table"
      dataSource={tableDataSource}
      columns={tableColumns(
        allFetchedUsers,
        setModalOpened,
        setFetchCount,
        setSelectedUser,
        setIsLoading,
        user,
        customApiRequest
      )}
      loading={isLoading}
      // scroll={{ x: 400 }}
      footer={false}
    />
  );
};

export default PageTable;
