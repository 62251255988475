import './MeasurementUnitsPage.scss';

import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import PageTable from './PageTable';
import PageModal from './PageModal';
import StoreVariantsContext from '../../../contexts/store-variants/VariantsContextPage';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

const VariantsPage = () => {
  const { modalOpened, setModalOpened, setSelectedUnit } =
    useContext(StoreVariantsContext);
  const { t } = useTranslation();

  return (
    <div className="measurement-units-page">
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">{t('Inventory.VariantsTypes.title')}</h2>
          <p>{t('Inventory.VariantsTypes.subTitle')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font">{t('Inventory.VariantsTypes.title')}</h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.storePage.title')}
            title3={t('Inventory.VariantsTypes.title')}
            link3={'/store/Variants'}
          />
        </div>
        <SearchNotificationsProfile />
      </div>

      <div className="add-section">
        <div
          className="add-account-btn"
          onClick={() => {
            // if selected unit then reset it
            // open the unit modal
            setSelectedUnit(null);
            setModalOpened(true);
          }}
        >
          <span className="text-span">
            {t('Inventory.VariantsTypes.addNewVariant')}
          </span>
        </div>
      </div>

      <PageTable />

      {modalOpened && <PageModal />}
    </div>
  );
};

export default VariantsPage;
