/* eslint-disable eqeqeq */
import * as Yup from 'yup';

const sharedProductSchemaObj = (t) => {
  return {
    product_id: Yup.string()
      .test(
        'products.index.product_id',
        t(t('schema.ChooseProduct')),
        (v, context) => {
          let result = true;
          if (!v) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.product_id',
        t(t('schema.ChooseAnotherProduct')),
        (v, context) => {
          let result = true;
          if (
            v &&
            context.from[1]?.value?.products?.length > 0 &&
            context.from[1]?.value?.products.filter((p) => p.product_id == v)
              ?.length > 1
          ) {
            result = false;
          }
          return result;
        }
      ),
    price: Yup.string()
      .test(
        'products.index.price',
        t('schema.EnterProductPrice'),
        (v, context) => {
          let result = true;
          // if the bill of type products === 1 and no value of price
          if (!v || v == 0) result = false;
          return result;
        }
      )
      .test(
        'products.index.price',
        t('schema.OnlyNumbersAllowedPrice'),
        (v, context) => {
          let result = true;
          if (v && !v.match(/^(\d+)?(\.\d+)?$/)) result = false;
          return result;
        }
      ),
    quantity: Yup.string()
      .test(
        'products.index.quantity',
        t('schema.EnterQuantity'),
        (v, context) => {
          let result = true;
          if (!v) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.quantity',
        t('schema.EnterQuantity'),
        (v, context) => {
          let result = true;
          if (
            v &&
            // !v.match(/^[0-9]+$/) &&
            !v.match(/^(\d+)?(\.\d+)?$/)
          ) {
            result = false;
          }
          return result;
        }
      ),
    tax: Yup.string().test(
      'products.index.tax',
      t('schema.EnterQuantity'),
      (v, context) => {
        let result = true;
        if (v && !v.match(/^(\d+)?(\.\d+)?$/)) {
          result = false;
        }
        return result;
      }
    ),
    discount_type: Yup.string().test(
      'products.index.discount_type',
      t('schema.ChooseDiscountType'),
      (v, context) => {
        let result = true;
        if (!v) result = false;
        return result;
      }
    ),
    discount_price: Yup.string()
      .test(
        'products.index.discount_price',
        t('schema.EnterDiscountPrice'),
        (v, context) => {
          let result = true;
          if (!v && context.parent.discount_type == '1') {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.discount_price',
        t('schema.OnlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.parent.discount_type == '1'
          ) {
            result = false;
          }
          return result;
        }
      ),
    // .test(
    //   'products.index.discount_price',
    //   'سعر الخصم اعلى من السعر',
    //   (v, context) => {
    //     let result = true;
    //     if (
    //       v &&
    //
    //       context.parent.discount_type == '1' &&
    //       parseInt(v) > parseInt(context.parent.price)
    //     )
    //       result = false;
    //     return result;
    //   }
    // ),
    discount_percentage: Yup.string()
      .test(
        'products.index.discount_percentage',
        t('schema.OnlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (!v && context.parent.discount_type == '2') {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.discount_percentage',
        t('schema.OnlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.parent.discount_type == '2'
          ) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.discount_price',
        t('schema.DiscountPercentageHigher'),
        (v, context) => {
          let result = true;
          if (v && context.parent.discount_type == '2' && parseInt(v) > 100)
            result = false;
          return result;
        }
      )
  };
};

const sharedSchemaObj = (t) => {
  return {
    userOrSupplier: Yup.string().required(t('schema.ChoosePaymentType')),
    // user_id: Yup.string().test('user_id', 'أختار العميل', (v, context) => {
    //   let result = true;
    //   if (!v && context?.parent?.userOrSupplier == 1) {
    //     result = false;
    //   }
    //   return result;
    // }),
    // supplier_id: Yup.string().test(
    //   'supplier_id',
    //   'أختار المورد',
    //   (v, context) => {
    //     let result = true;
    //     if (!v && context?.parent?.userOrSupplier == 2) {
    //       result = false;
    //     }
    //     return result;
    //   }
    // ),
    // serviceOrPackage: Yup.string().required('اختار نوعية الفاتورة'),
    products: Yup.array().of(
      Yup.object().shape({
        ...sharedProductSchemaObj(t)
      })
    ),

    // due_date: Yup.mixed().required('اختار تاريخ الفاتورة'),
    discount_type: Yup.string().required(t('schema.ChooseDiscountType')),
    discount_price: Yup.string()
      .test('discount_price', t('schema.EnterDiscountPrice'), (v, context) => {
        let result = true;
        if (!v && context.parent?.discount_type === '1') result = false;
        return result;
      })
      .test('discount_price', t('schema.OnlyNumbersAllowed'), (v, context) => {
        let result = true;
        if (
          v &&
          !v.match(/^(\d+)?(\.\d+)?$/) &&
          context.parent?.discount_type === '1'
        )
          result = false;
        return result;
      }),
    discount_percentage: Yup.string()
      .test(
        'discount_percentage',
        t('schema.EnterDiscountPercentage'),
        (v, context) => {
          let result = true;
          if (!v && context.parent?.discount_type === '2') result = false;
          return result;
        }
      )
      .test(
        'discount_percentage',
        t('schema.OnlyNumbersAllowed'),

        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.parent?.discount_type === '2'
          )
            result = false;
          return result;
        }
      ),
    extra_prices: Yup.array().of(
      Yup.object().shape({
        price: Yup.string()
          .test(
            'extra_prices.index.price',
            t('schema.EnterPrice'),
            (v, context) => {
              let result = true;
              if (!v && context.parent.title) {
                result = false;
              }
              return result;
            }
          )
          .test(
            'extra_prices.index.price',
            t('schema.OnlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (v && !v.match(/^(\d+)?(\.\d+)?$/)) {
                result = false;
              }
              return result;
            }
          ),
        title: Yup.string().test(
          'extra_prices.index.title',
          t('schema.EnterPriceStatement'),
          (v, context) => {
            let result = true;
            if (!v && context.parent.price) {
              result = false;
            }
            return result;
          }
        )
      })
    ),
    // paymentType: Yup.string().optional('اختار نوع الدفع'),
    installments: Yup.array().of(
      Yup.object().shape({
        price: Yup.string()
          .test(
            'installments.index.price',
            t('schema.EnterPrice'),
            (v, context) => {
              let result = true;
              if (!v && context.from[1].value.paymentType == '2') {
                result = false;
              }
              return result;
            }
          )
          .test(
            'installments.index.price',
            t('schema.OnlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (
                v &&
                !v.match(/^(\d+)?(\.\d+)?$/) &&
                context.from[1].value.paymentType == '2'
              ) {
                result = false;
              }
              return result;
            }
          ),
        date: Yup.mixed().test(
          'installments.index.date',
          'اختار تاريخ السداد',
          (v, context) => {
            let result = true;
            if (!v && context.from[1].value.paymentType == '2') {
              result = false;
            }
            return result;
          }
        )
        // status: Yup.mixed().test(
        //   'installments.index.status',
        //   'اختار حالة الايصال',
        //   (v, context) => {
        //     let result = true;
        //     if (!v && context.from[1].value.paymentType == '2') {
        //       result = false;
        //     }
        //     return result;
        //   }
        // )
      })
    ),
    cheque: Yup.object().shape({
      bankName: Yup.string().test(
        'cheque.bankName',
        'ادخل اسم البنك',
        (v, context) => {
          let result = true;
          if (!v && context.from[1].value.paymentType == '4') {
            result = false;
          }
          return result;
        }
      ),
      cheekNum: Yup.string()
        .test('cheque.cheekNum', 'ادخل رقم الشيك', (v, context) => {
          let result = true;
          if (!v && context.from[1].value.paymentType == '4') {
            result = false;
          }
          return result;
        })
        .test(
          'cheque.cheekNum',
          t('schema.OnlyNumbersAllowed'),
          (v, context) => {
            let result = true;
            if (
              v &&
              !v.match(/^(\d+)?(\.\d+)?$/) &&
              context.from[1].value.paymentType == '4'
            ) {
              result = false;
            }
            return result;
          }
        )
    })
  };
};

const newInvoiceSchema = (t) => {
  return Yup.object().shape({
    ...sharedSchemaObj(t)
  });
};

const editInvoiceSchema = (t) => {
  return Yup.object().shape({
    ...sharedSchemaObj(t),
    products: Yup.array().of(
      Yup.object().shape({
        ...sharedProductSchemaObj(t)
        // returnQuantity: Yup.string()
        //   .test(
        //     'products.index.returnQuantity',
        //     'ادخل الكمية المراد استرجاعها ',
        //     (v, context) => {
        //       let result = true;
        //       if (!v) {
        //         result = false;
        //       }
        //       return result;
        //     }
        //   )
        //   .test(
        //     'products.index.returnQuantity',
        //     t('schema.OnlyNumbersAllowed'),
        //     (v, context) => {
        //       let result = true;
        //       if (
        //         v &&
        //         // !v.match(/^[0-9]+$/) &&
        //         !v.match(/^(\d+)?(\.\d+)?$/)
        //       ) {
        //         result = false;
        //       }
        //       return result;
        //     }
        //   )
      })
    )
  });
};

const getIncommingInvoiceSchema = (selectedReceipt, t) => {
  if (!selectedReceipt) return newInvoiceSchema(t);

  return editInvoiceSchema(t);
};

export default getIncommingInvoiceSchema;
