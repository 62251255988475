import HomeIcon from '../../common/icons/HomeIcon';
import AnalysisIcon from '../../common/icons/AnalysisIcon';
// import routerLinks from '../app/routerLinks';
import AccountingNewIcon from '../../common/icons/AccountingNewIcon';
import ArchiveIcon from '../../common/icons/ArchiveIcon';
import Links from '../app/Links';
const routerLinks = Links()
const posLinks = [
  {
    id: 1,
    to: routerLinks.homePage,
    name: 'الرئيســية',
    isLink: true,
    icon: (color) => (color ? <HomeIcon color={color} /> : <HomeIcon />),
    subMenu: [],
    visibleSubMenu: false
  },
  {
    id: 1,
    to: routerLinks.posPage,
    name: 'نقاط البيع',
    isLink: true,
    icon: (color) => (color ? <HomeIcon color={color} /> : <HomeIcon />),
    subMenu: [],
    visibleSubMenu: false
  },
];

export default posLinks;
