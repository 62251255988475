/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Table, Empty, Pagination } from 'antd';
import tableColumns from './tableColumns';
import { useEffect } from 'react';
// import catsTableData from './catsTableData';
import { useState } from 'react';
import UserContext from '../../../contexts/user-context/UserProvider';
import IncomingPurchaseInvoiceContext from '../../../contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import { useHistory } from 'react-router-dom';
import storeRouterLinks from '../../../components/app/store-routes/storeRouterLinks';
import getAllTaxInvoiceReportsApi from '../../../apis/reports-apis/getAllTaxInvoiceReportsApi';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const PageTable = ({
  allFetchedReceipts,
  withoutReturnBtn,
  reports,
  taxPage,
  selectedPicker,
  flag,
  currentLang
}) => {
  const {
    fetchCount,
    isLoading,
    setIsLoading,
    setModalOpened,
    setFetchCount,
    setSelectedReceipt,
    setSelectedReceiptModalOpened,
    setInstallmentModalOpened,
    tablePagination,
    setTablePagination,
    setAllFetchedReceipts
  } = useContext(IncomingPurchaseInvoiceContext);
  const { pathname } = useLocation();
  const user_id_from_payment = pathname?.split('/')[3];
  const systemSettings = useSystemSettings();
  const history = useHistory();
  const { user } = useContext(UserContext);
  const [tableDataSource, setTableDataSource] = useState([]);
  const { t } = useTranslation();
  const mapDataSource = (data) => {
    if (flag === '1') {
      return data?.map((obj, index) => ({
        ...obj,
        key: obj?.id
      }));
    } else if (flag === '4') {
      return data?.data.map((obj, index) => ({
        ...obj,
        key: obj?.id
      }));
    }
  };

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (taxPage) {
      setTableDataSource(mapDataSource(allFetchedReceipts));
      setTablePagination(allFetchedReceipts?.pagination);
    } else {
      if (allFetchedReceipts?.invoices?.data?.length >= 0) {
        if (isMounted) {
          setTableDataSource(mapDataSource(allFetchedReceipts?.invoices.data));
        }
      }
      if (allFetchedReceipts?.length > 0) {
        if (isMounted) {
          setTableDataSource(mapDataSource(allFetchedReceipts));
        }
      } else {
        setTableDataSource(mapDataSource(allFetchedReceipts));
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedReceipts, allFetchedReceipts?.length]);

  const handlePageChange = async (page) => {
    try {
      setIsLoading(true);
      let res = await getAllTaxInvoiceReportsApi(user?.token, {
        page: page,
        from: selectedPicker?.strings[0],
        to: selectedPicker?.strings[1]
      });
      if (res.status === 200) {
        setIsLoading(false);
        setAllFetchedReceipts(res.data.data.buyInvoice.data);
        setTablePagination(res.data.data.buyInvoice.pagination);
        setTableDataSource(res.data.data.buyInvoice.data);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <section>
      <Table
        locale={{
          emptyText: (
            <Empty description={false}>{t('purchaseInvoices.noData')}</Empty>
          )
        }}
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedReceipts,
          setModalOpened,
          setFetchCount,
          setSelectedReceipt,
          setIsLoading,
          setSelectedReceiptModalOpened,
          setInstallmentModalOpened,
          withoutReturnBtn,
          user?.token,
          taxPage,
          t,
          systemSettings?.subscriptionType
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />

      {tablePagination && (
        <Pagination
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px'
          }}
          defaultCurrent={1}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}

          onChange={(page, pageSize) => {
            setFetchCount((prev) => prev + 1);
            if (taxPage) {
              handlePageChange(page);
            } else {
              setFetchCount((prev) => prev + 1);
              //  if (reports) {
              //    history.push(
              //      `${storeRouterLinks?.incomingPurchaseInvoiceReports}${
              //        param?.id ? '/' + param?.id : ''
              //      }?page=${page}`
              //    );
              //  } else {
              //    history.push(
              //      `${storeRouterLinks?.incomingPurchaseInvoice}${
              //        param?.id ? '/' + param?.id : ''
              //      }?page=${page}`
              //    );
              //  }
              if (reports) {
                // console.log(reports);
                history.push(
                  `${storeRouterLinks?.incomingPurchaseInvoiceReports}?page=${page}`
                );
                setTablePagination({
                  ...tablePagination,
                  current_page: page
                });
              } else {
                setTablePagination({ ...tablePagination, current_page: page });
                // history.push(
                //   `${storeRouterLinks?.incomingPurchaseInvoice}?page=${page}`
                // );
              }
            }
          }}
          hideOnSinglePage={true}
        />
      )}
    </section>
  );
};

export default PageTable;
