
const tableColumns = (
  allFetchedProfitLosesReports,
  setLaterInvoiceModalOpened,
  setFetchCountSaleInvoices,
  setSelectedInvoice,
  setIsLoadingAllSaleInvoices,
  setSelectedSaleInvoiceModalOpened,
  token,
  t,
  taxPage
) => {
  const handleViewReceiptClick = (record) => {
    if (taxPage) {
      setSelectedInvoice(record);
      setSelectedSaleInvoiceModalOpened(true);
    } else {
      const foundInvoice = allFetchedProfitLosesReports.find(
        (receipt) => receipt.id === record.id
      );
      if (foundInvoice) {
        setSelectedInvoice(record);
        setSelectedSaleInvoiceModalOpened(true);
      }
    }
  };

  return [
    {
      title: t('ProfitAndLossReports.serial'),
      dataIndex: 'manualNum',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span>{`${record.index}`}</span>
          </div>
        );
      }
    },

    {

      title: t('ProfitAndLossReports.label'),
      dataIndex: 'title',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.title
              ? record.title
              : '----'}
          </div>
        );
      }
    },

    {
      title: t('ProfitAndLossReports.purchases'),
      dataIndex: 'purchases',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record.purchases_totalPrice
              ? record.purchases_totalPrice
              : '----'}
          </div>
        );
      }
    },
    {

      title: t('ProfitAndLossReports.sales'),
      dataIndex: 'sales',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.sales_totalPrice ? record.sales_totalPrice : '----'}
          </div>
        );
      }
    },
    {

      title: t('ProfitAndLossReports.difference'),
      dataIndex: 'sales',
      render: (_, record) => {
        // console.log(record)
        return (
          <div className="row-cell row-text">
            {record?.difference ? record.difference : '----'}
          </div>
        );
      }
    },
  ];
};

export default tableColumns;
