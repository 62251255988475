/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import ceateNewClientApi from '../../../apis/clients-apis/createNewClientApi';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import ClientsContext from '../../../contexts/clients-context/ClientsContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import { useTranslation } from 'react-i18next';

const InvoiceClientForm = ({ setAllClientsFetchCount }) => {
  const {
    setIsLoading,
    setModalOpened,
    selectedClient,
    setSelectedClientId,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(ClientsContext);
  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  let schema;
  if (!selectedClient) {
    schema = Yup.object().shape({
      name: Yup.string().required(t('UserProfile.nameValidation')),
      phone: Yup.string()
        .required(t('UserProfile.phoneValidation'))
        .matches(/^[0-9]+$/, t('UserProfile.onlyNumbersAllowed'))
        .min(9, t('UserProfile.phoneLengthValidation')),
      email: Yup.string().email(t('UserProfile.correctEmailValidation'))
    });
  } else {
    schema = Yup.object().shape({
      name: Yup.string().required('ادخل اسم المستخدم'),
      phone: Yup.number()
        .required(t('UserProfile.phoneValidation'))
        .typeError('لا يسمح الا بكتابة ارقام')
    });
  }
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      phone: '',
      email: ''
    }
  });
  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    const apiData = {
      name: data?.name,
      phone: data?.phone,
      email: data?.email,
      address: data?.address
    };
    setIsLoading(true);
    setIsSubmittingForm(true);
    if (selectedClient) {
      customApiRequest(
        ceateNewClientApi(
          user?.token,
          { ...apiData, id: selectedClient.id },
          true
        ),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (checkRes(res) && res?.data?.data) {
            setModalOpened(false);
            setSelectedClientId(res?.data?.data?.id);
            setAllClientsFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Messages.addedSuccessfully'),

              message: t('Messages.Modifiedsuccessfully')
            });
          } else {
            errorNotification({
              title: t('Messages.ErrorOccurred'),
              message: res?.data?.message || t('Messages.TryAgainLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message:
              error?.response?.data?.message || t('Messages.TryAgainLater')
          });
        }
      );
    } else {
      customApiRequest(
        ceateNewClientApi(user?.token, { ...apiData }),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (checkRes(res) && res?.data?.data) {
            setModalOpened(false);
            setSelectedClientId(res?.data?.data?.id);
            setAllClientsFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Messages.addedSuccessfully'),
              message: t('Messages.addsuccessfully')
            });
          } else {
            errorNotification({
              title: t('Messages.ErrorOccurred'),
              message: res?.data?.message || t('Messages.TryAgainLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message:
              error?.response?.data?.message || t('Messages.TryAgainLater')
          });
        }
      );
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedClient) {
      setValue('name', selectedClient?.name ? selectedClient.name : '');
      setValue('phone', selectedClient?.phone ? selectedClient.phone : '');
      setValue('email', selectedClient?.email ? selectedClient.email : '');
    }
  }, [selectedClient, selectedClient?.id]);

  const [form] = Form.useForm();
  return (
    <Form
      className="system-users-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body" style={{ margin: '0 12px' }}>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Quotations.Name')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="name"
              type="text"
              placeholder={`${t('Quotations.Name')}...`}
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Quotations.Phone')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="phone"
              type="text"
              placeholder={`${t('Quotations.Phone')}...`}
              errorMsg={errors?.phone?.message}
              validateStatus={errors?.phone ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('Quotations.Email')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="email"
              type="text"
              placeholder={`${t('Quotations.Email')}...`}
              errorMsg={errors?.email?.message}
              validateStatus={errors?.email ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('Quotations.Address')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="address"
              type="text"
              placeholder={`${t('Quotations.Address')}...`}
              errorMsg={errors?.address?.message}
              validateStatus={errors?.address ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedClient ? t('Quotations.Edit') : t('Quotations.Add')}
        </Button>
      </div>
    </Form>
  );
};

export default InvoiceClientForm;
