import axios from 'axios';

const getAllProductsWithoutPage = async (token) => {
  try {
    let url = `/Product/all?is_ingredient=2&is_select=1`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};


export default getAllProductsWithoutPage;
