/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import TableFilter from './TableFilter';
import SaleInvoicesContext from '../../contexts/sale-invoices-context/SaleInvoicesContext';
import SearchNotificationsProfile from '../../components/search-notifications-profile/SearchNotificationsProfile';
import UserContext from '../../contexts/user-context/UserProvider';
import SharedSalesInvoice from './SharedSalesInvoice';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import './SaleInvoicesPage.scss';
import getAllSaleInvoicesApi from '../../apis/sales-apis/getAllSaleInvoicesApi';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
const SaleInvoicesPage = (reports) => {
  const { user } = useContext(UserContext);
  const { search } = useLocation();
  const { t } = useTranslation();
  const values = queryString.parse(search);
  const {
    allFetchedSaleInvoices,
    setIsLoadingAllSaleInvoices,
    setAllFetchedSaleInvoice,
    fetchCountSaleInvoices,
    tableFilter,
    setTablePagination,
    tablePagination
  } = useContext(SaleInvoicesContext);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoadingAllSaleInvoices(true);
      customApiRequest(
        getAllSaleInvoicesApi(user?.token, {
          ...tableFilter,
          ...values,
          page: tablePagination?.current_page
        }),
        (res) => {
          setIsLoadingAllSaleInvoices(false);
          if (res?.status === 200 && res?.data?.data?.data?.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedSaleInvoice(data);
          }
          if (res?.data?.data?.pagination) {
            setTablePagination(res.data.data.pagination);
          }
        },
        (error) => {
          setIsLoadingAllSaleInvoices(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCountSaleInvoices]);

  return (
    <div className="sale-invoices-page">
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">{t('CashiersalesInvoiceReport.title')}</h2>
          <p>{t('CashiersalesInvoiceReport.subTitle')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('AppBar.Products.analysisPage.salesPageReports')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.analysisPage.title')}
            title3={t('AppBar.Products.analysisPage.salesPageReports')}
            link3={'/sales-reports'}
          />
        </div>
        <SearchNotificationsProfile />
      </div>

      <div className="custom-filter-add-section1">
        <TableFilter allFetchedSaleInvoices={allFetchedSaleInvoices} />
      </div>

      <div className="table-wrapper">
        <SharedSalesInvoice
          allFetchedSaleInvoices={allFetchedSaleInvoices}
          isReportsPage={true}
        />
      </div>
    </div>
  );
};

export default SaleInvoicesPage;
