/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import errorNotification from '../../utils/errorNotification';
import UserContext from '../../contexts/user-context/UserProvider';
import getSingleInvoiceApi from '../../apis/sale-invoice-api/getSingleInvoiceApi';
import ReturnModalContext from '../../contexts/return-modal-context/ReturnModalContext';
import { useHistory } from 'react-router-dom';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import ReactHookFormContext from '../../contexts/react-hook-form-context/ReactHookFormContext';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import Links from '../app/Links';
import { useTranslation } from 'react-i18next';
import getReturnInvoiceApi from '../../apis/return-invoice-apis/getReturnInvoiceApi';
const ReturnModalForm = ({ invoiceId }) => {
  const { t } = useTranslation();

  const routerLinks = Links();
  const { setReturnModalOpened } = useContext(ReturnModalContext);
  const { setStep } = useContext(ReactHookFormContext);
  const { setSelectedInvoice, selectedInvoice } = useContext(PosInvoiceContext);
  const { user } = useContext(UserContext);
  const schema = Yup.object().shape({
    invoiceNumber: Yup.string().required(t('SalesInvoices.EnterInvoiceNumber'))
  });
  const history = useHistory();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      invoiceNumber: ''
    }
  });

  const [isSubmittingForm, setIsSubmittingForm] = React.useState(false);

  const customApiRequest = useCustomApiRequest();

  const onSubmit = (data) => {
    let id = data.invoiceNumber;
    if (id?.includes('invoice-details')) {
      const arr = id.split('/');
      id = arr[arr.length - 1];
    }
    setIsSubmittingForm(true);
    customApiRequest(
      getReturnInvoiceApi(user?.token || user?.employee?.token, selectedInvoice?.id ,2 ,{
        invoiceNumber: data?.invoiceNumber
      }),
      (res) => {
        setIsSubmittingForm(false);
        if (res?.status === 200 && res?.data?.data) {
          setValue('invoiceNumber', '');
          setReturnModalOpened(false);
          if (
            !res?.data?.data?.products ||
            res?.data?.data?.products?.length === 0 ||
            parseFloat(res?.data?.data?.totalPrice) == 0 ||
            res?.data?.data?.is_return == 1
          ) {
            errorNotification({
              title: t('Messages.ErrorOccurred'),
              message: t('SalesInvoices.Messages')
            });
          } else {
            setStep(1);
            history.push(routerLinks.posPage);
            setSelectedInvoice(res?.data?.data);
          }
        } else {
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message: res?.data?.message
              ? res.data.message
              : t('Messages.TryAgainLater')
          });
        }
      },
      (error) => {
        setIsSubmittingForm(false);
        errorNotification({
          title: t('Messages.ErrorOccurred'),
          message: error?.response?.data?.message || t('Messages.TryAgainLater')
        });
      }
    );
  };

  // handle initial values
  useEffect(() => {
    if (invoiceId) {
      setValue('invoiceNumber', invoiceId ? invoiceId : '');
    } else if (selectedInvoice?.invoiceNumber) {
      setValue('invoiceNumber', selectedInvoice.invoiceNumber);
    } else {
      setValue('invoiceNumber', '');
    }
  }, [invoiceId]);

  const [form] = Form.useForm();

  return (
    <Form
      className="system-users-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('SalesInvoices.InvoiceNumber')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="invoiceNumber"
              type="text"
              placeholder={t('SalesInvoices.InvoiceNumber') + '...'}
              errorMsg={errors?.invoiceNumber?.message}
              validateStatus={errors?.invoiceNumber ? 'error' : ''}
              control={control}
              autoFocus
            />
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {t('Invoices.Search')}
        </Button>
      </div>
    </Form>
  );
};

export default ReturnModalForm;
