import React from 'react';
import './Breadcrumbs.scss';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
const Breadcrumbs = ({
  title1,
  link1,
  title2,
  link2,
  title3,
  link3,
  title4,
  link4
}) => {
  return (
    <div
      className="bread-container"
      style={{
        color: 'rgba(0, 0, 0, 0.45)',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        flexWrap: 'wrap'
      }}
    >
      <span>
        <Link to={`${link1}`} className="main-page-bread">
          {title1}
        </Link>
      </span>
      {title2 ? (
        <span
          style={{
            transform: `${
              Cookies.get('i18next') == 'en'
                ? 'rotate(180deg) translateY(-5px)'
                : 'rotate(0deg) translateY(5px)'
            }`
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M10 13.779C9.87335 13.779 9.74669 13.7324 9.64669 13.6324L5.30002 9.2857C4.59335 8.57904 4.59335 7.41904 5.30002 6.71237L9.64669 2.3657C9.84002 2.17237 10.16 2.17237 10.3534 2.3657C10.5467 2.55904 10.5467 2.87904 10.3534 3.07237L6.00669 7.41904C5.68669 7.73904 5.68669 8.25904 6.00669 8.57904L10.3534 12.9257C10.5467 13.119 10.5467 13.439 10.3534 13.6324C10.2534 13.7257 10.1267 13.779 10 13.779Z"
              fill="#6F7C84"
            />
          </svg>
        </span>
      ) : null}
      {title2 ? <span>{title2}</span> : null}
      <span
        style={{
          transform: `${
            Cookies.get('i18next') == 'en'
              ? 'rotate(180deg) translateY(-5px)'
              : 'rotate(0deg) translateY(5px)'
          }`
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M10 13.779C9.87335 13.779 9.74669 13.7324 9.64669 13.6324L5.30002 9.2857C4.59335 8.57904 4.59335 7.41904 5.30002 6.71237L9.64669 2.3657C9.84002 2.17237 10.16 2.17237 10.3534 2.3657C10.5467 2.55904 10.5467 2.87904 10.3534 3.07237L6.00669 7.41904C5.68669 7.73904 5.68669 8.25904 6.00669 8.57904L10.3534 12.9257C10.5467 13.119 10.5467 13.439 10.3534 13.6324C10.2534 13.7257 10.1267 13.779 10 13.779Z"
            fill="#6F7C84"
          />
        </svg>
      </span>
      {title3 && !title4 ? (
        <div>
          {' '}
          <div className={'active-link'}>{title3}</div>{' '}
        </div>
      ) : (
        <span>
          {' '}
          <Link
            to={`${link3}`}
            className={title4 ? 'main-page-bread' : 'active-link'}
          >
            {title3}
          </Link>{' '}
        </span>
      )}
      {title4 && (
        <span
          style={{
            transform: `${
              Cookies.get('i18next') == 'en'
                ? 'rotate(180deg) translateY(-5px)'
                : 'rotate(0deg) translateY(5px)'
            }`
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M10 13.779C9.87335 13.779 9.74669 13.7324 9.64669 13.6324L5.30002 9.2857C4.59335 8.57904 4.59335 7.41904 5.30002 6.71237L9.64669 2.3657C9.84002 2.17237 10.16 2.17237 10.3534 2.3657C10.5467 2.55904 10.5467 2.87904 10.3534 3.07237L6.00669 7.41904C5.68669 7.73904 5.68669 8.25904 6.00669 8.57904L10.3534 12.9257C10.5467 13.119 10.5467 13.439 10.3534 13.6324C10.2534 13.7257 10.1267 13.779 10 13.779Z"
              fill="#6F7C84"
            />
          </svg>
        </span>
      )}
      {title4 && (
        <span>
          {' '}
          <div className="active-link">{title4}</div>{' '}
        </span>
      )}
    </div>
  );
};

export default Breadcrumbs;
