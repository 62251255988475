import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedCategories: null,
  setAllFetchedCategories: (v) => {},
  selectedCategory: null,
  setSelectedCategory: (v) => {}
};

const SettingsCategoriesContext = createContext(INITIAL_VALUES);

export const SettingsCategoriesProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedCategories, setAllFetchedCategories] = useState(
    INITIAL_VALUES.allFetchedCategories
  );
  const [selectedCategory, setSelectedCategory] = useState(
    INITIAL_VALUES.selectedCategory
  );

  return (
    <SettingsCategoriesContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedCategories,
        setAllFetchedCategories,
        selectedCategory,
        setSelectedCategory
      }}
    >
      {children}
    </SettingsCategoriesContext.Provider>
  );
};

export default SettingsCategoriesContext;
