import React, { useContext } from 'react';
import { Modal } from 'antd';
import AccountingContext from '../../../contexts/accounting-context/AccountingContext';
import AccountingForm from './AccountingForm';
import { getLeafType } from '../../../custom-hooks/getLeafType';
import ViewAccountInfo from './ViewAccountInfo';
import { useTranslation } from 'react-i18next';

const AccountInfoModal = ({ typeOfAccount }) => {
  const {
    accountingModalOpened,
    setAccountingModalOpened,
    accountInfoModalOpened,
    setAccountInfoModalOpened,
    setSelectedAccountNode,
    selectedAccountNode,
    editMode
  } = useContext(AccountingContext);
  const { t } = useTranslation();

  let leafType = getLeafType(selectedAccountNode?.id) || typeOfAccount;

  if (leafType === 1) {
    return <></>;
  } else {
    return (
      <Modal
        transitionName=""
        className="work-places-modal shared-custom-modal"
        width="90%"
        style={{ maxWidth: '742px' }}
        title={t('Accounts.theDetails')}
        visible={accountInfoModalOpened}
        onOk={() => {
          setAccountInfoModalOpened(false);
          setSelectedAccountNode(null);
        }}
        onCancel={() => {
          setAccountInfoModalOpened(false);
          setSelectedAccountNode(null);
        }}
        footer={false}
      >
        <ViewAccountInfo />
      </Modal>
    );
  }
};

export default AccountInfoModal;
