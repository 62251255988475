/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React from 'react';
import { Tooltip, Button } from 'antd';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import { DeleteOutlined } from '@ant-design/icons';
import { calcProductTotalValue } from './receiptCalculations';
import RadioButtonFilled from '../../../common/icons/RadioButtonFilled';
import RadioButtonEmpty from '../../../common/icons/RadioButtonEmpty';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';

const RenderProductsFields = ({
  allProducts,
  productsFields,
  removeProductField,
  selectedReceipt,
  watch,
  setValue,
  getValues,
  control,
  errors,
  register,
  systemSettings,
  t
}) => {
  const getTaxDefaultValue = (index) => {
    // if (allProducts?.length > 0 && !selectedReceipt) {
    if (allProducts?.length > 0) {
      const found = allProducts.find((p) => {
        return p.id == watch(`products.${index}.product_id`);
      });
      if (found?.tax && parseFloat(found.tax) > 0) {
        return found.tax;
      } else {
        return systemSettings?.tax;
      }
    }
  };
  const handlePriceDefaultValue = (item, index) => {
    if (item?.price) return item?.price;
    else if (allProducts?.length > 0) {
      const foundP = allProducts?.find(
        (p) => p?.id == watch(`products.${index}.product_id`)
      );
      if (foundP) return foundP.salePrice;
      return '';
    }
  };
  const handleNetPriceDefaultValue = (item, index) => {
    const price = watch(`products.${index}.price`);

    const quantity = watch(`products.${index}.quantity`);
    if (price && quantity)
      return (parseFloat(price) * parseFloat(quantity)).toFixed(2);
    return '';
  };
  const handleChangeQuantity = (item, index) => {
    if (
      watch(`products.${index}.price`) &&
      watch(`products.${index}.quantity`)
    ) {
      setValue(
        `products.${index}.netPrice`,
        parseFloat(watch(`products.${index}.price`)) *
          parseFloat(watch(`products.${index}.quantity`))
      );
    }
  };
  const handleChangeSubTotalPrice = (item, index) => {
    if (
      watch(`products.${index}.netPrice`) &&
      watch(`products.${index}.price`)
    ) {
      setValue(
        `products.${index}.quantity`,
        (
          parseFloat(watch(`products.${index}.netPrice`)) /
          parseFloat(watch(`products.${index}.price`))
        ).toFixed(2)
      );
    }
  };
  const handlePriceChange = (item, index) => {
    if (
      watch(`products.${index}.price`) &&
      watch(`products.${index}.quantity`)
    ) {
      setValue(
        `products.${index}.netPrice`,
        parseFloat(watch(`products.${index}.price`)) *
          parseFloat(watch(`products.${index}.quantity`))
      );
    }
  };
  const handleSelectService = (item, index, e) => {
    if (
      watch(`products.${index}.price`) &&
      watch(`products.${index}.quantity`)
    ) {
      const foundP = allProducts?.find((p) => p?.id == e);
      if (foundP?.salePrice) {
        setValue(
          `products.${index}.netPrice`,
          parseFloat(foundP.salePrice) *
            parseFloat(watch(`products.${index}.quantity`))
        );
      }
    } else {
      setValue(`products.${index}.netPrice`, '');
    }
  };
  return (
    productsFields?.length > 0 && (
      <ul className="fields-array-ul without-padding">
        {productsFields.map((item, index) => {
          return (
            <li className="bill-field-delete-li" key={item.id}>
              <div className="li-fields">
                <div
                  className={`product-id-quantity-wrap ${
                    selectedReceipt ? 'isReturn' : ''
                  }`}
                >
                  <div className="text-field-label-wrap">
                    <p className="label-p">
                      {t('purchaseInvoices.serviceName')}
                    </p>
                    <div className="text-field-wrap">
                      <AntdTextField
                        disabled={selectedReceipt ? true : false}
                        setValue={setValue}
                        className="form-text-field"
                        name={`products.${index}.name`}
                        type="text"
                        placeholder={t(
                          'purchaseInvoices.serviceNamePlaceholder'
                        )}
                        errorMsg={
                          errors?.products &&
                          errors.products[index]?.name &&
                          errors.products[index].name.message
                        }
                        validateStatus={
                          errors?.products &&
                          errors.products[index]?.name &&
                          errors?.products[index]?.name
                            ? 'error'
                            : ''
                        }
                        control={control}
                        defaultValue={item?.name ? item.name : ''}
                      />
                    </div>
                  </div>

                  {/* <div className="select-label-wrap">
                    <p className="label-p">إختار الخدمة</p>
                    <div className="custom-select-wrap without-icon">
                      {(allProducts?.length >= 0 &&
                        allProducts.find((p) => p.id == item?.product_id)) ||
                        !selectedReceipt ? (
                          <AntdSelectOption
                            disabled={selectedReceipt ? true : false}
                            name={`products.${index}.product_id`}
                            errorMsg={
                              errors?.products &&
                              errors.products[index]?.product_id &&
                              errors.products[index].product_id.message
                            }
                            validateStatus={
                              errors?.products &&
                              errors.products[index]?.product_id &&
                              errors?.products[index]?.product_id
                                ? 'error'
                                : ''
                            }
                            control={control}
                            setValue={setValue}
                            placeholder="إختار الخدمة"
                            options={
                              allProducts?.length > 0 &&
                              allProducts.map((p) => {
                                return {
                                  title: p.name,
                                  value: String(p.id)
                                };
                              })
                            }
                            showSearch={true}
                            // onSearch={onSearch}
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            formClassName="create-receipt-form"
                            onChange={(e) => {
                              handleSelectService(item, index, e);
                            }}
                          />
                        ) : (
                          <div
                            style={{
                              display: 'grid',
                              justifyItems: 'start',
                              alignItems: 'center',
                              paddingRight: 8
                            }}
                          >
                            <p>
                              {selectedReceipt?.products?.length > 0 &&
                                selectedReceipt?.products[index]?.product?.name}
                            </p>
                          </div>
                        )}
                      </div>
                  </div> */}

                  <div className="q-return-q-price-wrapper">
                    {watch(`products.${index}.name`) && selectedReceipt && (
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('purchaseInvoices.returnedQuantity')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            name={`products.${index}.returnQuantity`}
                            type="text"
                            placeholder={t(
                              'purchaseInvoices.returnedQuantityPlaceholder'
                            )}
                            errorMsg={
                              errors?.products &&
                              errors.products[index]?.returnQuantity &&
                              errors.products[index].returnQuantity.message
                            }
                            validateStatus={
                              errors?.products &&
                              errors.products[index]?.returnQuantity &&
                              errors?.products[index]?.returnQuantity
                                ? 'error'
                                : ''
                            }
                            control={control}
                            onChange={() => {
                              if (selectedReceipt) {
                                const handleReturnQuantity = () => {
                                  return (
                                    parseFloat(
                                      selectedReceipt?.products[index]?.quantity
                                    ) -
                                    parseFloat(
                                      watch(`products.${index}.returnQuantity`)
                                    )
                                  );
                                };

                                if (
                                  parseFloat(
                                    watch(`products.${index}.returnQuantity`)
                                  ) >= 0 &&
                                  parseFloat(
                                    watch(`products.${index}.returnQuantity`)
                                  ) <=
                                    parseFloat(
                                      selectedReceipt?.products[index]?.quantity
                                    )
                                )
                                  setValue(
                                    `products.${index}.quantity`,
                                    handleReturnQuantity()
                                  );
                                else if (
                                  parseFloat(
                                    watch(`products.${index}.returnQuantity`)
                                  ) >= 0 &&
                                  parseFloat(
                                    watch(`products.${index}.returnQuantity`)
                                  ) >
                                    parseFloat(
                                      selectedReceipt?.products[index]?.quantity
                                    )
                                ) {
                                  setValue(
                                    `products.${index}.returnQuantity`,
                                    selectedReceipt?.products[index]?.quantity
                                  );
                                  setValue(`products.${index}.quantity`, 0);
                                } else {
                                  setValue(
                                    `products.${index}.returnQuantity`,
                                    ''
                                  );
                                  setValue(
                                    `products.${index}.quantity`,
                                    selectedReceipt?.products[index]?.quantity
                                  );
                                }
                              }
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {watch(`products.${index}.name`) && (
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('purchaseInvoices.quantity')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            disabled={selectedReceipt}
                            name={`products.${index}.quantity`}
                            type="text"
                            placeholder={t(
                              'purchaseInvoices.quantityPlaceholder'
                            )}
                            errorMsg={
                              errors?.products &&
                              errors.products[index]?.quantity &&
                              errors.products[index].quantity.message
                            }
                            validateStatus={
                              errors?.products &&
                              errors.products[index]?.quantity &&
                              errors?.products[index]?.quantity
                                ? 'error'
                                : ''
                            }
                            control={control}
                            // onChange={() => {
                            //   if (selectedReceipt) {
                            //     if (
                            //       parseFloat(
                            //         watch(`products.${index}.quantity`)
                            //       ) <=
                            //       parseFloat(
                            //         selectedReceipt?.details[index]?.quantity
                            //       )
                            //     ) {
                            //       setValue(
                            //         `products.${index}.quantity`,
                            //         watch(`products.${index}.quantity`)
                            //       );
                            //     } else {
                            //       setValue(
                            //         `products.${index}.quantity`,
                            //         selectedReceipt?.details[index]?.quantity
                            //       );
                            //     }
                            //   }
                            // }}
                            // defaultValue={watch(`products.${index}.quantity`)} // make sure to set up defaultValue
                          />
                        </div>
                      </div>
                    )}

                    {watch(`products.${index}.name`) && (
                      <div className="text-field-label-wrap">
                        <p className="label-p">{t('purchaseInvoices.price')}</p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            disabled={selectedReceipt ? true : false}
                            setValue={setValue}
                            className="form-text-field"
                            name={`products.${index}.price`}
                            type="text"
                            placeholder={t('purchaseInvoices.pricePlaceholder')}
                            errorMsg={
                              errors?.products &&
                              errors.products[index]?.price &&
                              errors.products[index].price.message
                            }
                            validateStatus={
                              errors?.products &&
                              errors.products[index]?.price &&
                              errors?.products[index]?.price
                                ? 'error'
                                : ''
                            }
                            control={control}
                            defaultValue={item?.price ? item.price : ''}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="desc-tax-wrap">
                    {watch(`products.${index}.name`) && (
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('purchaseInvoices.description')}
                        </p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            disabled={selectedReceipt ? true : false}
                            setValue={setValue}
                            className="form-text-field"
                            name={`products.${index}.desc`}
                            type="text"
                            placeholder={t(
                              'purchaseInvoices.descriptionPlaceholder'
                            )}
                            errorMsg={
                              errors?.products &&
                              errors.products[index]?.desc &&
                              errors.products[index].desc.message
                            }
                            validateStatus={
                              errors?.products &&
                              errors.products[index]?.desc &&
                              errors?.products[index]?.desc
                                ? 'error'
                                : ''
                            }
                            control={control}
                            defaultValue={
                              item?.desc
                                ? item.desc
                                : allProducts?.length > 0
                                ? allProducts.find(
                                    (p) =>
                                      // eslint-disable-next-line eqeqeq
                                      p.id ==
                                      watch(`products.${index}.product_id`)
                                  )?.desc
                                : ''
                            }
                            // defaultValue={item?.desc ? item.desc : ''}
                          />
                        </div>
                      </div>
                    )}

                    {watch(`products.${index}.name`) && (
                      <div className="text-field-label-wrap">
                        <p className="label-p">{t('purchaseInvoices.tax')}</p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            disabled={
                              selectedReceipt !== null ? selectedReceipt : true
                            }
                            className="form-text-field"
                            setValue={setValue}
                            name={`products.${index}.tax`}
                            type="text"
                            placeholder={t('purchaseInvoices.taxPlaceholder')}
                            errorMsg={
                              errors?.products &&
                              errors.products[index]?.tax &&
                              errors.products[index].tax.message
                            }
                            validateStatus={
                              errors?.products &&
                              errors.products[index]?.tax &&
                              errors?.products[index]?.tax
                                ? 'error'
                                : ''
                            }
                            control={control}
                            defaultValue={getTaxDefaultValue(index)}
                            // defaultValue={watch(`products.${index}.tax`)} // make sure to set up defaultValue
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {watch(`products.${index}.price`) && (
                  <div className="radios-wrap">
                    <p className="radios-group-title">
                      {t('purchaseInvoices.discountType')}
                    </p>
                    <div className="labels-wrap">
                      {[
                        {
                          title: t('purchaseInvoices.fixedNumber'),
                          value: '1'
                        },
                        {
                          title: t('purchaseInvoices.rate'),
                          value: '2'
                        },
                        {
                          title: t('purchaseInvoices.withoutDiscount'),
                          value: 'no_discount'
                        }
                      ].map((obj, i) => (
                        <label
                          key={i}
                          className={`radio-input-label ${
                            String(watch(`products.${index}.discount_type`)) ===
                            String(obj.value)
                              ? 'selected'
                              : ''
                          }`}
                        >
                          <input
                            type="radio"
                            value={obj.value}
                            {...register(`products.${index}.discount_type`)}
                            // onChange={() => {
                            //   if (
                            //     watch(`products.${index}.discount_type`) == '1'
                            //   )
                            //     // fixed discount price
                            //     setValue(
                            //       `products.${index}.discount_percentage`,
                            //       ''
                            //     );
                            //   else if (
                            //     // precentage discount
                            //     watch(`products.${index}.discount_type`) == '2'
                            //   ) {
                            //     setValue(
                            //       `products.${index}.discount_price`,
                            //       ''
                            //     );
                            //   } else {
                            //     // no discount
                            //     setValue(
                            //       `products.${index}.discount_percentage`,
                            //       ''
                            //     );
                            //     setValue(
                            //       `products.${index}.discount_price`,
                            //       ''
                            //     );
                            //   }
                            // }}
                          />
                          <span className="label-span">{obj.title}</span>
                          {watch(`products.${index}.discount_type`) ==
                          String(obj.value) ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonEmpty />
                          )}
                        </label>
                      ))}
                    </div>

                    {errors?.organization_type && (
                      <p className="error-p">
                        {errors.organization_type.message}
                      </p>
                    )}

                    {String(watch(`products.${index}.discount_type`)) ==
                      '1' && (
                      <div className="before-after-wrap">
                        <div className="text-field-label-wrap">
                          <p className="label-p">
                            {t('purchaseInvoices.discountPrice')}
                          </p>
                          <div className="text-field-wrap">
                            <AntdTextField
                              // disabled={selectedReceipt ? true : false}
                              disabled={selectedReceipt}
                              className="form-text-field"
                              name={`products.${index}.discount_price`}
                              type="text"
                              placeholder={t(
                                'purchaseInvoices.discountPricePlaceholder'
                              )}
                              errorMsg={
                                errors?.products &&
                                errors.products[index]?.discount_price &&
                                errors.products[index].discount_price.message
                              }
                              validateStatus={
                                errors?.products &&
                                errors.products[index]?.discount_price
                                  ? 'error'
                                  : ''
                              }
                              control={control}
                            />
                          </div>
                        </div>
                        {!isNaN(parseInt(watch(`products.${index}.price`))) &&
                          !isNaN(
                            parseInt(watch(`products.${index}.discount_price`))
                          ) &&
                          !isNaN(
                            parseInt(watch(`products.${index}.quantity`))
                          ) && (
                            <div className="after-wrap">
                              <div className="after-title">
                                {t('purchaseInvoices.priceAfterDiscount')}
                              </div>
                              <span className="after-value">
                                {!isNaN(watch(`products.${index}.price`)) &&
                                !isNaN(watch(`products.${index}.quantity`)) &&
                                !isNaN(
                                  parseInt(
                                    watch(`products.${index}.discount_price`)
                                  )
                                )
                                  ? calcProductTotalValue(
                                      watch(`products.${index}.discount_type`),
                                      watch(`products.${index}.price`),
                                      watch(`products.${index}.discount_price`),
                                      watch(
                                        `products.${index}.discount_percentage`
                                      ),
                                      selectedReceipt
                                        ? watch(
                                            `products.${index}.returnQuantity`
                                          )
                                        : watch(`products.${index}.quantity`),
                                      watch(`products.${index}.tax`)
                                    )
                                  : null}
                              </span>
                            </div>
                          )}
                      </div>
                    )}

                    {String(watch(`products.${index}.discount_type`)) ==
                      '2' && (
                      <div className="before-after-wrap">
                        <div className="text-field-label-wrap">
                          <p className="label-p">
                            {t('purchaseInvoices.discountPercentage')}( % )
                          </p>
                          <div className="text-field-wrap">
                            <AntdTextField
                              // disabled={selectedReceipt ? true : false}
                              disabled={selectedReceipt}
                              className="form-text-field"
                              name={`products.${index}.discount_percentage`}
                              type="text"
                              placeholder={`${t(
                                'purchaseInvoices.discountPercentage'
                              )} ( % )`}
                              errorMsg={
                                errors?.products &&
                                errors.products[index]?.discount_percentage &&
                                errors.products[index].discount_percentage
                                  .message
                              }
                              validateStatus={
                                errors?.products &&
                                errors.products[index]?.discount_percentage
                                  ? 'error'
                                  : ''
                              }
                              control={control}
                            />
                          </div>
                        </div>
                        {!isNaN(parseInt(watch(`products.${index}.price`))) &&
                          !isNaN(
                            parseInt(
                              watch(`products.${index}.discount_percentage`)
                            )
                          ) &&
                          !isNaN(
                            parseInt(watch(`products.${index}.quantity`))
                          ) && (
                            <div className="after-wrap">
                              <div className="after-title">
                                {t('purchaseInvoices.priceAfterDiscount')}
                              </div>
                              <span className="after-value">
                                {!isNaN(watch(`products.${index}.price`)) &&
                                !isNaN(watch(`products.${index}.quantity`)) &&
                                !isNaN(
                                  parseInt(
                                    watch(
                                      `products.${index}.discount_percentage`
                                    )
                                  )
                                )
                                  ? calcProductTotalValue(
                                      watch(`products.${index}.discount_type`),
                                      watch(`products.${index}.price`),
                                      watch(`products.${index}.discount_price`),
                                      watch(
                                        `products.${index}.discount_percentage`
                                      ),
                                      selectedReceipt
                                        ? watch(
                                            `products.${index}.returnQuantity`
                                          )
                                        : watch(`products.${index}.quantity`),
                                      watch(`products.${index}.tax`)
                                    )
                                  : null}
                              </span>
                            </div>
                          )}
                      </div>
                    )}

                    {String(watch(`products.${index}.discount_type`)) ==
                      'no_discount' && (
                      <div className="before-after-wrap-without-discount">
                        {!isNaN(parseInt(watch(`products.${index}.price`))) &&
                          !isNaN(
                            parseInt(watch(`products.${index}.quantity`))
                          ) && (
                            <div className="after-wrap">
                              <div className="after-title">
                                {t('purchaseInvoices.price')}
                              </div>

                              <span className="after-value">
                                {!isNaN(watch(`products.${index}.price`)) &&
                                !isNaN(watch(`products.${index}.quantity`))
                                  ? calcProductTotalValue(
                                      watch(`products.${index}.discount_type`),
                                      watch(`products.${index}.price`),
                                      watch(`products.${index}.discount_price`),
                                      watch(
                                        `products.${index}.discount_percentage`
                                      ),
                                      selectedReceipt
                                        ? watch(
                                            `products.${index}.returnQuantity`
                                          )
                                        : watch(`products.${index}.quantity`),
                                      systemSettings?.tax
                                    )
                                  : null}
                              </span>
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              {productsFields?.length >= 0 && (
                <Tooltip title={t('purchaseInvoices.delete')}>
                  <Button
                    className="delete-field-btn"
                    size="large"
                    type="dashed"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => removeProductField(index)}
                  />
                </Tooltip>
              )}
            </li>
          );
        })}
      </ul>

      // <ul className="fields-array-ul without-padding">
      //   {productsFields.map((item, index) => {
      //     return (
      //       <li className="bill-field-delete-li" key={item.id}>
      //         <div className="li-fields">
      //           <div
      //             className={`product-id-quantity-wrap ${
      //               selectedReceipt ? 'isReturn' : ''
      //             }`}
      //           >
      //             <div className="select-label-wrap">
      //               <p className="label-p">إختار الخدمة</p>
      //               <div className="custom-select-wrap without-icon">
      //                 {(allProducts?.length >= 0 &&
      //                   allProducts.find((p) => p.id == item?.product_id)) ||
      //                 !selectedReceipt ? (
      //                   <AntdSelectOption
      //                     disabled={selectedReceipt ? true : false}
      //                     name={`products.${index}.product_id`}
      //                     errorMsg={
      //                       errors?.products &&
      //                       errors.products[index]?.product_id &&
      //                       errors.products[index].product_id.message
      //                     }
      //                     validateStatus={
      //                       errors?.products &&
      //                       errors.products[index]?.product_id &&
      //                       errors?.products[index]?.product_id
      //                         ? 'error'
      //                         : ''
      //                     }
      //                     control={control}
      //                     setValue={setValue}
      //                     placeholder="إختار الخدمة"
      //                     options={
      //                       allProducts?.length > 0 &&
      //                       allProducts.map((p) => {
      //                         return {
      //                           title: p.name,
      //                           value: String(p.id)
      //                         };
      //                       })
      //                     }
      //                     showSearch={true}
      //                     // onSearch={onSearch}
      //                     filterOption={(input, option) =>
      //                       option.children
      //                         .toLowerCase()
      //                         .indexOf(input.toLowerCase()) >= 0
      //                     }
      //                     formClassName="create-receipt-form"
      //                     onChange={(e) => {
      //                       handleSelectService(item, index, e);
      //                     }}
      //                   />
      //                 ) : (
      //                   <div
      //                     style={{
      //                       display: 'grid',
      //                       justifyItems: 'start',
      //                       alignItems: 'center',
      //                       paddingRight: 8
      //                     }}
      //                   >
      //                     <p>
      //                       {selectedReceipt?.products?.length > 0 &&
      //                         selectedReceipt?.products[index]?.product?.name}
      //                     </p>
      //                   </div>
      //                 )}
      //               </div>
      //             </div>

      //             <div className="q-return-q-price-wrapper">
      //               {watch(`products.${index}.product_id`) &&
      //                 selectedReceipt && (
      //                   <div className="text-field-label-wrap">
      //                     <p className="label-p">الكمية المرتجعة</p>
      //                     <div className="text-field-wrap">
      //                       <AntdTextField
      //                         className="form-text-field"
      //                         name={`products.${index}.returnQuantity`}
      //                         type="text"
      //                         placeholder="الكمية المرتجعة..."
      //                         errorMsg={
      //                           errors?.products &&
      //                           errors.products[index]?.returnQuantity &&
      //                           errors.products[index].returnQuantity.message
      //                         }
      //                         validateStatus={
      //                           errors?.products &&
      //                           errors.products[index]?.returnQuantity &&
      //                           errors?.products[index]?.returnQuantity
      //                             ? 'error'
      //                             : ''
      //                         }
      //                         setValue={setValue}
      //                         control={control}
      //                         onChange={() => {
      //                           if (selectedReceipt) {
      //                             const handleReturnQuantity = () => {
      //                               return (
      //                                 parseFloat(
      //                                   selectedReceipt?.products[index]
      //                                     ?.quantity
      //                                 ) -
      //                                 parseFloat(
      //                                   watch(
      //                                     `products.${index}.returnQuantity`
      //                                   )
      //                                 )
      //                               );
      //                             };
      //                             if (selectedReceipt.products.length > 1) {
      //                               if (
      //                                 parseFloat(
      //                                   watch(
      //                                     `products.${index}.returnQuantity`
      //                                   )
      //                                 ) >= 0 &&
      //                                 parseFloat(
      //                                   watch(
      //                                     `products.${index}.returnQuantity`
      //                                   )
      //                                 ) <=
      //                                   parseFloat(
      //                                     selectedReceipt?.products[index]
      //                                       ?.quantity
      //                                   )
      //                               )
      //                                 setValue(
      //                                   `products.${index}.quantity`,
      //                                   handleReturnQuantity()
      //                                 );
      //                               else if (
      //                                 parseFloat(
      //                                   watch(
      //                                     `products.${index}.returnQuantity`
      //                                   )
      //                                 ) >= 0 &&
      //                                 parseFloat(
      //                                   watch(
      //                                     `products.${index}.returnQuantity`
      //                                   )
      //                                 ) >
      //                                   parseFloat(
      //                                     selectedReceipt?.products[index]
      //                                       ?.quantity
      //                                   )
      //                               ) {
      //                                 setValue(
      //                                   `products.${index}.returnQuantity`,
      //                                   selectedReceipt?.products[index]
      //                                     ?.quantity
      //                                 );
      //                                 setValue(`products.${index}.quantity`, 0);
      //                               } else {
      //                                 setValue(
      //                                   `products.${index}.returnQuantity`,
      //                                   ''
      //                                 );
      //                                 setValue(
      //                                   `products.${index}.quantity`,
      //                                   selectedReceipt?.products[index]
      //                                     ?.quantity
      //                                 );
      //                               }
      //                             } else {
      //                               if (
      //                                 parseFloat(
      //                                   watch(
      //                                     `products.${index}.returnQuantity`
      //                                   )
      //                                 ) > 0 &&
      //                                 parseFloat(
      //                                   watch(
      //                                     `products.${index}.returnQuantity`
      //                                   )
      //                                 ) <=
      //                                   parseFloat(
      //                                     selectedReceipt?.products[index]
      //                                       ?.quantity
      //                                   )
      //                               )
      //                                 setValue(
      //                                   `products.${index}.quantity`,
      //                                   handleReturnQuantity()
      //                                 );
      //                               else if (
      //                                 parseFloat(
      //                                   watch(
      //                                     `products.${index}.returnQuantity`
      //                                   )
      //                                 ) > 0 &&
      //                                 parseFloat(
      //                                   watch(
      //                                     `products.${index}.returnQuantity`
      //                                   )
      //                                 ) >
      //                                   parseFloat(
      //                                     selectedReceipt?.products[index]
      //                                       ?.quantity
      //                                   )
      //                               ) {
      //                                 setValue(
      //                                   `products.${index}.returnQuantity`,
      //                                   selectedReceipt?.products[index]
      //                                     ?.quantity
      //                                 );
      //                                 setValue(`products.${index}.quantity`, 0);
      //                               } else {
      //                                 setValue(
      //                                   `products.${index}.returnQuantity`,
      //                                   ''
      //                                 );
      //                                 setValue(
      //                                   `products.${index}.quantity`,
      //                                   selectedReceipt?.products[index]
      //                                     ?.quantity
      //                                 );
      //                               }
      //                             }
      //                           }
      //                         }}
      //                       />
      //                     </div>
      //                   </div>
      //                 )}
      //               {watch(`products.${index}.product_id`) && (
      //                 <div className="text-field-label-wrap">
      //                   <p className="label-p">الكمية</p>
      //                   <div className="text-field-wrap">
      //                     <AntdTextField
      //                       className="form-text-field"
      //                       disabled={selectedReceipt}
      //                       name={`products.${index}.quantity`}
      //                       type="text"
      //                       placeholder="كمية الخدمة..."
      //                       errorMsg={
      //                         errors?.products &&
      //                         errors.products[index]?.quantity &&
      //                         errors.products[index].quantity.message
      //                       }
      //                       validateStatus={
      //                         errors?.products &&
      //                         errors.products[index]?.quantity &&
      //                         errors?.products[index]?.quantity
      //                           ? 'error'
      //                           : ''
      //                       }
      //                       setValue={setValue}
      //                       control={control}
      //                       defaultValue={watch(`products.${index}.quantity`)}
      //                       onChange={(e) =>
      //                         handleChangeQuantity(item, index, e)
      //                       }
      //                     />
      //                   </div>
      //                 </div>
      //               )}
      //               {watch(`products.${index}.product_id`) && (
      //                 <div className="prices-wrap">
      //                   <div className="text-field-label-wrap">
      //                     <p className="label-p">سعر الوحدة</p>
      //                     <div className="text-field-wrap">
      //                       <AntdTextField
      //                         disabled={selectedReceipt ? true : false}
      //                         setValue={setValue}
      //                         className="form-text-field"
      //                         name={`products.${index}.price`}
      //                         type="text"
      //                         placeholder="سعر بيع الخدمة..."
      //                         errorMsg={
      //                           errors?.products &&
      //                           errors.products[index]?.price &&
      //                           errors.products[index].price.message
      //                         }
      //                         validateStatus={
      //                           errors?.products &&
      //                           errors.products[index]?.price &&
      //                           errors?.products[index]?.price
      //                             ? 'error'
      //                             : ''
      //                         }
      //                         control={control}
      //                         defaultValue={handlePriceDefaultValue(
      //                           item,
      //                           index
      //                         )}
      //                         onChange={(e) => {
      //                           handlePriceChange(item, index, e);
      //                         }}
      //                         // defaultValue={
      //                         //   item?.price
      //                         //     ? item.price
      //                         //     : allProducts?.length > 0
      //                         //     ? allProducts.find(
      //                         //         (p) =>
      //                         //           // eslint-disable-next-line eqeqeq
      //                         //           p.id ==
      //                         //           watch(`products.${index}.product_id`)
      //                         //       )?.salePrice
      //                         //     : ''
      //                         // }
      //                       />
      //                     </div>
      //                   </div>
      //                 </div>
      //               )}

      //               {watch(`products.${index}.product_id`) && (
      //                 <div className="text-field-label-wrap">
      //                   <p className="label-p">السعر الصافى</p>
      //                   <div className="text-field-wrap"></div>

      //                   <AntdTextField
      //                     disabled={selectedReceipt ? true : false}
      //                     setValue={setValue}
      //                     className="form-text-field"
      //                     name={`products.${index}.netPrice`}
      //                     type="text"
      //                     // placeholder="سعر بيع الخدمة..."
      //                     control={control}
      //                     // defaultValue={handleSubTotalPriceDefaultValue(
      //                     //   item,
      //                     //   index
      //                     // )}
      //                     defaultValue={
      //                       selectedReceipt
      //                         ? handleNetPriceDefaultValue(item, index)
      //                         : ''
      //                     }
      //                     onChange={(e) => {
      //                       handleChangeSubTotalPrice(item, index, e);
      //                     }}
      //                   />
      //                 </div>
      //               )}
      //             </div>

      //             <div className="desc-tax-wrap">
      //               {watch(`products.${index}.product_id`) && (
      //                 <div className="text-field-label-wrap">
      //                   <p className="label-p">الوصف</p>
      //                   <div className="text-field-wrap">
      //                     <AntdTextField
      //                       disabled={selectedReceipt ? true : false}
      //                       setValue={setValue}
      //                       className="form-text-field"
      //                       name={`products.${index}.desc`}
      //                       type="text"
      //                       placeholder="الوصف..."
      //                       errorMsg={
      //                         errors?.products &&
      //                         errors.products[index]?.desc &&
      //                         errors.products[index].desc.message
      //                       }
      //                       validateStatus={
      //                         errors?.products &&
      //                         errors.products[index]?.desc &&
      //                         errors?.products[index]?.desc
      //                           ? 'error'
      //                           : ''
      //                       }
      //                       control={control}
      //                       defaultValue={item?.desc ? item.desc : ''}
      //                     />
      //                   </div>
      //                 </div>
      //               )}

      //               {watch(`products.${index}.product_id`) && (
      //                 <div className="text-field-label-wrap">
      //                   <p className="label-p">الضريبة</p>
      //                   <div className="text-field-wrap">
      //                     <AntdTextField
      //                       className="form-text-field"
      //                       disabled
      //                       setValue={setValue}
      //                       name={`products.${index}.tax`}
      //                       type="text"
      //                       placeholder="الضريبة..."
      //                       errorMsg={
      //                         errors?.products &&
      //                         errors.products[index]?.tax &&
      //                         errors.products[index].tax.message
      //                       }
      //                       validateStatus={
      //                         errors?.products &&
      //                         errors.products[index]?.tax &&
      //                         errors?.products[index]?.tax
      //                           ? 'error'
      //                           : ''
      //                       }
      //                       control={control}
      //                       defaultValue={getTaxDefaultValue(index)}
      //                       // defaultValue={watch(`products.${index}.tax`)} // make sure to set up defaultValue
      //                       // defaultValue={
      //                       //   allProducts?.length > 0 && !selectedReceipt
      //                       //     ? allProducts.find(
      //                       //         (p) =>
      //                       //           p.id ==
      //                       //           watch(`products.${index}.product_id`)
      //                       //       )?.tax
      //                       //     : watch(`products.${index}.tax`)
      //                       // }
      //                     />
      //                   </div>
      //                 </div>
      //               )}
      //             </div>
      //           </div>

      //           {watch(`products.${index}.price`) && (
      //             <div className="radios-wrap">
      //               <p className="radios-group-title">إختار نوع الخصم</p>
      //               <div className="labels-wrap">
      //                 {[
      //                   {
      //                     title: 'رقم ثابت',
      //                     value: '1'
      //                   },
      //                   {
      //                     title: 'نسبة',
      //                     value: '2'
      //                   },
      //                   {
      //                     title: 'بدون خصم',
      //                     value: 'no_discount'
      //                   }
      //                 ].map((obj, i) => (
      //                   <label
      //                     key={i}
      //                     className={`radio-input-label ${
      //                       String(watch(`products.${index}.discount_type`)) ===
      //                       String(obj.value)
      //                         ? 'selected'
      //                         : ''
      //                     }`}
      //                   >
      //                     <input
      //                       type="radio"
      //                       value={obj.value}
      //                       {...register(`products.${index}.discount_type`)}
      //                       // onChange={() => {
      //                       //   if (
      //                       //     watch(`products.${index}.discount_type`) == '1'
      //                       //   )
      //                       //     // fixed discount price
      //                       //     setValue(
      //                       //       `products.${index}.discount_percentage`,
      //                       //       ''
      //                       //     );
      //                       //   else if (
      //                       //     // precentage discount
      //                       //     watch(`products.${index}.discount_type`) == '2'
      //                       //   ) {
      //                       //     setValue(
      //                       //       `products.${index}.discount_price`,
      //                       //       ''
      //                       //     );
      //                       //   } else {
      //                       //     // no discount
      //                       //     setValue(
      //                       //       `products.${index}.discount_percentage`,
      //                       //       ''
      //                       //     );
      //                       //     setValue(
      //                       //       `products.${index}.discount_price`,
      //                       //       ''
      //                       //     );
      //                       //   }
      //                       // }}
      //                     />
      //                     <span className="label-span">{obj.title}</span>
      //                     {watch(`products.${index}.discount_type`) ==
      //                     String(obj.value) ? (
      //                       <RadioButtonFilled />
      //                     ) : (
      //                       <RadioButtonEmpty />
      //                     )}
      //                   </label>
      //                 ))}
      //               </div>

      //               {errors?.organization_type && (
      //                 <p className="error-p">
      //                   {errors.organization_type.message}
      //                 </p>
      //               )}

      //               {String(watch(`products.${index}.discount_type`)) ==
      //                 '1' && (
      //                 <div className="before-after-wrap">
      //                   <div className="text-field-label-wrap">
      //                     <p className="label-p">سعر الخصم</p>
      //                     <div className="text-field-wrap">
      //                       <AntdTextField
      //                         // disabled={selectedReceipt ? true : false}
      //                         disabled={selectedReceipt}
      //                         className="form-text-field"
      //                         name={`products.${index}.discount_price`}
      //                         type="text"
      //                         placeholder="سعر الخصم..."
      //                         errorMsg={
      //                           errors?.products &&
      //                           errors.products[index]?.discount_price &&
      //                           errors.products[index].discount_price.message
      //                         }
      //                         validateStatus={
      //                           errors?.products &&
      //                           errors.products[index]?.discount_price
      //                             ? 'error'
      //                             : ''
      //                         }
      //                         control={control}
      //                       />
      //                     </div>
      //                   </div>
      //                   {!isNaN(parseInt(watch(`products.${index}.price`))) &&
      //                     !isNaN(
      //                       parseInt(watch(`products.${index}.discount_price`))
      //                     ) &&
      //                     !isNaN(
      //                       parseInt(watch(`products.${index}.quantity`))
      //                     ) && (
      //                       <div className="after-wrap">
      //                         <div className="after-title">السعر بعد الخصم</div>
      //                         <span className="after-value">
      //                           {!isNaN(watch(`products.${index}.price`)) &&
      //                           !isNaN(watch(`products.${index}.quantity`))
      //                             ? calcProductTotalValue(
      //                                 watch(`products.${index}.discount_type`),
      //                                 watch(`products.${index}.price`),
      //                                 watch(`products.${index}.discount_price`),
      //                                 watch(
      //                                   `products.${index}.discount_percentage`
      //                                 ),
      //                                 watch(`products.${index}.quantity`),
      //                                 watch(`products.${index}.tax`),
      //                                 systemSettings?.tax
      //                               )
      //                             : null}
      //                         </span>
      //                       </div>
      //                     )}
      //                 </div>
      //               )}

      //               {String(watch(`products.${index}.discount_type`)) ==
      //                 '2' && (
      //                 <div className="before-after-wrap">
      //                   <div className="text-field-label-wrap">
      //                     <p className="label-p">نسبة الخصم ( % )</p>
      //                     <div className="text-field-wrap">
      //                       <AntdTextField
      //                         // disabled={selectedReceipt ? true : false}
      //                         disabled={selectedReceipt}
      //                         className="form-text-field"
      //                         name={`products.${index}.discount_percentage`}
      //                         type="text"
      //                         placeholder="نسبة الخصم ( % )"
      //                         errorMsg={
      //                           errors?.products &&
      //                           errors.products[index]?.discount_percentage &&
      //                           errors.products[index].discount_percentage
      //                             .message
      //                         }
      //                         validateStatus={
      //                           errors?.products &&
      //                           errors.products[index]?.discount_percentage
      //                             ? 'error'
      //                             : ''
      //                         }
      //                         control={control}
      //                       />
      //                     </div>
      //                   </div>
      //                   {!isNaN(parseInt(watch(`products.${index}.price`))) &&
      //                     !isNaN(
      //                       parseInt(
      //                         watch(`products.${index}.discount_percentage`)
      //                       )
      //                     ) &&
      //                     !isNaN(
      //                       parseInt(watch(`products.${index}.quantity`))
      //                     ) && (
      //                       <div className="after-wrap">
      //                         <div className="after-title">السعر بعد الخصم</div>
      //                         <span className="after-value">
      //                           {!isNaN(watch(`products.${index}.price`)) &&
      //                           !isNaN(watch(`products.${index}.quantity`))
      //                             ? calcProductTotalValue(
      //                                 watch(`products.${index}.discount_type`),
      //                                 watch(`products.${index}.price`),
      //                                 watch(`products.${index}.discount_price`),
      //                                 watch(
      //                                   `products.${index}.discount_percentage`
      //                                 ),
      //                                 watch(`products.${index}.quantity`),
      //                                 watch(`products.${index}.tax`),
      //                                 systemSettings?.tax
      //                               )
      //                             : null}
      //                         </span>
      //                       </div>
      //                     )}
      //                 </div>
      //               )}

      //               {String(watch(`products.${index}.discount_type`)) ==
      //                 'no_discount' && (
      //                 <div className="before-after-wrap-without-discount">
      //                   {!isNaN(parseInt(watch(`products.${index}.price`))) &&
      //                     !isNaN(
      //                       parseInt(watch(`products.${index}.quantity`))
      //                     ) && (
      //                       <div className="after-wrap">
      //                         <div className="after-title">السعر</div>
      //                         <span className="after-value">
      //                           {!isNaN(watch(`products.${index}.price`)) &&
      //                           !isNaN(watch(`products.${index}.quantity`))
      //                             ? calcProductTotalValue(
      //                                 watch(`products.${index}.discount_type`),
      //                                 watch(`products.${index}.price`),
      //                                 watch(`products.${index}.discount_price`),
      //                                 watch(
      //                                   `products.${index}.discount_percentage`
      //                                 ),
      //                                 watch(`products.${index}.quantity`),
      //                                 watch(`products.${index}.tax`),
      //                                 systemSettings?.tax
      //                               )
      //                             : null}
      //                         </span>
      //                       </div>
      //                     )}
      //                 </div>
      //               )}
      //             </div>
      //           )}
      //         </div>
      //         {/* {productsFields?.length >= 1 && !selectedReceipt && ( */}
      //         {productsFields?.length >= 0 && (
      //           <Tooltip title="حذف">
      //             <Button
      //               className="delete-field-btn"
      //               size="large"
      //               type="dashed"
      //               shape="circle"
      //               icon={<DeleteOutlined />}
      //               onClick={() => {
      //                 removeProductField(index);
      //               }}
      //             />
      //           </Tooltip>
      //         )}
      //       </li>
      //     );
      //   })}
      // </ul>
    )
  );
};

export default RenderProductsFields;
