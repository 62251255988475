import axios from 'axios';

const getAllIngredients = async (token, page) => {
  let url;
  if (Boolean(page)) {
    url = `page=${page}&test=1`
  }
  else {
    url = ``
  }
  try {
    const res = await axios.get(`/StockMaterial/all?${url}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllIngredients;
