/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import UserContext from '../../../contexts/user-context/UserProvider';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import useWarehouses from '../../../custom-hooks/useWarehouses';
import useProducts from '../../../custom-hooks/useProducts';
import useIngredients from '../../../custom-hooks/useIngredients';
import AntdRadioGroup from '../../../common/antd-form-components/AntdRadioGroup';
import StoreInventoryContext from '../../../contexts/store-inventory-context/StoreInventoryProvider';
import createNewInventoryApi from '../../../apis/store-apis/inventory-apis/createNewInventoryApi';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import useProductsWithSelect from '../../../custom-hooks/useProductsWithSelect';
import { useTranslation } from 'react-i18next';

const TableForm = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const { selectedInventory, setIsLoading, setModalOpened, setFetchCount } =
    useContext(StoreInventoryContext);
  const allWarehouses = useWarehouses();
  const { allProducts } = useProductsWithSelect();
  const allIngredients = useIngredients();
  const schema = Yup.object().shape({
    stock_id: Yup.string().required(
      t('Inventory.WarehouseInventory.pleaseSelectStock')
    ),
    model_id: Yup.string().required(
      t('Inventory.WarehouseInventory.pleaseSelectProduct')
    ),
    // type: Yup.string().required('من فضلك اختار النوع'),
    quantity: Yup.string()
      .required(
        t('Inventory.WarehouseInventory.pleaseEnterActualQuantityStock')
      )
      .matches(/^[0-9]+$/, t('Inventory.WarehouseInventory.onlyNumbersAllowed'))
  });
  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      stock_id: '',
      model_id: '',
      quantity: ''
    }
  });

  const handleModelOptions = () => {
    if (watch('type') == 1) {
      return (
        allProducts?.length > 0 &&
        allProducts.map((p) => ({
          title: p.name,
          value: p.id
        }))
      );
    } else if (watch('type') == 2) {
      return (
        allIngredients?.length > 0 &&
        allIngredients.map((ing) => ({
          title: ing.name,
          value: String(ing.id)
        }))
      );
    }
    return null;
  };

  const customApiRequest = useCustomApiRequest();
  const [isSubmittingForm, setIsSubmittingForm] = React.useState(false);
  const onSubmit = async (data) => {
    setIsSubmittingForm(true);
    setIsLoading(true);
    customApiRequest(
      createNewInventoryApi(user?.token, data),
      (res) => {
        setIsSubmittingForm(false);
        setIsLoading(false);
        if (res?.status === 200 && res?.data?.data) {
          setModalOpened(false);
          setFetchCount((prevState) => prevState + 1);
          successNotification({
            title: t('Inventory.WarehouseInventory.addedSuccessfully'),
            message: t(
              'Inventory.WarehouseInventory.inventoryCountCompletedSuccessfully'
            )
          });
        } else {
          errorNotification({
            title: t('Inventory.WarehouseInventory.errorOccurred'),
            message: res?.data?.message
              ? res.data.message
              : t('Inventory.WarehouseInventory.pleaseTryLater')
          });
        }
      },
      (error) => {
        setIsSubmittingForm(false);
        setIsLoading(false);
        errorNotification({
          title: t('Inventory.WarehouseInventory.errorOccurred'),
          message:
            error?.response?.data?.message ||
            t('Inventory.WarehouseInventory.pleaseTryLater')
        });
      }
    );
  };

  // handle initial values
  useEffect(() => {
    if (selectedInventory) {
      setValue(
        'stock_id',
        selectedInventory?.stock?.id ? selectedInventory.stock.id : ''
      );
      setValue(
        'model_id',
        selectedInventory?.model_id ? selectedInventory.model_id : ''
      );
      setValue('type', selectedInventory.type ? selectedInventory.type : '');
      setValue(
        'quantity',
        String(selectedInventory.quantity) ? selectedInventory.quantity : ''
      );
    }
  }, [selectedInventory, selectedInventory?.id]);

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form inventory-form"
      // fields={[
      //   {
      //     name: 'type',
      //     value: watch('type')
      //   }
      // ]}
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        {/*   <AntdRadioGroup
          name="type"
          className="form-radio-group"
          control={control}
          label="اختار نوع الجرد"
          validateStatus={errors?.type ? 'error' : ''}
          errorMsg={errors?.type?.message}
          onChange={() => setValue('model_id', '')}
          radios={[
            {
              title: 'منتجات',
              value: '1'
            }
            // {
            //   title: 'مكونات منتجات',
            //   value: '2'
            // }
          ]}
          // defaultValue={
          //   selectedInventory?.type ? String(selectedInventory.type) : ''
          // }
          isRadioButton
        /> */}

        <div className="select-label-wrap">
          <p className="label-p">
            {t('Inventory.WarehouseInventory.selectWarehouse')}
          </p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`stock_id`}
              errorMsg={errors?.stock_id && errors.stock_id.message}
              validateStatus={
                errors?.stock_id && errors?.stock_id ? 'error' : ''
              }
              control={control}
              setValue={setValue}
              placeholder={t('Inventory.WarehouseInventory.selectWarehouse')}
              options={
                allWarehouses?.length > 0 &&
                allWarehouses.map((p) => ({
                  title: p.name,
                  value: String(p.id)
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              // filterOption={(input, option) =>
              //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              // }
                                filterOption={(input, option) =>
                option?.children?.props?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
              formClassName="inventory-form"
            />
          </div>
        </div>

        <div className="select-label-wrap">
          <p className="label-p">
            {t('Inventory.WarehouseInventory.chooseProduct')}
          </p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`model_id`}
              errorMsg={errors?.model_id && errors.model_id.message}
              validateStatus={
                errors?.model_id && errors?.model_id ? 'error' : ''
              }
              control={control}
              setValue={setValue}
              placeholder={t('Inventory.WarehouseInventory.chooseProduct')}
              options={
                allProducts?.length > 0 &&
                allProducts.map((p) => ({
                  // title: `${p?.name} ( الكمية : ${p?.quantity} ) `,
                  title: p?.name,
                  value: p.id
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              // filterOption={(input, option) =>
              //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              // }
                                filterOption={(input, option) =>
                option?.children?.props?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
              formClassName="inventory-form"
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">
            {t('Inventory.WarehouseInventory.enterQuantity')}
          </p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name={`quantity`}
              type="text"
              placeholder={t(
                'Inventory.WarehouseInventory.enterQuantityPlaceholder'
              )}
              errorMsg={errors?.quantity && errors.quantity.message}
              validateStatus={errors?.quantity ? 'error' : ''}
              control={control}
              // defaultValue={watch(`products.${index}.quantity`)} // make sure to set up defaultValue
            />
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {t('Inventory.WarehouseInventory.warehouse')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
