/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import PageTable from './PageTable';
import { Descriptions } from 'antd';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import PageModal from './PageModal';
import SelectedReceiptModal from './SelectedReceiptModal';
import IncomingPurchaseInvoiceContext from '../../../contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import SelectedReceiptModalProducts from './SelectedReceiptModalProducts';
import { useTranslation } from 'react-i18next';
const SharedIncomingPurchaseInvoice = ({
  allFetchedReceipts,
  pagination,
  withoutReturnBtn = false,
  total,
  reports,
  taxPage,
  selectedPicker,
  allReceiptsData,
  flag
}) => {
  const systemSettings = useSystemSettings();
  const { modalOpened, selectedReceiptModalOpened } = useContext(
    IncomingPurchaseInvoiceContext
  );
  const { user } = useContext(UserContext);

  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);

  const renderSystemSettings = (obj) => {
    const {
      name,
      phone,
      email,
      addressCode,
      buildingNum,
      streetName,
      district,
      city,
      anotherId,
      country,
      VATRegistrationNumber,
      tax_number,
      postalCode
    } = obj;
    return (
      <>
       <div
        style={{
          marginTop: 32
        }}
        className="system-settings-details-wrap"
      >
        <Descriptions title="" bordered column={2}>
          {name && (
            <Descriptions.Item label={t('purchaseInvoiceReport.name')}>
              {name}
            </Descriptions.Item>
          )}
          {phone && (
            <Descriptions.Item label={t('purchaseInvoiceReport.phone')}>
              {phone}
            </Descriptions.Item>
          )}
          {email && (
            <Descriptions.Item label={t('purchaseInvoiceReport.email')}>
              {email}
            </Descriptions.Item>
          )}
          {buildingNum && (
            <Descriptions.Item
              label={t('purchaseInvoiceReport.buildingNumber')}
            >
              {buildingNum}
            </Descriptions.Item>
          )}
          {streetName && (
            <Descriptions.Item label={t('purchaseInvoiceReport.streetName')}>
              {streetName}
            </Descriptions.Item>
          )}
          {district && (
            <Descriptions.Item label={t('purchaseInvoiceReport.district')}>
              {district}
            </Descriptions.Item>
          )}
          {city && (
            <Descriptions.Item label={t('purchaseInvoiceReport.city')}>
              {city}
            </Descriptions.Item>
          )}
          {country && (
            <Descriptions.Item label={t('purchaseInvoiceReport.country')}>
              {country}
            </Descriptions.Item>
          )}
          {postalCode && (
            <Descriptions.Item label={t('purchaseInvoiceReport.postalCode')}>
              {postalCode}
            </Descriptions.Item>
          )}
          {addressCode && (
            <Descriptions.Item label={t('purchaseInvoiceReport.addressCode')}>
              {addressCode}
            </Descriptions.Item>
          )}
          {VATRegistrationNumber && (
            <Descriptions.Item label={t('purchaseInvoiceReport.vatNumber')}>
              {VATRegistrationNumber}
            </Descriptions.Item>
          )}
          {tax_number && (
            <Descriptions.Item label={t('purchaseInvoiceReport.vatNumber')}>
              {tax_number}
            </Descriptions.Item>
          )}
          {anotherId && (
            <Descriptions.Item label={t('purchaseInvoiceReport.anotherId')}>
              {anotherId}
            </Descriptions.Item>
          )}
          {String(total) && (
            <Descriptions.Item label={t('purchaseInvoiceReport.totalPrice')}>
              {parseFloat(total).toFixed(2)} {t('purchaseInvoiceReport.sar')}
            </Descriptions.Item>
          )}
          {/* {String(allFetchedReceipts?.total) && (
            <Descriptions.Item label="السعر الإجمالى">
              {allFetchedReceipts?.total} ريـــال
            </Descriptions.Item>
          )} */}
        </Descriptions>

      </div>
       {allReceiptsData && allFetchedReceipts?.length > 0 &&  <div
        style={{
          marginTop: 32
        }}
        className="system-settings-details-wrap"
      >
        <Descriptions title="" bordered column={2}>

          {String(allReceiptsData?.total_invoice) && (
            <Descriptions.Item label={t('InvoiceDetails.invoicesTotal')}>
              {allReceiptsData?.total_invoice ? allReceiptsData.total_invoice : 0 } {t('purchaseInvoiceReport.sar')}
            </Descriptions.Item>
          )}
           {String(allReceiptsData?.total_return) && (
            <Descriptions.Item label={t('InvoiceDetails.returnedTotal')}>
              {allReceiptsData?.total_return ? allReceiptsData.total_return : 0 } {t('purchaseInvoiceReport.sar')}
            </Descriptions.Item>
          )}
          {String(allReceiptsData?.total_number) && (
           <Descriptions.Item label={t('InvoiceDetails.invoicesNumber')}>
             {allReceiptsData?.total_number ? allReceiptsData.total_number : 0} 
           </Descriptions.Item>
         )}
         {String(allReceiptsData?.total) && (
           <Descriptions.Item label={t('InvoiceDetails.total')}>
             {allReceiptsData?.total? parseFloat(allReceiptsData?.total).toFixed(2)  : 0 } {t('purchaseInvoiceReport.sar')}
           </Descriptions.Item>
         )}
          </Descriptions>
      </div>}
      </>
    );
  };
  const handleData = (user) => {
    if (allFetchedReceipts) {
      if (user === 1) {
        return allFetchedReceipts?.data;
      } else if (user === 2) {
        return allFetchedReceipts;
      }
    }
  };

  const handleModal = (user) => {
    if (user === 1) {
      return <SelectedReceiptModalProducts />;
    } else if (user === 2) {
      return <SelectedReceiptModal />;
    }
  };

  return (
    <>
      {withoutReturnBtn &&
        systemSettings &&
        renderSystemSettings(systemSettings)}
      <div className="PageTable-wrapper">
        <PageTable
          withoutReturnBtn={withoutReturnBtn}
          allFetchedReceipts={allFetchedReceipts}
          pagination={pagination}
          reports={reports}
          taxPage={taxPage}
          selectedPicker={selectedPicker}
          flag={flag}
          currentLang={currentLang}
        />
      </div>

      {modalOpened && <PageModal />}
      {selectedReceiptModalOpened && handleModal(user.subscriptionType)}
    </>
  );
};

export default SharedIncomingPurchaseInvoice;
