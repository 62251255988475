/* eslint-disable eqeqeq */
import DeleteIcon from '../../../common/icons/DeleteIcon';
import { Button, Popconfirm, Tooltip } from 'antd';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import deleteInventoryApi from '../../../apis/store-apis/inventory-apis/deleteInventoryApi';
import { EyeOutlined } from '@ant-design/icons';

const tableColumns = (
  allFetchedInventories,
  setModalOpened,
  setFetchCount,
  setSelectedInventory,
  setIsLoading,
  t,
  token,
  setSelectedReceiptModalOpened,
  setSelectedReceipt,
  allFetchedReceipts,
  taxPage
) => {
   const arr =
     allFetchedReceipts?.invoices?.length > 0
       ? allFetchedReceipts.invoices
       : allFetchedReceipts;
  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteInventoryApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Inventory.WarehouseInventory.addedSuccessfully'),
          message: t('Inventory.WarehouseInventory.deletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Inventory.WarehouseInventory.errorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Inventory.WarehouseInventory.pleaseTryLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Inventory.WarehouseInventory.errorOccurred'),
        message: t('Inventory.WarehouseInventory.pleaseTryLater')
      });
    }
  };

  const renderRealQuantity = (realQuantity) => {
    if (realQuantity == 0) {
      return t('Inventory.WarehouseInventory.noDeficit');
    } else {
      if (String(realQuantity)) {
        if (parseFloat(realQuantity) > 0) {
          return (
            <div>
              {realQuantity}{' '}
              {t('Inventory.WarehouseInventory.inventoryShortage')}
            </div>
          );
        } else {
          return (
            <div>
              {realQuantity}{' '}
              {t('Inventory.WarehouseInventory.increaseInventory')}
            </div>
          );
        }
      }
    }
  };

  const renderActions = (obj) => {
    return (
      <div className="action-btns-wrap">
        {/* <div className="edit-btn" onClick={() => handleEditClick(obj)}>
          <StockOutlined />
          اجرد المخزون
        </div> */}
        <Popconfirm
          title={t('Inventory.WarehouseInventory.areyouSureToDelete')}
          onConfirm={() => handleDeleteRequest(obj.id)}
          okText={t('Inventory.WarehouseInventory.yes')}
          cancelText={t('Inventory.WarehouseInventory.no')}
        >
          <Tooltip title={t('Inventory.WarehouseInventory.delete')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };
  const handleViewReceiptClick = (record) => {
    if (taxPage) {
      setSelectedReceipt(record);
      setSelectedReceiptModalOpened(true);
    }

    // const foundReceipt =
    //   arr?.length > 0 && arr.find((receipt) => receipt.id === record.id);

    // if (foundReceipt) {
    //   setSelectedReceipt({ ...foundReceipt });
    //   setSelectedReceiptModalOpened(true);
    // }
    // if (foundReceipt) {
      setSelectedReceipt({ ...record });
      setSelectedReceiptModalOpened(true);
    // }
  };
  return [
    {
      title: t('Inventory.WarehouseInventory.code'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.WarehouseInventory.name'),
      dataIndex: 'type',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text desc-wrap">
            <span className="name-it-self">
              {record.name ? record.name : '----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.WarehouseInventory.quantityStock'),
      dataIndex: 'stockPhysicalQuantity',
      width: 122,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.quantity ? record.quantity : '----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.WarehouseInventory.inventoryResult'),
      dataIndex: 'quantityDiff',
      width: 162,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {renderRealQuantity(record.real_quantity)}
            </span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.WarehouseInventory.inventory'),
      dataIndex: 'stock',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.stock?.name ? record.stock.name : '-----'}
          </div>
        );
      }
    },
    {
      title: t('Restrictions.Preview'),
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            <Tooltip title={t('Restrictions.Preview')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => handleViewReceiptClick(record)}
              />
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: t('Inventory.WarehouseInventory.deleteInventory'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
