import React, { useContext, useEffect, useRef, useState } from 'react';
// import getQRApi from '../../apis/QR/getQRApi';
import { RWebShare } from 'react-web-share';
import * as htmlToImage from 'html-to-image';
import './QRcode.css';
import { useTranslation } from 'react-i18next';
import TagsContext from '../../contexts/nfcTags-context/tagsContext';
import UserContext from '../../contexts/user-context/UserProvider';

const QRcode = () => {
  const { user } = useContext(UserContext);
  const [qrString, setQrString] = useState();
  const { selectedTag } = useContext(TagsContext);
  const { t } = useTranslation();
  // const getQrCode = async () => {
  //   try {
  //     const res = await getQRApi(userData?.client_id);
  //     if (res?.status === 200 && res?.data?.status_code) {
  //       setQrString(res?.data?.Qr_string);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   userData?.client_id && getQrCode();
  // }, [userData?.client_id]);

  const qrCodeRef = useRef(null);
  const downloadQRCode = () => {
    htmlToImage
      .toPng(qrCodeRef.current)
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = 'qr-code.png';
        link.click();
      })
      .catch(function (error) {
        console.error('Error generating QR code:', error);
      });
  };

  return (
    <>
      <div class="box-all container profile-page qrcode-modal">
        <div class="home-content">
          <div class="content">
            <div class="bar-code-box">
              <h2>Scan QR-Code</h2>
              {selectedTag?.qr && (
                <div class="code">
                  <img src={selectedTag?.qr} alt="Embedded" ref={qrCodeRef} />
                </div>
              )}
              <button
                title="تحميل download"
                className="qr-btn"
                onClick={downloadQRCode}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30px"
                  height="30px"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12.5535 16.5061C12.4114 16.6615 12.2106 16.75 12 16.75C11.7894 16.75 11.5886 16.6615 11.4465 16.5061L7.44648 12.1311C7.16698 11.8254 7.18822 11.351 7.49392 11.0715C7.79963 10.792 8.27402 10.8132 8.55352 11.1189L11.25 14.0682V3C11.25 2.58579 11.5858 2.25 12 2.25C12.4142 2.25 12.75 2.58579 12.75 3V14.0682L15.4465 11.1189C15.726 10.8132 16.2004 10.792 16.5061 11.0715C16.8118 11.351 16.833 11.8254 16.5535 12.1311L12.5535 16.5061Z"
                    fill="#fff"
                  />
                  <path
                    d="M3.75 15C3.75 14.5858 3.41422 14.25 3 14.25C2.58579 14.25 2.25 14.5858 2.25 15V15.0549C2.24998 16.4225 2.24996 17.5248 2.36652 18.3918C2.48754 19.2919 2.74643 20.0497 3.34835 20.6516C3.95027 21.2536 4.70814 21.5125 5.60825 21.6335C6.47522 21.75 7.57754 21.75 8.94513 21.75H15.0549C16.4225 21.75 17.5248 21.75 18.3918 21.6335C19.2919 21.5125 20.0497 21.2536 20.6517 20.6516C21.2536 20.0497 21.5125 19.2919 21.6335 18.3918C21.75 17.5248 21.75 16.4225 21.75 15.0549V15C21.75 14.5858 21.4142 14.25 21 14.25C20.5858 14.25 20.25 14.5858 20.25 15C20.25 16.4354 20.2484 17.4365 20.1469 18.1919C20.0482 18.9257 19.8678 19.3142 19.591 19.591C19.3142 19.8678 18.9257 20.0482 18.1919 20.1469C17.4365 20.2484 16.4354 20.25 15 20.25H9C7.56459 20.25 6.56347 20.2484 5.80812 20.1469C5.07435 20.0482 4.68577 19.8678 4.40901 19.591C4.13225 19.3142 3.9518 18.9257 3.85315 18.1919C3.75159 17.4365 3.75 16.4354 3.75 15Z"
                    fill="#fff"
                  />
                </svg>
              </button>
              <RWebShare
                data={{
                  text: 'Share qr invoice',
                  url: 'Mahsoon here!',
                  title: 'Fatoorah'
                }}
                onClick={() => console.log('shared successfully!')}
              >
                <button className="share-btn">
                  <span>{t('NFCTags.share')}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25px"
                    height="25px"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M9 12C9 13.3807 7.88071 14.5 6.5 14.5C5.11929 14.5 4 13.3807 4 12C4 10.6193 5.11929 9.5 6.5 9.5C7.88071 9.5 9 10.6193 9 12Z"
                      stroke="#fff"
                      stroke-width="1.5"
                    />
                    <path
                      d="M14 6.5L9 10"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M14 17.5L9 14"
                      stroke="#fff"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M19 18.5C19 19.8807 17.8807 21 16.5 21C15.1193 21 14 19.8807 14 18.5C14 17.1193 15.1193 16 16.5 16C17.8807 16 19 17.1193 19 18.5Z"
                      stroke="#fff"
                      stroke-width="1.5"
                    />
                    <path
                      d="M19 5.5C19 6.88071 17.8807 8 16.5 8C15.1193 8 14 6.88071 14 5.5C14 4.11929 15.1193 3 16.5 3C17.8807 3 19 4.11929 19 5.5Z"
                      stroke="#fff"
                      stroke-width="1.5"
                    />
                  </svg>
                </button>
              </RWebShare>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QRcode;
