/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import StoreWelcomeSharedSection from '../store-welcome-shared-section/StoreWelcomeSharedSection';
import StoreItemManagerTable from './FirstTermStockTable';
import StoreItemManagerTableFilter from './FirstTermStockTableFilter';
import storeItemManagerImg from '../../../assets/imgs/store/store-item-manager.png';
import SelectedProductModal from './SelectedProductModal';
import './StoreItemManagerPage.scss';
import ProductBarCodeModal from './ProductsBarCodeModal';
import StoreNewProductModalContext from '../../../contexts/store-new-product-context/StoreNewProductProvider';
import Countdown from '../../../components/countdown/Countdown';
import { useContext } from 'react';
import UserContext from '../../../contexts/user-context/UserProvider';
import FirstTermStockTableFilter from './FirstTermStockTableFilter';
import FirstTermStockTable from './FirstTermStockTable';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

const FirstTermStock = () => {
  const { barCodeModalOpened } = React.useContext(StoreNewProductModalContext);
  const { user, filterValues, setFilterValues } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <div className="store-item-manager-page">
      <div className="top-section-wrap">
        {/* <StoreItemManagerHelloCard /> */}
        {/* <StoreWelcomeSharedSection
          img={storeItemManagerImg}
          title={t('Inventory.FirstTermStock.title')}
          subTitle={t('Inventory.FirstTermStock.subTitle')}
        /> */}
        {/* <Countdown /> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('Inventory.FirstTermStock.title')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.storePage.title')}
            title3={t('Inventory.FirstTermStock.title')}
            link3={'/store/first-term-stock'}
          />
        </div>
        <div className="search-wrap">
          <SearchNotificationsProfile noSearch />
        </div>
      </div>

      <FirstTermStockTableFilter />
      <div className="StoreItemManagerPage-table-wrapper">
        <FirstTermStockTable />
      </div>
      <SelectedProductModal />
      {barCodeModalOpened && <ProductBarCodeModal />}
      {/* {selectedProductModalOpened && <SelectedProductModal />} */}
    </div>
  );
};

export default FirstTermStock;
