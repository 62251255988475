import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedSubCategories: null,
  setAllFetchedSubCategories: (v) => {},
  selectedSubCategory: null,
  setSelectedSubCategory: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {}
};

const SettingsSubCategoriesContext = createContext(INITIAL_VALUES);

export const SettingsSubCategoriesProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedSubCategories, setAllFetchedSubCategories] = useState(
    INITIAL_VALUES.allFetchedSubCategories
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    INITIAL_VALUES.selectedSubCategory
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );

  return (
    <SettingsSubCategoriesContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedSubCategories,
        setAllFetchedSubCategories,
        selectedSubCategory,
        setSelectedSubCategory,
        isSubmittingForm,
        setIsSubmittingForm
      }}
    >
      {children}
    </SettingsSubCategoriesContext.Provider>
  );
};

export default SettingsSubCategoriesContext;
