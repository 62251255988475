/* eslint-disable eqeqeq */
import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import FileSaver from 'file-saver';
import { EyeOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import deleteProductApi from '../../../apis/store-apis/products/deleteProductApi';
import productTaxTypes from '../../../productTaxTypes';

const saveFile = (url, title) => {
  FileSaver.saveAs(url, title);
};

const tableColumns = (
  allFetchedProducts,
  setModalOpened,
  setFetchCount,
  setSelectedProduct,
  setSelectedProductId,
  setIsLoading,
  setSelectedProductModalOpened,
  setBarCodeModalOpned,
  t,
  token
) => {
  const handleEditProduct = (record) => {
    const found = allFetchedProducts.find((place) => place.id === record.id);
    if (found) {
      setSelectedProduct({ ...found });
      setModalOpened(true);
    }
  };

  const productSaleValue = (product) => {
    if (product) {
      if (product?.taxType == productTaxTypes?.withTax)
        return product?.salePriceWithTax;
      else if (product?.taxType == productTaxTypes?.withoutTax)
        return product?.salePrice
          ? parseFloat(product?.salePrice).toFixed(2)
          : '';
    }
    return '---';
  };
  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteProductApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Inventory.CurrentStock.addedSuccessfully'),
          message: t('Inventory.CurrentStock.deletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Inventory.CurrentStock.errorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Inventory.CurrentStock.pleaseTryLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Inventory.CurrentStock.errorOccurred'),
        message: t('Inventory.CurrentStock.pleaseTryLater')
      });
    }
  };
  const hanldeSelectProduct = (record) => {
    setSelectedProductModalOpened(true);
    setSelectedProductId(record.id);
    // const found = allFetchedProducts.find((obj) => obj.id === record.id);
    // if (found) setSelectedProduct({ ...found });
  };
  const handlePrintClick = (record) => {
    setSelectedProduct(record);
    setBarCodeModalOpned(true);
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditProduct(record)}>
          <EditIcon />
          {t('Inventory.CurrentStock.edit')}
        </div>
        <Popconfirm
          title={t('Inventory.CurrentStock.deletedSuccessfully')}
          onConfirm={() => handleDeleteRequest(record.id)}
          okText={t('Inventory.CurrentStock.yes')}
          cancelText={t('Inventory.CurrentStock.no')}
        >
          <Tooltip title={t('Inventory.CurrentStock.delete')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      title: t('Inventory.CurrentStock.productCode'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.CurrentStock.productName'),
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-title desc-wrap">
            <span className="name-it-self">{record.name}</span>
          </div>
        );
      },
      sorter: (a, b) => {
        let nameA = a.name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      }
    },
    {
      title: t('Inventory.CurrentStock.buyPrice'),
      dataIndex: 'buyPrice',
      // width: 192,
      render: (_, record) => {
        return <div className="row-cell row-text">{record.buyPrice}</div>;
      },
      sorter: (a, b) => a.buyPrice - b.buyPrice
    },
    {
      title: t('Inventory.CurrentStock.salePrice'),
      dataIndex: 'salePrice',
      // width: 192,
      render: (_, record) => {
        // return <div className="row-cell row-text">{record.salePrice}</div>;
        return (
          <div className="row-cell row-text">{productSaleValue(record)}</div>
        );
      },
      sorter: (a, b) => a.salePrice - b.salePrice
    },
    {
      title: t('Inventory.CurrentStock.wholesalePrice'),
      dataIndex: 'wholesalePrice',
      // width: 192,
      render: (_, record) => {
        return <div className="row-cell row-text">{record.wholesalePrice}</div>;
      },
      sorter: (a, b) => a.wholesalePrice - b.wholesalePrice
    },
    {
      title: t('Inventory.CurrentStock.quantity'),
      dataIndex: 'pQuantity',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record.quantity ? record.quantity : 0}
          </div>
        );
      },
      sorter: (a, b) => a.quantity - b.quantity
    },
    {
      title: t('Inventory.CurrentStock.productImage'),
      dataIndex: 'image',
      // width: 180,
      render: (_, record) => {
        return (
          <div className="row-cell row-img">
            {record.image ? (
              <img
                onClick={() => saveFile(record.image, record.name)}
                src={record.image}
                alt={record.name ? record.name : 'product'}
              />
            ) : (
              '-----'
            )}
          </div>
        );
      }
    },
    {
      title: t('Inventory.CurrentStock.barcodePrinting'),
      render: (_, record) => {
        return (
          <div
            className="row-cell row-circled-btn"
            onClick={() => {
              handlePrintClick(record);
            }}
          >
            <Tooltip title={t('Inventory.CurrentStock.barcodePrinting')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<PrinterOutlined />}
              />
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: t('Inventory.CurrentStock.productPreview'),
      render: (_, record) => {
        return (
          <div
            className="row-cell row-circled-btn"
            onClick={() => {
              hanldeSelectProduct(record);
            }}
          >
            <Tooltip title={t('Inventory.CurrentStock.productPreview')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
              />
            </Tooltip>
          </div>
        );
      }
    }
    // {
    //   title: 'الاعدادات',
    //   dataIndex: 'actions',
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-actions">{renderActions(record)}</div>
    //     );
    //   }
    // }
  ];
};

export default tableColumns;
