import React from 'react';

const StoreIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.583"
      height="15.316"
      viewBox="0 0 18.583 15.316"
    >
      <path
        id="box"
        d="M17.657,13.463h-.641V7.747a1.012,1.012,0,0,0-1.011-1.011H13.064V4.651a.274.274,0,1,0-.549,0V6.736H10.045a1.008,1.008,0,0,0-.737.32,1.007,1.007,0,0,0-.736-.32H5.631V1.011A.462.462,0,0,1,6.093.549H7.336V1.555a.625.625,0,0,0,.624.625H10.1a.625.625,0,0,0,.623-.625V.549h1.334a.463.463,0,0,1,.462.462v2.35a.274.274,0,1,0,.549,0V1.011A1.012,1.012,0,0,0,12.053,0H6.093A1.012,1.012,0,0,0,5.082,1.011V6.736H2.611A1.012,1.012,0,0,0,1.6,7.747v5.716H.926a.927.927,0,0,0,0,1.853H17.657a.927.927,0,0,0,0-1.853ZM10.17,1.555a.076.076,0,0,1-.074.076H7.96a.077.077,0,0,1-.075-.076V.549H10.17Zm1.666,5.731h2.286V8.291a.076.076,0,0,1-.075.075H11.912a.076.076,0,0,1-.075-.075Zm-1.791,0h1.243V8.291a.625.625,0,0,0,.624.624h2.135a.625.625,0,0,0,.624-.624V7.285h1.335a.463.463,0,0,1,.462.462v5.716H9.582V7.747a.463.463,0,0,1,.462-.462Zm-5.641,0H6.689V8.291a.075.075,0,0,1-.074.075H4.478A.075.075,0,0,1,4.4,8.291Zm13.254,7.482H.926a.377.377,0,0,1,0-.755H4.788a.275.275,0,0,0,0-.549H2.149V7.747a.462.462,0,0,1,.462-.462H3.855V8.291a.624.624,0,0,0,.623.624H6.614a.624.624,0,0,0,.623-.624V7.285H8.572a.463.463,0,0,1,.462.462v5.716H6.045a.275.275,0,0,0,0,.549H17.657a.377.377,0,1,1,0,.755Zm0,0"
        transform="translate(-0.001)"
        fill={color ? color : '#b5b5b5'}
      />
    </svg>
  );
};

export default StoreIcon;
