import accountsRouterLinks from './accounting-routes/accountingRouterLinks';
import archiveRouterLinks from './archive-routes/arciveRouterLinks';
import fatoorahStoreRouterLinks from './FatoorahStore/FatoorahStore';
import reportsRouterLinks from './reports-routes/reportsRouterLinks';
// import salesRouterLinks from './sales-routes/salesRouterLinks';
import settingsRouterLinks from './settings-routes/settingsRouterLinks';
import storeRouterLinks from './store-routes/storeRouterLinks';

const routerLinks = {
  ////////////////////////////
  homePage: '/',
  signInPage: '/',
  generalOutgoingInvoiceDetailsPage: (id) => {
    if (id) return `/outgoing-invoice-details/${id}`;
    return '/outgoing-invoice-details/:id';
  },
  generalIncomingInvoiceDetailsPage: (id) => {
    if (id) return `/incoming-invoice-details/${id}`;
    return '/incoming-invoice-details/:id';
  },

  generalVatReportsPage: () => {
    return '/vat-invoice-report';
  },
  signupPage: '/signup',
  projectsPage: '/projects',
  crmPage: '/crm',
  //////////////////////////////
  // store router links
  ...storeRouterLinks,
  //////////////////////////////
  // accounting router links
  ...accountsRouterLinks,
  //////////////////////////////
  // FatoorahStorePage router links
  // ...fatoorahStoreRouterLinks,
  //////////////////////////////
  // ...priceOfferRouterLinks,
  //////////////////////////////
  // settings routes
  ...settingsRouterLinks,
  //////////////////////////////
  // archive routes
  ...archiveRouterLinks,
  //////////////////////////////
  // ...salesRouterLinks,
  //////////////////////////////
  ...reportsRouterLinks,
  //////////////////////////////
  saleInvoicesPage: 'sale-invoices',
  importExportPage: '/imports-exports',
  humanResourcesPage: '/human-resources',
  webProgrammingPage: '/web-programming',
  analysisPage: '/analysis',
  // settingsPage: '/settings',
  notificationsPage: '/notifications',
  ProfilePage: '/profile',
  ////////////////////////////////
  notFound: '/not-found'
};

export default routerLinks;
