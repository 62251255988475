import { useContext } from 'react';
import { SettingsCategoriesProvider } from '../../../contexts/settings-contexts/SettingsCategoriesProvider';
import { SettingsSubCategoriesProvider } from '../../../contexts/settings-contexts/SettingsSubCategoriesProvider';
import { UpgradeProvider } from '../../../contexts/upgrade-context/UpgradeContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import SettingsCategoriesPage from '../../../pages/settings-pages/settings-categories-page/SettingsCategoriesPage';
import SettingsHelpPage from '../../../pages/settings-pages/settings-help-page/SettingsHelpPage';
import SettingsSubCategoriesPage from '../../../pages/settings-pages/settings-sub-categories-page/SettingsSubCategoriesPage';
import SystemSettingsPage from '../../../pages/settings-pages/system-settings-page/SystemSettingsPage';
import UpgradePage from '../../../pages/upgrade-page/UpgradePage';
import protectMe from '../../../utils/protectMe';
// import routerLinks from '../routerLinks';
import SystemUsersPage from '../../../pages/settings-pages/system-users-page/SystemUsersPage';
import { SettingsSystemUsersProvider } from '../../../contexts/settings-contexts/SettingsSystemUsersProvider';
import { SettingsWarehousesProvider } from '../../../contexts/settings-contexts/SettinsWarehousesProvider';
import SettingsWarehousePage from '../../../pages/settings-pages/settings-warehouse-page/SettingsWarehousePage';
import Links from '../Links';
import { SettingsWorkPlacesProvider } from '../../../contexts/settings-contexts/SettingsWorkPlacesProvider';
import WorkPlacesPage from '../../../pages/settings-pages/work-places/WorkPlacesPage';
import { SettingsMeasurementUnitsProvider } from '../../../contexts/settings-contexts/SettingsMeasurementUnitsPage';
import MeasurementUnitsPage from '../../../pages/settings-pages/measurement-units-page/MeasurementUnitsPage';
import GeneralSettingsPage from '../../../pages/settings-pages/general-settings-page/GeneralSettingsPage';
// import SettingsPosPage from '../../../pages/settings-pages/system-settings-page/settings-pos-page/SettingsPosPage';
import SettingsPosPage from '../../../pages/settings-pages/settings-pos-page/SettingsPosPage';
import { SettingsPosProvider } from '../../../contexts/settings-contexts/SettingsPosPage';
import SettingsWarehousesDetailsPage from '../../../pages/settings-pages/settings-warehouse-page/SettingsWarehousesDetailsPage';
import TechnicalSupportPage from '../../../pages/technical-support-page/TechnicalSupportPage';
import ChatSupportPage from '../../../pages/technical-support-page/ChatSupportPage';

const SettingsRoutes = () => {
  const { loggedIn, user } = useContext(UserContext);
  const routerLinks = Links();
  return [
    //
    protectMe(
      routerLinks.settingsCategoriesPage,
      <SettingsCategoriesProvider>
        <SettingsSubCategoriesProvider>
          <SettingsCategoriesPage />
        </SettingsSubCategoriesProvider>
      </SettingsCategoriesProvider>,
      5,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.settingsSubCategoriesPage(),
      <SettingsSubCategoriesProvider>
        <SettingsSubCategoriesPage />
      </SettingsSubCategoriesProvider>,
      6,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    //
    protectMe(
      routerLinks.systemSettingsPage,
      <SystemSettingsPage />,
      70,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    //
    protectMe(
      routerLinks.generalSettingsPage,
      <GeneralSettingsPage />,
      7,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.settingsPosPage,
      <SettingsPosProvider>
        <SettingsPosPage />
      </SettingsPosProvider>,
      100,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.systemHelpPage,
      <SettingsHelpPage />,
      10,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.upgradePage,
      <UpgradeProvider>
        <UpgradePage />
      </UpgradeProvider>,
      19000,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.systemUsersPage,
      <SettingsSystemUsersProvider>
        <SystemUsersPage />
      </SettingsSystemUsersProvider>,
      9,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.settingsWarehousesPage,
      <SettingsWarehousesProvider>
        <SettingsWarehousePage />
      </SettingsWarehousesProvider>,
      3,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.settingsWarehousesDetailsPage(),
      <SettingsWarehousesProvider>
        <SettingsWarehousesDetailsPage />
      </SettingsWarehousesProvider>,
      4,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.viewPosPage,
      <SettingsPosPage />,
      16,
      routerLinks.signInPage,
      loggedIn
    ),
    protectMe(
      routerLinks.technicalSupportPage,
      <TechnicalSupportPage />,
      106,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.chatSupportPage(),
      <ChatSupportPage />,
      106,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.settingsWorkPlacesPage,
      <SettingsWorkPlacesProvider>
        <WorkPlacesPage />
      </SettingsWorkPlacesProvider>,
      1,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.settingsMeasureUnitsPage,
      <SettingsMeasurementUnitsProvider>
        <MeasurementUnitsPage />
      </SettingsMeasurementUnitsProvider>,
      2,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    )
    //
  ];
};

export default SettingsRoutes;
