/* eslint-disable eqeqeq */
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import AntdRadioGroup from '../../../common/antd-form-components/AntdRadioGroup';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import CustomAntdDatePicker from '../../../common/antd-form-components/CustomAntdDatePicker';
import { calcProductTotalValue } from './receiptCalculations';

const RenderIngredientsFields = (
  watch,
  allIngredients,
  allWarehouses,
  control,
  setValue,
  errors,
  ingredientsFields,
  removeIngredientField,
  selectedReceipt,
  systemSettings
) => {
  const getTaxDefaultValue = (index) => {
    if (allIngredients?.length > 0 && !selectedReceipt) {
      return '0';
    }

    return watch(`ingredients.${index}.ingredient_id`)?.tax;
  };
  const { t } = useTranslation();

  return (
    ingredientsFields?.length > 0 && (
      <ul className="fields-array-ul without-padding">
        {ingredientsFields.map((item, index) => {
          return (
            <li className="bill-field-delete-li" key={item.id}>
              <div className="li-fields">
                <div
                  className={`product-id-quantity-tax-wrap ${
                    selectedReceipt ? 'isReturn' : ''
                  }`}
                >
                  <div className="select-label-wrap">
                    <p className="label-p">{t('Quotations.ChooseComponent')}</p>
                    <div className="custom-select-wrap without-icon">
                      <AntdSelectOption
                        disabled={selectedReceipt ? true : false}
                        name={`ingredients.${index}.ingredient_id`}
                        errorMsg={
                          errors?.ingredients &&
                          errors.ingredients[index]?.ingredient_id &&
                          errors.ingredients[index].ingredient_id.message
                        }
                        validateStatus={
                          errors?.ingredients &&
                          errors.ingredients[index]?.ingredient_id &&
                          errors?.ingredients[index]?.ingredient_id
                            ? 'error'
                            : ''
                        }
                        control={control}
                        setValue={setValue}
                        placeholder={`${t('Quotations.ChooseComponent')}`}
                        options={
                          allIngredients?.length > 0 &&
                          allIngredients.map((p) => ({
                            title: p.name,
                            value: String(p.id)
                          }))
                        }
                        showSearch={true}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        formClassName="create-receipt-form"
                      />
                    </div>
                  </div>

                  <div className="q-return-q-tax-wrapper">
                    {selectedReceipt && (
                      <div className="text-field-label-wrap">
                        <p className="label-p">كمية المكون المراد استرجاعها</p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            name={`ingredients.${index}.returnQuantity`}
                            type="text"
                            placeholder="كمية المكون المراد استرجاعها..."
                            errorMsg={
                              errors?.ingredients &&
                              errors.ingredients[index]?.returnQuantity &&
                              errors.ingredients[index].returnQuantity.message
                            }
                            validateStatus={
                              errors?.ingredients &&
                              errors.ingredients[index]?.returnQuantity &&
                              errors?.ingredients[index]?.returnQuantity
                                ? 'error'
                                : ''
                            }
                            control={control}
                            onChange={() => {
                              if (selectedReceipt) {
                                const handleReturnQuantity = () => {
                                  return (
                                    parseFloat(
                                      selectedReceipt?.details[index]?.quantity
                                    ) -
                                    parseFloat(
                                      watch(
                                        `ingredients.${index}.returnQuantity`
                                      )
                                    )
                                  );
                                };

                                if (
                                  parseFloat(
                                    watch(`ingredients.${index}.returnQuantity`)
                                  ) > 0 &&
                                  parseFloat(
                                    watch(`ingredients.${index}.returnQuantity`)
                                  ) <=
                                    parseFloat(
                                      selectedReceipt?.details[index]?.quantity
                                    )
                                )
                                  setValue(
                                    `ingredients.${index}.quantity`,
                                    handleReturnQuantity()
                                  );
                                else if (
                                  parseFloat(
                                    watch(`ingredients.${index}.returnQuantity`)
                                  ) > 0 &&
                                  parseFloat(
                                    watch(`ingredients.${index}.returnQuantity`)
                                  ) >
                                    parseFloat(
                                      selectedReceipt?.details[index]?.quantity
                                    )
                                ) {
                                  setValue(
                                    `ingredients.${index}.returnQuantity`,
                                    selectedReceipt?.details[index]?.quantity
                                  );
                                  setValue(`ingredients.${index}.quantity`, 0);
                                } else {
                                  setValue(
                                    `ingredients.${index}.returnQuantity`,
                                    ''
                                  );
                                  setValue(
                                    `ingredients.${index}.quantity`,
                                    selectedReceipt?.details[index]?.quantity
                                  );
                                }
                              }
                            }}
                            // defaultValue={watch(`ingredients.${index}.quantity`)} // make sure to set up defaultValue
                          />
                        </div>
                      </div>
                    )}
                    <div className="text-field-label-wrap">
                      <p className="label-p">كمية المكون</p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          className="form-text-field"
                          disabled={selectedReceipt}
                          name={`ingredients.${index}.quantity`}
                          type="text"
                          placeholder="كمية المكون..."
                          errorMsg={
                            errors?.ingredients &&
                            errors.ingredients[index]?.quantity &&
                            errors.ingredients[index].quantity.message
                          }
                          validateStatus={
                            errors?.ingredients &&
                            errors.ingredients[index]?.quantity &&
                            errors?.ingredients[index]?.quantity
                              ? 'error'
                              : ''
                          }
                          control={control}
                          onChange={() => {
                            if (selectedReceipt) {
                              if (
                                parseFloat(
                                  watch(`ingredients.${index}.quantity`)
                                ) <=
                                parseFloat(
                                  selectedReceipt?.details[index]?.quantity
                                )
                              ) {
                                setValue(
                                  `ingredients.${index}.quantity`,
                                  watch(`ingredients.${index}.quantity`)
                                );
                              } else {
                                setValue(
                                  `ingredients.${index}.quantity`,
                                  selectedReceipt?.details[index]?.quantity
                                );
                              }
                            }
                          }}
                          // defaultValue={watch(`ingredients.${index}.quantity`)} // make sure to set up defaultValue
                        />
                      </div>
                    </div>

                    {watch(`ingredients.${index}.ingredient_id`) && (
                      <div className="text-field-label-wrap">
                        <p className="label-p">الضريبة</p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            disabled={selectedReceipt}
                            setValue={setValue}
                            name={`ingredients.${index}.tax`}
                            type="text"
                            placeholder="الضريبة..."
                            errorMsg={
                              errors?.ingredients &&
                              errors.ingredients[index]?.tax &&
                              errors.ingredients[index].tax.message
                            }
                            validateStatus={
                              errors?.ingredients &&
                              errors.ingredients[index]?.tax &&
                              errors?.ingredients[index]?.tax
                                ? 'error'
                                : ''
                            }
                            control={control}
                            defaultValue={getTaxDefaultValue(index)}
                            // defaultValue={watch(`ingredients.${index}.tax`)} // make sure to set up defaultValue
                            // defaultValue={
                            //   allIngredients?.length > 0
                            //     ? allIngredients.find(
                            //         (p) =>
                            //           p.id ==
                            //           watch(`ingredients.${index}.ingredient_id`)
                            //       )?.tax
                            //     : watch(`ingredients.${index}.tax`)
                            // }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="warehoues-expiration-date-wrap">
                  <div className="select-label-wrap">
                    <p className="label-p">
                      {t('Quotations.ChooseRepository')}
                    </p>
                    <div className="custom-select-wrap without-icon">
                      <AntdSelectOption
                        name={`ingredients.${index}.warehouse_id`}
                        errorMsg={
                          errors?.ingredients &&
                          errors.ingredients[index]?.warehouse_id &&
                          errors.ingredients[index].warehouse_id.message
                        }
                        validateStatus={
                          errors?.ingredients &&
                          errors.ingredients[index]?.warehouse_id &&
                          errors?.ingredients[index]?.warehouse_id
                            ? 'error'
                            : ''
                        }
                        control={control}
                        setValue={setValue}
                        placeholder={`${t('Quotations.ChooseRepository')}`}
                        options={
                          allWarehouses?.length > 0 &&
                          allWarehouses.map((p) => ({
                            title: p.name,
                            value: String(p.id)
                          }))
                        }
                        showSearch={true}
                        // onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        formClassName="create-receipt-form"
                      />
                    </div>
                  </div>
                  <label className="custom-date-input">
                    <span className="label-span">
                      {t('Quotations.ExpirationDate')}
                    </span>
                    <CustomAntdDatePicker
                      // disablePast
                      control={control}
                      name={`ingredients.${index}.expirationDate`}
                      className="receipt-date-picker"
                      formClassName="create-receipt-form"
                      errorMsg={
                        errors?.ingredients &&
                        errors.ingredients[index]?.expirationDate &&
                        errors.ingredients[index].expirationDate.message
                      }
                    />
                  </label>
                </div>

                {watch(`ingredients.${index}.ingredient_id`) && (
                  <div className="prices-wrap">
                    <div className="text-field-label-wrap">
                      <p className="label-p">{t('Quotations.PurchasePrice')}</p>
                      <div className="text-field-wrap">
                        <AntdTextField
                          disabled={selectedReceipt ? true : false}
                          setValue={setValue}
                          className="form-text-field"
                          name={`ingredients.${index}.price`}
                          type="text"
                          placeholder={`${t('Quotations.PurchasePrice')}...`}
                          errorMsg={
                            errors?.ingredients &&
                            errors.ingredients[index]?.price &&
                            errors.ingredients[index].price.message
                          }
                          validateStatus={
                            errors?.ingredients &&
                            errors.ingredients[index]?.price &&
                            errors?.ingredients[index]?.price
                              ? 'error'
                              : ''
                          }
                          control={control}
                          defaultValue={
                            item?.price
                              ? item.price
                              : allIngredients?.length > 0
                              ? allIngredients.find(
                                  (ing) =>
                                    // eslint-disable-next-line eqeqeq
                                    ing.id ==
                                    watch(`ingredients.${index}.ingredient_id`)
                                )?.price
                              : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

                {watch(`ingredients.${index}.price`) && (
                  <div className="radios-field-array-wrap1">
                    <AntdRadioGroup
                      // disabled={selectedReceipt ? true : false}
                      name={`ingredients.${index}.discount_type`}
                      className="form-radio-group"
                      control={control}
                      label={t('Quotations.ChooseDiscountType')}
                      defaultValue={
                        selectedReceipt
                          ? item?.discount_type == '1' ||
                            item?.discount_type == '2'
                            ? String(item.discount_type)
                            : 'no_discount'
                          : ''
                      }
                      onChange={() => {
                        if (watch(`ingredients.${index}.discount_type`) == '1')
                          setValue(
                            `ingredients.${index}.discount_percentage`,
                            ''
                          );
                        else if (
                          watch(`ingredients.${index}.discount_type`) == '2'
                        ) {
                          setValue(`ingredients.${index}.discount_price`, '');
                        } else {
                          // no discount
                          setValue(
                            `ingredients.${index}.discount_percentage`,
                            ''
                          );
                          setValue(`ingredients.${index}.discount_price`, '');
                        }
                      }}
                      errorMsg={
                        errors?.ingredients &&
                        errors.ingredients[index]?.discount_type &&
                        errors.ingredients[index].discount_type.message
                      }
                      validateStatus={
                        errors?.ingredients &&
                        errors.ingredients[index]?.discount_type
                          ? 'error'
                          : ''
                      }
                      radios={[
                        {
                          title: t('Quotations.FixedNumber'),
                          value: '1'
                        },
                        {
                          // title: 'نسبة',
                          title: t('Quotations.Percentage'),

                          value: '2'
                        },
                        {
                          // title: 'بدون خصم',
                          title: t('Quotations.NoDiscount'),

                          value: 'no_discount'
                        }
                      ]}
                      // isRadioButton
                    />
                    {String(watch(`ingredients.${index}.discount_type`)) ==
                      '1' && (
                      <div className="before-after-wrap">
                        <div className="text-field-label-wrap">
                          <p className="label-p">
                            {t('Quotations.DiscountPrice')}
                          </p>
                          <div className="text-field-wrap">
                            <AntdTextField
                              // disabled={selectedReceipt ? true : false}
                              className="form-text-field"
                              name={`ingredients.${index}.discount_price`}
                              type="text"
                              placeholder={`${t(
                                'Quotations.DiscountPrice'
                              )}...`}
                              errorMsg={
                                errors?.ingredients &&
                                errors.ingredients[index]?.discount_price &&
                                errors.ingredients[index].discount_price.message
                              }
                              validateStatus={
                                errors?.ingredients &&
                                errors.ingredients[index]?.discount_price
                                  ? 'error'
                                  : ''
                              }
                              control={control}
                            />
                          </div>
                        </div>
                        {!isNaN(
                          parseInt(watch(`ingredients.${index}.price`))
                        ) &&
                          !isNaN(
                            parseInt(
                              watch(`ingredients.${index}.discount_price`)
                            )
                          ) &&
                          !isNaN(
                            parseInt(watch(`ingredients.${index}.quantity`))
                          ) && (
                            <div className="after-wrap">
                              <div className="after-title">
                                {t('Quotations.FinalPriceAfterDiscount')}
                              </div>
                              <span className="after-value">
                                {!isNaN(watch(`ingredients.${index}.price`)) &&
                                !isNaN(watch(`ingredients.${index}.quantity`))
                                  ? calcProductTotalValue(
                                      watch(
                                        `ingredients.${index}.discount_type`
                                      ),
                                      watch(`ingredients.${index}.price`),
                                      watch(
                                        `ingredients.${index}.discount_price`
                                      ),
                                      watch(
                                        `ingredients.${index}.discount_percentage`
                                      ),
                                      watch(`ingredients.${index}.quantity`),
                                      watch(`ingredients.${index}.tax`),
                                      systemSettings?.tax,
                                      true
                                    )
                                  : null}
                              </span>
                            </div>
                          )}
                      </div>
                    )}
                    {String(watch(`ingredients.${index}.discount_type`)) ==
                      '2' && (
                      <div className="before-after-wrap">
                        <div className="text-field-label-wrap">
                          <p className="label-p">
                            {t('Quotations.DiscountPercentage')} ( % )
                          </p>
                          <div className="text-field-wrap">
                            <AntdTextField
                              // disabled={selectedReceipt ? true : false}
                              className="form-text-field"
                              name={`ingredients.${index}.discount_percentage`}
                              type="text"
                              placeholder={`${t(
                                'Quotations.DiscountPercentage'
                              )} ( % )`}
                              errorMsg={
                                errors?.ingredients &&
                                errors.ingredients[index]
                                  ?.discount_percentage &&
                                errors.ingredients[index].discount_percentage
                                  .message
                              }
                              validateStatus={
                                errors?.ingredients &&
                                errors.ingredients[index]?.discount_percentage
                                  ? 'error'
                                  : ''
                              }
                              control={control}
                            />
                          </div>
                        </div>
                        {!isNaN(
                          parseInt(watch(`ingredients.${index}.price`))
                        ) &&
                          !isNaN(
                            parseInt(
                              watch(`ingredients.${index}.discount_percentage`)
                            )
                          ) &&
                          !isNaN(
                            parseInt(watch(`ingredients.${index}.quantity`))
                          ) && (
                            <div className="after-wrap">
                              <div className="after-title">
                                {t('Quotations.FinalPriceAfterDiscount')}
                              </div>
                              <span className="after-value">
                                {!isNaN(watch(`ingredients.${index}.price`)) &&
                                !isNaN(watch(`ingredients.${index}.quantity`))
                                  ? calcProductTotalValue(
                                      watch(
                                        `ingredients.${index}.discount_type`
                                      ),
                                      watch(`ingredients.${index}.price`),
                                      watch(
                                        `ingredients.${index}.discount_price`
                                      ),
                                      watch(
                                        `ingredients.${index}.discount_percentage`
                                      ),
                                      watch(`ingredients.${index}.quantity`),
                                      watch(`ingredients.${index}.tax`)
                                    )
                                  : null}
                              </span>
                            </div>
                          )}
                      </div>
                    )}

                    {String(watch(`ingredients.${index}.discount_type`)) ==
                      'no_discount' && (
                      <div className="before-after-wrap-without-discount">
                        {!isNaN(
                          parseInt(watch(`ingredients.${index}.price`))
                        ) &&
                          !isNaN(
                            parseInt(watch(`ingredients.${index}.quantity`))
                          ) && (
                            <div className="after-wrap">
                              <div className="after-title">
                                {t('Quotations.Price')}
                              </div>
                              <span className="after-value">
                                {!isNaN(watch(`ingredients.${index}.price`)) &&
                                !isNaN(watch(`ingredients.${index}.quantity`))
                                  ? calcProductTotalValue(
                                      watch(
                                        `ingredients.${index}.discount_type`
                                      ),
                                      watch(`ingredients.${index}.price`),
                                      watch(
                                        `ingredients.${index}.discount_price`
                                      ),
                                      watch(
                                        `ingredients.${index}.discount_percentage`
                                      ),
                                      watch(`ingredients.${index}.quantity`),
                                      watch(`ingredients.${index}.tax`),
                                      systemSettings?.tax
                                    )
                                  : null}
                              </span>
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                )}
              </div>

              {ingredientsFields?.length >= 1 && !selectedReceipt && (
                <Tooltip title="حذف">
                  <Button
                    className="delete-field-btn"
                    size="large"
                    type="dashed"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => removeIngredientField(index)}
                  />
                </Tooltip>
              )}
            </li>
          );
        })}
      </ul>
    )
  );
};

export default RenderIngredientsFields;
