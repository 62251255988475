import axios from 'axios';

const deleteUnitApi = async (token, id) => {
  const formData = new FormData();
  formData.append('id', id); 
  try {
    const res = await axios.post(
      `/MajorUnit/delete`,
      formData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token,
          lang: 'ar'
        }
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export default deleteUnitApi;
