import axios from 'axios';

const ceateNewClientApi = async (token, values, edit = false) => {
  try {
    let url;
    if (!edit) url = '/User/create';
    else url = '/User/update';
    const res = await axios.post(url, values, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        lagn: 'ar'
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default ceateNewClientApi;
