/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import PageModal from './PageModal';
import ReservationContext from '../../contexts/reservation-context/ReservationContext';
import UserContext from '../../contexts/user-context/UserProvider';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import SelectedSaleInvoiceModal from '../sale-invoices-page/SelectedSaleInvoiceModal';
import './ReservationPage.scss';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import getAllSaleInvoicesApi from '../../apis/sale-invoice-api/getAllSaleInvoicesApi';
import SharedLayoutBtns from '../../components/app/pos-section/SharedLayoutBtns';
import NotificationsProfile from '../../components/app/pos-section/notifications-profile/NotificationsProfile';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';

const localizer = momentLocalizer(moment);

// const myEventsList = [
//   {
//     title: 'Event one title',
//     desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia ducimus illum et mollitia voluptatem odit cupiditate labore, alias corrupti exercitationem voluptate quisquam fugiat id unde laboriosam deserunt soluta quia. Ratione.',
//     start: new Date(),
//     end: new Date(),
//     allDay: true
//     // allDay?: boolean
//     // resource?: any,
//   },
//   {
//     title: 'Event Two title',
//     desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia ducimus illum et mollitia voluptatem odit cupiditate labore, alias corrupti exercitationem voluptate quisquam fugiat id unde laboriosam deserunt soluta quia. Ratione.',
//     start: new Date(2021, 6, 17),
//     end: new Date(2021, 6, 17),
//     allDay: true
//     // allDay?: boolean
//     // resource?: any,
//   }
// ];

const ReservationPage = () => {
  const { selectedSaleInvoiceModalOpned } = useContext(PosInvoiceContext);
  const { t } = useTranslation();

  const {
    setIsLoading,
    modalOpened,
    setModalOpened,
    setSelectedCalendar,
    allFetchedReservations,
    setAllFetchedReservations
  } = useContext(ReservationContext);
  const { user } = useContext(UserContext);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      setAllFetchedReservations(true);
      customApiRequest(
        getAllSaleInvoicesApi(user?.token || user?.employee?.token, {
          type: 3
        }),
        (res) => {
          if (res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllFetchedReservations(data);
            setIsLoading(false);
          }
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const [calendarEvents, setCalendarEvents] = React.useState([]);
  useEffect(() => {
    if (allFetchedReservations?.length > 0) {
      setCalendarEvents(
        allFetchedReservations.map((obj) => ({
          ...obj,
          start: obj?.dueDate ? new Date(obj?.dueDate) : null,
          end: obj?.dueDate ? new Date(obj?.dueDate) : null,
          title: obj.name,
          allDay: true
        }))
      );
    }
  }, [allFetchedReservations]);

  const handleSelectEvent = (b) => {
    setSelectedCalendar(b);
    setModalOpened(true);
  };

  // const eventStyleGetter = (event, start, end, isSelected) => {

  //   const backgroundColor = '#' + event.hexColor;
  //   // if (event.end.getTime() - new Date().getTime() > 0) {

  //   // }
  //   const sharedStyle = {
  //     borderRadius: '4px',
  //     // opacity: 0.8,
  //     // color: 'black',
  //     border: '0px',
  //     display: 'block'
  //   };
  //   const greenStyle = {
  //     backgroundColor: 'green',
  //     ...sharedStyle
  //   };
  //   const orangeStyle = {
  //     backgroundColor: 'orange',
  //     ...sharedStyle
  //   };
  //   const redStyle = {
  //     backgroundColor: 'red',
  //     ...sharedStyle
  //   };

  //   if (moment(event.end).isAfter()) {
  //     return {
  //       style: greenStyle
  //     };
  //   } else if (moment(event.end).isBefore()) {
  //     return {
  //       style: redStyle
  //     };
  //   } else if (moment(event.end).isSame()) {
  //     return {
  //       style: orangeStyle
  //     };
  //   }
  //   // return {
  //   //   style: sharedStyle
  //   // };
  // };

  return (
    <div className="archive-calendar-page">
      <div className="pos-navbar">
        <SharedLayoutBtns />
        <NotificationsProfile />
      </div>
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">{t('Calendar.Calendar')}</h2>
          <p>كشف وعرض كافة الحجوزات</p>
          <p>{t('Calendar.ShowAndDisplayAllReservations')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('AppBar.Products.archivePage.archiveCalendarPage')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.archivePage.title')}
            title3={t('AppBar.Products.archivePage.archiveCalendarPage')}
            link3={'/archive/calendar'}
          />
        </div>
      </div>

      <div className="calendar-wrap">
        <Calendar
          className="calendar-itself"
          selectable
          rtl={true}
          localizer={localizer}
          events={calendarEvents}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 632 }}
          onSelectEvent={handleSelectEvent}
          // eventPropGetter={eventStyleGetter}
        />
      </div>

      {modalOpened && <PageModal />}
      {selectedSaleInvoiceModalOpned && <SelectedSaleInvoiceModal />}
    </div>
  );
};

export default ReservationPage;
