/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import getAllUsersApi from '../apis/settings-apis/users-apis/getAllUsersApi';
import UserContext from '../contexts/user-context/UserProvider';
import checkRes from '../utils/checkRes';
import useCustomApiRequest from './useCustomApiRequest';
const useUsers = (role) => {
  const [allUsers, setAllUsers] = useState(null);
  const { user } = useContext(UserContext);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getAllUsersApi(user?.token, { role }),
        (res) => {
          if (checkRes(res) && res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllUsers(data);
          }
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return allUsers;
};

export default useUsers;
