import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import SettingsWorkPlacesContext from '../../../contexts/settings-contexts/SettingsWorkPlacesProvider';
import { useTranslation } from 'react-i18next';

const PageModal = () => {
  const { modalOpened, setModalOpened, setSelectedPlace, selectedPlace } =
    useContext(SettingsWorkPlacesContext);
  const { t } = useTranslation();
  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        selectedPlace
          ? t('Inventory.WorkPlaces.modifyWorkPlace')
          : t('Inventory.WorkPlaces.addNewWorkPlace')
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedPlace(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedPlace(null);
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default PageModal;
