import React from 'react';
import VariantsComponent from './VariantsComponent';
import VariantsComponentV1 from './VariantsComponentV1';
import VariantsComponentV2 from './VariantsComponentV2';

const RenderFunction = (props) => {
  return (
    <>
      {props.index == 0 && (
        <VariantsComponent
          index={props.index}
          control={props.control}
          setValue={props.setValue}
          watch={props.watch}
          errors={props.errors}
          allVariants={props.allVariants}
          register={props.register}
        />
      )}
      {props.index == 1 && (
        <VariantsComponentV1
          index={props.index}
          control={props.control}
          setValue={props.setValue}
          watch={props.watch}
          errors={props.errors}
          allVariants={props.allVariants}
          register={props.register}
        />
      )}
      {props.index == 2 && (
        <VariantsComponentV2
          index={props.index}
          control={props.control}
          setValue={props.setValue}
          watch={props.watch}
          errors={props.errors}
          allVariants={props.allVariants}
          register={props.register}
        />
      )}
    </>
  );
};

export default RenderFunction;
