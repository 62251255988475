/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-has-content */
import QRCode from 'qrcode.react';
import React, { useContext, useEffect, useRef , useState } from 'react';
import Barcode from 'react-barcode';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
// import useSystemSettings from '../../custom-hooks/useSystemSettings';
import { useTranslation } from 'react-i18next';
import WaterMark from '../../common/water-mark/WaterMark';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import './InvoiceRender.scss';
import InvoiceA4Render from './InvoiceA4Render';
const InvoiceRender = ({ fetchedInvoice }) => {
  const { t,i18n} = useTranslation();
  const history = useHistory();
  const [currentLang, setCurrentLang] = useState(i18n.language);
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  const { systemSettings } = useContext(PosInvoiceContext);
  
  const { barcode, logo, qrcode, tax_num, commercialRecord } =
    fetchedInvoice?.setting?.invoiceSetting;
  const renderProducts = () => {
    return (
      <div className="products-table-total-wrap">
        <table
          className="invoice-products-table"
          style={{
            fontFamily: 'DINNEXTLTARABIC-Regular',
            width: 'calc(100% - 36px)',
            margin: '0 18px',
            padding: 0,
            textAlign:  currentLang == 'ar' ? 'right' : 'left',
            borderCollapse: 'separate',
            borderCpacing: '4px 18px'
          }}
        >
          <thead>
            <tr className="invoice-products-head">
              <th
                style={{
                  minWidth: '118px',
                  padding: '2px',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-name-cell"
              >
                {t('Pos.printInvoice.item')}
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-quantity-cell"
              >
                {t('Pos.printInvoice.quantity')}
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-price-cell"
              >
                {t('Pos.printInvoice.price')}
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-price-cell"
              >
                {t('Pos.printInvoice.DiscountValue')}
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-price-cell"
              >
                {t('Pos.Main.taxPrice')}
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-price-cell"
              >
                {t('Pos.printInvoice.total')}
              </th>
            </tr>
          </thead>
          <tbody>
            {fetchedInvoice?.products?.length > 0 &&
              fetchedInvoice.products.map((p) => renderInoviceProduct(p))}
          </tbody>
        </table>
        {fetchedInvoice.addPrices?.length > 0 &&
          fetchedInvoice.addPrices[0].desc &&
          parseFloat(fetchedInvoice.addPrices[0].price) > 0 && (
            <table
              className="prices-table"
              style={{
                width: 'calc(100% - 36px)',
                margin: '0 18px',
                padding: 0,
                borderTop: '1px dashed #000',
                textAlign: currentLang == 'ar' ? 'right' : 'left',
                borderCollapse: 'separate',
                borderSpacing: '4px 8px',
                color: '#000'
              }}
            >
              <tbody>
                {fetchedInvoice.addPrices.map((extra, index) =>
                  renderInvoiceExtraPrice(extra, index)
                )}
              </tbody>
            </table>
          )}

        <table
          className="prices-table"
          style={{
            width: 'calc(100% - 36px)',
            borderTop: '1px dashed #000',
            margin: 'auto',
            textAlign:  currentLang == 'ar' ? 'right' : 'left',
            borderCollapse: 'separate',
            borderSpacing: '4px 8px',
            color: '#000'
          }}
        >
          <tbody>
            {/* {fetchedInvoice.productPrice > 0 && fetchedInvoice?.type === 1 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.totalPriceOfProducts')} :
                </th>
                <td
                  className="fake-td invoice-table-cell"
                  style={{ padding: '2px', color: '#000', width: '70px' }}
                ></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {fetchedInvoice.productPrice}
                </td>
              </tr>
            )} */}
                {fetchedInvoice?.products?.length > 0 &&
                fetchedInvoice?.type === 1 && (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px 4px',
                      minWidth: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    {t('Pos.printInvoice.productsNum')} :{' '}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px', color: '#000' }}
                    className="invoice-table-cell discount-value"
                  >  
                     { fetchedInvoice?.products.reduce((accumulator, object) => {
                          return accumulator + object.quantity;
                        }, 0)
                      
                    }
                  </td>
                </tr>
              )}
            {fetchedInvoice.discountPrice > 0 && fetchedInvoice?.type === 1 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px 4px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.totalDiscount')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {fetchedInvoice.discountPrice}
                </td>
              </tr>
            )}

            {fetchedInvoice.additionPrice > 0 && fetchedInvoice?.type === 1 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.additionalPrices')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {fetchedInvoice.additionPrice}
                </td>
              </tr>
            )}
            {fetchedInvoice.totalBeforeTax > 0 &&
              fetchedInvoice?.type === 1 && (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px',
                      minWidth: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    {t('Pos.printInvoice.totalBeforeTax')} :{' '}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px' }}
                    className="invoice-table-cell discount-value"
                  >
                    {fetchedInvoice.totalBeforeTax}
                  </td>
                </tr>
              )}
            {fetchedInvoice.taxPrice > 0 && fetchedInvoice?.type === 1 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {` ${t('Pos.printInvoice.totalTax')} ${
                    fetchedInvoice?.setting?.invoiceSetting?.tax > 0
                      ? fetchedInvoice?.setting?.invoiceSetting?.tax
                      : ''
                  }% :`}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {fetchedInvoice.taxPrice}
                </td>
              </tr>
            )}
            {fetchedInvoice.totalPrice > 0 && fetchedInvoice?.type === 1 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.totalPriceAfterTax')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px' }}
                  className="invoice-table-cell discount-value"
                >
                  {fetchedInvoice.totalPrice}
                </td>
              </tr>
            )}
            {fetchedInvoice.returnPrice && fetchedInvoice?.type === 2 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.returnedAmount')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px' }}
                  className="invoice-table-cell discount-value"
                >
                  {fetchedInvoice.returnPrice}
                </td>
              </tr>
            )}
            {fetchedInvoice.totalPrice > 0 && fetchedInvoice?.type === 1 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.paymentMethod')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {fetchedInvoice.paymentType == 1 &&
                    t('purchaseInvoices.cash')}
                  {fetchedInvoice.paymentType == 2 &&
                    t('purchaseInvoices.postpaid')}
                  {fetchedInvoice.paymentType == 3 &&
                    t('purchaseInvoices.visa')}
                  {fetchedInvoice.paymentType == 5 &&
                    t('purchaseInvoices.bankTransfer')}
                  {fetchedInvoice.paymentType == 6 &&
                    t('purchaseInvoices.multiplePayment')}
                </td>
              </tr>
            )}

            {fetchedInvoice.payments?.length > 0 &&
              fetchedInvoice.payments?.map((pay, index) => (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px',
                      width: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    {`${t('Pos.printInvoice.batchNo')} ${index + 1} :`}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px', color: '#000' }}
                    className="invoice-table-cell discount-value"
                  >
                    {pay?.amount}
                  </td>
                </tr>
              ))}
            {/* {fetchedInvoice.paymentType === 2 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  الدفعة الأولى :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {fetchedInvoice.first_paid}
                </td>
              </tr>
            )} */}
            {fetchedInvoice.paymentType === 2 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.residual')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {fetchedInvoice.rest}
                </td>
              </tr>
            )}

            {fetchedInvoice.paymentType === 6 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.cashAmount')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {fetchedInvoice.cash}
                </td>
              </tr>
            )}
            {fetchedInvoice.paymentType === 6 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.visaAmount')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {fetchedInvoice.visa}
                </td>
              </tr>
            )}
            {/* {fetchedInvoice.returnTax &&
              parseFloat(fetchedInvoice.returnTax) > 0 && (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px',
                      width: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    الضريبة المرتجعة :{' '}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px' }}
                    className="invoice-table-cell discount-value"
                  >
                    {fetchedInvoice.returnTax}
                  </td>
                </tr>
              )} */}
          </tbody>
        </table>

        {/* {invoiceTotalPriceAfterTax > 0 && (
          <div className="grand-total-invoice-price">
            <span>المجموع الكلى</span>
            <span className="bold-font">{invoiceTotalPriceAfterTax}</span>
          </div>
        )} */}

        {/* <div className="qr-code-img-wrap">
          <img className="qrcode-img" src={qrCodeImg} alt="qrcode" />
        </div> */}
        {qrcode === 1 && (
          <div
            className="qr-code-img-wrap"
            style={{
              display: 'grid',
              placeItems: 'center',
              padding: '18px',
              paddingTop: '0px',
              marginTop: '8px',
              marginBottom: 8
            }}
          >
            <QRCode
              value={fetchedInvoice?.qrString ? fetchedInvoice.qrString : ''}
            />
            {/* <QRCode
            value={
              fetchedInvoice?.encryptId
                ? `${process.env.REACT_APP_POS_URL}/invoice-details/${String(
                    fetchedInvoice.encryptId
                  )}`
                : ''
            }
          /> */}
          </div>
        )}

        {fetchedInvoice?.setting?.invoiceSetting?.terms && (
          <div
            style={{
              display: 'grid',
              borderTop: '1px dashed rgba(0, 0, 0, 0.1)',
              borderBottom: '1px dashed rgba(0, 0, 0, 0.1)',
              padding: '12px 20px'
            }}
          >
            <div
              style={{
                width: '100%'
              }}
            ></div>
            {fetchedInvoice?.setting?.invoiceSetting?.terms_text
              ?.toString()
              .split('\r\n')
              .map((item) => (
                <p>{item}</p>
              ))}
          </div>
        )}

        <div
          style={{
            textAlign: 'center',
            padding: '0 8px 10px 8px'
          }}
        >
          {/* {systemSettings?.slogan ? systemSettings.slogan : ''} */}
          {fetchedInvoice?.setting?.slogan ? fetchedInvoice.setting.slogan : ''}
        </div>
      </div>
    );
  };

  const renderInoviceProduct = (p) => {
    if (p?.materials?.length > 0) {
      return (
        <tr key={p.id} className="invoice-product-li">
          <td
            style={{ padding: '2px', fontSize: '13px', color: '#000' }}
            className="invoice-table-cell product-name-cell"
          >
            {p?.product?.name ? p.product.name : '----'}
            {p?.materials.map((ing) =>
              ing.name ? (
                <div key={ing.id}>
                  {' '}
                  == ( {t('Pos.printInvoice.add')} {ing.name} )
                </div>
              ) : (
                '---'
              )
            )}
          </td>
          <td
            style={{ padding: '2px', color: '#000' }}
            className="invoice-table-cell product-quantity-cell"
          >
            {p.quantity ? p.quantity : '----'}
            {p.materials?.length > 0 &&
              p.materials.map((ing) =>
                ing.quantity ? <div key={ing.id}>{ing.quantity}</div> : '---'
              )}
          </td>
          <td
            style={{ padding: '2px', color: '#000' }}
            className="invoice-table-cell product-price-cell"
          >
            {p.price ? parseFloat(p.price) : '----'}
            {p.materials.map((ing) =>
              String(ing.priceOfUnit) ? (
                <div key={ing.id}>{parseFloat(ing.priceOfUnit)}</div>
              ) : (
                '---'
              )
            )}
          </td>
          <td
            style={{ padding: '2px', color: '#000' }}
            className="invoice-table-cell product-price-cell"
          >
            {p.priceBeforeDiscount ? p.priceBeforeDiscount : '----'}
            {p.materials?.length > 0 &&
              p.materials.map((ing) =>
                String(ing.price) ? (
                  <div key={ing.id}>{parseFloat(ing.price)}</div>
                ) : (
                  '---'
                )
              )}
          </td>
        </tr>
      );
    }
    return (
      <tr key={p.id} className="invoice-product-li">
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-name-cell"
        >
          {p?.product?.name ? p.product.name : '----'}
        </td>
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-quantity-cell"
        >
           {fetchedInvoice?.type == 2 &&
            p.quantity &&
            p.quantity > 0 &&
            '-'}
          {p.quantity ? p.quantity : '----'}
        </td>
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-price-cell"
        >
          {p.price ? parseFloat(p.price) : '----'}
        </td>
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-price-cell"
        >
          {fetchedInvoice?.type == 2 &&
            p.discountPrice &&
            p.discountPrice > 0 &&
            '-'}
          {p.discountPrice ? p.discountPrice : ''}
        </td>
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-price-cell"
        >
           {fetchedInvoice?.type == 2 &&
            p.totalTax &&
            p.totalTax > 0 &&
            '-'}
          {p.totalTax ? p.totalTax : ''}
        </td>
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-price-cell"
        >
          {fetchedInvoice?.type == 2 &&
            p.totalPrice &&
            p.totalPrice > 0 &&
            '-'}
          {p.totalPrice ? p.totalPrice : ''}
        </td>
      </tr>
    );
  };

  const renderInvoiceExtraPrice = (extraPriceObj, index) => {
    return (
      <tr key={index} className="invoice-product-li">
        <th
          className="invoice-table-cell col-head extra-prices-cell"
          style={{
            fontWeight: 'normal',
            padding: '2px 4px',
            width: '194px',
            color: '#000'
          }}
        >
          {extraPriceObj.desc ? extraPriceObj.desc : ''}
        </th>
        {/* <td className="style={{padding: '2px 4px', , color: '#000', width: '70px'}} fake-td invoice-table-cell">
          ====
        </td> */}
        <td
          style={{ padding: '2px 4px', textAlign: 'center' }}
          className="invoice-table-cell product-quantity-cell extra-prices-cell"
        >
          {extraPriceObj.price ? extraPriceObj.price : ''}
        </td>
      </tr>
    );
  };

  const componentRef = useRef();
  // const a4Ref = useRef();
  const handlePrintReceipt = useReactToPrint({
    onAfterPrint: () => history.push('/pos'),
    content: () => componentRef.current,
    pageStyle: `
    @media print {
      @page {
        size: 200mm auto;
        margin: none;
      }

      .pos-receipt-wrap {
        direction: ${currentLang == 'ar' ? 'rtl' : 'ltr'};
        background-color: #fff;
        border-radius: 10px;
        color: "#000 !important";
        width: 382px;
        margin: 0 auto;
      }

      .store-logo-name-wrap {
        padding-top: 6px;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      .show-note-in-print {
        padding-top: 6px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }

      .store-name {
        margin-top: 2px;
        color: #000;
        font-size: 18px;
        padding: 0 4px;
        text-align: center;
      }

      .number-span {
        padding: 0 4px;
        text-align: center;
      }
      .invoice-number {
        margin-top: 2px;
      }

      .system-info {
        padding-bottom: 4px;
        margin: 4px;
        margin-bpttom: 0px;
        border-bottom: 1px dashed #000;
        gap: 5px 4px;
        color: #000;
        font-size: 15px;
      }
      .models-title {
        direction: rtl;
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .note-title {
        direction: rtl;
        color: rgba(0, 0, 0, 0.9);
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }

      .cell-wrap {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 2px;
      }
      .date-time-wrap {
        display: flex;
        justify-content: space-between;
      }
      .date-time-wrap .cell-wrap  {
        margin-left: 28px;
      }
      .info-cell-value {
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
        text-overflow: ellipsis;
      }

      .info-cell-title {
        font-size: 12px;
      }
    `
  });

  const renderClient = () => {
    const { name, email, phone, address, taxNumber } = fetchedInvoice?.user;
    return (
      <div className="products-table-total-wa">
        <table
          className="prices-table"
          style={{
            width: 'calc(100% - 36px)',
            borderTop: '1px dashed #000',
            margin: 'auto',
            textAlign: currentLang == 'ar' ? 'right' : 'left',
            borderCollapse: 'separate',
            borderSpacing: '4px 8px',
            color: '#000',
            borderBottom: '1px dashed rgb(0, 0, 0)'
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  padding: '2px',
                  minWidth: '182px',
                  fontSize: '15px',
                  color: '#000'
                }}
                className="invoice-table-cell col-head"
              >
                {t('Pos.printInvoice.customerData')}
              </th>
            </tr>
          </thead>
          <tbody>
            {name && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.name')} :
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {name}
                </td>
              </tr>
            )}
            {email && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px 4px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.email')} :
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {email}
                </td>
              </tr>
            )}
            {phone && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.phone')} :
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {phone}
                </td>
              </tr>
            )}
            {address && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.address')} :
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px' }}
                  className="invoice-table-cell discount-value"
                >
                  {address}
                </td>
              </tr>
            )}
            {taxNumber && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.taxNumber')} :
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {taxNumber}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  const renderType = (type) => {
    if (type === 1) {
      return t('Pos.printInvoice.saleInvoice');
    } else if (type === 2) {
      return t('Pos.printInvoice.returnedInvoice');
    }
  };

  useEffect(() => {
    handlePrintReceipt();
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <div className="print-invoice-page">
        <div className="print-buttons-wrap">
          <button className="invoice-print-btn" onClick={handlePrintReceipt}>
            {t('Pos.printInvoice.printReceipt')}
          </button>
          {/* <button className="invoice-print-btn" onClick={handlePrintInvoice}>
            طباعة الفاتورة
          </button> */}
        </div>

        {fetchedInvoice?.setting?.invoiceSetting?.template == 'thermal' && <div ref={componentRef} className="print-pos-whole-receipt-wrap">
          <div
            className="pos-receipt-wrap"
            style={{
              fontFamily: 'DINNEXTLTARABIC-Regular',
              height: 'max-content',
              color: '#000',
              position: 'relative'
            }}
          >
            {systemSettings?.package_id == 1 && (
              <WaterMark text={t('Pos.printInvoice.freeTrial')} fontSize={70} />
            )}

            {/* <p
              style={{
                fontSize: 16,
                color: '#000',
                fontWeight: 'bold',
                textAlign: 'center',
                marginBottom: 6
              }}
            >
              {fetchedInvoice?.setting?.ref_id}
            </p> */}
            <p
              style={{
                fontSize: 16,
                color: '#000',
                fontWeight: 'bold',
                textAlign: 'center',
                marginBottom: 6
              }}
            >
              <span>
                {fetchedInvoice?.user?.taxNumber
                  ? t('Pos.printInvoice.taxInvoice')
                  : t('Pos.printInvoice.simpleTaxInvoice')}
              </span>
              {/* <span> {fetchedInvoice?.setting?.ref_id}</span> */}
            </p>
            <div className="store-logo-name-wrap">
              {logo === 1 && (
                <img
                  src={
                    fetchedInvoice?.setting?.image
                      ? fetchedInvoice?.setting.image
                      : '/assets/imgs/logo/logo-dark.svg'
                  }
                  alt="Logo"
                  style={{ width: '122px' }}
                />
              )}
              <h2 className="store-name">
                {fetchedInvoice?.setting?.name
                  ? fetchedInvoice?.setting.name
                  : ''}
              </h2>
              {/* <div className="store-number">
                رقم الفاتورة
                {fetchedInvoice?.ShowInvoiceNumber
                  ? ` ${fetchedInvoice.ShowInvoiceNumber} `
                  : ''}
              </div> */}

              <div
                className="store-name"
                style={{
                  fontSize: 18
                }}
              >
                {renderType(fetchedInvoice?.type)}
              </div>
              {barcode === 1 && (
                <>
                  <Barcode
                    format="CODE128"
                    value={fetchedInvoice.ShowInvoiceNumber}
                    height={50}
                    fontSize={15}
                    // width={3}
                  />
                </>
              )}
              
            </div>
            <div className="invoice-content">
              

              <div className="invoice-number"></div>

              <div
                className="system-info"
                style={{
                  margin: '4px 18px',
                  paddingBottom: '12px',
                  marginBottom: '0',
                  borderBottom: '1px dashed #000',
                  gap: '12px 4px',
                  color: '#000',
                  fontSize: '15px'
                }}
              >
                {fetchedInvoice?.shift?.pos?.address && (
                  <div className="cell-wrap address-wrap">
                    <span className="info-cell-title">
                      {t('Pos.printInvoice.shopAddress')} :{' '}
                    </span>
                    {fetchedInvoice?.shift?.pos?.address && (
                      <span className="info-cell-value">
                        {fetchedInvoice?.shift?.pos.address}
                      </span>
                    )}
                  </div>
                )}
                <div className="cell-wrap vat-wrap">
                  <span className="info-cell-title">
                    {t('Pos.printInvoice.taxNumbe2')} :{' '}
                  </span>
                  <span className="info-cell-value">
                    {tax_num
                      ? tax_num
                      : ''}
                  </span>
                </div>
                <div className="cell-wrap vat-wrap">
                  <span className="info-cell-title">
                    {t('Pos.printInvoice.commercialRecord')} :{' '}
                  </span>
                  <span className="info-cell-value">
                    {fetchedInvoice?.setting?.commercialRecord
                      ? fetchedInvoice?.setting?.commercialRecord
                      : ''}
                  </span>
                </div>
                <div className="date-time-wrap">
                  <div className="cell-wrap date-wrap">
                    {/* <span className="info-cell-title">التاريخ : </span> */}
                    <span className="info-cell-value">
                      {fetchedInvoice.date ? fetchedInvoice.date : ''}
                    </span>
                  </div>
                  <div className="cell-wrap time-wrap">
                    <span className="info-cell-value">
                      {fetchedInvoice?.time ? fetchedInvoice.time : ''}
                    </span>
                  </div>
                </div>

                {fetchedInvoice?.note && (
                  <div className='show-note-in-print'>
                    <div className="vat-wrap" style={{ textAlign:  currentLang == 'ar' ? 'right' : 'left'}}>
                      <h3 className="info-cell-title">
                        {t('Pos.printInvoice.saleNotes')} :{' '}
                      </h3>
                    </div>
                    <div className="vat-wrap" style={{ textAlign:  currentLang == 'ar' ? 'right' : 'left'}}>
                      <p className="info-cell-value">
                        {fetchedInvoice?.note
                          ?.toString()
                          .split('\r\n')
                          .map((item) => (
                            <p>{item}</p>
                        ))}
                      </p>
                    </div>
                  </div>
                )}
              </div>

              {fetchedInvoice.user && renderClient()}

              {renderProducts()}
            </div>
          </div>
        </div>}
        {fetchedInvoice?.setting?.invoiceSetting?.template == 'A4' && <InvoiceA4Render fetchedInvoice={fetchedInvoice} ref={componentRef} />}
      </div>
    </div>
  );
};

export default InvoiceRender;
