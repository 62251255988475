import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import PageTable from './PageTable';
import PageModal from './PageModal';
import warehouseImg from '../../../assets/imgs/store/store-item-manager.png';
import StoreWelcomeSharedSection from '../../store-pages/store-welcome-shared-section/StoreWelcomeSharedSection';
import SettingsWarehousesContext from '../../../contexts/settings-contexts/SettinsWarehousesProvider';
import TableFilter from './TableFilter';
import './SettingsWarehousePage.scss';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

const SettingsWarehousePage = () => {
  const { modalOpened, setModalOpened, setSelectedWarehouse } = useContext(
    SettingsWarehousesContext
  );
  const { t } = useTranslation();

  return (
    <div className="settings-warhouses-page">
      <div className="page-head-wrap">
        {/* <StoreWelcomeSharedSection
          img={warehouseImg}
          title={t('Inventory.Warehouses.title')}
          subTitle={t('Inventory.Warehouses.subTitle')}
        /> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('AppBar.Products.storePage.settingsWarehousesPage')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.storePage.title')}
            title3={t('AppBar.Products.storePage.settingsWarehousesPage')}
            link3={'/settings/warehouses'}
          />
        </div>
        <div className="search-wrap">
          <SearchNotificationsProfile noSearch />
        </div>
      </div>

      <div className="custom-filter-add-section">
        <div className="add-section">
          {/* <div className="section-text">
            <p className="bold-font">المستودعات</p>
          </div> */}
          <div
            className="add-btn"
            onClick={() => {
              // if selected unit then reset it
              // open the unit modal
              setSelectedWarehouse(null);
              setModalOpened(true);
            }}
          >
            <span className="text-span">
              {t('Inventory.Warehouses.addNewWarehouse')}
            </span>
          </div>
        </div>
        <TableFilter />
      </div>
      <div className="table-wrapper">
        <PageTable />
      </div>

      {modalOpened && <PageModal />}
    </div>
  );
};

export default SettingsWarehousePage;
