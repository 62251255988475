import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import deleteWarehouseApi from '../../../apis/settings-apis/settings-warehouses-apis/deleteWarehouse';
import { Link as RouterLink } from 'react-router-dom';
import settingsRouterLinks from '../../../components/app/settings-routes/settingsRouterLinks';
const tableColumns = (
  allFetchedWarehouses,
  setModalOpened,
  setFetchCount,
  setSelectedWarehouse,
  setIsLoading,
  t,
  token
) => {
  const handleEditClick = (record) => {
    const found = allFetchedWarehouses.find((item) => item.id === record.id);
    if (found) {
      setSelectedWarehouse({ ...found });
      setModalOpened(true);
    }
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteWarehouseApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Inventory.Warehouses.addedSuccessfully'),
          message: t('Inventory.Warehouses.warehousesSuccessfullyDeleted')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Inventory.Warehouses.errorOccurred'),
          message: res?.data?.message
            ? res.data.message
            : t('Inventory.Warehouses.pleaseTryLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Inventory.Warehouses.errorOccurred'),
        message: t('Inventory.Warehouses.pleaseTryLater')
      });
    }
  };
  const renderActions = (obj) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(obj)}>
          <EditIcon />
          {t('Inventory.Warehouses.edit')}
        </div>
        <Popconfirm
          title={t('Inventory.Warehouses.areyouSureToDelete')}
          onConfirm={() => handleDeleteRequest(obj.id)}
          okText={t('Inventory.Warehouses.yes')}
          cancelText={t('Inventory.Warehouses.no')}
        >
          <Tooltip title={t('Inventory.Warehouses.delete')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      title: t('Inventory.Warehouses.warehousCode'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.Warehouses.warehousName'),
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record.name}</span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.Warehouses.workplace'),
      dataIndex: 'workplace',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.workplace?.name || '-----'}
            </span>
          </div>
        );
      }
    },
    // {
    //   title: 'اجمالى كمية المنتجات',
    //   dataIndex: 'totalProductsCount',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         <span className="name-it-self">{record.totalProductsCount}</span>
    //       </div>
    //     );
    //   },
    //   sorter: (a, b) => a.totalProductsCount - b.totalProductsCount
    // },
    // {
    //   title: 'إجمالى سعر المستودع',
    //   dataIndex: 'totalWarehousePrice',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         <span className="name-it-self">{record.totalWarehousePrice}</span>
    //       </div>
    //     );
    //   },
    //   sorter: (a, b) => a.totalWarehousePrice - b.totalWarehousePrice
    // },
    // {
    //   title: 'سعر المبيعات الإجمالى',
    //   dataIndex: 'totalSoldPrice',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         <span className="name-it-self">{record.totalSoldPrice}</span>
    //       </div>
    //     );
    //   },
    //   sorter: (a, b) => a.totalSoldPrice - b.totalSoldPrice
    // },
    {
      title: t('Inventory.Warehouses.warehousePreview'),
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            <Tooltip title={t('Inventory.Warehouses.warehousePreview')}>
              <RouterLink
                to={settingsRouterLinks.settingsWarehousesDetailsPage(
                  record.id
                )}
              >
                <Button
                  className="circled-btn"
                  type="dashed"
                  shape="circle"
                  icon={<EyeOutlined />}
                />
              </RouterLink>
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: t('Inventory.Warehouses.settings'),
      // dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
