import './TechnicalSupportPage.scss';

import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../components/search-notifications-profile/SearchNotificationsProfile';
import TechnicalSupportContext from '../../contexts/technical-support-context/TechnicalSupportContext';
import PageTable from './PageTable';
import PageModal from './PageModal';
import ShowModal from './ShowModal';

const TechnicalSupportPage = () => {
  const { modalOpened, setModalOpened, setSelectedTicket, showModalOpened } =
    useContext(TechnicalSupportContext);
  return (
    <div className="measurement-units-page">
      <div className="page-head-wrap">
        <div className="page-title">
          <h2 className="bold-font">تذاكر الدعم </h2>
          <p>كشف وعرض كافة تذاكر الدعم</p>
        </div>
        <SearchNotificationsProfile />
      </div>

      <div className="add-section">
        {/* <div className="section-text">
          <p className="bold-font">وحدات القياس</p>
        </div> */}
        <div
          className="add-account-btn"
          onClick={() => {
            // if selected unit then reset it
            // open the unit modal
            setSelectedTicket(null);
            setModalOpened(true);
          }}
        >
          <span className="text-span">إضافة تذكرة جديدة</span>
        </div>
      </div>

      <PageTable />

      {modalOpened && <PageModal />}
      {showModalOpened && <ShowModal />}
    </div>
  );
};

export default TechnicalSupportPage;
