import { useState, useEffect, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  fetchedAccountStatementDetails: null,
  setFetchedAccountStatementDetails: (v) => {},
  selectedAccountStatement: null,
  setSelectedAccountStatement: (v) => {},
  singleJournal: null,
  setSingleJournal: (v) => {},
  selectedJournalId: null,
  setSelectedJournalId: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  modalOpenedCreate: false,
  setModalOpenedCreate: (v) => {},
  modalOpenedUpdate: false,
  setModalOpenedUpdate: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  accountsLists: null,
  setAccountsLists : (v) => {},
  accountsListId: null,
  setAccountsListId : (v) => {},
  accountsType: null,
  setAccountsType : (v) => {},
};

const AccountStatementContext = createContext(INITIAL_VALUES);

export const AccountStatementProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [fetchedAccountStatementDetails, setFetchedAccountStatementDetails] = useState(
    INITIAL_VALUES.fetchedAccountStatementDetails
  );
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [modalOpenedCreate, setModalOpenedCreate] = useState(INITIAL_VALUES.modalOpenedCreate);
  const [modalOpenedUpdate, setModalOpenedUpdate] = useState(INITIAL_VALUES.modalOpenedUpdate);
  const [selectedAccountStatement, setSelectedAccountStatement] = useState(
    INITIAL_VALUES.selectedAccountStatement
  );
  const [selectedJournalId, setSelectedJournalId] = useState(
    INITIAL_VALUES.selectedJournalId
  );
  const [singleJournal, setSingleJournal] = useState(
    INITIAL_VALUES.singleJournal
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  const [accountsLists , setAccountsLists] = useState(
    INITIAL_VALUES.accountsLists
  );
  const [accountsListId , setAccountsListId] = useState(
    INITIAL_VALUES.accountsListId
  );
  const [accountsType , setAccountsType] = useState(
    INITIAL_VALUES.accountsType
  );
  // useEffect(() => {
  //   if (journalsTree?.length >= 0) {
  //     setMappedAccountStatementTree({
  //       name: 'شجرة الحســابات',
  //       toggled: true,
  //       fakeLeaf: true,
  //       children: [...journalsTree]
  //     });
  //   }
  // }, [journalsTree]);

  // const allAccountStatementRoles = useAccountStatementRoles();

  // useEffect(() => {
  //   if (allAccountStatementRoles?.length > 0) {
  //     setAccountStatementRoles(allAccountStatementRoles);
  //   }
  // }, [allAccountStatementRoles]);

  return (
    <AccountStatementContext.Provider
      value={{
        // isLoading,
        setIsLoading,
        fetchCount,
        setFetchCount,
        fetchedAccountStatementDetails,
        setFetchedAccountStatementDetails,
        selectedAccountStatement,
        setSelectedAccountStatement,
        singleJournal,
        setSingleJournal,
        selectedJournalId,
        setSelectedJournalId,
        modalOpened,
        setModalOpened,
        modalOpenedCreate,
        setModalOpenedCreate,
        modalOpenedUpdate,
        setModalOpenedUpdate,
        isSubmittingForm,
        setIsSubmittingForm,
        accountsLists,
        setAccountsLists,
        accountsType, 
        setAccountsType,
        accountsListId,
        setAccountsListId
      }}
    >
      {children}
    </AccountStatementContext.Provider>
  );
};

export default AccountStatementContext;
