import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getAllSummaryApi from '../../../apis/accounting-apis/accounting-details-apis/getAllSummaryApi';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import SummaryContext from '../../../contexts/accounting-context/SummaryContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import SummaryDetailsTable from './SummaryDetailsTable';
import TableFilter from './TableFilter';
import './summary.scss';
const SummaryPage = () => {
  const { user } = useContext(UserContext);
  const {
    modalOpened,
    apiLoading,
    setAllSummary,
    setIsLoading,
    fetchCount,
    tableFilter,
    modalOpenedCreate,
    setModalOpenedCreate
  } = useContext(SummaryContext);
  const { t } = useTranslation();

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllSummaryApi(user?.token, {
          ...tableFilter
        }),
        (res) => {
          if (res?.data?.data) {
            setIsLoading(false);
            setAllSummary(res?.data?.data);
          } else {
            setIsLoading(false);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }
    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  return (
    <div className="accounting-page summary-page">
      <div className="page-head-wrap">
        {/* <div
          className="page-title"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 32
          }}
        >
          <div>
            <h2 className="bold-font">{t('FinancialTerms.TrialBalance')}</h2>
            <p>{t('FinancialTerms.ShowAndDisplayBalanceSheet')}</p>
          </div>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('FinancialTerms.TrialBalance')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.accountingPage.title')}
            title3={t('FinancialTerms.TrialBalance')}
            link3={'/accounting/summary'}
          />
        </div>
        <SearchNotificationsProfile />
      </div>
      <TableFilter />
      <SummaryDetailsTable />
      {/* <AccountingTree /> */}
      {/* {<TransactionsDetailsPage />} */}

      {/* {modalOpened && <TransactionsDetailsModal />}
        {modalOpenedCreate && <TransactionsCreateModal />} */}
    </div>
  );
};

export default SummaryPage;
