import { Button, Modal, Select, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import getAllClientsApi from '../../apis/pos-api/clients-pos-apis/getAllClientsApi';
import ArrowDownIcon from '../../common/icons/ArrowDownIcon';
import ClientsPosContext from '../../contexts/clients-context-pos/ClientsPosContext';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import TableForm from './TableForm';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;
const { Option } = Select;

const PagePosModal = () => {
  const { t } = useTranslation();
  const {
    modalOpened,
    setModalOpened,
    setSelectedClient,
    selectedClient,
    selectedClientId,
    allFetchedClients,
    setSelectedClientId
  } = useContext(ClientsPosContext);
  const { user } = useContext(UserContext);
  const handleClinetChange = (v) => {
    // const foundClient =
    //   allFetchedClients?.length > 0 && allFetchedClients.find((c) => c.id == v);
    // if (foundClient) setSelectedClient(foundClient);
    setSelectedClientId(v);
  };
  const customApiRequest = useCustomApiRequest();
  const [allClients, setAllClients] = useState();

  useEffect(() => {
    if (modalOpened) {
      fetchClients();
      setSelectedClientId('');
    }
  }, [modalOpened]);

  const fetchClients = async () => {
    try {
      const clients = await getAllClientsApi(
        user?.token || user?.employee?.token
      );
      if (
        clients.data.message === t('Pos.Main.operationAccomplishedSuccessfully')
      ) {
        setAllClients(clients.data.data);
      }
    } catch (error) {
      
    }
  };
  return (
    <Modal
      transitionName=""
      className="settings-users-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        selectedClient
          ? t('Pos.Main.modifyCustomerData')
          : t('Pos.Main.addCustomer')
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedClient(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedClient(null);
      }}
      footer={false}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab={t('Pos.Main.choosePreviousCustomer')} key="1">
          <div className="custom-shared-form">
            <div className="form-body" style={{ margin: '0 12px' }}>
              <div className="select-label-wrap client-select-wrap">
                <p className="label-p">{t('Pos.Main.chooseCustomer')}</p>
                <div className="custom-select-wrap without-icon">
                  <>
                    <Select
                      // onClear={hanldeClear}
                      onChange={(v) => handleClinetChange(v)}
                      size="large"
                      suffixIcon={<ArrowDownIcon />}
                      value={selectedClientId}
                      // allowClear
                      // getPopupContainer={() =>
                      //   // document.querySelector('.cats-sub-cats-wrap')
                      //   document.querySelector('.settings-users-modal')
                      // }
                      showSearch={true}
                      filterOption={(input, option) => {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {allClients?.length > 0 &&
                        allClients.map((op, index) => (
                          <Option key={index} value={String(op.id)}>
                            {op.name}
                          </Option>
                        ))}
                    </Select>
                  </>
                </div>
              </div>
              <Button
                className="submit-btn"
                type="primary"
                onClick={() => setModalOpened(false)}
              >
                {t('Pos.Main.choose')}
              </Button>
            </div>
          </div>
        </TabPane>
        <TabPane
          tab={
            selectedClient
              ? t('Pos.Main.modifyCustomerData')
              : t('Pos.Main.addNewCustomer')
          }
          key="2"
        >
          <TableForm />
        </TabPane>
      </Tabs>
    </Modal>
  );
};

export default PagePosModal;
