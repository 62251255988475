import axios from 'axios';

const createNewInventoryApi = async (token, values) => {
  const formData = new FormData();
  formData.append('stock_id', values.stock_id);
  formData.append('model_id', values.model_id);
  formData.append('quantity', values.quantity);
  try {
    const url = '/storeInventory/create';
    const res = await axios.post(url, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default createNewInventoryApi;
