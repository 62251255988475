import axios from 'axios';

const getAllProductsWithSelect = async (token, filterValues , is_buy =false) => {
  try {
    let url = `/Product/all?is_select=1&stock_id=${
      filterValues?.stock_id ? filterValues?.stock_id : ''
    }&is_buy=${is_buy}`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllProductsWithSelect;
