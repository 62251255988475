/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { FieldTimeOutlined } from '@ant-design/icons';
import createActivityDetector from 'activity-detector';
import StopwatchContext from '../../contexts/stopwatch-context/StopwatchContext';
import useSignOut from '../../custom-hooks/useSignout';

const ShiftStopwatch = ({ loadingSignOut, setLoadingSignOut }) => {
  const { stopwatchHours, stopwtachMinutes, stopwtachSeconds } =
    useContext(StopwatchContext);
  const stopwatchOffset = new Date();
  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + stopwtachSeconds);
  stopwatchOffset.setMinutes(stopwatchOffset.getMinutes() + stopwtachMinutes);
  stopwatchOffset.setHours(stopwatchOffset.getHours() + stopwatchHours);

  const { seconds, minutes, hours } = useStopwatch({
    autoStart: true,
    offsetTimestamp: stopwatchOffset
    // offsetTimestamp: diff.toDate()
  });

  const { setStopwatchHours, setStopwatchMinutes, setStopwatchSeconds } =
    useContext(StopwatchContext);
  React.useEffect(() => {
    return () => {
      setStopwatchHours(hours);
      setStopwatchMinutes(minutes);
      setStopwatchSeconds(seconds);
    };
  }, []);

  const { signMeOut } = useSignOut();
  const handleSignOut = () => {
    signMeOut();
  };

  React.useEffect(() => {
    const activityDetector = createActivityDetector({
      timeToIdle: 600000 * 3, // wait 30mints of inactivity to consider the user is idle
      // timeToIdle: 300000, // wait 5mints of inactivity to consider the user is idle
      autoInit: true, // I don't want to start the activity detector yet.
      inactivityEvents: [],
      ignoredEventsWhenIdle: []
    });
    activityDetector.on('idle', () => {
      // await sleep(600000);
      handleSignOut();
      // setTimeout(handleSignOut, expiryTimestamp);
    });
    return () => {
      activityDetector.stop();
    };
  }, []);
  return (
    <div className="shift-timer-wrap">
      <FieldTimeOutlined />
      <div className="time-wrap">
        <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
      </div>
      {/* <button onClick={start}>Start</button>
      <button onClick={pause}>Pause</button> */}
    </div>
  );
};

export default ShiftStopwatch;
