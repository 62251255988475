import React, { useContext } from 'react';
import searchAllClientsApi from '../../apis/clients-apis/searchAllClientsApi';
// import { Select } from 'antd';
import SearchIcon from '../../common/icons/SearchIcon';
import ClientsContext from '../../contexts/clients-context/ClientsContext';
import UserContext from '../../contexts/user-context/UserProvider';
// import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import checkRes from '../../utils/checkRes';
import PageModal from './PageModal';
import './TableFilter.scss';
import { useTranslation } from 'react-i18next';

const TableFilter = () => {
  // const { Option } = Select;
  const {
    // setNameSearchValue,
    modalOpened,
    setModalOpened,
    setSelectedClient,
    tablePagination,
    setTablePagination,
    setIsLoading,
    setAllFetchedClients
  } = useContext(ClientsContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const handleTableSearch = async (e) => {
    try {
      setIsLoading(true);
      let res = await searchAllClientsApi(user?.token, {
        page: tablePagination.current_page,
        searchText: e.target.value
      });
      if (res) {
        setIsLoading(false);
        if (checkRes(res) && res?.data?.data?.data?.length >= 0) {
          const data = res.data.data.data;
          setAllFetchedClients(data);
        }
        if (res?.data?.data?.pagination) {
          setTablePagination(res.data.data.pagination);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  return (
    <div className="custom-filter-add-section">
      <div className="add-section">
        <div
          className="add-btn"
          onClick={() => {
            // if selected supplier then reset it
            // open the supplier modal
            setSelectedClient(null);
            setModalOpened(true);
          }}
        >
          <span className="text-span">{t('ShopInvoice.AddaQuote')}</span>
        </div>
      </div>

      <div className="custom-table-fitler">
        <div className="form-excel-export-wrap">
          <form className="filter-form">
            <div className="table-search">
              <label>
                <SearchIcon />
                <input
                  placeholder={t('ShopInvoice.ClientName')}
                  type="text"
                  name="table_search"
                  onKeyUp={handleTableSearch}
                />
              </label>
            </div>
          </form>
          {/* <div className="excel-btn-wrap">
            <a
              href={`${process.env.REACT_APP_BASE_URL}/Excel/exportUsers?client_id=${systemSettings?.id}`}
              download
              className="excel-export-btn"
            >
              <img
                style={{ height: '42px' }}
                src={excelImg}
                alt="export to excel"
              />
            </a>
          </div> */}
        </div>
      </div>
      {modalOpened && <PageModal />}
    </div>
  );
};

export default TableFilter;
