import React, { useState, useContext } from 'react';
import UpgradeContext from '../../contexts/upgrade-context/UpgradeContext';
import img1 from '../../assets/imgs/upgrade/1.png';
import img2 from '../../assets/imgs/upgrade/2.png';
import img3 from '../../assets/imgs/upgrade/3.png';
import img4 from '../../assets/imgs/upgrade/4.png';
import img5 from '../../assets/imgs/upgrade/5.png';
import paytabsImg from '../../assets/imgs/upgrade/paytabs.png';
import stcpayImg from '../../assets/imgs/upgrade/stcpay.png';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import upgradeUserPackageApi from '../../apis/upgrade/upgradeUserApi';
import UserContext from '../../contexts/user-context/UserProvider';
import errorNotification from '../../utils/errorNotification';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import DocTitleScrollTop from '../../utils/DocTitleScrollTop';
// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
import { useTranslation } from 'react-i18next';
const PaymentSelection = () => {
  DocTitleScrollTop();
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const {
    selectedPackage,
    paymentVisible,
    setPaymentVisible,
    selectedPaymentType,
    setSelectedPaymentType,
    checkCodeRes,
    // posNum,
    discountPrice,
    total
  } = useContext(UpgradeContext);
  const customApiRequest = useCustomApiRequest();
  const [isLoading, setIsLoading] = useState(false);

  const successCallback = async (res) => {
    if (res?.data?.status === 1) {
      // await sleep(2000);
      setIsLoading(false);
      window.location.href = res?.data?.data?.redirect_url;
    } else {
      setIsLoading(false);
      errorNotification({
        title: t('FatoorahPackages.errorOccurred'),
        message: res?.data?.message
          ? res.data.message
          : t('FatoorahPackages.tryLater')
      });
    }
  };
  const errorCallback = (error) => {
    setIsLoading(false);
  };

  const handleSubmitOrder = async () => {
    if (
      user?.ref_key &&
      selectedPackage?.id &&
      // posNum >= 0 &&
      total &&
      selectedPaymentType
    ) {
      setIsLoading(true);
      customApiRequest(
        upgradeUserPackageApi({
          ref_key: user?.ref_key,
          package_id: selectedPackage?.id,
          num_pos: 0,
          // code_id: checkCodeRes?.id || '',
          user_id: checkCodeRes?.id || '',
          discount_cost: discountPrice,
          total
        }),
        successCallback,
        errorCallback
      );
    } else {
      errorNotification({
        title: t('FatoorahPackages.errorOccurred'),
        message: t('SystemSettings.ChoosePayment')
      });
    }
  };

  if (paymentVisible)
    return (
      <div className="payment-selection">
        <div
          className="paytabs-section"
          onClick={() => setSelectedPaymentType('paytabs')}
        >
          <div
            className={`circle-div-wrap ${
              selectedPaymentType === 'paytabs' ? 'selected' : ''
            }`}
          >
            <div className="circle-span"></div>
          </div>
          <div className="gateway-img">
            <img src={paytabsImg} alt="paytabs" />
            <div className="gateway-imgs">
              <img src={img1} alt="img1" />
              <img src={img2} alt="img2" />
              <img src={img3} alt="img3" />
              <img src={img4} alt="img4" />
              <img src={img5} alt="img5" />
            </div>
          </div>
        </div>

        <div
          className="stcpay-section"
          // onClick={() => setSelectedPaymentType('stcpay')}
        >
          <div
            className={`circle-div-wrap ${
              selectedPaymentType === 'stcpay' ? 'selected' : ''
            }`}
          >
            <div className="circle-span"></div>
          </div>
          <div className="gateway-img">
            <img src={stcpayImg} alt="paytabs" />
          </div>
          <div className="gateway-imgs"></div>
        </div>

        <div className="continue-back-btns-wrap">
          <div
            className="continue-btn"
            onClick={() => {
              handleSubmitOrder();
            }}
          >
            {t('FatoorahPackages.followProcess')}
          </div>
          <div
            className="back-btn"
            onClick={() => {
              if (selectedPackage) {
                setPaymentVisible(false);
              }
            }}
          >
            {t('FatoorahPackages.previousStep')}
          </div>
        </div>

        {isLoading && <LoadingModal />}
      </div>
    );
  return null;
};

export default PaymentSelection;
