import React from 'react';
import CalendarIcon from '../../../common/icons/CalendarIcon';
import { DatePicker, Select } from 'antd';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useState } from 'react';
import './TableFilter.scss';
import { useEffect } from 'react';
import getAccountsListLeafApi from '../../../apis/accounting-apis/accounting-details-apis/getAccountsListLeafApi';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import UserContext from '../../../contexts/user-context/UserProvider';
import BalanceSheetContext from '../../../contexts/accounting-context/BalanceSheetContext';
import checkRes from '../../../utils/checkRes';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import getBalanceSheetDataApi from '../../../apis/accounting-apis/accounting-details-apis/getBalanceSheetDataApi';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import { Field } from 'formik';
import getAccountStatementDataApi from '../../../apis/accounting-apis/accounting-details-apis/getAccountStatementDataApi';
import AccountStatementContext from '../../../contexts/accounting-context/AccountStatementContext';

const TableFilter = () => {
  const { user } = useContext(UserContext);
  const {
    setApiLoading,
    setIsLoading,
    setFetchedAccountStatementDetails,
    setModalOpenedCreate,
    accountsLists,
    setAccountsLists,
    accountsType,
    setAccountsType,
    setFetchCount,
    accountsListId,
    setAccountsListId
  } = useContext(AccountStatementContext);

  const [schema, setSchema] = useState();
  // const {accountsType , setAccountsType} = useState()
  // const {accountsListId,setAccountsListId} = useState()
  const { pickerValue, setPickerValue } = useState();
  const { RangePicker } = DatePicker;
  const [selectedPicker, setSelectedPicker] = React.useState(null);
  const customApiRequest = useCustomApiRequest();
  const handlePickerChange = (value, string) => {
    
    
    setSelectedPicker({
      moments: value,
      strings: string
    });
  };
  React.useEffect(() => {
    if (selectedPicker?.strings?.length > 0) {
      setSelectedPicker((prev) => ({
        ...prev,
        from: selectedPicker.strings[0],
        to: selectedPicker.strings[1]
      }));
    }
  }, [selectedPicker?.strings]);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  });

  useEffect(() => {
    setSchema(
      Yup.object().shape({
        account_id: Yup.string().required('ادخل اسم الحساب'),
        type: Yup.string().required('ادخل نوع الحساب')
      })
    );
  }, []);

  const handleShowData = (e) => {
    e.preventDefault();
    // setApiLoading(true);
    let api, successMessage, errorMessage;
    successMessage = 'تم عرض تفاصيل الحساب بنجاح';
    errorMessage = 'حدث خطأ أثناء عرض تفاصيل الحساب';

    api = getAccountStatementDataApi(user?.token, {
      accountsListId: accountsListId,
      accountsType: accountsType,
      selectedPicker: selectedPicker
    });
    customApiRequest(
      api,
      (res) => {
        if (checkRes(res)) {
          setFetchCount((prevState) => prevState + 1);
          setFetchedAccountStatementDetails(res.data.data.transactions);
          successNotification({
            title: 'العملية تمت بنجاح',
            message: successMessage
          });
        } else {
          setApiLoading(false);
          errorNotification({
            title: 'حدث خطأ',
            message: res?.data?.message || errorMessage
          });
        }
      },
      (error) => {
        setIsLoading(false);
        // setApiLoading(false);
        errorNotification({
          title: 'حدث خطأ',
          message: error?.response?.data?.message || 'حاول في وقت لاحق'
        });
      }
    );
  };

  const handleChangeAcc = (value) => {
    setAccountsListId(value);
  };
  const handleChangeType = (value) => {
    setAccountsType(value);
  };

  return (
    <div className="account-statement-filter-form">
      <h2>بيان الحساب</h2>
      <form onSubmit={handleShowData} className="account-statement-form">
        <div className="select-label-wrap">
          <div className="custom-select-wrap without-icon">
            <Select
              name="account_id"
              as="select"
              placeholder="إســم الحساب"
              onChange={(e) => handleChangeAcc(e)}
              defaultActiveFirstOption="true"
              // defaultValue="كل الحركات الماليه"
            >
              {accountsLists?.length &&
                accountsLists?.map((acc) => (
                  <Select.Option value={acc.id}>{acc.holder}</Select.Option>
                ))}
            </Select>
            {/* <AntdSelectOption
                            name='account_id'
                            errorMsg={errors?.account_id && errors.account_id.message}
                            validateStatus={errors?.account_id ? 'error' : ''}
                            control={control}
                            setValue={setValue}
                            placeholder="إســم الحساب"
                            onChange={e=>handleChangeAcc(e)}
                            options={
                                accountsLists?.length && accountsLists?.map((acc) => {
                                return (
                                    {
                                        title: `${acc.holder}`,
                                        value: `${acc.id}`,
                                    }
                                )
                                })
                            }
                            formClassName="accounting-form"
                        /> */}
          </div>
        </div>
        <div className="statement-select-label-wrap">
          <div className="Journals-custom-select-wrap">
            <Select
              name="type"
              as="select"
              placeholder="اختر النوع"
              onChange={(e) => handleChangeType(e)}
              // defaultValue="كل الحركات الماليه"
            >
              <Select.Option value="" selected hidden>
                النوع
              </Select.Option>
              <Select.Option value="all">كل الحركات الماليه</Select.Option>
              <Select.Option value="credit">الـدائـن</Select.Option>
              <Select.Option value="debit">الـمـديـن</Select.Option>
            </Select>
          </div>
        </div>
        <div className="range-picker-wrap">
          <RangePicker
            value={selectedPicker?.moments && selectedPicker.moments}
            onChange={handlePickerChange}
            // format="YYYY-MM-DD"
            className={`filter-range-picker`}
            allowClear={true}
            suffixIcon={<CalendarIcon />}
            placeholder={['تاريخ من', 'تاريخ الى']}
          />
        </div>
        <button className="filter-submit-btn" type="submit">
          عــرض
        </button>
      </form>
    </div>
  );
};

export default TableFilter;
