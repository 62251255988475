import { Modal } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ArchiveDocsContext from '../../../contexts/archive-contexts/ArchiveDocsProvider';
import TableForm from './TableForm';

const SettingsWorkPlaceModal = () => {
  const { modalOpened, setModalOpened, setSelectedDoc, selectedDoc } =
    useContext(ArchiveDocsContext);
  const { t } = useTranslation();

  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        selectedDoc
          ? t('Documents.editDocument')
          : t('Documents.AddNewDocument')
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedDoc(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedDoc(null);
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default SettingsWorkPlaceModal;
