/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button, DatePicker } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import SettingsWarehousesContext from '../../../contexts/settings-contexts/SettinsWarehousesProvider';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import addNewWarehouseApi from '../../../apis/settings-apis/settings-warehouses-apis/addNewWarehouse';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import useWorkPlaces from '../../../custom-hooks/useWorkPlaces';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useTranslation } from 'react-i18next';

const TableForm = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const schema = Yup.object().shape({
    warehouse_name: Yup.string()
      .required(t('Inventory.Warehouses.enterWarehouseName'))
      .max('100', t('Inventory.Products.maxProductNameLength')),
    warehouse_work_place: Yup.string().required(
      t('Inventory.Warehouses.chooseWorkplaces')
    ),
    warehouse_description: Yup.string().max(
      '250',
      t('Inventory.Products.maxProductNameLength')
    )
  });
  const {
    setModalOpened,
    setIsLoading,
    setFetchCount,
    selectedWarehouse,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(SettingsWarehousesContext);
  const allWorkPlaces = useWorkPlaces();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      warehouse_name: '',
      warehouse_work_place: '',
      warehouse_description: ''
    }
  });

  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    setIsSubmittingForm(true);
    if (selectedWarehouse) {
      customApiRequest(
        addNewWarehouseApi(
          user?.token,
          {
            ...data,
            stock_id: selectedWarehouse.id
          },
          true
        ),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Inventory.Warehouses.addedSuccessfully'),
              message: t('Inventory.Warehouses.warehousModifiedSuccessfully')
            });
          } else {
            errorNotification({
              title: t('Inventory.Warehouses.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Inventory.Warehouses.pleaseTryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('Inventory.Warehouses.errorOccurred'),
            message:
              error?.response?.data?.message ||
              t('Inventory.Warehouses.pleaseTryLater')
          });
        }
      );
    } else {
      customApiRequest(
        addNewWarehouseApi(user?.token, data),

        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Inventory.Warehouses.addedSuccessfully'),
              message: t('Inventory.Warehouses.warehousAddedSuccessfully')
            });
          } else {
            errorNotification({
              title: t('Inventory.Warehouses.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Inventory.Warehouses.pleaseTryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('Inventory.Warehouses.errorOccurred'),
            message:
              error?.response?.data?.message ||
              t('Inventory.Warehouses.pleaseTryLater')
          });
        }
      );
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedWarehouse) {
      setValue(
        'warehouse_name',
        selectedWarehouse?.name ? selectedWarehouse.name : ''
      );
      setValue(
        'warehouse_work_place',
        selectedWarehouse?.workPlace
          ? String(selectedWarehouse.workPlace.id)
          : ''
      );
      setValue(
        'warehouse_description',
        selectedWarehouse?.description ? selectedWarehouse.description : ''
      );
    }
  }, [selectedWarehouse, selectedWarehouse?.id]);

  const [form] = Form.useForm();
  return (
    <Form
      className="settings-warehouse-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.Warehouses.warehouseName')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="warehouse_name"
              type="text"
              placeholder={t('Inventory.Warehouses.warehouseNamePlaceholder')}
              errorMsg={errors?.warehouse_name?.message}
              validateStatus={errors?.warehouse_name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="select-label-wrap">
          <p className="label-p">
            {t('Inventory.Warehouses.warehouseWorkplace')}
          </p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="warehouse_work_place"
              errorMsg={errors?.warehouse_work_place?.message}
              validateStatus={errors?.warehouse_work_place ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder={t('Inventory.Warehouses.warehouseWorkplace')}
              options={
                allWorkPlaces?.length > 0
                  ? allWorkPlaces.map((p) => ({
                      title: p?.name,
                      value: p?.id
                    }))
                  : []
              }
              showSearch={true}
              // filterOption={(input, option) =>
              //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              // }
                                filterOption={(input, option) =>
                option?.children?.props?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}
              formClassName="settings-warehouse-form"
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">
            {t('Inventory.Warehouses.warehouseDetails')}
          </p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="warehouse_description"
              rows={8}
              placeholder={t(
                'Inventory.Warehouses.warehouseDetailsPlaceholder'
              )}
              errorMsg={errors?.warehouse_description?.message}
              validateStatus={errors?.warehouse_description ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {t('Inventory.Warehouses.add')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
