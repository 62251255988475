import React, { useContext } from 'react';
import { Descriptions, Modal } from 'antd';
import ArchiveCalendarContext from '../../../contexts/archive-contexts/ArchiveCalendarProvider';

const PageModal = () => {
  const { modalOpened, setModalOpened, setSelectedCalendar, selectedCalendar } =
    useContext(ArchiveCalendarContext);

  const renderModalContent = () => {
    return (
      <Descriptions bordered column={1}>
        {selectedCalendar.id && (
          <Descriptions.Item label="كود الفاتورة">
            {selectedCalendar.id}
          </Descriptions.Item>
        )}
        {selectedCalendar.name && (
          <Descriptions.Item label="إســـم العميل">
            {selectedCalendar.name}
          </Descriptions.Item>
        )}
        {selectedCalendar.phone && (
          <Descriptions.Item label="رقم الهاتف : ">
            {selectedCalendar.phone}
          </Descriptions.Item>
        )}
        {selectedCalendar.address && (
          <Descriptions.Item label="العنوان : ">
            {selectedCalendar.address}
          </Descriptions.Item>
        )}
        {selectedCalendar.dueDate && (
          <Descriptions.Item label="تاريخ الفاتورة : ">
            {selectedCalendar.dueDate}
          </Descriptions.Item>
        )}
        {selectedCalendar.totalPrice && (
          <Descriptions.Item label="السعر الإجمالى للفاتورة : ">
            {selectedCalendar.totalPrice}
          </Descriptions.Item>
        )}
      </Descriptions>
    );
  };

  return (
    <Modal
      transitionName=""
      className="shared-custom-modal"
      width="90%"
      style={{ maxWidth: '532px' }}
      title="تفاصيل الحجز"
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedCalendar(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedCalendar(null);
      }}
      footer={false}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default PageModal;
