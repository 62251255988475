import axios from 'axios';

const getAllShiftReportsApi = async (token, filterValues,client_id="") => {
  try {
    const url = `/Shift/filter?client_id=${client_id}`;

    const formData = new FormData();
    // Append filterValues to FormData
    Object.keys(filterValues).forEach(key => {
      formData.append(key, filterValues[key]);
    });

    const res = await axios.post(
      url,
      formData, // Use formData as the data payload
      {
        headers: {
          Accept: 'application/json',
          'Content-Type'
          : 'multipart/form-data', // Set Content-Type to multipart/form-data
          lang: 'ar',
          Authorization: 'Bearer ' + token
        }
      }
    );

    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllShiftReportsApi;
