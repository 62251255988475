/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Table, Empty } from 'antd';
import UserContext from '../../../contexts/user-context/UserProvider';
import TableColumns from './TableColumns';
import TransactionsContext from '../../../contexts/accounting-context/TransactionsContext';
import { useTranslation } from 'react-i18next';


const TransactionsDetailsTable = ({ type }) => {
  const { user } = useContext(UserContext);
  const {
    fetchedTransactionsDetails,
    fetchCount,
    setIsLoading,
    setFetchCount,
    setModalOpened,
    setSelectedTransactions,
    isLoading
  } = useContext(TransactionsContext);
  const { t } = useTranslation();

  // map all data to dataSource
  const [tableDataSource, setTableDataSource] = useState([]);
  // const mapDataSource = (data) => {
  //   return data.map((obj, index) => ({
  //     ...obj,
  //     key: obj.id,
  //     index: index + 1,
  //     name: obj?.name ? obj.name : '',
  //     description: obj?.name ? obj.name : '',
  //     title: obj?.title ? obj.title : '',
  //     the_amount : obj?.balance ? obj.balance : (obj.initial_balance ? obj.initial_balance : "")
  //   }));
  // };

  useEffect(() => {
    let isMounted = true;


    // if (type === "types") {
    //   if (fetchedJournalsDetails?.subtypes.length >= 0) {
    //     const mappedData = mapDataSource(fetchedJournalsDetails?.subtypes);
    //     if (isMounted) {
    //       setTableDataSource(mappedData);
    //     }
    //   }
    // }
    // else if (type === "subtypes") {
    //   if (fetchedJournalsDetails?.accounts?.length >= 0) {
    //     const mappedData = mapDataSource(fetchedJournalsDetails?.accounts);
    //     if (isMounted) {
    //       setTableDataSource(mappedData);
    //     }
    //   }
    // }
    // else if (type === "account") {
    //   if (fetchedJournalsDetails?.transactions?.length >= 0) {
    //     const mappedData = mapDataSource(fetchedJournalsDetails?.transactions);
    //     if (isMounted) {
    //       setTableDataSource(mappedData);
    //     }
    //   }
    // }
    // else if (type === "company") {
    //   if (fetchedJournalsDetails?.types?.length >= 0) {
    //     const mappedData = mapDataSource(fetchedJournalsDetails?.types);
    //     if (isMounted) {
    //       setTableDataSource(mappedData);
    //     }
    //   }
    // }
    if (isMounted) {
      setTableDataSource(fetchedTransactionsDetails)
    }
    return () => {
      isMounted = false;
    };
  }, [fetchCount, fetchedTransactionsDetails]);

  return (
    <Table
      locale={{
        emptyText: <Empty description={false}>{t("Messages.noDataAvailable")}</Empty>
      }}
      pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
      className="antd-custom-table"
      dataSource={tableDataSource && tableDataSource}
      columns={TableColumns(
        fetchedTransactionsDetails,
        setModalOpened,
        setFetchCount,
        setSelectedTransactions,
        setIsLoading,
        user?.token,
        type
      )}
      loading={isLoading}
      // scroll={{ x: 400 }}
      footer={false}
    />
  );
};

export default TransactionsDetailsTable;
