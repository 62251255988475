import React, { useContext } from 'react';
import { Modal } from 'antd';
import TagsContext from '../../contexts/nfcTags-context/tagsContext';
import QRcode from './QRcode';

const QRModal = () => {
  const { setQrCodeModalOpened, qrCodeModalOpened } = useContext(TagsContext);
  return (
    <Modal
      className="QR-modal"
      width="70%"
      title=""
      style={{
        width: '100%',
        maxWidth: '700px',
        height: '100%',
        minHeight: '500px',
        padding: '0',
        borderRadius: '30px',
        overflow: 'hidden'
      }}
      visible={qrCodeModalOpened}
      onOk={() => {
        setQrCodeModalOpened(false);
      }}
      onCancel={() => {
        setQrCodeModalOpened(false);
      }}
      footer={false}
    >
      <QRcode />
    </Modal>
  );
};

export default QRModal;
