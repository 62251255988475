/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import PosInvoice from './PosInvoice';
import { WarningOutlined } from '@ant-design/icons';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import RenderProducts from './RenderProducts';
import InvoiceExtraPrices from './InvoiceExtraPrices';
import { useEffect } from 'react';
import './PosStepTwo.scss';
import { useTranslation } from 'react-i18next';
import PosStepOne from './PosStepOne';

const PosStepTwo = () => {
  const { t } = useTranslation();
  const {
    selectedProducts,
    setInvoiceDiscountValue,
    invoiceTotalPriceAfterTax,
    resetPosStepOne,
    allProducts
  } = useContext(PosInvoiceContext);
  // const { step, setStep } = useContext(ReactHookFormContext);
  // useEffect(() => {
  //   if (selectedInvoice) {
  //     if (!selectedProducts || selectedProducts?.length === 0) {
  //       setStep(0);
  //     }
  //   }
  // }, [selectedInvoice, selectedProducts]);
  // const handleDiscountRadioChange = (e) => {
  //   setInvoiceDiscountType(e.target.value);
  //   setInvoiceDiscountValue(0);
  // };

  // const handleInvoiceDiscountValue = (e) => {
  //   if (e.target.value.match(/^(\d+)?(\.\d+)?$/)) {
  //     setInvoiceDiscountValue(e.target.value);
  //   }
  // };

  const renderProducts = () => {
    // if (loadingProducts && (!allProducts || allProducts?.length === 0)) {
    if (selectedProducts?.length > 0) {
      return <RenderProducts step={2} products={selectedProducts} />;
    } else if (resetPosStepOne) {
      return <RenderProducts step={1} products={allProducts} />;
    }
    // } else {
    //   return <PosStepOne />;
    // }
    return (
      <div
        style={{
          padding: 32,
          display: 'grid',
          placeItems: 'center',
          minHeight: 142
        }}
      >
        <p
          style={{
            backgroundColor: '#fcebb8',
            padding: '12px 28px',
            borderRadius: '8px',
            border: '1px solid #ffc107',
            fontSize: 18
          }}
        >
          <WarningOutlined /> {t('Pos.Main.noProductsAvailable')}
        </p>
      </div>
    );
  };

  useEffect(() => {
    if (parseFloat(invoiceTotalPriceAfterTax) === 0) {
      setInvoiceDiscountValue(0);
    }
  }, [invoiceTotalPriceAfterTax]);

  return (
    <div className="stepper-step pos-step-two">
      <PosInvoice />
      <div className="step-main-content">
        <div className="step-field-wrap">{renderProducts()}</div>

        {/* {selectedProducts?.length > 0 && (
          <div className="invoice-discount-wrap">
            <div className="discount-title bold-font">
              إختار الخصم على  :
            </div>
            <div className="discount-radios-wrap">
              <label
                className={`radio-input-label ${
                  parseInt(invoiceDiscountType) !== 1 &&
                  parseInt(invoiceDiscountType) !== 2
                    ? 'selected'
                    : ''
                }`}
              >
                <input
                  // disabled={selectedInvoice ? true : false}
                  type="radio"
                  name="invoice_discount_type"
                  value="0"
                  onChange={(e) => handleDiscountRadioChange(e)}
                  checked={
                    parseInt(invoiceDiscountType) !== 1 &&
                    parseInt(invoiceDiscountType) !== 2
                  }
                />
                بدون خصــم
              </label>
              <label
                className={`radio-input-label ${
                  invoiceDiscountType == '1' ? 'selected' : ''
                }`}
              >
                <input
                  // disabled={selectedInvoice ? true : false}
                  type="radio"
                  name="invoice_discount_type"
                  value="1"
                  onChange={(e) => handleDiscountRadioChange(e)}
                  checked={invoiceDiscountType == '1'}
                />
                خصم رقم ثابت
              </label>
              <label
                className={`radio-input-label ${
                  invoiceDiscountType == '2' ? 'selected' : ''
                }`}
              >
                <input
                  // disabled={selectedInvoice ? true : false}
                  type="radio"
                  name="invoice_discount_type"
                  value="2"
                  onChange={(e) => handleDiscountRadioChange(e)}
                  checked={invoiceDiscountType == '2'}
                />
                خصم نسبة
              </label>
            </div>

            {invoiceDiscountType == '1' && (
              <label className="discount-value-label-wrap">
                <span className="label-span">أدخل سعر الخصم :</span>
                <input
                  // disabled={selectedInvoice ? true : false}
                  name="invice_discount_value"
                  type="number"
                  value={invoiceDiscountValue}
                  onChange={(e) => handleInvoiceDiscountValue(e)}
                />
              </label>
            )}
            {invoiceDiscountType == '2' && (
              <label className="discount-value-label-wrap">
                <span className="label-span">ادخل نسبة الخصم :</span>
                <input
                  // disabled={selectedInvoice ? true : false}
                  name="invice_discount_value"
                  type="number"
                  value={invoiceDiscountValue}
                  onChange={(e) => handleInvoiceDiscountValue(e)}
                />
              </label>
            )}
          </div>
        )} */}
        {selectedProducts?.length > 0 && (
          <div className="invoice-discount-wrap">
            {/* <div className="discount-title bold-font">
              {t('Pos.Main.invoiceAddOns')}
            </div> */}
            <InvoiceExtraPrices />
          </div>
        )}
      </div>
    </div>
  );
};

export default PosStepTwo;
