import { useContext } from 'react';
import StoreItemManagerPage from '../../../pages/store-pages/store-item-manager/StoreItemManagerPage';
// import routerLinks from '../routerLinks';
import { IncomingPurchaseInvoiceProvider } from '../../../contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import { InstallmentsPageProvider } from '../../../contexts/installments-context/InstallmentsContext';
import { OutgoingPurchaseInvoiceProvider } from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import { PosCashPaymentsProvider } from '../../../contexts/pos-cash-payments/PosCashPaymentsContext';
import { StoreConversionProvider } from '../../../contexts/store-conversion-context/StoreConversionProvider';
import { StoreInventoryProvider } from '../../../contexts/store-inventory-context/StoreInventoryProvider';
import { StoreNewProductModalProvider } from '../../../contexts/store-new-product-context/StoreNewProductProvider';
import { StoreProductsIngredientsProvider } from '../../../contexts/store-products-ingredients-context/StoreProductsIngredientsProvider';
import { StorePurchaseOrderProvider } from '../../../contexts/store-purchase-order-context/StorePurchaseOrderProvider';
import { StoreVariantsProvider } from '../../../contexts/store-variants/VariantsContextPage';
import UserContext from '../../../contexts/user-context/UserProvider';
import FatoorahStorePage from '../../../pages/FatoorahStorePage/FatoorahStorePage';
import CashInProducts from '../../../pages/cash-payments-products/cash-in-products/CashInProducts';
import CashOutProducts from '../../../pages/cash-payments-products/cash-out-products/CashOutProducts';
import ClientsPage from '../../../pages/clients-page/ClientsPage';
import SingleSupplierPaymentsPage from '../../../pages/single-supplier-payments-page/SingleSupplierPaymentsPage';
import CurrentStockPage from '../../../pages/store-pages/current-stock-page/CurrentStockPage';
import FirstTermStock from '../../../pages/store-pages/first-term-stock/FirstTermStock';
import PriceOfferPageNew from '../../../pages/store-pages/price-offer-page-new/PriceOfferPageNew';
import StoreConversionPage from '../../../pages/store-pages/store-conversion-page/StoreConversionPage';
import IncomingPurchaseInvoicePage from '../../../pages/store-pages/store-incoming-purchase-invoices-page/IncomingPurchaseInvoicePage';
import InstallmentsPage from '../../../pages/store-pages/store-incoming-purchase-invoices-page/InstallmentsPage';
import StoreIngredientsPage from '../../../pages/store-pages/store-ingredients-page/StoreIngredientsPage';
import StoreIngredientsProductsPage from '../../../pages/store-pages/store-ingredients-products-page/StoreIngredientsProductsPage';
import StoreInventoryPage from '../../../pages/store-pages/store-inventory-page/StoreInventoryPage';
import StoreItemManagerProductsPage from '../../../pages/store-pages/store-item-manager-products/StoreItemManagerProductsPage';
import SaleInvoiceInstallmentsPage from '../../../pages/store-pages/store-outgoing-purchase-invoices-page/InstallmentsPage';
import OutgoingPurchaseInvoicePage from '../../../pages/store-pages/store-outgoing-purchase-invoices-page/OutgoingPurchaseInvoicePage';
import StorePurchasesPage from '../../../pages/store-pages/store-purchases-page/StorePurchasesPage';
import VariantsPage from '../../../pages/store-pages/variants-page/VariantsPage';
import SuppliersPage from '../../../pages/suppliers-page/SuppliersPage';
import SuppliersPaymentsPage from '../../../pages/suppliers-payments-page/SuppliersPaymentsPage';
import UserInstallmentsPage from '../../../pages/user-installments-page/UserInstallmentsPage';
import protectMe from '../../../utils/protectMe';
import Links from '../Links';
import storeRouterLinks from './storeRouterLinks';
import { SettingsCategoriesProvider } from '../../../contexts/settings-contexts/SettingsCategoriesProvider';
import { SettingsMeasurementUnitsProvider } from '../../../contexts/settings-contexts/SettingsMeasurementUnitsPage';
import IncomingPurchaseInvoiceReturnPage from '../../../pages/store-pages/store-incoming-purchase-invoices-Return-page/IncomingPurchaseInvoiceReturnPage';
import OutgoingPurchaseReturnedInvoicePage from '../../../pages/store-pages/store-outgoing-purchase-returned-invoices-page/OutgoingPurchaseReturnedInvoicePage';

const StoreRoutes = () => {
  const { loggedIn, user } = useContext(UserContext);
  const routerLinks = Links();
  return [
    protectMe(
      routerLinks.storeItemManagerPage,
      <StoreNewProductModalProvider>
        <StoreItemManagerPage />
      </StoreNewProductModalProvider>,
      'store_item_mangaer_route_key',
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.storeItemManagerProductsPage,
      <StoreNewProductModalProvider>
        <SettingsCategoriesProvider>
          <SettingsMeasurementUnitsProvider>
            <StoreItemManagerProductsPage />
          </SettingsMeasurementUnitsProvider>
        </SettingsCategoriesProvider>
      </StoreNewProductModalProvider>,
      'store_item_mangaer_route_key',
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),

    protectMe(
      storeRouterLinks?.priceOfferRouteNew,
      <OutgoingPurchaseInvoiceProvider>
        <PriceOfferPageNew />
      </OutgoingPurchaseInvoiceProvider>,
      'price_offer_page_new_key',
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),

    //
    protectMe(
      routerLinks.storeProductsIngredientsPage,
      <StoreProductsIngredientsProvider>
        <StoreIngredientsPage />
      </StoreProductsIngredientsProvider>,
      2,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    //
    protectMe(
      routerLinks.outgoingPurchaseInvoice,
      <OutgoingPurchaseInvoicePage isReportsPage={false} />,
      992311,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.outgoingPurchaseInvoices(),
      <OutgoingPurchaseInvoicePage isReportsPage={false} />,
      992311,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.outgoingPurchaseInvoiceReturned,
      <OutgoingPurchaseReturnedInvoicePage />,
      992311,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.incomingPurchaseInvoice,
      <IncomingPurchaseInvoicePage />,
      9991,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.incomingPurchaseInvoices(),
      <IncomingPurchaseInvoicePage />,
      99915,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.incomingPurchaseInvoiceReturned,
      <IncomingPurchaseInvoiceReturnPage />,
      9991,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    // protectMe(
    //   routerLinks.incomingPurchaseInvoiceReports,
    //   <IncomingPurchaseInvoicePage reports={true} withoutReturnBtn={true} />,
    //   12341133334,
    //   routerLinks.signInPage,
    //   loggedIn,
    //   user?.expire
    // ),
    protectMe(
      routerLinks.storeVariantsPage,
      <StoreVariantsProvider>
        <VariantsPage />
      </StoreVariantsProvider>,
      2,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.incomingPurchaseInvoiceReports,
      <IncomingPurchaseInvoiceProvider>
        <IncomingPurchaseInvoicePage reports={true} withoutReturnBtn={true} />
      </IncomingPurchaseInvoiceProvider>,
      12341133334,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.outgoingPurchaseInvoiceReports,
      <OutgoingPurchaseInvoicePage
        reports={true}
        withoutReturnBtn={true}
        isReportsPage={true}
      />,
      992391,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.installmentsPage(),
      <InstallmentsPageProvider>
        <InstallmentsPage />
      </InstallmentsPageProvider>,
      41,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.saleInvoiceInstallmentsPage(),
      <InstallmentsPageProvider>
        <SaleInvoiceInstallmentsPage />
      </InstallmentsPageProvider>,
      'sale_invoice_installments_key',
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.userInstallmentsRoute(),
      <InstallmentsPageProvider>
        <UserInstallmentsPage />
      </InstallmentsPageProvider>,
      'user_installments_route_id',
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.supplierInstallmentsRoute(),
      <InstallmentsPageProvider>
        <UserInstallmentsPage />
      </InstallmentsPageProvider>,
      'supplier_installments_route_id',
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.clientPage,
      <ClientsPage />,
      'clients_page_key',
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.fatoorahStorePage,
      <FatoorahStorePage />,
      'clients_page_key',
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),

    protectMe(
      routerLinks.suppliersPage,
      <SuppliersPage />,
      2998989891928,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.suppliersPaymentsPage,
      <SuppliersPaymentsPage />,
      299898,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.singleSupplierPaymentsPage(),
      <SingleSupplierPaymentsPage />,
      29989800,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),

    protectMe(
      routerLinks.firstTermStock,
      <StoreNewProductModalProvider>
        <FirstTermStock />
      </StoreNewProductModalProvider>,
      2998989891928,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    //
    protectMe(
      routerLinks.storeConversionPage,
      <StoreConversionProvider>
        <StoreConversionPage />
      </StoreConversionProvider>,

      'store_conversion_key',
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    //
    protectMe(
      routerLinks.storeInventory,
      <StoreInventoryProvider>
        <StoreInventoryPage />
      </StoreInventoryProvider>,
      6,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    //
    protectMe(
      routerLinks.currentStock,
      <StoreInventoryProvider>
        <CurrentStockPage />
      </StoreInventoryProvider>,
      7,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    //
    protectMe(
      routerLinks.incomingPurchaseInvoiceReports,
      <IncomingPurchaseInvoiceProvider>
        <IncomingPurchaseInvoicePage reports={true} withoutReturnBtn={true} />
      </IncomingPurchaseInvoiceProvider>,
      4,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.purchaseOrderPage,
      <StorePurchaseOrderProvider>
        <StorePurchasesPage />
      </StorePurchaseOrderProvider>,
      3,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    protectMe(
      routerLinks.storeIngredientsProductsPage,
      <StoreProductsIngredientsProvider>
        <StoreIngredientsProductsPage />
      </StoreProductsIngredientsProvider>,
      2,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    // سند صرف
    protectMe(
      routerLinks.paymentVoucher,
      <PosCashPaymentsProvider>
        <CashOutProducts />
      </PosCashPaymentsProvider>,
      2,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    ),
    // سند قبض
    protectMe(
      routerLinks.receiptVoucher,
      <PosCashPaymentsProvider>
        <CashInProducts />
      </PosCashPaymentsProvider>,
      2,
      routerLinks.signInPage,
      loggedIn,
      user?.expire
    )
    //
    // protectMe(
    //   routerLinks.productLabelPage,
    //   <StoreProductLabelPage />,
    //   7,
    //   routerLinks.signInPage,
    //   loggedIn
    // )
  ];
};

export default StoreRoutes;
