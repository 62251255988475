import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import PosCashPaymentsContext from '../../../contexts/pos-cash-payments/PosCashPaymentsContext';
import { useTranslation } from 'react-i18next';

const PageModal = ({ departments }) => {
  const { modalOpened, setModalOpened, selectedCash, setSelectedCash } =
    useContext(PosCashPaymentsContext);
  const { t } = useTranslation();
  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '642px' }}
      title={
        selectedCash
          ? t('Inventory.CashOut.modifyCashOut')
          : t('Inventory.CashOut.addCashOut')
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedCash(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedCash(null);
      }}
      footer={false}
    >
      <TableForm departments={departments} />
    </Modal>
  );
};

export default PageModal;
