import React, { useContext, useEffect } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import { Button } from 'antd';
import BalanceSheetContext from '../../../contexts/accounting-context/BalanceSheetContext';
import BalanceSheetDetailsTable from './BalanceSheetDetailsTable';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import TableFilter from './TableFilter';
import BalanceSheetDetailsPage from './BalanceSheetDetailsPage';
import './balanceSheet.scss';
import JournalsDetailsModal from './JournalsDetailsModal';
import SummaryContext from '../../../contexts/accounting-context/SummaryContext';
import getJournalAccountsListLeafApi from '../../../apis/accounting-apis/accounting-details-apis/getJournalAccountsListLeafApi';
import useAccounts from '../../../custom-hooks/useAccounts';
import getAccountsList from '../../../apis/accounting-apis/accounting-details-apis/getAccountsList';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
const BalanceSheetPage = () => {
  const { user } = useContext(UserContext);
  const { modalOpened, setAccountsLists, accountsLists, fetchCount } =
    useContext(BalanceSheetContext);
  const { t } = useTranslation();

  useEffect(() => {
    let api = getAccountsList(user?.token, user?.client_id);
    customApiRequest(api, (res) => {
      if (res?.data?.accounts) {
        setAccountsLists(res?.data?.accounts);
      }
    });
  }, []);
  const allAccounts = useAccounts();

  const customApiRequest = useCustomApiRequest();
  // useEffect(() => {
  //     let isMounted = true;
  //     if (isMounted) {
  //     setIsLoading(true);
  //     customApiRequest(
  //         getAllBalanceSheetApi(user?.token),
  //         (res) => {
  //             if (res?.status === 200 && res?.data?.data && res?.data?.data?.list !== 'not found') {
  //                 setIsLoading(false);
  //                 setFetchedBalanceSheetDetails(res?.data?.data);
  //             }
  //         },
  //         (error) => {
  //             setIsLoading(false);
  //         }
  //     );
  //     }

  //     return () => {
  //     isMounted = false;
  //     };
  // }, [fetchCount]);

  return (
    <div className="accounting-page">
      <div className="page-head-wrap">
        {/* <div
          className="page-title"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 32
          }}
        >
          <div>
            <h2 className="bold-font">{t('ProfessorBook.ProfessorBook')}</h2>
            <p> {t('ProfessorBook.ShowAndDisplayProfessorBook')}</p>
          </div>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('ProfessorBook.ProfessorBook')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.accountingPage.title')}
            title3={t('ProfessorBook.ProfessorBook')}
            link3={'/accounting/balance-sheet'}
          />
        </div>
        <SearchNotificationsProfile />
      </div>
      <TableFilter allAccounts={allAccounts} />
      <BalanceSheetDetailsPage />

      {modalOpened && <JournalsDetailsModal />}
    </div>
  );
};

export default BalanceSheetPage;
