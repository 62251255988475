import { useState, useEffect, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchedStatiscts: null,
  setFetchedStatistics: (v) => {},
  fetchStatisctsCount: 0,
  setFetchStatistcsCount: (v) => {},
  //
  buyInvoiceChart: [],
  setBuyInvoiceChart: (v) => {},
  saleInvoiceChart: [],
  setSaleInvoiceChart: (v) => {},
  //
  lastSaleInvoices: [],
  setLastSaleInvoices: (v) => {}
};

const StatisticsContext = createContext(INITIAL_VALUES);

export const StatisticsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchedStatiscts, setFetchedStatistics] = useState(
    INITIAL_VALUES.fetchedStatiscts
  );
  const [buyInvoiceChart, setBuyInvoiceChart] = useState(
    INITIAL_VALUES.buyInvoiceChart
  );
  const [saleInvoiceChart, setSaleInvoiceChart] = useState(
    INITIAL_VALUES.saleInvoiceChart
  );
  const [lastSaleInvoices, setLastSaleInvoices] = useState(
    INITIAL_VALUES.lastSaleInvoices
  );
  const [fetchStatisctsCount, setFetchStatistcsCount] = useState(
    INITIAL_VALUES.fetchStatisctsCount
  );

  useEffect(() => {
    if (fetchedStatiscts) {
      if (fetchedStatiscts.buyInvoiceChart?.length > 0) {
        setBuyInvoiceChart(fetchedStatiscts.buyInvoiceChart);
      }
      if (fetchedStatiscts.SaleInvoiceChart?.length > 0) {
        setSaleInvoiceChart(fetchedStatiscts.SaleInvoiceChart);
      }
      if (fetchedStatiscts.lastSaleInvoice?.length > 0) {
        setLastSaleInvoices(fetchedStatiscts.lastSaleInvoice);
      }
    }
  }, [fetchedStatiscts]);

  return (
    <StatisticsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fetchStatisctsCount,
        setFetchStatistcsCount,
        fetchedStatiscts,
        setFetchedStatistics,
        buyInvoiceChart,
        setBuyInvoiceChart,
        saleInvoiceChart,
        setSaleInvoiceChart,
        lastSaleInvoices,
        setLastSaleInvoices
      }}
    >
      {children}
    </StatisticsContext.Provider>
  );
};

export default StatisticsContext;
