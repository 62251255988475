import React, { useCallback, useContext, useEffect } from 'react';
// import { Select } from 'antd';
import debounce from 'lodash.debounce';
import getAllClientsApi from '../../apis/clients-apis/getAllClientsApi';
import SearchIcon from '../../common/icons/SearchIcon';
import ClientsContext from '../../contexts/clients-context/ClientsContext';
import CustomersPaymentsContext from '../../contexts/customers-payments-context/CustomersPaymentsContext';
import UserContext from '../../contexts/user-context/UserProvider';
import excelImg from '../../assets/imgs/icons/excel-btn.png';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
import PageModal from './PageModal';
import './TableFilter.scss';
import { useTranslation } from 'react-i18next';
const TableFilter = () => {
  // const { Option } = Select;
  const systemSettings = useSystemSettings();
  const {
    setSearchTargetValue,
    SearchTargetValue,
    modalOpened,
    tablePagination,
    setTablePagination,
    setIsLoading,
  } = useContext(CustomersPaymentsContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const {
    setAllFetchedClients
    // fetchCount,
    // setFetchCount,
    // setModalOpened,
    // setSelectedClient,
    // nameSearchValue,
    // tablePagination,
    // setTablePagination
  } = useContext(ClientsContext);
  // function handleChange(value) {
  
  // }

  const debouncedChangeHandler = useCallback(
    debounce(async (e) => {
      setSearchTargetValue((prevState) => ({
        ...prevState,
        invoiceNumber: e.target.value
      }));
    }, 500),
    []
  );

  const handleTableSearch = useCallback(
    async (target) => {
      try {
        setIsLoading(true);
        // const res = await searchAllSuppliersApi(user?.token, {
        //   page: tablePagination.current_page,
        //   searchText: target
        // });

        const res = await getAllClientsApi(user?.token, {
          page: tablePagination.current_page,
          searchText: target?.invoiceNumber
        });
        if (res) {
          setIsLoading(false);
          // if (checkRes(res) && res?.data?.data?.data?.length >= 0) {
          //   const data = res.data.data.data;
          //   setAllFetchedClients(data);
          // }
          if (res.data.data.data.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedClients(data);
          }
          if (res?.data?.data?.pagination) {
            setTablePagination(res?.data?.data?.pagination);
          }
        }
      } catch (error) {
        setIsLoading(false);
      }
    },
    [
      setTablePagination,
      setAllFetchedClients,
      setIsLoading,
      tablePagination,
      user
    ]
  );
  useEffect(() => {
    if (SearchTargetValue) {
      handleTableSearch(SearchTargetValue);
    }
  }, [SearchTargetValue]);
  // const handleExcel = async () => {
  //   let data;
  //   try {
  //     const res = await getAllSuppliersApi(
  //       user?.token,
  //       {
  //         searchText: SearchTargetValue ? SearchTargetValue : '',
  //         page: ''
  //       },
  //       true
  //     );
  //     if (res.status === 200) {
  //       data = res?.data?.data;
  //       exportToExcel(data, 'الموردين');
  //     }
  //   } catch (error) {
  //     
  //   }
  // };

  return (
    <div className="custom-filter-add-section">
      {/* <div className="add-section">
        <div
          className="add-btn"
          onClick={() => {
            // if selected supplier then reset it
            // open the supplier modal
            setSelectedCustomerPayment(null);
            setModalOpened(true);
          }}
        >
          <span className="text-span">تسديد دفعة جديدة</span>
        </div>
      </div> */}

      <div className="custom-table-fitler">
        <div className="form-excel-export-wrap">
          <form
            className="filter-form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="table-search">
              <label>
                <SearchIcon />
                <input
                  placeholder={t('ClientPayments.EnterCustomerNameOrCode')}
                  type="text"
                  name="table_search"
                  onChange={debouncedChangeHandler}
                />
              </label>
            </div>
          </form>
          <div className="excel-btn-wrap">
            <a
              href={`${
                process.env.REACT_APP_BASE_URL
              }/Excel/exportUserPayment?client_id=${
                systemSettings?.id || ''
              }&searchText=${
                SearchTargetValue?.invoiceNumber
                  ? SearchTargetValue?.invoiceNumber
                  : ''
              }`}
              className="excel-export-btn"
            >
              <img
                style={{ height: '42px' }}
                src={excelImg}
                alt="export to excel"
              />
            </a>
          </div>
          {/* <div className="excel-btn-wrap" onClick={() => handleExcel()}>
            <img
              style={{ height: '42px' }}
              src={excelImg}
              alt="export to excel"
            />
          </div>
          <div className="excel-btn-wrap">
            <a
              href={`${process.env.REACT_APP_BASE_URL}/Excel/exportSuppliers?client_id=${systemSettings?.id}`}
              download
              className="excel-export-btn"
            >
              <img
                style={{ height: '42px' }}
                src={excelImg}
                alt="export to excel"
              />
            </a>
          </div> */}
          {/* <div className="excel-btn-wrap">
            <a
              href={`${process.env.REACT_APP_BASE_URL}/Excel/User/userExcel?client_id=${systemSettings?.id}`}
              download
              className="excel-export-btn"
              style={{ cursor: 'pointer' }}
            >
              <img
                style={{ height: '42px' }}
                src={excelImg}
                alt="export to excel"
              />
            </a>
          </div> */}
        </div>
      </div>

      {modalOpened && <PageModal />}
    </div>
  );
};

export default TableFilter;
