import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import { ReadOutlined , EyeOutlined} from '@ant-design/icons';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import countWords from '../../../utils/countWords';
import trimWords from '../../../utils/trimWords';
import moment from 'moment';
import deleteAccountingApi from '../../../apis/accounting-apis/deleteAccountingApi';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import UserContext from '../../../contexts/user-context/UserProvider';
import JournalsContext from '../../../contexts/accounting-context/JournalsContext';
import deleteJournalApi from '../../../apis/accounting-apis/accounting-details-apis/deleteJournalApi';
import SummaryContext from '../../../contexts/accounting-context/SummaryContext';

const TableColumns = (
  fetchedSummaryDetails,
  setModalOpened,
  setModalOpenedUpdate,
  setFetchCount,
  setSelectedSummary,
  setIsLoading,
  token,
  type,
) => {
  const { user } = useContext(UserContext);

  const params = useParams()

  const {setSelectedJournalId} = useContext(SummaryContext)

  const handleEditClick = (record) => {
    setSelectedSummary(record);
    setModalOpenedUpdate(true);
    setSelectedJournalId(record.id)
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteJournalApi(user?.token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: 'العملية تمت بنجاح',
          message: 'تم حذف الفرع بنجاح'
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: 'حدث خطأ اثناء العملية',
          message: res?.data?.message
            ? res.data.message
            : 'من فضلك حاول فى وقت لاحق'
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: 'حدث خطأ',
        message: 'حاول فى وقت لاحق'
      });
    }
  };

  const handlePreview = (id) => {
    setSelectedJournalId(id)
    setModalOpened(true);
  }

  // const renderActions = (obj) => {
  //   if (!obj.model_id) {
  //     return (
  //       <div className="action-btns-wrap">
  //         <div className="circled-btn-wrap">
  //           <Tooltip title="معاينة فرع الحسابات">
  //               <Button
  //                 id={obj?.id}
  //                 onClick={() => handlePreview(obj?.id)}
  //                 className="circled-btn"
  //                 type="dashed"
  //                 shape="circle"
  //                 icon={<EyeOutlined />}
  //               />
  //           </Tooltip>
  //         </div>
          
  //         {/* <div className="edit-btn" onClick={() => handleEditClick(obj)}>
  //           <EditIcon />
  //           تعديل
  //         </div> */}
  //         <Popconfirm
  //           title="هل أنت متأكد من حذف الفرع"
  //           onConfirm={() => handleDeleteRequest(obj.id)}
  //           okText="نعم"
  //           cancelText="لا"
  //         >
  //           <Tooltip title="حذف الفرع">
  //             <div className="delete-btn">
  //               <DeleteIcon />
  //             </div>
  //           </Tooltip>
  //         </Popconfirm>
  //       </div>
  //     );
  //   }
  //   return '------';
  // };

  return [
    {
      title: 'التاريــخ',
      dataIndex: 'date',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.created_at ? moment(record.created_at.split('T')[0]).format("MM/DD/YYYY") : '----'}
            </span>
          </div>
        );
      }
    },
    {
      title: 'الملاحظه',
      dataIndex: 'note',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.note ? record.note : '----'}
            </span>
          </div>
        );
      },
    },
    {
      title: 'طريقه الدفع',
      dataIndex: 'method',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.method ? record.method : '----'}
            </span>
          </div>
        );
      },
    },
    {
      title: 'المدين',
      dataIndex: 'debit',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.debit ? record.debit : '-----'}
            </span>
          </div>
        );
      }
    },
    {
      title: 'الدائن',
      dataIndex: 'credit',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.credit ? record.credit : '-----'}
            </span>
          </div>
        );
      }

    },
    {
      title: 'الرصيد',
      dataIndex: 'balance',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.balance ? record.balance : '-----'}
            </span>
          </div>
        );
      }

    }
  ];
};

export default TableColumns;
