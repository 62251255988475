/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { CameraFilled } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import getAllClientsApi from '../../../apis/clients-apis/getAllClientsApi';
import createOutgoningPurchaseInvoiceApi from '../../../apis/store-apis/outgoing-purchas-invoices-apis/CreateOutgoingPurchaseInvoiceApi';
import AntdRadioGroup from '../../../common/antd-form-components/AntdRadioGroup';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import CustomAntdDatePicker from '../../../common/antd-form-components/CustomAntdDatePicker';
import MyDropZone from '../../../common/dorp-zone/MyDropZone';
import MyDropZonePreview from '../../../common/dorp-zone/MyDropZonePreview';
import AddToCartIcon from '../../../common/icons/AddToCartIcon';
import RadioButtonEmpty from '../../../common/icons/RadioButtonEmpty';
import RadioButtonFilled from '../../../common/icons/RadioButtonFilled';
import StoreNewProductModal from '../../../components/store-new-product-modal/StoreNewProductModal';
import ClientsContext from '../../../contexts/clients-context/ClientsContext';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import StatisticsContext from '../../../contexts/statistics-context/StatisticsContext';
import StoreNewProductModalContext from '../../../contexts/store-new-product-context/StoreNewProductProvider';
import SuppliersContext from '../../../contexts/suppliers-context/SuppliersContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useClients from '../../../custom-hooks/useClients';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import useProductsSelected from '../../../custom-hooks/useProductsSelect';
import useSuppliers from '../../../custom-hooks/useSuppliers';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import userRoles from '../../../userRoles';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import InvoiceNewSupplierModal from '../../suppliers-page/PageModal';
import InvoiceNewClientModal from './InvoiceNewClientModal';
import RenderProductsFields from './RenderProductFields';
import './TableForm.scss';
import {
  renderFieldsTotalValue,
  // renderReceiptTotalValueAfterTax,
  renderTotalPriceAfterExtraPrices
} from './receiptCalculations';
import renderExtraPricesFields from './renderExtraPricesFields';
import getOutgoingInvoiceSchema from './tableSchema';
import useClientsWithTax from '../../../custom-hooks/useClientsWithTax';

const TableForm = ({ setModalVisible }) => {
  const systemSettings = useSystemSettings();
  const { t } = useTranslation();
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedReceipt,
    setSelectedReceipt,
    isSubmittingForm,
    setIsSubmittingForm,
    setTotalInvoiceValue
    // allProducts
  } = useContext(OutgoingPurchaseInvoiceContext);
  const { allClients, setAllClientsFetchCount } = useClients();
  const { allClientWithTax, setAllClientsTaxFetchCount } = useClientsWithTax();
  const { allSuppliers, setAllSuppliersFetchCount } = useSuppliers();
  const { allProducts, setAllProductsFetchCount } = useProductsSelected();
  const { user } = useContext(UserContext);
  const {
    // setModalOpened: setClientModalOpened,
    // modalOpened: clientModalOpened,
    modalOpenedClintHome,
    setModalOpenedClintHome,
    selectedClientId
  } = useContext(ClientsContext);

  const {
    setModalOpened: setSupplierModalOpened,
    modalOpened: supplierModalOpened,
    selectedSupplierId
  } = useContext(SuppliersContext);
  const {
    setModalOpened: setNewProductModalOpened,
    modalOpened: newProductModalOpened
  } = useContext(StoreNewProductModalContext);
  const { setFetchStatistcsCount } = useContext(StatisticsContext);
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    getValues,
    clearErrors,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(getOutgoingInvoiceSchema(selectedReceipt, t)),
    mode: 'all',
    defaultValues: {
      userOrSupplier: '1',
      user_id: '',
      supplier_id: '',
      products: [
        {
          product_id: '',
          returnQuantity: '',
          desc: '',
          discount_type: 'no_discount',
          discount_price: '',
          discount_percentage: '',
          totalPrice: '',
          quantity: 1,
          tax: '0',
          netPrice: ''
        }
      ],
      due_date: null,
      //
      discount_type: 'no_discount', // => 1 is fixed price && 2 is percentage price
      discount_price: '',
      discount_percentage: '',
      //
      // extra_prices: [
      //   {
      //     price: '',
      //     title: ''
      //   }
      // ],
      //
      // paymentType: '',

      invoice_due_date: '',
      first_paid: 0,
      first_pay_type: '',
      first_pay_img: '',
      invoice_image: '',
      // deferred_invoice_client: '',
      pay_type: '',
      multiPaymentVisa: '',
      multiPaymentCash: '',
      //
      note: ''
      // installments: [
      //   {
      //     price: '',
      //     date: null,
      //     note: '',
      //     status: ''
      //   }
      // ],
      // cheque: {
      //   bankName: '',
      //   cheekNum: ''
      // }
      // bankAccount: {
      //   accountNumber: '',
      //   price: ''
      // }
    }
  });
  const {
    fields: productsFields,
    append: appendProductField,
    remove: removeProductField
  } = useFieldArray({
    control,
    name: 'products'
  });

  const {
    fields: extraPricesFields,
    append: appendExtraPriceField,
    remove: removeExtraPriceField
  } = useFieldArray({
    control,
    name: 'extra_prices'
  });
  useEffect(() => {
    setTotalInvoiceValue(renderFieldsTotalValue(watch('products')));
  }, [watch('products')]);
  const [invoiceFileToUpload, setInvoiceFileToUpload] = useState(
    watch('invoice_image') ? watch('invoice_image') : null
  );
  const [invoiceFileToUploadDefferd, setInvoiceFileToUploadDefferd] = useState(
    watch('first_pay_img') ? watch('first_pay_img') : null
  );
  useEffect(() => {
    setValue('invoice_image', invoiceFileToUpload);
    if (watch('invoice_image')) {
      clearErrors('invoice_image');
    }
  }, [invoiceFileToUpload, invoiceFileToUpload?.length]);
  useEffect(() => {
    setValue('first_pay_img', invoiceFileToUploadDefferd);
    if (watch('first_pay_img')) {
      clearErrors('first_pay_img');
    }
  }, [invoiceFileToUploadDefferd, invoiceFileToUploadDefferd?.length]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handleFilesDrop = async (acceptedFiles, formFiles, setFormFiles) => {
    const duplicates = [];
    for (let i of acceptedFiles) {
      if (formFiles?.length) {
        for (let f of formFiles) {
          if (i.name === f.name) {
            duplicates.push(i);
          }
        }
      }
    }
    for (let i = 0; i < acceptedFiles.length; i++) {
      for (let f of duplicates) {
        if (acceptedFiles[i].name === f.name) {
          acceptedFiles.splice(i, 1);
        }
      }
    }
    acceptedFiles.forEach(async (file) => {
      const preview = await getBase64(file);
      Object.assign(file, {
        preview
      });
      setFormFiles((prevState) => [file]);
      if (formFiles?.length > 0) {
        setFormFiles((currState) => [file]);
      } else {
        setFormFiles([file]);
      }
    });
  };

  const clearFileFromUpload = (file, setFilesToUpload) => {
    setFilesToUpload((prevState) => {
      const filtered = prevState.filter((asset) => asset.name !== file.name);
      if (filtered?.length === 0) return null;
      return filtered;
    });
  };
  useEffect(() => {
    if (watch('pay_type')) {
      setValue('invoice_due_date', '');
      clearErrors('invoice_due_date');
      setValue('first_paid', 0);
      clearErrors('first_paid');
      setValue('first_pay_type', '');
      clearErrors('first_pay_type');
      setValue('invoice_image', null);
      clearErrors('invoice_image');
      setValue('first_pay_img', null);
      clearErrors('first_pay_img');
      setInvoiceFileToUpload(null);
    }
  }, [watch('pay_type')]);
  useEffect(() => {
    setValue('first_pay_img', null);
    clearErrors('first_pay_img');
  }, [watch('first_pay_type')]);
  useEffect(() => {
    if (watch('multiPaymentCash')) {
      clearErrors('multiPaymentVisa');
    }
  }, [watch('multiPaymentCash')]);
  useEffect(() => {
    if (watch('multiPaymentVisa')) {
      clearErrors('multiPaymentCash');
    }
  }, [watch('multiPaymentVisa')]);
  useEffect(() => {
    if (renderFieldsTotalValue(watch('products'))) {
      clearErrors('multiPaymentCash');
      clearErrors('multiPaymentVisa');
      setValue('multiPaymentCash', '');
      setValue('multiPaymentVisa', '');
    }
  }, [renderFieldsTotalValue(watch('products'))]);
  useEffect(() => {
    if (selectedClientId) {
      setValue('user_id', String(selectedClientId));
    }
  }, [selectedClientId]);
  useEffect(() => {
    if (selectedSupplierId) {
      setValue('supplier_id', String(selectedSupplierId));
    }
  }, [selectedSupplierId]);

  useEffect(() => {
    if (watch('userOrSupplier') == 1) setValue('supplier_id', '');
    if (watch('userOrSupplier') == 2) setValue('user_id', '');
  }, [watch('userOrSupplier')]);
  console.log(errors);
  let [paymentMethods, setPaymentStatus] = useState([]);
  useEffect(() => {
    if (selectedReceipt) {
      systemSettings?.invoiceSetting?.payment_methods_objects.map(
        (paymentMethod) => {
          paymentMethod.id != 2 &&
            paymentMethod.id != 6 &&
            setPaymentStatus((prv) => [
              ...prv,
              { title: paymentMethod.name, value: paymentMethod.id }
            ]);
        }
      );
    } else {
      systemSettings?.invoiceSetting?.payment_methods_objects.map(
        (paymentMethod) => {
          setPaymentStatus((prv) => [
            ...prv,
            { title: paymentMethod.name, value: paymentMethod.id }
          ]);
        }
      );
    }

  }, [systemSettings]);

  const renderAddComp = (compType) => {
    if (
      user?.role === userRoles?.manager ||
      user?.role === userRoles?.dataEntry
    ) {
      if (compType === 'client') {
        return (
          <>
            <div
              className="new-client-btn "
              onClick={() => {
                setModalOpenedClintHome(true);
              }}
            >
              <AddToCartIcon />
            </div>

            {modalOpenedClintHome && (
              <>
                <InvoiceNewClientModal
                  setAllClientsFetchCount={setAllClientsFetchCount}
                />
              </>
            )}
          </>
        );
      } else if (compType === 'supplier') {
        return (
          <>
            <div
              className="new-client-btn"
              onClick={() => {
                setSupplierModalOpened(true);
              }}
            >
              <AddToCartIcon />
            </div>
            {supplierModalOpened && (
              <InvoiceNewSupplierModal
                setAllSuppliersFetchCount={setAllSuppliersFetchCount}
              />
            )}
          </>
        );
      }
    }
  };

  const renderClientsTabs = () => {
    return (
      <div className="prev-new-client-wrap">
        <div className="select-label-wrap">
          <p className="label-p">{t('SalesInvoices.ChooseClient')}</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`user_id`}
              errorMsg={errors?.user_id && errors.user_id.message}
              validateStatus={errors?.user_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder={t('SalesInvoices.ChooseClient')}
              options={
                allClients?.length > 0 &&
                allClients.map((role) => ({
                  title: role?.name,
                  value: String(role?.id)
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              formClassName="outgoing-invoice-form"
            />
          </div>
        </div>

        {renderAddComp('client')}
      </div>
    );
  };
  const renderClintWithTax = () => {
    return (
      <div className="prev-new-client-wrap">
        <div className="select-label-wrap">
          <p className="label-p">{t('SalesInvoices.ChooseClient')}</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`user_id`}
              errorMsg={errors?.user_id && errors.user_id.message}
              validateStatus={errors?.user_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder={t('SalesInvoices.ChooseClient')}
              options={
                allClientWithTax?.length > 0 &&
                allClientWithTax.map((role) => ({
                  title: role.name,
                  value: String(role.id)
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              formClassName="outgoing-invoice-form"
            />
          </div>
        </div>

        {renderAddComp('client')}
      </div>
    );
  };

  const renderSuppliersTabs = () => {
    return (
      <div className="prev-new-client-wrap">
        <div className="select-label-wrap">
          <p className="label-p">إختار الشركة</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`supplier_id`}
              errorMsg={errors?.supplier_id && errors.supplier_id.message}
              validateStatus={errors?.supplier_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="إختار الشركة"
              options={
                allSuppliers?.length > 0 &&
                allSuppliers.map((role) => ({
                  title: role.name,
                  value: String(role.id)
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="outgoing-invoice-form"
            />
          </div>
        </div>

        {renderAddComp('supplier')}
      </div>
    );
  };

  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    const mappedData = {};
    if (data.userOrSupplier) mappedData.userOrSupplier = data.userOrSupplier;
    if (data.user_id) mappedData.user_id = data.user_id;
    if (data.manualNum) mappedData.manualNum = data.manualNum;
    if (data.supplier_id) mappedData.supplier_id = data.supplier_id;
    if (data.note) mappedData.note = data.note;
    if (data.due_date)
      mappedData.dueDate = moment(data.due_date).format('YYYY-MM-DD');
    /////////////////
    // prdoucts
    const mappedProducts = data.products.map((pro, index) => {
      if (
        pro.product_id &&
        (pro.discount_type == '1' || pro.discount_type == '2')
      )
        return {
          id: pro.product_id,
          desc: pro.desc,
          discountType: pro.discount_type,
          discountValue:
            pro.discount_type === '1'
              ? pro.discount_price
              : pro.discount_percentage,
          price: pro?.price ? pro.price : '',
          totalPrice: pro.price && pro.quantity ? pro.price * pro.quantity : '',
          quantity: selectedReceipt ? pro.returnQuantity : pro.quantity,
          parent_quantity:
            selectedReceipt?.products?.length > 0
              ? selectedReceipt.products[index]?.quantity
              : 0,
          tax: pro?.tax,
          including_tax: pro.including_tax
        };
      else if (
        pro.product_id &&
        !(pro.discount_type == '1' || pro.discount_type == '2')
      )
        return {
          id: pro.product_id,
          desc: pro.desc,
          price: pro?.price ? pro.price : '',
          totalPrice: pro.price && pro.quantity ? pro.price * pro.quantity : '',
          quantity: selectedReceipt ? pro.returnQuantity : pro.quantity,
          parent_quantity:
            selectedReceipt?.products?.length > 0
              ? selectedReceipt.products[index]?.quantity
              : 0,
          tax: pro?.tax,

          including_tax: pro?.including_tax
        };
      return null;
    });
    // if (mappedProducts?.length > 0 && mappedProducts[0] !== null) {
    //   mappedData.products = JSON.stringify(mappedProducts);
    // }
    mappedData.products =
      mappedProducts?.length > 0 ? JSON.stringify(mappedProducts) : [];
    mappedData.type = 1; // => buy invoice type
    /////////////////
    if (data.extra_prices?.length > 0) {
      const mappedExtraPrices = data.extra_prices.map((extra) => {
        if (extra.price && extra.title) {
          return {
            price: extra.price,
            desc: extra.title
          };
        }
        return null;
      });
      if (mappedExtraPrices?.length > 0 && mappedExtraPrices[0] !== null) {
        mappedData.addPrices = JSON.stringify(mappedExtraPrices);
      }
    }
    /////////////////
    if (data.discount_type) {
      if (data.discount_type == '1' || data.discount_type == '2') {
        mappedData.discountType = data.discount_type;
      }
      if (data.discount_price && data.discount_type === '1') {
        mappedData.discountValue = data.discount_price;
      } else if (data.discount_percentage && data.discount_type === '2') {
        mappedData.discountValue = data.discount_percentage;
      }
    }
    /////////////
    // if (data.paymentType) {
    //   mappedData.paymentType = data.paymentType;
    // }

    // if (data.paymentType) {
    //   mappedData.paymentType = data.paymentType;
    //   if (data.paymentType === '2' && data.installments?.length > 0) {
    //     const mappedInstallments = data.installments.map((ins) => {
    //       return {
    //         price: ins.price ? ins.price : null,
    //         note: ins.note ? ins.note : null,
    //         date: ins.date ? moment(ins.date).format('YYYY-MM-DD') : null,
    //         status: ins.status ? ins.status : null
    //       };
    //     });
    //     if (mappedInstallments?.length > 0 && mappedInstallments[0] !== null) {
    //       mappedData.installments = JSON.stringify(mappedInstallments);
    //     }
    //   } else if (mappedData.paymentType == '4') {
    //     if (data?.cheque?.bankName) {
    //       mappedData.bankName = data.cheque.bankName;
    //     }
    //     if (data?.cheque.cheekNum) {
    //       mappedData.cheekNum = data.cheque.cheekNum;
    //     }
    //   } else if (mappedData.paymentType == '5') {
    //     if (data?.bankAccount?.accountNumber) {
    //       mappedData.bankName = data.bankAccount.accountNumber;
    //     }
    //     if (data?.bankAccount.price) {
    //       mappedData.bank = data.bankAccount.price;
    //     }
    //   }
    // }
    if (data.pay_type) {
      mappedData.paymentType = data.pay_type;
      if (data.pay_type == '2') {
        // const mappedInstallments = data.installments.map((ins) => {
        //   return {
        //     price: ins.price ? ins.price : null,
        //     note: ins.note ? ins.note : null,
        //     date: ins.date ? moment(ins.date).format('YYYY-MM-DD') : null,
        //     status: ins.status ? ins.status : null
        //   };
        // });
        // if (mappedInstallments?.length > 0 && mappedInstallments[0] !== null) {
        //   mappedData.installments = JSON.stringify(mappedInstallments);
        // }

        if (data?.invoice_due_date) {
          mappedData.invoice_due_date = moment(data.invoice_due_date).format(
            'YYYY-MM-DD'
          );
        }
        mappedData.first_paid = data.first_paid ? data.first_paid : 0;
        if (data?.first_pay_type) {
          mappedData.first_pay_type = data.first_pay_type;
          if (data?.first_pay_type == '5') {
            if (data?.first_pay_img?.length) {
              mappedData.first_pay_img = data.first_pay_img[0];
            }
          }
        }
        if (renderFieldsTotalValue(watch('products'))) {
          mappedData.deferred = renderFieldsTotalValue(watch('products'));
        }
      } else if (mappedData.paymentType == '5') {
        if (data?.invoice_image?.length) {
          mappedData.bank_image = data.invoice_image[0];
        }
        if (renderFieldsTotalValue(watch('products'))) {
          mappedData.bank = renderFieldsTotalValue(watch('products'));
        }
      } else if (mappedData.paymentType == '6') {
        mappedData.totalInvoicePrice = renderFieldsTotalValue(
          watch('products')
        );
        if (data?.multiPaymentVisa) {
          mappedData.visa = data.multiPaymentVisa;
        }
        if (data?.multiPaymentCash) {
          mappedData.cash = data.multiPaymentCash;
        }
      }
    }

    setIsSubmittingForm(true);
    setIsLoading(true);

    if (selectedReceipt) {
      mappedData.parentInvoice = selectedReceipt.id;
      mappedData.type = 2; // => 2 is the type of return receipt
      const formData = new FormData();
      for (let key in mappedData) {
        formData.append(key, mappedData[key]);
      }
      customApiRequest(
        createOutgoningPurchaseInvoiceApi(user?.token, formData, true),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setModalOpened(false);
            setSelectedReceipt(null);
            setModalVisible && setModalVisible(false);
            setFetchCount((prevState) => prevState + 1);
            setFetchStatistcsCount((prev) => prev + 1);
            successNotification({
              title: t('Messages.addedSuccessfully'),

              message: t('Messages.Modifiedsuccessfully')
            });
          } else {
            errorNotification({
              title: t('Messages.ErrorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Messages.TryAgainLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message:
              error?.response?.data?.message || t('Messages.TryAgainLater')
          });
        }
      );
    } else {
      const formData = new FormData();
      for (let key in mappedData) {
        formData.append(key, mappedData[key]);
      }
      customApiRequest(
        createOutgoningPurchaseInvoiceApi(user?.token, formData),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setModalOpened(false);
            setSelectedReceipt(null);
            setModalVisible && setModalVisible(false);
            setFetchCount((prevState) => prevState + 1);
            setFetchStatistcsCount((prev) => prev + 1);
            successNotification({
              title: t('Messages.addedSuccessfully'),
              message: t('Messages.addsuccessfully')
            });
          } else {
            errorNotification({
              title: t('Messages.ErrorOccurred'),
              message: res?.data?.message || t('Messages.TryAgainLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message:
              error?.response?.data?.message || t('Messages.TryAgainLater')
          });
        }
      );
    }
  };

  // handle initial tax
  const handleInitialTax = (tax) => {
    if (parseFloat(tax) > 0) return tax;
    return parseFloat(systemSettings?.tax);
  };
  // handle initial values
  useEffect(() => {
    if (selectedReceipt && systemSettings) {
      if (selectedReceipt?.userOrSupplier) {
        setValue('userOrSupplier', String(selectedReceipt.userOrSupplier));
      }
      if (selectedReceipt?.user?.id) {
        setValue('user_id', String(selectedReceipt?.user?.id));
      }
      if (selectedReceipt?.supplier?.id) {
        setValue('supplier_id', String(selectedReceipt.supplier.id));
      }
      if (selectedReceipt?.manualNum) {
        setValue('manualNum', String(selectedReceipt.manualNum));
      }
      if (selectedReceipt?.dueDate)
        setValue('due_date', moment(selectedReceipt.dueDate, 'YYYY-MM-DD'));
      if (selectedReceipt?.note) {
        setValue('note', selectedReceipt.note);
      }
      //
      if (selectedReceipt?.discountType) {
        if (
          selectedReceipt.discountType == '1' ||
          selectedReceipt.discountType == '2'
        ) {
          // 1 => fixed price discount
          // 2 => percentage discount
          setValue('discount_type', String(selectedReceipt.discountType));
        } else {
          setValue('discount_type', 'no_discount');
        }
      } else {
        setValue('discount_type', 'no_discount');
      }
      if (
        selectedReceipt?.paymentType &&
        String(selectedReceipt.paymentType) != 2 &&
        String(selectedReceipt.paymentType) != 6
      ) {
        setValue('pay_type', String(selectedReceipt.paymentType));
      }
      //
      if (
        String(selectedReceipt.discountValue) &&
        selectedReceipt.discountType == '1'
      ) {
        setValue('discount_price', selectedReceipt.discountValue);
      } else if (
        String(selectedReceipt.discountValue) &&
        selectedReceipt.discountType == '2'
      ) {
        setValue('discount_percentage', selectedReceipt.discountValue);
      }
      if (selectedReceipt?.paymentType) {
        setValue('paymentType', String(selectedReceipt.paymentType));
      }
      //
      if (selectedReceipt?.paymentType == '4') {
        if (selectedReceipt?.bankName) {
          setValue('cheque.bankName', selectedReceipt.bankName);
        }
        if (selectedReceipt?.cheekNum) {
          setValue('cheque.cheekNum', selectedReceipt.cheekNum);
        }
      } else if (selectedReceipt?.paymentType == '5') {
        if (selectedReceipt?.bankName) {
          setValue('bankAccount.accountNumber', selectedReceipt.bankName);
        }
        if (selectedReceipt?.cheekNum) {
          setValue('bankAccount.price', selectedReceipt.cheekNum);
        }
      }
    }
    /////////////////////////
    if (selectedReceipt) {
      const sharedReset = {
        userOrSupplier: selectedReceipt?.userOrSupplier
          ? String(selectedReceipt.userOrSupplier)
          : '',
        user_id: selectedReceipt?.user?.id
          ? String(selectedReceipt.user.id)
          : '',
        manualNum: selectedReceipt?.manualNum || '',
        supplier_id: selectedReceipt?.supplier?.id
          ? String(selectedReceipt.supplier.id)
          : '',
        due_date: selectedReceipt.dueDate
          ? moment(selectedReceipt.dueDate, 'YYYY-MM-DD')
          : null,
        note: selectedReceipt.note,
        //
        discount_type: selectedReceipt.discountType
          ? String(selectedReceipt.discountType)
          : 'no_discount',
        discount_price:
          selectedReceipt?.discountType == '1' &&
          String(selectedReceipt.discountValue)
            ? selectedReceipt.discountValue
            : '',
        discount_percentage:
          selectedReceipt?.discountType == '2' &&
          String(selectedReceipt.discountValue)
            ? selectedReceipt.discountValue
            : '',
        //
        paymentType: selectedReceipt.paymentType
          ? String(selectedReceipt.paymentType)
          : '',
        //
        cheque: {
          bankName:
            selectedReceipt?.bankName && selectedReceipt?.paymentType == '4'
              ? selectedReceipt.bankName
              : '',
          cheekNum:
            selectedReceipt?.cheekNum && selectedReceipt?.paymentType == '4'
              ? selectedReceipt.cheekNum
              : ''
        },
        bankAccount: {
          accountNumber:
            selectedReceipt?.bankName && selectedReceipt?.paymentType == '5'
              ? selectedReceipt.bankName
              : '',
          price:
            selectedReceipt?.cheekNum && selectedReceipt?.paymentType == '5'
              ? selectedReceipt.cheekNum
              : ''
        }
      };
      if (selectedReceipt.products?.length >= 0) {
        const { products } = selectedReceipt;
        let mappedProducts = [];
        let mappedInstallments = [];
        mappedProducts = products.map((product) => {
          return {
            product_id: String(product?.product?.id),
            desc: product.desc,
            discount_type: product.discountType
              ? String(product.discountType)
              : 'no_discount',
            discount_price:
              product?.discountType == '1' && String(product.discountValue)
                ? product.discountValue
                : '',
            discount_percentage:
              product?.discountType == '2' && String(product.discountValue)
                ? product.discountValue
                : '',
            price: product?.price ? product.price : '',
            totalPrice:
              product?.totalPrice && product.quantity
                ? product.totalPrice * product.quantity
                : '',
            quantity: product?.quantity ? product.quantity : 1,
            including_tax: product?.including_tax,
            tax: handleInitialTax(product?.tax)
            // parseFloat(product?.tax) > 0 ? product.tax : systemSettings?.tax
          };
        });
        //
        const mappedExtraPrices =
          selectedReceipt?.addPrices?.length > 0 &&
          selectedReceipt.addPrices.map((obj) => ({
            price: obj.price,
            title: obj.desc
          }));

        if (
          selectedReceipt.paymentType == '2' &&
          selectedReceipt.Installment?.length > 0
        ) {
          mappedInstallments = selectedReceipt.Installment.map((ins) => ({
            price: ins.price,
            //  status: String(ins.status),
            note: ins.note,
            date: moment(ins.date, 'YYYY-MM-DD')
          }));
        }

        reset({
          ...sharedReset,
          products: products?.length > 0 ? [...mappedProducts] : [],
          extra_prices:
            selectedReceipt?.addPrices?.length > 0
              ? [...mappedExtraPrices]
              : [],
          installments:
            selectedReceipt.Installment?.length > 0
              ? [...mappedInstallments]
              : []
        });
      }
    }
  }, [selectedReceipt, selectedReceipt?.id, reset, systemSettings]);

  const [form] = Form.useForm();
  return (
    <Form
      className="create-receipt-form custom-shared-form outgoing-invoice-form"
      form={form}
      layout="vertical"
      // initialValues={{
      //   products: watch('products')
      // }}
      fields={[
        {
          name: 'discount_type',
          value: watch('discount_type')
        },
        {
          name: 'pay_type',
          value: watch('pay_type')
        },
        {
          name: 'products',
          value: watch('products')
        },
        {
          name: 'installments',
          value: watch('installments')
        }
      ]}
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        {selectedReceipt?.user?.name && (
          <div className="supplier-name-view">
            <span>{t('SalesInvoices.InvoiceClient')} : </span>
            <span className="supp-name">{selectedReceipt.user?.name}</span>
          </div>
        )}
        {selectedReceipt?.supplier?.name && (
          <div className="supplier-name-view">
            <span>{t('SalesInvoices.InvoiceSupplier')} : </span>
            <span className="supp-name">{selectedReceipt.supplier?.name}</span>
          </div>
        )}
        {!selectedReceipt && (
          <div className="radios-wrap">
            {/* <p className="radios-group-title">إختار النـــوع</p> */}
            <div className="labels-wrap">
              {[
                {
                  // title: ' ( فاتورة مبسطة) عميل',
                  title: t('SalesInvoices.SimplifiedInvoice'),
                  value: '1'
                },
                {
                  // title: ' ( فاتورة ضريبية) مورد',
                  title: t('SalesInvoices.TaxInvoice'),
                  value: '2'
                }
              ].map((obj, i) => (
                <label
                  key={i}
                  className={`radio-input-label ${
                    String(watch(`userOrSupplier`)) === String(obj.value)
                      ? 'selected'
                      : ''
                  }`}
                >
                  <input
                    type="radio"
                    value={obj.value}
                    {...register('userOrSupplier')}
                  />
                  {watch(`userOrSupplier`) == String(obj.value) ? (
                    <RadioButtonFilled />
                  ) : (
                    <RadioButtonEmpty />
                  )}
                  <span className="label-span">{obj.title}</span>
                </label>
              ))}
            </div>

            {errors?.userOrSupplier && (
              <p className="error-p">{errors.userOrSupplier.message}</p>
            )}
          </div>
        )}
        {!selectedReceipt && watch('userOrSupplier') == 1 // فاتوره مبسطه
          ? renderClientsTabs()
          : null}
        {!selectedReceipt && watch('userOrSupplier') == 2 // فاتورة ضريبيه
          ? renderClintWithTax()
          : null}

        {/* <div className="text-field-label-wrap">
          <p className="label-p">رقم امر الشراء</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="manualNum"
              type="text"
              placeholder="رقم امر الشراء..."
              errorMsg={errors?.manualNum?.message}
              validateStatus={errors?.manualNum ? 'error' : ''}
              control={control}
            />
          </div>
        </div> */}
        <div className="date-picker-wrap">
          <p className="label-p">
            {t('SalesInvoices.DateOfCompletionService')}
          </p>
          <CustomAntdDatePicker
            // disablePast
            control={control}
            name="due_date"
            className="receipt-date-picker"
            formClassName="outgoing-invoice-form"
          />
          {errors?.due_date && (
            <p className="error-p">{errors.due_date.message}</p>
          )}
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('SalesInvoices.notes')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="note"
              rows={8}
              placeholder={t('SalesInvoices.notesPlaceholder')}
              errorMsg={errors?.note?.message}
              validateStatus={errors?.note ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div
          className="radios-field-array-wrap no-border"
          style={{ marginBottom: 0 }}
        >
          <div className="products-add-btn-wrap">
           
            <RenderProductsFields
              allProducts={allProducts}
              productsFields={productsFields}
              removeProductField={removeProductField}
              selectedReceipt={selectedReceipt}
              watch={watch}
              getValues={getValues}
              errors={errors}
              setValue={setValue}
              control={control}
              register={register}
              systemSettings={systemSettings}
            />

           {!selectedReceipt && (
              <div
                style={{
                  display: 'flex',
                  gap: 32,
                  justifyContent: 'space-between',
                  alignContent: 'center',
                  marginTop :'30px'
                }}
              >
                <div
                  className="add-new-field-btn"
                  style={{
                    marginBottom: 12
                  }}
                  onClick={() =>
                    appendProductField({
                      product_id: '',
                      discount_type: 'no_discount',
                      discount_price: '',
                      discount_percentage: '',
                      price: '',
                      quantity: '1',
                      tax: '0'
                    })
                  }
                >
                  <span>{t('SalesInvoices.AddPreviousServiceOrPackage')}</span>
                </div>

                <div
                  className="add-new-field-btn"
                  style={{
                    marginBottom: 12
                  }}
                  onClick={() => {
                    setNewProductModalOpened(true);
                  }}
                >
                  <span>{t('SalesInvoices.AddNewServiceOrPackage')}</span>
                </div>

                {/* <div
                  className="new-client-btn"
                  onClick={() => {
                    setNewProductModalOpened(true);
                  }}
                >
                  <span>إضافة خدمة او باقة جديدة</span>
                  <AddToCartIcon />
                </div> */}

                {newProductModalOpened && (
                  <StoreNewProductModal
                    setAllProductsFetchCount={setAllProductsFetchCount}
                  />
                )}
              </div>
            )} 
            {watch('products')?.length > 0 &&
              watch(`products[0].product_id`) && (
                <div className="total-fields-price">
                  <span className="total-title">
                    {t('SalesInvoices.ServicesPrice')} :{' '}
                  </span>
                  <span className="total-value">
                    {renderFieldsTotalValue(
                      watch('products'),
                      systemSettings?.tax
                    ).toFixed(2)}
                  </span>
                </div>
              )}
          </div>
        </div>
        {/* extra prices section */}
        {/* <div
          style={{
            paddingTop: 22,
            marginBottom: 18,
            borderTop: '1px solid rgba(0, 0, 0, .1)'
          }}
        >
          <div
            className="add-new-field-btn"
            onClick={() =>
              appendExtraPriceField({
                price: '',
                title: ''
              })
            }
          >
            <span>{t('SalesInvoices.NewAdditionalPrice')}</span>
          </div>
          {renderExtraPricesFields(
            control,
            setValue,
            errors,
            extraPricesFields,
            removeExtraPriceField
          )}

          {watch('extra_prices')?.length > 0 &&
            watch(`extra_prices[0].price`) && (
              <div className="total-fields-price">
                <span className="total-title">
                  {t('SalesInvoices.PriceAfterExtras')} :
                </span>
                <span className="total-value">
                  {renderTotalPriceAfterExtraPrices(
                    watch('products'),
                    watch('extra_prices'),
                    systemSettings?.tax
                  )}
                </span>
              </div>
            )}
        </div> */}
        {/* ./extra prices section */}

        {/* paytype section */}
        <div
          className="radios-field-array-wrap no-border"
          style={{ paddingBottom: '22px' }}
        >
          <AntdRadioGroup
            name="pay_type"
            className="form-radio-group"
            control={control}
            label={t('SalesInvoices.ChoosePaymentType')}
            validateStatus={errors?.pay_type ? 'error' : ''}
            errorMsg={errors?.pay_type?.message}
            radios={paymentMethods}
          />

          {String(watch('pay_type')) == '2' && (
            <div
              className="ingredients-add-btn-wrap"
              style={{
                display: 'grid',
                alignItems: 'start',
                gridTemplateColumns: '1fr 1fr',
                width: '100%',
                gap: '0 30px'
              }}
            >
              {/* <div
                className="add-new-field-btn"
                onClick={() =>
                  appendInstallmentField({
                    price: '',
                    date: null,
                    note: '',
                    status: ''
                  })
                }
              >
                <span>+ اضافة ايصال جديد</span>
              </div>
              {renderInstallmentsFields(
                control,
                setValue,
                errors,
                installmentsFields,
                removeInstallmentField
              )} */}
              {/* <div className="" style={{ width: '45%' }}>
                <p style={{ marginBottom: '8px' }}>إسم العميل</p>
                <div className="custom-select-wrap without-icon">
                  <AntdSelectOption
                    name={`deferred_invoice_client`}
                    errorMsg={
                      errors?.deferred_invoice_client &&
                      errors.deferred_invoice_client.message
                    }
                    validateStatus={
                      errors?.deferred_invoice_client ? 'error' : ''
                    }
                    control={control}
                    setValue={setValue}
                    placeholder={'إختار إسم العميل'}
                    options={
                      allClients?.length > 0 &&
                      allClients.map((role) => ({
                        title: role.name,
                        value: String(role.id)
                      }))
                    }
                    showSearch={true}
                    // onSearch={onSearch}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    formClassName="outgoing-invoice-form"
                  />
                </div>
              </div> */}

              <div className="date-picker-wrap">
                <p style={{ marginBottom: '8px' }}>
                  {t('SalesInvoices.DueDate')}
                </p>
                <CustomAntdDatePicker
                  // disablePast
                  control={control}
                  name={`invoice_due_date`}
                  className="receipt-date-picker"
                  formClassName="create-receipt-form"
                  errorMsg={
                    errors?.invoice_due_date && errors.invoice_due_date.message
                  }
                  validateStatus={errors?.invoice_due_date ? 'error' : ''}
                  setValue={setValue}
                  disablePast={true}
                />
              </div>
              <div className="text-field-label-wrap">
                <p className="label-p">{t('SalesInvoices.FirstPay')}</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name={`first_paid`}
                    type="text"
                    placeholder={t('SalesInvoices.EnterDownPaymentAmount')}
                    errorMsg={errors?.first_paid && errors?.first_paid?.message}
                    validateStatus={errors?.first_paid ? 'error' : ''}
                    control={control}
                  />
                </div>
              </div>
              <div className="text-field-label-wrap">
                <div className="text-field-wrap">
                  <AntdRadioGroup
                    name="first_pay_type"
                    className="form-radio-group"
                    control={control}
                    label={t('SalesInvoices.PaymentTypeForDownPayment')}
                    validateStatus={errors?.first_pay_type ? 'error' : ''}
                    errorMsg={errors?.first_pay_type?.message}
                    // radios={paymentMethods}
                    radios={[
                      {
                        title: t('SalesInvoices.Cash'),
                        value: '1'
                      },
                      {
                        title: t('SalesInvoices.Visa'),
                        value: '3'
                      },
                      {
                        title: t('SalesInvoices.BankTransfer'),
                        value: '5'
                      }
                    ]}
                    // isRadioButton
                  />
                </div>
              </div>
            </div>
          )}
          {String(watch('first_pay_type')) == '5' && (
            <div className="product-img-wrap">
              <h3 style={{ marginBottom: '6px' }}>
                {t('SalesInvoices.UploadReceiptImage')}
              </h3>

              <MyDropZone
                className="product-header-dropzone"
                multipleFiles={false}
                handleFilesDrop={(acceptedFiles) =>
                  handleFilesDrop(
                    acceptedFiles,
                    invoiceFileToUploadDefferd,
                    setInvoiceFileToUploadDefferd
                  )
                }
                filesToUpload={invoiceFileToUploadDefferd}
                id="avatar-input"
                dropzoneText={t('SalesInvoices.UploadProductImage')}
                inputName="first_pay_img"
                icon={<CameraFilled className="dropzone-camera" />}
                dropZoneUrls={
                  watch('first_pay_img')?.file
                    ? [watch('first_pay_img')?.file]
                    : null
                }
              >
                {t('SalesInvoices.DragAndDropImageHere')}
              </MyDropZone>

              <MyDropZonePreview
                filesToUpload={invoiceFileToUploadDefferd}
                clearFileFromUpload={(f) =>
                  clearFileFromUpload(f, setInvoiceFileToUploadDefferd)
                }
              />
              {errors?.first_pay_img && (
                <p
                  className="error-p"
                  style={{ color: '#ff4d4f', fontSize: '14px' }}
                >
                  {errors?.first_pay_img?.message}
                </p>
              )}
            </div>
          )}
          {String(watch('pay_type')) == '5' && (
            // <div className="cheque-wrapper">
            //   <div className="text-field-label-wrap">
            //     <p className="label-p">رقم الحساب</p>
            //     <div className="text-field-wrap">
            //       <AntdTextField
            //         setValue={setValue}
            //         className="form-text-field"
            //         name={`bankAccountNumber`}
            //         type="text"
            //         placeholder="رقم الحساب..."
            //         errorMsg={
            //           errors?.bankAccountNumber &&
            //           errors.bankAccountNumber.message
            //         }
            //         validateStatus={errors?.bankAccountNumber ? 'error' : ''}
            //         control={control}
            //       />
            //     </div>
            //   </div>
            //   <div className="text-field-label-wrap">
            //     <p className="label-p">المبلغ</p>
            //     <div className="text-field-wrap">
            //       <AntdTextField
            //         setValue={setValue}
            //         className="form-text-field"
            //         name={`bankPrice`}
            //         type="text"
            //         placeholder="المبلغ..."
            //         errorMsg={errors?.bankPrice && errors.bankPrice.message}
            //         validateStatus={errors?.bankPrice ? 'error' : ''}
            //         control={control}
            //       />
            //     </div>
            //   </div>
            // </div>
            <div className="product-img-wrap">
              <h3 style={{ marginBottom: '6px' }}>
                {t('SalesInvoices.UploadReceiptImage')}
              </h3>

              <MyDropZone
                className="product-header-dropzone"
                multipleFiles={false}
                handleFilesDrop={(acceptedFiles) =>
                  handleFilesDrop(
                    acceptedFiles,
                    invoiceFileToUpload,
                    setInvoiceFileToUpload
                  )
                }
                filesToUpload={invoiceFileToUpload}
                id="avatar-input"
                dropzoneText={t('SalesInvoices.UploadProductImage')}
                inputName="invoice_image"
                icon={<CameraFilled className="dropzone-camera" />}
                dropZoneUrls={
                  watch('invoice_image')?.file
                    ? [watch('invoice_image')?.file]
                    : null
                }
              >
                {t('SalesInvoices.DragAndDropImageHere')}
              </MyDropZone>

              <MyDropZonePreview
                filesToUpload={invoiceFileToUpload}
                clearFileFromUpload={(f) =>
                  clearFileFromUpload(f, setInvoiceFileToUpload)
                }
              />
              {errors?.invoice_image && (
                <p
                  className="error-p"
                  style={{ color: '#ff4d4f', fontSize: '14px' }}
                >
                  {errors?.invoice_image?.message}
                </p>
              )}
            </div>
          )}
          {String(watch('pay_type')) == '6' && (
            <div className="cheque-wrapper">
              <div className="text-field-label-wrap">
                <p className="label-p">{t('SalesInvoices.Cash')}</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name={`multiPaymentCash`}
                    type="text"
                    placeholder={t('SalesInvoices.EnterCashAmount')}
                    errorMsg={
                      errors?.multiPaymentCash &&
                      errors?.multiPaymentCash?.message
                    }
                    validateStatus={errors?.multiPaymentCash ? 'error' : ''}
                    control={control}
                    defaultValue={
                      renderFieldsTotalValue(watch('products')) &&
                      (watch('multiPaymentVisa')
                        ? renderFieldsTotalValue(watch('products')) -
                            parseFloat(watch('multiPaymentVisa')) >
                          0
                          ? Math.ceil(
                              parseFloat(
                                renderFieldsTotalValue(watch('products')) -
                                  parseFloat(watch('multiPaymentVisa'))
                              ) * 100
                            ) / 100
                          : 0
                        : null)
                    }
                  />
                </div>
                {/* {Number(renderFieldsTotalValue(watch('products')))
                  ? renderFieldsTotalValue(watch('products')) <
                      watch('multiPaymentCash') && (
                      <p
                        className="error-p"
                        style={{ color: '#ff4d4f', fontSize: '14px' }}
                      >
                        من فضلك أدخل رقم أقل من قيمة الفاتورة
                      </p>
                    )
                  : null} */}
              </div>
              <div className="text-field-label-wrap">
                <p className="label-p">{t('SalesInvoices.Visa')}</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name={`multiPaymentVisa`}
                    type="text"
                    placeholder={t('SalesInvoices.EnterNetworkAmount')}
                    errorMsg={
                      errors?.multiPaymentVisa &&
                      errors.multiPaymentVisa.message
                    }
                    validateStatus={errors?.multiPaymentVisa ? 'error' : ''}
                    control={control}
                    defaultValue={
                      renderFieldsTotalValue(watch('products')) &&
                      (watch('multiPaymentCash')
                        ? renderFieldsTotalValue(watch('products')) -
                            parseFloat(watch('multiPaymentCash')) >
                          0
                          ? Math.ceil(
                              parseFloat(
                                renderFieldsTotalValue(watch('products')) -
                                  parseFloat(watch('multiPaymentCash'))
                              ) * 100
                            ) / 100
                          : 0
                        : null)
                    }
                  />
                </div>
                {/* {Number(renderFieldsTotalValue(watch('products'))) &&
                !errors.multiPaymentVisa
                  ? renderFieldsTotalValue(watch('products')) <
                      watch('multiPaymentVisa') && (
                      <p
                        className="error-p"
                        style={{ color: '#ff4d4f', fontSize: '14px' }}
                      >
                        من فضلك أدخل رقم أقل من قيمة الفاتورة
                      </p>
                    )
                  : null} */}
              </div>
            </div>
          )}
        </div>

        {/* paytype section */}
        {/* <div
          className="radios-field-array-wrap no-border"
          style={{ paddingBottom: '22px' }}
        >
          <AntdRadioGroup
            name="paymentType"
            className="form-radio-group"
            control={control}
            label="اختار نوع الدفع"
            validateStatus={errors?.paymentType ? 'error' : ''}
            errorMsg={errors?.paymentType?.message}
            radios={paymentMethods}
            // isRadioButton
          />

          {String(watch('paymentType')) === '2' && (
            <div className="ingredients-add-btn-wrap">
              <div
                className="add-new-field-btn"
                onClick={() =>
                  appendInstallmentField({
                    price: '',
                    date: null,
                    note: '',
                    status: ''
                  })
                }
              >
                <span>+ اضافة ايصال جديد</span>
              </div>
              {renderInstallmentsFields(
                control,
                setValue,
                errors,
                installmentsFields,
                removeInstallmentField
              )}
            </div>
          )}

          {String(watch('paymentType')) == '4' && (
            <div className="cheque-wrapper">
              <div className="text-field-label-wrap">
                <p className="label-p">إســم البنك</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name={`cheque.bankName`}
                    type="text"
                    placeholder="إســم البنك..."
                    errorMsg={
                      errors?.cheque?.bankName && errors.cheque.bankName.message
                    }
                    validateStatus={errors?.cheque?.bankName ? 'error' : ''}
                    control={control}
                  />
                </div>
              </div>
              <div className="text-field-label-wrap">
                <p className="label-p">رقم الشيك</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name={`cheque.cheekNum`}
                    type="text"
                    placeholder="رقم الشيك..."
                    errorMsg={
                      errors?.cheque?.cheekNum && errors.cheque.cheekNum.message
                    }
                    validateStatus={errors?.cheque?.cheekNum ? 'error' : ''}
                    control={control}
                  />
                </div>
              </div>
            </div>
          )}

          {String(watch('paymentType')) == '5' && (
            <div className="cheque-wrapper">
              <div className="text-field-label-wrap">
                <p className="label-p">رقم الحساب</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name={`bankAccount.accountNumber`}
                    type="text"
                    placeholder="رقم الحساب..."
                    errorMsg={
                      errors?.bankAccount?.accountNumber &&
                      errors.bankAccount.accountNumber.message
                    }
                    validateStatus={
                      errors?.bankAccount?.accountNumber ? 'error' : ''
                    }
                    control={control}
                  />
                </div>
              </div>
              <div className="text-field-label-wrap">
                <p className="label-p">المبلغ</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name={`bankAccount.price`}
                    type="text"
                    placeholder="المبلغ..."
                    errorMsg={
                      errors?.bankAccount?.price &&
                      errors.bankAccount.price.message
                    }
                    validateStatus={errors?.bankAccount?.price ? 'error' : ''}
                    control={control}
                  />
                </div>
              </div>
            </div>
          )}
        </div> */}
        {/* ./paytype section */}

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedReceipt
            ? t('purchaseInvoices.Return')
            : t('purchaseInvoices.add')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;