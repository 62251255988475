/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import storeItemManagerImg from '../../../assets/imgs/store/store-item-manager.png';
import StoreWelcomeSharedSection from '../store-welcome-shared-section/StoreWelcomeSharedSection';
import TableFilter from './TableFilter';
import PageTable from './PageTable';
import PageModal from './PageModal';
import StorePurchaseOrderContext from '../../../contexts/store-purchase-order-context/StorePurchaseOrderProvider';
import './StorePurchasesPage.scss';
import SelectedPurchaseOrderModal from './SelectedPurchaseOrderModal';
import Countdown from '../../../components/countdown/Countdown';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

const StorePurchasesPage = () => {
  const { modalOpened, selectedOrderModalOpened } = useContext(
    StorePurchaseOrderContext
  );
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  return (
    <div className="store-item-manager-page">
      <div className="top-section-wrap">
        {/* <StoreWelcomeSharedSection
          img={storeItemManagerImg}
          title={t('PurchaseOrder.title')}
          subTitle={t('PurchaseOrder.subTitle')}
        /> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('PurchaseOrder.title')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.incomingPurchaseInvoice.title')}
            title3={t('PurchaseOrder.title')}
            link3={'/store/purchase-order'}
          />
        </div>
        {/* <Countdown /> */}

        <div className="search-wrap">
          <SearchNotificationsProfile noSearch />
        </div>
      </div>

      <TableFilter />
      <div className="table-wrapper">
        <PageTable currentLang={currentLang} />
      </div>

      {modalOpened && <PageModal />}
      {selectedOrderModalOpened && (
        <SelectedPurchaseOrderModal currentLang={currentLang} />
      )}
    </div>
  );
};

export default StorePurchasesPage;
