import React from 'react';

const UpgradeBoxIcon = ({ color }) => {
  return (
    <svg
      id="time"
      xmlns="http://www.w3.org/2000/svg"
      width="29.574"
      height="29.565"
      viewBox="0 0 29.574 29.565"
    >
      <path
        id="Path_800"
        data-name="Path 800"
        d="M27.605,15.969a13.352,13.352,0,1,0-13.251,11.75c.319,0,.637-.015.954-.038V30.1a.477.477,0,0,0,.477.477H30.095a.477.477,0,0,0,.477-.477V22A7.617,7.617,0,0,0,27.605,15.969ZM29.618,22v.477h-.954V22a5.724,5.724,0,1,0-11.448,0h-.954a6.678,6.678,0,1,1,13.355,0Zm-15.74.477v1.407a9.487,9.487,0,0,1-5.912-2.452l1-1-.674-.674-1,1a9.487,9.487,0,0,1-2.452-5.912H6.246v-.954H4.839A9.489,9.489,0,0,1,7.291,7.974l1,1L8.967,8.3l-1-1a9.487,9.487,0,0,1,5.912-2.452V6.255h.954V4.848A9.487,9.487,0,0,1,20.746,7.3l-1,1,.674.674,1-1a9.492,9.492,0,0,1,2.474,6.389v.066A7.584,7.584,0,0,0,15.308,22V23.85c-.158.016-.317.028-.477.036V22.472Zm.477,4.293a12.4,12.4,0,1,1,12.4-12.4c0,.334-.016.672-.043,1.006a7.581,7.581,0,0,0-1.87-.757c0-.083.006-.166.006-.249A10.494,10.494,0,1,0,14.354,24.857c.319,0,.637-.02.954-.048v1.916C14.992,26.748,14.673,26.765,14.354,26.765Zm6.2,0h4.77v.954h-4.77ZM19.6,23.9v-.954h6.678V23.9Zm5.724.954v.954h-4.77v-.954Zm-4.77,3.816h4.77v.954h-4.77Zm5.724.954v-4.77h.477a.477.477,0,0,0,.477-.477V22.472A.477.477,0,0,0,26.756,22H19.124a.477.477,0,0,0-.477.477V24.38a.477.477,0,0,0,.477.477H19.6v4.77H16.262V22.949h1.431a.477.477,0,0,0,.477-.477V22a4.77,4.77,0,1,1,9.54,0v.954a.477.477,0,0,0,.477.477h1.431v6.2Z"
        transform="translate(-0.998 -1.016)"
        fill={color ? color : '#2E4652'}
      />
      <path
        id="Path_801"
        data-name="Path 801"
        d="M45.431,37a1.431,1.431,0,1,0,1.431,1.431A1.431,1.431,0,0,0,45.431,37Zm0,1.908a.477.477,0,1,1,.477-.477A.477.477,0,0,1,45.431,38.908Z"
        transform="translate(-23.489 -19.836)"
        fill={color ? color : '#2E4652'}
      />
      <path
        id="Path_802"
        data-name="Path 802"
        d="M25.678,21.155a1.908,1.908,0,0,0-1.431-1.84V15.629l.617.617.674-.674L24.107,14.14a.477.477,0,0,0-.674,0L22,15.571l.674.674.617-.617v3.686a1.908,1.908,0,0,0-1.431,1.84,1.883,1.883,0,0,0,.27.963L19.954,24.3V23.54H19v1.908a.477.477,0,0,0,.477.477h1.908v-.954h-.756l2.178-2.178a1.883,1.883,0,0,0,.963.27,1.908,1.908,0,0,0,1.908-1.908Zm-1.908.954a.954.954,0,1,1,.954-.954A.954.954,0,0,1,23.77,22.109Z"
        transform="translate(-10.413 -7.807)"
        fill={color ? color : '#2E4652'}
      />
    </svg>
  );
};

export default UpgradeBoxIcon;
