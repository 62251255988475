/* eslint-disable eqeqeq */
import React from 'react';
import { Empty, Table } from 'antd';
import QRCode from 'qrcode.react';
import './receiptDetailsComponentV2.scss';
import { useTranslation } from 'react-i18next';

const ReceiptDetailsComponentV2 = React.forwardRef((props, ref) => {
  const { selectedReceipt } = props;
  const { t } = useTranslation();
  const renderDiscountValue = (record) => {
    if (record.discountType == '1') {
      // fixed discount price
      return record.discountPrice;
    } else if (record.discountType == '2') {
      // percentage discount
      return `${record.discountPrice}`;
    } else {
      // no discount
      return 'بدون خصم';
    }
  };

  const rowWrapStyles = {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gap: '8px'
  };

  const renderUser = (obj, type) => {
    const { receipt_holder, serial_number, date, username_reciept } = obj;
    return (
      <div
        className="supplier-details-wrap"
        style={{
          display: 'grid',
          width: 'fit-content'
        }}
      >
        <div
          style={{
            display: 'grid',
            gap: '10px'
          }}
        >
          {receipt_holder && (
            <div className="row-wrap" style={rowWrapStyles}>
              <h3 className="row-label">{t('Inventory.CashIn.receiptName')}</h3>
              <h3 className="row-value">{receipt_holder}</h3>
            </div>
          )}

          {serial_number && (
            <div className="row-wrap" style={rowWrapStyles}>
              <h3 className="row-label">{t('Inventory.CashIn.receiptCode')}</h3>
              <h3 className="row-value">{serial_number}</h3>
            </div>
          )}
          {date && (
            <div className="row-wrap" style={rowWrapStyles}>
              <h3 className="row-label">
                {t('Inventory.CashIn.receiptDueDate')}
              </h3>
              <h3 className="row-value">{date}</h3>
            </div>
          )}
          {username_reciept && (
            <div className="row-wrap" style={rowWrapStyles}>
              <h3 className="row-label">
                {t('Inventory.CashIn.receiptUserName')}
              </h3>
              <h3 className="row-value">{username_reciept}</h3>
            </div>
          )}
        </div>
      </div>
    );
  };
  const columns = [
    {
      title: t('Inventory.CashIn.payWay'),
      dataIndex: 'pay_way'
    },
    {
      title: t('Inventory.CashIn.paymentAccount'),
      dataIndex: 'pay_acc'
    },
    {
      title: t('Inventory.CashIn.tableAmount'),
      dataIndex: 'amount'
    },
    {
      title: t('Inventory.CashIn.notes'),
      dataIndex: 'title'
    },
    {
      title: t('Inventory.CashIn.signature'),
      dataIndex: 'sign'
    }
  ];
  const data = [
    {
      pay_acc: `${selectedReceipt?.account?.holder || '---'}`,
      pay_way: `${selectedReceipt?.payment_method?.name || '---'}`,
      amount: `${selectedReceipt.amount || '---'}`,
      title: `${selectedReceipt.title || '---'}`,
      sign: ''
    }
  ];
  const renderModels = () => {
    if (selectedReceipt) {
      return (
        <div className="receipt_table">
          <div
            className="models-title bold-font"
            style={{
              marginBottom: 5,
              fontWeight: 'bold'
            }}
          >
            {t('Inventory.CashIn.bondDescription')}
          </div>
          <Table
            pagination={false}
            bordered
            columns={columns}
            dataSource={data}
            size="large"
          />
        </div>
      );
    }
    return null;
  };

  const renderInovicePrices = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontWeight: '900'
        }}
      >
        {selectedReceipt?.amount && (
          <div className="row-wrap" style={rowWrapStyles}>
            <h2 className="row-label">{t('Inventory.CashIn.total')}</h2>
            <h2 className="row-value">{selectedReceipt?.amount}</h2>
          </div>
        )}
        {selectedReceipt?.price_word && (
          <div className="row-wrap" style={rowWrapStyles}>
            <h2 className="row-value">{selectedReceipt?.price_word}</h2>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="invoice-details-wrap"
      ref={ref}
      style={{
        padding: '28px 28px',
        paddingTop: 0,
        marginTop: '-16px',
        direction: 'rtl',
        display: 'grid',
        gap: '16px'
      }}
    >
      <div
        className="invoice-heading-wrap"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: 14
        }}
      >
        <div
          className="qr-code-label-wrap"
          style={{
            alignSelf: 'end',
            display: 'grid',
            justifyItems: 'center'
          }}
        >
          <div
            style={{
              fontSize: 22,
              color: '#000',
              fontWeight: 'bold',
              textAlign: 'center',
              marginTop: 15,
              marginBottom: 15
            }}
          >
            <h2>{t('Inventory.CashIn.title')}</h2>
          </div>
        </div>
        <div
          className="buyer-supplier-wrap"
          style={{
            display: 'grid',
            justifyContent: 'start'
          }}
        >
          {selectedReceipt && renderUser(selectedReceipt)}
          {/* {selectedReceipt?.supplier && renderUser(selectedReceipt?.supplier, 2)} */}
        </div>
      </div>

      {renderModels()}
      {renderInovicePrices()}
    </div>
  );
});

export default ReceiptDetailsComponentV2;
