/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import getAllIngredients from '../apis/store-apis/ingredients/getAllIngredients';
import UserContext from '../contexts/user-context/UserProvider';
import useCustomApiRequest from '../custom-hooks/useCustomApiRequest';
import checkRes from '../utils/checkRes';

const useIngredients = () => {
  const [allIngredients, setAllIngredients] = useState(null);

  const { user } = useContext(UserContext);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getAllIngredients(user?.token),
        (res) => {
          if (checkRes(res) && res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllIngredients(data);
          } else {
            //
          }
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return allIngredients;
};

export default useIngredients;
