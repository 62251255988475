import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import PageTable from './PageTable';
import PageModal from './PageModal';
import TableFilter from './TableFilter';
import SettingsSystemUsersContext from '../../../contexts/settings-contexts/SettingsSystemUsersProvider';
import './SystemUsersPage.scss';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

const SettingsSystemUsersPage = () => {
  const { modalOpened, setModalOpened, setSelectedUser } = useContext(
    SettingsSystemUsersContext
  );
  const { t } = useTranslation();

  return (
    <div className="settings-system-users-page">
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">{t('Users.Users')}</h2>
          <p>{t('Users.ListAndDisplayAllUsers')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('Users.Users')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title3={t('Users.Users')}
            link3={'/store/services'}
          />
        </div>
        <SearchNotificationsProfile />
      </div>

      <div className="custom-filter-add-section">
        <div className="add-section">
          <div
            className="add-btn"
            onClick={() => {
              // if selected unit then reset it
              // open the unit modal
              setSelectedUser(null);
              setModalOpened(true);
            }}
          >
            <span className="text-span">{t('Users.AddANewUser')}</span>
          </div>
        </div>
        <TableFilter />
      </div>
      <div className="SettingsSystemUsersPage-table-wrapper">
        <PageTable />
      </div>

      {modalOpened && <PageModal />}
    </div>
  );
};

export default SettingsSystemUsersPage;
