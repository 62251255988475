import HomeIcon from '../../common/icons/HomeIcon';
import AnalysisIcon from '../../common/icons/AnalysisIcon';
// import routerLinks from '../app/routerLinks';
import AccountingNewIcon from '../../common/icons/AccountingNewIcon';
import ArchiveIcon from '../../common/icons/ArchiveIcon';
import Links from '../app/Links';
const routerLinks = Links();
const partnerLinks = (t) => {
  return [
    {
      id: 1,
      to: routerLinks.homePage,
      name: t('AppBar.Services.homePage'),
      isLink: true,
      icon: (color) => (color ? <HomeIcon color={color} /> : <HomeIcon />),
      subMenu: [],
      visibleSubMenu: false
    },

    {
      id: 5,
      to: routerLinks.accountingTreePage,
      name: t('AppBar.Services.accountingPage.title'),
      isLink: true,
      icon: (color) =>
        color ? <AccountingNewIcon color={color} /> : <AccountingNewIcon />,
      visibleSubMenu: false,
      subMenu: []
      // subMenu: [
      //   {
      //     id: 1,
      //     to: routerLinks.accountsPage,
      //     name: 'الحسابات'
      //   },
      //   {
      //     id: 2,
      //     to: routerLinks.accountsTransactionsPage,
      //     name: 'المعاملات'
      //   },
      //   {
      //     id: 3,
      //     to: routerLinks.accountsRevenuesPage,
      //     name: 'الايرادات'
      //   },
      //   {
      //     id: 4,
      //     to: routerLinks.accountsExpensesPage,
      //     name: 'المصروفات'
      //   }
      // ]
    },

    {
      id: 91,
      to: routerLinks.analysisPage,
      name: t('AppBar.Services.analysisPage.title'),
      icon: (color) =>
        color ? <AnalysisIcon color={color} /> : <AnalysisIcon />,
      subMenu: [
        {
          id: 777,
          name: t(
            'AppBar.Products.analysisPage.incomingPurchaseInvoiceReports'
          ),
          to: routerLinks.incomingPurchaseInvoiceReports
        },
        {
          id: 888,
          name: t(
            'AppBar.Products.analysisPage.outgoingPurchaseInvoiceReports'
          ),
          to: routerLinks.outgoingPurchaseInvoiceReports
        },
        {
          id: 8723487,
          name: t(
            'AppBar.Services.analysisPage.outgoingPurchaseInvoiceReports'
          ),
          to: routerLinks.salesPageReports
        },
        {
          id: 772,
          name: t('AppBar.Products.analysisPage.taxInvoiceReportsPage'),
          to: routerLinks.taxInvoiceReportsPage
        }
      ],
      visibleSubMenu: false
    },
    {
      id: 11,
      to: routerLinks.archivePage,
      name: t('AppBar.Products.archivePage.title'),
      icon: (color) =>
        color ? <ArchiveIcon color={color} /> : <ArchiveIcon />,
      subMenu: [
        {
          id: 1,
          to: routerLinks.archiveNotesPage,
          name: t('AppBar.Products.archivePage.archiveNotesPage')
        },
        {
          id: 2,
          to: routerLinks.archiveCalendarPage,
          name: t('AppBar.Products.archivePage.archiveCalendarPage')
        },
        {
          id: 3,
          to: routerLinks.archiveDocsPage,
          name: t('AppBar.Products.archivePage.archiveDocsPage')
        }
      ],
      visibleSubMenu: false
    }
  ];
};

// const partnerLinks = [
//   {
//     id: 1,
//     to: routerLinks.homePage,
//     name: t('AppBar.Services.homePage'),
//     isLink: true,
//     icon: (color) => (color ? <HomeIcon color={color} /> : <HomeIcon />),
//     subMenu: [],
//     visibleSubMenu: false
//   },

//   {
//     id: 5,
//     to: routerLinks.accountingTreePage,
//     name: 'الحسابات',
//     isLink: true,
//     icon: (color) =>
//       color ? <AccountingNewIcon color={color} /> : <AccountingNewIcon />,
//     visibleSubMenu: false,
//     subMenu: []
//     // subMenu: [
//     //   {
//     //     id: 1,
//     //     to: routerLinks.accountsPage,
//     //     name: 'الحسابات'
//     //   },
//     //   {
//     //     id: 2,
//     //     to: routerLinks.accountsTransactionsPage,
//     //     name: 'المعاملات'
//     //   },
//     //   {
//     //     id: 3,
//     //     to: routerLinks.accountsRevenuesPage,
//     //     name: 'الايرادات'
//     //   },
//     //   {
//     //     id: 4,
//     //     to: routerLinks.accountsExpensesPage,
//     //     name: 'المصروفات'
//     //   }
//     // ]
//   },

//   {
//     id: 91,
//     to: routerLinks.analysisPage,
//     name: 'البيانات والتقارير',
//     icon: (color) =>
//       color ? <AnalysisIcon color={color} /> : <AnalysisIcon />,
//     subMenu: [
//       {
//         id: 777,
//         name: 'تقرير فواتير الشراء الواردة',
//         to: routerLinks.incomingPurchaseInvoiceReports
//       },
//       {
//         id: 888,
//         name: 'تقرير فواتير البيع الصادرة',
//         to: routerLinks.outgoingPurchaseInvoiceReports
//       },
//       {
//         id: 8723487,
//         name: 'تقرير فواتير البيع',
//         to: routerLinks.salesPageReports
//       },
//       {
//         id: 772,
//         name: 'تقرير الفاتورة الضريبية',
//         to: routerLinks.taxInvoiceReportsPage
//       }
//     ],
//     visibleSubMenu: false
//   },
//   {
//     id: 11,
//     to: routerLinks.archivePage,
//     name: 'الارشيف',
//     icon: (color) => (color ? <ArchiveIcon color={color} /> : <ArchiveIcon />),
//     subMenu: [
//       {
//         id: 1,
//         to: routerLinks.archiveNotesPage,
//         name: 'ملاحظات'
//       },
//       {
//         id: 2,
//         to: routerLinks.archiveCalendarPage,
//         name: 'تقويم'
//       },
//       {
//         id: 3,
//         to: routerLinks.archiveDocsPage,
//         name: 'وثائق'
//       }
//     ],
//     visibleSubMenu: false
//   }
// ];

export default partnerLinks;
