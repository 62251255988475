import axios from 'axios';
import moment from 'moment';
const date = new Date();
const getAllSummaryApi = async (token, filterValues) => {
  try {
    const res = await axios({
      method: 'get',
      // url: `accounting/report/trial_balance`,
      // url: `accounting\report\trial_balance_tree`,
      url: `accounting/report/trial_balance_tree?from_date=${
        filterValues?.from ? filterValues?.from : moment("2023-01-01").format("YYYY-MM-DD")
      }&to_date=${filterValues?.to ? filterValues?.to : moment(date).format("YYYY-MM-DD")}`,
      baseURL: process.env.REACT_APP_BASE_TEST_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllSummaryApi;
