import axios from 'axios';

const getSingleInvoiceApi = async (token, filterValues) => {
  try {
    const res = await axios({
      method: 'get',
      baseURL: process.env.REACT_APP_POS_BASE_URL,
      url: `/saleInvoice/single?id=${
        filterValues?.id ? filterValues.id : ''
      }&invoiceNumber=${
        filterValues?.invoiceNumber ? filterValues.invoiceNumber : ''
      }&qr_search=${
        filterValues?.qr_search ? filterValues.qr_search : ''
      }`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getSingleInvoiceApi;
