/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import TableFilter from './TableFilter';
import SaleInvoicesContext from '../../../contexts/sale-invoices-context/SaleInvoicesContext';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import UserContext from '../../../contexts/user-context/UserProvider';
import SharedSalesInvoice from './SharedSalesInvoice';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import './SaleInvoicesPage.scss';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import getAllSaleInvoicesApi from '../../../apis/sales-apis/getAllSaleInvoicesApi';
import getProfitLosesReportsApi from '../../../apis/profit-loses-api/getProfitLosesReportsApi';
import ProfitLosesReportsContext from '../../../contexts/profit-loses-reports-context/ProfitLosesReportsContext';
import PageTable from './PageTable';
const ProfitAndLosesPage = (reports) => {
  const { user } = useContext(UserContext);
  const { search } = useLocation();
  const { t } = useTranslation();
  const values = queryString.parse(search);
 
  const {
    allFetchedProfitLosesReports,
    setIsLoadingAllProfitLosesReports,
    setAllFetchedProfitLosesReports,
    setFetchCountProfitLosesReports,
    fetchCountProfitLosesReports,
    tableFilter,
    setTablePagination,
    tablePagination
  } = useContext(ProfitLosesReportsContext);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoadingAllProfitLosesReports(true);
      customApiRequest(
        getProfitLosesReportsApi( user?.token, {
          ...tableFilter,
          ...values,
          client_id: user?.client_id,
          page: tablePagination?.current_page
        }),
        (res) => {
          setIsLoadingAllProfitLosesReports(false);
          if (res?.status === 200 && res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllFetchedProfitLosesReports(data);
          }
          if (res?.data?.data?.pagination) {
            setTablePagination(res.data.data.pagination);
          }
        },
        (error) => {
          setIsLoadingAllProfitLosesReports(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="sale-invoices-page">
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">{t('CashiersalesInvoiceReport.title')}</h2>
          <p>{t('CashiersalesInvoiceReport.subTitle')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('ProfitAndLossReports.title')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.analysisPage.title')}
            title3={t('ProfitAndLossReports.title')}
            link3={'/sales-reports'}
          />
        </div>
        <SearchNotificationsProfile />
      </div>

      <div className="custom-filter-add-section1">
        <TableFilter allFetchedProfitLosesReports={allFetchedProfitLosesReports} />
      </div>

      <div className="table-wrapper">
        <PageTable
          allFetchedSaleInvoices={allFetchedProfitLosesReports}
        />
        {/* <SharedSalesInvoice
          allFetchedProfitLosesReports={allFetchedProfitLosesReports}
        /> */}
      </div>
    </div>
  );
};

export default ProfitAndLosesPage;
