import axios from 'axios';

const updateProfileApi = async (token, values) => {
  try {
    const signinRes = await axios.post('/Auth_private/edit_profile', values, {
      headers: {
        lang: 'ar',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      }
    });
    return signinRes;
  } catch (error) {
  }
};

export default updateProfileApi;
