import axios from 'axios';

const addPaymentsApi = async (token, values) => {

  // let formData = new FormData();
  // formData.append('payment_methods', JSON.stringify(values?.payment_methods));

  try {
    const res = await axios.post('/invoice-setting/payments', values , {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default addPaymentsApi;
