import axios from 'axios';

const createPriceOfferApiNew = async (url, token, values, edit = false) => {
  try {
    // let url = '/OfferPriceService/create';
    // if (!edit) url = '/BuyInvoice/create';
    // else url = '/BuyInvoice/update';

    const res = await axios.post(url, values, {
      headers: {
        lang: 'ar',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default createPriceOfferApiNew;
