/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, createContext } from 'react';

const INITIAL_VALUES = {
  step: 0,
  setStep: (v) => {},
  isLastStep: false,
  setIsLastStep: (v) => {},
  childrenArray: null,
  setChildrenArray: (v) => {},
  currentChild: null,
  setCurrentChild: (v) => {}
};

const ReactHookFormContext = createContext(INITIAL_VALUES);

export const ReactHookFormProvider = ({ children }) => {
  const [step, setStep] = useState(INITIAL_VALUES.step);
  const [isLastStep, setIsLastStep] = useState(INITIAL_VALUES.isLastStep);
  const [childrenArray, setChildrenArray] = useState(
    INITIAL_VALUES.childrenArray
  );
  const [currentChild, setCurrentChild] = useState(INITIAL_VALUES.currentChild);

  useEffect(() => {
    if (childrenArray?.length > 0) {
      setCurrentChild(childrenArray[step]);
      setIsLastStep(step === childrenArray.length - 1);
    }
  }, [step, childrenArray]);

  // const isLastStep = () => {
  //   return step === childrenArray.length - 1;
  // };

  return (
    <ReactHookFormContext.Provider
      value={{
        step,
        setStep,
        isLastStep,
        setIsLastStep,
        childrenArray,
        setChildrenArray,
        currentChild,
        setCurrentChild
      }}
    >
      {children}
    </ReactHookFormContext.Provider>
  );
};

export default ReactHookFormContext;
