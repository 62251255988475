/* eslint-disable react-hooks/exhaustive-deps */
import { CaretLeftFilled } from '@ant-design/icons';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import CustomImage from '../../../common/custom-image/CustomImage';
import HomeIcon from '../../../common/icons/HomeIcon';
import routerLinks from '../../../components/app/routerLinks';
import './SettingsHelpPage.scss';
import helpPageLinks from './helpPageLinks';

const SettingsHelpPage = () => {
  const [selectedMainPageLink, setSelectedMainPageLink] = useState(
    helpPageLinks()[0]
  );
  const { t } = useTranslation();
  const [selectedSubPageLink, setSelectedSubPageLink] = useState(null);
  useEffect(() => {
    if (selectedMainPageLink?.subMenu?.subLinks?.length > 0) {
      setSelectedSubPageLink(selectedMainPageLink.subMenu.subLinks[0]);
    }
  }, [selectedMainPageLink]);

  const handleMainPageLinkClick = (obj) => {
    setSelectedMainPageLink(obj);
    if (!obj?.subMenu) {
      setSelectedSubPageLink(null);
    }
  };

  const handleSubPageClick = (obj, e) => {
    setSelectedSubPageLink(obj);
    e.stopPropagation();
  };

  const renderNestedLinks = (obj) => {
    return (
      <ul className="nested-ul">
        {obj?.subLinks.length > 0 &&
          obj.subLinks.map((item) => {
            return (
              <li
                className={`${
                  selectedMainPageLink.id === obj.parent_id &&
                  selectedSubPageLink?.id === item.id
                    ? 'selected'
                    : ''
                }`}
                key={item.id}
                onClick={(e) => handleSubPageClick(item, e)}
              >
                <div className="name-icon-wrap">
                  <span className="name-span">{item.name}</span>
                  {item?.subMenu?.subLinks?.length > 0 && (
                    <div className="caret-left-icon-wrap">
                      <CaretLeftFilled />
                    </div>
                  )}
                </div>
              </li>
            );
          })}
      </ul>
    );
  };

  const renderHelpPageLinks = () => {
    return (
      helpPageLinks()?.length > 0 &&
      helpPageLinks().map((obj) => {
        return (
          <li
            className={`${
              selectedMainPageLink?.id === obj.id ? 'selected' : ''
            }`}
            key={obj.id}
          >
            <div
              className="name-icon-wrap"
              onClick={() => handleMainPageLinkClick(obj)}
            >
              <span className="name-span">{obj.name}</span>
              {obj?.subMenu?.subLinks?.length > 0 && (
                <div className="caret-left-icon-wrap">
                  <CaretLeftFilled />
                </div>
              )}
            </div>
            {obj?.id === selectedMainPageLink?.id &&
              selectedMainPageLink?.subMenu?.subLinks?.length > 0 &&
              renderNestedLinks(selectedMainPageLink?.subMenu)}
          </li>
        );
      })
    );
  };

  const handleImgSrc = () => {
    if (selectedMainPageLink?.img && selectedSubPageLink?.img)
      return selectedSubPageLink.img;
    else if (selectedMainPageLink && !selectedSubPageLink)
      return selectedMainPageLink.img;
    return '';
  };

  return (
    <div className="settings-help-page-wrapper">
      <h1 className="page-main-title">{t('help.FatawaUserGuide')}</h1>
      <div className="page-links-img-wrapper">
        <div className="home-main-links-wrap">
          <RouterLink className="page-home-link" to={routerLinks.homePage}>
            <HomeIcon color="#b5b5b5" />
            {t('help.Home')}
          </RouterLink>
          <ul className="help-page-links">{renderHelpPageLinks()}</ul>
        </div>
        <div className="page-img-wrap">
          <CustomImage src={handleImgSrc()} />
          {/* <img
            src={handleImgSrc()}
            alt={selectedMainPageLink?.name ? selectedMainPageLink.name : 'alt'}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default SettingsHelpPage;
