/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import StoreWelcomeSharedSection from '../store-welcome-shared-section/StoreWelcomeSharedSection';
import StoreItemManagerTable from './StoreItemManagerTable';
import StoreItemManagerTableFilter from './StoreItemManagerTableFilter';
import storeItemManagerImg from '../../../assets/imgs/store/store-item-manager.png';
import SelectedProductModal from './SelectedProductModal';
import './StoreItemManagerPage.scss';
import ProductBarCodeModal from './ProductsBarCodeModal';
import StoreNewProductModalContext from '../../../contexts/store-new-product-context/StoreNewProductProvider';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
const StoreItemManagerPage = () => {
  const { barCodeModalOpened } = React.useContext(StoreNewProductModalContext);
  const { t } = useTranslation();
  return (
    <div className="store-item-manager-page">
      <div className="top-section-wrap">
        {/* <StoreItemManagerHelloCard /> */}
        {/* <StoreWelcomeSharedSection
          img={storeItemManagerImg}
          title={t('services.title')}
          subTitle={t('services.subTitle')}
        /> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('AppBar.Services.storePage.title')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title3={t('AppBar.Services.storePage.title')}
            // title3={t('AppBar.Services.storePage.storeItemManagerPage')}
            // link3={'/store/services'}
          />
        </div>
        <div className="search-wrap">
          <SearchNotificationsProfile noSearch />
        </div>
      </div>
      <StoreItemManagerTableFilter />
      <div className="table-wrapper">
        <StoreItemManagerTable />
      </div>
      <SelectedProductModal />
      {barCodeModalOpened && <ProductBarCodeModal />}
      {/* {selectedProductModalOpened && <SelectedProductModal />} */}
    </div>
  );
};

export default StoreItemManagerPage;
