import axios from 'axios';

const updateAccountingLeafApi = async (token, values, url) => {
  const formData = new FormData();
  const keys = Object.keys(values)
  keys.forEach(elem => {
    formData.append(elem,values[elem])
  });
  
    try {
      const res = await axios({
          method: 'post',
          url,
          baseURL: process.env.REACT_APP_BASE_TEST_URL,
          // baseURL: 'https://testclient-api.fatoorah.sa/apiAdmin',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'applications/json',
            Authorization: 'Bearer ' + token,
            lang: 'ar'
          },
          data: formData
      });

      return res;
    } catch (error) {
      throw error;
    }
};

export default updateAccountingLeafApi;
