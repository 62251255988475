import React, { useContext } from 'react';
import { Modal } from 'antd';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import ProductDiscountForm from './ProductDiscountForm';
import { useTranslation } from 'react-i18next';
const ProductDiscountModal = () => {
  const {
    foundProduct,
    setFoundProduct,
    productDiscountModalOpened,
    setproductDiscountModalOpened
  } = useContext(PosInvoiceContext);
  const { t } = useTranslation()
  return (
    <Modal
      transitionName=""
      className="settings-users-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '542px' }}
      title={t('Pos.Main.productDiscount')}
      visible={productDiscountModalOpened}
      onOk={() => {
        setproductDiscountModalOpened(false);
        setFoundProduct(null);
      }}
      onCancel={() => {
        setproductDiscountModalOpened(false);
        setFoundProduct(null);
      }}
      footer={false}
    >
      <ProductDiscountForm />
    </Modal>
  );
};

export default ProductDiscountModal;
