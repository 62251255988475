import React, { useContext } from 'react';
import searchAllSuppliersApi from '../../apis/store-apis/suppliers/searchAllSuppliersApi';
// import { Select } from 'antd';
import SearchIcon from '../../common/icons/SearchIcon';
import SuppliersContext from '../../contexts/suppliers-context/SuppliersContext';
import UserContext from '../../contexts/user-context/UserProvider';
import checkRes from '../../utils/checkRes';
import PageModal from './PageModal';
import './TableFilter.scss';
import { exportToExcel } from 'react-json-to-excel';
import getAllSuppliersApi from '../../apis/store-apis/suppliers/getAllSuppliersApi';
import excelImg from '../../assets/imgs/icons/excel-btn.png';
import ExcelModal from './ExcelModal';
import { useTranslation } from 'react-i18next';
const TableFilter = () => {
  // const { Option } = Select;
  const {
    setNameSearchValue,
    nameSearchValue,
    modalOpened,
    setModalOpened,
    excelModalOpened,
    setExcelModalOpened,
    setSelectedSupplier,
    tablePagination,
    setTablePagination,
    setIsLoading,
    setAllFetchedSuppliers
  } = useContext(SuppliersContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const handleTableSearch = async (e) => {
    setNameSearchValue(e.target.value);
    try {
      setIsLoading(true);
      let res = await searchAllSuppliersApi(user?.token, {
        page: tablePagination.current_page,
        searchText: e.target.value
      });

      if (res) {
        setIsLoading(false);
        if (checkRes(res) && res?.data?.data?.data?.length >= 0) {
          const data = res.data.data.data;
          setAllFetchedSuppliers(data);
        }
        if (res?.data?.data?.pagination) {
          setTablePagination(res.data.data.pagination);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleExcel = async () => {
    let data;
    try {
      const res = await getAllSuppliersApi(
        user?.token,
        {
          searchText: nameSearchValue ? nameSearchValue : '',
          page: ''
        },
        true
      );
      if (res.status === 200) {
        data = res?.data?.data;
        exportToExcel(data, t('suppliers.suppliers'));
      }
    } catch (error) {
      
    }
  };

  return (
    <div className="custom-filter-add-section">
      <div className="add-section">
        <div
          className="add-btn"
          onClick={() => {
            // if selected supplier then reset it
            // open the supplier modal
            setSelectedSupplier(null);
            setModalOpened(true);
          }}
        >
          <span className="text-span">{t('suppliers.addNewSupplier')}</span>
        </div>
      </div>

      <div className="custom-table-fitler">
        <div className="form-excel-export-wrap">
          <form className="filter-form">
            <div className="table-search">
              <label>
                <SearchIcon />
                <input
                  placeholder={t('suppliers.searchSupplierNamePlaceholder')}
                  type="text"
                  name="table_search"
                  onKeyUp={handleTableSearch}
                />
              </label>
            </div>
          </form>
          <div className="excel-btn-wrap" onClick={() => handleExcel()}>
            <img
              style={{ height: '42px' }}
              src={excelImg}
              alt="export to excel"
            />
          </div>
          {/* <div className="excel-btn-wrap">
            <a
              href={`${process.env.REACT_APP_BASE_URL}/Excel/exportSuppliers?client_id=${systemSettings?.id}`}
              download
              className="excel-export-btn"
            >
              <img
                style={{ height: '42px' }}
                src={excelImg}
                alt="export to excel"
              />
            </a>
          </div> */}
        </div>
      </div>

      <div
        className="preview-download-upload add-btn"
        onClick={(e) => {
          setExcelModalOpened(true);
          e.stopPropagation();
        }}
      >
        <span className="text-span">{t('suppliers.uploadSuppliersData')}</span>
      </div>

      {modalOpened && <PageModal />}
      {excelModalOpened && <ExcelModal />}
    </div>
  );
};

export default TableFilter;
