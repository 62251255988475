import React, { useContext } from 'react';
import { Modal } from 'antd';
import ArchiveNotesContext from '../../../contexts/archive-contexts/ArchiveNotesProvider';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

const StickyNoteModal = () => {
  const {
    stickyNoteModalOpened,
    setStickyNoteModalOpened,
    setSelectedNote,
    selectedNote
  } = useContext(ArchiveNotesContext);
  const { t } = useTranslation();

  const renderDescNote = () => {
    return selectedNote?.note ? (
      <div className="sticky-note-wrap">
        <div className="desc-wrap">{parse(selectedNote.note)}</div>
      </div>
    ) : (
      ''
    );
  };

  return (
    <Modal
      // transitionName=""
      className="shared-custom-modal sticky-modal"
      width="90%"
      style={{ maxWidth: '532px' }}
      title={t('Notes.NoteDetails')}
      visible={stickyNoteModalOpened}
      onOk={() => {
        setStickyNoteModalOpened(false);
        setSelectedNote(null);
      }}
      onCancel={() => {
        setStickyNoteModalOpened(false);
        setSelectedNote(null);
      }}
      footer={false}
    >
      {renderDescNote()}
    </Modal>
  );
};

export default StickyNoteModal;
