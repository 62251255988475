import axios from 'axios';

const addTaxSettingsApi = async (token, values, taxChecked) => {
  let formData = new FormData(); //formdata object
  formData.append('active_tax', taxChecked ? 1 : 0);
  formData.append('tax', values?.tax);
  // formData.append('buy_tax', values?.buy_tax);
  // formData.append('sale_tax', values?.sale_tax);
  formData.append('tax_num', values?.tax_num);
  formData.append('tax_name', values?.tax_name);
  formData.append('including_tax', Number(values?.including_tax));
  try {
    const res = await axios.post('/invoice-setting/tax', formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default addTaxSettingsApi;
