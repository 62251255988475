/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import getAllSuppliersApi from '../apis/store-apis/suppliers/getAllSuppliersApi';
import UserContext from '../contexts/user-context/UserProvider';
import useCustomApiRequest from './useCustomApiRequest';
import checkRes from '../utils/checkRes';

const useSuppliers = () => {
  const [isLoadingSuppliers, setIsLoadingSuppliers] = useState(false);
  const [allSuppliersFetchCount, setAllSuppliersFetchCount] = useState(null);
  const [allSuppliers, setAllSuppliers] = useState(null);
  const { user } = useContext(UserContext);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoadingSuppliers(true);
      customApiRequest(
        getAllSuppliersApi(user?.token, false, false),
        (res) => {
          setIsLoadingSuppliers(false);
          if (checkRes(res) && res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllSuppliers(data);
          }
        },
        (error) => {
          setIsLoadingSuppliers(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [allSuppliersFetchCount]);

  return {
    allSuppliers,
    isLoadingSuppliers,
    allSuppliersFetchCount,
    setAllSuppliersFetchCount
  };
};

export default useSuppliers;
