import { Modal } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ClientsContext from '../../contexts/clients-context/ClientsContext';
import './PageModal.scss';
import TableForm from './TableForm';

const PageModal = () => {
  const { modalOpened, setModalOpened, selectedClient, setSelectedClient } =
    useContext(ClientsContext);
  const { t } = useTranslation();

  return (
    <Modal
      className="new-supplier-modal "
      width="90%"
      style={{ maxWidth: '642px' }}
      title={
        selectedClient
          ? t('Quotations.editClient')
          : t('Quotations.AddNewClient')
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedClient(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedClient(null);
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default PageModal;
