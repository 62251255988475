/* eslint-disable react-hooks/exhaustive-deps */
import { Empty, Pagination, Table } from 'antd';
import React, { useContext, useEffect } from 'react';
import tableColumns from './tableColumns';
// import catsTableData from './catsTableData';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import storeRouterLinks from '../../../components/app/store-routes/storeRouterLinks';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import tableColumnsProducts from './tableColumnsProducts';
import getAllIncomingPurchaseInvoicesApi from '../../../apis/store-apis/incoming-purchas-invoices-apis/getAllIncomingPurchaseInvoicesApi';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import getAllTaxInvoiceReportsApi from '../../../apis/reports-apis/getAllTaxInvoiceReportsApi';
// import TaxInvoiceReportsContext from '../../../contexts/reports-contexts/TaxInvoiceReportsContext';

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const PageTable = ({
  allFetchedReceipts,
  withoutReturnBtn,
  isReportsPage,
  taxPage,
  selectedPicker,
  tab
}) => {
  const history = useHistory();
  const {
    fetchCount,
    isLoading,
    setIsLoading,
    setModalOpened,
    setFetchCount,
    setSelectedReceipt,
    setSelectedReceiptModalOpened,
    setInstallmentModalOpened,
    tablePagination,
    setTablePagination,
    setAllFetchedReceipts,
    tableFilter,
    setTableFilter
  } = useContext(OutgoingPurchaseInvoiceContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const user_id_from_payment = pathname?.split('/')[3];
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };
  const handleTableColumns = (User) => {
    if (User === 1) {
      return tableColumnsProducts(
        allFetchedReceipts,
        setModalOpened,
        setFetchCount,
        setSelectedReceipt,
        setIsLoading,
        setSelectedReceiptModalOpened,
        setInstallmentModalOpened,
        withoutReturnBtn,
        user?.token,
        taxPage,
        tab
      );
    } else if (User === 2) {
      return tableColumns(
        allFetchedReceipts,
        setModalOpened,
        setFetchCount,
        setSelectedReceipt,
        setIsLoading,
        setSelectedReceiptModalOpened,
        setInstallmentModalOpened,
        withoutReturnBtn,
        user?.token,
        taxPage
      );
    }
  };

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedReceipts?.invoices?.length >= 0) {
      if (isMounted) {
        setTableDataSource(mapDataSource(allFetchedReceipts?.invoices));
      }
    }
    if (allFetchedReceipts?.length >= 0) {
      if (isMounted) {
        setTableDataSource(mapDataSource(allFetchedReceipts));
      }
    }

    if (taxPage) {
      setTableDataSource(mapDataSource(allFetchedReceipts.data));
      setTablePagination(allFetchedReceipts.pagination);
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedReceipts, allFetchedReceipts?.length]);

  const handlePageChange = async (page) => {
    try {
      setIsLoading(true);
      if (taxPage) {
        let res = await getAllTaxInvoiceReportsApi(user?.token, {
          page: page,
          from: selectedPicker?.strings[0],
          to: selectedPicker?.strings[1]
        });
        if (res?.data?.data && res?.data.status) {
          setIsLoading(false);
          if (user.subscriptionType === 1) {
            setAllFetchedReceipts(res.data.data.buySader.data);
            setTablePagination(res.data.data.buySader.pagination);
            setTableDataSource(res.data.data.buySader.data);
          } else if (user.subscriptionType === 2) {
            setAllFetchedReceipts(res.data.data.saleInvoice.data);
            setTablePagination(res.data.data.saleInvoice.pagination);
            setTableDataSource(res.data.data.saleInvoice.data);
          }
          setIsLoading(false);
        }
      } else {
        let res = await getAllIncomingPurchaseInvoicesApi(user?.token, {
          page: page,
          from: selectedPicker?.strings[0],
          to: selectedPicker?.strings[1],
          ...tableFilter,
          user_id_from_payment: user_id_from_payment
        });
        if (res?.data?.data?.invoices && res?.data.status) {
          setIsLoading(false);
          if (user.subscriptionType === 1) {
            setAllFetchedReceipts(res.data.data.invoices.data);
            setTablePagination(res.data.data.invoices.pagination);
            setTableDataSource(res.data.data.invoices.data);
          } else if (user.subscriptionType === 2) {
            setAllFetchedReceipts(res.data.data.saleInvoice.data);
            setTablePagination(res.data.data.saleInvoice.pagination);
            setTableDataSource(res.data.data.saleInvoice.data);
          }
          setIsLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <section>
      <Table
        locale={{
          emptyText: (
            <Empty description={false}>{t('Messages.noDataAvailable')}</Empty>
          )
        }}
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={handleTableColumns(user.subscriptionType)}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />

      {tablePagination && (
        <Pagination
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px'
          }}
          defaultCurrent={1}
          showSizeChanger={false}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}
          onChange={(page, pageSize) => {
            if (user.subscriptionType === 1) {
              setTablePagination({ ...tablePagination, current_page: page });
              setFetchCount((prev) => prev + 1);
              if (taxPage) {
                setTablePagination({ ...tablePagination, current_page: page });
                handlePageChange(page);
              }
            } else if (user.subscriptionType === 2) {
              // setFetchCount((prev) => prev + 1);
              setTablePagination({ ...tablePagination, current_page: page });
              setFetchCount((prev) => prev + 1);
              if (!taxPage) {
                if (isReportsPage) {
                  history.push(
                    `${storeRouterLinks?.outgoingPurchaseInvoiceReports}?page=${page}`
                  );
                } else {
                  history.push(
                    `${storeRouterLinks?.outgoingPurchaseInvoice}?page=${page}`
                  );
                }
              } else {
                handlePageChange(page);
              }
            }
          }}
          hideOnSinglePage={true}
        />
      )}
    </section>
  );
};

export default PageTable;
