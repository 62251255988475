import axios from 'axios';

const getAccountStatementDataApi = async (token, Values) => {
  let url = `accountingTree/accounts/account-statement?account_id=${Values.accountsListId}&type=${Values.accountsType}&from_date=${Values.selectedPicker.from}&to_date=${Values.selectedPicker.to}`
  try {
    const res = await axios({
      method: 'get',
      url: url,
      baseURL: process.env.REACT_APP_BASE_TEST_URL,
      // baseURL: "https://testclient-api.fatoorah.sa/apiAdmin",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAccountStatementDataApi;
