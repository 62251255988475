import axios from 'axios';

const OtpVerificationApi = async (token, phone, otp) => {
  const formData = new FormData();
  formData.append('phone', phone);
  formData.append('otp', otp);

  try {
    const response = await axios.post('/Auth_general/validate_otp', formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export default OtpVerificationApi;
