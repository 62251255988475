import Modal from 'antd/lib/modal/Modal';
import React, { useContext } from 'react';
import { Descriptions, Form, Spin } from 'antd';
import './PosBoxModal.scss';
import PosReportContext from '../../../../contexts/pos-report-context/PosReportContext';
import UserContext from '../../../../contexts/user-context/UserProvider';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import { Button } from '@mui/material';
import AntdTextField from '../../../../common/antd-form-components/AntdTextField';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useCustomApiRequest from '../../../../custom-hooks/useCustomApiRequest';
import errorNotification from '../../../../utils/errorNotification';
import successNotification from '../../../../utils/successNotification';
import addAmountBox from '../../../../apis/pos-api/addAmountBox';
import { useTranslation } from 'react-i18next';

const PosBoxModal = () => {
  const { t } = useTranslation();

  const componentRef = useRef();
  const schema = Yup.object().shape({
    shiftMoney: Yup.string()
      .required(t('ShiftReport.EnterAmount'))
      .matches(/^(\d+)?(\.\d+)?$/, t('ShiftReport.OnlyNumbersAllowed'))
  });
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @media print {
      html, body { height: initial !important; overflow: initial !important; }

      tbody { display: table-row-group !important; }
      thead {
        display: table-row-group !important;
      }
      tfoot {
        display: table-row-group !important;
      }
      @page {
        size: auto;
        margin: none;
      }
      .qr-code-wrap canvas {
        width: 94px !important;
        height: 94px !important;
      }
      .pagebreak {
        display: block;
        size: auto;
      }
      .invoice-details-wrap {
        padding: 0;
      }
      .ant-descriptions-item-content,
        .ant-descriptions-item-label {
          padding: 4px 12px !important;
        }
      }
      .ant-descriptions-header {
        margin: 4px 0 !important;
      }
      .models-title {
        direction: rtl;
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .ant-table-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
      }
      th {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      .invoice-extra-prices-table .ant-table-cell {
        padding: 4px 12px !important;
        text-align: right;
      }
    `
  });
  const { posReportIsLoading, posReport, setFetchCount } =
    useContext(PosReportContext);
  const { posBoxModalOpened, setPosBoxModalOpened, user } =
    useContext(UserContext);

  const {
    control,
    setValue,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      shiftMoney: ''
    }
  });

  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    const mappedData = new FormData();
    if (data.shiftMoney) mappedData.append('shiftMoney', data.shiftMoney);
    // setIsSubmittingForm(true);
    // setIsLoading(true);

    customApiRequest(
      addAmountBox(user?.token, mappedData),
      (res) => {
        if (res?.status === 200 && res?.data?.status == 1) {
          setPosBoxModalOpened(false);
          successNotification({
            title: t('Messages.addedSuccessfully'),
            message: t('Messages.addsuccessfully')
          });
        } else {
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message: res?.data?.message
              ? res.data.message
              : t('Messages.TryAgainLater')
          });
        }
      },
      (error) => {
        // setIsSubmittingForm(false);
        // setIsLoading(false);
        errorNotification({
          title: t('Messages.ErrorOccurred'),
          message: t('Messages.TryAgainLater')
        });
      }
    );
  };

  const [form] = Form.useForm();

  const renderAmountField = () => {
    return (
      <Form
        className="custom-shared-form add-amount-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className="text-field-label-wrap">
            <p className="label-p">{t('ShiftReport.Amount')}</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="shiftMoney"
                type="text"
                placeholder={t('ShiftReport.Amount') + '...'}
                errorMsg={errors?.shiftMoney && errors.shiftMoney.message}
                validateStatus={errors?.shiftMoney ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            // loading={isSubmittingForm}
          >
            {t('ShiftReport.AddToBox')}
          </Button>
        </div>
      </Form>
    );
  };
  const renderReportDetails = () => {
    return (
      <div className="report-modal-content" ref={componentRef}>
        {/* <Descriptions title="بيانات الموظف" bordered column={1}>
          {posReport.employee?.name && (
            <Descriptions.Item label="إســـم الموظف">
              {posReport.employee.name}
            </Descriptions.Item>
          )}
          {posReport.employee?.phone && (
            <Descriptions.Item label="رقم الهاتف">
              {posReport.employee.phone}
            </Descriptions.Item>
          )}
        </Descriptions> */}
        {/* <Descriptions
          style={{ marginTop: 14 }}
          title="بيانات نقطة البيع"
          bordered
          column={1}
        >
          {posReport.pos?.name && (
            <Descriptions.Item label="نقطة البيع">
              {posReport.pos.name}
            </Descriptions.Item>
          )}
          {posReport.pos?.balance && (
            <Descriptions.Item label="الحســاب الكلى">
              {posReport.pos.balance}
            </Descriptions.Item>
          )}
          {posReport.pos?.cash && (
            <Descriptions.Item label="حساب الكاش">
              {posReport.pos.cash}
            </Descriptions.Item>
          )}
          {posReport.pos?.visa && (
            <Descriptions.Item label="حساب الفيزا">
              {posReport.pos.visa}
            </Descriptions.Item>
          )}
        </Descriptions> */}
        {renderAmountField()}
        {/* <Descriptions
          style={{ marginTop: 14 }}
          title="بيانات الشيفت"
          bordered
          column={1}
        >
          {posReport.start_shift && (
            <Descriptions.Item label="بداية الشيفت">
              {posReport.start_shift}
            </Descriptions.Item>
          )}
          {posReport.end_shift && (
            <Descriptions.Item label="نهاية الشيفت">
              {posReport.end_shift}
            </Descriptions.Item>
          )}
          {posReport.cash && (
            <Descriptions.Item label="الكـــاش">
              {posReport.cash}
            </Descriptions.Item>
          )}
          {posReport.visa && (
            <Descriptions.Item label="الفـيزا">
              {posReport.visa}
            </Descriptions.Item>
          )}
        </Descriptions> */}
      </div>
    );
  };

  const renderModalContent = () => {
    if (!posReportIsLoading && posReport) return renderReportDetails();
    else if (posReportIsLoading) {
      return (
        <div style={{ minHeight: 182, display: 'grid', placeItems: 'center' }}>
          <Spin />
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Modal
      transitionName=""
      className="shared-custom-modal pos-box-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        <div className={'model-header'}>
          <h3>{t('ShiftReport.ExpensesBox')}</h3>
        </div>
      }
      visible={posBoxModalOpened}
      onOk={() => {
        setPosBoxModalOpened(false);
      }}
      onCancel={() => {
        setPosBoxModalOpened(false);
      }}
      footer={false}
    >
      {renderModalContent()}
    </Modal>
  );
};

export default PosBoxModal;
