import axios from 'axios';

const createNewDocumentApi = async (token, values, edit = false) => {
  try {
    let url;
    if (!edit) url = '/Document/create';
    else url = '/Document/update';
    const res = await axios.post(url, values, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default createNewDocumentApi;
