import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import TechnicalSupportContext from '../../contexts/technical-support-context/TechnicalSupportContext';

const PageModal = () => {
  const { modalOpened, setModalOpened, setSelectedTicket, selectedTicket } =
    useContext(TechnicalSupportContext);

  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={selectedTicket ? 'تعديل التذكرة' : 'إضافة تذكرة جديدة'}
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedTicket(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedTicket(null);
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default PageModal;
