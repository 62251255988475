import { useState, createContext } from 'react';
const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  allFetchedProducts: null,
  setAllFetchedProducts: (v) => {},
  selectedProductId: '',
  selectedProduct: null,
  setSelectedProduct: (v) => {},
  setSelectedProductId: (v) => {},
  selectedProductModalOpened: false,
  setSelectedProductModalOpened: (v) => {},
  nameSearchValue: '',
  setNameSearchValue: (v) => {},
  //barcode
  barCodeModalOpened: false,
  setBarCodeModalOpned: (v) => {},
  totalAmountForIngredients: [],
  setTotalAmountForIngredients: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {},
  tableFilter: {
    page: 1,
    parent_cat: '',
    stock_id: '',
    work_place: '',
    searchText: '',
  },
  setTableFilter: (v) => {}
};

const StockContext = createContext(INITIAL_VALUES);

export const StockProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [selectedProduct, setSelectedProduct] = useState(
    INITIAL_VALUES.selectedProduct
  );
  const [allFetchedProducts, setAllFetchedProducts] = useState(
    INITIAL_VALUES.allFetchedProducts
  );
  const [tableFilter, setTableFilter] = useState(INITIAL_VALUES?.tableFilter);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);

  const [selectedProductModalOpened, setSelectedProductModalOpened] = useState(
    INITIAL_VALUES.selectedProductModalOpened
  );
  const [nameSearchValue, setNameSearchValue] = useState(
    INITIAL_VALUES.nameSearchValue
  );
  const [selectedProductId, setSelectedProductId] = useState(
    INITIAL_VALUES.selectedProductId
  );
  //
  const [barCodeModalOpened, setBarCodeModalOpned] = useState(
    INITIAL_VALUES.barCodeModalOpened
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES?.tablePagination
  );
  const [totalAmountForIngredients , setTotalAmountForIngredients] = useState(
    INITIAL_VALUES?.totalAmountForIngredients
  );
  return (
    <StockContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fetchCount,
        setFetchCount,
        modalOpened,
        setModalOpened,
        allFetchedProducts,
        setAllFetchedProducts,
        selectedProduct,
        setSelectedProduct,
        selectedProductId,
        setSelectedProductId,
        selectedProductModalOpened,
        setSelectedProductModalOpened,
        nameSearchValue,
        setNameSearchValue,
        barCodeModalOpened,
        setBarCodeModalOpned,
        isSubmittingForm,
        setIsSubmittingForm,
        tablePagination,
        setTablePagination,
        totalAmountForIngredients, 
        setTotalAmountForIngredients,
        tableFilter,
        setTableFilter,
      }}
    >
      {children}
    </StockContext.Provider>
  );
};

export default StockContext;
