import { Tooltip, Button } from 'antd';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const RenderExtraPricesFields = (
  control,
  setValue,
  errors,
  extraPricesFields,
  removeIngredientField
) => {
  const { t } = useTranslation();

  return (
    extraPricesFields?.length > 0 && (
      <ul className="fields-array-ul without-padding">
        {extraPricesFields.map((item, index) => {
          return (
            <li className="field-delete-li" key={item.id}>
              <div className="text-field-label-wrap">
                <p className="label-p">{t('Quotations.ExtraPrice')}</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    className="form-text-field"
                    name={`extra_prices.${index}.price`}
                    type="text"
                    placeholder={`${t('Quotations.ExtraPrice')}`}
                    errorMsg={
                      errors?.extra_prices &&
                      errors.extra_prices[index]?.price &&
                      errors.extra_prices[index].price.message
                    }
                    validateStatus={
                      errors?.extra_prices &&
                      errors.extra_prices[index]?.price &&
                      errors?.extra_prices[index]?.price
                        ? 'error'
                        : ''
                    }
                    control={control}
                  />
                </div>
              </div>
              <div className="text-field-label-wrap">
                <p className="label-p">
                  {t('Quotations.AdditionalPriceStatement')}
                </p>{' '}
                <div className="text-field-wrap">
                  <AntdTextField
                    className="form-text-field"
                    name={`extra_prices.${index}.title`}
                    type="text"
                    placeholder={`${t('Quotations.AdditionalPriceStatement')}`}
                    errorMsg={
                      errors?.extra_prices &&
                      errors.extra_prices[index]?.title &&
                      errors.extra_prices[index].title.message
                    }
                    validateStatus={
                      errors?.extra_prices &&
                      errors.extra_prices[index]?.title &&
                      errors?.extra_prices[index]?.title
                        ? 'error'
                        : ''
                    }
                    control={control}
                  />
                </div>
              </div>

              {extraPricesFields?.length >= 1 && (
                <Tooltip title="حذف">
                  <Button
                    className="delete-field-btn"
                    size="large"
                    type="dashed"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => removeIngredientField(index)}
                  />
                </Tooltip>
              )}
            </li>
          );
        })}
      </ul>
    )
  );
};

export default RenderExtraPricesFields;
