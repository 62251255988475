import React, { useContext } from 'react';
import { Select } from 'antd';
import SearchIcon from '../../../common/icons/SearchIcon';
import SelectDropDown from '../../../common/icons/SelectDropDown';
import roles from '../../../roles';
import SettingsSystemUsersContext from '../../../contexts/settings-contexts/SettingsSystemUsersProvider';
import getAllUsersApi from '../../../apis/settings-apis/users-apis/getAllUsersApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useTranslation } from 'react-i18next';

const TableFilter = () => {
  const { Option } = Select;
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const { setNameSearchValue, setAllFetchedUsers, setIsLoading } = useContext(
    SettingsSystemUsersContext
  );
  const handleTableSearch = (e) => {
    setNameSearchValue(e.target.value);
  };

  const customApiRequest = useCustomApiRequest();
  function handleChange(value) {
    setIsLoading(true);

    if (value === 'all') {
      customApiRequest(
        getAllUsersApi(user?.token),
        (res) => {
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllFetchedUsers(data);
          } else {
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    } else {
      customApiRequest(
        getAllUsersApi(user?.token, { role: value }),
        (res) => {
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllFetchedUsers(data);
          } else {
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }
  }

  return (
    <div className="custom-table-fitler warehouse-table-filter">
      <div className="form-excel-export-wrap">
        <form className="filter-form">
          <div className="table-search">
            <label>
              <SearchIcon />
              <input
                placeholder={t('Users.EnterUsernameToSearch')}
                type="text"
                name="table_search"
                onKeyUp={handleTableSearch}
              />
            </label>
          </div>
          <label className="entries-wrap">
            <span className="select-title">{t('Users.UserPermission')}</span>
            <Select
              defaultValue="all"
              style={{ width: 152 }}
              onChange={handleChange}
              suffixIcon={<SelectDropDown />}
            >
              <Option value="all">{t('Users.AllUsers')}</Option>
              {roles.map((role, index) => (
                <Option key={index} value={role.value}>
                  {role.title}
                </Option>
              ))}
            </Select>
          </label>

          {/* <button className="filter-submit-btn" type="submit">
            بحث
          </button> */}
        </form>
      </div>
    </div>
  );
};

export default TableFilter;
