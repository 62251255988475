import React from 'react'
import { useEffect } from 'react';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import UserContext from '../../../contexts/user-context/UserProvider';
import TransactionsDetailsTable from './TransactionsDetailsTable';
import getAllTransactionsApi from '../../../apis/accounting-apis/accounting-details-apis/getAllTransactionsApi';
import TransactionsContext from '../../../contexts/accounting-context/TransactionsContext';
import TransactionsDetailsModal from './TransactionsDetailsModal';

const TransactionsDetailsPage = () => {
    const { user } = useContext(UserContext);
    const {
        setIsLoading,
        fetchCount,
        setFetchedTransactionsDetails,
        fetchedTransactionDetails,
        modalOpened,
        setSelectedTransactions,
        setModalOpened
    } = useContext(TransactionsContext);

    const params = useParams();
    let paramIds = params?.id?.split('-');
    let typeOfAccount = paramIds?.length

    const customApiRequest = useCustomApiRequest();
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setIsLoading(true);
            customApiRequest(
                getAllTransactionsApi(user?.token, user?.client_id),
                (res) => {
                    if (res?.status === 200 && res?.data?.data && res?.data?.data !== 'not found' && res?.data?.data !== 'fail') {
                        setIsLoading(false);
                        setFetchedTransactionsDetails(res?.data?.data);
                    }
                },
                (error) => {
                    setIsLoading(false);
                }
            );
        }

        return () => {
        isMounted = false;
        };
    }, [fetchCount]);

    return (
        <div>
            {<TransactionsDetailsTable />}
        </div>
    )
}

export default TransactionsDetailsPage