import axios from 'axios';

const deleteProductApi = async (token, id) => {
  // const formDate = new FormData();
  // for (let id of ids) {
  //   formDate.append('id[]', id);
  // }
  const formData = new FormData();
  formData.append('id', id);
  try {
    const res = await axios.post(
      '/Product/delete',
      formData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token,
          lang: 'ar'
        }
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export default deleteProductApi;
