import React, { useContext } from 'react';
import { Modal, Tabs } from 'antd';
import TableForm from './TableForm';
import IncomingPurchaseInvoiceForm from '../../store-pages/store-incoming-purchase-invoices-page/TableForm';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import ClientsContext from '../../../contexts/clients-context/ClientsContext';
import { useTranslation } from 'react-i18next';

const PageModal = ({ modalVisible, setModalVisible, isHomepage = false }) => {
  const { modalOpened, setModalOpened, setSelectedReceipt, selectedReceipt } =
    useContext(OutgoingPurchaseInvoiceContext);
  const { setSelectedClientId } = useContext(ClientsContext);
  const { t } = useTranslation();

  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        selectedReceipt
          ? t('Invoices.InvoiceReturnRequest')
          : t('Invoices.AddNewSalesInvoice')
      }
      visible={modalOpened || modalVisible}
      onOk={() => {
        setModalOpened(false);
        setSelectedReceipt(null);
        setSelectedClientId('');
        setModalVisible && setModalVisible(false);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedReceipt(null);
        setSelectedClientId('');
        setModalVisible && setModalVisible(false);
      }}
      footer={false}
    >
      {isHomepage ? (
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab={t('Invoices.SalesInvoice')} key="1">
            <TableForm setModalVisible={setModalVisible} />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t('Invoices.Purchasetaxinvoice')}
            // tab=" فاتورة الشراء الضريبية"
            key="2"
          >
            <IncomingPurchaseInvoiceForm setModalVisible={setModalVisible} />
          </Tabs.TabPane>
        </Tabs>
      ) : (
        <TableForm setModalVisible={setModalVisible} />
      )}
    </Modal>
  );
};

export default PageModal;
