/* eslint-disable eqeqeq */
export const calcProductTotalValue = (
  discountType,
  itemPrice,
  discountPrice,
  discountPercentage,
  quantity,
  tax
) => {
  // discount type ====> 1 = fixed discount, 2 = percentage discount, no_discount
  const totalWithoutDiscount = parseFloat(itemPrice) * parseFloat(quantity);
  if (discountType == '1') {
    let total = totalWithoutDiscount - discountPrice;
    if (parseFloat(tax) > 0) total = total + (total * parseFloat(tax)) / 100;
    if (!isNaN(total)) {
      return parseFloat(total.toFixed(2));
    }
    return null;
  } else if (discountType == '2') {
    const itemTotal = totalWithoutDiscount;
    let total = itemTotal - (parseFloat(discountPercentage) / 100) * itemTotal;
    if (parseFloat(tax) > 0) total = total + (total * parseFloat(tax)) / 100;
    if (!isNaN(total)) {
      return parseFloat(total.toFixed(2));
    }
    return null;
  } else {
    let total = 0;
    if (parseFloat(tax) >= 0)
      total =
        totalWithoutDiscount + (totalWithoutDiscount * parseFloat(tax)) / 100;
    else total = totalWithoutDiscount;
    if (!isNaN(total)) {
      return parseFloat(total.toFixed(2));
    }
    return null;
  }
};

export const renderFieldsTotalValue = (arr) => {
  let total = 0;
  if (arr?.length > 0) {
    for (let item of arr) {
      total =
        parseFloat(total) +
        calcProductTotalValue(
          item?.discount_type,
          parseFloat(item?.price),
          parseFloat(item?.discount_price),
          parseFloat(item?.discount_percentage),
          parseFloat(item?.quantity),
          parseFloat(item?.tax)
          // item?.including_tax == 0 ? parseFloat(item?.tax) : ''
        );
    }
  }
  if (!isNaN(total)) {
    return parseFloat(total.toFixed(2));
  }
  return null;
};
/////////////////////////////////
/////////////////////////////////
/////////////////////////////////
/////////////////////////////////
export const renderTotalPriceAfterExtraPrices = (products, extraPricesArr) => {
  // products => products arr
  // extraPricesArr
  let total = 0;
  // receipt of products
  // first calc the total price of products
  // second add the extra prices to the products total price
  total = renderFieldsTotalValue(products);
  if (!isNaN(total)) {
    if (extraPricesArr?.length > 0) {
      for (let extra of extraPricesArr) {
        if (extra.price) {
          total = parseFloat(total) + parseFloat(extra.price);
        }
      }
    }
  }

  if (!isNaN(total)) {
    return parseFloat(total.toFixed(2));
  }
  return null;
};

/////////////////////////
/////////////////////////
export const renderReceiptTotalValueAfterDiscount = (
  products,
  extraPricesArr,
  discountType,
  discountPrice,
  discountPercentage
) => {
  // products => products arr
  // extraPricesArr
  // discountType => receipt discount type => 1 = fixed price discount, 2 = percentage discount

  let total = 0;
  if (!isNaN(renderTotalPriceAfterExtraPrices(products, extraPricesArr))) {
    // total after extra prices
    total =
      parseFloat(total) +
      parseFloat(renderTotalPriceAfterExtraPrices(products, extraPricesArr));
  }
  if (discountType == '1' && discountPrice && !isNaN(discountPrice)) {
    // fixed discount price
    total = parseFloat(total) - parseFloat(discountPrice);
  } else if (
    discountType == '2' &&
    discountPercentage &&
    !isNaN(discountPercentage)
  ) {
    // precentage discount
    total =
      parseFloat(total) -
      (parseFloat(total) * parseFloat(discountPercentage)) / 100;
  } else {
    // no discount
    total = parseFloat(total);
  }

  if (!isNaN(total)) {
    return parseFloat(total.toFixed(2));
  }
  return null;
};
////////////////////////
////////////////////////
export const renderReceiptTotalValueAfterTax = (
  products,
  extraPricesArr,
  discountType,
  discountPrice,
  discountPercentage,
  tax
) => {
  let total = 0;
  total = renderReceiptTotalValueAfterDiscount(
    products,
    extraPricesArr,
    discountType,
    discountPrice,
    discountPercentage,
    tax
  );

  if (!isNaN(total)) {
    return (parseFloat(total) + parseFloat(total) * (tax / 100)).toFixed(2);
  }
  return null;
};
