import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import ClientsPosContext from '../../../contexts/clients-context-pos/ClientsPosContext';
import ReturnModalContext from '../../../contexts/return-modal-context/ReturnModalContext';
import Links from '../Links';
import PagePosModal from '../../../pages/clients-page-pos/PagePosModal';
// import './sharedLayoutBtns.scss';

const SharedLayoutBtns = () => {
  const { t } = useTranslation();
  const routerLinks = Links();
  const { setModalOpened, modalOpened } = useContext(ClientsPosContext);
  const { setReturnModalOpened } = useContext(ReturnModalContext);
  const history = useHistory();
  const { pathname } = useLocation();
  return (
    <div className="header-links">
      {/* <Button onClick={() => setReturnModalOpened(true)}>
        <PlusOutlined />
        فاتورة مرتجع
      </Button>
      {pathname === routerLinks.posPage ||
      pathname === routerLinks.printInvoicePage ? (
        <Button onClick={() => setModalOpened(true)}>
          <PlusOutlined />
          إضافة عميل
        </Button>
      ) : (
        <Button
          onClick={() => {
            resetPos();
            history.push(routerLinks.posPage);
          }}
        >
          الرئيسية
        </Button>
      )} */}
      {pathname === routerLinks.posPage ||
        (pathname !== routerLinks.printInvoicePage && (
          <Button
            onClick={() => {
              history.push(routerLinks.posPage);
            }}
          >
            {t('Pos.SharedLayoutBtns.homePage')}
          </Button>
        ))}

      <Button onClick={() => history.push(routerLinks.saleInvoicesPosPage)}>
        {t('Pos.SharedLayoutBtns.invoices')}
      </Button>
      <Button onClick={() => history.push(routerLinks.reservationsPage)}>
        {t('Pos.SharedLayoutBtns.reservations')}
      </Button>
      <Button onClick={() => history.push(routerLinks.clientsPage)}>
        {t('Pos.SharedLayoutBtns.customers')}
      </Button>
      {pathname === routerLinks.clientsPage || (
        <Button
          onClick={() => {
            setModalOpened(true);
          }}
        >
          <PlusOutlined />
          {t('Pos.SharedLayoutBtns.addClient')}
        </Button>
      )}
      {modalOpened && <PagePosModal />}

      {/* <Button onClick={() => history.push(routerLinks.priceOfferRouteNew)}>
        عرض السعر
      </Button> */}
      {/* <Button onClick={() => history.push(routerLinks.cashOutPage)}>
        صرف نقدية
      </Button>
      <Button onClick={() => history.push(routerLinks.cashInPage)}>
        إستلام نقدية
      </Button> */}
    </div>
  );
};

export default SharedLayoutBtns;
