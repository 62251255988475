/* eslint-disable react-hooks/exhaustive-deps */
import { Descriptions, Empty, Modal, Spin, Table } from 'antd';
import FileSaver from 'file-saver';
import parse from 'html-react-parser';
import React, { useContext } from 'react';
import getSingleProductApi from '../../../apis/store-apis/products/getSingleProductApi';
import StoreNewProductModalContext from '../../../contexts/store-new-product-context/StoreNewProductProvider';
import UserContext from '../../../contexts/user-context/UserProvider';
import './SelectedProudctModal.scss';
import { useTranslation } from 'react-i18next';

const saveFile = (url, title) => {
  FileSaver.saveAs(url, title);
};
const SelectedProductModal = () => {
  const [requestLoading, setRequestLoading] = React.useState(false);
  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  const {
    selectedProduct,
    setSelectedProduct,
    selectedProductModalOpened,
    setSelectedProductModalOpened,
    setSelectedProductId,
    selectedProductId
  } = React.useContext(StoreNewProductModalContext);

  React.useEffect(() => {
    if (selectedProductModalOpened) {
      const fetchSingleProduct = async () => {
        try {
          setRequestLoading(true);
          const res = await getSingleProductApi(selectedProductId, user?.token);
          if (res?.status === 200 && res?.data?.data) {
            setRequestLoading(false);
            setSelectedProduct({ ...res.data.data });
          }
        } catch (err) {
          setRequestLoading(false);
        }
      };
      fetchSingleProduct();
    }
  }, [selectedProductModalOpened]);

  if (selectedProduct && !requestLoading) {
    const renderMaterialsTable = (arr) => {
      return (
        <Table
          locale={{
            emptyText: (
              <Empty description={false}>
                {t('Inventory.FirstTermStock.noDataAvailable')}
              </Empty>
            )
          }}
          pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
          className="antd-custom-table"
          dataSource={selectedProduct?.productData?.materials.map(
            (material) => ({
              ...material,
              key: material.id
            })
          )}
          columns={[
            {
              title: t('Inventory.FirstTermStock.ingredientCode'),
              dataIndex: selectedProduct?.productData?.id,
              render: (_, record) => {
                return (
                  <div className="row-cell row-index bold-font">
                    <span># {record.id}</span>
                  </div>
                );
              }
            },
            {
              title: t('Inventory.FirstTermStock.ingredientName'),
              dataIndex: selectedProduct?.productData?.name,
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>{record.name ? record.name : '-----'}</span>
                  </div>
                );
              }
            },
            {
              title: t('Inventory.FirstTermStock.quantity'),
              dataIndex: selectedProduct?.productData?.quantity,
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>{record.quantity ? record.quantity : '-----'}</span>
                  </div>
                );
              }
            },
            {
              title: t('Inventory.FirstTermStock.ingredientPrice'),
              dataIndex: 'price',
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>{record.price ? record.price : '-----'}</span>
                  </div>
                );
              }
            },
            {
              title: t('Inventory.FirstTermStock.category'),
              dataIndex: 'cat',
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>{record.cat?.name ? record.cat.name : '-----'}</span>
                  </div>
                );
              }
            }
          ]}
          // loading={isLoading}
          // scroll={{ x: 400 }}
          footer={false}
        />
      );
    };

    const renderModalBody = () => {
      return (
        <div className="product-details-wrap">
          <Descriptions
            title={t('Inventory.FirstTermStock.productDetails')}
            bordered
            column={1}
          >
            {selectedProduct?.productData?.id && (
              <Descriptions.Item label={t('Inventory.FirstTermStock.code')}>
                {selectedProduct?.productData?.id}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.name && (
              <Descriptions.Item label={t('Inventory.FirstTermStock.name')}>
                {selectedProduct?.productData?.name}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.cat?.parent && (
              <Descriptions.Item
                label={t('Inventory.FirstTermStock.mainCategory')}
              >
                {selectedProduct?.productData?.cat?.parent}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.cat?.name && (
              <Descriptions.Item
                label={t('Inventory.FirstTermStock.subCategory')}
              >
                {selectedProduct?.productData?.cat?.name}
              </Descriptions.Item>
            )}
            {String(selectedProduct?.productData?.quantity) && (
              <Descriptions.Item
                label={t('Inventory.FirstTermStock.productQuantity')}
              >
                {selectedProduct?.productData?.quantity}
              </Descriptions.Item>
            )}
            {String(selectedProduct?.productData?.unit?.name) && (
              <Descriptions.Item
                label={t('Inventory.FirstTermStock.measruingUnit')}
              >
                {selectedProduct?.productData?.unit?.name}
              </Descriptions.Item>
            )}
            {String(selectedProduct?.productData?.buyPrice) && (
              <Descriptions.Item label={t('Inventory.FirstTermStock.buyPrice')}>
                {selectedProduct?.productData?.buyPrice}
              </Descriptions.Item>
            )}
            {String(selectedProduct?.productData?.salePrice) && (
              <Descriptions.Item
                label={t('Inventory.FirstTermStock.salePrice')}
              >
                {selectedProduct?.productData?.salePrice}
              </Descriptions.Item>
            )}
            {String(selectedProduct?.productData?.wholesalePrice) && (
              <Descriptions.Item
                label={t('Inventory.FirstTermStock.wholesalePriceProduct')}
              >
                {selectedProduct?.productData?.wholesalePrice}
              </Descriptions.Item>
            )}
            {String(selectedProduct?.productData?.alarmQuantity) && (
              <Descriptions.Item
                label={t('Inventory.FirstTermStock.alertQuantity')}
              >
                {selectedProduct?.productData?.alarmQuantity}
              </Descriptions.Item>
            )}
            {String(selectedProduct?.productData?.tax) && (
              <Descriptions.Item label={t('Inventory.FirstTermStock.tax')}>
                {selectedProduct?.productData?.tax + ' %'}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.defaultParCode && (
              <Descriptions.Item
                label={t('Inventory.FirstTermStock.defaultBarcode')}
              >
                {selectedProduct?.productData?.defaultParCode}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.parCode && (
              <Descriptions.Item
                label={t('Inventory.FirstTermStock.generatedBarcode')}
              >
                {selectedProduct?.prodctData?.parCode}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.parCode && (
              <Descriptions.Item
                label={t('Inventory.FirstTermStock.productDescription')}
              >
                {parse(
                  selectedProduct?.prouductData?.desc
                    ? selectedProduct?.productData?.desc
                    : ''
                )}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.parCode && (
              <Descriptions.Item
                label={t('Inventory.FirstTermStock.productImage')}
              >
                <img
                  style={{ maxHeight: 182, objectFit: 'contain' }}
                  src={selectedProduct?.productData?.image}
                  alt={selectedProduct?.productData?.name}
                  onClick={() =>
                    saveFile(
                      selectedProduct?.productData?.image,
                      selectedProduct?.productData?.name
                    )
                  }
                />
              </Descriptions.Item>
            )}
          </Descriptions>

          {selectedProduct?.productData?.type === 2 &&
            selectedProduct?.productData?.materials?.length > 0 &&
            renderMaterialsTable(selectedProduct?.productData?.materials)}
        </div>
      );
    };

    return (
      <Modal
        className="shared-custom-modal selected-product-modal"
        width="90%"
        style={{ maxWidth: '942px' }}
        title={t('Inventory.FirstTermStock.productDetails')}
        visible={selectedProductModalOpened}
        onOk={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        onCancel={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        footer={false}
      >
        {renderModalBody()}
        {/* <pre style={{ direction: 'ltr' }}>
        {JSON.stringify(selectedProduct, null, 2)}
      </pre> */}
      </Modal>
    );
  } else if (requestLoading && !selectedProduct)
    return (
      <Modal
        className="shared-custom-modal selected-product-modal"
        width="90%"
        style={{ maxWidth: '942px' }}
        title={t('Inventory.FirstTermStock.productDetails')}
        visible={selectedProductModalOpened}
        onOk={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        onCancel={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        footer={false}
      >
        <div style={{ height: 232, display: 'grid', placeItems: 'center' }}>
          <Spin />
        </div>
      </Modal>
    );
  else {
    return (
      <Modal
        className="shared-custom-modal selected-product-modal"
        width="90%"
        style={{ maxWidth: '942px' }}
        title={t('Inventory.FirstTermStock.productDetails')}
        visible={selectedProductModalOpened}
        onOk={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        onCancel={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        footer={false}
      >
        <div style={{ height: 132, display: 'grid', placeItems: 'center' }}>
          {t('Inventory.FirstTermStock.noDataAvailable')}
        </div>
      </Modal>
    );
  }
};

export default SelectedProductModal;
