import axios from 'axios';

const ceateNewTicketApi = async (token, values, id, edit = false) => {
  try {
    let url;
    let res;
    if (!edit) {
      url = 'tickets';
      res = await axios.post(url, values, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
          lagn: 'ar'
        }
      });
    } else {
      url = `tickets/${id}/update`;
      res = await axios.post(url, values, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
          lagn: 'ar'
        }
      });
    }

    return res;
  } catch (error) {
    throw error;
  }
};

export default ceateNewTicketApi;
