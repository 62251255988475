/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import updatePosApi from '../../../apis/pos-api/pos-settings-apis/updatePosApi';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import SettingsPosContext from '../../../contexts/settings-contexts/SettingsPosPage';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import useWarehouses from '../../../custom-hooks/useWarehouses';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';

import { useTranslation } from 'react-i18next';
// console.log(useTranslation)

const TableForm = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    name: Yup.string().required(t('PointsOfSale.salePointNameValidation')),
    stock_id: Yup.string().required(t('PointsOfSale.selectStock'))
  });
  const {
    selectedPos,
    setIsLoading,
    setModalOpened,
    setFetchCount,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(SettingsPosContext);
  const allWarehouses = useWarehouses();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      stock_id: '',
      visa: '',
      cash: '',
      address: ''
    }
  });

  const customApiRequest = useCustomApiRequest();

  const onSubmit = async (data) => {
    if (selectedPos) {
      setIsLoading(true);
      setIsSubmittingForm(true);
      customApiRequest(
        updatePosApi(
          user?.token,
          {
            ...data,
            id: selectedPos.id
          },
          true
        ),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('PointsOfSale.operationCompletedSuccessfully'),
              message: t('PointsOfSale.salePointUpdates')
            });
          } else {
            errorNotification({
              title: t('PointsOfSale.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('PointsOfSale.tryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('PointsOfSale.errorOccurred'),
            message:
              error?.response?.data?.message || t('PointsOfSale.tryLater')
          });
        }
      );
    } else {
      setIsLoading(true);
      customApiRequest(
        updatePosApi(user?.token, data),
        (res) => {
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setIsSubmittingForm(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('PointsOfSale.operationCompletedSuccessfully'),
              message: t('PointsOfSale.newSalePointAdded')
            });
          } else {
            errorNotification({
              title: t('PointsOfSale.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('PointsOfSale.tryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('PointsOfSale.errorOccurred'),
            message:
              error?.response?.data?.message || t('PointsOfSale.tryLater')
          });
        }
      );
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedPos) {
      setValue('name', selectedPos?.name ? selectedPos.name : '');
      setValue('address', selectedPos?.address ? selectedPos.address : '');
      setValue('cash', selectedPos?.cash ? selectedPos.cash : '');
      setValue('visa', selectedPos?.visa ? selectedPos.visa : '');
      setValue(
        'stock_id',
        selectedPos.stock?.id ? String(selectedPos.stock.id) : ''
      );
    }
  }, [selectedPos, selectedPos?.id]);

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form settings-pos-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('PointsOfSale.salePointName')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="name"
              type="text"
              placeholder={t('PointsOfSale.salePointNamePlaceholder')}
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('PointsOfSale.salePointAddress')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="address"
              type="text"
              placeholder={t('PointsOfSale.salePointAddressPlaceholder')}
              errorMsg={errors?.address?.message}
              validateStatus={errors?.address ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('PointsOfSale.CashAccount')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="cash"
              type="text"
              placeholder={t('PointsOfSale.CashAccountPlaceholder')}
              errorMsg={errors?.cash?.message}
              validateStatus={errors?.cash ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('PointsOfSale.visaAccount')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="visa"
              type="text"
              placeholder={t('PointsOfSale.visaAccountPlaceholder')}
              // errorMsg={errors?.name?.message}
              // validateStatus={errors?.name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="select-label-wrap">
          <p className="label-p">{t('PointsOfSale.selectStock')}</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="stock_id"
              errorMsg={errors?.stock_id?.message}
              validateStatus={errors?.stock_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              options={
                allWarehouses?.length > 0
                  ? allWarehouses.map((p) => ({
                      title: p?.name,
                      value: p?.id
                    }))
                  : []
              }
              showSearch={true}
              // filterOption={(input, option) =>
              //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              // }
                                filterOption={(input, option) =>
                option?.children?.props?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
               }
              formClassName="settings-pos-form"
            />
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {t('PointsOfSale.update')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
