/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import CustomAntdDatePicker from '../../common/antd-form-components/CustomAntdDatePicker';
// import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { useContext } from 'react';
import { useEffect } from 'react';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import useClients from '../../custom-hooks/useClients';
import addNewCustomerPaymentApi from '../../apis/store-apis/customer-payments/addNewCustomerPaymentApi';
import moment from 'moment';
import CustomersPaymentsContext from '../../contexts/customers-payments-context/CustomersPaymentsContext';
import getDeferredInvoicesApi from '../../apis/store-apis/customer-payments/getDeferredInvoicesApi';
import AntdRadioGroup from '../../common/antd-form-components/AntdRadioGroup';
import MyDropZone from '../../common/dorp-zone/MyDropZone';
import { CameraFilled } from '@ant-design/icons';
import MyDropZonePreview from '../../common/dorp-zone/MyDropZonePreview';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
import { useTranslation } from 'react-i18next';

const TableForm = ({ setAllSuppliersFetchCount }) => {
  const { user } = React.useContext(UserContext);
  const systemSettings = useSystemSettings();
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    user_id: Yup.string().required('ادخل اسم العميل'),
    pay_type: Yup.string().required(t('ClientPayments.EnterPaymentType')),
    multiPaymentCash: Yup.string()
      .test('multiPaymentCash', 'أدخل مبلغ الكاش', (v, context) => {
        let result = true;
        if (!v && context.from[0].value.pay_type == '6') {
          result = false;
        }
        return result;
      })
      .test('multiPaymentCash', 'لا يسمح الا بالارقام', (v, context) => {
        let result = true;
        if (
          v &&
          !v.match(/^(\d+)?(\.\d+)?$/) &&
          context.from[0].value.pay_type == '6'
        ) {
          result = false;
        }
        return result;
      }),
    multiPaymentVisa: Yup.string()
      .test('multiPaymentVisa', '  أدخل مبلغ الشبكة  ', (v, context) => {
        let result = true;
        if (!v && context.from[0].value.pay_type == '6') {
          result = false;
        }
        return result;
      })
      .test('multiPaymentVisa', 'لا يسمح الا بالارقام', (v, context) => {
        let result = true;
        if (
          v &&
          !v.match(/^(\d+)?(\.\d+)?$/) &&
          context.from[0].value.pay_type == '6'
        ) {
          result = false;
        }
        return result;
      }),
    amount: Yup.string()
      .required(t('ClientPayments.EnterAmount'))
      .matches(/^(\d+)?(\.\d+)?$/, 'لا يسمح الا بكتابة الارقام'),
    date: Yup.string().required(t('ClientPayments.EnterDate'))
    // invoice_type: Yup.string().required('ادخل نوع الفاتورة')
  });
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedCustomerPayment,
    setSelectedCustomerPaymentId,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(CustomersPaymentsContext);
  const { allClients } = useClients();
  const [deferredInvoices, setDeferredInvoices] = useState([]);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      user_id: '',
      invoice_type: '',
      amount: '',
      date: '',
      invoice_id: ''
    }
  });
  const customApiRequest = useCustomApiRequest();

  // handle initial values
  // useEffect(() => {
  //   if (selectedSupplier) {
  //     if (selectedSupplier?.name) {
  //       setValue('name', selectedSupplier.name);
  //     }
  //     if (selectedSupplier?.phone) {
  //       setValue('phone', selectedSupplier.phone);
  //     }
  //   }
  // }, [selectedSupplier]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getDeferredInvoicesApi(user?.token, watch('user_id'), 2),
        (res) => {
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data?.length) {
            const data = res.data.data;
            setDeferredInvoices(data);
          }
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [watch('user_id')]);

  let [paymentMethods, setPaymentStatus] = useState([]);
  useEffect(() => {
    systemSettings?.invoiceSetting?.payment_methods_objects.map(
      (paymentMethod) => {
        if (paymentMethod.name != 'آجل') {
          setPaymentStatus((prv) => [
            ...prv,
            {
              title: paymentMethod.name,
              value: paymentMethod.id
            }
          ]);
        }
      }
    );
  }, [systemSettings]);
  const [invoiceFileToUpload, setInvoiceFileToUpload] = useState(
    watch('invoice_image') ? watch('invoice_image') : null
  );
  const [invoiceFileToUploadDefferd, setInvoiceFileToUploadDefferd] = useState(
    watch('first_pay_img') ? watch('first_pay_img') : null
  );
  useEffect(() => {
    setValue('invoice_image', invoiceFileToUpload);
    if (watch('invoice_image')) {
      clearErrors('invoice_image');
    }
  }, [invoiceFileToUpload, invoiceFileToUpload?.length]);
  useEffect(() => {
    setValue('first_pay_img', invoiceFileToUploadDefferd);
    if (watch('first_pay_img')) {
      clearErrors('first_pay_img');
    }
  }, [invoiceFileToUploadDefferd, invoiceFileToUploadDefferd?.length]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handleFilesDrop = async (acceptedFiles, formFiles, setFormFiles) => {
    const duplicates = [];
    for (let i of acceptedFiles) {
      if (formFiles?.length) {
        for (let f of formFiles) {
          if (i.name === f.name) {
            duplicates.push(i);
          }
        }
      }
    }
    for (let i = 0; i < acceptedFiles.length; i++) {
      for (let f of duplicates) {
        if (acceptedFiles[i].name === f.name) {
          acceptedFiles.splice(i, 1);
        }
      }
    }
    acceptedFiles.forEach(async (file) => {
      const preview = await getBase64(file);
      Object.assign(file, {
        preview
      });
      setFormFiles((prevState) => [file]);
      if (formFiles?.length > 0) {
        setFormFiles((currState) => [file]);
      } else {
        setFormFiles([file]);
      }
    });
  };

  const clearFileFromUpload = (file, setFilesToUpload) => {
    setFilesToUpload((prevState) => {
      const filtered = prevState.filter((asset) => asset.name !== file.name);
      if (filtered?.length === 0) return null;
      return filtered;
    });
  };
  useEffect(() => {
    if (watch('pay_type')) {
      setValue('invoice_due_date', '');
      clearErrors('invoice_due_date');
      setValue('first_paid', '');
      clearErrors('first_paid');
      setValue('first_pay_type', '');
      clearErrors('first_pay_type');
      setValue('invoice_image', null);
      clearErrors('invoice_image');
      setValue('first_pay_img', null);
      clearErrors('first_pay_img');
      setInvoiceFileToUpload(null);
    }
  }, [watch('pay_type')]);
  useEffect(() => {
    setValue('first_pay_img', null);
    clearErrors('first_pay_img');
  }, [watch('first_pay_type')]);
  useEffect(() => {
    if (watch('multiPaymentCash')) {
      clearErrors('multiPaymentVisa');
    }
  }, [watch('multiPaymentCash')]);
  useEffect(() => {
    if (watch('multiPaymentVisa')) {
      clearErrors('multiPaymentCash');
    }
  }, [watch('multiPaymentVisa')]);
  useEffect(() => {
    if (watch('amount')) {
      clearErrors('multiPaymentCash');
      clearErrors('multiPaymentVisa');
      setValue('multiPaymentCash', '');
      setValue('multiPaymentVisa', '');
    }
  }, [watch('amount')]);

  useEffect(() => {
    if (selectedCustomerPayment?.id) {
      setValue('user_id', selectedCustomerPayment.id.toString());
    }
    return () => {};
  }, [selectedCustomerPayment]);

  const onSubmit = async (data) => {
    const mappedData = {};
    mappedData.type = 2;
    if (data.user_id) mappedData.user_id = data.user_id;
    if (data.amount) mappedData.amount = data.amount;
    if (data.notes) mappedData.notes = data.notes;
    if (data.date) mappedData.date = moment(data.date).format('MM/DD/YYYY');
    if (data.invoice_id) mappedData.invoice_id = data.invoice_id;
    if (data.invoice_type) mappedData.invoice_type = data.invoice_type;

    if (data.pay_type) {
      mappedData.paymentType = data.pay_type;
      if (data.pay_type == '2') {
        if (data?.invoice_due_date) {
          mappedData.invoice_due_date = moment(data.invoice_due_date).format(
            'YYYY-MM-DD'
          );
        }
        if (data?.first_paid) {
          mappedData.first_paid = data.first_paid;
        }
        if (data?.first_pay_type) {
          mappedData.first_pay_type = data.first_pay_type;
          if (data?.first_pay_type == '5') {
            if (data?.first_pay_img?.length) {
              mappedData.first_pay_img = data.first_pay_img[0];
            }
          }
        }
        if (watch('amount')) {
          mappedData.deferred = watch('amount');
        }
      } else if (data.pay_type == '5') {
        if (data?.invoice_image?.length) {
          mappedData.bank_image = data.invoice_image[0];
        }
        if (watch('amount')) {
          mappedData.bank = watch('amount');
        }
      } else if (data.pay_type == '6') {
        mappedData.totalInvoicePrice = watch('amount');
        if (data?.multiPaymentVisa) {
          mappedData.visa = data.multiPaymentVisa;
        }
        if (data?.multiPaymentCash) {
          mappedData.cash = data.multiPaymentCash;
        }
      }
    }
    const formData = new FormData();
    for (let key in mappedData) {
      formData.append(key, mappedData[key]);
    }
    setIsSubmittingForm(true);
    setIsLoading(true);
    customApiRequest(
      addNewCustomerPaymentApi(user?.token, formData),
      (res) => {
        setIsLoading(false);
        setIsSubmittingForm(false);
        if (res?.status === 200 && res?.data?.data) {
          setModalOpened(false);
          setSelectedCustomerPaymentId(res?.data?.data?.id);
          setFetchCount((prevState) => prevState + 1);
          if (setAllSuppliersFetchCount)
            setAllSuppliersFetchCount((prev) => prev + 1);
          successNotification({
            title: t('Messages.addedSuccessfully'),
            message: res.data.message
          });
        } else {
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message: res?.data?.message
              ? res.data.message
              : t('Messages.TryAgainLater')
          });
        }
      },
      (error) => {
        setIsLoading(false);
        setIsSubmittingForm(false);
        errorNotification({
          title: t('Messages.ErrorOccurred'),
          message: error?.response?.data?.message || t('Messages.TryAgainLater')
        });
      }
    );
    // if (selectedCustomerPayment) {
    //   mappedData.id = selectedCustomerPayment.id;
    //   customApiRequest(
    //     addNewCustomerPaymentApi(user?.token, mappedData, true),
    //     (res) => {
    //       setIsLoading(false);
    //       setIsSubmittingForm(false);
    //       if (res?.status === 200 && res?.data?.data) {
    //         setIsLoading(false);
    //         setModalOpened(false);
    //         setSelectedCustomerPaymentId(res?.data?.data?.id);
    //         setFetchCount((prevState) => prevState + 1);
    //         if (setAllSuppliersFetchCount)
    //           setAllSuppliersFetchCount((prev) => prev + 1);
    //         successNotification({
    //           title: 'العملية تمت بنجاح',
    //           message: 'تم تعديل المورد بنجاح'
    //         });
    //       } else {
    //         setIsLoading(false);
    //         errorNotification({
    //           title: 'حدث خطأ',
    //           message: res?.data?.message
    //             ? res.data.message
    //             : 'حاول فى وقت لاحق'
    //         });
    //       }
    //     },
    //     (error) => {
    //       setIsLoading(false);
    //       setIsSubmittingForm(false);
    //       errorNotification({
    //         title: 'حدث خطأ',
    //         message: error?.response?.data?.message || 'حاول فى وقت لاحق'
    //       });
    //     }
    //   );
    // } else {
    //   customApiRequest(
    //     addNewCustomerPaymentApi(user?.token, mappedData),
    //     (res) => {
    //       setIsLoading(false);
    //       setIsSubmittingForm(false);
    //       if (res?.status === 200 && res?.data?.data) {
    //         setModalOpened(false);
    //         setSelectedCustomerPaymentId(res?.data?.data?.id);
    //         setFetchCount((prevState) => prevState + 1);
    //         if (setAllSuppliersFetchCount)
    //           setAllSuppliersFetchCount((prev) => prev + 1);
    //         successNotification({
    //           title: 'العملية تمت بنجاح',
    //           message: 'تم اضافة المورد بنجاح'
    //         });
    //       } else {
    //         errorNotification({
    //           title: 'حدث خطأ',
    //           message: res?.data?.message
    //             ? res.data.message
    //             : 'حاول فى وقت لاحق'
    //         });
    //       }
    //     },
    //     (error) => {
    //       setIsLoading(false);
    //       setIsSubmittingForm(false);
    //       errorNotification({
    //         title: 'حدث خطأ',
    //         message: error?.response?.data?.message || 'حاول فى وقت لاحق'
    //       });
    //     }
    //   );
    // }
  };

  const [form] = Form.useForm();

  return (
    <Form
      className="customers-payments-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="select-label-wrap">
          <p className="label-p">{t('ClientPayments.ClientName')}</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="user_id"
              errorMsg={errors?.user_id?.message}
              validateStatus={errors?.user_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder={t('ClientPayments.ClientName')}
              options={
                allClients?.length > 0 &&
                allClients.map((role) => ({
                  title: role.name,
                  value: String(role.id)
                }))
              }
              disabled={true}
              // defaultValues={selectedCustomerPayment?.name}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="customers-payments-form"
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('ClientPayments.AmountLabel')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="amount"
              type="text"
              placeholder={t('ClientPayments.AmountLabel') + '...'}
              errorMsg={errors?.amount?.message}
              validateStatus={errors?.amount ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="date-picker-wrap">
          <p className="label-p">{t('ClientPayments.DateLabel')}</p>
          <CustomAntdDatePicker
            // disablePast
            control={control}
            name="date"
            className="receipt-date-picker"
            formClassName="customers-payments-form"
            // disablePast={true}
          />
          {errors?.date && <p className="error-p">{errors.date.message}</p>}
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p"> {t('ClientPayments.NotesLabel')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="notes"
              rows={8}
              placeholder={t('ClientPayments.NotesLabel') + '...'}
              errorMsg={errors?.notes?.message}
              validateStatus={errors?.notes ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        {/* <div className="select-label-wrap">
          <p className="label-p"> نوع الفاتورة </p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="invoice_type"
              errorMsg={errors?.invoice_type?.message}
              validateStatus={errors?.invoice_type ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="نوع الفاتورة "
              options={[
                {
                  title: 'فاتورة شراء',
                  value: '2'
                },
                {
                  title: 'فاتورة بيع',
                  value: '1'
                }
              ]}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="customers-payments-form"
            />
          </div>
        </div> */}
        {watch('user_id') && (
          <div className="select-label-wrap">
            <p className="label-p"> {t('ClientPayments.InvoiceLabel')} </p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name="invoice_id"
                errorMsg={errors?.invoice_id?.message}
                validateStatus={errors?.invoice_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder={t('ClientPayments.InvoiceLabel')}
                options={
                  deferredInvoices?.length > 0 &&
                  deferredInvoices.map((role) => ({
                    title: `رقم الفاتورة ${role.id} [ الإجمالي : ${
                      role.total
                    } ] [ المتبقي : ${role.rest ? role?.rest : 0} ]`,
                    value: String(role.invoice_id)
                  }))
                }
                showSearch={true}
                                 filterOption={(input, option) =>
                option?.children?.props?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
               }
                formClassName="customers-payments-form"
              />
            </div>
          </div>
        )}

        {/* paytype section */}
        <div
          className="radios-field-array-wrap no-border"
          style={{ paddingBottom: '22px' }}
        >
          <AntdRadioGroup
            name="pay_type"
            className="form-radio-group"
            control={control}
            label={t('ClientPayments.PaymentTypeLabel')}
            validateStatus={errors?.pay_type ? 'error' : ''}
            errorMsg={errors?.pay_type?.message}
            radios={paymentMethods}
            // isRadioButton
          />

          {/* {String(watch('pay_type')) == '2' && (
            <div
              className="ingredients-add-btn-wrap"
              style={{
                display: 'grid',
                alignItems: 'start',
                gridTemplateColumns: '1fr 1fr',
                width: '100%',
                gap: '0 30px'
              }}
            >
              <div className="date-picker-wrap">
                <p style={{ marginBottom: '8px' }}>
                  تاريخ السداد ( DD / MM / YYYY )
                </p>
                <CustomAntdDatePicker
                  // disablePast
                  control={control}
                  name={`invoice_due_date`}
                  className="receipt-date-picker"
                  formClassName="customers-payments-form"
                  errorMsg={
                    errors?.invoice_due_date && errors.invoice_due_date.message
                  }
                  validateStatus={errors?.invoice_due_date ? 'error' : ''}
                  setValue={setValue}
                  disablePast={true}
                />
              </div>
              <div className="text-field-label-wrap">
                <p className="label-p">الدفعة الأولى</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name={`first_paid`}
                    type="text"
                    placeholder="أدخل قيمة الدفعة الأولى"
                    errorMsg={errors?.first_paid && errors?.first_paid?.message}
                    validateStatus={errors?.first_paid ? 'error' : ''}
                    control={control}
                  />
                </div>
              </div>
              <div className="text-field-label-wrap">
                <div className="text-field-wrap">
                  <AntdRadioGroup
                    name="first_pay_type"
                    className="form-radio-group"
                    control={control}
                    label="نوع الدفع للدفعة الأولى"
                    validateStatus={errors?.first_pay_type ? 'error' : ''}
                    errorMsg={errors?.first_pay_type?.message}
                    // radios={paymentMethods}
                    radios={[
                      {
                        title: 'كاش',
                        value: '1'
                      },
                      {
                        title: 'شبكه',
                        value: '3'
                      },
                      {
                        title: 'تحويل بنكى',
                        value: '5'
                      }
                    ]}
                    // isRadioButton
                  />
                </div>
              </div>
            </div>
          )}
          {String(watch('first_pay_type')) == '5' && (
            <div className="product-img-wrap">
              <h3 style={{ marginBottom: '6px' }}>صورة الإيصال</h3>

              <MyDropZone
                className="product-header-dropzone"
                multipleFiles={false}
                handleFilesDrop={(acceptedFiles) =>
                  handleFilesDrop(
                    acceptedFiles,
                    invoiceFileToUploadDefferd,
                    setInvoiceFileToUploadDefferd
                  )
                }
                filesToUpload={invoiceFileToUploadDefferd}
                id="avatar-input"
                dropzoneText="صورة المنتج"
                inputName="first_pay_img"
                icon={<CameraFilled className="dropzone-camera" />}
                dropZoneUrls={
                  watch('first_pay_img')?.file
                    ? [watch('first_pay_img')?.file]
                    : null
                }
              >
                اسحب الصورة وضعها هنا
              </MyDropZone>

              <MyDropZonePreview
                filesToUpload={invoiceFileToUploadDefferd}
                clearFileFromUpload={(f) =>
                  clearFileFromUpload(f, setInvoiceFileToUploadDefferd)
                }
              />
              {errors?.first_pay_img && (
                <p
                  className="error-p"
                  style={{ color: '#ff4d4f', fontSize: '14px' }}
                >
                  {errors?.first_pay_img?.message}
                </p>
              )}
            </div>
          )} */}
          {String(watch('pay_type')) == '5' && (
            <div className="product-img-wrap">
              <h3 style={{ marginBottom: '6px' }}>
                {t('Pos.Main.copyOfReceipt')}
              </h3>

              <MyDropZone
                className="product-header-dropzone"
                multipleFiles={false}
                handleFilesDrop={(acceptedFiles) =>
                  handleFilesDrop(
                    acceptedFiles,
                    invoiceFileToUpload,
                    setInvoiceFileToUpload
                  )
                }
                filesToUpload={invoiceFileToUpload}
                id="avatar-input"
                dropzoneText={t('Pos.Main.productImage')}
                inputName="invoice_image"
                icon={<CameraFilled className="dropzone-camera" />}
                dropZoneUrls={
                  watch('invoice_image')?.file
                    ? [watch('invoice_image')?.file]
                    : null
                }
              >
                {t('Pos.Main.dragImageAndPlaceItHere')}
              </MyDropZone>

              <MyDropZonePreview
                filesToUpload={invoiceFileToUpload}
                clearFileFromUpload={(f) =>
                  clearFileFromUpload(f, setInvoiceFileToUpload)
                }
              />
              {errors?.invoice_image && (
                <p
                  className="error-p"
                  style={{ color: '#ff4d4f', fontSize: '14px' }}
                >
                  {errors?.invoice_image?.message}
                </p>
              )}
            </div>
          )}
          {String(watch('pay_type')) == '6' && (
            <div className="cheque-wrapper">
              <div className="text-field-label-wrap">
                <p className="label-p">{t('Quotations.cash')}</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name={`multiPaymentCash`}
                    type="text"
                    placeholder={t('Quotations.cashAmount')}
                    errorMsg={
                      errors?.multiPaymentCash &&
                      errors?.multiPaymentCash?.message
                    }
                    validateStatus={errors?.multiPaymentCash ? 'error' : ''}
                    control={control}
                    defaultValue={
                      watch('multiPaymentVisa')
                        ? watch('amount') -
                            parseFloat(watch('multiPaymentVisa')) >
                          0
                          ? Math.ceil(
                              parseFloat(
                                watch('amount') -
                                  parseFloat(watch('multiPaymentVisa'))
                              ) * 100
                            ) / 100
                          : 0
                        : null
                    }
                  />
                </div>
              </div>
              <div className="text-field-label-wrap">
                <p className="label-p">{t('Quotations.Network')}</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name={`multiPaymentVisa`}
                    type="text"
                    placeholder={t('Quotations.EnterNetworkAmount')}
                    errorMsg={
                      errors?.multiPaymentVisa &&
                      errors.multiPaymentVisa.message
                    }
                    validateStatus={errors?.multiPaymentVisa ? 'error' : ''}
                    control={control}
                    defaultValue={
                      watch('multiPaymentCash')
                        ? watch('amount') -
                            parseFloat(watch('multiPaymentCash')) >
                          0
                          ? Math.ceil(
                              parseFloat(
                                watch('amount') -
                                  parseFloat(watch('multiPaymentCash'))
                              ) * 100
                            ) / 100
                          : 0
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* ./paytype section */}
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {t('Documents.addition')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
