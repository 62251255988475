import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import SettingsSystemUsersContext from '../../../contexts/settings-contexts/SettingsSystemUsersProvider';
import { useTranslation } from 'react-i18next';

const SettingsWorkPlaceModal = () => {
  const { modalOpened, setModalOpened, setSelectedUser, selectedUser } =
    useContext(SettingsSystemUsersContext);
  const { t } = useTranslation();

  return (
    <Modal
      transitionName=""
      className="settings-users-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={selectedUser ? t('Users.editUserData') : t('Users.AddNewUser')}
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedUser(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedUser(null);
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default SettingsWorkPlaceModal;
