import axios from 'axios';

const getAllProducts = async (token, values, type , is_select) => {
  try {
    let url;
    if (!values.noTest) {
      url = `/Product/all?product_type=${
        values?.product_type_value ? values?.product_type_value && values?.product_type_value !== 0
          ? values.product_type_value
          : values?.product_type_value == 0
          ? ''
          : values : ''
      }&type=${type ? type : ''}&page=${
        values?.page ? values.page : ''
      }&test=1&searchText=${values?.searchText ? values.searchText : ''}`;
      if (type)
        url = `/Product/all?type=${type}&test=1&searchText=${
          values?.searchText ? values.searchText : ''
        }`;
    } else {
      url = `/Product/all?type=${type ? type : ''}&product_type=${
        values?.product_type_value ? values?.product_type_value && values?.product_type_value !== 0
          ? values.product_type_value
          : values?.product_type_value == 0
          ? ''
          : values : ''
      }&is_select=${is_select ? is_select : ''}`;
    }

    const res = await axios.get(url, {
      
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllProducts;
