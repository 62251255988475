import { Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import getAccountInfoApi from '../../../apis/accounting-apis/accounting-details-apis/getAccountInfoApi';
import AccountingContext from '../../../contexts/accounting-context/AccountingContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import './ViewAccountInfo.scss';

const ViewAccountInfo = () => {
  const { user } = useContext(UserContext);
  const {
    setSelectedAccountId,
    selectedAccountId,
    selectedTransactionId,
    setIsLoading,
    isLoading,
    accountInfo,
    setAccountInfo,
    fetchCount,
    isInfoLoading,
    setIsInfoLoading
  } = useContext(AccountingContext);
  const { t } = useTranslation();

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsInfoLoading(true);
      customApiRequest(
        getAccountInfoApi(user?.token, selectedAccountId),
        (res) => {
          setIsInfoLoading(false);
          setAccountInfo(res?.data?.data);
        },
        (error) => {
          setIsInfoLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const handleFetchDataForAccount = () => {
    return (
      <div className="view-accounts-content">
        <div>
          {/* <h4>اسم الحـسـاب</h4> */}
          <h4>{t('Accounts.AccountName')}</h4>
          <span> : </span>
          <span>{accountInfo?.name || 'لا يوجد'}</span>
        </div>
        <div>
          {/* <h4>رقم الحـسـاب</h4> */}
          <h4>{t('Accounts.AccountNumber')}</h4>

          <span> : </span>
          <span>{accountInfo?.acn || t('Accounts.nothing')}</span>
        </div>
        <div>
          {/* <h4>الرصيد</h4> */}
          <h4>{t('Accounts.Balance')}</h4>

          <span> : </span>
          <span>
            {(accountInfo?.current_balance && accountInfo?.current_balance) ||
              t('Accounts.nothing')}
          </span>
        </div>
        {/* <div>
                    <h4>موعد الافتتاح</h4>
                    <span> : </span>
                    <span>{(accountInfo?.created_at && accountInfo?.created_at?.split('T')[0]) || 'لا يوجد'}</span>
                </div> */}
        <div>
          {/* <h4>مـلـحـوظـه</h4> */}
          <h4>{t('Accounts.Note')}</h4>

          <span> : </span>
          <span>{(accountInfo?.note && accountInfo?.note) || 'لا يوجد'}</span>
        </div>
        <div>
          {/* <h4>النـــوع</h4> */}
          <h4>{t('Accounts.type')}</h4>

          <span> : </span>
          <span>{(accountInfo?.type && accountInfo?.type) || 'لا يوجد'}</span>
        </div>
      </div>
    );
  };
  // const handleFetchDataForAccount = () => {
  //     if (!accountInfo) {
  //         <div className="spin-tree-wrap">
  //             <Spin />
  //         </div>
  //     } else if (accountInfo) {
  //         return(
  //             <div className='view-accounts-content'>
  //                 <div>
  //                     <h4>اسم الحـسـاب</h4>
  //                     <span> : </span>
  //                     <span>{accountInfo?.name || 'لا يوجد'}</span>
  //                 </div>
  //                 <div>
  //                     <h4>رقم الحـسـاب</h4>
  //                     <span> : </span>
  //                     <span>{accountInfo?.acn || 'لا يوجد'}</span>
  //                 </div>
  //                 <div>
  //                     <h4>الرصيد</h4>
  //                     <span> : </span>
  //                     <span>{(accountInfo?.current_balance && accountInfo?.current_balance) || 'لا يوجد'}</span>
  //                 </div>
  //                 <div>
  //                     <h4>موعد الافتتاح</h4>
  //                     <span> : </span>
  //                     <span>{(accountInfo?.created_at && accountInfo?.created_at?.split('T')[0]) || 'لا يوجد'}</span>
  //                 </div>
  //                 <div>
  //                     <h4>مـلـحـوظـه</h4>
  //                     <span> : </span>
  //                     <span>{(accountInfo?.note && accountInfo?.note) || 'لا يوجد'}</span>
  //                 </div>
  //                 <div>
  //                     <h4>النـــوع</h4>
  //                     <span> : </span>
  //                     <span>{(accountInfo?.type && accountInfo?.type) || 'لا يوجد'}</span>
  //                 </div>
  //             </div>
  //         )
  //     }
  // }

  return (
    <>
      {accountInfo ? (
        <div className="view-accounts">
          <div className="view-accounts-center">
            {/* <h2>تفاصيل الحســاب</h2> */}
            <h2>{t('Accounts.AccountDetail')}</h2>

            {/* <Button>دفتر الأستاذ</Button> */}
          </div>
          <hr style={{ marginBottom: '20px' }} />
          {handleFetchDataForAccount()}
        </div>
      ) : (
        <>
          {isInfoLoading ? (
            <div className="spin-tree-wrap">
              <Spin />
            </div>
          ) : (
            <h2 style={{ textAlign: 'center' }}>
              {t('Messages.noDataAvailable')}
            </h2>
          )}
        </>
      )}
    </>
  );
};

export default ViewAccountInfo;
