import React, { useContext } from 'react';
import { Modal, Tabs } from 'antd';
import TableForm from './TableForm';
import IncomingPurchaseInvoiceForm from '../store-incoming-purchase-invoices-page/TableForm';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import ClientsContext from '../../../contexts/clients-context/ClientsContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import TableProductsForm from './TableProductsForm';
import ConvertToInvoiceForm from './ConvertToInvoiceForm';
import { useTranslation } from 'react-i18next';

const ConvertToInvoiceModal = ({
  modalVisible,
  setModalVisible,
  isHomepage = false
}) => {
  const {
    convertModalOpened,
    setConvertModalOpened,
    setSelectedReceipt,
    selectedReceipt
  } = useContext(OutgoingPurchaseInvoiceContext);

  const { setSelectedClientId } = useContext(ClientsContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={t('Quotations.Convertaquotetoaninvoice')}
      visible={convertModalOpened}
      onOk={() => {
        setConvertModalOpened(false);
        setSelectedReceipt(null);
        setSelectedClientId('');
        setModalVisible && setModalVisible(false);
      }}
      onCancel={() => {
        setConvertModalOpened(false);
        setSelectedReceipt(null);
        setSelectedClientId('');
        setModalVisible && setModalVisible(false);
      }}
      footer={false}
    >
      <ConvertToInvoiceForm />
    </Modal>
  );
};

export default ConvertToInvoiceModal;
