import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import ArchiveNotesContext from '../../../contexts/archive-contexts/ArchiveNotesProvider';
import './ArchiveNotesPage.scss';
import PageModal from './PageModal';
import PageTable from './PageTable';
import StickyNoteModal from './StickyNoteModal';

const ArchiveNotesPage = () => {
  const { modalOpened, setModalOpened, selectedNote, setSelectedNote } =
    useContext(ArchiveNotesContext);
  const { t } = useTranslation();

  return (
    <div className="archive-notes-page">
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">{t('Notes.Notes')}</h2>
          <p>{t('Notes.ShowAndDisplayAllNotes')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('AppBar.Products.archivePage.archiveNotesPage')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.archivePage.title')}
            title3={t('AppBar.Products.archivePage.archiveNotesPage')}
            link3={'/archive/notes'}
          />
        </div>
        <SearchNotificationsProfile />
      </div>

      <div className="add-section" >
        {/* <div className="section-text">
          <p className="bold-font">وحدات القياس</p>
        </div> */}
        <div
          className="add-account-btn"
          onClick={() => {
            // if selected unit then reset it
            // open the unit modal
            setSelectedNote(null);
            setModalOpened(true);
          }}
        >
          <span className="text-span">{t('Notes.AddANewNote')}</span>
        </div>
      </div>

      <PageTable />

      {modalOpened && <PageModal />}
      {selectedNote?.note && <StickyNoteModal />}
      {/* {stickyNoteModalOpened && <StickyNoteModal />} */}
    </div>
  );
};

export default ArchiveNotesPage;
