import axios from 'axios';

const createTag = async (token, values, url) => {
  try {
    const res = await axios.post(url, values, {
      headers: {
        baseUrl: process.env.REACT_APP_BASE_URL,
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    return res;
  } catch (error) {}
};

export default createTag;
