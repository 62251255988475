/* eslint-disable eqeqeq */
import * as Yup from 'yup';

const sharedProductsSchemaObj = (t) => {
  return {
    name: Yup.string().test(
      'products.index.name',
      t('purchaseInvoices.serviceNameValidation'),
      (v, context) => {
        let result = true;
        if (!v) {
          result = false;
        }
        return result;
      }
    ),
    price: Yup.string()
      .test(
        'products.index.price',
        t('purchaseInvoices.enterPrice'),
        (v, context) => {
          let result = true;
          // if the bill of type products === 1 and no value of price
          if (!v || v == 0) result = false;
          return result;
        }
      )
      .test(
        'products.index.price',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (v && !v.match(/^(\d+)?(\.\d+)?$/)) result = false;
          return result;
        }
      ),
    quantity: Yup.string()
      .test(
        'products.index.quantity',
        t('purchaseInvoices.quantityValidation'),
        (v, context) => {
          let result = true;
          if (!v) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.quantity',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            // !v.match(/^[0-9]+$/) &&
            !v.match(/^(\d+)?(\.\d+)?$/)
          ) {
            result = false;
          }
          return result;
        }
      ),
    tax: Yup.string().test(
      'products.index.tax',
      t('purchaseInvoices.onlyNumbersAllowed'),
      (v, context) => {
        let result = true;
        if (v && !v.match(/^(\d+)?(\.\d+)?$/)) {
          result = false;
        }
        return result;
      }
    ),
    discount_type: Yup.string().test(
      'products.index.discount_type',
      t('purchaseInvoices.chooseDiscountTypeValidation'),
      (v, context) => {
        let result = true;
        if (!v) result = false;
        return result;
      }
    ),
    discount_price: Yup.string()
      .test(
        'products.index.discount_price',
        t('purchaseInvoices.enterDiscountPrice'),
        (v, context) => {
          let result = true;
          if (!v && context.parent.discount_type == '1') {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.discount_price',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.parent.discount_type == '1'
          ) {
            result = false;
          }
          return result;
        }
      ),
    // .test(
    //   'products.index.discount_price',
    //   'سعر الخصم اعلى من السعر',
    //   (v, context) => {
    //     let result = true;
    //     if (
    //       v &&
    //
    //       context.parent.discount_type == '1' &&
    //       parseInt(v) > parseInt(context.parent.price)
    //     )
    //       result = false;
    //     return result;
    //   }
    // ),
    discount_percentage: Yup.string()
      .test(
        'products.index.discount_percentage',
        t('purchaseInvoices.enterDiscountRate'),
        (v, context) => {
          let result = true;
          if (!v && context.parent.discount_type == '2') {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.discount_percentage',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.parent.discount_type == '2'
          ) {
            result = false;
          }
          return result;
        }
      )
      .test(
        'products.index.discount_price',
        t('purchaseInvoices.returnedQuantityValidate'),
        (v, context) => {
          let result = true;
          if (v && context.parent.discount_type == '2' && parseInt(v) > 100)
            result = false;
          return result;
        }
      )
  };
};

const sharedSchemaObj = (t) => {
  return {
    supplier_id: Yup.string().required(t('purchaseInvoices.chooseSupplier')),
    // serviceOrPackage: Yup.string().required('اختار نوعية الفاتورة'),
    products: Yup.array().of(
      Yup.object().shape({
        ...sharedProductsSchemaObj(t)
      })
    ),

    // due_date: Yup.mixed().required('اختار تاريخ الفاتورة'),
    discount_type: Yup.string().required(
      t('purchaseInvoices.chooseDiscountTypeValidation')
    ),
    discount_price: Yup.string()
      .test(
        'discount_price',
        t('purchaseInvoices.enterDiscountPrice'),
        (v, context) => {
          let result = true;
          if (!v && context.parent?.discount_type === '1') result = false;
          return result;
        }
      )
      .test(
        'discount_price',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.parent?.discount_type === '1'
          )
            result = false;
          return result;
        }
      ),
    discount_percentage: Yup.string()
      .test(
        'discount_percentage',
        t('purchaseInvoices.enterDiscountRate'),
        (v, context) => {
          let result = true;
          if (!v && context.parent?.discount_type === '2') result = false;
          return result;
        }
      )
      .test(
        'discount_percentage',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.parent?.discount_type === '2'
          )
            result = false;
          return result;
        }
      ),
    extra_prices: Yup.array().of(
      Yup.object().shape({
        price: Yup.string()
          .test(
            'extra_prices.index.price',
            t('purchaseInvoices.enterPrice'),
            (v, context) => {
              let result = true;
              if (!v && context.parent.title) {
                result = false;
              }
              return result;
            }
          )
          .test(
            'extra_prices.index.price',
            t('purchaseInvoices.onlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (v && !v.match(/^(\d+)?(\.\d+)?$/)) {
                result = false;
              }
              return result;
            }
          ),
        title: Yup.string().test(
          'extra_prices.index.title',
          t('purchaseInvoices.enterPriceStatement'),
          (v, context) => {
            let result = true;
            if (!v && context.parent.price) {
              result = false;
            }
            return result;
          }
        )
      })
    ),
    pay_type: Yup.string().required(t('purchaseInvoices.payTypeValidation')),
    multiPaymentVisa: Yup.string()
      .test(
        'multiPaymentVisa',
        t('purchaseInvoices.enterVisaValidation'),
        (v, context) => {
          let result = true;
          if (!v && context.from[0].value.pay_type == '6') {
            result = false;
          }
          return result;
        }
      )
      .test(
        'multiPaymentVisa',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[0].value.pay_type == '6'
          ) {
            result = false;
          }
          return result;
        }
      ),
    multiPaymentCash: Yup.string()
      .test(
        'multiPaymentCash',
        t('purchaseInvoices.enterCashValidation'),
        (v, context) => {
          let result = true;
          if (!v && context.from[0].value.pay_type == '6') {
            result = false;
          }
          return result;
        }
      )
      .test(
        'multiPaymentCash',
        t('purchaseInvoices.onlyNumbersAllowed'),
        (v, context) => {
          let result = true;
          if (
            v &&
            !v.match(/^(\d+)?(\.\d+)?$/) &&
            context.from[0].value.pay_type == '6'
          ) {
            result = false;
          }
          return result;
        }
      ),
    invoice_due_date: Yup.mixed().test(
      'invoice_due_date',
      t('purchaseInvoices.paymentDateValidation'),
      (v, context) => {
        let result = true;
        if (!v && context.from[0].value.pay_type == '2') {
          result = false;
        }
        return result;
      }
    ),
    // first_paid: Yup.mixed().test(
    //   'first_paid',
    //   'من فضلك أدخل الدفعة الأولى',
    //   (v, context) => {
    //     let result = true;
    //     if (!v && context.from[0].value.pay_type == '2') {
    //       result = false;
    //     }
    //     return result;
    //   }
    // ),
    invoice_image: Yup.mixed()
  };
};

const newInvoiceSchema = (t) => {
  return Yup.object().shape({
    ...sharedSchemaObj(t)
  });
};

const editInvoiceSchema = (t) => {
  return Yup.object().shape({
    ...sharedSchemaObj(t),
    products: Yup.array().of(
      Yup.object().shape({
        ...sharedProductsSchemaObj(t),
        returnQuantity: Yup.string()
          .test(
            'products.index.returnQuantity',
            t('purchaseInvoices.returnedQuantityValidate'),
            (v, context) => {
              let result = true;
              if (!v) {
                result = false;
              }
              return result;
            }
          )
          .test(
            'products.index.returnQuantity',
            t('purchaseInvoices.onlyNumbersAllowed'),
            (v, context) => {
              let result = true;
              if (
                v &&
                // !v.match(/^[0-9]+$/) &&
                !v.match(/^(\d+)?(\.\d+)?$/)
              ) {
                result = false;
              }
              return result;
            }
          )
      })
    )
  });
};

const getIncommingInvoiceSchema = (selectedReceipt, t) => {
  if (!selectedReceipt) return newInvoiceSchema(t);

  return editInvoiceSchema(t);
};

export default getIncommingInvoiceSchema;
