/* eslint-disable eqeqeq */
import { Descriptions, Empty, Table } from 'antd';
import QRCode from 'qrcode.react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import WaterMark from '../../../common/water-mark/WaterMark';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
const ReceiptDetailsComponent = React.forwardRef((props, ref) => {
  const { selectedReceipt } = props;
  const systemSettings = useSystemSettings();
  const { t } = useTranslation();

  const renderInvoiceHeading = () => {
    if (selectedReceipt?.type == 2) {
      return <p>{t('purchaseInvoices.returnedInvoice')}</p>;
    } else if (selectedReceipt?.type == 1) {
      if (props?.isIncomingInvoice) {
        return t('purchaseInvoices.taxInvoice');
      } else {
        if (selectedReceipt?.user?.taxNumber && selectedReceipt?.supplier) {
          return t('purchaseInvoices.taxPurchaseInvoice');
        } else if (
          !selectedReceipt?.user?.taxNumber &&
          selectedReceipt?.supplier
        ) {
          return t('purchaseInvoices.simplifiedPurchaseInvoice');
        }
        return t('purchaseInvoices.purchaseInvoice');
      }
    }
  };

  const renderDiscountValue = (record) => {
    if (record.discountType == '1') {
      // fixed discount price
      return record.discountPrice;
    } else if (record.discountType == '2') {
      // percentage discount
      return `${record.discountPrice}`;
    } else {
      // no discount
      return t('purchaseInvoices.withoutDiscount');
    }
  };

  const renderTitle = (invoice_type) => {
    if (invoice_type === 1) {
      return t('purchaseInvoices.buyer');
    } else if (invoice_type === 2) {
      return ` ${t('purchaseInvoices.supplier')} :`;
    }
    return '';
  };

  const renderUser = (obj, type) => {
    const {
      name,
      phone,
      address,
      addressCode,
      VATRegistrationNumber,
      tax_number,
      taxNumber,
      commercialRecord
    } = obj;
    return (
      <div className="supplier-details-wrap">
        {/* <div
          style={{
            marginBottom: 2
          }}
        >
          {renderTitle(type)}
        </div> */}
        <Descriptions title={renderTitle(type)} bordered column={1}>
          {name && (
            <Descriptions.Item label={t('purchaseInvoices.name')}>
              {name}
            </Descriptions.Item>
          )}
          {phone && (
            <Descriptions.Item label={t('purchaseInvoices.phone')}>
              {phone}
            </Descriptions.Item>
          )}
          {address && (
            <Descriptions.Item label={t('purchaseInvoices.address')}>
              {address}
            </Descriptions.Item>
          )}
          {!address && addressCode && (
            <Descriptions.Item label={t('purchaseInvoices.address')}>
              {addressCode}
            </Descriptions.Item>
          )}
          {VATRegistrationNumber && (
            <Descriptions.Item label={t('purchaseInvoices.VATNumber')}>
              {VATRegistrationNumber}
            </Descriptions.Item>
          )}
          {tax_number && (
            <Descriptions.Item label={t('purchaseInvoices.VATNumber')}>
              {tax_number}
            </Descriptions.Item>
          )}
          {taxNumber && (
            <Descriptions.Item label={t('purchaseInvoices.VATNumber')}>
              {taxNumber}
            </Descriptions.Item>
          )}
          {commercialRecord && (
            <Descriptions.Item
              label={t('purchaseInvoices.CommercialRegistryNumber')}
            >
              {commercialRecord}
            </Descriptions.Item>
          )}
          {/* {buildingNum && (
            <Descriptions.Item label="رقـــم المبنى">
              {buildingNum}
            </Descriptions.Item>
          )}
          {streetName && (
            <Descriptions.Item label="اســم الشارع">
              {streetName}
            </Descriptions.Item>
          )}
          {district && (
            <Descriptions.Item label="الحــى">{district}</Descriptions.Item>
          )}
          {city && (
            <Descriptions.Item label="المدينة">{city}</Descriptions.Item>
          )}
          {country && (
            <Descriptions.Item label="البـلد">{country}</Descriptions.Item>
          )}
          {postalCode && (
            <Descriptions.Item label="الرمز البريدى">
              {postalCode}
            </Descriptions.Item>
          )}
          {addressCode && (
            <Descriptions.Item label="الرقم الاضافى للعنوان">
              {addressCode}
            </Descriptions.Item>
          )}
          {VATRegistrationNumber && (
            <Descriptions.Item label="رقم ضريبة القيمة المضافة">
              {VATRegistrationNumber}
            </Descriptions.Item>
          )}
          {tax_number && (
            <Descriptions.Item label="رقم ضريبة القيمة المضافة">
              {tax_number}
            </Descriptions.Item>
          )}
          {anotherId && (
            <Descriptions.Item label="معرف أخر">{anotherId}</Descriptions.Item>
          )} */}
        </Descriptions>
      </div>
    );
  };

  const renderModels = () => {
    const products = selectedReceipt.products;
    if (products?.length > 0) {
      return (
        <div className="models-wrap pagebreak">
          <div className="models-title bold-font">
            {t('purchaseInvoices.serviceDescription')} :
          </div>
          <Table
            bordered
            locale={{
              emptyText: (
                <Empty description={false}>
                  {t('purchaseInvoices.noData')}
                </Empty>
              )
            }}
            pagination={false}
            // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            // className="antd-custom-table"
            dataSource={products.map((item, index) => ({
              ...item,
              key: item.id,
              index
            }))}
            columns={[
              {
                title: 'م',
                dataIndex: 'id',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-index bold-font">
                      <span>{record.index + 1}</span>
                      {/* <span># {record.id}</span> */}
                    </div>
                  );
                }
              },
              {
                title: t('purchaseInvoices.name'),
                dataIndex: 'name',
                width: 150,
                render: (_, record) => {
                  // const wordsCount = countWords(String(record?.name || record?.product?.name || record[record.index]?.product?.name));
                  // if ((record?.name || record?.product?.name || record[record.index]?.product?.name) && wordsCount > 0) {
                  //   if (wordsCount > 3) {
                  //     return (
                  //       <Popover title="اقرأ المزيد" content={record?.name || record?.product?.name || record[record.index]?.product?.name}>
                  //         <Button size="small" type="text">
                  //           <p className="has-read-more">
                  //             <span>{trimWords(recordText, 4)}</span>
                  //             <ReadOutlined
                  //               className="read-more-book"
                  //               style={{ margin: '0 4px' }}
                  //             />
                  //           </p>
                  //         </Button>
                  //       </Popover>
                  //     );
                  //   } else
                  //     return (
                  //       <div className="row-cell row-text">{recordText}</div>
                  //     );
                  // }
                  return (
                    <div className="row-cell row-text desc-wrap">
                      <span>
                        {record.name ||
                          record?.product?.name ||
                          record[record.index]?.product?.name}
                      </span>
                    </div>
                  );
                }
              },
              {
                title: t('purchaseInvoices.description'),
                dataIndex: 'desc',
                render: (_, record) => {
                  const recordText = String(record?.desc);
                  // const wordsCount = countWords(String(record.desc));
                  // if (record.desc && wordsCount > 0) {
                  //   if (wordsCount > 10) {
                  //     return (
                  //       <Popover title="اقرأ المزيد" content={record.desc}>
                  //         <Button size="large" type="text">
                  //           <p className="has-read-more">
                  //             <span>{trimWords(recordText, 4)}</span>
                  //             <ReadOutlined
                  //               className="read-more-book"
                  //               style={{ margin: '0 4px' }}
                  //             />
                  //           </p>
                  //         </Button>
                  //       </Popover>
                  //     );
                  //   } else
                  //     return (
                  //       <div className="row-cell row-text">{recordText}</div>
                  //     );
                  // }

                  if (record?.desc) {
                    return (
                      <div className="row-cell row-text desc-wrap">
                        {recordText}
                      </div>
                    );
                  } else {
                    return '----';
                  }
                }
              },
              {
                title: t('purchaseInvoices.quantity'),
                dataIndex: 'quantity',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>
                        {selectedReceipt?.type == 2 &&
                          record?.quantity > 0 &&
                          ' - '}
                        {record.quantity}
                      </span>
                    </div>
                  );
                }
              },
              // {
              //   title: 'المستودع',
              //   dataIndex: 'warehouse',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record?.stock?.name}</span>
              //       </div>
              //     );
              //   }
              // },

              {
                title: t('purchaseInvoices.unitPrice'),
                dataIndex: 'price',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>
                        {/* {selectedReceipt?.type == 2 && record?.price > 0 && " - "} */}
                        {record.price}
                      </span>
                    </div>
                  );
                }
              },
              // {
              //   title: 'الوصف',
              //   dataIndex: 'desc',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record.desc}</span>
              //       </div>
              //     );
              //   }
              // },
              // {
              //   title: 'قبل الخصم',
              //   dataIndex: 'priceBeforeDiscount',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record.priceBeforeDiscount}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: t('purchaseInvoices.discount'),
                dataIndex: 'discount',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{renderDiscountValue(record)}</span>
                    </div>
                  );
                }
              },
              // {
              //   title: 'سعر الخصم',
              //   dataIndex: 'discount',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record.discountPrice}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: t('purchaseInvoices.tax'),
                dataIndex: 'tax',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.tax} %</span>
                    </div>
                  );
                }
              },
              {
                title: t('purchaseInvoices.taxPrice'),
                dataIndex: 'totalTax',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>
                        {selectedReceipt?.type == 2 &&
                          record?.totalTax > 0 &&
                          ' - '}
                        {record.totalTax}
                      </span>
                    </div>
                  );
                }
              },
              {
                title: t('purchaseInvoices.total'),
                dataIndex: 'discount',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>
                        {selectedReceipt?.type == 2 &&
                          record?.totalPrice > 0 &&
                          ' - '}
                        {record.totalPrice}
                      </span>
                    </div>
                  );
                }
              }
            ]}
            // loading={isLoading}
            // scroll={{ x: 400 }}
            footer={false}
          />
        </div>
      );
    }
    return null;
  };

  const renderExtraPrices = () => {
    const addPrices = selectedReceipt.addPrices;
    if (addPrices?.length > 0) {
      return (
        <div className="models-wrap">
          <div className="models-title bold-font">
            {t('purchaseInvoices.extraPrices')} :{' '}
          </div>
          <Table
            className="invoice-extra-prices-table"
            bordered
            showHeader={false}
            locale={{
              emptyText: (
                <Empty description={false}>
                  {t('purchaseInvoices.noData')}
                </Empty>
              )
            }}
            pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            // className="antd-custom-table"
            dataSource={addPrices.map((item) => ({
              ...item,
              key: item.id
            }))}
            columns={[
              // {
              //   title: 'الكود',
              //   dataIndex: 'id',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-index bold-font">
              //         <span># {record.id}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: t('purchaseInvoices.description'),
                dataIndex: 'desc',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text extra-prices-cell">
                      <span>{record.desc}</span>
                    </div>
                  );
                }
              },
              {
                title: t('purchaseInvoices.price'),
                dataIndex: 'price',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text extra-prices-cell">
                      <span>{record.price}</span>
                    </div>
                  );
                }
              }
            ]}
            // loading={isLoading}
            // scroll={{ x: 400 }}
            footer={false}
          />
        </div>
      );
    }
    return null;
  };

  const renderInvoicePrices = () => {
    const {
      productPrice,
      discountType,
      discountValue,
      discountPrice,
      additionPrice,
      totalBeforeTax,
      taxPrice,
      totalPrice,
      invoice_due_date,
      paymentType,
      visa,
      cash,
      rest,
      payments
    } = selectedReceipt;
    return (
      <div className="supplier-details-wrap">
        {/*   <div
          style={{
            marginBottom: 2
          }}
        >
          {t('purchaseInvoices.totalAmounts')}:
        </div> */}
        <Descriptions
          title={`${t('purchaseInvoices.totalAmounts')}:`}
          bordered
          column={1}
        >
          {productPrice && (
            <Descriptions.Item
              label={t('purchaseInvoices.unitsPriceAfterDiscount')}
            >
              {selectedReceipt?.type == 2 && productPrice > 0 && ' - '}
              {productPrice}
            </Descriptions.Item>
          )}
          {discountPrice && (
            <Descriptions.Item label={t('purchaseInvoices.discount')}>
              {/* {renderDiscountValue({
                discountType,
                discountValue
              })} */}
              {discountPrice}
            </Descriptions.Item>
          )}
          {/* {discountPrice && (
            <Descriptions.Item label="سعر الخصم">
              {discountPrice}
            </Descriptions.Item>
          )} */}
          {additionPrice && (
            <Descriptions.Item label={t('purchaseInvoices.extraPrices')}>
              {selectedReceipt?.type == 2 && additionPrice > 0 && ' - '}

              {additionPrice}
            </Descriptions.Item>
          )}
          {totalBeforeTax && (
            <Descriptions.Item label={t('purchaseInvoices.priceBeforeTax')}>
              {selectedReceipt?.type == 2 && totalBeforeTax > 0 && ' - '}

              {totalBeforeTax}
            </Descriptions.Item>
          )}
          {taxPrice && (
            <Descriptions.Item label={t('purchaseInvoices.taxPrice')}>
              {selectedReceipt?.type == 2 && taxPrice > 0 && ' - '}

              {taxPrice}
            </Descriptions.Item>
          )}
          {totalPrice && (
            <Descriptions.Item
              style={{
                fontSize: '18px',
                color: '#000',
                fontWeight: 'bold'
              }}
              label={t('purchaseInvoices.priceAfterTax')}
            >
              {selectedReceipt?.type == 2 && totalPrice > 0 && ' - '}

              {totalPrice}
            </Descriptions.Item>
          )}
          {paymentType && (
            <Descriptions.Item
              style={{
                fontSize: '18px',
                color: '#000',
                fontWeight: 'bold'
              }}
              label={t('purchaseInvoices.paymentMethod')}
            >
              {paymentType == 1 && t('purchaseInvoices.cash')}
              {paymentType == 2 && t('purchaseInvoices.postpaid')}
              {paymentType == 3 && t('purchaseInvoices.visa')}
              {paymentType == 5 && t('purchaseInvoices.bankTransfer')}
              {paymentType == 6 && t('purchaseInvoices.multiplePayment')}
            </Descriptions.Item>
          )}
          {paymentType == 2 && (
            <Descriptions.Item label={t('purchaseInvoices.dateOfPayment')}>
              {invoice_due_date}
            </Descriptions.Item>
          )}
          {paymentType == 2 && (
            <>
              {payments?.length &&
                payments?.map((pay, index) => (
                  <Descriptions.Item
                    label={`${t('purchaseInvoices.paymentNumber')}${index + 1}`}
                  >
                    {pay?.amount}
                  </Descriptions.Item>
                ))}
            </>
          )}
          {/* {paymentType == 2 && (
            <Descriptions.Item label="أول دفعة">{first_paid}</Descriptions.Item>
          )} */}
          {paymentType == 2 && (
            <Descriptions.Item label={t('purchaseInvoices.residual')}>
              {rest}
            </Descriptions.Item>
          )}
          {paymentType == 6 && (
            <Descriptions.Item label={t('purchaseInvoices.cashAmountValue')}>
              {cash}
            </Descriptions.Item>
          )}
          {paymentType == 6 && (
            <Descriptions.Item label={t('purchaseInvoices.visaAmountValue')}>
              {visa}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  };

  return (
    <div
      className="invoice-details-wrap"
      ref={ref}
      style={{
        padding: '12px 12px',
        // direction: 'rtl',
        position: 'relative'
        // display: 'grid',
        // gap: '16px'
      }}
    >
      {props.currentLang}
      {systemSettings?.package_id == 1 && (
        <WaterMark text={t('purchaseInvoices.freeTrial')} />
      )}
      <div
        className="inovice-nums-qrcode"
        style={{
          display: 'grid',
          gap: '22px',
          alignItems: 'center',
          direction: 'rtl'
        }}
      >
        <div
          className="qr-code-logo-wrap"
          style={{
            display: 'flex',
            gap: '22px',
            alignItems: 'center',
            direction: 'rtl',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <div
            className="img-wrap"
            style={{
              display: 'grid',
              placeItems: 'center',
              minWidth: '92px',
              minHeight: '92px'
            }}
          >
            {selectedReceipt?.setting?.image ? (
              <img
                className="system-logo"
                src={selectedReceipt?.setting?.image}
                alt="logo"
                style={{
                  // width: '92px',
                  height: '92px'
                }}
              />
            ) : null}
          </div>

          <div
            style={{
              fontSize: 20,
              color: '#000',
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 12
            }}
          >
            {selectedReceipt?.type == 2 && (
              <p>{t('purchaseInvoices.returnedInvoice')}</p>
            )}
            {selectedReceipt?.type == 1 && (
              <span>{renderInvoiceHeading()}</span>
            )}
            {/* {selectedReceipt?.is_return == 1 ? (
              <p>{t('purchaseInvoices.returnedInvoice')}</p>
            ) : null}
             */}
          </div>

          <div className="qr-code-wrap">
            {/* <img className="qr-code-img" src={qrcodeImg} alt="qrcode" /> */}

            {/* <QRCode
              value={selectedReceipt?.qrString ? selectedReceipt.qrString : ''}
              width={92}
              height={92}
              renderAs="svg"
            /> */}

            {/* <QRCode
            value={
              selectedReceipt?.encryptId
                ? `${
                    process.env.REACT_APP_FATOORAH_URL
                  }/incoming-invoice-details/${String(
                    selectedReceipt.encryptId
                  )}`
                : ''
            }
          /> */}
          </div>
        </div>
      </div>
      <div className="supp-buyer-wrap">
        {selectedReceipt?.setting && (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: 2
            }}
          >
            <div>{renderUser(selectedReceipt?.setting, 1)}</div>
            <span></span>
          </div>
        )}

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 2
          }}
        >
          {selectedReceipt?.supplier &&
            renderUser(selectedReceipt?.supplier, 2)}
          <div>
            {/* <div
              style={{
                marginBottom: 2,
                opacity: 0,
                visibility: 'hidden'
              }}
            >
              {'--'}
            </div> */}
            <div
              className="ssssssss"
              style={{ height: '22px', width: '100%' }}
            ></div>
            <Descriptions title="" bordered column={1}>
              {selectedReceipt?.parentInvoiceNumber && (
                <Descriptions.Item
                  label={t('Quotations.Returnedinvoicenumber')}
                >
                  {selectedReceipt.parentInvoiceNumber}
                </Descriptions.Item>
              )}
              {selectedReceipt?.invoiceNumber && (
                <Descriptions.Item label={t('purchaseInvoices.invoiceNumber')}>
                  {systemSettings?.invoiceSetting?.change_invoice_num
                    ? selectedReceipt.invoiceNumber
                    : selectedReceipt.manualNum}
                </Descriptions.Item>
              )}
              {selectedReceipt?.dueDate && (
                <Descriptions.Item
                  label={t('purchaseInvoices.issuingInvoiceDate')}
                >
                  {selectedReceipt.dueDate}
                </Descriptions.Item>
              )}
              {selectedReceipt?.date && (
                <Descriptions.Item
                  label={t('purchaseInvoices.invoiceEntryDate')}
                >
                  {selectedReceipt.date}
                </Descriptions.Item>
              )}
              <Descriptions.Item label={t('purchaseInvoices.billingTime')}>
                {selectedReceipt?.time ? selectedReceipt.time : '---'}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      </div>
      {renderModels()}
      {renderExtraPrices()}
      {renderInvoicePrices()}
      {selectedReceipt?.note && (
        <>
          <h3 className="note-modal-box">{t('Quotations.Notes')}</h3>
          <div className="note">
            <p>{selectedReceipt?.note}</p>
          </div>
        </>
      )}

      <div>
        <Descriptions
          title=""
          bordered
          column={1}
          labelStyle={{ fontWeight: 'bold', width: '150px' }}
        >
          {selectedReceipt?.setting?.invoiceSetting?.terms && (
            <Descriptions.Item label={t('SystemSettings.termsAndConditions')}>
              {selectedReceipt?.setting?.invoiceSetting?.terms_text
                ?.toString()
                .split('\r\n')
                .map((item) => (
                  <p>{item}</p>
                ))}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
      <div
        style={{
          textAlign: 'center',
          padding: '10px'
        }}
      >
        {selectedReceipt?.setting?.slogan ? selectedReceipt.setting.slogan : ''}
      </div>
    </div>
  );
});

export default ReceiptDetailsComponent;
