import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { store } from 'react-notifications-component';
import { useLocation } from 'react-router-dom';
import SaveTokenApi from '../../apis/fatoorahStore-api/SaveTokenApi';
import getDataByCodeApi from '../../apis/fatoorahStore-api/getDataByCodeApi';
import storeItemManagerImg from '../../assets/imgs/store/store-item-manager.png';
import SearchNotificationsProfile from '../../components/search-notifications-profile/SearchNotificationsProfile';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import errorNotification from '../../utils/errorNotification';
import StoreWelcomeSharedSection from '../store-pages/store-welcome-shared-section/StoreWelcomeSharedSection';
import './FatoorahStorePage.scss';
import PageTable from './PageTable';
import TableFilter from './TableFilter';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';

const FatoorahStorePage = () => {
  const { user } = useContext(UserContext);
  const { search } = useLocation();
  const { t } = useTranslation();

  let getCode =
    search.includes('code') &&
    search?.split('?')[1]?.split('&')[2]?.split('=')[1];
  let getType =
    search.includes('code') &&
    search?.split('?')[1]?.split('&')[1]?.split('=')[1];
  const [getTokenStoreData, setGetTokenStoreData] = useState();
  const customApiRequest = useCustomApiRequest();
  const formData = new FormData();
  formData.append('code', getCode);
  useEffect(() => {
    customApiRequest(
      getCode && getDataByCodeApi(user?.token, getCode, getType),
      (res) => {
        if (res?.data?.status === 'failed' || res?.data?.status === false) {
          errorNotification({
            title: t('Messages.linkingError'),
            message: t('Messages.incorrectData')
          });
        } else if (res?.status === 200 && res?.data) {
          setGetTokenStoreData(res?.data);
          // store.addNotification({
          // title: res?.data?.message,
          // message: res?.data?.message,
          // type: 'success',
          // insert: 'top',
          // container: 'top-right',
          // animationIn: ['animate__animated', 'animate__fadeIn'],
          // animationOut: ['animate__animated', 'animate__fadeOut'],
          // dismiss: {
          //     duration: 3000,
          //     showIcon: true,
          //     onScreen: true
          // }
          // });
        } else {
          errorNotification({
            title: t('Messages.linkingError'),
            message: t('Messages.tryAgain')
            // message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
          });
        }
      },
      (error) => {
        errorNotification({
          title: t('Messages.linkingError'),
          message: t('Messages.tryAgain')
          // message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
        });
      }
    );
  }, [getCode]);

  const sallaObj = {
    token: getTokenStoreData?.token,
    refresh_token: getTokenStoreData?.refresh_token,
    user_id: user?.client_id,
    type: getType
  };
  const zidObj = {
    access_token: getTokenStoreData?.access_token,
    refresh_token: getTokenStoreData?.refresh_token,
    user_id: user?.client_id,
    authorization: getTokenStoreData?.authorization,
    type: getType
  };

  useEffect(() => {
    customApiRequest(
      getTokenStoreData &&
        (getTokenStoreData?.token || getTokenStoreData?.refresh_token) &&
        SaveTokenApi(
          getType == 'zid' && { ...zidObj }
          // getType == 'salla' ? { ...sallaObj } : getType == 'zid' && { ...zidObj }
        ),
      (res) => {
        if (res?.data?.status === 'failed' || res?.data?.status === false) {
          errorNotification({
            title: t('Messages.linkingError'),
            message: t('Messages.incorrectData')
          });
        } else if (res?.data?.status === 'success') {
          store.addNotification({
            title: res?.data?.message,
            message: res?.data?.message,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              showIcon: true,
              onScreen: true
            }
          });
        } else {
          errorNotification({
            title: t('Messages.linkingError'),
            message:t('Messages.tryAgain')
            // message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
          });
        }
      },
      (error) => {
        errorNotification({
          title: t('Messages.linkingError'),
          message: t('Messages.tryAgain')
          // message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
        });
      }
    );
  }, [getTokenStoreData]);
  return (
    <div className="clients-page">
      <div className="top-section-wrap">
        {/* <StoreWelcomeSharedSection
          img={storeItemManagerImg}
          title={`${t('ShopInvoice.ShopInvoice')} ! `}
          subTitle={t('ShopInvoice.ListAndViewAllQuotations')}
        /> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('AppBar.Products.fatoorahStorePage')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title3={t('AppBar.Products.fatoorahStorePage')}
            link3={'/fatoorahStorePage'}
          />
        </div>
        <div className="search-wrap">
          <SearchNotificationsProfile noSearch />
        </div>
      </div>
      <TableFilter />
      <div className="ClientsPage-table-wrapper">
        <PageTable token={getTokenStoreData && getTokenStoreData?.token} />
      </div>
    </div>
  );
};

export default FatoorahStorePage;
