import axios from 'axios';

const upgradeUserCheckCodeApi = async (data) => {
  try {
    const formData = new FormData();
    // Append filterValues to FormData
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    const res = await axios({
      method: 'post',
      baseURL: process.env.REACT_APP_BASE_URL_WITH_API,
      url: '/checkCode',
      data: formData, // Use formData as the data payload

      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data' // Set Content-Type to multipart/form-data
        // lang: 'ar',
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};
export default upgradeUserCheckCodeApi;
