import React from 'react';

const ArchiveIcon = ({ color }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 26"
    >
      <path
        fill={color ? color : '#b5b5b5'}
        d="M7.44,5.32A.45.45,0,0,0,7,4.88H2.19a.44.44,0,0,0-.44.44V21.41a.44.44,0,0,0,.44.44H7a.45.45,0,0,0,.45-.44Zm-1,15.59H2.6V5.73H6.49Z"
      />
      <path
        fill={color ? color : '#b5b5b5'}
        d="M5.47,19.06a.88.88,0,1,1-1.76,0,.88.88,0,0,1,1.76,0Z"
      />
      <path
        fill={color ? color : '#b5b5b5'}
        d="M4.64,17a.43.43,0,0,1-.43-.45V7.56a.43.43,0,1,1,.86,0V16.5A.43.43,0,0,1,4.64,17Z"
      />
      <path
        fill={color ? color : '#b5b5b5'}
        d="M14,5.32a.44.44,0,0,0-.44-.44H8.74a.45.45,0,0,0-.45.44V21.41a.45.45,0,0,0,.45.44h4.8a.44.44,0,0,0,.44-.44ZM13,20.91H9.15V5.73H13Z"
      />
      <path
        fill={color ? color : '#b5b5b5'}
        d="M12,19.06a.88.88,0,1,1-.88-.88A.88.88,0,0,1,12,19.06Z"
      />
      <path
        fill={color ? color : '#b5b5b5'}
        d="M11.14,17a.47.47,0,0,1-.48-.45V7.56a.48.48,0,0,1,.95,0V16.5A.47.47,0,0,1,11.14,17Z"
      />
      <path
        fill={color ? color : '#b5b5b5'}
        d="M24.24,20.06,20.17,4.48a.45.45,0,0,0-.55-.32L15,5.37a.42.42,0,0,0-.32.54l4.07,15.58a.45.45,0,0,0,.43.34l.11,0,4.62-1.21a.45.45,0,0,0,.27-.2A.45.45,0,0,0,24.24,20.06Zm-4.73.77L15.67,6.12l3.74-1,3.85,14.71Z"
      />
      <path
        fill={color ? color : '#b5b5b5'}
        d="M21.08,19.26a.78.78,0,0,1-.22,0,.88.88,0,0,1-.85-.66.89.89,0,0,1,.63-1.08l.22,0a.88.88,0,0,1,.22,1.73Z"
      />
      <path
        fill={color ? color : '#b5b5b5'}
        d="M20.25,16.46a.44.44,0,0,1-.43-.33L17.56,7.47a.44.44,0,0,1,.32-.54.45.45,0,0,1,.55.32l2.26,8.65a.45.45,0,0,1-.32.55Z"
      />
    </svg>
  );
};

export default ArchiveIcon;
