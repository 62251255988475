import { Modal } from 'antd';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ArchiveNotesContext from '../../../contexts/archive-contexts/ArchiveNotesProvider';
import TableForm from './TableForm';

const SettingsWorkPlaceModal = () => {
  const { modalOpened, setModalOpened, setSelectedNote, selectedNote } =
    useContext(ArchiveNotesContext);
  const { t } = useTranslation();

  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={selectedNote ? t('Notes.editNote') : t('Notes.AddANewNote')}
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedNote(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedNote(null);
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default SettingsWorkPlaceModal;
