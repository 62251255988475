import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  isSingleLoading: false,
  setIsSingleLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  showModalOpened: false,
  setShowModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  fetchCountMsg: 0,
  setFetchCountMsg: (v) => {},
  allFetchedTickets: null,
  setAllFetchedTickets: (v) => {},
  selectedTicket: null,
  setSelectedTicket: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  allMsgs: {},
  setAllMsgs: (v) => {}
};

const TechnicalSupportContext = createContext(INITIAL_VALUES);

export const TechnicalSupportProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [isSingleLoading, setIsSingleLoading] = useState(
    INITIAL_VALUES.isSingleLoading
  );

  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [showModalOpened, setShowModalOpened] = useState(
    INITIAL_VALUES.showModalOpened
  );

  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedTickets, setAllFetchedTickets] = useState(
    INITIAL_VALUES.allFetchedTickets
  );
  const [selectedTicket, setSelectedTicket] = useState(
    INITIAL_VALUES.selectedUnit
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  const [allMsgs, setAllMsgs] = useState(
    INITIAL_VALUES.allMsgs
  );
  const [fetchCountMsg, setFetchCountMsg] = useState(
    INITIAL_VALUES.fetchCountMsg
  );
  return (
    <TechnicalSupportContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isSingleLoading,
        setIsSingleLoading,
        modalOpened,
        setModalOpened,
        showModalOpened,
        setShowModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedTickets,
        setAllFetchedTickets,
        selectedTicket,
        setSelectedTicket,
        isSubmittingForm,
        setIsSubmittingForm,
        allMsgs, 
        setAllMsgs,
        fetchCountMsg, 
        setFetchCountMsg
      }}
    >
      {children}
    </TechnicalSupportContext.Provider>
  );
};

export default TechnicalSupportContext;
