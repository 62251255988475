import { Popconfirm, Tooltip } from 'antd';
import EditIcon from '../../common/icons/EditIcon';
import errorNotification from '../../utils/errorNotification';
import successNotification from '../../utils/successNotification';
import DeleteIcon from '../../common/icons/DeleteIcon';
import deleteClientApi from '../../apis/pos-api/clients-pos-apis/deleteClientApi';
import { useTranslation } from 'react-i18next';
const TableColumns = (
  allFetchedUsers,
  setModalOpened,
  setFetchCount,
  setSelectedUser,
  setIsLoading,
  token
) => {
  const handleEditClick = (record) => {
    const found = allFetchedUsers.find((place) => place.id === record.id);
    if (found) {
      setSelectedUser({ ...found });
      setModalOpened(true);
    }
  };
  const { t } = useTranslation();

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteClientApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Messages.addedSuccessfully'),
          message: t('Messages.DeletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Messages.ErrorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Messages.TryAgainLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Messages.ErrorOccurred'),
        message: t('Messages.TryAgainLater')
      });
    }
  };

  const renderActions = (obj) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(obj)}>
          <EditIcon />
          {t('Quotations.update')}
        </div>
        <Popconfirm
          title={t('Messages.areyouSureToDelete')}
          onConfirm={() => handleDeleteRequest(obj.id)}
          okText={t('Messages.yes')}
          cancelText={t('Messages.no')}
        >
          <Tooltip title={t('Customers.DeleteCustomer')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      title: t('Customers.CustomerCode'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('Customers.Username'),
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.name ? record.name : '----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('Customers.PhoneNumber'),
      dataIndex: 'phone',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.phone ? record.phone : '-----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('Customers.Email'),
      dataIndex: 'email',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.email ? record.email : '-----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('Customers.Settings'),
      // dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default TableColumns;
