import axios from 'axios';

const createNewConversionApi = async (token, values, edit = false) => {
  const formData = new FormData();
  formData.append('type', values.type);
  formData.append('intoStock_id', values.intoStock_id);
  formData.append('quantity', values.quantity);
  formData.append('FromStock_id', values.FromStock_id);
  formData.append('model_id', values.model_id);
  try {
    let url;
    if (!edit) url = '/TransferOrder/create';
    else url = '/TransferOrder/update';
    const res = await axios.post(url, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default createNewConversionApi;
