import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import PaymentDepartmentContext from '../../contexts/payment-departments-context/PaymentDepartmentContext';
import { useTranslation } from 'react-i18next';
const SettingsWorkPlaceModal = () => {
  const {
    modalOpened,
    setModalOpened,
    selectedDepartment,
    setSelectedDepartment
  } = useContext(PaymentDepartmentContext);
  const { t } = useTranslation();
  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '642px' }}
      title={
        selectedDepartment
          ? t('SystemSettings.updateDepartment')
          : t('SystemSettings.addNewDepartment')
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedDepartment(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedDepartment(null);
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default SettingsWorkPlaceModal;
