/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Select, DatePicker } from 'antd';
import SelectDropDown from '../../../common/icons/SelectDropDown';
import UserContext from '../../../contexts/user-context/UserProvider';
import useClients from '../../../custom-hooks/useClients';
import SaleInvoicesContext from '../../../contexts/sale-invoices-context/SaleInvoicesContext';
import CalendarIcon from '../../../common/icons/CalendarIcon';
import usePos from '../../../custom-hooks/usePos';
import excelImg from '../../../assets/imgs/icons/excel-btn.png';
// import Workbook from 'react-excel-workbook';
// import Workbook from 'react-excel-workbook';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import queryString from 'query-string';
import useUsers from '../../../custom-hooks/useUsers';
import userRoles from '../../../userRoles';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import { useTranslation } from 'react-i18next';
import ProfitLosesReportsContext from '../../../contexts/profit-loses-reports-context/ProfitLosesReportsContext';
import getProfitLosesReportsApi from '../../../apis/profit-loses-api/getProfitLosesReportsApi';
import errorNotification from '../../../utils/errorNotification';
const { RangePicker } = DatePicker;

const TableFilter = () => {
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { user } = useContext(UserContext);
  const {
    tableFilter,
    setTableFilter,
    setFetchCountProfitLosesReports,
    setTablePagination,
    setAllFetchedProfitLosesReports,
    setIsLoadingAllProfitLosesReports
  } = useContext(ProfitLosesReportsContext);
  const { Option } = Select;
  const [selectedPicker, setSelectedPicker] = React.useState(null);
  const [selectedPeriod, setSelectedPeriod] = React.useState();

  const { allClients } = useClients();
  const { t } = useTranslation();

  const range = [
    {
      title: t('ProfitAndLossReports.yearly'),
      value: 1
    },
    {
      title: t('ProfitAndLossReports.semiAnnual'),
      value: 2
    },
    {
      title: t('ProfitAndLossReports.quarterly'),
      value: 3
    },
    {
      title: t('ProfitAndLossReports.monthly'),
      value: 4
    },
    {
      title: t('ProfitAndLossReports.custom'),
      value: 5
    },
    {
      title: t('ProfitAndLossReports.total'),
      value: 6
    }
  ];
  const handlePickerChange = (value, string) => {
    setSelectedPicker({
      moments: value,
      strings: string
    });
  };

  React.useEffect(() => {
    if (selectedPicker?.strings?.length > 0) {
      setTableFilter((prev) => ({
        ...prev,
        from: selectedPicker.strings[0],
        to: selectedPicker.strings[1]
      }));
    }
  }, [selectedPicker?.strings]);

  async function handleChange(filterType, value) {
    setSelectedPeriod(value);
    setTableFilter((prevState) => {
      if (filterType === 'period') {
        return {
          ...prevState,
          period: value
        };
      }
    });
  }

  const customApiRequest = useCustomApiRequest();

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoadingAllProfitLosesReports(true);
    customApiRequest(
      getProfitLosesReportsApi(user?.token, {
        ...tableFilter,
        ...values,
        page: 1,
        client_id: user?.client_id
      }),
      (res) => {
        setIsLoadingAllProfitLosesReports(false);
        if (res?.status === 200 && res?.data?.data) {
          if (res.data.data.length >= 0) {
            const data = res.data.data;
            setAllFetchedProfitLosesReports(data);
            // setFetchCountProfitLosesReports(prev => prev + 1)
          }
          if (res?.data?.data?.pagination) {
            setTablePagination(res.data.data.pagination);
          }
        } else {
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message: res?.data?.message || t('Messages.TryAgainLater')
          });
        }
      },
      (error) => {
        setIsLoadingAllProfitLosesReports(false);
      }
    );
  }

  return (
    <div className="custom-table-fitler warehouse-table-filter">
      <div className="form-excel-export-wrap">
        <form
          className="filter-form receipt-filter-form"
          onSubmit={handleSubmit}
        >
          <label className="receipt-filter-option-wrap">
            <span className="select-title">
              {t('ProfitAndLossReports.chooseRange')}
            </span>
            <Select
              defaultValue={null}
              style={{ width: '202px' }}
              onChange={(v) => handleChange('period', v)}
              suffixIcon={<SelectDropDown />}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>
                {t('ProfitAndLossReports.chooseRange')}
              </Option>
              {range &&
                range.map((item, index) => (
                  <Option key={index} value={String(item.value)}>
                    {item.title}
                  </Option>
                ))}
            </Select>
          </label>
          {selectedPeriod == 5 && (
            <div className="range-picker-wrap">
              <RangePicker
                value={selectedPicker?.moments && selectedPicker.moments}
                onChange={handlePickerChange}
                // format="YYYY-MM-DD"
                className={`filter-range-picker`}
                allowClear={true}
                suffixIcon={<CalendarIcon />}
                placeholder={[
                  t('ProfitAndLossReports.dateFrom'),
                  t('ProfitAndLossReports.dateTo')
                ]}
              />
            </div>
          )}

          {selectedPeriod && (
            <button className="filter-submit-btn" type="submit">
              {t('ProfitAndLossReports.search')}
            </button>
          )}
        </form>

        {/* <div className="excel-btn-wrap">
          <a
            href={`${
              process.env.REACT_APP_BASE_URL
            }/exportExcelInvoice?client_id=${systemSettings?.id || ''}
            &type=${1}
            &today_reservations=${tableFilter?.today_reservations || ''}
            &user_id=${tableFilter?.user_id || ''}
            &from=${tableFilter?.from || ''}
            &to=${tableFilter?.to || ''}&paymentType=${
              tableFilter?.pay_id || ''
            }
            &admin_id=${tableFilter?.admin_id || ''}
            &page=${tableFilter?.page || '1'}
            &typeN=${tableFilter?.type || ''}
            &pos_id=${tableFilter?.pos_id || ''}`}
            download
            className="excel-export-btn"
          >
            <img
              style={{ height: '42px' }}
              src={excelImg}
              alt="export to excel"
            />
          </a>
        </div> */}

        {/* <div className="excel-btn-wrap">
          <Workbook
            filename="sales_invoice.xlsx"
            element={
              <div className="excel-export-btn">
                <img
                  style={{ height: '42px' }}
                  src={excelImg}
                  alt="export to excel"
                />
              </div>
            }
          >
            <Workbook.Sheet
              data={allFetchedSaleInvoices ? allFetchedSaleInvoices : []}
              name="Sheet A"
            >
              <Workbook.Column label="مسلسل" value="invoiceNumber" />
              <Workbook.Column label="العميل" value={(row) => row?.name} />
              <Workbook.Column
                label="الموظف"
                value={(row) => row?.shift?.employee?.name}
              />
              <Workbook.Column
                label="تاريخ الفاتورة"
                value={(row) => row.date}
              />
              <Workbook.Column
                label="السعر الإجمالى"
                value={(row) => row.totalPrice}
              />
            </Workbook.Sheet>
          </Workbook>
        </div> */}
      </div>
    </div>
  );
};

export default TableFilter;
