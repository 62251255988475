import React, { useContext } from 'react';
import { Modal } from 'antd';
import TagForm from './TagForm';
import TagsContext from '../../contexts/nfcTags-context/tagsContext';
import { useTranslation } from 'react-i18next';
import './nfcTagsModal.scss';

const TagModal = () => {
  const { isModalOpend, setIsModalOpend, setSelectedTag, selectedTag } =
    useContext(TagsContext);
  const { t } = useTranslation();

  return (
    <Modal
      className="nfcTags-modal"
      width="90%"
      style={{ maxWidth: '642px' }}
      title={selectedTag ? t('NFCTags.updateTag') : t('NFCTags.addNewTag')}
      visible={isModalOpend}
      onOk={() => {
        setIsModalOpend(false);
        setSelectedTag(null);
      }}
      onCancel={() => {
        setIsModalOpend(false);
        setSelectedTag(null);
      }}
      footer={false}
    >
      <TagForm />
    </Modal>
  );
};

export default TagModal;
