import axios from 'axios';

// level 1=>main , 2=>sub
// cat_id ==> when createing sub category send the cat_id of the main category

const addNewCategoryApi = async (token, values, edit = false) => {
  // const mappedValues = {};
  // if (values.category_name) mappedValues.name = values.category_name;
  // if (values.category_description)
  //   mappedValues.description = values.category_description;

  // if (values.level) mappedValues.level = values.level;
  // if (values.cat_id) mappedValues.cat_id = values.cat_id;
  // if (values.id) mappedValues.id = values.id;

  try {
    let url;
    if (!edit) url = '/Category/create';
    else url = '/Category/update';
    const res = await axios.post(url, values, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default addNewCategoryApi;
