import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  modalOpened: false,
  setModalOpened: (v) => {},
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedPlaces: null,
  setAllFetchedPlaces: (v) => {},
  selectedPlace: null,
  setSelectedPlace: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {}
};

const SettingsWorkPlacesContext = createContext(INITIAL_VALUES);

export const SettingsWorkPlacesProvider = ({ children }) => {
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedPlaces, setAllFetchedPlaces] = useState(
    INITIAL_VALUES.allFetchedPlaces
  );
  const [selectedPlace, setSelectedPlace] = useState(
    INITIAL_VALUES.selectedPlace
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES?.tablePagination
  );

  return (
    <SettingsWorkPlacesContext.Provider
      value={{
        modalOpened,
        setModalOpened,
        fetchCount,
        setFetchCount,
        isLoading,
        setIsLoading,
        allFetchedPlaces,
        setAllFetchedPlaces,
        selectedPlace,
        setSelectedPlace,
        isSubmittingForm,
        setIsSubmittingForm,
        tablePagination,
        setTablePagination
      }}
    >
      {children}
    </SettingsWorkPlacesContext.Provider>
  );
};

export default SettingsWorkPlacesContext;
