import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import SettingsCategoriesContext from '../../../contexts/settings-contexts/SettingsCategoriesProvider';
import { useTranslation } from 'react-i18next';

const PageModal = () => {
  const { modalOpened, setModalOpened, setSelectedCategory, selectedCategory } =
    useContext(SettingsCategoriesContext);
  const { t } = useTranslation();

  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        selectedCategory
          ? t('Inventory.Categories.editProductCategory')
          : t('Inventory.Categories.addNewProductCategory')
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedCategory(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedCategory(null);
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default PageModal;
