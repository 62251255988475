/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import UserContext from '../../../contexts/user-context/UserProvider';
import getAllTaxInvoiceReportsApi from '../../../apis/reports-apis/getAllTaxInvoiceReportsApi';
import TaxInvoiceReportsContext from '../../../contexts/reports-contexts/TaxInvoiceReportsContext';
import PageReportFilter from './PageReportFilter';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import SharedTaxInvoiceComponent from './SharedTaxInvoiceComponent';
import TaxInvoiceReportsTables from './TaxInvoiceReportsTables';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import IncomingPurchaseInvoiceContext from '../../../contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import './TaxInvoiceReportsPage.scss';
import TaxInvoiceReportsTablesForProducts from './TaxInvoiceReportsTablesForProducts';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
const TaxInvoiceReportsPage = ({ taxPage }) => {
  const { user } = useContext(UserContext);
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  const { isLoading, setIsLoading, fetchedReports, setFetchedReports } =
    useContext(TaxInvoiceReportsContext);
  const {
    resetTableFilter: resetIncommingInvoicesTableFilter,
    tablePagination
  } = useContext(IncomingPurchaseInvoiceContext);
  const { resetTableFilter: resetOutgoingInvoicesTableFilter } = useContext(
    OutgoingPurchaseInvoiceContext
  );

  // reset table filter
  useEffect(() => {
    resetIncommingInvoicesTableFilter();
    resetOutgoingInvoicesTableFilter();
  }, [pathname]);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllTaxInvoiceReportsApi(user?.token),
        (res) => {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setFetchedReports(res?.data?.data);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const componentRef = React.useRef();
  const handlePrintClick = useReactToPrint({
    content: () => componentRef.current
  });

  const [selectedPicker, setSelectedPicker] = React.useState(null);
  return (
    <div className="tax-invoice-reports-page">
      <div className="top-section-wrap">
        {/* <h1>{t('taxInvoiceReports.title')}</h1> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('AppBar.Products.analysisPage.taxInvoiceReportsPage')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.analysisPage.title')}
            title3={t('AppBar.Products.analysisPage.taxInvoiceReportsPage')}
            link3={'/reports/tax-invoice-report'}
          />
        </div>
        <div className="search-wrap">
          <SearchNotificationsProfile noSearch />
        </div>
      </div>

      <div className="page-main-content">
        <PageReportFilter
          selectedPicker={selectedPicker}
          setSelectedPicker={setSelectedPicker}
          handleClick={handlePrintClick}
        />

        <SharedTaxInvoiceComponent
          isLoading={isLoading}
          fetchedReports={fetchedReports}
          selectedPicker={selectedPicker}
          setSelectedPicker={setSelectedPicker}
          ref={componentRef}
          currentLang={currentLang}
        />
        {!isLoading && fetchedReports && (
          <TaxInvoiceReportsTables
            selectedPicker={selectedPicker}
            taxPage={taxPage}
          />
        )}
        {/* {!isLoading && fetchedReports && (
          <TaxInvoiceReportsTablesForProducts
            selectedPicker={selectedPicker}
            taxPage={taxPage}
          />
        )} */}

        {/* {!isLoading && fetchedReports && (
          <ReportsBoxs
            selectedPicker={selectedPicker}
            setSelectedPicker={setSelectedPicker}
            boxs={fetchedReports}
            ref={componentRef}
          />
        )}
        {!isLoading && fetchedReports && <TaxInvoiceReportsTables />}
        {isLoading && (
          <div style={{ padding: '32px', minHeight: '182px' }}>
            <Spin />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default TaxInvoiceReportsPage;
