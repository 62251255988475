import EditIcon from '../../../common/icons/EditIcon';

const tableColumns = (
  allFetchedIngredients,
  setModalOpened,
  setFetchCount,
  setSelectedIngredient,
  setIsLoading,
  token
) => {
  const handleEditClick = (record) => {
    const found = allFetchedIngredients.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedIngredient({ ...found });
      setModalOpened(true);
    }
  };

  const renderActions = (obj) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(obj)}>
          <EditIcon />
          تعديل
        </div>
        {/* <Popconfirm
          title="هل أنت متأكد من الحذف"
          onConfirm={() => handleDeleteRequest(obj.id)}
          okText="نعم"
          cancelText="لا"
        >
          <Tooltip title="حذف">
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm> */}
      </div>
    );
  };

  return [
    {
      title: 'الكود',
      dataIndex: 'index',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: 'اسم المكون',
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record.name}</span>
          </div>
        );
      }
    },
    {
      title: 'وحدة القياس الاساسية',
      dataIndex: 'measuringUnit',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.unit ? record.unit : '----'}
            </span>
          </div>
        );
      }
    },
    {
      title: 'وحدة القياس الفرعية',
      dataIndex: 'measuringSubUnit',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.sub_unit_name ? record.sub_unit_name : '----'}
            </span>
          </div>
        );
      }
    },
    {
      title: 'السعر',
      dataIndex: 'price',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.price ? record.price : '-----'}
            </span>
          </div>
        );
      }
    },
    {
      title: 'السعر التقريبى للوحدة',
      dataIndex: 'priceOfUnit',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.priceOfUnit ? record.priceOfUnit : '-----'}
            </span>
          </div>
        );
      }
    },
    {
      title: 'الفئة',
      dataIndex: 'cat',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.cat?.name ? record.cat.name : '----'}
            </span>
          </div>
        );
      }
    },
    {
      title: 'الاعدادات',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
