import axios from 'axios';

const getAllShiftsApi = async (token, filterValues) => {
  try {
    const url = `/Shift/all?from=${
      filterValues?.from ? filterValues?.from : ''
    }&to=${filterValues?.to ? filterValues.to : ''}&pos_id=${
      filterValues?.pos_id ? filterValues.pos_id : ''
    }&admin_id=${filterValues?.admin_id ? filterValues.admin_id : ''}&status=${
      filterValues?.status ? filterValues.status : ''
    }&page=${
      filterValues?.page ? filterValues.page : ''
    }`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllShiftsApi;
