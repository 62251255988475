import axios from 'axios';

const getAllInventoriesApi = async (token, type) => {
  try {
    let url = '/storeInventory/all';
    if (type) url = `storeInventory/all?type=${type}`;

    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllInventoriesApi;
