import { useState, useEffect, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  fetchedJournalsDetails: null,
  setFetchedJournalsDetails: (v) => {},
  selectedJournals: null,
  setSelectedJournals: (v) => {},
  singleJournal: null,
  setSingleJournal: (v) => {},
  selectedJournalId: null,
  setSelectedJournalId: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  modalOpenedCreate: false,
  setModalOpenedCreate: (v) => {},
  modalOpenedUpdate: false,
  setModalOpenedUpdate: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  accountsLists: null,
  setAccountsLists : (v) => {},
};

const JournalsContext = createContext(INITIAL_VALUES);

export const JournalsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [fetchedJournalsDetails, setFetchedJournalsDetails] = useState(
    INITIAL_VALUES.fetchedJournalsDetails
  );
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [modalOpenedCreate, setModalOpenedCreate] = useState(INITIAL_VALUES.modalOpenedCreate);
  const [modalOpenedUpdate, setModalOpenedUpdate] = useState(INITIAL_VALUES.modalOpenedUpdate);
  const [selectedJournals, setSelectedJournals] = useState(
    INITIAL_VALUES.selectedJournals
  );
  const [selectedJournalId, setSelectedJournalId] = useState(
    INITIAL_VALUES.selectedJournalId
  );
  const [singleJournal, setSingleJournal] = useState(
    INITIAL_VALUES.singleJournal
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  const [accountsLists , setAccountsLists] = useState(
    INITIAL_VALUES.accountsLists
  );
  // useEffect(() => {
  //   if (journalsTree?.length >= 0) {
  //     setMappedJournalsTree({
  //       name: 'شجرة الحســابات',
  //       toggled: true,
  //       fakeLeaf: true,
  //       children: [...journalsTree]
  //     });
  //   }
  // }, [journalsTree]);

  // const allJournalsRoles = useJournalsRoles();

  // useEffect(() => {
  //   if (allJournalsRoles?.length > 0) {
  //     setJournalsRoles(allJournalsRoles);
  //   }
  // }, [allJournalsRoles]);

  return (
    <JournalsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fetchCount,
        setFetchCount,
        fetchedJournalsDetails,
        setFetchedJournalsDetails,
        selectedJournals,
        setSelectedJournals,
        singleJournal,
        setSingleJournal,
        selectedJournalId,
        setSelectedJournalId,
        modalOpened,
        setModalOpened,
        modalOpenedCreate,
        setModalOpenedCreate,
        modalOpenedUpdate,
        setModalOpenedUpdate,
        isSubmittingForm,
        setIsSubmittingForm,
        accountsLists,
        setAccountsLists
      }}
    >
      {children}
    </JournalsContext.Provider>
  );
};

export default JournalsContext;
