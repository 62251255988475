import React, { useContext } from 'react';
// import { Select } from 'antd';
import PageModal from './PageModal';
import './TableFilter.scss';
import CustomersPaymentsContext from '../../contexts/customers-payments-context/CustomersPaymentsContext';
const TableFilter = () => {
  // const { Option } = Select;
  const {
    modalOpened,
  } = useContext(CustomersPaymentsContext);
  return (
    <div className="custom-filter-add-section">
      {/* <div className="add-section">
        <div
          className="add-btn"
          onClick={() => {
            // if selected supplier then reset it
            // open the supplier modal
            setSelectedCustomerPayment(null);
            setModalOpened(true);
          }}
        >
          <span className="text-span">تسديد دفعة جديدة</span>
        </div>
      </div> */}

      {/* <div className="custom-table-fitler">
        <div className="form-excel-export-wrap">
          <form className="filter-form">
            <div className="table-search">
              <label>
                <SearchIcon />
                <input
                  placeholder="أدخل اسم المدفوع للبحث"
                  type="text"
                  name="table_search"
                  onKeyUp={handleTableSearch}
                />
              </label>
            </div>
          </form>
          <div className="excel-btn-wrap" onClick={() => handleExcel()}>
            <img
              style={{ height: '42px' }}
              src={excelImg}
              alt="export to excel"
            />
          </div>
          <div className="excel-btn-wrap">
            <a
              href={`${process.env.REACT_APP_BASE_URL}/Excel/exportSuppliers?client_id=${systemSettings?.id}`}
              download
              className="excel-export-btn"
            >
              <img
                style={{ height: '42px' }}
                src={excelImg}
                alt="export to excel"
              />
            </a>
          </div>
        </div>
      </div> */}

      {modalOpened && <PageModal />}
    </div>
  );
};

export default TableFilter;
