import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import userImg from '../../assets/imgs/signin/user.png';
import eyeOffImg from '../../assets/imgs/signin/eye-off.png';
import eyeOnImg from '../../assets/imgs/signin/eye-on.png';
import lockImg from '../../assets/imgs/signin/lock.png';
import ForgetPasswordModal from './ForgetPasswordModal';
import './LoginForm.scss';
import useSigninEmailPassword from '../../custom-hooks/useSigninEmailPassword';
import useFCMDeviceToken from '../../custom-hooks/useFCMDeviceToken';
import { useTranslation } from 'react-i18next';
// const schema = Yup.object().shape({
//   name: Yup.string()
//     .required('ادخل البريد الاكترونى')
//     .email('ادخل بريد الكترونى صحيح'),
//   password: Yup.string().required('من فضلك ادخل الرقم السرى')
// });

const LoginForm = () => {
  const [passwrodVisible, setPasswordVisible] = React.useState(false);
  const [modalOpened, setModalOpened] = React.useState(false);
  const fcmDeviceToken = useFCMDeviceToken();
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    phone: Yup.string()
      .required(t('LoginPage.title'))
      .min(9, t('LoginPage.phoneMinValidation'))
      .max(10, t('LoginPage.phoneLengthValidation'))
    // .matches(/^((05))[0-9]{8}$/, 'يجب أن يبدأ بــ 05')
  });
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      // name: '',
      phone: '',
      // password: '',
      fire_base: '',
      remember: true
    }
  });

  const { signMeInWithEmailPassword, isLoadingSignin } =
    useSigninEmailPassword();
  const onSubmit = (data) => {
    signMeInWithEmailPassword({
      ...data,
      fire_base: fcmDeviceToken
    });
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="login-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-header">
        <p className="bold-font main-title">{t('LoginPage.title')}</p>
        <p className="sub-title">{t('LoginPage.subTitle')}</p>
      </div>

      <div className="form-body login-input">
        <AntdTextField
          name="phone"
          type="tel"
          placeholder={t('LoginPage.phonePlaceholder')}
          errorMsg={errors?.phone?.message}
          validateStatus={errors?.phone ? 'error' : ''}
          prefix={<img style={{ height: 22 }} src={userImg} alt="user" />}
          control={control}
        />

        {/* <AntdTextField
          name="name"
          type="text"
          placeholder={'البريد الاكترونى...'}
          // label="الاســــم"
          errorMsg={errors?.name?.message}
          validateStatus={errors?.name ? 'error' : ''}
          prefix={<img style={{ height: 22 }} src={userImg} alt="user" />}
          control={control}
        /> */}

        {/* <div className="login-password-field-wrap">
          <AntdTextField
            className="form-text-field"
            name="password"
            type={passwrodVisible ? 'text' : 'password'}
            placeholder="كلمة المرور..."
            errorMsg={errors?.password?.message}
            prefix={<img style={{ height: 22 }} src={lockImg} alt="lock" />}
            validateStatus={errors?.password ? 'error' : ''}
            control={control}
          />

          {watch('password') && (
            <div
              className="eye-icon-btn"
              onClick={() => {
                setPasswordVisible((prevState) => !prevState);
              }}
            >
              {passwrodVisible ? (
                <img src={eyeOnImg} alt="eye on" />
              ) : (
                <img src={eyeOffImg} alt="eye off" />
              )}
            </div>
          )}
        </div>

        <p className="forget-p" onClick={() => setModalOpened(true)}>
          هل نسيت كلمة المرور؟
        </p> */}

        {/* <AntdCheckbox name="remember" label="تذكرنى" control={control} /> */}
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isLoadingSignin}
        >
          {t('LoginPage.title')}
        </Button>
      </div>

      {/* <ForgetPasswordModal
        modalOpened={modalOpened}
        setModalOpened={setModalOpened}
      /> */}
    </Form>
  );
};

export default LoginForm;
