/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal } from 'antd';
import React, { useContext, useRef } from 'react';
import Barcode from 'react-barcode';
import StoreNewProductModalContext from '../../../contexts/store-new-product-context/StoreNewProductProvider';
// import Barcode from 'react-jsbarcode';
import { useReactToPrint } from 'react-to-print';
import './ProductBarCodeModal.scss';
import { useTranslation } from 'react-i18next';

const ProductBarCodeModal = () => {
  const { t } = useTranslation();
  const {
    selectedProduct,
    setSelectedProduct,
    barCodeModalOpened,
    setBarCodeModalOpned
  } = useContext(StoreNewProductModalContext);
  const renderBarCodes = (num) => {
    const arr = Array.from(Array(num).keys());
    return arr.map((item, index) => (
      <div key={index} className="wrapper-ref" ref={componentRef}>
        <Barcode
          // width="4mm"
          height="60mm"
          value={
            selectedProduct?.defaultParCode
              ? selectedProduct.defaultParCode
              : selectedProduct.parCode
          }
        />
        <div
          className="p-name-price"
          style={{
            marginTop: '-6px'
            // paddingLeft: '10px',
            // marginRight: '10px'
          }}
        >
          <h3
            style={{
              textAlign: 'center',
              borderBottomColor: '#000',
              borderBottomStyle: 'solid',
              borderBottomWidth: '1px',
              paddingBottom: '5px'
            }}
          >
            {selectedProduct?.name}
            {/* said */}
          </h3>
          <p
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingLeft: '10px',
              paddingRight: '10px'
            }}
          >
            <span>SR</span>
            <h3>{Number(selectedProduct?.salePriceWithTax).toFixed(2)}</h3>
          </p>
          <div 
            style={{
              textAlign:"center",
              padding:"10px 0 10px 0",
              fontSize:"16px"
            }}
          >{t('Inventory.Products.sellingPriceIncludesTax')}</div>
        </div>
      </div>
    
    ));
  };

  // .wrapper-ref svg {
  //   // width: 37mm;
  //   // height: 25mm;
  // }
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @media print {
      html, body { height: initial !important; overflow: initial !important; }

      tbody { display: table-row-group !important; }
      thead {
        display: table-row-group !important;
      }
      tfoot {
        display: table-row-group !important;
      }
      @page {
        size: 50mm 25mm;
        margin: none;
      }
      .wrapper-ref {
        size: 50mm 25mm;
      }
      .p-name-price {
        width: 36mm;
      }
      .p-name-price p {
        line-height: 14px;
        font-size: 11px;
        text-align: center;
        width: 36mm;
        white-space: noWrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 36mm;
        direction: rtl;
      }
      .wrapper-ref svg {
        margin: 0 auto;
        width: 36mm;
        height: 17mm;
      }
    `
  });

  // const handlePrint = (e) => {
  //   const opt = {
  //     scale: 4
  //   };
  //   const elem = wrapper_ref.current;
  //   html2canvas(elem, opt).then((canvas) => {
  //     const iframe = document.createElement('iframe');
  //     iframe.name = 'printf';
  //     iframe.id = 'printf';
  //     iframe.height = 0;
  //     iframe.width = 0;
  //     document.body.appendChild(iframe);

  //     const imgUrl = canvas.toDataURL({
  //       format: 'jpeg',
  //       quality: '1.0'
  //     });

  //     const style = `
  //           height:100vh;
  //           width:100vw;
  //           position:absolute;
  //           left:0:
  //           top:0;
  //       `;

  //     const url = `<img style="${style}" src="${imgUrl}"/>`;
  //     var newWin = window.frames['printf'];
  //     newWin.document.write(`<body onload="window.print()">${url}</body>`);
  //     newWin.document.close();
  //   });
  // };

  return (
    <Modal
      className="shared-custom-modal product-barcodes-modal"
      width="94%"
      style={{ maxWidth: '842px' }}
      title={`${t('Inventory.Products.barcodePrinting')} : ${
        selectedProduct?.name ? selectedProduct.name : ''
      }`}
      visible={barCodeModalOpened}
      onOk={() => {
        setBarCodeModalOpned(false);
        setSelectedProduct(null);
      }}
      onCancel={() => {
        setBarCodeModalOpned(false);
        setSelectedProduct(null);
      }}
      footer={false}
    >
      <div className="barcoes-btn">
        <div className="barcodes-wrap">{renderBarCodes(1)}</div>

        <Button
          className="print-btn"
          type="primary"
          // icon={<LoginOutlined />}
          onClick={handlePrint}
        >
          {t('Inventory.Products.print')}
        </Button>
      </div>
    </Modal>
  );
};

export default ProductBarCodeModal;
