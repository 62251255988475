/* eslint-disable react-hooks/exhaustive-deps */
import { Descriptions } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import getAllIncomingPurchaseInvoicesApi from '../../../apis/store-apis/incoming-purchas-invoices-apis/getAllIncomingPurchaseInvoicesApi';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import PageModal from './PageModal';
import PageModalForProducts from './PageModalForProducts';
import PageTable from './PageTable';
import SelectedReceiptModal from './SelectedReceiptModal';
import SelectedReceiptModalProducts from './SelectedReceiptModalProducts';
import SelectedReceiptModalProductsReport from './SelectedReceiptModalProductsReport';
import './sharedOutgoingPurchaseInvoice.scss';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Cookies from 'js-cookie';
const SharedOutgoingPurchaseInvoice = ({
  allFetchedReceipts,
  withoutReturnBtn = false,
  total,
  isReportsPage,
  taxPage,
  selectedPicker,
  allReceiptsData,
  flag
}) => {
  const systemSettings = useSystemSettings();
  const { pathname } = useLocation();
  const { modalOpened, selectedReceiptModalOpened, tableFilter } = useContext(
    OutgoingPurchaseInvoiceContext
  );

  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const renderSystemSettings = (obj) => {
    const {
      name,
      phone,
      email,
      addressCode,
      buildingNum,
      streetName,
      district,
      city,
      anotherId,
      country,
      VATRegistrationNumber,
      tax_number,
      postalCode
    } = obj;
    return (
      <>
        <div
          style={{
            marginTop: 32
          }}
          className="system-settings-details-wrap"
        >
          <Descriptions title="" bordered column={2}>
            {name && (
              <Descriptions.Item label={t('salesInvoiceReport.name')}>
                {name}
              </Descriptions.Item>
            )}
            {phone && (
              <Descriptions.Item label={t('salesInvoiceReport.phone')}>
                {phone}
              </Descriptions.Item>
            )}
            {email && (
              <Descriptions.Item label={t('salesInvoiceReport.email')}>
                {email}
              </Descriptions.Item>
            )}
            {buildingNum && (
              <Descriptions.Item label={t('salesInvoiceReport.buildingNumber')}>
                {buildingNum}
              </Descriptions.Item>
            )}
            {streetName && (
              <Descriptions.Item label={t('salesInvoiceReport.streetName')}>
                {streetName}
              </Descriptions.Item>
            )}
            {district && (
              <Descriptions.Item label={t('salesInvoiceReport.district')}>
                {district}
              </Descriptions.Item>
            )}
            {city && (
              <Descriptions.Item label={t('salesInvoiceReport.city')}>
                {city}
              </Descriptions.Item>
            )}
            {country && (
              <Descriptions.Item label={t('salesInvoiceReport.country')}>
                {country}
              </Descriptions.Item>
            )}
            {postalCode && (
              <Descriptions.Item label={t('salesInvoiceReport.postalCode')}>
                {postalCode}
              </Descriptions.Item>
            )}
            {addressCode && (
              <Descriptions.Item label={t('salesInvoiceReport.addressCode')}>
                {addressCode}
              </Descriptions.Item>
            )}
            {VATRegistrationNumber && (
              <Descriptions.Item label={t('salesInvoiceReport.vatNumber')}>
                {VATRegistrationNumber}
              </Descriptions.Item>
            )}
            {tax_number && (
              <Descriptions.Item label={t('salesInvoiceReport.vatNumber')}>
                {tax_number}
              </Descriptions.Item>
            )}
            {anotherId && (
              <Descriptions.Item label={t('salesInvoiceReport.anotherId')}>
                {anotherId}
              </Descriptions.Item>
            )}
            {allReceiptsData?.total && (
              <Descriptions.Item label={t('salesInvoiceReport.totalPrice')}>
                {allReceiptsData?.total
                  ? parseFloat(allReceiptsData?.total).toFixed(2)
                  : 0}{' '}
                {t('purchaseInvoiceReport.sar')}
              </Descriptions.Item>
            )}
            {/* {String(total) && (
            <Descriptions.Item label="السعر الإجمالى">
              {total} ريـــال
            </Descriptions.Item>
          )} */}
            {/* {String(allFetchedReceipts?.total) && (
            <Descriptions.Item label="السعر الإجمالى">
              {allFetchedReceipts?.total} ريـــال
            </Descriptions.Item>
          )} */}
          </Descriptions>
        </div>
        {allReceiptsData && allFetchedReceipts?.length > 0 && (
          <div
            style={{
              marginTop: 32
            }}
            className="system-settings-details-wrap"
          >
            <Descriptions title="" bordered column={2}>
              {String(allReceiptsData?.total_invoice) && (
                <Descriptions.Item label={t('InvoiceDetails.invoicesTotal')}>
                  {allReceiptsData?.total_invoice
                    ? allReceiptsData.total_invoice
                    : 0}{' '}
                  {t('purchaseInvoiceReport.sar')}
                </Descriptions.Item>
              )}
              {String(allReceiptsData?.total_return) && (
                <Descriptions.Item label={t('InvoiceDetails.returnedTotal')}>
                  {allReceiptsData?.total_return
                    ? allReceiptsData.total_return
                    : 0}{' '}
                  {t('purchaseInvoiceReport.sar')}
                </Descriptions.Item>
              )}
              {String(allReceiptsData?.total_number) && (
                <Descriptions.Item label={t('InvoiceDetails.invoicesNumber')}>
                  {allReceiptsData?.total_number
                    ? allReceiptsData.total_number
                    : 0}
                </Descriptions.Item>
              )}
              {String(allReceiptsData?.total) && (
                <Descriptions.Item label={t('InvoiceDetails.total')}>
                  {allReceiptsData?.total
                    ? parseFloat(allReceiptsData?.total).toFixed(2)
                    : 0}{' '}
                  {t('purchaseInvoiceReport.sar')}
                </Descriptions.Item>
              )}
            </Descriptions>
          </div>
        )}
      </>
    );
  };

  const handleModal = (flag) => {
    if (flag === '1') {
      return <SelectedReceiptModalProductsReport />;
    } else if (flag === '3') {
      return <SelectedReceiptModalProducts />;
    }
  };
  const HandleRenderSystemSettings = (user, flag) => {
    if (user === 1) {
      if (flag === '3') {
        return renderSystemSettings(systemSettings);
      } else if (flag !== '3' && flag !== '1') {
        return renderSystemSettings(systemSettings);
      }
    } else if (user === 2) {
      return renderSystemSettings(systemSettings);
    }
  };

  return (
    <>
      {withoutReturnBtn &&
        systemSettings &&
        HandleRenderSystemSettings(user.subscriptionType, flag)}
      <div className="PageTable-wrapper">
        <PageTable
          withoutReturnBtn={withoutReturnBtn}
          allFetchedReceipts={allFetchedReceipts}
          isReportsPage={isReportsPage}
          taxPage={taxPage}
          selectedPicker={selectedPicker}
          tab={flag}
        />
      </div>
      {modalOpened && user.subscriptionType === 1 && <PageModalForProducts />}
      {modalOpened && user.subscriptionType === 2 && <PageModal />}
      {selectedReceiptModalOpened &&
        (user.subscriptionType === 1 ? (
          handleModal(flag)
        ) : (
          <SelectedReceiptModal systemSettings={systemSettings} />
        ))}
    </>
  );
};

export default SharedOutgoingPurchaseInvoice;
