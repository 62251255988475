import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import AccountingContext from '../../../contexts/accounting-context/AccountingContext';
import AccountingTree from './AccountingTree';
import PageModal from './PageModal';
import './AccountingPage.scss';
import AccountingDetailsTable from '../accounting-details-page/AccountingDetailsTable';
import TransactionsDetailsPage from '../transactions-details-page/TransactionsDetailsPage';
import TransactionsContext from '../../../contexts/accounting-context/TransactionsContext';
import TransactionsDetailsModal from '../transactions-details-page/TransactionsDetailsModal';
import TransactionsDetailsModalForm from '../transactions-details-page/TransactionsDetailsModalForm';
import { Button } from 'antd';
import TransactionsCreateModal from '../transactions-details-page/TransactionsCreateModal';

const AccountingTransactionsPage = () => {
  const { 
    modalOpened,
    apiLoading,
    modalOpenedCreate,
    setModalOpenedCreate
  } = useContext(TransactionsContext);

  const handleCreateTransaction = () => {
    setModalOpenedCreate(true)
  }

  return (
    <div className="accounting-page">
      <div className="page-head-wrap">
        <div
          className="page-title"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 32
          }}
        >
          <div>
            <h2 className="bold-font">المـعـامـلات</h2>
            <p>كشف وعرض كــافة المـعـامـلات</p>
            <div>
              <Button 
                onClick={() => handleCreateTransaction()}
                className="submit-btn"
                htmlType="submit"
                type="default"
                loading={apiLoading}
              >إضافة معامله جديده</Button>
            </div>
          </div>
        </div>
        <SearchNotificationsProfile />
      </div>
      {/* <AccountingTree /> */}
      {<TransactionsDetailsPage />}

      {modalOpened && <TransactionsDetailsModal />}
      {modalOpenedCreate && <TransactionsCreateModal />}
    </div>
  );
};

export default AccountingTransactionsPage;
