/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { CameraFilled } from '@ant-design/icons';
import { Button, Form } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import createPriceOfferApiNew from '../../../apis/new-price-offers-apis/createPriceOfferApiNew';
import AntdRadioGroup from '../../../common/antd-form-components/AntdRadioGroup';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import CustomAntdDatePicker from '../../../common/antd-form-components/CustomAntdDatePicker';
import MyDropZone from '../../../common/dorp-zone/MyDropZone';
import MyDropZonePreview from '../../../common/dorp-zone/MyDropZonePreview';
import AddToCartIcon from '../../../common/icons/AddToCartIcon';
import ClientsContext from '../../../contexts/clients-context/ClientsContext';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import StatisticsContext from '../../../contexts/statistics-context/StatisticsContext';
import StoreNewProductModalContext from '../../../contexts/store-new-product-context/StoreNewProductProvider';
import SuppliersContext from '../../../contexts/suppliers-context/SuppliersContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useClients from '../../../custom-hooks/useClients';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import useProducts from '../../../custom-hooks/useProducts';
import useSuppliers from '../../../custom-hooks/useSuppliers';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import userRoles from '../../../userRoles';
import checkRes from '../../../utils/checkRes';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import InvoiceNewSupplierModal from '../../suppliers-page/PageModal';
import InvoiceNewClientModal from './InvoiceNewClientModal';
import './TableForm.scss';

const ConvertToInvoiceForm = ({ setModalVisible }) => {
  const { t } = useTranslation();

  const systemSettings = useSystemSettings();
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    isSubmittingForm,
    setIsSubmittingForm,
    selectedReceipt,
    setConvertModalOpened
  } = useContext(OutgoingPurchaseInvoiceContext);
  const { allClients, setAllClientsFetchCount } = useClients();
  const { allSuppliers, setAllSuppliersFetchCount } = useSuppliers();
  const { setAllProductsFetchCount } = useProducts();
  const { user } = useContext(UserContext);
  const {
    setModalOpened: setClientModalOpened,
    modalOpened: clientModalOpened,
    selectedClientId
  } = useContext(ClientsContext);
  const {
    setModalOpened: setSupplierModalOpened,
    modalOpened: supplierModalOpened,
    selectedSupplierId
  } = useContext(SuppliersContext);
  const {
    setModalOpened: setNewProductModalOpened,
    modalOpened: newProductModalOpened
  } = useContext(StoreNewProductModalContext);
  const { setFetchStatistcsCount } = useContext(StatisticsContext);
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    getValues,
    register,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: 'all',
    defaultValues: {
      userOrSupplier: '1',
      user_id: '',
      supplier_id: '',
      due_date: null,
      paymentType: '',
      cheque: {
        bankName: '',
        cheekNum: ''
      },
      bankAccount: {
        accountNumber: '',
        price: ''
      }
    }
  });
  let [paymentMethods, setPaymentStatus] = useState([]);
  useEffect(() => {
    systemSettings?.invoiceSetting?.payment_methods_objects?.map(
      (paymentMethod) => {
        setPaymentStatus((prv) => [
          ...prv,
          { title: paymentMethod.name, value: paymentMethod.id }
        ]);
      }
    );
  }, [systemSettings]);
  const [invoiceFileToUpload, setInvoiceFileToUpload] = useState(
    watch('invoice_image') ? watch('invoice_image') : null
  );
  const [invoiceFileToUploadDefferd, setInvoiceFileToUploadDefferd] = useState(
    watch('first_pay_img') ? watch('first_pay_img') : null
  );
  useEffect(() => {
    setValue('invoice_image', invoiceFileToUpload);
    if (watch('invoice_image')) {
      clearErrors('invoice_image');
    }
  }, [invoiceFileToUpload, invoiceFileToUpload?.length]);
  useEffect(() => {
    setValue('first_pay_img', invoiceFileToUploadDefferd);
    if (watch('first_pay_img')) {
      clearErrors('first_pay_img');
    }
  }, [invoiceFileToUploadDefferd, invoiceFileToUploadDefferd?.length]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  const handleFilesDrop = async (acceptedFiles, formFiles, setFormFiles) => {
    const duplicates = [];
    for (let i of acceptedFiles) {
      if (formFiles?.length) {
        for (let f of formFiles) {
          if (i.name === f.name) {
            duplicates.push(i);
          }
        }
      }
    }
    for (let i = 0; i < acceptedFiles.length; i++) {
      for (let f of duplicates) {
        if (acceptedFiles[i].name === f.name) {
          acceptedFiles.splice(i, 1);
        }
      }
    }
    acceptedFiles.forEach(async (file) => {
      const preview = await getBase64(file);
      Object.assign(file, {
        preview
      });
      setFormFiles((prevState) => [file]);
      if (formFiles?.length > 0) {
        setFormFiles((currState) => [file]);
      } else {
        setFormFiles([file]);
      }
    });
  };

  const clearFileFromUpload = (file, setFilesToUpload) => {
    setFilesToUpload((prevState) => {
      const filtered = prevState.filter((asset) => asset.name !== file.name);
      if (filtered?.length === 0) return null;
      return filtered;
    });
  };
  useEffect(() => {
    if (watch('pay_type')) {
      setValue('invoice_due_date', '');
      clearErrors('invoice_due_date');
      setValue('first_paid', '');
      clearErrors('first_paid');
      setValue('first_pay_type', '');
      clearErrors('first_pay_type');
      setValue('invoice_image', null);
      clearErrors('invoice_image');
      setValue('first_pay_img', null);
      clearErrors('first_pay_img');
      setInvoiceFileToUpload(null);
    }
  }, [watch('pay_type')]);
  useEffect(() => {
    setValue('first_pay_img', null);
    clearErrors('first_pay_img');
  }, [watch('first_pay_type')]);
  useEffect(() => {
    if (watch('multiPaymentCash')) {
      clearErrors('multiPaymentVisa');
    }
  }, [watch('multiPaymentCash')]);
  useEffect(() => {
    if (watch('multiPaymentVisa')) {
      clearErrors('multiPaymentCash');
    }
  }, [watch('multiPaymentVisa')]);
  useEffect(() => {
    if (selectedReceipt?.totalPrice) {
      clearErrors('multiPaymentCash');
      clearErrors('multiPaymentVisa');
      setValue('multiPaymentCash', '');
      setValue('multiPaymentVisa', '');
    }
  }, [selectedReceipt?.totalPrice]);

  useEffect(() => {
    if (selectedReceipt?.id) {
      setValue('user_id', String(selectedReceipt?.id));
    }
  }, [selectedReceipt?.id]);
  useEffect(() => {
    if (selectedSupplierId) {
      setValue('supplier_id', String(selectedSupplierId));
    }
  }, [selectedSupplierId]);

  useEffect(() => {
    if (watch('userOrSupplier') == 1) setValue('supplier_id', '');
    if (watch('userOrSupplier') == 2) setValue('user_id', '');
  }, [watch('userOrSupplier')]);

  const renderAddComp = (compType) => {
    if (
      user?.role === userRoles?.manager ||
      user?.role === userRoles?.dataEntry
    ) {
      if (compType === 'client') {
        return (
          <>
            <div
              className="new-client-btn"
              onClick={() => {
                setClientModalOpened(true);
              }}
            >
              <AddToCartIcon />
            </div>
            {clientModalOpened && (
              <InvoiceNewClientModal
                setAllClientsFetchCount={setAllClientsFetchCount}
              />
            )}
          </>
        );
      } else if (compType === 'supplier') {
        return (
          <>
            <div
              className="new-client-btn"
              onClick={() => {
                setSupplierModalOpened(true);
              }}
            >
              <AddToCartIcon />
            </div>
            {supplierModalOpened && (
              <InvoiceNewSupplierModal
                setAllSuppliersFetchCount={setAllSuppliersFetchCount}
              />
            )}
          </>
        );
      }
    }
  };

  const renderClientsTabs = () => {
    return (
      <div className="select-label-wrap">
        <p className="label-p">إختار العميــل</p>
        <div className="custom-select-wrap without-icon">
          <AntdSelectOption
            name={`user_id`}
            errorMsg={errors?.user_id && errors.user_id.message}
            validateStatus={errors?.user_id ? 'error' : ''}
            control={control}
            setValue={setValue}
            placeholder="إختار العميــل"
            options={
              allClients?.length > 0 &&
              allClients.map((role) => ({
                title: role.name,
                value: String(role.id)
              }))
            }
            disabled={true}
            showSearch={true}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            formClassName="outgoing-invoice-form"
          />
        </div>
      </div>
    );
  };

  const renderSuppliersTabs = () => {
    return (
      <div className="prev-new-client-wrap">
        <div className="select-label-wrap">
          <p className="label-p">إختار الشركة</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`supplier_id`}
              errorMsg={errors?.supplier_id && errors.supplier_id.message}
              validateStatus={errors?.supplier_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="إختار الشركة"
              options={
                allSuppliers?.length > 0 &&
                allSuppliers.map((role) => ({
                  title: role.name,
                  value: String(role.id)
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="outgoing-invoice-form"
            />
          </div>
        </div>

        {renderAddComp('supplier')}
      </div>
    );
  };

  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    const mappedData = {};
    // if (data.userOrSupplier) mappedData.userOrSupplier = data.userOrSupplier;
    if (data.user_id) mappedData.id = data.user_id;
    if (data.manualNum) mappedData.manualNum = data.manualNum;
    if (data.supplier_id) mappedData.supplier_id = data.supplier_id;
    if (data.due_date)
      mappedData.dueDate = moment(data.due_date).format('YYYY-MM-DD');
    // mappedData.invoice_type = 2; // => buy invoice type
    if (data.pay_type) {
      mappedData.paymentType = data.pay_type;
      if (data.pay_type == '2') {
        if (data?.invoice_due_date) {
          mappedData.invoice_due_date = moment(data.invoice_due_date).format(
            'YYYY-MM-DD'
          );
        }
        if (data?.first_paid) {
          mappedData.first_paid = data.first_paid;
        }
        if (data?.first_pay_type) {
          mappedData.first_pay_type = data.first_pay_type;
          if (data?.first_pay_type == '5') {
            if (data?.first_pay_img?.length) {
              mappedData.first_pay_img = data.first_pay_img[0];
            }
          }
        }
        if (selectedReceipt?.totalPrice) {
          mappedData.deferred = selectedReceipt?.totalPrice;
        }
      } else if (mappedData.paymentType == '5') {
        if (data?.invoice_image?.length) {
          mappedData.bank_image = data.invoice_image[0];
        }
        if (selectedReceipt?.totalPrice) {
          mappedData.bank = selectedReceipt?.totalPrice;
        }
      } else if (mappedData.paymentType == '6') {
        mappedData.totalInvoicePrice = selectedReceipt?.totalPrice;
        if (data?.multiPaymentVisa) {
          mappedData.visa = data.multiPaymentVisa;
        }
        if (data?.multiPaymentCash) {
          mappedData.cash = data.multiPaymentCash;
        }
      }
    }

    setIsSubmittingForm(true);
    setIsLoading(true);
    const formData = new FormData();
    for (let key in mappedData) {
      formData.append(key, mappedData[key]);
    }

    customApiRequest(
      createPriceOfferApiNew(
        user.subscriptionType === 1
          ? '/offerPrice/convertToInvoice'
          : user.subscriptionType === 2
          ? '/OfferPriceService/convertToInvoice'
          : '',
        user?.token,
        formData
      ),
      (res) => {
        setIsSubmittingForm(false);
        setIsLoading(false);
        if (checkRes(res) && res?.data) {
          setConvertModalOpened(false);
          setModalVisible && setModalVisible(false);
          setFetchCount((prevState) => prevState + 1);
          setFetchStatistcsCount((prev) => prev + 1);
          successNotification({
            title: t('Quotations.SuccessOperation'),
            message: t(
              'Quotations.Thepricequotehasbeensuccessfullyconvertedintoaninvoice'
            )
          });
        } else {
          errorNotification({
            title: t('Quotations.ErrorOccurredDuringOperation'),
            message: res?.data?.message || t('Quotations.PleaseTryAgainLater')
          });
        }
      },
      (error) => {
        setIsSubmittingForm(false);
        setIsLoading(false);
        errorNotification({
          title: t('Quotations.ErrorOccurredDuringOperation'),
          message:
            error?.response?.data?.message ||
            t('Quotations.PleaseTryAgainLater')
        });
      }
    );
  };

  // handle initial tax
  const handleInitialTax = (tax) => {
    if (parseFloat(tax) > 0) return tax;
    return parseFloat(systemSettings?.tax);
  };
  // handle initial values
  const [form] = Form.useForm();
  return (
    <Form
      className="create-receipt-form custom-shared-form outgoing-invoice-form"
      form={form}
      layout="vertical"
      // initialValues={{
      //   products: watch('products')
      // }}
      fields={[
        {
          name: 'discount_type',
          value: watch('discount_type')
        },
        {
          name: 'paymentType',
          value: watch('paymentType')
        },
        {
          name: 'products',
          value: watch('products')
        },
        {
          name: 'installments',
          value: watch('installments')
        }
      ]}
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        {/* {selectedReceipt?.user?.name && (
          <div className="supplier-name-view">
            <span>{t('SalesInvoices.InvoiceClient')} : </span>
            <span className="supp-name">{selectedReceipt.user?.name}</span>
          </div>
        )}
        {selectedReceipt?.supplier?.name && (
          <div className="supplier-name-view">
            <span>مورد الفاتورة: </span>
            <span className="supp-name">{selectedReceipt.supplier?.name}</span>
          </div>
        )} */}

        {/* {watch('userOrSupplier') == 1 ? renderClientsTabs() : null}
        {watch('userOrSupplier') == 2 ? renderSuppliersTabs() : null} */}
        {/*paytype section */}
        <div
          className="radios-field-array-wrap no-border"
          style={{ paddingBottom: '22px' }}
        >
          <AntdRadioGroup
            name="pay_type"
            className="form-radio-group"
            control={control}
            label={t('Quotations.SelectPaymentType')}
            validateStatus={errors?.pay_type ? 'error' : ''}
            errorMsg={errors?.pay_type?.message}
            radios={paymentMethods}
            // isRadioButton
          />

          {String(watch('pay_type')) == '2' && (
            <div
              className="ingredients-add-btn-wrap"
              style={{
                display: 'grid',
                alignItems: 'start',
                gridTemplateColumns: '1fr 1fr',
                width: '100%',
                gap: '0 30px'
              }}
            >
              <div className="date-picker-wrap">
                <p style={{ marginBottom: '8px' }}>
                  تاريخ السداد ( DD / MM / YYYY )
                </p>
                <CustomAntdDatePicker
                  // disablePast
                  control={control}
                  name={`invoice_due_date`}
                  className="receipt-date-picker"
                  formClassName="create-receipt-form"
                  errorMsg={
                    errors?.invoice_due_date && errors.invoice_due_date.message
                  }
                  validateStatus={errors?.invoice_due_date ? 'error' : ''}
                  setValue={setValue}
                  disablePast={true}
                />
              </div>
              <div className="text-field-label-wrap">
                <p className="label-p">الدفعة الأولى</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name={`first_paid`}
                    type="text"
                    placeholder="أدخل قيمة الدفعة الأولى"
                    errorMsg={errors?.first_paid && errors?.first_paid?.message}
                    validateStatus={errors?.first_paid ? 'error' : ''}
                    control={control}
                  />
                </div>
              </div>
              <div className="text-field-label-wrap">
                <div className="text-field-wrap">
                  <AntdRadioGroup
                    name="first_pay_type"
                    className="form-radio-group"
                    control={control}
                    label="نوع الدفع للدفعة الأولى"
                    validateStatus={errors?.first_pay_type ? 'error' : ''}
                    errorMsg={errors?.first_pay_type?.message}
                    // radios={paymentMethods}
                    radios={[
                      {
                        title: 'كاش',
                        value: '1'
                      },
                      {
                        title: 'شبكه',
                        value: '3'
                      },
                      {
                        title: 'تحويل بنكى',
                        value: '5'
                      }
                    ]}
                    // isRadioButton
                  />
                </div>
              </div>
            </div>
          )}
          {String(watch('first_pay_type')) == '5' && (
            <div className="product-img-wrap">
              <h3 style={{ marginBottom: '6px' }}>صورة الإيصال</h3>

              <MyDropZone
                className="product-header-dropzone"
                multipleFiles={false}
                handleFilesDrop={(acceptedFiles) =>
                  handleFilesDrop(
                    acceptedFiles,
                    invoiceFileToUploadDefferd,
                    setInvoiceFileToUploadDefferd
                  )
                }
                filesToUpload={invoiceFileToUploadDefferd}
                id="avatar-input"
                dropzoneText="صورة المنتج"
                inputName="first_pay_img"
                icon={<CameraFilled className="dropzone-camera" />}
                dropZoneUrls={
                  watch('first_pay_img')?.file
                    ? [watch('first_pay_img')?.file]
                    : null
                }
              >
                اسحب الصورة وضعها هنا
              </MyDropZone>

              <MyDropZonePreview
                filesToUpload={invoiceFileToUploadDefferd}
                clearFileFromUpload={(f) =>
                  clearFileFromUpload(f, setInvoiceFileToUploadDefferd)
                }
              />
              {errors?.first_pay_img && (
                <p
                  className="error-p"
                  style={{ color: '#ff4d4f', fontSize: '14px' }}
                >
                  {errors?.first_pay_img?.message}
                </p>
              )}
            </div>
          )}
          {String(watch('pay_type')) == '5' && (
            <div className="product-img-wrap">
              <h3 style={{ marginBottom: '6px' }}>صورة الإيصال</h3>

              <MyDropZone
                className="product-header-dropzone"
                multipleFiles={false}
                handleFilesDrop={(acceptedFiles) =>
                  handleFilesDrop(
                    acceptedFiles,
                    invoiceFileToUpload,
                    setInvoiceFileToUpload
                  )
                }
                filesToUpload={invoiceFileToUpload}
                id="avatar-input"
                dropzoneText="صورة المنتج"
                inputName="invoice_image"
                icon={<CameraFilled className="dropzone-camera" />}
                dropZoneUrls={
                  watch('invoice_image')?.file
                    ? [watch('invoice_image')?.file]
                    : null
                }
              >
                اسحب الصورة وضعها هنا
              </MyDropZone>

              <MyDropZonePreview
                filesToUpload={invoiceFileToUpload}
                clearFileFromUpload={(f) =>
                  clearFileFromUpload(f, setInvoiceFileToUpload)
                }
              />
              {errors?.invoice_image && (
                <p
                  className="error-p"
                  style={{ color: '#ff4d4f', fontSize: '14px' }}
                >
                  {errors?.invoice_image?.message}
                </p>
              )}
            </div>
          )}
          {String(watch('pay_type')) == '6' && (
            <div className="cheque-wrapper">
              <div className="text-field-label-wrap">
                <p className="label-p">كاش</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name={`multiPaymentCash`}
                    type="text"
                    placeholder="أدخل مبلغ الكاش"
                    errorMsg={
                      errors?.multiPaymentCash &&
                      errors?.multiPaymentCash?.message
                    }
                    validateStatus={errors?.multiPaymentCash ? 'error' : ''}
                    control={control}
                    defaultValue={
                      watch('multiPaymentVisa')
                        ? selectedReceipt?.totalPrice -
                            parseFloat(watch('multiPaymentVisa')) >
                          0
                          ? Math.ceil(
                              parseFloat(
                                selectedReceipt?.totalPrice -
                                  parseFloat(watch('multiPaymentVisa'))
                              ) * 100
                            ) / 100
                          : 0
                        : null
                    }
                  />
                </div>
              </div>
              <div className="text-field-label-wrap">
                <p className="label-p">شبكة</p>
                <div className="text-field-wrap">
                  <AntdTextField
                    setValue={setValue}
                    className="form-text-field"
                    name={`multiPaymentVisa`}
                    type="text"
                    placeholder="أدخل مبلغ الشبكة"
                    errorMsg={
                      errors?.multiPaymentVisa &&
                      errors.multiPaymentVisa.message
                    }
                    validateStatus={errors?.multiPaymentVisa ? 'error' : ''}
                    control={control}
                    defaultValue={
                      watch('multiPaymentCash')
                        ? selectedReceipt?.totalPrice -
                            parseFloat(watch('multiPaymentCash')) >
                          0
                          ? Math.ceil(
                              parseFloat(
                                selectedReceipt?.totalPrice -
                                  parseFloat(watch('multiPaymentCash'))
                              ) * 100
                            ) / 100
                          : 0
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {/* ./paytype section */}

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {t('Quotations.Convert')}
        </Button>
      </div>
    </Form>
  );
};

export default ConvertToInvoiceForm;
