/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useTranslation } from 'react-i18next';
import storeItemManagerImg from '../../assets/imgs/store/store-item-manager.png';
import SearchNotificationsProfile from '../../components/search-notifications-profile/SearchNotificationsProfile';
import StoreWelcomeSharedSection from '../store-pages/store-welcome-shared-section/StoreWelcomeSharedSection';
import './ClientsPage.scss';
import PageTable from './PageTable';
import TableFilter from './TableFilter';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';

const ClientsPage = () => {
  const { t } = useTranslation();

  return (
    <div className="clients-page">
      <div className="top-section-wrap">
        {/* <StoreWelcomeSharedSection
          img={storeItemManagerImg}
          title={`${t('Clients.Clients')} !`}
          subTitle={t('Clients.WelcomeBack')}
        /> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('Clients.Clients')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.salesPage.title')}
            title3={t('Clients.Clients')}
            link3={'/clients'}
          />
        </div>
        <div className="search-wrap">
          <SearchNotificationsProfile noSearch />
        </div>
      </div>
      <TableFilter />
      <div className="ClientsPage-table-wrapper">
        <PageTable />
      </div>
    </div>
  );
};

export default ClientsPage;
