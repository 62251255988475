/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Table, Empty } from 'antd';
import { useEffect } from 'react';
// import catsTableData from './catsTableData';
import { useState } from 'react';
import UserContext from '../../contexts/user-context/UserProvider';
import InstallmentsPageContext from '../../contexts/installments-context/InstallmentsContext';
import UserInstallmentsTableColumns from './UserInstallmentsTableColumns';
import { useTranslation } from 'react-i18next';
// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const UserInstallmentsPageTable = ({ arr, arrType, currentLang }) => {
  const {
    fetchCount,
    isLoading,
    setIsLoading,
    setModalOpened,
    setFetchCount,
    setSelectedIntallment
  } = useContext(InstallmentsPageContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (arr?.length >= 0) {
      if (isMounted) {
        setTableDataSource(mapDataSource(arr));
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, arr, arr?.length]);

  return (
    <Table
      locale={{
        emptyText: <Empty description={false}>{t('Receipts.noData')}</Empty>
      }}
      pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
      className="antd-custom-table"
      dataSource={tableDataSource}
      columns={UserInstallmentsTableColumns(
        arr,
        arrType,
        setModalOpened,
        setFetchCount,
        setSelectedIntallment,
        setIsLoading,
        user?.token,
        t
      )}
      loading={isLoading}
      // scroll={{ x: 400 }}
      footer={false}
    />
  );
};

export default UserInstallmentsPageTable;
