/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Table, Empty } from 'antd';
import tableColumns from './tableColumns';
import SettingsMeasurementUnitsContext from '../../../contexts/settings-contexts/SettingsMeasurementUnitsPage';
// import pageData from './pageData';
import getAllUnits from '../../../apis/settings-apis/measuremnet-units-apis/getAllUnits';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
import { useTranslation } from 'react-i18next';

const PageTable = () => {
  const { user } = useContext(UserContext);
  const {
    isLoading,
    setIsLoading,
    setModalOpened,
    allFetchedUnits,
    fetchCount,
    setAllFetchedUnits,
    setFetchCount,
    setSelectedUnit
  } = useContext(SettingsMeasurementUnitsContext);
  const { t } = useTranslation();

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: index + 1,
      name: obj?.name ? obj.name : '',
      title: obj?.title ? obj.title : ''
    }));
  };

  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllUnits(user?.token),
        (res) => {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setAllFetchedUnits(res.data.data);
          }
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (allFetchedUnits?.length >= 0) {
      const mappedData = mapDataSource(allFetchedUnits);
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedUnits, allFetchedUnits?.length]);

  return (
    <Table
      locale={{
        emptyText: (
          <Empty description={false}>
            {t('Inventory.MeasurementUnits.noDataAvailable')}
          </Empty>
        )
      }}
      pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
      className="antd-custom-table"
      dataSource={tableDataSource}
      columns={tableColumns(
        allFetchedUnits,
        setModalOpened,
        setFetchCount,
        setSelectedUnit,
        setIsLoading,
        t,
        user?.token
      )}
      loading={isLoading}
      // scroll={{ x: 400 }}
      footer={false}
    />
  );
};

export default PageTable;
