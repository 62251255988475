/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
// import TableFilter from './TableFilter';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import getAllIncomingPurchaseInvoicesReturnApi from '../../../apis/store-apis/incoming-purchas-invoices-return-apis/getAllIncomingPurchaseInvoicesReturnApi';
import getAllProductsWithSelect from '../../../apis/store-apis/products/getAllProductsWithSelect';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import IncomingPurchaseInvoiceContext from '../../../contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import SharedIncomingPurchaseInvoice from './SharedIncomingPurchaseInvoice';
import './StoreReceiptPage.scss';
import TableFilter from './TableFilter';

const IncomingPurchaseInvoiceReturnPage = ({ reports, withoutReturnBtn }) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const {
    modalOpened,
    setModalOpened,
    setSelectedReceipt,
    allFetchedReceipts,
    setIsLoading,
    setAllFetchedReceipts,
    fetchCount,
    tableFilter,
    resetTableFilter,
    setTablePagination,
    tablePagination,
    setAllProducts,
    filterValues
  } = useContext(IncomingPurchaseInvoiceContext);
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { pathname } = useLocation();
  // reset table filter
  useEffect(() => {
    resetTableFilter();
    if (values.page) {
      setTablePagination({ tablePagination, current_page: +values.page });
    }
  }, [pathname]);

  React.useEffect(() => {
    if (modalOpened) {
      setModalOpened(true);
    }
    return () => {
      setModalOpened(false);
    };
  }, [modalOpened, pathname]);
  const user_id_from_payment = pathname?.split('/')[3];
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllIncomingPurchaseInvoicesReturnApi(user?.token, {
          invoice_type: 2,
          ...tableFilter,
          ...values,
          page: tablePagination?.current_page,
          supplier_id_from_payment: user_id_from_payment
        }),
        (res) => {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data?.invoices) {
            const data = res?.data?.data?.invoices?.data;
            setAllFetchedReceipts(data);
            setIsLoading(false);
          }
          if (res?.data?.data?.invoices?.pagination) {
            setTablePagination(res?.data?.data?.invoices?.pagination);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }
    return () => {
      isMounted = false;
    };
  }, [fetchCount]);
  // }, [fetchCount, tableFilter]);

  useEffect(() => {
    customApiRequest(
      getAllProductsWithSelect(user?.token, filterValues),
      (res) => {
        if (res?.status === 200 && res?.data?.data?.length >= 0) {
          const data = res.data.data;
          setAllProducts(data);
        }
      },
      (error) => {
        // setIsLoadingProducts(false);
      }
    );
  }, [fetchCount]);

  return (
    <div className="settings-categories-page">
      <div className="IncomingPurchaseInvoicePage-page-title-page-head-wrap">
        {/* <div className="page-title">
          {!reports ? (
            <h2 className="bold-font">{t('purchaseInvoices.title')}</h2>
          ) : (
            <h2 className="bold-font">{t('purchaseInvoices.titleReport')}</h2>
          )}
          <p>{t('purchaseInvoices.subTitle')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {!reports
              ? t(
                  'AppBar.Products.incomingPurchaseInvoice.ReturnPurchaseInvoices'
                )
              : t('purchaseInvoices.ReturnPurchaseInvoices')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={
              !reports
                ? t('AppBar.Products.incomingPurchaseInvoice.title')
                : t('AppBar.Products.analysisPage.title')
            }
            title3={
              !reports
                ? t(
                    'AppBar.Products.incomingPurchaseInvoice.ReturnPurchaseInvoices'
                  )
                : t('purchaseInvoices.ReturnPurchaseInvoices')
            }
            link3={'/store/incoming-purchase-invoice-return'}
          />
        </div>
        <SearchNotificationsProfile />
      </div>
      <div className="custom-filter-add-section1">
        {/* {!reports && (
          <div className="add-section">
            <div
              className="add-btn"
              onClick={() => {
                setSelectedReceipt(null);
                setModalOpened(true);
              }}
            >
              <span className="text-span">
                {t('purchaseInvoices.addNewInvoice')}
              </span>
            </div>
          </div>
        )} */}
        <TableFilter
          allFetchedReceipts={allFetchedReceipts}
          reports={reports}
        />
      </div>
      <SharedIncomingPurchaseInvoice
        allFetchedReceipts={allFetchedReceipts}
        withoutReturnBtn={withoutReturnBtn}
        total={allFetchedReceipts?.total}
        reports={reports}
        flag={'1'}
      />
      {/* {modalOpened && <PageModal />}
      {selectedReceiptModalOpened && <SelectedReceiptModal />} */}
    </div>
  );
};

export default IncomingPurchaseInvoiceReturnPage;
