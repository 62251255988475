/* eslint-disable react-hooks/exhaustive-deps */
import { Empty, Pagination, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../../contexts/user-context/UserProvider';
import tableColumns from './tableColumns';

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
import { useTranslation } from 'react-i18next';
import ProfitLosesReportsContext from '../../../contexts/profit-loses-reports-context/ProfitLosesReportsContext';
const PageTable = () => {
  const [index, setIndex] = useState(0);
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  const {
    allFetchedProfitLosesReports,
    fetchCountProfitLosesReports,
    isLoadingAllProfitLosesReports,
    setIsLoadingAllProfitLosesReports,
    setFetchCountProfitLosesReports,
    setSelectedInvoice,
    setSelectedProfitLosesReportsModalOpened,
    tablePagination,
    setTablePagination,
    setAllFetchedSaleInvoice
  } = useContext(ProfitLosesReportsContext);
  const { user } = useContext(UserContext);
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: tablePagination.current_page * 10 + (index + 1) - 10
    }));
  };

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;

    if (allFetchedProfitLosesReports?.length >= 0) {
      if (isMounted) {
        setTableDataSource(mapDataSource(allFetchedProfitLosesReports));
      }
    } else {
    }
    return () => {
      isMounted = false;
    };
  }, [
    fetchCountProfitLosesReports,
    allFetchedProfitLosesReports,
    allFetchedProfitLosesReports?.length
  ]);

  // const handlePageChange = async (page) => {
  //    try {
  //     setIsLoadingAllProfitLosesReports(true);
  //        let res = await getAllTaxInvoiceReportsApi(user?.token, {
  //       page: page,
  //       from: selectedPicker?.strings[0],
  //       to: selectedPicker?.strings[1]
  //     });

  //     if (res.status === 200) {
  //       setIsLoadingAllProfitLosesReports(false);
  //       setAllFetchedSaleInvoice(res.data.data.saleInvoice.data);
  //       setTablePagination(res.data.data.saleInvoice.pagination);
  //       setTableDataSource(res.data.data.saleInvoice.data);
  //     }
  //   } catch (error) {
  //     setIsLoadingAllProfitLosesReports(false);
  //   }
  // };

  return (
    <>
      <Table
        locale={{
          emptyText: (
            <Empty description={false}>
              {t('ProfitAndLossReports.noData')}
            </Empty>
          )
        }}
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedProfitLosesReports,
          setSelectedProfitLosesReportsModalOpened,
          setFetchCountProfitLosesReports,
          setSelectedInvoice,
          setIsLoadingAllProfitLosesReports,
          setSelectedProfitLosesReportsModalOpened,
          user?.token,
          t,
        )}
        loading={isLoadingAllProfitLosesReports}
        // scroll={{ x: 400 }}
        footer={false}
      />
      {tablePagination && (
        <Pagination
          defaultCurrent={1}
          showSizeChanger={false}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          style={{ display: 'flex', justifyContent: 'center' }}
          // itemRender={itemRender}
          onChange={(page, pageSize) => {
            setFetchCountProfitLosesReports((prev) => prev + 1);
            setTablePagination({ ...tablePagination, current_page: page });

            // handlePageChange(page);

          }}
          hideOnSinglePage={true}
        />
      )}
    </>
  );
};

export default PageTable;
