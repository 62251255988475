import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchIcon from '../../common/icons/SearchIcon';
import TagsTable from './TagsTable';
import QRModal from './qrcodeModal';
import TagModal from './TagModal';
import SearchNotificationsProfile from '../../components/search-notifications-profile/SearchNotificationsProfile';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
import UserContext from '../../contexts/user-context/UserProvider';
import TagsContext from '../../contexts/nfcTags-context/tagsContext';
import './qrcode.scss';

const NfcTagsPage = () => {
    const { t } = useTranslation();
    const { user } = useContext(UserContext);
    const location = useLocation();
    const {
        isModalOpend,
        setIsModalOpend,
        qrCodeModalOpened,
        setSelectedTag,
        setTagsData,
        tagsData,
        previousTagsData,
        setFilter,
        setTableFilter
    } = useContext(TagsContext);

    // Debounce function to delay search execution
    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
            timeoutId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const handleSearch = (value) => {
        if (value.length > 0) {
            const filteredData = previousTagsData.filter((item) =>
                item.tag.toLowerCase().includes(value.toLowerCase())
            );
            setTagsData(filteredData);
            setTableFilter(true);
        } else {
            setTagsData(previousTagsData);
            setTableFilter(false);
        }
    };

    const debouncedSearch = debounce(handleSearch, 500); // Debounce search function with 300ms delay

    return (
        <div className="nfcTags-page">
            <div className="nfcTags-page-head-wrap">
                <div className="page-title">
                    <h2 className="bold-font" style={{ fontSize: '42px' }}>
                        {t('NFCTags.title')}
                    </h2>
                    <Breadcrumbs
                        title1={t('AppBar.Products.homePage')}
                        link1={'/'}
                        title3={t('NFCTags.title')}
                        link3={'/nfc-tags'}
                    />
                </div>
                <SearchNotificationsProfile />
            </div>

            <div className="custom-filter-add-section">
                <div className="add-section">
                    <div
                        className="add-btn"
                        onClick={() => {
                            setSelectedTag(null);
                            setIsModalOpend(true);
                        }}
                    >
                        <span className="text-span">{t('NFCTags.addNewTag')}</span>
                    </div>
                </div>

                <div className="table-search" style={{ width: '270px' }}>
                    <label>
                        <SearchIcon />
                        <input
                            placeholder={t('NFCTags.EnterYourSerialInvoiceNumber')}
                            type="text"
                            name="tag"
                            onChange={(e) => {
                                const { value } = e.target;
                                setFilter(value);
                                debouncedSearch(value); // Call debounced search function
                            }}
                        />
                    </label>
                </div>
            </div>
            <TagsTable />

            {qrCodeModalOpened && <QRModal />}
            {isModalOpend && <TagModal />}
        </div>
    );
};

export default NfcTagsPage;
