import React from 'react';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import './StoreWelcomeSharedSection.scss';

const StoreWelcomeSharedSection = ({ img, title, subTitle }) => {
  const systemSettings = useSystemSettings();
  return (
    <div className="store-welcome-shared-section">
      <div className="section-title">
        <div className="main-title bold-font">{title}</div>
        <div className="sub-title">{subTitle}</div>
      </div>
      <div className="hello-img">
        <img src={systemSettings?.image || img} alt="store" />
      </div>
    </div>
  );
};

export default StoreWelcomeSharedSection;
