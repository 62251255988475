import axios from 'axios';

const upgradeUserPackageApi = async (data) => {
  try {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    const res = await axios({
      method: 'post',
      baseURL : process.env.REACT_APP_BASE_URL_WITH_API,
      url: `/upgradeUser`,
      data: formData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json'
        // lang: 'ar'
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default upgradeUserPackageApi;
