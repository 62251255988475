import axios from 'axios';

const signOutApi = async (token, data) => {
  try {
    const signoutRes = await axios.post(
      '/Auth_private/logout',
      data ? data : null,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
          lang: 'ar'
        }
      }
    );
    return signoutRes;
  } catch (error) {
    throw error;
  }
};

export default signOutApi;
