import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedVariants: null,
  setAllFetchedVariants: (v) => {},
  selectedUnit: null,
  setSelectedUnit: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {}
};

const StoreVariantsContext = createContext(INITIAL_VALUES);

export const StoreVariantsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allFetchedVariants, setAllFetchedVariants] = useState(
    INITIAL_VALUES.allFetchedVariants
  );
  const [selectedUnit, setSelectedUnit] = useState(INITIAL_VALUES.selectedUnit);
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  return (
    <StoreVariantsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        setModalOpened,
        fetchCount,
        setFetchCount,
        allFetchedVariants,
        setAllFetchedVariants,
        selectedUnit,
        setSelectedUnit,
        isSubmittingForm,
        setIsSubmittingForm
      }}
    >
      {children}
    </StoreVariantsContext.Provider>
  );
};

export default StoreVariantsContext;
