/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Table, Empty } from 'antd';
import tableColumns from './tableColumns';
import ArchiveNotesContext from '../../../contexts/archive-contexts/ArchiveNotesProvider';
import getAllNotesApi from '../../../apis/settings-apis/settings-notes-apis/getAllNotesApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import { useTranslation } from 'react-i18next';

const PageTable = () => {
  const {
    isLoading,
    setIsLoading,
    fetchCount,
    setFetchCount,
    setModalOpened,
    setSelectedNote,
    setStickyNoteModalOpened,
    allFetchedNotes,
    setAllFetchedNotes
  } = useContext(ArchiveNotesContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: index + 1,
      title: obj?.title ? obj.title : '',
      note: obj?.note ? obj.note : ''
    }));
  };

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllNotesApi(user?.token),
        (res) => {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllFetchedNotes(data);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedNotes?.length >= 0) {
      if (isMounted) {
        setTableDataSource(mapDataSource(allFetchedNotes));
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedNotes, allFetchedNotes?.length]);

  return (
    <Table
      locale={{
        emptyText: (
          <Empty description={false}>{t('Messages.noDataAvailable')}</Empty>
        )
      }}
      pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
      className="antd-custom-table"
      dataSource={tableDataSource}
      columns={tableColumns(
        setModalOpened,
        setFetchCount,
        setSelectedNote,
        setStickyNoteModalOpened,
        setIsLoading,
        user?.token
      )}
      loading={isLoading}
      // scroll={{ x: 400 }}
      footer={false}
    />
  );
};

export default PageTable;
