/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import UserContext from '../../../contexts/user-context/UserProvider';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
// import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import AccountingDetailsContext from '../../../contexts/accounting-context/AccountingDetailsContext';
import { useParams } from 'react-router-dom';
// import createAccountingDetailsApi from '../../../apis/accounting-apis/accounting-details-apis/createAccountingDetailsApi';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import { useState } from 'react';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import CustomAntdDatePicker from '../../../common/antd-form-components/CustomAntdDatePicker';
import createAccountingLeafApi from '../../../apis/accounting-apis/createAccountingLeafApi';
import updateAccountingLeafApi from '../../../apis/accounting-apis/updateAccountingLeafApi';

const AccountingDetailsModalForm = ({ typeOfAccount }) => {
  const { user } = useContext(UserContext);
  const {
    selectedAccounting,
    setIsLoading,
    setModalOpened,
    setFetchCount,
    apiLoadingForm,
    setapiLoadingForm
  } = useContext(AccountingDetailsContext);
  const [apiLoading, setApiLoading] = useState(false);
  const [schema, setSchema] = useState();

  const params = useParams();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
  });

  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    setApiLoading(true);
    let api, successMessage, errorMessage;
    if (selectedAccounting) {
      successMessage = 'تم التعديل بنجاح';
      errorMessage = 'حدث خطأ أثناء التعديل';
      if (typeOfAccount === 2) {
        api = updateAccountingLeafApi(
          user?.token,
          { name: data.name },
          `/accounting/subtype/update/${selectedAccounting?.id}`
        );
      } else if (typeOfAccount === 3) {
        api = updateAccountingLeafApi(
          user?.token,
          { ...data },
          `/accounting/account/update_account/${selectedAccounting?.id}`
        );
      }
    } else {
      successMessage = 'تم اضافة فرع الحساب بنجاح';
      errorMessage = 'حدث خطأ أثناء اضافة فرع الحساب';
      if (typeOfAccount === 2) {
        api = createAccountingLeafApi(
          user?.token,
          {
            name: data.name,
            type_id: +params.id.split('-')[1],
            company_id: +params.id.split('-')[0],
            client_id: user?.ref_key.split('_')[1]
          },
          '/accounting/subtype/create'
        );
      } else if (typeOfAccount === 3) {
        api = createAccountingLeafApi(
          user?.token,
          {
            ...data,
            subtype_id: +params.id.split('-')[2],
            company_id: +params.id.split('-')[0],
            type_id: +params.id.split('-')[1]
          },
          '/accounting/account'
        );
      } else if (typeOfAccount === 4) {
        api = createAccountingLeafApi(
          user?.token,
          {
            ...data,
            date: data.date.toLocaleDateString(),
            account_id: +params.id.split('-')[3],
            company_id: +params.id.split('-')[0]
          },
          '/accounting/transaction/create_transaction'
        );
      }
    }

    customApiRequest(
      api,
      (res) => {
        setApiLoading(false);
        if (checkRes(res)) {
          setModalOpened(false);
          setFetchCount((prevState) => prevState + 1);
          successNotification({
            title: 'العملية تمت بنجاح',
            message: successMessage
          });
        } else {
          errorNotification({
            title: 'حدث خطأ',
            message: res?.data?.message || errorMessage
          });
        }
      },
      (error) => {
        setIsLoading(false);
        setApiLoading(false);
        errorNotification({
          title: 'حدث خطأ',
          message: error?.response?.data?.message || 'حاول في وقت لاحق'
        });
      }
    );
  };

  useEffect(() => {
    if (typeOfAccount === 2) {
      setSchema(
        Yup.object().shape({
          name: Yup.string().required('ادخل اسم الحساب')
        })
      );
    } else if (typeOfAccount === 3) {
      setSchema(
        Yup.object().shape({
          holder: Yup.string().required('ادخل اسم صاحب الحساب '),
          initial_balance: Yup.number().required('ادخل الرصيد المبدأي   '),
          analytical: Yup.string().required('اختر الاحصائيات'),
          type: Yup.string().required('اختر النوع')
        })
      );
    } else if (typeOfAccount === 4) {
      setSchema(
        Yup.object().shape({
          payer: Yup.string().required('ادخل اسم المشتري  '),
          type: Yup.string().required('ادخل النوع    '),
          cat: Yup.string().required('ادخل الفئة'),
          method: Yup.string().required('اختر وسيلة الدفع'),
          debit: Yup.number().required('ادخل  المدين'),
          account: Yup.string().required(' ادخل اسم الحساب '),
          date: Yup.date().required('اختر التاريخ ')
        })
      );
    }
  }, []);

  // handle initial values
  useEffect(() => {
    if (selectedAccounting) {
      const {
        name,
        holder,
        initial_balance,
        analytical,
        type,
        method,
        payer,
        note,
        debit,
        account,
        cat
      } = selectedAccounting;
      if (typeOfAccount === 2) {
        setValue('name', name || '');
      } else if (typeOfAccount === 3) {
        setValue('holder', name || '');
        setValue('initial_balance', initial_balance || '');
        setValue('analytical', analytical || '');
        setValue('type', type || '');
      } else if (typeOfAccount === 4) {
        setValue('payer', payer || '');
        setValue('type', type || '');
        setValue('method', method || '');
        setValue('note', note || '');
        setValue('debit', debit || '');
        setValue('account', account || '');
        setValue('cat', cat || '');
      }
    }
  }, [selectedAccounting]);

  const [form] = Form.useForm();
  if (typeOfAccount === 2) {
    return (
      <Form
        className="custom-shared-form accounting-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className="text-field-label-wrap">
            <p className="label-p">إســم الحساب</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="name"
                type="text"
                placeholder="اسـم فرع الحساب..."
                errorMsg={errors?.name?.message}
                validateStatus={errors?.name ? 'error' : ''}
                control={control}
              />
            </div>
          </div>

          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={apiLoading}
          >
            {!selectedAccounting ? 'إضافة' : 'تعديل'}
          </Button>
        </div>
      </Form>
    );
  } else if (typeOfAccount === 3) {
    return (
      <Form
        className="custom-shared-form accounting-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className="text-field-label-wrap">
            <p className="label-p"> اسم صاحب الحساب </p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="holder"
                type="text"
                placeholder="اسم صاحب الحساب  ..."
                errorMsg={errors?.holder?.message}
                validateStatus={errors?.holder ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">الرصيد المبدئي </p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="initial_balance"
                type="number"
                placeholder=" الرصيد المبدئي..."
                errorMsg={errors?.initial_balance?.message}
                validateStatus={errors?.initial_balance ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="select-label-wrap">
            <p className="label-p"> الاحصائيات</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name="analytical"
                errorMsg={errors?.analytical && errors.analytical.message}
                validateStatus={errors?.analytical ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder=" الاحصائيات"
                options={[
                  {
                    title: 'نعم ',
                    value: '1'
                  },
                  {
                    title: 'لا',
                    value: '0'
                  }
                ]}
                // onSearch={onSearch}

                formClassName="accounting-form"
              />
            </div>
          </div>
          <div className="select-label-wrap">
            <p className="label-p"> النوع</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name="type"
                errorMsg={errors?.type && errors.type.message}
                validateStatus={errors?.type ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder=" النوع"
                options={[
                  {
                    title: 'دائن ',
                    value: 'credit'
                  },
                  {
                    title: 'مدين',
                    value: 'debit'
                  }
                ]}
                // onSearch={onSearch}

                formClassName="accounting-form"
              />
            </div>
          </div>
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={apiLoading}
          >
            {!selectedAccounting ? 'إضافة' : 'تعديل'}
          </Button>
        </div>
      </Form>
    );
  } else if (typeOfAccount === 4) {
    return (
      <Form
        className="custom-shared-form accounting-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className="text-field-label-wrap">
            <p className="label-p"> اسم المشتري </p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="payer"
                type="text"
                placeholder="اسم المشتري   ..."
                errorMsg={errors?.payer?.message}
                validateStatus={errors?.payer ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="date-picker-wrap">
            <p className="label-p">التــاريخ </p>
            <CustomAntdDatePicker
              // disablePast
              control={control}
              name="date"
              className="receipt-date-picker"
              formClassName="accounting-form"
            />
            {errors?.date && <p className="error-p">{errors.date.message}</p>}
          </div>
          <div className="select-label-wrap">
            <p className="label-p"> النوع</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name="type"
                errorMsg={errors?.type && errors.type.message}
                validateStatus={errors?.type ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder=" النوع"
                options={[
                  {
                    title: 'ايرادات ',
                    value: 'Income'
                  },
                  {
                    title: 'المصروفات',
                    value: 'Expense '
                  },
                  {
                    title: 'نقل',
                    value: 'transfer '
                  }
                ]}
                // onSearch={onSearch}

                formClassName="accounting-form"
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p"> الفئة </p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="cat"
                type="text"
                placeholder=" الفئة   ..."
                errorMsg={errors?.cat?.message}
                validateStatus={errors?.cat ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="select-label-wrap">
            <p className="label-p"> وسيلة الدفع</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name="method"
                errorMsg={errors?.method && errors.method.message}
                validateStatus={errors?.method ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder=" وسيلة الدفع"
                options={[
                  {
                    title: 'كاش ',
                    value: 'Cash'
                  },
                  {
                    title: 'بنك',
                    value: 'Bank'
                  },
                  {
                    title: 'كارت بنكى',
                    value: 'Card Swipe'
                  }
                ]}
                formClassName="accounting-form"
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p"> ملاحظات </p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="note"
                type="text"
                placeholder=" ملاحظات   ..."
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">المدين </p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="debit"
                type="number"
                placeholder=" المدين..."
                errorMsg={errors?.debit?.message}
                validateStatus={errors?.debit ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p"> اسم الحساب </p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="account"
                type="text"
                placeholder=" اسم الحساب   ..."
                errorMsg={errors?.account?.message}
                validateStatus={errors?.account ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={apiLoading}
          >
            {!selectedAccounting ? 'إضافة' : 'تعديل'}
          </Button>
        </div>
      </Form>
    );
  } else {
    return (
      <Form
        className="custom-shared-form accounting-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="form-body">
          <div className="text-field-label-wrap">
            <p className="label-p">إســم الحساب</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="name"
                type="text"
                placeholder="اسـم فرع الحساب..."
                errorMsg={errors?.name?.message}
                validateStatus={errors?.name ? 'error' : ''}
                control={control}
              />
            </div>
          </div>
          <div className="text-field-label-wrap">
            <p className="label-p">رمــز البطــاقة</p>
            <div className="text-field-wrap">
              <AntdTextField
                className="form-text-field"
                name="account_code"
                type="text"
                placeholder="رمــز البطــاقة..."
                errorMsg={errors?.account_code?.message}
                validateStatus={errors?.account_code ? 'error' : ''}
                control={control}
              />
            </div>
          </div>

          <div className="select-label-wrap">
            <p className="label-p">إختار النـــوع</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name={`type`}
                errorMsg={errors?.type && errors.type.message}
                validateStatus={errors?.type ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder="إختار النـــوع"
                options={[
                  {
                    title: 'قائـمة الدخل',
                    value: '1'
                  },
                  {
                    title: 'ميزانية',
                    value: '2'
                  }
                ]}
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                formClassName="accounting-form"
              />
            </div>
          </div>

          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            loading={apiLoading}
          >
            {!selectedAccounting ? 'إضافة' : 'تعديل'}
          </Button>
        </div>
      </Form>
    );
  }
};

export default AccountingDetailsModalForm;
