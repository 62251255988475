import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import StoreProductsIngredientsContext from '../../../contexts/store-products-ingredients-context/StoreProductsIngredientsProvider';

const SettingsWorkPlaceModal = () => {
  const {
    modalOpened,
    setModalOpened,
    setSelectedIngredient,
    selectedIngredient
  } = useContext(StoreProductsIngredientsContext);

  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '642px' }}
      title={selectedIngredient ? 'تعديل المكون' : 'إضافة مكون جديد'}
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedIngredient(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedIngredient(null);
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default SettingsWorkPlaceModal;
