import axios from 'axios';

const getAllTaxInvoiceReportsApi = async (token, filterValues) => {
  try {
    const url = `/Reports/taxReport?from=${
      filterValues?.from ? filterValues?.from : ''
    }&to=${filterValues?.to ? filterValues.to : ''}&page=${
      filterValues?.page ? filterValues.page : ''
    }`;

    const res = await axios({
      method: 'get',
      url,
      // baseURL: 'https://client-api.fatoorah.sa/Accounting',
      baseURL: process.env.REACT_APP_ACCOUNTING_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllTaxInvoiceReportsApi;
