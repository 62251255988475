import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import EditIcon from '../../common/icons/EditIcon';
import DeleteIcon from '../../common/icons/DeleteIcon';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import deleteDepartment from '../../apis/payment-departments/deleteDepartment';

const tableColumns = (
  allFetchedDepartments,
  setSelectedDepartment,
  setIsLoading,
  setModalOpened,
  setFetchCount,
  t,
  token
) => {
  const handleEditProduct = (record) => {
    const found = allFetchedDepartments.find((place) => place.id === record.id);
    if (found) {
      setSelectedDepartment({ ...found });
      setModalOpened(true);
    }
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteDepartment(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('SystemSettings.operationCompletedSuccessfully'),
          message: t('SystemSettings.departmentDeletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('SystemSettings.errorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('SystemSettings.tryLaterMessage')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('SystemSettings.errorOccurred'),
        message: t('SystemSettings.tryLaterMessage')
      });
    }
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditProduct(record)}>
          <EditIcon />
          {t('SystemSettings.update')}
        </div>
        <Popconfirm
          title={t('SystemSettings.areyouSureToDeleteDepartment')}
          onConfirm={() => handleDeleteRequest(record.id)}
          okText={t('SystemSettings.yes')}
          cancelText={t('SystemSettings.no')}
        >
          <Tooltip title={t('SystemSettings.deletDepartment')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      title: t('SystemSettings.serial'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span>{record.id}</span>
          </div>
        );
      }
    },

    {
      title: t('SystemSettings.department'),
      dataIndex: 'department',
      render: (_, record) => {
        return <div className="row-cell row-text">{record.name}</div>;
      }
    },
    // {
    //   title: 'المبلغ',
    //   dataIndex: 'amount',
    //   render: (_, record) => {
    //     return <div className="row-cell row-text">{record.amount}</div>;
    //   }
    // },

    // {
    //   title: 'الملاحظة',
    //   dataIndex: 'title',
    //   render: (_, record) => {
    //     return <div className="row-cell row-text">{record.title}</div>;
    //   }
    // },

    // {
    //   title: 'التاريخ',
    //   dataIndex: 'date',
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         {record?.date ? record.date : '----'}
    //       </div>
    //     );
    //   }
    // },
    {
      title: t('SystemSettings.settings'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }

    // {
    //   title: 'معاينة الفاتورة',
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-circled-btn">
    //         <Tooltip title="معاينة الفاتورة">
    //           <Button
    //             className="circled-btn"
    //             type="dashed"
    //             shape="circle"
    //             icon={<EyeOutlined />}
    //             onClick={() => handleViewReceiptClick(record)}
    //           />
    //         </Tooltip>
    //       </div>
    //     );
    //   }
    // },
  ];
};

export default tableColumns;
