import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allFetchedDocs: null,
  setAllFetchedDocs: (v) => {},
  selectedDoc: null,
  setSelectedDoc: (v) => {},
  nameSearchValue: '',
  setNameSearchValue: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {}
};

const ArchiveDocsContext = createContext(INITIAL_VALUES);

export const ArchiveDocsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [allFetchedDocs, setAllFetchedDocs] = useState(
    INITIAL_VALUES.allFetchedDocs
  );
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [selectedDoc, setSelectedDoc] = useState(INITIAL_VALUES.selectedDoc);
  const [nameSearchValue, setNameSearchValue] = useState(
    INITIAL_VALUES.nameSearchValue
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  return (
    <ArchiveDocsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        modalOpened,
        allFetchedDocs,
        setAllFetchedDocs,
        setModalOpened,
        fetchCount,
        setFetchCount,
        selectedDoc,
        setSelectedDoc,
        nameSearchValue,
        setNameSearchValue,
        isSubmittingForm,
        setIsSubmittingForm
      }}
    >
      {children}
    </ArchiveDocsContext.Provider>
  );
};

export default ArchiveDocsContext;
