import axios from 'axios';

const getAllUsersApi = async (token, filterValues) => {
  try {
    const url = `/Admin/all?role=${
      filterValues?.role ? filterValues.role : ''
    }&status=${filterValues?.statue ? filterValues.status : ''}`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllUsersApi;
