import accountsRouterLinks from './accounting-routes/accountingRouterLinks';
import archiveRouterLinks from './archive-routes/arciveRouterLinks';
import reportsRouterLinks from './reports-routes/reportsRouterLinks';
// import salesRouterLinks from './sales-routes/salesRouterLinks';
import Cookies from 'js-cookie';
import posRouterLinks from './pos-routes/posRouterLinks';
import salesRouterLinks from './sales-routes/salesRouterLinks';
import settingsRouterLinks from './settings-routes/settingsRouterLinks';
import storeRouterLinks from './store-routes/storeRouterLinks';

const Links = () => {
  // const { user } = useContext(UserContext);
  let user =
    Cookies.get('currentUser') && JSON.parse(Cookies.get('currentUser'));
  // let user = 2;
  let routerLinks = {
    homePage: '/',
    signInPage: '/'
  };
  if (Cookies.get('currentUser') && user.subscriptionType === 2) {
    routerLinks = {
      ////////////////////////////
      homePage: '/',
      signInPage: '/',
      generalOutgoingInvoiceDetailsPage: (id) => {
        if (id) return `/outgoing-invoice-details/${id}`;
        return '/outgoing-invoice-details/:id';
      },
      generalIncomingInvoiceDetailsPage: (id) => {
        if (id) return `/incoming-invoice-details/${id}`;
        return '/incoming-invoice-details/:id';
      },

      generalVatReportsPage: () => {
        return '/vat-invoice-report';
      },
      signupPage: '/signup',
      projectsPage: '/projects',
      crmPage: '/crm',
      // store router links
      ...storeRouterLinks,
      // accounting router links
      ...accountsRouterLinks,
      // FatoorahStorePage router links
      // ...fatoorahStoreRouterLinks,
      // ...priceOfferRouterLinks,
      // settings routes
      ...settingsRouterLinks,
      // archive routes
      ...archiveRouterLinks,
      ...salesRouterLinks,
      ...reportsRouterLinks,
      importExportPage: '/imports-exports',
      humanResourcesPage: '/human-resources',
      webProgrammingPage: '/web-programming',
      analysisPage: '/analysis',
      // settingsPage: '/settings',
      notificationsPage: '/notifications',
      ProfilePage: '/profile',
      QRPage: '/nfc-tags',
      notFound: '/not-found'
    };
    return routerLinks;
  } else if (Cookies.get('currentUser') && user.subscriptionType === 1) {
    routerLinks = {
      homePage: '/',
      signInPage: '/',
      generalInvoiceDetailsPage: (id) => {
        if (id) return `/invoice-details/${id}`;
        return '/invoice-details/:id';
      },
      generalSaleInvoiceDetailsPage: (id) => {
        if (id) return `/sale-invoice-details/${id}`;
        return '/sale-invoice-details/:id';
      },
      generalVatReportsPage: () => {
        return '/vat-invoice-report';
      },
      signupPage: '/signup',
      projectsPage: '/projects',
      crmPage: '/crm',
      // PosPage: '/Pos',
      //////////////////////////////
      // store router links
      ...storeRouterLinks,
      //////////////////////////////
      // accounting router links
      ...accountsRouterLinks,
      //////////////////////////////
      // ...priceOfferRouterLinks,
      // settings routes
      ...settingsRouterLinks,
      //////////////////////////////
      // archive routes
      ...archiveRouterLinks,
      //////////////////////////////
      // Pos routes
      ...posRouterLinks,
      //////////////////////////////
      ...salesRouterLinks,
      //////////////////////////////
      ...reportsRouterLinks,
      //////////////////////////////
      //...fatoorahMarketRouterLinks,  // This is Work
      ///////////

      importExportPage: '/imports-exports',
      humanResourcesPage: '/human-resources',
      webProgrammingPage: '/web-programming',
      analysisPage: '/analysis',
      // settingsPage: '/settings',
      QRPage: '/nfc-tags',
      notificationsPage: '/notifications',
      ProfilePage: '/profile',
      ////////////////////////////////

      notFound: '/not-found'
    };
    return routerLinks;
  }
  return routerLinks;
};

export default Links;
