import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ClientsProvider } from '../../contexts/clients-context/ClientsContext';
import { IncomingPurchaseInvoiceProvider } from '../../contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import { OutgoingPurchaseInvoiceProvider } from '../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import routerLinks from './routerLinks';
import protectMe from '../../utils/protectMe';
import { SuppliersProvider } from '../../contexts/suppliers-context/SuppliersContext';
import UserContext from '../../contexts/user-context/UserProvider';
// import { CategoryPage } from '../../pages/category/CategoryPage';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import IncomingPurchaseInvoicePage from '../../pages/store-pages/store-incoming-purchase-invoices-page/IncomingPurchaseInvoicePage';
import OutgoingPurchaseInvoicePage from '../../pages/store-pages/store-outgoing-purchase-invoices-page/OutgoingPurchaseInvoicePage';
import PriceOffersRoutes from './price-offers-routes/priceOffersRoutes';
import storeRouterLinks from './store-routes/storeRouterLinks';
import PriceOfferPageNew from '../../pages/store-pages/price-offer-page-new/PriceOfferPageNew';
// import { StatisticsProvider } from '../../contexts/statistics-context/StatisticsContext';
// const HomePage = lazy(() => import('../../pages/home-page/HomePage'));

const DelegateRoleRoutes = () => {
  const { loggedIn, user } = useContext(UserContext);
  return (
    <Switch>
      {[
        protectMe(
          routerLinks.incomingPurchaseInvoice,
          <IncomingPurchaseInvoiceProvider>
            <IncomingPurchaseInvoicePage   isReportsPage={false} />
          </IncomingPurchaseInvoiceProvider>,
          4,
          routerLinks.signInPage,
          loggedIn,
          user?.expire
        ),
        protectMe(
          routerLinks.outgoingPurchaseInvoice,
          <OutgoingPurchaseInvoiceProvider>
            <SuppliersProvider>
              <ClientsProvider>
                <OutgoingPurchaseInvoicePage isReportsPage={false} />
              </ClientsProvider>
            </SuppliersProvider>
          </OutgoingPurchaseInvoiceProvider>,
          9991,
          routerLinks.signInPage,
          loggedIn,
          user?.expire
        ),

        protectMe(
          storeRouterLinks?.priceOfferRouteNew,
          <OutgoingPurchaseInvoiceProvider>
            <PriceOfferPageNew />
          </OutgoingPurchaseInvoiceProvider>,
          'price_offer_page_new_key',
          routerLinks.signInPage,
          loggedIn,
          user?.expire
        )
      ]}

      {PriceOffersRoutes()}

      {/* not found page */}
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
};

export default DelegateRoleRoutes;
