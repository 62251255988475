import axios from 'axios';

const cashPaymentsProducts = async (token, values) => {
  try {
    let url = `/receipts?filter=${values.filter}&page=${values.page}`;
    const res = await axios.get(url, {
      
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default cashPaymentsProducts;
