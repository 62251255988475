import React from 'react';

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.341"
      height="15.344"
      viewBox="0 0 15.341 15.344"
    >
      <path
        id="Icon_ionic-ios-search"
        data-name="Icon ionic-ios-search"
        d="M19.661,18.727,15.394,14.42a6.08,6.08,0,1,0-.923.935l4.239,4.279a.657.657,0,0,0,.927.024A.661.661,0,0,0,19.661,18.727Zm-9.044-3.32A4.8,4.8,0,1,1,14.012,14,4.771,4.771,0,0,1,10.616,15.407Z"
        transform="translate(-4.5 -4.493)"
      />
    </svg>
  );
};

export default SearchIcon;
