import React, { useContext } from 'react';
import { Modal } from 'antd';
import { useParams } from 'react-router-dom';
import { getLeafType } from '../../../custom-hooks/getLeafType';
import ViewJournal from './ViewJournal';
import JournalsContext from '../../../contexts/accounting-context/JournalsContext';
import SummaryContext from '../../../contexts/accounting-context/SummaryContext';
import BalanceSheetContext from '../../../contexts/accounting-context/BalanceSheetContext';
import { useTranslation } from 'react-i18next';
const JournalsDetailsModal = () => {
  const {
    modalOpened,
    setModalOpened,
    setSelectedBalanceSheet,
    selectedAccounting
  } = useContext(BalanceSheetContext);
  const {t} = useTranslation()
  const params = useParams();
  let typeOfAccount = getLeafType(params.id);

  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '850px' }}
      title={t('ProfessorBook.registrationDetails')}
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedBalanceSheet(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedBalanceSheet(null);
      }}
      footer={false}
    >
      <ViewJournal />
    </Modal>
  );
};

export default JournalsDetailsModal;
