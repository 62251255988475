import { useState, useEffect, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  allSummary: null,
  setAllSummary: (v) => {},
  selectedSummary: null,
  setSelectedSummary: (v) => {},
  singleJournal: null,
  setSingleJournal: (v) => {},
  selectedJournalId: null,
  setSelectedJournalId: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  modalOpenedCreate: false,
  setModalOpenedCreate: (v) => {},
  modalOpenedUpdate: false,
  setModalOpenedUpdate: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  accountsLists: null,
  setAccountsLists : (v) => {},
  tableFilter: {
    from: '',
    to: ''
  },
  setTableFilter: (v) => {},
};

const SummaryContext = createContext(INITIAL_VALUES);

export const SummaryProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [allSummary, setAllSummary] = useState(
    INITIAL_VALUES.allSummary
  );
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [modalOpenedCreate, setModalOpenedCreate] = useState(INITIAL_VALUES.modalOpenedCreate);
  const [modalOpenedUpdate, setModalOpenedUpdate] = useState(INITIAL_VALUES.modalOpenedUpdate);
  const [selectedSummary, setSelectedSummary] = useState(
    INITIAL_VALUES.selectedSummary
  );
  const [selectedJournalId, setSelectedJournalId] = useState(
    INITIAL_VALUES.selectedJournalId
  );
  const [singleJournal, setSingleJournal] = useState(
    INITIAL_VALUES.singleJournal
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  const [accountsLists , setAccountsLists] = useState(
    INITIAL_VALUES.accountsLists
  );
  const [tableFilter, setTableFilter] = useState(INITIAL_VALUES?.tableFilter);
  // useEffect(() => {
  //   if (journalsTree?.length >= 0) {
  //     setMappedSummaryTree({
  //       name: 'شجرة الحســابات',
  //       toggled: true,
  //       fakeLeaf: true,
  //       children: [...journalsTree]
  //     });
  //   }
  // }, [journalsTree]);

  // const allSummaryRoles = useSummaryRoles();

  // useEffect(() => {
  //   if (allSummaryRoles?.length > 0) {
  //     setSummaryRoles(allSummaryRoles);
  //   }
  // }, [allSummaryRoles]);

  return (
    <SummaryContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fetchCount,
        setFetchCount,
        allSummary,
        setAllSummary,
        selectedSummary,
        setSelectedSummary,
        singleJournal,
        setSingleJournal,
        selectedJournalId,
        setSelectedJournalId,
        modalOpened,
        setModalOpened,
        modalOpenedCreate,
        setModalOpenedCreate,
        modalOpenedUpdate,
        setModalOpenedUpdate,
        isSubmittingForm,
        setIsSubmittingForm,
        accountsLists,
        setAccountsLists,
        tableFilter,
        setTableFilter,
      }}
    >
      {children}
    </SummaryContext.Provider>
  );
};

export default SummaryContext;
