/* eslint-disable react-hooks/exhaustive-deps */
import { Radio, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import storeItemManagerImg from '../../../assets/imgs/store/store-item-manager.png';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import StoreNewProductModalContext from '../../../contexts/store-new-product-context/StoreNewProductProvider';
import UserContext from '../../../contexts/user-context/UserProvider';
import StoreWelcomeSharedSection from '../store-welcome-shared-section/StoreWelcomeSharedSection';
import ProductBarCodeModal from './ProductsBarCodeModal';
import SelectedProductModal from './SelectedProductModal';
import './StoreItemManagerPage.scss';
import StoreItemManagerTable from './StoreItemManagerTable';
import StoreItemManagerTableFilter from './StoreItemManagerTableFilter';
import SettingsCategoriesContext from '../../../contexts/settings-contexts/SettingsCategoriesProvider';
import PageCatModal from '../../settings-pages/settings-categories-page/PageModal';
import SettingsMeasurementUnitsContext from '../../../contexts/settings-contexts/SettingsMeasurementUnitsPage';
import PageUnitModal from '../../settings-pages/measurement-units-page/PageModal';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

const StoreItemManagerProductsPage = () => {
  const { t } = useTranslation();
  let radios = [
    {
      title: t('Inventory.Products.all'),
      value: ''
    },
    {
      title: t('Inventory.Products.easyProduct'),
      value: '1'
    },
    {
      title: t('Inventory.Products.serviceProduct'),
      value: '2'
    },
    {
      title: t('Inventory.Products.balancedProduct'),
      value: '3'
    },
    {
      title: t('Inventory.Products.digitalProduct'),
      value: '4'
    },
    {
      title: t('Inventory.Products.assembledProduct'),
      value: '5'
    },
    {
      title: t('Inventory.Products.multipleProduct'),
      value: '6'
    },
    {
      title: t('Inventory.Products.productComponent'),
      value: '7'
    }
  ];
  const [valueSelectedFromTypes, setValueSelectedFromTypes] = useState(0);
  const { barCodeModalOpened, setTotalAmountForIngredients, modalOpened } =
    React.useContext(StoreNewProductModalContext);

  const { user, filterValues, setFilterValues } = useContext(UserContext);
  const [flag, setFlag] = useState('1');
  const { modalOpened: CatModalOpened } = useContext(SettingsCategoriesContext);
  const { modalOpened: UnitModalOpened } = useContext(
    SettingsMeasurementUnitsContext
  );
  const onChange = (key) => {
    setFlag(key);
  };

  useEffect(() => {
    setTotalAmountForIngredients([]);
  }, [modalOpened]);

  return (
    <div className="store-item-manager-page">
      <div className="top-section-wrap">
        <div className="page-title" style={{alignSelf:"start"}}>
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('Inventory.Products.Products')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.storePage.title')}
            title3={t('Inventory.Products.Products')}
            link3={'/store/products'}
          />
        </div>
        {/* <StoreItemManagerHelloCard /> */}
        {/* <StoreWelcomeSharedSection
          img={storeItemManagerImg}
          title={t('Inventory.Products.title')}
          subTitle={t('Inventory.Products.subTitle')}
        /> */}
        {/* <Countdown /> */}

        <div className="search-wrap">
          <SearchNotificationsProfile noSearch />
        </div>
      </div>

      <StoreItemManagerTableFilter  product_type={valueSelectedFromTypes}/>
      <div className="products-types">
        {/* <Tabs centered type="card" defaultActiveKey="1" onChange={onChange}>
          <TabPane tab="الـكـل" key="1">
            <div className="product-tab-content">
              <div className="section-text">
                <p className="bold-font">جميع المنتجــات</p>
              </div>
              <StoreItemManagerTableFilter />
              <div className="StoreItemManagerPage-table-wrapper">
                <StoreItemManagerTable />
              </div>
              <SelectedProductModal />
              {barCodeModalOpened && <ProductBarCodeModal />}
            </div>
          </TabPane>
          <TabPane tab="منتج سهل" key="2">
            <div className="product-tab-content">

            </div>
          </TabPane>
          <TabPane tab="منتج خدمه" key="3">
            <div className="product-tab-content">

            </div>
          </TabPane>
          <TabPane tab="منتج موزون" key="4">
            <div className="product-tab-content">
              <div className="section-text">
                <h2 className="bold-font">منتج موزون</h2>
              </div>
              <StoreItemManagerTableFilter />
              <div className="StoreItemManagerPage-table-wrapper">
                <StoreItemManagerTable />
              </div>
              <SelectedProductModal />
              {barCodeModalOpened && <ProductBarCodeModal />}
            </div>
          </TabPane>
          <TabPane tab="منتج رقمى" key="5">
            <div className="product-tab-content">

            </div>
          </TabPane>
          <TabPane tab="منتج مجمع" key="6">
            <div className="product-tab-content">

            </div>
          </TabPane>
          <TabPane tab="منتج متعدد" key="7">
            <div className="product-tab-content">

            </div>
          </TabPane>
        </Tabs> */}
        <Radio.Group defaultChecked defaultValue="" buttonStyle="solid">
          {radios?.length &&
            radios?.map((radio, id) => (
              <Radio.Button
                onChange={() => setValueSelectedFromTypes(radio?.value)}
                value={radio?.value}
              >
                {radio?.title}
              </Radio.Button>
            ))}
        </Radio.Group>
      </div>
      <div className="StoreItemManagerPage-table-wrapper">
        <StoreItemManagerTable product_type_value={valueSelectedFromTypes} />
      </div>
      <SelectedProductModal />
      {barCodeModalOpened && <ProductBarCodeModal />}
      {CatModalOpened && <PageCatModal />}
      {UnitModalOpened && <PageUnitModal />}
      {/* {selectedProductModalOpened && <SelectedProductModal />} */}
    </div>
  );
};

export default StoreItemManagerProductsPage;
