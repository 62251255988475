/* eslint-disable eqeqeq */
import React, { useRef, useEffect, useState } from 'react';
import { Button, Modal, Tabs, Tooltip ,Descriptions} from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import ReceiptDetailsComponent from './ReceiptDetailsComponent';
import IncomingPurchaseInvoiceContext from '../../../contexts/incoming-purchase-invoice-context/IncomingPurchaseInvoiceContext';
import ReceiptDetailsComponentV2 from './ReceiptDetailsComponentV2';
import './SelectedReceiptModal.scss';
import { useTranslation } from 'react-i18next';
const SelectedReceiptModal = () => {
  const {
    selectedReceipt,
    setSelectedReceipt,
    setSelectedReceiptModalOpened,
    selectedReceiptModalOpened
  } = React.useContext(IncomingPurchaseInvoiceContext);
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  const a4Ref = useRef();
  const a4RefV2 = useRef();

  const handlePrintA4 = useReactToPrint({
    content: () => a4Ref.current,
    pageStyle: `
      @media print {
        html, body { height: initial !important; overflow: initial !important; }
        //tr { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
        tr { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
        //td { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
        td { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
        tbody { display: table-row-group !important; }
        thead {
          display: table-row-group !important;
        }
        tfoot {
          display: table-row-group !important;
        }
      @page {
        size: auto;
        margin: none;
      }
      .qr-code-wrap canvas {
        width: 128px !important;
        height: 128px !important;
      }
      .pagebreak {
        display: block;
        size: auto;
      }
      .invoice-details-wrap {
        padding: 0;
      }
      .ant-descriptions-item-content,
        .ant-descriptions-item-label {
          padding: 4px 12px !important;
        }
      }
      .ant-descriptions-header {
        margin: 4px 0 !important;
      }
      .models-title {
        direction:${currentLang == 'ar' ? 'rtl' : 'ltr'};
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .ant-table-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
      }
      th {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      .invoice-extra-prices-table .ant-table-cell {
        padding: 4px 12px !important;
        text-align: right;
      }
      .supp-buyer-wrap,
      .note,
      .note-modal-box{
        direction:${currentLang == 'ar' ? 'rtl' : 'ltr'};
      }
      .supplier-details-wrap{
        direction:${currentLang == 'ar' ? 'rtl' : 'ltr'};
        margin-bottom: 20px !important;
      }
    `
  });

  const handlePrintA4V2 = useReactToPrint({
    content: () => a4RefV2.current,
    pageStyle: `
      @media print {
        html, body { height: initial !important; overflow: initial !important; }
        //tr { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
        tr { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
        //td { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
        td { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
        tbody { display: table-row-group !important; }
        thead {
          display: table-row-group !important;
        }
        tfoot {
          display: table-row-group !important;
        }
      @page {
        size: auto;
        margin: none;
      }
      .qr-code-wrap canvas {
        width: 128px !important;
        height: 128px !important;
      }
      .pagebreak {
        display: block;
        size: auto;
      }
      .invoice-details-wrap {
        padding: 0;
      }
      .ant-descriptions-item-content,
        .ant-descriptions-item-label {
          padding: 4px 12px !important;
        }
      }
      .ant-descriptions-header {
        margin: 4px 0 !important;
      }
      .models-title {
        direction:${currentLang == 'ar' ? 'rtl' : 'ltr'};
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .ant-table-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
      }
      th {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      .invoice-extra-prices-table .ant-table-cell {
        padding: 4px 12px !important;
        text-align: right;
      }
      .invoice-heading-wrap,
      .supplier-details-wrap,
      .note,
      .note-modal-box{
        direction:${currentLang == 'ar' ? 'rtl' : 'ltr'};
      }
      
    `
  });

  const renderModalBody = () => {
    return (
      <Tabs defaultActiveKey="1" style={{ zIndex: '100' }}>
        <Tabs.TabPane tab="A4 Invoice" key="1" forceRender={true}>
          <ReceiptDetailsComponent
            ref={a4Ref}
            selectedReceipt={selectedReceipt}
          />
        </Tabs.TabPane>

        <Tabs.TabPane tab="A4 Invoice.V2" key="2" forceRender={true}>
          <ReceiptDetailsComponentV2
            ref={a4RefV2}
            selectedReceipt={selectedReceipt}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  };

  return (
    <Modal
      className="shared-custom-modal selected-inovice-modal"
      width="90%"
      style={{ maxWidth: '942px' }}
      title={
        <>
          {selectedReceipt?.type == 2 &&
            t('purchaseInvoices.invoiceDetailsReturn')}
          {selectedReceipt?.type == 1 && t('purchaseInvoices.invoiceDetails')}

          {/* <Tooltip title="طباعة الفاتورة"> */}
          <Tooltip title="">
            <Button
              onClick={handlePrintA4}
              className="circled-btn"
              type="dashed"
              shape="circle"
              icon={<PrinterOutlined />}
            />
          </Tooltip>

          <Tooltip title="Print A4 V2">
            <Button
              onClick={handlePrintA4V2}
              className="circled-btn"
              type="dashed"
              shape="circle"
              icon={<PrinterOutlined />}
            />
          </Tooltip>
        </>
      }
      visible={selectedReceiptModalOpened}
      onOk={() => {
        setSelectedReceiptModalOpened(false);
        setSelectedReceipt(null);
      }}
      onCancel={() => {
        setSelectedReceiptModalOpened(false);
        setSelectedReceipt(null);
      }}
      footer={false}
    >
      {renderModalBody()}


      {/* <pre style={{ direction: 'ltr' }}>
        {JSON.stringify(selectedReceipt, null, 2)}
      </pre> */}
    </Modal>
  );
};

export default SelectedReceiptModal;
