import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  selectedConversionModalOpened: false,
  setSelectedConversionModalOpened: (v) => {},
  allFetchedConversions: null,
  setAllFetchedCoversions: (v) => {},
  selectedConversion: null,
  setSelectedConversion: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  allProducts: false,
  setAllProducts: (v) => {}
};

const StoreConversionContext = createContext(INITIAL_VALUES);

export const StoreConversionProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [selectedConversionModalOpened, setSelectedConversionModalOpened] = useState(INITIAL_VALUES.selectedConversionModalOpened);
  const [selectedConversion, setSelectedConversion] = useState(
    INITIAL_VALUES.selectedConversion
  );
  const [allFetchedConversions, setAllFetchedCoversions] = useState(
    INITIAL_VALUES.allFetchedConversions
  );
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [isSubmittingForm, setIsSubmittingForm] = useState(INITIAL_VALUES.isSubmittingForm);
  const [allProducts, setAllProducts] = useState(INITIAL_VALUES.allProducts);

  return (
    <StoreConversionContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fetchCount,
        setFetchCount,
        modalOpened,
        setModalOpened,
        allFetchedConversions,
        setAllFetchedCoversions,
        selectedConversion,
        setSelectedConversion,
        isSubmittingForm,
        setIsSubmittingForm,
        allProducts, 
        setAllProducts,
        setSelectedConversionModalOpened,
        selectedConversionModalOpened
      }}
    >
      {children}
    </StoreConversionContext.Provider>
  );
};

export default StoreConversionContext;
