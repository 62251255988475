// import EditIcon from '../../../common/icons/EditIcon';

import EditIcon from '../../../common/icons/EditIcon';

const tableColumns = (
  allFetchedPos,
  setModalOpened,
  setFetchCount,
  setSelectedPos,
  setIsLoading,
  token,
  t
) => {
  const handleEditClick = (record) => {
    const found = allFetchedPos.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedPos({ ...found });
      setModalOpened(true);
    }
  };

  const renderActions = (obj) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(obj)}>
          <EditIcon />
          {t('PointsOfSale.update')}
        </div>
      </div>
    );
  };

  return [
    {
      title: t('PointsOfSale.code'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('PointsOfSale.salePointName'),
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.name ? record.name : '-----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('PointsOfSale.salePointAddress'),
      dataIndex: 'address',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.address ? record.address : '-----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('PointsOfSale.totalAccount'),
      dataIndex: 'balance',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.balance ? record.balance : '-----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('PointsOfSale.CashAccount'),
      dataIndex: 'cash',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.cash ? record.cash : '-----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('PointsOfSale.visaAccount'),
      dataIndex: 'visa',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.visa ? record.visa : '-----'}
            </span>
          </div>
        );
      }
    },
    // {
    //   title: 'الشيفت الحالى',
    //   dataIndex: 'currentShift',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         <span className="name-it-self">
    //           {record.currentShift ? record.currentShift : '-----'}
    //         </span>
    //       </div>
    //     );
    //   }
    // },
    {
      title: t('PointsOfSale.stock'),
      dataIndex: 'stock',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record.stock?.name ? record.stock.name : '-----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('PointsOfSale.settings'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
