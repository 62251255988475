import React from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
// import settingsImg from '../../../assets/imgs/settings/settings-img.png';
import './SystemSettingsPage.scss';
import * as Yup from 'yup';
import { Tabs } from 'antd';
import { useState } from 'react';
import PaymentDepartment from '../../payment-departments/PaymentDepartment';
import { PaymentDepartmentProvider } from '../../../contexts/payment-departments-context/PaymentDepartmentContext';
import { Checkbox, Divider } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import AntdCheckbox from '../../../common/antd-form-components/AntdCheckbox';
import AntdRadioGroup from '../../../common/antd-form-components/AntdRadioGroup';

import InvoiceSettingsPage from './InvoiceSettingsPage';
import TaxSettingsPage from './TaxSettingsPage';
import PaymentSettingsPage from './PaymentSettingsPage';
import SettingsSystemUsersPage from './system-users-page/SystemUsersPage';
import UpgradePage from '../../upgrade-page/UpgradePage';
import UpgradeSettingPage from './upgrade-setting-page/UpgradeSettingPage';
import SystemSettingsPosPage from './system-settings-pos-page/SystemSettingsPosPage';
import { useTranslation } from 'react-i18next';

const CheckboxGroup = Checkbox.Group;
const plainOptions = ['Apple', 'Pear', 'Orange'];
const defaultCheckedList = ['Apple', 'Orange'];

const SystemSettingsPage = () => {
  const { TabPane } = Tabs;
  const [flag, setFlag] = useState('1');
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    lang: Yup.string().required(t('SystemSettings.languageValidation'))
  });
  const onChange = (key) => {
    setFlag(key);
  };
  const {
    control,
    handleSubmit,
    // watch,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: ''
    }
  });

  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);
  const handleInvoicesSettings = () => {
    const onChange = (list) => {
      setCheckedList(list);
      setIndeterminate(!!list.length && list.length < plainOptions.length);
      setCheckAll(list.length === plainOptions.length);
    };
    const onCheckAllChange = (e) => {
      setCheckedList(e.target.checked ? plainOptions : []);
      setIndeterminate(false);
      setCheckAll(e.target.checked);
    };
    return (
      <div>
        {/* <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
          إختيار الجميع
        </Checkbox>
        <Divider /> */}
        <div>
          <div
            className="radios-field-array-wrap no-border"
            style={{ paddingBottom: '15px' }}
          >
            <AntdRadioGroup
              name="lang"
              className="form-radio-group"
              control={control}
              label={t('SystemSettings.languageValidation')}
              validateStatus={errors?.lang ? 'error' : ''}
              errorMsg={errors?.lang?.message}
              defaultValue="1"
              radios={[
                {
                  title: 'عربى',
                  value: 'AR'
                },
                {
                  title: 'English',
                  value: 'EN'
                }
              ]}
            />
          </div>
        </div>
        <AntdCheckbox
          name="change-num"
          label={t('SystemSettings.ChangeTheInvoiceNumberByDefault')}
          control={control}
        />
        <AntdCheckbox
          name="show-qr"
          label={t('SystemSettings.showQRCode')}
          control={control}
        />
        <AntdCheckbox
          name="show-br"
          label={t('SystemSettings.showBarCode')}
          control={control}
        />
        <AntdCheckbox
          name="show-logo"
          label={t('SystemSettings.showCompanyLogo')}
          control={control}
        />
        <AntdCheckbox
          name="show-br"
          label={t('SystemSettings.termsAndConditions')}
          control={control}
        />
      </div>
    );
  };

  return (
    <div className="system-settings-page">
      <div className="page-head-wrap">
        <div className="page-title">
          <h2 className="bold-font">{t('SystemSettings.title')}</h2>
        </div>
        <SearchNotificationsProfile />
      </div>

      <div className="system-tabs">
        <Tabs type="card" defaultActiveKey="1" onChange={onChange}>
          <TabPane tab={t('SystemSettings.invoicesSettings')} key="1">
            <div className="tax-invoice-reports-purchases-wrap">
              <InvoiceSettingsPage />
            </div>
          </TabPane>
          <TabPane tab={t('SystemSettings.paymentSettings')} key="2">
            <div className="tax-invoice-reports-purchases-wrap">
              <PaymentSettingsPage />
            </div>
          </TabPane>

          <TabPane tab={t('SystemSettings.taxSettings')} key="3">
            <div className="tax-invoice-reports-purchases-wrap">
              <TaxSettingsPage />
            </div>
          </TabPane>
          {/* <TabPane tab="إعدادات نقاط البيع" key="4">
            <div className="tax-invoice-reports-purchases-wrap">
              <SystemSettingsPosPage />
            </div>
          </TabPane> */}
          <TabPane tab={t('SystemSettings.userSettings')} key="5">
            <div className="tax-invoice-reports-purchases-wrap">
              <SettingsSystemUsersPage />
            </div>
          </TabPane>
          <TabPane
            tab={t('SystemSettings.cashDisbursementAndReceiptSettings')}
            key="6"
          >
            <div className="tax-invoice-reports-purchases-wrap">
              <PaymentDepartmentProvider>
                <PaymentDepartment />
              </PaymentDepartmentProvider>
            </div>
          </TabPane>
          <TabPane tab={t('SystemSettings.systemUpgrade')} key="7">
            <div className="tax-invoice-reports-purchases-wrap">
              <UpgradeSettingPage />
            </div>
          </TabPane>
        </Tabs>
      </div>

      <div className="img-form-wrap">
        {/* <SystemSettingsPageForm /> */}
        {/* <img src={settingsImg} alt="settings" /> */}
      </div>
    </div>
  );
};

export default SystemSettingsPage;
