/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import SettingsSubCategoriesContext from '../../../contexts/settings-contexts/SettingsSubCategoriesProvider';
import useMainCategories from '../../../custom-hooks/useMainCategories';
import PageModal from './PageModal';
import PageTable from './PageTable';
import './SettingsSubCategoriesPage.scss';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

const SettingsSubCategoriesPage = () => {
  const { catId } = useParams();
  const { modalOpened } = useContext(SettingsSubCategoriesContext);
  const [addModal, setAddModal] = React.useState(true);
  const allMainCategories = useMainCategories();
  const [pageMainCategory, setpageMainCategory] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (allMainCategories?.length > 0) {
      const found = allMainCategories.find(
        (cat) => String(cat.id) === String(catId)
      );
      if (found) setpageMainCategory({ ...found });
    }
  }, [catId, allMainCategories]);

  return (
    <div className="settings-categories-page">
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font">{t('Subcategories.title')}</h2>

          {pageMainCategory?.name && (
            <p>
              {t('Subcategories.mainCategory')} :{' '}
              <span className="bold-font">{pageMainCategory.name}</span>
            </p>
          )}
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('Subcategories.title')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.storePage.title')}
            title3={t('AppBar.Products.storePage.settingsCategoriesPage')}
            link3={'/settings/categories'}
            title4={t('Subcategories.title')}
            link4={`/sales/customer-payments-page/${catId}`}
          />
          {pageMainCategory?.name && (
            <p>
              {t('Subcategories.mainCategory')} :{' '}
              <span className="bold-font">{pageMainCategory.name}</span>
            </p>
          )}
        </div>
        <SearchNotificationsProfile />
      </div>

      {/* <div className="custom-filter-add-section">
        <div className="add-section">
          <div
            className="add-btn"
            onClick={() => {
              setSelectedSubCategory(null);
              setModalOpened(true);
              // add modal
              setAddModal(true);
            }}
          >
            <span className="text-span">إضافة فئة فرعية جديدة</span>
          </div>
        </div>
      </div> */}

      <PageTable setAddModal={setAddModal} />

      {modalOpened && <PageModal addModal={addModal} />}
    </div>
  );
};

export default SettingsSubCategoriesPage;
