import { createContext, useState } from 'react';

const INTIAL_VALUES = {
  isLoadingAllSaleInvoices: false,
  setIsLoadingAllSaleInvoices: (v) => {},
  fetchCountSaleInvoices: 0,
  setFetchCountSaleInvoices: (v) => {},
  allFetchedSaleInvoices: [],
  setAllFetchedSaleInvoice: (v) => {},
  selectedInvoice: null,
  setSelectedInvoice: (v) => {},
  selectedSaleInvoiceModalOpned: false,
  setSelectedSaleInvoiceModalOpened: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {},
  // filter
  tableFilter: {
    page: 1,
    user_id: null,
    type: null,
    from: '',
    to: '',
    pos_id: ''
  },
  setTableFilter: (v) => {}
};

const SaleInvoicesContext = createContext({ INTIAL_VALUES });

export const SaleInvoiceProvider = ({ children }) => {
  const [isLoadingAllSaleInvoices, setIsLoadingAllSaleInvoices] = useState(
    INTIAL_VALUES.isLoadingAllSaleInvoices
  );
  const [fetchCountSaleInvoices, setFetchCountSaleInvoices] = useState(
    INTIAL_VALUES.fetchCountSaleInvoices
  );
  const [allFetchedSaleInvoices, setAllFetchedSaleInvoice] = useState(
    INTIAL_VALUES.allFetchedSaleInvoices
  );
  const [selectedInvoice, setSelectedInvoice] = useState(
    INTIAL_VALUES.selectedInvoice
  );

  const [selectedSaleInvoiceModalOpned, setSelectedSaleInvoiceModalOpened] =
    useState(INTIAL_VALUES.selectedSaleInvoiceModalOpned);
    
    const [tableFilter, setTableFilter] = useState(INTIAL_VALUES?.tableFilter);

    const [tablePagination, setTablePagination] = useState(
      INTIAL_VALUES?.tablePagination
    );

  return (
    <SaleInvoicesContext.Provider
      value={{
        isLoadingAllSaleInvoices,
        setIsLoadingAllSaleInvoices,
        fetchCountSaleInvoices,
        setFetchCountSaleInvoices,
        allFetchedSaleInvoices,
        setAllFetchedSaleInvoice,
        selectedInvoice,
        setSelectedInvoice,
        selectedSaleInvoiceModalOpned,
        setSelectedSaleInvoiceModalOpened,
        //
        tableFilter,
        setTableFilter,
        //
        tablePagination,
        setTablePagination
      }}
    >
      {children}
    </SaleInvoicesContext.Provider>
  );
};

export default SaleInvoicesContext;
