/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import getAllProducts from '../apis/store-apis/products/getAllProducts';
import UserContext from '../contexts/user-context/UserProvider';
import useCustomApiRequest from './useCustomApiRequest';
import getAllProductsWithSelect from '../apis/store-apis/products/getAllProductsWithSelect';

const useProductsWithSelect = () => {
  const [allProducts, setAllProducts] = useState(null);
  const [pType, setPType] = useState('');
  const { user } = useContext(UserContext);

  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [allProductsFetchCount, setAllProductsFetchCount] = useState(null);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getAllProductsWithSelect(user?.token,{noTest:true}, pType),
        (res) => {
          if (res?.status === 200 && res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllProducts(data);
          }
        },
        (error) => {
          setIsLoadingProducts(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [pType, allProductsFetchCount]);

  return {
    allProducts,
    pType,
    setPType,
    allProductsFetchCount,
    setAllProductsFetchCount,
    isLoadingProducts
  };
};

export default useProductsWithSelect;
