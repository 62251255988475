import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import { ReadOutlined, EyeOutlined } from '@ant-design/icons';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import countWords from '../../../utils/countWords';
import trimWords from '../../../utils/trimWords';
import moment from 'moment';
import deleteAccountingApi from '../../../apis/accounting-apis/deleteAccountingApi';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import UserContext from '../../../contexts/user-context/UserProvider';
import JournalsContext from '../../../contexts/accounting-context/JournalsContext';
import deleteJournalApi from '../../../apis/accounting-apis/accounting-details-apis/deleteJournalApi';
import SummaryContext from '../../../contexts/accounting-context/SummaryContext';
import './summary.scss';
import { useTranslation } from 'react-i18next';

const TableColumns = (
  fetchedSummaryDetails,
  setModalOpened,
  setModalOpenedUpdate,
  setFetchCount,
  setSelectedSummary,
  setIsLoading,
  token,
  type
) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const params = useParams();

  const { setSelectedJournalId } = useContext(SummaryContext);

  const handleEditClick = (record) => {
    setSelectedSummary(record);
    setModalOpenedUpdate(true);
    setSelectedJournalId(record.id);
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteJournalApi(user?.token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Messages.addedSuccessfully'),
          message: t('FinancialTerms.branchDeleted')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Messages.ErrorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Messages.pleaseTryLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title:  t('Messages.ErrorOccurred'),
        message: t('Messages.pleaseTryLater')
      });
    }
  };

  const handlePreview = (id) => {
    setSelectedJournalId(id);
    setModalOpened(true);
  };

  // const renderActions = (obj) => {
  //   if (!obj.model_id) {
  //     return (
  //       <div className="action-btns-wrap">
  //         <div className="circled-btn-wrap">
  //           <Tooltip title="معاينة فرع الحسابات">
  //               <Button
  //                 id={obj?.id}
  //                 onClick={() => handlePreview(obj?.id)}
  //                 className="circled-btn"
  //                 type="dashed"
  //                 shape="circle"
  //                 icon={<EyeOutlined />}
  //               />
  //           </Tooltip>
  //         </div>

  //         {/* <div className="edit-btn" onClick={() => handleEditClick(obj)}>
  //           <EditIcon />
  //           تعديل
  //         </div> */}
  //         <Popconfirm
  //           title="هل أنت متأكد من حذف الفرع"
  //           onConfirm={() => handleDeleteRequest(obj.id)}
  //           okText="نعم"
  //           cancelText="لا"
  //         >
  //           <Tooltip title="حذف الفرع">
  //             <div className="delete-btn">
  //               <DeleteIcon />
  //             </div>
  //           </Tooltip>
  //         </Popconfirm>
  //       </div>
  //     );
  //   }
  //   return '------';
  // };

  return [
    // {
    //   title: 'الكود',
    //   dataIndex: 'acn',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         <span className={`name-it-self level-${record.level}`}>
    //           {record.acn}
    //         </span>
    //       </div>
    //     );
    //   }
    // },
    {
      // title: 'اسم الحساب',
      title: t('FinancialTerms.AccountName'),

      dataIndex: 'holder',
      // width: 242,
      render: (_, record) => {
        return (
          <div
            className="row-cell row-text"
            style={{ display: 'flex', justifyContent: 'start' }}
          >
            {record.level === 'final' ? (
              <h2
                style={{ color: 'red' }}
                className={`name-it-self level-${record.level}`}
              >
                {record.note ? record.note : '----'}
              </h2>
            ) : (
              <h5
                style={{ fontWeight: '600' }}
                className={`name-it-self level-${record.level}`}
              >
                {record.holder ? `${record.acn} - ${record.holder} ` : '----'}
              </h5>
            )}
          </div>
        );
      }
    },
    {
      // title: 'رصيد الحساب',
      title: t('FinancialTerms.AccountBalance'),

      dataIndex: 'current_balance',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className={`name-it-self level-${record.level}`}>
              {record.current_balance}
            </span>
          </div>
        );
      }
    },
    {
      // title: 'الرصيد السابق (المدينه)',
      title: t('FinancialTerms.PreviousBalanceDebit'),

      dataIndex: 'trial_previews_debit',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className={`name-it-self level-${record.level}`}>
              {record.trial_previews_debit}
            </span>
          </div>
        );
      }
    },
    {
      // title: 'الرصيد السابق (الدائنة)',
      title: t('FinancialTerms.PreviousBalanceCredit'),

      dataIndex: 'trial_previews_credit',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className={`name-it-self level-${record.level}`}>
              {record.trial_previews_credit}
            </span>
          </div>
        );
      }
    },
    {
      // title: 'الحركات (المدينة)',
      title: t('FinancialTerms.TransactionsDebit'),

      dataIndex: 'trial_sums_debit',
      width: 100,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className={`name-it-self level-${record.level}`}>
              {record.trial_sums_debit}
            </span>
          </div>
        );
      }
    },
    {
      // title: 'الحركات (الدائنة)',
      title: t('FinancialTerms.TransactionsCredit'),

      dataIndex: 'trial_sums_credit',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className={`name-it-self level-${record.level}`}>
              {record.trial_sums_credit}
            </span>
          </div>
        );
      }
    },
    {
      title: t('FinancialTerms.BalancesDebit'),

      dataIndex: 'trial_balance_debit',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className={`name-it-self level-${record.level}`}>
              {record.trial_balance_debit}
            </span>
          </div>
        );
      }
    },
    {
      // title: 'الأرصدة (الدائنة)',
      title: t('FinancialTerms.BalancesCredit'),

      dataIndex: 'trial_balance_credit',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className={`name-it-self level-${record.level}`}>
              {record.trial_balance_credit}
            </span>
          </div>
        );
      }
    },
    {
      // title: 'مـطـابـق',
      title: t('FinancialTerms.Matching'),

      dataIndex: 'exact',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className={`name-it-self level-${record.level}`}>
              {record.exact}
            </span>
          </div>
        );
      }
    }

    // {
    //   title: 'الرصيد المبدأى',
    //   dataIndex: 'initial_balance',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         <span className={`name-it-self level-${record.level}`}>
    //           {record.initial_balance ? record.initial_balance : '-----'}
    //         </span>
    //       </div>
    //     );
    //   }
    // },
    // {
    //   title: 'المدين',
    //   dataIndex: 'debit',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         <span className={`name-it-self level-${record.level}`}>
    //           {record.summary_debit}
    //         </span>
    //       </div>
    //     );
    //   }

    // },
    // {
    //   title: 'الدائن',
    //   dataIndex: 'credit',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         <span className={`name-it-self level-${record.level}`}>
    //           {record.summary_credit}
    //         </span>
    //       </div>
    //     );
    //   }
    // }
  ];
};

export default TableColumns;
