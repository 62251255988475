/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
// import { useTranslation } from 'react-i18next';
// import { LoadingOutlined } from '@ant-design/icons';
// import { Button } from 'antd';
import ReactHookFormContext from '../../contexts/react-hook-form-context/ReactHookFormContext';
import './ReactHookFormStepper.scss';

const ReactHookFormStepper = ({
  children,
  sideWrapper,
  className,
  defaultValues,
  ...props
}) => {
  // const childrenArray = React.Children.toArray(children);
  // const { i18n } = useTranslation();
  // const [step, setStep] = useState(0);
  // const currentChild = childrenArray[step];
  // const isLastStep = () => {
  //   return step === childrenArray.length - 1;
  // };

  // const { setStep, isLastStep, setChildrenArray, currentChild } =
  const { setChildrenArray, currentChild } = useContext(ReactHookFormContext);

  useEffect(() => {
    setChildrenArray(React.Children.toArray(children));
  }, []);

  // const onNext = async () => {
  //   if (isLastStep) {
  //     props.onNext();
  //   } else {
  //     setStep((currState) => currState + 1);
  //   }
  // };

  return (
    <div className={`stepper-form-wrapper ${className ? className : ''}`}>
      <div {...props} className="stepper-form">
        {currentChild}
        {/* <div className={`stepper-btns ${i18n.dir()}`}>
              {step > 0 && (
                <Button
                  type="button"
                  onClick={() => setStep((currState) => currState - 1)}
                >
                  <span>Prev Step</span>
                </Button>
              )}
              {step < childrenArray?.length && (
                <Button htmlType="submit">
                  {methods.formState.isSubmitting ? (
                    <span className="img-msg">
                      <LoadingOutlined />
                      Saving...
                    </span>
                  ) : (
                    <span>{`${isLastStep ? 'Finish' : 'Next Step'}`}</span>
                  )}
                </Button>
              )}
            </div> */}
        {/* <div style={{ direction: 'ltr', overflow: 'scroll' }}>
            <pre />
            <h1>Values</h1>
            <pre>{JSON.stringify(methods.watch(), null, 2)}</pre>
            <pre />
            <h1>Errors</h1>
            <pre>{JSON.stringify(methods.formState.errors, null, 2)}</pre>
            <pre />
            <h1>Touched</h1>
            <pre>{JSON.stringify(touched, null, 2)}</pre>
          </div> */}
      </div>
    </div>
  );
};

export default ReactHookFormStepper;
