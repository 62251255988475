import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Button, Form, Spin } from 'antd';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import AntdCheckbox from '../../../common/antd-form-components/AntdCheckbox';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import getPaymentSettingsApi from '../../../apis/settings-apis/system-settings-api/getPaymentSettingsApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import addPaymentsApi from '../../../apis/settings-apis/system-settings-api/addPaymentsApi';
import { useHistory } from 'react-router-dom';
import { store } from 'react-notifications-component';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import { useTranslation } from 'react-i18next';
const schema = Yup.object().shape({
  show_br_1: Yup.string().optional(''),
  show_br_2: Yup.string().optional(''),
  show_br_3: Yup.string().optional(''),
  show_br_4: Yup.string().optional(''),
  show_br_5: Yup.string().optional('')
});
const PaymentSettingsPage = () => {
  const [render, setRender] = useState(0);
  const customApiRequest = useCustomApiRequest();
  const systemSettings = useSystemSettings();
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const [paymentsTypes, setPaymentsTypes] = useState();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      show_br_1: '',
      show_br_2: '',
      show_br_3: '',
      show_br_4: ''
    }
  });
  let payment_methods = [];
  watch('show_br_1') && payment_methods.push(watch('show_br_1') && 1);
  watch('show_br_2') && payment_methods.push(watch('show_br_2') && 2);
  watch('show_br_3') && payment_methods.push(watch('show_br_3') && 3);
  watch('show_br_4') && payment_methods.push(watch('show_br_4') && 5);
  watch('show_br_5') && payment_methods.push(watch('show_br_5') && 6);

  useEffect(() => {
    customApiRequest(
      getPaymentSettingsApi(user?.token),
      (res) => {
        if (res?.data?.data) {
          setPaymentsTypes(res?.data?.data?.data);
        }
      },
      (error) => {}
    );
    setRender(render + 1);
  }, [systemSettings]);

  useEffect(() => {
    if (paymentsTypes) {
      setValue(
        'show_br_1',
        systemSettings?.invoiceSetting?.payment_methods?.includes(
          (1).toString()
        )
          ? true
          : false
      );
      setValue(
        'show_br_2',
        systemSettings?.invoiceSetting?.payment_methods?.includes(
          (2).toString()
        )
          ? true
          : false
      );
      setValue(
        'show_br_3',
        systemSettings?.invoiceSetting?.payment_methods?.includes(
          (3).toString()
        )
          ? true
          : false
      );
      setValue(
        'show_br_4',
        systemSettings?.invoiceSetting?.payment_methods?.includes(
          (5).toString()
        )
          ? true
          : false
      );
      setValue(
        'show_br_5',
        systemSettings?.invoiceSetting?.payment_methods?.includes(
          (6).toString()
        )
          ? true
          : false
      );
    }
  }, [systemSettings, render, paymentsTypes]);

  const check = (index) => {
    let payNumber = index < 3 ? index + 1 : index + 2;
    const isFind = systemSettings?.invoiceSetting?.payment_methods?.includes(
      payNumber.toString()
    );
    return isFind;
  };

  const onSubmit = async (data) => {
    customApiRequest(
      addPaymentsApi(user?.token, {
        payment_methods: payment_methods
      }),
      (res) => {
        if (res?.data?.status && res?.data?.data) {
          store.addNotification({
            title: res?.data?.message,
            message: res?.data?.message,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              showIcon: true,
              onScreen: true
            }
          });
        } else {
          store.addNotification({
            title: t('SystemSettings.operationFailed'),
            message:
              res?.data?.message || t('SystemSettings.enterPaymentMethod'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              showIcon: true,
              onScreen: true
            }
          });
        }
      },
      (error) => {
        store.addNotification({
          title: t('SystemSettings.operationFailed'),
          message: t('SystemSettings.enterPaymentMethod'),
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    );
  };
  const [form] = Form.useForm();

  return (
    <div className="payment-settings">
      {systemSettings ? (
        <>
          <h2>{t('SystemSettings.chooseNewPaymentMethod')}</h2>
          {/* <Button className="add-payment-btn">إضافه طريقه دفع جديده</Button> */}
          <Form
            className="custom-shared-form"
            form={form}
            layout="vertical"
            onFinish={handleSubmit(onSubmit)}
          >
            {paymentsTypes?.length &&
              paymentsTypes?.map((pay, index) => (
                <div className="payment-card">
                  <AntdCheckbox
                    name={`show_br_${index + 1}`}
                    label=""
                    control={control}
                    checked={
                      render == 0 ? check(index) : watch(`show_br_${index + 1}`)
                    }
                  />
                  <h2>{pay?.name}</h2>
                </div>
              ))}
            <div className="btn">
              <Button
                className="submit-btn"
                htmlType="submit"
                type="primary"
                // icon={<LoginOutlined />}
                // loading={isSubmittingForm}
              >
                {t('SystemSettings.save')}
              </Button>
            </div>
          </Form>
        </>
      ) : (
        <div className="payment-loading">
          <Spin />
        </div>
      )}
    </div>
  );
};

export default PaymentSettingsPage;
