import axios from 'axios';

const getWarehouseQuantityApi = async (stockId, modelId, type, token) => {
  try {
    const res = await axios.get(
      `/TransferOrder/getQuantityInStock?stock_id=${stockId}&model_id=${modelId}&type=${type}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'applications/json',
          Authorization: 'Bearer ' + token,
          lang: 'ar'
        }
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export default getWarehouseQuantityApi;
