import axios from 'axios';

const getAllDepartments = async (token, values, getAll = false) => {
  try {
    let url;
    if (getAll)
      url = `/receipt-departments?page=${values.page ? values.page : ''}`;
    else
      url = `/receipt-departments?page=${
        values.page ? values.page : ''
      }&test=1`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllDepartments;
