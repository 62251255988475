/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Descriptions, Modal, Spin } from 'antd';
import ShiftsContext from '../../contexts/shifts-context/ShiftsContext';
import './SelectedShiftModal.scss';
import { useTranslation } from 'react-i18next';
const SelectedShiftModal = () => {
  const {
    selectedShift,
    setSelectedShift,
    selectedShiftModalOpened,
    setSelectedShiftModalOpened
  } = useContext(ShiftsContext);
 const {t} =useTranslation()
  if (selectedShift) {
    const renderModalBody = () => {
      return (
        <div className="product-details-wrap">
          <Descriptions title={t('Shift.shiftDetails')} bordered column={1}>
            {selectedShift?.id && (
              <Descriptions.Item label={t('Shift.code')}>
                {selectedShift.id}
              </Descriptions.Item>
            )}
            {selectedShift?.start_shift && (
              <Descriptions.Item label={t('Shift.beginningOfShift')}>
                {selectedShift?.start_shift}
              </Descriptions.Item>
            )}
            {selectedShift?.end_shift && (
              <Descriptions.Item label={t('Shift.endOfShift')}>
                {selectedShift?.end_shift}
              </Descriptions.Item>
            )}
            {!selectedShift?.end_shift && (
              <Descriptions.Item label={t('Shift.endOfShift')}>
               {t('Shift.notFinished')}
              </Descriptions.Item>
            )}
            {selectedShift?.shiftCash && (
              <Descriptions.Item label={t('Shift.accountWithCash')}>
                {selectedShift?.shiftCash}
              </Descriptions.Item>
            )}
            {selectedShift?.shiftVisa && (
              <Descriptions.Item label={t('Shift.accountWithVisa')}>
                {selectedShift?.shiftVisa}
              </Descriptions.Item>
            )}
          </Descriptions>

          <Descriptions title={t('Shift.shiftEmployeeDetails')} bordered column={1}>
            {selectedShift?.employee?.id && (
              <Descriptions.Item label={t('Shift.code')}>
                {selectedShift?.employee?.id}
              </Descriptions.Item>
            )}
            {selectedShift?.employee?.name && (
              <Descriptions.Item label={t('Shift.employeeName')}>
                {selectedShift?.employee?.name}
              </Descriptions.Item>
            )}
            {selectedShift?.employee?.phone && (
              <Descriptions.Item label={t('Shift.phone')}>
                {selectedShift?.employee?.phone}
              </Descriptions.Item>
            )}
            {String(selectedShift?.employee?.email) && (
              <Descriptions.Item label={t('Shift.email')}>
                {selectedShift?.employee?.email}
              </Descriptions.Item>
            )}
          </Descriptions>

          <Descriptions title={t('Shift.salePointDetails')} bordered column={1}>
            {selectedShift?.pos?.id && (
              <Descriptions.Item label={t('Shift.code')}>
                {selectedShift?.pos?.id}
              </Descriptions.Item>
            )}
            {selectedShift?.pos?.name && (
              <Descriptions.Item label={t('Shift.salePointName')}>
                {selectedShift?.pos?.name}
              </Descriptions.Item>
            )}
            {selectedShift?.posCash && (
              <Descriptions.Item label={t('Shift.salePointCashAccount')}>
                {selectedShift.posCash}
              </Descriptions.Item>
            )}
            {selectedShift?.posVisa && (
              <Descriptions.Item label={t('Shift.salePointVisaAccount')}>
                {selectedShift.posVisa}
              </Descriptions.Item>
            )}
          </Descriptions>

          <Descriptions title={t('Shift.WarehouseDetails')} bordered column={1}>
            {selectedShift?.pos?.stock?.id && (
              <Descriptions.Item label={t('Shift.code')}>
                {selectedShift.pos.stock?.id}
              </Descriptions.Item>
            )}
            {selectedShift?.pos?.stock?.name && (
              <Descriptions.Item label={t('Shift.WarehouseName')}>
                {selectedShift.pos.stock?.name}
              </Descriptions.Item>
            )}
          </Descriptions>
        </div>
      );
    };

    return (
      <Modal
        className="shared-custom-modal selected-product-modal"
        width="90%"
        style={{ maxWidth: '942px' }}
        title={t('Shift.shiftDetails')}
        visible={selectedShiftModalOpened}
        onOk={() => {
          setSelectedShiftModalOpened(false);
          setSelectedShift(null);
        }}
        onCancel={() => {
          setSelectedShiftModalOpened(false);
          setSelectedShift(null);
        }}
        footer={false}
      >
        {renderModalBody()}
        {/* <pre style={{ direction: 'ltr' }}>
        {JSON.stringify(selectedShift, null, 2)}
      </pre> */}
      </Modal>
    );
  } else if (!selectedShift)
    return (
      <Modal
        className="shared-custom-modal selected-product-modal"
        width="90%"
        style={{ maxWidth: '942px' }}
        title={t('Shift.shiftDetails')}
        visible={selectedShiftModalOpened}
        onOk={() => {
          setSelectedShiftModalOpened(false);
          setSelectedShift(null);
        }}
        onCancel={() => {
          setSelectedShiftModalOpened(false);
          setSelectedShift(null);
        }}
        footer={false}
      >
        <div style={{ height: 232, display: 'grid', placeItems: 'center' }}>
          <Spin />
        </div>
      </Modal>
    );
  else {
    return (
      <Modal
        className="shared-custom-modal selected-product-modal"
        width="90%"
        style={{ maxWidth: '942px' }}
        title={t('Shift.shiftDetails')}
        visible={selectedShiftModalOpened}
        onOk={() => {
          setSelectedShiftModalOpened(false);
          setSelectedShift(null);
        }}
        onCancel={() => {
          setSelectedShiftModalOpened(false);
          setSelectedShift(null);
        }}
        footer={false}
      >
        <div
          style={{ height: 132, display: 'grid', placeItems: 'center' }}
        ></div>
      </Modal>
    );
  }
};

export default SelectedShiftModal;
