import React, { useCallback, useContext, useEffect, useState } from 'react';
import searchAllSuppliersApi from '../../apis/store-apis/suppliers/searchAllSuppliersApi';
// import { Select } from 'antd';
import SearchIcon from '../../common/icons/SearchIcon';
import UserContext from '../../contexts/user-context/UserProvider';
import checkRes from '../../utils/checkRes';
import PageModal from './PageModal';
import './TableFilter.scss';
import { exportToExcel } from 'react-json-to-excel';
import getAllSuppliersApi from '../../apis/store-apis/suppliers/getAllSuppliersApi';
import excelImg from '../../assets/imgs/icons/excel-btn.png';
import CustomersPaymentsContext from '../../contexts/customers-payments-context/CustomersPaymentsContext';
import debounce from 'lodash.debounce';
import SuppliersContext from '../../contexts/suppliers-context/SuppliersContext';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
import { useTranslation } from 'react-i18next';
const TableFilter = ({ currentLang }) => {
  // const { Option } = Select;
  const {
    modalOpened,
    setModalOpened,
    setSelectedCustomerPayment,
    setSearchTargetValue,
    SearchTargetValue
  } = useContext(CustomersPaymentsContext);
  const {
    setFetchCount,
    setAllFetchedSuppliers,
    setNameSearchValue,
    setIsLoading,
    tablePagination,
    setTablePagination,
    nameSearchValue
  } = useContext(SuppliersContext);
  const { user } = useContext(UserContext);
  const systemSettings = useSystemSettings();
  const [tableSearch, setTableSearch] = useState();
  const { t } = useTranslation();
  const debouncedChangeHandler = useCallback(
    debounce(async (e) => {
      handleTableSearch(e);
    }, 500),
    []
  );

  const handleTableSearch = async (e) => {
    setNameSearchValue(e.target.value);
    try {
      setIsLoading(true);
      let res = await searchAllSuppliersApi(user?.token, {
        page: tablePagination.current_page,
        searchText: e.target.value
      });

      if (res) {
        setIsLoading(false);
        if (checkRes(res) && res?.data?.data?.data?.length >= 0) {
          const data = res.data.data.data;
          setAllFetchedSuppliers(data);
        }
        if (res?.data?.data?.pagination) {
          setTablePagination(res.data.data.pagination);
        }
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleExcel = async () => {
    let data;
    try {
      const res = await getAllSuppliersApi(
        user?.token,
        {
          searchText: nameSearchValue ? nameSearchValue : '',
          page: ''
        },
        true
      );
      if (res.status === 200) {
        data = res?.data?.data;
        exportToExcel(data, t('purchaseInvoices.suppliers'));
      }
    } catch (error) {
      
    }
  };

  return (
    <div className="custom-filter-add-section">
      {/* <div className="add-section">
        <div
          className="add-btn"
          onClick={() => {
            // if selected supplier then reset it
            // open the supplier modal
            setSelectedCustomerPayment(null);
            setModalOpened(true);
          }}
        >
          <span className="text-span">تسديد دفعة جديدة</span>
        </div>
      </div> */}

      <div className="custom-table-fitler">
        <div className="form-excel-export-wrap">
          <form className="filter-form">
            <div className="table-search">
              <label>
                <SearchIcon />
                <input
                  placeholder={t('supplierPayments.searchSupplierPlaceholder')}
                  type="text"
                  name="table_search"
                  onChange={debouncedChangeHandler}
                />
              </label>
            </div>
          </form>
          <div className="excel-btn-wrap">
            <a
              href={`${
                process.env.REACT_APP_BASE_URL
              }/Excel/exportSuppliersPayment?client_id=${
                systemSettings?.id || ''
              }&searchText=${
                nameSearchValue ? nameSearchValue : ''
              }&type=supplier`}
              className="excel-export-btn"
            >
              <img
                style={{ height: '42px' }}
                src={excelImg}
                alt="export to excel"
              />
            </a>
          </div>
          {/* <div className="excel-btn-wrap" onClick={() => handleExcel()}>
            <img
              style={{ height: '42px' }}
              src={excelImg}
              alt="export to excel"
            />
          </div> */}
          {/* <div className="excel-btn-wrap">
            <a
              href={`${process.env.REACT_APP_BASE_URL}/Excel/exportSuppliers?client_id=${systemSettings?.id}`}
              download
              className="excel-export-btn"
            >
              <img
                style={{ height: '42px' }}
                src={excelImg}
                alt="export to excel"
              />
            </a>
          </div> */}
        </div>
        {/* {systemSettings?.sallaToken && (
          <div className="preview-download-upload add-btn">
            <span className="text-span">ربط مع سلة</span>
          </div>
        )} */}
      </div>

      {modalOpened && <PageModal currentLang={currentLang} />}
    </div>
  );
};

export default TableFilter;
