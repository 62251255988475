import axios from 'axios';

const deleteDocApi = async (token, id) => {
  try {
        const formData = new FormData();
    formData.append('id', id);

    const res = await axios.post(
      '/Document/delete',
          formData,

      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + token,
          lang: 'ar'
        }
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export default deleteDocApi;
