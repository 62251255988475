import { Spin } from 'antd';
import React from 'react';
import ReportsBoxs from './ReportsBoxs';

const SharedTaxInvoiceComponent = React.forwardRef(
  (
    {
      isLoading,
      fetchedReports,
      selectedPicker,
      setSelectedPicker,
      currentLang
    },
    ref
  ) => {
    return (
      <>
        {!isLoading && fetchedReports && (
          <ReportsBoxs
            selectedPicker={selectedPicker}
            setSelectedPicker={setSelectedPicker}
            boxs={fetchedReports}
            ref={ref}
            currentLang={currentLang}
          />
        )}
        {isLoading && (
          <div style={{ padding: '32px', minHeight: '182px' }}>
            <Spin />
          </div>
        )}
      </>
    );
  }
);

export default SharedTaxInvoiceComponent;
