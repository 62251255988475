import axios from 'axios';

const createJournalsLeafApi = async (token, values, url, edit = false) => {
  // let data = JSON.stringify({
  //   "client_id": values.client_id,
  //   "note": values.note,
  //   "date": values.date,
  //   "method": values.payment_method,
  //   'transactions': [
  //     values.transactions.map((trans) => (
  //         {
  //           account_id: trans.account_id,
  //           credit: trans.credit,
  //           debit: trans.debit,
  //           type: trans.type,
  //         }
  //     ))
  //   ]
  // });

  try {
    const res = await axios({
      method: 'post',
      url,
      baseURL: process.env.REACT_APP_BASE_TEST_URL,
      // baseURL: 'https://testclient-api.fatoorah.sa/apiAdmin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      },
      data: { ...values }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default createJournalsLeafApi;
