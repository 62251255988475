import { useState, createContext } from 'react';

const INITIAL_VALUES = {
  isLoading: false,
  setIsLoading: (v) => {},
  fetchCount: 0,
  setFetchCount: (v) => {},
  modalOpened: false,
  setModalOpened: (v) => {},
  excelModalOpened: false,
  setExcelModalOpened: (v) => {},
  allFetchedSuppliers: null,
  setAllFetchedSuppliers: (v) => {},
  selectedSupplier: null,
  setSelectedSupplier: (v) => {},
  nameSearchValue: '',
  setNameSearchValue: (v) => {},
  //
  selectedSupplierId: '',
  setSelectedSupplierId: (v) => {},
  isSubmittingForm: false,
  setIsSubmittingForm: (v) => {},
  tablePagination: {
    current_page: 1,
    per_page: 0,
    total: 0
  },
  setTablePagination: (v) => {}
};

const SuppliersContext = createContext(INITIAL_VALUES);

export const SuppliersProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(INITIAL_VALUES.isLoading);
  const [fetchCount, setFetchCount] = useState(INITIAL_VALUES.fetchCount);
  const [modalOpened, setModalOpened] = useState(INITIAL_VALUES.modalOpened);
  const [excelModalOpened, setExcelModalOpened] = useState(
    INITIAL_VALUES.excelModalOpened
  );
  const [allFetchedSuppliers, setAllFetchedSuppliers] = useState(
    INITIAL_VALUES.allFetchedSuppliers
  );
  const [selectedSupplier, setSelectedSupplier] = useState(
    INITIAL_VALUES.selectedSupplier
  );
  const [nameSearchValue, setNameSearchValue] = useState(
    INITIAL_VALUES.nameSearchValue
  );
  //
  const [selectedSupplierId, setSelectedSupplierId] = useState(
    INITIAL_VALUES.selectedSupplierId
  );
  const [isSubmittingForm, setIsSubmittingForm] = useState(
    INITIAL_VALUES.isSubmittingForm
  );
  const [tablePagination, setTablePagination] = useState(
    INITIAL_VALUES?.tablePagination
  );

  return (
    <SuppliersContext.Provider
      value={{
        isLoading,
        setIsLoading,
        fetchCount,
        setFetchCount,
        modalOpened,
        setModalOpened,
        excelModalOpened,
        setExcelModalOpened,
        allFetchedSuppliers,
        setAllFetchedSuppliers,
        selectedSupplier,
        setSelectedSupplier,
        nameSearchValue,
        setNameSearchValue,
        //
        selectedSupplierId,
        setSelectedSupplierId,
        isSubmittingForm,
        setIsSubmittingForm,
        tablePagination,
        setTablePagination
      }}
    >
      {children}
    </SuppliersContext.Provider>
  );
};

export default SuppliersContext;
