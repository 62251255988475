/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Empty, Pagination, Table } from 'antd';
import tableColumns from './tableColumns';
import StorePurchaseOrderContext from '../../../contexts/store-purchase-order-context/StorePurchaseOrderProvider';
import getAllPurchasesOrdersApi from '../../../apis/store-apis/purchase-order-apis/getAllPurchasesOrdersApi';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useHistory } from 'react-router-dom';
import storeRouterLinks from '../../../components/app/store-routes/storeRouterLinks';
import { useTranslation } from 'react-i18next';
const StoreProductCategoriesTable = ({ currentLang }) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const {
    isLoading,
    setIsLoading,
    fetchCount,
    setFetchCount,
    allFetchedOrders,
    setAllFetchedOrders,
    setSelectedOrder,
    setModalOpened,
    nameSearchValue,
    setSelectedOrderModalOpened,
    tablePagination,
    tableDataSource,
    setTableDataSource
  } = useContext(StorePurchaseOrderContext);

  // const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllPurchasesOrdersApi(user?.token),
        (res) => {
          setIsLoading(false);
          if (res?.data?.data?.length >= 0) {
            const data = res.data.data;
            setAllFetchedOrders(data);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedOrders?.length >= 0) {
      let mappedData = null;
      if (nameSearchValue.trim()) {
        const filtered = allFetchedOrders.filter((obj) =>
          obj.supplier?.name
            ?.toLowerCase()
            .trim()
            .includes(nameSearchValue.toLowerCase().trim())
        );
        mappedData = mapDataSource(filtered);
      } else {
        mappedData = mapDataSource(allFetchedOrders);
      }
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedOrders, allFetchedOrders?.length, nameSearchValue]);
  const history = useHistory();

  return (
    <>
      <Table
        locale={{
          emptyText: (
            <Empty description={false}>{t('PurchaseOrder.noData')}</Empty>
          )
        }}
        pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedOrders,
          setModalOpened,
          setFetchCount,
          setSelectedOrder,
          setIsLoading,
          setSelectedOrderModalOpened,
          t,
          user?.token
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
      {tablePagination && (
        <Pagination
          style={{ display: 'flex', justifyContent: 'center' }}
          defaultCurrent={1}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}
          onChange={(page, pageSize) => {
            setFetchCount((prev) => prev + 1);
            history.push(`${storeRouterLinks?.purchaseOrderPage}?page=${page}`);
          }}
          hideOnSinglePage={true}
        />
      )}
    </>
  );
};

export default StoreProductCategoriesTable;
