import React, { createContext, useReducer, useState } from 'react';
import { setUser, removeUser, setUserToStateAction } from './user.actions';
import userReducer from './user.reducer';
import Cookies from 'js-cookie';

const getUserFromSession = () => {
  if (sessionStorage.getItem('currentUser')) {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  } else if (Cookies.get('currentUser')) {
    return JSON.parse(Cookies.get('currentUser'));
  }
  return null;
};

const INITIAL_STATE = {
  loggedIn:
    Cookies.get('currentUser') || sessionStorage.getItem('currentUser')
      ? true
      : false,
  user: getUserFromSession()
};
const contextInitialState = {
  ...INITIAL_STATE,
  setUserToState: (user) => {},
  setCurrentUser: (user) => {},
  removeCurrentUser: () => {},
  isNewLoggedIn: false,
  setNewIsLoggedIn: () => {},
  otpValue: false,
  setOtpValue: () => {},
  msg: false,
  setMsg: () => {},
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  isAuthenticatedCount: false,
  setIsAuthenticatedCount: () => {}
};

const UserContext = createContext(contextInitialState);

export const UserProvider = ({ children }) => {
  const [reducerState, dispatch] = useReducer(userReducer, INITIAL_STATE);
  const { user, loggedIn } = reducerState;
  const setCurrentUser = (cUser) => dispatch(setUser(cUser));
  const setUserToState = (u) => dispatch(setUserToStateAction(u));
  const removeCurrentUser = () => dispatch(removeUser());
  const [filterValues, setFilterValues] = React.useState({});
  const [isNewLoggedIn, setIsNewLoggedIn] = useState(
    contextInitialState.isNewLoggedIn
  );
  const [otpValue, setOtpValue] = useState(contextInitialState.otpValue);
  const [posReportModalOpened, setPosReportModalOpened] = useState(false);
  const [endTheShit, setEndTheShit] = useState(false);
  const [posBoxModalOpened, setPosBoxModalOpened] = useState(false);
  const [phone, setPhone] = useState('');
  const [msg , setMsg] = useState('');
  const [isAuthenticated , setIsAuthenticated] = useState(contextInitialState.isAuthenticated);
  const [isAuthenticatedCount , setIsAuthenticatedCount] = useState(contextInitialState.isAuthenticatedCount);

  return (
    <UserContext.Provider
      value={{
        loggedIn,
        user,
        setUserToState,
        setCurrentUser,
        filterValues,
        setFilterValues,
        removeCurrentUser,
        isNewLoggedIn,
        setIsNewLoggedIn,
        otpValue,
        setOtpValue,
        posReportModalOpened,
        setPosReportModalOpened,
        posBoxModalOpened,
        setPosBoxModalOpened,
        phone,
        setPhone,
        endTheShit,
        setEndTheShit,
        msg, 
        setMsg,
        isAuthenticated,
        setIsAuthenticated,
        isAuthenticatedCount,
        setIsAuthenticatedCount
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
