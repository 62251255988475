/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import { useParams } from 'react-router-dom';
import SettingsSubCategoriesContext from '../../../contexts/settings-contexts/SettingsSubCategoriesProvider';
import useMainCategories from '../../../custom-hooks/useMainCategories';
import addNewCategoryApi from '../../../apis/settings-apis/categories-apis/addNewCategoryApi';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import { useTranslation } from 'react-i18next';

const schema = Yup.object().shape({
  category_name: Yup.string().required('ادخل اسم الفئة'),
  main_category_id: Yup.string().required('اختار الفئة الاساسية')
});

const TableForm = ({ addModal }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const {
    setIsLoading,
    setFetchCount,
    setModalOpened,
    selectedSubCategory,
    setIsSubmittingForm,
    isSubmittingForm
  } = useContext(SettingsSubCategoriesContext);
  const params = useParams();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      category_name: '',
      category_description: '',
      main_category_id: params?.catId ? params.catId : ''
    }
  });

  const allMainCategories = useMainCategories();

  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    setIsLoading(true);
    setIsSubmittingForm(true);
    if (selectedSubCategory) {
      const fromData = new FormData();
      // const mappedData = { ...data };

      if (data.main_category_id)
        fromData.append('cat_id', selectedSubCategory.cat_id);
      if (data.category_description)
        fromData.append('description', data.category_description);
      if (data.category_name) fromData.append('name', data.category_name);
      if (selectedSubCategory.id) fromData.append('id', selectedSubCategory.id);
      fromData.append('level', 2);
      const mappedData = { ...data };
      mappedData.level = 2; // level is 2 as its a sub category type
      mappedData.cat_id = selectedSubCategory.cat_id;
      mappedData.id = selectedSubCategory.id;
      customApiRequest(
        addNewCategoryApi(user?.token, fromData, true),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (checkRes(res) && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Subcategories.addedSuccessfully'),
              message: t('Subcategories.categoryModifiedSuccessfully')
            });
          } else {
            errorNotification({
              title: t('Subcategories.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Subcategories.tryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('Subcategories.errorOccurred'),
            message:
              error?.response?.data?.message || t('Subcategories.tryLater')
          });
        }
      );
    } else {
      const mappedData = { ...data };
      mappedData.level = 1;
      mappedData.cat_id = 0;
      customApiRequest(
        addNewCategoryApi(user?.token, mappedData),
        (res) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          if (checkRes(res) && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Subcategories.addedSuccessfully'),
              message: t('Subcategories.categoryAddedSuccessfully')
            });
          } else {
            errorNotification({
              title: t('Subcategories.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Subcategories.tryLater')
            });
          }
        },
        (error) => {
          setIsLoading(false);
          setIsSubmittingForm(false);
          errorNotification({
            title: t('Subcategories.errorOccurred'),
            message:
              error?.response?.data?.message || t('Subcategories.tryLater')
          });
        }
      );
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedSubCategory) {
      setValue(
        'category_name',
        selectedSubCategory?.name ? selectedSubCategory.name : ''
      );
      setValue(
        'category_description',
        selectedSubCategory?.description ? selectedSubCategory.description : ''
      );
    }
  }, [selectedSubCategory, selectedSubCategory?.id]);

  const [form] = Form.useForm();

  return (
    <Form
      className="settings-warehouse-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Subcategories.categoryName')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="category_name"
              type="text"
              placeholder={`${t('Subcategories.categoryName')} ...`}
              errorMsg={errors?.category_name?.message}
              validateStatus={errors?.category_name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('Subcategories.categoryDetails')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="category_description"
              rows={8}
              placeholder={`${t('Subcategories.categoryDetails')} ...`}
              errorMsg={errors?.category_description?.message}
              validateStatus={errors?.category_description ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        {addModal && (
          <div className="select-label-wrap">
            <p className="label-p">{t('Subcategories.mainCategory')}</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name="main_category_id"
                errorMsg={errors?.main_category_id?.message}
                validateStatus={errors?.main_category_id ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder={t('Subcategories.mainCategory')}
                options={allMainCategories}
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="settings-warehouse-form"
              />
            </div>
          </div>
        )}

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedSubCategory
            ? t('Subcategories.edit')
            : t('Subcategories.add')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
