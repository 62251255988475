import axios from 'axios';

const sendMsgTicketApi = async (token, values) => {
  try {
    const res = await axios.post('ticket-message', values, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        lagn: 'ar'
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default sendMsgTicketApi;
