/* eslint-disable eqeqeq */
import { Button, Popover, Tooltip } from 'antd';
import EditIcon from '../../common/icons/EditIcon';
import countWords from '../../utils/countWords';
import trimWords from '../../utils/trimWords';
import { ReadOutlined } from '@ant-design/icons';
import { EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import getReturnInvoiceApi from '../../apis/return-invoice-apis/getReturnInvoiceApi';
import errorNotification from '../../utils/errorNotification';
import { useContext } from 'react';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';

const TableColumns = (
  allFetchedSaleInvoices,
  setLaterInvoiceModalOpened,
  setFetchCountSaleInvoices,
  selectedInvoice,
  setSelectedInvoice,
  setIsLoadingAllSaleInvoices,
  setSelectedSaleInvoiceModalOpened,
  setReturnModalOpened,
  token,
  setLoadingInvoice
) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();
  const handleViewReceiptClick = (record) => {
    if (selectedInvoice) {
      customApiRequest(
        getReturnInvoiceApi(user?.token, record?.id, 2),
        (res) => {
          if (res?.data?.data) {
            setSelectedSaleInvoiceModalOpened(true);
            setSelectedInvoice(res?.data?.data);
            // setFetchCount((prevState) => prevState + 1);
          } else {
            errorNotification({
              title: t('Invoices.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Invoices.tryLaterMessage')
            });
          }
        },
        (error) => {}
      );
    } else {
      const foundInvoice = allFetchedSaleInvoices.find(
        (receipt) => receipt.id === record.id
      );
      if (foundInvoice) {
        setSelectedInvoice({ ...foundInvoice });
        setSelectedSaleInvoiceModalOpened(true);
      }
    }
  };

  const handleReturnClick = (record) => {
    let id =
      record?.parentInvoice != 0 && record?.parentInvoice != undefined
        ? record?.parentInvoice
        : record.id;
    customApiRequest(
      getReturnInvoiceApi(user?.token, id, 2),
      (res) => {
        setLoadingInvoice(false);
        if (res?.data?.data) {
          setReturnModalOpened(true);
          setSelectedInvoice(res?.data?.data);
          // setFetchCount((prevState) => prevState + 1);
        } else {
          errorNotification({
            title: t('Invoices.errorOccurred'),
            message: res?.data?.message
              ? res.data.message
              : t('Invoices.tryLaterMessage')
          });
        }
      },
      (error) => {
        setLoadingInvoice(false);
      }
    );
    // const foundInvoice = allFetchedSaleInvoices.find(
    //   (receipt) => receipt.id === record.id
    // );
    // if (foundInvoice) {
    //   setSelectedInvoice({ ...foundInvoice });
    //   setReturnModalOpened(true);
    // }
  };
  const renderActions = (record) => {
    if (record?.is_return === 0) {
      if (parseFloat(record?.totalPrice) == 0) {
        return (
          <div
            className="edit-btn"
            style={{
              opacity: '.6',
              cursor: 'not-allowed'
            }}
          >
            <EditIcon />
            {t('purchaseInvoices.returnRequest')}
          </div>
        );
      }
      return (
        <div className="edit-btn" onClick={() => handleReturnClick(record)}>
          <EditIcon />
          {t('purchaseInvoices.returnRequest')}
        </div>
      );
    } else if (record?.is_return === 1) {
      return (
        <div
          className="edit-btn"
          style={{
            opacity: '.6',
            cursor: 'not-allowed'
          }}
        >
          {t('Invoices.invoiceReturned')}
        </div>
      );
    } else if (record?.is_return === 2) {
      return (
        <div className="edit-btn" onClick={() => handleReturnClick(record)}>
          <EditIcon />
          {t('Invoices.ReturnRequest')}
        </div>
      );
    } else {
      return t('purchaseInvoices.invoiceReturned');
    }
  };
  // const renderActions = (record) => {
  //   if (record?.is_return === 0) {
  //     if (parseFloat(record?.totalPrice) == 0) {
  //       return (
  //         <div
  //           className="edit-btn"
  //           style={{
  //             opacity: '.6',
  //             cursor: 'not-allowed'
  //           }}
  //         >
  //           <EditIcon />
  //           {t('Invoices.ReturnRequest')}
  //         </div>
  //       );
  //     }
  //     return (
  //       <div className="edit-btn" onClick={() => handleReturnClick(record)}>
  //         <EditIcon />
  //         {t('Invoices.ReturnRequest')}
  //       </div>
  //     );
  //   } else {
  //     return t('Invoices.invoiceReturned');
  //   }
  // };

  return [
    {
      title: t('Invoices.Serial'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span>{`${record.invoiceNumber}`}</span>
          </div>
        );
      }
    },

    {
      title: t('Invoices.Employee'),
      dataIndex: 'employee',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record.shift?.employee?.name
              ? record.shift?.employee?.name
              : '---'}
          </div>
        );
      }
    },

    {
      title: t('Invoices.CustomerSerial'),
      dataIndex: 'client',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record.name ? String(record.name) : t('Invoices.NoClient')}
          </div>
        );
      }
    },

    {
      title: t('Invoices.InvoiceDate'),
      dataIndex: 'date',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.date ? record.date : '----'}
          </div>
        );
      }
    },

    {
      title: t('Invoices.Notes'),
      dataIndex: 'note',
      render: (_, record) => {
        if (record.note) {
          return (
            <div className="row-cell row-text desc-wrap">{record.note}</div>
          );
          // const wordsCount = countWords(String(record.note));
          // if (wordsCount > 3) {
          //   return (
          //     <Popover title="اقرأ المزيد" content={String(record.note)}>
          //       <Button size="large" type="text">
          //         <p className="has-read-more">
          //           <span>{trimWords(String(record.note), 4)}</span>
          //           <ReadOutlined
          //             className="read-more-book"
          //             style={{ margin: '0 4px' }}
          //           />
          //         </p>
          //       </Button>
          //     </Popover>
          //   );
          // } else
          //   return (
          //     <div className="row-cell row-text">{String(record.note)}</div>
          //   );
        } else {
          return '----';
        }
      }
    },

    {
      title: t('Invoices.TotalPrice'),
      dataIndex: 'totalPrice',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.totalPrice ? (
              <span>
                {record?.type == 2 && '-'} {record.totalPrice}
              </span>
            ) : (
              '----'
            )}
          </div>
        );
      }
    },

    {
      title: t('Invoices.PreviewInvoice'),
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            <Tooltip title={t('Invoices.PreviewInvoice')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => handleViewReceiptClick(record)}
              />
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: t('Invoices.ReturnRequest'),
      dataIndex: 'totalPrice',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">
            {record?.type == 2 &&
            record?.parentInvoice != 0 &&
            record?.parentInvoice != undefined ? (
              <div className="row-cell row-actions">
                <h3>{t('purchaseInvoices.returnedInvoice')}</h3>
              </div>
            ) : record?.type == 1 &&
              (record?.parentInvoice == 0 ||
                record?.parentInvoice == undefined) ? (
              <div className="row-cell row-actions">
                {renderActions(record)}
              </div>
            ) : null}
          </div>
        );
      }
    }
  ];
};

export default TableColumns;
