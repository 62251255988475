/* eslint-disable eqeqeq */
import React, { useRef, useState, useEffect } from 'react';
import { Button, Modal, Tooltip } from 'antd';
import SaleInvoicesContext from '../../contexts/sale-invoices-context/SaleInvoicesContext';
import { PrinterOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import './SelectedSaleInvoiceModal.scss';
import SaleInvoiceDetailsComponent from './SaleInvoiceDetailsComponent';
import { useTranslation } from 'react-i18next';
const SelectedSaleInvoiceModal = () => {
  const componentRef = useRef();
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
      @media print {
      html, body { height: initial !important; overflow: initial !important; }

      tbody { display: table-row-group !important; }
      thead {
        display: table-row-group !important;
      }
      tfoot {
        display: table-row-group !important;
      }
      @page {
        size: auto;
        margin: none;
      }
      .qr-code-wrap canvas {
        width: 94px !important;
        height: 94px !important;
      }
      .pagebreak {
        display: block;
        size: auto;
      }
      .invoice-details-wrap {
        padding: 0;
      }
      .ant-descriptions-item-content,
        .ant-descriptions-item-label {
          padding: 4px 12px !important;
        }
      }
      .ant-descriptions-header {
        margin: 4px 0 !important;
      }
      .models-title {
        direction: rtl;
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .ant-table-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
      }
      th {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      .invoice-extra-prices-table .ant-table-cell {
        padding: 4px 12px !important;
        text-align: right;
      }
    `
  });

  const {
    selectedInvoice,
    setSelectedInvoice,
    setSelectedSaleInvoiceModalOpened,
    selectedSaleInvoiceModalOpned
  } = React.useContext(SaleInvoicesContext);

  const renderModalBody = () => {
    return (
      <SaleInvoiceDetailsComponent
        ref={componentRef}
        selectedInvoice={selectedInvoice}
      />
    );
  };

  return (
    <Modal
      className="shared-custom-modal selected-inovice-modal"
      width="94%"
      style={{ maxWidth: '1172px' }}
      title={
        <>
          {t('CashiersalesInvoiceReport.salesInvoiceDetails')}
          <Tooltip title="">
            <Button
              onClick={handlePrint}
              className="circled-btn"
              type="dashed"
              shape="circle"
              icon={<PrinterOutlined />}
            />
          </Tooltip>
        </>
      }
      visible={selectedSaleInvoiceModalOpned}
      onOk={() => {
        setSelectedSaleInvoiceModalOpened(false);
        setSelectedInvoice(null);
      }}
      onCancel={() => {
        setSelectedSaleInvoiceModalOpened(false);
        setSelectedInvoice(null);
      }}
      footer={false}
    >
      {renderModalBody()}

      {/* <pre style={{ direction: 'ltr' }}>
        {JSON.stringify(selectedInvoice, null, 2)}
      </pre> */}
    </Modal>
  );
};

export default SelectedSaleInvoiceModal;
