import axios from 'axios';

const createAccountingTreeApi = async (token , client_id) => {
  // console.log(token);
  let url = `/accounting/account?client_id=${client_id}`
  try {
    const res = await axios({
      method: 'get',
      url,
      baseURL: process.env.REACT_APP_BASE_TEST_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      },
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default createAccountingTreeApi;
