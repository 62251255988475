import axios from 'axios';

// type === 1 => invoice is a normal buying invoice
// type === 2 => invoice is a return invoice
// type === 3 => invoice is a reservation invoice
// is_return === 1 => invoice of products
// today_reservations === 1 => get today reservations
// user_id === 2 => id of the client
const getAllSaleInvoicesApi = async (token, filterValues) => {
  try {
    const url = `/saleInvoice/all?type=${
      filterValues?.type ? filterValues?.type : ''
    }&is_return=${
      filterValues?.is_return ? filterValues.is_return : ''
    }&today_reservations=${
      filterValues?.today_reservations ? filterValues.today_reservations : ''
    }&user_id=${
      filterValues?.user_id ? filterValues.user_id : ''
    }&invoiceNumber=${
      filterValues?.invoiceNumber ? filterValues.invoiceNumber : ''
    }&admin_id=${filterValues?.admin_id ? filterValues.admin_id : ''}&page=${
      filterValues?.page ? filterValues.page : ''
    }&test=1`;
    const res = await axios({
      method: 'get',
      baseURL: process.env.REACT_APP_POS_BASE_URL,
      url,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllSaleInvoicesApi;
