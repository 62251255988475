/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import getAllCategoriesApi from '../apis/settings-apis/categories-apis/getAllCategoriesApis';
import UserContext from '../contexts/user-context/UserProvider';
import useCustomApiRequest from './useCustomApiRequest';
import SettingsCategoriesContext from '../contexts/settings-contexts/SettingsCategoriesProvider';

const useMainCategories = () => {
  const { user } = useContext(UserContext);
  const [allCategoreis, setAllCategories] = useState(null);
  const { fetchCount } = useContext(SettingsCategoriesContext);
  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      customApiRequest(
        getAllCategoriesApi(user?.token, 1, 0),
        (res) => {
          if (isMounted) {
            // is response is success
            if (res?.status === 200 && res?.data?.data) {
              if (res.data.data.length >= 0) {
                const data = res.data.data;
                setAllCategories(data);
              }
            } else {
              //
            }
          }
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  return allCategoreis;
};

export default useMainCategories;
