const settingsRouterLinks = {
  settingsPage: '/settings',
  settingsWorkPlacesPage: '/settings/work-places',
  settingsMeasureUnitsPage: '/settings/measure-units',
  settingsCategoriesPage: '/settings/categories',
  settingsSubCategoriesPage: (catId) => {
    if (catId) {
      return `/settings/categories/${catId}`;
    }
    return `/settings/categories/:catId`;
  },
  settingsWarehousesPage: '/settings/warehouses',
  settingsWarehousesDetailsPage: (id) => {
    if (id) return `/settings/warehouses/${id}`;
    return '/settings/warehouses/:id';
  },
  systemSettingsPage: '/settings/system-settings',
  generalSettingsPage: '/settings/general-settings',
  systemHelpPage: '/settings/help',
  systemUsersPage: '/settings/system-users',
  settingsPosPage: '/settings/view-pos',
  upgradePage: '/upgrade',
  technicalSupportPage: '/support',
  // chatSupportPage: '/chat-support',
  chatSupportPage: (id) => {
    if (id) return `/chat-support/${id}`;
    return '/chat-support/:id';
  },
};

export default settingsRouterLinks;
