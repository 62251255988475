/* eslint-disable eqeqeq */
import { PrinterOutlined } from '@ant-design/icons';
import { Button, Descriptions, Modal, Spin, Tooltip } from 'antd';
import React, { useContext, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import getSingleCustomerPayment from '../../apis/store-apis/customer-payments/getSingleCustomerPayment';
import CustomersPaymentsContext from '../../contexts/customers-payments-context/CustomersPaymentsContext';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';

const SelectedCustomerPayment = ({ systemSettings }) => {
  const {
    selectedCustomerPayment,
    setSelectedCustomerPayment,
    setCustomerPaymentModalOpened,
    customerPaymentModalOpened,
    setIsSingleLoading,
    isSingleLoading,
    selectedCustomerPaymentId
  } = React.useContext(CustomersPaymentsContext);

  const { user } = useContext(UserContext);

  const a4Ref = useRef();

  const customApiRequest = useCustomApiRequest();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsSingleLoading(true);
      customApiRequest(
        getSingleCustomerPayment(user?.token, selectedCustomerPaymentId),
        (res) => {
          setIsSingleLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            const data = res?.data?.data;
            setSelectedCustomerPayment(data);
          }
        },
        (error) => {
          setIsSingleLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const handlePrintA4 = useReactToPrint({
    content: () => a4Ref.current,
    pageStyle: `
      @media print {
        html, body { height: initial !important; overflow: initial !important; }
        // tr { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
        // td { page-break-inside:avoid; page-break-after:always; page-break-before: always; }
        tr { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
        td { page-break-inside:avoid; page-break-after:auto; page-break-before: auto; }
        tbody { display: table-row-group !important; }
        thead {
          display: table-row-group !important;
        }
        tfoot {
          display: table-row-group !important;
        }
      @page {
        // content:url('../../../assets/imgs/Watermark.png') !important;
        size: auto;
        margin: none;
      }
      .qr-code-wrap canvas {
        width: 128px !important;
        height: 128px !important;
      }
      .pagebreak {
        display: block;
        size: auto;
      }
      .invoice-details-wrap {

        padding: 0;
      }
      .ant-descriptions-item-content,
        .ant-descriptions-item-label {
          padding: 4px 12px !important;
        }
      }
      .ant-descriptions-header {
        margin: 4px 0 !important;
      }
      .models-title {
        direction: rtl;
        color: rgba(0, 0, 0, 0.9);
        font-weight: bold;
        fontSize: 16px;
        margin-top: 14px;
        margin-bottom: 8px;
      }
      .ant-table-wrapper {
        border-top: 1px solid rgba(0, 0, 0, 0.08);
      }
      th {
        color: rgba(0, 0, 0, 0.85) !important;
      }
      .invoice-extra-prices-table .ant-table-cell {
        padding: 4px 12px !important;
        text-align: right;
      }
    `
  });

  const renderModalBody = () => {
    return (
      <div
        className="report-modal-content"
        ref={a4Ref}
        style={{ padding: '24px', minHeight: '400px' }}
      >
        <Descriptions bordered column={1}>
          {selectedCustomerPayment?.user?.name && (
            <Descriptions.Item
              label="إســـم العميل"
              style={{ height: '50px', fontWeight: '500', fontSize: '18px' }}
            >
              {selectedCustomerPayment?.user?.name}
            </Descriptions.Item>
          )}
          {selectedCustomerPayment?.user?.phone && (
            <Descriptions.Item
              label="رقم الهاتف"
              style={{ height: '50px', fontWeight: '500', fontSize: '18px' }}
            >
              {selectedCustomerPayment?.user?.phone}
            </Descriptions.Item>
          )}
          {selectedCustomerPayment?.user?.email && (
            <Descriptions.Item
              label="البريد الإلكتروني"
              style={{ height: '50px', fontWeight: '500', fontSize: '18px' }}
            >
              {selectedCustomerPayment?.user?.email}
            </Descriptions.Item>
          )}
          {selectedCustomerPayment?.amount && (
            <Descriptions.Item
              label="المبلغ"
              style={{ height: '50px', fontWeight: '500', fontSize: '18px' }}
            >
              {selectedCustomerPayment?.amount}
            </Descriptions.Item>
          )}
          {selectedCustomerPayment?.user?.balance && (
            <Descriptions.Item
              label="الرصيد"
              style={{ height: '50px', fontWeight: '500', fontSize: '18px' }}
            >
              {selectedCustomerPayment?.user?.balance}
            </Descriptions.Item>
          )}
          {selectedCustomerPayment?.user?.paid && (
            <Descriptions.Item
              label="المدفوع"
              style={{ height: '50px', fontWeight: '500', fontSize: '18px' }}
            >
              {selectedCustomerPayment?.user?.paid}
            </Descriptions.Item>
          )}
          {selectedCustomerPayment?.user?.unpaid && (
            <Descriptions.Item
              label="المستحق من العميل"
              style={{ height: '50px', fontWeight: '500', fontSize: '18px' }}
            >
              {selectedCustomerPayment?.user?.unpaid}
            </Descriptions.Item>
          )}
          {selectedCustomerPayment?.notes && (
            <Descriptions.Item
              label="الملاحظات"
              style={{ height: '50px', fontWeight: '500', fontSize: '18px' }}
            >
              {selectedCustomerPayment?.notes}
            </Descriptions.Item>
          )}
        </Descriptions>

        {/* <Descriptions
          style={{ marginTop: 14 }}
          title="بيانات نقطة البيع"
          bordered
          column={1}
        >
          {posReport.pos?.name && (
            <Descriptions.Item label="نقطة البيع">
              {posReport.pos.name}
            </Descriptions.Item>
          )}
          {posReport.pos?.balance && (
            <Descriptions.Item label="الحســاب الكلى">
              {posReport.pos.balance}
            </Descriptions.Item>
          )}
          {posReport.pos?.cash && (
            <Descriptions.Item label="حساب الكاش">
              {posReport.pos.cash}
            </Descriptions.Item>
          )}
          {posReport.pos?.visa && (
            <Descriptions.Item label="حساب الفيزا">
              {posReport.pos.visa}
            </Descriptions.Item>
          )}
        </Descriptions>

        <Descriptions
          style={{ marginTop: 14 }}
          title="بيانات الشيفت"
          bordered
          column={1}
        >
          {posReport.start_shift && (
            <Descriptions.Item label="بداية الشيفت">
              {posReport.start_shift}
            </Descriptions.Item>
          )}
          {posReport.end_shift && (
            <Descriptions.Item label="نهاية الشيفت">
              {posReport.end_shift}
            </Descriptions.Item>
          )}
          {posReport.cash && (
            <Descriptions.Item label="الكـــاش">
              {posReport.cash}
            </Descriptions.Item>
          )}
          {posReport.visa && (
            <Descriptions.Item label="الفـيزا">
              {posReport.visa}
            </Descriptions.Item>
          )}
        </Descriptions> */}
      </div>
    );
  };

  const renderModalTitle = () => {
    return 'تفاصيل مدفوعات العميل';
  };

  return (
    <Modal
      className="shared-custom-modal selected-inovice-modal"
      width="90%"
      style={{ maxWidth: '942px' }}
      title={
        <>
          <div className="title-wrapper">
            {renderModalTitle()}
            <div className="title-wrapper-icons">
              <Tooltip title="Print A4">
                <Button
                  onClick={handlePrintA4}
                  className="circled-btn"
                  type="dashed"
                  shape="circle"
                  icon={<PrinterOutlined />}
                />
              </Tooltip>
            </div>
          </div>
        </>
      }
      visible={customerPaymentModalOpened}
      onOk={() => {
        setCustomerPaymentModalOpened(false);
        setSelectedCustomerPayment(null);
      }}
      onCancel={() => {
        setCustomerPaymentModalOpened(false);
        setSelectedCustomerPayment(null);
      }}
      footer={false}
    >
      {isSingleLoading ? (
        <div
          style={{
            height: '400px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Spin />
        </div>
      ) : (
        renderModalBody()
      )}
    </Modal>
  );
};

export default SelectedCustomerPayment;
