import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import SettingsSubCategoriesContext from '../../../contexts/settings-contexts/SettingsSubCategoriesProvider';
import { useTranslation } from 'react-i18next';

const PageModal = ({ addModal }) => {
  const {
    modalOpened,
    setModalOpened,
    setSelectedSubCategory,
    selectedSubCategory
  } = useContext(SettingsSubCategoriesContext);
  const { t } = useTranslation();
  return (
    <Modal
      transitionName=""
      className="shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        selectedSubCategory
          ? t('Subcategories.editProductCategory')
          : t('Subcategories.addNewProductCategory')
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedSubCategory(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedSubCategory(null);
      }}
      footer={false}
    >
      <TableForm addModal={addModal} />
    </Modal>
  );
};

export default PageModal;
