/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import addNewUserApi from '../../../apis/settings-apis/users-apis/addNewUserApi';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import SettingsSystemUsersContext from '../../../contexts/settings-contexts/SettingsSystemUsersProvider';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import usePos from '../../../custom-hooks/usePos';
import roles from '../../../roles';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';

const TableForm = () => {
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedUser,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(SettingsSystemUsersContext);
  const allPos = usePos();
  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  let schema;
  if (!selectedUser) {
    schema = Yup.object().shape({
      name: Yup.string().required(t('Users.Name')),
      // username: Yup.string().required('ادخل اسم المستخدم'),
      phone: Yup.string()
        .required(t('Users.EnterPhoneNumber'))
        .matches(/^[0-9]+$/, t('Users.NumericRestriction'))
        .min(9, t('Users.rangeValidation')),
      email: Yup.string()
        .required(t('Users.EnterEmail'))
        .email(t('Users.InvalidInput')),
      // password: Yup.string()
      //   .required('ادخل كلمة المرور')
      //   .min(6, 'كلمة المرور لا تقل عن 6 حروف')
      //   .trim('لا يسمح بالفراغات')
      //   // .trim("No leading or trailing spaces")
      //   .strict(),
      // confirm_password: Yup.string()
      //   .required('اعد كلمة المرور')
      //   .oneOf([Yup.ref('password')], 'كلمة المرور غير مطابقة'),
      role: Yup.string().required(t('Users.SelectUserPermission')),
      pos: Yup.string().test(
        'pos',
        t('SystemSettings.pointOfSaleValidation'),
        (v, context) => {
          let result = true;
          if (context.parent.role == '5' && !v) result = false;
          return result;
        }
      )
    });
  } else {
    schema = Yup.object().shape({
      name: Yup.string().required(t('Users.enterName')),
      // username: Yup.string().required('ادخل اسم المستخدم'),
      phone: Yup.number()
        .required(t('Users.EnterPhoneNumber'))
        .typeError(t('Users.NumericRestriction')),
      email: Yup.string()
        .required(t('Users.EnterEmail'))
        .email(t('Users.InvalidInput')),
      role: Yup.string().required(t('Users.SelectUserPermission')),
      pos: Yup.string().test(
        'pos',
        t('Users.pointOfSaleValidation'),
        (v, context) => {
          let result = true;
          if (context.parent.role == '5' && !v) result = false;
          return result;
        }
      )
    });
  }

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      // username: '',
      phone: '',
      email: '',
      // password: '',
      // confirm_password: '',
      role: '',
      pos: ''
    }
  });
  const [passwrodVisible, setPasswordVisible] = useState(false);

  const customApiRequest = useCustomApiRequest();

  const onSubmit = async (data) => {
    const apiData = {
      name: data.name,
      // username: data.username,
      phone: data.phone,
      email: data.email,
      role: data.role,
      password: data.password,
      pos: data.pos
      // status: 2
    };

    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedUser) {
      customApiRequest(
        addNewUserApi(user?.token, { ...apiData, id: selectedUser?.id }, true),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Messages.addedSuccessfully'),

              message: t('Messages.Modifiedsuccessfully')
            });
          } else {
            errorNotification({
              title: t('Messages.ErrorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Messages.TryAgainLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message:
              error?.response?.data?.message || t('Messages.TryAgainLater')
          });
        }
      );
    } else {
      customApiRequest(
        addNewUserApi(user?.token, { ...apiData }),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Messages.addedSuccessfully'),
              message: t('Messages.addsuccessfully')
            });
          } else {
            errorNotification({
              title: t('Messages.ErrorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Messages.TryAgainLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message:
              error?.response?.data?.message || t('Messages.TryAgainLater')
          });
        }
      );
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedUser) {
      setValue('name', selectedUser?.name ? selectedUser.name : '');
      // setValue('username', selectedUser?.username ? selectedUser.username : '');
      setValue('phone', selectedUser?.phone ? selectedUser.phone : '');
      setValue('email', selectedUser?.email ? selectedUser.email : '');
      setValue('password', selectedUser?.password ? selectedUser.password : '');
      setValue('role', selectedUser?.role ? String(selectedUser.role) : '');
      setValue('pos', selectedUser?.pos_id ? String(selectedUser.pos_id) : '');
    }
  }, [selectedUser, selectedUser?.id]);

  const [form] = Form.useForm();
  return (
    <Form
      className="system-users-form custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Users.Name')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="name"
              type="text"
              placeholder={t('Users.Name') + '...'}
              errorMsg={errors?.name?.message}
              validateStatus={errors?.name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        {/* <div className="text-field-label-wrap">
          <p className="label-p">اســم المستخدم</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="username"
              type="text"
              placeholder="اسـم المستخدم..."
              errorMsg={errors?.username?.message}
              validateStatus={errors?.username ? 'error' : ''}
              control={control}
            />
          </div>
        </div> */}
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Users.PhoneNumber')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="phone"
              type="text"
              placeholder={t('Users.PhoneNumber') + '...'}
              errorMsg={errors?.phone?.message}
              validateStatus={errors?.phone ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('Users.Email')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="email"
              type="text"
              placeholder={t('Users.Email') + '...'}
              errorMsg={errors?.email?.message}
              validateStatus={errors?.email ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        {/* <div className="system-users-passwords-wrap">
          <div className="text-field-label-wrap">
            <p className="label-p">كلمة المرور</p>
            <div className="text-field-wrap with-eye-icon">
              <AntdTextField
                className="form-text-field"
                name="password"
                type={passwrodVisible ? 'text' : 'password'}
                placeholder="كلمة المرور..."
                errorMsg={errors?.password?.message}
                validateStatus={errors?.password ? 'error' : ''}
                control={control}
              />

              {watch('password') && (
                <div
                  className="eye-icon-btn"
                  onClick={() => {
                    setPasswordVisible((prevState) => !prevState);
                  }}
                >
                  {passwrodVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
              )}
            </div>
          </div>

          <div className="text-field-label-wrap">
            <p className="label-p">تأكيد كلمة المرور</p>
            <div className="text-field-wrap with-eye-icon">
              <AntdTextField
                className="form-text-field"
                name="confirm_password"
                type={passwrodVisible ? 'text' : 'password'}
                placeholder="تأكيد كلمة المرور..."
                errorMsg={errors?.confirm_password?.message}
                validateStatus={errors?.confirm_password ? 'error' : ''}
                control={control}
              />

              {watch('confirm_password') && (
                <div
                  className="eye-icon-btn"
                  onClick={() => {
                    setPasswordVisible((prevState) => !prevState);
                  }}
                >
                  {passwrodVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </div>
              )}
            </div>
          </div>
        </div> */}

        <div className="roles-pos-wrap">
          <div className="select-label-wrap">
            <p className="label-p">{t('Users.UserPermission')}</p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name="role"
                errorMsg={errors?.role?.message}
                validateStatus={errors?.role ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder={t('Users.UserPermission')}
                options={roles}
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                formClassName="system-users-form"
              />
            </div>
          </div>
          {watch('role') == '5' && (
            <div className="select-label-wrap">
              <p className="label-p">{t('Users.userPointOfSale')}</p>
              <div className="custom-select-wrap without-icon">
                <AntdSelectOption
                  name="pos"
                  errorMsg={errors?.pos?.message}
                  validateStatus={errors?.pos ? 'error' : ''}
                  control={control}
                  setValue={setValue}
                  placeholder={t('Users.userPointOfSale')}
                  options={
                    allPos?.length > 0
                      ? allPos.map((p) => ({
                          title: p.name ? p.name : '----',
                          value: p.id ? p.id : ''
                        }))
                      : null
                  }
                  showSearch={true}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  formClassName="system-users-form"
                />
              </div>
            </div>
          )}
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedUser ? t('Users.edit') : t('Users.add')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
