import axios from 'axios';

// type === 1 => invoice is a normal buying invoice
// type === 2 => invoice is a return invoice
// type === 3 => invoice is a reservation invoice
// is_return === 1 => invoice of products
// today_reservations === 1 => get today reservations
// user_id === 2 => id of the client
const getAllReservationsApi = async (token, filterValues) => {
  try {
    const url = `/SaleInvoice/all?type=${
      filterValues?.type ? filterValues?.type : ''
    }&is_return=${
      filterValues?.is_return ? filterValues.is_return : ''
    }&today_reservations=${
      filterValues?.today_reservations ? filterValues.today_reservations : ''
    }&user_id=${filterValues?.user_id ? filterValues.user_id : ''}`;
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllReservationsApi;
