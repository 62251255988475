/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Descriptions, Modal, Table, Spin, Empty } from 'antd';
import StoreNewProductModalContext from '../../../contexts/store-new-product-context/StoreNewProductProvider';
import parse from 'html-react-parser';
import FileSaver from 'file-saver';
import UserContext from '../../../contexts/user-context/UserProvider';
import getSingleProductApi from '../../../apis/store-apis/products/getSingleProductApi';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import './SelectedProudctModal.scss';
import checkRes from '../../../utils/checkRes';
import { useTranslation } from 'react-i18next';
const saveFile = (url, title) => {
  FileSaver.saveAs(url, title);
};
const SelectedProductModal = () => {
  const [requestLoading, setRequestLoading] = React.useState(false);
  const { user } = useContext(UserContext);
  const {
    selectedProduct,
    setSelectedProduct,
    selectedProductModalOpened,
    setSelectedProductModalOpened,
    setSelectedProductId,
    selectedProductId
  } = React.useContext(StoreNewProductModalContext);
  const { t } = useTranslation();
  const customApiRequest = useCustomApiRequest();
  React.useEffect(() => {
    if (selectedProductModalOpened) {
      setRequestLoading(true);
      customApiRequest(
        getSingleProductApi(selectedProductId, user?.token),
        (res) => {
          if (checkRes(res) && res?.data?.data) {
            setRequestLoading(false);
            setSelectedProduct({ ...res.data.data });
          }
        },
        (error) => {
          setRequestLoading(false);
        }
      );
    }
  }, [selectedProductModalOpened]);

  if (selectedProduct && !requestLoading) {
    const renderMaterialsTable = (arr) => {
      return (
        <Table
          locale={{
            emptyText: <Empty description={false}>{t('services.noData')}</Empty>
          }}
          pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
          className="antd-custom-table"
          dataSource={selectedProduct?.productData?.materials.map(
            (material) => ({
              ...material,
              key: material.id
            })
          )}
          columns={[
            {
              title: t('services.componentCode'),
              dataIndex: selectedProduct?.productData?.id,
              render: (_, record) => {
                return (
                  <div className="row-cell row-index bold-font">
                    <span># {record.id}</span>
                  </div>
                );
              }
            },
            {
              title: t('services.componentName'),
              dataIndex: selectedProduct?.productData?.name,
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>{record.name ? record.name : '-----'}</span>
                  </div>
                );
              }
            },
            // {
            //   title: 'الكمية',
            //   dataIndex: selectedProduct?.productData?.quantity,
            //   render: (_, record) => {
            //     return (
            //       <div className="row-cell row-text">
            //         <span>{record.quantity ? record.quantity : '-----'}</span>
            //       </div>
            //     );
            //   }
            // },
            {
              title: t('services.componentPrice'),
              dataIndex: 'price',
              render: (_, record) => {
                return (
                  <div className="row-cell row-text">
                    <span>{record.price ? record.price : '-----'}</span>
                  </div>
                );
              }
            }
            // {
            //   title: 'الفئة',
            //   dataIndex: 'cat',
            //   render: (_, record) => {
            //     return (
            //       <div className="row-cell row-text">
            //         <span>{record.cat?.name ? record.cat.name : '-----'}</span>
            //       </div>
            //     );
            //   }
            // }
          ]}
          // loading={isLoading}
          // scroll={{ x: 400 }}
          footer={false}
        />
      );
    };

    const renderModalBody = () => {
      return (
        <div className="product-details-wrap">
          <Descriptions
            title={t('services.serviceDetails')}
            bordered
            column={1}
          >
            {selectedProduct?.productData?.id && (
              <Descriptions.Item label={t('services.code')}>
                {selectedProduct?.productData?.id}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.name && (
              <Descriptions.Item label={t('services.serviceName')}>
                {selectedProduct?.productData?.name}
              </Descriptions.Item>
            )}
            {/* {selectedProduct?.productData?.cat?.parent && (
              <Descriptions.Item label="الفئة الرئيسية">
                {selectedProduct?.productData?.cat?.parent}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.cat?.name && (
              <Descriptions.Item label="الفئة الفرعية">
                {selectedProduct?.productData?.cat?.name}
              </Descriptions.Item>
            )}
            {String(selectedProduct?.productData?.quantity) && (
              <Descriptions.Item label="كمية الخدمة">
                {selectedProduct?.productData?.quantity}
              </Descriptions.Item>
            )}
            {String(selectedProduct?.productData?.unit?.name) && (
              <Descriptions.Item label="وحدة القياس">
                {selectedProduct?.productData?.unit?.name}
              </Descriptions.Item>
            )}
            {String(selectedProduct?.productData?.buyPrice) && (
              <Descriptions.Item label="سعر الشراء">
                {selectedProduct?.productData?.buyPrice}
              </Descriptions.Item>
            )} */}
            {String(selectedProduct?.productData?.salePrice) && (
              <Descriptions.Item label={t('services.sellingPrice')}>
                {parseFloat(selectedProduct?.productData?.salePrice).toFixed(2)}
              </Descriptions.Item>
            )}
             {String(selectedProduct?.productData?.tax) && (
              <Descriptions.Item label={t('services.tax')}>
                {selectedProduct?.productData?.tax}
              </Descriptions.Item>
            )}
             {String(selectedProduct?.productData?.salePriceWithTax) && (
              <Descriptions.Item label={t('services.salePriceWithTax')}>
                {selectedProduct?.productData?.salePriceWithTax}
              </Descriptions.Item>
            )} 
           
              {String(selectedProduct?.productData?.including_tax) && (
              <Descriptions.Item label={t('services.serviceWithTax')}>
                {selectedProduct?.productData?.including_tax == 0 ? t('services.no')  : selectedProduct?.productData?.including_tax == 1 ? t('services.yes') :''} 
              </Descriptions.Item>
            )}
            {/* {String(selectedProduct?.productData?.wholesalePrice) && (
              <Descriptions.Item label="سعر الجملة">
                {selectedProduct?.productData?.wholesalePrice}
              </Descriptions.Item>
            )}
            {String(selectedProduct?.productData?.alarmQuantity) && (
              <Descriptions.Item label="كمية التنبيه">
                {selectedProduct?.productData?.alarmQuantity}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.defaultParCode && (
              <Descriptions.Item label="default barcode">
                {selectedProduct?.productData?.defaultParCode}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.parCode && (
              <Descriptions.Item label="Generated barcode">
                {selectedProduct?.productData?.parCode}
              </Descriptions.Item>
            )} */}
            {selectedProduct?.productData?.parCode && (
              <Descriptions.Item label={t('services.serviceDescription')}>
                {parse(selectedProduct?.productData?.desc)}
              </Descriptions.Item>
            )}
            {selectedProduct?.productData?.parCode && (
              <Descriptions.Item label={t('services.serviceImage')}>
                <img
                  style={{ maxHeight: 182, objectFit: 'contain' }}
                  src={selectedProduct?.productData?.image}
                  alt={selectedProduct?.productData?.name}
                  onClick={() =>
                    saveFile(
                      selectedProduct?.productData?.image,
                      selectedProduct?.productData?.name
                    )
                  }
                />
              </Descriptions.Item>
            )}
          </Descriptions>

          {selectedProduct?.productData?.type === 2 &&
            selectedProduct?.productData?.materials?.length > 0 &&
            renderMaterialsTable(selectedProduct?.productData?.materials)}
        </div>
      );
    };

    return (
      <Modal
        className="shared-custom-modal selected-product-modal"
        width="90%"
        style={{ maxWidth: '942px' }}
        title={t('services.serviceDetails')}
        visible={selectedProductModalOpened}
        onOk={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        onCancel={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        footer={false}
      >
        {renderModalBody()}
        {/* <pre style={{ direction: 'ltr' }}>
        {JSON.stringify(selectedProduct, null, 2)}
      </pre> */}
      </Modal>
    );
  } else if (requestLoading && !selectedProduct)
    return (
      <Modal
        className="shared-custom-modal selected-product-modal"
        width="90%"
        style={{ maxWidth: '942px' }}
        title={t('services.serviceDetails')}
        visible={selectedProductModalOpened}
        onOk={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        onCancel={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        footer={false}
      >
        <div style={{ height: 232, display: 'grid', placeItems: 'center' }}>
          <Spin />
        </div>
      </Modal>
    );
  else {
    return (
      <Modal
        className="shared-custom-modal selected-product-modal"
        width="90%"
        style={{ maxWidth: '942px' }}
        title={t('services.serviceDetails')}
        visible={selectedProductModalOpened}
        onOk={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        onCancel={() => {
          setSelectedProductModalOpened(false);
          setSelectedProduct(null);
          setSelectedProductId('');
        }}
        footer={false}
      >
        <div style={{ height: 132, display: 'grid', placeItems: 'center' }}>
          {t('services.noData')}
        </div>
      </Modal>
    );
  }
};

export default SelectedProductModal;
