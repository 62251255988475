/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Button } from 'antd';
import React, { useContext } from 'react';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import './ProductIngredientsForm.scss';

const ProductIngredientsForm = () => {
  const {
    selectedInvoice,
    foundProduct,
    setFoundProduct,
    setSelectedProducts,
    setproductIngredientsModalOpened
  } = useContext(PosInvoiceContext);
  const handleCheckboxChange = (ingredient, e) => {
    // check if the ingredient in ingredients arr
    // if exists then remove it
    // if not exists then push it

    if (foundProduct?.productIngredients?.length >= 0) {
      // const foundIng = foundProduct.productIngredients.find(
      //   (obj) => obj?.id == ingredient?.id
      // );
      setFoundProduct((prev) => {
        return {
          ...prev,
          productIngredients: prev.productIngredients.map((ing) => {
            if (ing.id === ingredient.id) {
              return {
                ...ing,
                ingredientInCartQuantity: ing.checked
                  ? 0
                  : ing.ingredientInCartQuantity,
                checked: !ing.checked
              };
            } else {
              return {
                ...ing
              };
            }
          })
        };
      });
    }
  };

  const handleQuantityChange = (ingredient, e) => {
    setFoundProduct((prev) => {
      return {
        ...prev,
        productIngredients: prev.productIngredients.map((ing) => {
          if (ing.id === ingredient.id)
            return {
              ...ing,
              ingredientInCartQuantity:
                e.target.value && !isNaN(e.target.value) ? e.target.value : '',
              checked: true
            };
          else {
            return ing;
          }
        })
      };
    });
  };

  const handleQuantityChangeSelectedInvoice = (ingredient, e) => {
    setFoundProduct((prev) => {
      return {
        ...prev,
        productIngredients: prev.productIngredients.map((ing) => {
          const foundP =
            selectedInvoice.products?.length > 0 &&
            selectedInvoice.products.find((fp) => fp?.product?.id === prev.id);
          const foundIng =
            foundP?.materials?.length > 0 &&
            foundP.materials.find((o) => o.model_id === ingredient.id);
          if (ing.id === ingredient.id) {
            if (
              e.target.value === '' ||
              (parseFloat(e.target.value) >= 0 &&
                parseFloat(e.target.value) <= parseFloat(foundIng.quantity))
            ) {
              return {
                ...ing,
                ingredientInCartQuantity:
                  e.target.value === '' ? 0 : parseFloat(e.target.value),
                checked: true
              };
            } else {
              return {
                ...ing,
                ingredientInCartQuantity: foundIng.quantity,
                checked: true
              };
            }
          } else {
            return ing;
          }
        })
      };
    });
  };

  const handleChecked = (ing) => {
    if (foundProduct?.productIngredients?.length > 0) {
      const foundIng = foundProduct.productIngredients.find(
        (item) => item?.id == ing?.id
      );
      if (foundIng?.checked) return true;
    }
    return false;
  };

  const handleQuantityValue = (ing) => {
    const found = foundProduct.productIngredients.find((i) => i.id == ing.id);
    if (found && found.checked) {
      return found.ingredientInCartQuantity;
    } else return '';
  };

  const renderProductIngredients = () => {
    return (
      <div className="checkboxs-button-wrap">
        {foundProduct?.productIngredients?.length > 0 &&
          foundProduct.productIngredients.map((ing) => (
            <div key={ing.id} className="checkbox-quantity-wrap">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  value={ing?.id}
                  name="ingredient_quantity"
                  onChange={(e) => handleCheckboxChange(ing, e)}
                  checked={handleChecked(ing)}
                />
                <span className="label-span">{ing?.name}</span>
              </label>

              <label className="quantity-label">
                <span className="label-span">الكـــمية :</span>
                <input
                  min={0.1}
                  step={0.1}
                  type="number"
                  value={handleQuantityValue(ing)}
                  onChange={(e) =>
                    !selectedInvoice
                      ? handleQuantityChange(ing, e)
                      : handleQuantityChangeSelectedInvoice(ing, e)
                  }
                />
              </label>
            </div>
          ))}
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          onClick={() => {
            setproductIngredientsModalOpened(false);
            setFoundProduct(null);
          }}
          // icon={<LoginOutlined />}
          // loading={isSubmitting}
        >
          حفــظ
        </Button>
      </div>
    );
  };

  React.useEffect(() => {
    if (setSelectedProducts?.length > 0) {
      setSelectedProducts((prev) =>
        prev.map((p) =>
          p?.id === foundProduct?.id ? { ...foundProduct } : { ...p }
        )
      );
    }
  }, [foundProduct]);

  return (
    <div className="product-ingredients-wrap">
      {/* <div className="checkboxs-label">إختار الاضافات</div> */}
      {renderProductIngredients()}
    </div>
  );
};

export default ProductIngredientsForm;
