import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import { Button, Popconfirm, Tooltip } from 'antd';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import deletePurchaseOrderApi from '../../../apis/store-apis/purchase-order-apis/deletePurchaseOrderApi';
import { EyeOutlined } from '@ant-design/icons';

const tableColumns = (
  allFetchedOrders,
  setModalOpened,
  setFetchCount,
  setSelectedOrder,
  setIsLoading,
  setSelectedOrderModalOpened,
  t,
  token
) => {
  const handleEditClick = (record) => {
    const found = allFetchedOrders.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedOrder({ ...found });
      setModalOpened(true);
    }
  };

  const handleViewPurchaseOrder = (record) => {
    const foundObj = allFetchedOrders.find((obj) => obj.id === record.id);
    if (foundObj) {
      setSelectedOrder({ ...foundObj });
      setSelectedOrderModalOpened(true);
    }
  };
  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deletePurchaseOrderApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('PurchaseOrder.operationCompletedSuccessfully'),
          message: t('PurchaseOrder.orderDeletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('PurchaseOrder.operationEroor'),
          message: res?.data?.message
            ? res.data.message
            : t('PurchaseOrder.tryLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('PurchaseOrder.errorOccurred'),
        message: t('PurchaseOrder.tryLater')
      });
    }
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(record)}>
          <EditIcon />
          {t('PurchaseOrder.update')}
        </div>
        <Popconfirm
          title={t('PurchaseOrder.deleteConfirm')}
          onConfirm={() => handleDeleteRequest(record.id)}
          okText={t('PurchaseOrder.yes')}
          cancelText={t('PurchaseOrder.no')}
        >
          <Tooltip title={t('PurchaseOrder.delete')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      title: t('PurchaseOrder.code'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('PurchaseOrder.supplierName'),
      dataIndex: 'supplier_name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text desc-wrap">
            <span className="name-it-self">{record?.supplier?.name}</span>
          </div>
        );
      },
      sorter: (a, b) => {
        let nameA = a.supplier?.name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.supplier?.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      }
    },
    {
      title: t('PurchaseOrder.dueDateCell'),
      dataIndex: 'due_date',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record.dueDate}</span>
          </div>
        );
      },
      sorter: (a, b) => new Date(a.dueDate) - new Date(b.dueDate)
    },
    {
      title: t('PurchaseOrder.orderPreview'),
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            <Tooltip title={t('PurchaseOrder.orderPreview')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => handleViewPurchaseOrder(record)}
              />
            </Tooltip>
          </div>
        );
      }
    },
    {
      title: t('PurchaseOrder.settings'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
