import React, { useContext } from 'react';
import PageTable from './PageTable';
import TableFilter from './TableFilter';
import './ClientsPage.scss';
import ClientsPosContext from '../../contexts/clients-context-pos/ClientsPosContext';
import PagePosModal from './PagePosModal';
import SharedLayoutBtns from '../../components/app/pos-section/SharedLayoutBtns';
import NotificationsProfile from '../../components/app/pos-section/notifications-profile/NotificationsProfile';
import { useTranslation } from 'react-i18next';

const ClientsPosPage = () => {
  const { modalOpened, setModalOpened, setSelectedClient } =
    useContext(ClientsPosContext);
  const { t } = useTranslation();

  return (
    <>
      <div className="settings-system-users-page">
        <div className="pos-navbar">
          <SharedLayoutBtns />
          <NotificationsProfile />
        </div>
        <div className="page-head-wrap">
          <div className="page-title">
            <h2 className="bold-font">{t('Clients.Clients')}</h2>
            {/* <p>كشف وعرض كافة العمـــلاء</p> */}
          </div>
        </div>

        <div className="custom-filter-add-section">
          <div className="add-section">
            <div
              className="add-btn"
              onClick={() => {
                // if selected unit then reset it
                // open the unit modal
                setSelectedClient(null);
                setModalOpened(true);
              }}
            >
              <span className="text-span">{t('Customers.AddNewCustomer')}</span>
            </div>
          </div>
          <TableFilter />
        </div>

        <PageTable />

        {/* {modalOpened && <PagePosModal />} */}
      </div>
    </>
  );
};

export default ClientsPosPage;
