import axios from 'axios';

const addNewWarehouseApi = async (token, values, edit = false) => {
  const formData = new FormData();
 
  //const mappedValues = {};
  if (values.warehouse_name)  formData.append('name', values.warehouse_name); // mappedValues.name = values.warehouse_name;
  if (values.warehouse_description)
  formData.append('note', values.warehouse_description); // mappedValues.note = values.warehouse_description;
  if (values.warehouse_work_place)
  formData.append('workPlace_id',values.warehouse_work_place);  // mappedValues.workPlace_id = values.warehouse_work_place;
  if (values.stock_id) formData.append('stock_id', values.stock_id); // mappedValues.stock_id = values.stock_id;

  try {
    let url;
    if (!edit) url = '/Stock/create';
    else url = '/Stock/update';
    const res = await axios.post(url, formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default addNewWarehouseApi;
