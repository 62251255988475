/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import SettingsWorkPlacesContext from '../../../contexts/settings-contexts/SettingsWorkPlacesProvider';
import addNewWorkPlaceApi from '../../../apis/settings-apis/work-places-apis/addNewWorkPlaceApi';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useTranslation } from 'react-i18next';

const TableForm = () => {
  const {
    selectedPlace,
    setIsLoading,
    setFetchCount,
    setModalOpened,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(SettingsWorkPlacesContext);
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const schema = Yup.object().shape({
    place_name: Yup.string()
      .required(t('Inventory.WorkPlaces.enterNamePlace'))
      .max('100', t('Inventory.Products.maxProductNameLength')),
    place_address: Yup.string().required(
      t('Inventory.WorkPlaces.enterPlaceAddress')
    ),
    address_url: Yup.string().required(
      t('Inventory.WorkPlaces.addressUrlValidation')
    )
    // tax_deduction_rate
  });
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      place_name: '',
      place_address: '',
      address_url:''
    }
  });

  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    setIsSubmittingForm(true);
    setIsLoading(true);

    if (selectedPlace) {
      customApiRequest(
        addNewWorkPlaceApi(
          user?.token,
          {
            ...data,
            WorkPalace_id: selectedPlace.id
          },
          true
        ),

        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Inventory.WorkPlaces.addedSuccessfully'),
              message: t('Inventory.WorkPlaces.workplaceModifiedSuccessfully')
            });
          } else {
            setIsLoading(false);
            errorNotification({
              title: t('Inventory.WorkPlaces.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Inventory.WorkPlaces.pleaseTryLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('Inventory.WorkPlaces.errorOccurred'),
            message:
              error?.response?.data?.message ||
              t('Inventory.WorkPlaces.pleaseTryLater')
          });
        }
      );
    } else {
      customApiRequest(
        addNewWorkPlaceApi(user?.token, data),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Inventory.WorkPlaces.addedSuccessfully'),
              message: t('Inventory.WorkPlaces.workplaceAddedSuccessfully')
            });
          } else {
            setIsLoading(false);
            errorNotification({
              title: t('Inventory.WorkPlaces.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Inventory.WorkPlaces.pleaseTryLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('Inventory.WorkPlaces.errorOccurred'),
            message:
              error?.response?.data?.message ||
              t('Inventory.WorkPlaces.pleaseTryLater')
          });
        }
      );
    }
  };

  // handle initial values
  useEffect(() => {
    if (selectedPlace) {
      setValue('place_name', selectedPlace?.name ? selectedPlace.name : '');
      setValue(
        'place_address',
        selectedPlace?.address ? selectedPlace.address : ''
      );
      setValue('address_url', selectedPlace?.address_url ? selectedPlace.address_url : '');
    }
  }, [selectedPlace, selectedPlace?.id]);

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.WorkPlaces.workPlaceName')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="place_name"
              type="text"
              placeholder={t('Inventory.WorkPlaces.workPlaceNamPlaceholder')}
              errorMsg={errors?.place_name?.message}
              validateStatus={errors?.place_name ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.WorkPlaces.placeAddress')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="place_address"
              type="text"
              placeholder={t('Inventory.WorkPlaces.placeAddressPlaceholder')}
              errorMsg={errors?.place_address?.message}
              validateStatus={errors?.place_address ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.WorkPlaces.addressUrl')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="address_url"
              type="text"
              placeholder={t('Inventory.WorkPlaces.addressUrlPlaceholder')}
              errorMsg={errors?.address_url?.message}
              validateStatus={errors?.address_url ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedPlace
            ? t('Inventory.WorkPlaces.edit')
            : t('Inventory.WorkPlaces.add')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
