/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import { useParams } from 'react-router-dom';
import AccountingDetailsContext from '../../../contexts/accounting-context/AccountingDetailsContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import getAccountingDetails from '../../../apis/accounting-apis/accounting-details-apis/getAccountingDetailsApi';
import AccountingDetailsTable from './AccountingDetailsTable';
import AccountingDetailsModal from './AccountingDetailsModal';
// import useAccountingLeaf from '../../../custom-hooks/useAccountingLeaf';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import checkRes from '../../../utils/checkRes';
import './AccountingDetailsPage.scss';

const AccountingDetailsPage = () => {
  const { user } = useContext(UserContext);
  const {
    setIsLoading,
    fetchCount,
    setFetchedAccountingDetails,
    fetchedAccountingDetails,
    modalOpened,
    setSelectedAccounting,
    setModalOpened
  } = useContext(AccountingDetailsContext);

  const params = useParams();

  let paramIds = params.id.split('-');
  let typeOfAccount = paramIds.length

  // const accountingLeaf = useAccountingLeaf();

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAccountingDetails(user?.token, paramIds[paramIds.length - 1], typeOfAccount, user?.email),
        (res) => {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setFetchedAccountingDetails(res.data.data);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount]);



  return (
    <div className="accounting-details-page">
      <div className="page-head-wrap">
        <div className="page-title">
          <h2 className="bold-font">

            {fetchedAccountingDetails?.name && <>حسـابات {fetchedAccountingDetails?.name}</>}
            {fetchedAccountingDetails?.cname && <>حسـابات {fetchedAccountingDetails?.cname}</>}
          </h2>
          <p>كشف وعرض كــافة التفاصيل</p>
        </div>
        <SearchNotificationsProfile />
      </div>

      {
        typeOfAccount === 1 ? "" : <div className="add-section">
          <div
            className="add-account-btn"
            onClick={() => {
              setSelectedAccounting(null);
              setModalOpened(true);
            }}
          >
            <span className="text-span" >إضافة</span>
          </div>
        </div>
      }





      <div className="accounting-details-section">
        <AccountingDetailsTable 
          type={ typeOfAccount === 1 ? 
            "company" 
            : 
            (typeOfAccount === 2) ? "types" 
            :
            (typeOfAccount === 3) ? "subtypes" 
            : 
            (typeOfAccount === 4) ? 'account' 
            : "transaction"} 
        />
      </div>

      {modalOpened && <AccountingDetailsModal />} 
      
    </div>
  );
};

export default AccountingDetailsPage;
