/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import RadioButtonEmpty from '../../../common/icons/RadioButtonEmpty';
import RadioButtonFilled from '../../../common/icons/RadioButtonFilled';
import { calcProductTotalValue } from './receiptCalculations';
import { useTranslation } from 'react-i18next';

const RenderProductsFields = ({
  allProducts,
  productsFields,
  removeProductField,
  selectedReceipt,
  watch,
  setValue,
  getValues,
  control,
  errors,
  register,
  systemSettings
}) => {
  // const { allProducts, setPType } = useProducts();
  // const [fetchedAllProducts, setFetchedAllProducts] =
  //   React.useState(allProducts);
  const { t } = useTranslation();

  // React.useEffect(() => {
  //   if (allProducts) {
  //     setFetchedAllProducts(allProducts);
  //   }
  //   setPType(watch('serviceOrPackage'));
  // }, [allProducts, watch('serviceOrPackage')]);

  const handlePriceDefaultValue = (item, index) => {
    if (item?.price) return item?.price;
    else if (allProducts?.length > 0) {
      const foundP = allProducts?.find(
        (p) => p?.id == watch(`products.${index}.product_id`)
      );
      if (foundP) return foundP.salePrice;
      return '';
    }
  };

  const handleNetPriceDefaultValue = (item, index) => {
    const price = watch(`products.${index}.price`);

    const quantity = watch(`products.${index}.quantity`);
    if (price && quantity)
      return (parseFloat(price) * parseFloat(quantity)).toFixed(2);
    return '';
  };

  const handleChangeQuantity = (item, index) => {
    if (
      watch(`products.${index}.price`) &&
      watch(`products.${index}.quantity`)
    ) {
      setValue(
        `products.${index}.netPrice`,
        parseFloat(watch(`products.${index}.price`)) *
          parseFloat(watch(`products.${index}.quantity`))
      );
    }
  };
  const handleChangeSubTotalPrice = (item, index) => {
    if (
      watch(`products.${index}.netPrice`) &&
      watch(`products.${index}.price`)
    ) {
      setValue(
        `products.${index}.quantity`,
        (
          parseFloat(watch(`products.${index}.netPrice`)) /
          parseFloat(watch(`products.${index}.price`))
        ).toFixed(2)
      );
    }
  };
  const handlePriceChange = (item, index) => {
    if (
      watch(`products.${index}.price`) &&
      watch(`products.${index}.quantity`)
    ) {
      setValue(
        `products.${index}.netPrice`,
        parseFloat(watch(`products.${index}.price`)) *
          parseFloat(watch(`products.${index}.quantity`))
      );
    }
  };
  const handleSelectService = (item, index, e) => {
    if (
      watch(`products.${index}.price`) &&
      watch(`products.${index}.quantity`)
    ) {
      const foundP = allProducts?.find((p) => p?.id == e);
      if (foundP?.salePrice) {
        setValue(
          `products.${index}.netPrice`,
          parseFloat(foundP.salePrice) *
            parseFloat(watch(`products.${index}.quantity`))
        );
      }
    } else {
      setValue(`products.${index}.netPrice`, '');
    }
  };

  return (
    productsFields?.length > 0 && (
      <ul className="fields-array-ul without-padding">
        {productsFields.map((item, index) => {
          return (
            <li className="bill-field-delete-li" key={item.id}>
              <div className="li-fields">
                <div
                  className={`product-id-quantity-wrap ${
                    selectedReceipt ? 'isReturn' : ''
                  }`}
                >
                  <div className="select-label-wrap">
                    <p className="label-p">{t('Quotations.SelectProduct')}</p>{' '}
                    <div className="custom-select-wrap without-icon">
                      {(allProducts?.length >= 0 &&
                        allProducts.find((p) => p.id == item?.product_id)) ||
                      !selectedReceipt ? (
                        <AntdSelectOption
                          // disabled={selectedReceipt ? true : false}
                          name={`products.${index}.product_id`}
                          errorMsg={
                            errors?.products &&
                            errors.products[index]?.product_id &&
                            errors.products[index].product_id.message
                          }
                          validateStatus={
                            errors?.products &&
                            errors.products[index]?.product_id &&
                            errors?.products[index]?.product_id
                              ? 'error'
                              : ''
                          }
                          control={control}
                          setValue={setValue}
                          placeholder={t('Quotations.SelectProduct')}
                          options={
                            allProducts?.length > 0 &&
                            allProducts.map((p) => {
                              return {
                                title: p.name,
                                value: String(p.id)
                              };
                            })
                          }
                          showSearch={true}
                          // onSearch={onSearch}
                          // filterOption={(input, option) =>
                          //   option.children
                          //     .toLowerCase()
                          //     .indexOf(input.toLowerCase()) >= 0
                          // }
                          filterOption={(input, option) =>
                            option?.children?.props?.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          formClassName="create-receipt-form"
                          onChange={(e) => {
                            handleSelectService(item, index, e);
                          }}
                        />
                      ) : (
                        <AntdSelectOption
                          // disabled={selectedReceipt ? true : false}
                          name={`products.${index}.product_id`}
                          errorMsg={
                            errors?.products &&
                            errors.products[index]?.product_id &&
                            errors.products[index].product_id.message
                          }
                          validateStatus={
                            errors?.products &&
                            errors.products[index]?.product_id &&
                            errors?.products[index]?.product_id
                              ? 'error'
                              : ''
                          }
                          control={control}
                          setValue={setValue}
                          placeholder={t('Quotations.SelectProduct')}
                          options={
                            allProducts?.length > 0 &&
                            allProducts.map((p) => {
                              return {
                                title: p.name,
                                value: String(p.id)
                              };
                            })
                          }
                          showSearch={true}
                          // onSearch={onSearch}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          formClassName="create-receipt-form"
                          onChange={(e) => {
                            handleSelectService(item, index, e);
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="q-return-q-price-wrapper">
                    {/* {watch(`products.${index}.product_id`) &&
                      selectedReceipt && (
                        <div className="text-field-label-wrap">
                          <p className="label-p">الكمية المرتجعة</p>
                          <div className="text-field-wrap">
                            <AntdTextField
                              className="form-text-field"
                              name={`products.${index}.returnQuantity`}
                              type="text"
                              placeholder="الكمية المرتجعة..."
                              errorMsg={
                                errors?.products &&
                                errors.products[index]?.returnQuantity &&
                                errors.products[index].returnQuantity.message
                              }
                              validateStatus={
                                errors?.products &&
                                errors.products[index]?.returnQuantity &&
                                errors?.products[index]?.returnQuantity
                                  ? 'error'
                                  : ''
                              }
                              setValue={setValue}
                              control={control}
                              onChange={() => {
                                if (selectedReceipt) {
                                  const handleReturnQuantity = () => {
                                    return (
                                      parseFloat(
                                        selectedReceipt?.products[index]
                                          ?.quantity
                                      ) -
                                      parseFloat(
                                        watch(
                                          `products.${index}.returnQuantity`
                                        )
                                      )
                                    );
                                  };

                                  if (
                                    parseFloat(
                                      watch(`products.${index}.returnQuantity`)
                                    ) > 0 &&
                                    parseFloat(
                                      watch(`products.${index}.returnQuantity`)
                                    ) <=
                                      parseFloat(
                                        selectedReceipt?.products[index]
                                          ?.quantity
                                      )
                                  )
                                    setValue(
                                      `products.${index}.quantity`,
                                      handleReturnQuantity()
                                    );
                                  else if (
                                    parseFloat(
                                      watch(`products.${index}.returnQuantity`)
                                    ) > 0 &&
                                    parseFloat(
                                      watch(`products.${index}.returnQuantity`)
                                    ) >
                                      parseFloat(
                                        selectedReceipt?.products[index]
                                          ?.quantity
                                      )
                                  ) {
                                    setValue(
                                      `products.${index}.returnQuantity`,
                                      selectedReceipt?.products[index]?.quantity
                                    );
                                    setValue(`products.${index}.quantity`, 0);
                                  } else {
                                    setValue(
                                      `products.${index}.returnQuantity`,
                                      ''
                                    );
                                    setValue(
                                      `products.${index}.quantity`,
                                      selectedReceipt?.products[index]?.quantity
                                    );
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                      )} */}
                    {watch(`products.${index}.product_id`) && (
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('Quotations.ProductQuantity')}
                        </p>{' '}
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            disabled={selectedReceipt}
                            name={`products.${index}.quantity`}
                            type="text"
                            placeholder={
                              t('Quotations.ProductQuantity') + '...'
                            }
                            errorMsg={
                              errors?.products &&
                              errors.products[index]?.quantity &&
                              errors.products[index].quantity.message
                            }
                            validateStatus={
                              errors?.products &&
                              errors.products[index]?.quantity &&
                              errors?.products[index]?.quantity
                                ? 'error'
                                : ''
                            }
                            setValue={setValue}
                            control={control}
                            defaultValue={watch(`products.${index}.quantity`)}
                            onChange={(e) =>
                              handleChangeQuantity(item, index, e)
                            }
                          />
                        </div>
                      </div>
                    )}
                    {watch(`products.${index}.product_id`) && (
                      <div className="prices-wrap">
                        <div className="text-field-label-wrap">
                          <p className="label-p">
                            {t('Quotations.Productsellingprice')}
                          </p>{' '}
                          <div className="text-field-wrap">
                            <AntdTextField
                              disabled={selectedReceipt ? true : false}
                              setValue={setValue}
                              className="form-text-field"
                              name={`products.${index}.price`}
                              type="text"
                              placeholder={
                                t('Quotations.Productsellingprice') + '...'
                              }
                              errorMsg={
                                errors?.products &&
                                errors.products[index]?.price &&
                                errors.products[index].price.message
                              }
                              validateStatus={
                                errors?.products &&
                                errors.products[index]?.price &&
                                errors?.products[index]?.price
                                  ? 'error'
                                  : ''
                              }
                              control={control}
                              defaultValue={handlePriceDefaultValue(
                                item,
                                index
                              )}
                              onChange={(e) => {
                                handlePriceChange(item, index, e);
                              }}
                              // defaultValue={
                              //   item?.price
                              //     ? item.price
                              //     : allProducts?.length > 0
                              //     ? allProducts.find(
                              //         (p) =>
                              //           // eslint-disable-next-line eqeqeq
                              //           p.id ==
                              //           watch(`products.${index}.product_id`)
                              //       )?.salePrice
                              //     : ''
                              // }
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {watch(`products.${index}.product_id`) && (
                      <div className="text-field-label-wrap">
                        <p className="label-p">
                          {t('SalesInvoices.PurePrice')}
                        </p>
                        <div className="text-field-wrap"></div>

                        <AntdTextField
                          disabled={selectedReceipt ? true : false}
                          setValue={setValue}
                          className="form-text-field"
                          name={`products.${index}.netPrice`}
                          type="text"
                          // placeholder="سعر بيع الخدمة..."
                          control={control}
                          // defaultValue={handleSubTotalPriceDefaultValue(
                          //   item,
                          //   index
                          // )}
                          defaultValue={
                            selectedReceipt
                              ? handleNetPriceDefaultValue(item, index)
                              : ''
                          }
                          onChange={(e) => {
                            handleChangeSubTotalPrice(item, index, e);
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <div className="desc-tax-wrap">
                    {watch(`products.${index}.product_id`) && (
                      <div className="text-field-label-wrap">
                        <p className="label-p">{t('Quotations.description')}</p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            disabled={selectedReceipt ? true : false}
                            setValue={setValue}
                            className="form-text-field"
                            name={`products.${index}.desc`}
                            type="text"
                            placeholder={t('Quotations.description')}
                            errorMsg={
                              errors?.products &&
                              errors.products[index]?.desc &&
                              errors.products[index].desc.message
                            }
                            validateStatus={
                              errors?.products &&
                              errors.products[index]?.desc &&
                              errors?.products[index]?.desc
                                ? 'error'
                                : ''
                            }
                            control={control}
                            defaultValue={
                              item?.desc
                                ? item.desc
                                : allProducts?.length > 0
                                ? allProducts.find(
                                    (p) =>
                                      // eslint-disable-next-line eqeqeq
                                      p.id ==
                                      watch(`products.${index}.product_id`)
                                  )?.desc
                                : ''
                            }
                            // defaultValue={item?.desc ? item.desc : ''}
                          />
                        </div>
                      </div>
                    )}

                    {watch(`products.${index}.product_id`) && (
                      <div className="text-field-label-wrap">
                        <p className="label-p">{t('Quotations.Tax')}</p>
                        <div className="text-field-wrap">
                          <AntdTextField
                            className="form-text-field"
                            // disabled
                            setValue={setValue}
                            name={`products.${index}.tax`}
                            type="text"
                            placeholder={t('Quotations.Tax') + '...'}
                            errorMsg={
                              errors?.products &&
                              errors.products[index]?.tax &&
                              errors.products[index].tax.message
                            }
                            validateStatus={
                              errors?.products &&
                              errors.products[index]?.tax &&
                              errors?.products[index]?.tax
                                ? 'error'
                                : ''
                            }
                            control={control}
                            // defaultValue={systemSettings?.tax}
                            // defaultValue={getTaxDefaultValue(index)}
                            // defaultValue={watch(`products.${index}.tax`)} // make sure to set up defaultValue
                            defaultValue={
                              allProducts?.length > 0 && !selectedReceipt
                                ? allProducts.find(
                                    (p) =>
                                      p.id ==
                                      watch(`products.${index}.product_id`)
                                  )?.tax
                                : watch(`products.${index}.tax`)
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {watch(`products.${index}.price`) && (
                  <div className="radios-wrap">
                    <p className="radios-group-title">
                      {t('Quotations.SelectDiscountType')}
                    </p>{' '}
                    <div className="labels-wrap">
                      {[
                        {
                          title: t('Quotations.FixedNumber'),
                          value: '1'
                        },
                        {
                          // title: 'نسبة',
                          title: t('Quotations.Percentage'),

                          value: '2'
                        },
                        {
                          // title: 'بدون خصم',
                          title: t('Quotations.NoDiscount'),

                          value: 'no_discount'
                        }
                      ].map((obj, i) => (
                        <label
                          key={i}
                          className={`radio-input-label ${
                            String(watch(`products.${index}.discount_type`)) ===
                            String(obj.value)
                              ? 'selected'
                              : ''
                          }`}
                        >
                          <input
                            type="radio"
                            value={obj.value}
                            {...register(`products.${index}.discount_type`)}
                            // onChange={() => {
                            //   if (
                            //     watch(`products.${index}.discount_type`) == '1'
                            //   )
                            //     // fixed discount price
                            //     setValue(
                            //       `products.${index}.discount_percentage`,
                            //       ''
                            //     );
                            //   else if (
                            //     // precentage discount
                            //     watch(`products.${index}.discount_type`) == '2'
                            //   ) {
                            //     setValue(
                            //       `products.${index}.discount_price`,
                            //       ''
                            //     );
                            //   } else {
                            //     // no discount
                            //     setValue(
                            //       `products.${index}.discount_percentage`,
                            //       ''
                            //     );
                            //     setValue(
                            //       `products.${index}.discount_price`,
                            //       ''
                            //     );
                            //   }
                            // }}
                          />
                          <span className="label-span">{obj.title}</span>
                          {watch(`products.${index}.discount_type`) ==
                          String(obj.value) ? (
                            <RadioButtonFilled />
                          ) : (
                            <RadioButtonEmpty />
                          )}
                        </label>
                      ))}
                    </div>
                    {errors?.organization_type && (
                      <p className="error-p">
                        {errors.organization_type.message}
                      </p>
                    )}
                    {String(watch(`products.${index}.discount_type`)) ==
                      '1' && (
                      <div className="before-after-wrap">
                        <div className="text-field-label-wrap">
                          <p className="label-p">
                            {t('Quotations.DiscountPrice')}
                          </p>{' '}
                          <div className="text-field-wrap">
                            <AntdTextField
                              // disabled={selectedReceipt ? true : false}
                              disabled={selectedReceipt}
                              className="form-text-field"
                              name={`products.${index}.discount_price`}
                              type="text"
                              placeholder={`${t(
                                'Quotations.DiscountPrice'
                              )}...`}
                              errorMsg={
                                errors?.products &&
                                errors.products[index]?.discount_price &&
                                errors.products[index].discount_price.message
                              }
                              validateStatus={
                                errors?.products &&
                                errors.products[index]?.discount_price
                                  ? 'error'
                                  : ''
                              }
                              control={control}
                            />
                          </div>
                        </div>
                        {!isNaN(parseInt(watch(`products.${index}.price`))) &&
                          !isNaN(
                            parseInt(watch(`products.${index}.discount_price`))
                          ) &&
                          (!selectedReceipt ? !isNaN(watch(`products.${index}.quantity`)) :
                                watch(`products.${index}.returnQuantity`) > 0)&& (
                            <div className="after-wrap">
                              <div className="after-title">السعر بعد الخصم</div>
                              <span className="after-value">
                                {!isNaN(watch(`products.${index}.price`)) &&
                                !isNaN(watch(`products.${index}.quantity`))
                                  ? calcProductTotalValue(
                                      watch(`products.${index}.discount_type`),
                                      watch(`products.${index}.price`),
                                      watch(`products.${index}.discount_price`),
                                      watch(
                                        `products.${index}.discount_percentage`
                                      ),
                                      watch(`products.${index}.quantity`),
                                      watch(`products.${index}.tax`),
                                      systemSettings?.tax
                                    ).toFixed(2)
                                  : null}
                              </span>
                            </div>
                          )}
                      </div>
                    )}
                    {String(watch(`products.${index}.discount_type`)) ==
                      '2' && (
                      <div className="before-after-wrap">
                        <div className="text-field-label-wrap">
                          <p className="label-p">
                            {t('Quotations.DiscountPercentage')} ( % )
                          </p>{' '}
                          <div className="text-field-wrap">
                            <AntdTextField
                              // disabled={selectedReceipt ? true : false}
                              disabled={selectedReceipt}
                              className="form-text-field"
                              name={`products.${index}.discount_percentage`}
                              type="text"
                              placeholder={`${t(
                                'Quotations.DiscountPercentage'
                              )} ( % )`}
                              errorMsg={
                                errors?.products &&
                                errors.products[index]?.discount_percentage &&
                                errors.products[index].discount_percentage
                                  .message
                              }
                              validateStatus={
                                errors?.products &&
                                errors.products[index]?.discount_percentage
                                  ? 'error'
                                  : ''
                              }
                              control={control}
                            />
                          </div>
                        </div>
                        {!isNaN(parseInt(watch(`products.${index}.price`))) &&
                          !isNaN(
                            parseInt(
                              watch(`products.${index}.discount_percentage`)
                            )
                          ) &&
                          (!selectedReceipt ? !isNaN(watch(`products.${index}.quantity`)) :
                                watch(`products.${index}.returnQuantity`) > 0) && (
                            <div className="after-wrap">
                              <div className="after-title">
                                {t('Quotations.FinalPriceAfterDiscount')}
                              </div>{' '}
                              <span className="after-value">
                                {!isNaN(watch(`products.${index}.price`)) &&
                                !isNaN(watch(`products.${index}.quantity`))
                                  ? calcProductTotalValue(
                                      watch(`products.${index}.discount_type`),
                                      watch(`products.${index}.price`),
                                      watch(`products.${index}.discount_price`),
                                      watch(
                                        `products.${index}.discount_percentage`
                                      ),
                                      watch(`products.${index}.quantity`),
                                      watch(`products.${index}.tax`),
                                      systemSettings?.tax
                                    ).toFixed(2)
                                  : null}
                              </span>
                            </div>
                          )}
                      </div>
                    )}
                    {String(watch(`products.${index}.discount_type`)) ==
                      'no_discount' && (
                      <div className="before-after-wrap-without-discount">
                        {!isNaN(parseInt(watch(`products.${index}.price`))) &&
                          !isNaN(
                            parseInt(watch(`products.${index}.quantity`))
                          ) && (
                            <div className="after-wrap">
                              <div className="after-title">
                                {' '}
                                {t('Quotations.Price')}
                              </div>
                              <span className="after-value">
                                {!isNaN(watch(`products.${index}.price`)) &&
                                !isNaN(watch(`products.${index}.quantity`))
                                  ? calcProductTotalValue(
                                      watch(`products.${index}.discount_type`),
                                      watch(`products.${index}.price`),
                                      watch(`products.${index}.discount_price`),
                                      watch(
                                        `products.${index}.discount_percentage`
                                      ),
                                      watch(`products.${index}.quantity`),
                                      watch(`products.${index}.tax`),
                                      systemSettings?.tax
                                    ).toFixed(2)
                                  : null}
                              </span>
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              {/* {productsFields?.length >= 1 && !selectedReceipt && ( */}
              {productsFields?.length >= 0 && (
                <Tooltip title={t('Quotations.Delete')}>
                  <Button
                    className="delete-field-btn"
                    size="large"
                    type="dashed"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      removeProductField(index);
                    }}
                  />
                </Tooltip>
              )}
            </li>
          );
        })}
      </ul>
    )
  );
};

export default RenderProductsFields;
