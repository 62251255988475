import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import StoreInventoryContext from '../../../contexts/store-inventory-context/StoreInventoryProvider';
import { useTranslation } from 'react-i18next';

const SettingsWorkPlaceModal = () => {
  const {
    modalOpened,
    setModalOpened,
    selectedInventory,
    setSelectedInventory
  } = useContext(StoreInventoryContext);
  const { t } = useTranslation();

  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '742px' }}
      title={
        selectedInventory
          ? t('Inventory.WarehouseInventory.editInventory')
          : t('Inventory.WarehouseInventory.addNewInventory')
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedInventory(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedInventory(null);
      }}
      footer={false}
    >
      <TableForm />
    </Modal>
  );
};

export default SettingsWorkPlaceModal;
