import axios from 'axios';

const getAllSuppliersApi = async (token, filterValues, excel) => {
  let url;
  if (excel) {
    url = `/Supplier/all?page=${filterValues.page}&searchText=${filterValues.searchText}`;
  } else {
    if (filterValues) {
      url = `/Supplier/all?test=1&page=${filterValues.page}&searchText=${filterValues.searchText}`;
    } else {
      url = `/Supplier/all`;
    }
  }
  try {
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllSuppliersApi;
