import React, { useContext } from 'react';
import { Modal } from 'antd';
import InstallmentsPageContext from '../../contexts/installments-context/InstallmentsContext';
import UserInstallmentModalForm from './UserInstallmentModalForm';
import './UserInstallmentModal.scss';
import { useTranslation } from 'react-i18next';
const UserInstallmentModal = () => {
  const {
    modalOpened,
    setModalOpened,
    selectedInstallment,
    setSelectedIntallment
  } = useContext(InstallmentsPageContext);
  const { t } = useTranslation();
  return (
    <Modal
      className="user-installment-modal"
      width="90%"
      style={{ maxWidth: '642px' }}
      title={
        selectedInstallment
          ? t('Receipts.updateInstallment')
          : t('Receipts.newInstallment')
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedIntallment(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedIntallment(null);
      }}
      footer={false}
    >
      <UserInstallmentModalForm />
    </Modal>
  );
};

export default UserInstallmentModal;
