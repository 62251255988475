import React, { useContext } from 'react';
import { Modal } from 'antd';
import AccountingDetailsContext from '../../../contexts/accounting-context/AccountingDetailsContext';
import AccountingDetailsModalForm from './AccountingDetailsModalForm';
import { useParams } from 'react-router-dom';
// import AccountingForm from '../accounting-tree-page/AccountingForm';
import { getLeafType } from '../../../custom-hooks/getLeafType';

const AccountingDetailsModal = () => {
  const {
    modalOpened,
    setModalOpened,
    setSelectedAccounting,
    selectedAccounting
  } = useContext(AccountingDetailsContext);

  const params = useParams();
  let typeOfAccount = getLeafType(params.id);




  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '542px' }}
      title={
        selectedAccounting ? 'تعديل تفاصيل الحســاب' : 'إضافة تفاصيل الحســاب'
      }
      visible={modalOpened}
      onOk={() => {
        setModalOpened(false);
        setSelectedAccounting(null);
      }}
      onCancel={() => {
        setModalOpened(false);
        setSelectedAccounting(null);
      }}
      footer={false}
    >
      <AccountingDetailsModalForm typeOfAccount={typeOfAccount} />

    </Modal>
  );
};

export default AccountingDetailsModal;
