import React from 'react';

const ShoppingIcon = ({ color }) => {
  return (
    <svg
      id="shopping"
      xmlns="http://www.w3.org/2000/svg"
      width="29.385"
      height="27.311"
      viewBox="0 0 29.385 27.311"
    >
      <path
        id="Path_374"
        data-name="Path 374"
        d="M214.272,300.275a.574.574,0,1,0-.812,0A.575.575,0,0,0,214.272,300.275Zm0,0"
        transform="translate(-201.051 -282.118)"
        fill={color ? color : '#b5b5b5'}
      />
      <path
        id="Path_375"
        data-name="Path 375"
        d="M224.582,62.152V63.3a3.444,3.444,0,0,0,6.887,0V62.152a.574.574,0,0,0,0-1.148h-1.148a.574.574,0,1,0,0,1.148V63.3a2.3,2.3,0,0,1-4.592,0V62.152a.574.574,0,1,0,0-1.148h-1.148a.574.574,0,1,0,0,1.148Zm0,0"
        transform="translate(-211.152 -57.503)"
        fill={color ? color : '#b5b5b5'}
      />
      <path
        id="Path_376"
        data-name="Path 376"
        d="M6.67,27.143a.574.574,0,0,0,.811,0L9.244,25.38a2.657,2.657,0,0,0,.771-2.154l.632-.61a2.663,2.663,0,0,1,1.856-.75h7.556a5.136,5.136,0,0,0,3.6-1.464.538.538,0,0,0,.041-.044l5.142-6.174a2.3,2.3,0,0,0-3.494-2.979l-.555.574V.573A.574.574,0,0,0,24.22,0H9.47A.574.574,0,0,0,8.9.573V13.432A6.9,6.9,0,0,0,3.961,17.3a2.65,2.65,0,0,0-2.021.779L.169,19.837a.574.574,0,0,0,0,.813Zm16.976-26V12.967l-1.661,1.717a2.306,2.306,0,0,0-1.783-.853H13.548a.317.317,0,0,1-.117-.025,7.417,7.417,0,0,0-2.814-.549c-.192,0-.384.007-.574.02V1.147ZM9.552,14.481a6.521,6.521,0,0,1,3.448.39,1.46,1.46,0,0,0,.548.109H20.2a1.148,1.148,0,1,1,0,2.3H15.209a.574.574,0,1,0,0,1.148H20.2a2.3,2.3,0,0,0,2.3-2.3,2.322,2.322,0,0,0-.02-.3c.12-.123,3.589-3.711,3.709-3.835l.026-.028a1.148,1.148,0,1,1,1.752,1.484L22.84,19.6a3.994,3.994,0,0,1-2.781,1.118H12.5A3.8,3.8,0,0,0,9.849,21.79l-.253.244a2.672,2.672,0,0,0-.352-.434L5.719,18.075a2.68,2.68,0,0,0-.633-.468,5.746,5.746,0,0,1,4.466-3.126Zm-6.8,4.406a1.522,1.522,0,0,1,2.156,0l3.526,3.525a1.522,1.522,0,0,1,0,2.156L7.075,25.925,1.387,20.244Zm0,0"
        transform="translate(0 0)"
        fill={color ? color : '#b5b5b5'}
      />
    </svg>
  );
};

export default ShoppingIcon;
