/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { Empty, Pagination, Table } from 'antd';
import tableColumns from './tableColumns';
import StoreNewProductModalContext from '../../../contexts/store-new-product-context/StoreNewProductProvider';
import { useEffect } from 'react';
import { useState } from 'react';
import getAllProducts from '../../../apis/store-apis/products/getAllProducts';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const StoreItemManagerTable = (props) => {
  const { user, filterValues, setFilterValues } = useContext(UserContext);
  const {
    isLoading,
    setIsLoading,
    fetchCount,
    setFetchCount,
    setModalOpened,
    allFetchedProducts,
    setAllFetchedProducts,
    setSelectedProduct,
    setSelectedProductId,
    setSelectedProductModalOpened,
    nameSearchValue,
    setBarCodeModalOpned,
    tablePagination,
    setTablePagination
  } = useContext(StoreNewProductModalContext);
  const { t } = useTranslation();
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id,
      index: index + 1,
      name: obj?.name ? obj.name : '',
      title: obj?.title ? obj.title : ''
    }));
  };

  const customApiRequest = useCustomApiRequest();
  useEffect(async () => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllProducts(user?.token, {
          ...filterValues,
          page: tablePagination.current_page,
          product_type_value: props?.product_type_value
        }),
        (res) => {
          setIsLoading(false);
          setFilterValues({
            ...filterValues,
            page: tablePagination.current_page
          });
          if (res?.status === 200 && res?.data?.data?.data?.length >= 0) {
            const data = res?.data?.data?.data;
            setAllFetchedProducts(data);
          } else {
          }
          if (res?.data.data.pagination) {
            setTablePagination(res?.data.data.pagination);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, props?.product_type_value]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedProducts?.length >= 0) {
      let mappedData = null;
      if (nameSearchValue.trim()) {
        const filtered = allFetchedProducts.filter((obj) =>
          obj.name
            ?.toLowerCase()
            .trim()
            .includes(nameSearchValue.toLowerCase().trim())
        );
        mappedData = mapDataSource(filtered);
      } else {
        mappedData = mapDataSource(allFetchedProducts);
      }
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    fetchCount,
    allFetchedProducts,
    allFetchedProducts?.length,
    nameSearchValue
  ]);

  const history = useHistory();
  return (
    <section>
      <Table
        locale={{
          emptyText: (
            <Empty description={false}>
              {t('Inventory.Products.noDataAvailable')}
            </Empty>
          )
        }}
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        // pagination={{defaultPageSize: 20 ,...tablePagination , page: tablePagination.current_page}}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedProducts,
          setModalOpened,
          setFetchCount,
          setSelectedProduct,
          setSelectedProductId,
          setIsLoading,
          setSelectedProductModalOpened,
          setBarCodeModalOpned,
          t,
          user?.token
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />

      {tablePagination && (
        <Pagination
          style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
          defaultCurrent={1}
          showSizeChanger={false}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}
          onChange={(page, pageSize) => {
            setFetchCount((prev) => prev + 1);
            setTablePagination({ ...tablePagination, current_page: page });
            // history.push(`${storeRouterLinks?.storeItemManagerPage}?page=${page}`)
          }}
          hideOnSinglePage={true}
        />
      )}
    </section>
  );
};

export default StoreItemManagerTable;
