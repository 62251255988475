import React, { useContext } from 'react';
import SearchNotificationsProfile from '../../../components/search-notifications-profile/SearchNotificationsProfile';
import PageTable from './PageTable';
import PageModal from './PageModal';
import StoreConversionContext from '../../../contexts/store-conversion-context/StoreConversionProvider';
import './StoreConversionPage.scss';
import Countdown from '../../../components/countdown/Countdown';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useEffect } from 'react';
import getAllProductsWithSelect from '../../../apis/store-apis/products/getAllProductsWithSelect';
import UserContext from '../../../contexts/user-context/UserProvider';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';
import excelImg from '../../../assets/imgs/icons/excel-btn.png';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import SelectedConversionModal from './selectedConversionModal';
const StoreConversionPage = () => {
  const {
    modalOpened,
    setModalOpened,
    setSelectedConversion,
    setAllProducts,
    fetchCount,
    selectedConversionModalOpened
  } = useContext(StoreConversionContext);
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const systemSettings = useSystemSettings()
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    customApiRequest(
      getAllProductsWithSelect(user?.token),
      (res) => {
        if (res?.status === 200 && res?.data?.data?.length >= 0) {
          const data = res.data.data;
          setAllProducts(data);
        }
      },
      (error) => {
        // setIsLoadingProducts(false);
      }
    );
  }, [fetchCount]);

  return (
    <div className="measurement-units-page">
      <div className="page-head-wrap">
        {/* <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: 32 }}>
            {t('Inventory.TransferStocks.title')}
          </h2>
          <p>{t('Inventory.TransferStocks.subTitle')}</p>
        </div> */}
        <div className="page-title">
          <h2 className="bold-font" style={{ fontSize: '42px' }}>
            {t('Inventory.TransferStocks.title')}
          </h2>
          <Breadcrumbs
            title1={t('AppBar.Products.homePage')}
            link1={'/'}
            title2={t('AppBar.Products.storePage.title')}
            title3={t('Inventory.TransferStocks.title')}
            link3={'/store/store-conversion'}
          />
        </div>
        {/* <Countdown /> */}
        <SearchNotificationsProfile />
      </div>

    <div className='add-excel-con'>
    <div className="add-section" style={{marginTop:0}}>
        <div
          className="add-account-btn"
          onClick={() => {
            setSelectedConversion(null);
            setModalOpened(true);
          }}
        >
          <span className="text-span">
            {t('Inventory.TransferStocks.addNewTransformation')}
          </span>
        </div>
      </div>

      <div className="excel-btn-wrap">
              <a
                href={`${
                  process.env.REACT_APP_BASE_URL
                }/exportExcelStoreConversion?type=1&client_id=${systemSettings?.id}`}
                download
                className="excel-export-btn"
              >
                <img
                  style={{ height: '42px' }}
                  src={excelImg}
                  alt="export to excel"
                />
              </a>
       </div>
    </div>
      <div className="table-wrapper">
        <PageTable />
      </div>

      {modalOpened && <PageModal />}
      {selectedConversionModalOpened && <SelectedConversionModal />}
    </div>
  );
};

export default StoreConversionPage;
