/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Table, Empty, Pagination } from 'antd';
import tableColumns from './tableColumns';
import UserContext from '../../contexts/user-context/UserProvider';
import getAllSuppliersApi from '../../apis/store-apis/suppliers/getAllSuppliersApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import storeRouterLinks from '../../components/app/store-routes/storeRouterLinks';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import getAllCustomerPaymentApi from '../../apis/store-apis/customer-payments/getAllCustomerPaymentApi';
import CustomersPaymentsContext from '../../contexts/customers-payments-context/CustomersPaymentsContext';
import ClientsContext from '../../contexts/clients-context/ClientsContext';
import getAllClientsApi from '../../apis/clients-apis/getAllClientsApi';
import checkRes from '../../utils/checkRes';
import salesRouterLinks from '../../components/app/sales-routes/salesRouterLinks';
import { useTranslation } from 'react-i18next';

const PageTable = () => {
  const { t } = useTranslation();

  const {
    allFetchedCustomersPayments,
    setAllFetchedCustomersPayments,
    isLoading,
    setIsLoading,
    setFetchCount,
    fetchCount: fetchCountCustomers,
    setCustomerPaymentModalOpened,
    setSelectedCustomerPayment,
    nameSearchValue,
    searchTargetValue,
    tablePagination,
    setTablePagination,
    setSelectedCustomerPaymentId,
    setModalOpened
  } = useContext(CustomersPaymentsContext);

  const {
    allFetchedClients,
    setAllFetchedClients,
    fetchCount,
    setFetchCount: setFetchCountClients
    // fetchCount,
    // setFetchCount,
    // setModalOpened
    // setSelectedClient,
    // nameSearchValue,
    // tablePagination,
    // setTablePagination
  } = useContext(ClientsContext);
  const { user, filterValues, setFilterValues } = useContext(UserContext);

  const history = useHistory();

  const { search } = useLocation();
  const values = queryString.parse(search);

  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);
      customApiRequest(
        getAllClientsApi(
          user?.token,
          {
            ...filterValues,
            page: values.page ? values.page : 1,
            searchText: searchTargetValue
          },
          false
        ),
        (res) => {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data?.data?.length >= 0) {
            setFilterValues({
              ...filterValues,
              page: tablePagination.current_page
            });
            const data = res.data.data.data;
            setAllFetchedClients(data);
          }
          if (res?.data?.data?.pagination) {
            setTablePagination(res.data.data.pagination);
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, fetchCountCustomers]);

  // useEffect(() => {
  //   let isMounted = true;
  //   if (isMounted) {
  //     setIsLoading(true);
  //     customApiRequest(
  //       getAllCustomerPaymentApi(
  //         user?.token,
  //         { page: values.page ? values.page : 1, searchText: nameSearchValue },
  //         2
  //       ),
  //       (res) => {
  //         setIsLoading(false);
  //         if (res?.status === 200 && res?.data?.data) {
  //           if (res.data.data.length >= 0) {
  //             const data = res.data.data;
  //             setAllFetchedCustomersPayments(data);
  //             setTableDataSource(data);
  //           }
  //           if (res?.data?.data?.pagination) {
  //             setTablePagination(res.data.data.pagination);
  //           }
  //         }
  //       },
  //       (error) => {
  //         setIsLoading(false);
  //       }
  //     );
  //   }

  //   return () => {
  //     isMounted = false;
  //   };
  // }, [fetchCount]);

  useEffect(() => {
    if (values.page) {
      setTablePagination({ ...tablePagination, current_page: +values.page });
    }
  }, [search]);

  useEffect(() => {
    let isMounted = true;
    if (allFetchedClients?.length >= 0) {
      let mappedData = null;
      if (nameSearchValue.trim()) {
        const filtered = allFetchedClients.filter((user) => {
          if (
            user.name
              .toLowerCase()
              .trim()
              .includes(nameSearchValue.toLowerCase().trim())
          ) {
            return true;
          } else if (user.id) {
            return true;
          } else if (user.email) {
            return true;
          } else if (user.phone) {
            return true;
          }
        });
        mappedData = mapDataSource(filtered);
      } else {
        mappedData = mapDataSource(allFetchedClients);
      }
      if (isMounted) {
        setTableDataSource(mappedData);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    fetchCount,
    allFetchedClients,
    allFetchedClients?.length,
    nameSearchValue
  ]);

  return (
    <section>
      <Table
        locale={{
          emptyText: (
            <Empty description={false}>{t('Messages.noDataAvailable')}</Empty>
          )
        }}
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedClients,
          setCustomerPaymentModalOpened,
          setSelectedCustomerPaymentId,
          setFetchCount,
          setFetchCountClients,
          setSelectedCustomerPayment,
          setIsLoading,
          user?.token,
          setModalOpened
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />
      {tablePagination && (
        <Pagination
          style={{ display: 'flex', justifyContent: 'center' }}
          defaultCurrent={1}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}

          onChange={(page, pageSize) => {
            setTablePagination({ ...tablePagination, current_page: page });
            // setTablePagination(...tablePagination , {current_page:page})
            setFetchCount((prev) => prev + 1);
            history.push(
              `${salesRouterLinks?.customerPaymentsPage}?page=${page}`
            );
          }}
          hideOnSinglePage={true}
        />
      )}
    </section>
  );
};

export default PageTable;
