/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import storeItemManagerImg from '../../assets/imgs/store/store-item-manager.png';
import SearchNotificationsProfile from '../../components/search-notifications-profile/SearchNotificationsProfile';
import StoreWelcomeSharedSection from '../store-pages/store-welcome-shared-section/StoreWelcomeSharedSection';
import TableFilter from './TableFilter';
import PageTable from './PageTable';
import './SuppliersPaymentsPage';
import CustomersPaymentsContext from '../../contexts/customers-payments-context/CustomersPaymentsContext';
import SelectedCustomerPayment from './SelectedCustomerPayment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/breadcrumbs/Breadcrumbs';
const CustomerPaymentsPage = () => {
  const { customerPaymentModalOpened } = React.useContext(
    CustomersPaymentsContext
  );
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  const path = useHistory();
  return (
    <div className="clients-page">
      <div className="top-section-wrap">
        <div className="settings-categories-page">
          <div className="OutgoingPurchaseInvoicePage-page-head-wrap">
            {/* <div className="page-title">
              <h2 className="bold-font">{t('supplierPayments.title')}</h2>
              <p>{t('supplierPayments.subTitle')}</p>
            </div> */}
            <div className="page-title">
              <h2 className="bold-font" style={{ fontSize: '42px' }}>
                {t('supplierPayments.title')}
              </h2>
              <Breadcrumbs
                title1={t('AppBar.Products.homePage')}
                link1={'/'}
                title2={t('AppBar.Products.incomingPurchaseInvoice.title')}
                title3={t('supplierPayments.title')}
                link3={'/store/suppliers-payments-page'}
              />
            </div>
          </div>
        </div>

        <div className="search-wrap">
          <SearchNotificationsProfile noSearch />
        </div>
      </div>
      <TableFilter currentLang={currentLang} />
      <div className="suppliersPage-table-wrapper">
        <PageTable currentLang={currentLang} />
      </div>
      {customerPaymentModalOpened && (
        <SelectedCustomerPayment currentLang={currentLang} />
      )}
    </div>
  );
};

export default CustomerPaymentsPage;
