/* eslint-disable eqeqeq */
import React, { useContext, useRef, useState } from 'react';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import {
  PlusOutlined,
  MinusOutlined,
  PicRightOutlined,
  TableOutlined
} from '@ant-design/icons';

import addToCartImg from '../../assets/imgs/pos/empty-cart-img.png';
import removeToCartImg from '../../assets/imgs/pos/remove-from-cart.png';

import './RenderProducts.scss';
import { Pagination } from 'antd';
import { useHistory } from 'react-router-dom';
import Links from '../app/Links';
import { useTranslation } from 'react-i18next';

const RenderProducts = ({
  products,
  productsAppearance,
  setProductsAppearance,
  step
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    selectedInvoice,
    selectedProducts,
    setSelectedProducts,
    setproductDiscountModalOpened,
    setproductIngredientsModalOpened,
    setFoundProduct,
    setFetchProductsCount,
    productsPagination,
    barCodeQuantity,
    itemCode,
    productsFilter,
    setProductsFilter,
    setSearchTextState,
    searchTextState,
    setProductBarCode,
    setItemCode,
    setBarCodeQuantity,
    productBarCode
  } = useContext(PosInvoiceContext);
  const routerLinks = Links();

  // const autoAddProduct = useRef()
  // const autoIncQuantity = useRef()
  const handleProductSelect = (p) => {
    setSelectedProducts((prev) => {
      if (selectedProducts?.length >= 0) {
        const found = selectedProducts.find((obj) => obj.id === p.id);
        if (found) {
          return selectedProducts.filter((item) => item.id !== p.id);
        } else {
          return [
            ...prev,
            {
              ...p,
              productDiscountType: '0',
              productDiscountValue: '0',
              // productInCartQuantity: p.quantityInPosStock == 0 ? 0 : 1,
              productInCartQuantity:
                (p?.parCode == itemCode ? barCodeQuantity : '') || 1,
              productIngredients:
                p.materials?.length > 0
                  ? p.materials
                      .map((m) =>
                        m.quantityInPosStock
                          ? {
                              ...m,
                              ingredientInCartQuantity: 0
                              // ingredientInCartQuantity:
                              //   m.quantityInPosStock === 0 ? 0 : 1
                            }
                          : null
                      )
                      .filter((i) => i)
                  : []
            }
          ];
        }
      }
    });
  };

  // useEffect(() => {
  //   let p = products?.find((obj) => obj)
  //   if (products?.length == '1') {
  //     handleProductSelect(p)
  //     // setSelectedProducts((prev) => {
  //     //   if (selectedProducts?.length >= 0) {
  //     //     const found = selectedProducts.find((obj) => obj.id === p.id);
  //     //     if (found) {
  //     //       return selectedProducts.filter((item) => item.id !== p.id);
  //     //     } else {
  //     //       return [
  //     //         ...prev,
  //     //       {
  //     //         ...p,
  //     //         productDiscountType: '0',
  //     //         productDiscountValue: '0',
  //     //         // productInCartQuantity: p.quantityInPosStock == 0 ? 0 : 1,
  //     //         productInCartQuantity:
  //     //           (p?.parCode == itemCode ? barCodeQuantity : '') || 1,
  //     //         productIngredients:
  //     //           p.materials?.length > 0
  //     //             ? p.materials
  //     //                 .map((m) =>
  //     //                   m.quantityInPosStock
  //     //                     ? {
  //     //                         ...m,
  //     //                         ingredientInCartQuantity: 0
  //     //                         // ingredientInCartQuantity:
  //     //                         //   m.quantityInPosStock === 0 ? 0 : 1
  //     //                       }
  //     //                     : null
  //     //                 )
  //     //                 .filter((i) => i)
  //     //             : []
  //     //       }
  //     //       ];
  //     //     }
  //     //   }
  //     // });
  //   }
  // } , [products])

  // const element = autoAddProduct.current;
  // // const element1 = autoIncQuantity.current;
  // useEffect(() => {
  //   if (products?.length == '1' && element) {
  //     element.click();
  //   }
  // }, [products?.length == '1' , element]);

  // useEffect(() => {
  //   if (products?.length > '1' && element1) {
  //     element1.click();
  //   }
  // }, [element1]);
  // useEffect(() => {
  //   if (products?.length == '2' && element) {
  //     element.click();
  //   }
  // }, [products?.length == '2']);

  const handleQuantityBtnClick = (type, product, e) => {
    // type => 'add', 'sub'
    if (type === 'add') {
      setSelectedProducts((prev) => {
        return prev.map((p) =>
          p.id === product.id
            ? {
                ...p,
                productInCartQuantity: (
                  parseFloat(p.productInCartQuantity) + 1
                ).toFixed(2)
              }
            : { ...p }
        );
      });
    } else if (type === 'sub') {
      setSelectedProducts((prev) => {
        return prev.map((p) =>
          p.id === product.id && parseFloat(p.productInCartQuantity) > 0
            ? {
                ...p,
                productInCartQuantity: (
                  parseFloat(p.productInCartQuantity) - 1
                ).toFixed(2)
              }
            : {
                ...p,
                productInCartQuantity:
                  parseFloat(p.productInCartQuantity) <= 0
                    ? 0
                    : parseFloat(p.productInCartQuantity).toFixed(2)
              }
        );
      });
    }

    e.preventDefault();
  };
  const hadleQuantityInputChange = (product, e) => {
    // if (!isNaN(e.target.value)) {
    // }
    setBarCodeQuantity('');
    setSelectedProducts((prev) => {
      return prev.map((p) => {
        if (p.id === product.id) {
          if (!isNaN(e.target.value)) {
            return {
              ...p,
              productInCartQuantity: parseFloat(e.target.value),
              productDiscountValue:
                parseFloat(e.target.value) == 0 ? 0 : p.productDiscountValue
            };
          }
        }
        return { ...p };
      });
    });

    e.preventDefault();
  };
  const hadleQuantityInputChangeSelectedInvoice = (product, e) => {
    setSelectedProducts((prev) => {
      return prev.map((p) => {
        const foundP =
          selectedInvoice.products?.length > 0 &&
          selectedInvoice.products.find((fp) => fp?.product?.id == product.id);
        if (p.id === product.id) {
          if (
            // !isNaN(e.target.value) &&
            e.target.value === '' ||
            (parseFloat(e.target.value) >= 0 &&
              parseFloat(e.target.value) <= parseFloat(foundP?.quantity))
          ) {
            return {
              ...p,
              productInCartQuantity:
                e.target.value === '' ? 0 : parseFloat(e.target.value),
              productDiscountValue:
                parseFloat(e.target.value) == 0 ? 0 : product?.apiDiscountValue
            };
          } else {
            return {
              ...p,
              productInCartQuantity: foundP?.quantity,
              productDiscountValue:
                parseFloat(e.target.value) == 0 ? 0 : product?.apiDiscountValue
            };
          }
        }
        return { ...p };
      });
    });

    e.preventDefault();
  };
  const handleQuantityReturnInputChangeSelectedInvoice = (product, e) => {
    setSelectedProducts((prev) => {
      return prev.map((p) => {
        const foundP =
          selectedInvoice.products?.length > 0 &&
          selectedInvoice.products.find((fp) => fp?.product?.id == product.id);
        if (p.id == product.id) {
          if (
            // !isNaN(e.target.value) &&
            parseFloat(e.target.value) > 0 &&
            parseFloat(e.target.value) <= parseFloat(foundP?.quantity)
          ) {
            return {
              ...p,
              productInCartQuantity:
                parseFloat(e.target.value) > 0
                  ? parseFloat(foundP?.quantity) - parseFloat(e.target.value)
                  : parseFloat(foundP?.productInCartQuantity),
              returnProductInCartQuantity:
                e.target.value == '' ? 1 : parseFloat(e.target.value),
              productDiscountValue:
                parseFloat(e.target.value) >= parseFloat(foundP?.quantity)
                  ? 0
                  : product?.apiDiscountValue
            };
          } else if (
            parseFloat(e.target.value) > 0 &&
            parseFloat(e.target.value) > parseFloat(foundP?.quantity)
          ) {
            return {
              ...p,
              productInCartQuantity: 0,
              returnProductInCartQuantity: foundP?.quantity,
              productDiscountValue:
                parseFloat(e.target.value) >= parseFloat(foundP?.quantity)
                  ? 0
                  : product?.apiDiscountValue
            };
          } else {
            return {
              ...p,
              productInCartQuantity: foundP?.quantity,
              returnProductInCartQuantity: 0,
              productDiscountValue:
                parseFloat(e.target.value) >= parseFloat(foundP?.quantity)
                  ? 0
                  : product?.apiDiscountValue
            };
          }
        }
        return { ...p };
      });
    });

    e.preventDefault();
  };
  const renderCartIcon = (p) => {
    const foundInSelectedProducts = selectedProducts?.find(
      (item) => item.id === p.id
    );
    if (foundInSelectedProducts) {
      return (
        <div
          className="cart-btn"
          onClick={() => {
            handleProductSelect(p);
          }}
        >
          <img
            src={removeToCartImg}
            className="cart-img"
            alt="remove from cart"
          />
        </div>
      );
    }
    return null;
    // return (
    //   <div className="cart-btn" onClick={() => handleProductSelect(p)}>
    //     <img src={addToCartImg} className="cart-img" alt="add from cart" />
    //   </div>
    // );
  };
  const renderNotAvailableproduct = (p) => {
    if (p.quantityInPosStock == 0 && p?.type == '1') {
      return (
        <div className="not-available-tag">{t('Pos.Main.unavailable')}</div>
      );
    }
    return null;
  };
  const handleInputQuantityDefaultValue = (p) => {
    if (p?.parCode == itemCode && barCodeQuantity) return barCodeQuantity;
  };
  const handleInputReturnQuantityValue = (p) => {
    if (selectedProducts && selectedInvoice) {
      const foundInSelectedProducts = selectedProducts?.find(
        (item) => item.id === p.id
      );
      // if (barCodeQuantity) return barCodeQuantity;
      return parseFloat(foundInSelectedProducts.returnProductInCartQuantity) < 0
        ? 0
        : parseFloat(foundInSelectedProducts.returnProductInCartQuantity);
    } else return 0;
  };
  const handleInputQuantityValue = (p) => {
    if (selectedProducts) {
      const foundInSelectedProducts = selectedProducts?.find(
        (item) => item.id === p.id
      );
      // if (barCodeQuantity) return barCodeQuantity;
      return parseFloat(foundInSelectedProducts.productInCartQuantity) < 0
        ? 0
        : parseFloat(foundInSelectedProducts.productInCartQuantity);
    }
  };
  // const handleInputQuantityValue = (p) => {
  //   if (p.quantityInPosStock === 0) return 0;
  //   else {
  //     const foundInSelectedProducts = selectedProducts?.find(
  //       (item) => item.id === p.id
  //     );
  //     return parseFloat(foundInSelectedProducts.productInCartQuantity) < 0
  //       ? 0
  //       : parseFloat(foundInSelectedProducts.productInCartQuantity);
  //   }
  // };

  // taxtype = 1 => price without tax
  // taxtype = 2 => price with tax
  const productTaxTypes = {
    withTax: '2',
    withoutTax: '1'
  };
  const renderProductSalePrice = (p) => {
    if (parseFloat(p?.salePriceWithTax) > 0) {
      return (
        <div className="p-sale-price">
          <span className="price-span">{p?.salePriceWithTax}</span>
        </div>
      );
    } else if (parseFloat(p?.salePrice) > 0) {
      return (
        <div className="p-sale-price">
          <span className="price-span">{p?.salePrice}</span>
        </div>
      );
    }
  };

  const handleImgClick = (p, e) => {
    if ((p.quantityInPosStock > 0 && p.type == 1) || p.type == 2) {
      if (selectedProducts || selectedProducts?.length === 0) {
        handleProductSelect(p);
        if (searchTextState.length > 0) {
          // setProductsFilter({});
          setSearchTextState('');
        }
      } else if (selectedProducts.length > 0) {
        handleQuantityBtnClick('add', p, e);
      }
    }
  };

  const renderProducts = () => {
    return (
      products?.length > 0 &&
      products.map((p, index) => {
        return (
          <li key={p.id} className="product-li">
            {/* {p?.salePrice && (
              <div className="p-sale-price">
                <span className="price-span">{p?.salePrice}</span>
                <span className="currency-span">ريــال</span>
              </div>
            )} */}

            {renderProductSalePrice(p)}
            {!selectedInvoice && renderCartIcon(p)}
            {renderNotAvailableproduct(p)}

            {p.image && (
              <div className="p-img">
                <img
                  src={p.image}
                  alt={p.name ? p.name : 'product'}
                  onClick={(e) => handleImgClick(p, e)}
                />
              </div>
            )}

            <div className="name-quantity-wrap">
              {p.name && <p className="p-name">{p.name}</p>}
              {p.product_type !== 2 ? (
                p.RemainingQuantity ? (
                  <p className="p-name">
                    {t('Pos.Main.stock')} (
                    {p.RemainingQuantity > 0 ? p.RemainingQuantity : 0})
                  </p>
                ) : (
                  <p className="p-name" style={{ color: '#d13b3b' }}>
                    {t('Pos.Main.outOfStock')}
                  </p>
                )
              ) : (
                <p
                  className="p-name"
                  style={{ height: '25px', lineHeight: '25px' }}
                >
                  {t('Pos.Main.serviceProduct')}
                </p>
              )}

              {selectedProducts?.length > 0 &&
              selectedProducts.find((item) => item.id === p.id) ? (
                <div className="quantity-ingredients-wrap">
                  {/* {selectedInvoice && selectedInvoice?.products?.length > 1 && (
                    <div className="return-q-wrapper">
                      <p>{t('Pos.Main.returnedQuantity')}</p>
                      <input
                        type="number"
                        step={0.1}
                        name={`products.${index}.returnQuantity`}
                        defaultValue=""
                        value={handleInputReturnQuantityValue(p)}
                        min={0.1}
                        onChange={(e) =>
                          handleQuantityReturnInputChangeSelectedInvoice(p, e)
                        }
                      />
                    </div>
                  )} */}

                  <div className="p-quantity-wrap">
                    {!selectedInvoice && (
                      <button
                        // ref={autoIncQuantity}
                        className="add-btn"
                        onClick={(e) => handleQuantityBtnClick('add', p, e)}
                      >
                        <PlusOutlined />
                      </button>
                    )}
                    {selectedInvoice && (
                      <button
                        className="add-btn"
                        style={{ cursor: 'not-allowed' }}
                      >
                        <PlusOutlined />
                      </button>
                    )}

                    <input
                      type="number"
                      step={0.1}
                      name={`products.${index}.quantity`}
                      defaultValue={handleInputQuantityDefaultValue(p)}
                      value={handleInputQuantityValue(p)}
                      min={0.1}
                      onChange={(e) =>
                        selectedInvoice
                          ? hadleQuantityInputChangeSelectedInvoice(p, e)
                          : hadleQuantityInputChange(p, e)
                      }
                    />

                    <button
                      className="sub-btn"
                      onClick={(e) => handleQuantityBtnClick('sub', p, e)}
                    >
                      <MinusOutlined />
                    </button>
                  </div>
                  {selectedProducts.find((i) => i.id === p.id)
                    ?.productIngredients?.length > 0 ? (
                    <div className="cart-btns-wrap">
                      <button
                        onClick={(e) => {
                          setproductIngredientsModalOpened(true);
                          setFoundProduct({
                            ...selectedProducts.find((o) => o.id === p.id),
                            productIngredients: selectedProducts.find(
                              (s) => s.id === p.id
                            )?.productIngredients
                              ? [
                                  ...selectedProducts.find((a) => a.id === p.id)
                                    .productIngredients
                                ]
                              : []
                          });
                        }}
                        className="p-ingredient-btn  ingredients-placeholder"
                      >
                        {t('Pos.Main.addOns')}
                      </button>
                      {p?.accept_discount == '1' ? (
                        <button
                          disabled={selectedInvoice}
                          onClick={(e) => {
                            setproductDiscountModalOpened(true);
                            setFoundProduct({
                              ...selectedProducts.find((o) => o.id === p.id),
                              productDiscountType:
                                selectedProducts?.find((s) => s.id === p.id)
                                  ?.productDiscountType || '0',
                              productDiscountValue:
                                selectedProducts?.find((s) => s.id === p.id)
                                  ?.productDiscountValue || '0'
                            });
                          }}
                          className="p-discount-btn"
                        >
                          {t('Pos.Main.discount')}
                        </button>
                      ) : null}
                    </div>
                  ) : (
                    <div className="cart-btns-wrap">
                      <div className="no-ingredients-tag ingredients-placeholder">
                        {t('Pos.Main.addOns')}
                      </div>
                      {p?.accept_discount == '1' ? (
                        <button
                          disabled={selectedInvoice}
                          onClick={(e) => {
                            setproductDiscountModalOpened(true);
                            setFoundProduct({
                              ...selectedProducts.find((o) => o.id === p.id),
                              productDiscountType:
                                selectedProducts?.find((s) => s.id === p.id)
                                  ?.productDiscountType || '0',
                              productDiscountValue:
                                selectedProducts?.find((s) => s.id === p.id)
                                  ?.productDiscountValue || '0'
                            });
                          }}
                          className="p-discount-btn"
                        >
                          {t('Pos.Main.discount')}
                        </button>
                      ) : null}
                    </div>
                  )}
                </div>
              ) : (p.quantityInPosStock > 0 && p.type == 1) || p.type == 2 ? (
                <div className="p-quantity-wrap add-to-cart-wrap">
                  <button
                    // ref={autoAddProduct}
                    className="btn-it-self"
                    onClick={() => {
                      handleProductSelect(p);
                      if (
                        searchTextState.length > 0 ||
                        productBarCode > 0 ||
                        productsFilter.parent_cat !== ''
                      ) {
                        setProductsFilter({});
                        setSearchTextState('');
                      }
                    }}
                  >
                    <img
                      src={addToCartImg}
                      className="cart-img"
                      alt="add from cart"
                    />
                    <span>{t('Pos.Main.addToInvoice')}</span>
                  </button>
                </div>
              ) : (
                <div className="p-quantity-wrap add-to-cart-wrap">
                  <div className="btn-it-self disabled">
                    <img
                      src={addToCartImg}
                      className="cart-img"
                      alt="add from cart"
                    />
                    <span>{t('Pos.Main.addToInvoice')}</span>
                  </div>
                </div>
              )}
            </div>
          </li>
        );
      })
    );
  };
  return (
    <div className="pos-products-wrap">
      <div className="products-change-appearance">
        {productsAppearance === 'window' && (
          <div
            className="btn-icon-wrap"
            onClick={() => setProductsAppearance('stacked')}
          >
            <PicRightOutlined />
          </div>
        )}
        {productsAppearance === 'stacked' && (
          <div
            className="btn-icon-wrap"
            onClick={() => setProductsAppearance('window')}
          >
            <TableOutlined />
          </div>
        )}
      </div>
      <ul className={`products-ul ${productsAppearance}`}>
        {renderProducts()}
      </ul>
      {productsPagination && step == 1 && (
        <Pagination
          style={{
            marginTop: 32
          }}
          defaultCurrent={1}
          current={productsPagination?.current_page}
          pageSizeOptions={[10, 20, 50, 100]}
          pageSize={productsPagination?.per_page}
          total={productsPagination?.total}
          onChange={(page, pageSize) => {
            console.log(pageSize);
            setFetchProductsCount((prev) => prev + 1);
            setProductsFilter((prev) => ({ ...prev, per_page: pageSize }));
            history.push(`${routerLinks.posPage}?page=${page}`);
          }}
          hideOnSinglePage={false}
          showSizeChanger={true}
        />
      )}
      {/* {
        <pre style={{ direction: 'ltr' }}>
          {JSON.stringify(products, null, 2)}
        </pre>
      } */}
    </div>
  );
};

export default RenderProducts;
