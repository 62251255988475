import DeleteIcon from '../../common/icons/DeleteIcon';
import EditIcon from '../../common/icons/EditIcon';
import { Button, Popconfirm, Tooltip } from 'antd';
import successNotification from '../../utils/successNotification';
import errorNotification from '../../utils/errorNotification';
import deleteSupplierApi from '../../apis/store-apis/suppliers/deleteSupplierApi';
import { Link as RouterLink } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import routerLinks from '../../components/app/routerLinks';
const tableColumns = (
  allFetchedClients,
  setModalOpened,
  setFetchCount,
  setSelectedClient,
  setIsLoading,
  token,
  t
) => {
  const handleEditUser = (record) => {
    const found = allFetchedClients.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedClient({ ...found });
      setModalOpened(true);
    }
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteSupplierApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('suppliers.successNotification'),
          message: t('suppliers.deleteSupplierSuccess')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('suppliers.errorNotification'),
          message: res?.data?.message
            ? res.data.message
            : t('suppliers.tryLaterMessage')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('suppliers.errorOccurred'),
        message: t('suppliers.tryLaterMessage2')
      });
    }
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditUser(record)}>
          <EditIcon />
          {t('suppliers.update')}
        </div>
        <Popconfirm
          title={t('suppliers.deleteConfirm')}
          onConfirm={() => handleDeleteRequest(record.id)}
          okText={t('suppliers.yes')}
          cancelText={t('suppliers.no')}
        >
          <Tooltip title={t('suppliers.deleteSupplier')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      title: t('suppliers.code'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('suppliers.supplierName'),
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text desc-wrap">
            <span className="name-it-self">{record.name}</span>
          </div>
        );
      },
      sorter: (a, b) => {
        let nameA = a.name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }
    },
    {
      title: t('suppliers.email'),
      dataIndex: 'email',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">
              {record?.email ? record.email : '----'}
            </span>
          </div>
        );
      }
    },
    {
      title: t('suppliers.phone'),
      dataIndex: 'phone',
      // width: 192,
      render: (_, record) => {
        return <div className="row-cell row-text">{record.phone}</div>;
      }
    },
    {
      title: t('suppliers.receipts'),
      render: (_, record) => {
        return (
          <RouterLink
            className="row-cell row-circled-btn"
            to={routerLinks.supplierInstallmentsRoute(record.id)}
          >
            <Tooltip title={t('suppliers.receipts')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
              />
            </Tooltip>
          </RouterLink>
        );
      }
    },
    {
      title: t('suppliers.settings'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
