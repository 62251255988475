import axios from 'axios';

const deleteNoteApi = async (token, id) => {
  try {
    const formData = new FormData();
    formData.append('id', id);

    const res = await axios.post(
      '/Note/delete',
      formData, // Use formData as the data payload
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data', // Set Content-Type to multipart/form-data
          Authorization: 'Bearer ' + token,
          lang: 'ar'
        }
      }
    );
    return res;
  } catch (error) {
    throw error;
  }
};

export default deleteNoteApi;
