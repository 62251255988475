import React, { useContext } from 'react';
import { Modal } from 'antd';
import TableForm from './TableForm';
import PosCashPaymentsContext from '../../../contexts/pos-cash-payments/PosCashPaymentsContext';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import { useTranslation } from 'react-i18next';

const SettingsWorkPlaceModal = ({ departments }) => {
  const {
    modalOpenedCashIn,
    setModalOpenedCashIn,
    selectedCash,
    setSelectedCash
  } = useContext(PosCashPaymentsContext);
  const { t } = useTranslation();

  return (
    <Modal
      transitionName=""
      className="work-places-modal shared-custom-modal"
      width="90%"
      style={{ maxWidth: '642px' }}
      title={
        selectedCash
          ? t('Inventory.CashIn.modifyCashIn')
          : t('Inventory.CashIn.addCashIn')
      }
      visible={modalOpenedCashIn}
      onOk={() => {
        setModalOpenedCashIn(false);
        setSelectedCash(null);
      }}
      onCancel={() => {
        setModalOpenedCashIn(false);
        setSelectedCash(null);
      }}
      footer={false}
    >
      <TableForm departments={departments} />
    </Modal>
  );
};

export default SettingsWorkPlaceModal;
