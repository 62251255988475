import { Redirect, Route } from 'react-router-dom';
import AppLayout from '../components/app/Layout';
import routerLinks from '../components/app/routerLinks';
import { SuppliersProvider } from '../contexts/suppliers-context/SuppliersContext';
import { ClientsProvider } from '../contexts/clients-context/ClientsContext';
import { UpgradeProvider } from '../contexts/upgrade-context/UpgradeContext';
import UpgradePage from '../pages/upgrade-page/UpgradePage';
const protectMe = (path, component, key, redirectRoute, loggedIn, expire) => {
  const renderComp = () => {
    if (loggedIn && expire === 0) {
      return (
        <SuppliersProvider>
          <ClientsProvider>
            <AppLayout>{component}</AppLayout>
          </ClientsProvider>
        </SuppliersProvider>
      );
    } else if (loggedIn && expire === 1) {
      return (
        <UpgradeProvider>
          <div className="custom-container">
            <UpgradePage />
          </div>
        </UpgradeProvider>
      );
    } else if (!loggedIn) {
      return (
        <Redirect to={redirectRoute ? redirectRoute : routerLinks?.signupPage} />
      );
    } else return null;
  };

  return (
    <Route exact path={path} key={key}>
      {renderComp()}

      {/* {loggedIn ? (
        component
      ) : (
        <Redirect to={redirectRoute ? redirectRoute : routerLinks.signupPage} />
      )} */}
    </Route>
  );
};

export default protectMe;
