/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import slugify from 'slugify';
import Logo from '../../common/logo/Logo';
import MainAppBarContext from '../../contexts/main-app-bar-context/MainAppBarProvider';
import MainAppBarSideMenu from './MainAppBarSubMenu';
import useSignOut from '../../custom-hooks/useSignout';
import { LoadingOutlined } from '@ant-design/icons';
import './MainAppBar.scss';
import SignOutIcon from '../../common/icons/SignoutIcon';

const MainAppBar = () => {
  const { menuVisible, useOutsideAlerter, setMenuVisible } =
    useContext(MainAppBarContext);
  const { pathname } = useLocation();
  const { links, setLinks, setSelectedLink, activeLink } =
    useContext(MainAppBarContext);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const handleMenuBtnClick = (id) => {
    setLinks((prevState) => {
      return prevState.map((menu) =>
        menu.id === id
          ? { ...menu, visibleSubMenu: true }
          : { ...menu, visibleSubMenu: false }
      );
    });

    const foundLink = links.find((link) => link.id === id);
    if (foundLink) setSelectedLink({ ...foundLink, visibleSubMenu: true });
  };

  const ref = useRef();
  const handleClickOutsideMenu = (event) => {
    if (ref && ref.current && ref.current.contains(event.target)) {
      // inside
      // to be added later
    } else {
      // outside
      event.stopPropagation();
      setLinks((prevState) => {
        return prevState.map((obj) => ({ ...obj, visibleSubMenu: false }));
      });
      setSelectedLink(null);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutsideMenu);
    document.addEventListener('keyup', handleClickOutsideMenu);

    return () => {
      document.removeEventListener('click', handleClickOutsideMenu);
      document.removeEventListener('keyup', handleClickOutsideMenu);
    };
  }, []);

  const renderMainLinks = () => {
    return (
      <ul className="app-bar-ul">
        {links?.map((item) => {
          const { id, isLink, to, name, icon, visibleSubMenu, subMenu } = item;
          return isLink ? (
            <RouterLink
              to={to}
              className="link-li"
              key={id}
              onClick={() => {
                setMenuVisible(false);
                handleMenuBtnClick(id);
              }}
            >
              <div
                className={`nav-link
                ${
                  slugify(toString(pathname)) === slugify(toString(to))
                    ? 'active-link'
                    : ''
                }
                ${visibleSubMenu ? 'active-link' : ''}
                ${activeLink ? 'active-link' : ''}`}
              >
                <span className="icon-span">
                  {icon &&
                    icon(
                      slugify(toString(pathname)) === slugify(toString(to)) ||
                        visibleSubMenu ||
                        (activeLink &&
                          slugify(toString(activeLink?.to)) ===
                            slugify(toString(to)))
                        ? '#fff'
                        : ''
                    )}
                </span>
                <span className="name-span">{name}</span>
              </div>
              <MainAppBarSideMenu
                visibleSubMenu={visibleSubMenu}
                name={id}
                menuItems={subMenu}
              />
            </RouterLink>
          ) : (
            <li
              className="link-li"
              key={id}
              onClick={() => handleMenuBtnClick(id)}
            >
              <div
                className={`nav-link
                ${
                  slugify(toString(pathname)) === slugify(toString(to))
                    ? 'active-link'
                    : ''
                }
                ${visibleSubMenu ? 'active-link' : ''}
                ${
                  activeLink &&
                  slugify(toString(activeLink?.to)) === slugify(toString(to))
                    ? 'active-link'
                    : ''
                }
                `}
                // to={path}
              >
                <span className="icon-span">
                  {icon &&
                    icon(
                      slugify(toString(pathname)) === slugify(toString(to)) ||
                        visibleSubMenu ||
                        (activeLink &&
                          slugify(toString(activeLink?.to)) ===
                            slugify(toString(to)))
                        ? '#fff'
                        : ''
                    )}
                </span>
                <span className="name-span">{name}</span>
              </div>
              <MainAppBarSideMenu
                visibleSubMenu={visibleSubMenu}
                name={id}
                menuItems={subMenu}
              />
            </li>
          );
        })}
      </ul>
    );
  };

  const { signMeOut, isLoadingSignOut } = useSignOut();
  const handleSignOut = () => {
    signMeOut();
  };
  return (
    <div className="appbar-wrapper-div" ref={wrapperRef}>
      <div
        className={`main-app-bar-wrap ${menuVisible ? 'visible-lg' : ''}`}
        ref={ref}
      >
        <Logo className="side-menu-logo" />
        {renderMainLinks()}
        <div className="settings-signout-wrap" onClick={handleSignOut}>
          {isLoadingSignOut ? (
            <LoadingOutlined style={{ color: '#b5b5b5', fontSize: 24 }} />
          ) : (
            <SignOutIcon />
          )}
        </div>
      </div>
    </div>
  );
};

export default MainAppBar;
