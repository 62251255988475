import axios from 'axios';

const getReturnInvoiceApi = async (token, invoiceId, type) => {
  try {
    const res = await axios.get(
      `/BuyInvoice/returned-invoice?id=${invoiceId}&type=${type ? type : ''}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token
          // lang: 'ar'
        }
      }
    );

    return res;
  } catch (error) {
    throw error;
  }
};

export default getReturnInvoiceApi;
