import { Button, Popconfirm, Popover, Tooltip } from 'antd';
import DeleteIcon from '../../../common/icons/DeleteIcon';
import EditIcon from '../../../common/icons/EditIcon';
import countWords from '../../../utils/countWords';
import trimWords from '../../../utils/trimWords';
import { ReadOutlined } from '@ant-design/icons';
import AddDocumentIcon from '../../../common/icons/AddDocumentIcon';
import { Link as RouterLink } from 'react-router-dom';
import routerLinks from '../../../components/app/routerLinks';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import deleteCategoryApi from '../../../apis/settings-apis/categories-apis/deleteCategoryApi';

const tableColumns = (
  setModalOpened,
  setFetchCount,
  setSelectedCategory,
  setIsLoading,
  t,
  token
) => {
  const handleEditClick = (obj) => {
    setSelectedCategory(obj);
    setModalOpened(true);
  };

  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteCategoryApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Inventory.Categories.addedSuccessfully'),
          message: t('Inventory.Categories.categorySuccessfullyDeleted')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Inventory.Categories.errorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Inventory.Categories.pleaseTryLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Inventory.Categories.errorOccurred'),
        message: t('Inventory.Categories.tryLater')
      });
    }
  };

  const renderActions = (obj) => {
    return (
      <div className="action-btns-wrap">
        <div className="edit-btn" onClick={() => handleEditClick(obj)}>
          <EditIcon />
          {t('Inventory.Categories.edit')}
        </div>
        <Popconfirm
          title={t('Inventory.Categories.areyouSureToDelete')}
          onConfirm={() => handleDeleteRequest(obj.id)}
          okText={t('Inventory.Categories.yes')}
          cancelText={t('Inventory.Categories.no')}
        >
          <Tooltip title={t('Inventory.Categories.delete')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };

  return [
    {
      title: t('Inventory.Categories.categoryCode'),
      dataIndex: 'id',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span># {record.id}</span>
          </div>
        );
      }
    },
    {
      title: t('Inventory.Categories.categoryName'),
      dataIndex: '',
      // width: 192,
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record.name}</span>
          </div>
        );
      },
      sorter: (a, b) => {
        let nameA = a.name.toUpperCase(); // ignore upper and lowercase
        let nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      }
    },
    // {
    //   title: 'إجمالى المنتجات',
    //   dataIndex: 'totalProductsCount',
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         <span>{record.totalProductsCount}</span>
    //       </div>
    //     );
    //   },
    //   sorter: (a, b) => a.totalProductsCount - b.totalProductsCount
    // },
    {
      title: t('Inventory.Categories.categoryDescription'),
      dataIndex: 'categoryDescription',
      render: (_, record) => {
        if (record.categoryDescription) {
          const wordsCount = countWords(String(record.categoryDescription));
          if (wordsCount > 3) {
            return (
              <Popover
                title={t('Inventory.Categories.readMore')}
                content={String(record.categoryDescription)}
              >
                <Button type="text">
                  <p className="has-read-more">
                    <span>
                      {trimWords(String(record.categoryDescription), 3)}
                    </span>
                    <ReadOutlined
                      className="read-more-book"
                      style={{ margin: '0 4px' }}
                    />
                  </p>
                </Button>
              </Popover>
            );
          } else
            return (
              <div className="row-cell row-text">
                {String(record.categoryDescription)}
              </div>
            );
        } else {
          return '----';
        }
      }
    },
    {
      title: t('Inventory.Categories.subcategories'),
      render: (_, record) => {
        return (
          <div className="row-cell row-action-btn">
            <RouterLink
              className="custom-btn"
              to={routerLinks.settingsSubCategoriesPage(record.id)}
            >
              <AddDocumentIcon />
              {t('Inventory.Categories.subcategories')}
            </RouterLink>
          </div>
        );
      }
    },
    {
      title: t('Inventory.Categories.settings'),
      // dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }
  ];
};

export default tableColumns;
