/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Pagination, Table } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import PosCashPaymentsContext from '../../../contexts/pos-cash-payments/PosCashPaymentsContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import cashPayments from '../../../apis/pos-api/cash-payments/cashPayments';
import tableColumns from './tableColumns';
import cashPaymentsProducts from '../../../apis/cash-payments-products/cashPaymentsProducts';
import { useTranslation } from 'react-i18next';

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const PageTable = () => {
  const {
    isLoading,
    setIsLoading,
    fetchCount,
    setFetchCount,
    allFetchedCashOut,
    setAllFetchedCashOut,
    tablePagination,
    setTablePagination,
    setSelectedCash,
    setModalOpened,
    setModalOpenedCashIn,
    printModalCash,
    setPrintModalCash
  } = useContext(PosCashPaymentsContext);
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [tableDataSource, setTableDataSource] = useState([]);
  const mapDataSource = (data) => {
    return data.map((obj, index) => ({
      ...obj,
      key: obj.id
    }));
  };

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setIsLoading(true);

      customApiRequest(
        cashPaymentsProducts(user?.token || user?.employee?.token, {
          filter: 2,
          page: tablePagination.current_page
        }),
        (res) => {
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data?.data?.length >= 0) {
            const data = res?.data?.data?.data;
            setAllFetchedCashOut(data);

            if (res?.data?.data?.pagination) {
              setTablePagination(res.data.data.pagination);
            }
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }
    return () => {
      isMounted = false;
    };
  }, [fetchCount]);

  // map all data to dataSource
  useEffect(() => {
    let isMounted = true;
    if (allFetchedCashOut?.length >= 0) {
      if (isMounted) {
        setTableDataSource(mapDataSource(allFetchedCashOut));
      }
    }

    return () => {
      isMounted = false;
    };
  }, [fetchCount, allFetchedCashOut, allFetchedCashOut?.length]);

  return (
    <>
      <Table
        // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
        pagination={false}
        className="antd-custom-table"
        dataSource={tableDataSource}
        columns={tableColumns(
          allFetchedCashOut,
          setSelectedCash,
          setIsLoading,
          setModalOpenedCashIn,
          setFetchCount,
          setModalOpened,
          printModalCash,
          setPrintModalCash,
          t,
          user?.token || user?.employee?.token
        )}
        loading={isLoading}
        // scroll={{ x: 400 }}
        footer={false}
      />

      {tablePagination && (
        <Pagination
          style={{ display: 'flex', justifyContent: 'center' }}
          defaultCurrent={1}
          current={tablePagination.current_page}
          pageSize={tablePagination.per_page}
          total={tablePagination.total}
          // itemRender={itemRender}

          onChange={(page, pageSize) => {
            setTablePagination({ ...tablePagination, current_page: page });
            // setTablePagination(...tablePagination , {current_page:page})
            setFetchCount((prev) => prev + 1);
          }}
          hideOnSinglePage={true}
        />
      )}
    </>
  );
};

export default PageTable;
