/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import './ProductDiscountForm.scss';
import { store } from 'react-notifications-component';
import { Button } from '@mui/material';
import { useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const ProductDiscountForm = () => {
  const { t } = useTranslation();
  const {
    foundProduct,
    setFoundProduct,
    setSelectedProducts,
    setproductDiscountModalOpened
  } = useContext(PosInvoiceContext);
  const [isDiscount, setIsDiscount] = useState(false);
  const handleDiscountRadioChange = (e) => {
    setFoundProduct((prev) => {
      return {
        ...prev,
        productDiscountType: e?.target?.value,
        productDiscountValue: '0'
      };
    });
  };

  const handleInvoiceDiscountValue = (e) => {
    if (e.target.value.match(/^(\d+)?(\.\d+)?$/) && e.target.value >= 0) {
      setFoundProduct((prev) => {
        return {
          ...prev,
          productDiscountValue:
            prev?.productInCartQuantity == 0 ? 0 : e?.target?.value
        };
      });
    }
    // e.preventDefault();
  };

  const handleApplyDiscount = () => {
    if (foundProduct?.productDiscountValue > 0) {
      setIsDiscount(true);
      setproductDiscountModalOpened(false);
      store.addNotification({
        title: t('Pos.Main.confirmDiscount'),
        message: t('Pos.Main.discountConfirmed'),
        type: 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 3000,
          showIcon: true,
          onScreen: true
        }
      });
    } else if (foundProduct?.productDiscountValue == 0) {
      store.addNotification({
        title: t('Pos.Main.errorOccurred'),
        message: t('Pos.Main.discountValueNotzAdded'),
        type: 'danger',
        insert: 'bottom',
        container: 'bottom-left',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 3000,
          showIcon: true,
          onScreen: true
        }
      });
    }
  };

  React.useEffect(() => {
    if (setSelectedProducts?.length > 0) {
      setSelectedProducts((prev) =>
        prev.map((p) =>
          p?.id === foundProduct?.id ? { ...foundProduct } : { ...p }
        )
      );
    }
  }, [foundProduct]);

  const renderDiscountSection = () => {
    return (
      <div className="invoice-discount-wrap">
        <div className="discount-title bold-font">
          {t('Pos.Main.chooseDiscountOnProduct')}
        </div>
        <div className="discount-radios-wrap">
          {/* <label
            className={`radio-input-label ${
              foundProduct?.productDiscountType != 1 &&
              foundProduct?.productDiscountType != 2
                ? 'selected'
                : ''
            }`}
          >
            <input
              // disabled={selectedInvoice ? true : false}
              type="radio"
              name="invoice_discount_type"
              value="0"
              onChange={(e) => handleDiscountRadioChange(e)}
              checked={
                foundProduct?.productDiscountType != 1 &&
                foundProduct?.productDiscountType != 2
              }
            />
            بدون خصــم
          </label> */}
          <label
            className={`radio-input-label ${
              foundProduct?.productDiscountType == '1' ? 'selected' : ''
            }`}
          >
            <input
              // disabled={selectedInvoice ? true : false}
              type="radio"
              name="invoice_discount_type"
              value="1"
              onChange={(e) => handleDiscountRadioChange(e)}
              checked={foundProduct?.productDiscountType == '1'}
            />
            {t('Pos.Main.fixedNumberDiscount')}
          </label>
          <label
            className={`radio-input-label ${
              foundProduct?.productDiscountType == '2' ? 'selected' : ''
            }`}
          >
            <input
              // disabled={selectedInvoice ? true : false}
              type="radio"
              name="invoice_discount_type"
              value="2"
              onChange={(e) => handleDiscountRadioChange(e)}
              checked={foundProduct?.productDiscountType == '2'}
            />
            {t('Pos.Main.percentageDiscount')}
          </label>
        </div>

        {foundProduct?.productDiscountType == '1' && (
          <label className="discount-value-label-wrap">
            <span className="label-span">
              {t('Pos.Main.enterDiscountPrice')}
            </span>
            <input
              // disabled={selectedInvoice ? true : false}
              name="invice_discount_value"
              type="number"
              value={foundProduct?.productDiscountValue}
              onChange={(e) => handleInvoiceDiscountValue(e)}
            />
            <Button
              onClick={() => handleApplyDiscount()}
              style={{
                backgroundColor: '#2e4652',
                color: '#fff',
                width: '120px',
                height: '48px'
              }}
            >
              {t('Pos.Main.discount')}
            </Button>
          </label>
        )}
        {foundProduct?.productDiscountType == '2' && (
          <label className="discount-value-label-wrap">
            <span className="label-span">
              {t('Pos.Main.enterDiscountPercentage')}
            </span>
            <input
              // disabled={selectedInvoice ? true : false}
              name="invice_discount_value"
              type="number"
              value={foundProduct?.productDiscountValue}
              onChange={(e) => handleInvoiceDiscountValue(e)}
            />
            <Button
              onClick={() => handleApplyDiscount()}
              style={{
                backgroundColor: '#2e4652',
                color: '#fff',
                width: '120px',
                height: '48px'
              }}
            >
              {t('Pos.Main.discount')}
            </Button>
          </label>
        )}
      </div>
    );
  };

  if (foundProduct)
    return (
      <div className="product-discount-fields-wrap">
        {/* <div className="checkboxs-label">إختار الاضافات</div> */}
        {renderDiscountSection()}
      </div>
    );

  return null;
};

export default ProductDiscountForm;
