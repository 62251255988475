import axios from 'axios';

const deleteSinglePriceOfferApiNew = async (token, values, defaultUrl) => {
  try {
    let url = '/offerPrice/delete';
    const formData = new FormData();
    formData.append('id', values);
    const res = await axios.post(defaultUrl || url, formData, {
      headers: {
        lang: 'ar',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default deleteSinglePriceOfferApiNew;
