import axios from 'axios';

const getAllClientsApi = async (token, filterValues, excel, is_tax) => {
  let url;
  if (excel) {
    url = `User/all?page=${
      filterValues.page ? filterValues.page : ''
    }&searchText=${filterValues.searchText ? filterValues.searchText : ''}`;
  } else {
    if (!filterValues) {
      url = `User/all?is_tax=${is_tax ? is_tax : ''}`;
    } else {
      url = `User/all?test=1&page=${
        filterValues.page ? filterValues.page : ''
      }&searchText=${
        filterValues.searchText ? filterValues.searchText : ''
      }&is_tax=${is_tax ? is_tax : ''}`;
    }
  }

  try {
    const res = await axios.get(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      }
    });

    return res;
  } catch (error) {
    throw error;
  }
};

export default getAllClientsApi;
