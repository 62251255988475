/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import getPosSystemSettingsApi from '../apis/pos-settings-apis/PosSettingsApi';
import UserContext from '../contexts/user-context/UserProvider';
import useCustomApiRequest from './useCustomApiRequest';
const usePosSettings = () => {
  const [posSettings, setPosSettings] = useState(null);
  const { user } = useContext(UserContext);

  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      customApiRequest(
        getPosSystemSettingsApi(user?.token || user?.employee?.token),
        (res) => {
          if (res?.status === 200 && res?.data?.data) {
            const data = res.data.data;
            setPosSettings(data);
          }
        },
        (error) => {}
      );
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return posSettings;
};

export default usePosSettings;
