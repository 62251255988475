import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import createPriceOfferApiNew from '../../../apis/new-price-offers-apis/createPriceOfferApiNew';
import getAllProductsWithSelect from '../../../apis/store-apis/products/getAllProductsWithSelect';
import AntdRadioGroup from '../../../common/antd-form-components/AntdRadioGroup';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import CustomAntdDatePicker from '../../../common/antd-form-components/CustomAntdDatePicker';
import AddToCartIcon from '../../../common/icons/AddToCartIcon';
import NewClientModal from '../../../components/new-client-modal/NewClientModal';
import ClientsContext from '../../../contexts/clients-context/ClientsContext';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useClients from '../../../custom-hooks/useClients';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import useIngredients from '../../../custom-hooks/useIngredients';
import useSuppliers from '../../../custom-hooks/useSuppliers';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import useWarehouses from '../../../custom-hooks/useWarehouses';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import RenderProductsFieldsProducts from './RenderProductsFieldsProducts';
import './TableProductsForm.scss';
import {
  renderFieldsTotalValue,
  // renderReceiptTotalValueAfterTax,
  renderTotalPriceAfterExtraPrices
} from './receiptCalculations';
import renderExtraPricesFields from './renderExtraPricesFields';
import renderIngredientsFields from './renderIngredientFieldsProducts';
import getIncommingInvoiceSchema from './tableSchemaProducts';
const TableProductsForm = () => {
  const systemSettings = useSystemSettings();
  const {
    setIsLoading,
    setModalOpened,
    setFetchCount,
    selectedReceipt,
    isSubmittingForm,
    setIsSubmittingForm,
    allProducts,
    setFilterValues,
    fetchNewCount,
    setFetchNewCount,
    filterValues,
    setAllProducts
  } = useContext(OutgoingPurchaseInvoiceContext);

  const { setClientModalOpened, clientModalOpened, selectedClientId } =
    useContext(ClientsContext);
  const { allSuppliers } = useSuppliers();
  const { allClients, setAllClientsFetchCount } = useClients();
  const allIngredients = useIngredients();
  const allWarehouses = useWarehouses();
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    watch,
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(getIncommingInvoiceSchema(selectedReceipt, t)),
    mode: 'all',
    defaultValues: {
      manualNum: '',
      user_id: '',
      products: [
        {
          product_id: '',
          returnQuantity: '',
          quantity: '',
          warehouse_id: '',
          expirationDate: '',
          discount_type: 'no_discount',
          discount_price: '',
          discount_percentage: '',
          price: '',
          tax: '0',
          desc: '',
          including_tax: ''
        }
      ],
      ingredients: [
        {
          ingredient_id: '',
          returnQuantity: '',
          quantity: '',
          warehouse_id: '',
          expirationDate: '',
          discount_type: 'no_discount',
          discount_price: '',
          discount_percentage: '',
          price: '',
          tax: '0'
        }
      ],
      details_type: '1',
      due_date: null,
      expirationDate: null,
      //
      discount_type: 'no_discount', // => 1 is fixed price && 2 is percentage price
      discount_price: '',
      discount_percentage: '',
      //
      // extra_prices: [
      //   {
      //     price: '',
      //     title: ''
      //   }
      // ],
      //
      // pay_type: '',
      installments: [
        {
          price: '',
          date: null,
          note: '',
          status: ''
        }
      ],
      bankAccount: {
        accountNumber: '',
        price: ''
      },
      //
      note: ''
    }
  });
  const {
    fields: productsFields,
    append: appendProductField,
    remove: removeProductField
  } = useFieldArray({
    control,
    name: 'products'
  });
  const {
    fields: ingredientsFields,
    append: appendIngredientField,
    remove: removeIngredientField
  } = useFieldArray({
    control,
    name: 'ingredients'
  });
  const {
    fields: extraPricesFields,
    append: appendExtraPriceField,
    remove: removeExtraPriceField
  } = useFieldArray({
    control,
    name: 'extra_prices'
  });
  const {
    fields: installmentsFields,
    append: appendInstallmentField,
    remove: removeInstallmentField
  } = useFieldArray({
    control,
    name: 'installments'
  });

  const [receiptRadiosArr, setReceiptRadiosArr] = React.useState([]);
  useEffect(() => {
    if (selectedReceipt?.detailsType == '1') {
      setReceiptRadiosArr([
        {
          title: t('Quotations.products'),
          value: '1'
        }
      ]);
    } else if (selectedReceipt?.detailsType == '2') {
      setReceiptRadiosArr([
        {
          title: t('Quotations.productIngredients'),
          value: '2'
        }
      ]);
    } else {
      setReceiptRadiosArr([
        {
          title: t('Quotations.products'),
          value: '1'
        }
        // {
        //   title: 'مكونات منتجات',
        //   value: '2'
        // }
      ]);
    }
  }, [selectedReceipt]);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    const mappedData = {};
    // if (data.manualNum) mappedData.manualNum = data.manualNum;
    if (data.user_id) mappedData.user_id = data.user_id;
    if (data.note) mappedData.note = data.note;
    if (data.due_date)
      mappedData.dueDate = moment(data.due_date).format('YYYY-MM-DD');
    if (data.expirationDate)
      mappedData.expirationDate = moment(data.expirationDate).format(
        'YYYY-MM-DD'
      );
    if (data.details_type) mappedData.detailsType = data.details_type;
    if (data.products?.length > 0 && data.details_type === '1') {
      // prdoucts
      let mappedProducts = data.products.map((pro) => {
        if (
          pro.product_id &&
          (pro.discount_type == '1' || pro.discount_type == '2')
        )
          return {
            id: pro.product_id,
            quantity: pro.quantity,
            stock_id: pro.warehouse_id,
            discountType: pro.discount_type,
            discountValue:
              pro.discount_type === '1'
                ? pro.discount_price
                : pro.discount_percentage,
            expirationDate: pro.expirationDate
              ? moment(pro.expirationDate).format('YYYY-MM-DD')
              : null,
            price: pro.price ? pro.price : '',
            tax: pro.tax,
            desc: pro.desc,
            including_tax: pro.including_tax
          };
        else if (
          pro.product_id &&
          !(pro.discount_type == '1' || pro.discount_type == '2')
        )
          return {
            id: pro.product_id,
            quantity: pro.quantity,
            stock_id: pro.warehouse_id,
            expirationDate: pro.expirationDate
              ? moment(pro.expirationDate).format('YYYY-MM-DD')
              : null,
            price: pro.price ? pro.price : '',
            tax: pro.tax,
            desc: pro.desc,
            including_tax: pro.including_tax
          };
        return null;
      });

      if (selectedReceipt) {
        mappedProducts = data.products.map((pro, index) => {
          if (
            pro.product_id &&
            (pro.discount_type == '1' || pro.discount_type == '2')
          )
            return {
              id: pro.product_id,
              quantity: pro.quantity,
              parent_quantity:
                selectedReceipt?.details?.length > 0
                  ? selectedReceipt.details[index]?.quantity
                  : 0,
              stock_id: pro.warehouse_id,
              discountType: pro.discount_type,
              discountValue:
                pro.discount_type === '1'
                  ? pro.discount_price
                  : pro.discount_percentage,
              expirationDate: pro.expirationDate
                ? moment(data.expirationDate).format('YYYY-MM-DD')
                : null,
              price: pro.price ? pro.price : '',
              tax: pro?.tax,
              desc: pro?.desc,
              including_tax: pro.including_tax
            };
          else if (
            pro.product_id &&
            !(pro.discount_type == '1' || pro.discount_type == '2')
          )
            return {
              id: pro.product_id,
              quantity: pro.quantity,
              parent_quantity:
                selectedReceipt?.details?.length > 0
                  ? selectedReceipt.details[index]?.quantity
                  : 0,
              stock_id: pro.warehouse_id,
              expirationDate: pro.expirationDate
                ? moment(data.expirationDate).format('YYYY-MM-DD')
                : null,
              price: pro?.price ? pro?.price : '',
              tax: pro?.tax,
              desc: pro?.desc,
              including_tax: pro?.including_tax
            };
          return null;
        });
      }
      if (mappedProducts?.length > 0 && mappedProducts[0] !== null) {
        mappedData.details = JSON.stringify(mappedProducts);
      }
    }
    if (data.ingredients?.length > 0 && data.details_type == '2') {
      // ingredients
      let mappedIngredients = data.ingredients.map((ing) => {
        if (
          ing.ingredient_id &&
          (ing.discount_type == '1' || ing.discount_type == '2')
        )
          return {
            id: ing.ingredient_id,
            quantity: ing.quantity,
            stock_id: ing.warehouse_id,
            discountType: ing.discount_type,
            discountValue:
              ing.discount_type === '1'
                ? ing.discount_price
                : ing.discount_percentage,
            expirationDate: ing.expirationDate
              ? moment(data.expirationDate).format('YYYY-MM-DD')
              : null,
            price: ing.price ? ing.price : '',
            tax: ing.tax
          };
        else if (
          ing.ingredient_id &&
          !(ing.discount_type == '1' || ing.discount_type == '2')
        )
          return {
            id: ing.ingredient_id,
            quantity: ing.quantity,
            stock_id: ing.warehouse_id,
            expirationDate: ing.expirationDate
              ? moment(data.expirationDate).format('YYYY-MM-DD')
              : null,
            price: ing.price ? ing.price : '',
            tax: ing.tax
          };

        return null;
      });
      if (selectedReceipt) {
        mappedIngredients = data.ingredients.map((ing, index) => {
          if (
            ing.ingredient_id &&
            (ing.discount_type == '1' || ing.discount_type == '2')
          )
            return {
              id: ing.ingredient_id,
              quantity: ing.quantity,
              parent_quantity:
                selectedReceipt?.details?.length > 0
                  ? selectedReceipt.details[index]?.quantity
                  : 0,
              stock_id: ing.warehouse_id,
              discountType: ing.discount_type,
              discountValue:
                ing.discount_type === '1'
                  ? ing.discount_price
                  : ing.discount_percentage,
              expirationDate: ing.expirationDate
                ? moment(data.expirationDate).format('YYYY-MM-DD')
                : null,
              price: ing.price ? ing.price : '',
              tax: ing.tax
            };
          else if (
            ing.ingredient_id &&
            !(ing.discount_type == '1' || ing.discount_type == '2')
          )
            return {
              id: ing.ingredient_id,
              quantity: ing.quantity,
              parent_quantity:
                selectedReceipt?.details?.length > 0
                  ? selectedReceipt.details[index]?.quantity
                  : 0,
              stock_id: ing.warehouse_id,
              expirationDate: ing.expirationDate
                ? moment(data.expirationDate).format('YYYY-MM-DD')
                : null,
              price: ing.price ? ing.price : '',
              tax: ing.tax
            };

          return null;
        });
      }

      if (mappedIngredients?.length > 0 && mappedIngredients[0] !== null) {
        mappedData.details = JSON.stringify(mappedIngredients);
      }
    }

    mappedData.type = 1; // => buy receipt type
    mappedData.invoice_type = 2; //  1 => incoming purchase invoice, 2 => outgoing purchase invoice

    if (data.extra_prices?.length > 0) {
      const mappedExtraPrices = data.extra_prices.map((extra) => {
        if (extra.price && extra.title) {
          return {
            price: extra.price,
            desc: extra.title
          };
        }
        return null;
      });
      if (mappedExtraPrices?.length > 0 && mappedExtraPrices[0] !== null) {
        mappedData.addPrices = JSON.stringify(mappedExtraPrices);
      }
    }
    /////////////////
    if (data.discount_type) {
      if (data.discount_type == '1' || data.discount_type == '2') {
        mappedData.discountType = data.discount_type;
      }
      if (data.discount_price && data.discount_type === '1') {
        mappedData.discountValue = data.discount_price;
      } else if (data.discount_percentage && data.discount_type === '2') {
        mappedData.discountValue = data.discount_percentage;
      }
    }
    /////////////
    // if (data.pay_type) {
    //   mappedData.paymentType = data.pay_type;
    //   if (data.pay_type == '2' && data.installments?.length > 0) {
    //     const mappedInstallments = data.installments.map((ins) => {
    //       return {
    //         price: ins.price ? ins.price : null,
    //         note: ins.note ? ins.note : null,
    //         date: ins.date ? moment(ins.date).format('YYYY-MM-DD') : null,
    //         status: ins.status ? ins.status : null
    //       };
    //     });
    //     if (mappedInstallments?.length > 0 && mappedInstallments[0] !== null) {
    //       mappedData.installments = JSON.stringify(mappedInstallments);
    //     }
    //   } else if (mappedData.paymentType == '5') {
    //     if (data?.bankAccount?.accountNumber) {
    //       mappedData.bankName = data.bankAccount.accountNumber;
    //     }
    //     if (data?.bankAccount.price) {
    //       mappedData.cheekNum = data.bankAccount.price;
    //     }
    //   }
    // }

    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedReceipt) {
      mappedData.id = selectedReceipt.id;
      mappedData.type = 1; // => 2 is the type of return receipt
      customApiRequest(
        createPriceOfferApiNew(
          '/offerPrice/update',
          user?.token,
          mappedData,
          true
        ),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Messages.addedSuccessfully'),
              message: t('Messages.Modifiedsuccessfully')
            });
          } else {
            errorNotification({
              title: t('Messages.ErrorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Messages.TryAgainLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message:
              error?.response?.data?.message || t('Messages.TryAgainLater')
          });
        }
      );
    } else {
      customApiRequest(
        createPriceOfferApiNew('/offerPrice/create', user?.token, mappedData),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpened(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Messages.addedSuccessfully'),
              message: t('Messages.addsuccessfully')
            });
          } else {
            errorNotification({
              title: t('Messages.ErrorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Messages.TryAgainLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message:
              error?.response?.data?.message || t('Messages.TryAgainLater')
          });
        }
      );
    }
  };

  useEffect(() => {
    customApiRequest(
      getAllProductsWithSelect(user?.token, filterValues),
      (res) => {
        if (res?.status === 200 && res?.data?.data?.length >= 0) {
          const data = res.data.data;
          setAllProducts(data);
        }
      },
      (error) => {
        // setIsLoadingProducts(false);
      }
    );
  }, [filterValues]);

  useEffect(() => {
    if (selectedReceipt) {
      if (selectedReceipt?.supplier?.id) {
        setValue('user_id', String(selectedReceipt.supplier.id));
      }
      if (selectedReceipt?.user?.id) {
        setValue('user_id', String(selectedReceipt.user.id));
      }
      if (selectedReceipt?.manualNum) {
        setValue('manualNum', selectedReceipt.manualNum);
      }
      if (selectedReceipt?.dueDate)
        setValue('due_date', moment(selectedReceipt.dueDate, 'YYYY-MM-DD'));
      if (selectedReceipt.expirationDate)
        setValue(
          'expirationDate',
          moment(selectedReceipt.expirationDate, 'YYYY-MM-DD')
        );
      if (selectedReceipt?.note) {
        setValue('note', selectedReceipt.note);
      }
      if (selectedReceipt?.desc) {
        setValue('desc', selectedReceipt.desc);
      }
      //
      if (selectedReceipt?.detailsType) {
        setValue('details_type', String(selectedReceipt.detailsType));
      }
      //
      if (selectedReceipt?.discountType) {
        if (
          selectedReceipt.discountType == '1' ||
          selectedReceipt.discountType == '2'
        ) {
          // 1 => fixed price discount
          // 2 => percentage discount
          setValue('discount_type', String(selectedReceipt.discountType));
        } else {
          setValue('discount_type', 'no_discount');
        }
      } else {
        setValue('discount_type', 'no_discount');
      }
      //
      if (
        String(selectedReceipt.discountValue) &&
        selectedReceipt.discountType == '1'
      ) {
        setValue('discount_price', selectedReceipt.discountValue);
      } else if (
        String(selectedReceipt.discountValue) &&
        selectedReceipt.discountType == '2'
      ) {
        setValue('discount_percentage', selectedReceipt.discountValue);
      }
      // if (selectedReceipt?.paymentType) {
      //   setValue('pay_type', String(selectedReceipt.paymentType));
      // }
      if (selectedReceipt?.paymentType == '5') {
        if (selectedReceipt?.bankName) {
          setValue('bankAccount.accountNumber', selectedReceipt.bankName);
        }
        if (selectedReceipt?.cheekNum) {
          setValue('bankAccount.price', selectedReceipt.cheekNum);
        }
      }
      //
    }
    /////////////////////////
    if (selectedReceipt) {
      // const { products, ingredients, extraPrices, installments } =
      //   selectedReceipt;

      const sharedReset = {
        manualNum: selectedReceipt?.manualNum ? selectedReceipt.manualNum : '',
        // user_id: selectedReceipt?.supplier?.id
        //   ? String(selectedReceipt.supplier.id)
        //   : '',
        user_id: selectedReceipt?.user?.id
          ? String(selectedReceipt.user.id)
          : '',
        due_date: selectedReceipt.dueDate
          ? moment(selectedReceipt.dueDate, 'YYYY-MM-DD')
          : null,
        expirationDate: selectedReceipt.expirationDate
          ? moment(selectedReceipt.expirationDate, 'YYYY-MM-DD')
          : null,
        note: selectedReceipt.note,
        details_type: selectedReceipt.detailsType
          ? String(selectedReceipt.detailsType)
          : '',
        //
        discount_type: selectedReceipt.discountType
          ? String(selectedReceipt.discountType)
          : 'no_discount',
        discount_price:
          selectedReceipt?.discountType == '1' &&
          String(selectedReceipt.discountValue)
            ? selectedReceipt.discountValue
            : '',
        discount_percentage:
          selectedReceipt?.discountType == '2' &&
          String(selectedReceipt.discountValue)
            ? selectedReceipt.discountValue
            : '',
        //
        // pay_type: selectedReceipt.paymentType
        //   ? String(selectedReceipt.paymentType)
        //   : '',
        bankAccount: {
          accountNumber:
            selectedReceipt?.bankName && selectedReceipt?.paymentType == '5'
              ? selectedReceipt.bankName
              : '',
          price:
            selectedReceipt?.cheekNum && selectedReceipt?.paymentType == '5'
              ? selectedReceipt.cheekNum
              : ''
        }
      };
      if (selectedReceipt.details?.length > 0) {
        const { details } = selectedReceipt;
        let mappedProducts = [];
        let mappedIngredients = [];
        let mappedInstallments = [];
        if (selectedReceipt?.detailsType == '1') {
          mappedProducts = details.map((product) => ({
            product_id: String(product.model_id),
            quantity: product.quantity,
            warehouse_id: product.stock?.id ? String(product.stock.id) : '',
            expirationDate: product.expirationDate
              ? moment(product.expirationDate, 'YYYY-MM-DD')
              : null,
            discount_type: product.discountType
              ? String(product.discountType)
              : 'no_discount',
            discount_price:
              product?.discountType == '1' && String(product.discountValue)
                ? product.discountValue
                : '',
            discount_percentage:
              product?.discountType == '2' && String(product.discountValue)
                ? product.discountValue
                : '',
            price: product?.price ? product.price : '',
            desc: product?.desc ? product.desc : '',
            tax: parseFloat(product?.tax) > 0 ? product.tax : '0'
          }));
        } else if (selectedReceipt?.detailsType == '2') {
          mappedIngredients = details.map((ingredient) => ({
            ingredient_id: String(ingredient.model_id),
            quantity: ingredient.quantity,
            warehouse_id: ingredient.stock?.id
              ? String(ingredient.stock.id)
              : '',
            expirationDate: ingredient.expirationDate
              ? moment(ingredient.expirationDate, 'YYYY-MM-DD')
              : null,
            discount_type: ingredient.discountType
              ? String(ingredient.discountType)
              : 'no_discount',
            discount_price:
              ingredient?.discountType == '1' &&
              String(ingredient.discountValue)
                ? ingredient.discountValue
                : '',
            discount_percentage:
              ingredient?.discountType == '2' &&
              String(ingredient.discountValue)
                ? ingredient.discountValue
                : '',
            price: ingredient?.price ? ingredient.price : '',
            tax: parseFloat(ingredient?.tax) > 0 ? ingredient.tax : '0'
          }));
        }
        //

        const mappedExtraPrices =
          selectedReceipt?.addPrices?.length > 0 &&
          selectedReceipt.addPrices.map((obj) => ({
            price: obj.price,
            title: obj.desc
          }));

        if (
          selectedReceipt.paymentType == '2' &&
          selectedReceipt.Installment?.length > 0
        ) {
          mappedInstallments = selectedReceipt.Installment.map((ins) => ({
            price: ins.price,
            //  status: String(ins.status),
            note: ins.note,
            date: moment(ins.date, 'YYYY-MM-DD')
          }));
        }

        reset({
          ...sharedReset,
          products: details?.length > 0 ? [...mappedProducts] : [],
          ingredients: details?.length > 0 ? [...mappedIngredients] : [],
          extra_prices:
            selectedReceipt?.addPrices?.length > 0
              ? [...mappedExtraPrices]
              : [],
          installments:
            selectedReceipt.Installment?.length > 0
              ? [...mappedInstallments]
              : []
        });
      }
    }
  }, [selectedReceipt, selectedReceipt?.id, reset]);

  const [form] = Form.useForm();
  return (
    <Form
      className="create-receipt-form custom-shared-form"
      form={form}
      layout="vertical"
      // initialValues={{
      //   products: watch('products')
      // }}
      fields={[
        {
          name: 'details_type',
          value: watch('details_type')
        },
        {
          name: 'discount_type',
          value: watch('discount_type')
        },
        // {
        //   name: 'pay_type',
        //   value: watch('pay_type')
        // },
        {
          name: 'products',
          value: watch('products')
        },
        {
          name: 'ingredients',
          value: watch('ingredients')
        },
        {
          name: 'installments',
          value: watch('installments')
        }
      ]}
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        {selectedReceipt?.user.name && (
          <div className="supplier-name-view">
            <span>{t('Quotations.InvoiceSupplier')}: </span>
            <span className="supp-name">{selectedReceipt?.user.name}</span>
          </div>
        )}
        {selectedReceipt?.supplier?.name && (
          <div className="supplier-name-view">
            <span>{t('Quotations.InvoiceSupplier')}: </span>
            <span className="supp-name">{selectedReceipt.supplier?.name}</span>
          </div>
        )}
        {/* <div className="text-field-label-wrap">
          <p className="label-p">رقم العرض</p>
          <div className="text-field-wrap">
            <AntdTextField
              disabled={selectedReceipt ? true : false}
              className="form-text-field"
              name="manualNum"
              type="text"
              placeholder="رقم العرض..."
              errorMsg={errors?.manualNum && errors.manualNum.message}
              validateStatus={errors?.manualNum ? 'error' : ''}
              control={control}
            />
          </div>
        </div> */}
        {!selectedReceipt && (
          <div className="prev-new-client-wrap">
            <div className="select-label-wrap">
              <p className="label-p">{t('Quotations.Choosetheclient')}</p>
              <div className="custom-select-wrap without-icon">
                <AntdSelectOption
                  name="user_id"
                  errorMsg={errors?.user_id?.message}
                  validateStatus={errors?.user_id ? 'error' : ''}
                  control={control}
                  setValue={setValue}
                  placeholder={`${t('Quotations.Choosetheclient')}`}
                  options={
                    allClients?.length > 0 &&
                    allClients.map((supp) => ({
                      title: supp.name,
                      value: String(supp.id)
                    }))
                  }
                  showSearch={true}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option?.children?.props?.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  formClassName="create-receipt-form"
                />
              </div>
            </div>
            <div
              className="new-client-btn"
              onClick={() => {
                setClientModalOpened(true);
              }}
            >
              <AddToCartIcon />
            </div>
            {clientModalOpened && (
              <NewClientModal
                setAllClientsFetchCount={setAllClientsFetchCount}
                isClient={true}
              />
            )}
          </div>
        )}
        <div className="date-picker-wrap">
          {/* <p className="label-p">تاريخ الاستحقاق ( DD / MM / YYYY )</p> */}
          <p className="label-p">{t('Quotations.ReleaseDate')}</p>
          <CustomAntdDatePicker
            // disablePast
            control={control}
            name="due_date"
            className="receipt-date-picker"
            formClassName="create-receipt-form"
          />
          {errors?.due_date && (
            <p className="error-p">{errors.due_date.message}</p>
          )}
        </div>
        <div className="date-picker-wrap">
          {/* <p className="label-p">تاريخ الاستحقاق ( DD / MM / YYYY )</p> */}
          <p className="label-p">{t('Quotations.OfferExpiryDate')}</p>
          <CustomAntdDatePicker
            // disablePast
            control={control}
            name="expirationDate"
            className="receipt-date-picker"
            formClassName="create-receipt-form"
          />
          {errors?.expirationDate && (
            <p className="error-p">{errors.expirationDate.message}</p>
          )}
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Quotations.Reviews')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="note"
              rows={8}
              placeholder={`${t('Quotations.Reviews')}`}
              errorMsg={errors?.note?.message}
              validateStatus={errors?.note ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div
          className="radios-field-array-wrap no-border"
          style={{ marginBottom: 0 }}
        >
          <AntdRadioGroup
            name="details_type"
            className="form-radio-group"
            control={control}
            label={t('Quotations.SelectInvoiceType')}
            validateStatus={errors?.details_type ? 'error' : ''}
            errorMsg={errors?.details_type?.message}
            radios={receiptRadiosArr}
            // defaultValue={
            //   selectedReceipt?.detailsType
            //     ? String(selectedReceipt.detailsType)
            //     : ''
            // }
            // isRadioButton
          />

          {/* {String(watch('details_type')) == '1' && ()} */}
          <div className="products-add-btn-wrap">
            <RenderProductsFieldsProducts
              allProducts={allProducts}
              allWarehouses={allWarehouses}
              productsFields={productsFields}
              removeProductField={removeProductField}
              selectedReceipt={selectedReceipt}
              watch={watch}
              errors={errors}
              setValue={setValue}
              control={control}
              register={register}
              systemSettings={systemSettings}
              setFilterValues={setFilterValues}
              setFetchNewCount={setFetchNewCount}
            />

            {watch('products')?.length > 0 &&
              watch(`products[0].product_id`) && (
                <div className="total-fields-price">
                  <span className="total-title">
                    {t('Quotations.ProductsPrice')} :{' '}
                  </span>
                  <span className="total-value">
                    {renderFieldsTotalValue(watch('products'))}
                  </span>
                </div>
              )}
          </div>

          {String(watch('details_type')) === '2' && (
            <div className="ingredients-add-btn-wrap s">
              <div
                className="add-new-field-btn"
                style={{ marginBottom: 12 }}
                onClick={() =>
                  appendIngredientField({
                    ingredient_id: '',
                    quantity: '',
                    warehouse_id: '',
                    expirationDate: '',
                    discount_type: '',
                    discount_price: '',
                    discount_percentage: '',
                    price: '',
                    tax: '0',
                    including_tax: ''
                  })
                }
              >
                <span>+ {t('Quotations.NewComponent')}</span>
              </div>
              {renderIngredientsFields(
                watch,
                allIngredients,
                allWarehouses,
                control,
                setValue,
                errors,
                ingredientsFields,
                removeIngredientField,
                selectedReceipt,
                systemSettings
              )}
              {watch('ingredients')?.length > 0 &&
                watch(`ingredients[0].ingredient_id`) && (
                  <div className="total-fields-price">
                    <span className="total-title">
                      {t('purchaseInvoices.ingredientsPrice')}
                    </span>
                    <span className="total-value">
                      {renderFieldsTotalValue(watch('ingredients'))}
                    </span>
                  </div>
                )}
            </div>
          )}
        </div>

        <div
          //  style={{
          //   display: 'flex',
          //   alignItems: 'center',
          //   justifyContent: 'space-between',
          //   marginTop: '10px',
          //   marginBottom: '30px'
          // }}

          className="newPriceContainer"
        >
          {/* extra prices section */}

          {/* {(watch('details_type') == '1' || watch('details_type') == '2') && (
            <div
              //  style={{
              //   borderTop: '1px solid rgba(0, 0, 0, .1)'
              // }}
              className="newPriceContainerCon"
            >
              <div
                className="add-new-field-btn"
                onClick={() =>
                  appendExtraPriceField({
                    price: '',
                    title: ''
                  })
                }
              >
                <span>+ {t('Quotations.NewExtraPrice')}</span>
              </div>

              {watch('extra_prices')?.length > 0 &&
                watch(`extra_prices[0].price`) && (
                  <div className="total-fields-price">
                    <span className="total-title">
                      {t('Quotations.PriceAfterAdditions')} :{' '}
                    </span>
                    <span className="total-value">
                      {renderTotalPriceAfterExtraPrices(
                        watch('details_type'),
                        watch('products'),
                        watch('ingredients'),
                        watch('extra_prices')
                      )}
                    </span>
                  </div>
                )}
            </div>
          )} */}

          {/* ./extra prices section */}

          {/* add new price */}
          <div
            className="add-new-field-btn"
            onClick={() =>
              appendProductField({
                product_id: '',
                quantity: '',
                warehouse_id: '',
                expirationDate: '',
                discount_type: 'no_discount',
                discount_price: '',
                discount_percentage: '',
                price: '',
                tax: '0',
                including_tax: ''
              })
            }
          >
            <span>+ {t('Quotations.NewProduct')}</span>
          </div>
          {/* ./add new price */}
        </div>
        <div>
          {renderExtraPricesFields(
            control,
            setValue,
            errors,
            extraPricesFields,
            removeExtraPriceField
          )}
        </div>
        {/* paytype section */}
        {/* {(watch('details_type') == '1' || watch('details_type') == '2') && (
          <div
            className="radios-field-array-wrap no-border"
            style={{ paddingBottom: '22px' }}
          >
            <AntdRadioGroup
              name="pay_type"
              className="form-radio-group"
              control={control}
              label="اختار نوع الدفع"
              validateStatus={errors?.pay_type ? 'error' : ''}
              errorMsg={errors?.pay_type?.message}
              radios={[
                {
                  title: 'كاش',
                  value: '1'
                },
                {
                  title: 'غير مدفوع',
                  value: '2'
                },
                {
                  title: 'تحويل بنكى',
                  value: '5'
                }
              ]}
              // isRadioButton
            />

            {String(watch('pay_type')) == '2' && (
              <div className="ingredients-add-btn-wrap">
                <div
                  className="add-new-field-btn"
                  onClick={() =>
                    appendInstallmentField({
                      price: '',
                      date: null,
                      note: '',
                      status: ''
                    })
                  }
                >
                  <span>+ اضافة ايصال جديد</span>
                </div>
                {renderInstallmentsFields(
                  control,
                  setValue,
                  errors,
                  installmentsFields,
                  removeInstallmentField
                )}
              </div>
            )}

            {String(watch('pay_type')) == '5' && (
              <div className="cheque-wrapper">
                <div className="text-field-label-wrap">
                  <p className="label-p">رقم الحساب</p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      setValue={setValue}
                      className="form-text-field"
                      name={`bankAccount.accountNumber`}
                      type="text"
                      placeholder="رقم الحساب..."
                      errorMsg={
                        errors?.bankAccount?.accountNumber &&
                        errors.bankAccount.accountNumber.message
                      }
                      validateStatus={
                        errors?.bankAccount?.accountNumber ? 'error' : ''
                      }
                      control={control}
                    />
                  </div>
                </div>
                <div className="text-field-label-wrap">
                  <p className="label-p">المبلغ</p>
                  <div className="text-field-wrap">
                    <AntdTextField
                      setValue={setValue}
                      className="form-text-field"
                      name={`bankAccount.price`}
                      type="text"
                      placeholder="المبلغ..."
                      errorMsg={
                        errors?.bankAccount?.price &&
                        errors.bankAccount.price.message
                      }
                      validateStatus={errors?.bankAccount?.price ? 'error' : ''}
                      control={control}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )} */}
        {/* ./paytype section */}
        <Button
          className="submit-btn "
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedReceipt
            ? t('Quotations.update')
            : t('Quotations.AddNewPrice')}
        </Button>
      </div>
    </Form>
  );
};

export default TableProductsForm;
