/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Select } from 'antd';
import SelectDropDown from '../../common/icons/SelectDropDown';
import UserContext from '../../contexts/user-context/UserProvider';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import useClients from '../../custom-hooks/useClients';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import routerLinks from '../../components/app/routerLinks';
import getAllSaleInvoicesApi from '../../apis/sale-invoice-api/getAllSaleInvoicesApi';
import getAllClientsApi from '../../apis/pos-api/clients-pos-apis/getAllClientsApi';
import userRoles from '../../userRoles';
import useUsers from '../../custom-hooks/useUsers';
import SearchIcon from '../../common/icons/SearchIcon';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';



const TableFilter = () => {
  const history = useHistory();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { user } = useContext(UserContext);
  const allDelegateUsers = useUsers(userRoles?.delegate);
  const [allClients, setAllClients] = useState();
  const customApiRequest = useCustomApiRequest();
  const { t } = useTranslation();

  const { Option } = Select;
  const receiptTypes = [
    {
      title: t('Invoices.saleInvoices'),
      value: '1'
    },
    {
      title: t('Invoices.returnInvoices'),
      value: '2'
    },
    {
      title:  t('Invoices.bookingInvoices'),
      value: '3'
    }
  ];
  const {
    setAllFetchedSaleInvoice,
    setIsLoadingAllSaleInvoices,
    setTableFilter,
    tableFilter,
    setTablePagination
  } = useContext(PosInvoiceContext);

  const debouncedChangeHandler = useCallback(
    debounce(async (e) => {
      setTableFilter((prevState) => ({
        ...prevState,
        invoiceNumber: e.target.value
      }));
    }, 500),
    []
  );

  async function handleChange(filterType, value) {
    setTableFilter((prevState) => {
      if (filterType === 'client') {
        return {
          ...prevState,
          user_id: value
        };
      } else if (filterType === 'type') {
        return {
          ...prevState,
          type: value
        };
      } else if (filterType === 'admin_id') {
        return {
          ...prevState,
          admin_id: value
        };
      }
    });
  }

  useEffect(() => {
    customApiRequest(
      getAllClientsApi(user?.token),
      (res) => {
        if (res?.status === 200 && res?.data?.data?.length >= 0) {
          const data = res.data.data;
          setAllClients(data);
        }
      },
      (error) => {}
    );
  }, []);

  useEffect(() => {
    setIsLoadingAllSaleInvoices(true);
    customApiRequest(
      getAllSaleInvoicesApi(user?.token || user?.employee?.token, {
        ...tableFilter,
        ...values,
        page: 1
      }),
      (res) => {
        history.push(`${routerLinks?.saleInvoicesPage}?page=1`);
        setIsLoadingAllSaleInvoices(false);
        if (res?.status === 200 && res?.data?.data?.data?.length >= 0) {
          if (res?.status === 200 && res?.data?.data?.data?.length >= 0) {
            const data = res.data.data.data;
            setAllFetchedSaleInvoice(data);
          }
          if (res?.data?.data?.pagination) {
            setTablePagination(res.data.data.pagination);
          }
        }
      },
      (error) => {
        setIsLoadingAllSaleInvoices(false);
      }
    );
  }, [tableFilter]);

  // async function handleSubmit(e) {
  //   e.preventDefault();
  //   setIsLoadingAllSaleInvoices(true);
  //   customApiRequest(
  //     getAllSaleInvoicesApi(user?.token || user?.employee?.token, {
  //       ...tableFilter,
  //       ...values,
  //       page: 1
  //     }),
  //     (res) => {
  //       history.push(`${routerLinks?.saleInvoicesPage}?page=1`);
  //       setIsLoadingAllSaleInvoices(false);
  //       if (res?.status === 200 && res?.data?.data?.data?.length >= 0) {
  //         if (res?.status === 200 && res?.data?.data?.data?.length >= 0) {
  //           const data = res.data.data.data;
  //           setAllFetchedSaleInvoice(data);
  //         }
  //         if (res?.data?.data?.pagination) {
  //           setTablePagination(res.data.data.pagination);
  //         }
  //       }
  //     },
  //     (error) => {
  //       setIsLoadingAllSaleInvoices(false);
  //     }
  //   );
  // }

  return (
    <div className="custom-table-fitler warehouse-table-filter">
      <div className="form-excel-export-wrap">
        <form
          className="filter-form receipt-filter-form"
          // onSubmit={handleSubmit}
        >
          <div className="search-label-wrapper">
            <p
              style={{
                marginBottom: 5
              }}
            >
              {t('Invoices.InvoiceNumber')}
            </p>
            <div className="table-search" style={{ width: '270px' }}>
              <label>
                <SearchIcon />
                <input
                  placeholder={t('SalesInvoices.EnterYourSerialInvoiceNumber')}
                  type="text"
                  name="invoiceNumber"
                  onChange={(e) => debouncedChangeHandler(e)}
                />
              </label>
            </div>
          </div>

          <label className="receipt-filter-option-wrap">
            <span className="select-title">{t('Invoices.CustomerName')} </span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('client', v)}
              suffixIcon={<SelectDropDown />}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>{t('Invoices.AllCustomers')}</Option>
              {allClients?.length > 0 &&
                allClients.map((item) => (
                  <Option key={item.id} value={String(item.id)}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </label>
          {user?.role !== userRoles?.delegate && (
            <label className="receipt-filter-option-wrap">
              <span className="select-title">{t('Invoices.EmployeeName')}</span>
              <Select
                defaultValue={null}
                style={{ width: 172 }}
                onChange={(v) => handleChange('admin_id', v)}
                suffixIcon={<SelectDropDown />}
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={null}>{t('Invoices.allEmployees')}</Option>
                {allDelegateUsers?.length > 0 &&
                  allDelegateUsers.map((item) => (
                    <Option key={item.id} value={String(item.id)}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </label>
          )}
          <label className="receipt-filter-option-wrap">
            <span className="select-title">{t('Invoices.InvoiceType')}</span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('type', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>{t('Invoices.allInvoices')}</Option>
              {receiptTypes.map((role, index) => (
                <Option key={index} value={role.value}>
                  {role.title}
                </Option>
              ))}
            </Select>
          </label>

          {/* <button className="filter-submit-btn" type="submit">
            بحث
          </button> */}
        </form>
      </div>
    </div>
  );
};

export default TableFilter;
