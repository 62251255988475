import React, { useContext } from 'react';
import { Dropdown, Menu, Button, Avatar } from 'antd';
import { Link as RouterLink } from 'react-router-dom';
import {
  UserOutlined,
  LogoutOutlined,
  LoadingOutlined,
  CaretDownOutlined
} from '@ant-design/icons';
import routerLinks from '../app/routerLinks';
import UserContext from '../../contexts/user-context/UserProvider';
import StopwatchContext from '../../contexts/stopwatch-context/StopwatchContext';
import useSignOut from '../../custom-hooks/useSignout';
import userRoles from '../../userRoles';
import { useTranslation } from 'react-i18next';
const MainAppProfileMenu = () => {
  const { user } = useContext(UserContext);
  useContext(StopwatchContext);
  const { t } = useTranslation();
  const { signMeOut, isLoadingSignOut } = useSignOut();
  const handleSignOut = () => {
    signMeOut();
  };

  return (
    <div className="avatar-wrapper">
      <Dropdown
        arrow
        trigger={['click']}
        // disabled={loadingSignOut}
        overlay={
          <Menu>
            {user?.role === userRoles?.manager && (
              <Menu.Item key="1" icon={<UserOutlined />}>
                <RouterLink to={routerLinks.ProfilePage}>
                  {t('LogOut.UserProfile')}
                </RouterLink>
              </Menu.Item>
            )}
            <Menu.Item
              key="2"
              icon={<LogoutOutlined />}
              onClick={handleSignOut}
            >
              {t('LogOut.logOut')}
            </Menu.Item>
          </Menu>
        }
      >
        <Button className="profile-menu-btn" type="text">
          <Avatar size={38} icon={<UserOutlined />} src={user?.image} />
          {isLoadingSignOut ? <LoadingOutlined /> : <CaretDownOutlined />}
        </Button>
      </Dropdown>
    </div>
  );
};

export default MainAppProfileMenu;
