/* eslint-disable eqeqeq */
// helpers
const calcProductIngredientsPrice = (ingredients) => {
  let total = 0;
  if (ingredients?.length > 0) {
    for (let ing of ingredients) {
      if (
        ing?.ingredientInCartQuantity &&
        parseFloat(ing.ingredientInCartQuantity) > 0 &&
        ing?.priceOfUnit &&
        parseFloat(ing.priceOfUnit) > 0
      ) {
        total =
          total +
          parseFloat(ing.priceOfUnit) *
            parseFloat(ing.ingredientInCartQuantity);
      }
    }
  }
  return total;
};

// end helpers

// calc product net price
export const calcProductNetPrice = (product) => {
  // salePrice * quantity
  let total = 0;
  if (
    product?.salePrice &&
    parseFloat(product.salePrice) >= 0 &&
    product?.productInCartQuantity &&
    parseFloat(product.productInCartQuantity) >= 0
  ) {
    total =
      parseFloat(product.salePrice) * parseFloat(product.productInCartQuantity);
  }
  return total;
};

//////////////////////////////////////////////////
//////////////////////////////////////////////////
// calc product net price with ingredients without discount and without tax
export const calcProudctNetPriceWithIngredients = (product, ingredients) => {
  let total = calcProductNetPrice(product);
  total = total + calcProductIngredientsPrice(ingredients);

  return total;
};
//////////////////////////////////////////////////
// calculate product net price with discount on the product
// posOfferType = 0 or no_discount => no discount
// posOfferType = 1 => fixed number discount
// posOfferType = 2 => percentage number discount
export const calcProductNetPriceWithDiscount = (
  product,
  ingredients,
  posOfferType,
  posOffer
) => {
  // first get the product total net price
  let total = calcProudctNetPriceWithIngredients(product, ingredients);
  // second get the product discount
  if (posOfferType == 'no_discount' || posOfferType == '0') {
    return total;
  } else if (posOfferType == '1' && posOffer && parseFloat(posOffer) > 0) {
    // fixed number discount
    total = total - parseFloat(posOffer);
  } else if (posOfferType == '2' && posOffer && parseFloat(posOffer) > 0) {
    // percentage number discount
    total = total - (total * parseFloat(posOffer)) / 100;
  }
  return total;
};
//////////////////////////////////////////////////
//////////////////////////////////////////////////
// calcuate product net price with tax
// if product has tax use it
// if not use the system tax
export const calcProductNetPriceWithTax = (
  product,
  ingredients,
  posOfferType,
  posOffer,
  systemTax
) => {
  // use product tax
  if (product && product?.tax && parseFloat(product.tax) > 0) {
    return (
      calcProductNetPriceWithDiscount(
        product,
        ingredients,
        posOfferType,
        posOffer
      ) +
      (calcProductNetPriceWithDiscount(
        product,
        ingredients,
        posOfferType,
        posOffer
      ) *
        parseFloat(product.tax)) /
        100
    );
  }
  // use system tax
  // else if (
  //   product &&
  //   (!product?.tax || (product.tax && parseFloat(product.tax) == 0)) &&
  //   systemTax &&
  //   parseFloat(systemTax) >= 0
  // )
  else {
    return (
      calcProductNetPriceWithDiscount(
        product,
        ingredients,
        posOfferType,
        posOffer
      ) +
      (calcProductNetPriceWithDiscount(
        product,
        ingredients,
        posOfferType,
        posOffer
      ) *
        systemTax) /
        100
    );
  }
};
//////////////////////////////////////////////////
//////////////////////////////////////////////////
// calculate product price after tax and after ingredients
export const calcProductNetPriceWithTaxWithIngredients = (
  product,
  ingredients,
  posOfferType,
  posOffer,
  systemTax
) => {
  // first calculate product net price with tax then add product ingredients price to it
  let total = calcProductNetPriceWithTax(
    product,
    ingredients,
    posOfferType,
    posOffer,
    systemTax
  );

  total = total + calcProductIngredientsPrice(ingredients);

  return total;
};
//////////////////////////////////////////////////
//////////////////////////////////////////////////
